import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutConfirmarCarga() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putConfirmarCarga = useCallback(
    (id: string) => {
      return invocarApi({
        url: `/v3/conversao/${id}/confirmar`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlCarga}/api`,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putConfirmarCarga,
  };
}
