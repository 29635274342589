import { VariaveisAmbiente } from 'config';
import { isEmpty } from 'lodash';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import React from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';
export function useMovInsercaoStorage() {
  const { getRegistro, setRegistro } = useGestaoStorage();

  const setInsercaoFavorita = React.useCallback(
    async (rota: string) => {
      setRegistro(GestaoStorageKeys.MovSimplesInsercaoFavorita, rota, false);
    },
    [setRegistro]
  );

  const getInsercaoFavorita = React.useCallback((): string => {
    const tpInsercao = getRegistro(
      GestaoStorageKeys.MovSimplesInsercaoFavorita,
      false
    );

    if (!isEmpty(tpInsercao)) {
      return tpInsercao
    };

    const arrayValidation = [EnumDeviceType.CORDOVA, EnumDeviceType.MERCADOPAGO, EnumDeviceType.MERCADOPAGO_DEV]

    if (VariaveisAmbiente.isSlowDevice && arrayValidation.includes(VariaveisAmbiente.paymentDevice)) {
      setInsercaoFavorita(EnumInsercaoFavorita.AVULSO)
      return EnumInsercaoFavorita.AVULSO;
    }

    if (
      arrayValidation.includes(VariaveisAmbiente.paymentDevice)
    ) {
      setInsercaoFavorita(EnumInsercaoFavorita.CATALOGO)
      return EnumInsercaoFavorita.CATALOGO;
    }

    setInsercaoFavorita(EnumInsercaoFavorita.CATALOGO)
    return EnumInsercaoFavorita.CATALOGO;
  }, [getRegistro, setInsercaoFavorita]);

  return {
    getInsercaoFavorita,
    setInsercaoFavorita
  };
}