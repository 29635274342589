import { CardNaoEncontrado } from "../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../components/icons/informacao-icon';
import { ManifestoModel } from "model/api/gestao/manifesto/manifesto-model";
import { CardManifesto } from "views/components/cards/card-manifesto/card-manifesto";

export interface ManifestoListDataProps {
    list: Array<ManifestoModel>;
    carregando: boolean;
    onCardClick: (model: ManifestoModel) => any;
}

export const ManifestosListData = ((props: ManifestoListDataProps) => {

    const onCardClick = (model: ManifestoModel) => {
        props.onCardClick(model)
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado 
                mensagem="Nenhum Manifesto encontrado no período." 
                icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((model, index) => {
                return (
                    <CardManifesto
                        model={model}
                        onClick={onCardClick}
                        selected={false}
                    />
                );
            })}

        </>
    );
}
);