import * as Yup from "yup";

export const useFormInfoAdicionalProdutoValidation = () => {
    const FormInfoAdicionalProdutoValidation = Yup.object().shape({
        infAdic: Yup.string().test('is-valid', 'A observação deve conter no máximo 150 caracteres.', function (value) {
            if (value === null || value === undefined || value.trim() === '') {
              return true; // A validação é ignorada se o campo estiver vazio ou nulo
            }
            return value.length <= 150;
          }),
    });

    return {
        FormInfoAdicionalProdutoValidation,
    };
}