import { Button, Grid } from '@material-ui/core';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { SalvarEditarIcon } from '../../../../icons/salvar-editar-icon';
import { SalvarNovoIcon } from '../../../../icons/salvar-novo-icon';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { usePostSalao } from 'data/api/gestao/saloes/post-salao';
import { SaloesCadastroFormModel } from 'model/app/forms/saloes/saloes-cadastro-form-model';
import { NewSalaoModel } from 'model/api/gestao/saloes/saloes-model';
import { FormSalaoCadastro } from 'views/components/form/saloes/form-salao-cadastro/form-salao-cadastro';

export const SaloesCadastro = () => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postSalao, carregando } = usePostSalao();
  const { fecharCadastroSaloes, abrirCadastroSaloes } = useCadastros();

  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  const cadSaloesFormRef =
    useRef<DefaultFormRefs<SaloesCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const saveNewSalao = useCallback(
    async (model: NewSalaoModel) => {
      try {
        const ret = await postSalao(model, getEmpresaSelecionada()?.Id || '');
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroSaloes(ret.resultado?.data.id, '', true);
          }, 1);
        }
        showToast('success', 'Salão adicionado com sucesso!');

        if (!redirect.current) {
          cadSaloesFormRef.current?.resetForm();
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadSaloesFormRef.current?.fillForm({
          ...model,
        });
      }
    },
    [postSalao, getEmpresaSelecionada, showToast, abrirCadastroSaloes],
  );

  const handleSubmit = useCallback(
    async (modelP: SaloesCadastroFormModel) => {
      return saveNewSalao({ ...modelP, status: 1 });
    },
    [saveNewSalao],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadSaloesFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadSaloesFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroSaloes();
  }, [fecharCadastroSaloes]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Cadastro de Salão'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormSalaoCadastro
            ref={cadSaloesFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
