import { useSessaoAtual } from 'services/app';
import { MovModeloMock } from 'data/mocks/mov-modelo-mock';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { ProdsModel } from 'model/api/gestao/venda/venda-completa-model';
import { Alinhamento } from 'utils/alinhamento';
import { StringBuilder } from 'utils/stringBuilder';
import { toDateString } from 'utils/to-date';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useCriptografia } from './criptografia';
import { EnumTpEmis } from 'model/enums/enum-tp-emis';
import { validaCaracter } from 'utils/valida-caracter';

export const useImpressaoTicket = () => {
  const { criptografar } = useCriptografia();
  const { getPessoa } = useSessaoAtual();

  const impressaoTicket = async (
    venda: any,
    qtd_colunas: number,
    tpEmis: EnumTpEmis
  ): Promise<string> => {
    const getPessoaWrapper = async (id: string) => {
      const res = await TouchoneDBPrimary.clientes.get({
        id
      });

      if (!res) {
        return validaCaracter(getPessoa().pessoa?.nome) ?? 'Vendedor';
      }

      return validaCaracter(res?.nome ?? 'Vendedor');
    };

    const nome = await getPessoaWrapper(venda!.prod[0].vendedorId);
    
    let indexItem = 0;
    const ticketStringNormal = venda!.prod
      .filter((prod: ProdsModel) => prod.cancelado === false && prod.indFin)
      .map((prods: ProdsModel, index: number, arr: ProdsModel[]) => {
        const totalItens = arr.reduce<number>((prev, curr) => curr.qCom + prev, 0) || 0;
        let retorno: string = '';
        for (let i = 0; i < prods.qCom; i++) {
          indexItem += 1;
          const xImp = new StringBuilder();

          xImp.clear();
          xImp.append("<n>")
          xImp.appendWithln('<centro><expandido>Ticket Consumação</expandido>');
          xImp.append("</n>")

          const modelo =
            'Item ' +
            (indexItem) +
            '/' +
            totalItens;
          xImp.append('<cond>');
          xImp.appendWithln(
            modelo
          );
          const date = 'Valido para ' + toDateString(new Date(), 'DD/MM/yyyy  HH:mm')
          xImp.appendWithln(
            Alinhamento.AlinhaCentro(
              date,
              ' ',
              date.length
            )
          )
          xImp.appendWithln(
            'Vendedor: ' + nome
          );
          xImp.append(Alinhamento.AlinhaCentro('-', '-', qtd_colunas));
          xImp.append('</cond>');

          xImp.appendWithln('<esquerda>');
          xImp.appendWithln('<expandido>Codigo: ' + prods.cProd);
          xImp.appendWithln('Valor: R$ ' + toDecimalString(prods.vUnCom));
          xImp.append("<n>")
          xImp.appendWithln(`${prods.xProd}</expandido>`);
          xImp.append("</n>")

          xImp.appendWithln('<cond>');

          xImp.appendWithln(Alinhamento.AlinhaCentro('-', '-', qtd_colunas));
          xImp.appendWithln(
            '<qrCODE>' +
            criptografar(
              `${prods.id}|${String(i + 1)}|${venda.emit!.doc}|${prods.cProd
              }|${String(toDecimal(prods!.qCom))}|${String(
                toDecimal(prods!.vProd)
              )}|${prods.xProd}|${toDateString(venda.dhEmi)}|${String(
                venda!.nnf
              )}|${MovModeloMock.filter((item) => item.Key === venda.mod)[0]
                .Value
              }`
            ) +
            '</qrCODE>'
          );
          xImp.appendWithln(Alinhamento.AlinhaCentro('-', '-', qtd_colunas));

          xImp.appendWithln('<guilhotina>');

          retorno += xImp;
        }
        return retorno;
      });
    return ticketStringNormal.join(' ');
  };

  return {
    impressaoTicket
  };
};