import { roundTo } from './round-to';

export function toDecimal(value: any, casas: number = 2): number {
  const ret = parseFloat(roundTo(parseFloat(value.toString().replace(',', '.')), casas).toString().replace(",", "."))
  if (isNaN(ret))
    return 0;
  return ret;
}

export function toDecimalString(value: any, casas: number = 2): string {
  if (value === '') return '';
  return roundTo(parseFloat(value.toString().replace(",", ".")), casas).toFixed(casas).toString().replace(".", ",");
}


export function toDecimalStringGeneralizated(value: any, casas: number = 2): string {
  if (value === '') return '';
  return roundTo(parseFloat(value.toString().replace(",", ".")), casas).toString().replace(".", ",");
}

export function toCurrency(value: any): string {
  if(typeof value !== 'number') return ''
  const ret = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  return ret;
}

export function isDecimal(value: number): boolean {
  const strNumber = value.toString()
  return strNumber.includes('.') && Number(strNumber.split('.')[1]) > 0
}

export function formatDecimalInteger(value: number, casas: number = 2): string {
  return isDecimal(value) ? toDecimalString(value, casas) : value.toString()
}

export function isOnlyHasNumber(str: string) {
  return /^[0-9]+$/.test(str);
}
