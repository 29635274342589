import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormVolumeTranValidation } from './form-volume-tran-validation';
import { FormDadosTransportadoraProps } from './form-volume-tran-props';
import { TranpsVolumeFormModel } from 'model/app/forms/entrada/transp-volume-form-model';
import { picker } from 'utils/picker';

export const FormVolumeTran = forwardRef<
    DefaultFormRefs<TranpsVolumeFormModel>,
    FormDadosTransportadoraProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formVolumeTranValidationYup } = useFormVolumeTranValidation();
    const [model, setModel] = useState<TranpsVolumeFormModel>(new TranpsVolumeFormModel())
    const modelFormRef = useRef<TranpsVolumeFormModel>(new TranpsVolumeFormModel())

    const {
        handleSubmit,
        control,
        formState,
        reset,
    } = useForm<TranpsVolumeFormModel>({
        resolver: yupResolver(formVolumeTranValidationYup),
        criteriaMode: 'all',
        defaultValues: {
            ...model
        },
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: TranpsVolumeFormModel) => {
        const vol = picker<TranpsVolumeFormModel>(values, new TranpsVolumeFormModel())
        props.onSubmit(vol, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: TranpsVolumeFormModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="qVol"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Quantidade"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.qVol && errors.qVol.message)}
                                        helperText={
                                            touchedFields.qVol || errors.qVol
                                                ? errors.qVol?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="esp"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Espécie"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.esp && errors.esp.message)}
                                        helperText={
                                            touchedFields.esp || errors.esp
                                                ? errors.esp?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="nVol"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.nVol && errors.nVol.message)}
                                        helperText={
                                            touchedFields.nVol || errors.nVol
                                                ? errors.nVol?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="marca"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Marca"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.marca && errors.marca.message)}
                                        helperText={
                                            touchedFields.marca || errors.marca
                                                ? errors.marca?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="pesoL"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        casasDecimais={4}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Peso Líquido"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        manterMascara
                                        error={Boolean(errors.pesoL && errors.pesoL.message)}
                                        helperText={
                                            touchedFields.pesoL || errors.pesoL
                                                ? errors.pesoL?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="pesoB"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        casasDecimais={4}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Peso Bruto (Kg)"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        manterMascara
                                        error={Boolean(errors.pesoB && errors.pesoB.message)}
                                        helperText={
                                            touchedFields.pesoB || errors.pesoB
                                                ? errors.pesoB?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
