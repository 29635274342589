import { EnumTipoProduto } from "model/enums/enum-tipo-produto";

export class MedicamentoCompletoFormModel {
  constructor(
    public nomeCategoria: string = "",
    public categoriaId: string | null = null,
    public nome: string = "",
    public infAdic: string = "",
    public medidaId: string | null = null,
    public medida: string = "",
    public vCompra: number = 0,
    public vPreco: number = 0,
    public pLucro: number = 0,
    public precoMaximoConsumidor: number = 0,
    public precoFabricacao: number = 0,
    public codigoNcm: string = "",
    public ncmId: string | null = null,
    public impostoId: string | null = null,
    public imposto: string = "",
    public imagemUrl: string = "",
    public tipo: EnumTipoProduto = EnumTipoProduto.Medicamento
  ) { }
}