import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, VoltarIcon } from "views/components/icons";
import { PesquisaDashboardAdmin } from "./pesquisa-dashboard-admin/pesquisa-dashboard-admin";
import { MenuOptions } from "views/components/menu-options/menu-options";
import { MenuOptionsModel } from "views/components/menu-options/model/menu-options-model";
import { useCadastros } from "services/app";

interface Props {
    titulo: string
    setPesquisa: (termo: string) => any;
    openPesquisa: boolean;
    setOpenPesquisa: (state: boolean) => any;
    onClickRemove: () => void;
}
export const DashboardAdminHeader = ({ titulo, setPesquisa, setOpenPesquisa, openPesquisa, onClickRemove }: Props) => {

    const { abrirCadastroContratos } = useCadastros()

    const leftArea = useCallback(
        () => (
            titulo ? <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Voltar" onClick={() => {
                onClickRemove()
                setPesquisa('')
            }}></ButtonPrivateHeader> : null
        ),
        [onClickRemove, setPesquisa, titulo]
    );

    const createButtonRight = useCallback(() => {
        return (
            <MenuOptions
                headerPage
                options={[
                    new MenuOptionsModel(`${!openPesquisa ? "Exibir filtro" : "Fechar filtro"}`,
                        <FiltroIcon tipo="BUTTON" />,
                        () => setOpenPesquisa(!openPesquisa)
                    ),
                    new MenuOptionsModel("Vincular contrato", <></>, () => abrirCadastroContratos('', '', false))
                ]}
            />
        );
    }, [abrirCadastroContratos, openPesquisa, setOpenPesquisa]);

    const closePesquisaWrapper = useCallback(() => {
        setOpenPesquisa(false);
    }, [setOpenPesquisa]);

    const onPesquisar = useCallback(
        (termo: string) => {
            setPesquisa(termo);
        },
        [setPesquisa]
    );


    const pesquisa = <PesquisaDashboardAdmin isOpened={openPesquisa!} onCloseSearch={closePesquisaWrapper} onPesquisar={onPesquisar} />;

    return (
        <>
            <PrivatePageHeader
                title={`Dashboard${titulo?.length !== 0 ? `: ${titulo}` : ''}`}
                leftArea={leftArea()}
                rightArea={createButtonRight()}
                bottomArea={pesquisa}
            />
        </>
    );
};
