import { Grid } from "@material-ui/core"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { Processos } from "views/components/form/setor/form-setor-edit/components/processos"
import { AccordionProcessosProps } from "./accordion-processos-props"
import { EnumStatusProcessoTipo } from "model/enums/enum-processo-tipo"
import { useState } from "react"
import { useThemeQueries } from "views/theme"

export const AccordionProcessos = ({ models, handleClickCard, handleOpenNovoProcesso, ...props }: AccordionProcessosProps) => {
    const [accProd, setAccProd] = useState<boolean>(true)

    const { theme } = useThemeQueries()

    return (
        <>
            <Grid item xs={12}>
                <AccordionSaurus
                    labelPrimary={'Inicial'}
                    showDivider
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    heightDivider={2}
                    tipoExpand="bold"
                >
                    <Processos
                        listProcessos={models.filter(
                            (item) =>
                                item.status.codigo ===
                                EnumStatusProcessoTipo.AguardandoInicio
                        )}
                        handleClickCard={(model) => handleClickCard(model, true)}
                        handleOpenNovoProcesso={handleOpenNovoProcesso}
                        showAddButton={false}
                    />
                </AccordionSaurus>
            </Grid>
            <Grid item xs={12}>
                <AccordionSaurus
                    labelPrimary={'Produção'}
                    showDivider
                    expanded={accProd}
                    onChange={() => setAccProd(prev => !prev)}
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    heightDivider={2}
                    tipoExpand="bold"
                >
                    <Processos
                        listProcessos={models.filter((item) =>
                            [
                                EnumStatusProcessoTipo.EmProducao,
                                EnumStatusProcessoTipo.AguardandoRetirada,
                                EnumStatusProcessoTipo.EmEntrega
                            ].includes(Number(item.status.codigo))
                        )}
                        handleClickCard={handleClickCard}
                        handleOpenNovoProcesso={handleOpenNovoProcesso}
                        showAddButton
                    />
                </AccordionSaurus>
            </Grid>
            <Grid item xs={12}>
                <AccordionSaurus
                    labelPrimary={'Finalização'}
                    showDivider
                    colorDivider={theme.palette.primary.main}
                    colorExpand={theme.palette.primary.main}
                    heightDivider={2}
                    tipoExpand="bold"
                >
                    <Processos
                        listProcessos={models.filter(
                            (item) =>
                                item.status.codigo ===
                                EnumStatusProcessoTipo.Finalizado ||
                                item.status.codigo === EnumStatusProcessoTipo.Cancelado
                        )}
                        handleClickCard={(model) => handleClickCard(model, true)}
                        handleOpenNovoProcesso={handleOpenNovoProcesso}
                        showAddButton={false}
                    />
                </AccordionSaurus>
            </Grid>
        </>
    )
}