import { isEmpty } from "lodash";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogAdicionarProdutoBalanca, DialogAdicionarProdutoBalancaProps } from "views/components/dialog/dialog-adicionar-produto-balanca/dialog-adicionar-produto-balanca";

export const AdicionarProdutoBalancaDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogAdicionarProdutoBalancaProps>({
        produto: new MovSimplesProdutoModel(),
        aberto: false,
    });

    consoleDev('AdicionarProdutoBalancaDialog')

    const modalAlterado = useCallback(({ produto, aberto }: any) => {
        setModalState({
            produto: produto,
            aberto: aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.AdicionarProdutoBalancaDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.AdicionarProdutoBalancaDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {!isEmpty(modalState.produto) && modalState.aberto && (
                <DialogAdicionarProdutoBalanca aberto={modalState.aberto} produto={modalState.produto} />
            )}
        </>
    )
}