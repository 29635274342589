export class ProdutoMedicamentoPrecoFormModel {
    constructor(
        public vCompra: string = "0",
        public vPreco: string = "0",
        public pLucro: string = "0",
        public dataInicial: string = '',
        public dataFinal: string = '',
        public uf: string = '',
        public qtdEmbalagem: number = 1,
        public precoMaximoConsumidor: number = 0,
        public precoFabricacao: number = 0,
    ) { }
}  