import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    
  },
  contatoField: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  btnDeletar: {
    "& svg": {
      marginRight: theme.spacing(1),
      width: "22px",
      height: "22px",
      fill: theme.palette.primary.main,
    },
  },
}));