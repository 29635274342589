import { makeStyles } from "@material-ui/core";

export const styles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        background: theme.palette.primary.main,
        margin: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[4],
        
    },
    header: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
    },
    contentSubtitle: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
    },
    textTitle: {
        color: '#fff',
        fontWeight: 800,
        lineHeight: "150%",

    },
    text: {
        color: '#fff',
        fontSize: 13
    },
    button: {
        borderRadius: "10px",
        fontWeight: 800,
        lineHeight: "20px",
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
    },
    chart: {
        display: "flex",
        flexDirection: "row",
        margin: theme.spacing( 0 , 0 , 2, 0),
    },
    buttonClose: {
        borderRadius: "100%",
        padding: 3,
        marginTop: 0,
        marginRight: -8,
        fontSize: 16,
        width: 26,
        height: 26,
        display: "flex",
        alignItems: "flex-start",
        color: "#fff",
        "& svg": {
            width: "100%",
            height: "100%",
            margin: 0
        }
    },
    textInfoAndButton: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        marginRight: theme.spacing(2)
    },
    containerInterno: {
        flex: 1,
        flexDirection:'column',
        boxSizing:'border-box',
    }

}));

