import { useCallback, FocusEvent, useState } from 'react';

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { TouchoneDBPrimary } from 'database/touchone-database';

export interface AutocompleteFinalizadorasProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  finalizadoraId?: string;
  allowSubmit?: boolean;
  modo: 'descricao';
  disabled?: boolean;
}
interface FinalizadorasPesquisa extends FinalizadoraModel {
  campoFormatado: string;
}

export const AutocompleteFinalizadora = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteFinalizadorasProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [finalizadorasState, setFinalizadorasState] = useState<
    Array<FinalizadorasPesquisa>
  >(new Array<FinalizadorasPesquisa>());

  const getFinalizadorasWrapper = useCallback(async (termo: string) => {
    try {
      setLoading(true);
      const ret = await TouchoneDBPrimary.finalizadoras
        .filter((finalizadora) =>
          finalizadora
            .descricao!.toLowerCase()
            .includes(termo.toLocaleLowerCase()),
        )
        .toArray();

      let finalizadoras: Array<FinalizadorasPesquisa> =
        new Array<FinalizadorasPesquisa>();

      if (ret.length > 0) {
        finalizadoras = ret as Array<FinalizadorasPesquisa>;
      }
      finalizadoras.forEach((item) => {
        item.campoFormatado = `${item.descricao}`;
      });

      setFinalizadorasState(finalizadoras);
    } catch (e: any) {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const onPesquisa = useCallback(
    (termo: string) => {
      let termoPesq = termo.length > 0 ? termo.split('-')[0].trim() : termo;
      getFinalizadorasWrapper(termoPesq);
    },
    [getFinalizadorasWrapper],
  );

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      loading={loading}
      tipoTextField="TEXTO"
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={finalizadorasState}
      optionLabelKey={props.modo}
      optionValueKey="codigo"
      disabledValueKey="id"
      disabledValues={[props.finalizadoraId]}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
