import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PerfilModel } from 'model/api/gestao/perfil/perfil-model';
import { VariaveisAmbiente } from 'config';

export function usePutPerfil() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putPerfil = useCallback(
		(perfil: PerfilModel) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/Perfil/${perfil.id}`,
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				data: JSON.stringify(perfil),
				enviarTokenUsuario: true,
			}),
		[invocarApi]
	);
	return {
		...outrasPropriedades,
		putPerfil
	}
}