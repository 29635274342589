import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: ({ paymentScreen }: any) => {

    return {
      margin: theme.spacing(0, 1, 1, 0),
      minWidth: paymentScreen ? '80px' : '130px',
      height: paymentScreen ? '80px' : '130px',
      flex: 1,
      padding: theme.spacing(1),
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "transparent",
      transition: "0.3s ease-in-out border",
      background: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      cursor: "pointer",
      borderRadius: 14,
      display: "flex",
      flexDirection: "column",
      minHeight: paymentScreen ? '80px' : "110px",
      position: "relative",
    }
  },
  cardIcon: ({ paymentScreen }: any) => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        margin: 0,
        width: paymentScreen ? '24px' : '48px',
        height: paymentScreen ? '24px' : '48px',
      },
    }
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
  },
  celulaGrid: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
    "& p": {
      textAlign: "center",
      lineHeight: 1,
    }
  },
  textPayment: {
    fontWeight: 600,
    fontSize: 12,
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',

  },
  textPaymentMain: {
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  textPaymentDisabled: {
    color: theme.palette.action.disabledBackground
  },
  iconCadeado: {
    position: 'absolute',
    right: 0,
    top: 2,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
}));

