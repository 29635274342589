import { EnumTpDescontoAcrescimo } from 'model/enums/enum-tp-desconto-acrescimo';
import * as Yup from 'yup';

export const useFormAdicionarDescontoAcrescimoValidation = (valor: number) => {

  const formAdicionarDescontoAcrescimoValidation = Yup.object().shape({
    vPorcentagem: valor === EnumTpDescontoAcrescimo.Porcentagem
      ? Yup.string().required('Este campo é obrigatório.').typeError('Este campo é obrigatório.')
        .test("vPorcentagem", "O valor da porcentagem não pode ser negativo.", val => {
          if (val && val.includes('-')) {
            return false
          }
          return true
        })
      : Yup.string().nullable(),
    vValorFixo: valor === EnumTpDescontoAcrescimo.ValorFixo
      ? Yup.string().required('Este campo é obrigatório').typeError('Este campo é obrigatório.')
        .test("vValorFixo", "O valor fixo não pode ser negativo.", val => {
          if (val && val.includes('-')) {
            return false
          }
          return true
        })
      : Yup.string().nullable(),
  });

  return {
    formAdicionarDescontoAcrescimoValidation
  }
}

