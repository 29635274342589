import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 220,
    background: theme.palette.common.white,
    padding: '8px'
  },
  header: ({ removerMargem }: any) => {
    return {
      display: 'flex',
      flex: 1,
      padding: 8,
      background: '#F5F5F5',
      borderRadius: 4,
      marginBottom: removerMargem ? 0 : 8
    };
  },
  headerPartUm: {
    display: 'flex',
    flex: 1,
    padding: 5,
    justifyContent: 'space-between',
    background: theme.palette.grey[200],
    borderRadius: 4
  },
  headerPartDois: {
    flex: 1,
    maxWidth: '27%',
    padding: 5,
    background: theme.palette.common.white,
    borderRadius: 4,
    marginLeft: 8
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  labelText: ({ fontSize }: any) => {
    return {
      fontSize: fontSize,
      fontWeight: 600,
      fontFamily: 'Montserrat',
      color: theme.palette.secondary.dark
    }
   },
  divider: {
    margin: '8px 4px 4px 4px',
    width: 1,
    height: 40,
    background: '#F5F5F5',
    borderRightWidth: `1px solid #F5F5F5`
  },
  labelReais: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main
  },
  moreArea: {
    marginTop: 8,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  labelMoreActions: {
    fontSize: 10,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    color: theme.palette.secondary.main,
    textAlign: 'center'
  },
  paymentArea: {
    display: 'flex',
    flexDirection: 'row'
  }
}));
