import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    button: {
        height: '120px',
        marginBottom: '8px'
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
