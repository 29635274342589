import { useMemo } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useCadastros } from 'services/app';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useMedicamentoCadastro } from './components/use-medicamento-cadastro';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { Stepper } from 'views/components/stepper';
import { useStyles } from '../produto-cadastro/produto-cadastro-styles';
import { Box, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { CardPropagandaUrl } from 'views/components/cards/card-propaganda-url/card-propaganda-url';
import { useLocation } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

export const MedicamentoCadastro = (props: {
  empresaId: string;
  contratoId: string;
  title: string;
  isPlanoFarmaceutico: boolean;
  voltarTela: () => void;
}) => {
  const { fecharCadastroProduto } = useCadastros();
  const { carregando, formArray, formStepper } = useMedicamentoCadastro(
    props.contratoId,
    props.empresaId,
    props.voltarTela,
  );
  const modalClasses = useModalStyles();
  const classes = useStyles();
  const stepperClasses = useStepperStyles();
  const confirm = useConfirm();

  const location = useLocation();

  const onCloseClick = () => {
    const redirect = () => {
      const url = location.pathname.split('/')[1]
      fecharCadastroProduto('/' + url)
    }


    if (formStepper.currentStep > 0) {
      confirm({
        title: '',
        description: 'Deseja cancelar o cadastro do medicamento?',
        confirmationText: 'Sim',
        cancellationText: 'Não'
      }).then(() => redirect())
      return
    } else {
      if (props.isPlanoFarmaceutico) {
        props.voltarTela();
        return
      }
    }

    redirect()
  }

  const getButtons = useMemo(() => {
    return (
      <div className={classNames(stepperClasses.stepButtons, classes.buttons)}>
        <Box>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={formArray[formStepper.currentStep].previousButton ? 6 : 12}
                sm={formArray[formStepper.currentStep].previousButton ? 6 : 12}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, classes.buttons, formArray, formStepper.currentStep]);

  return (
    <div className={classNames(modalClasses.root, classes.root)}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title='Cadastrar Medicamento'
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={stepperClasses.stepper}>
          <Stepper
            linear={true}
            steps={formArray}
            stepAtual={formStepper.currentStep}
          />
        </div>
        <div className={stepperClasses.stepFix}>
          <div className={classNames(stepperClasses.step, classes.stepContent)}>
            <div className={stepperClasses.stepTitleContent}>
              <Typography
                align="center"
                className={stepperClasses.stepTitle}
                variant="h4"
                color="primary"
              >
                {formArray[formStepper.currentStep].formTitle}
              </Typography>
              <Box mt={3}>
                <Typography align="center" variant="body1">
                  {formArray[formStepper.currentStep].formDesc}
                </Typography>
              </Box>
            </div>
            <div className={stepperClasses.stepForm}>
              {formArray[formStepper.currentStep].form}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 8 }}>
          <CardPropagandaUrl />
        </div>
      </div>
      {getButtons}
    </div>
  );
};

export default MedicamentoCadastro;