import { PlanoPost } from './../../../../model/api/gestao/plano/plano-post';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostPlano() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postPlano = useCallback(
        (postDataPlano: PlanoPost) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/planos/alterar-plano`,
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(postDataPlano),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postPlano,
    };
}
