import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetDepositoEmpresa() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getDepositoEmpresa = useCallback(
    (query?: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/DepositoEmpresa?${query}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getDepositoEmpresa,
  };
}
