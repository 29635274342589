import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const SexoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path d="M 37 2 L 37 4 L 46.5625 4 L 38.03125 12.53125 C 36.125 10.949219 33.683594 10 31 10 C 24.898438 10 20 14.898438 20 21 C 20 25.5 22.789063 29.394531 26.6875 31.09375 C 26.886719 30.394531 27 29.699219 27 29 C 24 27.5 22 24.5 22 21 C 22 16 26 12 31 12 C 36 12 40 16 40 21 C 40 26 36 30 31 30 L 30.90625 30 C 30.804688 30.699219 30.695313 31.300781 30.59375 32 L 31 32 C 37.101563 32 42 27.101563 42 21 C 42 18.316406 41.050781 15.875 39.46875 13.96875 L 48 5.4375 L 48 15 L 50 15 L 50 2 Z M 19 18 C 12.898438 18 8 22.898438 8 29 C 8 31.683594 8.949219 34.125 10.53125 36.03125 L 6 40.5625 L 1.71875 36.28125 L 0.28125 37.71875 L 4.5625 42 L 0.28125 46.28125 L 1.71875 47.71875 L 6 43.4375 L 10.28125 47.71875 L 11.71875 46.28125 L 7.4375 42 L 11.96875 37.46875 C 13.875 39.050781 16.316406 40 19 40 C 25.101563 40 30 35.101563 30 29 C 30 24.5 27.210938 20.605469 23.3125 18.90625 C 23.113281 19.507813 23 20.199219 23 21 C 26 22.5 28 25.5 28 29 C 28 34 24 38 19 38 C 14 38 10 34 10 29 C 10 24 14 20 19 20 L 19.09375 20 C 19.195313 19.300781 19.304688 18.699219 19.40625 18 Z" />
            </DefaultIcon>
        </>
    );
};
