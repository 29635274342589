import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },

  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },

  cardContent: {
    padding: theme.spacing(0, 2),
    margin: theme.spacing(2, 0),
    alignItems: "center",
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  celulaGridNomeCliente: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  celulaGridCodigo: {
    maxHeight: 200,
    "& p": {
      maxHeight: 200,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGrid: {
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGridFull: {
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGridStatus: {
    height: '30px',
    padding: theme.spacing(0.5),
    borderRadius: 4,
    justifyContent: "center",
    textOverflow: "ellipsis"
  }
}));
