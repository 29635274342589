import * as Yup from 'yup';

export const useFormComandaFacilitadoValidation = () => {

  const FormComandaFacilitadoYupValidation = Yup.object().shape({
    codigoComanda: Yup.string().required('Codigo é obrigatório'),
  });

  return {
    FormComandaFacilitadoYupValidation
  }
}

