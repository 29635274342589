export function useCriptografia() {
    const chave = 's@urustecnologia';
    const CryptoJS = require("crypto-js");

    const criptografar = (texto: string) => {
        let key = CryptoJS.MD5(chave).toString();
        var k1 = key.substring(0, 16);
        key = key + k1;

        return CryptoJS.TripleDES.encrypt(CryptoJS.enc.Utf8.parse(texto), CryptoJS.enc.Hex.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
    }

    const descriptografar = (texto: string) => {
        let key = CryptoJS.MD5(chave).toString();
        var k1 = key.substring(0, 16);
        key = key + k1;

        return CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(texto)
        }, CryptoJS.enc.Hex.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
    }

    return {
        criptografar,
        descriptografar
    }

}