import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            fill: theme.palette.error.main
        },
        '&:hover': {
            borderColor: theme.palette.error.dark
        }
    }
}))