import { Box, Button, Divider, Grid, Tooltip, Typography } from "@material-ui/core"
import { DefaultCard } from "../components"
import { CardEdicaoGerenciamentoSessaoValorProps } from "./card-edicao-gerenciamento-sessao-valor-props"
import { toDateString, toDateStringApi } from "utils/to-date"
import { useCallback, useEffect, useRef, useState } from "react"
import { toCurrency } from "utils/to-decimal"
import { useStyles } from "./card-edicao-gerenciamento-sessao-valor-styles"
import { useThemeQueries } from "views/theme"
import { DeletarProcessoIcon } from "views/components/icons/deletar-processo-icon"
import { isEmpty } from "lodash"
import { useConfirm } from "material-ui-confirm"
import { FormGerenciamentoSessaoValor } from "views/components/form/gerenciamento-sessoes-valor/form-gerenciamento-sessao-valor"
import { DefaultFormRefs } from "views/components/form/utils"
import { SessaoValorPostModel } from "model/api/gestao/sessao/sessao-valor-post-model"
import { CancelarIcon, EditarIcon, SalvarIcon } from "views/components/icons"
import { useSessaoAtual, useToastSaurus } from "services/app"
import { usePutSessaoValor } from "data/api/gestao/sessao/put-sessao-valor"
import { useDeleteSessaoValor } from "data/api/gestao/sessao/delete-sessao-valor"
import { CircularLoading } from "views/components/utils"
import { picker } from "utils/picker"
import { SessaoValoresModel } from "model/api/gestao/sessao/sessao-gerenciamento-model"
import { TouchoneDBPrimary } from "database/touchone-database"
import { TabelaFinalizadoras } from "database/interfaces/interface-tabela-finalizadoras"
import { TpLancamentoSessaoValorMock } from "data/mocks/tp-lancamento-sessao-valor-mock"
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor"

export const CardEdicaoGerenciamentoSessaoValor = ({
    model,
    sessao,
    getSessaoValorWrapper,
}: CardEdicaoGerenciamentoSessaoValorProps) => {

    //AUX
    const classes = useStyles();
    const { theme, isMobile } = useThemeQueries();
    const confirm = useConfirm();
    const { showToast } = useToastSaurus();

    //STATES E REFS
    const [editar, setEditar] = useState<boolean>(false);
    const [formaPagamento, setFormaPagamento] = useState<string>('');
    const [finalizadoras, setFinalizadoras] = useState<TabelaFinalizadoras[]>([]);
    const refEditForm = useRef<DefaultFormRefs<SessaoValorPostModel>>(null);

    //HOOKS
    const { getEmpresaSelecionada } = useSessaoAtual();

    //CHAMADAS DA API
    const { putSessaoValor, carregando: carregandoPut } = usePutSessaoValor();
    const { deleteSessaoValor, carregando: carregandoDelete } = useDeleteSessaoValor();

    const carregando = carregandoDelete || carregandoPut;

    const handleSubmit = useCallback(async (modelForm: SessaoValorPostModel) => {
        try {
            const modelPicker = picker<SessaoValoresModel>(model, modelForm);

            modelPicker.tpLancamento = modelForm.tpLancamento;
            modelPicker.valor = modelForm.valor;
            modelPicker.dLancamento = toDateStringApi(new Date());

            const res = await putSessaoValor(getEmpresaSelecionada()!.Id, sessao?.caixaId, sessao?.id, modelPicker);

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'Correção alterada com sucesso.');
            await getSessaoValorWrapper();
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getEmpresaSelecionada, getSessaoValorWrapper, model, putSessaoValor, sessao?.caixaId, sessao?.id, showToast])

    const deletarCorrecao = useCallback(async () => {
        try {
            const res = await deleteSessaoValor(getEmpresaSelecionada()!.Id, sessao?.caixaId, sessao?.id, model.id);

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'A correção foi removida com sucesso.')
            await getSessaoValorWrapper();
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [deleteSessaoValor, getEmpresaSelecionada, getSessaoValorWrapper, model.id, sessao?.caixaId, sessao?.id, showToast])

    const retornaFinalizadoras = useCallback(async () => {
        try {
            const finalizadorasDB = await TouchoneDBPrimary.finalizadoras.toArray();
            const finalizadoraFiltrada = finalizadorasDB.filter(x => x.id === model.pagamentoId);

            setFormaPagamento(finalizadoraFiltrada[0]?.descricao ?? '');
            setFinalizadoras(finalizadorasDB);
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [model.pagamentoId, showToast])

    useEffect(() => {
        retornaFinalizadoras();
    }, [retornaFinalizadoras])

    const statusSessao = useCallback(() => {
        if (isEmpty(sessao.dFechamento) || !isEmpty(sessao.dConferencia)) {
            return true;
        }

        return false;
    }, [sessao.dConferencia, sessao.dFechamento])

    const isConferida = !isEmpty(sessao.dConferencia);

    return (
        <>
            <DefaultCard
                className={statusSessao() ? classes.root : ''}
                onClick={() => {
                    if (!editar && !statusSessao()) {
                        setEditar(true);
                    }
                }}
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container spacing={2} className={classes.containerRoot}>
                    {!editar ? (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={1} className={classes.containerButtonDeletar}>
                                    <Grid item xs={5}>
                                        <Typography
                                            variant='body1'
                                            color="textPrimary"
                                        >
                                            <Box fontWeight={700}>
                                                {TpLancamentoSessaoValorMock.filter(x => x.Key === model.tpLancamento)[0].Value}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={isConferida ? 12 : 10} className={classes.containerData}>
                                                <Typography
                                                    variant={isMobile ? "caption" : "body2" }
                                                    color="textPrimary"
                                                >
                                                    {toDateString(model.dLancamento,'DD/MM/yyyy HH:mm')}
                                                </Typography>

                                            </Grid>
                                            {!isConferida && (
                                                <Grid item xs={2} className={classes.buttonContainer}>
                                                    {!statusSessao() && (
                                                        <Tooltip arrow title="Editar">
                                                            <Button
                                                                size="small"
                                                                className={classes.button}
                                                                onClick={() => {
                                                                    if (!editar) {
                                                                        setEditar(true);
                                                                    }
                                                                }}
                                                            >
                                                                <EditarIcon tipo="BUTTON" fill={theme.palette.text.primary} />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                    {model.tpLancamento === EnumTpLancamentoSessaoValor.ABERTURA ? null :
                                                        <Tooltip arrow title='Remover'>
                                                            <Button
                                                                size="small"
                                                                className={classes.button}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    confirm({
                                                                        title: 'Remover Correção',
                                                                        description: 'Deseja realmente remover a correção?',
                                                                    }).then(async () => {
                                                                        await deletarCorrecao();
                                                                    })
                                                                }}
                                                            >
                                                                <DeletarProcessoIcon tipo="BUTTON" fill={theme.palette.error.main} />
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Forma de Pagamento
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(formaPagamento) ? formaPagamento : 'Sem Pagamento'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Responsável
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.responsavelNome) ? model.responsavelNome : 'Sem Responsável'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Operador
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.operadorNome) ? model.operadorNome : 'Sem Operador'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Supervisor
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.portadorNome) ? model.portadorNome : 'Sem Supervisor'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Valor Movimentado
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {toCurrency(model.valor)}
                                </Typography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    <Box fontWeight={600}>
                                        Editar Correção
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGerenciamentoSessaoValor
                                    ref={refEditForm}
                                    onSubmit={handleSubmit}
                                    showLoading={false}
                                    loading={false}
                                    model={model}
                                    editar={true}
                                    finalizadoras={finalizadoras}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={() => setEditar(false)}
                                >
                                    <CancelarIcon tipo="BUTTON" />
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={() => refEditForm.current?.submitForm()}
                                >
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DefaultCard>
        </>
    )
}