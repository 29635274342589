
import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { CadastroProdutoFacilitado } from "./components/cadastro-produto-facilitado";
import { ProdutoNovoModel } from "model/api/gestao/produto/produto/produto-novo-model";
import { ProdutoCompletoPreCadastroFormModel } from "model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model";

interface DialogProdutoFacilitadoProps {
    openned: boolean;
    finalizar: (produto: ProdutoCompletoPreCadastroFormModel) => Promise<void>;
    produto: ProdutoNovoModel;
    gradeId: string;
}

export const DialogProdutoFacilitado = ({openned, finalizar, produto, gradeId}: DialogProdutoFacilitadoProps) => {

    const {isMobile} = useThemeQueries();

    return (
        <DialogSaurus
            aberto={openned || false}
            titulo='Editar Adicional'
            tamanho="md"
            fullScreen={isMobile}
        >
            <CadastroProdutoFacilitado finalizar={finalizar} produto={produto} gradeId={gradeId}/>
        </DialogSaurus>
    );
};