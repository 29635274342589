import { Divider, Paper, Typography } from '@material-ui/core';
import { CardDashboardProps } from './card-dashboard-props';
import { useStyles } from './card-dashboard-styles';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';
export const CardDashboard = ({
  conteudo,
  icone,
  titulo,
  tipo,
  isDivider,
}: CardDashboardProps) => {
  const utilClasses = useStyles();

  return (
    <div>
      <Paper className={classNames(utilClasses.cardContainer)}>
        <div className={utilClasses.cardContent}>
          <div className={utilClasses.cardTitle}>
            <div>
              <Typography className={'celula-grid-value'} color="textPrimary">
                {icone}
              </Typography>
            </div>
            <div>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                style={{ fontWeight: 500 }}
              >
                {titulo}
              </Typography>
            </div>
          </div>

          <div>
            {isDivider && (
              <Divider className={utilClasses.divider} />
            )}
          </div>

          <div
            style={{
              padding: '5px 0',
              display: 'flex',
              flexDirection: 'row-reverse',
              overflow: 'auto',
            }}
          >
            {tipo === 'dinheiro' ? (
              <>
                <Typography style={{ fontWeight: 600, marginLeft: 5 }} variant="h6">
                  {toDecimalString(conteudo, 2)}
                </Typography>
                <span>R$</span>
              </>
            ) : (
              <Typography style={{ fontWeight: 600 }} variant="h6">
                {conteudo}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};
