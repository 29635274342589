import { PropTypes, Tooltip } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { useButtonFabStyles } from './button-fab-styles';
import { getButtonFabColor } from 'utils/button-fab-color';
interface ButtonFabProps {
  id?: string;
  tooltip: string;
  icon: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  color?: PropTypes.Color;
  removePadding?: boolean;
  onClick: () => any;
}

export const ButtonFab = ({
  color = getButtonFabColor(),
  removePadding,
  ...props
}: ButtonFabProps) => {
  const classes = useButtonFabStyles();
  return (
    <>
      <Tooltip arrow title={props.tooltip} placement="right">
        <Fab
          id={props.id}
          size={props.size}
          color={color === undefined ? 'primary' : color}
          style={{
            padding: removePadding ? '0' : undefined
          }}
          className={classes.root}
          onClick={props.onClick}
        >
          {props.icon}
        </Fab>
      </Tooltip>
    </>
  );
};
