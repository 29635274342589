import * as Yup from 'yup';

export const useFormComandaEditValidation = () => {

  const FormComandaEditYupValidation = Yup.object().shape({
    codigoComanda: Yup.string().required('O campo codigo é obrigatório'),
    status: Yup.number().required('Status é obrigatório'),
  });

  return {
    FormComandaEditYupValidation
  }
}

