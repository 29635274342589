import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetSessaoAtiva() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getSessaoAtiva = useCallback(
    (empresaId: string, caixaId: string, operadorId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${caixaId}/Sessao/${operadorId}/ativa`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getSessaoAtiva
  };
}
