import { EnumComandas } from '../../../enums/enum-comandas';

export class ComandasEditModel {
    constructor(
        public id: string = '',
        public codigoComanda: string = '',
        public rfId: string = '',
        public status: EnumComandas = EnumComandas.ATIVO
    ) { }
}
