import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
    },
    qrText: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        fontSize: theme.spacing(2),
        fontWeight: 600
    }
}));
