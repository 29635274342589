export class EmitFormModel {
  constructor(
    public doc: string = '',
    public xNome: string = '',
    public xFant: string = '',
    public xLgr: string = '',
    public nro: string = '',
    public xCpl: string = '',
    public xBairro: string = '',
    public cMun: number = 0,
    public xMun: string = '',
    public uf: string = '',
    public cep: string = '',
    public cPais: number = 0,
    public xPais: string = '',
    public fone: string = '',
    public email: string = '',
    public ie: string = '',
    public iest: string = '',
    public im: string = '',
    public cnae: string = '',
    public crt: number = 0,
  ) { }
}
