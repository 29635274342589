import { DefaultModal } from '../components/default-modal/default-modal';
import { SelecionarRastros } from './components/selecionar-rastros/selecionar-rastros';
import { RastroMedicamentoProps } from './rastro-medicamento-modal-props';

export const RastroMedicamentoModal = ({ medicamentos, receitaMed, ...props }: RastroMedicamentoProps) => {
    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={'temporary'}
            anchor="right"
        >
            {props.openned && <SelecionarRastros {...{ medicamentos, receitaMed }} />}
        </DefaultModal>
    );
};
