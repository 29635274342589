import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutAcessoSuporte() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAcessoSuporte = useCallback(
        () =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/usuario/meu-usuario/acesso-suporte`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAcessoSuporte,
    };
}
