import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme)=>({
    botoesQuestaoAtivaModulo:{
        display:"flex"
    },
    question:{
        fontWeight:500
    }

}))