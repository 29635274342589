import { VariaveisAmbiente } from "config";
import { PedidoTransferirProduto } from "model/api/gestao/pedido/pedido-transferir-produto";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutPedidoProdutoTransferir() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPedidoProdutoTransferir = useCallback(
        (empresaId: string, pedidoId: string, transferirItens: PedidoTransferirProduto) => {
            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/produto/transferir-produto`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(transferirItens),
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putPedidoProdutoTransferir,
    };
}
