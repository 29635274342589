import * as Yup from "yup";
import { useMemo } from "react";

export const useFormMarcaCadastroValidation = () => {
  const FormMarcaCadastroYupValidation = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required('O Nome é obrigatório.'),
    });
  }, []);

  return {
    FormMarcaCadastroYupValidation,
  };
};
