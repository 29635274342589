import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    containerButton: {
        display: 'flex',
        width: '100%',
    },
    containerIcon: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '5px',
        alignItems: 'center',
    },
    textTitle: {
        fontSize: '14px'
    },
}))