import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const FecharIconCircle = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
        onClick={props.onClick}
      >
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0C7.32759 0 4.81034 1.03448 2.93103 2.93103C1.03448 4.81034 0 7.32759 0 10C0 12.6724 1.03448 15.1897 2.93103 17.069C4.82759 18.9655 7.32759 20 10 20C12.6724 20 15.1897 18.9655 17.069 17.069C18.9655 15.1724 20 12.6724 20 10C20 7.32759 18.9655 4.81034 17.069 2.93103C15.1897 1.03448 12.6724 0 10 0ZM10 1.03448C12.3966 1.03448 14.6381 1.96538 16.345 3.65504C18.0519 5.34469 18.9655 7.60345 18.9655 10C18.9655 12.3966 18.0346 14.6381 16.345 16.345C14.6381 18.0346 12.3966 18.9655 10 18.9655C7.60345 18.9655 5.36193 18.0346 3.65504 16.345C1.94814 14.6553 1.03448 12.3966 1.03448 10C1.03448 7.60345 1.96538 5.36193 3.65504 3.65504C5.34469 1.94814 7.60345 1.03448 10 1.03448ZM7.68319 7.15517C7.55172 7.15517 7.42241 7.20696 7.32759 7.31041C7.12069 7.51731 7.12069 7.84476 7.32759 8.03442L9.276 10L7.31041 11.9484C7.10352 12.1553 7.10352 12.4828 7.31041 12.6724C7.41386 12.7759 7.55172 12.8277 7.67241 12.8277C7.7931 12.8277 7.93097 12.7759 8.03442 12.6724L10 10.724L11.9484 12.6724C12.0519 12.7759 12.1897 12.8277 12.3104 12.8277C12.4311 12.8277 12.569 12.7759 12.6724 12.6724C12.8793 12.4655 12.8793 12.1381 12.6724 11.9484L10.724 10L12.6724 8.05159C12.8793 7.84469 12.8792 7.51731 12.6896 7.31041C12.4827 7.10352 12.1552 7.10352 11.9656 7.31041L10 9.276L8.05159 7.31041C7.94814 7.20696 7.81465 7.15517 7.68319 7.15517Z" fill={props.fill} />
        </svg>
      </DefaultIcon>
    </>
  );
};
