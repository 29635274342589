import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './dialog-adicionar-produto-styles';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { toDecimalString } from 'utils/to-decimal';
import { useCadastros } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { FormInfoAdicionalProduto } from 'views/components/form/produto/form-info-adicional-produto/form-info-adicional-produto';
import { DefaultFormRefs } from 'views/components/form/utils';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';

export interface DialogAdicionarProdutosProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
  codigo?: ProdutoCodigoModel | null
}

export const DialogAdicionarProduto = ({
  produto: prod,
  codigo,
  ...props
}: DialogAdicionarProdutosProps) => {
  const [qtde, setQtde] = useState<number>(1);
  const classes = useStyles();
  const { fecharAdicionarProduto } = useCadastros()
  const { carregando, adicionarProduto } = useMovProd()

  const porcentagem = (codigo?.percTabela ?? 0) / 100;
  const porcCalc = prod.vUnCom * porcentagem;

  const produto = useMemo(() => ({
    ...prod,
    vUnCom: codigo ? codigo.precoFixo ? codigo.precoFixo / 1 : codigo.percTabela ? prod.vUnCom + porcCalc : prod.vUnCom : prod.vUnCom
  }), [codigo, porcCalc, prod])

  const refFormInfo = useRef<DefaultFormRefs<MovSimplesProdutoModel>>(null)

  const onCloseClick = useCallback(() => {
    fecharAdicionarProduto();
  }, [fecharAdicionarProduto]);

  const { callEvent } = useEventTools()

  useEffect(() => {
    if (props.aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [callEvent, produto, props.aberto])

  const handleClickSum = () => {
    setQtde(qtde + 1);
  };

  const handleClickASubtraction = () => {
    setQtde(qtde - 1);
  };

  const handleClickSave = async (model: MovSimplesProdutoModel) => {
    let addProduto = produto

    produto.qCom = qtde;
    produto.infAdic = model.infAdic;
    await adicionarProduto(addProduto)
    onCloseClick()
  }

  const valorTotal = produto.vUnCom * qtde;

  return (
    <DialogSaurus
      aberto={props.aberto}
      carregando={carregando}
      titulo={produto.xProd}
      tamanho="sm"
      isButtonTitleClose
      handleClickClose={onCloseClick}
    >
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {produto.imgUrl.length > 0 && (
              <img
                src={produto.imgUrl}
                className={classes.cardImg}
                alt={produto.xProd}
              />
            )}
            {produto.imgUrl.length === 0 && (
              <img
                src={SemImagem}
                className={classes.cardImg}
                alt={produto.xProd}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" color="textPrimary">
                  <Box fontWeight={600}>
                    {`R$ ${toDecimalString(produto.vUnCom)} UN`}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormInfoAdicionalProduto
                  ref={refFormInfo}
                  loading={false}
                  showLoading
                  onSubmit={handleClickSave}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.buttonQuantidade}>
            <Grid item xs={4} container justifyContent='flex-end'>
              <Button
                disabled={qtde <= 1 ? true : false}
                className={classes.buttonChanged}
                variant="contained"
                color="primary"
                onClick={handleClickASubtraction}
              >
                <Typography className={classes.prefix}>-</Typography>
              </Button>
            </Grid>
            <Grid item xs={4} container justifyContent='center'>
              <TextFieldSaurus
                className={classes.textPerson}
                tipo="NUMERO"
                value={qtde}
                onChange={(e) => setQtde(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs={4} container justifyContent='flex-start'>
              <Button
                className={classes.buttonChanged}
                color="primary"
                variant="contained"
                onClick={handleClickSum}
              >
                <Typography className={classes.prefix}>+</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="fullWidth" className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => refFormInfo.current?.submitForm()}
            >
              {`Adicionar R$ ${toDecimalString(valorTotal)}`}
            </Button>
          </Grid>
        </Grid>
      </>
    </DialogSaurus>
  );
};
