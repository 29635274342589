import { Grid, Typography } from '@material-ui/core';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { PagamentoEfetuarFragmentProps } from './pagamento-efetuar-fragment-props';
import { useStyles } from './pagamento-efetuar-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

export const PagamentoParcialEfetuarFragment = (
  props: PagamentoEfetuarFragmentProps,
) => {
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  const {
    getFormattedText,
    sendText,
    resetText,
    addKey,
    backSpaceKey,
  } = useKeyboard({
    maxLength: 7,
    floatCases: 2,
    handleTextChanged: props.textChanged,
    isNumeric: true,
    digitado: digitado,
    textoAtual: textoAtual
  });
  const { addHandler, removeHandler } = useEventTools()

  const attValueDisplay = useCallback(() => {
    const v = getFormattedText();
    if (valueRef.current) {
      valueRef.current.textContent = v;
    }
  }, [getFormattedText])

  const attDisplay = useCallback(({valor}: {valor?: string}) => {
   resetText(valor || '0')
  }, [resetText])

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    addHandler(AppEventEnum.AlterarDisplayKeybordPayment, attDisplay)

    return () => {
      removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
      removeHandler(AppEventEnum.AlterarDisplayKeybordPayment, attDisplay)
    }
  }, [addHandler, attValueDisplay, removeHandler, attDisplay]);
  

  const classes = useStyles();

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleEscape = useCallback(() => {
    resetText('');
  }, [resetText]);

  const vAPagar = useCallback(() => {
    return toDecimal(getFormattedText(), 2) === 0
      ? (props.vPessoa ?? 0)
      : toDecimal(getFormattedText(), 2);
  }, [getFormattedText, props.vPessoa]);

  const teclado = useMemo(() => {
    return (
      <Grid container style={{ flex: 1, overflowY: 'auto' }}>
        <Keyboard
          isButtonKeyboard00
          isButtonBackspace
          handleAdd={handleAdd}
          handleBackSpace={handleBackSpace}
          handleText={handleText}
          handleEscape={handleEscape}
          isNumeric
        />
      </Grid>
    );
  }, [
    handleAdd,
    handleBackSpace,
    handleEscape,
    handleText,
  ]);

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid className={classes.middleContainer}>
          <Grid container className={classes.middleContent}>
            <Grid item>
              <div className={classes.valorContainer}>
                <div className="valorMonetario">
                  <Typography className="unidade" variant="body1">
                    R$
                  </Typography>
                  <Typography className="valor" variant="h3" ref={valueRef}>
                    {toDecimalString(vAPagar(), 2)}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {teclado}
      </Grid>
    </>
  );
};

export default memo(PagamentoParcialEfetuarFragment);
