import { isEmpty } from "lodash";

import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { useSessaoAtual } from "services/app";
import { useThemeQueries } from "views/theme";
import ProdutoEdit from "./components/produto-edit/produto-edit";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { produtoPageNome } from "views/pages/private/cadastros/produto/produto-page";
import { CadastroSwitch } from "./components/cadastro-switch/cadastro-switch";

interface ProdutoModalProps extends ModalProps {
  tipo: EnumTipoProduto;
}

export const ProdutoModal = (props: ProdutoModalProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { isMobile } = useThemeQueries();

  return (
    <DefaultModal
      minWidth={isMobile ? "0px" : "800px"}
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (
        <CadastroSwitch
          title={produtoPageNome(props.tipo, false)}
          empresaId={getEmpresaSelecionada()?.Id || ""}
          contratoId={getEmpresaSelecionada()?.ContratoId || ""}
          tipo={props.tipo}
        />
      )}
      {props.openned && !isEmpty(props.id) && (
        <ProdutoEdit
          id={props.id}
          empresaId={getEmpresaSelecionada()?.Id || ""}
          contratoId={getEmpresaSelecionada()?.ContratoId || ""}
          tipo={props.tipo}
        />
      )}
    </DefaultModal>
  );
};
