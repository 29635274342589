import { isEmpty } from "lodash";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogAdicionarProdutoComSubItem, DialogAdicionarProdutoComSubItensProps } from "views/components/dialog/dialog-adicionar-produto-com-subitem/dialog-adicionar-produto-com-subitem";

export const AdicionarProdutoSubItemDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogAdicionarProdutoComSubItensProps>({
        produto: new MovSimplesProdutoModel(),
        aberto: false,
    });

    consoleDev('AdicionarProdutoSubItemDialog')

    const modalAlterado = useCallback(({ produto, aberto, edit }: any) => {
        setModalState({
            produto: produto,
            aberto: aberto,
            edit: edit
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.AdicionarProdutoSubItemDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.AdicionarProdutoSubItemDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {!isEmpty(modalState.produto) && modalState.aberto && (
                <DialogAdicionarProdutoComSubItem aberto={modalState.aberto} produto={modalState.produto} edit={modalState.edit} />
            )}
        </>
    )
}