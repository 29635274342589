export const calcVTrib = (pTribManual: number, pTribFederal: number, pTribEstadual: number, pTribmunicipal: number, valorProduto: number) => {
    let vTrib = 0;

    if (pTribManual > 0) {
        vTrib = pTribManual * valorProduto
    } else {
        vTrib = ((pTribFederal * valorProduto) + (pTribEstadual * valorProduto) + (pTribmunicipal * valorProduto))
    }

    return vTrib;
}