import { useMemo } from 'react';
import * as Yup from 'yup';
import {
  useValidationYupDocumento,
  useValidationYupRazaoSocial,
} from 'views/components/form-validations';
import { isEmpty } from 'lodash';

interface useFormPessoaValidationProps {
  cpfObrigatorio: boolean;
  showDocs: boolean;
}

export const useFormPessoaValidation = ({
  cpfObrigatorio,
  showDocs,
}: useFormPessoaValidationProps) => {
  const { razaoSocialYup } = useValidationYupRazaoSocial()
  const { documentoYup } = useValidationYupDocumento(true);

  const FormPessoaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        nome: razaoSocialYup("O Nome é obrigatório."),
        cpfcnpj: cpfObrigatorio ? documentoYup().required('CPF/CNPJ é obrigatório.').nullable(false) : documentoYup().notRequired(),
        documento: showDocs ? Yup.object({
          documento: Yup.string().required('Documento é obrigatório.'),
          tipo: Yup.number().required('Tipo do documento obrigatório'),
          orgaoExpedicao: Yup.number().required('Órgão expedidor é obrigatório.'),
          ufExpedicao: Yup.string().required('Informe a UF').typeError('Informe a UF'),
          dataValidade: Yup.string().test({
            test: (value) => {
              if (isEmpty(value)) return true
              return new Date(value || '') > new Date()
            },
            message: 'Data de validade expirada.'
          })
        }) : Yup.object({})
      })
    )
  }, [cpfObrigatorio, documentoYup, razaoSocialYup, showDocs])
  return {
    FormPessoaValidationYup,
  }
}

