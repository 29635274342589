import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { TutorialPostModel } from "model/api/gestao/tutorial/tutorial-post-model";

export function usePutTutorial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putTutorial = useCallback(
        (tutorialStatusId: string, payload: TutorialPostModel) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Tutorial/${tutorialStatusId}`,
                method: "PUT",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(payload),
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putTutorial,
    };
}
