import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetTutorial() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getTutorial = useCallback(
        (usuarioId:string, sistemaId:string) => invocarApi({
            url: `/${VariaveisAmbiente.gatewayVersion}/Tutorial/${usuarioId}/pendentes?sistemaId=${sistemaId}`,
            method: 'GET',
            enviarTokenUsuario: true
        }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getTutorial,
    };
}
