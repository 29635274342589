import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { PesquisaImpressoras } from "./components/pesquisa-impressoras/pesquisa-impressoras";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { ImpressorasListSearchProps } from "../impressoras-list/impressoras-list-search-props";

export interface ImpressorasHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: ImpressorasListSearchProps) => any;
}

export const ImpressorasHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: ImpressorasHeaderProps) => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () => (
      isMobile ?
        <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
        null
    ),
    [isMobile, abrirMenu]
  );

  // const rightArea = useCallback(
  //   () => (
  //     <ButtonPrivateHeader
  //       icon={
  //         openPesquisa ?
  //           <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
  //           :
  //           <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
  //       tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
  //       onClick={() => setOpenPesquisa(!openPesquisa)}
  //     ></ButtonPrivateHeader>
  //   ),
  //   [setOpenPesquisa, openPesquisa]
  // );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: ImpressorasListSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps]
  );

  const pesquisa = <PesquisaImpressoras isOpened={openPesquisa!} onCloseSearch={closePesquisaWrapper} onPesquisar={onPesquisar} />;

  return (
    <>
      <PrivatePageHeader
        title="Impressoras"
        leftArea={leftArea()}
        bottomArea={pesquisa}
      // rightArea={rightArea()}
      />
    </>
  );
};
