import { KeyValueModel } from "model/api";


export const SimNaoMock: KeyValueModel[] = [
  new KeyValueModel(0, "Não"),
  new KeyValueModel(1, "Sim"),
];

export const SimNaoBooleanMock: KeyValueModel[] = [
  new KeyValueModel(false, "Não"),
  new KeyValueModel(true, "Sim"),
];
