import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const UltimaVendaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M44.3475 48.9904C44.0995 48.9904 43.9267 48.9904 43.7555 48.9904C31.3688 48.9904 18.9804 48.9888 6.59359 49C6.14561 49 5.99841 48.8977 6.00001 48.4263C6.01281 32.4909 6.00961 16.5555 6.00961 0.621747C6.00961 0.449145 6.00961 0.276543 6.00961 0.0256296C6.272 0.0256296 6.4896 0.0256296 6.70719 0.0256296C15.0397 0.0256296 23.3722 0.0448077 31.7047 5.88806e-05C32.7047 -0.00473563 33.3703 0.282935 34.0631 0.987728C37.3685 4.36147 40.7364 7.67128 44.0691 11.0195C44.2371 11.1873 44.3507 11.4909 44.3507 11.7306C44.3603 23.9167 44.3539 36.1044 44.3491 48.2904C44.3475 48.495 44.3475 48.7011 44.3475 48.9904ZM8.15674 46.8153C19.518 46.8153 30.8504 46.8153 42.2228 46.8153C42.2228 46.6155 42.2228 46.4589 42.2228 46.3023C42.2228 35.3133 42.2196 24.3242 42.234 13.3352C42.234 12.8749 42.0868 12.7822 41.6644 12.7838C38.5045 12.7982 35.343 12.7918 32.1831 12.7918C31.9959 12.7918 31.8087 12.7918 31.5463 12.7918C31.5463 9.21192 31.5463 5.70074 31.5463 2.18796C23.7034 2.18796 15.9373 2.18796 8.15674 2.18796C8.15674 17.0781 8.15674 31.9363 8.15674 46.8153ZM33.7303 3.81809C33.7303 6.09868 33.7303 8.3537 33.7303 10.6199C36.0134 10.6199 38.2693 10.6199 40.5252 10.6199C38.2501 8.34251 36.0038 6.09229 33.7303 3.81809Z" />
        <path d="M13.5054 34.097C15.6221 34.097 17.6908 34.097 19.814 34.097C19.8284 34.2601 19.854 34.4103 19.854 34.5605C19.8572 36.3617 19.8476 38.1612 19.8636 39.9623C19.8668 40.3443 19.7724 40.4913 19.3596 40.4865C17.542 40.4658 15.7245 40.4753 13.907 40.4801C13.6382 40.4801 13.451 40.4578 13.4526 40.1062C13.4638 38.1948 13.459 36.2817 13.4606 34.3703C13.4622 34.2936 13.4862 34.2185 13.5054 34.097ZM15.6221 36.261C15.6221 36.9578 15.6221 37.6194 15.6221 38.3322C16.2653 38.3322 16.8813 38.3402 17.4956 38.321C17.5708 38.3194 17.7004 38.1676 17.7036 38.0829C17.7212 37.4836 17.7132 36.8843 17.7132 36.261C16.9805 36.261 16.3053 36.261 15.6221 36.261Z" />
        <path d="M16.6301 31.9619C15.7197 31.9619 14.8094 31.9507 13.9006 31.9683C13.5758 31.9747 13.4494 31.8804 13.451 31.5368C13.4638 29.6861 13.4622 27.8354 13.4526 25.9847C13.451 25.6843 13.5342 25.5612 13.859 25.5628C15.7261 25.5756 17.5949 25.5724 19.462 25.5644C19.7452 25.5628 19.8604 25.6475 19.8604 25.9432C19.8524 27.8258 19.8524 29.7085 19.8604 31.5911C19.862 31.8932 19.7324 31.9699 19.4556 31.9667C18.5148 31.9555 17.5725 31.9619 16.6301 31.9619ZM17.6893 29.7916C17.6893 29.1012 17.6893 28.4379 17.6893 27.7427C16.9933 27.7427 16.3165 27.7427 15.6333 27.7427C15.6333 28.4427 15.6333 29.114 15.6333 29.7916C16.3373 29.7916 16.9997 29.7916 17.6893 29.7916Z" />
        <path d="M19.8572 20.2856C19.8572 21.195 19.846 22.1044 19.8636 23.0137C19.87 23.343 19.766 23.4564 19.4268 23.4532C17.5757 23.4388 15.7229 23.4404 13.8718 23.4516C13.563 23.4532 13.451 23.3589 13.4526 23.0425C13.4638 21.1918 13.4638 19.3411 13.4526 17.492C13.451 17.1628 13.5518 17.0462 13.8894 17.0493C15.7405 17.0621 17.5933 17.0605 19.4444 17.0493C19.7564 17.0478 19.8668 17.15 19.862 17.4633C19.8476 18.403 19.8556 19.3443 19.8572 20.2856ZM17.6908 21.2733C17.6908 20.5797 17.6908 19.9181 17.6908 19.2245C16.9901 19.2245 16.3133 19.2245 15.6157 19.2245C15.6157 19.9165 15.6157 20.5797 15.6157 21.2733C16.3133 21.2733 16.9901 21.2733 17.6908 21.2733Z" />
        <path d="M36.8694 19.226C36.8694 19.9165 36.8694 20.5781 36.8694 21.2749C31.9063 21.2749 26.9721 21.2749 22.0043 21.2749C22.0043 20.5829 22.0043 19.9212 22.0043 19.226C26.9689 19.226 31.9047 19.226 36.8694 19.226Z" />
        <path d="M22.0059 29.79C22.0059 29.0932 22.0059 28.4331 22.0059 27.7411C26.9722 27.7411 31.9064 27.7411 36.8694 27.7411C36.8694 28.4379 36.8694 29.0996 36.8694 29.79C31.9032 29.79 26.969 29.79 22.0059 29.79Z" />
        <path d="M21.9786 38.3066C21.9786 37.6818 21.9579 37.0777 21.9994 36.4799C22.0058 36.384 22.289 36.237 22.4458 36.2354C24.009 36.2162 25.5721 36.2226 27.1353 36.2226C30.1976 36.2226 33.2599 36.2322 36.3222 36.213C36.7813 36.2098 36.9461 36.3329 36.9093 36.7996C36.8709 37.287 36.9013 37.7808 36.9013 38.3066C31.9095 38.3066 26.9753 38.3066 21.9786 38.3066Z" />
      </DefaultIcon>
    </>
  );
};
