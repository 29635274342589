import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import { PublicPageHeader } from "views/components/headers";
import { CircularLoading } from "views";
import { VoltarIcon } from "views/components/icons";
import { useStyles } from "./ativar-email-page-styles";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { usePostIdentidadeConfirmarUsuario } from '../../../../data/api/gestao/identidade/post-confirmar-usuario';
import { useLocation } from "react-router-dom";
import { isEmpty } from 'lodash';
import { AlertaIcon } from '../../../components/icons/alerta-icon';
import { OkIcon } from '../../../components/icons/ok-icon';
import { ConviteFormModel } from "model/app/forms/master/convite-form-model";


const AtivarEmailPage = () => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { usuario, refreshUser, carregando: carregandoSessao } = useSessaoAtual();
    const theme = useTheme();
    const { postIdentidadeConfirmarUsuario, carregando: carregandoConfirmar } = usePostIdentidadeConfirmarUsuario();
    const { push: historyPush } = useHistory();
    const search = useLocation().search;
    const [chave, setChave] = useState('');
    const [email, setEmail] = useState('');
    const carregando = carregandoConfirmar || carregandoSessao;

    useEffect(() => {
        const chave = new URLSearchParams(search).get('chave');
        const email = new URLSearchParams(search).get('email');
        if (isEmpty(chave) && isEmpty(email)) {
            showToast('error', 'Parâmetro esperado para ativação de usuário não Recebido.');
            historyPush('login');
            return;
        }
        setChave(chave || '');
        setEmail(email || '');
    }, [showToast, historyPush, search]);


    const enviarChave = useCallback(async () => {
        try {
            if (chave) {
                const unchave = atob(chave);
                const splits = unchave.split('|');
                if (splits.length !== 2)
                    throw new Error('A chave informada está inválida');

                const model = {
                    id: splits[0],
                    email: splits[1]

                } as ConviteFormModel

                const ret = await postIdentidadeConfirmarUsuario(model);
                if (ret.erro) {
                    throw ret.erro;
                }

                if (usuario) {
                    refreshUser(usuario?.originalToken || '');
                    historyPush('/');
                    showToast('success', `Seu usuário foi alterado para ${splits[1]}.`);
                } else {
                    showToast('success', 'Seu usuário foi Confirmado. Realize o login para iniciar sua sessão.');
                    historyPush('/login');
                }
                return;

            }
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [chave, historyPush, postIdentidadeConfirmarUsuario, showToast, refreshUser, usuario]);

    useEffect(() => {
        (async function () {
            if (chave) {
                await enviarChave();
            }
        })();

    }, [chave, enviarChave]);

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>

                    <Grid item xs={12}>
                        <Grid container alignItems="center" direction="column">
                            <Grid item xs={10} md={10}>
                                {email && (
                                    <Typography align="center" variant="h3" color="primary">
                                        <AlertaIcon tipo="GERAL" class={classes.imgTitulo} />
                                        Email não Confirmado
                                    </Typography>
                                )}
                                {chave && (
                                    <Typography align="center" variant="h3" color="primary">
                                        <OkIcon tipo="GERAL" class={classes.imgTitulo} />
                                        Finalizando Ativação
                                    </Typography>
                                )}
                                <Box mt={3}>
                                    {
                                        chave && carregando && (
                                            <Typography align="center" variant="body1" >
                                                Ativando seu usuário, aguarde mais alguns instantes que já finalizaremos este processo.
                                            </Typography>
                                        )
                                    }
                                    {
                                        email && (
                                            <>
                                                <Typography align="center" variant="body1" >
                                                    O E-mail <b>{email}</b> ainda não foi confirmado. Não poderemos prosseguir enquanto seu e-mail não seja confirmado.
                                                </Typography>
                                                <br />
                                                <br />
                                                <Typography align="center" variant="subtitle2" color="primary" >
                                                    <strong>Verifique seu e-mail novamente para realizar a ativação.</strong>
                                                </Typography>
                                            </>
                                        )
                                    }
                                </Box>

                                <Box my={5}>
                                    {
                                        carregando ?
                                            <CircularLoading tipo="FULLSIZED" />
                                            : null
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} style={{ width: "100%" }}>
                                {!carregando && (
                                    <Box mt={5}>
                                        <Grid spacing={2} container>
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth={true}
                                                    disabled={carregando}
                                                    onClick={() => {
                                                        historyPush('/login')
                                                    }}>
                                                    <VoltarIcon fill={theme.palette.primary.contrastText} tipo="BUTTON" />
                                                    Voltar ao Login
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default AtivarEmailPage;