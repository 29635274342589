import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function usePostRetiradaCompartilhar() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postRetiradaCompartilhar = useCallback(
    (id: string, email: string, documento: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documento}/retirada/compartilhar`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(
          {
            id: id,
            email: email
          }
        ),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postRetiradaCompartilhar,
  };
}
