import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  textVazio: {
    whiteSpace: 'pre-line'

  },
  buttonGerar: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '1.25rem !important',
  }
}));
