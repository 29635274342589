import tap2 from '../assets/sounds/tap2.wav';

export function handleClickFeedbackTatil() {
  try {
    navigator.vibrate(20);
    if (tap2) {
      const audio = new Audio(tap2);
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  } catch (e: any) {}
}
