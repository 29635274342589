import * as Yup from 'yup';

export const useFormSessaoNovoValorValidation = () => {

    const FormSessaoNovoValorYupValidation = Yup.object().shape({
        valorMovimentacao: Yup.string().required('Digite um valor para a movimentação'),
    });

    return {
        FormSessaoNovoValorYupValidation
    }
}

