import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  label: {
    fontSize: 14,
    color: theme.palette.text.primary
  }
}));
