import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ConfirmadoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
                viewBox={props.viewBox}
            >
                <path opacity="0.4" d="M69 138C107.108 138 138 107.108 138 69C138 30.8924 107.108 0 69 0C30.8923 0 0 30.8924 0 69C0 107.108 30.8923 138 69 138Z" fill="#D1EEFF" />
                <path d="M60.5019 93.9605C58.5501 95.8744 55.423 95.8661 53.4814 93.9417L37.6232 78.2239C35.6139 76.2323 35.6557 72.9718 37.7154 71.0324L38.9549 69.8653L39.6324 69.3039C41.5944 67.678 44.4652 67.7877 46.2974 69.5585L53.377 76.4005C55.3666 78.3234 58.5395 78.2648 60.4568 76.2698L90.5205 44.9873C92.4548 42.9745 95.6626 42.9355 97.6453 44.9007L100.452 47.6826C102.433 49.6461 102.425 52.8509 100.433 54.8038L60.5019 93.9605Z" />
            </DefaultIcon>
        </>
    );
};

//fill="#1F9CE4"