export enum StatusPagamento {
    Aberto,
    Pago,
    Cancelado
}

export enum StatusPagamentoMov {
    EmAndamento = 0,
    Confirmado = 100,
    Estornado = 200,
    ConfirmacaoPendente = 3,
    EstornoPendente = 4,
    EstornoAberto = 5,
    ConfirmacaoEstornoPendente = 6,
    Ignorado = 900
}