import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import CategoriaEdit from "./components/categoria-edit/categoria-edit";

interface CategoriaModalProps extends ModalProps{
  openned: boolean;
  id: string;
}

export const CategoriaModal = (props: CategoriaModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && <CategoriaEdit id={props.id} />}
    </DefaultModal>
  );
};
