import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './central-page-styles';
import { CentralHeader } from './components/central-header/central-header';
import { OpcoesListData } from './components/opcoes-list/opcoes-list-data';
import { useHistory } from 'react-router-dom';
import { VariaveisAmbiente } from 'config';
import { PublicPageHeader } from 'views/components/headers';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { PdvIcon } from 'views/components/icons/pdv-icon';
import { LogoutIcon } from 'views/components/icons';
import { SessaoPDV } from '../../main/menu-principal/components/sessao-pdv/sessao-pdv';
import { EnumMenuPrincipalModelo } from 'model/enums/enum-menu-principal-modelo';
import { useEffect } from 'react';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';

export const CentralAdministrativa = () => {
  // AUX
  const classes = useStyles();
  const history = useHistory()
  const { alterarModo } = useMenuPrincipal()
  const { callTour } = useTourSaurus()

  useEffect(() => {
    callTour(EnumTour.FAVORITARMENU)
  }, [callTour])

  return (
    <>
      <PublicPageHeader hideInfo topOpacity="0.8" />
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <CentralHeader />
        </Grid>
        <Grid container className={classes.contentPerfil}>
          <SessaoPDV carregando={false} tiraBorderTop modeloAtual={EnumMenuPrincipalModelo.Completo} />
        </Grid>
        <div className={classes.defaultContainer}>
          <Grid className={classes.containerListCard}>
            <OpcoesListData />
          </Grid>
        </div>
        <div className={classes.drawerVersion}>
          <div className={classes.versionControl}>
            <div>
              <b>Ambiente: </b>
              <i>{VariaveisAmbiente.environment}</i>
            </div>
            <div>
              <b>SlowDevice: </b>
              <i>{VariaveisAmbiente.isSlowDevice ? 'Sim' : 'Não'}</i>
            </div>
            <div>
              <b>Versão: </b>
              <i>{VariaveisAmbiente.version}</i>
            </div>
          </div>
        </div>
        <Box className={classes.btnsFixosContainer}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              alterarModo(EnumMenuPrincipalModo.Retaguarda);
              history.push('/produtos');
            }}
            className={classes.btnFixoContent}
          >
            <LogoutIcon tipo="BUTTON_PRIMARY" />
            <Box lineHeight={1} style={{ marginLeft: 8 }}>
              <b>Sair do PDV</b>
              <Typography variant="body2">
                <Box fontWeight={500} fontSize={11}>
                  Acessar Gestão e Cadastros
                </Box>
              </Typography>
            </Box>
          </Button>
          <Box className={classes.divisorBtns}></Box>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => history.push('/venda-simples/gerenciar-sessao')}
            className={classes.btnFixoContent}>
            <PdvIcon tipo="BUTTON_PRIMARY" />
            <Box style={{ marginLeft: 8 }} lineHeight={1}>
              <b>Meu Caixa</b>
              <Typography variant="body2">
                <Box fontWeight={500} fontSize={11}>
                  Sangrias, suprimentos e <b>fechamento de caixa</b>
                </Box>
              </Typography>
            </Box>
          </Button>
        </Box>
      </Grid>
    </>
  );
};
