import { Redirect, Route, RouteProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { guidEmpty } from 'utils/guid-empty';
import { EnumSituacaoContrato } from 'model/enums/enum-situacao-contrato';
import {
  GestaoStorageKeys,
  useGestaoStorage,
  useGestaoToken,
  useToastSaurus,
  useSessaoAtual
} from 'services/app';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';

export const ExpiredTokenRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  const { usuario, plano, termosDeUsoAtivo, carregando: carregandoSessaoAtual, validaUsuarioConectado, refreshUser } = useSessaoAtual();
  const { isTokenExpired, getEmpresaAtual } = useGestaoToken();
  const { showToast } = useToastSaurus();
  const { delRegistro, getRegistro } = useGestaoStorage();
  const [carregandoInterno, setCarregandoInterno] = useState<boolean>(false)
  const carregando = carregandoSessaoAtual || carregandoInterno

  useEffect(() => {
    (async () => {
      if (isTokenExpired(usuario)) {
        if (validaUsuarioConectado()) {
          setCarregandoInterno(true)
          await refreshUser(usuario!.originalToken)
        }
      }
    })()
    setCarregandoInterno(false)
  }, [isTokenExpired, refreshUser, usuario, validaUsuarioConectado])

  const retRoute = useCallback(() => {
    if (!validaUsuarioConectado()) {
      if (isTokenExpired(usuario)) {
        if (
          !getRegistro(GestaoStorageKeys.LandingPage, false).visto &&
          VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA
        ) {
          return <Redirect to="/landing" />;
        }
        showToast(
          'error',
          `Sua sessão expirou. Realize o login novamente para continuar. (401) `
        );
        delRegistro(GestaoStorageKeys.Token, false);
        return <Redirect to="/login/expirou" />;
      }
    }

    return carregando ? (
      children
    ) : isEmpty(usuario) ? (
      <Redirect to="/login/expirou" />
    ) : !usuario?.apelido ? (
      <Redirect to="/cadastro-apelido" />
    ) : plano?.plano?.id === guidEmpty() ? (
      <Redirect to="/selecionar-plano" />
    ) : !termosDeUsoAtivo() ? (
      <Redirect to="/termos-de-uso" />
    ) : plano?.contratado.status !== null &&
      plano?.contratado.status !== EnumSituacaoContrato.OK ? (
      <>
        {showToast(
          'info',
          'Parece que tem algo errado com seu contrato, faça login novamente.'
        )}
        <Redirect to="/login/expirou" />
      </>
    ) : isEmpty(getEmpresaAtual()) ? (
      <Redirect to="/selecionar-empresa" />
    ) : (
      children
    );
  }, [validaUsuarioConectado, carregando, children, usuario, plano?.plano?.id, plano?.contratado.status, termosDeUsoAtivo, showToast, getEmpresaAtual, isTokenExpired, getRegistro, delRegistro]);
  return <Route {...otherProps}>{retRoute()}</Route>;
};
