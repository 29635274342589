import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const AddIconAlt = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M23.1094 26.4609C18.2813 26.4609 13.6875 26.4609 9 26.4609C9 25.2656 9 24.2578 9 23.0859C13.5938 23.0859 18.1875 23.0859 22.9453 23.0859C22.9453 18.3281 22.9453 13.7578 22.9453 9C24.1641 9 25.1953 9 26.4141 9C26.4141 13.5703 26.4141 18.1406 26.4141 22.9688C31.2188 22.9688 35.8359 22.9688 40.5703 22.9688C40.5703 24.2109 40.5703 25.2188 40.5703 26.4141C35.9297 26.4141 31.3359 26.4141 26.5547 26.4141C26.5547 31.1719 26.5547 35.7656 26.5547 40.4531C25.3125 40.4531 24.2813 40.4531 23.1094 40.4531C23.1094 35.8359 23.1094 31.3125 23.1094 26.4609Z" />
      </DefaultIcon>
    </>
  );
};
