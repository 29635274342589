import { EntradaListSearchProps } from 'views/pages/private/entrada/components/entrada-list/entrada-list-search-props';
import { DefaultModal } from '../components/default-modal/default-modal';
import { FiltroModalProps } from '../utils';
import { EntradaFiltroConteudo } from './entrada-filtro-content/entrada-filtro-conteudo';

export const EntradaFiltroModal = (props: FiltroModalProps<EntradaListSearchProps>) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <EntradaFiltroConteudo filtros={props.filtros} />}
    </DefaultModal>
  );
};
