import { EnumEntradaFiltroPagina } from "../../entrada-filtro-conteudo"
import { EntradaFiltroNNFProps } from "./entrada-filtro-nnf-props"
import { Box, Button, Grid } from "@material-ui/core"
import { OkIcon } from "views/components/icons"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { Controller, useForm } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"

export const EntradaFiltroNNF = (props: EntradaFiltroNNFProps) => {
    const modalClasses = useModalStyles()

    const { control, handleSubmit } = useForm<{ nnf: number }>({
        defaultValues: { nnf: props.vFiltro }
    })

    const submit = (value: { nnf: number }) => {
        props.alterarFiltro('nnf', value.nnf)

        props.entrarPagina(EnumEntradaFiltroPagina.Inicial)
    }

    return (
        <Box component="form" display="flex" height="100%" onSubmit={handleSubmit(submit)}>
            <div className={modalClasses.content}>
                <Box width="100%" p={3} mt={1} height="0" flex="1">
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Controller
                                control={control}
                                name="nnf"
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        {...field}
                                        label="Número da Nota Fiscal"
                                        tipo="NUMERO"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div className={modalClasses.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                <OkIcon tipo="BUTTON_PRIMARY" />
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Box>
    )
}