import { UsuarioEditPostModel } from 'model/app/forms/perfil/perfil-usuario-edit-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostPerfilUsuario() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const postPerfilUsuario = useCallback(
		(usuario: UsuarioEditPostModel) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/UsuarioEmpresaPerfil`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				data: JSON.stringify(usuario),
				enviarTokenUsuario: true,
			}),
		[invocarApi]
	);
	return {
		...outrasPropriedades,
		postPerfilUsuario
	}
}