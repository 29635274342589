import { useEffect, useState, useCallback } from 'react';

import { Grid } from '@material-ui/core';
import { useStyles } from './modificadores-list-styles';
import { ModificadoresListData } from './modificadores-list-data';

import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { useHistory } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useGetModificadores } from 'data/api/gestao/modificador/get-modificadores';
import { ModificadorModel } from 'model/api/gestao/modificador/modificador-model';

export const ModificadoresList = () => {
  const classes = useStyles();
  const { abrirCadastroModificador } = useCadastros();
  const { getModificadores, carregando } = useGetModificadores()
  const { addHandler, removeHandler } = useEventTools()

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<ModificadorModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ModificadorModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const urlParams = new URLSearchParams(history.location.search)
  const filtros = {
    descricao: urlParams.get('descricao') || ''
  }

  const search = useCallback(
    async (newPage: number) => {

      try {
        const query = filtros.descricao.length > 0 ? `&descricao=${filtros.descricao}` : ''
        const res = await getModificadores(query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data.pageIndex,
          res.resultado?.data.totalPages,
          res.resultado?.data.totalResults,
          res.resultado?.data.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.descricao, getModificadores, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.ModificadorModal, modalEdit)

    return () => removeHandler(AppEventEnum.ModificadorModal, modalEdit)
  }, [addHandler, modalEdit, removeHandler])

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }
  }, [modalEditAberto, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    abrirCadastroModificador(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <ModificadoresListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
