import { useCallback } from 'react';
import { useApiBase } from 'data/api/base';
import { VariaveisAmbiente } from 'config';

export function useGetMedicamentoPrecoByMedId() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMedicamentoPrecoByMedId = useCallback(
        (id: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/MedicamentoPreco/medicamento/${id}`,
                method: 'GET',
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMedicamentoPrecoByMedId
    };
}
