import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export interface RefreshProps{
    refreshTokenId: string;
    usuarioId: string;
}

export function usePostIdentidadeRefresh() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeRefresh = useCallback(
        (model: RefreshProps) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Auth/refresh?usuarioId=${model.usuarioId}&refreshToken=${model.refreshTokenId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeRefresh,
    };
}
