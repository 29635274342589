import { Box, Grid, Typography } from "@material-ui/core"
import { DefaultCard } from "../components"
import { PessoaContatosModel } from "model/api/gestao/pessoa"
import { EditarIcon } from "views/components/icons";
import { useStyles } from '../card-pessoa-endereco/card-pessoa-endereco-styles'
import classNames from "classnames";

interface CardPessoaContatoProps {
    model: PessoaContatosModel;
    onClick: (model: PessoaContatosModel) => void;
}

export const CardPessoaContato = ({
    model,
    onClick,
}: CardPessoaContatoProps) => {
    const classes = useStyles();
    return (
      <DefaultCard onClick={() => onClick(model)} className={classes.card}>
        <Grid container spacing={2} >
          <Grid item xs={model.responsavel?.length > 0 ? 6 : 12}>
            <Typography color="textPrimary" variant="caption">
              Contato
            </Typography>
            <Typography
              className={classNames('celula-grid-value', classes.overflowText )}
              color="textPrimary"
              variant="body1"
            >
              {model.valor}
            </Typography>
          </Grid>
          {model.responsavel?.length > 0 && (
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Responsável
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.responsavel}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Box className={classes.iconContainer}>
          <EditarIcon tipo="BUTTON" />
        </Box>
      </DefaultCard>
    );
}