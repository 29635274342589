import { KeyValueModel } from "model";
import { TipoDocumentoOrgaoExpedidorEnum } from "model/enums/enum-orgao-expedidor";

export const TipoDocumentoOrgaoExpedidorMock: KeyValueModel[] = [
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFarmacia, "CRF - Conselho Regional de Farmácia"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.SecretariaSegurancaPublica, "SSP - Secretaria de Segurança Pública"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalMedicina, "CRM - Conselho Regional de Medicina"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoFederalPsicologia, "CFP - Conselho Federal de Psicologia"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalOdontologia, "CRO - Conselho Regional de Odontologia"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalEnfermagem, "COREN - Conselho Regional de Enfermagem"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoFederalNutricionistas, "CFN - Conselho Federal de Nutricionistas"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalBiologia, "CRBio - Conselho Regional de Biologia"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalNutricao, "CRN - Conselho Regional de Nutrição"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoFederalEnfermagem, "Cofen - Conselho Federal de Enfermagem"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalPsicologia, "CRP - Conselho Regional de Psicologia"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFisioterapiaTerapiaOcupacional, "CREFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional"),
    new KeyValueModel(TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFonoaudiologia, "CREFONO - Conselho Regional de Fonoaudiologia"),
];
