import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { DesfazerIcon, FiltroIcon, VoltarIcon } from "views/components/icons";
import { useHistory } from "react-router-dom";
import { PesquisaListaEmpresas } from "./pesquisa-lista-empresas/pesquisa-lista-empresas";
import { ListaEmpresasListSearchProps } from "../lista-empresas-list/lista-empresas-list-search-props";

export interface ListaEmpresasHeaderProps {
    openPesquisa: boolean;
    setOpenPesquisa: (state: boolean) => any;
    setSearchProps: (props: any) => any;
}

export const ListaEmpresasHeader = ({ openPesquisa,
    setOpenPesquisa,
    setSearchProps
}: ListaEmpresasHeaderProps) => {
    const history = useHistory()

    const leftArea = useCallback(
        () =>
            <ButtonPrivateHeader
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Voltar para Configurações"
                onClick={() => history.push("/configuracoes")}
            />,
        [history]
    );

    const rightArea = useCallback(() => (
        <ButtonPrivateHeader
            icon={
                openPesquisa ?
                    <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
                    :
                    <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
            onClick={() => setOpenPesquisa(!openPesquisa)}
        ></ButtonPrivateHeader>
    ),
        [setOpenPesquisa, openPesquisa]
    );

    const closePesquisaWrapper = useCallback(() => {
        setOpenPesquisa(false);
    }, [setOpenPesquisa]);

    const onPesquisar = useCallback(
        (props: ListaEmpresasListSearchProps) => {
            setSearchProps(props);
        },
        [setSearchProps]
    );

    const pesquisa = <PesquisaListaEmpresas isOpened={openPesquisa!} onCloseSearch={closePesquisaWrapper} onPesquisar={onPesquisar} />
    return (
        <>
            <PrivatePageHeader title="Suas Empresas" leftArea={leftArea()} bottomArea={pesquisa} rightArea={rightArea()} />
        </>
    );
};
