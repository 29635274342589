import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetObterCupomVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getObterCupomVenda = useCallback(
        (idVenda: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Movimento/${idVenda}/cupom?tpDanfe=1`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getObterCupomVenda,
    };
}
