import { useCallback, useMemo, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import ProdLeitor from './venda-leitor-fragment';

const MovProdLeitorPage = () => {
  //STATES E REFS
  const codigo = useRef<string>('');

  //PROVIDERS
  const { showToast } = useToastSaurus();
  const { inserirProdutoByCodigo } = useMovProd();

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      const ArrayComma = text.split('').filter((comma) => comma === ',');
      if (ArrayComma && ArrayComma.length > 1) {
        return false;
      }
      codigo.current = text;
      return true;
    },
    [],
  );

  const submit = useCallback(async () => {
    return false;
  }, []);

  const add = useCallback(async () => {
    return true;
  }, []);

  const handleAddByCodigo = useCallback(async () => {
    try {
      await inserirProdutoByCodigo(codigo.current);
    } catch (err: any) {
      showToast('error', err.message);
    } finally {
    }
  }, [inserirProdutoByCodigo, showToast]);

  const fragmento = useMemo(() => {
    return <ProdLeitor
      submit={submit}
      add={add}
      textChanged={textChanged}
      handleAddByCodigo={handleAddByCodigo}
    />;
  }, [add, handleAddByCodigo, submit, textChanged]);

  return (
    <>
      {fragmento}
    </>
  );
};

export default MovProdLeitorPage;
