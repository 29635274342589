import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-erro-finalizar-page-styles';
import { ErroFinalizaHeader } from './components/erro-finalizar-venda-header';
import { CircularLoading, useThemeQueries } from 'views';
import { ErroFinalizarVendaIcon } from 'views/components/icons/erro-finalizar-venda-icon';
import { useHistory } from 'react-router-dom';
import { usePutAlterarNumeracao } from 'data/api/gestao/ultima-numerao/put-alterar-numeracao';
import { useToastSaurus } from 'services/app';
import { AlterarNumeracaoModel } from 'model/api/gestao/alterar-numeracao/alterar-numeracao-model';
import { DialogAlterarNumeracao } from 'views/components/dialog/dialog-alterar-numeracao/dialog-alterar-numeracao';
import { ChangeEvent, useState } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import classNames from 'classnames';
import { AtualizarIcon, EditarIcon, LixoIcon } from 'views/components/icons';

const MovErroFinalizarPage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const {
    carregando: getStatusErroFinalizarCarregando,
    getMov,
    alterarNNF
  } = useMovAtual();
  const { putAlterarNumeracao, carregando: putAlterarNumeracaoCarregando } =
    usePutAlterarNumeracao();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const mov = getMov();

  const [modal, setModal] = useState<boolean>();
  const [numero, setNumero] = useState<number>(mov?.nnf || 0);

  const carregando =
    getStatusErroFinalizarCarregando || putAlterarNumeracaoCarregando;

  const duplicidade: boolean =
    mov?.retornoFinalizacao?.error !== undefined &&
    mov?.retornoFinalizacao.adicional === 424;
  const loteProcessamento: boolean =
    mov?.retornoFinalizacao?.error !== undefined &&
    mov?.retornoFinalizacao.adicional === 423;

  const atualizarNumeracao = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      const res = await putAlterarNumeracao(
        new AlterarNumeracaoModel(mov?.serie, numero),
        mov?.id || ''
      );

      if (res.erro) {
        throw res.erro;
      }

      await alterarNNF(numero ?? 0);

      history.push('/venda-simples/finalizar-venda');
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.header}>
          <ErroFinalizaHeader />
        </Grid>
        <Grid
          className={`${classes.containerErroFinalizar} ${
            !isMobile ? classes.alignCenter : ''
          }`}
        >
          <Grid className={classes.content}>
            <Grid className={classes.infoContainer}>
              <Typography className={classes.textTitle}>
                {!duplicidade
                  ? 'Ops, encontramos um problema!'
                  : 'Duplicidade de Numeração'}
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    maxWidth: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <ErroFinalizarVendaIcon
                    tipo="GERAL"
                    style={{ width: '210px' }}
                  />
                </Grid>
              </Grid>
              <Grid className={!isMobile ? classes.cardInfo : ''}>
                <Grid container justifyContent="space-between">
                  <Typography className={classes.label}>
                    Número / Série
                  </Typography>
                  <Typography className={classes.label}>Valor</Typography>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: 24 }}
                  justifyContent="space-between"
                >
                  <Typography className={classes.infoNomeEQuantidade}>
                    {mov?.nnf + ' / ' + mov?.serie}
                  </Typography>
                  <Typography className={classes.infoNomeEQuantidade}>
                    R$ {toDecimalString(mov?.vNF)}
                  </Typography>
                </Grid>
                <Typography className={classes.textSubtitle}>
                  {!duplicidade
                    ? 'Ocorreu um erro ao finalizar a venda.'
                    : 'Identificamos uma duplicidade de numeração em sua venda. Isso pode acontecer por ter realizado uma venda com a mesmo numeração em outro sistema ou por algum eventual problema na SEFAZ.'}
                </Typography>
                <Typography
                  className={`${classes.textSubtitle} ${
                    !isMobile ? classes.maxDetails : ''
                  }`}
                >
                  Detalhe:{' '}
                  {mov?.retornoFinalizacao?.error
                    ? mov?.retornoFinalizacao?.error
                    : ''}
                </Typography>
              </Grid>
            </Grid>
            {!isMobile && (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  className={classes.button}
                  onClick={
                    !duplicidade
                      ? () => history.push('/venda-simples/finalizar-venda')
                      : () => setModal(!modal)
                  }
                >
                  {!duplicidade ? (
                    <AtualizarIcon tipo="BUTTON_PRIMARY" />
                  ) : (
                    <EditarIcon tipo="BUTTON_PRIMARY" />
                  )}
                  {!duplicidade ? 'Tentar novamente' : 'Mudar Numeração'}
                </Button>

                {!loteProcessamento && (
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    className={classNames(classes.button, 'round')}
                    onClick={() => history.push('/venda-simples/cancelar')}
                  >
                    <LixoIcon tipo="BUTTON" />
                    Descartar venda
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {isMobile && (
          <Grid className={classes.containerButton}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className={classNames(classes.button, 'round')}
              style={{ marginTop: isMobile ? 8 : 0 }}
              onClick={
                !duplicidade
                  ? () => history.push('/venda-simples/finalizar-venda')
                  : () => setModal(!modal)
              }
            >
              {!duplicidade ? (
                <AtualizarIcon tipo="BUTTON_PRIMARY" />
              ) : (
                <EditarIcon tipo="BUTTON_PRIMARY" />
              )}
              {!duplicidade ? 'Tentar novamente' : 'Mudar Numeração'}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              className={classNames(classes.button, 'round')}
              onClick={() => history.push('/venda-simples/cancelar')}
            >
              <LixoIcon tipo="BUTTON" />
              Descartar venda
            </Button>
          </Grid>
        )}
      </Grid>
      <DialogAlterarNumeracao
        closeModal={() => setModal(!modal)}
        loading={carregando}
        enviarNumero={atualizarNumeracao}
        numero={numero || 0}
        setNumero={setNumero}
        openned={modal || false}
      />
    </>
  );
};

export default MovErroFinalizarPage;
