import { FinalizadoraModel } from "model/api/gestao/finalizadora/finalizadora-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogCredenciarPix } from "views/components/dialog/dialog-credenciar-pix/dialog-credenciar-pix";

class CredenciarPixDialogProps {
    constructor(
        public aberto: boolean = false,
        public finalizar: (pagamento: FinalizadoraModel) => Promise<void> = async () => { },
        public cancelar: (message: string) => Promise<void> = async () => { },
        public finalizadoraId: string = ''
    ) { }
}

export const CredenciarPixDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<CredenciarPixDialogProps>(
        new CredenciarPixDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, cancelar, finalizadoraId, finalizar }: CredenciarPixDialogProps) => {
        setModalState({
            aberto: aberto,
            finalizar,
            cancelar,
            finalizadoraId
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogCredenciarPix, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogCredenciarPix, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogCredenciarPix finalizar={modalState.finalizar} openned={modalState.aberto}
                    cancelar={modalState.cancelar} pagamentoId={modalState.finalizadoraId} />
            )}
        </>
    )
}