import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-generico-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { TextFieldSaurus } from '../../controles/inputs';
import { useEffect, useRef } from 'react';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Controller, useForm } from 'react-hook-form';
import { useDialogProdutoGenericoValidation } from './dialog-produto-generico-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProdutoGenericoFormModel } from 'model/app/forms/movimentacao/produto-generico-form-model';

export interface DialogAdicionarProdutoGenericoProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel | null;
}

export const DialogAdicionarProdutoGenerico = ({
  aberto,
  produto,
}: DialogAdicionarProdutoGenericoProps) => {
  const refInputXProd = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  // const [error, setError] = useState(false);
  const { isMobile } = useThemeQueries();
  const { carregando, adicionarProdutoGenerico } = useMovProd()
  const { fecharAdicionarProdutoGenerico } = useCadastros()
  const { callEvent } = useEventTools()

  useEffect(() => {
    if (aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, callEvent, produto])

  const { DialogProdutoGenericoValidation } = useDialogProdutoGenericoValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
  } = useForm<ProdutoGenericoFormModel>({
    defaultValues: { ...new ProdutoGenericoFormModel() },
    resolver: yupResolver(DialogProdutoGenericoValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (produto) {
      setValue('qCom', produto.qCom.toString())
      if (aberto && !isMobile) {
        setTimeout(() => {
          refInputXProd.current?.focus();
        }, 50);
      }
    }
    // setError(false);
  }, [aberto, isMobile, produto, setValue]);

  const onSubmit = async (value: ProdutoGenericoFormModel) => {
    const { qCom, vUnCom, xProd } = value

    if (toDecimal(vUnCom, 2) === 0 || toDecimal(qCom, 2) === 0) {
      showToast('error', 'Informe o Valor e Quantidade do Produto');
      // setError(true);
      return;
    }

    // setError(false);
    await adicionarProdutoGenerico(
      produto!,
      toDecimal(vUnCom, 10),
      toDecimal(qCom, 4),
      xProd.length === 0 ? produto!.xProd : xProd,
    );
    fecharAdicionarProdutoGenerico()
  };

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Produto Genérico"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name='xProd'
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      disabled={carregando}
                      fullWidth
                      inputRef={refInputXProd}
                      size="medium"
                      variant="outlined"
                      label="Descrição do Produto"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      error={Boolean(
                        errors.xProd && errors.xProd.message,
                      )}
                      helperText={
                        touchedFields.xProd || errors.xProd
                          ? errors.xProd?.message
                          : undefined
                      }
                      placeholder={produto!.xProd}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="vUnCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Valor de Venda"
                      max={8}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={12}
                      {...field}
                      error={Boolean(
                        errors.vUnCom && errors.vUnCom.message,
                      )}
                      helperText={
                        touchedFields.vUnCom || errors.vUnCom
                          ? errors.vUnCom?.message
                          : undefined
                      }
                      placeholder={toDecimalString(produto!.vUnCom, 2)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name="qCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Quantidade"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={12}
                      {...field}
                      error={Boolean(
                        errors.qCom && errors.qCom.message,
                      )}
                      helperText={
                        touchedFields.qCom || errors.qCom
                          ? errors.qCom?.message
                          : undefined
                      }
                      placeholder=""
                      allowSubmit
                      onChange={(e)=>{
                        let qtd = e.target.value
                        setValue('qCom', qtd)
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                {fecharAdicionarProdutoGenerico && (
                  <Button
                    disabled={carregando}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      fecharAdicionarProdutoGenerico();
                    }}
                    className='round'
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  className='round'
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  {'Confirmar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
