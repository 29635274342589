import { forwardRef, useImperativeHandle } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDocumentoFiscalValidation } from './form-documento-fiscal-cadastro-validation';
import { DocumentoFiscalCadastroFormModel } from 'model/app/forms/documento-fiscal/documento-fiscal-cadastro-form-model';

export const FormDocumentoFiscalCadastro = forwardRef<
  DefaultFormRefs<DocumentoFiscalCadastroFormModel>,
  DefaultFormProps<DocumentoFiscalCadastroFormModel>
>((props: DefaultFormProps<DocumentoFiscalCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCnpjCpf = useRef<HTMLInputElement>(null);
  const { isMobile } = useThemeQueries();
  const { FormDocumentoFiscalValidation } = useFormDocumentoFiscalValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<DocumentoFiscalCadastroFormModel>({
    resolver: yupResolver(FormDocumentoFiscalValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: DocumentoFiscalCadastroFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
    fillForm: () => {
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dInicial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'DATA'}
                      disabled={props.loading}
                      allowSubmit
                      fullWidth
                      variant="outlined"
                      label="Data Inicial"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: 1"
                      error={Boolean(
                        errors.dInicial && errors.dInicial.message,
                      )}
                      helperText={
                        touchedFields.dInicial || errors.dInicial
                          ? errors.dInicial?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="dFinal"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'DATA'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Data Final"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                      error={Boolean(
                        errors.dFinal && errors.dFinal.message,
                      )}
                      helperText={
                        touchedFields.dFinal || errors.dFinal
                          ? errors.dFinal?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
