export class EmitCompletoModel {
    constructor(
        public id: string = "",
        public ideId: string = "",
        public empresaId: string = "",
        public doc: string = "",
        public xNome: string = "",
        public xFant: string = "",
        public xLgr: string = "",
        public nro: string = "",
        public xCpl: string = "",
        public xBairro: string = "",
        public cMun: number = 0,
        public xMun: string = "",
        public uf: string = "",
        public cep: string = "",
        public cPais: number = 0,
        public xPais: string = "",
        public fone: string = "",
        public ie: string = "",
        public iest: string = "",
        public im: string = "",
        public cnae: string = "",
        public crt: number = 0
    ) { }
}

export class IcmsCompletoModel {
    constructor(
        public id: string = "",
        public prodId: string = "",
        public vBcUfDest: number = 0,
        public vBcFcpUfDest: number = 0,
        public pFcpUfDest: number = 0,
        public pIcmsUfDest: number = 0,
        public pIcmsInter: number = 0,
        public pIcmsInterPart: number = 0,
        public orig: number = 0,
        public cst: number = 0,
        public csosn: number = 0,
        public vFcpUfDest: number = 0,
        public vIcmsUfDest: number = 0,
        public vIcmsUfRemet: number = 0,
        public modBc: number = 0,
        public pRedBc: number = 0,
        public vBc: number = 0,
        public pIcms: number = 0,
        public vIcmsOp: number = 0,
        public pDif: number = 0,
        public vIcmsDif: number = 0,
        public vIcms: number = 0,
        public pFcp: number = 0,
        public vFcp: number = 0,
        public vBcFcp: number = 0,
        public modBcSt: number = 0,
        public pMvaSt: number = 0,
        public pRedBcSt: number = 0,
        public vBcSt: number = 0,
        public pIcmsSt: number = 0,
        public vIcmsSt: number = 0,
        public vBcFcpSt: number = 0,
        public pFcpSt: number = 0,
        public vFcpSt: number = 0,
        public ufSt: string = "",
        public pBcOp: number = 0,
        public vBcStRet: number = 0,
        public pSt: number = 0,
        public vIcmsDeson: number = 0,
        public motDesIcms: number = 0,
        public vIcmsSubstituto: number = 0,
        public vIcmsStRet: number = 0,
        public vBcFcpStRet: number = 0,
        public pFcpStRet: number = 0,
        public vFcpStRet: number = 0,
    ) { }
}

export class PisCompletoModel {
    constructor(
        public id: string = '',
        public prodId: string = '',
        public cst: number = 0,
        public vbc: number = 0,
        public pPis: number = 0,
        public qBcProd: number = 0,
        public vAliqProd: number = 0,
        public vpis: number = 0,
    ) { }
}

export class ConfisCompletoModel {
    constructor(
        public id: string = '',
        public prodId: string = '',
        public cst: number = 0,
        public vbc: number = 0,
        public pConfins: number = 0,
        public qBcProd: number = 0,
        public vAliqProd: number = 0,
        public vConfis: number = 0,
    ) { }
}

export class ProdsCompletoModel {
    constructor(
        public id: string = "",
        public vendedorId: string = "",
        public produtoGradeId: string = "",
        public ideId: string = "",
        public categoriaId: string = "",
        public categoriaNome: string = "",
        public nItem: number = 0,
        public cProd: string = "",
        public cEan: string = "",
        public xProd: string = "",
        public ncm: string = "",
        public nve: string = "",
        public cest: string = "",
        public indEscala: number = 78,
        public cnpjFab: string = "",
        public cBenef: string = "",
        public extIpi: string = "",
        public cfop: number = 0,
        public uCom: string = "",
        public qCom: number = 0,
        public vUnCom: number = 0,
        public vProd: number = 0,
        public cEanTrib: string = "",
        public uTrib: string = "",
        public qTrib: number = 0,
        public vUnTrib: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDesc: number = 0,
        public vOutro: number = 0,
        public indTot: number = 0,
        public xPed: string = "",
        public nItemPed: string = "",
        public nFci: string = "",
        public nrecopi: string = "",
        public infAdProd: string = "",
        public vTotTrib: number = 0,
        public cancelado: boolean = true,
        public vCusto: number = 0,
        public pTribFed: number = 0,
        public pTribEst: number = 0,
        public pTribMun: number = 0,
        public icms: IcmsCompletoModel = new IcmsCompletoModel(),
        public pis: PisCompletoModel = new PisCompletoModel(),
        public confins: ConfisCompletoModel = new ConfisCompletoModel(),
        public taxaServico: number = 0,
        public valorServico: number = 0,
        public indFin: boolean = true,
    ) { }
}

export class CardPagsCompletoModel {
    constructor(
        public id: string = '',
        public detPagId: string = '',
        public tpIntegra: number = 1,
        public cnpj: string = '',
        public tBand: number = 1,
        public cAut: string = '',
    ) { }
}

export class PagsCompletoModel {
    constructor(
        public id: string = '',
        public vTroco: number = 0,
        public status: number = 0,
        public tPag: number = 1,
        public tpTransacao: number = 0,
        public vPag: number = 0,
        public descricao: string = '',
        public cancelado: boolean = true,
        public card: CardPagsCompletoModel = new CardPagsCompletoModel(),
    ) { }
}

export class DestCompletoModel {
    constructor(
        public id: string = '',
        public pessoaId: string = '',
        public documento: string = '',
        public xNome: string = '',
        public fone: string = '',
        public email: string = '',
    ) { }
}

export class IcmsTotCompletoModel {
    constructor(
        public id: string = '',
        public qItens: number = 0,
        public qCom: number = 0,
        public vProd: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDesc: number = 0,
        public vOutro: number = 0,
        public vnf: number = 0,
    ) { }
}

export class MovStatusCompletoModel {
    constructor(
        public contratoId: string = '',
        public ideId: string = '',
        public id: string = '',
        public nSeq: number = 0,
        public descricao: string = '',
        public level: number = 10,
    ) { }
}

export class HistoricoVendasCompletoModel {
    constructor(
        public mod: number = 1,
        public tpEmis: number = 1,
        public status: number = 10,
        public idNFe: string = '',
        public dhEmi: Date = new Date(),
        public serie: number = 0,
        public nNf: number = 0,
        public nnf: number = 0,
        public tpAmb: number = 1,
        public infCpl: string = '',
        public infAdFisco: string = '',
        public emit: EmitCompletoModel = new EmitCompletoModel(),
        public prods: Array<ProdsCompletoModel> = new Array<ProdsCompletoModel>(),
        public pags: Array<PagsCompletoModel> = new Array<PagsCompletoModel>(),
        public dest: DestCompletoModel = new DestCompletoModel(),
        public icmsTot: IcmsTotCompletoModel = new IcmsTotCompletoModel(),
        public movStatus: Array<MovStatusCompletoModel> = new Array<MovStatusCompletoModel>(),
    ) { }
}