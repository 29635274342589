import { Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useGetMeuUsuario } from 'data/api/gestao/meu-usuario';
import { usePutAcessoSuporte } from "data/api/gestao/usuario/put-acesso-suporte";
import { usePutRevogarAcessoSuporte } from "data/api/gestao/usuario/put-revogar-acesso-suporte";
import { isEmpty } from "lodash";
import { UsuarioModel } from "model/api/gestao/usuarios/usuario-model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormCodigoSuporteRemoto } from "views/components/form/master/codigo-suporte-remoto/form-codigo-suporte-remoto";
import { CancelarIcon } from "views/components/icons";
import { ChaveIcon } from "views/components/icons/chave-icon";
import { SuporteRemotoIcon } from "views/components/icons/suporte-remoto-icon";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useThemeQueries } from "views/theme"
import { useStyles } from "./accordion-suporte-remoto-styles";

export const AccordionSuporteRemoto = () => {
    //AUX
    const { theme } = useThemeQueries();
    const classesModal = useModalStyles();
    const { showToast } = useToastSaurus();
    const styles = useStyles()

    //STATES
    const [revogar, setRevogar] = useState<boolean>(false);
    const [expirado, setExpirado] = useState<boolean>(false);
    const [openAccordion, setOpenAccordion] = useState<boolean>(false);
    const [model, setModel] = useState<UsuarioModel>(new UsuarioModel());

    //CHAMADAS DA API
    const { getMeuUsuario, carregando: carregandoGet } = useGetMeuUsuario();
    const { putAcessoSuporte, carregando: carregandoPutAcessoSuporte } = usePutAcessoSuporte();
    const { putRevogarAcessoSuporte, carregando: carregandoPutRevogarAcesso } = usePutRevogarAcessoSuporte();

    const carregando = carregandoPutRevogarAcesso || carregandoPutAcessoSuporte || carregandoGet;

    const getUsuarioWrapper = useCallback(async () => {
        setExpirado(false);
        const res = await getMeuUsuario();

        if (res.erro) throw res.erro

        if (isEmpty(res.resultado?.data.codAcessoSuporte) && isEmpty(res.resultado?.data.expiracaoAcessoSuporte)) {
            setRevogar(true);
        }

        const dataAtual = new Date();
        const dataValidar = new Date(res.resultado?.data.expiracaoAcessoSuporte);

        if (dataValidar < dataAtual) {
            setExpirado(true);
        }

        return setModel(res.resultado?.data)
    }, [getMeuUsuario])

    useEffect(() => {
        if (openAccordion) {
            getUsuarioWrapper();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAccordion])

    const putRevogarAcessoSuporteWrapper = useCallback(async () => {
        const res = await putRevogarAcessoSuporte();

        if (res.erro) throw res.erro

        return res.resultado?.data as UsuarioModel;
    }, [putRevogarAcessoSuporte])

    const putAcessoSuporteWrapper = useCallback(async () => {
        const res = await putAcessoSuporte();

        if (res.erro) throw res.erro

        return;
    }, [putAcessoSuporte])

    const salvarSenha = useCallback(async () => {
        try {
            setRevogar(false);
            await putAcessoSuporteWrapper()
            await getUsuarioWrapper();

            showToast('success', 'Código gerado com sucesso.');
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getUsuarioWrapper, putAcessoSuporteWrapper, showToast])

    const revogarAcesso = useCallback(async () => {
        try {
            const res = await putRevogarAcessoSuporteWrapper();

            if (isEmpty(res.codAcessoSuporte) && isEmpty(res.expiracaoAcessoSuporte)) {
                setRevogar(true);
            }

            await getUsuarioWrapper();
            showToast('success', 'O acesso a terceiros, foi revogado com sucesso.');
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getUsuarioWrapper, putRevogarAcessoSuporteWrapper, showToast])

    //BUTTON SALVAR
    const buttonAlterarSenha = useMemo(() => {
        return (
            <div className={classesModal.acoes}>
                <Grid container spacing={2}>
                    {!revogar && (
                        <Grid item sm={12} xs={12} md={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={revogarAcesso}
                            >
                                <CancelarIcon tipo="BUTTON" />
                                Revogar Acesso
                            </Button>
                        </Grid>
                    )}
                    <Grid item sm={12} xs={12} md={revogar ? 12 : 6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={salvarSenha}
                        >
                            <ChaveIcon tipo="BUTTON_PRIMARY" />
                            Gerar Código
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }, [classesModal.acoes, revogar, revogarAcesso, salvarSenha]);

    //FORMS 
    const forms = useMemo(() => {
        return (
            <>
                <div className={classNames(classesModal.contentForms, styles.formulario)}>
                    <FormCodigoSuporteRemoto
                        model={model}
                        revogar={revogar}
                        expirado={expirado}
                    />
                </div>
            </>
        )
    }, [classesModal.contentForms, expirado, model, revogar, styles.formulario])

    return (
        <>
            <AccordionSaurus
                labelPrimary="Suporte Remoto"
                labelColor={theme.palette.primary.main}
                tipoExpand="bold"
                tituloChildren={
                    <SuporteRemotoIcon tipo="BUTTON" />
                }
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
            >
                <div className={classesModal.root}>
                    {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                    <div className={classesModal.content}>
                        {forms}
                        {buttonAlterarSenha}
                    </div>
                </div>
            </AccordionSaurus>
        </>
    )
}