import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        background: "#FFF"
    },
    topHeader: {
        height: "90px",
        display: "flex",
        background: "rgba(236, 237, 237, 0.6)",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "space-between",
    },
    unselectable: {
        "-webkit-touch-callout": 'none',
        "-webkit-user-select": 'none',
        "-khtml-user-select": 'none',
        "-moz-user-select": 'none',
        "-ms-user-select": 'none',
        "user-select": 'none',
    },
    containerTela: {
        minHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    containerCarrinhoAvulso: {
        with: '100%',
        padding: '0px 10px',
        fontSize: 13,
    },
    contentTela: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    title: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
    },
    middleContainer: {
        display: "flex",
        height: "90px",
        alignItems: "flex-end",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    numpadContainer: {
        flexGrow: 1,
        height: "100%",
        background: theme.palette.grey[50],
    },
    numpadValueContainer: {
        background: theme.palette.primary.contrastText,
    },
    numpadValue: {
        fontWeight: "bold",
        color: "#8D8D8D",
    },
    setarValorPadraoContainer: {
        display: "flex",
    },
    setarValorPadraoItem: {
        flexBasis: "20%",
    },
    valorContainer: {
        "& .valorMonetario": {
            display: "flex",
            color: "#696969",
            "& .unidade": {
                fontWeight: "600",
                marginRight: "1.5vw",
                [theme.breakpoints.up("sm")]: {
                    marginRight: "1vw",
                },
                [theme.breakpoints.up("md")]: {
                    marginRight: "0.7vw",
                },
            },
            "& .valor": {
                fontWeight: "bold",
            },
        },
    },
    buttonsContainer: {
        height: "100%",
    },
    parcelasLabel: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '22px'
    },
    parcelasValue: {
        textAlign: "left",
    },
    containerFooter: {
        display: 'flex',
    },
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        marginTop: '80px'
    },
}));