import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const TecladoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
            <path d="M 36.9375 2.84375 C 36.9375 5.9375 36.214844 7.304688 35.28125 8.0625 C 34.347656 8.820313 32.925781 9.058594 31.3125 9.28125 C 29.699219 9.503906 27.898438 9.710938 26.40625 10.90625 C 25.167969 11.898438 24.3125 13.539063 24.0625 16 L 4 16 C 1.800781 16 0 17.800781 0 20 L 0 40 C 0 42.199219 1.800781 44 4 44 L 46 44 C 48.199219 44 50 42.199219 50 40 L 50 20 C 50 17.800781 48.199219 16 46 16 L 26.09375 16 C 26.308594 14.0625 26.910156 13.066406 27.65625 12.46875 C 28.585938 11.722656 29.976563 11.503906 31.59375 11.28125 C 33.210938 11.058594 35.039063 10.839844 36.53125 9.625 C 38.023438 8.410156 38.9375 6.269531 38.9375 2.84375 Z M 4 18 L 46 18 C 47.117188 18 48 18.882813 48 20 L 48 40 C 48 41.117188 47.117188 42 46 42 L 4 42 C 2.882813 42 2 41.117188 2 40 L 2 20 C 2 18.882813 2.882813 18 4 18 Z M 5 22 L 5 26 L 9 26 L 9 22 Z M 11 22 L 11 26 L 15 26 L 15 22 Z M 17 22 L 17 26 L 21 26 L 21 22 Z M 23 22 L 23 26 L 27 26 L 27 22 Z M 29 22 L 29 26 L 33 26 L 33 22 Z M 35 22 L 35 26 L 39 26 L 39 22 Z M 41 22 L 41 26 L 45 26 L 45 22 Z M 5 28 L 5 32 L 12 32 L 12 28 Z M 14 28 L 14 32 L 18 32 L 18 28 Z M 20 28 L 20 32 L 24 32 L 24 28 Z M 26 28 L 26 32 L 30 32 L 30 28 Z M 32 28 L 32 32 L 36 32 L 36 28 Z M 38 28 L 38 32 L 45 32 L 45 28 Z M 5 34 L 5 38 L 9 38 L 9 34 Z M 11 34 L 11 38 L 15 38 L 15 34 Z M 17 34 L 17 38 L 33 38 L 33 34 Z M 35 34 L 35 38 L 39 38 L 39 34 Z M 41 34 L 41 38 L 45 38 L 45 34 Z"></path>
            </DefaultIcon>
        </>
    );
};
