import { Grid, Tooltip } from '@material-ui/core';
import { CardDocReferenciadoProps } from './card-doc-referenciado-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { DocReferenciadoNF } from './components/doc-referenciado-nf';
import { DocReferenciadoECF } from './components/doc-referenciado-ecf';
import { DocReferenciadoCTE } from './components/doc-referenciado-cte';
import { DocReferenciadoNFe } from './components/doc-referenciado-nfe';
import { DocReferenciadoNFP } from './components/doc-referenciado-nfp';
import { useStyles } from './card-doc-referenciados-styles';
import classNames from 'classnames';

export const CardDocReferenciado = ({
  model,
  onClick,
}: CardDocReferenciadoProps) => {
  const classes = useDefaultCardStyles();
  const valorReal = Object.entries(model).find(x => x[1])
  const getKey = Object.keys(model).filter(x => x === valorReal?.[0] || '')
  const myClasses = useStyles()
  const switchComp = (name: string) => ({
    refNFe: <DocReferenciadoNFe model={model} />,
    refCTe: <DocReferenciadoCTE model={model} />,
    refECF: <DocReferenciadoECF model={model} />,
    refNF: <DocReferenciadoNF model={model} />,
    refNFP: <DocReferenciadoNFP model={model} />,
  })[name] || <DocReferenciadoNFe model={model} />

  return (
    <>
      <DefaultCard
        onClick={() => {
          onClick(model.id);
        }}
      >
        <div className={classNames(classes.cardContent, myClasses.cardBorder)}>
          <Grid container className={classes.celulaGridFull} style={{ whiteSpace: 'break-spaces' }}>
              {switchComp(getKey[0])}
          </Grid>
        </div>
        <Tooltip arrow title="Editar Documento">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
