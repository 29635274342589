import { EnumStatusPerfil } from "model/enums/enum-status-perfil";
import { PerfilUsuarioModel } from ".";
import { guidEmpty } from "../../../../utils/guid-empty";


export class ContratoPerfilInfoModel {
  constructor(
    public id: string = '',
    public sistemaId: string = '',
    public sistemaImagem: string = '',
    public sistemaNome: string = '',
  ) { }
}

export class EmpresaPerfilInfoModel {
  constructor(
    public cnpj: string = '',
    public contratoId: string = '',
    public fantasia: string = '',
    public id: string = '',
    public nome: string = '',
    public sistemaId: string | null = null,
    public sistemaImagem: string | null = null,
    public sistemaNome: string | null = null,
  ) { }
}

export class EmpresaPerfilModel {
  constructor(
    public status: EnumStatusPerfil = EnumStatusPerfil.Convidado,
    public perfil: { id: string, nome: string } = { id: '', nome: '' },
    public id: string = '',
    public contrato: ContratoPerfilInfoModel = new ContratoPerfilInfoModel(),
    public empresa: EmpresaPerfilInfoModel = new EmpresaPerfilInfoModel(),
  ) { }
}

export class EmpresaUsuarioModel {
  constructor(
    public id: string = guidEmpty(),
    public usuario: PerfilUsuarioModel = new PerfilUsuarioModel(),
    public empresaPerfis: EmpresaPerfilModel[] = []
  ) { }
}
