import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormEntradaCadastroValidation = () => {

  const formEntradaCadastroValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        emitCnpj: Yup.string().required('Emitente é obrigatório'),
        finNFe: Yup.number().required('Finalidade é obrigatório'),
        natOp: Yup.string().required('Natureza da Operação é obrigatório')
      })
    )
  }, [])
  return {
    formEntradaCadastroValidationYup,
  }
}

