import { isEmpty } from "lodash";
import { DefaultModal } from "../components";
import { ModalProps } from "../utils";
import { SetoresCadastro } from "./setores-cadastro/setores-cadastro";
import { SetoresEdit } from "./setores-edit/setores-edit";

export const SetoresModal = (props: ModalProps) => {
    return (
        <DefaultModal
          minWidth="400px"
          open={props.openned || false}
          variant={'temporary'}
          anchor="right"
        >
          {props.openned && isEmpty(props.id) && <SetoresCadastro />}
          {props.openned && !isEmpty(props.id) && <SetoresEdit id={props.id} />}
        </DefaultModal>
      );
}