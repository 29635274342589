import * as Yup from 'yup';

export const useFormRecarregarDadosValidation = () => {

    const FormRecarregarDadosYupValidation = Yup.object().shape({
        senhaTecnica: Yup.string().required('A senha técnica é obrigatória.'),
    });

    return {
        FormRecarregarDadosYupValidation
    }
}

