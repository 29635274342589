import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetPedidoCupom() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedidoCupom = useCallback(
        (empresaId: string, pedidoId: string, qtdColunas: number) => {
            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/obter-cupom`,
                method: "GET",
                headers: {
                    qtdColunas: qtdColunas
                },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPedidoCupom,
    };
}
