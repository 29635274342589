import * as Yup from "yup";
import { useMemo } from "react";

export const useFormReceitaPreCadastroValidation = () => {
  const FormYupValidationPreReceita = useMemo(() => {
    return Yup.object().shape({
      receitaTipo: Yup.number().required('Informe o tipo da receita.'),
      dataHoraLancamento: Yup.date().required('Data e hora lançamento é obrigatória').typeError('Deve ser uma data e hora válida'),
      dataReceita: Yup.date().required('Data da eceita é obrigatória').typeError('Deve ser uma data válida'),
      numeroReceita: Yup.string().required('Numero da receita é obrigatória'),
      uf: Yup.string().required('UF é obrigatório'),
      cid: Yup.string().required('cid é obrigatório')
    });
  }, []);

  return {
    FormYupValidationPreReceita,
  };
};
