import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { MesasComandasHeader } from './components/mesas-comandas-header/mesas-comandas-header';
import { MesasComandasTabs } from './components/mesas-comandas-tabs/mesas-comandas-tabs';

export const MesasComandasPage = () => {
  const classes = useDefaultCadastroStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <MesasComandasHeader />
      </Grid>
      <Grid className={classes.list}>
        <MesasComandasTabs />
      </Grid>
    </Grid>
  );
};

export default MesasComandasPage;
