
import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetSetores() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSetores = useCallback(
        (empresaId: string, query?: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/setor${query ?? ''}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getSetores,
    };
}