import { Grid } from '@material-ui/core';
import { useStyles } from './selecionar-tipo-produto-styles';
import {
  TpProdutoCadastroFarmacia,
  TpProdutoCadastroIsFarmacia
} from 'data/mocks/tp-produto-mock';
import { IconCard } from 'views/components/cards/components';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { CestaProdutoIcon } from 'views/components/icons/cesta-produto-icon';
import { MedicamentoIcon } from 'views/components/icons/medicamento-icon';
import { useSessaoAtual } from 'services/app';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useThemeQueries } from 'views/theme';

interface SelecionarTipoProdutoProps {
  onConfirm: (tipo: EnumTipoProduto) => void;
}

export const SelecionarTipoProduto = ({
  onConfirm
}: SelecionarTipoProdutoProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();
  const { plano } = useSessaoAtual();

  const retornarIcon = (tp: EnumTipoProduto) => {
    switch (tp) {
      case EnumTipoProduto.Medicamento:
        return <MedicamentoIcon fill={theme.palette.secondary.main} />;
      case EnumTipoProduto.Produto:
        return <CestaProdutoIcon fill={theme.palette.secondary.main} />;
    }
  };
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const mockCadastro = isFarmaceutico
    ? TpProdutoCadastroIsFarmacia
    : TpProdutoCadastroFarmacia;

  return (
    <Grid container spacing={2}>
      {mockCadastro.map((item) => (
        <Grid item xs={4} className={classes.cardContainer}>
          <IconCard<EnumTipoProduto>
            icon={retornarIcon(item.Key)}
            model={item.Key}
            title={item.Value}
            onClick={() => onConfirm(item.Key)}
            isOpacity
          />
        </Grid>
      ))}
    </Grid>
  );
};
