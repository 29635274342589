import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    },
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& svg': {
        fill: '#000',
        height: 25,
        width: 25
      }
    }
  },
  greyText: {
    color: `${theme.palette.grey[700]} !important`,
    fontSize: '13px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 200,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      width: 75
    },
    '& svg': {
      fill: theme.palette.secondary.main,
      height: 25
    },
  },
  icon: {
    fill: theme.palette.secondary.main + '!important'
  },
  removeIcon: {
    fill: theme.palette.primary.main + '!important',
    '&:hover': {
      fill: theme.palette.error.main + '!important'
    }
  }
}));
