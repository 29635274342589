
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogConfiguracoesIniciais, DialogConfiguracoesIniciaisProps } from "views/components/dialog/dialog-configuracoes-iniciais/dialog-configuracoes-inicias";


export const AdicionarConfigIniciaisDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogConfiguracoesIniciaisProps>({
        aberto: false,
    });

    consoleDev('AdicionarConfigIniciaisDialog')

    const modalAlterado = useCallback(({ aberto }: any) => {
        setModalState({ 
            aberto: aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogConfigIniciais, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogConfigIniciais, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogConfiguracoesIniciais aberto={modalState.aberto}  />)}
           
        </>
    )
}