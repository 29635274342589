import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import CardParcelamentoImportacaoXML from 'views/components/cards/card-parcelamento-importacao-xml/card-produto-importacao-xml';
import { toDecimalString } from 'utils/to-decimal';

export default function FormEntradaCadastroNovoParcelamento({
  index,
  setParcelaSelected,
  parcelaSelected
}: any): React.ReactElement {
  const { getValues } = useFormContext();


  const parcelas = [1, 2, 3, 4, 5, 6, 7, 8];

  const handleParcelaSeleted = (parcela: number) => {
    setParcelaSelected(parcela);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Typography color="textPrimary" variant="caption">
          Valor Total
        </Typography>
        <Typography color="textPrimary" variant="h3">
          <strong>
            R$
            {toDecimalString(getValues(`infMov.pag.${index}.vPag`), 2)}
          </strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography color="textPrimary" variant="h6">
          Quantidade de Parcelas
        </Typography>

        {parcelas.map((parcela) => (
          <CardParcelamentoImportacaoXML
            key={parcela}
            model={getValues(`infMov.pag.${index}`)}
            parcela={parcela}
            onClick={() => handleParcelaSeleted(parcela)}
            selected={parcelaSelected === parcela}
          />
        ))}
      </Grid>
    </Grid>
  );
}
