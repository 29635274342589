import { VariaveisAmbiente } from 'config';
import { PedidoProdutoFechamentoParcialModelPost } from 'model/api/gestao/pedido/pedido-produto-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutFechamentoParcialPedido() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putFechamentoParcialPedido = useCallback(
    (
      empresaId: string,
      pedidoId: string,
      produtos: PedidoProdutoFechamentoParcialModelPost[]
    ) => {
      return invocarApi({
        url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/fechamento-parcial`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: produtos,
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putFechamentoParcialPedido
  };
}
