import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        background: theme.palette.common.white,
    },
    containerFinal: {
        display: "flex",
        justifyContent: "space-between",

    },
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainerChart: {
        "& .card": {
            height: '100%'
        },
    },
    containerCardDashboard: {
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between!important',
        },
    },
    containerCardDashboardChart: {
        padding: theme.spacing(1),
    },
    inputDate: {
        flex: 1,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: "1px solid #ccc",
        borderRadius: "4px",
        paddingLeft: "8px",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        "appearance": "none",
    },
    gridInputDate: {
        marginTop: theme.spacing(1),
        marginRight: '1px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btnPesquisa: {
        marginLeft: theme.spacing(1),
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: 0,
        "& svg": {
            margin: 0,
        }
    }
}));
