
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { ComandasEditModel } from "model/api/gestao/comanda/comanda-edit-model";

export function usePostComanda() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postComanda = useCallback(
    (comanda: ComandasEditModel, empresaId: string) =>
      invocarApi({
        url: `/v2/empresa/${empresaId}/comanda`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(comanda),
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    postComanda,
  };
}
