import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { ManifestosHeader } from './components/manifestos-header/manifestos-header';
import { ManifestoList } from './components/manifestos-list/manifestos-list';

const ManifestosPage = () => {
    const classes = useDefaultCadastroStyles();


    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <ManifestosHeader />
            </Grid>
            <Grid className={classes.list}>
                <ManifestoList />
            </Grid>
        </Grid>
    );
};

export default ManifestosPage;