import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeUtilClasses } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { UploadCargaPessoa } from 'model/api/gestao/upload-carga/upload-carga-model';
import { IndIEDestMock, SexoMock, TpCadastroMock } from 'data/mocks';
import { toDateString } from 'utils/to-date';

export const FormUploadCargaItemParceiroVizualizar = (props: {
  model: UploadCargaPessoa;
  loading: boolean;
}) => {
  const utilClasses = makeUtilClasses();
  const { control } = useForm<UploadCargaPessoa>({
    defaultValues: { ...props.model },
    criteriaMode: 'all',
  });

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {props.loading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <div className={props.loading ? utilClasses.controlLoading : ''}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="tipo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      disabled={props.loading}
                      label="Tipo"
                      variant="outlined"
                      {...field}
                      value={
                        TpCadastroMock.filter(
                          (x) => x.Key === field.value,
                        )[0].Value
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="documento"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      fullWidth
                      tipo="CNPJ_CPF"
                      manterMascara
                      disabled={props.loading}
                      label="CPF/CNPJ"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="sexo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      tipo="TEXTO"
                      manterMascara
                      allowSubmit
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Gênero"
                      {...field}
                      value={SexoMock.find(x => x.Key === field.value)?.Value || 'Não Informado'}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="dtNasc"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      tipo="TEXTO"
                      fullWidth
                      disabled={props.loading}
                      label="Data de Nascimento"
                      variant="outlined"
                      {...field}
                      value={
                        field.value ? toDateString(field.value) : 'Não Informado'
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="telefone"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      tipo={field.value ? "TELEFONE" : "TEXTO"}
                      fullWidth
                      disabled={props.loading}
                      label="Telefone"
                      variant="outlined"
                      {...field}
                      value={field.value ? field.value : 'Não Informado'}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      tipo="TEXTO"
                      fullWidth
                      disabled={props.loading}
                      label="E-mail"
                      variant="outlined"
                      {...field}
                      value={field.value || 'Não Informado'}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="razaoSocial"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Nome / Razão Social"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Endereço</Typography>
                <Divider style={{ background: 'black' }} />
              </Grid>
              <Grid item xs={6} md={4}>
                <Controller
                  name="endereco.cep"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="CEP"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={8}>
                <Controller
                  name="endereco.logradouro"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Logradouro"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="endereco.numero"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Número"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="endereco.complemento"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Complemento"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="endereco.indIEDest"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      readOnly
                      tipo="TEXTO"
                      manterMascara
                      allowSubmit
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Indicador IE"
                      {...field}
                      value={IndIEDestMock.find(x => x.Key === field.value)?.Value || 'Não Informado'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="endereco.bairro"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Bairro"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="endereco.xMun"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Município"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="endereco.uf"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="UF"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </>
  );
};
