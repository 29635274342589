import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPagamentoEditValidation = () => {

  const formPagamentoEditValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        // transportadora: Yup.string().required('Nome do PagamentoEdit é Obrigatório'),
        // descricao: Yup.string().required('Descrição é Obrigatório'),
      })
    )
  }, [])
  return {
    formPagamentoEditValidationYup,
  }
}

