import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetUltimaNumeracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getUltimaNumeracao = useCallback(
        (empresaId: string, mod: string = '65', serie: string = '500', tpAmb: string = '1') => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Movimento/ultima-numeracao?empresaId=${empresaId}&mod=${mod}&serie=${serie}&tpAmb=${tpAmb}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getUltimaNumeracao,
    };
}
