import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { toDateString } from "utils/to-date";
import { descMonth, getCurrentMonth } from "utils/get-date";
import { isEmpty } from "lodash";


export const ManifestosHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { abrirManifestosFiltroModal } = useFiltrosModais()
    const history = useHistory();

    const urlParams = new URLSearchParams(history.location.search)
    const filtros = {
        dInicio: urlParams.get('dInicial') || toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
        dFinal: urlParams.get('dFinal') || toDateString(new Date(), 'yyyy-MM-DD')!,
        statusManifesto: !isEmpty(urlParams.getAll('status')) ? urlParams.getAll('status').map(tp => Number(tp)) : [-1],
        integrado: urlParams.get('integrado') || 'false'
    }

    const leftArea = useCallback(() => (
        isMobile ?
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            /> :
            null
    ),
        [isMobile, abrirMenu]
    );

    const rightArea = useCallback(() => (
        <ButtonPrivateHeader
            icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={"Pesquisar"}
            onClick={() => {
                abrirManifestosFiltroModal({
                    dFinal: filtros.dFinal || '',
                    dInicial: filtros.dInicio || '',
                    statusManifesto: filtros.statusManifesto || [-1],
                    integrado: filtros.integrado || '-1'
                })
            }}
        ></ButtonPrivateHeader>
    ),
        [abrirManifestosFiltroModal, filtros.dFinal, filtros.dInicio, filtros.integrado, filtros.statusManifesto]
    );

    return (
        <>
            <PrivatePageHeader
                title="Manifestos"
                leftArea={leftArea()}
                rightArea={rightArea()}
            />
        </>
    );
};