import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const UltimoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 35.984375 3.9863281 A 1.0001 1.0001 0 0 0 35 5 L 35 24.984375 L 35 45 A 1.0001 1.0001 0 1 0 37 45 L 37 5 A 1.0001 1.0001 0 0 0 35.984375 3.9863281 z M 35 24.984375 A 1.0001 1.0001 0 0 0 34.707031 24.292969 L 14.707031 4.2929688 A 1.0001 1.0001 0 0 0 13.990234 3.9902344 A 1.0001 1.0001 0 0 0 13.292969 5.7070312 L 32.585938 25 L 13.292969 44.292969 A 1.0001 1.0001 0 1 0 14.707031 45.707031 L 34.707031 25.707031 A 1.0001 1.0001 0 0 0 35 24.984375 z" />
            </DefaultIcon>
        </>
    );
};
