import { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './documento-fiscal-list-styles';
import { DocumentoFiscalListData } from './documento-fiscal-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { useGetRetiradaDocumento } from 'data/api/gestao/relatorios/retirada-documento/get-retirada-documento';
import { RetiradaDocumentoModel } from 'model/api/gestao/retirada-documento/retirada-documento-model';
import { DialogOpcoesRetirada } from 'views/components/dialog/dialog-opcoes-retirada/dialog-opcoes-retirada';
import { usePostRetiradaDocumento } from 'data/api/gestao/relatorios/retirada-documento/post-retirada-documento';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useHistory } from 'react-router-dom';

interface DocumentoObject {
  id: string;
  link: string;
}

export const DocumentoFiscalList = () => {
  const classes = useStyles();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getRetiradaDocumento, carregando: carregangoGet } = useGetRetiradaDocumento()
  const [modal, setModal] = useState<boolean>(false)
  const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
  const { carregando: carregandoPost } = usePostRetiradaDocumento()
  const history = useHistory();
  const [object, setObject] = useState<DocumentoObject>({
    id: '',
    link: ''
  })
  const { addHandler, removeHandler } = useEventTools()
  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<RetiradaDocumentoModel>(),
  });

  const carregando = carregangoGet || carregandoPost

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const urlParams = new URLSearchParams(history.location.search)

    const filtros = {
        dInicial: urlParams.get('dInicial') || '',
        dFinal: urlParams.get('dFinal') || '',
    }

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<RetiradaDocumentoModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(filtros.dInicial)
          ? `&DInicial=${filtros.dInicial}T00:00:00.000Z`
          : '') +
        (!isEmpty(filtros.dFinal)
          ? `&DFnicial=${filtros.dFinal}T23:59:59.396Z`
          : '');
      try {
        const res = await getRetiradaDocumento(
          getEmpresaSelecionada()!.Documento,
          query,
          newPage,
        );
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.dInicial, filtros.dFinal, getRetiradaDocumento, getEmpresaSelecionada, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalCadastroAberto(openned)
  }, [])

  useEffect(() => {
    if (!modalCadastroAberto) {
      search(queryStatus.page);
    }

    addHandler(AppEventEnum.DocumentoFiscalModal, modalEdit)

    return () => removeHandler(AppEventEnum.DocumentoFiscalModal, modalEdit)
  }, [addHandler, modalCadastroAberto, modalEdit, queryStatus.page, removeHandler, search]);

  useEffect(() => {
    addHandler(AppEventEnum.RecarregarpaginaRetiradaDocumentos, search)
    return () => removeHandler(AppEventEnum.RecarregarpaginaRetiradaDocumentos, search)
  }, [addHandler, modalCadastroAberto, search, queryStatus.page, removeHandler]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string, link: string) => {
    setObject({ id: id, link: link })
    setModal(!modal)
  };

  const closeModal = () => {
    setModal(!modal)
  }

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <DocumentoFiscalListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
      {modal && (
        <DialogOpcoesRetirada
          link={object.link}
          id={object.id}
          openned={modal}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
