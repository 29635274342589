import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormApelidoValidation = () => {
  const FormApelidoValidationYup = useMemo(() => {
    return Yup.object().shape({
      apelido: Yup.string()
        .trim()
        .required('O apelido é obrigatório.')
        .min(3, 'No mínimo 3 caracteres.')
        .max(26, 'No máximo 26 caracteres.')
        .test({
            message: 'Não pode conter espaços',
            test: function (apelido) {
                const regexEspaco = /^\S+$/;

                return regexEspaco.test(apelido ?? '')
            }
        }),
    });
  }, []);

  return {
    FormApelidoValidationYup
  };
};
