import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  btnSticky: {
    position: "sticky",
    bottom: "0",
    zIndex: 2,
    backgroundColor: "#FFFFFF",
  },
  formContainer: {
    overflowX: 'hidden',
    flex: 1,
    padding: '0 16px'
  },
  infoIcon: {
    height: 20,
    width: 20,
    fill: theme.palette.primary.main
  }
}));