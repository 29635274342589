import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from "../../../../../../components/icons/informacao-icon";
import { ImpressoraModel } from "model/api/gestao/impressora/impressora-model";
import { CardImpressora } from "views/components/cards/card-impressora";

export interface ImpressorasGridProps {
  list: Array<ImpressoraModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const ImpressorasListData = (props: ImpressorasGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Impressora Encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((ncm, index) => {
          return (
            <CardImpressora
              selected={
                props.selectedList.filter((item) => item === ncm.id).length > 0 ? true : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={ncm}
              key={index}
            />
          );
        })}
    </>
  );
};
