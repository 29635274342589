import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useCadastros } from 'services/app';
import { useCallback } from 'react';

export interface ConversaoEmAndamentoProps {
  close?: () => any;
  carregando?: boolean;
  aberto: boolean;
}

export const DialogConversaoEmAndamento = ({
  close,
  ...props
}: ConversaoEmAndamentoProps) => {
  const classes = useModalStyles();
  const { fecharConversaoEmAndamento } = useCadastros();

  const onCloseClick = useCallback(() => {
    fecharConversaoEmAndamento();
  }, [fecharConversaoEmAndamento]);

  const handleClick = () => {
    fecharConversaoEmAndamento();
  };

  return (
    <>
      {props.aberto && (
        <DialogSaurus
          aberto={props.aberto}
          carregando={props.carregando}
          titulo="Conversão de Dados"
          tamanho="xs"
          isButtonTitleClose
          handleClickClose={onCloseClick}
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                  props.carregando ? classes.contentFormsLoading : undefined,
                )}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    color: '#6C6C6C',
                  }}
                >
                  Você possui uma conversão em{' '}
                  <strong
                    style={{ fontWeight: 800, lineHeight: 2, color: '#F44336' }}
                  >
                    Andamento
                  </strong>
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#B7B7B7',
                  }}
                >
                  Para enviar uma nova carga, aguarde o processamento da
                  anterior, e depois faça a confirmação ou cancelamento da
                  mesma.
                </Typography>
              </div>
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    disabled={props.carregando}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                  >
                    Aguardar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogSaurus>
      )}
    </>
  );
};
