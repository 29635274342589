import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetUsuarioValidarCadastro() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getUsuarioValidarCadastro = useCallback(
        (field: 'email' | 'apelido' | 'telefone', value: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/usuario/existe?${field}=${value}`,
                method: 'GET',
                enviarTokenUsuario: false,
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getUsuarioValidarCadastro,
    };
}
