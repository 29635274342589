import { KeyValueModel } from "model";
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";

export const TpLancamentoSessaoValorMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpLancamentoSessaoValor.ABERTURA, "Abertura"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.DESPESA, "Despesa"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SANGRIA, "Sangria"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.FECHAMENTO, "Fechamento"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.GORJETA, "Gorjeta"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.RECEITA, "Receita"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SUPRIMENTO, "Suprimento"),
];
