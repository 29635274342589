import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: 1,
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
        padding: '0 8px',
    },
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainer: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        margin: '2px 0',
        height: '2px'
    },
    containerInfo: {
        display: 'flex',
        height: '80px',
        padding: '5px 8px',
        alignItems: 'center',
    },
    containerIcon: {
        height: '40px',
        width: '40px',
        marginRight: '8px'
    },
    textTitle: {
        fontSize: '14px'
    },
    containerAcao: {
        display: 'flex',
        padding: theme.spacing(1)
    },
    buttonfechamento: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        }
    }
}));
