import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginBottom: theme.spacing(2),
    },
    loadingPesando: {
        height: 100
    }
}));
