import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowX: 'hidden',
    },
    header: {
        flex: '0 1 auto',
    },
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    btnRetaguarda: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
    },
    btnRoot: {
        backgroundColor: '#FFF',
        zIndex: 2,
        '&:hover': {
            backgroundColor: '#f5f5f5',
        }
    },
    drawerVersion: {
        bottom: 0,
        width: '100%'
    },
    versionControl: {
        flex: '3',
        paddingTop: 5,
        paddingBottom: 5,
        pointerEvents: "none",
        opacity: 0.6,
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        "& *": {
            marginLeft: 3,
            color: '#000',
            display: 'flex',
        },
        "& b": {
            fontSize: 10,
            fontWeight: "normal",
        },
        "& i": {
            fontSize: 10,
            fontWeight: "bold",
        }
    },
    btnsFixosContainer: {
        width: '100%',
        padding: 10,
        paddingTop: 0,
        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: 2,
        display: 'flex'

    },
    btnFixoContent: {
        borderRadius: 8,
        // color: '#FFF',
        // borderRadius: 0,
        textAlign: 'left',
        '& p': {
            fontWeight: 'normal',
        },
        '& svg':
        {
            marginLeft: theme.spacing(1),
        }
    },
    divisorBtns: {
        width: 10,
        height: '100%',
    },
    contentPerfil: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));
