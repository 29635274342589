import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetMesas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMesas = useCallback((queryBusca: string, empresaId: string, salaoId?: string) => {

        let queryPage = "";
        if (salaoId) {
            queryPage = "salaoId=" + salaoId.toString();
        }
        let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
            }${queryPage}${queryBusca}`;

        return invocarApi({
            url: `/v2/empresa/${empresaId}/salao/mesa${queryFinal}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            enviarTokenUsuario: true,
            baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
        })
    }, [invocarApi]);

    return {
        ...outrasPropriedades,
        getMesas,
    };
}
