import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePostProdutoVariacaoAtualizarSaldo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutoVariacaoAtualizarSaldo = useCallback(
    (
      produtoId: string,
      variacaoId: string,
      saldo: {
        qSaldo: number,
        depositoId: string,
        empresaId: string,
        movId: string
      }
    ) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/deposito`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(saldo),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoVariacaoAtualizarSaldo,
  };
}
