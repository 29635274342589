import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minWidth: '160px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 170,
    },
    minHeight: "140px",
    height: 190,
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",

  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
    "& span": {
      fontSize: "0.7em",
      textOverflow: 'ellipsis'
    },
    "& p": {
      fontSize: "1.0em",
      textOverflow: 'ellipsis'
    },
  },
  topContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
  },
  hourContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: theme.spacing(1)
  },
  codigoComanda: {
    fontSize: '20px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    maxWidth: '90px',
    overflow: 'hidden',
    textAlign: 'right'
  },
  pessoaLabel: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
      fontSize: '1em'
    }
  },
  pessoasContainer: {
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  textInfo: {
    fontWeight: 600
  },
  textBottomInfo: {
    color: theme.palette.grey[700],
    fontSize: '10px',
    textAlign: 'right',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fechamentoContainer: {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.warning.light,
    color: '#FFF'
  },
  importadoContainer: {
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[500],
    color: '#FFF'
  },
  btnContainer: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  editIcon: {
    width: 20
  }
}));

