import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%'
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  label: {
    fontSize: '0.9rem',
    color: '#BCBCBC',
    lineHeight: '14,1px'
  },
  subtitle: {
    fontWeight: 600,
    color: '#8b8b8b'
  },
  cancelar: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light
    }
  },
  inputValorCenter: {
    '& input': {
      fontWeight: 600,
      fontSize: 40,
      padding: '12px 14px',
      textAlign: 'center'
    }
  },
  container: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: '100%',
    background: '#fafafa',
    padding: theme.spacing(1),
    zIndex: 99999
  },
  fieldContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  depositoEstoque: {
    padding: '8px',
    overflowY: 'auto',
    maxHeight: () => window.innerHeight - 100
  }
}));
