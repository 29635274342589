import { makeStyles } from '@material-ui/core';
import { VariaveisAmbiente } from 'config';


export const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        width:'100%',
        boxSizing:'border-box',
        padding: theme.spacing(2    ),
        minHeight: 155,
        height: '100%',
        boxShadow: '0px 2.5px 4px 0px #28293D40',
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        cursor: 'pointer',
        '& svg': {
            fill: VariaveisAmbiente.environment.toLowerCase().includes('safra') ? theme.palette.secondary.main : theme.palette.primary.main,
            height:  52,
            width: 52,
            margin: 0
        },
        '& p': {
            fontSize: '14px'
        }
    },
    container: {
        padding: theme.spacing(2),
        height: '100%',
        background: theme.palette.background.default,
        flex: 1
    },
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    placeholder: {
        border: 'none',
        width:'100%',
        lineHeight: '50px',

        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        textAlign: 'center',
        textAlignLast: 'center',
        '& input':{
            justifyContent:'center',
            lineHeight: '50px',
            backgroundColor: 'transparent',
            fontFamily: 'Montserrat',
            color:'#666'
        },
        '& :after':{
            border:'none'
        },
        '& :focus':{
            outline:'none'
        }
    
    },
    inputText: {
        height: 100,
        border: 'none',
        outline: 'none'
    },
    title: {
        fontSize: '26px',
        lineHeight: '30px',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        fontWeight:500
    },
    inputArea: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 350,
        maxWidth: 395,
        width:'100%',
        borderBottom: '2px solid ' + theme.palette.secondary.main,
    },
    content: {
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    keyboardContainer: {
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .teclado': {
            flexGrow: 1
        }
    },
    buttonContainer:{
        display: 'flex',
        gridGap: theme.spacing(2),
        '& button': {
            flex: 1
        }
    },
    buttonFlexWrap: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        flexDirection: 'column',
    },
    hasValue: {
        fontSize: '35px',
        lineHeight: '50px',
        fontWeight: 600,
    },
    noValue: {
        fontSize: '16px',
        lineHeight: '50px',
    },
    cardTitle:{
        margin:0,
        fontWeight:500
    },
    hasValueInput:{
        fontSize:'32px'
    },
    hidden:{
        display:'none'
    },
    buttonsInputAlt:{
        flexGrow:1,
        alignItems:'flex-end'
    }
}));
