import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  btnSticky: {
    position: "sticky",
    bottom: "0",
    zIndex: 2,
    backgroundColor: "#FFFFFF",
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "normal",
  },

  divField: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  infoIcon: {
    height: 20,
    width: 20,
    fill: theme.palette.primary.main
  },
  tooltipText: {
    padding: theme.spacing(1)
  },
  accordionConfiguracoesPDV:{
    zIndex:1
  }
}));