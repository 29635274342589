import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { PessoaModel } from "model/api/gestao/pessoa/pessoa-model";
import { VariaveisAmbiente } from "config";

export function usePostPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPessoa = useCallback(
    (pessoa: PessoaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(pessoa),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postPessoa,
  };
}
