import { Tooltip, Typography } from '@material-ui/core';
import { CardCategoriaProps } from './card-categoria-props';
import { useCallback } from 'react';
import { EditarIcon } from '../../icons/editar-icon';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../../theme/util-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';

export const CardCategoria = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardCategoriaProps) => {
  const defaultCardClasses = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [theme.palette.success.main, theme.palette.error.main, model.ativo]);

  const descricao = model.ativo
    ? 'Categoria Habilitada'
    : 'Categoria Desabilitada';


  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={defaultCardClasses.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={defaultCardClasses.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={defaultCardClasses.cardImg} alt={model.nome} />
      );
    }
  };

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={defaultCardClasses.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={defaultCardClasses.cardContent}>
          <div className={defaultCardClasses.celulaGrid} style={{ marginLeft: 8 }}>
            {img(model.foto)}
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
            style={{ flexBasis: 150, flexGrow: 1, maxWidth: isMobile ? 130 : "100%" }}
          >
            <Typography color="textPrimary" variant="caption">
              Nome da Categoria
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>
                {model.nome !== null ? model.nome : 'Sem nome'}
              </strong>
            </Typography>
          </div>
          <div className={defaultCardClasses.celulaGrid}
            style={{ flexBasis: 100, maxWidth: isMobile ? 130 : "100%", flexGrow: 1 }}>
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.descricao !== null
                ? model.descricao.length === 0 ? model.nome
                  : model.descricao : 'Sem nome'}
            </Typography>
          </div>
          <Tooltip arrow title={model.cor ? model.cor : 'Não Definida'}>
            <div
              className={defaultCardClasses.celulaGrid}
              style={{ textAlign: 'center', marginRight: theme.spacing(2) }}
            >
              <Typography color="textPrimary" variant="caption">
                Cor
              </Typography>
              <p
                style={{
                  background: model.cor ? model.cor : '#FFF',
                }}
                className={defaultCardClasses.cardColor}
              ></p>
            </div>
          </Tooltip>
        </div>
        <Tooltip arrow title="Editar Categoria">
          <div className={defaultCardClasses.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
