import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-processando-pagamento-styles';
import { MovProcessandoPagamentoHeader } from './components/processando-pagamento-header';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useRef } from 'react';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useHistory, useLocation } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { useToastSaurus } from 'services/app';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';

interface LocationProps {
    modoPagamento: MovSimplesPagamentoModel;
    credenciais: CredenciamentoSafra
}

const MovProcessandoPagamentoPage = () => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const location = useLocation<LocationProps>()
    const history = useHistory();
    const { showToast } = useToastSaurus()
    const { iniciarPagamento, getMov, saveQtdPessoasPagamento, getQtdPessoasPagamento } = useMovAtual()
    const { modoPagamento, credenciais } = location.state
    const textTitle = useRef<HTMLParagraphElement>(null)
    const processarPagamento = useCallback(async () => {
        try {
            await iniciarPagamento(modoPagamento, true, credenciais);
            const novaMov = getMov();
            const objQtdPessoas = getQtdPessoasPagamento()
            const qtdePessoas = objQtdPessoas.pessoas ? objQtdPessoas.pessoas : 1
            if (novaMov!.vPago >= novaMov!.vNF) {
                if (!novaMov?.clienteIdentificado) {
                    history.push('/venda-simples/identificar-cliente');
                    return;
                }

                history.push('/venda-simples/finalizar-venda');
                return
            }
            const novaQtde = qtdePessoas > 1 ? qtdePessoas - 1 : 1
            saveQtdPessoasPagamento(novaQtde)
            history.push('/venda-simples/novo-pagamento')

        } catch (err: any) {
            history.push('/venda-simples/novo-pagamento')
            showToast('error', err.message)
        }
    }, [credenciais, getMov, getQtdPessoasPagamento, history, iniciarPagamento, modoPagamento, saveQtdPessoasPagamento, showToast])

    useEffect(() => {
        processarPagamento()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const feedBackPagamento = useCallback((text: any) => {
        if (text.detail && textTitle.current) {
            textTitle.current.textContent = text.detail
        }
    }, [])

    useEffect(() => {
        window.addEventListener('PointPayment.FeedBack', feedBackPagamento, false);

        return () => {
            window.removeEventListener('PointPayment.FeedBack', feedBackPagamento);
        };
    }, [feedBackPagamento]);

    return (
        <>
            <Grid
                className={`${classes.container} ${classes.containerBackgroundColor}`}
            >
                <Grid className={classes.header}>
                    <MovProcessandoPagamentoHeader />
                </Grid>
                <Grid className={classes.content}>
                    <Grid
                        className={
                            isMobile ? classes.infoContainer : classes.infoContainerPC
                        }
                    >
                        <Typography ref={textTitle} className={classes.textTitle}>
                            PROCESSANDO PAGAMENTO
                        </Typography>
                        <Grid
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 32,
                            }}
                        >
                            <LoadingFinalizacao />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MovProcessandoPagamentoPage;