
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormConfigEmpresaEditValidation = () => {

  const FormConfigEmpresaEditValidationYup = useMemo(() => {
    return Yup.object().shape({
    });
  }, [])

  return {
    FormConfigEmpresaEditValidationYup,
  }
}
