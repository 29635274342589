import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(() => ({
    formContainer: {
        marginTop: '60px',
        "& input": {
            fontSize: '2em',
            textAlign: 'center',
        }
    }
}));
