import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { useStyles } from './mov-definicoes-venda-styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { DefinicoesVendaHeader } from './components/definicoes-venda-header/definicoes-venda-header';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import {
  EditarIcon,
  GarcomIcon,
  HistoricoIcon,
  LixoIcon,
  OlhoAbertoIcon,
  VoltarIcon
} from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { NfceIcon } from 'views/components/icons/nfce-icon';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import {
  PutConfiguracoesPontosVendaProps,
  usePatchConfiguracoesPontosVenda
} from 'data/api/gestao/pontos-venda/put-configuracoes-pontos-venda';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { CircularLoading } from 'views/components';
import { usePDV } from 'services/app/hooks/pdv';
import { PontosVendaConfiguracoesModel } from 'model/api/gestao/pontos-venda/pontos-venda-configuracoes-model';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumMovModelo } from 'model';
import { LampadaIcon } from 'views/components/icons/lampada-icon.tsx';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { SwitchSaurus } from 'views/components/controles';
import { useThemeQueries } from 'views/theme';
import { DescontoIcon } from 'views/components/icons/desconto-icon';
import { AcrescimoIcon } from 'views/components/icons/acrescimo-icon';
import { DividirIcon } from 'views/components/icons/dividir-icon';
import { isPlanoControleMesasComandas } from 'utils/plano-utils';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { toDecimalString } from 'utils/to-decimal';
import { roundTo } from 'utils/round-to';
import { qualPercent } from 'utils/qual-percent';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { NfeIcon } from 'views/components/icons/nfe-icon';

interface LocationProps {
  callbackUrl: string;
}

const MovDefinicoesVendaPage = () => {
  const refTaxa = useRef<HTMLParagraphElement>(null);
  const { getRegistro, setRegistro } = useGestaoStorage();
  const { abrirVendaAcrescDesc, abrirDialogAlterarQuantidadeClientesVenda, abrirDialogTaxaServicoCarrinho } = useCadastros();
  // HOOKS
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const { getConfigByCod } = useContratoAtual()
  const { getConsumidor } = useCadastroPadrao();
  const { getMov, alterarTpMod } = useMovAtual();
  const { getPDV, procurarMeuPDV } = usePDV();
  const { push, goBack } = useHistory();
  const { state } = useLocation<LocationProps>();

  const getCancelados = useCallback(() => {
    const cancelados = getRegistro(
      GestaoStorageKeys.Cancelados,
      false
    ) as boolean;

    if (cancelados) {
      return cancelados;
    }

    return false;
  }, [getRegistro]);

  // STATES E REFS
  const [tipoVenda, setTipoVEnda] = useState<
    undefined | 'Venda Simples' | 'NFC-e' | 'NF-e'
  >(undefined);
  const [canceladosCarrinho, setCanceladosCarrinho] = useState(getCancelados());


  // CALL API
  const { patchConfigPontosVenda, carregando: loadingPutConfig } =
    usePatchConfiguracoesPontosVenda();

  // AUX
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();
  const cadastroClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const carregando = loadingPutConfig;
  const { isMobile, theme: { palette } } = useThemeQueries();

  const retTxtTaxa = useCallback(() => {
    const mov = getMov();

    let valorTaxa = 0;
    let valorProdutos = 0;

    mov?.produtos
      .filter((x) => x.ativo && x.indFin)
      .forEach((x) => {
        if (x.produtoId === getConfigByCod(EnumContratoConfig.ProdutoServico))
          valorTaxa += x.vFinal;

        if (x.cobraTaxaServico)
          valorProdutos += x.vFinal;
      });

    const valorTotal = valorProdutos ?? 0;
    return `R$ ${toDecimalString(roundTo(valorTaxa))} (${roundTo(qualPercent(valorTaxa, valorTotal), 0) ?? 0
      }%)`;
  }, [getConfigByCod, getMov]);

  const saveChangesConfiguracoes = useCallback(
    async (configuracoes: Array<PontosVendaConfiguracoesModel>, id: string) => {
      const ret = await patchConfigPontosVenda(
        configuracoes.map(
          (x) => new PutConfiguracoesPontosVendaProps(x.id, x.cod, x.vConfig)
        ),
        getEmpresaSelecionada()?.Id || '',
        id
      );
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [patchConfigPontosVenda, getEmpresaSelecionada]
  );

  const handleClickTypeOrder = useCallback(
    async (type: 'Venda Simples' | 'NFC-e' | 'NF-e') => {
      try {
        const config =
          getPDV()?.configuracoes.map((c) => {
            if (c.cod === EnumPDVConfigCod.ModeloVenda) {
              if (c.vConfig === type) {
                return c;
              }

              return {
                ...c,
                vConfig: type
              };
            }

            return c;
          }) ?? [];

        await saveChangesConfiguracoes(config, getPDV()?.id ?? '');
        setTipoVEnda(type);
        await alterarTpMod(
          type === 'Venda Simples'
            ? EnumMovModelo.ORCAMENTO
            : type === 'NFC-e'
              ? EnumMovModelo.NFCE
              : EnumMovModelo.NFE
        );
        procurarMeuPDV(true);
      } catch (err: any) {
        showToast('error', err.message)
      }
    }, [getPDV, saveChangesConfiguracoes, alterarTpMod, procurarMeuPDV, showToast]);

  const setCancelados = useCallback(
    (cancelados: boolean) => {
      setRegistro(GestaoStorageKeys.Cancelados, cancelados, false);
      setCanceladosCarrinho(cancelados);
    },
    [setRegistro]
  );

  useEffect(() => {
    const config = getPDV()?.configuracoes.find(
      (c) => c.cod === EnumPDVConfigCod.ModeloVenda
    );
    setTipoVEnda((config?.vConfig as 'Venda Simples' | 'NFC-e') ?? undefined);
  }, [getPDV]);

  const handleCancel = useCallback(async () => {
    const consumidorPadrao = await getConsumidor();
    if (
      consumidorPadrao &&
      getMov()!.produtos.length <= 0 &&
      (consumidorPadrao.id === getMov()!.cliente?.id ||
        consumidorPadrao?.cpfcnpj === getMov()!.cliente?.cpfcnpj)
    ) {
      showToast('info', 'Não há produtos na venda.');
      return;
    }
    push('/venda-simples/cancelar');
  }, [getConsumidor, getMov, push, showToast]);

  const validarRota = (termo: string) => {
    return state?.callbackUrl.toLowerCase().includes(termo.toLowerCase()) ?? '/venda-simples/catalogo';
  };

  const handleClickEdit = useCallback(() => {
    push('/venda-simples/identificar-cliente');
  }, [push]);

  const movProdAlterado = useCallback((any: any) => {
    if (refTaxa.current) {
      refTaxa.current.textContent = retTxtTaxa();
    }
  },
    [retTxtTaxa]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    return () => {
      removeHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    }
  }, [addHandler, removeHandler, movProdAlterado])

  return (
    <Grid className={cadastroClasses.root} style={{ background: '#fff' }}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={cadastroClasses.header}>
        <DefinicoesVendaHeader />
      </Grid>
      <Grid className={cadastroClasses.list}>
        <Grid className={classes.containerPessoa}>
          <Grid className={classes.containerIconPessoa}>
            <PessoaIcon tipo="PRIVATE_HEADER" />
          </Grid>
          <Grid className={classes.infoContainer}>
            <Grid container>
              <Typography className={classes.labelPessoa}>
                Nome:{' '}
              </Typography>
              <Typography className={classes.labelInfoPessoa}>
                {getMov()?.cliente?.nome}
              </Typography>
            </Grid>
            <Grid container>
              <Typography className={classes.labelPessoa}>
                Documento:{' '}
              </Typography>
              <Typography className={classes.labelInfoPessoa}>
                {getMov()?.documento ? formatarCPFCNPJ(getMov()?.documento || '') : ''}
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={classes.containerIconEditar}
            onClick={handleClickEdit}
          >
            <EditarIcon
              tipo="GERAL"
              fill={palette.primary.main}
              style={{ width: 26, height: 26 }}
            />
          </Button>
        </Grid>

        <Grid className={classes.content}>
          {/* VENDA */}
          <Grid className={classes.sessaoHeader}>
            <Grid container style={{ marginBottom: 8, alignItems: 'center' }}>
              <ImpressoraIcon
                tipo="GERAL"
                style={{ width: 26, height: 26, marginRight: 8 }}
                fill={palette.primary.main}
              />
              <Typography className={classes.sessaoText}>
                Tipo de Venda
              </Typography>
            </Grid>
            <Divider />
          </Grid>
          <Grid
            container
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <CarrinhoIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Venda simples
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === 'Venda Simples'}
              onClick={() => handleClickTypeOrder('Venda Simples')}
              control={<SaurusRadio />}
            />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <NfceIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Nota Fiscal Consumidor
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === 'NFC-e'}
              onClick={() => handleClickTypeOrder('NFC-e')}
              control={<SaurusRadio />}
            />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <NfeIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Nota Fiscal Eletrônica
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === 'NF-e'}
              onClick={() => handleClickTypeOrder('NF-e')}
              control={<SaurusRadio />}
            />
          </Grid>

          {/* Opções */}
          <Grid className={classes.sessaoHeader}>
            <Grid
              container
              style={{ marginBottom: 8, marginTop: 16, alignItems: 'center' }}
            >
              <LampadaIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.primary.main}
              />
              <Typography className={classes.sessaoText}>
                Opções
              </Typography>
            </Grid>
            <Divider />
          </Grid>

          {validarRota('carrinho') && (
            <Grid
              container
              style={{
                marginBottom: 8,
                alignItems: 'center',
                flexWrap: 'nowrap',
                height: 50
              }}
            >
              <Grid container alignItems="center">
                <OlhoAbertoIcon
                  tipo="GERAL"
                  style={{ width: 24, height: 24, marginRight: 8 }}
                  fill={palette.secondary.main}
                />
                <Typography className={classes.sessaoTextItem}>
                  Visualizar Cancelados
                </Typography>
              </Grid>
              <Grid className={classes.gridCheck}>
                <SwitchSaurus
                  variant={isMobile ? 'DARK' : 'LIGHT'}
                  checked={canceladosCarrinho}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCancelados(event.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          )}

          {validarRota('pagamento') && (
            <>
              <Grid
                container
                style={{
                  marginBottom: 8,
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  cursor: 'pointer',
                  height: 50
                }}
                onClick={() => abrirVendaAcrescDesc('desc')}
              >
                <Grid container alignItems="center">
                  <DescontoIcon
                    tipo="GERAL"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                    fill={palette.secondary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Desconto
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.contentOpcao}
                onClick={() => abrirVendaAcrescDesc('acresc')}
              >
                <Grid container alignItems="center">
                  <AcrescimoIcon
                    tipo="GERAL"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                    fill={palette.secondary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Acréscimo
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.contentOpcao}
                onClick={() => abrirDialogAlterarQuantidadeClientesVenda()}
              >
                <Grid container alignItems="center">
                  <DividirIcon
                    tipo="GERAL"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                    fill={palette.secondary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Dividir Conta
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.contentOpcao}
                onClick={() => {
                  push('/venda-simples/pagamentos')
                }}
              >
                <Grid container alignItems="center">
                  <HistoricoIcon
                    tipo="GERAL"
                    style={{ width: 24, height: 24, marginRight: 8 }}
                    fill={palette.secondary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Histórico de Pagamento
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {isPlanoControleMesasComandas(plano?.plano) && validarRota('carrinho') && (
            <Grid
              container
              className={classes.contentOpcao}
              onClick={() => {
                if (
                  (
                    getMov()?.produtos.filter(
                      (x) =>
                        x.produtoId ===
                        getConfigByCod(EnumContratoConfig.ProdutoServico)
                    ) ?? []
                  ).length <= 0
                ) {
                  showToast('info', 'Não há taxa.');
                  return;
                }
                abrirDialogTaxaServicoCarrinho()
              }}
            >
              <Grid
                container
                alignItems="center"
              >
                <GarcomIcon
                  tipo="GERAL"
                  style={{ width: 24, height: 24, marginRight: 8 }}
                  fill={palette.secondary.main}
                />
                <Typography
                  className={classes.sessaoTextItem}
                >
                  Taxa de Serviço
                </Typography>
              </Grid>
              <Grid
                style={{ width: 100, display: 'flex', justifyContent: 'flex-end' }}
              >
                <div>
                  <Typography
                    className={classes.sessaoTextItem}
                    align="right"
                    style={{ fontWeight: 600, fontSize: 12 }}
                  >
                    Valor
                  </Typography>
                  <Typography
                    ref={refTaxa}
                    className={classes.sessaoTextItem}
                    style={{ fontWeight: 600, fontSize: 12 }}
                    color="secondary"
                  >
                    {retTxtTaxa()}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            className={classes.contentOpcao}
            onClick={handleCancel}
          >
            <Grid container alignItems="center">
              <LixoIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={'red'}
              />
              <Typography
                className={classes.sessaoTextItem}
                style={{ color: 'red' }}
              >
                Cancelar venda
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ margin: 16 }}>
        <Button fullWidth variant="contained" color="primary" onClick={() => {
          goBack()
        }}>
          <VoltarIcon tipo="BUTTON_PRIMARY" />
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
};

export default MovDefinicoesVendaPage;
