import { EnumBalcaoSalao } from "model/enums/enum-balcao-salao";
import { EnumStatusSalao } from "model/enums/enum-status-salao";
import { guidEmpty } from "../../../../utils/guid-empty";

export class SaloesEditFormModel {
  constructor(
    public id: string = guidEmpty(),
    public descricao: string = '',
    public status: EnumStatusSalao = EnumStatusSalao.ATIVO,
    public balcao: EnumBalcaoSalao = EnumBalcaoSalao.UTILIZA
  ) { }
}
