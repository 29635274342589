import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { BluetoothIcon } from '../../../../../components/icons/bluetooth-icon';
import { useStyles } from './accordion-bluetooth-styles';
import { useState } from 'react';
import { useDevice } from 'services/app/hooks/device';

export const AccordionBluetooth = () => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);

  // PROVIDERS
  const { launchBluetoothSettings, carregando } = useDevice();

  // AUX
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const classes = useStyles();

  return (
    <AccordionSaurus
      labelPrimary={`Bluetooth`}
      tituloChildren={
        <BluetoothIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classNames(classesModal.root, classes.root)}>
        <div className={classesModal.content}>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', fontWeight: '500' }}
                >
                  Para sincronizar com outro aparelho aperte no botão, assim irá
                  abrir uma tela do Android para poder estar realizando a
                  sincronização.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  disabled={carregando}
                  onClick={() => launchBluetoothSettings()}
                  variant="contained"
                  color="primary"
                >
                  Acessar configurações bluetooth
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </AccordionSaurus>
  );
};
