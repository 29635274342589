import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { PessoaContatosModel } from "model/api/gestao/pessoa";
export function usePostContatoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postContatoPessoa = useCallback(
    (contato: PessoaContatosModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${contato.pessoaId}/contato`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data:JSON.stringify(contato),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postContatoPessoa,
  };
}
