import { Button, Dialog, Fade, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { forwardRef, useCallback } from 'react';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import { useStyles } from './dialog-barcode-cam-transferir-itens-styles';
import CloseIcon from '@material-ui/icons/Close';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { CircularLoading } from 'views/components/utils';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { PedidoTransferirProduto } from 'model/api/gestao/pedido/pedido-transferir-produto';
import { usePutPedidoProdutoTransferir } from 'data/api/gestao/pedido-produto/put-pedido-produto-transferir';
import { useHistory } from 'react-router-dom';
import { PedidoAlterarVendedorProduto } from 'model/api/gestao/pedido/pedido-alterar-vendedor';
import { usePutPedidoProdutoAlterarVendedor } from 'data/api/gestao/pedido-produto/put-pedido-produto-alterar-vendedor';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumComandas } from 'model/enums/enum-comandas';

interface DialogBarCodeProps {
  openned?: boolean;
  loading?: boolean;
  closeModal: () => void;
  zIndex?: string;
  tamanho?: DialogProps['maxWidth'];
  idsProdutos: string[];
  dialogTransferir: (valor: boolean) => void;
  pedido: PedidoModel;
  vendedor: PessoaModel | null;
}

export const DialogBarCodeCamComandaTransferirItens = ({
  closeModal,
  idsProdutos,
  pedido,
  vendedor,
  dialogTransferir,
  ...props
}: DialogBarCodeProps) => {
  const classes = useStyles();

  const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Fade timeout={500} ref={ref} {...props} />;
  });

  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { putPedidoProdutoTransferir, carregando: PutTransferirProdutos } =
    usePutPedidoProdutoTransferir();
  const {
    putPedidoProdutoAlterarVendedor,
    carregando: PutAlterarVendedorProdutos,
  } = usePutPedidoProdutoAlterarVendedor();

  const carregando =
    carregandoGetPedidos || PutTransferirProdutos || PutAlterarVendedorProdutos;

  const history = useHistory();

  const handleClickTransferirItens = useCallback(
    async (codigo: string) => {
      try {
        let comanda = null;
        let mesa = null;
        let queryBusca = '';

        // valido o tipo de trabalho
        if (
          getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          mesa = await TouchoneDBPrimary.mesas.get({ codigo: codigo });

          if (!mesa) {
            throw new Error(`Mesa com o código ${codigo} não encontrada.`);
          }

          if (mesa && mesa.status?.codigo !== EnumMesas.ATIVO) {
            throw new Error(
              `A mesa com o código ${codigo} está com status de INATIVA.`,
            );
          }

          queryBusca = `mesaId=${mesa.id}`;
        } else {
          comanda = await TouchoneDBPrimary.comandas.get({
            codigoComanda: codigo,
          });

          if (!comanda) {
            throw new Error(`Comanda com o código ${codigo} não encontrada.`);
          }

          if (comanda && comanda.status?.codigo !== EnumComandas.ATIVO) {
            throw new Error(
              `Comanda com o código ${codigo} está com status de inativo.`,
            );
          }

          queryBusca = `comandaId=${comanda.id}`;
        }

        const responsePedidos = await getPedidoSalao(
          getEmpresaSelecionada()!.Id,
          queryBusca,
        );

        if (responsePedidos.erro) {
          throw new Error(responsePedidos.erro);
        }

        const pedidos =
          (responsePedidos?.resultado?.data as PedidoModel[]) ?? [];

        if (pedidos.length === 0) {
          throw new Error(
            `A ${
              getConfiguracoesMesaEComanda()?.tipoTrabalho ===
              EnumTipoTrabalho.MESA
                ? 'mesa'
                : 'comanda'
            } com o código ${codigo} não possui um pedido em andamento.`,
          );
        }

        const produtosParaTransferencia = new PedidoTransferirProduto();
        produtosParaTransferencia.produtosIds = idsProdutos;

        if (
          getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          produtosParaTransferencia.destino.mesaId = mesa!.id;
        } else {
          produtosParaTransferencia.destino.comandaId = comanda!.id;
        }

        const respostaPut = await putPedidoProdutoTransferir(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          produtosParaTransferencia,
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast(
          'success',
          `Os produtos selecionados foram transferidos para a ${
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.MESA
              ? 'mesa'
              : 'comanda'
          } com o código: ${codigo} com sucesso.`,
        );

        history.goBack();
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      getEmpresaSelecionada,
      getPedidoSalao,
      getConfiguracoesMesaEComanda,
      history,
      idsProdutos,
      pedido.id,
      putPedidoProdutoTransferir,
      showToast,
    ],
  );

  const handleClickAlterarVendedor = useCallback(async () => {
    try {
      if (!vendedor) {
        throw new Error('O vendedor não foi identificado.');
      }

      const produtosAlterarVendedor = new PedidoAlterarVendedorProduto(
        idsProdutos,
        vendedor.id,
        vendedor.nome,
      );

      const respostaPut = await putPedidoProdutoAlterarVendedor(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        produtosAlterarVendedor,
      );

      if (respostaPut.erro) {
        throw new Error(respostaPut.erro);
      }

      showToast(
        'success',
        `Os produtos selecionados foram transferidos para o ${vendedor.nome} com sucesso.`,
      );

      if (
        getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA
      ) {
        history.push(`/venda-simples/visualizar-pedido/${pedido.mesaId}`);
      } else {
        history.push(`/venda-simples/visualizar-pedido/${pedido.comandaId}`);
      }
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    getEmpresaSelecionada,
    getConfiguracoesMesaEComanda,
    history,
    idsProdutos,
    pedido.comandaId,
    pedido.id,
    pedido.mesaId,
    putPedidoProdutoAlterarVendedor,
    showToast,
    vendedor,
  ]);

  const handleSubmit = useCallback(
    (valor: string) => {
      vendedor
        ? handleClickAlterarVendedor()
        : handleClickTransferirItens(valor);
    },
    [handleClickAlterarVendedor, handleClickTransferirItens, vendedor],
  );

  return (
    <Dialog
      maxWidth={props.tamanho || 'md'}
      open={props.openned || false}
      fullWidth
      keepMounted
      className={classes.root}
      disableEscapeKeyDown
      TransitionComponent={Transition}
      ref={(el: any) => {
        if (el && (props.zIndex || '').length > 0) {
          el.style.setProperty('z-index', props.zIndex, 'important');
        }
      }}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <div style={{ padding: 8 }}>
        <Typography
          style={{
            textAlign: 'center',
            margin: '0 0 24px 0',
            fontWeight: '600',
            background: 'rgb(0 0 0 / 50%)',
            padding: 12,
            color: '#FFF',
            borderRadius: 8,
          }}
        >
          Posicione o código de barras na marcação
        </Typography>
        <div style={{ borderRadius: 8, overflowY: 'hidden' }}>
          {props.openned && (
            <Scanner onScan={handleSubmit} inicialOrSecundario={false} />
          )}
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            padding: '8px 0',
          }}
        >
          <Button
            variant="text"
            style={{
              fontWeight: '600',
              background: 'rgb(0 0 0 / 50%)',
              color: '#FFF',
            }}
            onClick={() => {
              dialogTransferir(true);
              closeModal();
            }}
          >
            Digite o código da comanda
          </Button>
        </div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
            style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
          />
        </div>
      </div>
    </Dialog>
  );
};
