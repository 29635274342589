import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { VendaCompletaModel, VolTranspModel } from "model/api/gestao/venda/venda-completa-model";
import { VolumeTransportadoConteudo } from "./volume-transportado-conteudo/volume-transportado-conteudo";

export interface VolumeTransportadoModalProps extends ModalProps {
  mov: VendaCompletaModel
  volume: VolTranspModel
}

export const VolumeTransportadoModal = (props: VolumeTransportadoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && (
        <VolumeTransportadoConteudo volume={props.volume} mov={props.mov} />
      )}
    </DefaultModal>
  );
};
