import { Tooltip, Typography } from '@material-ui/core';
import { CardProdutoFiscalProps } from './card-produto-fiscal-props';
import { useCallback } from 'react';

import { EditarIcon } from '../../icons/editar-icon';

import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CfopMock } from '../../../../data/mocks/cst-cfop-mock';
import { CstIcmsMock } from '../../../../data/mocks/cst-icms-mock';
import { CstPisCofinsMock } from '../../../../data/mocks/cst-pis-mock';
import { useThemeQueries } from '../../..';

export const CardProdutoFiscal = ({
  model,
  onClick,
  onCheck,
  selected
}: CardProdutoFiscalProps) => {
  const classes = useDefaultCardStyles();
  const { theme } = useThemeQueries();
  const cfop = CfopMock.find((x) => x.Key === String(model.cfop));
  const icms = CstIcmsMock.find((x) => x.Key === model.icmsCst);
  const pis = CstPisCofinsMock.find((x) => x.Key === model.pisCst);
  const cofins = CstPisCofinsMock.find((x) => x.Key === model.cofinsCst);
  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [theme.palette.error.main, model.ativo, theme.palette.success.main]);
  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip
          arrow
          title={model.ativo ? 'Habilitado' : 'Desabilitado'}
          placement="right"
        >
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div
            className={classes.celulaGridFull}
            style={{ maxWidth: 200, minWidth: 200 }}
          >
            <Typography color="textPrimary" variant="caption">
              Nome do Grupo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.descricao}</strong>
            </Typography>
          </div>
          <div className={classes.celulaGridFull} style={{ maxWidth: 200 }}>
            <Typography color="textPrimary" variant="caption">
              CFOP
            </Typography>
            <Tooltip arrow title={cfop?.Value || ''}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {cfop?.Value || ''}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.celulaGrid} style={{ maxWidth: 200 }}>
            <Typography color="textPrimary" variant="caption">
              CST/CSOSN ICMS
            </Typography>
            <Tooltip arrow title={icms?.Value || ''}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {icms?.Value || ''}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.celulaGrid} style={{ maxWidth: 150 }}>
            <Typography color="textPrimary" variant="caption">
              CST PIS
            </Typography>
            <Tooltip arrow title={pis?.Value || ''}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {pis?.Value || ''}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.celulaGrid} style={{ maxWidth: 150 }}>
            <Typography color="textPrimary" variant="caption">
              CST COFINS
            </Typography>
            <Tooltip arrow title={cofins?.Value || ''}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {cofins?.Value || ''}
              </Typography>
            </Tooltip>
          </div>
        </div>
        <Tooltip arrow title="Editar ProdutoFiscal">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
