import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    text: {
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    containerButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonChanged: {
        width: '55px',
        height: '55px',
        padding: '0!important',
        borderRadius: '50%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    prefix: {
        fontSize: '38px',
        fontWeight: 600,
    },
    textPerson: {
        fontSize: '40px',
        lineHeight: '32px',
        fontWeight: 700
    }
}));
