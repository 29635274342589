import { useMemo } from 'react';
import * as Yup from 'yup';
import { EnumIndIEDest } from 'model';


export const useFormPessoaEnderecoValidation = () => {

  const FormPessoaEnderecoValidationYup = useMemo(() => {

    return (
      Yup.object().shape({
        ierg: Yup.string().test({
          message: 'Inscrição Estadual é obrigatório',
          test: (value, context: any) => {
            if (context.parent.indIEDest === EnumIndIEDest.CONTRIBUINTE_ICMS) {
              if (!value) {
                return false
              }
            }
            return true
          }
        }),
        indIEDest: Yup.number()
      })
    )
  }, [])
  return {
    FormPessoaEnderecoValidationYup,
  }
}

