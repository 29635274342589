import { guidEmpty } from "utils/guid-empty";
import { ModuloPlanoModel, ModuloPlanoPutModel } from "./plano-modulo-model";
import { EnumTipoCobranca } from "model/enums/enum-tipo-combranca";

export class ContratoModuloModel {
  constructor(
      public id: string = guidEmpty(),
      public codigo: number = 0,
      public nome: string = '',
      public valorOriginal: number = 0,
      public valorCustoOriginal: number = 0,
      public quantidadeContratada: number = 0,
      public valorFinal: number = 0,
      public percAdicional: number = 0,
      public moduloId: string = '',
      public contratoId: string = '',
      public quantidadeMaxima: number = 0,
      public quantidadeMinima: number = 0,
      public quantidadePadrao: number = 0,
      public valorCusto: number = 0,
      public percAdicionalCusto: number = 0,
      public codModuloBaseCalculo: string = '',
      public tipoCalculo: number = 0,
      public tipoControle: number = 0,
      public avisoValor: boolean = false,
      public valorAnterior?: number,
      public editavel: boolean = false,
  ) { }
}

export class PagamentoContratoModel{
  constructor(
      public id: string = guidEmpty(),
      public nome: string = '',
  ){}
}

export class AmbienteContratoModel{
  constructor(
      public id: string = guidEmpty(),
      public nome: string = '',
      public revendaId: string = ''
  ){}
}

export class PlanoModel {
  constructor(
    public id: string = guidEmpty(),
    public revendaId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public diasValidade: number = 0,
    public sistemaId: string = guidEmpty(),
    public sistemaNome: string = '',
    public valorDe: number = 0,
    public status: number = 0,
    public valorPor: number = 0,
    public modulos: ModuloPlanoModel[] = new Array<ModuloPlanoModel>()
  ) { }
}

export class ModuloModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public tipoCalculo: number = 0,
    public codModuloBaseCalculo: string = '',
    public codigo: number = 0,
    public descricao: string = '',
    public quantidadeMinima: number = 0,
    public quantidadeMaxima: number = 0,
    public quantidadePadrao: number = 0,
    public tipoControle: number = 0,
    public tipoTerminal: string = ''
  ) { }
}


export class PrecoModulos {
  constructor(
    public id: string = guidEmpty(),
    public valor: number = 0,
    public valorCustoOriginal: number = 0,
    public percAdicional: number = 0,
    public valorCusto: number = 0,
    public percAdicionalCusto: number = 0,
    public modulo: ModuloModel = new ModuloModel()
  ) { }
}


export class TabelaPrecoContratoModel{
  constructor(
      public tabelaPrecoId: string = guidEmpty(),
      public nome: string = '',
      public precoModulos: PrecoModulos[] = []
  ){}
}

export class PrecoContratoModel{
  constructor(
      public id: string = guidEmpty(),
      public nome: string = '',
      public tabelas: TabelaPrecoContratoModel[] = []
  ){}
}


export class ContratoDadosModel {
  constructor(
      public precos: PrecoContratoModel = new PrecoContratoModel(),
      public pagamentos: PagamentoContratoModel[] = [],
      public planos: PlanoModel[] = [],
      public ambientes: AmbienteContratoModel[] = [],
      public tipoCobrancaRevenda: EnumTipoCobranca.ClienteFinal = EnumTipoCobranca.ClienteFinal,
  ) { }
}

export class PlanoFormModel {
  constructor(
    public id: string = guidEmpty(),
    public valorCalculado: number = 0,
    public valor: number = 0,
    public tabelaPrecoId: string = '',
    public sistemaId: string = '',
    public modulos: ModuloPlanoModel[] = new Array<ModuloPlanoModel>(),
  ) { }
}

export class PlanoPutModel {
  constructor(
      public id: string = guidEmpty(),
      public clienteId: string = guidEmpty(),
      public sistemaId: string = guidEmpty(),
      public ambienteId: string = guidEmpty(),
      public planoId: string | null = null,
      public tabelaPrecoId: string = guidEmpty(),
      public formaPagamentoId: string | null = null,
      public melhorDia: number | null = null,
      public dataExpiracao: string | null = null,
      public valorCalculado: number = 0,
      public valor: number = 0,
      public modulos: ModuloPlanoPutModel[] = [],
  ) { }
}

//ESSE MODEL ESTÁ FALTANDO ALGUMAS TIPAGENS PQ N QUIS TRAZER TUDO DO LICENCIAMENTO, É SÓ PRA USAR EM UM REF
export class PlanoGetModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public ambienteId: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public planoId: string = guidEmpty(),
        public tabelaPreco: string = '',
        public melhorDia: number = 0,
        public dataExpiracao: string = '',
        public sistemaNome: string = '',
        public ambienteNome: string = '',
        public valorVigente: number = 0,
        public valorCalculado: number = 0,
        public valorCusto: number = 0,
        public dataFaturaMaisAntiga: string = '',
        public dataUltimaFatura: string = '',
        public dataAdesao: string = '',
        public valorUltimaFatura: number = 0,
        public ativo: boolean = true,
        public dataCancelamento: string = '',
        public usuarioCancelamento: string = '',
        public motivoCancelamento: string = '',
        public dominio: string = '',
        public plano: PlanoModel = new PlanoModel(),
        public modulos: ContratoModuloModel[] = [],
        public formaPagamentoId: string = guidEmpty(),
        public revendaId: string = guidEmpty(),
        public tipoCobrancaRevenda: number = 0,
        public formaPagamentoNome: string = '',
        public dadosTela: ContratoDadosModel = new ContratoDadosModel(),
    ) { }
}