import { ManifestoModel } from "model/api/gestao/manifesto/manifesto-model";

export const getNNFManifesto = (model: ManifestoModel) => {
    let chave = model.chave;
    if (!chave || chave.length < 40 || !chave.includes('NFe')) return null; // Verifica se a chave é válida
  
    let numero, serie;
    chave = chave.split(model.cnpj)[1]
  
    chave = chave.substring(2)
  
    serie = chave.substring(0, 3)
    numero = chave.substring(3, 12)
    let removerZeros = true;
    numero = Array.from(numero).filter(x => {
      if (x !== '0') {
        removerZeros = false;
        return true
      }
      if (removerZeros && x === '0') {
        return false
      }
      return true
    }).join('')
  
    return {
      numero,
      serie
    };
  }