import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-numeracao-page-styles';
import { NumeracaoHeader } from './components/numeracao-header';
import { useCallback, useEffect, useState } from 'react';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { usePDV } from 'services/app/hooks/pdv';
import { OkIcon } from 'views/components/icons';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { useNumeracao } from 'services/app/hooks/gerenciar-numeracao';
import useFirebase, { EnumColectionFireBase } from 'services/app/hooks/firebase';

interface Fields {
  value: string;
  error: boolean;
  msg: string;
}

const MovNumeracaoPage = () => {
  // STATES E REFS
  const [senhaAsync, setSenhaAsync] = useState<Fields>({
    value: '',
    error: false,
    msg: '',
  });
  const [numeracao, setNumeracaoAtual] = useState<string>('');

  // HOOKS
  const { getMov } = useMovAtual();
  const { getPDV } = usePDV();
  const { setNumeracao } = useNumeracao();
  const { logError } = useFirebase();
  const { getEmpresaAtual } = useGestaoToken();

  // AUX
  const mov = getMov();
  const classes = useStyles();
  const history = useHistory();
  const { showToast } = useToastSaurus();

  useEffect(() => {
    if (!mov) {
      history.push('/dashboard');
    }

    if (!getPDV) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      const data = new Date();
      const senhaAsyncCorreta = `@${data.getDate() + data.getMonth() + 1}${data.getHours() + 10
        }`;

      if (senhaAsyncCorreta !== senhaAsync.value) {
        setSenhaAsync({
          value: '',
          error: true,
          msg: 'Campo senha sync está incorreto e é obrigatório.',
        });
        return;
      }

      await setNumeracao(
        mov!.retornoFinalizacao?.adicional ?? 10,
        getPDV()!.numCaixa + 100,
        mov!.tpAmb,
        Number(numeracao),
      );

      const logObj = {
        mode: mov!.retornoFinalizacao?.adicional ?? 10,
        serie: getPDV()!.numCaixa + 100,
        tpAmb: mov!.tpAmb,
        numeracao: Number(numeracao)
      }

      try {
        await logError({
          empresa: getEmpresaAtual()?.nomeFantasia,
          documento: getEmpresaAtual()?.cpfcnpj,
          empresaId: getEmpresaAtual()?.id ?? '',
          numeracao: logObj,
          numercaoString: JSON.stringify(logObj),
          data: new Date(),
        }, EnumColectionFireBase.NUMERACAO);
      } catch (e: any) {

      }

      history.push('/venda-simples/finalizar-venda');
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEmpresaAtual, getPDV, history, logError, mov, numeracao, senhaAsync.value, setNumeracao, showToast]);

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.header}>
          <NumeracaoHeader />
        </Grid>

        <Grid className={classes.containerInterno}>
          <Grid className={classes.content}>
            <Typography variant="body1" className={classes.textSubtitle}>
              Não foi possível identificar a ultima numeração, por favor digite
              o número para prosseguir com a venda ou tente novamente.
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextFieldSaurus
                  tipo="NUMERO"
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label="Numeração"
                  value={numeracao}
                  placeholder="Ex: 31"
                  onChange={(e) => setNumeracaoAtual(e.target.value)}
                  className={classes.field}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextFieldSaurus
                  tipo="TEXTO"
                  fullWidth
                  size="medium"
                  variant="outlined"
                  label="Senha Sync"
                  value={senhaAsync.value}
                  error={senhaAsync.error}
                  helperText={senhaAsync.error ? senhaAsync.msg : undefined}
                  onChange={(e) =>
                    setSenhaAsync({
                      value: e.target.value,
                      error: false,
                      msg: '',
                    })
                  }
                  className={classes.field}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: '20px' }}>
              {/* <Grid item xs={12} md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                >
                  <AtualizarIcon tipo="BUTTON_PRIMARY" />
                  Tentar Novamente
                </Button>
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Button
                  disabled={
                    senhaAsync.value === '' ||
                      senhaAsync.error ||
                      numeracao === ''
                      ? true
                      : false
                  }
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={handleConfirm}
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Confirmar numeração
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MovNumeracaoPage;
