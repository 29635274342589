import { useMemo } from 'react';
import * as Yup from 'yup';
import {
    useValidationYupSenhaConfirmacao,
    useValidationYupEmail,
    useValidationYupNome,
    useValidationYupSenha
} from '../../../form-validations'


export const useFormContratoUsuarioValidation = () => {

    const { nomeYup } = useValidationYupNome()
    const { emailYup } = useValidationYupEmail()
    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()
    const FormContratoUsuarioValidationYup = useMemo(() => {
        return Yup.object().shape({
            saudacao: nomeYup(),
            apelido:
                Yup
                    .string()
                    .trim()
                    .min(3, 'O apelido deve conter no mínimo 3 caracteres')
                    .max(26, 'No máximo 26 caracteres.')
                    .required('Informe um Apelido / Nome de usuário')
                    .test({
                        message: 'Não pode conter espaços',
                        test: function (apelido) {
                            const regexEspaco = /^\S+$/;

                            return regexEspaco.test(apelido ?? '')
                        }
                    }),
            email: emailYup(),
            senha: senhaYup(),
            confirmar: senhaConfirmacaoYup('senha')
        });
    }, [nomeYup, emailYup, senhaYup, senhaConfirmacaoYup])

    return {
        FormContratoUsuarioValidationYup
    }
}

