import { forwardRef, useImperativeHandle, useRef } from 'react';
import UsuarioSemImagem from 'assets/img/usuario-sem-imagem.png';
import { Box, Grid, Button } from '@material-ui/core';
import { useFormDadosContaValidation } from './form-dados-conta-validation';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  ImagemInput,
  TextFieldSaurus,
} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { DadosContaFormModel } from 'model/app/forms/master/dados-conta-form-model';
import { useThemeQueries, makeUtilClasses } from 'views';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormDadosConta = forwardRef<
  DefaultFormRefs<DadosContaFormModel>,
  DefaultFormProps<DadosContaFormModel>
>(
  (
    { loading, onSubmit, ...props }: DefaultFormProps<DadosContaFormModel>,
    ref,
  ) => {
    const { isMobile, lg } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const modelForm = useRef<DadosContaFormModel>();
    const { FormDadosContaValidationYup } = useFormDadosContaValidation();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue,
    } = useForm<DadosContaFormModel>({
      defaultValues: { ...props.model! },
      resolver: yupResolver(FormDadosContaValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const submitAlterarDadosConta = (values: DadosContaFormModel) => {
      const model = picker<DadosContaFormModel>(
        values,
        new DadosContaFormModel(),
      );

      onSubmit(model, modelForm.current);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: DadosContaFormModel) => {
        modelForm.current = model;
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));


    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Box my={3}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div style={{ marginBottom: lg ? 20 : 0 }}>
                      <Controller
                        name="imagem"
                        control={control}
                        render={({ field }) => (
                          <ImagemInput
                            loadErrorImage={UsuarioSemImagem}
                            imageRadius="100%"
                            disabled={
                              VariaveisAmbiente.paymentDevice ===
                              EnumDeviceType.MERCADOPAGO ||
                              VariaveisAmbiente.paymentDevice ===
                              EnumDeviceType.MERCADOPAGO_DEV
                            }
                            width={isMobile ? '180px' : '180px'}
                            height={isMobile ? '180px' : '180px'}
                            accept="image/*"
                            error={Boolean(
                              errors.imagem && errors.imagem.message,
                            )}
                            helperText={
                              touchedFields.imagem && errors.imagem
                                ? errors.imagem.message
                                : undefined
                            }
                            {...field}
                            onChange={({ base64 }: any) => {
                              setValue('imagem', base64);
                            }}
                            onBlur={() => {
                              setValue('imagem', '');
                            }}
                            value={getValues('imagem')}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    spacing={2}
                    xs={12}
                    lg={8}
                  >
                    <Grid item xs={isMobile ? 12: 6}>
                      <Controller
                        name="nome"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            disabled={loading}
                            allowSubmit={false}
                            id="nome"
                            label="Nome de Exibição"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.nome && errors.nome
                                ? errors.nome.message
                                : undefined
                            }
                            error={Boolean(errors.nome && errors.nome.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={isMobile ? 12: 6}>
                      <Controller
                        name="apelido"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            inputRef={refInputNome}
                            disabled={loading}
                            allowSubmit={false}
                            id="apelido"
                            label="Apelido"
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.apelido
                                ? errors.apelido.message
                                : undefined
                            }
                            error={Boolean(errors.apelido && errors.apelido.message)}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={isMobile ? 12: 6}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            id="Email"
                            label="E-Mail"
                            tipo="EMAIL"
                            allowSubmit
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              touchedFields.email && errors.email
                                ? errors.email.message
                                : undefined
                            }
                            error={Boolean(
                              errors.email && errors.email.message,
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={isMobile ? 12: 6}>
                      <Controller
                        name="telefone"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading}
                            id="telefone"
                            label="Telefone"
                            tipo="TELEFONE"
                            allowSubmit
                            fullWidth
                            autoComplete={'off'}
                            helperText={
                              errors.telefone
                                ? errors.telefone.message
                                : undefined
                            }
                            error={Boolean(
                              errors.telefone && errors.telefone.message,
                            )}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
