import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { DialogDescontoAcrescimo } from './dialog-modal/dialog/dialog-desconto-acrescimo'

export const DialogAcresDesc = (props: ModalProps & {tipo: 'acresc' | 'desc'}) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <DialogDescontoAcrescimo tipo={props.tipo}/>}
    </DefaultModal>
  );
};
