import { guidEmpty } from "utils/guid-empty";
import { EnumFaturaStatus } from "../../../enums/enum-fatura-status";

export class FaturaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public dVencimento: Date = new Date(),
        public status: EnumFaturaStatus = EnumFaturaStatus.ABERTA,
        public valor: number = 0,
        public encargo: number = 0,
        public descricao: string = '',
        public dInserido: Date = new Date(),
    ) { }
}