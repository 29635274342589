import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import classNames from 'classnames';
import { useCadastros } from 'services/app';
import { OkIcon } from 'views/components/icons';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { KeyValueModel } from 'model';
import { toDecimalString } from 'utils/to-decimal';
import CardParcelamento from 'views/components/cards/card-parcelamento/card-parcelamento';
import { roundTo } from 'utils/round-to';

interface ParcelamentoDialog {
  callback: (qtdeParcelas: number) => void;
  qtdePessoas: number;
  vRestante: number;
  paymentMethod: FinalizadoraModel;
}

export const DialogParcelamento = ({ callback, vRestante, paymentMethod }: ParcelamentoDialog) => {
  const [qtdeParcelas, setQtdParcelas] = useState<number>(1);

  const { fecharParcelamento } = useCadastros();

  const classes = useModalStyles();

  const handleSubmit = useCallback(
    async () => {
      await callback(qtdeParcelas)
      fecharParcelamento()
    },
    [callback, fecharParcelamento, qtdeParcelas],
  );

  const handleParcelas = useCallback(
    (parcela: KeyValueModel) => {
      setQtdParcelas(parcela.Key);
    }, []);

  const vAPagar = useMemo(() => {
    return Number(roundTo(Number(vRestante), 2))
  }, [vRestante]);

  const arrayParcelamento = useMemo(() => {
    const p: number[] = [];
    for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
      p.push(i);
    }
    return p.filter((item) => {
      return vAPagar / item >= paymentMethod.vMinParc;
    })
      .map((p) => {
        return new KeyValueModel(
          p,
          `${p}X de R$ ${toDecimalString(vAPagar / p, 2)}`,
        );
      });
  }, [paymentMethod.qMaxParc, paymentMethod.vMinParc, vAPagar])

  const onCloseClick = useCallback(() => {
    fecharParcelamento();
  }, [fecharParcelamento]);

  return (
    <div className={classes.root}>
      <ModalHeader
        title={'Parcelamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            boxShadow: '0px 4px 4px 0px #00000033',
            padding: 8,
            marginBottom: 8
          }}
        >
          <Typography style={{ fontSize: 18, fontWeight: 600 }}>Valor Total</Typography>
          <Typography style={{ fontWeight: 600, fontSize: 36 }}>
            R$ {toDecimalString(vAPagar, 2)}
          </Typography>
        </div>
        <Typography style={{ fontSize: 13, fontWeight: 600, marginLeft: 16, marginBottom: 8 }}>Quantidade de Parcelas</Typography>
        <div className={classNames(classes.contentForms)}>
          <div className={classes.listModal}>

            {arrayParcelamento.map((op) => (
              <CardParcelamento
                model={op}
                selected={op.Key === qtdeParcelas ? true : false}
                onClick={handleParcelas}
              />
            ))}
          </div>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Continuar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
