import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentForm: {
        height: 'auto',
        padding: '0 16px',
        width: '100%',
        display: 'flex',
    },
    button: {
        padding: '0 16px',
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
}));