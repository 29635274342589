import { isEmpty } from "lodash";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { useCallback } from "react";
import { GestaoStorageKeys, useGestaoStorage } from ".";

export function useMovAnteriorStorage() {
    const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();

    const get = useCallback((): MovSimplesModel | undefined => {
        return isEmpty(getRegistro(GestaoStorageKeys.MovSimplesAnterior, false))
            ? undefined
            : getRegistro(GestaoStorageKeys.MovSimplesAnterior, false);
    }, [getRegistro]);

    const persist = useCallback((model: MovSimplesModel | undefined) => {
        if (model === undefined) {
            delRegistro(GestaoStorageKeys.MovSimplesAnterior, false);
        } else {
            setRegistro(GestaoStorageKeys.MovSimplesAnterior, model, false);
        }
    }, [delRegistro, setRegistro]);

    return {
        get,
        persist
    };
}
