import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(()=>({
    inputInfo:{
        minHeight:155,
        '& textarea':{
            height:155
        }
    }

}))