import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles(() => ({
    container: {
        width: '100%'
    },
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon:{
        width: '45px',
        heigth: '45px'
    }
}));