import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { PontosVendaModel } from "model/api/gestao/pontos-venda/pontos-venda-model";
import { VariaveisAmbiente } from "config";

export function usePostPontosVenda() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPontosVenda = useCallback(
    ( pontosVenda: PontosVendaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${pontosVenda.empresaId}/Caixa`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(pontosVenda),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postPontosVenda,
  };
}
