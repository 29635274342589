import { isEmpty } from 'lodash';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { ModificadoresCadastro } from './modificadores-modal/modificadores-cadastro/modificadores-cadastro';
import { ModificadoresEdit } from './modificadores-modal/modificadores-edit/modificadores-edit';

export const ModificadorModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && <ModificadoresCadastro />}
      {props.openned && !isEmpty(props.id) && <ModificadoresEdit id={props.id} />}
    </DefaultModal>
  );
};
