import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetConfiguracoesPontosVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConfiguracoesPontosVenda = useCallback(
        (empresaId: string, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/caixa/${id}/configuracao`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getConfiguracoesPontosVenda,
    }

}
