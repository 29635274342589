import { guidEmpty } from 'utils/guid-empty';
import { ConfigEmpresaModel } from '../config-empresa/config-empresa-model';


export class ContratoConfigCompletoModel {
    constructor(
        public id: string = guidEmpty(),
        public configuracoes: ConfigEmpresaModel[] = [new ConfigEmpresaModel()],
    ) { }
}

