import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export class IdentidadeConfirmarUsuarioProps {
    public constructor(
        public id: string,
        public email: string = '',
        public telefone: string = '',
        public senha: string = '',
        public confirmarSenha: string = ''
    ) { }
}

export function usePostIdentidadeConfirmarUsuario() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeConfirmarUsuario = useCallback(
        (confirmarProps: IdentidadeConfirmarUsuarioProps) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Auth/confirmar`,
                method: 'POST',
                data: confirmarProps,
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeConfirmarUsuario,
    };
}
