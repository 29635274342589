import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const LimiteAtingidoVendaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4164 57.6877C-3.80681 67.1337 -3.80479 82.4508 15.4184 91.8988C34.6416 101.345 65.8091 101.345 85.0303 91.8988C104.253 82.4528 104.253 67.1357 85.0283 57.6897C65.803 48.2437 34.6396 48.2417 15.4164 57.6877Z"
            fill="#F5F5F5"
          />
          <path
            d="M47.7714 88.8936L34.0356 80.9604C33.8135 80.8311 33.8135 80.6231 34.0356 80.4958L61.1436 64.8555C61.3658 64.7263 61.7273 64.7283 61.9495 64.8555L75.6832 72.7888C75.9054 72.918 75.9054 73.1261 75.6832 73.2533L48.5752 88.8936C48.3531 89.0229 47.9936 89.0229 47.7714 88.8936Z"
            fill="#E0E0E0"
          />
          <path
            d="M25.4723 86.0741L11.577 78.0439C11.3467 77.9106 11.3467 77.6945 11.577 77.5612L43.0313 59.4024C43.2615 59.2691 43.6352 59.2691 43.8654 59.4024L57.7607 67.4326C57.991 67.5659 57.991 67.782 57.7607 67.9153L26.3064 86.0741C26.0762 86.2074 25.7025 86.2074 25.4723 86.0741Z"
            fill="#E0E0E0"
          />
          <path
            d="M31.7373 17.7528C30.8002 17.2135 29.651 17.2802 28.4998 17.9446C25.8459 19.4776 23.6869 23.9047 23.6869 27.8168V35.8288C23.6869 36.059 23.8747 36.2469 24.107 36.2469C24.3372 36.2469 24.5271 36.059 24.5271 35.8288V27.8168C24.5271 24.1733 26.4983 20.0693 28.9198 18.6717C29.8146 18.1547 30.6446 18.088 31.3212 18.4798C32.321 19.0575 32.8946 20.5237 32.8946 22.503V30.4746C32.8946 30.7049 33.0824 30.8927 33.3146 30.8927C33.5449 30.8927 33.7347 30.7049 33.7347 30.4746V22.503C33.7307 20.2167 33.0036 18.4859 31.7373 17.7528Z"
            fill="#263238"
          />
          <path
            d="M52.3379 69.4159L49.7547 25.0883L42.3607 20.8328L39.9371 62.2582L52.3379 69.4159Z"
            fill="#46B2F0"
          />
          <path
            opacity="0.4"
            d="M52.3379 69.4159L49.7547 25.0883L42.3607 20.8328L39.9371 62.2582L52.3379 69.4159Z"
            fill="white"
          />
          <path
            d="M16.3879 35.8309L13.9623 77.2542L39.9372 62.2582L42.3608 20.8328L16.3879 35.8309Z"
            fill="#3CAEF0"
          />
          <path
            opacity="0.3"
            d="M16.3879 35.8309L13.9623 77.2542L39.9372 62.2582L42.3608 20.8328L16.3879 35.8309Z"
            fill="white"
          />
          <path
            opacity="0.05"
            d="M16.3879 35.8309L13.9623 77.2542L39.9372 62.2582L42.3608 20.8328L16.3879 35.8309Z"
            fill="black"
          />
          <path
            d="M16.3879 35.8309L23.7799 40.0843L26.363 84.4119L13.9623 77.2542L16.3879 35.8309Z"
            fill="#46B2F0"
          />
          <path
            opacity="0.55"
            d="M16.3879 35.8309L23.7799 40.0843L26.363 84.4119L13.9623 77.2542L16.3879 35.8309Z"
            fill="white"
          />
          <path
            opacity="0.5"
            d="M26.363 84.4119L21.0331 76.9311L20.3666 50.8026L19.7002 76.4766L13.9623 77.2542L26.363 84.4119Z"
            fill="#3CAEF0"
          />
          <path
            d="M49.7547 25.0883L23.7798 40.0843L26.3629 84.4119L52.3379 69.4159L49.7547 25.0883ZM31.5131 41.1224C31.0001 41.4132 30.5861 41.1688 30.5861 40.5791C30.5861 39.9873 31.0021 39.2683 31.5131 38.9714C32.0261 38.6766 32.4401 38.9189 32.4401 39.5147C32.4421 40.1126 32.0261 40.8316 31.5131 41.1224ZM40.7187 35.7662C40.2057 36.0571 39.7917 35.8147 39.7917 35.2229C39.7917 34.6312 40.2078 33.9122 40.7187 33.6153C41.2317 33.3204 41.6458 33.5628 41.6458 34.1586C41.6478 34.7564 41.2317 35.4754 40.7187 35.7662Z"
            fill="#65C2F8"
          />
          <path
            opacity="0.7"
            d="M49.7547 25.0883L23.7798 40.0843L26.3629 84.4119L52.3379 69.4159L49.7547 25.0883ZM31.5131 41.1224C31.0001 41.4132 30.5861 41.1688 30.5861 40.5791C30.5861 39.9873 31.0021 39.2683 31.5131 38.9714C32.0261 38.6766 32.4401 38.9189 32.4401 39.5147C32.4421 40.1126 32.0261 40.8316 31.5131 41.1224ZM40.7187 35.7662C40.2057 36.0571 39.7917 35.8147 39.7917 35.2229C39.7917 34.6312 40.2078 33.9122 40.7187 33.6153C41.2317 33.3204 41.6458 33.5628 41.6458 34.1586C41.6478 34.7564 41.2317 35.4754 40.7187 35.7662Z"
            fill="white"
          />
          <path
            d="M39.1454 21.9678C38.2082 21.4285 37.059 21.4952 35.9078 22.1617C33.254 23.6946 31.095 28.1217 31.095 32.0338V40.0458C31.095 40.2761 31.2828 40.4659 31.5151 40.4659C31.7453 40.4659 31.9352 40.2781 31.9352 40.0458V32.0338C31.9352 28.3903 33.9063 24.2864 36.3279 22.8888C37.2206 22.3717 38.0527 22.3051 38.7273 22.6969C39.727 23.2745 40.3026 24.7408 40.3026 26.7201V34.6917C40.3026 34.9219 40.4905 35.1098 40.7227 35.1098C40.953 35.1098 41.1428 34.9219 41.1428 34.6917V26.7201C41.1388 24.4318 40.4117 22.7009 39.1454 21.9678Z"
            fill="#37474F"
          />
          <path
            d="M52.8248 29.588C52.0068 29.1174 51.003 29.176 49.9992 29.7557C47.6827 31.0927 45.7983 34.9583 45.7983 38.3716V45.2101C45.7983 45.4121 45.9619 45.5757 46.1639 45.5757C46.3659 45.5757 46.5295 45.4121 46.5295 45.2101V38.3716C46.5295 35.1906 48.2502 31.6097 50.3628 30.3898C51.1424 29.9394 51.8674 29.8809 52.4572 30.2222C53.3297 30.7271 53.8306 32.0056 53.8306 33.7344V40.5366C53.8306 40.7386 53.9942 40.9022 54.1961 40.9022C54.3981 40.9022 54.5617 40.7386 54.5617 40.5366V33.7344C54.5637 31.7369 53.9295 30.2262 52.8248 29.588Z"
            fill="#263238"
          />
          <path
            d="M70.8017 74.5216L68.5478 35.8349L62.097 32.1227L59.9803 68.2748L70.8017 74.5216Z"
            fill="#3CAEF0"
          />
          <path
            opacity="0.2"
            d="M70.8017 74.5216L68.5478 35.8349L62.097 32.1227L59.9803 68.2748L70.8017 74.5216Z"
            fill="black"
          />
          <path
            d="M39.4283 45.2102L37.3116 81.3622L59.9804 68.2748L62.097 32.1227L39.4283 45.2102Z"
            fill="#3CAEF0"
          />
          <path
            opacity="0.3"
            d="M39.4283 45.2102L37.3116 81.3622L59.9804 68.2748L62.097 32.1227L39.4283 45.2102Z"
            fill="black"
          />
          <path
            d="M39.4283 45.2102L45.8791 48.9244L48.133 87.6091L37.3116 81.3623L39.4283 45.2102Z"
            fill="#3CAEF0"
          />
          <path
            opacity="0.15"
            d="M39.4283 45.2102L45.8791 48.9244L48.133 87.6091L37.3116 81.3623L39.4283 45.2102Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M48.133 87.609L43.4817 81.0794L42.9001 58.2774L42.3204 80.6856L37.3116 81.3622L48.133 87.609Z"
            fill="black"
          />
          <path
            d="M68.5478 35.8349L45.879 48.9223L48.133 87.6091L70.8017 74.5216L68.5478 35.8349ZM52.6288 49.8292C52.1824 50.0837 51.8189 49.8716 51.8189 49.3546C51.8189 48.8375 52.1824 48.2114 52.6288 47.9529C53.0751 47.6944 53.4386 47.9065 53.4386 48.4275C53.4386 48.9466 53.0771 49.5747 52.6288 49.8292ZM60.663 45.1536C60.2166 45.4081 59.8531 45.194 59.8531 44.679C59.8531 44.162 60.2146 43.5359 60.663 43.2774C61.1093 43.0189 61.4729 43.2309 61.4729 43.752C61.4729 44.2731 61.1114 44.9012 60.663 45.1536Z"
            fill="#3CAEF0"
          />
          <path
            d="M59.2896 33.2658C58.4716 32.7952 57.4679 32.8538 56.4641 33.4335C54.1475 34.7705 52.2632 38.6361 52.2632 42.0494V48.8879C52.2632 49.0899 52.4268 49.2535 52.6287 49.2535C52.8307 49.2535 52.9943 49.0899 52.9943 48.8879V42.0494C52.9943 38.8684 54.7151 35.2875 56.8296 34.0676C57.6092 33.6172 58.3343 33.5587 58.924 33.9C59.7965 34.4049 60.2974 35.6834 60.2974 37.4122V44.2144C60.2974 44.4164 60.461 44.58 60.663 44.58C60.8649 44.58 61.0285 44.4164 61.0285 44.2144V37.4122C61.0285 35.4147 60.3964 33.904 59.2896 33.2658Z"
            fill="#37474F"
          />
          <path
            d="M67.4087 90.8364L56.4116 84.4845C56.2339 84.3815 56.2339 84.2159 56.4116 84.1129L78.115 71.589C78.2927 71.486 78.5815 71.486 78.7592 71.589L89.7563 77.9408C89.9341 78.0438 89.9341 78.2094 89.7563 78.3124L68.053 90.8364C67.8752 90.9394 67.5864 90.9394 67.4087 90.8364Z"
            fill="#E0E0E0"
          />
          <path
            d="M71.4743 44.0993C70.8361 43.7317 70.0505 43.7782 69.2648 44.2306C67.4532 45.2768 65.9808 48.2982 65.9808 50.9682V56.3163C65.9808 56.4738 66.1101 56.603 66.2676 56.603C66.4252 56.603 66.5544 56.4738 66.5544 56.3163V50.9682C66.5544 48.482 67.8995 45.6807 69.5536 44.7254C70.1636 44.374 70.7311 44.3275 71.1916 44.5941C71.8742 44.9879 72.266 45.9897 72.266 47.3409V52.6607C72.266 52.8182 72.3953 52.9475 72.5528 52.9475C72.7104 52.9475 72.8396 52.8182 72.8396 52.6607V47.3409C72.8356 45.7796 72.3408 44.5981 71.4743 44.0993Z"
            fill="#263238"
          />
          <path
            d="M85.5352 79.2375L83.772 48.9849L78.7269 46.0806L77.0728 74.3519L85.5352 79.2375Z"
            fill="#D0EDFD"
          />
          <path
            opacity="0.2"
            d="M85.5352 79.2375L83.772 48.9849L78.7269 46.0806L77.0728 74.3519L85.5352 79.2375Z"
            fill="black"
          />
          <path
            d="M61.0004 56.3163L59.3442 84.5876L77.0729 74.3519L78.727 46.0806L61.0004 56.3163Z"
            fill="#D0EDFD"
          />
          <path
            opacity="0.3"
            d="M61.0004 56.3163L59.3442 84.5876L77.0729 74.3519L78.727 46.0806L61.0004 56.3163Z"
            fill="black"
          />
          <path
            d="M61.0004 56.3163L66.0455 59.2185L67.8066 89.4732L59.3442 84.5876L61.0004 56.3163Z"
            fill="#D0EDFD"
          />
          <path
            opacity="0.15"
            d="M61.0004 56.3163L66.0455 59.2185L67.8066 89.4732L59.3442 84.5876L61.0004 56.3163Z"
            fill="black"
          />
          <path
            opacity="0.15"
            d="M67.8066 89.4731L64.1692 84.3674L63.7148 66.5338L63.2604 84.0564L59.3442 84.5876L67.8066 89.4731Z"
            fill="black"
          />
          <path
            d="M83.7722 48.9849L66.0455 59.2205L67.8087 89.4752L85.5353 79.2395L83.7722 48.9849ZM71.3229 59.9274C70.9735 60.1254 70.6908 59.9597 70.6908 59.5558C70.6908 59.1519 70.9735 58.6611 71.3249 58.4591C71.6743 58.2572 71.9571 58.4228 71.9571 58.8307C71.9571 59.2387 71.6723 59.7295 71.3229 59.9274ZM77.6061 56.2718C77.2567 56.4698 76.974 56.3041 76.974 55.9002C76.974 55.4963 77.2567 55.0055 77.6081 54.8035C77.9575 54.6016 78.2403 54.7672 78.2403 55.1751C78.2403 55.5831 77.9555 56.0739 77.6061 56.2718Z"
            fill="#D0EDFD"
          />
          <path
            d="M76.5316 46.9753C75.8913 46.6077 75.1077 46.6542 74.3221 47.1066C72.5104 48.1528 71.0381 51.1742 71.0381 53.8442V59.1923C71.0381 59.3498 71.1673 59.4791 71.3249 59.4791C71.4824 59.4791 71.6117 59.3498 71.6117 59.1923V53.8442C71.6117 51.358 72.9568 48.5567 74.6109 47.6014C75.2208 47.248 75.7883 47.2035 76.2488 47.4701C76.9315 47.864 77.3233 48.8657 77.3233 50.2169V55.5367C77.3233 55.6942 77.4526 55.8235 77.6101 55.8235C77.7676 55.8235 77.8969 55.6942 77.8969 55.5367V50.2169C77.8928 48.6557 77.396 47.4742 76.5316 46.9753Z"
            fill="#37474F"
          />
          <path
            d="M74.8552 26.956L74.8552 26.956C77.3689 24.5659 81.3586 24.6681 83.7473 27.1796C84.8866 28.3782 85.4567 29.9123 85.4726 31.4524L85.4347 32.1936C85.2801 33.6243 84.6398 35.0075 83.5194 36.0725L83.5193 36.0726C81.008 38.4611 77.018 38.3608 74.6298 35.8476L74.6298 35.8476C73.5648 34.7272 72.9953 33.3141 72.9138 31.8786L72.9155 31.1365C73.0077 29.6002 73.6562 28.0958 74.8552 26.956Z"
            stroke="#455A64"
            stroke-width="0.769606"
          />
          <path
            d="M82.0256 28.2761L81.8975 28.1703C81.6215 27.9415 81.2126 27.9789 80.9839 28.254L76.3445 33.834C76.1157 34.1099 76.1532 34.5188 76.4283 34.7476L76.5564 34.8535C76.8323 35.0823 77.2412 35.0448 77.47 34.7697L82.1094 29.1898C82.339 28.9147 82.3015 28.5058 82.0256 28.2761Z"
            fill="#3CAEF0"
          />
          <path
            d="M76.3164 28.3763L76.1967 28.4918C75.9385 28.7409 75.9312 29.1516 76.1803 29.4098L81.2196 34.6321C81.4687 34.8903 81.8794 34.8976 82.1376 34.6485L82.2573 34.533C82.5155 34.2838 82.5228 33.8731 82.2737 33.6149L77.2344 28.3927C76.9853 28.1354 76.5746 28.128 76.3164 28.3763Z"
            fill="#3CAEF0"
          />
          <path
            d="M54.9925 7.38163C53.5706 9.58208 53.1347 12.1403 53.5676 14.5361L53.8321 15.6423C54.4874 17.8217 55.8883 19.7877 57.9444 21.1164C62.5437 24.0894 68.7062 22.7637 71.6762 18.1644C73.0049 16.1083 73.4738 13.7395 73.1762 11.4819L72.9658 10.3637C72.3736 8.00088 70.9277 5.84853 68.7272 4.42666C64.1279 1.45665 57.9655 2.77933 54.9925 7.38163Z"
            fill="white"
          />
          <path
            d="M54.295 6.90964C51.3942 11.3827 52.2449 17.2205 56.0506 20.7105L55.3682 23.9872C55.251 24.5463 55.8852 24.9581 56.3482 24.6214L59.0627 22.6615C63.8002 24.7146 69.4787 23.1124 72.3796 18.6394C75.6141 13.6553 74.1892 6.96676 69.2022 3.73522C64.2151 0.497679 57.5296 1.92256 54.295 6.90964ZM68.7422 4.43865C70.9397 5.86353 72.3826 8.01888 72.9688 10.3817L73.1792 11.4999C73.4738 13.7545 73.0018 16.1233 71.6671 18.1794C68.6881 22.7727 62.5226 24.0864 57.9294 21.1073C55.8732 19.7757 54.4784 17.8037 53.8261 15.6243L53.5615 14.518C53.1347 12.1222 53.5736 9.56401 54.9984 7.36657C57.9835 2.77027 64.1459 1.45662 68.7422 4.43865Z"
            fill="#455A64"
          />
          <path
            d="M67.8564 8.21618L67.6763 8.0467C67.2882 7.68055 66.6773 7.69724 66.3112 8.08405L58.8883 15.9299C58.5221 16.318 58.5388 16.9289 58.9256 17.295L59.1057 17.4644C59.4938 17.8306 60.1048 17.8139 60.4708 17.4271L67.8938 9.58128C68.2611 9.19457 68.2444 8.58363 67.8564 8.21618Z"
            fill="#3CAEF0"
          />
          <path
            d="M59.3669 7.82066L59.178 7.98081C58.7708 8.32623 58.7208 8.93562 59.0662 9.34284L66.0534 17.5801C66.3988 17.9874 67.0082 18.0374 67.4154 17.692L67.6042 17.5318C68.0114 17.1864 68.0615 16.577 67.716 16.1698L60.7289 7.9325C60.3834 7.52658 59.774 7.47654 59.3669 7.82066Z"
            fill="#3CAEF0"
          />
          <rect width="100" height="100" fill="#C4C4C4" fillOpacity="0.01" />
        </svg>
      </DefaultIcon>
    </>
  );
};
