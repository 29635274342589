import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    footerContainer: {
        width: '100%',
        flexDirection: 'column',
        height: '70px',
        background: '#FCFCFC',
    },
    itemsFooterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: '2px'
    },
    itemsFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textTotalInfo: {
        fontWeight: 'bold'
    },
    button: {
        margin: 0,
        width: '45px',
        height: '45px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

        "& div": {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },

        "& svg": {
            width: '20px',
            height: '20px',
            margin: 0
        }
    },
    textButtonFiltro: {
        color: '#FFFFFF',
        fontSize: '10px'
    },
    arrowDown: {
        position: 'absolute',
        background: '#f9f9f9',
        boxShadow: '0px 3.62319px 3.62319px rgba(0, 0, 0, 0.5)',
        width: '300px',
        bottom: '60px',
        padding: '4px 8px',
        borderRadius: '5px',
        marginBottom: '8px'

    },
    containerArrow: {
        "&:after, &::before": {
            top: '100%',
            border: 'solid transparent',
            content: "' '",
            height: 0,
            width: 0,
            position: 'absolute',
        },

        "&:before": {
            borderTopColor: '#CDCDCD',
            boxShadow: 'inset 10px 10px 15.62319px rgba(0, 0, 0, 0.5)',
            borderWidth: '15px',
            left: '50%',
            marginLeft: '37.4%',
            marginBottom: '25px',
            borderSpacing: '0.5px'
        },
    },
    filtroContainer: {
    },
    filtroItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        "& .MuiFormControlLabel-root": {
            marginRight: 0
        }
    },
    textFiltroItem: {
        fontWeight: 600
    },

}));
