import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetNcmById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getNcmById = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/ncm/${id}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );



    return {
      ...outrasPropriedades,
      getNcmById,
    };
    



}