import { Button, Grid } from '@material-ui/core';
import { ProcessosModel } from 'model/api/gestao/processos/processos-model';
import { AdicionarProcessoIcon } from 'views/components/icons';
import { useStyles } from './processos-styles';
import { ProcessosListData } from './processos/processos-list-data';

interface ProcessosProps {
  listProcessos: ProcessosModel[];
  handleClickCard: (model: ProcessosModel) => void;
  handleOpenNovoProcesso: () => void;
  showAddButton: boolean;
}

export const Processos = ({
  listProcessos,
  handleClickCard,
  handleOpenNovoProcesso,
  showAddButton,
}: ProcessosProps) => {

  // AUX
  const classes = useStyles()

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.listContainer}>
          <ProcessosListData
            onClick={(value) => {
              handleClickCard(value);
            }}
            model={listProcessos ?? []}
          />
        </Grid>
        {showAddButton && (
          <Grid
            item
            xs={12}
            className={classes.btnNovo}
          >
            <Grid container className={classes.containerBtn}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleOpenNovoProcesso}
                >
                  <AdicionarProcessoIcon tipo="BUTTON" />
                  Novo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
