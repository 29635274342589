import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { RetiradaDocumentoPostModel } from "model/api/gestao/retirada-documento/retirada-documento-post-model";
import { VariaveisAmbiente } from 'config';

export function usePostRetiradaDocumento() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postRetiradaDocumento = useCallback(
    (documento: RetiradaDocumentoPostModel, documentoEmpresa: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documentoEmpresa}/retirada`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
        },
        data: JSON.stringify(documento),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postRetiradaDocumento,
  };
}
