import { useMemo } from 'react';
import * as Yup from 'yup';
import {
  useValidationYupDocumento,
  useValidationYupRazaoSocial,
} from 'views/components/form-validations';
import { validarEmail } from 'utils/valida-email';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';


export const useFormPessoaValidation = () => {
  const { documentoYup } = useValidationYupDocumento(true)
  const { razaoSocialYup } = useValidationYupRazaoSocial()
  const FormPessoaValidationYup = useMemo(() => {

    return (
      Yup.object().shape({
        cpfcnpj: documentoYup(),
        nome: razaoSocialYup(),
        contatos: Yup.array().of(
          Yup.object({
            valor: Yup.string().test({
              message: 'Formato Inválido',
              test: (value, context) => {
                if (value === '') return true
                switch (context.parent.tipo) {
                  case EnumTipoPessoaContato.EMAIL:
                    return Boolean(value && validarEmail(value));
                  default:
                    return Boolean(value && (value.length === 14 || value.length === 15))
                }
              }
            })
          })
        )
      })
    )
  }, [documentoYup, razaoSocialYup])
  return {
    FormPessoaValidationYup,
  }
}

