import * as Yup from 'yup';
import { useValidationYupDocumento } from '../../../form-validations'
import { EnumPagTpMod } from 'model';
import { useCallback } from 'react';

interface FinalizadoraValidationProps {
  tipo: EnumPagTpMod;
  tpModIntegrado: boolean;
  isDev: boolean;
}

export const useFormFinalizadoraEditValidation = ({ tipo, tpModIntegrado, isDev }: FinalizadoraValidationProps) => {
  const { documentoYup } = useValidationYupDocumento(true)

  const credenciais = Yup.object().shape({
    merchantId: Yup.string().required('Merchant ID é obrigatório.').typeError('Merchant ID é obrigatório.'),
    merchantToken: Yup.string().required('Merchant Token é obrigatório.').typeError('Merchant Token é obrigatório.'),
    ambiente: isDev ? Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.') : Yup.number().notRequired().nullable(),
  })

  const validarCredenciais = useCallback(() => {

    switch (tipo) {
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return credenciais
      default:
        return Yup.object().shape({}).nullable(true)
    }
  }, [credenciais, tipo])

  const FormFinalizadoraEditYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    tpMod: Yup.number().required('O campo Modelo de pagamento é obrigatório'),
    qMaxParc: Yup.number().required('Parcelamento Máximo é obrigatório').min(1, 'Parcelamento Máximo não inválido').max(60, 'Parcelamento Máximo inválido').typeError('Parcelamento Máximo é obrigatório.'),
    cnpjCred: documentoYup('CNPJ'),
    credenciais: validarCredenciais()

  });

  return {
    FormFinalizadoraYupValidation: FormFinalizadoraEditYupValidation
  }
}

