import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const OutrosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 8 20 C 4.699219 20 2 22.699219 2 26 C 2 29.300781 4.699219 32 8 32 C 11.300781 32 14 29.300781 14 26 C 14 22.699219 11.300781 20 8 20 Z M 25 20 C 21.699219 20 19 22.699219 19 26 C 19 29.300781 21.699219 32 25 32 C 28.300781 32 31 29.300781 31 26 C 31 22.699219 28.300781 20 25 20 Z M 42 20 C 38.699219 20 36 22.699219 36 26 C 36 29.300781 38.699219 32 42 32 C 45.300781 32 48 29.300781 48 26 C 48 22.699219 45.300781 20 42 20 Z M 8 22 C 10.222656 22 12 23.777344 12 26 C 12 28.222656 10.222656 30 8 30 C 5.777344 30 4 28.222656 4 26 C 4 23.777344 5.777344 22 8 22 Z M 25 22 C 27.222656 22 29 23.777344 29 26 C 29 28.222656 27.222656 30 25 30 C 22.777344 30 21 28.222656 21 26 C 21 23.777344 22.777344 22 25 22 Z M 42 22 C 44.222656 22 46 23.777344 46 26 C 46 28.222656 44.222656 30 42 30 C 39.777344 30 38 28.222656 38 26 C 38 23.777344 39.777344 22 42 22 Z" />
            </DefaultIcon>
        </>
    );
};

