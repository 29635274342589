import * as Yup from 'yup';

export const useFormDepositoValidation = () => {

  const FormDepositoYupValidation = Yup.object().shape({
    nome: Yup.string().required('O nome do depósito é obrigatório.'),
  });

  return {
    FormDepositoYupValidation
  }
}

