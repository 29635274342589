import { useCallback } from 'react';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { CompartilharIcon } from 'views/components/icons/compartilhar-icon';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';

interface PropsComprovantePagamentoHeader {
  openModalComprovante?: () => void;
}

export const ComprovantePagamentoHeader = ({
  openModalComprovante,
}: PropsComprovantePagamentoHeader) => {
  const { goToAdministrativo } = useOpenMovRota()

  const leftArea = useCallback(
    () => {
      return (
        <ButtonPrivateHeader
          icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
          tooltip="Ir para Menu Administrativo"
          onClick={goToAdministrativo}
        />
      )
    }, [goToAdministrativo]);

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<CompartilharIcon tipo="PRIVATE_HEADER" />}
        tooltip="Compartilhar"
        onClick={openModalComprovante ? openModalComprovante : () => { }}
      ></ButtonPrivateHeader>
    ),
    [openModalComprovante],
  );

  return (
    <>
      <PrivatePageHeader
        leftArea={leftArea()}
        rightArea={openModalComprovante && rightArea()}
        title={'Resumo da Venda'}
      />
    </>
  );
};
