import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const SemDinheiroIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <g id="surface227572676">
          <path d="M 0.90625 -0.03125 C 0.863281 -0.0234375 0.820312 -0.0117188 0.78125 0 C 0.40625 0.0664062 0.105469 0.339844 0 0.703125 C -0.105469 1.070312 0.00390625 1.460938 0.28125 1.71875 L 48.28125 49.71875 C 48.679688 50.117188 49.320312 50.117188 49.71875 49.71875 C 50.117188 49.320312 50.117188 48.679688 49.71875 48.28125 L 35.84375 34.40625 C 35.941406 33.617188 36 32.804688 36 32 C 36 30.628906 35.824219 29.285156 35.53125 28 C 35.691406 28 35.851562 28 36 28 C 43.738281 28 50 21.738281 50 14 C 50 6.261719 43.738281 0 36 0 C 28.261719 0 22 6.261719 22 14 C 22 14.15625 22.027344 14.308594 22.03125 14.46875 C 20.734375 14.167969 19.382812 14 18 14 C 17.195312 14 16.398438 14.070312 15.625 14.1875 L 1.71875 0.28125 C 1.511719 0.0585938 1.210938 -0.0546875 0.90625 -0.03125 Z M 36 2 C 42.664062 2 48 7.335938 48 14 C 48 20.664062 42.664062 26 36 26 C 35.550781 26 35.167969 25.976562 34.9375 25.9375 C 33.117188 20.890625 29.109375 16.882812 24.0625 15.0625 C 24.007812 14.734375 24 14.429688 24 14 C 24 7.335938 29.335938 2 36 2 Z M 35.3125 8.6875 L 32.3125 11.6875 L 34.5 11.6875 L 34.5 20.09375 L 37.5 20.09375 L 37.5 8.6875 Z M 11 15.3125 C 4.601562 18.113281 0 24.5 0 32 C 0 41.898438 8.101562 50 18 50 C 25.5 50 31.894531 45.40625 34.59375 38.90625 L 33.09375 37.40625 C 30.894531 43.507812 24.898438 48 18 48 C 9.199219 48 2 40.800781 2 32 C 2 25.101562 6.492188 19.105469 12.59375 16.90625 Z M 18 16 C 26.800781 16 34 23.199219 34 32 C 34 32.179688 33.976562 32.351562 33.96875 32.53125 L 17.46875 16.03125 C 17.648438 16.023438 17.820312 16 18 16 Z M 17.09375 25 L 13.09375 29 L 16 29 L 16 40 L 20 40 L 20 25 Z M 17.09375 25 " />
        </g>
      </DefaultIcon>
    </>
  );
};
