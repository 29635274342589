import { Box, Card, Grid, Typography } from "@material-ui/core";
import { CardPagamentosDetalheVendaProps } from "./card-pagamentos-detalhe-venda-props"
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";
import { TpModMock } from "data/mocks";

export const CardPagamentosDetalheVenda = (props: CardPagamentosDetalheVendaProps) => {

    const { theme } = useThemeQueries();

    const model = props.model;
    const order = props.index + 1;

    return (
        <>
            <Card style={{ boxShadow: theme.shadows[1] }}>
                <Grid container spacing={1} style={{ padding: '8px' }}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 8px'
                    }}>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Ordem
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                <Box fontWeight={500}>
                                    {`N° ${order}`}
                                </Box>
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Descrição
                            </Typography>
                            <Typography
                                variant="body2"
                                color='textPrimary'
                            >
                                <Box fontWeight={500}>
                                    {TpModMock.find(x => x.Key === props.model.tPag)?.Value}
                                </Box>
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Troco
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                <Box fontWeight={500}>
                                    {`R$ ${toDecimalString(model.vTroco)}`}
                                </Box>
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Total
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                <Box fontWeight={500}>
                                    {`R$ ${toDecimalString(model.vPag)}`}
                                </Box>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}