import { Button, Grid } from "@material-ui/core"
import { useGetHorario, usePostHorario, usePutHorario } from "data/api/gestao/empresa-horario";
import { isEqual } from "lodash";
import { HorarioModel } from "model/api/gestao/horarios-funcionamento/horario-model";
import { useCallback, useEffect, useRef, useState } from "react";
import { useToastSaurus } from "services/app";
import { useSessaoAtual } from 'services/app';
import { guidEmpty } from "utils/guid-empty";
import { FormDeliveryHorarios } from "views/components/form/horarios-funcionamento"
import { DefaultFormRefs } from "views/components/form/utils";
import { SATIcon, SalvarIcon, VoltarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";

type Props = {
    fecharDialog: () => void;
    empresaId?: string
    hasEmpresaId?: boolean
}

export const DeliveryHorarios = ({ fecharDialog, empresaId, hasEmpresaId = false }: Props) => {

    const cadHorarioFormRef =
        useRef<DefaultFormRefs<HorarioModel>>(null);
    const { isMobile, xs } = useThemeQueries()
    const [preenchendoTela, setPreenchendoTela] = useState<boolean>(false)
    const { getHorario, carregando: carregandoGet } = useGetHorario()
    const { postHorario, carregando: carregandoPost } = usePostHorario()
    const { putHorario, carregando: carregandoPut } = usePutHorario()
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const [horarioModel, setHorarioModel] = useState<HorarioModel>(new HorarioModel())
    const horarioFormRef = useRef<HorarioModel>(new HorarioModel())

    const carregando =
        preenchendoTela ||
        carregandoGet ||
        carregandoPost ||
        carregandoPut

    const valueEmpresaId = hasEmpresaId ? empresaId : (getEmpresaSelecionada()?.Id || '')

    const recarregarForm = useCallback((value: HorarioModel) => {
        cadHorarioFormRef.current?.fillForm(value)
    }, [])

    useEffect(() => {
        recarregarForm(horarioModel)
    }, [horarioModel, recarregarForm])

    const getHorarioWrapper = useCallback(async (tipo: number = 1) => {
        const res = await getHorario(valueEmpresaId ?? '', tipo)

        if (res.erro) throw res.erro

        const ret = res.resultado?.data?.list.length === 0
            ? new HorarioModel()
            : res.resultado?.data?.list[0] as HorarioModel

        ret.tipo = 1
        return ret
    }, [getHorario, valueEmpresaId])

    useEffect(() => {
        (async () => {
            try {
                setPreenchendoTela(true)
                const horario = await getHorarioWrapper()
                setHorarioModel(horario)
                horarioFormRef.current = horario
            } catch (e: any) {
                showToast('error', e.message)
            } finally {
                setPreenchendoTela(false)
            }
        })()
    }, [getHorarioWrapper, showToast])

    const replicarHorarioEstabelicamento = useCallback(async () => {
        try {
            setPreenchendoTela(true)
            const horario = await getHorarioWrapper(0)

            //colocando os IDS do horário atual no horário replicado do delivery
            horario.id = horarioFormRef.current.id
            horario.empresaHorarioDias.map((dia) => {
                dia.empresaHorarioId = horarioFormRef.current.id
                const diaId = horarioFormRef.current.empresaHorarioDias.find(item => item.diaSemana === dia.diaSemana)?.id

                if (!diaId) {
                    dia.id = guidEmpty()
                    return dia
                }

                dia.id = diaId

                return dia
            })

            setHorarioModel(horario)
        } catch (e: any) {
            showToast('error', e.message)
        } finally {
            setPreenchendoTela(false)
        }
    }, [getHorarioWrapper, showToast])

    const saveHorario = useCallback(async (value: HorarioModel, post: boolean = true) => {
        if (post) {
            value.contratoId = getEmpresaSelecionada()?.ContratoId || ''
            value.empresaId = valueEmpresaId || ''
            const res = await postHorario(valueEmpresaId || '', value)

            if (res.erro) throw res.erro
            return
        }

        const res = await putHorario(valueEmpresaId || '', value)

        if (res.erro) throw res.erro

        horarioFormRef.current = value

    }, [getEmpresaSelecionada, postHorario, putHorario, valueEmpresaId])

    const handleSubmit = useCallback(
        async (model: HorarioModel) => {
            try {
                model.tipo = 1
                if (isEqual(model, horarioFormRef.current)) {
                    showToast('info', 'Nenhuma informação alterada.')
                    return
                }

                if (horarioModel.id === guidEmpty()) {
                    await saveHorario(model)
                    showToast('success', 'Horários Salvos')
                    return
                }
                await saveHorario(model, false)

                showToast('success', 'Horários Salvos')
            } catch (e: any) {
                showToast('error', e.message)
            }
        },
        [horarioModel.id, saveHorario, showToast],
    );
    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                height: isMobile ? '0' : '100%',
                flex: '1 1 auto',
                overflowY: 'auto'
            }}>
                <FormDeliveryHorarios
                    loading={carregando}
                    onSubmit={handleSubmit}
                    showLoading={false}
                    ref={cadHorarioFormRef}
                />
            </div>
            <div style={{
                flex: '0 0 auto',
                padding: '16px',
                display: 'flex',
            }}>
                <Grid container spacing={2} style={{
                    justifyContent: 'between'
                }}>
                    <Grid item xs={12} md={6}>
                        <Grid item>
                            <Button color="primary" onClick={replicarHorarioEstabelicamento} variant="outlined" size='small' fullWidth={xs}>
                                <SATIcon tipo="BUTTON" />
                                Replicar do Estabelecimento
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item xs={6} md={'auto'}>
                                <Button color="primary" onClick={fecharDialog} variant="outlined" fullWidth={isMobile}>
                                    <VoltarIcon tipo="BUTTON" />
                                    Voltar
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={'auto'}>
                                <Button color="primary" onClick={() => cadHorarioFormRef.current?.submitForm()} variant="contained" fullWidth={isMobile}>
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}