import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ManifestoFiltroModal } from "views/components/modals/manifestos-filtro/manifesto-filtro-modal";
import { ManifestosSearchProps } from "views/pages/private/manifestos/components/manifestos-list/manifestos-search-props";

export const ManifestoFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            dFinal: '',
            dInicial: '',
            statusManifesto: [-1],
            integrado: '-1'
        } as ManifestosSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ManifestosFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ManifestosFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            {modalState.openned && <ManifestoFiltroModal openned={modalState.openned} filtros={modalState.filtros} key="mdlManifestoModal" />}
        </>
    )
}