import { Typography } from '@material-ui/core';
import { CardCargaResumoProps } from './card-carga-resumo-props';
import { useCallback } from 'react';
import { Styles } from './card-carga-resumo-styles';
import { TpUploadCargaStatusMock } from 'data/mocks/tp-carga-tipo-mock';
import { EnumUploadCargaStatus } from 'model/enums/enum-upload-carga-status';
import { useThemeQueries } from 'views/theme';

export const CardCargaResumo = ({ model }: CardCargaResumoProps) => {
  const classes = Styles();

  const { theme } = useThemeQueries();

  const retornarCorStatus = useCallback((): any => {
    switch (model.codigo) {
      case EnumUploadCargaStatus.Processado:
        return '#10C822';
      case EnumUploadCargaStatus.Aguardando:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Analisando:
        return '#4A4A4A';
      case EnumUploadCargaStatus.Erro:
        return '#F44336';
      case EnumUploadCargaStatus.Processando:
        return theme.palette.warning.dark;
      case EnumUploadCargaStatus.AguardandoProcessamento:
        return '#1F9CE4';
        case EnumUploadCargaStatus.Cancelado:
          return '#4A4A4A';
      default:
        return '#28EC87';
    }
  }, [model.codigo, theme.palette.warning.dark]);

  const status = TpUploadCargaStatusMock.filter((x) => x.Key === model.codigo)[0]?.Value;

  return (
    <div>
      <div className={classes.cardContainer}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className={classes.celulaGridStatus}
              style={{
                background: retornarCorStatus(),
                display: 'nowrap',
              }}
            >
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{
                  color: '#fff',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '12px',
                }}
              >
                {status}
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.celulaGridFull}>
              <Typography color="textPrimary" variant="caption">
                Descrição
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model.descricao || 'Sem Descrição'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
