export enum EnumDistaciaCardapio {
    DEZ_METROS = 10,
    VINTE_METROS = 20,
    TRINTA_METROS = 30,
    QUARENTA_METROS = 40,
    CINQUENTA_MENTROS = 50,
    CEM_METROS = 100,
    DUZENTOS_METROS = 200,
    TREZENTOS_METROS = 300,
    QUATROZENTOS_METROS = 400,
    QUINHENTOS_METROS = 500,
    UM_QUILOMETRO = 1000,
    CINCO_QUILOMETROS = 5000,
    CINQUENTA_QUILOMETROS = 50000,
    CEM_QUILOMETROS = 100000
}