import * as Yup from "yup";
import { useMemo } from "react";

export const useFormProdutoValorCompraVendaPreCadastroValidation = () => {
  
  const FormProdutoValorCompraVendaPreCadastroYupValidation = useMemo(() => {
    return Yup.object().shape({
     
    });
  }, []);

  return {
    FormProdutoValorCompraVendaPreCadastroYupValidation,
  };
};
