import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CircularLoading } from 'views/components/utils';
import { guidEmpty } from 'utils/guid-empty';
import { SwitchSaurus } from 'views/components/controles';
import { FecharIcon, InformacaoIcon, RelogioIcon } from 'views/components/icons';
import { PerfilModel } from 'model';
import classNames from 'classnames';
import { useStyles } from './form-horario-acesso-styles'

export interface FormPerfilEditProps
  extends DefaultFormProps<PerfilModel> {
  perfilId: string;
}

export const FormHorarioAcesso = forwardRef<
  DefaultFormRefs<PerfilModel>,
  FormPerfilEditProps
>(({ perfilId, ...props }: FormPerfilEditProps, ref) => {


  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState<PerfilModel>(new PerfilModel())
  const { isMobile, theme } = useThemeQueries()

  const {
    handleSubmit,
    control,
    reset,
    getValues
  } = useForm<PerfilModel>({
    defaultValues: {
      ...model,
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'horarios'
  });


  const diasSemana = useCallback((dia: number) => {
    switch (dia) {
      case 0:
        return "Domingo"
      case 1:
        return "Segunda-feira"
      case 2:
        return "Terça-feira"
      case 3:
        return "Quarta-feira"
      case 4:
        return "Quinta-feira"
      case 5:
        return "Sexta-feira"
      case 6:
        return "Sábado"
    }
  }, [])

  const fieldsSerializados = fields.map((fields, index) => {
    return {
      ...fields,
      indexOriginal: index,
    };
  });

  const onSubmit = (values: PerfilModel) => {
    props.onSubmit(values, model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (values: PerfilModel) => {
      setModel(values)
      reset({ ...values })
    },
  }));

  const diasNumber = [0, 1, 2, 3, 4, 5, 6]

  return (
    <>
      {props.loading && props.showLoading ? (
        <CircularLoading tipo="FULLSIZED" />
      ) : null}
      <div className={classNames(utilClasses.formContainer, classes.formContainer)}>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={1}>

              {diasNumber
                .sort((a, b) => a - b)
                .map(grupo => {

                  return (
                    <Grid item xs={12}>
                      <Grid container spacing={2} style={{
                        minHeight: isMobile ? 0 : 72,
                        backgroundColor: '#FBFBFB',
                        marginBottom: '4px'
                      }}>
                        <Grid item xs={12} md={4}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <Typography style={{
                              fontWeight: 600
                            }}>
                              {diasSemana(grupo)}
                            </Typography>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: 8,
                              gap: 8
                            }}>
                              <SwitchSaurus
                                checked={
                                  fields
                                    .filter(obj =>
                                      obj.diaSemana === grupo).length > 0
                                }
                                onClick={() => {
                                  if (fieldsSerializados.filter(obj => obj.diaSemana === grupo).length > 0) {
                                    const items = fieldsSerializados.filter(obj => obj.diaSemana === grupo)
                                    let indexesRemover: number[] = []
                                    for (let obj of items) {
                                      indexesRemover.push(obj.indexOriginal)
                                    }
                                    remove(indexesRemover)
                                    return
                                  }
                                  const diaAnterior = grupo === 0 ? 6 : grupo - 1
                                  const horariosAnteriores = getValues('horarios').filter(horario => horario.diaSemana === diaAnterior)

                                  if (horariosAnteriores.length > 0) {
                                    horariosAnteriores.forEach(horario => {
                                      append({
                                        id: guidEmpty(),
                                        diaSemana: grupo,
                                        perfilId: getValues('id'),
                                        inicio: horario.inicio,
                                        fim: horario.fim,
                                      })
                                    })
                                  } else {
                                    append({
                                      id: guidEmpty(),
                                      diaSemana: grupo,
                                      perfilId: getValues('id'),
                                      inicio: '',
                                      fim: '',
                                    })
                                  }
                                }}
                                variant='LIGHT'
                              />
                              <Typography>
                                {fields
                                  .filter(obj =>
                                    obj.diaSemana === grupo).length > 0 ? "Habilitado" : "Desabilitado"}
                              </Typography>
                              <Tooltip
                                title='Habilitado significa que o sistema passa a controlar o horário nesse dia.
                                    Desabilitado significa que não há controle, portanto o acesso é liberado.'
                              >
                                <div>
                                  <InformacaoIcon tipo='GERAL' class={classes.infoIcon} />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </Grid>
                        {fieldsSerializados
                          .filter((fil) => {
                            return fil.diaSemana === grupo
                          }).length > 0 && (
                            <Grid item xs={12} md={8}>
                              <Grid container spacing={2}>
                                {fieldsSerializados
                                  .filter((fil) => {

                                    return fil.diaSemana === grupo
                                  })
                                  .map((item, idx, count) => {
                                    return (
                                      <>
                                        <Grid item xs={12} md={8}>
                                          <Grid spacing={1} style={{
                                            display: 'flex'
                                          }}>
                                            <Controller
                                              render={({ field }) =>
                                                <TextFieldSaurus
                                                  allowSubmit
                                                  tipo="HORA"
                                                  label="Abre às"
                                                  {...field}
                                                  style={{
                                                    marginRight: 8,
                                                    backgroundColor: '#FFF'
                                                  }}
                                                  value={getValues(`horarios.${item.indexOriginal}.inicio`)}
                                                />}
                                              name={`horarios.${item.indexOriginal}.inicio`}
                                              control={control}
                                            />

                                            <Controller
                                              render={({ field }) =>
                                                <TextFieldSaurus
                                                  allowSubmit
                                                  label='Fecha às'
                                                  tipo="HORA"
                                                  style={{
                                                    marginRight: 8,
                                                    backgroundColor: '#FFF'
                                                  }}
                                                  {...field}
                                                  value={getValues(`horarios.${item.indexOriginal}.fim`)}
                                                />}
                                              name={`horarios.${item.indexOriginal}.fim`}
                                              control={control}
                                            />
                                            {
                                              count.length !== 1 && (
                                                <Tooltip title="Remover" placement='top'>
                                                  <Button color="secondary" onClick={() => {
                                                    remove(item.indexOriginal)
                                                  }}>
                                                    <FecharIcon tipo="BUTTON" fill={theme.palette.error.main} style={{
                                                      margin: '0 5px',
                                                      width: 20,
                                                      height: 20
                                                    }} />
                                                  </Button>
                                                </Tooltip>
                                              )
                                            }
                                          </Grid>
                                        </Grid>
                                        {
                                          (count.length - 1) === idx && (
                                            <Grid item xs={12} md={4} style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                            }}>
                                              <div style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}>
                                                <Button color="primary" onClick={() => {
                                                  append({
                                                    diaSemana: grupo,
                                                    perfilId: perfilId,
                                                    id: guidEmpty(),
                                                    inicio: '',
                                                    fim: ''
                                                  })
                                                }}>
                                                  <RelogioIcon tipo="BUTTON" style={{ width: 20, height: 20 }} />
                                                  Adicionar horário
                                                </Button>
                                              </div>
                                            </Grid>
                                          )
                                        }
                                      </>
                                    )
                                  })}
                              </Grid>
                            </Grid>
                          )}

                      </Grid>
                    </Grid>
                  )
                })}
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
