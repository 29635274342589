import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';

export const SessaoFechamentoCaixaHeader = () => {
  const { push: historyPush } = useHistory();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => {
          historyPush('/venda-simples/gerenciar-sessao');
        }}
      ></ButtonPrivateHeader>
    );
  }, [historyPush]);

  return (
    <>
      <PrivatePageHeader title={'Fechamento de Caixa'} leftArea={leftArea()} />
    </>
  );
};
