import { makeStyles } from '@material-ui/core';

export const styles = makeStyles(() => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    padding: '3PX',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '10px',
    background: 'rgba(0, 0, 0, 0.15)',
    marginLeft: '5px',
  },
  percent: {
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(0, 0, 0, 0.15)',
  },
  textConsumption: {
    color: '#fff',
  },
  containerProgress: {
    position: 'relative',
    overflow: 'hidden',
    padding: '3PX',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '10px',
    background: 'rgba(0, 0, 0, 0.15)',
  },
  progress: {
    height: 'px',
    position: 'absolute',
    top: '0',
    left: '0',
    background: 'rgba(0, 0, 0, 0.15)',
  },
}));
