
import { guidEmpty } from "utils/guid-empty";
import { EnumTpCalculoModificador } from "model/enums/enum-tpcalculo-modificador";
import { ProdutoModificadorModel } from "../produto/produto-modificador/produto-modificador-model";


export class ModificadorCompletoModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public tpCalculo: EnumTpCalculoModificador = EnumTpCalculoModificador.Soma,
    public produtos: Array<ProdutoModificadorModel> = new Array<ProdutoModificadorModel>(),
  ) { }
}



