import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";

export class SessaoValoresModel {
    constructor(
        public id: string = '',
        public dLancamento: string = '',
        public pagamentoId: string = '',
        public valor: number = 0,
        public tpLancamento: EnumTpLancamentoSessaoValor = EnumTpLancamentoSessaoValor.ABERTURA,
        public observacao: string = '',
        public operadorId: string = '',
        public operadorNome: string = '',
        public responsavelId: string = '',
        public responsavelNome: string = '',
        public portadorId: string = '',
        public portadorNome: string = '',
    ) { }
}

export class SessaoGerenciamentoModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public caixaId: string = '',
        public operadorId: string = '',
        public operadorNome: string = '',
        public dAbertura: string = '',
        public aberturaId: string = '',
        public aberturaNome: string = '',
        public dFechamento: string = '',
        public fechamentoId: string = '',
        public fechamentoNome: string = '',
        public dConferencia: string = '',
        public conferenciaId: string = '',
        public conferenciaNome: string = '',
        public sessaoValores: Array<SessaoValoresModel> = new Array<SessaoValoresModel>(),
        public qtdItens: number = 0,
        public qtdCancelamentos: number = 0,
        public valorCancelado: number = 0,
        public valorVendido: number = 0,
    ) { }
}