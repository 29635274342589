import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
      float: 'right',
      width: '100%'
    },

    '&.Mui-focused': {
      background: 'rgba(20,20,20, 0.1)'
    },
    borderRadius: theme.shape.borderRadius,
    '& svg': {
      fill: theme.palette.grey[700]
    },
    '& input::placeholder': {
      color: theme.palette.grey[400]
    },
    color: theme.palette.grey[700],

    [theme.breakpoints.down('sm')]: {
      '&.Mui-focused': {
        background: 'rgba(20,20,20, 0.2)',
        borderColor: '#f00'
      },
      borderRadius: theme.shape.borderRadius,
      '& fieldset': {
        background: 'rgba(0,0,0, 0.1)',
        border: '0px solid #fff'
      },
      '& svg': {
        fill: theme.palette.common.white
      },
      '& input::placeholder': {
        color: theme.palette.common.white
      },
      color: theme.palette.common.white
    }
  }
}));
