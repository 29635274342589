import { Grid, Typography, Button } from '@material-ui/core';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { ComandasFechamentoListData } from './components/comanda-fechamento-list/comandas-fechamento-list-data';
import { useStyles } from './mov-selecionar-comanda-fechamento-styles';

interface LocationProps {
  pedidos: PedidoModelComanda[];
  mesaId: string;
}

export const MovSelecionarComandaFechamentoPage = () => {
  const [comandasSelecionada, setComandasSelecionada] = useState<
    PedidoModelComanda[]
  >([]);

  const location = useLocation<LocationProps>();
  const history = useHistory();
  const classes = useStyles();
  const { goBack } = useHistory();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => {
          goBack();
        }}
      ></ButtonPrivateHeader>
    );
  }, [goBack]);

  const header = () => {
    return (
      <PrivatePageHeader title={`Fechamento Comanda`} leftArea={leftArea()} />
    );
  };

  const onCardComandaSelected = useCallback((comanda: PedidoModelComanda) => {
    setComandasSelecionada((prev: PedidoModelComanda[]) => {
      const temEssaComandaSelecionada = prev.find(
        (c) => c.comandaId === comanda.comandaId,
      );

      if (temEssaComandaSelecionada) {
        return prev.filter(
          (coman) => coman.comandaId !== temEssaComandaSelecionada.comandaId,
        );
      }

      return [...prev, comanda];
    });
  }, []);

  const pedidosAutorizadosParaFechamento = [
    EnumStatusPedido.AGUARDANDO_CONFIRMACAO,
    EnumStatusPedido.CONFIRMADO,
    EnumStatusPedido.EM_PREPARO,
    EnumStatusPedido.FINALIZADOOUENTREGUE,
  ];

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>{header()}</Grid>

        <Grid className={classes.containerInfo}>
          <Grid
            style={{
              display: 'flex',
              background: '#fff',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography style={{ fontWeight: '600' }}>
              Escolha a comanda que deseja realizar o fechamento.
            </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.list}>
          <ComandasFechamentoListData
            comandas={
              location?.state?.pedidos.filter(
                (pedido) =>
                  pedido.valorTotalPedido > 0 &&
                  pedidosAutorizadosParaFechamento.includes(
                    pedido.statusPedido.codigo,
                  ),
              ) ?? []
            }
            carregando={false}
            onCardSelected={onCardComandaSelected}
            comandaSeleciona={comandasSelecionada}
          />
        </Grid>
        <Grid className={classes.containerAcao}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={comandasSelecionada.length === 0 ? true : false}
                onClick={() =>
                  history.push({
                    pathname: '/venda-simples/fechar-comanda',
                    state: { pedidos: comandasSelecionada },
                  })
                }
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
