import { VariaveisAmbiente } from "config";
import { ReactNode } from "react";
import { RelatorioPerformanceProvider } from "./relatorio-performance-provider";

interface RelatorioPerformanceWrapperProps {
    children: ReactNode;
}

export const RelatorioPerformanceWrapper = ({ children }: RelatorioPerformanceWrapperProps) => {

    if (VariaveisAmbiente.performanceDebugMode) {
        return (
            <RelatorioPerformanceProvider>
                {children}
            </RelatorioPerformanceProvider>
        )
    }

    return (
        <>
            {children}
        </>
    )
}