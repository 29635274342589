import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    button: {
        boxSizing:'border-box',
        minWidth:100,
        backgroundColor: '#FFF',
        minHeight: 105,
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#f3f3f3'
        },
        '& .MuiTypography-body1': {
            fontSize: 13,
            fontWeight: 500,
        }
    },
    icon: {
        width: 32,
        height: 32,
        margin: 0,
        fill: theme.palette.secondary.main,
        opacity: '1rem'
    },
    btnDestaque: {
        border: '.5px solid ' + theme.palette.success.main,
        position: 'relative',
        backgroundColor: '#FFF',
    },
    iconDestaque: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        fill: theme.palette.success.main,
        width: 15,
        height: 15,
    },
    title: {
        fontWeight: 700
    },
    cards: {
        opacity: '50%'
    }
}))