import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetEmpresaId() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getEmpresaId = useCallback(
    (empresaId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Empresa/${empresaId}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getEmpresaId,
  };
}
