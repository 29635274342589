import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { CircularLoading } from 'views';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { DinheiroIcon, VoltarIcon } from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useStyles } from './dialog-finalizar-venda-garcom-styles';
import { useMemo } from 'react';
import { calcPercent } from 'utils/calc-percent';
import { roundTo } from 'utils/round-to';
import { useConfirm } from 'material-ui-confirm';
import classNames from 'classnames';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

interface DialogFinalizarVendaGarcomProps {
  openned: boolean;
  loading?: boolean;
  closeModal: () => void;
}

type Valores = {
  porTaxa: number;
  porServico: number;
}

export const DialogFinalizarVendaGarcom = ({
  openned,
  closeModal,
  loading,
}: DialogFinalizarVendaGarcomProps) => {
  const { retornaFluxoVenda, setTipoMovimentacao, getMov } = useMovAtual();
  const { getConfigByCod } = useContratoAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { showToast } = useToastSaurus();
  const confirm = useConfirm()

  const classes = useStyles();
  const history = useHistory();

  const handleClickFinalizarComoVenda = async () => {
    try {
      await setTipoMovimentacao(EnumTipoMovimentacao.VENDA, true);
      const ret = await retornaFluxoVenda();
      if (ret.error) {
        showToast('error', ret.msg);
      }
      history.push(ret.url);
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const handleClickVincularMesa = async () => {
    await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO, true);
    history.push('/venda-simples/vincular-mesa');
  };

  const handleClickVincularComanda = async () => {
    await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO, true);
    history.push('/venda-simples/leitor-comanda');
  };


  const taxaAlterada = useMemo(() => {
    const mov = getMov()
    const produtoTaxa = getConfigByCod(EnumContratoConfig.ProdutoServico)
    const produtos = mov?.produtos.filter(x => x.ativo && x.cobraTaxaServico) ?? []
    const produtosTaxa = mov?.produtos.filter(x => x.ativo && x.produtoId === (produtoTaxa ?? ''))

    const valores: Valores = {
      porTaxa: 0,
      porServico: produtosTaxa?.reduce((a, b) => a + b.vFinal, 0) ?? 0
    }

    produtos.forEach(x => {
      if (!produtosTaxa?.find(y => y.vendedorId === x.vendedorId)?.ativo) {
        return
      }
      const valorPorTaxa = calcPercent(x.vFinal, x.taxaServico ?? 0)
      valores.porTaxa += valorPorTaxa
    })

    return roundTo(valores.porServico) !== roundTo(valores.porTaxa)
  }, [getConfigByCod, getMov])

  const confirmVincularPedido = (tipo: 'mesa' | 'comanda') => {
    confirm({
      title: 'Taxa Alterada',
      description: `Se você vincular em ${tipo === 'mesa' ? 'mesa' : 'comanda'}, o valor da taxa que você alterou vai ser reajustada. Deseja continuar?`
    })
      .then(async () => {
        if (tipo === 'mesa') {
          await handleClickVincularMesa()
          return
        }
        await handleClickVincularComanda()
      })
  }


  return (
    <DialogSaurus
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Vincular / Finalizar venda"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={6}>
          {getConfiguracoesMesaEComanda()?.tipoTrabalho === 1 ? (
            <Button
              disabled={loading}
              variant="contained"
              fullWidth
              color="primary"
              onClick={async () => {
                if (taxaAlterada) {
                  confirmVincularPedido('mesa')
                  return
                }
                await handleClickVincularMesa()
              }}
              className={classNames(classes.button, 'round')}
            >
              <Grid className={classes.buttonContent}>
                <MesaIcon
                  tipo="BUTTON_PRIMARY"
                  style={{
                    width: '40px',
                    height: '40px',
                    margin: 0,
                    marginBottom: '8px',
                  }}
                />
                <Typography variant="button">Vincular em mesa</Typography>
              </Grid>
            </Button>
          ) : (
            <Button
              disabled={loading}
              variant="contained"
              fullWidth
              color="primary"
              onClick={async () => {
                if (taxaAlterada) {
                  confirmVincularPedido('comanda')
                  return
                }
                await handleClickVincularComanda()
              }}
              className={classNames(classes.button, 'round')}
            >
              <Grid className={classes.buttonContent}>
                <ComandasIcon
                  tipo="BUTTON_PRIMARY"
                  style={{
                    width: '40px',
                    height: '40px',
                    margin: 0,
                    marginBottom: '8px',
                  }}
                />
                <Typography variant="button">Vincular em comanda</Typography>
              </Grid>
            </Button>
          )}
        </Grid>

        <Grid item xs={6}>
          <Button
            disabled={loading}
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleClickFinalizarComoVenda}
            className={classNames(classes.button, 'round')}
          >
            <Grid className={classes.buttonContent}>
              <DinheiroIcon
                tipo="BUTTON_PRIMARY"
                style={{
                  width: '40px',
                  height: '40px',
                  margin: 0,
                  marginBottom: '8px',
                }}
              />
              <Typography variant="button">Finalizar como venda</Typography>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{marginBottom: 8}}>
        {closeModal && (
          <Button
            disabled={loading}
            variant="outlined"
            fullWidth
            color="primary"
            onClick={closeModal}
            className='round'
          >
            <VoltarIcon tipo="BUTTON" />
            Voltar
          </Button>
        )}
      </Grid>
    </DialogSaurus>
  );
};
