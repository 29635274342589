import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useCallback } from 'react';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumSincronizacaoGeral } from 'model/enums/enum-sincronizacao-geral';
import { usePostSincronizarValores } from 'data/api/gestao/pontos-venda/post-sincronizar-valores';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { usePostNovaVenda } from 'data/api/gestao/venda/post-nova-venda';
import { useVenda } from './gerenciar-venda';
import { VendaModel } from 'model/api/gestao/venda/venda-model';
import { useSessaoAtual } from '../providers';
import { EnumStatusSincronizacaoGeral } from 'model/enums/enum-status-sincronizacao-geral';
import useFirebase, { EnumColectionFireBase } from './firebase';
import { useEmpresaAtual } from './empresa-atual';
import { useToastSaurus } from './toast-saurus';

export interface ISincronizacaoAtual {
  ativo: boolean;
  status: EnumSincronizacaoGeralStatus;
  sincronizandoAtual: number;
  tipo: EnumSincronizacaoGeral;
  totalAsincronizar: number;
}

export const useSincronizacaoGeral = () => {
  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getEmpresaAtual } = useEmpresaAtual();
  const {
    verificarItensParaSincronizacao,
    getSessao,
    agruparValores,
    converterDados,
    atualizarValores
  } = useSessaoPDV();
  const { callEvent } = useEventTools();
  const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
  const { getVendas, atualizarVendaSinc } = useVenda();
  const { logError } = useFirebase();
  const { showToast } = useToastSaurus();

  // CHAMADAS API
  const { postSincronizarValores } = usePostSincronizarValores();
  const { postNovaVenda } = usePostNovaVenda();

  const getStatusSincronizacaoAtual = useCallback(():
    | ISincronizacaoAtual
    | undefined => {
    const statusUltimaSinc = getRegistro(
      GestaoStorageKeys.SincronizacaoGeralAtual,
      false
    ) as ISincronizacaoAtual;

    if (Object.entries(statusUltimaSinc)?.length === 0) {
      return undefined;
    }
    return statusUltimaSinc;
  }, [getRegistro]);

  const setStatusSincronizacaoAtual = useCallback(
    (sincAtual: ISincronizacaoAtual | undefined) => {
      if (sincAtual === undefined)
        delRegistro(GestaoStorageKeys.SincronizacaoGeralAtual, false);
      else
        setRegistro(
          GestaoStorageKeys.SincronizacaoGeralAtual,
          sincAtual,
          false
        );
    },
    [delRegistro, setRegistro]
  );

  const sincronizarVendas = useCallback(async () => {
    const vendas = await getVendas();

    if (!vendas || vendas.length === 0) {
      return false;
    }

    let sincAtual = 0;

    for (; sincAtual < vendas?.length; sincAtual++) {
      setStatusSincronizacaoAtual({
        ativo: true,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        sincronizandoAtual: sincAtual + 1,
        tipo: EnumSincronizacaoGeral.Vendas,
        totalAsincronizar: vendas?.length
      });

      callEvent(AppEventEnum.SincronizacaoGeral, {
        ativo: true,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        sincronizandoAtual: sincAtual + 1,
        tipo: EnumSincronizacaoGeral.Vendas,
        totalAsincronizar: vendas?.length
      });

      const venda = vendas[sincAtual] as VendaModel;

      const respostaPost = await postNovaVenda(venda);
      if (respostaPost.erro) {
        const erro = {
          mensagem: respostaPost.erro?.message,
          stack: respostaPost.erro?.stack,
          statusCode: respostaPost.statusCode
        };

        const token = getRegistro(GestaoStorageKeys.Token, false);

        const objToLog = {
          empresa: getEmpresaAtual()?.nomeFantasia,
          documento: getEmpresaAtual()?.cpfcnpj,
          empresaId: getEmpresaSelecionada()?.Id ?? '',
          venda: venda,
          vendaId: venda.id,
          vendaString: JSON.stringify(venda),
          erro,
          data: new Date(),
          token,
          mod: venda.mod
        };

        try {
          await logError(objToLog, EnumColectionFireBase.VENDAS);
        } catch (e: any) {

        }

        if (respostaPost.statusCode === 412) {
          showToast(
            'error',
            'Não foi possível realizar a sincronização da venda. Motivo: Você excedeu o limite de vendas do seu plano atual. Para continuar com a sincronização das suas vendas, faça um upgrade do seu plano.',
            10000
          );
        }

        continue;
      }

      await atualizarVendaSinc(vendas[sincAtual].id);
    }

    callEvent(AppEventEnum.AtualizarVendaOffiline, 1);
    return true;
  }, [
    atualizarVendaSinc,
    callEvent,
    getEmpresaAtual,
    getEmpresaSelecionada,
    getRegistro,
    getVendas,
    logError,
    postNovaVenda,
    setStatusSincronizacaoAtual,
    showToast
  ]);

  const sincronizarValoresSessao = useCallback(async () => {
    const sessao = await getSessao();

    if (!sessao) {
      return false;
    }

    const valores = await verificarItensParaSincronizacao();

    if (!valores || valores.length === 0) {
      return false;
    }

    const agrupados = agruparValores(valores, 50);
    const convertidos = agrupados.map((grupo) => converterDados(grupo));

    let sincAtual = 0;

    for (; sincAtual < convertidos?.length; sincAtual++) {
      setStatusSincronizacaoAtual({
        ativo: true,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        sincronizandoAtual: sincAtual + 1,
        tipo: EnumSincronizacaoGeral.SessaoValores,
        totalAsincronizar: convertidos?.length
      });

      callEvent(AppEventEnum.SincronizacaoGeral, {
        ativo: true,
        status: EnumSincronizacaoGeralStatus.EmAndamento,
        sincronizandoAtual: sincAtual + 1,
        tipo: EnumSincronizacaoGeral.SessaoValores,
        totalAsincronizar: convertidos?.length
      });

      const dadosParaSinc = convertidos[sincAtual];

      const respostaPost = await postSincronizarValores(
        getEmpresaSelecionada()!.Id,
        dadosParaSinc[0].caixaId,
        dadosParaSinc
      );

      if (respostaPost.erro) {
        const token = getRegistro(GestaoStorageKeys.Token, false);

        const objToLog = {
          empresa: getEmpresaAtual()?.nomeFantasia,
          documento: getEmpresaAtual()?.cpfcnpj,
          empresaId: getEmpresaSelecionada()?.Id ?? '',
          valores: valores,
          valoresString: JSON.stringify(valores),
          error: respostaPost?.erro ?? {},
          data: new Date(),
          token
        };

        await logError(objToLog, EnumColectionFireBase.VALORES);
        throw respostaPost.erro;
      }

      await atualizarValores(agrupados[sincAtual]);
    }

    return true;
  }, [
    agruparValores,
    atualizarValores,
    callEvent,
    converterDados,
    getEmpresaAtual,
    getEmpresaSelecionada,
    getRegistro,
    getSessao,
    logError,
    postSincronizarValores,
    setStatusSincronizacaoAtual,
    verificarItensParaSincronizacao
  ]);

  const iniciarSincronizacaoGeral = useCallback(
    async (callback?: () => void) => {
      try {
        const sincronizandoValores = await sincronizarValoresSessao();
        const sincronizandoVendas = await sincronizarVendas();

        if (!sincronizandoVendas && !sincronizandoValores)
          return EnumStatusSincronizacaoGeral.Vazio;

        setStatusSincronizacaoAtual({
          ativo: false,
          status: EnumSincronizacaoGeralStatus.Finalizado,
          sincronizandoAtual: 0,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        callEvent(AppEventEnum.SincronizacaoGeral, {
          ativo: false,
          status: EnumSincronizacaoGeralStatus.Finalizado,
          sincronizandoAtual: 0,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        if (callback) callback();
        return EnumStatusSincronizacaoGeral.Sucesso;
      } catch (err: any) {
        setStatusSincronizacaoAtual({
          ativo: false,
          status: EnumSincronizacaoGeralStatus.Erro,
          sincronizandoAtual: 0,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        callEvent(AppEventEnum.SincronizacaoGeral, {
          ativo: false,
          status: EnumSincronizacaoGeralStatus.Erro,
          sincronizandoAtual: 0,
          tipo: EnumSincronizacaoGeral.SessaoValores,
          totalAsincronizar: 0
        });

        return EnumStatusSincronizacaoGeral.Erro;
      }
    },
    [
      callEvent,
      setStatusSincronizacaoAtual,
      sincronizarValoresSessao,
      sincronizarVendas
    ]
  );

  return {
    // SINCRONIZACAOATUAL
    getStatusSincronizacaoAtual,
    setStatusSincronizacaoAtual,

    iniciarSincronizacaoGeral
  };
};
