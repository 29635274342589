import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogAlterarQuantidadeClientes } from 'views/components/dialog/dialog-alterar-quantidade-clientes/dialog-alterar-quantidade-clientes';

class AlterarQuantidadeClientesProps {
  constructor(
    public aberto: boolean = false,
    public alterarQuantidade: (quantidadeClientes: number) => void = () => {},
    public qtdeInicial: number = 0,
    public comanda: boolean = false,
  ) {}
}

export const AlterarQuantidadeClientesDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<AlterarQuantidadeClientesProps>(
    new AlterarQuantidadeClientesProps()
  );

  consoleDev('AlterarQuantidadeClientesDialog');

  const modalAlterado = useCallback(
    ({ aberto, alterarQuantidade, qtdeInicial, comanda}: any) => {
      setModalState({
        aberto,
        alterarQuantidade,
        qtdeInicial,
        comanda
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogAlterarQuantidadeClientes, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogAlterarQuantidadeClientes, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogAlterarQuantidadeClientes
          openned={modalState.aberto}
          alterarQuantidade={modalState.alterarQuantidade}
          comanda
          qtdeInicial={modalState.qtdeInicial}
        />
      )}
    </>
  );
};
