import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    contentTab: {
        margin: theme.spacing(2),
    },
    limitPopUp: {
        zIndex: 1, 
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(0),
        right: theme.spacing(0),
    }
}));
