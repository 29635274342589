import { guidEmpty } from 'utils/guid-empty';
export class ProdutoModificadorModel {
  constructor(
    public id: string | null = null,
    public modificadorId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public produtoGrade: string = '',
    public ordem: number = 0,
    public valor: number = 0,
  ) { }
}
