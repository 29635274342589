import { KeyValueModel } from "model";
import { EnumCargaTipo } from "model/enums/enum-carga-tipo";
import { EnumUploadCargaStatus } from "model/enums/enum-upload-carga-status";

export const TpCargaTipoMock: KeyValueModel[] = [
    new KeyValueModel(EnumCargaTipo.ATUALIZAR, "Atualizar"),
    new KeyValueModel(EnumCargaTipo.ADICIONAR, "Adicionar"),
    new KeyValueModel(EnumCargaTipo.ANALISANDO, "Analisando"),
    new KeyValueModel(EnumCargaTipo.REJEITADO, "Rejeitado"),
    new KeyValueModel(EnumCargaTipo.PRONTO, "Pronto"),
    new KeyValueModel(EnumCargaTipo.FALHA, "Falha"),
    new KeyValueModel(EnumCargaTipo.SEM_ALTERACOES, "Sem Alterações")
];

export const TpUploadCargaStatusMock: KeyValueModel[] = [
    new KeyValueModel(EnumUploadCargaStatus.Aguardando, "Aguardando"),
    new KeyValueModel(EnumUploadCargaStatus.Analisando, "Analisando"),
    new KeyValueModel(EnumUploadCargaStatus.AguardandoProcessamento, "Pendente"),
    new KeyValueModel(EnumUploadCargaStatus.Processando, "Processando"),
    new KeyValueModel(EnumUploadCargaStatus.Processado, "Processado"),
    new KeyValueModel(EnumUploadCargaStatus.Cancelado, "Cancelado"),
    new KeyValueModel(EnumUploadCargaStatus.Erro, "Erro")
];
