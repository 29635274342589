import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetSetorById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getSetorById = useCallback(
    (setorId: string, empresaId: string) =>
      invocarApi({
        url: `/v5/empresa/${empresaId}/setor/${setorId}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    getSetorById,
  };
}
