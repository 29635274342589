import { usePostMarca } from "data/api/gestao/marca/post-marca";
import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { useToastSaurus } from "./toast-saurus";
import { useSessaoAtual } from "../providers";


export const useMarca = () => {

    const { postMarca, carregando } = usePostMarca();
    const { showToast } = useToastSaurus();
    const { getEmpresaSelecionada } = useSessaoAtual();

    const addNovaMarca = async (marca: MarcaModel, ) => {
        try {
            marca.contratoId = getEmpresaSelecionada()?.ContratoId || '';
            const res = await postMarca(marca);

            if(res.erro) throw res.erro;

            return res.resultado?.data as MarcaModel
        } catch (e: any) {
            showToast('error', e.message)
            return null
        }
    }

    return {
        addNovaMarca,
        carregando
    };
};