import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogAviso } from "views/components/dialog/dialog-aviso/dialog-aviso";

class AvisoDialogProps {
    constructor(
        public aberto: boolean = false,
        public mode: 'success' | 'error' | 'info' | 'warning' = 'success',
        public text: string = '',
        public showCloseButton?: boolean,
        public permitirFechar?: boolean,
    ) { }
}


export const AvisoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<AvisoDialogProps>(
        new AvisoDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, mode, text, showCloseButton, permitirFechar }: AvisoDialogProps) => {
        setModalState({
            aberto,
            mode,
            text,
            showCloseButton,
            permitirFechar,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogAviso, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogAviso, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogAviso
                    aberto={modalState.aberto}
                    mode={modalState.mode}
                    text={modalState.text}
                    showCloseButton={modalState.showCloseButton}
                    permitirFechar={modalState.permitirFechar}
                />
            )}
        </>
    )
}