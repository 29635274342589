import { SetorCadastroFormModel } from "model/app/forms/setor/setor-cadastro-form-model";
import { ConfigInicialMesasEcomandasModel } from "./config-inicial-mesas-e-comandas-model";
import { ConfigInicialPerfis } from "./config-inicial-permissoes";
import { HorarioModel } from "../horarios-funcionamento/horario-model";
import { ConfigInicialCardapioModel } from "./config-inicial-cardapio-model";
import { ConfigNfceFormModel } from "model/app/forms/master/configuracoes-nfce-form-model";
import { PlanoModel } from "../plano/plano-model";


export class ConfigInicialModel {
        constructor(
            public mesasEcomandas: ConfigInicialMesasEcomandasModel | null = new ConfigInicialMesasEcomandasModel(),
            public setores: Array<SetorCadastroFormModel> | null = null,
            public perfis: ConfigInicialPerfis | null = new ConfigInicialPerfis(), 
            public horario: HorarioModel | null = new HorarioModel(), 
            public cardapio: ConfigInicialCardapioModel | null = new ConfigInicialCardapioModel(), 
            public nfce: ConfigNfceFormModel | null = new ConfigNfceFormModel(),
            public suporte: number | null = 0,
            public plano: PlanoModel | null = null
        ){}

}




