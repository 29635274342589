import { KeyValueModel } from "model";
import { EnumModFrete } from "model/enums/enum-mod-frete";

export const TpModFreteMock: KeyValueModel[] = [
    new KeyValueModel(EnumModFrete.CONTRATACAO_DESTINATARIO, 'Contratação do Frete por conta do Destinatário (FOB)'),
    new KeyValueModel(EnumModFrete.CONTRATACAO_REMETENTE, 'Contratação do Frete por conta do Remetente (CIF)'),
    new KeyValueModel(EnumModFrete.CONTRATACAO_TERCEIROS, 'Contratação do Frete por conta de Terceiros'),
    new KeyValueModel(EnumModFrete.PROPRIO_DESTINATARIO, 'Transporte Próprio por conta do Destinatário'),
    new KeyValueModel(EnumModFrete.PROPRIO_REMETENTE, 'Transporte Próprio por conta do Remetente'),
    new KeyValueModel(EnumModFrete.SEM_TRANSPORTE, 'Sem Ocorrência de Transporte'),
]