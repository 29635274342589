import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { MeuUsuarioPutModel } from 'model/api/gestao/master/meu-usuario';
import { VariaveisAmbiente } from 'config';

export function usePutMeuUsuario() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putMeuUsuario = useCallback(
    (usuario: MeuUsuarioPutModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/Usuario/meu-usuario`,
        method: 'PUT',
        data: usuario,
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putMeuUsuario
  };
}
