import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetProdutoVariacaoModificador() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoVariacaoModificador = useCallback(
    (produtoId: string, variacaoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Produto/${produtoId}/variacao/${variacaoId}/modificadores`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoVariacaoModificador,
  };
}
