import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    alert: {
        marginBottom: "40px",
        padding: "15px",
        maxWidth: "500px"
    },
    content: {
        marginBottom: '0px',
        padding: '8px'
    },
    textAlert: {
        textAlign: "center",
    },
}));
