import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormMesaValidation = (openMesa: boolean, openComanda: boolean) => {
  const FormMesaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        mesaInicial: openMesa ?  Yup.string().required('Mesa inical é obrigatória').min(1).typeError('Mesa inical é obrigatória') : Yup.string().notRequired().nullable(),        
        mesaFinal: openMesa  ?  Yup.string().required('Mesa final é obrigatória').typeError('Mesa final é obrigatória') : Yup.string().notRequired().nullable(),
        comandaInicial: openComanda ? Yup.string().required('Comanda inical é obrigatória').typeError('Comanda inical é obrigatória') :Yup.string().notRequired().nullable(),
        comandaFinal: openComanda ? Yup.string().required('Comanda final é obrigatória').typeError('Comanda final é obrigatória'): Yup.string().notRequired().nullable(),
      })
    )
  }, [openComanda, openMesa]);

  return {
    FormMesaValidationYup,
  }
}