import { PerfilModel } from "model/api/gestao/perfil/perfil-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePostPerfil() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPerfil = useCallback(
    (perfil: PerfilModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Perfil`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(perfil),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return { 
    ...outrasPropriedades,
    postPerfil,
  };
} 
