import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormProdutoFiscalCadastroValidation = () => {

  const FormProdutoFiscalCadastroValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        descricao: Yup.string().required('Descrição do Grupo Necessária.'),
        cfop: Yup.string().required('CFOP é obrigatório.').min(4, 'CFOP informado está inválido').max(4, 'CFOP informado está inválido'),
        icmsCst: Yup.number(),
        pisCst: Yup.number(),
        cofinsCst: Yup.number(),
      })
    )
  }, [])
  return {
    FormProdutoFiscalCadastroValidationYup,
  }
}

