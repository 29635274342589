import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: '1 1 100%',
        flexDirection: 'column',
        display: 'flex',
    },
    stepContent: {
        gap: theme.spacing(2)
    },
    root: {
        height: '100%'
    },
    buttons: {
        padding: theme.spacing(1, 2),
        paddingBottom: theme.spacing(2)
    }
}));
