import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  btnConfirmar: {
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.dark
    }
  },
  btnConfirmarTodosMedicamentos: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark
    }
  },
  titleQtd: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: 70,
    backgroundColor: theme.palette.grey[300],
    minWidth: 70
  },
  titleQtdNumber: {
    fontWeight: 700
  },

  titleQtdRestante: {
    backgroundColor: '#1F9CE4',
    color: theme.palette.common.white
  },
  titleQtdRestanteTodos: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white
  },
  contentInfoMed: {
    margin: theme.spacing(-2),
    marginBottom: 0,
    backgroundColor: theme.palette.common.white,
    minHeight: 65,
    boxShadow: '0px 2.5px 4px 0px rgba(40, 41, 61, 0.25)'
  },
  codEan: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 700,
    lineHeight: 1
  },
  titleQtdContent: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    '& p': {
      fontSize: 10,
      fontWeight: 700
    },
    '& h4': {
      fontSize: 28
    }
  },
  titleMedicamento: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1
  },
  listContainer: {
    '& .card': {
      margin: theme.spacing(1, 0)
    }
  },
  divider: {
    height: 2
  }
}));
