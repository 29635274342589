import { Badge, Button, Grid, Tooltip } from '@material-ui/core';
import { useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { useStyles } from './carrinho-button-styles';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { formatDecimalInteger } from 'utils/to-decimal';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';

export const CarrinhoButton = () => {
  const { getMov } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const carrinhoBtn = useRef<HTMLButtonElement | null>(null)
  const history = useHistory();
  const classes = useStyles();
  const { isMobile, theme } = useThemeQueries();

  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();

  const retQtdItens = useCallback(() => {
    const mov = getMov();
    const prodServico = getContratoConfigByCod(EnumContratoConfig.ProdutoServico);

    const qtdProdutos = mov?.produtos.filter(produtoPai => [EnumTpProduto.Produto, EnumTpProduto.ProdutoComSubItem, EnumTpProduto.Combo, EnumTpProduto.Medicamento].includes(produtoPai.tpProduto) && produtoPai.ativo)

    const qtdSomada = qtdProdutos?.reduce((acc, current) => acc + current.qCom, 0) ?? 0;

    let quantidade = qtdSomada

    const prodTaxaNaVenda = mov?.produtos.filter(prod => prod.produtoId === prodServico);

    if (prodTaxaNaVenda) {
      quantidade = quantidade <= 0 ? quantidade : (quantidade - (prodTaxaNaVenda.length ?? 0));
    }

    return quantidade;
  }, [getContratoConfigByCod, getMov]);

  const [qtdeItens, setQtdeItens] = useState<number | undefined>(retQtdItens());

  const movProdAlterado = useCallback((prod: MovSimplesProdutoModel) => {
    setQtdeItens((prevState: number | undefined) => {
      if (retQtdItens() !== prevState || 0) {
        carrinhoBtn.current?.classList.add(classes.fePulse)
        setTimeout(() =>
          carrinhoBtn.current?.classList.remove(classes.fePulse), 500)
        return retQtdItens();
      }
      return prevState;
    });
  }, [classes.fePulse, retQtdItens]);

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);


  const navigateToCarrinho = () => {
    history.push('/venda-simples/carrinho');
  };

  return (
    <Grid item className={classes.containerItemFooter}>
      <Tooltip arrow title={"Visualizar o Carrinho de Compras"}>
        <Button
          fullWidth
          ref={carrinhoBtn}
          variant="contained"
          color="primary"
          className={`${classes.textPreview} round`}
          style={{ padding: 0, marginLeft: theme.spacing(1), width: isMobile ? '57px' : '57px', height: isMobile ? '57px' : '57px' }}
          onClick={navigateToCarrinho}
        >
          <Badge
            badgeContent={qtdeItens && formatDecimalInteger(qtdeItens)}
            color={
              process.env.REACT_APP_ENV === 'prodSafraWeb' ? 'secondary' : 'error'
            }
            classes={{
              badge: classes.badge
            }}
          >
            <CarrinhoIcon tipo='BUTTON_FAB'></CarrinhoIcon>
          </Badge>
        </Button>
      </Tooltip>
    </Grid>
  );
};
