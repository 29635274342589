import { Grid, Typography, Button, Box } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './venda-leitor-fragment-styles';
import { VendaHeader } from '../components/produtos/produto-header/produto-header';
import Scanner from './components/scanner/scanner';
import { useToastSaurus } from 'services/app';
import { useThemeQueries } from 'views';
import { ArrowDownIcon } from 'views/components/icons/arrow-down-icon';
import { CarrinhoButton } from '../components/buttons-actions/carrinho-button/carrinho-button';
import { FinalizarVendaButton } from '../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { ButtonKeyboardBackspace } from 'views/components/keyboard/components/button-keyboard-backspace/button-keyboard-backspace';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { ButtonKeyboardTopMultiply } from 'views/components/keyboard/components/button-keyboard-top-multiply/button-keyboard-top-multiply';
import { KeypadIcon } from 'views/components/icons/keypad-icon';
import { ToastLeitor } from '../components/toast-leitor/toast-leitor';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import classNames from 'classnames';
import { useDigitarCodigo } from 'services/app/hooks/digitar-codigo';

interface ProdLeitorProps {
  handleAddByCodigo: () => void;
  textChanged: (text: string, formattedText: string) => Promise<boolean>;
  submit: () => Promise<boolean>;
  add: () => Promise<boolean>;
}

const ProdLeitor = ({ handleAddByCodigo, textChanged, submit, add }: ProdLeitorProps) => {
  //STATES E REFS
  const { get, set } = useDigitarCodigo();
  const [digitarCodigo, setDigitarCodigo] = useState(get());

  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')
  const valueRef = useRef<HTMLParagraphElement | null>(null);
  const valueCodRef = useRef<HTMLParagraphElement | null>(null)
  const btnAddRef = useRef<HTMLDivElement | null>(null)
  const btnFinRef = useRef<HTMLDivElement | null>(null)

  //PROVIDERS
  const { inserirProdutoByCodigo } = useMovProd();
  const { callEvent } = useEventTools()
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
    addMultiply,
  } = useKeyboard({
    maxLength: 20,
    floatCases: 0,
    isNumeric: false,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual
  });

  //AUX E Outros
  const classes = useDefaultCadastroStyles();
  const classesLeitor = useStyles();

  const { addHandler, removeHandler } = useEventTools()

  const attValueDisplay = useCallback(() => {

    const v = getFormattedText();
    const vReplace = v.replace(/\*/g, 'x')

    if (valueCodRef.current) {
      valueCodRef.current.textContent = vReplace
    }
    if (valueRef.current) {
      if (v.length === 0) {
        valueRef.current.textContent = "Aguardando Digitação"
        valueRef.current.className = classesLeitor.textVisorAguardando
        if (btnFinRef.current) btnFinRef.current.style.display = 'flex'
        if (btnAddRef.current) btnAddRef.current.style.display = 'none'
        return
      }
      valueRef.current.textContent = vReplace
      valueRef.current.className = classesLeitor.textVisor
      if (btnFinRef.current) btnFinRef.current.style.display = 'none'
      if (btnAddRef.current) btnAddRef.current.style.display = 'flex'
    }
  }, [classesLeitor.textVisor, classesLeitor.textVisorAguardando, getFormattedText])

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler])

  const { showToast } = useToastSaurus();
  const { theme } = useThemeQueries();

  const digitos = getFormattedText();

  const possuiScanner =
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO &&
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO_DEV;

  const exibirDigitarCodigo = digitarCodigo || !possuiScanner;

  useEffect(() => {
    if (!exibirDigitarCodigo) callEvent(AppEventEnum.PermiteEntradaDigitada, true)

    return () => {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }
  }, [callEvent, exibirDigitarCodigo])


  const handleClickDigitarCodigo = useCallback(() => {
    setDigitarCodigo(!digitarCodigo);
    set(!digitarCodigo)
  }, [digitarCodigo, set]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    }, [addValue]);

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleTextMultiply = useCallback(
    (value: any) => {
      addMultiply(value);
    },
    [addMultiply],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    handleAddByCodigo();
    resetText('');
    submitKey();
  }, [handleAddByCodigo, resetText, submitKey]);

  const inserirProduto = useCallback(
    async (codProduto: string) => {
      try {
        await inserirProdutoByCodigo(getFormattedText() + codProduto);
      } catch (err: any) {
        showToast('error', err.message);
      } finally {
        resetText('');
      }
    },
    [getFormattedText, inserirProdutoByCodigo, resetText, showToast],
  );

  const RenderFluxoInicialLeitor = memo(() => {
    return (
      <>
        <Grid className={classesLeitor.content}>
          <Grid style={{ flex: 1, display: 'block' }}>
            <ButtonKeyboardTopMultiply
              handleButtonMultiplyValue={handleTextMultiply}
            />
          </Grid>
        </Grid>

        <Button
          className={classNames(classesLeitor.button, 'round')}
          onClick={handleClickDigitarCodigo}
          variant="outlined"
          color="primary"
          style={{ height: '60px' }}
        >
          <KeypadIcon tipo="BUTTON" fill={theme.palette.primary.main} />

          <Typography className={classesLeitor.textButton}>
            Inserir Código Manualmente
          </Typography>
        </Button>
      </>
    );
  });

  const teclado = useMemo(() => {
    return (
      <>
        <Grid
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto'
          }}
        >
          <Keyboard
            isButtonMultiplyTopKeyboard
            isButtonMultiply
            handleMultiplyValue={handleTextMultiply}
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric={false}
            isButtonComma
          />
        </Grid>
      </>
    );
  }, [
    handleAdd,
    handleAddValue,
    handleBackSpace,
    handleSubmit,
    handleText,
    handleTextMultiply,
  ]);

  const butonBackspace = useMemo(() => {
    return (
      <Grid style={{ marginRight: 15 }}>
        <ButtonKeyboardBackspace alt="apagar" clickFunc={handleBackSpace} />
      </Grid>
    );
  }, [handleBackSpace]);

  useEffect(() => {

  }, [digitos])

  const renderInserirCodigoManual = useCallback(() => {
    return (
      <>
        <Grid className={classesLeitor.visorCodigo}>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {possuiScanner && (
              <Grid>
                <Button
                  onClick={handleClickDigitarCodigo}
                  variant="outlined"
                  color="primary"
                  style={{
                    marginLeft: '8px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    padding: '0',
                  }}
                >
                  <ArrowDownIcon
                    tipo="GERAL"
                    fill={theme.palette.primary.main}
                  />
                </Button>
              </Grid>
            )}
            <Grid className={classesLeitor.contentVisor}>
              <Typography className={classesLeitor.label}>
                Código do Produto
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                ref={valueRef}
              >
                {getFormattedText().replace(/\*/g, 'x') || "Aguardando Digitação"}
              </Typography>
            </Grid>
            {butonBackspace}
          </Grid>
        </Grid>
        {teclado}
      </>
    );
  }, [
    butonBackspace,
    classesLeitor.contentVisor,
    classesLeitor.label,
    classesLeitor.visorCodigo,
    getFormattedText,
    handleClickDigitarCodigo,
    possuiScanner,
    teclado,
    theme.palette.primary.main
  ]);

  const leituraRealizada = async (novoCod: string) => {
    await inserirProduto(novoCod);
  };

  const header = useMemo(() => {
    return (
      <Grid className={classNames(classes.header, classesLeitor.headerHighSide)}>
        <VendaHeader
          title="Leitor de Código"
        />
      </Grid>
    );
  }, [classes.header, classesLeitor.headerHighSide]);

  const butonsCarrinho = useMemo(() => {
    return <CarrinhoButton />;
  }, []);

  const finalizarButton = useMemo(() => {
    return <FinalizarVendaButton />;
  }, []);

  const adicionarButton = useMemo(() => {
    return (
      <Grid item xs={12} className={classesLeitor.containerItemFooter}>
        <Button
          onClick={() => {
            handleAddByCodigo();
            resetText('');
          }}
          variant="contained"
          color="primary"
          fullWidth
          className={classesLeitor.textButton}
          style={{ height: '57px', color: '#fff' }}
        >
          Adicionar
        </Button>
      </Grid>
    );
  }, [
    classesLeitor.containerItemFooter,
    classesLeitor.textButton,
    handleAddByCodigo,
    resetText,
  ]);

  const toastLeitor = useMemo(() => (
    <ToastLeitor />
  ), [])

  return (
    <Grid className={classes.root}>
      {header}
      <div style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        position: 'relative'
      }}>
        {exibirDigitarCodigo ? (
          <></>
        ) : (
          <Grid className={classesLeitor.containerPreviewInfo}>
            <Typography className={classesLeitor.textPreview}>
              Passe o código do produto no scanner
            </Typography>
            <Typography
              ref={valueCodRef}
              className={classesLeitor.textPreview}
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {digitos}
            </Typography>
          </Grid>
        )}

        {possuiScanner && (
          !exibirDigitarCodigo ? (
            <>
              <Scanner
                beep
                onScan={leituraRealizada}
                inicialOrSecundario={false}
              />
            </>
          ) : (
            renderInserirCodigoManual()
          )
        )}

        {/* {exibirDigitarCodigo ? (
        renderInserirCodigoManual()
      ) : (
        <RenderFluxoInicialLeitor />
      )} */}
        <Box width="100%" display={"flex"} flexDirection="column" bgcolor={"#f5f5f5"} zIndex="2">
          {possuiScanner && !exibirDigitarCodigo && <RenderFluxoInicialLeitor />}

          <Grid className={classesLeitor.containerFooter}>
            {butonsCarrinho}
            <Grid ref={btnAddRef} container justifyContent='center' alignItems='center' style={{ display: 'none' }}>
              {adicionarButton}
            </Grid>
            <Grid ref={btnFinRef} container justifyContent='center' alignItems='center'>
              {finalizarButton}
            </Grid>
          </Grid>
        </Box>
        {toastLeitor}
      </div>
    </Grid>
  );
};

export default ProdLeitor;
