import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    containerButtonBottom: {
        padding: '8px'
    },
    itemButtonBottom: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textList: {
        listStyleType: 'circle',
    }
}))