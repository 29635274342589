import { NFrefModel, RefNfModel } from "model/api/gestao/venda/venda-completa-model"
import { Grid, Typography } from "@material-ui/core";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import classNames from "classnames";
import { TipoDocRefMock } from "data/mocks/tipo-doc-ref-mock";
import { useStyles } from "../card-doc-referenciados-styles";
import { useThemeQueries } from "views/theme";
import { EditarIcon } from "views/components/icons";

interface Props {
    model: NFrefModel
}

export const DocReferenciadoNF = ({ model }: Props) => {
    const { aamm, cnpj, nNF, serie } = model.refNF || new RefNfModel()
    const myClasses = useStyles()
    const valorReal = Object.entries(model).find(x => x[1])
    const getKey = Object.keys(model).filter(x => x === valorReal?.[0] || '')
    const { isMobile } = useThemeQueries()
    return (
        <>
            <Grid container spacing={1} >
                <Grid item xs={6} >
                    <Grid className={myClasses.content}>
                        <Typography color="primary" variant="body2">
                            Tipo de Documento
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body1"
                        >
                            {TipoDocRefMock.filter(x => x.Key === getKey[0])[0].Value}
                        </Typography>
                    </Grid>
                    <Grid className={myClasses.content}>
                        <Typography color="primary" variant="body1">
                            CPF/CNPJ
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body1"
                        >
                            {formatarCPFCNPJ(cnpj)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid className={myClasses.content}>
                        <Typography color="primary" variant="body1">
                            Número
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body1"
                        >
                            {nNF}
                        </Typography>
                    </Grid>
                    <Grid className={myClasses.content}>
                        <Typography color="primary" variant="body1">
                            Ano/Mês
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', myClasses.valores)}
                            color="textPrimary"
                            variant="body1"
                        >
                            {aamm}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2} className={myClasses.content}>
                    <Typography color="primary" variant="body1">
                        Série
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', myClasses.valores)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {(`000` + serie).substr(-3)}
                    </Typography>
                </Grid>
                {isMobile &&
                    <Grid xs={1} item style={{ display: 'flex' }} alignItems="center" justifyContent="flex-end">
                        <EditarIcon tipo='BUTTON' />
                    </Grid>
                }
            </Grid>



        </>
    )
}