import { Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GestaoStorageKeys, useGestaoStorage } from "services/app";
import { PublicPageHeader } from "views/components/headers";
import { SelecionarEmpresaList } from "./components/selecionar-empresa-list/selecionar-empresa-list";
import { useStyles } from "./selecionar-empresa-styles";

const SelecionarEmpresaPage = () => {
    const { exists } = useGestaoStorage()
    const history = useHistory()

    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)

    const callbackUrl = urlParams.get('callbackUrl')

    useEffect(() => {
        if (!exists(GestaoStorageKeys.Token, false)) {
            history.push('/login')
        }
    }, [exists, history])


    const classes = useStyles()

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Grid className={classes.container}>
                <Grid className={classes.content}>
                    <Grid className={classes.infoContainer}>
                        <Typography className={classes.textTitle}>
                            Empresas
                        </Typography>
                        <Typography className={classes.textSubtitle}>
                            Escolha a empresa para prosseguir.
                        </Typography>
                        <SelecionarEmpresaList callbackUrl={callbackUrl} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SelecionarEmpresaPage