import { guidEmpty } from 'utils/guid-empty';

export class MeuUsuarioModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public email: string | null = null,
    public apelido: string | null = null,
    public imagem: string | null = null,
    public status: number = 0,
    public userKey: string | null = null,
    public userFacebook: string | null = null,
    public userGoogle: string | null = null,
    public codAcessoSuporte: string | null = null ,
    public expiracaoAcessoSuporte: string | null = null,
    public telefone: string | null = null
  ) {}
}

export class MeuUsuarioPutModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = '',
    public email: string = '',
    public apelido: string = '',
    public imagem: string = '',
    public status: number = 0,
    public telefone: string = ''
  ) {}
}
