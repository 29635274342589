import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px'
  },
  statusFechada: {
    background: theme.palette.warning.main
  },
  statusConferida: {
    background: theme.palette.success.main
  },
  statusPendente: {
    background: theme.palette.primary.main
  },
  buttonImpressora: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttons: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    padding: 0,
    '& svg': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '0px'
    }
  }
}));
