export enum tipoEdit {
  Resume = 0,
  Stepper = 1,
  Itens = 2,
  Pagamento = 3
}

export enum Steppers {
  nfe = 0,
  products = 1,
  payments = 2,
  resume = 3
}

export interface OpcoesEntradaImportacaoXMLTemplateProps {
  carregando: boolean;
  setEditing: (value: React.SetStateAction<number>) => void;
}

export interface OpcoesEntradaImportacaoXMLStepperTemplateProps
  extends OpcoesEntradaImportacaoXMLTemplateProps {
  activeStep: number;
  setActiveStep: (value: React.SetStateAction<number>) => void;
  setPaymentEditing: (
    value: React.SetStateAction<{ index: number; isCreating: boolean, remainingValue: number }>
  ) => void;
}

export interface OpcoesEntradaImportacaoXMLPagamentoTemplateProps
  extends OpcoesEntradaImportacaoXMLTemplateProps {
  paymentEditing: { index: number; isCreating: boolean, remainingValue: number };
}
