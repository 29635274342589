import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const ApagarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0,0,256,256">
          <g transform="translate(42.24,42.24) scale(0.67,0.67)"><g fill={props.fill} fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><path d="M128,319.04478c-105.51112,0 -191.04478,-85.53366 -191.04478,-191.04478v0c0,-105.51112 85.53366,-191.04478 191.04478,-191.04478v0c105.51112,0 191.04478,85.53366 191.04478,191.04478v0c0,105.51112 -85.53366,191.04478 -191.04478,191.04478z" id="shape"></path></g><g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="translate(-9.43,-0.512) scale(5.12,5.12)"><path d="M12.54297,10l-12.85937,15l12.85938,15h33.45703c2.19922,0 4,-1.80078 4,-4v-22c0,-2.19922 -1.80078,-4 -4,-4zM13.45703,12h32.54297c1.11719,0 2,0.88281 2,2v22c0,1.11719 -0.88281,2 -2,2h-32.53906l-11.14453,-13zM22.70703,18.29297l-1.41406,1.41406l5.29297,5.29297l-5.29297,5.29297l1.41406,1.41406l5.29297,-5.29297l5.29297,5.29297l1.41406,-1.41406l-5.29297,-5.29297l5.29297,-5.29297l-1.41406,-1.41406l-5.29297,5.29297z"></path></g></g></g>
        </svg>

      </DefaultIcon>
    </>
  );
};
