import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 100,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        overflowY: 'auto',
        overfloX: 'hidden',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden'
        },
        '&::-webkit-scrollbar': {
            height: theme.spacing(0.5),
            width: theme.spacing(0.5)
        }
    },
    ocultar: {
        display: 'none'
    },
    iconDelivery: {
        fill: "#FFF",
        width: 40,
        height: 40,
    },
    contentDelivery: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(0, 1.1),
        alignItems: 'center',
    }
}))