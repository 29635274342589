import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    carrouselFullscreen:
    {
        height: '100%',
        "& .rec-carousel-wrapper": {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
        },
        "& .rec-carousel": {
            height: "80% !important",
        },
        "& .rec-slider": {
            height: "100%",
        },
        "& .rec-slider-container": {
            margin: 0,
        },
        "& .rec-item-wrapper": {
            height: "100%",
        },
    },
    carrouselNormal: {
        "& .rec-carousel-wrapper": {
        },
        "& .rec-carousel": {
        },
        "& .rec-slider": {
        },
        "& .rec-slider-container": {
        },
        "& .rec-item-wrapper": {
            padding: "10px !important",
        },
    },
    carrouselContainer: {
        width: '100%',
        minHeight: '550px',
        display: 'flex',
        "& .rec.rec-arrow": {
            borderRadius: '50%',
            color: '#fff',
            backgroundColor: theme.palette.primary.main
        },
        "& .rec.rec-arrow:hover:enabled": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main
        },
        "& .rec.rec-arrow:disabled": {
            visibility: 'hidden'
        },
        "& .rec-carousel-item:focus": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main,
            outline: 'none',
            boxShadow: 'inset 0 0 1px 1px lightgrey',
        },
        "& .rec.rec-arrow,.rec.rec-arrow-left,.rec.rec-arrow-right": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main
        },
        "& .rec-pagination": {
            marginTop: '0px'
        },
    },
    carrouselContainerNoMinHeight: {
        width: '100%',
        display: 'flex',
        "& .rec.rec-arrow": {
            borderRadius: '50%',
            color: '#fff',
            backgroundColor:  theme.palette.primary.main
        },
        "& .rec.rec-arrow:hover:enabled": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main
        },
        "& .rec.rec-arrow:disabled": {
            visibility: 'hidden'
        },
        "& .rec-carousel-item:focus": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main,
            outline: 'none',
            boxShadow: 'inset 0 0 1px 1px lightgrey',
        },
        "& .rec.rec-arrow,.rec.rec-arrow-left,.rec.rec-arrow-right": {
            color: '#fff',
            backgroundColor:  theme.palette.primary.main
        },
        "& .rec-pagination": {
            marginTop: '0px'
        },
    },
    carrouselContainerEscuro: {
        "& .rec-dot,.rec.rec-dot_active,.rec.rec-dot_active:focus": {
            color: '#fff',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderWidth: 1,
            borderColor: "#FFF",
            borderStyle: "solid",
        },
        "& .rec.rec-dot_active,.rec.rec-dot_active:focus": {
            color: '#fff',
            backgroundColor: '#FFF',
            borderWidth: 1,
            borderColor: "#FFF",
            borderStyle: "solid",
            boxShadow: 'none',
        },
        "& .rec-dot:hover": {
            color: '#fff',
            backgroundColor: '#FFF',
            boxShadow: 'none',
        }
    },
    carrouselContainerClaro: {
        "& .rec-dot,.rec.rec-dot_active,.rec.rec-dot_active:focus": {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
        },
        "& .rec.rec-dot_active,.rec.rec-dot_active:focus": {
            backgroundColor: theme.palette.primary.main,
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            boxShadow: 'none',
        },
        "& .rec-dot:hover": {
            backgroundColor: '#FFF',
            boxShadow: 'none',
        }
    },
}));
