import * as Yup from 'yup';

export const useValidationYupFone = () => {
    const foneYup = () => Yup.string().nullable().when({
        is: (val: string) => val && val.length > 0,
        then: Yup.string().nullable().min(14, "Telefone inválido."),
        otherwise: Yup.string().nullable().notRequired()
    })

    return {
        foneYup
    }
}