import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardRightButton: {
    paddingLeft: 8,
    height: '100%',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      margin: 0,
      width: '22px',
      height: '22px',
      fill: theme.palette.text.primary
    }
  },
  cardLeftButton: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  celulaGrid: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      fontWeight: 500,
      fontSize: '13px'
    },
    [theme.breakpoints.down('xs')]: {
      '& p, span': {
        fontSize: '13px'
      }
    }
  },
  label: {
    fontSize: 10
  },
  textConteudo: {
    fontSize: 12,
    fontWeight: 500
  }
}));
