import { Box, Divider, Grid, Typography } from '@material-ui/core';

import FormEntradaCadastroNFE from './form-entrada-cadastro-xml-nfe';
import FormEntradaCadastroResumoItens from './form-entrada-cadastro-xml-resumo-itens';
import FormEntradaCadastroEmpresa from './form-entrada-cadastro-empresa';

export default function FormEntradaCadastroImportacaoXML() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h6">Dados da NF-e</Typography>
            <Divider />
          </Box>
        </Grid>
        <FormEntradaCadastroNFE />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h6">Fornecedor - Emitente</Typography>
            <Divider />
          </Box>
        </Grid>
        <FormEntradaCadastroEmpresa
          docField='infMov.emit.doc'
          razaoSocialField='infMov.emit.xNome'
          logradouroField='infMov.emit.xLgr'
          municipioField='infMov.emit.xMun'
          pessoaIdField='infMov.emit.pessoaId'
          ufField='infMov.emit.uf'
          bairroField='infMov.emit.xBairro'
          codigoMunicipioField='infMov.emit.cMun'
          numeroField='infMov.emit.nro'
          titulo='O Fornecedor'
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h6">Empresa - Destinatário</Typography>
            <Divider />
          </Box>
        </Grid>
        <FormEntradaCadastroEmpresa
          docField='infMov.dest.documento'
          razaoSocialField='infMov.dest.xNome'
          logradouroField='infMov.dest.xLgr'
          municipioField='infMov.dest.xMun'
          pessoaIdField='infMov.dest.pessoaId'
          ufField='infMov.dest.uf'
          bairroField='infMov.dest.xBairro'
          codigoMunicipioField='infMov.dest.cMun'
          indIEDestField='infMov.dest.indIEDest'
          numeroField='infMov.dest.nro'
          titulo='O Destinatário'
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h6">Transportadora</Typography>
            <Divider />
          </Box>
        </Grid>
        <FormEntradaCadastroEmpresa
          docField='infMov.transp.doc'
          razaoSocialField='infMov.transp.xNome'
          logradouroField='infMov.transp.xEnder'
          municipioField='infMov.transp.xMun'
          pessoaIdField='infMov.transp.pessoaId'
          ufField='infMov.transp.uf'
          titulo='A Transportadora'
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h6">Resumo dos Itens</Typography>
            <Divider />
          </Box>
        </Grid>
        <FormEntradaCadastroResumoItens />
      </Grid>
    </>
  );
}
