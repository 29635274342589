import { VariaveisAmbiente } from 'config';
import { useApiBase } from 'data/api/base';
import { useCallback } from 'react';

export class IdentidadeAutenticarApelidoProps {
    public constructor(
        public apelido: string = '',
        public senha: string = '',
        public filtros?: {
            contratoId?: string
        }
    ) { }
}

export function usePostIdentidadeAutenticarApelido() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarApelido = useCallback(
        (autenticacaoProps: IdentidadeAutenticarApelidoProps) =>
        invocarApi({
            method: 'POST',
            url: `/${VariaveisAmbiente.gatewayVersion}/Auth/token-apelido`,
            data: autenticacaoProps,
            enviarTokenUsuario: false,
        }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarApelido,
    };
}
