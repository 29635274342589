import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    removerBtn: {
        width: 56,
        height: 56,
        margin: 0,
        padding: 0,
        marginLeft: theme.spacing(1)
    },
    iconPessoa: {
        margin: 0,
        width: 20
    },
    nome: {
        fontWeight: 600,
        fontSize: 13
    },
    disabledIcon: {
        opacity: .3
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    itemContent:{
        margin: theme.spacing(2, 0)
    },
    itemInput:{
        margin: theme.spacing(0, 0, 3, 0)
    }
}))