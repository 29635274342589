import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogTrocarCliente } from 'views/components/dialog/dialog-trocar-cliente/dialog-trocar-cliente';

class TrocarClienteDialogProps {
    constructor(
        public aberto: boolean = false,
        public finalizar: () => Promise<void> = async () => {},
    ) { }
}

export const TrocarClienteDialog = () => {

    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<TrocarClienteDialogProps>(new TrocarClienteDialogProps());

    const { fecharTrocarClienteDialog } = useCadastros()

    const modalAlterado = useCallback(({ aberto, finalizar }: TrocarClienteDialogProps) => {
        setModalState({
            aberto: aberto,
            finalizar
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.TrocarClienteDialog, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.TrocarClienteDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogTrocarCliente
                    closeDialog={fecharTrocarClienteDialog}
                    openned={modalState.aberto}
                    finalizar={modalState.finalizar}
                />
            )}
        </>
    );
};