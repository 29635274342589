import { PessoaModel } from "model/api/gestao/pessoa";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
export function usePutPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putPessoa = useCallback(
    (pessoa: PessoaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${pessoa.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          ...pessoa,
          fantasia: '',
        }),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putPessoa,
  };
}
