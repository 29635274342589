import * as Yup from "yup";
import { useMemo } from "react";

export const useFormProdutoNcmPreCadastroValidation = () => {
  const FormProdutoNcmYupValidation = useMemo(() => {
    return Yup.object().shape({
      imposto: Yup.string().required("Nos informe o grupo de imposto do produto."),
      codigoNcm: Yup.string().required("Nos informe o código NCM do produto."),
    });

  }, []);

  return {
    FormProdutoNcmYupValidation,
  };
};
