import { Redirect, Route, Switch } from 'react-router-dom';
import { MainPage } from 'views/pages/private/main/main-page';
import ConfiguracoesPage from 'views/pages/private/configuracoes/configuracoes-page';
import PessoaPage from 'views/pages/private/cadastros/pessoa/pessoa-page';
import FinalizadoraPage from 'views/pages/private/cadastros/finalizadora/finalizadora-page';
import CadastrosPage from 'views/pages/private/cadastros/cadastros/cadastros-page';
import CategoriaPage from 'views/pages/private/cadastros/categoria/categoria-page';
import NcmPage from 'views/pages/private/cadastros/ncm/ncm-page';
import ProdutoPage from 'views/pages/private/cadastros/produto/produto-page';
import PerfisPage from 'views/pages/private/cadastros/perfis/perfis-page';
import DashboardPage from 'views/pages/private/relatorios/dashboard/dashboard-page';
import ImpostosPage from 'views/pages/private/cadastros/impostos/impostos-page';
import PontosVendaPage from 'views/pages/private/pontos-venda/pontos-venda-page';
import { HistoricoVendaPage } from 'views/pages/private/historico-vendas/historico-vendas-page';
import { VariaveisAmbiente } from 'config';
import RouterVenda from '../router-venda';
import AlterarPlanoPage from 'views/pages/private/plano/alterar-plano/alterar-plano-page';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import MovCancelarPage from 'views/pages/private/movimentacao/mov-cancelar/mov-cancelar-page';
import UploadCargaPage from 'views/pages/private/cadastros/upload-carga/upload-carga-page';
import UploadCargaDetalhesPage from 'views/pages/private/cadastros/upload-carga-detalhes/upload-carga-detalhes-page';
import AdicionarEmpresaPage from 'views/pages/private/empresas/adicionar-empresa/adicionar-empresa';
import ConfiguracoesEmpresaPage from 'views/pages/private/empresas/configuracoes/configuracoes-page';
import ListaEmpresasPage from 'views/pages/private/empresas/lista-empresas/lista-empresas-page';
import DocumentoFiscalPage from 'views/pages/private/fiscal/documento-fiscal/documento-fiscal-page';
import { AtivarRevisarNFCE } from 'views/pages/private/fiscal/ativar-revisar-nfc-e/ativar-revisar-nfce-page';
import DashboardAdminPage from 'views/pages/private/relatorios/dashboard-admin/dashboard-admin-page';
import SincronizacaoPage from 'views/pages/private/sincronizacao-dados/sincronizar/sincronizacao-page';
import SincronizacaoSucessoErroPage from 'views/pages/private/sincronizacao-dados/sincronizacao-sucesso-erro-listagem/sincronizacao-sucesso-erro-page';
import { SemConexaoRoute } from '../sem-conexao-route';
import DepositoPage from 'views/pages/private/cadastros/deposito/deposito-page';
import { RenderModais } from 'views/components/render-modais/render-modais';
import SetoresProcessosPage from 'views/pages/private/cadastros/setores-processos/setores-processos-page';
import { isPlanoRelatoriosBasicos } from 'utils/plano-utils';
import ImpressorasPage from 'views/pages/private/cadastros/impressoras/impressoras-page';
import GerenciarSessaoPage from 'views/pages/private/gerenciar-sessao/sessao-seletor/geranciar-sessao-page';
import SessaoSangriaPage from 'views/pages/private/gerenciar-sessao/sessao-sangria/sessao-sangria-page';
import SessaoDespesaPage from 'views/pages/private/gerenciar-sessao/sessao-despesa/sessao-despesa-page';
import SessaoReforcoPage from 'views/pages/private/gerenciar-sessao/sessao-reforco/sessao-reforco-page';
import SessaoReceitaPage from 'views/pages/private/gerenciar-sessao/sessao-receita/sessao-receita-page';
import { SessaoLeituraCaixaPage } from 'views/pages/private/gerenciar-sessao/sessao-leitura-caixa/sessao-leitura-caixa-page';
import { SessaoFechamentoCaixaPage } from 'views/pages/private/gerenciar-sessao/sessao-fechamento-caixa/sessao-fechamento-caixa-page';
import SincronizarSessaoPage from 'views/pages/private/gerenciar-sessao/sessao-sincronizacao-dados/sessao-sincronizar/sessao-sincronizar-page';
import SincronizarSessaoFechamentoPage from 'views/pages/private/gerenciar-sessao/sessao-sincronizacao-dados/sessao-sincronizar-fechamento/sessao-sincronizar-fechamento-page';
import { SuporteComponent } from 'routers/components/suporte/suporte';
import { useSessaoAtual } from 'services/app';
import { DetalhesVendaPage } from 'views/pages/private/detalhes-venda/detalhes-venda-page';
import MesasComandasPage from 'views/pages/private/cadastros/mesas-comandas/mesas-comandas-page';
import SaloesPage from 'views/pages/private/cadastros/saloes/saloes-page';
import { PerformanceDebugPage } from 'views/pages/private/relatorios/performance-debug/performance-debug-page';
import AutenticacaoPage from 'views/pages/private/autenticacao/autenticacao-page';
import { WhiteLabelProvider } from 'services/app/providers/whitelabel-provider';
import ModificadoresPage from 'views/pages/private/cadastros/modificadores/modificadores-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import EntradaPage from 'views/pages/private/entrada/entrada-page';
import OpcoesEntradaPage from 'views/pages/private/entrada/pages/opcoes-entrada/opcoes-entrada-page';
import DadosEntradaPage from 'views/pages/private/entrada/pages/dados-entrada/dados-entrada-page';
import DadosEmitentePage from 'views/pages/private/entrada/pages/dados-emitente/dados-emitente-page';
import DadosTransportadoraPage from 'views/pages/private/entrada/pages/dados-transportadora/dados-transportadora-page';
import DadosDestinatarioPage from 'views/pages/private/entrada/pages/dados-destinatario/dados-destinatario-page';
import InfoComplementaresPage from 'views/pages/private/entrada/pages/info-complementares/info-complementares-page';
import InfoPedidoCompraPage from 'views/pages/private/entrada/pages/info-pedido-compra/info-pedido-compra-page';
import DocReferenciadosPage from 'views/pages/private/entrada/pages/doc-referenciados/doc-referenciados-page';
import DadosPagamentoPage from 'views/pages/private/entrada/pages/dados-pagamento/dados-pagamento-page';
import SituacaoEntradaPage from 'views/pages/private/entrada/pages/situacao-entrada/situacao-entrada-page';
import DadosProdutoPage from 'views/pages/private/entrada/pages/dados-produto/dados-produto-page';
import NovoProdutoEntradaPage from 'views/pages/private/entrada/pages/novo-produto/novo-produto-page';
import MovGerenciamentoSessoesPage  from 'views/pages/private/movimentacao/mov-gerenciamento-sessoes/mov-gerenciamento-sessoes-page';
import ManifestosPage from 'views/pages/private/manifestos/manifestos-page';
import ImportacaoEntradaXMLPage from 'views/pages/private/entrada/pages/importacao-xml/opcoes-entrada-page';
import MarcaPage from 'views/pages/private/cadastros/marca/marca-page';
import { consoleDev } from 'utils/console-dev';
import { CentralAdministrativa } from 'views/pages/private/movimentacao/mov-central-administrativa/central-page';

export const RouterMain = () => {
  const { plano } = useSessaoAtual();
  consoleDev('RouterMain');

  return (
    <WhiteLabelProvider>
      <MainPage>
        <SemConexaoRoute>
          <Switch>
            <Route path="/configuracoes" component={ConfiguracoesPage} />
            <Route
              path="/sincronizar-resumo"
              component={SincronizacaoSucessoErroPage}
            />
            <Route
              path="/venda-simples/sincronizar-dados"
              component={SincronizacaoPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao"
              component={GerenciarSessaoPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-sangria"
              component={SessaoSangriaPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-despesa"
              component={SessaoDespesaPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-reforco"
              component={SessaoReforcoPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-receita"
              component={SessaoReceitaPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-leitura-caixa"
              component={SessaoLeituraCaixaPage}
            />
            <Route
              exact
              path="/venda-simples/administrativo"
              component={CentralAdministrativa}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-fechamento-caixa"
              component={SessaoFechamentoCaixaPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-sincronizar"
              component={SincronizarSessaoPage}
            />
            <Route
              path="/venda-simples/gerenciar-sessao-sincronizar-fechamento"
              component={SincronizarSessaoFechamentoPage}
            />
            <Route
              path="/upload-carga/detalhes/:id"
              component={UploadCargaDetalhesPage}
            />
            <Route path="/upload-carga" component={UploadCargaPage} />
            <Route path="/adicionar-empresa" component={AdicionarEmpresaPage} />
            <Route
              path="/configuracoes-empresa/:id"
              component={ConfiguracoesEmpresaPage}
            />
             <Route path="/gerenciamento-sessoes" component={MovGerenciamentoSessoesPage}/>
          
        
            <Route path="/pessoas" component={PessoaPage} />
            <Route path="/lista-empresas" component={ListaEmpresasPage} />
            <Route path="/formas-de-pagamento" component={FinalizadoraPage} />
            <Route path="/outros-cadastros" component={CadastrosPage} />
            <Route path="/categorias" component={CategoriaPage} />
            <Route path="/depositos" component={DepositoPage} />
            <Route path="/ncms" component={NcmPage} />
            <Route path="/produtos">
              <ProdutoPage tipo={EnumTipoProduto.Produto} />
            </Route>
            <Route path="/insumos">
              <ProdutoPage tipo={EnumTipoProduto.Insumo} />
            </Route>
            <Route path="/combos">
              <ProdutoPage tipo={EnumTipoProduto.Combo} />
            </Route>
            <Route path="/impostos" component={ImpostosPage} />
            <Route path="/permissoes" component={PerfisPage} />
            <Route path="/entrada-mercadoria" exact component={EntradaPage} />
            <Route
              path="/entrada-mercadoria/visualizar/:movId"
              exact
              component={OpcoesEntradaPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/entrada"
              component={DadosEntradaPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/emitente"
              component={DadosEmitentePage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/transportadora"
              component={DadosTransportadoraPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/destinatario"
              component={DadosDestinatarioPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/info-complementares"
              component={InfoComplementaresPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/info-pedido-compra"
              component={InfoPedidoCompraPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/doc-referenciados"
              component={DocReferenciadosPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/pagamento"
              component={DadosPagamentoPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/situacao-entrada"
              component={SituacaoEntradaPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/produtos"
              exact
              component={DadosProdutoPage}
            />
            <Route
              path="/entrada-mercadoria/visualizar/:movId/produtos/:prodId"
              component={NovoProdutoEntradaPage}
            />
            <Route path="/manifestos" component={ManifestosPage}/>
            <Route
              path="/entradas/visualizar/:id/importacao-xml"
              component={ImportacaoEntradaXMLPage}
            />
            <Route
              path="/venda-simples/cancelar/:id"
              component={MovCancelarPage}
            />

            <Route path="/gerenciar" component={MesasComandasPage} />
            <Route path="/saloes" component={SaloesPage} />
            <Route path="/setor" component={SetoresProcessosPage} />

            <Route path="/pontos-de-venda" component={PontosVendaPage} />
            <Route path="/modificadores" component={ModificadoresPage} />
            <Route path="/marcas" component={MarcaPage} />
            <Route path="/venda-simples" component={RouterVenda} />
            <Route path="/alterar-plano" component={AlterarPlanoPage} />
            <Route path="/historico" component={HistoricoVendaPage} />
            <Route path="/detalhes-venda/:id" component={DetalhesVendaPage} />
            <Route path="/documentos" component={DocumentoFiscalPage} />
            <Route path="/ativar-revisar-nfc-e" component={AtivarRevisarNFCE} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route path="/dashboard-admin" component={DashboardAdminPage} />
            <Route path="/impressoras" component={ImpressorasPage} />
            <Route path="/autenticacao" component={AutenticacaoPage} />
            {VariaveisAmbiente.performanceDebugMode && (
              <Route
                path="/relatorio-performance"
                component={PerformanceDebugPage}
              />
            )}
            <Route path="/">
              {VariaveisAmbiente.paymentDevice !== EnumDeviceType.NAVIGATOR ? (
                <Redirect to="/venda-simples/landing?verificarCadastros=true" />
              ) : !isPlanoRelatoriosBasicos(plano?.plano) ? (
                <Redirect to="/historico" />
              ) : (
                <Redirect to="/dashboard" />
              )}
            </Route>
          </Switch>
        </SemConexaoRoute>
        <SuporteComponent />
        <RenderModais />
      </MainPage>
    </WhiteLabelProvider>
  );
};
export default RouterMain;
