import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCadastros, useToastSaurus } from '.';
import { useMovAtual } from './mov-atual';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useSessaoAtual } from '../providers';

export function useValidarVenda() {
  const { showToast } = useToastSaurus();
  const { finalizarMov, carregando } = useMovAtual();
  const history = useHistory();
  const { abrirSolicitarPermissao, fecharSolicitarPermissao } = useCadastros();
  const { getPermissaoBoolean } = useSessaoAtual();

  const validarVendaAtual = useCallback(
    async (mod: number | null = null, checked: boolean = false) => {
      try {
        if (
          !getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_VENDAS) &&
          !checked
        ) {
          abrirSolicitarPermissao(
            async () => {
                fecharSolicitarPermissao();
                setTimeout(async () => {
                    await validarVendaAtual(mod, true);
                }, 300)
            },
            EnumCodigosPermissoes.REALIZAR_VENDAS,
            'Realizar Venda',
            undefined,
            undefined,
            () => {
                history.push('/venda-simples/carrinho')
            }
          );
          return;
        }

        const res = await finalizarMov(mod);

        // se o retorno for sucesso redireciona para comprovante
        if (res.error) {
          if ((res.error?.message?.length || 0) < 200) {
            if (res.error.message !== 'Cliente não identificado!') {
              showToast('error', res.error, 3000, 'bottom-center', 50);
            }
          }

          if (res.url.length > 0) {
            return history.push(`${res.url}`);
          }
        }

        return history.push({
          pathname: `${res.url}`
        });
      } catch (e: any) {
        showToast(
          'error',
          e.message ? e.message : e,
          3000,
          'bottom-center',
          50
        );
      }
    },
    [abrirSolicitarPermissao, fecharSolicitarPermissao, finalizarMov, getPermissaoBoolean, history, showToast]
  );

  return {
    validarVendaAtual,
    carregando
  };
}
