import { useCallback } from "react";
import { useApiBase } from "../base/api-base";
import { VariaveisAmbiente } from "config/enviroment";

export function useGetNcmsMaster() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getNcmsMaster = useCallback(
    (codigo: string) => {
      return invocarApi({
        url: `/v1/ncms?Codigo=${codigo}&Page=1&PageSize=50`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlNcms}/api`,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getNcmsMaster,
  };
}
