import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Divider, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { toDateString } from 'utils/to-date';
import { DupCobrModel } from 'model/api/gestao/venda/venda-completa-model';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { roundTo } from 'utils/round-to';

interface FormEntradaCadastroNovoPagamentoResumoProps extends DefaultFormProps<DupFormModel> {
  index: number | null;
  parcelaSelected: number;
}

export interface DupFormModel {
  dups: DupCobrModel[];
}

export const FormEntradaCadastroNovoPagamentoResumo = forwardRef<
  DefaultFormRefs<DupFormModel>,
  FormEntradaCadastroNovoPagamentoResumoProps>(({
    index,
    parcelaSelected,
    ...props
  }: FormEntradaCadastroNovoPagamentoResumoProps, ref) => {
    const {
      control,
      getValues,
      formState: { errors, touchedFields }
    } = useFormContext();

    const [dups, setDups] = useState<DupCobrModel[]>([])

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        props.onSubmit({
          dups
        })
      },
      fillForm: () => { },
      resetForm: () => { }
    }))

    useEffect(() => {
      let dups = (getValues(`infMov.cobr.dup`) || []) as DupCobrModel[];
      const vPag = roundTo(getValues(`infMov.pag.${index}.vPag`) / parcelaSelected, 2);
      if (dups.length > 0) {
        const pagDups = dups.filter(x => x.pagId === getValues(`infMov.pag.${index}.id`));
        if (pagDups.length === 0) {
          let lastIndex = Number(dups[dups.length - 1].nDup) + 1

          for (let i = 0; i < parcelaSelected; i++) {
            dups.push(
              new DupCobrModel(lastIndex > 9 ? '0' + lastIndex : '00' + lastIndex, '', vPag, getValues(`infMov.pag.${index}.id`))
            )
            lastIndex++;
          }
        } else {
          if (parcelaSelected > pagDups.length) {
            dups = dups.map(dup => {
              if (dup.pagId === getValues(`infMov.pag.${index}.id`)) {
                dup.vDup = vPag;
              }
              return dup
            })
            const parcelasFaltantes = parcelaSelected - pagDups.length;
            let lastIndex = Number(dups[dups.length - 1].nDup) + 1;
            for (let i = 0; i < parcelasFaltantes; i++) {
              dups.push(
                new DupCobrModel(lastIndex > 9 ? '0' + lastIndex : '00' + lastIndex, '', vPag, getValues(`infMov.pag.${index}.id`))
              )
              lastIndex++;
            }
          } else if (parcelaSelected === pagDups.length) {
            dups = dups.map(dup => {
              if (dup.pagId === getValues(`infMov.pag.${index}.id`)) {
                dup.vDup = vPag;
              }
              return dup
            })
          } else {
            let removerIndexes = 0;
            let dupsAMais = pagDups.length - parcelaSelected;
            dups = dups.filter((dup, i) => {
              if (dup.pagId === getValues(`infMov.pag.${index}.id`) && removerIndexes < dupsAMais) {
                removerIndexes += 1;
                return false
              }
              return true
            }).map(dup => {
              if (dup.pagId === getValues(`infMov.pag.${index}.id`)) {
                dup.vDup = vPag;
              }
              return dup
            })
          }
        }
      } else {
        for (let i = 0; i < parcelaSelected; i++) {
          dups.push(
            new DupCobrModel(`00${i}`, '', vPag, getValues(`infMov.pag.${index}.id`))
          )
        }
      }
      const restante = getValues('infMov.total.vnf') - dups.reduce<number>((prev, curr) => prev + curr.vDup, 0)
      if (restante > 0 && restante < 1) {
        dups[dups.length - 1].vDup =
          roundTo(dups[dups.length - 1].vDup + restante);
      }


      setDups(dups)
    }, [getValues, index, parcelaSelected])

    return (
      <>
        {parcelaSelected > 1 && <Grid item xs={6}>
          <TextFieldSaurus
            tipo="NUMERO"
            fullWidth
            variant="outlined"
            label="Parcelas"
            disabled
            allowSubmit
            InputLabelProps={{
              shrink: true
            }}
            placeholder=""
            value={parcelaSelected}
          />
        </Grid>}

        <Grid item xs={6}>
          <Controller
            name={`infMov.pag.${index}.vPag`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="DECIMAL"
                fullWidth
                variant="outlined"
                label="Valor"
                showStartAdornment
                allowSubmit
                InputLabelProps={{
                  shrink: true
                }}
                placeholder=""
                error={Boolean(errors?.vProd?.message)}
                helperText={
                  touchedFields.vProd || errors.vProd
                    ? errors.vProd?.message
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={`infMov.pag.${index}.doc`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="TEXTO"
                fullWidth
                variant="outlined"
                label="Documento"
                allowSubmit
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Ex: 123456789"
                error={Boolean(errors?.vProd?.message)}
                helperText={
                  touchedFields.vProd || errors.vProd
                    ? errors.vProd?.message
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={`infMov.pag.${index}.descricao`}
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                tipo="TEXTO"
                fullWidth
                variant="outlined"
                label="Informação Adicional"
                allowSubmit
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Ex: Parcela paga antes do vencimento"
                error={Boolean(errors?.vProd?.message)}
                helperText={
                  touchedFields.vProd || errors.vProd
                    ? errors.vProd?.message
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        {parcelaSelected > 1 && <Grid item xs={12}>
          <Typography variant='h6'>Vencimento das Parcelas</Typography>
          <Divider />
        </Grid>}
        {dups.filter(dup => dup.pagId === getValues(`infMov.pag.${index}.id`)).map((dup, i) => {
          const dupIndex = dups.indexOf(dup);
          return <Grid item xs={12} md={6}>
            <TextFieldSaurus
              tipo="DATA"
              fullWidth
              variant="outlined"
              label={parcelaSelected > 1 ? `Data de Vencimento (${i + 1}ª Parcela)` : 'Data de Vencimento'}
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              onChange={(e) => {
                const arr = [...dups];
                arr[dupIndex].dVenc = e.target.value;

                setDups(arr);
              }}
              value={toDateString(dup.dVenc, 'YYYY-MM-DD')}
            />
          </Grid>
        })}
      </>
    );
  })