import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetDashboardVendedores() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getDashboardVendedores = useCallback(
        (
            query: string
        ) => {

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/dashboard/vendedores${query}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getDashboardVendedores,
    };
}
