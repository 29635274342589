import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { useCallback, useRef, useState } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils';
import { LoginFormModel } from 'model/app';
import { useGestaoToken, useSessaoAtual, useToastSaurus } from 'services/app';
import { useGetPerfilById } from 'data/api/gestao/perfil';
import { QrCodeIcon, VoltarIcon } from 'views/components/icons';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { DialogScannerPermissao } from './components/dialog-scanner-permissao';
import { RefreshProps, usePostIdentidadeRefresh } from 'data/api/gestao/identidade/post-refresh';
import { useAuth } from 'services/app/hooks/auth';
import { PerfilPermissaoModel } from 'model';
import { EnumTpPermissaoUsuario } from 'model/enums/enum-tp-permissao-usuario';

interface DialogSolicitarPermissaoProps {
    openned: boolean;
    closeModal: () => void;
    cod: number;
    acao: (...args: any[]) => Promise<any>;
    tipoPermissao?: EnumTpPermissaoUsuario;
    valor?: any;
    label?: string;
    cancelAction?: () => void;
}

export const DialogSolicitarPermissao = ({
    openned,
    closeModal,
    acao,
    cod,
    label,
    tipoPermissao = EnumTpPermissaoUsuario.SimNao,
    valor,
    cancelAction,
    ...props
}: DialogSolicitarPermissaoProps) => {
    const { auth, carregando: carregandoAuth } = useAuth();
    const { showToast } = useToastSaurus();
    const { convertToken } = useGestaoToken();
    const { getPessoa } = useSessaoAtual()


    const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
    const { postIdentidadeRefresh, carregando: refreshToken } = usePostIdentidadeRefresh()
    const { getPerfilById, carregando: carregandoPerfilById } = useGetPerfilById();

    const carregando = carregandoPerfilById || refreshToken || carregandoAuth

    const [scanner, setScanner] = useState<boolean>(false)

    const validar = useCallback(async (token: string) => {
        try {
            const user = convertToken(token);
            const perfilIds = Array<string>();
            //PREENCHO OS PERFIS DELE (DISTINCT)
            if (user?.empresa) {
                for (let i = 0; i < user?.empresa.length; i++) {
                    if (perfilIds.indexOf(user?.empresa[i]?.Perfil?.Id) === -1) {
                        perfilIds.push(user?.empresa[i]?.Perfil?.Id);
                    }
                }
            }

            let permitido = false

            //FACO A BUSCA NA API PELOS PERFIS DO USUARIO
            for (let i = 0; i < perfilIds.length; i++) {
                const retApiPerfil = await getPerfilById(perfilIds[i]);

                if (retApiPerfil.statusCode === 400) {
                    throw new Error(`O usuário ${user?.email} não está cadastrado.`);
                }

                if (retApiPerfil.erro) {
                    throw retApiPerfil.erro;
                }

                const permissoes = retApiPerfil.resultado?.data.permissoes as PerfilPermissaoModel[]

                permitido = Boolean(permissoes.find(element => {
                  if(element.codigo === 530106 || element.codigo === 530107){
                    return element.codigo === cod && Number(valor) <= Number(element.valor)
                  }
                  return element.codigo === cod && element.valor === 'true'
                }))
                if (permitido) break
            }

            if (permitido) {
                await acao(token) //caso precise do token para fazer requisição passo ele aqui, basta usar no callback
                closeModal();
                return
            }

            throw new Error('Usuário inserido não possui permissão.')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [acao, closeModal, cod, convertToken, getPerfilById, showToast, valor])

    const handleSubmit = useCallback(async (model: LoginFormModel) => {
        try {
            const res = await auth({ usuario: model.usuario, senha: model.senha, contratoId: getPessoa().pessoa.contratoId ?? '' })

            if (res.statusCode === 403) throw new Error('o usuário informado não é válido.')
            if (res.statusCode === 400) throw new Error('Usuário não identificado.')
            if (res.erro) throw res.erro

            const token = res.resultado?.data.accessToken


            validar(token);
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [auth, getPessoa, showToast, validar])


    const onScan = useCallback(async (jsonObj: string) => {
        try {
            const data = JSON.parse(jsonObj) as RefreshProps
            const res = await postIdentidadeRefresh(data)

            validar(res.resultado?.data.accessToken)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [postIdentidadeRefresh, showToast, validar])

    return (
      <DialogSaurus
        aberto={openned || false}
        titulo="Solicitar Permissão"
        tamanho="sm"
      >
        <Grid container spacing={1} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Typography>
              Você não possui permissão para {label || 'realizar esta ação'}.
              Autentique com um usuário com permissão para concluir esta ação.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLogin
              ref={loginRefs}
              loading={carregando}
              onSubmit={handleSubmit}
              showLoading={carregando}
              marginTop="16px"
              semAutocomplete
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                closeModal();
                cancelAction?.();
              }}
              fullWidth
              className="round"
            >
              <VoltarIcon tipo="BUTTON" />
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => loginRefs.current?.submitForm()}
              fullWidth
              className="round"
            >
              <ConfirmarIcon tipo="BUTTON_PRIMARY" />
              Confirmar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setScanner((prev) => !prev)}
              fullWidth
              className="round"
            >
              <QrCodeIcon tipo="BUTTON" />
              Escanear QRCode
            </Button>
          </Grid>
        </Grid>
        {scanner && (
          <DialogScannerPermissao
            closeModal={() => setScanner(false)}
            handleScan={onScan}
            openned={scanner}
          />
        )}
      </DialogSaurus>
    );
};