import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CircularLoading } from "views/components/utils";
import { useStyles } from "../../dialog-adicionar-produto-balanca-styles";
import { useDevice } from "services/app/hooks/device";
import { CancelarIcon, EditarIcon, SATIcon } from "views/components/icons";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useThemeQueries } from "views/theme";

export interface PesandoProdutoProps {
    submitPeso: (qCom: string) => Promise<void>;
    closeBalanca: () => void;
}

export const PesandoProduto = ({
    submitPeso,
    closeBalanca
}: PesandoProdutoProps) => {

    const { getBalanca, carregando } = useDevice();
    const classes = useStyles();
    const { theme } = useThemeQueries();

    const [erro, setErro] = useState<string>('')

    const capturarPeso = useCallback(async () => {
        setErro('')
        try {
            const peso = await getBalanca()
            await submitPeso(peso)
        } catch (e: any) {
            if(!isNaN(Number(e.message)) && Number(e.message) === 0){
                closeBalanca();
                return
            }
            setErro(e.message)
        }
    }, [closeBalanca, getBalanca, submitPeso])

    useEffect(() => {
        capturarPeso()
    }, [capturarPeso])
    return (
        <Grid className={classes.cardContainer}>
            <Grid className={classes.cardContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            {isEmpty(erro) || carregando ? (
                                <>
                                    <CircularLoading tipo='NORMAL' class={classes.loadingPesando}/>
                                    <Typography>Coloque o produto na balança para capturar o peso.</Typography>
                                </>
                            ) : (
                                <>
                                    <CancelarIcon tipo="GERAL" style={{fill: theme.palette.error.main, width: 100}}/>
                                    <Typography>{erro || 'Falha ao pesar produto. Tente novamente ou insira manualmente.'}</Typography>
                                </>
                        )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={carregando}
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={() => {
                                closeBalanca();
                            }}
                            className='round'
                        >
                            <EditarIcon tipo="BUTTON" />
                            Informar Manualmente
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={carregando}
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                            className='round'
                            onClick={() => capturarPeso()}
                        >
                            <SATIcon tipo="BUTTON_PRIMARY" />
                            Tentar Novamente
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};