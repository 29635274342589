import * as Yup from "yup";
import { useMemo } from "react";

export const useDialogProdutoSemPrecoValidation = () => {
  const DialogProdutoSemPrecoValidation = useMemo(() => {
    return Yup.object().shape({
      vUnCom: Yup.string().required('Adicione um valor.'),
      qCom: Yup.string().required('Adicione uma quantidade.'),
    });
  }, []);

  return {
    DialogProdutoSemPrecoValidation,
  };
};
