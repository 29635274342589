import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    header: {
        flex: "0 1 auto"
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    containerInterno: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: "flex",
        maxWidth: '450px',
        flexDirection: "column",
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(1),
    },
    textSubtitle: {
        textAlign: 'center',
        padding: 20,
        color: '#8D8D8D',
        lineHeight: '23px'
    },
    button: {
        height: '57px'
    },
    field: {
        marginBottom: '8px'
    }
}));