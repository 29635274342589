import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { PDVFiltroModal } from "views/components/modals/pdv-filtro/pdv-filtro-modal";
import { PontosVendaListSearchProps } from "views/pages/private/pontos-venda/components/pontos-venda-list/pontos-venda-list-search-props";

export const PDVFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            termo: '',
            status: 1
        } as PontosVendaListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PDVFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PDVFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if(!modalState.openned){
        return null
    }

    return (
        <PDVFiltroModal openned={modalState.openned} filtros={modalState.filtros} key="mdlPDVFiltro" />
    )
}