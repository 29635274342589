import { Typography, useTheme } from '@material-ui/core';
import { useStyles } from '../../fast-payment-styles';
import { DinheiroIcon, GarcomIcon } from 'views/components/icons';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePDV } from 'services/app/hooks/pdv';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { roundTo } from 'utils/round-to';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { useLocation } from 'react-router-dom';

interface HeaderPaymentProps {
  removerMargem?: boolean;
  fontSize?: number;
}

export const HeaderPayment = ({
  removerMargem = false,
  fontSize = 10
}: HeaderPaymentProps) => {
  // STATES E REFS
  const refFinalizar = useRef<HTMLParagraphElement>(null);
  const refAPagar = useRef<HTMLParagraphElement>(null);
  const refFinalizarQtdItens = useRef<HTMLParagraphElement>(null);
  const refAcrescDesc = useRef<HTMLParagraphElement>(null);
  const refTaxa = useRef<HTMLParagraphElement>(null);
  const { getMov, retornaAcrescimoTotal, retornaDescontoTotal, hasTaxaServicoProduto } = useMovAtual();
  const [aux, setAux] = useState<boolean>(retornaAcrescimoTotal() > retornaDescontoTotal());
  const mov = getMov();

  // HOOKS
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = usePDV();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
  const { plano } = useSessaoAtual();
  const { pathname } = useLocation()

  // AUX
  const classes = useStyles({ removerMargem: removerMargem, fontSize: fontSize });
  const { palette } = useTheme();
  const modeloTrabalho = getConfigByCod(101);
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const acrescimoTotal = useCallback((mov: MovSimplesModel) => {
    let vAcrescTotal = 0;

    mov?.produtos?.map((item) => {
      if (item.ativo) {
        return vAcrescTotal += item.vAcrescUsuario;;
      }
      return item;
    })

    return vAcrescTotal;
  }, [])

  const descontoTotal = useCallback((mov: MovSimplesModel) => {
    let vDescTotal = 0;

    mov?.produtos?.map((item) => {

      if (item.ativo) {
        return vDescTotal += item.vDescUsuario;;
      }
      return item;
    })

    return vDescTotal;
  }, [])

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const retTxtTotal = useCallback(() => {
    const mov = getMov();
    const prodServico = getContratoConfigByCod(
      EnumContratoConfig.ProdutoServico
    );

    let quantidade = mov?.produtos
      .filter((x) => x.ativo && x.indFin && x.produtoId !== getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
      .reduce((prev, curr) => prev + (curr.qComModificador > 0 ? curr.qComModificador : curr.qCom), 0) ?? 0
    let vnfAux = mov?.vNF ?? 0;

    const prodTaxaNaVenda = mov?.produtos.filter(
      (prod) => prod.produtoId === prodServico
    );

    const valorProdTaxa =
      prodTaxaNaVenda?.reduce((a, b) => a + b.vFinal, 0) ?? 0;

    if (isSomentePedido) {
      vnfAux = vnfAux - valorProdTaxa;
    }

    if (refFinalizarQtdItens.current) {
      refFinalizarQtdItens.current.textContent =
        quantidade > 1
          ? formatDecimalInteger(quantidade) + ' itens '
          : formatDecimalInteger(quantidade) + ' item ';
    }

    if (refTaxa.current) {
      let valorTaxa = 0;
      mov?.produtos
        .filter((x) => x.ativo && x.indFin)
        .forEach((x) => {
          if (x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
            valorTaxa += x.vFinal;
        });

      refTaxa.current.textContent = `R$ ${toDecimalString(roundTo(valorTaxa))}`;
    }

    if (refAPagar.current) {
      refAPagar.current.textContent = `R$ ${toDecimalString(
        (mov?.vNF ?? 0) - (mov?.vPago ?? 0),
        2
      )}`;
    }

    if (refAcrescDesc.current) {
      refAcrescDesc.current.textContent = `R$ ${toDecimalString(
        aux ? retornaAcrescimoTotal() - retornaDescontoTotal() : retornaDescontoTotal() - retornaAcrescimoTotal(),
        2
      )}`;
    }

    return `R$ ${toDecimalString(vnfAux ?? 0, 2)}`;
  }, [aux, getContratoConfigByCod, getMov, isSomentePedido, retornaAcrescimoTotal, retornaDescontoTotal]);

  const retTxtTaxa = useCallback(() => {
    const mov = getMov();
    let valorTaxa = 0;
    mov?.produtos
      .filter((x) => x.ativo && x.indFin)
      .forEach((x) => {
        if (x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
          valorTaxa += x.vFinal;
      });

    return `R$ ${toDecimalString(roundTo(valorTaxa))}`;
  }, [getContratoConfigByCod, getMov]);

  const movProdAlterado = useCallback(
    () => {
      if (refFinalizar.current)
        refFinalizar.current.textContent = retTxtTotal();
    },
    [retTxtTotal]
  );

  const movAlterada = useCallback(() => {
    const mov = getMov();
    if (refAPagar.current && mov) {
      refAPagar.current.textContent = `R$ ${toDecimalString(
        (mov?.vNF ?? 0) - (mov?.vPago ?? 0),
        2
      )}`;
      setAux(acrescimoTotal(mov) > descontoTotal(mov))
    }
  }, [acrescimoTotal, descontoTotal, getMov]);

  const updateHeader = useCallback(() => {
    movProdAlterado();
    movAlterada()
  }, [movAlterada, movProdAlterado])

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    addHandler(AppEventEnum.MovAtualAlterada, updateHeader);
    addHandler(AppEventEnum.PagamentoEfetuado, updateHeader);
    addHandler(AppEventEnum.AlterarDisplayKeybordPayment, updateHeader)

    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
      removeHandler(AppEventEnum.MovAtualAlterada, updateHeader);
      removeHandler(AppEventEnum.PagamentoEfetuado, updateHeader);
      removeHandler(AppEventEnum.AlterarDisplayKeybordPayment, updateHeader)
    };
  }, [addHandler, movProdAlterado, removeHandler, updateHeader]);


  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerPartUm}>
          <div style={{ flex: 1 }}>
            <div className={classes.headerItem}>
              <DinheiroIcon
                tipo="BUTTON"
                style={{ width: 12, height: 12 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.labelText}>Total</Typography>
            </div>
            <div className={classes.headerItem}>
              <Typography ref={refFinalizar} className={classes.labelReais}>
                {retTxtTotal()}
              </Typography>
            </div>
            <div className={classes.headerItem}>
              {!pathname.includes('novo-pagamento') && <Typography
                ref={refFinalizarQtdItens}
                className={classes.labelText}
                style={{ fontSize: 10 }}
              ></Typography>}
            </div>
          </div>
          <div className={classes.divider} />
          <div>
            <div className={classes.headerItem}>
              <DinheiroIcon
                tipo="BUTTON"
                style={{ width: 12, height: 12 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.labelText}>{aux ? ' Acréscimo' : ' Desconto'}</Typography>
            </div>
            <div className={classes.headerItem}>
              <Typography ref={refAcrescDesc} className={classes.labelReais}> {`R$ ${toDecimalString(
                aux ? retornaAcrescimoTotal() - retornaDescontoTotal() : retornaDescontoTotal() - retornaAcrescimoTotal(),
                2
              )}`}</Typography>
            </div>
          </div>
          <div className={classes.divider} />
          {!isFarmaceutico && hasTaxaServicoProduto() && <div>
            <div className={classes.headerItem}>
              <GarcomIcon
                tipo="BUTTON"
                style={{ width: 12, height: 12 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.labelText}>Serviço</Typography>
            </div>
            <div className={classes.headerItem}>
              <Typography ref={refTaxa} className={classes.labelReais}>{retTxtTaxa()}</Typography>
            </div>
          </div>}
        </div>
        <div className={classes.headerPartDois}>
          <div className={classes.headerItem}>
            <SacoDinheiroIcon
              tipo="BUTTON"
              style={{ width: 12, height: 12 }}
              fill={palette.secondary.main}
            />
            <Typography className={classes.labelText}>À Pagar</Typography>
          </div>
          <div className={classes.headerItem}>
            <Typography
              ref={refAPagar}
              className={classes.labelReais}
            >{`R$ ${toDecimalString(
              (mov?.vNF ?? 0) - (mov?.vPago ?? 0),
              2
            )}`}</Typography>
          </div>
        </div>
      </div>
    </>
  );
};
