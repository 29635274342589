import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    contentFatura: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        "& > div": {
            paddingBottom: 4,
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    buttonBottom: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    dividerPrincipal: {
        margin: '1rem 0',
        backgroundColor: theme.palette.primary.main
    }
}));
