
import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetSalaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSalaoById = useCallback(
        (empresaId: string, salaoId: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/salao/${salaoId}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getSalaoById,
    };
}