import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { SessaoValoresModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";

export function usePutSessaoValor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putSessaoValor = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string, payload: SessaoValoresModel) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Sessao/${sessaoId}/Valor/${payload.id}`,
                method: "PUT",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(payload),
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putSessaoValor,
    };
}
