import { Box, Tooltip, Typography } from '@material-ui/core';
import { CardProdutoEntradaProps } from './card-produto-entrada-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { toDecimalString } from '../../../../utils/to-decimal';
import { LixoIcon } from 'views/components/icons';
import { useStyles } from './card-produto-entrada-styles'

export const CardProdutoEntrada = ({
  model,
  onClick,
  onRemove,
  selected,
}: CardProdutoEntradaProps) => {
  const classes = useDefaultCardStyles();
  const cardClasses = useStyles();

  // const retornarCorStatus = useCallback((): string => {
  //   return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  // }, [model.ativo, theme.palette.error.main, theme.palette.success.main]);

  const vFinal = model.vProd + model.vFrete + model.vSeg + model.vOutro - model.vDesc

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model.id);
        }}
      >
        {/* <Tooltip
          arrow
          title={model.ativo ? 'Habilitado' : 'Desabilitado'}
          placement="right"
        >
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip> */}
        <div className={classes.cardContent}>
          {model.cEan.length > 0 && (
            <div className={classes.celulaGrid} style={{ minWidth: 100 }}>
              <Typography color="textPrimary" variant="caption">
                Código
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.cEan}</strong>
              </Typography>
            </div>
          )}

          <div className={classes.celulaGridFull} style={{
            textOverflow: 'ellipsis',
            maxWidth: 200
          }}>
            <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.xProd}
            </Typography>
          </div>

          {model?.ncm && model.ncm.length > 0 && (
            <div className={classes.celulaGrid} style={{ textAlign: 'center' }}>
              <Typography color="textPrimary" variant="caption">
                NCM
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model?.ncm}
              </Typography>
            </div>
          )}

          {/* <div className={classes.celulaGrid} style={{ textAlign: 'center' }}>
            <Typography color="textPrimary" variant="caption">
              Valor UN
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model?.vProd}
            </Typography>
          </div> */}

          <div className={classes.celulaGrid} style={{ textAlign: 'center' }}>
            <Typography color="textPrimary" variant="caption">
              Qtde.
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model?.qCom, 3)}
            </Typography>
          </div>

          <div className={classes.celulaGrid} style={{ textAlign: 'right' }}>
            <Typography color="textPrimary" variant="caption">
              Valor  {model.uCom}
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <Tooltip
                arrow
                title={
                  'R$ ' +
                  toDecimalString(vFinal) +
                  ' a cada ' +
                  model.uCom
                }
                placement="right"
              >
                <div>
                  R$ {toDecimalString(model.vUnCom)} {model.uCom}
                </div>
              </Tooltip>
            </Typography>
          </div>

          <div className={classes.celulaGrid} style={{ textAlign: 'right' }}>
            <Typography color="textPrimary" variant="caption">
              Valor Total
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <Tooltip
                arrow
                title={
                  'R$ ' +
                  toDecimalString(vFinal) +
                  ' a cada ' +
                  model.uCom
                }
                placement="right"
              >
                <strong>
                  R$ {toDecimalString(vFinal)}
                </strong>
              </Tooltip>
            </Typography>
          </div>
        </div>
        <Box display='flex' gridGap={1} alignItems='center'>
          <Tooltip arrow title="Editar Produto">
            <div className={classes.cardRightButton}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
          <Tooltip arrow title="Excluir Produto">
            <div className={cardClasses.removeIcon} onClick={(e) => {
              e.stopPropagation();
              onRemove(model.id);
            }}>
              <LixoIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        </Box>
      </DefaultCard>
    </>
  );
};
