export enum EnumFluxoTutorial {
    PRIMEIRAVENDA = 1,
    PAINELADMINISTRATIVO = 2,
    CONFIGURACOESPDV = 3,
    CADASTRODEPRODUTOS = 4,
    CADASTRODEPESSOAS = 5,
    PERFILDEACESSO = 6,
    CONFIGURACOESUSUARIOS  = 7,
    MESAS = 8,
    COMANDAS = 9, 
    CARDAPIO = 10,
    IMPRESSORAS = 11,
    BOASVINDAS = 12,
    WIZARD = 100
}