import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    padding: theme.spacing(1),
  },
  listContainer: {
    "& .card": {
      margin: theme.spacing(1),
    }
  },
  saveOrderBtn: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  btnText: {
    color: theme.palette.common.white
  }
}));
