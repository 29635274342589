import { Tooltip, Typography } from "@material-ui/core";
import { EditarIcon } from "views/components/icons";
import { useDefaultCardStyles } from "../components";
import { CardVolumeTransportadoProps } from "./card-volume-transportado-props";
import { DefaultCard } from "../components/default-card"
import { toDecimalString } from "utils/to-decimal";

export const CardVolumeTransportado = ({ model, onClick, onCheck, selected }: CardVolumeTransportadoProps) => {

  const defaultCardClasses = useDefaultCardStyles();


  return (
    <>
      <DefaultCard isSelected={selected}
        onClick={() => {
          onClick(model);
        }}>

        <div className={defaultCardClasses.cardContent}>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Qtd.
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.qVol}
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Espécie
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.esp}
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Número
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.nVol}
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Marca
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.marca}
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Peso L
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.pesoL, 4)}
            </Typography>
          </div>
          <div
            className={defaultCardClasses.celulaGrid}
          >
            <Typography color="textPrimary" variant="caption">
              Peso B
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.pesoB, 4)}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar volume">
          <div className={defaultCardClasses.cardRightButtonProcesso}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};

