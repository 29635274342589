import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-cancelar-importacao-styles';
import { CancelarImportacaoHeader } from './components/cancelar-importacao-header';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useThemeQueries } from 'views/theme';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { CancelarIcon } from 'views/components/icons';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';

interface LocationProps {
  pedidos: PedidoModel[];
  motivo: string;
}

const MovCancelarImportacaoPage = () => {
  const [number, setNumber] = useState<number>(0);
  const [erro, setErro] = useState<boolean>(false);

  const { removerPedidoDoCarrinho, cancelarMov } = useMovAtual();
  const { state } = useLocation<LocationProps>();

  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const cancelarimportacao = useCallback(
    async (id: string) => {
      await removerPedidoDoCarrinho(id);
    },
    [removerPedidoDoCarrinho],
  );

  const cancelandoImportacoes = useCallback(async () => {
    try {
      if (number < state.pedidos.length) {
        await cancelarimportacao(state.pedidos[number].id);
      }
      if (number === state.pedidos.length - 1) {
        await cancelarMov(state.motivo);
        return;
      }

      setNumber((prev) => prev + 1);
    } catch {
      setErro(true);
    }
  }, [cancelarMov, cancelarimportacao, number, state.motivo, state.pedidos]);

  useEffect(() => {
    (async () => {
      await cancelandoImportacoes();
    })();
  }, [cancelandoImportacoes]);

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.header}>
          <CancelarImportacaoHeader />
        </Grid>
        <Grid className={classes.content}>
          <Grid
            className={
              isMobile ? classes.infoContainer : classes.infoContainerPC
            }
          >
            <Typography className={classes.textTitle}>
              {!erro ? 'Cancelando Importações' : 'Ocorreu um erro'}
            </Typography>
            {!erro ? (
              <Typography
                className={classes.infoTotal}
                style={{ textAlign: 'center' }}
              >
                {number + 1}/{state.pedidos.length}
              </Typography>
            ) : (
              <></>
            )}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 32,
              }}
            >
              {!erro ? (
                <LoadingFinalizacao />
              ) : (
                <Grid className={classes.imageError}>
                  <CancelarIcon tipo="GERAL" fill="#D22" />
                </Grid>
              )}
            </Grid>
            <Grid
              className={!isMobile ? classes.infoCardContent : classes.cardInfo}
            >
              <Grid
                container
                justifyContent="center"
                style={{ flexDirection: 'column', display: 'flex' }}
              >
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {!erro ? (
                    <Typography className={classes.infoTotal}>
                      Aguarde...
                    </Typography>
                  ) : (
                    <Typography className={classes.infoNomeEQuantidade}>
                      Ocorreu um erro ao cancelar as importações
                    </Typography>
                  )}
                </Grid>
                {erro && (
                  <Grid
                    item
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      onClick={async () => {
                        setErro(false);
                        await cancelandoImportacoes();
                      }}
                      color="primary"
                      size="large"
                      style={{ marginTop: 32 }}
                    >
                      Tentar Novamente
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MovCancelarImportacaoPage;
