import * as Yup from "yup";
import { useMemo } from "react";

export const useFormPesquisaValorValidation = () => {
    const FormPesquisaValorYupValidation = useMemo(() => {
        return Yup.object().shape({
            pesquisa: Yup.string().required('Preencha o campo'),
        });
    }, []);

    return {
        FormPesquisaValorYupValidation,
    };
};
