import { VariaveisAmbiente } from 'config';
import { SessaoFechamentoPayload } from 'model/api/gestao/sessao/sessao-valor-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostFecharSessao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postFecharSessao = useCallback(
    (
      empresaId: string,
      caixaId: string,
      sessaoId: string,
      payload: SessaoFechamentoPayload
    ) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/caixa/${caixaId}/sessao/${sessaoId}/fechar`,
        method: 'POST',
        enviarTokenUsuario: true,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(payload),
        baseURL: `${VariaveisAmbiente.apiUrl}/api`
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postFecharSessao
  };
}
