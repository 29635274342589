import { guidEmpty } from "utils/guid-empty";
import { EnumPagTpTransacao } from "model/enums/enum-pag-tptransacao";
import { EnumPagTipo } from "model/enums/enum-pag-tipo";
import { EnumPagTpMod } from "model/enums/enum-pag-tpmod";
import { EnumPagVencimentoTipo } from "model/enums/enum-pag-vencimento-tipo";
import { EnumAmbientePagamento } from "model/enums/enum-ambiente-pagamento";
import { EnumInstituicao } from "model/enums/enum-instituicao-";
import { EnumTipoComunicacao } from "model/enums/enum-tipo-comunicacao";


export class CredenciamentoSafra {
  constructor(
    public instituicao: EnumInstituicao = EnumInstituicao.Safra,
    public tipo: EnumTipoComunicacao = EnumTipoComunicacao.Pix,
    public razaoSocial: string = '',
    public cnpj: string = '',
    public merchantId: string = '',
    public merchantToken: string = '',
    public codigoAtivacao: string = '',
    public chave: string = '',
    public ambiente: EnumAmbientePagamento = EnumAmbientePagamento.Producao,
  ) { }
}

export class FinalizadoraModel {
  constructor(
    public id: string = guidEmpty(),
    public empresaId: string | null = null,
    public descricao: string = "",
    public resumido: string = "",
    public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL,
    public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
    public tpPagamento: EnumPagTipo = EnumPagTipo.AMBOS,
    public vDesc: number = 0,
    public vAcresc: number = 0,
    public pDesc: number = 0,
    public pAcresc: number = 0,
    public vMinParc: number = 0,
    public qMaxParc: number = 1,
    public vencimento: EnumPagVencimentoTipo = EnumPagVencimentoTipo.FIXO,
    public qDias: number = 0,
    public ativo: boolean = true,
    public cnpjCred: string = "",
    public credenciais: string = '',
    public credenciado: boolean = false,
    public dataCredenciamento: string = '',
    public ordem: number = 0,
  ) { }
}
