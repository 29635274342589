import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@material-ui/core';
import { SetorCadastroFormModel } from 'model/app/forms/setor/setor-cadastro-form-model';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { useFormSetorCadastroValidation } from './form-setor-cadastro-validation';

export const FormSetorCadastro = forwardRef<
  DefaultFormRefs<SetorCadastroFormModel>,
  DefaultFormProps<SetorCadastroFormModel>
>((props: DefaultFormProps<SetorCadastroFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const { FormSetorCadastroValidationYup } = useFormSetorCadastroValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<SetorCadastroFormModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormSetorCadastroValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: SetorCadastroFormModel) => {
    const modelP = new SetorCadastroFormModel();
    modelP.descricao = values.descricao;
    props.onSubmit(modelP);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setValue('descricao', '');
      reset();
    },
    fillForm: () => {},
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={props.loading}
                    fullWidth
                    variant="outlined"
                    label="Descrição"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Digite o nome"
                    error={Boolean(
                      errors.descricao && errors.descricao.message,
                    )}
                    inputProps={{ maxLength: 70 }}
                    helperText={
                      touchedFields.descricao || errors.descricao
                        ? errors.descricao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
