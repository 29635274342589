import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { NcmModel } from "../../../../model/api/gestao/ncm/ncm-model";
import { VariaveisAmbiente } from "config";

export function usePutNcm() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putNcm = useCallback(
    (ncm: NcmModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/ncm/${ncm.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(ncm),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putNcm,
  };
}
