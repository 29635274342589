import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./modal-header-styles";
import { ModalHeaderProps } from "./modal-header-props"

export const ModalHeader = (props: ModalHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.mainHeaderContainer}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between" direction="row" alignItems="center">
            <Grid item className={classes.flexContent}>
              <Grid container spacing={1} alignItems="center">
                {props.leftArea !== undefined ? (
                  <Grid item className={classes.flexFixed}>{props.leftArea}</Grid>
                ) : null}
                <Grid item className={classes.flexContent}>
                  <Typography variant="h4" className={classes.title} innerRef={props.titleRef}>
                    {props.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {props.rightArea !== undefined ? (
              <Grid item className={classes.flexFixed}>{props.rightArea}</Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
