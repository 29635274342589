import { ClickAwayListener, Tooltip, Typography } from "@material-ui/core"
import { useStyles } from "./tooltip-saurus-styles"
import { TooltipImpressorasProps } from "./tooltip-impressoras-props"
import { useState } from "react"
import { InformacaoIcon } from "views/components/icons"


export const ToolTipImpressoras = ({ descricao }: TooltipImpressorasProps) => {
    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false)
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Tooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={() => { setOpen(false) }}
                open={open}
                title={
                    <Typography className={classes.tooltipText} align='center'>{descricao}</Typography>
                }
            >
                <div onClick={() => { setOpen(open) }} className={classes.iconDiv}>
                    <InformacaoIcon tipo='GERAL' class={classes.infoIconExt} />
                </div>
            </Tooltip>
        </ClickAwayListener>
    )
}