import { useCadastros } from "services/app";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { useThemeQueries } from "views/theme";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { ArrowRigthIcon } from "views/components/icons/arrow-rigth-icon";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { useStyles } from "./dialog-mov-opcoes-produto-carrinho-styles";
import { EnumDecontoOuAcrescimo } from "model/enums/enum-desconto-ou-acrescimo";

interface DialogMovOpcoesProdutoCarrinhoProps {
    aberto: boolean;
    model: MovSimplesProdutoModel;
    inativarProduto: (model: MovSimplesProdutoModel) => Promise<void>
}

export const DialogMovOpcoesProdutoCarrinho = ({
    aberto,
    model,
    inativarProduto,
}: DialogMovOpcoesProdutoCarrinhoProps) => {

    const { theme } = useThemeQueries();
    const classes = useStyles();
    const { fecharDialogOpcoesProdutoCarrinho, abrirDialogAdicionarAcrescDesc } = useCadastros();

    const inativarProdutoClick = async () => {
        await inativarProduto(model);
        fecharDialogOpcoesProdutoCarrinho();
    }

    const adicionarDescontoClick = () => {
        abrirDialogAdicionarAcrescDesc(EnumDecontoOuAcrescimo.DescontoItem, model);
        fecharDialogOpcoesProdutoCarrinho();
    }

    const adicionarAcrescimoClick = () => {
        abrirDialogAdicionarAcrescDesc(EnumDecontoOuAcrescimo.AcrescimoItem, model);
        fecharDialogOpcoesProdutoCarrinho();
    }

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo="Opções do Produto"
                isButtonTitleClose
                tamanho='xs'
                handleClickClose={fecharDialogOpcoesProdutoCarrinho}
                colorTitulo={theme.palette.primary.main}
            >
                <Grid className={classes.cardContainer}>
                    <Grid className={classes.cardContent}>
                        <Grid item xs={12}
                            className={classes.acoes}
                            onClick={adicionarDescontoClick}
                        >
                            <Typography>
                                <Box fontWeight={600}>
                                    Adicionar Desconto
                                </Box>
                            </Typography>
                            <ArrowRigthIcon
                                tipo="BUTTON"
                                style={{ width: '20px' }}
                            />
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12}
                            className={classes.acoes}
                            onClick={adicionarAcrescimoClick}
                        >
                            <Typography>
                                <Box fontWeight={600}>
                                    Adicionar Acréscimo
                                </Box>
                            </Typography>
                            <ArrowRigthIcon
                                tipo="BUTTON"
                                style={{ width: '20px' }}
                            />
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12}
                            onClick={inativarProdutoClick}
                            className={classes.acoes}
                        >
                            <Typography>
                                <Box fontWeight={600}>
                                    Cancelar Produto
                                </Box>
                            </Typography>
                            <ArrowRigthIcon
                                tipo="BUTTON"
                                style={{ width: '20px' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogSaurus>
        </>
    );
}