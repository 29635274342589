import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    button: {
        position: 'fixed',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        background: '#1F9CE4',
        color: '#fff',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 20,
        cursor: 'pointer'
    }
}));
