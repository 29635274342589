import { VariaveisAmbiente } from "config";
import { Profiler, ReactNode } from "react";
import { useRelatorioPerformance } from "services/app/providers/relatorio-performance-provider/relatorio-performance-provider";

interface RelatorioProfilerProps {
    component: string;
    children: ReactNode;
    naoLogar?: boolean;
}

export const RelatorioProfiler = ({ component, children, naoLogar }: RelatorioProfilerProps) => {
    const { onRender, recording } = useRelatorioPerformance();
    
    if (VariaveisAmbiente.performanceDebugMode && !naoLogar && recording) {
        return (
            <Profiler id={component} onRender={onRender}>
                {children}
            </Profiler>
        )
    }

    return (
        <>
            {children}
        </>
    )
}
