import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
export function useDeleteEnderecoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteEnderecoPessoa = useCallback(
    (pessoaId: string, id: string) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${pessoaId}/endereco/${id}`,
        method: "DELETE",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    deleteEnderecoPessoa,
  };
}
