import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  img: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    userSelect: "none"
  },
  textFlex: {
    flex: '0 auto',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  imgFlex: {
    flex: '1 1 100%',
    height: 0,
    display: 'block',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  imgFixFlex: {
    display: 'flex',
    height: '100%',
    width: '100%',
    textAlign: "center",
    alignItems: 'center',
    justifyContent: 'center',
  },
  texts: {
    color: theme.palette.primary.contrastText
  },
  paginationContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`
  },
  pagination: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: theme.palette.divider
  },
  paginationselected: {
    background: theme.palette.background.paper
  }
}));