import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormDocumentoFiscalValidation = () => {
    const FormDocumentoFiscalValidation = useMemo(() => {
        return (
            Yup.object().shape({
                dInicial: Yup.string().required('A Data Inicial é obrigatória.'),
                dFinal: Yup.string().required('A Data Final é obrigatória.'),
            })
        )
    }, [])
    return {
        FormDocumentoFiscalValidation,
    }
}

