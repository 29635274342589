import { ConfigInicialPermissao } from "./config-inicial-permissao-model";

export class ConfigInicialPerfil {
    constructor(
        public contratoId: string | null = null,
        public nome: string | null = null,
        public descricao: string | null = null,    
        public permissoes: ConfigInicialPermissao[] | [] = []
    ){}
}

