import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormMesaNovaValidation = () => {
  const FormMesaNovaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        codigo: Yup.string().required('Codigo da Mesa é obrigatória'),
      })
    )
  }, [])
  return {
    FormMesaNovaValidationYup,
  }
}

