
export enum EnumTipoDocumento {
  CarteiraRegistroProfissional = 1,
  CarteiraIdentidade = 2,
  CertidaoNascimento = 5,
  CertidaoCasamento = 6,
  Passaporte = 13,
  Outro = 999,
  AutorizacaoEspecialFuncionamento = 39,
  PedidoAutorizacaoTrabalho = 4,
  CertificadoReservista = 7,
  CartaPatente = 8,
  AutorizacaoFuncionamentoEmpresa = 38,
  CertificadoDispensaIncorporacao = 10,
  CarteiraIdentidadeEstrangeiro = 11,
  AutorizacaoEspecialSimplificada = 40,
  ProtocoloPoliciaFederal = 14,
  InscricaoEstadual = 19,
  InscricaoMunicipal = 20,
  AlvaraLicencaSanitariaMunicipal = 21,
  AlvaraLicencaSanitariaEstadual = 22,
  CarteiraTrabalhoPrevidenciaSocial = 50,
  CadastroNacionalPessoaJuridica = 62,
}