import { useCallback, FocusEvent, useState } from "react";

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../../autocomplete-saurus/autocomplete-saurus";
import { NcmMasterSummaryModel } from "model/api/ncm-master/ncm-master-model";
import { useGetNcmsMaster } from "data/api/ncm-master/get-ncms-master";

export interface AutocompleteNcmsProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  ncmId?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  modo: "campoFormatado" | "cest" | "codigo";
}
interface NcmPesquisa extends NcmMasterSummaryModel {
  campoFormatado: string;
}

export const AutocompleteNcmsMaster = ({ onChange, loadingExterno, ...props }: AutocompleteNcmsProps) => {
  const { getNcmsMaster, carregando: carregandoNcms } = useGetNcmsMaster();
  const [ncmsState, setNcmsState] = useState<Array<NcmMasterSummaryModel>>(
    new Array<NcmMasterSummaryModel>()
  );

  const getNcmsWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getNcmsMaster(termo);
        if (ret.erro) throw ret.erro;
        let ncms: Array<NcmPesquisa> = new Array<NcmPesquisa>();
        if (ret.resultado?.data?.list?.length > 0) {
          ncms = ret.resultado?.data?.list as Array<NcmPesquisa>;
        }
        ncms.forEach((item) => {
          item.campoFormatado = `${item.codigo} - ${item.descricao}`;
        });

        setNcmsState(ncms);
      } catch (e: any) { }
    },
    [getNcmsMaster]
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );
  const onPesquisa = useCallback(
    (termo: string) => {
      let termoPesq = termo.length > 0 ? termo.split("-")[0].trim() : termo;
      getNcmsWrapper(termoPesq);
    },
    [getNcmsWrapper]
  );
  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      inputRef={props.inputRef}
      tipoTextField="NUMERO"
      loading={carregandoNcms}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={ncmsState}
      optionLabelKey={props.modo}
      optionValueKey="codigo"
      disabledValueKey="id"
      disabledValues={[props.ncmId]}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
