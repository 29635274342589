import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import React from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export enum EnumColectionFireBase {
    VENDAS = 'vendas',
    VALORES = 'valorores',
    SESSOES = 'sessoes',
    PIX = 'pix',
    NUMERACAO = 'numeracao'
}

const useFirebase = () => {
  const logError = React.useCallback(async (errorData: any, colection: EnumColectionFireBase) => {
    try {
      if(!db.app.options.projectId){
        throw new Error('Variáveis de ambiente não inicializadas.')
      }
      const docRef = await addDoc(collection(db, colection), errorData);
      console.log("Log escrito: ", docRef.id);
    } catch (error) {
      console.error('Erro ao salvar o log de erro:', error);
    }
  }, []);

  return {
    logError,
    firebase: app,
  };
};

export default useFirebase;