/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@material-ui/core';
import { useGetMesas } from 'data/api/gestao/mesas/get-mesas';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { MesasNovaModel } from 'model/api/gestao/mesa/mesa-nova-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DialogEditarMesa } from 'views/components/dialog/dialog-editar-mesa/dialog-editar-mesa';
import { DialogRefazerMesa } from 'views/components/dialog/dialog-refazer-mesa/dialog-refazer-mesa';
import { ConfiguracaoIcon } from 'views/components/icons';
import { useStyles } from './mesas/list-styles';
import { MesasListData } from './mesas/mesas-list-data';
import { DialogAdicionarMesa } from 'views/components/dialog/dialog-adicionar-mesa/dialog-adicionar-mesa';
import { AdicionarMesaIcon } from 'views/components/icons/adicionar-mesa-icon';
interface MesasProps {
  idSalao: string;
}

export const Mesas = ({ idSalao }: MesasProps) => {
  // STATES E REFS
  const mesaParaEdicao = useRef<MesasNovaModel | undefined>(undefined);
  const [listMesas, setListMesas] = useState<MesasModel[]>([]);

  // STATES DE CONTROLE DIALOG
  const [showDialogRefazerMesa, setShowDialogRefazerMesa] =
    useState<boolean>(false);
  const [showDialogEditarMesa, setShowDialogEditarMesa] =
    useState<boolean>(false);
  const [showDialogAdicionarMesa, setShowDialogAdicionarMesa] =
    useState<boolean>(false);

  // STATES DE CONTROLE PARA ATUALIZAÇÃO
  const [modalEditMesaAberto, setModalEditMesaAberto] = useState(false);
  const [modalRefazerMesaAberto, setModalRefazerMesaAberto] = useState(false);
  const [modalAdicionarMesaAberto, setModalAdicionarMesaAberto] =
    useState(false);

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();

  // CHAMADAS API
  const { getMesas } = useGetMesas();

  // AUX
  const classes = useStyles();

  const searchMesas = useCallback(async () => {
    const mesas = await getMesas('&pageSize=0', getEmpresaSelecionada()!.Id, idSalao);
    if (mesas.erro) {
      showToast('error', 'Não foi possível carregar as mesas do salão.');
    }
    setListMesas(mesas?.resultado?.data.list || []);
  }, [getEmpresaSelecionada, getMesas, idSalao, showToast]);

  // EFFECT INICIAL
  useEffect(() => {
    try {
      searchMesas();
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEmpresaSelecionada, searchMesas, showToast]);

  // EFFECTS DE CONTROLE DE ATUALIZAÇÃO
  useEffect(() => {
    if (showDialogEditarMesa === false && modalEditMesaAberto) {
      searchMesas();
    }

    setModalEditMesaAberto(showDialogEditarMesa);
  }, [modalEditMesaAberto, searchMesas, showDialogEditarMesa]);

  useEffect(() => {
    if (showDialogAdicionarMesa === false && modalAdicionarMesaAberto) {
      searchMesas();
    }

    setModalAdicionarMesaAberto(showDialogAdicionarMesa);
  }, [modalEditMesaAberto, searchMesas, showDialogAdicionarMesa]);

  useEffect(() => {
    if (showDialogRefazerMesa === false && modalRefazerMesaAberto) {
      searchMesas();
    }

    setModalRefazerMesaAberto(showDialogRefazerMesa);
  }, [modalEditMesaAberto, searchMesas, showDialogRefazerMesa]);

  const handleClickCard = (mesa: MesasModel) => {
    const mesapicker = {
      ...mesa,
      status: mesa.status.codigo,
    } as MesasNovaModel;

    setShowDialogEditarMesa(true);
    mesaParaEdicao.current = mesapicker;
  };

  const handleClickConfigurarMesas = () => {
    setShowDialogRefazerMesa(true);
  };

  const handleOpenNovaMesa = () => {
    setShowDialogAdicionarMesa(true);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid item xs={12} className={classes.listContainer}>
          <MesasListData onClick={handleClickCard} mesas={listMesas || []} />
        </Grid>

        <Grid
          container
          style={{ justifyContent: 'flex-end', paddingRight: '8px' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickConfigurarMesas}
            style={{ marginRight: listMesas.length > 0 ? '8px' : '0px' }}
          >
            <ConfiguracaoIcon tipo="BUTTON_PRIMARY" />
            {listMesas.length > 0 ? 'Reconfigurar' : 'Configurar Mesas'}
          </Button>

          {listMesas.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenNovaMesa}
            >
              <AdicionarMesaIcon tipo="BUTTON_PRIMARY" />
              Adicionar Mesa
            </Button>
          )}
        </Grid>
      </div>

      {showDialogAdicionarMesa && (
        <DialogAdicionarMesa
          aberto={showDialogAdicionarMesa}
          close={() => {
            setShowDialogAdicionarMesa(false);
          }}
          salaoId={idSalao}
        />
      )}

      {showDialogRefazerMesa && (
        <DialogRefazerMesa
          temMesas={listMesas.length > 0}
          aberto={showDialogRefazerMesa}
          close={() => {
            setShowDialogRefazerMesa(false);
          }}
          salaoId={idSalao}
        />
      )}

      {mesaParaEdicao.current && showDialogEditarMesa && (
        <DialogEditarMesa
          aberto={showDialogEditarMesa}
          close={() => {
            setShowDialogEditarMesa(false);
            mesaParaEdicao.current = undefined;
          }}
          mesa={mesaParaEdicao.current}
          salaoId={idSalao}
        />
      )}
    </>
  );
};
