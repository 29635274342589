import { EnumStatusPerfil } from "model/enums/enum-status-perfil";
import { guidEmpty } from "../../../../utils/guid-empty";

export class UsuarioEditFormModel {
  constructor(
    public id: string = '',
    public empresaId: string = '',
    public empresaNome: string = '',
    public perfilId: string = '',
    public perfilNome: string = '',
    public email: string = '',
    public status: EnumStatusPerfil = EnumStatusPerfil.Convidado,
  ) { }
}

export class UsuarioEditPutModel {
  constructor(
    public id: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public perfilId: string = guidEmpty(),
    public usuarioId: string = guidEmpty(),
    public status: number = 0,
  ) { }
}

export class UsuarioEditPostModel {
  constructor(
    public id: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public perfilId: string = guidEmpty(),
    public usuarioId: string = guidEmpty(),
    public status: number = 0,
  ) { }
}
