import { isEmpty } from "lodash";
import { StringBuilder } from "./stringBuilder";

export enum Orientacao {
    Centro, Direita, Esquerda
}

export const Alinhamento = {
    pularLinha: "\n",

    AlinhamentoMultiLinhas(
        xOrientacao: Orientacao | null,
        xTexto: string,
        xColuna: number,
        xComplemento: string
    ): string {
        if (!xTexto)
            xTexto = '';
        let xTxt = xTexto.trimEnd();

        if (xColuna === 0) {
            return xTxt
        }

        let xRetorno = new StringBuilder();

        if (xTxt.includes(this.pularLinha)) {
            for (let xt in xTxt.split(this.pularLinha)) {
                xRetorno.append(this.AlinhamentoMultiLinhas(xOrientacao, xt, xColuna, xComplemento))
                xRetorno.appendWithln()
            }

            xRetorno = new StringBuilder(xRetorno.toString().substring(0, xRetorno.toString().lastIndexOf(this.pularLinha)))
            return xRetorno.toString();
        }

        xRetorno = new StringBuilder(this.Alinha(xOrientacao, xTxt, xColuna, xComplemento))

        if (xTxt.length > xColuna) {
            xRetorno.append(this.pularLinha)
            xRetorno.append(
                this.AlinhamentoMultiLinhas(
                    xOrientacao,
                    xTxt.substring(xColuna),
                    xColuna,
                    xComplemento
                )
            )
        }
        return xRetorno.toString()
    },

    Alinha(
        xOrientacao: Orientacao | null,
        xTexto: string,
        xTamanho: number,
        xComplemento: string
    ): string {
        switch (xOrientacao) {
            case Orientacao.Centro: return this.AlinhaCentro(xTexto, xComplemento, xTamanho)
            case Orientacao.Direita: return this.AlinhaDireita(xTexto, xComplemento, xTamanho)
            case Orientacao.Esquerda: return this.AlinhaEsquerda(xTexto, xComplemento, xTamanho)
            default: return ''
        }
    },

    AlinhaDireita(xTexto: string, xComplemento: string, xTamanho: number): string {
        if (xTamanho < 0) return ""
        if (isEmpty(xTexto)) {
            return ""
        }
        if (xTexto.length <= 0) {
            return ""
        }

        if (xTexto.length >= xTamanho) {
            return xTexto.substring(0, xTamanho)
        } else return this.CompletaTexto(
            xTexto,
            xComplemento,
            xTamanho
        ) + xTexto
    },

    AlinhaEsquerda(xTexto: string, xComplemento: string, xTamanho: number): string {
        if (xTamanho < 0) return ""
        if (isEmpty(xTexto)) {
            return ""
        }
        if (xTexto.length <= 0) {
            return ""
        }
        if (xTexto.length >= xTamanho) {
            return xTexto.substring(0, xTamanho)
        } else return xTexto + this.CompletaTexto(xTexto, xComplemento, xTamanho)
    },

    AlinhaCentro(xTexto: string, XComplemento: string, xTamanho: number): string {
        let xComplemento = XComplemento
        if (xTamanho <= 0) return ""
        if (isEmpty(xTexto)) {
            return ""
        }
        if (xTexto.length <= 0) {
            return ""
        }
        if (xTexto.length >= xTamanho) {
            return xTexto.substring(0, xTamanho)
        }
        if (isEmpty(xComplemento)) {
            return ""
        }
        xComplemento = xComplemento.substring(0, 1)
        let sb = new StringBuilder()

        for (let i = 0; i < (xTamanho - xTexto.length) / 2; i++) {
            sb.append(xComplemento)
        }

        sb.append(xTexto)

        while (sb.toString().length < xTamanho) {
            sb.append(xComplemento)
        }
        return sb.toString()
    },

    CompletaTexto(xTexto: string | '', XComplemento: string, xTamanho: number): string {
        let xComplemento = XComplemento
        let resultado = ""
        if (xTexto == null || xTexto.length >= xTamanho) {
            return resultado
        }
        if (xTamanho <= 0) {
            return ""
        }
        if (isEmpty(xComplemento)) {
            return ""
        }

        xComplemento = xComplemento.substring(0, 1)
        let tamanhoCompl = xTamanho - xTexto.length
        for (let i = 0; i < tamanhoCompl; i++) {
            resultado = resultado + xComplemento
        }

        return resultado
    },

    Aggregate(strings: Iterable<string>, separator: string | ''): string {
        let sb = new StringBuilder()
        let sep: string = ""
        for (let s in strings) {
            sb.append(sep ?? '')
            sb.append(s)
            sep = separator
        }
        return sb.toString()
    }


}