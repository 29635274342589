import * as Yup from 'yup';

export const useFormCredenciarPixValidation = () => {

    const FormCredenciarPixvalidation = Yup.object().shape({
        merchantId: Yup.string().required('O campo merchantId é obrigatório'),
        merchantToken: Yup.string().required('O campo merchantToken é obrigatório'),
    });

    return {
        FormCredenciarPixvalidation
    }
}