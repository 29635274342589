import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-alterar-quantidade-clientes-venda-styles';
import { OkIcon } from 'views/components/icons';
import { useState, useCallback } from 'react';
import { useCadastros } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

interface DialogAlterarQuantidadeClientesProps {
  openned: boolean;
}

export const DialogAlterarQuantidadeClientesVenda = ({
  openned,
}: DialogAlterarQuantidadeClientesProps) => {
  const classes = useStyles();
  const { fecharDialogAlterarQuantidadeClientesVenda } = useCadastros();
  const { saveQtdPessoasPagamento, getQtdPessoasPagamento } = useMovAtual()
  const [personCount, setPersonCount] = useState<number>(getQtdPessoasPagamento().pessoas);
  const { callEvent } = useEventTools();

  const handleSubmit = () => {
    saveQtdPessoasPagamento(personCount)
    callEvent(AppEventEnum.AtualizarQuantidadeDePessoas, personCount)
    fecharDialogAlterarQuantidadeClientesVenda();
  }

  const handleClickSum = useCallback(() => {
    setPersonCount((antigo) => antigo + 1);
  }, []);

  const handleClickASubtraction = useCallback(() => {
    setPersonCount((antigo) => antigo - 1);
  }, []);

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo={`Quantidade de Clientes`}
      tamanho="xs"
    >
      <>
        <div className={classes.container}>
          <Typography className={classes.text}>
            Digite a quantidade de pessoas que for dividir a conta.
          </Typography>

          <Grid className={classes.containerButton}>
            <Button
              disabled={personCount <= 1 ? true : false}
              className={classes.buttonChanged}
              variant="contained"
              color="primary"
              onClick={handleClickASubtraction}
            >
              <Typography className={classes.prefix}>-</Typography>
            </Button>
            <Typography className={classes.textPerson}>
              {personCount}
            </Typography>

            <Button
              className={classes.buttonChanged}
              color="primary"
              variant="contained"
              onClick={handleClickSum}
            >
              <Typography className={classes.prefix}>+</Typography>
            </Button>
          </Grid>
        </div>

        <div style={{ marginBottom: '16px', marginTop: '20px' }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            className='round'
          >
            <OkIcon tipo='BUTTON_PRIMARY' />
            Confirmar
          </Button>
        </div>
      </>
    </DialogSaurus>
  );
};
