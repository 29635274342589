
import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetSaloes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSaloes = useCallback(
        (queryBusca: string, empresaId: string) => {
            let queryPage = "";

            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryBusca}`;

            return invocarApi({
                url: `/v5/empresa/${empresaId}/salao${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            })
        }, [invocarApi],
    );

    return {
        ...outrasPropriedades,
        getSaloes,
    };
}
