import { PerfilHorarioModel } from "model/api/gestao/perfil-horario/perfil-horario-model";
import { guidEmpty } from "../../../../utils/guid-empty";
import { PerfilEditPermissaoFormModel } from "./perfil-edit-permissao-form-model";

export class PerfilEditFormModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public nome: string = '',
    public horarios: PerfilHorarioModel[] = new Array<PerfilHorarioModel>(),
    public descricao: string = '',
    public permissoes: Record<string, PerfilEditPermissaoFormModel[]> = {}
  ) { }
}
