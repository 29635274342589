import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {


    return {
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: 'center',
            position: "relative",
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'unset'
            }
        },
        header: {
            flex: "0 1 auto"
        },
        containerBackgroundColor: {
            background: theme.palette.grey[100],
        },
        content: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: "600px",
            padding: theme.spacing(1),
            overflow: 'auto'
        },
        infoContainer: {
            display: "flex",
            flexDirection: 'column',
        },
        textTitle: {
            fontWeight: 700,
            lineHeight: "42px",
            fontSize: "2rem",
            textAlign: "center",
            color: theme.palette.primary.main,
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        textSubtitle: {
            fontWeight: 500,
            lineHeight: "23px",
            fontSize: "1rem",
            textAlign: "center",
            color: theme.palette.grey[500],
            marginBottom: theme.spacing(2),
        },
        cardInfo: {
            width: "100%",
            backgroundColor: "#fff",
            "box-shadow": "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
            borderRadius: theme.spacing(1),
            padding: '12px'
        },
        label: {
            fontSize: '0.750rem',
            color: '#BCBCBC',
            lineHeight: '14,1px'
        },
        infoNomeEQuantidade: {
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '23px',
            maxWidth: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        divider: {
            marginTop: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
        },
        infoTotal: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '21px',
            color: '#8D8D8D',
            marginTop: theme.spacing(2)
        },
        buttonCancelar: {
            marginBottom: theme.spacing(1),
            color: '#fff',
            backgroundColor: theme.palette.error.main,
        },
        buttonTentarNov: {
            marginBottom: theme.spacing(1),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        buttonSuporte: {
            marginBottom: theme.spacing(1)
        },
        containerButton: {
            padding: 16
        },
        cardMP: {
            background: '#E4F5FF',
            padding: '8px 4px',
            borderRadius: 8
        },
        textCardMP: {
            color: theme.palette.primary.main,
            fontWeight: 500,
            marginBottom: 8
        },
        linkCardMP: {
            color: theme.palette.primary.main,
            fontWeight: 600
        }
    }
});