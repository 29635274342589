import { useEffect, useState, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { useStyles } from "./deposito-list-styles";
import { DepositoListData } from "./deposito-list-data";

import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useToastSaurus } from "services/app";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { DepositoModel } from "model/api/gestao/deposito/deposito-model";
import { useGetDeposito } from "data/api/gestao/deposito";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";

export const DepositoList = () => {
  const classes = useStyles();
  const { abrirCadastroDepositos } = useCadastros();
  const { getDeposito, carregando } = useGetDeposito();
  const { addHandler, removeHandler } = useEventTools()

  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<DepositoModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<DepositoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const urlParams = new URLSearchParams(history.location.search)
  const filtros = {
    status: isEmpty(urlParams.get('status')) ? 1 : Number(urlParams.get('status')),
    termo: urlParams.get('termo') || ''
  }

  const search = useCallback(
    async (newPage: number) => {
      const query =
        "" +
        (!isEmpty(filtros.termo) ? "&Nome=" + filtros.termo : "")

      try {
        const res = await getDeposito(query, newPage);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [filtros.termo, getDeposito, fillResult, showToast]
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.DepositoModal, modalEdit)

    return () => removeHandler(AppEventEnum.DepositoModal, modalEdit)
  }, [addHandler, modalEdit, removeHandler])

  useEffect(() => {
    if (!modalEditAberto) {
      search(queryStatus.page);
    }
  }, [modalEditAberto, queryStatus.page, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    abrirCadastroDepositos(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };
  
  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <DepositoListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

