import { KeyValueModel } from "model";
import { EnumTpHistoricoVenda } from "model/enums/enum-tphistorico-venda";


export const TpHistoricoVendaMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpHistoricoVenda.Entrada, "Entrada"),
    new KeyValueModel(EnumTpHistoricoVenda.NFCe, "NFC-e"),
    new KeyValueModel(EnumTpHistoricoVenda.NFe, "NF-e"),
    new KeyValueModel(EnumTpHistoricoVenda.VendaSimples, "Simples"),
    new KeyValueModel(EnumTpHistoricoVenda.NotaManual, "Manual"),
    new KeyValueModel(EnumTpHistoricoVenda.MDFe, "MDF-e"),
    new KeyValueModel(EnumTpHistoricoVenda.CTe, "CT-e"),
    new KeyValueModel(EnumTpHistoricoVenda.CTeOS, "CT-e OS"),
    new KeyValueModel(EnumTpHistoricoVenda.SAT, "SAT"),
    new KeyValueModel(EnumTpHistoricoVenda.Troca, "Troca"),
];