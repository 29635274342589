import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    header: {
        flex: "0 1 auto"
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    content: {
        display: "flex",
        overflow: 'auto',
        height: '100%',
        flex: 1,
        padding: theme.spacing(1),
        background: theme.palette.grey[100],
    },
}));