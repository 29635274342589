import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetPedidoSalaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedidoSalaoById = useCallback(
        (empresaId: string, pedidoId: string) => {

            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPedidoSalaoById,
    };
}
