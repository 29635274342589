import { KeyValueModel } from "model/api";
import { EnumPorDia, EnumPorMes } from '../../model/enums/enum-ultimos';

export const PorDiaMock: KeyValueModel[] = [
    new KeyValueModel(EnumPorDia.HOJE, "Hoje"),
    new KeyValueModel(EnumPorDia.SETEDIAS, "Últimos 7 dias"),
    new KeyValueModel(EnumPorDia.QUATORZEDIAS, "Últimos 14 dias"),
    new KeyValueModel(EnumPorDia.PERIODO, "Por período"),
];

export const PorMesMock: KeyValueModel[] = [
    new KeyValueModel(EnumPorMes.MESATUAL, "Mês atual"),
    new KeyValueModel(EnumPorMes.MESANTERIOR, "Mês anterior"),
    new KeyValueModel(EnumPorMes.TRESMESES, "Últimos 3 meses"),
    new KeyValueModel(EnumPorMes.PERIODO, "Por período"),
];