export enum EnumTour {
    VENDA = 1,
    FAVORITARMENU = 2,
    EDICAOPDVCONFIG = 3,
    CONFIGPDV = 4,
    EDICAOPDVIMPRESSOR = 5,
    CONFIGIMPRESSOR = 6,
    CADASTROPRODUTO = 7,
    CADASTROPESSOAS = 8,
    FORMPESSOAS = 9,
    ADDPERFILDEACESSO = 10,
    FORMPERFILDEACESSO = 11,
    CONFIGPERFILDEACESSO = 12,
    ADDUSUARIOS = 13,
    FORMUSUARIOS = 14,
    MESAS = 15,
    MESASFORM = 16,
    RECONFIGMESAS = 17,
    RECONFIGMESASFORM = 18,
    COMANDAS = 19,
    COMANDASFORM = 20,
    CARDAPIO = 21,
    IMPRESSORAS = 22,
    IMPRESSORASFORM = 23,
    MENUDENAVEGACAO = 24,
    INICIARVENDA = 25,
    INICIO = 26,
    CADASTROPESSOASCARDS = 27,
    CADASTROPESSOASDOCUMENTO = 28,
    WIZARD = 100



}