import { NewSalaoModel } from "model/api/gestao/saloes/saloes-model";
import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostSalao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postSalao = useCallback(
    (salao: NewSalaoModel, empresaId: string) =>
      invocarApi({
        url: `/v5/empresa/${empresaId}/salao`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(salao),
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    postSalao,
  };
}
