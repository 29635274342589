import { Box, Button, Container, Divider, Grid, Typography } from "@material-ui/core"
import { CircularLoading } from "views/components"
import { PrivatePageHeader } from "views/components/headers"
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles"
import { ButtonPrivateHeader } from "views/components/controles"
import { useCallback, useEffect, useRef, useState } from "react"
import { AvancarIcon, InformacaoIcon, NovoIcon, VoltarIcon } from "views/components/icons"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { TranspModel, VendaCompletaModel, VolTranspModel } from "model/api/gestao/venda/venda-completa-model"
import { useCadastros, useToastSaurus } from "services/app"
import { useStyles } from "./dados-transportadora-styles"
import classNames from "classnames"
import { DefaultFormRefs } from "views/components/form/utils"
import { FormDadosTransportadora } from "views/components/form/entrada/dados-transportadora/form-dados-transportadora"
import { CardVolumeTransportado } from "views/components/cards/card-volume-transportado/card-volume-transportado"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"
import { TranspFormModel } from "model/app/forms/entrada/transp-form-model"
import { picker } from "utils/picker"
import { isEmpty, isEqual } from "lodash"
import { CardNaoEncontrado } from "views/components/cards"
import { useEntrada } from "../../hooks/entrada"
import { ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model"

const DadosTransportadoraPage = () => {

    // hooks e utils
    const cadClasses = useDefaultCadastroStyles()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const { showToast } = useToastSaurus()
    const { abrirVolumeTransportadoCadastro } = useCadastros()
    const formRef = useRef<DefaultFormRefs<TranspFormModel>>(null)
    const { addHandler, removeHandler } = useEventTools()
    const firstEnterPageRef = useRef<boolean>(true)

    //state & refs
    const [mov, setMov] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [modalAberto, setModalAberto] = useState<{ openned: boolean, att?: boolean }>({ openned: false, att: false })
    const [modelForm, setModelForm] = useState<TranspFormModel>(new TranspFormModel())
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const rotaApos = useRef<'proximo' | 'anterior'>('proximo')

    // utils
    const { getVendaCompleta, saveChangesVendaCompleta, carregando: carregandoEntrada } = useEntrada(mov)
    const carregando = [preenchendoTela, carregandoEntrada].includes(true)

    const recarregarForm = useCallback((model: TranspFormModel) => {
        formRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(modelForm)
    }, [modelForm, recarregarForm])

    const getInfoVenda = useCallback(async (att: boolean = false) => {
        let movAtual = new VendaCompletaModel()
        if (location.state?.mov && !att) {
            movAtual = location.state.mov
        } else {
            movAtual = await getVendaCompleta(movId)
        }
        const transp = { ...movAtual.infMov.transp }
        const transpForm = picker<TranspFormModel>(transp, new TranspFormModel())
        setMov(movAtual)

        return transpForm;
    }, [getVendaCompleta, location.state?.mov, movId])

    const preencherTela = useCallback(async (att: boolean = false) => {
        try {
            setPreenchendoTela(true);
            const transp = await getInfoVenda(att);
            setModelForm(transp);
        } catch (e: any) {
            showToast('error', e.message);
        }
        finally {
            setPreenchendoTela(false);
        }
    }, [getInfoVenda, showToast])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => history.push({
                pathname: `/entrada-mercadoria/visualizar/${movId}`,
                search: location.search,
                state: {
                    mov,
                    manifesto: location.state?.manifesto,
                    dtManifesto: location.state?.dtManifesto
                }
            })}></ButtonPrivateHeader >
        ),
        [history, location.search, location.state, mov, movId]
    );

    const modalVolume = ({ openned, att }: any) => {
        setModalAberto({ openned, att })
    }

    useEffect(() => {
        if ((!modalAberto.openned && modalAberto.att) || firstEnterPageRef.current) {
            preencherTela(modalAberto.att)
            firstEnterPageRef.current = false
        }

        addHandler(AppEventEnum.VolumeTransportadoModal, modalVolume)

        return () => removeHandler(AppEventEnum.VolumeTransportadoModal, modalVolume)
    }, [addHandler, modalAberto, preencherTela, removeHandler])

    const proximaTela = (att?: boolean) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/info-complementares`,
        search: location.search,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    })

    const voltarTela = (att?: boolean) => history.push({
        pathname: `/entrada-mercadoria/visualizar/${movId}/produtos`,
        search: location.search,
        state: {
            mov: att ? null : mov,
            manifesto: location.state?.manifesto,
            dtManifesto: location.state?.dtManifesto
        }
    })

    const handleSubmit = async (model: TranspFormModel, beforeModel: TranspFormModel) => {
        try {
            const equal = isEqual(model, beforeModel)
            const transp = { ...mov.infMov.transp }
            if (equal) {
                showToast('info', 'Nenhuma informação alterada')
                if (rotaApos.current === 'proximo') {
                    proximaTela()
                } else {
                    voltarTela()
                }
                return
            }
            const transpForm = picker<TranspModel>(model, transp)
            transpForm.doc = model.doc
            transpForm.vol = transp.vol ?? []
            transpForm.reboque = transp.reboque ?? []

            const movAtual = {
                ...mov,
                infMov: {
                    ...mov.infMov,
                    dhEmi: mov.infMov.dhEmi,
                    transp: transpForm,
                },
            }

            await saveChangesVendaCompleta(movAtual)

            showToast('success', 'Dados da Transportadora alterado com sucesso!')

            await getInfoVenda(true)

            if (rotaApos.current === 'proximo') {
                proximaTela(true)
            } else {
                voltarTela(true)
            }
        } catch (error: any) {
            showToast('error', error.message)
        }
    }

    const onClick = (volume: VolTranspModel) => {
        abrirVolumeTransportadoCadastro(structuredClone(volume), structuredClone(mov))
    }

    const adicionarNovo = () => {
        abrirVolumeTransportadoCadastro(new VolTranspModel(), structuredClone(mov))
    }

    const submit = (rota: 'proximo' | 'anterior') => {
        rotaApos.current = rota
        formRef.current?.submitForm()
    }

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Dados da Transportadora"
                    leftArea={leftArea()}
                />
            </Grid>
            <div className={cadClasses.content}>
                <Container maxWidth="md" className={classes.container}>
                    <div
                        className={classNames(
                            cadClasses.contentForms,
                            carregando ? cadClasses.contentFormsLoading : undefined
                        )}
                    >
                        <FormDadosTransportadora
                            loading={carregando}
                            onSubmit={handleSubmit}
                            showLoading={false}
                            ref={formRef}
                        />
                        <Box mt={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                        Volumes Transportados
                                    </Typography>
                                    <Divider />
                                </Grid>
                                {isEmpty(mov.infMov.transp?.vol) && (
                                    <Box display="block" width="100%">
                                        <CardNaoEncontrado removeMarginCard mensagem="Nenhum Volume encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
                                    </Box>
                                )}
                                {mov.infMov.transp?.vol && mov.infMov.transp.vol.map((volume, index) => {
                                    const key = `volume-${index}-transp`
                                    return (
                                        <Box display="block" width="100%" key={key}>
                                            <CardVolumeTransportado model={volume} onClick={onClick} onCheck={() => { }} selected={false} />
                                        </Box>
                                    )
                                })}
                                <Grid item xs={12}>
                                    <Box width="100%" mt={4}>
                                        <Button fullWidth variant="outlined" onClick={adicionarNovo} color="primary" size="large">
                                            <NovoIcon tipo="BUTTON" />
                                            Adicionar Volume
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </Container>
                <div className={cadClasses.acoes}>
                    <Container maxWidth="md" className={classes.containerAcoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('anterior')}
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Anterior
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => submit('proximo')}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <AvancarIcon tipo="BUTTON_PRIMARY" />
                                    Próximo
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </Grid>
    )
}

export default DadosTransportadoraPage