import { KeyValueModel } from "model";
import { EnumIndPres } from "model/enums/enum-ind-pres";

export const IndPresMock: KeyValueModel[] = [
    new KeyValueModel(EnumIndPres.NAO_SE_APLICA, 'Não se aplica'),
    new KeyValueModel(EnumIndPres.ENTREGA_DOMICILIO, 'NFC-e em operação com entrega a domicílio'),
    new KeyValueModel(EnumIndPres.NAO_PRESENCIAL_INTERNET, 'Operação não presencial, pela Internet'),
    new KeyValueModel(EnumIndPres.NAO_PRESENCIAL_OUTROS, 'Operação não presencial, outros'),
    new KeyValueModel(EnumIndPres.NAO_PRESENCIAL_TELEATENDIMENTO, 'Operação não presencial, Teleatendimento'),
    new KeyValueModel(EnumIndPres.PRESENCIAL, 'Operação presencial'),
    new KeyValueModel(EnumIndPres.PRESENCIAL_FORA, 'Operação presencial, fora do estabelecimento'),
]