import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { PessoaModel } from '../../../../../../../model/api/gestao/pessoa/pessoa-model';
import { CardCustumer } from './components/card-custumer/card-custumer';

export interface CustumerGridProps {
  list: Array<PessoaModel>;
  carregando: boolean;
  onCardSelected: (cliente: PessoaModel) => any;
}

export const CustumerListData = (props: CustumerGridProps) => {
  const onCardSelected = (cliente: PessoaModel) => {
    props.onCardSelected(cliente);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum cliente encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((pessoa, index) => {
          return (
            <CardCustumer onClick={onCardSelected} model={pessoa} key={index} />
          );
        })}
    </>
  );
};
