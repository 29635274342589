import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const RelatorioConfigIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <g id="surface2602486">
                    <path d="M 30.414062 2 L 43 14.585938 L 43 28.363281 L 42.808594 27.410156 L 42.507812 25.710938 L 41 25.710938 L 41 16 L 29 16 L 29 4 L 9 4 L 9 46 L 27.601562 46 L 28.007812 46.410156 L 29.65625 48 L 7 48 L 7 2 Z M 31 14 L 39.585938 14 L 31 5.414062 Z M 38 25 C 38 25.25 37.953125 25.488281 37.871094 25.710938 L 35.007812 25.710938 L 34.710938 27.410156 L 34.507812 28.410156 L 33.609375 27.808594 L 32.886719 27.292969 L 34.136719 25.726562 C 34.046875 25.496094 34 25.25 34 25 C 34 23.894531 34.894531 23 36 23 C 37.105469 23 38 23.894531 38 25 Z M 26.105469 34.371094 L 20.402344 27.957031 C 20.269531 27.984375 20.136719 28 20 28 C 19.824219 28 19.652344 27.976562 19.484375 27.929688 L 15.933594 31.480469 C 15.976562 31.652344 16 31.824219 16 32 C 16 33.105469 15.105469 34 14 34 C 12.894531 34 12 33.105469 12 32 C 12 30.894531 12.894531 30 14 30 C 14.175781 30 14.347656 30.023438 14.515625 30.070312 L 18.070312 26.515625 C 18.023438 26.347656 18 26.175781 18 26 C 18 24.894531 18.894531 24 20 24 C 21.105469 24 22 24.894531 22 26 C 22 26.214844 21.964844 26.425781 21.898438 26.628906 L 26.980469 32.347656 L 27.808594 33.507812 L 28.410156 34.410156 L 27.410156 34.507812 L 26.015625 34.753906 C 26.03125 34.625 26.0625 34.496094 26.105469 34.371094 Z M 26.105469 34.371094 " />
                    <path d="M 49.710938 40.710938 L 49.710938 36.507812 L 46.507812 36.007812 C 46.410156 35.710938 46.210938 35.308594 46.109375 35.007812 L 47.910156 32.308594 L 45.007812 29.410156 L 42.410156 31.308594 C 42.109375 31.109375 41.710938 31.007812 41.410156 30.910156 L 40.808594 27.710938 L 36.609375 27.710938 L 36.109375 30.910156 C 35.808594 31.007812 35.410156 31.210938 35.109375 31.308594 L 32.410156 29.410156 L 29.410156 32.410156 L 31.210938 34.910156 C 31.007812 35.210938 30.910156 35.609375 30.808594 36.007812 L 27.710938 36.507812 L 27.710938 40.710938 L 30.808594 41.210938 C 30.910156 41.507812 31.109375 41.910156 31.210938 42.210938 L 29.410156 44.910156 L 32.410156 47.910156 L 35.109375 46.109375 C 35.507812 46.308594 35.808594 46.410156 36.210938 46.507812 L 36.609375 49.710938 L 40.808594 49.710938 L 41.308594 46.507812 C 41.609375 46.410156 42.007812 46.210938 42.308594 46.109375 L 45.007812 47.910156 L 48.007812 44.910156 L 46.109375 42.308594 C 46.308594 42.007812 46.410156 41.609375 46.507812 41.308594 Z M 38.710938 41.710938 C 37.007812 41.710938 35.710938 40.410156 35.710938 38.710938 C 35.710938 37.007812 37.007812 35.710938 38.710938 35.710938 C 40.410156 35.710938 41.710938 37.007812 41.710938 38.710938 C 41.710938 40.410156 40.410156 41.710938 38.710938 41.710938 Z M 38.710938 41.710938 " />
                </g>
            </DefaultIcon>
        </>
    );
};
