import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const CodigoBarrasIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          d="M5 5V17H3C1.35547 17 0 18.3555 0 20V42C0 43.6445 1.35547 45 3 45H47C48.6445 45 50 43.6445 50 42V20C50 18.3555 48.6445 17 47 17H45V5H43V17H41V5H37V17H35V5H33V17H31V5H28V17H26V5H24V17H22V5H14V17H12V5H9V17H7V5H5ZM3 19H47C47.5547 19 48 19.4453 48 20V42C48 42.5547 47.5547 43 47 43H3C2.44531 43 2 42.5547 2 42V20C2 19.4453 2.44531 19 3 19ZM5 21V35H7V21H5ZM9 21V35H12V21H9ZM14 21V35H22V21H14ZM24 21V35H26V21H24ZM28 21V35H31V21H28ZM33 21V35H35V21H33ZM37 21V35H41V21H37ZM43 21V35H45V21H43Z"
        />
      </DefaultIcon>
    </>
  );
};
