import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { CardSetores } from 'views/components/cards/card-setor';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';

export interface SetoresListDataProps {
  list: Array<SetoresModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const SetoresListData = (props: SetoresListDataProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum setor encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
          key={0}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((setor, index) => {
          return (
            <CardSetores
              model={setor}
              key={index}
              onClick={onCardSelected}
              onCheck={onCardChecked}
              selected={false}
            />
          );
        })}
    </>
  );
};
