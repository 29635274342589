import { Tooltip, Typography, Box } from '@material-ui/core';
import { CardPontosVendaProps } from './card-pontos-venda-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import { StatusIndstatusMock } from 'data/mocks/status-indstatus-mock';
import { TpCaixaMock } from 'data/mocks/tp-caixa-mock';
import { useStyles } from './card-pontos-venda-styles';
import classNames from 'classnames';
import { EnumIndstatusStatus } from 'model/enums/enum-indstatus-status';

export const CardPontosVenda = ({
  model,
  onClick,
  onCheck,
  selected,
  chavePdvRef
}: CardPontosVendaProps) => {
  const classes = useDefaultCardStyles();
  const cardPDVClasses = useStyles();
  const { isMobile, theme } = useThemeQueries();

  const descricao = StatusIndstatusMock.filter(
    (x) => x.Key === model.indStatus
  )[0].Value;

  const numeroStr = ('000' + model.numCaixa).substr(-3);

  const caixa = TpCaixaMock.filter((x) => x.Key === model.tpCaixa)[0]?.Value;

  return (
    <>
      <DefaultCard
        isSelected={chavePdvRef.current === model.chave}
        onClick={() => {
          onClick(model.id);
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={cardPDVClasses.tagStatus}
            style={{
              background:
                model.indStatus === EnumIndstatusStatus.BLOQUEADO
                  ? theme.palette.warning.main
                  : model.indStatus === EnumIndstatusStatus.DESATIVADO
                  ? theme.palette.error.main
                  : theme.palette.success.main
            }}
          ></div>
        </Tooltip>
        {isMobile ? (
          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Nro
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{numeroStr}</strong>
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.descricao}</strong>
              </Typography>
              <Typography color="textPrimary" variant="caption">
                {model.chave}
              </Typography>
            </div>
            <div
              className={classNames(
                classes.celulaGrid,
                cardPDVClasses.statusContainer
              )}
            >
             {chavePdvRef.current === model.chave && (
                <Box
                  className={classNames(cardPDVClasses.status, 'darkStatus')}
                  style={{ display: 'flex', height: '34px', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                    style={{ color: '#fff', fontSize: '12px' }}
                  >
                    Vinculado
                  </Typography>
                </Box>
              )}

              <Box className={cardPDVClasses.status}>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{ color: '#fff' }}
                >
                  {caixa}
                </Typography>
              </Box>
            </div>
          </div>
        ) : (
          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Nro
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{numeroStr}</strong>
              </Typography>
            </div>
            <div className={classes.celulaGridFull}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                <strong>{model.descricao}</strong>
              </Typography>
              <Typography color="textPrimary" variant="caption">
                {model.chave}
              </Typography>
            </div>

            <div
              className={classNames(
                classes.celulaGrid,
                cardPDVClasses.statusContainer
              )}
            >
               {chavePdvRef.current === model.chave && (
                <Box
                  className={classNames(cardPDVClasses.status, 'darkStatus')}
                  style={{ display: 'flex', height: '34px', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                    style={{ color: '#fff', fontSize: '12px' }}
                  >
                    Vinculado
                  </Typography>
                </Box>
              )}

              <Box className={cardPDVClasses.status}>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{ color: '#fff' }}
                >
                  {caixa}
                </Typography>
              </Box>
            </div>
          </div>
        )}
        <Tooltip arrow title="Editar PDV">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
