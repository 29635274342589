
import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { FinalizadoraModel } from "model/api/gestao/finalizadora/finalizadora-model";
import CredenciarPix from "./components/credenciar-pix";

interface DialogCredenciarPixProps {
    openned: boolean;
    finalizar: (finalizadora: FinalizadoraModel) => Promise<void>;
    cancelar: (message: string) => Promise<void>;
    pagamentoId: string;
}

export const DialogCredenciarPix = ({ openned, finalizar, cancelar, pagamentoId }: DialogCredenciarPixProps) => {

    const { isMobile } = useThemeQueries();

    return (
        <DialogSaurus
            aberto={openned || false}
            titulo='Credenciar PIX'
            tamanho="sm"
            fullScreen={isMobile}
        >
            <CredenciarPix
                cancelar={cancelar}
                finalizadoraId={pagamentoId}
                finalizar={finalizar}
            />
        </DialogSaurus>
    );
};