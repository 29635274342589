import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormInfoPedidoCompraValidation = () => {

  const formInfoPedidoCompraValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        // transportadora: Yup.string().required('Nome do InfoPedidoCompra é Obrigatório'),
        // descricao: Yup.string().required('Descrição é Obrigatório'),
      })
    )
  }, [])
  return {
    formInfoPedidoCompraValidationYup,
  }
}

