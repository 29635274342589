import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            width: "100%",
            textTransform: "capitalize",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: "normal",
        },
    })
);