import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    infoBox: {
        background: theme.palette.grey[100],
        padding: '1rem',
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        marginBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        gridGap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            padding: '10px',
            marginRight: 0
        }
    },
}))