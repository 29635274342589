import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        alignItems: 'stretch',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
    },
    resumoContainer: {
        flex: '1 1 100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        aligmItems: 'stretch',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    imagemContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'auto',
            marginLeft: theme.spacing(0),
            marginBottom: theme.spacing(4),
        },
    },
    tipoStatus: {
        padding: `8px`,
        fontWeight: 500,
        color: '#FFF',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
        "&:last-child": {
            marginRight: 0
        }
    },
    bgInfo: {
        background: theme.palette.info.main
    },
    bgSuccess: {
        background: theme.palette.success.main
    },
    bgError: {
        background: theme.palette.error.dark
    },
    bgPrimaryDark: {
        background: theme.palette.primary.dark
    }
}));
