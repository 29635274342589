import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        background: '#FFF',
        flex: 1,
        flexDirection: 'column',
        padding: 0
    },
    containerAcoes: {
        padding: 0,
    },
    buttonTitle:{
        color:theme.palette.primary.main
    },
    buttonAddPayment:{
        margin: theme.spacing(2 , 0),
    }
}))