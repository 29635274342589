import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento } from '../../../form-validations'


export const useFormContratoDocumentoValidation = () => {

    const { documentoYup } = useValidationYupDocumento(false)
    const FormContratoDocumentoValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                documento: documentoYup()
            })
        )
    }, [documentoYup])

    return {
        FormContratoDocumentoValidationYup
    }
}

