import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

interface UsePostNovaEntradaXMLProps {
  xml: string;
  id: string;
  refazer: boolean;
}

export function usePostNovaEntradaXML() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postNovaEntradaXML = useCallback(
    ({ xml, id, refazer }: UsePostNovaEntradaXMLProps) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${id}/Entrada/inicio?refazer=${refazer ? 'false' : 'true'}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: xml,
        enviarTokenUsuario: true,
        timeout: 100 * 1000,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postNovaEntradaXML
  };
}
