import { toInteger } from "lodash";
import { EmissorModel } from "model/api/gestao/master/emissor";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

class ConfigNfceModel {
    constructor(
        public tipo: string = "",
        public valor: any,
    ) { }
}


export function useGetConfigEmissor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConfigEmissor = useCallback(
        async (cnpj: string) => {
            const ret = await invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${cnpj}/fiscal/configuracao-fiscal`,
                method: "GET",
                enviarTokenUsuario: true,
                headers: { 'Domain': 'app.touchone.com.br' },
            });

            if (ret?.resultado) {
                const valida = ret.resultado.data as Array<ConfigNfceModel>;
                const novoModel = new EmissorModel();
                //TRATAMENTO PARA RETORNO DA API DO FILIPE
                if (valida && valida.length !== undefined) {
                    novoModel.cIdToken = valida.filter(x => x.tipo === "CIdToken")[0]?.valor
                    novoModel.csc = valida.filter(x => x.tipo === "CSC")[0]?.valor
                    novoModel.dValidade = valida.filter(x => x.tipo === "CertificadoVencimento")[0]?.valor
                    novoModel.pfxLocalUrl = valida.filter(x => x.tipo === "CertificadoArquivo")[0]?.valor
                    novoModel.tpAmb = toInteger(valida.filter(x => x.tipo === "TpAmb")[0]?.valor || "2")
                    novoModel.tpCertificado = valida.filter(x => x.tipo === "CertificadoTipo")[0]?.valor
                    ret.resultado.data = novoModel;
                }
            }
            return ret;
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getConfigEmissor,
    };
}
