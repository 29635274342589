import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ModificadorModel } from '../../../../model/api/gestao/modificador/modificador-model';
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function usePostModificador() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const postModificador = useCallback(
    (modificador: ModificadorModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(modificador),
        enviarTokenUsuario: true,
      }),
    [getEmpresaSelecionada, invocarApi]
  );

  return {
    ...outrasPropriedades,
    postModificador,
  };
}
