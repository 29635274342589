import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { CarrinhoButton } from '../../components/buttons-actions/carrinho-button/carrinho-button';
import { FinalizarVendaButton } from '../../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';
import { VendaHeader } from '../../components/produtos/produto-header/produto-header';
import { MovProdAvulsoFragmentProps } from './mov-prod-avulso-fragment-props';
import { useStyles } from './mov-prod-avulso-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

const MovProdAvulsoFragment = ({ submit, add, textChanged, ...props }: MovProdAvulsoFragmentProps) => {
  const defaultClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const { getMov } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools()

  const [carrinhoAvulso, setCarrinhoAvulso] = useState<
    Array<number> | undefined
  >(undefined);
  const inicial = useRef<boolean>(true)
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')

  const valueRef = useRef<HTMLParagraphElement | null>(null);

  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
  } = useKeyboard({
    maxLength: 7,
    floatCases: 2,
    isNumeric: true,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    textoAtual: textoAtual,
    digitado: digitado
  });

  const attValueDisplay = useCallback((any: any) => {
    const v = getFormattedText();
    if (valueRef.current) valueRef.current.textContent = v;
  }, [getFormattedText])


  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler])

  const atualizaCarrinhoAvulso = useCallback(() => {
    const mov = getMov();

    if (!props.produtoAvulso) return;
    // if (!versao) return;


    if (isEmpty(mov?.produtos)) {
      setCarrinhoAvulso([]);
      return;
    }

    const movProdAvulsos = mov!.produtos.filter(
      (x) =>
        x.produtoGradeId === props.produtoAvulso!.produtoGradeId &&
        x.ativo === true,
    );

    if (movProdAvulsos.length === 0 || !movProdAvulsos) {
      setCarrinhoAvulso([]);
    } else {
      setCarrinhoAvulso(movProdAvulsos.map((a) => a.vProd * 100));
    }
  }, [getMov, props.produtoAvulso]);

  //EFFECT PARA ATUALIZAR O CARRINHO APOS O CARREGAMENTO DA TELA
  useEffect(() => {
    atualizaCarrinhoAvulso();
  }, [atualizaCarrinhoAvulso]);

  //EFFECT PARA PREENCHER O ULTIMO VALOR DO CARRINHO (CARREGAMENTO TELA)
  useEffect(() => {
    if (inicial.current && carrinhoAvulso) {
      if (carrinhoAvulso.length > 0) {
        resetText(
          toDecimalString(carrinhoAvulso[carrinhoAvulso.length - 1], 2),
        );
      }
      inicial.current = false;
    }

  }, [carrinhoAvulso, resetText]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    submitKey();
  }, [submitKey]);

  const header = useMemo(() => {

    return (
      <Grid className={defaultClasses.header}>
        <VendaHeader
          id='menuAdm'
          title="Lançamento Avulso"
        />
      </Grid>
    );
  }, [defaultClasses.header]);

  const carrinho = useMemo(() => {
    const digitadoInterno = toDecimal(getFormattedText());

    return (
      <>
        {(carrinhoAvulso?.length || 0) > 1 && (
          <Grid className={classes.containerCarrinhoAvulso}>
            <span>
              Histórico:{' '}
              {carrinhoAvulso &&
                carrinhoAvulso
                  .filter((item, index) => {
                    if (digitadoInterno > 0 && index === carrinhoAvulso.length - 1) {
                      return false;
                    }
                    return true;
                  })
                  .map((item, index) => {
                    return (
                      toDecimalString(item / 100, 2).replace('R$', '') + ' + '
                    );
                  })}
            </span>
          </Grid>
        )}
      </>
    );
  }, [carrinhoAvulso, classes.containerCarrinhoAvulso, getFormattedText]);

  const display = useMemo(() => {
    return (
      <Grid className={classes.contentTela}>
        <span
          style={{ marginBottom: '3em', fontSize: '1rem', fontWeight: '500' }}
        >
          R$
        </span>
        <Typography
          ref={valueRef}
          style={{
            fontSize: '3rem',
            fontWeight: 700,
          }}
        >
          0,00
          {/* {versao && getFormattedText()} */}
        </Typography>
      </Grid>
    );
  }, [classes.contentTela]);

  const teclado = React.useMemo(() => {
    return (
      <>
        <Keyboard
          isButtonAddTopKeyboard
          isButtonKeyboardAdd
          handleAdd={handleAdd}
          handleBackSpace={handleBackSpace}
          handleAddValue={handleAddValue}
          handleSubmit={handleSubmit}
          handleText={handleText}
          isNumeric
          isButtonBackspace
        />
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const carrinhoButton = useMemo(() => {
    return (
      <>
        <CarrinhoButton />
      </>
    );
  }, []);

  const finalizarVendaButton = useMemo(() => {
    return (
      <>
        <FinalizarVendaButton />
      </>
    );
  }, []);

  return (
    <Grid className={defaultClasses.root}>
      {header}
      <Grid className={classNames(classes.containerTela, classes.unselectable)}>
        {carrinho}
        {display}
      </Grid>
      <Grid
        container
        className={classes.unselectable}
        style={{ flex: 1, overflowX: 'hidden' }}
      >
        {teclado}
      </Grid>
      <Grid
        className={classNames(classes.containerFooter, classes.unselectable)}
      >
        {carrinhoButton}
        {finalizarVendaButton}
      </Grid>
    </Grid>
  );
};

export default MovProdAvulsoFragment;
