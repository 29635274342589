import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    inputDate: {
        flex: 1,
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        "appearance": "none",
        width: '100%'
    }
}))