import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";
export const HistoricoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 7.90625 0.96875 C 7.863281 0.976563 7.820313 0.988281 7.78125 1 C 7.316406 1.105469 6.988281 1.523438 7 2 L 7 12 L 17 12 C 17.359375 12.003906 17.695313 11.816406 17.878906 11.503906 C 18.058594 11.191406 18.058594 10.808594 17.878906 10.496094 C 17.695313 10.183594 17.359375 9.996094 17 10 L 10.34375 10 C 14.105469 6.304688 19.269531 4 25 4 C 36.664063 4 46 13.335938 46 25 C 46 36.664063 36.664063 46 25 46 C 13.335938 46 4 36.664063 4 25 C 4 21.566406 4.847656 18.207031 6.28125 15.34375 L 4.5 14.4375 C 2.933594 17.574219 2 21.234375 2 25 C 2 37.734375 12.265625 48 25 48 C 37.734375 48 48 37.734375 48 25 C 48 12.265625 37.734375 2 25 2 C 18.777344 2 13.117188 4.496094 9 8.5 L 9 2 C 9.011719 1.710938 8.894531 1.433594 8.6875 1.238281 C 8.476563 1.039063 8.191406 0.941406 7.90625 0.96875 Z M 24 9 L 24 23.28125 C 23.402344 23.628906 23 24.261719 23 25 C 23 25.171875 23.019531 25.339844 23.0625 25.5 L 16.28125 32.28125 L 17.71875 33.71875 L 24.5 26.9375 C 24.660156 26.980469 24.828125 27 25 27 C 26.105469 27 27 26.105469 27 25 C 27 24.261719 26.597656 23.628906 26 23.28125 L 26 9 Z" />
            </DefaultIcon>
        </>
    );
};
