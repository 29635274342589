import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 'auto'
    },
    containerButton: {
        padding: '8px',
    },
    button: {
        height: '60px',
    },
    buttonRedondo: {
        width: '60px',
        padding: 0,
        borderRadius: '100%',
        marginLeft: '8px',
        "& svg": {
            marginLeft: 4,
        }
    },
    containerResumo: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: '8px',
        fontFamily: 'monospace !important'
    },
    resumo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
        borderRadius: '7px',
    },
    imageError: {
        textAlign: 'center',
        marginBottom: 30,
        "& svg": {
            width: 150,
            height: 150,

        },
    },
}));
