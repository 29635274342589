import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '155px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}));