export class UsuarioModel {
    constructor(
        public id: string = '',
        public nome: string = '',
        public email: string = '',
        public imagem: string = '',
        public status: number = 0,
        public codAcessoSuporte: string = '',
        public expiracaoAcessoSuporte: string = '',
        public userKey: string = '',
    ){}
}