import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalPropsCalculadora } from '../utils/modal-props';
import CalcModal from './calc-modal/calc-modal';

export const CalculadoraModal = (props: ModalPropsCalculadora) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && (
        <CalcModal
          index={Number(props.id)}
          value={props.value!}
          setValue={props.setValue!}
        />
      )}
    </DefaultModal>
  );
};
