import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerAvatar: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingRight: 8,
    },
    cardImg: {
        background: theme.palette.common.white,
        objectFit: 'contain',
        width: 45,
        height: 45,
        borderRadius: theme.shape.borderRadius,
    },
}));
