import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { ProcurarIcon, VoltarIcon } from 'views/components/icons';

interface IdentificacaoClienteHeaderProps {
  showButtonVoltar: boolean;
  handleClickVoltar: (state: boolean) => void;
  handleClickBuscar: () => void;
  handleCustomClickVoltar?: () => void
}

export const IdentificacaoClienteHeader = ({
  showButtonVoltar,
  handleClickVoltar,
  handleClickBuscar,
  handleCustomClickVoltar
}: IdentificacaoClienteHeaderProps) => {
  const { getMov } = useMovAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const history = useHistory();

  const handleClickVoltarInterno = useCallback(() => {
    handleClickVoltar(!showButtonVoltar);
  }, [handleClickVoltar, showButtonVoltar]);

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => {
          if (showButtonVoltar) {
            return handleClickVoltarInterno();
          }

          if (handleCustomClickVoltar) {
            handleCustomClickVoltar()
            return
          }

          if (getMov()?.tipoMovimentacao === EnumTipoMovimentacao.VENDA) {
            return history.push('/venda-simples/pagamentos');
          }

          if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.MESA
          ) {
            return history.push('/venda-simples/vincular-mesa');
          }

          if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.COMANDA
          ) {
            return history.push('/venda-simples/leitor-comanda');
          }

          return history.goBack();
        }}
      ></ButtonPrivateHeader>
    );
  }, [
    showButtonVoltar,
    getMov,
    getConfiguracoesMesaEComanda,
    history,
    handleClickVoltarInterno,
    handleCustomClickVoltar
  ]);

  const rightArea = useCallback(() => {
    return (
      <>
        {!showButtonVoltar && (
          <ButtonPrivateHeader
            icon={<ProcurarIcon tipo="PRIVATE_HEADER" />}
            tooltip="Procurar Cliente"
            onClick={handleClickBuscar}
          ></ButtonPrivateHeader>
        )}
      </>
    );
  }, [handleClickBuscar, showButtonVoltar]);

  return (
    <>
      <PrivatePageHeader
        title={'Identificação do Cliente'}
        leftArea={leftArea()}
        rightArea={rightArea()}
      />
    </>
  );
};
