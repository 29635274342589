import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    tagStatus: {
        width: "5px",
        borderRadius: "5px",
        margin: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cardContent: {
        flex: "1 1 100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        paddingRight: theme.spacing(1),
        "& span": {
            fontSize: "0.7em",
        },
        "& p": {
            fontSize: "1.0em",
        },
    },
    celulaGrid: {
        marginLeft: theme.spacing(2),
        minWidth: 0,
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    celulaGridValue: {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        paddingLeft: '5px',
        marginLeft: theme.spacing(2),
        marginTop: '8px',
        marginBottom: '8px',
        minWidth: 0,
        "& p": {
            maxWidth: '100px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center'
        }
    },
}));

