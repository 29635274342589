import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  status: {
    display: 'flex',
    padding: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    background: theme.palette.primary.main,
    fontSize: 12,
    '&.darkStatus': {
      background: '#666666'
    }
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1)
  },
  tagStatus: {
    width: '5px',
    borderRadius: '5px',
    margin: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
