import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    background: {
        background: theme.palette.primary.light,
        padding: '4px 4px 4px 4px',
        borderRadius: '50%',
        width: '160px',
    },
    cLoader: {
        animation: 'rotate 1.5s infinite',
        border: `4px solid ${theme.palette.primary.light}`,
        margin: 0,
        borderRadius: '100%',
        borderTopColor: '#FFF',
        height: '150px',
        width: '150px',
        background: theme.palette.primary.light,
        padding: 4
    },
    backgroundLoader: {
        background: theme.palette.primary.main,
        width: '100%',
        height: '100%',
        borderRadius: '100%',
    },
    "@keyframes rotate": {
        "to": {
            transform: 'rotate(1turn)'
        }
    }
}));

