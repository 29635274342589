import { NFrefModel } from "model/api/gestao/venda/venda-completa-model"
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../card-doc-referenciados-styles";
import classNames from "classnames";
import { useThemeQueries } from "views/theme";
import { TipoDocRefMock } from "data/mocks/tipo-doc-ref-mock";
import { EditarIcon } from "views/components/icons";

interface Props {
    model: NFrefModel
}

export const DocReferenciadoECF = ({ model }: Props) => {
    const classes = useStyles();
    const valorReal = Object.entries(model).find(x => x[1])
    const getKey = Object.keys(model).filter(x => x === valorReal?.[0] || '')
    const { isMobile } = useThemeQueries()
    return (
        <Grid container spacing={1} >
            <Grid item xs={6}>
                <Grid >
                    <Typography color="primary" variant="body1">
                        Tipo de Documento
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.valores)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {TipoDocRefMock.filter(x => x.Key === getKey[0])[0].Value}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="body1">
                        Número do Cupom
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.valores)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.refECF?.nECF}
                    </Typography>
                </Grid>

            </Grid>
            <Grid xs={5}>
                <Grid item >
                    <Typography color="primary" variant="body1"  >
                        Número do COO
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.valores)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.refECF?.nCOO}
                    </Typography>
                </Grid>

            </Grid>

            {isMobile &&
                <Grid xs={1} item style={{ display: 'flex' }} alignItems="center" justifyContent="flex-end">
                    <EditarIcon tipo='BUTTON' />
                </Grid>
            }

        </Grid>
    )
}