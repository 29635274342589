import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostEnviarCupomVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postEnviarCupomVenda = useCallback(
        (idVenda: string, email: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Movimento/${idVenda}/cupom`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({ email: email }),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEnviarCupomVenda,
    };
}
