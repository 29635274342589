import { EnumMesas } from "model/enums/enum-mesas";
import { PedidoModel } from "./pedido-model";

export class PedidoMesaProcessadaModel {
    constructor(
        public idMesa: string = '',
        public codigoMesa: string = '',
        public statusMesa: EnumMesas = EnumMesas.ATIVO,
        public pedidos: PedidoModel[] = [],
        public qtdePessoas: number = 0,
        public dataUltimoPedido: string | Date = '',
        public valorTotalPedido: number = 0,
        public salaoId: string = '',
        public dataCriacao: string | Date = '',
        public ociosa: boolean = false,
        public nomeSalao: string = ''
    ) { }
}
