import { useCallback, useEffect, useMemo, useRef } from "react";
import { useKeyboard } from "services/app/hooks/keyboard";
import { DataNascimentoTecladoProps } from "./data-nascimento-teclado-props";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./data-nascimento-teclado-styles";
import { Keyboard } from "views/components/keyboard/keyboard";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { maskDataNasc } from "views/components/controles/inputs/text-field-saurus/text-field-saurus-masks";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { toDateString } from "utils/to-date";
import { SugestaoSources, useClienteIdentificacaoUtils } from "../use-cliente-identificacao-utils";

export const DataNascimentoTeclado = ({
    textChanged,
    ...props
}: DataNascimentoTecladoProps) => {
    const { showSuggestionToast } = useClienteIdentificacaoUtils()
    const { currentClienteIsPadrao } = useMovAtual()
    const { getMov } = useMovAtual()
    const classes = useStyles()
    const { addHandler, removeHandler } = useEventTools()

    const valueRef = useRef<HTMLParagraphElement | null>(null);
    const valueInfo = useRef<string>('');
    const digitado = useRef<boolean>(false)
    const textoAtual = useRef<string>('')

    const {
        getFormattedText,
        addValue,
        sendText,
        addKey,
        submitKey,
        backSpaceKey,
    } = useKeyboard({
        maxLength: 8,
        floatCases: 0,
        isNumeric: false,
        handleTextChanged: textChanged,
        digitado: digitado,
        textoAtual: textoAtual
    });

    const attValueDisplay = useCallback((any: any) => {
        const v = getFormattedText();
        if (valueRef.current) {
            if (v.length === 0) {
                valueRef.current.className = classes.placeholder
                valueRef.current.textContent = "Aguardando Digitação"
                valueInfo.current = ''
                return
            }
            valueRef.current.className = classes.inputText
            valueRef.current.textContent = maskDataNasc(v)
            valueInfo.current = v;
        };
    }, [classes.inputText, classes.placeholder, getFormattedText])

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    const handleAddValue = useCallback(
        (value: number) => {
            addValue(value);
        },
        [addValue],
    );

    const handleBackSpace = useCallback(() => {
        backSpaceKey();
    }, [backSpaceKey]);

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    const handleSubmit = useCallback(() => {
        submitKey();
    }, [submitKey]);

    const fillDataNasc = useCallback(async () => {
        const isPadrao = await currentClienteIsPadrao()
        const mov = getMov()
        let dataNascValueToSet: Date | string | undefined
        let fonteSugestao: SugestaoSources = 'Vazio'
        if (mov) {
            if (!isPadrao) {
                const cliente = mov.cliente
                if (cliente && cliente.dtNasc) {
                    dataNascValueToSet = cliente.dtNasc
                    fonteSugestao = 'Fluxo de venda atual'
                }
            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    dataNascValueToSet = receita.comprador.dataNascimento
                    fonteSugestao = 'Receita'

                }
            }
        }
        if (valueRef.current) {
            if (dataNascValueToSet) {
                valueRef.current.className = classes.inputText
                const dateString = maskDataNasc(toDateString(dataNascValueToSet) ?? '')

                valueRef.current.textContent = dateString

                handleText(dateString)
                textChanged(dateString)
                showSuggestionToast({
                    key: 'Data de Nascimento',
                    source: fonteSugestao
                })
            }
        }
    }, [classes.inputText, currentClienteIsPadrao, getMov, handleText, showSuggestionToast, textChanged])
    useEffect(() => {
        fillDataNasc()
    }, [handleText, getMov, classes.inputText, textChanged, fillDataNasc])

    const teclado = useMemo(() => {
        return (
            <>
                <Grid container style={{ flex: 1 }}>
                    <Keyboard
                        handleAdd={handleAdd}
                        handleBackSpace={handleBackSpace}
                        handleAddValue={handleAddValue}
                        handleSubmit={handleSubmit}
                        handleText={handleText}
                        isNumeric
                        isButtonBackspace
                    />
                </Grid>
            </>
        );
    }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

    return (
        <Grid className={classes.container}>
            <Grid className={classes.content}>
                <Typography className={classes.title}>{'Informe a Data de Nascimento'}</Typography>
                <Grid className={classes.inputArea}>
                    <Typography className={classes.placeholder} ref={valueRef}>
                        Aguardando Digitação
                    </Typography>
                </Grid>
            </Grid>
            {teclado}
        </Grid>
    )
}