import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  selectDisabled: {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23) !important'
    },
    '& svg': {
      flex: 'none',
      margin: 0
    }
  },
  menuItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    lineHeight: '40px',
    [theme.breakpoints.up('sm')]: {
      lineHeight: '30px'
    }
  }
}));
