import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        '& p': {
            maxWidth: '50%',
            color: theme.palette.text.secondary,
            fontSize: '13px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            '& span': {
                color: theme.palette.text.primary,
                fontWeight: 600
            }
        },
    },
    divider: {
        height:2,
        background: theme.palette.primary.light
    },
    options:{
        flexWrap: 'wrap'
    }
}))