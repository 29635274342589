import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    header: {
        flex: "0 1 auto"
    },
    detail: {
        flex: "0 1 auto",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 250,
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    bottom: {
        flex: "0 1 auto",
        margin: theme.spacing(1),
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
    },

}));
