import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { picker } from 'utils/picker';
import { useToastSaurus } from 'services/app';
import { isEqual } from 'utils/is-equal';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useSessaoAtual } from 'services/app';
import { DadosContaFormModel } from 'model/app/forms/master/dados-conta-form-model';
import { FormDadosConta } from 'views/components/form/master/dados-conta/form-dados-conta';
import { useThemeQueries } from 'views/theme';
import { usePostImagemBase64 } from '../../../../../../data/api/imagem/post-imagem';
import { EnumRetornoApiBase } from '../../../../../../data/api/base/api-base-response';
import { imagemForUpload } from '../../../../../../utils/imagem-for-upload';
import { OkIcon } from '../../../../../components/icons/ok-icon';
import classNames from 'classnames';
import { useConfirm } from 'material-ui-confirm';
import { UserIcon } from 'views/components/icons';
import { MeuUsuarioPutModel } from 'model/api/gestao/master/meu-usuario';
import { usePutMeuUsuario } from 'data/api/gestao/meu-usuario';

export const AccordionDadosConta = () => {
  // STATES E REFS
  const refDadosContaForm = useRef<DefaultFormRefs<DadosContaFormModel>>(null);
  const [preenchendoTela, setPreenchendoTela] = useState(false);
  const [carregandoManual, setCarregandoManual] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(true);
  const [DadosContaFormFormState, setDadosContaForm] = useState<
    DadosContaFormModel | undefined
  >(undefined);

  // PROVIDERS
  const {
    meuUsuario,
    usuario,
    refreshUser,
    carregando: carregandoUsuario,
  } = useSessaoAtual();
  const { putMeuUsuario, carregando: carregandoPutMeuUsuario } = usePutMeuUsuario();

  // CHAMADAS api
  const { postImagemBase64, carregando: carregandoPostImagem } =
    usePostImagemBase64();

  // AUX
  const { showToast } = useToastSaurus();
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const confirm = useConfirm();

  const carregando =
    carregandoPutMeuUsuario ||
    carregandoManual ||
    preenchendoTela ||
    carregandoPostImagem ||
    carregandoUsuario;

  const carregarTela = useCallback(() => {
    setPreenchendoTela(true);
    let usuarioDadosForm = picker<DadosContaFormModel>(
      meuUsuario,
      new DadosContaFormModel(),
    );
    usuarioDadosForm.nome = meuUsuario?.nome || '';
    usuarioDadosForm.id = meuUsuario?.id || '';

    setDadosContaForm(usuarioDadosForm);
    setPreenchendoTela(false);
  }, [meuUsuario]);

  useEffect(() => {
    if (!carregando) {
      carregarTela();
    }
  }, [meuUsuario, carregarTela, carregando]);

  const recarregarForm = useCallback(
    (model: DadosContaFormModel | undefined) => {
      //CORRECAO DE BASE64
      if (model && model.imagem) {
        if (model.imagem.length > 0)
          try {
            model.imagem = atob(model.imagem);
          } catch { }
      }
      return refDadosContaForm.current?.fillForm(model);
    },
    [],
  );

  useEffect(() => {
    recarregarForm(DadosContaFormFormState);
  }, [DadosContaFormFormState, recarregarForm]);

  const saveChangesDadosConta = useCallback(
    async (dadosContaModel: DadosContaFormModel) => {
      try {
        setCarregandoManual(true);
        let imagem = dadosContaModel.imagem;

        let imgUpload = imagemForUpload(dadosContaModel.imagem);
        if (imgUpload.length > 0) {
          const retImagem = await postImagemBase64(
            imgUpload,
            'perfil/',
            meuUsuario?.id || '',
          );
          if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
            throw new Error('Erro ao enviar a Imagem selecionada.');
          }
          if (retImagem.resultado?.data.data.status === 2) {
            throw new Error(
              'Erro ao enviar a Imagem selecionada.Detalhe: ' +
              retImagem.resultado?.data?.data?.retorno,
            );
          }
          imagem =
            retImagem.resultado?.data?.data?.url_blob +
            '?timestamp=' +
            new Date().getTime();
        }


        let usuarioModel = picker<MeuUsuarioPutModel>(
          dadosContaModel,
          new MeuUsuarioPutModel(),
        );
        usuarioModel.imagem = imagem;

        let ret = await putMeuUsuario(usuarioModel);
        if (ret.erro) {
          throw ret.erro;
        }

        dadosContaModel.imagem = btoa(usuarioModel.imagem);

        setDadosContaForm(dadosContaModel);
        await refreshUser(usuario?.originalToken || '');
        carregarTela();
        showToast('success', 'Dados da conta atualizados com sucesso!');
      } catch (e: any) {
        showToast('error', e.message);
        refDadosContaForm.current?.resetForm();
      } finally {
        setCarregandoManual(false);
      }
    },
    [putMeuUsuario, refreshUser, usuario?.originalToken, carregarTela, showToast, postImagemBase64, meuUsuario?.id],
  );

  const handleSubmit = useCallback(
    async (model: DadosContaFormModel, beforeModel: DadosContaFormModel) => {
      const DadosContaEqual = isEqual(model, beforeModel);
      if (DadosContaEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
        return;
      }
      if (model.email !== beforeModel.email) {
        confirm({
          title: 'Troca de E-mail',
          description: `Atenção, um e-mail será enviado para ${model.email}. Seu e-mail permanecerá o mesmo até a confirmação do e-mail que será enviado. Deseja Continuar?`,
          confirmationButtonProps: { variant: 'contained' },
          confirmationText: 'Sim',
          cancellationText: 'Não',
          cancellationButtonProps: { color: 'secondary' },
        }).then(() => {
          saveChangesDadosConta(model);
        });
        return;
      }
      saveChangesDadosConta(model);
    },
    [saveChangesDadosConta, showToast, confirm],
  );

  const buttonAlterarPerfil = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            disabled={carregando}
            onClick={() => refDadosContaForm.current?.submitForm()}
            variant="contained"
            color="primary"
            fullWidth
          >
            <OkIcon tipo="BUTTON_PRIMARY" />
            Alterar Perfil
          </Button>
        </Grid>
      </Grid>
    );
  }, [carregando]);

  const form = useMemo(() => {
    return (
      <FormDadosConta
        ref={refDadosContaForm}
        onSubmit={handleSubmit}
        showLoading={false}
        loading={carregando}
      />
    );
  }, [carregando, handleSubmit]);

  const userIcon = useMemo(() => {
    return <UserIcon tipo="BUTTON" fill={theme.palette.text.primary} />;
  }, [theme.palette.text.primary]);

  return (
    <AccordionSaurus
      labelPrimary={`Perfil de ${meuUsuario?.nome}`}
      tituloChildren={userIcon}
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          <div
            className={classNames(
              classesModal.contentForms,
              preenchendoTela ? classesModal.contentFormsLoading : undefined,
            )}
            style={{ height: '100%' }}
          >
            {carregando || !DadosContaFormFormState ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : (
              form
            )}
          </div>
          <div className={classesModal.acoes}>{buttonAlterarPerfil}</div>
        </div>
      </div>
    </AccordionSaurus>
  );
};
