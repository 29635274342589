import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

export const ModulosPDVMock: MenuModel[] = [

  // PDV
  new MenuModel('Venda de Produtos', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, [], '', 0, 0, EnumMenu.PDV),
  new MenuModel('Operação de Venda', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, [], '', 2, 0, EnumMenu.PDV),
  new MenuModel('Operações de Pedidos', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, [], '', 3, 0, EnumMenu.PDV),
  new MenuModel('Delivery', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY, [], '', 4, 0, EnumMenu.PDV),
  new MenuModel('Administrativo e Configurações', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, [], '', 6, 0, EnumMenu.PDV),

  new MenuModel('Catálogo de Produtos', 'Venda utilizando uma grade com categorias e produtos.', EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO, [], '/venda-simples/catalogo', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, EnumMenu.PDV),
  new MenuModel('Valor Avulso', 'Adicione valores avulsos a venda.', EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO, [], '/venda-simples/avulso', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, EnumMenu.PDV),
  new MenuModel('Leitor de Códigos', 'Adicionar produtos utilizando o leitor de barras ou com câmera do dispositivo.', EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR, [], '/venda-simples/leitor', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, EnumMenu.PDV),
  new MenuModel('Carrinho de Compra', 'Painel com os produtos da venda com opção de inserção por digitação.', EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO, [], '/venda-simples/carrinho', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, EnumMenu.PDV),

  new MenuModel('Selecionar Vendedor', 'Selecionar o vendedor dos Itens da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR, [], '/venda-simples/selecao-vendedor', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, EnumMenu.PDV),
  new MenuModel('Identificar Cliente', 'Identificar o cliente da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE, [], '/venda-simples/identificar-cliente', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, EnumMenu.PDV),
  new MenuModel('Receber ou Finalizar', 'Iniciar o recebimento ou finalização da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA, [], '/venda-simples/finalizar-venda', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, EnumMenu.PDV),
  new MenuModel('Cancelar Venda', 'Realizar o cancelamento da Venda Atual.', EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR, [], '/venda-simples/cancelar', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, EnumMenu.PDV),

  new MenuModel('Pedidos em Andamento', 'Gerenciar Pedidos realizados no estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS, [], '/venda-simples/pedidos', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, EnumMenu.PDV),
  new MenuModel('Mapa de Mesas', 'Gerenciar situação das mesas do estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA, [], '/venda-simples/pedidos/mesas', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, EnumMenu.PDV),
  new MenuModel('Mapa de Comandas', 'Gerenciar situação das comandas do estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA, [], '/venda-simples/pedidos/comandas', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, EnumMenu.PDV),
  new MenuModel('Entrada no Salão', 'Módulo para registrar entrada de clientes no estabelecimento.', EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA, [], '/venda-simples/gerar-mesa-comanda', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, EnumMenu.PDV),
  // new MenuModel('Identificação de Pedido', '', EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICADOR_PAGER, [], '/venda-simples/identificador-pedido', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, EnumMenu.PDV),
  
  new MenuModel('Acompanhamento Delivery', 'Gerenciar Pedidos para entrega.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY, [], '/venda-simples/pedidos/delivery', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY, EnumMenu.PDV),

  new MenuModel('Gestão e Relatórios', 'Cadastro de clientes, produtos e configurações.', EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA, [], '/produtos', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, EnumMenu.PDV),
  new MenuModel('Sincronizar Cadastros', 'Forçar atualização de cadastros da Retaguarda.', EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS, [], '/venda-simples/sincronizar-dados', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, EnumMenu.PDV),
  new MenuModel('Histórico de Vendas', 'Buscar vendas realizadas anteriormente', EnumMenu.OPERACIONAL_DENTROVENDA_HISTORICO_VENDA, [EnumCodigosPermissoes.HISTORICO_VENDAS], '/venda-simples/historico', 1, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, EnumMenu.PDV),
  new MenuModel('Resumo da Última Venda', 'Visualizar a última venda realizada.', EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA, [], '/venda-simples/finalizar-venda/comprovante?anterior=true', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, EnumMenu.PDV),
  new MenuModel('Configurar este PDV', 'Acessar configurações de venda e operação deste PDV.', EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV, [], '/configurar-pdv', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, EnumMenu.PDV),
  new MenuModel('Gerenciamento de Sessões','',EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSOES,[],'/gerenciamento-sessoes',0,EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO,EnumMenu.PDV),
  // new MenuModel(
  //   'Atendimento Delivery',
  //   '',
  //   EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY,
  //   [],
  //   '/venda-simples/atendimento-delivery',
  //   0,
  //   EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY,
  //   EnumMenu.PDV
  // ),


];
