import { guidEmpty } from "../../../../utils/guid-empty";
import { PerfilHorarioModel } from "../perfil-horario/perfil-horario-model";
import { PerfilPermissaoModel } from "./perfil-permissao-model";

export class PerfilModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public descricao: string = '',
    public horarios: PerfilHorarioModel[] = new Array<PerfilHorarioModel>(),
    public nome: string = '',
    public permissoes: PerfilPermissaoModel[] = []
  ) { }
}
