import { EnumTipoDocRef, RefEcfModel, RefNfModel, RefNfpModel } from "model/api/gestao/venda/venda-completa-model";

export class DocReferenciadoFormModel {
  constructor(
    public tipo: EnumTipoDocRef = EnumTipoDocRef.NfeNfcSat,
    public refNFe: string | null = null,
    public refNF: RefNfModel | null = null,
    public refNFP: RefNfpModel | null = null,
    public refCTe: string | null = null,
    public refECF: RefEcfModel | null = null,
  ) { }
}
