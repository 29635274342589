import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const NfceIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M5.70037 1.92291C5.18603 2.1267 5.21514 0.573995 5.21514 24.4469V46.5536L5.43835 46.7768L5.66155 47H22.984H40.3161L40.5005 46.7671C40.6751 46.5536 40.6848 46.3498 40.6848 43.7587V40.9833L43.1109 38.5474L45.5371 36.1213V28.5034V20.8757L45.3139 20.6719C45.1004 20.4681 44.9936 20.4584 42.9169 20.439L40.7334 20.4099L40.6848 15.3344L40.6363 10.2687L36.4149 6.0473L32.1935 1.82587L19.044 1.83557C11.8142 1.83557 5.80712 1.87439 5.70037 1.92291ZM31.0969 7.21183C31.126 10.7345 31.1357 11.0548 31.3007 11.2101C31.4559 11.375 31.7956 11.3847 35.328 11.4333L39.1807 11.4818V15.9458V20.4099L24.4008 20.4584L9.61125 20.5069L9.42687 20.7398C9.25219 20.9533 9.24248 21.225 9.24248 28.4742V35.9758L9.47539 36.2475L9.7083 36.5192L24.4687 36.5483L39.2195 36.5677L39.2292 41.0318V45.4958H22.9743H6.71933V24.4372V3.37858H18.8984H31.0678L31.0969 7.21183ZM37.9676 9.89995C37.9676 9.94848 36.7643 9.97759 35.2989 9.97759H32.6302V7.24094V4.49459L35.2989 7.1633C36.7643 8.62867 37.9676 9.86114 37.9676 9.89995ZM44.0038 28.6586L44.0329 35.4032L42.3637 37.0724L40.6848 38.7512V37.674V36.5871L41.2671 36.5483C41.9949 36.4998 42.2764 36.2669 42.2764 35.7332C42.2764 35.4808 42.2084 35.3256 42.0532 35.1994C41.8397 35.0247 41.3253 35.015 26.3126 35.015H10.7952V28.5325C10.7952 24.9612 10.8243 22.0111 10.8631 21.982C10.8922 21.9432 18.3646 21.914 27.4577 21.914H43.9843L44.0038 28.6586Z" />
                <path d="M11.9112 6.14431C11.7074 6.3384 11.6686 6.47426 11.6686 6.91096C11.6686 7.49323 11.7171 7.4447 10.9407 7.59998C10.5817 7.67761 9.9509 8.15313 9.68888 8.56071C9.03868 9.56027 9.15513 10.7345 9.98001 11.5691C10.31 11.8893 10.9019 12.2096 11.1737 12.2096C11.6298 12.2096 11.6686 12.2872 11.6686 13.1412V13.9564H11.1445C10.4458 13.9564 10.1159 14.2087 10.1159 14.7327C10.1159 15.2568 10.4458 15.5091 11.1445 15.5091H11.6686V16.0331C11.6686 16.7318 11.9209 17.0618 12.4449 17.0618C12.969 17.0618 13.2213 16.7318 13.2213 16.0331C13.2213 15.6644 13.2601 15.5091 13.3474 15.5091C13.8133 15.5091 14.5411 15.1694 14.9293 14.7618C16.2879 13.3741 15.5407 11.1033 13.6192 10.7927L13.2213 10.7248V9.87081V9.00712H13.8036C14.5023 9.00712 14.774 8.80333 14.774 8.28899C14.774 7.75525 14.5217 7.54175 13.823 7.48352L13.2213 7.4253V6.90126C13.2213 6.27047 12.9399 5.9017 12.4449 5.9017C12.2606 5.9017 12.0568 5.98904 11.9112 6.14431ZM11.6686 9.88052C11.6686 10.6083 11.6395 10.7539 11.5133 10.7539C11.2513 10.7539 10.7952 10.2105 10.7952 9.90963C10.7952 9.48264 11.1834 9.04594 11.6007 9.01682C11.6395 9.00712 11.6686 9.3953 11.6686 9.88052ZM13.8521 12.5492C14.1917 12.8792 14.182 13.4032 13.8424 13.7138C13.6968 13.8496 13.5027 13.9564 13.4057 13.9564C13.2407 13.9564 13.2213 13.869 13.2213 13.1315C13.2213 12.3649 13.231 12.3066 13.4154 12.3066C13.5318 12.3066 13.7259 12.4134 13.8521 12.5492Z" />
                <path d="M18.6654 7.56115C18.1511 7.76494 18.025 8.30839 18.3743 8.7548L18.5684 9.00711H22.5472C26.3902 9.00711 26.526 8.99741 26.7104 8.81302C26.9627 8.56071 26.9627 8.03667 26.7201 7.73583L26.5357 7.50292L22.7122 7.48351C20.3831 7.47381 18.811 7.50292 18.6654 7.56115Z" />
                <path d="M18.4131 10.9965C17.9958 11.4041 18.1414 11.9961 18.6945 12.2096C19.073 12.3454 27.642 12.3454 28.0593 12.1999C28.6125 12.0058 28.7483 11.307 28.3019 10.9577C28.0496 10.7539 27.9526 10.7539 23.343 10.7539H18.646L18.4131 10.9965Z" />
                <path d="M18.5879 14.0534C18.1318 14.2378 18.0347 14.8977 18.4132 15.2665L18.6461 15.5091H21.6642C24.7405 15.5091 24.954 15.4897 25.2257 15.1306C25.4683 14.8104 25.2936 14.2475 24.8957 14.0631C24.6143 13.937 18.9081 13.9273 18.5879 14.0534Z" />
                <path d="M14.5119 23.7773C14.1043 23.9714 14.0849 24.1849 14.114 28.6489L14.1431 32.8994L14.376 33.0838C14.6672 33.3167 15.0747 33.3167 15.3659 33.0838C15.5988 32.8994 15.5988 32.88 15.6473 30.386L15.6958 27.8822L16.4625 29.3864C17.7144 31.8514 18.2869 32.9188 18.4422 33.0935C18.6363 33.3264 19.1894 33.3167 19.4223 33.0838C19.5291 32.9771 19.6358 32.7053 19.6746 32.4724C19.7038 32.2395 19.7232 30.2695 19.7038 28.0957L19.6746 24.1266L19.4029 23.8937C19.0536 23.5929 18.7139 23.5929 18.4131 23.9034L18.1705 24.1363V26.6401C18.1705 28.3384 18.1413 29.105 18.0734 29.0371C18.0152 28.9788 17.4135 27.8046 16.7245 26.4169C15.7249 24.3886 15.4241 23.8743 15.2203 23.7773C14.9098 23.6414 14.7933 23.6414 14.5119 23.7773Z" />
                <path d="M21.7514 23.787C21.3826 24.0684 21.3729 24.2334 21.3729 28.5615V32.7927L21.6155 33.0256C21.897 33.3167 22.3337 33.3361 22.6442 33.0838C22.8674 32.8994 22.8771 32.8509 22.9062 31.0944L22.9353 29.2894H24.129C25.4682 29.2894 25.8564 29.1923 26.0213 28.843C26.1766 28.4936 26.1572 28.2801 25.9243 27.989C25.7302 27.7367 25.7205 27.7367 24.3231 27.7367H22.9256V26.4751V25.2135H24.2843C25.6041 25.2135 25.6623 25.2038 25.8855 24.9709C26.0408 24.8253 26.1281 24.6215 26.1281 24.4372C26.1281 24.2528 26.0408 24.049 25.8855 23.9034C25.6526 23.6608 25.6429 23.6608 23.7796 23.6608C22.4598 23.6705 21.8581 23.6996 21.7514 23.787Z" />
                <path d="M29.2627 23.8452C28.5834 24.0781 28.0108 24.4857 27.6712 24.9903C27.1375 25.757 27.0986 25.9996 27.0986 28.4742C27.0986 31.1817 27.1763 31.5214 28.0108 32.356C28.6902 33.0256 29.3404 33.2682 30.4952 33.2682C31.5336 33.2682 31.7568 33.1323 31.7568 32.5112C31.7568 31.9387 31.5239 31.7834 30.5922 31.7543C29.6509 31.7252 29.2142 31.5311 28.8648 31.0071C28.6028 30.6189 28.6028 30.5801 28.5737 28.7168C28.5349 26.6498 28.5931 26.2907 29.0104 25.7861C29.3695 25.3688 29.7868 25.2135 30.5728 25.2135C31.4365 25.2135 31.7568 25 31.7568 24.4372C31.7568 24.146 31.6985 24.0199 31.5044 23.8646C31.1648 23.5929 30.0003 23.5832 29.2627 23.8452Z" />
                <path d="M37.8609 25.4464C37.2883 25.6502 36.8031 25.9899 36.5022 26.4072C36.0364 27.0574 35.9297 27.5911 35.9297 29.3476C35.9297 30.6868 35.9588 31.0071 36.1238 31.4438C36.3955 32.1619 36.8031 32.6277 37.5018 32.9674C38.055 33.2488 38.1617 33.2682 39.1807 33.2682C40.1899 33.2682 40.2773 33.2488 40.4714 33.045C40.7431 32.7539 40.7528 32.2686 40.4908 32.0066C40.2967 31.8125 40.219 31.7931 39.5203 31.764C39.3359 31.764 38.9769 31.7446 38.7149 31.7349C38.3461 31.7155 38.1908 31.6573 37.9094 31.3952C37.6086 31.1235 37.5503 30.9876 37.5115 30.5801L37.463 30.0754L39.268 30.0463C41.5777 30.0075 41.5194 30.0463 41.5 28.5324C41.4806 27.232 41.2768 26.6789 40.5781 26.0481C39.8212 25.3688 38.744 25.1262 37.8609 25.4464ZM39.4233 27.0476C39.7338 27.2611 40.0055 27.7949 40.0055 28.1928V28.513H38.7343H37.4533L37.5018 28.0278C37.5988 27.0088 38.6178 26.4945 39.4233 27.0476Z" />
                <path d="M32.1449 27.9308C31.8732 28.2025 31.8829 28.8042 32.1546 29.0565C32.3196 29.2021 32.504 29.2409 33.0863 29.2409C33.7365 29.2409 33.8335 29.2118 33.9985 29.008C34.2508 28.6975 34.2411 28.2122 33.9597 27.9502C33.6394 27.6494 32.4361 27.6397 32.1449 27.9308Z" />
            </DefaultIcon>
        </>
    );
};
