import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            backgroundColor: '#FFF0!important'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: "none"
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(255 255 255 / 90%)'
        },

    },
    textLabel: {
        textAlign: 'center',
        margin: '0 0 24px 0',
        fontWeight: 600,
        background: 'rgb(0 0 0 / 50%)',
        padding: theme.spacing(2),
        color: '#FFF',
        borderRadius: 8,
    }
}));