import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { ProdutoListSearchProps } from "views/pages/private/cadastros/produto/components/produto-list/produto-list-search-props";
import { ProdutoPesquisaModal } from "views/pages/private/cadastros/produto/components/produto-pesquisa-modal/produto-pesquisa-modal";

export const ProdutoFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            codigo: '',
            descricao: '',
            status: -1,
            termo: '',
            tipo: -1,
            categoria: '',
        } as ProdutoListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ProdutoFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ProdutoFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <ProdutoPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
        />
    )
}