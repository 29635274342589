import { FormGerenciamentoSessaoValor } from "views/components/form/gerenciamento-sessoes-valor/form-gerenciamento-sessao-valor";
import { DefaultCard } from "../components";
import { useCallback, useEffect, useRef, useState } from "react";
import { SessaoValorPostModel } from "model/api/gestao/sessao/sessao-valor-post-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { CardAdicionarGerenciamentoSessaoValorProps } from "./card-adicionar-gerenciamento-sessao-valor-props";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./card-adicionar-gerenciamento-sessao-valor-styles";
import { CancelarIcon, SalvarIcon } from "views/components/icons";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { usePostSessaoValor } from "data/api/gestao/sessao/post-sessao-valor";
import { guidEmpty } from "utils/guid-empty";
import { toDateStringApi } from "utils/to-date";
import { TouchoneDBPrimary } from "database/touchone-database";
import { TabelaFinalizadoras } from "database/interfaces/interface-tabela-finalizadoras";

export const CardAdicionarGerenciamentoSessaoValor = ({
    model,
    setAddValor,
    getSessaoValorWrapper,
}: CardAdicionarGerenciamentoSessaoValorProps) => {

    //AUX
    const classes = useStyles();
    const { showToast } = useToastSaurus()

    //HOOKS
    const { getEmpresaSelecionada } = useSessaoAtual();

    //STATES E REFS
    const refEditForm = useRef<DefaultFormRefs<SessaoValorPostModel>>(null);
    const [finalizadora, setFinalizadora] = useState<TabelaFinalizadoras[]>([])

    //CHAMADAS DA API
    const { postSessaoValor, carregando } = usePostSessaoValor();

    const handleSubmit = useCallback(async (modelForm: SessaoValorPostModel) => {
        try {

            const payload = {
                ...modelForm,
                id: guidEmpty(),
                dLancamento: toDateStringApi(new Date()),
                responsavelId: model.operadorId,
                responsavelNome: model.operadorNome,
                operadorId: model.operadorId,
                operadorNome: model.operadorNome,
                portadorId: model.operadorId,
                portadorNome: model.operadorNome,
            } as SessaoValorPostModel;

            const res = await postSessaoValor(getEmpresaSelecionada()!.Id, model.caixaId, model.id, payload);

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'Correção adicionada com sucesso.')
            await getSessaoValorWrapper();
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, 
        [
            getEmpresaSelecionada, 
            getSessaoValorWrapper, 
            model.caixaId, 
            model.id, 
            model.operadorId, 
            model.operadorNome, 
            postSessaoValor, 
            showToast,
        ],
    )

    const pegarFinalizadoras = useCallback(async () => {
        try {
            const finalizadorasDB = await TouchoneDBPrimary.finalizadoras.toArray();
    
            setFinalizadora(finalizadorasDB);
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [showToast])

    useEffect(() => {
        pegarFinalizadoras()
    }, [pegarFinalizadoras])

    return (
        <DefaultCard
            className={classes.rootContainer}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        <Box fontWeight={600}>
                            Adicionar Correção
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGerenciamentoSessaoValor
                        ref={refEditForm}
                        onSubmit={handleSubmit}
                        showLoading={false}
                        loading={carregando}
                        editar={false}
                        finalizadoras={finalizadora}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => setAddValor(false)}
                    >
                        <CancelarIcon tipo="BUTTON" />
                        Cancelar
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => refEditForm.current?.submitForm()}
                    >
                        <SalvarIcon tipo="BUTTON_PRIMARY" />
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </DefaultCard>
    );
}