import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },
    middleContainer: {
        minHeight: '60px',
        display: 'flex',
        padding: theme.spacing(1),
        background: '#FFF',
    },
    middleContent: {
        flexWrap: 'nowrap',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    valorContainer: {
        '& .valorMonetario': {
            display: 'flex',
            color: '#696969',
            '& .unidade': {
                fontWeight: '600',
                marginRight: '1.5vw',
                [theme.breakpoints.up('sm')]: {
                    marginRight: '1vw',
                },
                [theme.breakpoints.up('md')]: {
                    marginRight: '0.7vw',
                },
            },
            '& .valor': {
                fontWeight: 'bold',
            },
        },
    },
}));
