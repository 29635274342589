import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const PrincipioAtivoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M16 3C14.9069 3 14 3.90694 14 5V9C14 10.0931 14.9069 11 16 11V12.2793L14.1035 12.9121C11.6569 13.7281 10 16.0249 10 18.6035V20V38V41C10 44.3016 12.6984 47 16 47H34C37.3016 47 40 44.3016 40 41V38V20V18.6035C40 16.0249 38.3435 13.727 35.8965 12.9121L34 12.2793V11C35.0931 11 36 10.0931 36 9V5C36 3.90694 35.0931 3 34 3H16ZM16 5H34V9H16V5ZM18 11H32V13C31.9999 13.2099 32.0659 13.4146 32.1885 13.585C32.3112 13.7553 32.4844 13.8828 32.6836 13.9492L35.2637 14.8105H35.2656C36.9026 15.3557 38 16.8781 38 18.6035V19H12V18.6035C12 16.8786 13.0979 15.3551 14.7344 14.8086L17.3164 13.9492C17.5156 13.8828 17.6888 13.7553 17.8115 13.585C17.9341 13.4146 18.0001 13.2099 18 13V11ZM12 21H38V37H12V21ZM26.5 23.6777C25.5182 23.6777 24.5364 24.0496 23.793 24.793L22.4102 26.1758C22.3212 26.2395 22.2434 26.3173 22.1797 26.4062L20.793 27.793C19.3062 29.2798 19.3062 31.7202 20.793 33.207C22.2798 34.6938 24.7202 34.6938 26.207 33.207L27.5898 31.8242C27.6788 31.7605 27.7566 31.6827 27.8203 31.5938L29.207 30.207C30.6938 28.7202 30.6938 26.2798 29.207 24.793C28.4636 24.0496 27.4818 23.6777 26.5 23.6777ZM26.5 25.6641C26.9657 25.6641 27.4314 25.8454 27.793 26.207C28.5162 26.9302 28.5162 28.0698 27.793 28.793L27 29.5859L24.4141 27L25.207 26.207C25.5686 25.8454 26.0343 25.6641 26.5 25.6641ZM23 28.4141L25.5859 31L24.793 31.793C24.0698 32.5162 22.9302 32.5162 22.207 31.793C21.4838 31.0698 21.4838 29.9302 22.207 29.207L23 28.4141ZM12 39H38V41C38 43.2204 36.2204 45 34 45H16C13.7796 45 12 43.2204 12 41V39Z" />
      </DefaultIcon>
    </>
  );
};
