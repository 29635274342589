import { Box, Button, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { OkIcon } from 'views/components/icons';
import { useCadastros, useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInformacaoAdicionalValidation } from './dialog-informacao-adicional-validations';
import { isEmpty } from 'lodash';
import { DialogInformacaoAdicionalProps } from './dialog-informacao-adicional-props';
import { useThemeQueries } from 'views/theme';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useStyles } from './dialog-informacao-adicional-styles';

class InfAdicionalForm {
  constructor(
    public infAdicional: string = ''
  ) { }
}

export const DialogInformacaoAdicional = ({
  ...props
}: DialogInformacaoAdicionalProps) => {

  const { theme } = useThemeQueries()
  const { fecharDialogInformacaoAdicional } = useCadastros()
  const { getMov, editarInfAdicional } = useMovAtual()
  const history = useHistory()
  const { showToast } = useToastSaurus()
  const classes = useStyles()

  const { formInformacaoAdicionalYupValidation } = useFormInformacaoAdicionalValidation()

  const {
    handleSubmit,
    control,
  } = useForm<InfAdicionalForm>({
    defaultValues: {
      infAdicional: getMov()?.infAdicional ?? ''
    },
    resolver: yupResolver(formInformacaoAdicionalYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });


  const submit = useCallback(async (value: InfAdicionalForm) => {
    if (!getMov()) return;
    await editarInfAdicional(value.infAdicional ?? '')
    const rota = '/venda-simples/carrinho'

    if (getMov()?.produtos.length === 0) {
      history.push('/venda-simples/catalogo')
    } else if (history.location.pathname !== rota) {
      history.push(rota)
    }
    if (value.infAdicional.length > 0) {
      showToast('success', 'Informação Adicional atualizada!')
    }
    fecharDialogInformacaoAdicional()
  }, [editarInfAdicional, fecharDialogInformacaoAdicional, getMov, history, showToast])

  return (
    <DialogSaurus
      aberto={props.aberto}
      titulo="Informação Adicional"
      tamanho="xs"
      isButtonTitleClose={props.permiteFechar}
      colorTitulo={theme.palette.primary.main}
      handleClickClose={fecharDialogInformacaoAdicional}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Typography>
          <Box mb={2}>
            Caso precise adicionar uma informação para maiores detalhes da entrega(opcional).
          </Box>
        </Typography>
        <div>
          <Controller
            name='infAdicional'
            control={control}
            render={({ field, fieldState }) => (
              <TextFieldSaurus
                {...field}
                placeholder='Exemplo: Cliente gosta de pepperoni'
                error={!isEmpty(fieldState.error)}
                multiline
                minRows={2}
                allowSubmit
                helperText={fieldState.error
                  ? fieldState.error.message
                  : undefined}
              />
            )}
          />
        </div>

        <div className={classes.containerButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className='round'
            type="submit"
          >
            <OkIcon tipo='BUTTON_PRIMARY' />
            Confirmar
          </Button>
        </div>
      </form>
    </DialogSaurus>
  )
};
