import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormProcessosNovaValidation = () => {
  const FormProcessosNovaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        descricao: Yup.string().required('A descrição do setor é obrigatória'),
        processoProducao: Yup.number().typeError('Você deve informar um número').required('A ordem do processo é obrigatório'),
        status: Yup.string().required("O tipo é obrigatório"),
      })
    )
  }, [])
  return {
    FormProcessosNovaValidationYup,
  }
}