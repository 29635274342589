import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import CardProdutoPedido from 'views/components/cards/card-produto-pedido/card-produto-pedido';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';

export interface ProdutosListDataProps {
  produtos: Array<PedidoProdutoModel>;
  carregando: boolean;
  inativarProduto: (produto: PedidoProdutoModel) => any;
  statusPedido: EnumStatusPedido;
}

export const ProdutosListData = (props: ProdutosListDataProps) => {
  const inativarProduto = (produto: PedidoProdutoModel) => {
    props.inativarProduto(produto);
  };

  const produtosAdicionais =
    props.produtos.filter((produto) => (produto.tpProduto.codigo === EnumTpProduto.Adicional || produto.tpProduto.codigo === EnumTpProduto.Insumo || produto.tpProduto.codigo === EnumTpProduto.Opcional)) ?? [];

  const agruparProdutosSubItens = () => {
    const produtosAgrupados: PedidoProdutoModel[] =
      props.produtos.filter((p) => !p.produtoPai  && (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem || p.tpProduto.codigo === EnumTpProduto.Produto || p.tpProduto.codigo === EnumTpProduto.Combo || p.tpProduto.codigo === EnumTpProduto.Medicamento));

    return produtosAgrupados.map((prodAgrupado) => {
      const prodAdicional = produtosAdicionais.filter(
        (adicional) => adicional.groupId === prodAgrupado.codigoReferencia && adicional.tpProduto.codigo !== EnumTpProduto.Combo && adicional.tpProduto.codigo !== EnumTpProduto.ProdutoComSubItem && adicional.tpProduto.codigo !== EnumTpProduto.Produto && adicional.tpProduto.codigo !== EnumTpProduto.Medicamento
      );

      if (prodAdicional.length > 0) {
        return {
          ...prodAgrupado,
          adicionais: prodAdicional
        };
      }

      return {
        ...prodAgrupado,
        adicionais: []
      };
    });
  };

  const products = agruparProdutosSubItens();

  return (
    <>
      {props.produtos.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhum produto encontrado."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      {products.length > 0 &&
        products.map((produto) => {
          return (
            <CardProdutoPedido
              model={produto}
              key={produto.id}
              inativarProduto={inativarProduto}
              statusPedido={props.statusPedido}
            />
          );
        })}
    </>
  );
};
