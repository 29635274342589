import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { NovoPagamentoList } from './components/novo-pagamento-list/novo-pagamento-list';
import { HeaderPayment } from '../mov-carrinho/components/fast-payment/components/HeaderPayment/header-payment';
import { ReticenciasIcon } from 'views/components/icons/reticencias-icon';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';

export const MovPagamentoNovoPage = () => {
  const classes = useDefaultCadastroStyles();
  const { retornaFluxoVenda } = useMovAtual();
  const history = useHistory();
  const { showToast } = useToastSaurus();
  const { isPedidoDelivery } = usePedidoDelivery();
  const { goToAdministrativo } = useOpenMovRota();
  const { abrirDialogDefinicoes } = useCadastros();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={goToAdministrativo}
      ></ButtonPrivateHeader>
    );
  }, [goToAdministrativo]);

  const rightArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<ReticenciasIcon tipo="PRIVATE_HEADER" />}
        tooltip="Definições da Venda"
        onClick={abrirDialogDefinicoes}
      ></ButtonPrivateHeader>
    );
  }, [abrirDialogDefinicoes]);

  const verifyPayment = useCallback(async () => {
    try {
      const ret = await retornaFluxoVenda();
      if (ret.url.indexOf('pagamento') === -1) {
        if (ret.error) {
          showToast('error', ret.msg);
        }
        history.push(ret.url);
      }
    } catch (e: any) {
      history.push('/venda-simples/carrinho');
      showToast('error', e.message);
    }
  }, [history, retornaFluxoVenda, showToast]);

  useEffect(() => {
    (async () => {
      // if (!isPedidoDelivery()) {
      await verifyPayment();
      // }
    })();
  }, [isPedidoDelivery, verifyPayment]);

  const header = useMemo(() => {
    return (
      <PrivatePageHeader
        title="Forma de Pagamento"
        leftArea={leftArea()}
        rightArea={rightArea()}
      />
    );
  }, [leftArea, rightArea]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>{header}</Grid>
      <HeaderPayment removerMargem={true} fontSize={14}/>
      <Grid className={classes.list}>
        <NovoPagamentoList />
      </Grid>
    </Grid>
  );
};
