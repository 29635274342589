import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { useFormPagerIdentificadorValidation } from './form-pager-identificacao-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
// import SemImagem from 'assets/img/sem-imagem.jpg';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../utils';
import { useStyles } from './form-pager-identificador-styles';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { useToastSaurus } from 'services/app';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';

interface FormPagerIdentificadorProps extends DefaultFormProps<PedidoDadosModelPost> {
    handleSubmit: (model: PedidoDadosModelPost) => Promise<any>;
    semIdentificacao: boolean
}
export const FormPagerIdentificador = forwardRef<
    DefaultFormRefs<PedidoDadosModelPost>,
    FormPagerIdentificadorProps
>((props: FormPagerIdentificadorProps, ref) => {
    const {
        getSaloesCadastrados,
    } = usePedidoLocal();
    const { showToast } = useToastSaurus()
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const [saloes, setSaloes] = useState<SaloesModel[]>([]);
    const carregando = props.loading
    const { FormPagerIdentificadorValidationYup } = useFormPagerIdentificadorValidation(props.semIdentificacao);
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
        getFieldState,
    } = useForm<PedidoDadosModelPost>({
        defaultValues: { ...props.model },
        resolver: yupResolver(FormPagerIdentificadorValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });
    const buscarSaloes = useCallback(async () => {
        try {
            const res = await getSaloesCadastrados();
            const ret = res.filter(x => x.status.codigo === EnumStatusSalao.ATIVO)
            setSaloes(ret);
            return ret

        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getSaloesCadastrados, showToast]);

    const onSubmit = (values: PedidoDadosModelPost) => {
        props.handleSubmit(values);
    };
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: async (model: PedidoDadosModelPost) => {
            const ret = await buscarSaloes();
            if(ret && ret.length > 0){
                model.salaoId = ret[0].id || '';
            }
            reset({...model})
        },
    }));
    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={carregando ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2} justifyContent='center' className={classes.container}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                            <Typography color='primary' variant='body1'>Informe abaixo a identificação do cliente para chamar quando o pedido estiver pronto.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>
                        {saloes.length > 1 && saloes.filter(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA).length > 0 ?
                            <Grid item xs={12} >
                                <Controller
                                    name="salaoId"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            label="Selecione o Salão"
                                            conteudo={saloes.map((valor: any) => {
                                                return new KeyValueModel(valor.id.toString(), valor.descricao);
                                            })}

                                            fullWidth={true}
                                            variant="outlined"
                                            {...field}
                                            onChange={(event) => {
                                                let salaoSelecionado = event.target.value
                                                setValue('salaoId', salaoSelecionado)
                                            }}
                                        />
                                    )}

                                />
                            </Grid>
                            :
                            null}
                        <Grid item xs={12} >
                            <Controller
                                name="identificador"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={carregando}
                                        fullWidth
                                        variant="outlined"
                                        label="Identificador do Pedido"
                                        placeholder='Insira o nome do cliente ou o número de identificação do pedido'
                                        error={Boolean(
                                            getFieldState("identificador").error,
                                        )}
                                        helperText={
                                            errors.identificador
                                                ? errors.identificador?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>


                    </Grid >
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form >
            </div >
        </>
    )
});