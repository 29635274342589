import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& p': {
            fontSize: 13,
            fontWeight: 500
        },
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    btn: {
        backgroundColor: '#FFF',
        height: '100%',
        textAlign: 'start',
        alignItems: 'start',
        '&:hover': {
            backgroundColor: '#f3f3f3'
        },
        '& .MuiTypography-body1': {
            fontSize: 13,
            fontWeight: 500,
        }
    },
    btnCenter: {
        alignItems: 'center'
    },
    colorSecondary: {
        fill: theme.palette.secondary.main
    },
    mBottom: {
        marginBottom: theme.spacing(1),
    },
    lineInfo:{
        display: 'flex', 
        marginBottom: '8px'
    },
    infoContent:{
        display: 'flex', 
        flexDirection: 'column'
    },
    cardComp:{
        minHeight:150
    },
    lineHeight:{
        lineHeight:'25px'
    }
}))