import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { FaturasVisualizar } from "./components/faturas-visualizar";

export const FaturasModal = (props: ModalProps) => {

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={"temporary"}
            anchor="right"
        >
            {props.openned && <FaturasVisualizar id={props.id} />}
        </DefaultModal >
    );
};
