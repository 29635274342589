import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";
export const ChaveIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M34 0C25.1797 0 18 7.17578 18 16C18 17.9609 18.3828 19.8242 19.0312 21.5625L0.28125 40.2812L0 40.5938V47.4062L0.28125 47.7188L2.28125 49.7188L2.59375 50H9.40625L9.71875 49.7188L12.7188 46.7188L13 46.4062V44H15.4062L15.7188 43.7188L18.7188 40.7188L19 40.4062V39H20.4062L20.7188 38.7188L22.7188 36.7188L23 36.4062V35H24.4062L24.7188 34.7188L28.4375 30.9688C30.1758 31.6172 32.0391 32 34 32C42.8203 32 50 24.8203 50 16C50 7.17578 42.8203 0 34 0ZM34 2C41.7383 2 48 8.25781 48 16C48 23.7383 41.7383 30 34 30C32.0781 30 30.2578 29.6367 28.5938 28.9375C28.582 28.9258 28.5742 28.918 28.5625 28.9062C23.5352 26.7812 20 21.8047 20 16C20 8.25781 26.2617 2 34 2ZM34 5C31.1836 5 28.3633 6.07422 26.2188 8.21875L25.5 8.9375L26.2188 9.625L40.375 23.7812L41.0625 24.5L41.7812 23.7812C46.0703 19.4961 46.0703 12.5039 41.7812 8.21875C39.6367 6.07422 36.8164 5 34 5ZM34 7C36.3008 7 38.6133 7.86328 40.375 9.625C43.6484 12.8984 43.75 17.9961 40.9375 21.5312L28.4688 9.0625C30.1016 7.76562 32.0234 7 34 7ZM19.875 23.5312C21.3711 26.3281 23.6719 28.6289 26.4688 30.125L23.5625 33H21V35.5625L19.5625 37H17V39.5625L14.5625 42H11V45.5625L8.5625 48H3.4375L2.4375 47L19 30.4375C19.3594 30.1289 19.457 29.6133 19.2305 29.1992C19.0039 28.7812 18.5156 28.582 18.0625 28.7188C17.8711 28.7617 17.6992 28.8594 17.5625 29L2 44.5938V41.4375L19.875 23.5312Z" />
            </DefaultIcon>
        </>
    );
};
