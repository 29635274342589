import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetUsuarioPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getUsuarioPessoa = useCallback(
    (usuarioId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/pessoa/usuario/${usuarioId}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getUsuarioPessoa,
  };
}
