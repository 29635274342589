import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import CardPrincipioAtivo from '../card-principio-ativo/card-principio-ativo';

export interface PrincipioAtivoGridProps {
  list: Array<string>;
  carregando: boolean;
  onCardClicked: (principio: string) => void;
}

export const PesquisarPrincipioAtivoListData = (props: PrincipioAtivoGridProps) => {
  const onCardChecked = (principio: string) => {
    props.onCardClicked(principio);
  };

  return (
    <>
      {props.list.length > 0 && props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem={props.carregando ? `Procurando princípio ativo` : "Nenhum Princípio Ativo encontrado."}
          icon={props.carregando
            ? <div
              style={{
                marginRight: 30,
                marginTop: 0,
                position: 'relative',
              }}
            > <CircularLoading tipo="ONAUTOCOMPLETE" />
            </div>
            : <InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((principioAtivo) => {
          return (
            <CardPrincipioAtivo onClick={onCardChecked} model={principioAtivo} key={principioAtivo} />
          );
        })}
    </>
  );
};
