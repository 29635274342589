import classNames from 'classnames';
import { DefaultIconInternalProps } from './default-icon-internal-props';
import { useDefaultIconStyles } from './default-icon-styles';

export const DefaultIcon = (props: DefaultIconInternalProps, ref: any) => {
  const classes = useDefaultIconStyles();

  const myclass =
    props.tipo === 'BUTTON'
      ? classes.defaultIconOnButton
      : props.tipo === 'BUTTON_FAB'
      ? classes.defaultIconOnButtonFab
      : props.tipo === 'BUTTON_PRIMARY'
      ? classes.defaultIconOnButtonPrimary
      : props.tipo === 'INPUT'
      ? classes.defaultIconOnInput
      : props.tipo === 'PRIVATE_HEADER'
      ? classes.defaultIconOnPrivateHeader
      : props.tipo === 'MODAL_HEADER'
      ? classes.defaultIconOnModalHeader
      : props.tipo === 'BUTTON_XL'
      ? classes.defaultIconOnPrivateHeader
      
      : '';

  return (
    <>
      <svg
        className={classNames(myclass, props.class)}
        onClick={props.onClick}
        style={{
          fill: props.fill,
          ...props.style
        }}
        viewBox={props.viewBox ? props.viewBox : '0 0 50 50'}
      >
        {props.children}
      </svg>
    </>
  );
};
