import { KeyValueModel } from "model/api";

export const ModBcIcmsStMock: KeyValueModel[] = [
    new KeyValueModel(0, "Preço tabelado ou máximo sugerido"),
    // new KeyValueModel(1, "Lista Negativa (valor)"),
    // new KeyValueModel(2, "Lista Positiva (valor)"),
    // new KeyValueModel(3, "Lista Neutra (valor)"),
    new KeyValueModel(4, "Margem Valor Agregado (%)"),
    // new KeyValueModel(5, "Pauta (valor)"),
];
