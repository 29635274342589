import { Box, Divider, Typography } from "@material-ui/core"
import { useStyles } from "./carrinho-dados-endereco-styles"
import { CarrinhoDadosEnderecoProps } from "./carrinho-dados-endereco-props"
import { toDecimalString } from "utils/to-decimal"

export const CarrinhoDadosEndereco = ({ pedido, ...props }: CarrinhoDadosEnderecoProps) => {

    const classes = useStyles()

    return (
        <Box minHeight="70px" className={classes.container}>
            <Divider className={classes.divider} />
            <Box pt={1}>
                <Box pb={.5} width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="body2" className={classes.negrito}>
                        Dados de Entrega
                    </Typography>
                </Box>
                <Box pb={.5} width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="body2">
                        <span className={classes.negrito}>Informação Adicional:</span> {pedido.informacaoAdicional || "-"}
                    </Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="body2">
                        <span className={classes.negrito}>Pagamento{pedido.pagamentos.length > 1 ? 's' : ''}:</span> {pedido.pagamentos.map((pag, i, pags) => {
                            if (pags.length === 1) {
                                return `${pag.pagamentoDescricao}`
                            }
                            return `${pag.pagamentoDescricao} R$ ${toDecimalString(pag.valorPago)}${pags.length - 1 !== i ? ' - ' : ''}`
                        })}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}