import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1)
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontWeight: 500
        },
        [theme.breakpoints.down('xs')]: {
            '& p, span': {
                fontSize: '13px'
            },
        }
    },
    iconRight: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex !important',
            border: 'none'
        },
    }
}));