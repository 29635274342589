import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { Grid } from '@material-ui/core';

import { DashboardAdminHeader } from './components/dashboard-admin-header/dashboard-admin-header';
import { DashboardAdminContent } from './components/dashboard-admin-content/dashboard-admin-content';
import { memo, useEffect, useState } from "react";


const DashboardAdminPage = () => {

  const classes = useDefaultCadastroStyles();
  const [titulo, setTitulo] = useState('')
  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [remove, setRemove] = useState<boolean>(false)

  useEffect(() => {
    if (remove) setRemove(false)
  }, [remove])

  const removeSearch = () => {
    setRemove(true)
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <DashboardAdminHeader onClickRemove={removeSearch} openPesquisa={openPesquisa} setOpenPesquisa={setOpenPesquisa} setPesquisa={setSearch} titulo={titulo} />
      </Grid>
      <Grid className={classes.list}>
        <DashboardAdminContent removeTermo={remove} pesquisa={search} titulo={setTitulo} />
      </Grid>
    </Grid>

  );
};

export default memo(DashboardAdminPage);
