import { LocalizacaoModel } from 'model/api/gestao/localizacao/localizacao-model';
import { useCallback } from 'react';
import { ConsultaCNPJModel } from '../../../model/api/wsmaster';
import { EnumRegime } from '../../../model/enums';
import { useWsMaster } from './base/wsmaster';


export function useConsultaCnpj() {
    const { invocarApi, ...outros } = useWsMaster({
        operacao: 'retConsultaDocumento',
        config: {
            timeout: 10000
        }
    });

    const consultarCNPJ = useCallback(
        async (cnpj: string, localizacao?: LocalizacaoModel): Promise<ConsultaCNPJModel> => {
            const xml = (senha: string) => `
            <?xml version="1.0" encoding="utf-8"?>
            <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
                <soap12:Body>
                    <retConsultaDocumento xmlns="http://saurus.net.br/">
                        <xXMLParametros><![CDATA[<xmlIntegracao><Documento>${cnpj.replace(/\D+/g, '')}</Documento>
                        ${localizacao ? `<Latitude>${localizacao?.latitude || ''}</Latitude><Longitude>${localizacao?.longitude || ''}</Longitude>` : ''}
                        </xmlIntegracao>]]></xXMLParametros>
                        <xSenha>${senha}</xSenha>
                    </retConsultaDocumento>
                </soap12:Body>
            </soap12:Envelope>
            `;

            const result = await invocarApi({ xml, });
            let cep = result?.retConsultaDocumento.CadastroLocalizado._CEP;
            if (cep.length === 8) {
                cep = cep.substr(0, 5) + '-' + cep.substr(5, 3);
            }

            return new ConsultaCNPJModel(
                cnpj,
                result?.retConsultaDocumento.CadastroLocalizado._IE,
                result?.retConsultaDocumento.CadastroLocalizado._xNome,
                result?.retConsultaDocumento.CadastroLocalizado._xNome,
                result?.retConsultaDocumento.CadastroLocalizado._CNAE,
                result?.retConsultaDocumento.CadastroLocalizado._RegApuracao === '' ?
                    EnumRegime.NAOSEAPLICA :
                    result?.retConsultaDocumento.CadastroLocalizado._RegApuracao !== "SIMPLES NACIONAL" ? EnumRegime.LUCRO_REAL :
                        EnumRegime.SIMPLES_NACIONAL,
                cep,
                result?.retConsultaDocumento.CadastroLocalizado._xLgr,
                result?.retConsultaDocumento.CadastroLocalizado._nro,
                result?.retConsultaDocumento.CadastroLocalizado._xCpl,
                result?.retConsultaDocumento.CadastroLocalizado._xBairro,
                result?.retConsultaDocumento.CadastroLocalizado._xMun,
                result?.retConsultaDocumento.CadastroLocalizado._cMun,
                result?.retConsultaDocumento.CadastroLocalizado._UF,
                result?.retConsultaDocumento.CadastroLocalizado._indCredNFe
            );
        },
        [invocarApi],
    );

    return {
        ...outros,
        consultarCNPJ,
    };
}
