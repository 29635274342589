import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minWidth: '130px',
    minHeight: "140px",
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",

  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  }
}));

