import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const BaixarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 24.90625 2.96875 C 24.863281 2.976563 24.820313 2.988281 24.78125 3 C 24.316406 3.105469 23.988281 3.523438 24 4 L 24 32.5625 L 17.71875 26.28125 C 17.574219 26.136719 17.390625 26.035156 17.1875 26 C 17.054688 25.972656 16.914063 25.972656 16.78125 26 C 16.40625 26.066406 16.105469 26.339844 16 26.703125 C 15.894531 27.070313 16.003906 27.460938 16.28125 27.71875 L 24.15625 35.5625 C 24.230469 35.675781 24.324219 35.769531 24.4375 35.84375 L 25 36.40625 L 25.5625 35.84375 C 25.59375 35.824219 25.625 35.804688 25.65625 35.78125 L 25.71875 35.71875 C 25.71875 35.707031 25.71875 35.699219 25.71875 35.6875 C 25.742188 35.667969 25.761719 35.648438 25.78125 35.625 L 33.71875 27.71875 C 34.117188 27.320313 34.117188 26.679688 33.71875 26.28125 C 33.320313 25.882813 32.679688 25.882813 32.28125 26.28125 L 26 32.5625 L 26 4 C 26 3.96875 26 3.9375 26 3.90625 C 26 3.875 26 3.84375 26 3.8125 C 25.988281 3.738281 25.964844 3.664063 25.9375 3.59375 C 25.917969 3.570313 25.898438 3.550781 25.875 3.53125 C 25.851563 3.464844 25.820313 3.402344 25.78125 3.34375 C 25.761719 3.320313 25.742188 3.300781 25.71875 3.28125 C 25.6875 3.257813 25.65625 3.238281 25.625 3.21875 C 25.425781 3.042969 25.167969 2.953125 24.90625 2.96875 Z M 2 36 L 2 42 C 2 44.746094 4.253906 47 7 47 L 43 47 C 45.746094 47 48 44.746094 48 42 L 48 36 L 46 36 L 46 42 C 46 43.65625 44.65625 45 43 45 L 7 45 C 5.34375 45 4 43.65625 4 42 L 4 36 Z" />
      </DefaultIcon>
    </>
  );
};
