import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { useStyles } from './acres-desc-styles';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useThemeQueries } from 'views/theme';
import { OkIcon } from 'views/components/icons';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpAcresDescMock } from 'data/mocks/tp-acresc-desc-mock';
import { EnumTpDescontoAcrescimo } from 'model/enums/enum-tp-desconto-acrescimo';
import { AdicionarDescontoAcrescimoFormModel } from 'model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { EnumTpPermissaoUsuario } from 'model/enums/enum-tp-permissao-usuario';

interface DialogAcrescDesc {
  tipo: 'acresc' | 'desc';
}

export const DialogDescontoAcrescimo = ({ tipo }: DialogAcrescDesc) => {
  // STATES E REFS
  const valorInicial = useRef<string>('');
  const digitado = useRef<boolean>(false);
  const textoAtual = useRef<string>('');
  const valueRef = useRef<HTMLParagraphElement | null>(null);
  const [type, setType] = useState<EnumTpDescontoAcrescimo>(EnumTpDescontoAcrescimo.Porcentagem);
  const { fecharVendaAcrescDesc, abrirSolicitarPermissao, fecharDialogDefinicoes } = useCadastros();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const { getPermissaoBoolean, getPermissaoDesconto } = useSessaoAtual();
  const { adicionarDescontoVenda, adicionarAcrescimoVenda, getMov, getQtdPessoasPagamento } =
    useMovAtual();

  const classes = useModalStyles();
  const classesAcrescDesc = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      valorInicial.current = String(toDecimal(formattedText, 2));
      return true;
    },
    []
  );

  const onCloseClick = useCallback(() => {
    fecharVendaAcrescDesc();
  }, [fecharVendaAcrescDesc]);

  const handleSubmmit = useCallback(async () => {
    const valor = toDecimal(valorInicial.current);

    const limitePorcentagem = 100;

    if (tipo === 'acresc') {
      // caso não tenha permissão
      if (!getPermissaoBoolean(EnumCodigosPermissoes.ACRESCIMO_VENDA)) {
        abrirSolicitarPermissao(
          async () => {
            adicionarAcrescimoVenda(
              new AdicionarDescontoAcrescimoFormModel(
                type === EnumTpDescontoAcrescimo.Porcentagem ? valor : null,
                type === EnumTpDescontoAcrescimo.ValorFixo ? valor : null,
                type === EnumTpDescontoAcrescimo.Porcentagem
                  ? EnumTpDescontoAcrescimo.Porcentagem
                  : EnumTpDescontoAcrescimo.ValorFixo
              ),
              fecharVendaAcrescDesc
            );
          },
          EnumCodigosPermissoes.ACRESCIMO_VENDA,
          'aplicar acréscimo na venda total'
        );
        return;
      }

      // caso tenha permissão segue o fluxo normalmente
      adicionarAcrescimoVenda(
        new AdicionarDescontoAcrescimoFormModel(
          type === EnumTpDescontoAcrescimo.Porcentagem ? valor : null,
          type === EnumTpDescontoAcrescimo.ValorFixo ? valor : null,
          type === EnumTpDescontoAcrescimo.Porcentagem
            ? EnumTpDescontoAcrescimo.Porcentagem
            : EnumTpDescontoAcrescimo.ValorFixo
        ),
        fecharVendaAcrescDesc
      );
      return;
    } else if (tipo === 'desc') {
      if (
        type === EnumTpDescontoAcrescimo.Porcentagem &&
        (type === EnumTpDescontoAcrescimo.Porcentagem ? valor : null ?? 0) >=
        limitePorcentagem
      ) {
        showToast(
          'error',
          'O valor da porcentagem não pode ser maior ou igual que 100%.'
        );
        return;
      }

      if (
        type === EnumTpDescontoAcrescimo.ValorFixo &&
        (type === EnumTpDescontoAcrescimo.ValorFixo ? valor : null ?? 0) >=
        (getMov()?.vNF ?? 0)
      ) {
        showToast(
          'error',
          'O valor não pode ser maior ou igual ao valor total da venda.'
        );
        return;
      }

      //caso não tenha permissão
      if (!getPermissaoDesconto(EnumCodigosPermissoes.DESCONTO_VENDA, valor!, type, getMov()?.vnfAux ?? 0)) {
        abrirSolicitarPermissao(
          async () => {
            adicionarDescontoVenda(
              new AdicionarDescontoAcrescimoFormModel(
                type === EnumTpDescontoAcrescimo.Porcentagem ? valor : null,
                type === EnumTpDescontoAcrescimo.ValorFixo ? valor : null,
                type === EnumTpDescontoAcrescimo.Porcentagem
                  ? EnumTpDescontoAcrescimo.Porcentagem
                  : EnumTpDescontoAcrescimo.ValorFixo
              ),
              fecharVendaAcrescDesc
            );
          },
          EnumCodigosPermissoes.DESCONTO_VENDA,
          'desconto na venda total',
          EnumTpPermissaoUsuario.Numero,
          valor
        );
        return;
      }

      //caso tenha permissão segue o fluxo normalmente
      adicionarDescontoVenda(
        new AdicionarDescontoAcrescimoFormModel(
          type === EnumTpDescontoAcrescimo.Porcentagem ? valor : null,
          type === EnumTpDescontoAcrescimo.ValorFixo ? valor : null,
          type === EnumTpDescontoAcrescimo.Porcentagem
            ? EnumTpDescontoAcrescimo.Porcentagem
            : EnumTpDescontoAcrescimo.ValorFixo
        ),
        fecharVendaAcrescDesc
      );
      return;
    }
  }, [
    type,
    tipo,
    getPermissaoBoolean,
    adicionarAcrescimoVenda,
    fecharVendaAcrescDesc,
    abrirSolicitarPermissao,
    getMov,
    getPermissaoDesconto,
    adicionarDescontoVenda,
    showToast
  ]);

  const submit = useCallback(async () => {
    if (type === undefined) {
      showToast('error', 'Selecione o tipo da operação.');
      return false;
    }
    await handleSubmmit();
    setTimeout(() => {
      callEvent(AppEventEnum.MovAtualAlterada, getMov())
      callEvent(AppEventEnum.AlterarDisplayKeybordPayment, '0');
      callEvent(AppEventEnum.AtualizarQuantidadeDePessoas, getQtdPessoasPagamento().pessoas);
    }, 800)
    fecharDialogDefinicoes();
    return true;
  }, [callEvent, fecharDialogDefinicoes, getMov, getQtdPessoasPagamento, handleSubmmit, showToast, type]);

  const {
    getFormattedText,
    addValue,
    sendText,
    addKey,
    submitKey,
    backSpaceKey
  } = useKeyboard({
    handleTextChanged: textChanged,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual,
    maxLength: 7,
    floatCases: 2,
    isNumeric: true
  });

  const attValueDisplay = useCallback(
    (any: any) => {
      const v = getFormattedText();
      if (valueRef.current) {
        valueRef.current.className = classesAcrescDesc.inputText;
        valueRef.current.textContent = v;
      }
    },
    [classesAcrescDesc.inputText, getFormattedText]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay);

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay);
  }, [addHandler, attValueDisplay, removeHandler]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue]
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText]
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    submitKey();
  }, [submitKey]);

  const teclado = useMemo(() => {
    return (
      <>
        <Grid container style={{ flex: 1 }}>
          <Keyboard
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric
            isButtonBackspace
            isButtonKeyboard00
          />
        </Grid>
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const buttonsAction = useMemo(() => {
    return (
      <Grid
        container
        direction="column"
        style={{ flexWrap: 'nowrap', paddingBottom: theme.spacing(1) }}
      >
        <Grid
          item
          xs={12}
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
          }}
        >
          <Grid
            container
            xs={12}
            style={{
              marginTop: theme.spacing(1)
            }}
          >
            <Grid container xs={12}>
              <Button
                fullWidth
                size="large"
                type="button"
                variant="contained"
                color="primary"
                onClick={submit}
                style={{ marginLeft: theme.spacing(1) }}
                className="round"
              >
                <OkIcon tipo="BUTTON_PRIMARY"></OkIcon>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [submit, theme]);

  const renderKeyboard = () => {
    return (
      <>
        <Grid container style={{ flexDirection: 'row' }}>
          <Grid item xs={3} style={{ padding: 8 }}>
            <Typography
              className={classesAcrescDesc.placeholder}
              ref={valueRef}
            >
              <SelectSaurus
                conteudo={TpAcresDescMock}
                value={type}
                onChange={(e) => {
                  setType(Number(e.target.value) as EnumTpDescontoAcrescimo);
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={9} className={classesAcrescDesc.inputArea}>
            <Typography className={classesAcrescDesc.inputText} ref={valueRef}>
              0,00
            </Typography>
          </Grid>
        </Grid>
        {teclado}
        {buttonsAction}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <ModalHeader
        title={tipo === 'acresc' ? 'Acréscimo' : 'Desconto'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: '0px 4px 4px 0px #00000033',
          padding: 8,
          marginBottom: 8
        }}
      >
        <Grid
          container
          style={{ alignItems: 'flex-end', flexDirection: 'column' }}
        >
          <Typography style={{ fontSize: 8, fontWeight: 600 }}>
            Valor Total
          </Typography>
          <Typography style={{ fontWeight: 600, fontSize: 24 }}>
            R$ {toDecimalString(getMov()?.vNF ?? 0, 2)}
          </Typography>
        </Grid>
        {/* <Grid
          container
          style={{ alignItems: 'flex-end', flexDirection: 'column' }}
        >
          <Typography style={{ fontSize: 8, fontWeight: 600 }}>
            Valor total com {tipo === 'acresc' ? 'acréscimo' : 'desconto'}
          </Typography>
          <Typography style={{ fontWeight: 600, fontSize: 24 }}>
            R$ {toDecimalString(getMov()?.nnf ?? 0, 2)}
          </Typography>
        </Grid> */}
      </div>

      <Grid className={classesAcrescDesc.list}>{renderKeyboard()}</Grid>
    </div>
  );
};
