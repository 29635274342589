
import { EnumPagTpTransacao } from "model/enums";
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";
import { guidEmpty } from "utils/guid-empty";

export class PagamentoSessaoModel {
    constructor(
        public pagamentoId: string = guidEmpty(),
        public descricao: string = '',
        public tpMod: number = 0,
        public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL
    ) { }
}

export class ResponsavelSessaoModel {
    constructor(
        public responsavelId: string = guidEmpty(),
        public nome: string = '',
        public documento: string = '',
    ) { }
}

export class PortadorSessaoModel {
    constructor(
        public portadorId: string = guidEmpty(),
        public nome: string = '',
        public documento: string = '',
    ) { }
}

export class operadorSessaoModel {
    constructor(
        public operadorId: string = guidEmpty(),
        public nome: string = '',
        public documento: string = '',
    ) { }
}

export class SessaoValorModel {
    constructor(
        public id: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public sessaoId: string = '',
        public pagamento: PagamentoSessaoModel = new PagamentoSessaoModel(),
        public valor: number = 0,
        public responsavel: ResponsavelSessaoModel = new ResponsavelSessaoModel(),
        public tpLancamento: number,
        public observacao: string = '',
        public operador: operadorSessaoModel = new operadorSessaoModel(),
        public caixaId: string = guidEmpty(),
        public dataInsercao: string = '',
        public portador: PortadorSessaoModel = new PortadorSessaoModel(),
        public dataSincronizacao?: string,
        public idIndexed?: number,
    ) { }
}

export class SessaoValorFormModel {
    constructor(
        public pagamentoId: string = guidEmpty(),
        public descricao: string = '',
        public tpMod: number = 0,
        public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL,
        public portadorId: string = guidEmpty(),
        public nome: string = '',
        public documento: string = '',
        public observacao: string = '',
        public valorMovimentacao: string = ''
    ) { }
}

export class SessaoValueFechamento {
    constructor(
        public valor: string = '',
        public tpLancamento: EnumTpLancamentoSessaoValor = EnumTpLancamentoSessaoValor.FECHAMENTO,
        public pagamento: PagamentoSessaoModel = new PagamentoSessaoModel(),
        public observacao: string = 'Fechamento',
        public responsavel: ResponsavelSessaoModel | undefined = undefined,
    ) { }
}

export class SessaoSincronizar {
    constructor(
        public id: string = '',
        public sessaoId: string = '',
        public pagamentoId: string = '',
        public valor: number = 0,
        public tpLancamento: EnumTpLancamentoSessaoValor = EnumTpLancamentoSessaoValor.SUPRIMENTO,
        public dLancamento: string | Date = new Date().toISOString(),
        public observacao: string = 'Fechamento',
        public operadorId: string = '',
        public operadorNome: string = '',
        public responsavelId: string = '',
        public responsavelNome: string = '',
        public portadorId: string = '',
        public portadorNome: string = '',
        public caixaId: string = '',
    ) { }
}

export class SessaoFechamentoPayload {
    constructor(
        public responsavelId: string = '',
        public responsavelNome: string = '',
        public dataAcao: string | Date = '',
        public valores: Array<{ pagamentoId: string, valor: number }> = [],
    ) { }
}

export class SessaoAberturaPayload {
    constructor(
        public responsavelId: string = '',
        public responsavelNome: string = '',
        public dataAcao: string | Date = '',
        public valor: number = 0,
        public operadorId: string = '',

    ) { }
}

export class SessaoFechamento {
    constructor(
        public sessaoId: string = '',
        public payload: SessaoFechamentoPayload = new SessaoFechamentoPayload()
    ) { }
}

export class SessaoAberturaModel {
    constructor(
        public empresaId: string = '',
        public caixaId: string = '',
        public sessaoId: string = '',
        public payload: SessaoFechamentoPayload = new SessaoFechamentoPayload()
    ) { }
}