import { Box, Button, Grid } from '@material-ui/core';
import { CircularLoading, makeUtilClasses } from 'views';
import React, { useState } from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps
} from '../../utils/form-default-props';
import { RecuperarSenhaRetornoFormModel } from 'model/app';
import { useStyles } from './form-recuperar-senha-retorno-styles';
import { useFormRecuperarSenhaRetornoValidations } from './form-recuperar-senha-retorno-validation';
import { InputPin, TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormRecuperarSenhaRetorno = React.forwardRef<
  DefaultFormRefs<RecuperarSenhaRetornoFormModel>,
  DefaultFormProps<RecuperarSenhaRetornoFormModel>
>((props: DefaultFormProps<RecuperarSenhaRetornoFormModel>, refs) => {
  const { FormRecuperarSenhaRetornoYupValidations } =
    useFormRecuperarSenhaRetornoValidations();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState<{modeloForm: { email?: null | string, telefone?: null | string, codigo: string }}>({
    modeloForm: { email: null, telefone: null, codigo: '' }
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue
  } = useForm<RecuperarSenhaRetornoFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormRecuperarSenhaRetornoYupValidations),
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (form: RecuperarSenhaRetornoFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: { email: null, telefone: null, codigo: '' } });
      reset();
    },
    fillForm: (modell: RecuperarSenhaRetornoFormModel) => {
      setModel({ modeloForm: modell });
      reset((model.modeloForm = modell));
    }
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container spacing={3} style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="EMAIL"
                    disabled={true}
                    fullWidth={true}
                    showStartAdornment={false}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="E-mail para Recuperação"
                    placeholder="usuario@empresa.com.br"
                    error={Boolean(errors.email && errors.email.message)}
                    helperText={
                      touchedFields.email || errors.email
                        ? errors.email?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={3}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field: { name } }) => (
                    <InputPin
                      canFocus={true}
                      lenght={6}
                      disabled={props.loading}
                      fullWidth={true}
                      error={Boolean(errors.codigo && errors.codigo.message)}
                      helperText={
                        touchedFields.codigo || errors.codigo
                          ? errors.codigo?.message
                          : undefined
                      }
                      name="codigo"
                      onSubmit={handleSubmit}
                      onChange={setValue}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
