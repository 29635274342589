import { Grid } from '@material-ui/core';
import { SelecaoVendedorHeader } from './components/selecionar-vendedor-produto-header/selecionar-vendedor-produto-header';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SelecionarVendedorList } from './components/selecionar-vendedor-produto-list/selecionar-vendedor-produto-list';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

interface LocationProps {
  pedido: PedidoModel;
}

export const MovAlterarVendedorProdutoPedidoPage = () => {
  const location = useLocation<LocationProps>();
  const classes = useDefaultCadastroStyles();
  const history = useHistory()

  useEffect(() => {
    if (isEmpty(location?.state?.pedido)) {
      history.push('/venda-simples/pedidos')
    }
  }, [history, location?.state?.pedido])

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.root}>
          <Grid className={classes.header}>
            <SelecaoVendedorHeader />
          </Grid>
          <Grid className={classes.list}>
            <SelecionarVendedorList pedido={location?.state?.pedido} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
