import { Box, Button, Divider, Grid } from "@material-ui/core"
import { EnumEntradaFiltroPagina } from "../../entrada-filtro-conteudo"
import { EntradaFiltroStatusProps } from "./entrada-filtro-status-props"
import { useState } from "react"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { OkIcon } from "views/components/icons"
import { useStyles } from "./entrada-filtro-status-styles"
import { RadioControlLabel } from "./component/radio-control-label/radio-control-label"
import { StatusMovimentacaoMockTodos } from "data/mocks/status-entrada-mock"
import { EnumStatusEntrada } from "model/enums/enum-status-entrada"

export enum EnumEntradaFiltroStatus {
    SemManifesto,
    Ciencia,
    FoiRealizado,
    Nada = -1
}

export const EntradaFiltroStatus = (props: EntradaFiltroStatusProps) => {

    const classes = useStyles()

    const [status, setStatus] = useState<EnumStatusEntrada | -1>(props.vFiltro)

    const modalClasses = useModalStyles()

    const submit = () => {
        props.alterarFiltro('status', status)

        props.entrarPagina(EnumEntradaFiltroPagina.Inicial)
    }

    const onClick = (value: EnumStatusEntrada | -1) => {
        setStatus(value)
    }

    return (
        <div className={modalClasses.content}>
            <Box width="100%" height="0" flex="1">
                <Grid container>
                    {StatusMovimentacaoMockTodos.map(x => {
                        return (
                            <Grid xs={12} className={classes.pGrid}>
                                <Box py={1}>
                                    <RadioControlLabel
                                        checked={status === x.Key}
                                        onClick={() => onClick(x.Key)}
                                        label={x.Value}
                                    />
                                </Box>
                                <Divider />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <div className={modalClasses.acoes}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={submit}
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}