export enum EnumTpEmis {
    NORMAL = 1,
    CONTINGENCIA_FS_IA = 2,
    CONTINGENCIA_SCAN = 3,
    CONTINGENCIA_EPEC = 4,
    CONTINGENCIA_FS_DA = 5,
    CONTINGENCIA_SVC_AN = 6,
    CONTINGENCIA_SVC_RS = 7,
    OFFLINE = 9,
}
