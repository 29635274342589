
export function imagemBase64(img: string) {
  if (img) {
    try {
      return atob(img)
    } catch (e) {
      return img
    }
  } else {
    return ''
  }
}