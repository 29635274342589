import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { SessaoPostConferenciaModel } from "model/api/gestao/sessao/sessao-post-conferencia-model";

export function usePostSessaoConferencia() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postSessaoConferencia = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string, payload: SessaoPostConferenciaModel) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${caixaId}/Sessao/${sessaoId}/conferir`,
                method: "POST",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(payload),
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postSessaoConferencia,
    };
}
