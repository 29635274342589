interface ObjUF {
    sigla: string;
    codigo: number;
}

export const codigoUf: ObjUF[] = [
    { codigo: 11, sigla: 'RO' },
    { codigo: 12, sigla: 'AC' },
    { codigo: 13, sigla: 'AM' },
    { codigo: 14, sigla: 'RR' },
    { codigo: 15, sigla: 'PA' },
    { codigo: 16, sigla: 'AP' },
    { codigo: 17, sigla: 'TO' },
    { codigo: 21, sigla: 'MA' },
    { codigo: 22, sigla: 'PI' },
    { codigo: 23, sigla: 'CE' },
    { codigo: 24, sigla: 'RN' },
    { codigo: 25, sigla: 'PB' },
    { codigo: 26, sigla: 'PE' },
    { codigo: 27, sigla: 'AL' },
    { codigo: 28, sigla: 'SE' },
    { codigo: 29, sigla: 'BA' },
    { codigo: 31, sigla: 'MG' },
    { codigo: 32, sigla: 'ES' },
    { codigo: 33, sigla: 'RJ' },
    { codigo: 35, sigla: 'SP' },
    { codigo: 41, sigla: 'PR' },
    { codigo: 42, sigla: 'SC' },
    { codigo: 43, sigla: 'RS' },
    { codigo: 50, sigla: 'MS' },
    { codigo: 51, sigla: 'MT' },
    { codigo: 52, sigla: 'GO' },
    { codigo: 53, sigla: 'DF' }
]
