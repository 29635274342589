import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetDashboardVendas() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getDashboardVendas = useCallback(
    (
      query: string
    ) => {

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/dashboard/vendas${query}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getDashboardVendas,
  };
}
