import { Box, Slide, Typography } from '@material-ui/core';
import { ConfirmadoIcon } from 'views/components/icons/confirmado-icon';
import { useThemeQueries } from 'views/theme';

interface GerarComandaProps {
    fase: number;
    label: string;
}

export const GerarComandaConfirmacao = ({ fase, label }: GerarComandaProps) => {

    const {theme, xs} = useThemeQueries();

    return (
            <Slide in={fase === 2} direction='left'>
                <Box gridGap={16} flexDirection='column' display='flex' justifyContent='center' alignItems='center' flexGrow={1} marginBottom={xs ? '100px' : undefined}>
                    <ConfirmadoIcon tipo='GERAL' viewBox='0 0 138 138' style={{width: 130, height: 138, fill: theme.palette.primary.main}}/>
                    <Typography variant='h4' align='center' color='primary'>{label}</Typography>
                </Box>
            </Slide>
    );
};