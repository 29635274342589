import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const SincronizarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M41.9965 23.3134C41.5019 23.2144 41.4623 22.977 41.4623 22.5614C41.4821 16.7833 37.5844 11.8164 31.9059 10.3719C26.4649 8.98669 20.7468 11.5592 18.0164 16.625C17.7592 17.0801 17.6207 17.1197 17.1656 16.8624C13.1887 14.5274 8.12364 16.9218 7.45093 21.4929C7.37179 22.0272 7.17393 22.2844 6.67929 22.5021C2.48475 24.3424 0.328125 28.9135 1.55483 33.2471C2.68261 37.2641 6.26379 39.9553 10.5968 39.9751C15.5234 39.9949 20.45 39.9751 25.3767 39.9751C30.4022 39.9751 35.4277 40.0147 40.4533 39.9553C44.9644 39.9157 48.5456 36.6111 48.9611 32.1983C49.3765 27.9438 46.4285 24.1643 41.9965 23.3134ZM41.4623 37.9567C40.9281 38.0161 40.3741 38.0359 39.8399 38.0556C35.032 38.0556 30.2241 38.0556 25.436 38.0556C20.5885 38.0556 15.7213 38.0556 10.8738 38.0556C7.45093 38.0556 4.81945 36.3341 3.6521 33.4054C2.08904 29.4675 4.22588 25.0745 8.36106 23.8081C9.01399 23.6102 9.2712 23.3728 9.25142 22.6406C9.19206 20.5826 10.1813 19.0589 12.0412 18.2278C13.9604 17.3769 15.7411 17.7331 17.3239 19.1183C17.6801 19.4349 17.9769 20.1077 18.3923 20.0087C18.7881 19.9098 18.8078 19.1974 18.9859 18.7423C20.826 14.191 25.1194 11.5394 29.9867 11.9351C34.4978 12.3111 38.3164 15.6949 39.3255 20.2264C39.6025 21.4731 39.642 22.7197 39.464 23.9664C39.3453 24.8767 39.3255 24.8767 40.2752 24.8767C43.7574 24.9162 46.3889 27.0534 47.0023 30.3382C47.6552 33.9397 45.0633 37.5214 41.4623 37.9567Z" />
        <path d="M27.9884 25.6682C28.6215 25.0152 29.1359 24.4809 29.6899 23.9862C30.0856 23.63 30.0658 23.4322 29.6503 23.1156C26.5836 20.642 21.8548 22.047 20.6281 25.787C20.5292 26.064 20.6281 26.5191 20.2324 26.5785C19.7378 26.6577 19.1838 26.6774 18.6891 26.5785C18.333 26.4993 18.5506 26.1036 18.61 25.8859C19.4014 22.9177 21.2613 20.9586 24.2291 20.2067C26.7221 19.5735 29.0172 20.1077 30.9958 21.8095C31.4113 22.1657 31.6091 22.1064 31.9257 21.7502C32.4401 21.1763 32.9941 20.6222 33.5283 20.0682C33.5877 20.1077 33.647 20.1275 33.7064 20.1671C33.7064 21.9085 33.7064 23.63 33.7064 25.3714C33.7064 25.6485 33.5679 25.688 33.3502 25.688C31.6289 25.6682 29.8878 25.6682 27.9884 25.6682Z" />
        <path d="M18.4319 34.9489C18.4319 33.2669 18.4319 31.5651 18.4319 29.8831C18.4319 29.606 18.5308 29.4873 18.8276 29.4873C20.5489 29.4873 22.2505 29.4873 24.0708 29.6456C23.4772 30.2195 22.9034 30.8131 22.2901 31.3474C21.9537 31.6442 22.0131 31.8223 22.3296 32.0598C25.337 34.3157 30.1449 33.4648 31.5497 29.2499C31.6289 29.0124 31.5497 28.6166 31.8861 28.5771C32.4203 28.5177 32.9743 28.4979 33.4887 28.5968C33.7657 28.6562 33.5678 29.0322 33.5085 29.2499C32.5786 33.3065 28.5819 35.8591 24.4863 35.0478C23.1804 34.7906 21.9933 34.2563 20.9842 33.4252C20.6281 33.1284 20.4302 33.1679 20.1334 33.4845C19.6586 34.0386 19.1442 34.5729 18.6495 35.1072C18.5902 35.0478 18.511 35.0082 18.4319 34.9489Z" />
      </DefaultIcon>
    </>
  );
};
