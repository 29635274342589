import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardTopTitle: {
        width: "100%",
        height: 30,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
    },
    textTopTitle: {
        color: "#fff",
        textAlign: "center",
        padding: 1,
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    cardSubTitle: {
        marginleft: 0,
        marginbottom: 0,
        color: "#777",
        marginBottom: 10,
    },
    cardPricingFree: {
        textAlign: "center",
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
    },
    cardPricingR$: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0
    },
    cardPricing: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
        display: "flex",
        flexDirection: "row"
    },
    cardTextPerMonth: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    },
    cardContentDesconto: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '96px'
    },
    cardPricingDesconto: {
        display: 'flex',
        textDecoration: `line-through ${theme.palette.grey[500]}`,
    },
    cardValorDesconto: {
        display: 'flex',
        justifyContent: 'center'
    },
    cardAcoes: {
        marginTop: 16,
        marginBottom: 8,
    },
    textPerMonth: {
        fontWeight: 600
    },
    containerItem: {
        marginBottom: 20,
        width: "100%",
        maxHeight: 210,
        flexDirection: "column",
        justifyContent: "center",
        overflowY: 'auto'
    },
    containerItemMobile: {
        marginBottom: 20,
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        overflowY: 'auto'
    },
    cardOptionItem: {
        marginLeft: 1,
        marginBottom: 1,
        marginTop: 1,
    },
    list: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "10px",
    },
    cardFooterText: {
        marginBottom: 10,
        fontWeight: "bold",
        textAlign: "center",
        marginleft: 1
    },
    title: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
    },
}));
