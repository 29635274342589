import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: '100%',
        height: '100%',

    },
    contentCarousel: {
        flex: '1 1 100%',
    },
    contentButtons: {
        flex: '0 auto',
        margin: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    bgColor: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -100,
        width: '100vw',
        height: '100vh',
        background: theme.palette.primary.main,
    },
    carouselLandig: {
        height: '500px'
    },
    texts: {
        color: theme.palette.primary.contrastText
    },
    textFlex: {
        flex: '0 auto',
        padding: '30px'
    },
    welcomeText: {
        fontSize: 18,
        '@media screen and ( min-height: 800px )': {
            fontSize: 25
        }
    },
    descriptionText: {
        fontSize: 16,
        '@media screen and ( min-height: 800px )': {
            fontSize: 20
        }
    },
    titleText: {
        fontSize: 35,
        '@media screen and ( min-height: 800px )': {
            fontSize: 50 
        }
    },
    loginButton: {
        color: theme.palette.primary.main,
        background: theme.palette.primary.contrastText,
        borderRadius: 20,
        padding: '10px 0',
        '@media screen and ( min-height: 800px )': {
            fontSize: 18
        }
    },
    signupButton: {
        color: '#fff',
        '@media screen and ( min-height: 800px )': {
            fontSize: 18
        }
    }
}));