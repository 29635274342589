import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetManifesto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getManifesto = useCallback(
        (cnpj: string, query: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${cnpj}/Documento?${query}`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getManifesto,
    };
}