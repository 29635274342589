import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetTema () {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getTema = useCallback((empresaId: string) =>
    invocarApi({
      url: `/v4/empresa/${empresaId}/tema`,
      method: "GET",
      enviarTokenUsuario: true,
      headers: { 'Domain': 'app.touchone.com.br' },
      baseURL: `${VariaveisAmbiente.apiUrlCardapio}/api`
    }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getTema,
  }
}
