import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogAdicionarCodigo } from 'views/components/dialog/dialog-adicionar-codigo/dialog-adicionar-codigo';

export interface DialogCodigoProdutoProps {
  aberto: boolean;
  onSubmit: (
    model: ProdutoCodigoModel,
    beforeModel?: ProdutoCodigoModel | undefined
  ) => Promise<boolean>;
  carregando?: boolean;
}

export const DialogCodigoProduto = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogCodigoProdutoProps>();
  const { fecharDialogCodigoProduto } = useCadastros();

  consoleDev('DialogCodigoProduto');

  const modalAlterado = useCallback(
    ({ aberto, onSubmit, carregando }: DialogCodigoProdutoProps) => {
      setModalState({
        aberto,
        onSubmit,
        carregando
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogCodigoProduto, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogCodigoProduto, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState?.aberto && (
        <DialogAdicionarCodigo
          close={fecharDialogCodigoProduto}
          aberto={modalState.aberto}
          carregando={modalState.carregando}
          onSubmit={modalState.onSubmit}
        />
      )}
    </>
  );
};
