import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback } from "react";
import { useEventTools } from "./events/event-tools";
import { EntradaListSearchProps } from "views/pages/private/entrada/components/entrada-list/entrada-list-search-props";
import { ManifestosSearchProps } from "views/pages/private/manifestos/components/manifestos-list/manifestos-search-props";
import { CategoriaListSearchProps } from "views/pages/private/cadastros/categoria/components/categoria-list/categoria-list-search-props";
import { NcmListSearchProps } from "views/pages/private/cadastros/ncm/components/ncm-list/ncm-list-search-props";
import { ImpostoListSearchProps } from "views/pages/private/cadastros/impostos/components/imposto-list/imposto-list-search-props";
import { PontosVendaListSearchProps } from "views/pages/private/pontos-venda/components/pontos-venda-list/pontos-venda-list-search-props";
import { HistoricoVendaListSearchProps } from "views/pages/private/historico-vendas/components/historico-venda-tabs/components/historico-venda-list-search-props";
import { PessoaListSearchProps } from "views/pages/private/cadastros/pessoa/components/pessoa-list/pessoa-list-search-props";
import { DocumentoFiscalListSearchProps } from "views/pages/private/fiscal/documento-fiscal/components/documento-fiscal-list/documento-fiscal-list-search-props";
import { ProdutoListSearchProps } from "views/pages/private/cadastros/produto/components/produto-list/produto-list-search-props";


export const useFiltrosModais = () => {

    const { callEvent } = useEventTools()

    //----------------------------------------------------------------------------------------------------------------EntradaFiltro
    const abrirEntradaFiltroModal = useCallback(
        (filtros: EntradaListSearchProps) => {
            callEvent(AppEventEnum.EntradaFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharEntradaFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.EntradaFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    //-------------------------------------------------------------------------------------------------------ManifestosFiltro

    const abrirManifestosFiltroModal = useCallback(
        (filtros: ManifestosSearchProps) => {
            callEvent(AppEventEnum.ManifestosFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharManifestosFiltrosModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.ManifestosFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);
    ///-------------------------------------------------------------------------------------------------------------CategoriaFiltro

    const abrirCategoriaFiltroModal = useCallback(
        (filtros: CategoriaListSearchProps) => {
            callEvent(AppEventEnum.CategoriaFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharCategoriaFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.CategoriaFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------NcmsFiltro

    const abrirNcmsFiltroModal = useCallback(
        (filtros: NcmListSearchProps) => {
            callEvent(AppEventEnum.NcmsFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharNcmsFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.NcmsFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------ImpostosFiltro

    const abrirImpostosFiltroModal = useCallback(
        (filtros: ImpostoListSearchProps) => {
            callEvent(AppEventEnum.ImpostosFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharImpostosFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.ImpostosFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------PDVFiltro

    const abrirPDVFiltroModal = useCallback(
        (filtros: PontosVendaListSearchProps) => {
            callEvent(AppEventEnum.PDVFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharPDVFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.PDVFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------HistoricoVendaFiltros

    const abrirHistoricoVendaFiltroModal = useCallback(
        (filtros: HistoricoVendaListSearchProps) => {
            callEvent(AppEventEnum.HistoricoVendaFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharHistoricoVendaFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.HistoricoVendaFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------ProdutoFiltros

    const abrirProdutoFiltroModal = useCallback(
        (filtros: ProdutoListSearchProps) => {
            callEvent(AppEventEnum.ProdutoFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharProdutoFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.ProdutoFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------ProdutoFiltros

    const abrirPessoasFiltroModal = useCallback(
        (filtros: PessoaListSearchProps) => {
            callEvent(AppEventEnum.PessoasFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharPessoasFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.PessoasFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    ///-------------------------------------------------------------------------------------------------------------ProdutoFiltros

    const abrirDocumentoFiscalFiltroModal = useCallback(
        (filtros: DocumentoFiscalListSearchProps) => {
            callEvent(AppEventEnum.DocumentoFiscalFiltroModal, {
                openned: true,
                filtros,
            });
        },
        [callEvent]
    );

    const fecharDocumentoFiscalFiltroModal = useCallback((
        att: boolean = false
    ) => {
        callEvent(AppEventEnum.DocumentoFiscalFiltroModal, {
            openned: false,
            att
        });
    }, [callEvent]);

    return {
        abrirEntradaFiltroModal,
        fecharEntradaFiltroModal,

        abrirManifestosFiltroModal,
        fecharManifestosFiltrosModal,

        abrirCategoriaFiltroModal,
        fecharCategoriaFiltroModal,

        abrirNcmsFiltroModal,
        fecharNcmsFiltroModal,

        abrirImpostosFiltroModal,
        fecharImpostosFiltroModal,

        abrirPDVFiltroModal,
        fecharPDVFiltroModal,

        abrirHistoricoVendaFiltroModal,
        fecharHistoricoVendaFiltroModal,

        abrirProdutoFiltroModal,
        fecharProdutoFiltroModal,

        abrirPessoasFiltroModal,
        fecharPessoasFiltroModal,

        abrirDocumentoFiscalFiltroModal,
        fecharDocumentoFiscalFiltroModal
    }
}