import { Box, Button, Container, Grid, Radio, Typography } from "@material-ui/core"
import { SexoIdentificacaoProps } from "./sexo-identificacao-props"
import { useCallback, useEffect, useState } from "react"
import { EnumSexo } from "model/enums/enum-sexo"
import { SugestaoSources, useClienteIdentificacaoUtils } from "../use-cliente-identificacao-utils"
import { useMovAtual } from "services/app/hooks/mov-atual"

export const SexoIdentificacao = ({ valueChanged, sexo }: SexoIdentificacaoProps) => {
    const [value, setValue] = useState<EnumSexo>(EnumSexo.NaoInformado)
    const { showSuggestionToast } = useClienteIdentificacaoUtils()
    const { getMov, currentClienteIsPadrao } = useMovAtual()
    const handleChanged = useCallback((sexo: EnumSexo) => {
        valueChanged(sexo)
        setValue(sexo)
    }, [valueChanged, setValue])
    const fillSexo = useCallback(async () => {
        const mov = getMov()
        let sexoToSet: EnumSexo = EnumSexo.NaoInformado
        let fonteSugestao: SugestaoSources = 'Vazio'        
        if (mov) {
            const isPadrao = await currentClienteIsPadrao()
            if (!isPadrao) {
                const cliente = mov.cliente
                if (cliente) {
                    sexoToSet = cliente.sexo
                    fonteSugestao = 'Fluxo de venda atual'
                }

            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    sexoToSet = receita.comprador.sexo
                    fonteSugestao = 'Receita'
                }
            }
            showSuggestionToast({
                key: 'Sexo',
                source: fonteSugestao
                
            })
            handleChanged(sexoToSet)
        }
    }, [currentClienteIsPadrao, getMov, handleChanged, showSuggestionToast])
    useEffect(() => {
        fillSexo()
    }, [fillSexo])
    return (
        <Container maxWidth="sm">
            <Box width="100%">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box mb={3} mt={4}>
                            <Typography variant="h5" align='center'>
                                Informe o Gênero
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<Radio color="primary" checked={value === EnumSexo.Masculino} />}
                            variant={"outlined"}
                            color={value === EnumSexo.Masculino ? 'primary' : 'default'}
                            onClick={() => {
                                if (value !== EnumSexo.Masculino) {
                                    handleChanged(EnumSexo.Masculino)
                                }
                            }}
                            fullWidth
                        >
                            Masculino
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<Radio color="primary" checked={value === EnumSexo.Feminino} />}
                            variant={"outlined"}
                            color={value === EnumSexo.Feminino ? 'primary' : 'default'}
                            onClick={() => {
                                if (value !== EnumSexo.Feminino) {
                                    handleChanged(EnumSexo.Feminino)
                                }
                            }}
                            fullWidth
                        >
                            Feminino
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}