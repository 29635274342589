import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    loadingPesando: {
        height: 100
    },
    container: {
        height: '100%',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingBottom: 0
    },
    grow: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    scanAnim: {
        marginBottom: theme.spacing(2)
    }
}));
