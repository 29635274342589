import { useEffect } from "react"

export const useVerificarAbas = (deslogar: () => void) => {

    useEffect(() => {
        const logout = (event: StorageEvent) => {
            if (event.key === 'deslogar') {
                deslogar()
            }
        }
        localStorage.setItem('deslogar', 'false')
        window.addEventListener('storage', logout)
        return () => {
            window.removeEventListener('storage', logout)
        }
    }, [deslogar])

}