import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogControlados } from 'views/components/dialog/dialog-controlados/dialog-controlado';

export const DialogProdutosMedicamentosControlados = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<{
    produtos: number;
    aberto: boolean;
    handleClickNaoInformar: Function;
    callback?: () => Promise<any>;
  }>({
    produtos: 0,
    aberto: false,
    handleClickNaoInformar: () => {},
    callback: () => Promise.resolve()
  });

  const modalAlterado = useCallback(
    ({ quantidade, aberto, handleClickNaoInformar, callback }: { quantidade: number; aberto: boolean, handleClickNaoInformar: Function, callback?: () => Promise<any> }) => {
      setModalState({
        produtos: quantidade,
        aberto: aberto,
        handleClickNaoInformar,
        callback
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.Controlados, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.Controlados, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogControlados
          qtd={modalState.produtos}
          openned={modalState.aberto}
          handleClickNaoInformar={modalState.handleClickNaoInformar}
          callback={modalState.callback}
        />
      )}
    </>
  );
};
