import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { CardUploadCarga } from 'views/components/cards/card-upload-carga/card-upload-carga';
import { UploadCargaModel } from 'model/api/gestao/upload-carga/upload-carga-model';

export interface UploadCargaGridProps {
  list: Array<UploadCargaModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const UploadCargaListData = (props: UploadCargaGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Conversão encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((carga, index) => {
          return (
            <CardUploadCarga
              selected={
                props.selectedList.filter((item) => item === carga.id).length >
                0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={carga}
              key={index}
            />
          );
        })}
    </>
  );
};
