import { useCallback, FocusEvent, useState } from 'react';
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { useGetSetores } from 'data/api/gestao/setores';
import { useSessaoAtual } from 'services/app';

export interface AutocompleteSetorProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string | null;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  idSetor?: string | null;
  allowSubmit?: boolean;
  permiteAdicionar?: boolean;
  modo?: 'setor';
  disabled?: boolean;
}

export const AutocompleteProdutoSetor = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteSetorProps) => {
  // STATES E REFS
  const [setorState, setSetorState] = useState<Array<SetoresModel>>(
    new Array<SetoresModel>(),
  );

  // CHAMADAS API
  const { getSetores, carregando: carregandoSetores } = useGetSetores();

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();

  const getSetoresWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getSetores(getEmpresaSelecionada()?.Id ?? '', `?pageSize=0`);
        if (ret.erro) throw ret.erro;

        let setores: Array<SetoresModel> = new Array<SetoresModel>();

        const setoresFilter = (ret.resultado?.data.list as SetoresModel[]).filter(
          (setor) =>
            setor.descricao
              .toLocaleLowerCase()
              .includes(termo.toLocaleLowerCase()),
        );

        if (setoresFilter.length > 0) {
          setores = setoresFilter as Array<SetoresModel>;
        }

        setores.push({
          id: '1',
          descricao: 'Sem Setor',
          uriImage: '',
        });

        setSetorState(setores);
      } catch (e: any) { }
    },
    [getEmpresaSelecionada, getSetores],
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const onPesquisa = useCallback(
    (termo: string) => {
      getSetoresWrapper(termo);
    },
    [getSetoresWrapper],
  );

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      loading={carregandoSetores}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={setorState}
      optionLabelKey={'descricao'}
      optionValueKey={'descricao'}
      disabledValueKey="id"
      disabledValues={[props.idSetor]}
      noOptionsText={'Nenhum setor localizado'}
      onBlur={props.onBlur}
      value={props.value}
      name={props.name}
      label={props.label}
      allowSubmit={props.allowSubmit}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={props.permiteAdicionar}
    />
  );
};
