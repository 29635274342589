import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogDefinicoesVenda } from 'views/components/modals/definicoes-da-venda/dialog-definicoes-da-venda';

class DefinicoesDialogProps {
    constructor(
        public aberto: boolean = false,
    ) { }
}

export const Definicoes = () => {
    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<DefinicoesDialogProps>(new DefinicoesDialogProps());

    const modalAlterado = useCallback(({ aberto }: DefinicoesDialogProps) => {
        setModalState({
            aberto
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.DefinicoesVenda, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.DefinicoesVenda, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogDefinicoesVenda id='definicoesVenda' openned={modalState.aberto} />
            )}
        </>
    );
};