import { usePutAtualizarEntradaXML } from "data/api/gestao/venda/put-entrada-xml";
import { isEmpty } from "lodash";
import { TotalModel,  } from "model/api/gestao/venda/venda-completa-model";
import { ProdsXMLModel, VendaCompletaXMLModel } from "model/api/gestao/venda/venda-completa-xml-model";
import { ProdsPutModel, VendaCompletaXMLPutModel } from "model/api/gestao/venda/venda-completa-xml-put-model";
import { useToastSaurus } from "services/app";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { guidEmpty } from "utils/guid-empty";
import { picker } from "utils/picker";
import { toDecimal } from "utils/to-decimal";



export const useSalvarXML = () => {

    const { putAtualizarEntradaXML, carregando } = usePutAtualizarEntradaXML();

    const { getEmpresaAtual } = useEmpresaAtual();

    const { showToast } = useToastSaurus();

    const saveChangesXML = async (model: VendaCompletaXMLModel, logError: boolean = true) => {
        try {
            const mov = structuredClone(model as VendaCompletaXMLPutModel) as VendaCompletaXMLPutModel

            mov.infMov.prod = model.infMov.prod.map<ProdsPutModel>((produto) => {
                const castedProd = produto as ProdsXMLModel;
                return {
                    ...picker<ProdsPutModel>(castedProd, new ProdsPutModel()),
                    rastro: isEmpty(castedProd.rastro) ? [] : castedProd.rastro,
                    indFin: true,
                    indEstoque: true,
                    valorLucro: castedProd.valorLucro,
                    valorVenda: toDecimal(castedProd.valorVenda),
                    fator: castedProd.fator,
                    balanca: castedProd.balanca,
                    marca: castedProd.marca,
                }
            }) as ProdsPutModel[];
            mov.infMov.total = { ...model.infMov.total, qCom: toDecimal(model.infMov.total.qCom) } as TotalModel;

            mov.infMov.UFSaidaPais =
                mov.infMov.UFSaidaPais &&
                    mov.infMov.UFSaidaPais > 0
                    ? mov.infMov.UFSaidaPais
                    : null
            mov.terminalId = mov.terminalId === guidEmpty() ? null : mov.terminalId


            const res = await putAtualizarEntradaXML(mov, model.id, getEmpresaAtual()?.id || '');

            if (res.erro) throw res.erro

            return true

        } catch (e: any) {
            if (logError) {
                showToast('error', e.message)
            }
            return false
        }
    }
    return {
        saveChangesXML,
        carregando
    }

}