import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useDeleteProcesso() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteProcesso = useCallback(
        (empresaId: string, setorId: string, processoSetorId: string, processoId: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/setor/${setorId}/processo/${processoSetorId}/remover-processo/${processoId}`,
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        deleteProcesso,
    };
}