import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: '10px 5px'
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    title: {
        fontWeight: 500,
        lineHeight: "23px",
        fontSize: "1rem",
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(2),
    },
    cardSelecionarVendedor: {
        margin: '17px 0 17px 8px',
        padding: '11px 15px 12px 12px',
        width: '100%',
        maxWidth: '480px',
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        boxShadow: '3px -1px 4px rgba(0,0,0,0.3)',
        [theme.breakpoints.down("md")]: {
            margin: '10px 8px 0 8px',
        },
    },
    titleSelecionarVendedor: {
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
}));
