import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardFinalizacao: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    padding: 4
  },
  cardContainer: {
    margin: theme.spacing(1),
    minHeight: "80px",
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    position: "relative",
    overflow: "hidden",

    "& span": {
      fontSize: "0.7em",
      textOverflow: 'ellipsis'
    },
    "& p": {
      fontSize: "1.0em",
      textOverflow: 'ellipsis'
    },

  },
  topContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    alignItems: 'center',
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textInfo: {
    fontWeight: 600,
    fontSize: '16px',
    textOverflow: 'ellipsis',
  },
  multiButton: {

    '&:disabled': {
      color: '#fff',
      backgroundColor: 'rgba(218, 218, 218, 0.3)'
    }
  },
  botoesAgrupados: {
    alignItems: 'center', 
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '30px',
    height: '2.5rem',
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1)}px`
    }


  },
  botaoSelecionado: {
    '&:hover': {
      backgroundColor: '#fff'
    },
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    borderRadius: '30px',
    height: '2.5rem',
    boxShadow: 'none'
  },
  botaoNaoSelecionado: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '30px',
    height: '2.5rem',
    boxShadow: 'none'
  },
  textfieldFracionado: {
    maxWidth: 150,
    alignItems: 'center', 
    justifyContent: 'flex-end'
  },
  buttonAddSubDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

  },

  descricaoProduto: {
    flex: 1,
    width: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: 60
    }
  },
  qtd: {
    fontSize: 12
  },
  gridQtd: {
    display: 'flex', alignItems: 'center'
  },
  qtdDetalhada: {
    width: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      width: 60
    }
  }

}));

