import { KeyValueModel } from "model";
import { EnumAcaoManifesto } from "model/enums/enum-acao-manifesto";
import { EnumStatusManifesto } from "model/enums/enum-status-manifesto";

export const AcaoManifestoMock: KeyValueModel[] = [
    new KeyValueModel(EnumAcaoManifesto.EventoDeCartaDeCorrecao, "Evento de Carta de Correção"),
    new KeyValueModel(EnumAcaoManifesto.Cancelamento, "Cancelamento"),
    new KeyValueModel(EnumAcaoManifesto.EventosDeConfirmacaoDeOperacao, "Eventos de Confirmação de Operação"),
    new KeyValueModel(EnumAcaoManifesto.EventosDeCienciaDaOperacao, "Eventos de Ciência da Operação"),
    new KeyValueModel(EnumAcaoManifesto.EventosDeDesconhecimentoDaOperacao, "Eventos de Desconhecimento da Operação"),
    new KeyValueModel(EnumAcaoManifesto.EventosDeOperacaoNaoRealizada, "Eventos de Operação Não Realizada")
];

export const ManifestoStatusMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumStatusManifesto.ResumoEvento, "Resumo Evento"),
    new KeyValueModel(EnumStatusManifesto.ResumoNFe, "Resumo NFe"),
    new KeyValueModel(EnumStatusManifesto.EventosDeCienciaDaOperacao, "Ciência da Operação"),
    new KeyValueModel(EnumStatusManifesto.EventosDeDesconhecimentoDaOperacao, "Desconhecimento da Operação"),
    new KeyValueModel(EnumStatusManifesto.EventosDeOperacaoNaoRealizada, "Operação Não Realizada"),
    new KeyValueModel(EnumStatusManifesto.EventosDeConfirmacaoDeOperacao, "Operação Confirmada"),
];