import { Box, Tooltip, Typography } from '@material-ui/core';
import { CardTaxaServicoProps } from './card-taxa-servico-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { memo } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { useStyles } from './card-taxa-servico-styles'
import classNames from 'classnames';

export const CardTaxaServico = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardTaxaServicoProps) => {

  const classes = useDefaultCardStyles();
  const myClasses = useStyles()

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
      >
        <Box display="flex" width="100%" style={{
          opacity: model.ativo ? 1 : 0.6
        }}>
          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Vendedor
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                {model?.vendedorNome || '-'}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Taxa
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="primary"
                variant="body1"
              >
                ({model.taxa}%)
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography color="textPrimary" variant="caption">
                Valor
              </Typography>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
              >
                R$ {toDecimalString(model.valor)}
              </Typography>
            </div>
          </div>
          <Tooltip arrow title="Editar Taxa">
            <div className={classNames(classes.cardRightButton, myClasses.iconCor)}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        </Box>
      </DefaultCard>
    </>
  );
};

export default memo(CardTaxaServico);
