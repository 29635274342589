import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ComandasIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M44.6004 3.77971C44.6004 2.24897 44.3607 2 42.8299 2C31.1189 2 19.4078 2 7.6875 2C6.24898 2 6 2.23975 6 3.67828C6 17.5471 6 31.416 6 45.2941C6 46.7234 6.26742 47 7.67828 47C19.417 47 31.1557 47 42.9037 47C44.3238 47 44.6004 46.7234 44.6004 45.3125C44.6004 38.4057 44.6004 31.499 44.6004 24.5922C44.6004 17.6486 44.6004 10.7141 44.6004 3.77971ZM41.5481 44.8699C30.7223 44.8514 19.8873 44.8514 9.06148 44.8699C8.36066 44.8699 8.13012 44.7408 8.13934 43.9754C8.17623 37.4559 8.15779 30.9365 8.15779 24.4078C8.15779 17.9436 8.17623 11.4795 8.13934 5.00615C8.13934 4.24078 8.36066 4.11168 9.06148 4.11168C19.8873 4.13012 30.7223 4.13012 41.5481 4.11168C42.249 4.11168 42.4703 4.24078 42.4703 5.00615C42.4426 17.9898 42.4426 30.9826 42.4703 43.9662C42.4703 44.7316 42.249 44.8699 41.5481 44.8699Z" />
        <path d="M29.1177 21.2725C26.3329 21.2725 23.5388 21.2541 20.754 21.291C20.0993 21.3002 19.8964 21.125 19.9241 20.4703C19.9886 18.9027 19.7396 19.1516 21.2335 19.1424C26.5911 19.1332 31.9394 19.1516 37.297 19.124C37.9886 19.124 38.1915 19.3084 38.1638 20C38.1085 21.2817 38.1546 21.2817 36.9005 21.2817C35.047 21.2817 33.2028 21.2817 31.3493 21.2817C30.6116 21.2725 29.8646 21.2725 29.1177 21.2725Z" />
        <path d="M29.1178 27.709C31.8473 27.709 34.586 27.7274 37.3155 27.6998C37.9241 27.6905 38.1915 27.792 38.1731 28.4928C38.1178 29.8483 38.1639 29.8483 36.8083 29.8483C31.5061 29.8483 26.2038 29.8391 20.9108 29.8668C20.1915 29.8668 19.878 29.7469 19.9333 28.9354C20.0255 27.5061 19.7673 27.7274 21.1782 27.7182C23.8247 27.6998 26.4712 27.709 29.1178 27.709Z" />
        <path d="M29.0164 12.6967C26.2592 12.6967 23.4928 12.6783 20.7357 12.7059C20.1639 12.7152 19.8965 12.6229 19.9242 11.959C19.9703 10.5758 19.9242 10.5758 21.335 10.5758C26.6373 10.5758 31.9303 10.5943 37.2326 10.5574C38.0256 10.5482 38.2654 10.7971 38.1547 11.5256C38.1271 11.6916 38.1271 11.8576 38.1547 12.0236C38.2377 12.5768 37.998 12.7152 37.4631 12.7152C34.6506 12.6783 31.8381 12.6967 29.0164 12.6967Z" />
        <path d="M29.1178 36.294C31.8749 36.294 34.6413 36.3125 37.3985 36.2756C38.0348 36.2664 38.1915 36.4508 38.1731 37.0686C38.1362 38.4149 38.1639 38.4242 36.8176 38.4242C31.4876 38.4242 26.167 38.4057 20.837 38.4426C20.0901 38.4518 19.8319 38.2397 19.9518 37.5205C19.9795 37.3637 19.9795 37.1885 19.9518 37.0225C19.8688 36.4416 20.1085 36.2756 20.6803 36.2848C23.4835 36.3125 26.3053 36.294 29.1178 36.294Z" />
        <path d="M15.0738 21.2817C15.0461 21.2817 15.0184 21.2817 14.9908 21.2817C13.3217 21.2817 13.3309 21.2817 13.4785 19.6035C13.5061 19.2438 13.6352 19.124 13.9764 19.1332C14.7418 19.1424 15.5164 19.1516 16.2818 19.1332C16.6506 19.124 16.7244 19.2899 16.7428 19.6219C16.8627 21.2817 16.8811 21.2817 15.0738 21.2817Z" />
        <path d="M15.1567 27.709C15.295 27.709 15.4334 27.709 15.5717 27.709C16.7428 27.709 16.6967 27.709 16.7612 28.8709C16.8073 29.6455 16.6137 29.9959 15.793 29.8668C15.4426 29.8115 15.083 29.8576 14.7233 29.8576C13.2848 29.8392 13.5614 30.0328 13.4785 28.6496C13.4323 27.8935 13.6537 27.5892 14.419 27.709C14.6588 27.7459 14.917 27.709 15.1567 27.709Z" />
        <path d="M15.0737 12.6967C14.8801 12.6967 14.6772 12.669 14.4928 12.7059C13.6167 12.8719 13.4231 12.4846 13.4784 11.6731C13.5614 10.4375 13.3309 10.5942 14.6034 10.5758C14.9354 10.5758 15.2674 10.5758 15.5993 10.5758C16.7428 10.5758 16.6874 10.585 16.7704 11.6916C16.835 12.5307 16.5768 12.8535 15.7469 12.7059C15.5256 12.6598 15.295 12.6967 15.0737 12.6967Z" />
        <path d="M15.1567 36.2941C15.1844 36.2941 15.212 36.2941 15.2397 36.2941C16.8903 36.2941 16.8719 36.2941 16.752 37.9631C16.7243 38.3412 16.5952 38.4334 16.254 38.4242C15.8114 38.4057 15.3688 38.4242 14.9262 38.4242C13.4046 38.4242 13.2387 38.2029 13.4784 36.6629C13.5245 36.3678 13.6444 36.2941 13.9026 36.2941C14.336 36.3033 14.751 36.2941 15.1567 36.2941Z" />
      </DefaultIcon>
    </>
  );
};
