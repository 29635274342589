import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    content: {
        gap: theme.spacing(2),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
    svgContainer: {
        borderRadius: 8,
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        maxWidth: 600,
        maxHeight: 600,
        padding: 20,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));