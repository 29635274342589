import { makeStyles } from "@material-ui/core";

const heightWin = window.innerHeight

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    containerFull: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative',
    },
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"

    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        },
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto"
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '60px',
        background: '#FCFCFC',
        padding: '5px',
        position: 'sticky',
        bottom: 0,
        width: '100%',
    },
    itemsFooterContainer: {
        display: 'grid',
        gridTemplate: 'repeat(3, 1fr)',
        gridAutoFlow: 'column',
        placeItems: 'center'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        margin: '2px 0',
        height: '2px'
    },
    itemsFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textTotalInfo: {
        fontWeight: 'bold'
    },
    definirTamanhoVazio: {
        [theme.breakpoints.down('sm')]: {
          height: `calc(${heightWin}px - 215px)`,
        }
      },
}));
