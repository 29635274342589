import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { ProdutoCodigoModel } from '../../../../../model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { VariaveisAmbiente } from 'config';

export function usePostProdutoCodigo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutoCodigo = useCallback(
    (
      produtoId: string,
      variacaoId: string,
      produtoCodigo: ProdutoCodigoModel
    ) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/codigo`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtoCodigo),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoCodigo
  };
}
