import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  btnSticky: {
    position: "sticky",
    bottom: "0",
    zIndex: 2,
    backgroundColor: "#FFFFFF",
  },
  botaoAdicionar:{
    padding:'0'
    
  },
  containerCard:{
    minHeight: 50,
    backgroundColor: '#FBFBFB',
    marginBottom: '4px',
    boxShadow: ' 0px 2.5px 8px 0px #28293D40',
    border: '.5px inset #28293D40',
    borderRadius: theme.shape.borderRadius,
    display:'flex',
    justifyContent: 'space-between',
    boxSizing:'border-box',
    padding:'10px',
    [theme.breakpoints.down('sm')]:{
    
    }
  },
  formContainer:{
    overflowX: 'hidden', 
    overflowY: 'auto', 
    flex: 1,
    [theme.breakpoints.down('sm')]:{
      maxHeight:'100%'
    }
  }
}));