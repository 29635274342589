import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const DescontoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          d="M42.3704 12.331C41.3494 13.8368 40.3692 15.2613 39.389 16.6858C34.5902 23.7065 29.7709 30.7271 24.972 37.7681C24.7474 38.0937 24.5432 38.2362 24.1348 38.2158C22.8687 38.1751 21.6231 38.1955 20.2549 38.1955C21.0513 37.0152 21.8069 35.9163 22.5624 34.8174C27.545 27.5322 32.5481 20.247 37.5103 12.9414C37.8166 12.4938 38.1229 12.2903 38.6743 12.3106C39.8791 12.3717 41.0635 12.331 42.3704 12.331Z"
          fill={props.fill}
        />
        <path
          d="M16.1096 19.1685C16.1096 16.9503 16.7018 14.9561 18.4376 13.4502C21.2556 10.9879 27.5043 11.3135 28.75 17.1538C29.1584 19.0871 29.0563 21.0406 28.1782 22.8721C26.953 25.3751 24.6659 26.5351 21.6028 26.2298C19.1727 25.9856 17.0898 24.1745 16.4363 21.6918C16.1913 20.8778 16.1096 20.0232 16.1096 19.1685ZM25.2376 19.4737C25.2376 18.0899 25.1355 16.991 24.4412 16.0346C23.5223 14.7729 21.6845 14.7322 20.7043 15.9532C20.1937 16.6044 19.9691 17.337 19.8874 18.151C19.7445 19.4941 19.7445 20.8371 20.4592 22.0581C21.4394 23.7268 23.604 23.7065 24.6046 22.0378C25.1355 21.1627 25.2172 20.2063 25.2376 19.4737Z"
          fill={props.fill}
        />
        <path
          d="M33.712 31.4394C33.7325 29.4451 34.1409 27.6747 35.4274 26.2502C37.204 24.2966 39.4911 23.8286 41.962 24.4797C44.4124 25.1309 45.801 26.9014 46.3524 29.323C46.72 30.9103 46.6587 32.5179 46.189 34.0848C45.2905 36.9745 42.9626 38.5618 39.777 38.4804C36.9793 38.399 34.7943 36.6285 34.0183 33.7999C33.7937 32.9656 33.7325 32.1516 33.712 31.4394ZM37.4286 31.3783C37.3877 32.233 37.4899 33.0673 37.837 33.861C38.2454 34.7767 38.8989 35.3872 39.9607 35.4483C40.9409 35.5093 41.7373 35.1227 42.1866 34.2273C43.0851 32.3958 43.0851 30.5033 42.2683 28.6311C41.8599 27.7154 41.0839 27.1863 40.022 27.2473C38.9601 27.288 38.2454 27.8985 37.837 28.8549C37.4899 29.6486 37.4082 30.4829 37.4286 31.3783Z"
          fill={props.fill}
        />
        <path
          d="M9.18684 30.1166C7.61445 30.1166 6.06248 30.0963 4.49009 30.1166C4.12252 30.1166 4 30.0149 4 29.6486C4.02042 28.4072 4.02042 27.1659 4 25.9246C4 25.599 4.08168 25.4769 4.42883 25.4769C7.61445 25.4972 10.8205 25.4972 14.0061 25.4769C14.3328 25.4769 14.4554 25.5583 14.4554 25.9042C14.4349 27.1456 14.4349 28.3869 14.4554 29.6282C14.4758 30.0556 14.292 30.1166 13.9244 30.1166C12.3725 30.0963 10.7796 30.1166 9.18684 30.1166Z"
          fill={props.fill}
        />
      </DefaultIcon>
    </>
  );
};
