import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { PessoaEnderecoModel } from "model/api/gestao/pessoa/pessoa-endereco-model";
import { VariaveisAmbiente } from "config";
export function usePutEnderecoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putEnderecoPessoa = useCallback(
    (endereco: PessoaEnderecoModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${endereco.pessoaId}/endereco/${endereco.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(endereco),
        enviarTokenUsuario: true,
      }),
    [invocarApi] 
  );
  return {
    ...outrasPropriedades,
    putEnderecoPessoa,
  };
}
