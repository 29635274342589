import { makeStyles } from '@material-ui/core';

const height = window.innerHeight;
export const useStyles = makeStyles((theme) => ({
  vendedorContent: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  vendedorText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
    '& b': {
      color: theme.palette.text.primary
    }
  },
  vendedorDivider: {
    height: 2,
    backgroundColor: theme.palette.grey[300]
  },
  listContainer: {
    '& .card': {
      margin: theme.spacing(1)
    }
  },
  definirTamanhoPedido: {
    height: `calc(${height}px - 220px)`,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: `calc(${height}px - 285px)`
    }
  },
  definirTamanhoVenda: {
    height: `calc(${height}px - 285px)`,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: `calc(${height}px - 337px)`
    }
  },
  definirTamanhoDelivery: {
    height: `calc(${height}px - 316px)`,
    [theme.breakpoints.down('xs')]: {
      height: `calc(${height}px - 446px)`
    }
  }
}));
