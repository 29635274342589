import { guidEmpty } from "utils/guid-empty";
import { HorarioDiaModel } from "./horario-dia-model";

export class HorarioModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public tipo: number = 0,
        public status: number = 0,
        public empresaHorarioDias: HorarioDiaModel[] = new Array<HorarioDiaModel>(),
    ) { }
}
