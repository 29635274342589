import { useCallback, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { CancelarIcon } from 'views/components/icons';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { DialogSelecionarSalao } from 'views/components/dialog/dialog-selecionar-salao/dialog-selecionar-salao';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { DialogScannAtendimento } from './components/dialog-scann-atendimento/dialog-scann-atendimento';
import { useConfirm } from 'material-ui-confirm';
import { DialogScannRFID } from '../../../../../../components/dialog/dialog-scann-rfid/dialog-scann-rfid';
import { ScannerIcon } from 'views/components/icons/scanner-icon';
import { CodigoBarrasIcon } from 'views/components/icons/codigo-barras-icon';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { useGetPedidoSalaoProcessado } from 'data/api/gestao/pedido/get-pedido-salao-processado';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useToastSaurus } from 'services/app';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';

export const AtendimentoHeader = () => {
  const history = useHistory();
  const { getConsumidor } = useCadastroPadrao();
  const { getMov } = useMovAtual();
  const { showToast } = useToastSaurus();
  const [dialogSalao, setDialogSalao] = useState<boolean>(false)
  const [dialogScanner, setDialogScanner] = useState<boolean>(false)
  const [dialogRFID, setDialogRFID] = useState<boolean>(false)
  const { getConfiguracoesMesaEComanda, searchComandas } = usePedidoLocal()
  const { getPedidoSalaoProcessado } = useGetPedidoSalaoProcessado();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { push } = useHistory();
  const confirm = useConfirm();

  const [erro, setErro] = useState<string>('')
  const { goToAdministrativo } = useOpenMovRota()

  const closeDialogScanner = useCallback(() => {
    setDialogScanner(false);
  }, []);

  const abrirScanner = useCallback((tipo: 'codigo' | 'nfc') => {
    switch (tipo) {
      case 'codigo':
        setDialogScanner(true)
        break;
      case 'nfc':
        setDialogRFID(true)
        break;
    }
  }, [])

  const onScannerClick = useCallback(async () => {
    const tipoTrabalhoComanda = getConfiguracoesMesaEComanda()?.tipoTrabalhoComanda;
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.HIBRIDO) {
      confirm({
        title: 'Escanear Comanda',
        description: 'Como deseja escanear a comanda?',
        confirmationText: <>
          <ScannerIcon tipo='BUTTON_PRIMARY' />
          Comanda RFID
        </>,
        cancellationText: <>
          <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
          Código de Barras
        </>,
        cancellationButtonProps: {
          variant: 'contained',
          color: 'primary'
        }
      }).then(() => abrirScanner('nfc'))
        .catch(() => abrirScanner('codigo'))

      return
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID) {
      abrirScanner('nfc')
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_CODIGO) {
      abrirScanner('codigo')
    }
  }, [abrirScanner, confirm, getConfiguracoesMesaEComanda])

  const handleCancel = useCallback(async () => {
    const consumidorPadrao = await getConsumidor();
    if (
      consumidorPadrao &&
      getMov()!.produtos.length <= 0 &&
      (consumidorPadrao.id === getMov()!.cliente?.id ||
        consumidorPadrao?.cpfcnpj === getMov()!.cliente?.cpfcnpj)
    ) {
      showToast('info', 'Não há produtos na venda.');
      return;
    }
    history.push('/venda-simples/cancelar');
  }, [getConsumidor, getMov, history, showToast]);

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={goToAdministrativo}
      />
    ), [goToAdministrativo],
  );

  const isAbaDelivery = useCallback(
    () => {
      const rotaAtual = '/venda-simples/atendimento/delivery'.indexOf(history.location.pathname);
      return rotaAtual > -1;
    },
    [history.location.pathname]
  );

  const rightArea = useCallback(
    () => (
      <Box display="flex">
        {!(
          VariaveisAmbiente.paymentDevice === EnumDeviceType.MERCADOPAGO
          || VariaveisAmbiente.paymentDevice === EnumDeviceType.MERCADOPAGO_DEV
        )
          && !isAbaDelivery()
          && (
            <ButtonPrivateHeader
              icon={<BarcodeScanIcon tipo="PRIVATE_HEADER" />}
              tooltip="Abrir leitor"
              onClick={
                () =>
                  getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA ?
                    setDialogSalao(true) :
                    onScannerClick()
              }
            ></ButtonPrivateHeader>
          )}
        <ButtonPrivateHeader
          buttonCancel
          icon={<CancelarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Cancelar venda"
          onClick={handleCancel}
        ></ButtonPrivateHeader>
      </Box>
    ),
    [getConfiguracoesMesaEComanda, handleCancel, isAbaDelivery, onScannerClick],
  );

  const confirmarComanda = useCallback(
    async (rfid: string) => {
      setErro('')
      try {
        const comandas = await searchComandas(rfid, 'rfId');

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o RFID: ${rfid}`,
          );
        }

        const pedidos = await getPedidoSalaoProcessado(getEmpresaSelecionada()?.Id!, `comandaId=${comandas[0]?.id ?? ''}`);

        let comandaComPedido: string = "";
        pedidos.resultado?.data?.list?.forEach((item: any) => {
          item.pedidos.forEach((valor: any) => {
            comandaComPedido = valor.comandaId;
          })
        })

        if (isEmpty(comandaComPedido) || comandaComPedido !== comandas[0].id) {
          throw new Error(`A Comanda ${rfid} não possui um pedido.`)
        }

        else {
          push(`/venda-simples/visualizar-pedido/${comandas[0].id}`)
        }

      } catch (err: any) {
        setErro(err.message)
      }
    },
    [getEmpresaSelecionada, getPedidoSalaoProcessado, push, searchComandas],
  );

  return (
    <>
      <PrivatePageHeader
        title="Atendimento"
        leftArea={leftArea()}
        rightArea={rightArea()}
      />
      {dialogSalao && (
        <DialogSelecionarSalao
          aberto={dialogSalao}
          close={() => setDialogSalao(false)}
        />
      )}

      {dialogScanner && (
        <DialogScannAtendimento
          openned={dialogScanner}
          closeModal={closeDialogScanner}
        />
      )}

      {dialogRFID && (
        <DialogScannRFID
          closeDialog={() => setDialogRFID(false)}
          openned={dialogRFID}
          confirmarComandaRFID={confirmarComanda}
          erro={erro}
        />
      )}
    </>
  );
};
