import { useCallback } from "react";

import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { NcmHeader } from "./components/ncm-header/ncm-header";

import { NcmList } from "./components/ncm-list/ncm-list";
import { ButtonFab } from "views/components/controles";
import { Grid } from "@material-ui/core";
import { NcmAddIcon } from '../../../../components/icons/ncm-add-icon';
import { useCadastros } from "services/app";

export const NcmPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirCadastroNcm} = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroNcm("", "", true);
  }, [abrirCadastroNcm]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <NcmHeader />
      </Grid>
      <Grid className={classes.list}>
        <NcmList />
      </Grid>
      <ButtonFab
        tooltip="Adicionar NCM"
        icon={<NcmAddIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default NcmPage;
