import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogMovFechamento } from 'views/components/dialog/dialog-mov-fechamento/dialog-mov-fechamento';

class DialogMovFechamentoProps {
  constructor(
    public aberto: boolean = false,
    public handleFechamento: () => Promise<void> = () => Promise.resolve(),
    public handleImportar: () => Promise<void> = () => Promise.resolve()
  ) {}
}

export const MovFechamentoDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogMovFechamentoProps>(
    new DialogMovFechamentoProps()
  );

  consoleDev('MovFechamento');

  const modalAlterado = useCallback(
    ({ aberto, handleFechamento, handleImportar }: any) => {
      setModalState({
        aberto,
        handleFechamento,
        handleImportar
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovFechamento, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.MovFechamento, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogMovFechamento
          openned={modalState.aberto}
          handleFechamento={modalState.handleFechamento}
          handleImportar={modalState.handleImportar}
        />
      )}
    </>
  );
};
