// import { VariaveisAmbiente } from "config";
// import { EnumDeviceType } from "model/enums/enum-device-type";
import { Route, RouteProps } from "react-router-dom";

export const LandingRoute: React.FC<RouteProps> = ({
    children,
    ...otherProps
}
) => {
    return <Route {...otherProps}>{children}</Route>
};
