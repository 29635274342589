import { Grid } from "@material-ui/core";
import { useDefaultCadastroStyles } from "../../../cadastros/components/default-cadastro-styles";
import { GerarComandaHeader } from "./components/gerar-comanda-header/gerar-comanda-header";
import { GerarComandaCadastro } from "./components/gerar-comanda-cadastro/gerar-comanda-cadastro";
import { usePedidoLocal } from "services/app/hooks/pedido-local";
import { EnumTipoTrabalho } from "model/enums/enum-tipo-trabalho";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const MovGerarComandaPage = () => {
  const classes = useDefaultCadastroStyles();
  const history = useHistory()

  const { getConfiguracoesMesaEComanda } = usePedidoLocal();

  useEffect(() => {
    if (getConfiguracoesMesaEComanda()?.tipoTrabalho !== EnumTipoTrabalho.COMANDA) {
      history.push('/venda-simples/landing')
    }
  })

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <GerarComandaHeader title={"Registrar Entrada"} />
      </Grid>
      <Grid className={classes.list}>
        <GerarComandaCadastro tipoTrabalho={getConfiguracoesMesaEComanda()?.tipoTrabalho || EnumTipoTrabalho.COMANDA} />
      </Grid>
    </Grid>
  );
};

export default MovGerarComandaPage;
