import { VendaCompletaModel, VolTranspModel } from "model/api/gestao/venda/venda-completa-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { VolumeTransportadoModal } from "views/components/modals/volume-transportado/volume-transportado-modal";

export const CadastroVolumeTransportadoModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        volume: new VolTranspModel(),
        openned: false,
        mov: new VendaCompletaModel()
    });

    consoleDev('CadastroVolumeTransportadoModal')

    const modalAlterado = useCallback(({ volume, openned, mov }: any) => {
        setModalState({
            volume,
            openned,
            mov
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.VolumeTransportadoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.VolumeTransportadoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <VolumeTransportadoModal openned={modalState.openned} id="" key="mdlVolumeTransportado" volume={modalState.volume} mov={modalState.mov} />
    )
}