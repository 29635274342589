import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    header: {
        flex: "0 1 auto"
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    containerLoader: {
        display: 'flex',
        justifyContent: 'center',
        padding: 32
    },
    content: {
        display: "flex",
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(1)
    },
    infoContainer: {
        display: "flex",
        flexDirection: 'column',
        maxWidth: 650,
        flex: 1
    },
    textTitle: {
        fontWeight: 700,
        lineHeight: "42px",
        fontSize: "1.5rem",
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    textSubtitle: {
        textAlign: 'center',
        padding: 16,
        color: '#8D8D8D',
        lineHeight: '23px'
    },
    textASincronizar: {
        fontWeight: 600,
        lineHeight: "35px",
        fontSize: "1.1rem",
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    containerSincAtual: {
        display: 'flex',
        width: "100%",
        height: '60px',
        maxWidth: '700px',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F3F3F3',
        borderRadius: '9.87578px',
        marginBottom: theme.spacing(2),
    },
    textInfo: {
        fontWeight: 600,
        lineHeight: "25px",
        fontSize: "1.1rem",
    },
    button: {
        height: '52px',
    }
}));