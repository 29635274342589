import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        background: '#FFF',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    containerAcoes: {
        padding: 0,
    },
    marginTitle: {
        marginTop: theme.spacing(3)
    },
    text: {
        fontWeight: 700
    },
    divider:{
        backgroundColor: theme.palette.secondary.main
    },
   
}))