import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { MenuIcon } from 'views/components/icons';
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from 'views';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';

export interface MesasComandasHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: any) => any;
}

export const MesasComandasHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const {
    plano
  } = useSessaoAtual();
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu],
  );

  const pesquisa = () => null;
  return (
    <>
      <PrivatePageHeader
        title={`${isFarmaceutico ? "Comandas": "Mesas e Comandas"}`}
        leftArea={leftArea()}
        bottomArea={pesquisa}
      />
    </>
  );
};
