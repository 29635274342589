
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: theme.spacing(2),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(3)
    },
    button: {
        width: 320,
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    marginIcon: {
        marginRight: theme.spacing(1)
    }
}));