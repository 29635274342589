import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const CorrigirIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path d="M37.5903 16.1396C37.5903 15.6977 37.4278 15.3954 37.126 15.0931C32.9246 10.907 28.7231 6.72095 24.5449 2.53489C24.1735 2.16279 23.8021 2 23.2682 2C17.1401 2.02326 11.012 2.02326 4.88396 2C4.16437 2 3.97867 2.16279 4.00188 2.88373C4.02509 10.0233 4.02509 17.1629 4.02509 24.3257C4.02509 31.5118 4.02509 38.6746 4.02509 45.8607C4.02509 46.4421 4.07152 46.6979 4.7911 46.6979C9.66572 46.6514 14.5635 46.6747 19.4382 46.6747C19.6471 46.6747 19.856 46.6746 20.0417 46.6514C20.5059 46.5816 20.7613 46.3026 20.7845 45.8374C20.8077 45.3491 20.5988 44.977 20.0881 44.8839C19.7863 44.8142 19.4614 44.8142 19.1364 44.8142C14.9814 44.8142 10.8263 44.7909 6.6481 44.8374C5.99815 44.8374 5.83566 44.6746 5.83566 44.0235C5.85888 30.9071 5.85888 17.814 5.83566 4.69769C5.83566 3.9535 6.11421 3.88373 6.71774 3.88373C11.7548 3.90699 16.7687 3.90699 21.8058 3.88373C22.5718 3.88373 22.6879 4.11629 22.6647 4.79071C22.6415 8.55817 22.6647 12.3256 22.6415 16.0698C22.6415 16.721 22.7575 16.9536 23.4771 16.9303C26.9822 16.8838 30.4641 16.9071 33.9691 16.9071C35.7101 16.9071 35.7101 16.9071 35.7101 18.628C35.7101 21.3257 35.7101 24.0001 35.7101 26.6978C35.7101 27.628 36.0351 28.0699 36.685 28.0234C37.451 27.9769 37.5671 27.4187 37.5671 26.7908C37.5671 23.2559 37.5671 19.6978 37.5903 16.1396ZM25.0788 15.0466C24.7538 15.0466 24.5449 15.0233 24.5449 14.6047C24.5681 11.5349 24.5449 8.48841 24.5449 5.41862C27.725 8.6512 30.8819 11.8838 34.0156 15.0466C31.1372 15.0466 28.0964 15.0466 25.0788 15.0466Z" />
                <path d="M44.7395 25.5815C43.2307 24.9071 41.8147 25.0932 40.538 26.1862C40.0506 26.6048 39.656 27.1164 39.2149 27.5583C34.7349 32.0234 30.2549 36.5118 25.7749 40.977C25.4964 41.256 25.1714 41.5118 25.0553 41.9305C24.5679 43.7212 24.034 45.4886 23.5697 47.2793C23.3376 48.2096 23.9179 48.7212 24.8464 48.4886C26.5177 48.07 28.189 47.6049 29.8603 47.1863C30.2549 47.0933 30.6031 46.884 30.9049 46.6049C35.6866 41.8607 40.4684 37.1397 45.2502 32.3955C46.1554 31.4885 46.9447 30.4885 46.9215 29.0699C46.8518 27.4653 46.2019 26.2327 44.7395 25.5815ZM43.486 31.4885C38.9828 35.9537 34.4564 40.4421 29.9532 44.9072C29.6746 45.1863 29.5121 45.2793 29.2104 44.9537C28.5604 44.256 27.864 43.6049 27.1677 42.9537C26.8659 42.6746 26.8659 42.5118 27.1677 42.2095C31.6709 37.7211 36.1509 33.2327 40.6309 28.7443C40.9559 28.4188 41.1648 28.3257 41.513 28.7211C42.1397 29.3955 42.8129 30.0234 43.4628 30.6978C43.5789 30.8141 43.6949 30.9537 43.8342 31.0932C43.7181 31.2327 43.6021 31.3723 43.486 31.4885Z" />
            </DefaultIcon>
        </>
    );
};
