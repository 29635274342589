import { VariaveisAmbiente } from "config";
import { useApiBase } from "data/api/base";
import { MovRegistrarModel } from "model/api/gestao/mov-pag/mov-registrar";
import { useCallback } from "react";

export function usePostMovRegistrar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postMovRegistrar = useCallback(
        (empresaId: string, data: MovRegistrarModel) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/pagamento/registrar`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        postMovRegistrar,
    };
}