import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    containerRoot: {
        cursor: 'default',
    },
    containerIcon: {
        display: 'flex',
        alignItems: 'center',
    }
}))