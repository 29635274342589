import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutosCatalogo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutosCatalogo = useCallback(
    (queryBusca: string, empresaId: string, page?: number) => {
      let queryPage = '';
      if (page) {
        queryPage = 'page=' + page.toString();
      }
      let queryFinal = `${
        queryPage?.length > 0 || queryBusca?.length > 0 ? '?' : ''
      }${queryPage}${queryPage.length > 0 ? '&' : ''}${queryBusca}`;

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/produto/catalogo/${queryFinal}`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutosCatalogo
  };
}
