import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "normal",
    },
    card: {
        padding: '4px 8px',
        background: '#D9EEFB',
        boxShadow: 'none',
        color: theme.palette.primary.main
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center'
    },
    moduloNome: {
        marginRight: 4,
        fontWeight: 600
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));
