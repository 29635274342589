import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { PagsModel } from 'model/api/gestao/venda/venda-completa-model';
import { StatusPagamentoMov } from 'model/enums/enum-status-pagamento';
import { ProdsXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';

export default function FormEntradaCadastroPagamentos(): React.ReactElement {
  const {
    control,
    getValues,
    formState: { errors, touchedFields }
  } = useFormContext();

  function checkImpostoExiste(text: string): number {
    return getValues(text) ?? 0;
  }

  const impostoICMS = checkImpostoExiste('infMov.total.vICMS');
  const impostoFCP = checkImpostoExiste('infMov.total.vFCP');
  const impostoST = checkImpostoExiste('infMov.total.vST');
  const impostoFCPST = checkImpostoExiste('infMov.total.vFCPST');
  const impostoFCPSTRet = checkImpostoExiste('infMov.total.vFCPSTRet');
  const impostoII = checkImpostoExiste('infMov.total.vII');
  const impostoIPI = checkImpostoExiste('infMov.total.vIPI');
  const impostoIPIDevol = checkImpostoExiste('infMov.total.vIPIDevol');
  const impostoPIS = checkImpostoExiste('infMov.total.vPIS');
  const impostoCOFINS = checkImpostoExiste('infMov.total.vCOFINS');
  const impostoICMSDeson = checkImpostoExiste('infMov.total.vICMSDeson');
  const impostoFCPUFDest = checkImpostoExiste('infMov.total.vFCPUFDest');
  const impostoICMSUFDest = checkImpostoExiste('infMov.total.vICMSUFDest');
  const impostoICMSUFRemet = checkImpostoExiste('infMov.total.vICMSUFRemet');

  const valorTotalImpostos =
    impostoICMS +
    impostoICMSDeson +
    impostoFCPUFDest +
    impostoICMSUFDest +
    impostoICMSUFRemet +
    impostoFCP +
    impostoST +
    impostoFCPST +
    impostoFCPSTRet +
    impostoII +
    impostoIPI +
    impostoIPIDevol +
    impostoPIS +
    impostoCOFINS;

  const valorTotalDesconto = (getValues('infMov.prod') as ProdsXMLModel[]).reduce((prev, curr) => {
    return prev + curr.vDesc
  }, 0)

  const pags = getValues('infMov.pag') as PagsModel[];
  const valorPago = pags.filter(x => x.status === StatusPagamentoMov.Confirmado).reduce<number>((prev, curr) => curr.vPag + prev, 0)

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="infMov.total.vProd"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Valor dos Produtos"
              readOnly
              showStartAdornment
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.numero?.message)}
              helperText={
                touchedFields.valorProdutos || errors.valorProdutos
                  ? errors.valorProdutos?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldSaurus
          tipo="DECIMAL"
          fullWidth
          variant="outlined"
          label="(-) Valor de Desconto"
          showStartAdornment
          readOnly
          allowSubmit
          InputLabelProps={{
            shrink: true
          }}
          placeholder=""
          error={Boolean(errors?.numero?.message)}
          helperText={
            touchedFields.valorDesconto || errors.valorDesconto
              ? errors.valorDesconto?.message
              : undefined
          }
          value={valorTotalDesconto}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.total.vOutro"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Outros Valores"
              showStartAdornment
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.outrosValores?.message)}
              helperText={
                touchedFields.outrosValores || errors.outrosValores
                  ? errors.outrosValores?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldSaurus
          tipo="DECIMAL"
          fullWidth
          variant="outlined"
          label="Valor Impostos"
          showStartAdornment
          readOnly
          allowSubmit
          InputLabelProps={{
            shrink: true
          }}
          placeholder=""
          value={valorTotalImpostos}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.total.vSeg"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Valor Seguro"
              showStartAdornment
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.valorSeguro?.message)}
              helperText={
                touchedFields.valorSeguro || errors.valorSeguro
                  ? errors.valorSeguro?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.total.vFrete"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Valor Frete"
              showStartAdornment
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.valorFrete?.message)}
              helperText={
                touchedFields.valorFrete || errors.valorFrete
                  ? errors.valorFrete?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.total.vnf"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DECIMAL"
              fullWidth
              variant="outlined"
              label="Valor Total"
              showStartAdornment
              readOnly
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.valorTotal?.message)}
              helperText={
                touchedFields.valorTotal || errors.valorTotal
                  ? errors.valorTotal?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldSaurus
          tipo="DECIMAL"
          fullWidth
          variant="outlined"
          label="Valor Pago"
          showStartAdornment
          readOnly
          allowSubmit
          InputLabelProps={{
            shrink: true
          }}
          placeholder=""
          value={valorPago}
        />
      </Grid>
    </>
  );
}
