import { guidEmpty } from "../../../../utils/guid-empty";

export class NcmCadastroFormModel {
  constructor(
    public ncmId: string = guidEmpty(),
    public codigo: string = "",
    public cest: string = "",
    public descricao: string = ""
  ) {}
}
