import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { CardProdutoFiscal } from '../../../../../../components/cards/card-produto-fiscal/card-produto-fiscal';
import { ProdutoFiscalModel } from "model/api/gestao/produto/produto-fiscal/produto-fiscal-model";

export interface ImpostoGridProps {
    list: Array<ProdutoFiscalModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const ImpostoListData = ((props: ImpostoGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhum Grupo de Impostos encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((Imposto, index) => {
                return (
                    <CardProdutoFiscal
                        selected={
                            props.selectedList.filter((item) => item === Imposto.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={Imposto}
                        key={index}
                    />

                );
            })}

        </>
    );
}
);
