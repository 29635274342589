import { guidEmpty } from "./guid-empty"

export const removeGuidEmpty = (obj: any, setNull?: boolean) => {
    let objCopy = {...obj};
    Object.entries(obj).forEach(([key, value]) => {
        if(value === guidEmpty()){
            objCopy = {
                ...objCopy,
                [key]: setNull ? null : ''
            }
        }
    })

    return objCopy
}