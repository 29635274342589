import { KeyValueModel } from "model/api";
import { EnumStatusPedido } from "model/enums/enum-status-pedido";

export const StatusPedidoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusPedido.AGUARDANDO_CONFIRMACAO, "Aguar. Confirmação"),
    new KeyValueModel(EnumStatusPedido.CANCELADO, "Cancelado"),
    new KeyValueModel(EnumStatusPedido.CONFIRMADO, "Confirmado"),
    new KeyValueModel(EnumStatusPedido.EM_PREPARO, "Em preparo"),
    new KeyValueModel(EnumStatusPedido.ENVIADO, "Enviado"),
    new KeyValueModel(EnumStatusPedido.FECHADO, "Fechado"),
    new KeyValueModel(EnumStatusPedido.FINALIZADOOUENTREGUE, "Finalizado"),
    new KeyValueModel(EnumStatusPedido.IMPORTADO, "Importado"),
];
