import { LocalErroEnum } from "data/api/gestao/venda/post-validar-entrada-xml";
import { KeyValueModel } from "model";

export const ValidacaoXMLMock: KeyValueModel[] = [
    new KeyValueModel(LocalErroEnum.Ambiente, "Ambiente"),
    new KeyValueModel(LocalErroEnum.Destinatario, "Destinatário"),
    new KeyValueModel(LocalErroEnum.Emitente, "Emitente"),
    new KeyValueModel(LocalErroEnum.EmpresaLogada, "Empresa"),
    new KeyValueModel(LocalErroEnum.NotaImportada, "Nota Importada"),
    new KeyValueModel(LocalErroEnum.Produto, "Produto"),
    new KeyValueModel(LocalErroEnum.Transportadora, "Transportadora"),
];