import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-generico-pesavel-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { TextFieldSaurus } from '../../controles/inputs';
import { useCallback, useEffect, useRef } from 'react';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useCadastros } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Controller, useForm } from 'react-hook-form';
import { useDialogProdutoGenericoPesavelValidation } from './dialog-produto-generico-pesavel-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProdutoGenericoPesavelFormModel } from 'model/app/forms/movimentacao/produto-generico-pesavel-form-model';
import { isEmpty } from 'lodash';


export interface DialogAdicionarProdutoGenericoPesavelProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
}

export const DialogAdicionarProdutoGenericoPesavel = ({
  aberto,
  produto,
}: DialogAdicionarProdutoGenericoPesavelProps) => {
  const refInputXProd = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { carregando, adicionarProdutoGenericoPesavel } = useMovProd()
  const { fecharAdicionarProdutoGenericoPesavel } = useCadastros()
  const { callEvent } = useEventTools()
  const refInputQCom = useRef<HTMLInputElement>(null);
  const valorEscolhido = useRef<'qtd' | 'valor' | 'valorFinal'>()


  useEffect(() => {
    if (aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, callEvent, produto])

  const { DialogProdutoGenericoPesavelValidation } = useDialogProdutoGenericoPesavelValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    setError,
  } = useForm<ProdutoGenericoPesavelFormModel>({
    defaultValues: { ...new ProdutoGenericoPesavelFormModel() },
    resolver: yupResolver(DialogProdutoGenericoPesavelValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
        setTimeout(() => {
          refInputXProd.current?.focus();
        }, 50);
    
    // setError(false);
  }, [aberto, isMobile, produto, setValue]);

  const onSubmit = async (produtoPesavel: ProdutoGenericoPesavelFormModel) => {
   
    if (isEmpty(produtoPesavel.qCom) || toDecimal(produtoPesavel.qCom) === 0) {
      setError('qCom', {
        message: 'Informe a quantidade do produto'
      })
      return;
      
    } else if(isEmpty(produtoPesavel.vUnCom) || toDecimal(produtoPesavel.vUnCom) === 0){
      setError('vUnCom', {
        message: 'Informe o valor do produto'
        
      })
      return;
    }
   

      if (valorEscolhido.current === 'qtd') //VERIFICAR SE FOI FEITO VALOR OU QTDE
      {
        produto!.qCom = toDecimal(produtoPesavel.qCom, 10);
      } else {
        produto!.qCom = 0;
      }


      if(isEmpty(produtoPesavel.xProd)){
        produto!.xProd = 'Produto Pesável'
      } else{ 
        produto!.xProd = produtoPesavel.xProd;
      }
      
      produto!.vUnCom = toDecimal(produtoPesavel.vUnCom, 3);
      produto!.vFinal = toDecimal(produtoPesavel.vFinal, 2);
      produto!.vProd = toDecimal(produtoPesavel.vFinal, 2);

    

    await adicionarProdutoGenericoPesavel(produto!)
    fecharAdicionarProdutoGenericoPesavel()

  };

  const handleChangeQCom = useCallback(
    (qCom: string | undefined, vFinal: string | undefined, vUnCom: string | undefined) => {
      if (vUnCom !== undefined){
        valorEscolhido.current = 'valorFinal'
        setValue('vUnCom', vUnCom)
      }
  
      if (qCom !== undefined) {
        valorEscolhido.current = 'qtd'
        setValue('qCom', qCom);
        setValue('vFinal', toDecimalString(toDecimal(qCom, 4) * toDecimal(vUnCom, 2)));
        return
      }

      if (vFinal !== undefined) {
        valorEscolhido.current = 'valor'
        setValue('vFinal', vFinal);
        setValue('qCom', toDecimalString((toDecimal(vFinal,2) / toDecimal(vUnCom, 3)),3));
      }
    },
    [setValue],
  );


  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Produto Genérico Pesavel"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name='xProd'
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      disabled={carregando}
                      fullWidth
                      inputRef={refInputXProd}
                      size="medium"
                      variant="outlined"
                      label="Descrição do Produto"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      error={Boolean(
                        errors.xProd && errors.xProd.message,
                      )}
                      helperText={
                        touchedFields.xProd || errors.xProd
                          ? errors.xProd?.message
                          : undefined
                      }
                      placeholder={produto!.xProd}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="vUnCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      casasDecimais={2}
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Valor de Venda"
                      max={8}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={12}
                      {...field}
                      error={Boolean(
                        errors.vUnCom && errors.vUnCom.message,
                      )}
                      helperText={
                        touchedFields.vUnCom || errors.vUnCom
                          ? errors.vUnCom?.message
                          : undefined
                      }
                      placeholder={toDecimalString(produto!.vUnCom, 2)}
                      onChange={(e) => handleChangeQCom(getValues('qCom'), undefined, e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="qCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputQCom}
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth

                      variant="outlined"
                      label="Quantidade"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                      casasDecimais={3}
                      allowSubmit
                      maxTexto={12}
                      error={Boolean(
                        errors.qCom && errors.qCom.message,
                      )}
                      helperText={
                        touchedFields.qCom || errors.qCom
                          ? errors.qCom?.message
                          : undefined
                      }
                      placeholder={toDecimalString(produto!.qCom, 3)}
                      onChange={(e) => {
                        let valor = e.target.value
                        if(valor.startsWith(',')){
                         valor = `0${valor}`
                        }
                        handleChangeQCom(valor, undefined, getValues('vUnCom'))}}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>

                <Controller
                  control={control}
                  name="vFinal"
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputQCom}
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Valor Total"
                      maxTexto={12}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      allowSubmit
                      {...field}
                      error={Boolean(
                        errors.vFinal && errors.vFinal.message,
                      )}
                      helperText={
                        touchedFields.vFinal || errors.vFinal
                          ? errors.vFinal?.message
                          : undefined
                      }
                      placeholder={toDecimalString(produto!.vFinal, 2)}
                      onChange={(e) => handleChangeQCom(undefined, e.target.value, getValues('vUnCom'))}
                    />
                  )}
                />
              </Grid>


              <Grid item xs={4}>
                {fecharAdicionarProdutoGenericoPesavel && (
                  <Button
                    disabled={carregando}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      fecharAdicionarProdutoGenericoPesavel();
                    }}
                    className='round'
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  className='round'
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  {'Confirmar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
