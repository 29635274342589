import { EnumBalcaoSalao } from "model/enums/enum-balcao-salao";
import { EnumStatusSalao } from "model/enums/enum-status-salao";

export class ConfigInicialMesasEcomandasModel {
    constructor(
        public descricaoBalcao: string = "Salão Principal",
        public statusSalao: EnumStatusSalao = EnumStatusSalao.ATIVO,
        public balcao: EnumBalcaoSalao | null = null,
        public mesaInicial: number | null = null,
        public mesaFinal: number | null = null,
        public prefixoMesa: string | null = null,
        public comandaInicial: number | null = null,
        public comandaFinal: number | null = null,
        public prefixoRfId: string = '',
        public inicialRfId: number = 0,
        public finalRfId: number = 0,
        public mesaRequired: boolean = true,
        public comandaRequired: boolean = true
    ){}
}