export class DadosAnexo {
    constructor(
        public nomeArquivo?: string,
        public anexo?: string,
        public extensao?: string,
    ) { }
}

export class EnviarSMSModel {
    constructor(
        public telefoneDestinatario: string = '',
        public mensagem: string = '',
        public dadosAnexo: DadosAnexo = {}
    ) { }
}
