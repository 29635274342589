import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    button: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& svg': {
            [theme.breakpoints.down('sm')]: {
                marginRight: 0
            }
        }
    }
}));