import { handleClickFeedbackTatil } from './feedback-tatil-fn';

export function stopPropagationWithFeedback(event: any) {
  if (event.stopPropagation) {
    event.stopPropagation();
  } else {
    event.cancelBubble = true;
  }

  handleClickFeedbackTatil();
}
