import { useCallback } from "react"
import { useHistory } from "react-router"
import { EnumMenu } from "model"
import { useToastSaurus } from "./toast-saurus"
import { useMovAtual } from "./mov-atual"
import { useCadastros } from "./cadastros"
import { usePDV } from "./pdv"
import { useMenuPrincipal } from "./menu-principal"
import { EnumMenuPrincipalModo } from "model/enums/enum-menu-principal-modo"
import { useSessaoAtual } from "../providers"
import { useMovAnteriorStorage } from "./mov-anterior-storage"
import { isEmpty } from "lodash"

export const useOpenMovRota = () => {

    const history = useHistory()
    const { showToast } = useToastSaurus()
    const { getMov, retornaFluxoVenda, verificaProdutosControlados, naoInformarDadosDaReceita, carregando } = useMovAtual()
    const { get: getMovAnt } = useMovAnteriorStorage()
    const { getPDV } = usePDV()
    const { abrirCadastroPontosVenda, abrirSolicitarPermissao, abrirDialogControlado } = useCadastros()
    const { alterarModo } = useMenuPrincipal()
    const { getPermissaoBoolean } = useSessaoAtual()

    const openMovRota = useCallback((url: string) => {
        if (getMov()) {
            history.push(url)
            return
        }

        history.push({
            pathname: '/venda-simples/landing',
            search: `?url=${url}`
        })
    }, [getMov, history])

    const actionMenuPDV = useCallback(async (codigoModulo: EnumMenu, path: string) => {
        const mov = getMov()
        switch (codigoModulo) {
            case EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA:
            case EnumMenu.OPERACIONAL_DENTROVENDA_PAGAMENTO:
                const medicamentos = await verificaProdutosControlados();
                if (medicamentos && medicamentos.length > 0) {
                    const qtd = medicamentos.reduce((acc, current) => acc + current.qCom, 0);
                    abrirDialogControlado(qtd, naoInformarDadosDaReceita, async () => await actionMenuPDV(codigoModulo, path))
                    return
                }

                if ((getMov()?.produtos.filter((produto) => {
                    return produto.ativo
                }) ?? []).length <= 0) {
                    showToast('info', 'Não há produtos na venda.')
                    break;
                }
                const retorno = await retornaFluxoVenda()
                openMovRota(retorno.url)
                break;
            case EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR:
                openMovRota(path);
                break;
            case EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV:
                abrirCadastroPontosVenda(getPDV()?.id ?? '', history.location.pathname, false);
                break;
            case EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA:
                const ultimaVenda = getMovAnt();
                if (!ultimaVenda) {
                    showToast('info', 'Nenhuma venda foi localizada. Realize vendas para habilitar este módulo.')
                    break;
                }
                openMovRota(path);
                break;
            case EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA:
                alterarModo(EnumMenuPrincipalModo.Retaguarda);
                openMovRota(path);
                break;
            case EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY:
                if (mov && mov.produtos.filter(p => !isEmpty(p.pedidoId)).length > 0) {
                    showToast('info', 'Não pode entrar no modo delivery se estiver com pedido no carrinho.')
                    break;
                }
                openMovRota(path);
                break;
            default:
                openMovRota(path)
        }
    }, [abrirCadastroPontosVenda, abrirDialogControlado, alterarModo, getMov, getMovAnt, getPDV, history.location.pathname, naoInformarDadosDaReceita, openMovRota, retornaFluxoVenda, showToast, verificaProdutosControlados])

    const goToAdministrativo = useCallback(() => {
        if (!getPermissaoBoolean(5004)) {
            abrirSolicitarPermissao(
                async () => {
                    history.push('/venda-simples/administrativo');
                },
                5004,
                'ir a central administrativa'
            );
            return;
        }
        history.push('/venda-simples/administrativo');
    }, [abrirSolicitarPermissao, getPermissaoBoolean, history])

    return {
        openMovRota,
        actionMenuPDV,
        goToAdministrativo,
        carregando
    }
}