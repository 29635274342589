import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetPadroesContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPadroesContrato = useCallback(
        (contratoId: string, codigo: string = '326') => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Contrato/${contratoId}/configuracao/${codigo}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPadroesContrato,
    };
}