import { Grid } from '@material-ui/core';
import { useStyles } from './card-balcao-selecionar-styles';
import { useThemeQueries } from 'views';
import { CardBalcaoSelecionarProps } from './card-balcao-selecionar-props';
import { memo } from 'react';
import { BalcaoIcon } from 'views/components/icons/balcao-icon';

export const CardBalcaoSelecionar = ({
  model,
  onClick
}: CardBalcaoSelecionarProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  return (
    <Grid
      onClick={() => onClick(model)}
      className={classes.cardContainer}
      style={{
        borderStyle: 'dashed',
        borderColor: '#ECEDED'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <BalcaoIcon
          tipo="INPUT"
          fill={theme.palette.primary.main}
          style={{ marginRight: 0 }}
        />
        <p className={classes.balcao}>Balcão</p>
      </div>
    </Grid>
  );
};

export default memo(CardBalcaoSelecionar);
