import { Grid } from '@material-ui/core';
import { LeituraDeCaixaBody } from './components/leitura-de-caixa-body/leitura-de-caixa-body';
import { SessaoLeituraCaixaHeader } from './components/sessao-leitura-caixa-header/sessao-leitura-caixa-header';
import { useStyles } from './sessao-leitura-caixa-page-styles';

const SessaoLeituraCaixaPage = () => {
  // AUX
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.header}>
          <SessaoLeituraCaixaHeader />
        </Grid>
        <Grid className={classes.content}>
          <Grid style={{flex: 1 }}>
            <LeituraDeCaixaBody />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { SessaoLeituraCaixaPage };
