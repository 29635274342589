
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormConfiguracoesCardapioValidation = () => {

  const formConfiguracoesCardapioValidation = useMemo(() => {
    return Yup.object().shape({
        serie: Yup.number().test({
          message: 'Série deve ser maior do que 0',
          test: (value, context) => {
              if(context.parent.disponibilizarPagamentoFinalizacao) {
                if((value ?? 0) > 0) {
                  return true
                }
                return false
              }
              return true
          }
      }).required('Série é obrigatório.'),
    });
  }, [])

  return {
    formConfiguracoesCardapioValidation,
  }
}
