import { makeStyles } from "@material-ui/core";

export const useStyles = () => {

  const heightWin = window.innerHeight

  const styles = makeStyles((theme) => ({
    background: {
      background: "linear-gradient(335.37deg, rgba(246, 248, 250, 0.6) - 3.52 %, rgba(252, 251, 252, 0.6) 117.74 %)",
    },
    defaultContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden"
    },
    listContainer: {
      margin: 0
    },
    categoriaSemProdutos: {
      whiteSpace: 'normal',
      width: '100%'
    },
    definirTamanhoVazio: {
      [theme.breakpoints.down('sm')]: {
        height: `calc(${heightWin}px - 215px)`,
      }
    },
    listContent: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
      },
      gridGap: 4,
      justifyContent: 'space-evenly'
    }
  }))

  return styles()
};