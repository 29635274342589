import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    iconeCima: {
        transform: 'rotate(-90deg)',
        '& svg': {
            margin: 0,
            width: 25,
            height: 25,
        },
        display: 'flex',
        cursor: 'pointer',
    },
    iconeBaixo: {
        transform: 'rotate(90deg)',
        '& svg': {
            margin: 0,
            width: 25,
            height: 25,
        },
        display: 'flex',
        cursor: 'pointer',
    },
    botoesOrdem: {
        position: 'absolute',
        zIndex: 100,
        left: 16,
        top: 10
    },
    someDiv: {
        visibility: 'hidden'
    },
    espacamentoOrdem: {
        marginLeft: theme.spacing(6)
    }
}))