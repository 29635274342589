import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ReceitaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 10 7 C 6.1463017 7 3 10.146302 3 14 C 3 17.853698 6.1463017 21 10 21 C 13.853698 21 17 17.853698 17 14 C 17 10.146302 13.853698 7 10 7 z M 36 7 L 36 9 L 43.740234 9 L 35.964844 17.550781 L 32 13.585938 L 21 24.585938 L 17.027344 20.613281 L 3.3222656 33.265625 L 4.6777344 34.734375 L 16.972656 23.386719 L 21 27.414062 L 32 16.414062 L 36.035156 20.449219 L 45 10.585938 L 45 18 L 47 18 L 47 7 L 36 7 z M 10 9 C 12.772302 9 15 11.227698 15 14 C 15 16.772302 12.772302 19 10 19 C 7.2276983 19 5 16.772302 5 14 C 5 11.227698 7.2276983 9 10 9 z M 9.4902344 11.283203 L 7.6796875 13.09375 L 9.09375 13.09375 L 9.09375 16.716797 L 10.90625 16.716797 L 10.90625 11.283203 L 9.4902344 11.283203 z M 39 20 L 39 44 L 41 44 L 41 20 L 39 20 z M 45 22 L 45 44 L 47 44 L 47 22 L 45 22 z M 27 25 L 27 44 L 29 44 L 29 25 L 27 25 z M 33 25 L 33 44 L 35 44 L 35 25 L 33 25 z M 15 30 L 15 44 L 17 44 L 17 30 L 15 30 z M 21 31 L 21 44 L 23 44 L 23 31 L 21 31 z M 9 35 L 9 44 L 11 44 L 11 35 L 9 35 z M 3 39 L 3 44 L 5 44 L 5 39 L 3 39 z"></path>
      </DefaultIcon>
    </>
  );
};
