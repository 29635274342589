import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
        filter: 'grayscale(1)',
    },
    containerInternoCard: {
        padding: '8px',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    },
    containerInformacoes: {
        flex: '1',
        position: 'relative',
    },
    btnInativar: {
        color: theme.palette.error.main,
        width: '30px',
    },
    divider: {
        marginTop: '4px',
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.secondary}`,
    },
}));
