import { useCallback } from 'react';
import { useWsMaster } from './base/wsmaster';


export function useConsultaAssinaturaACSAT() {
  const { invocarApi, ...outros } = useWsMaster({
    operacao: 'retAssinaturaACSAT',
    config: {
      timeout: 2000
    }
  });

  const consultarAssinaturaACSAT = useCallback(
    async (cnpj: string): Promise<any> => {
      const xml = (senha: string) => `
        <?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
          <soap12:Body>
            <retAssinaturaACSAT xmlns="http://saurus.net.br/">
              <xXMLParametros><![CDATA[<xmlIntegracao><CNPJEmitente>${cnpj}</CNPJEmitente><CNPJSoftwareHouse>${'11914993000123'}</CNPJSoftwareHouse></xmlIntegracao>]]></xXMLParametros>
              <xSenha>${senha}</xSenha>
            </retAssinaturaACSAT>
          </soap12:Body>
        </soap12:Envelope>
    `;

      const result = await invocarApi({ xml, });
      return result.retAssinaturaACSAT.Assinatura
    },
    [invocarApi],
  );

  return {
    ...outros,
    consultarAssinaturaACSAT,
  };
}
