import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetSessaoGerenciamento() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSessaoGerenciamento = useCallback(
        (page: number, queryBusca: string, empresaId: string) => {

            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryBusca}`;

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/sessao${queryFinal}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSessaoGerenciamento,
    };
}
