import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const DespesaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 1.71875 0.28125 L 0.28125 1.71875 L 15.28125 16.71875 L 16 17.40625 L 16.71875 16.71875 L 20 13.4375 L 31.28125 24.71875 L 32 25.40625 L 32.71875 24.71875 L 36 21.4375 L 46.5625 32 L 40 32 L 40 34 L 50 34 L 50 23 L 48 23 L 48 30.5625 L 36.71875 19.28125 L 36 18.59375 L 35.28125 19.28125 L 32 22.5625 L 20.71875 11.28125 L 20 10.59375 L 19.28125 11.28125 L 16 14.5625 Z M 0 8 L 0 50 L 2 50 L 2 8 Z M 6 14 L 6 50 L 8 50 L 8 14 Z M 12 20 L 12 50 L 14 50 L 14 20 Z M 18 20 L 18 50 L 20 50 L 20 20 Z M 24 24 L 24 50 L 26 50 L 26 24 Z M 36 28 L 36 50 L 38 50 L 38 28 Z M 30 30 L 30 50 L 32 50 L 32 30 Z M 42 38 L 42 50 L 44 50 L 44 38 Z M 48 38 L 48 50 L 50 50 L 50 38 Z"></path>
      </DefaultIcon>
    </>
  );
};
