import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoMedidas() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoMedidas = useCallback(
    (
      queryBusca: string,
      empresaId: string,
      page?: number,
      pageSize: number = 10
    ) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Medida?empresaId=${empresaId}&query=${queryBusca}&page=${page}&pageSize=${pageSize}`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoMedidas
  };
}
