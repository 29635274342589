import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const SucessoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 138 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity={props.fill ? '0.8' : '0.4'}
            d="M69 138C107.108 138 138 107.108 138 69C138 30.8924 107.108 0 69 0C30.8923 0 0 30.8924 0 69C0 107.108 30.8923 138 69 138Z"
            fill={props.fill ?? '#10C822'}
          />
          <path
            d="M60.5012 93.9605C58.5494 95.8744 55.4222 95.8661 53.4807 93.9417L37.6225 78.2239C35.6132 76.2323 35.655 72.9718 37.7146 71.0324L38.9542 69.8653L39.6316 69.3039C41.5936 67.678 44.4644 67.7877 46.2967 69.5585L53.3762 76.4005C55.3658 78.3234 58.5388 78.2648 60.456 76.2698L90.5198 44.9873C92.4541 42.9745 95.6619 42.9355 97.6446 44.9007L100.451 47.6826C102.432 49.6461 102.424 52.8509 100.432 54.8038L60.5012 93.9605Z"
            fill={props.fill ? '#fff' : '#10C822'}
          />
        </svg>
      </DefaultIcon>
    </>
  );
};
