import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    cLoader: {
        animation: 'rotate 1.5s infinite',
        border: `4px solid ${theme.palette.grey[200]}`,
        margin: 0,
        borderRadius: '100%',
        borderTopColor: theme.palette.primary.light,
        height: '20px',
        width: '20px',
        background: theme.palette.primary.light,
        padding: 4,
        marginLeft: '8px'
    },
    "@keyframes rotate": {
        "to": {
            transform: 'rotate(1turn)'
        }
    }
}));

