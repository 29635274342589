import { Box, Tooltip, Typography, useTheme } from "@material-ui/core";
import { DefaultCard, useDefaultCardStyles } from "../components"
import { CardGerenciarMesaProps } from "./card-gerenciar-mesa-props"
import { useCallback } from "react";
import { EnumMesas } from "model/enums/enum-mesas";
import { EditarIcon, QrCodeIcon } from "views/components/icons";
import { MesaIcon } from "views/components/icons/mesa-icon";

export const CardGerenciarMesa = ({
    model,
    onCheck,
    onClick,
    selected,
    salao,
}: CardGerenciarMesaProps) => {

    const classes = useDefaultCardStyles();
    const theme = useTheme();

    const retornarCorStatus = useCallback((): string => {
        switch (model.status.codigo) {
            case EnumMesas.ATIVO:
                return theme.palette.success.main;
            case EnumMesas.INATIVO:
                return theme.palette.error.main;
            default:
                return theme.palette.primary.main;
        }
    }, [
        theme.palette.success.main,
        theme.palette.error.main,
        theme.palette.primary.main,
        model.status,
    ]);

    return (
        <>
            <DefaultCard
                isSelected={selected}
                onClick={() => {
                    onClick(model.id ?? '');
                }}
            >
                <Tooltip arrow title="" placement="right">
                    <div
                        className={classes.tagStatus}
                        style={{ background: retornarCorStatus() }}
                    ></div>
                </Tooltip>
                <div className={classes.cardContent}>
                    <div className={classes.celulaGrid}>
                        <MesaIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main} />
                    </div>
                    <div className={classes.celulaGridFullDescriptionFull}>
                        <Typography color="textPrimary" variant="caption">
                            Código
                        </Typography>
                        <Typography
                            className={'celula-grid-value'}
                            color="textPrimary"
                            variant="body1"
                        >
                            <strong>{model.codigo}</strong>
                        </Typography>
                    </div>
                    {model.pedidoComQrCode &&
                        <Box className={classes.celulaGrid} display={'flex'} alignItems="center">
                            <QrCodeIcon tipo="PRIVATE_HEADER" fill={theme.palette.primary.main} />
                            <Typography color="textPrimary" variant="caption">
                                QrCode na mesa
                            </Typography>
                        </Box>
                    }
                    <div className={classes.celulaGrid}>
                        <Typography color="textPrimary" variant="caption">
                            Salão
                        </Typography>
                        <Typography
                            className={'celula-grid-value'}
                            color="textPrimary"
                            variant="body1"
                        >
                            {salao?.Value}
                        </Typography>
                    </div>
                </div>
                <Tooltip arrow title="Editar Mesa">
                    <div className={classes.cardRightButton}>
                        <EditarIcon tipo="BUTTON" />
                    </div>
                </Tooltip>
            </DefaultCard>
        </>
    )
}