import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetHealth() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getHealth = useCallback(
        () => {
            return invocarApi({
                url: `/health`,
                method: "GET",
                enviarTokenUsuario: true,
                timeout: 3000,
                baseURL: VariaveisAmbiente.apiUrl
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getHealth,
    };
}
