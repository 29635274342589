import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetPedidoSalao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPedidoSalao = useCallback(
        (empresaId: string, queryBusca: string, page?: number) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao${queryFinal}`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getPedidoSalao,
    };
}
