import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    button: {
        [theme.breakpoints.down('sm')]: {
            flex: 1
        }
    },
    root: {
        paddingBottom: theme.spacing(1),
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'nowrap'
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        border: 'none'
    }
}));