import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { FiltroIcon, MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { EntradaHeaderProps } from "./entrada-header-props";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { EnumStatusEntrada } from "model/enums/enum-status-entrada";

export const EntradaHeader = ({ ...props }: EntradaHeaderProps) => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { abrirEntradaFiltroModal } = useFiltrosModais()
    const history = useHistory();

    const urlParams = new URLSearchParams(history.location.search)
    const filtros = {
        dInicial: urlParams.get('dInicial'),
        dFinal: urlParams.get('dFinal'),
        status: urlParams.get('status'),
        nnf: urlParams.get('nnf')
    }

    const leftArea = useCallback(() => (
        isMobile ?
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                tooltip="Menu"
                onClick={abrirMenu}
            /> :
            null
    ),
        [isMobile, abrirMenu]
    );

    const rightArea = useCallback(() => (
        <ButtonPrivateHeader
            icon={<FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
            tooltip={"Pesquisar"}
            onClick={() => abrirEntradaFiltroModal({
                dFinal: filtros.dFinal ?? '',
                dInicial: filtros.dInicial ?? '',
                status: filtros.status ? Number(filtros.status) : EnumStatusEntrada.EmDigitacao,
                termo: '',
                nnf: filtros.nnf ? Number(filtros.nnf) : 0
            })}
        ></ButtonPrivateHeader>
    ),
        [abrirEntradaFiltroModal, filtros.dFinal, filtros.dInicial, filtros.nnf, filtros.status]
    );

    return (
        <>
            <PrivatePageHeader
                title="Entradas"
                leftArea={leftArea()}
                rightArea={rightArea()}
            />
        </>
    );
};
