import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const DragIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M15 3C11.6914 3 9 5.69141 9 9C9 12.3086 11.6914 15 15 15C18.3086 15 21 12.3086 21 9C21 5.69141 18.3086 3 15 3ZM35 3C31.6914 3 29 5.69141 29 9C29 12.3086 31.6914 15 35 15C38.3086 15 41 12.3086 41 9C41 5.69141 38.3086 3 35 3ZM15 19C11.6914 19 9 21.6914 9 25C9 28.3086 11.6914 31 15 31C18.3086 31 21 28.3086 21 25C21 21.6914 18.3086 19 15 19ZM35 19C31.6914 19 29 21.6914 29 25C29 28.3086 31.6914 31 35 31C38.3086 31 41 28.3086 41 25C41 21.6914 38.3086 19 35 19ZM15 35C11.6914 35 9 37.6914 9 41C9 44.3086 11.6914 47 15 47C18.3086 47 21 44.3086 21 41C21 37.6914 18.3086 35 15 35ZM35 35C31.6914 35 29 37.6914 29 41C29 44.3086 31.6914 47 35 47C38.3086 47 41 44.3086 41 41C41 37.6914 38.3086 35 35 35Z" />
            </DefaultIcon>
        </>
    );
};