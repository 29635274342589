import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const FecharIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
        onClick={props.onClick}
      >
        <path d="M4.27361 2.68994C4.53992 2.95483 4.7682 3.14404 4.99647 3.33325C11.5022 9.80422 18.008 16.2373 24.4757 22.7462C25.2366 23.503 25.579 23.5787 26.378 22.784C32.8457 16.2752 39.3895 9.84206 45.8572 3.33325C46.6181 2.57642 46.9225 2.91699 47.4171 3.48462C47.8356 3.97657 48.5965 4.24146 47.6834 5.11182C41.1015 11.5449 34.5958 18.0916 28.052 24.5626C27.3671 25.2437 27.2911 25.5843 28.0139 26.3033C34.5577 32.7743 41.0635 39.2831 47.6453 45.754C48.5204 46.5866 47.8736 46.8893 47.4171 47.3812C46.9605 47.911 46.6561 48.3651 45.8572 47.5705C39.3514 41.0238 32.8076 34.5907 26.3019 28.044C25.6171 27.3629 25.2746 27.2872 24.5518 28.0062C18.046 34.515 11.5022 40.986 4.99647 47.5326C4.23556 48.2895 3.9312 47.911 3.43661 47.3812C2.98006 46.8893 2.29525 46.6244 3.17029 45.754C9.75214 39.3209 16.2579 32.7743 22.8017 26.3033C23.4865 25.6221 23.5626 25.2816 22.8397 24.5626C16.2959 18.1294 9.79019 11.6206 3.24638 5.11182C3.01811 4.88477 2.44743 4.69556 2.75179 4.2793C3.17029 3.67383 3.74097 3.21973 4.27361 2.68994Z" />
      </DefaultIcon>
    </>
  );
};
