import { useCallback } from "react";
import { useApiBase } from "../base/api-base";
import { VariaveisAmbiente } from "config/enviroment";

export function useGetInfoEanProduto() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getInfoEanProduto = useCallback(
    (codigo: string) => {
      return invocarApi({
        url: `/integrador/consultar-ean/${codigo}`,
        method: "GET",
        enviarTokenUsuario: false,
        baseURL: `${VariaveisAmbiente.apiTributarioUrl}/api`,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getInfoEanProduto,
  };
}
