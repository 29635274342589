import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ProdutoModal } from "views/components/modals/produto/produto-modal";

class CadastroProdutoModalProps {
    constructor(
        public id: string = '',
        public openned: boolean = false,
        public ultimaUrl: string = '',
        public callbackUrl: string = '',
        public trocarUrl: boolean = false,
        public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
        public search: string = '',
    ) { }
}

export const CadastroProdutoModal = () => {
    const { push } = useHistory();
    const location = useLocation()
    const { addHandler, removeHandler } = useEventTools();

    const [modalState, setModalState] = useState<CadastroProdutoModalProps>(new CadastroProdutoModalProps());
    const produtoRoute = "/produtos";
    const insumoRoute = "/insumos";
    const comboRoute = "/combos";
    const novoCadastroRoute = "adicionar";

    consoleDev('CadastroProdutoModal');
    const igual = location.pathname.indexOf("/produtos") === 0 ? produtoRoute : location.pathname.indexOf("/insumos") === 0 ? insumoRoute : comboRoute;

    const tipo = (() => {
        switch (igual) {
            case '/produtos':
                return EnumTipoProduto.Produto;
            case '/insumos':
                return EnumTipoProduto.Insumo;
            case '/combos':
                return EnumTipoProduto.Combo;
            default:
                return EnumTipoProduto.Produto
        }
    })()

    useEffect(() => {
        if (location.pathname.indexOf(igual) === 0) {
            const posrota = location.pathname.replace(igual, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, '').replace('/', ''),
                    openned: true,
                    ultimaUrl: igual,
                    tipo: tipo,
                    callbackUrl: '',
                    trocarUrl: false,
                    search: prev.search,
                }));
                return;
            }
        }
    }, [location.pathname, igual, tipo]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl, tipo }: CadastroProdutoModalProps) => {
        let url = callbackUrl.length === 0 ? igual : callbackUrl;
        const prevSearch = modalState.search
        setModalState({
            id,
            openned,
            ultimaUrl: url,
            callbackUrl,
            tipo,
            trocarUrl,
            search: window.location.search
        });
        if (!openned && trocarUrl) {
            const finalUrl = [url];
            push({
                pathname: finalUrl.join('/'),
                search: prevSearch
            });
            return;
        }
        if (trocarUrl) {
            const idPart = id?.length === 0 ? novoCadastroRoute : id;
            const finalUrl = [url, idPart];
            push(finalUrl.join('/'));
        }
    }, [igual, modalState.search, push])

    useEffect(() => {
        addHandler(AppEventEnum.ProdutoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ProdutoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            <ProdutoModal openned={modalState.openned} key="mdlProduto" id={modalState.id} tipo={modalState.tipo} />
        </>
    )
}