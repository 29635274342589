import { ProdutoNovoModel } from "model/api/gestao/produto/produto/produto-novo-model";
import { ProdutoCompletoPreCadastroFormModel } from "model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogProdutoFacilitado } from "views/components/dialog/dialog-produto-facilitado/dialog-produto-facilitado";

class ProdutoFacilitadoDialogProps {
    constructor(
        public aberto: boolean = false,
        public produto: ProdutoNovoModel = new ProdutoNovoModel(),
        public finalizar: (produto: ProdutoCompletoPreCadastroFormModel) => Promise<void> = async (produto: ProdutoCompletoPreCadastroFormModel) => { },
        public gradeId: string = ''
    ) { }
}

export const ProdutoFacilitadoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<ProdutoFacilitadoDialogProps>(
        new ProdutoFacilitadoDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, produto, finalizar, gradeId }: ProdutoFacilitadoDialogProps) => {
        setModalState({
            aberto: aberto,
            produto,
            finalizar,
            gradeId
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.CadastroProdutoFacilitado, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.CadastroProdutoFacilitado, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogProdutoFacilitado finalizar={modalState.finalizar}
                 openned={modalState.aberto} 
                 produto={modalState.produto}
                 gradeId={modalState.gradeId} />
            )}
        </>
    )
}