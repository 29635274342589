export const serializeHtmlToPrint = async (html: string): Promise<String> => {
    let retorno = '';
    if (html) {
        if (html.indexOf('<html>') === -1) {
            retorno += `
            <html>
                <head>
                    @@script
                </head>
                <body>${html}</body>
            </html>
            `
        } else {
            retorno = html;
            retorno = retorno.replace('<body',
                `
            @@script
            <body `);
        }
    }

    if (retorno.indexOf("<img") === -1) {
        retorno = retorno.replace('<body', `<body onload='getImags()'`);
        retorno = retorno.replace('@@script', `
        <script type="text/javascript">
        var notify = 0;
        var numImgs = 0;


        function getImags() {
            tagsImg = document.getElementsByTagName("img");
            numImgs = tagsImg.length;

            if (numImgs === 0) {
                notifyHtmlReadyToPrint();
                return
            }

            for (var i = 0; i <= numImgs; i++) {
                let srcAttribute = tagsImg[i].getAttribute("src");
                tagsImg[i].removeAttribute('src');
                tagsImg[i].setAttribute("onload", "notifyPrint()");
                tagsImg[i].setAttribute("src", srcAttribute);
            }
        }

        </script>
        <script src="../share/mobilewebkit.js"></script>
        <script src="../share/smart_webkit.js"></script>`);
    }
    else {
        retorno = retorno.replaceAll("http:", "https:")
        retorno = retorno.replace('@@script', ``);
        retorno = retorno.replace('</body>', `
        <script src="../share/mobilewebkit.js"></script>
        <script src="../share/smart_webkit.js"></script>
        </body>`)
    }


    return retorno;
};