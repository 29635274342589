
import { KeyValueModel } from "model";
import { EnumFaturaStatus } from "model/enums/enum-fatura-status";

export const StatusFaturaMock: KeyValueModel[] = [
    new KeyValueModel(EnumFaturaStatus.ABERTA, "Aberta"),
    new KeyValueModel(EnumFaturaStatus.PAGA, "Paga"),
    new KeyValueModel(EnumFaturaStatus.CANCELADA, "Cancelada"),
    new KeyValueModel(EnumFaturaStatus.REJEITADA, "Rejeitada"),
    new KeyValueModel(EnumFaturaStatus.FECHADO, "Fechada"),
    new KeyValueModel(EnumFaturaStatus.EMATRASO, "Em atraso"),
    new KeyValueModel(EnumFaturaStatus.BONIFICADA, "Bonificada"),
];

export const StatusFaturaMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumFaturaStatus.ABERTA, "Aberta"),
    new KeyValueModel(EnumFaturaStatus.PAGA, "Paga"),
    new KeyValueModel(EnumFaturaStatus.CANCELADA, "Cancelada"),
    new KeyValueModel(EnumFaturaStatus.REJEITADA, "Rejeitada"),
    new KeyValueModel(EnumFaturaStatus.FECHADO, "Fechada"),
    new KeyValueModel(EnumFaturaStatus.EMATRASO, "Em atraso"),
    new KeyValueModel(EnumFaturaStatus.BONIFICADA, "Bonificada"),
];