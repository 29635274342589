import { isEmpty } from "lodash";
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import PerfilCadastro from "./perfil-modal/perfil-cadastro/perfil-cadastro";
import PerfilEdit from "./perfil-modal/perfil-edit/perfil-edit";

export const PerfilModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (<PerfilCadastro />)}
      {props.openned && !isEmpty(props.id) && (<PerfilEdit id={props.id} />)}
    </DefaultModal>
  );
};
