import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
export interface IdentidadeAtualizarSenhaProps {
    telefone?: string | null
    email?: string | null,
    senha: string,
    senhaConfirmacao: string,
    redefinirCodigo: string
}

export function usePostIdentidadeAtualizarSenha() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAtualizarSenha = useCallback(
        (atualizarSenhaProps: IdentidadeAtualizarSenhaProps) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Auth/confirmar-redefinir-senha`,
                method: 'POST',
                data: atualizarSenhaProps,
                enviarTokenUsuario: false
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAtualizarSenha,
    };
}
