import { guidEmpty } from "utils/guid-empty";
export class ProdutoCodigoModel {
  constructor(
    public id: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public codigo: string = "",
    public isEanTrib: boolean = true,
    public fator: number = 1,
    public precoFixo: number | null = 0,
    public percTabela: number | null = 0,
  ) { }
}
