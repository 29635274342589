import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[50]
  },
  tabContent: {
    margin: theme.spacing(2)
  },
  principalMedicamento: {
    background: theme.palette.background.default,
    paddingBottom: 0
  },
  principal: {
    background: theme.palette.common.white,
    margin: theme.spacing(2) * -1,
    marginBottom: 0,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  content: {},
  tipoStatus: {
    background: theme.palette.primary.main,
    padding: `4px 8px`,
    fontWeight: 500,
    color: '#FFF',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  statusBox: {
    display: 'flex',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: 4,
    justifyContent: 'center',
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 250,
    '& svg': {
      fill: theme.palette.secondary.main
    }
  },
  relative: {
    position: 'relative'
  },
  contentAbasMedicamento: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2)
    }
  },
  contentAbas: {
    // background: theme.palette.common.white,
    // background: theme.palette.grey[100],
    boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0
    }
  }
}));
