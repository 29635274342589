import { useStyles } from './accordion-perfil-styles';
import { PerfilEditPermissaoFormModel } from 'model/app/forms/perfil/perfil-edit-permissao-form-model';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import AccordionPerfilControle from '../accordion-perfil-controle/accordion-perfil-controle';
import { useState } from 'react';

interface AccordionPropsPerfil {
  permissoes: Array<PerfilEditPermissaoFormModel>;
  onPermissaoChanced(cod: number, novoValor: any, titulo: string): void;
  titulo: string;
  chave: string;
}

function AccordionPerfil(props: AccordionPropsPerfil) {
  const handlePermissaoChanged = (cod: number, novoValor: string) => {
    props.onPermissaoChanced(cod, novoValor, props.chave);
  };

  const [openned, setOpenned] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AccordionSaurus
        labelPrimary={props.titulo}
        showDivider={true}
        onChange={() => {
          setOpenned(!openned);
        }}
        expanded={openned}
        noPaperRoot
        noPaddingContent
      >
        <AccordionPerfilControle
          onPermissaoChanced={handlePermissaoChanged}
          list={props.permissoes}
        />
      </AccordionSaurus>
    </div>
  );
}

export default AccordionPerfil;
