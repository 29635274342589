import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogMovImportar } from 'views/components/dialog/dialog-mov-importar/dialog-mov-importar';

class DialogImportarProps {
  constructor(
    public aberto: boolean = false,
    public pedido: PedidoModel | undefined = undefined,
    public importarPedido: (pedido: PedidoModel) => Promise<void> = () =>
      Promise.resolve(),
    public dispatchEvent: AppEventEnum = AppEventEnum.RecarregarPedidos,
  ) {}
}

export const ImportarDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogImportarProps>(
    new DialogImportarProps()
  );

  consoleDev('ImportarDialog');

  const modalAlterado = useCallback(
    ({ aberto, pedido, importarPedido, dispatchEvent }: any) => {
      setModalState({
        aberto,
        pedido,
        importarPedido,
        dispatchEvent,
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.ImportarDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.ImportarDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && modalState.pedido && (
        <DialogMovImportar
          openned={modalState.aberto}
          pedido={modalState.pedido}
          handleImportar={modalState.importarPedido}
          dispatchEvent={modalState.dispatchEvent}
        />
      )}
    </>
  );
};
