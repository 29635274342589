import { useCallback, useEffect, useState, useRef } from 'react';
import { NovoIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useGetModificadorById } from 'data/api/gestao/modificador/get-modificador-by-id';
import { ModificadorCompletoModel } from 'model/api/gestao/modificador/modificador-completo-model';
import { ModificadorEditFormModel } from 'model/app/forms/modificador/modificador-edit-form-model';
import { ModificadorModel } from 'model/api/gestao/modificador/modificador-model';
import { usePutModificador } from 'data/api/gestao/modificador/put-modificador';
import { FormModificadorEdit } from 'views/components/form/modificador/form-modificador-edit/form-modificador-edit';
import { ModificadorEditProdutos } from './components/modificador-produtos/modificador-produtos';
import { ProdutoModificadorModel } from 'model/api/gestao/produto/produto-modificador/produto-modificador-model';
import { useStyles } from './modificadores-edit-styles'
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useConfirm } from 'material-ui-confirm';
import { useDeleteModificador } from 'data/api/gestao/modificador/delete-modificador';

export const ModificadoresEdit = (props: { id: string }) => {

  const { showToast } = useToastSaurus();
  const classes = useModalStyles();
  const modClasses = useStyles()
  const { fecharCadastroModificador, abrirCadastroModificador } = useCadastros();
  const confirm = useConfirm()

  const { getModificadorById, carregando: carregandoModificador } = useGetModificadorById()
  const { putModificador, carregando: carregandoPut } = usePutModificador();
  const { deleteModificador, carregando: carregandoDelete } = useDeleteModificador()

  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [modificadorProdutos, setModificadorProdutos] = useState<ProdutoModificadorModel[]>([])
  const carregando = [
    carregandoPut,
    preenchendoTela,
    carregandoModificador,
    carregandoDelete
  ].includes(true);
  const [modificadorFormState, setModificadorForm] = useState<ModificadorEditFormModel>(
    new ModificadorEditFormModel(),
  );
  const refEditForm = useRef<DefaultFormRefs<ModificadorEditFormModel>>(null);
  const refModificadorModel = useRef<ModificadorCompletoModel>(new ModificadorCompletoModel());

  const recarregarForm = useCallback((model: ModificadorEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(modificadorFormState);
  }, [modificadorFormState, recarregarForm]);

  const getModificadorByIdWrapper = useCallback(async () => {
    const res = await getModificadorById(props.id);
    if (res.erro) {
      throw res.erro;
    }

    const resultado = res.resultado?.data as ModificadorCompletoModel
    refModificadorModel.current = resultado
    setModificadorProdutos(resultado.produtos)

    const modificadorForm = picker<ModificadorEditFormModel>(resultado, new ModificadorEditFormModel())

    return modificadorForm;
  }, [getModificadorById, props.id]);

  const preencherTela = useCallback(async () => {
    try {
      const modificador = await getModificadorByIdWrapper();
      setModificadorForm({ ...modificador });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getModificadorByIdWrapper, showToast]);

  useEffect(() => {
    (async () => {
      setPreenchendoTela(true);
      preencherTela();
      setPreenchendoTela(false);
    })();
    return () => {
      //funcao de limpeza
      setModificadorForm(new ModificadorEditFormModel());
    };
  }, [preencherTela]);

  const saveChangesModificador = useCallback(
    async (modificadorModelForm: ModificadorEditFormModel) => {
      const modificador = picker<ModificadorModel>(refModificadorModel.current, new ModificadorModel());
      const modificadorPut = picker<ModificadorModel>(modificadorModelForm, modificador);
      const ret = await putModificador(modificadorPut);
      if (ret.erro) {
        showToast(
          'error',
          'Não foi possível atualizar o modificador, tente novamente mais tarde',
        );
      }
    },
    [putModificador, showToast],
  );

  const handleSubmit = useCallback(
    async (model: ModificadorEditFormModel, beforeModel: ModificadorEditFormModel) => {
      try {
        const modificadorEqual = isEqual(model, beforeModel);
        if (!modificadorEqual) {
          await saveChangesModificador(model);
        }

        if (modificadorEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
        } else {
          showToast('success', 'Modificador atualizado com Sucesso!');
          preencherTela();
        }
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [saveChangesModificador, showToast, preencherTela],
  );

  const redirectToCadastro = useCallback(() => {
    abrirCadastroModificador('', '', true);
  }, [abrirCadastroModificador]);

  const onCloseClick = useCallback(() => {
    fecharCadastroModificador();
  }, [fecharCadastroModificador]);

  const removerModificador = useCallback(async () => {
    try {
      const res = await deleteModificador(props.id)
      if (res.erro) throw res.erro
      showToast('success', 'Modificador removido com sucesso!')
      fecharCadastroModificador()
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [deleteModificador, fecharCadastroModificador, props.id, showToast])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Edição de Modificador'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          <MenuOptions
            options={[
              new MenuOptionsModel(
                `Remover Modificador`,
                <></>,
                () => confirm({
                  title: 'Remover Modificador',
                  description: 'Tem certeza que deseja completar esta ação?',
                  confirmationText: 'Confirmar',
                  cancellationText: 'Voltar'
                })
                  .then(async () => await removerModificador()),
              ),
            ]}
          />
        }
      />
      <div className={classNames(classes.content, modClasses.content)}>
        <div
          className={classNames(
            preenchendoTela ?
              classes.contentFormsLoading
              : undefined,
            classes.contentForms
          )}
        >
          <FormModificadorEdit
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
          <Box mx={-2} className={modClasses.contentProdList}>
            <Box>
              <Box px={2} py={1}>
                <Typography color='primary' variant='h6'>
                  Seleção dos Produtos
                </Typography>
              </Box>
              <Divider className={modClasses.divider} />
            </Box>
            <Box>
              <ModificadorEditProdutos
                produtos={modificadorProdutos}
                atualizarArray={preencherTela}
                modificadorId={props.id}
              />
            </Box>
          </Box>
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={redirectToCadastro}
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  refEditForm.current?.submitForm();
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
