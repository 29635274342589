import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerItemFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    textPreview: {
        "&& svg": {
            width: 30,
            height: 30,
        }
    },
    "@keyframes pulse": {
        from: { transform: "scale(1)" },
        "30%": { transform: "scale(1.15)" },
        to: { transform: "scale(1)" }
    },
    fePulse: {
        animationName: "$pulse",
        animationDuration: ".5s",
        animationIterationCount: "infinite",
    },
    badge: {
        zIndex: 0
    }
}));