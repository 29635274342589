import { ManifestoModel } from "model/api/gestao/manifesto/manifesto-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { SituacaoManifestoModal } from "views/components/modals/situacao-manifesto/situacao-manifesto-modal";

export const ModalSituacaoManifesto = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        model: new ManifestoModel(),
        aberto: false,
    });

    const modalAlterado = useCallback(({ aberto, model }: any) => {
        setModalState({
            model,
            aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.SituacaoManifestoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.SituacaoManifestoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <SituacaoManifestoModal 
            model={modalState.model}
            openned={modalState.aberto}
        />
    )
}