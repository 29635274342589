import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { VincularReceita } from './components/vincular-receita/vincular-receita';

export const ReceitaModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <VincularReceita />}
    </DefaultModal>
  );
};
