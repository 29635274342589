
import { KeyValueModel } from "model";
import { EnumUploadCarga } from "model/enums/enum-upload-carga";

export const StatusUploadCargaMock: KeyValueModel[] = [
    new KeyValueModel(EnumUploadCarga.AGUARDANDO, "Aguardando"),
    new KeyValueModel(EnumUploadCarga.ANALISANDO, "Analisando"),
    new KeyValueModel(EnumUploadCarga.PROCESSADO, "Processado"),
    new KeyValueModel(EnumUploadCarga.CONFIRMACAO, "Processando"),
    new KeyValueModel(EnumUploadCarga.PENDENTE, "Análise Pendente"),
    new KeyValueModel(EnumUploadCarga.REJEITADO, "Rejeitado"),
    new KeyValueModel(EnumUploadCarga.CANCELADO, "Cancelado"),
];
