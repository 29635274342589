import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogTaxaServico } from 'views/components/dialog/dialog-taxa-servico/dialog-taxa-servico';

class TaxaServicoProps {
  constructor(
    public aberto: boolean = false,
    public model: PedidoModel = new PedidoModel(),
    public carregarInfoPedido?: () => void,
  ) {}
}

export const TaxaServicoDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<TaxaServicoProps>(
    new TaxaServicoProps()
  );

  consoleDev('TaxaServicoDialog');

  const modalAlterado = useCallback(
    ({ aberto, model, carregarInfoPedido}: any) => {
      setModalState({
        aberto,
        model,
        carregarInfoPedido
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogTaxaServico, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogTaxaServico, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogTaxaServico
        openned={modalState.aberto}
        model={modalState.model}
        carregarInfoPedido={modalState.carregarInfoPedido}
      />
      )}
    </>
  );
};
