import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const NumberIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path opacity="0.77" d="M14.3402 4.8V23H10.1282V8.18H6.48821V4.8H14.3402ZM43.9699 19.568V23H30.2159V20.27L37.2359 13.64C37.9812 12.9293 38.4839 12.3227 38.7439 11.82C39.0039 11.3 39.1339 10.7887 39.1339 10.286C39.1339 9.558 38.8825 9.00333 38.3799 8.622C37.8945 8.22333 37.1752 8.024 36.2219 8.024C35.4245 8.024 34.7052 8.18 34.0639 8.492C33.4225 8.78667 32.8852 9.23733 32.4519 9.844L29.3839 7.868C30.0945 6.81067 31.0739 5.98733 32.3219 5.398C33.5699 4.79133 34.9999 4.488 36.6119 4.488C37.9639 4.488 39.1425 4.71333 40.1479 5.164C41.1705 5.59733 41.9592 6.22133 42.5139 7.036C43.0859 7.83333 43.3719 8.778 43.3719 9.87C43.3719 10.858 43.1639 11.7853 42.7479 12.652C42.3319 13.5187 41.5259 14.5067 40.3299 15.616L36.1439 19.568H43.9699ZM27.3226 36.21C28.9173 36.47 30.1393 37.068 30.9886 38.004C31.838 38.9227 32.2626 40.0667 32.2626 41.436C32.2626 42.4933 31.9853 43.4727 31.4306 44.374C30.876 45.258 30.0266 45.9687 28.8826 46.506C27.756 47.0433 26.3693 47.312 24.7226 47.312C23.44 47.312 22.1746 47.1473 20.9266 46.818C19.696 46.4713 18.6473 45.986 17.7806 45.362L19.4186 42.138C20.112 42.658 20.9093 43.0653 21.8106 43.36C22.7293 43.6373 23.6653 43.776 24.6186 43.776C25.676 43.776 26.508 43.5767 27.1146 43.178C27.7213 42.762 28.0246 42.1813 28.0246 41.436C28.0246 39.9453 26.8893 39.2 24.6186 39.2H22.6946V36.418L26.4386 32.18H18.6906V28.8H31.4306V31.53L27.3226 36.21Z" />
            </DefaultIcon>
        </>
    );
};
