import { useState } from "react";
import { useCadastros, useSessaoAtual } from "services/app";
import { isPlanoFarmaceutico } from "utils/plano-utils";
import ProdutoCadastro from "../produto-cadastro/produto-cadastro";
import MedicamentoCadastro from "../medicamento-cadastro/medicamento-cadastro";
import { Box } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { VoltarIcon } from "views/components/icons";
import { useHistory } from "react-router-dom";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { SelecionarTipoProduto } from "../selecionar-tipo/selecionar-tipo-produto";
import { useStyles } from '../produto-cadastro/produto-cadastro-styles'

export enum EnumTelaCadastroProduto {
    SWITCH,
    PRODUTO,
    MEDICAMENTO
}

export const CadastroSwitch = (props: {
    empresaId: string;
    contratoId: string;
    title: string;
    tipo: EnumTipoProduto;
}) => {

    const { plano } = useSessaoAtual();
    const modalClasses = useModalStyles();
    const classes = useStyles();
    const { fecharCadastroProduto } = useCadastros();
    const { location } = useHistory();

    const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

    const [tela, setTela] =
        useState<EnumTelaCadastroProduto>(
            (props.tipo === EnumTipoProduto.Insumo || props.tipo === EnumTipoProduto.Combo) ? EnumTelaCadastroProduto.PRODUTO : 
            isFarmaceutico ? EnumTelaCadastroProduto.SWITCH : EnumTelaCadastroProduto.PRODUTO
        )


    const handleConfirm = (tipo: EnumTipoProduto) => {
        switch (tipo) {
            case EnumTipoProduto.Medicamento:
                setTela(EnumTelaCadastroProduto.MEDICAMENTO)
                break;
            default:
                setTela(EnumTelaCadastroProduto.PRODUTO)
                break;
        }
    }

    if (tela === EnumTelaCadastroProduto.PRODUTO) {
        return <ProdutoCadastro
            {...props}
            isPlanoFarmaceutico={isFarmaceutico}
            voltarTela={() => setTela(EnumTelaCadastroProduto.SWITCH)}
        />
    }

    if (tela === EnumTelaCadastroProduto.MEDICAMENTO) {
        return <MedicamentoCadastro
            {...props}
            isPlanoFarmaceutico={isFarmaceutico}
            voltarTela={() => setTela(EnumTelaCadastroProduto.SWITCH)}
        />
    }

    return <Box className={modalClasses.root}>
        <ModalHeader
            title='Adicionar Item'
            leftArea={
                <ButtonModalHeader
                    tooltip="Voltar"
                    icon={<VoltarIcon tipo="MODAL_HEADER" />}
                    onClick={() => {
                        const url = location.pathname.split('/')[1]
                        fecharCadastroProduto('/' + url)
                    }}
                />
            }
        />
        <Box className={classes.content}>
            <SelecionarTipoProduto
                onConfirm={handleConfirm}
            />
        </Box>
    </Box>
}