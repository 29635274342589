import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerGrid: {
    padding: theme.spacing(1),
    display: 'block'
  },
  backgroundMobile: {
    [theme.breakpoints.down("sm")]: {
      // background: theme.palette.common.white,
    }
  }
}));