import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { FaturasModal } from "views/components/modals/faturas/faturas-modal";

export const CadastroFaturasModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: ''
    });

    const faturasRoute = `/faturas/`;
    const novoCadastroRoute = 'adicionar';
    
    consoleDev('CadastroFaturasModal')

    useEffect(() => {
        if (location.pathname.indexOf(faturasRoute) === 0) {
            const posrota = location.pathname.replace(faturasRoute, '');
            if (posrota.length > 0) {
                setModalState({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: faturasRoute
                });
                return;
            }
        }
    }, [location.pathname, faturasRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? faturasRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url
        });
        if (!openned) {
            push(faturasRoute)
        }
        if (trocarUrl) {
            push(faturasRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
    }, [modalState.aberto, modalState.ultimaUrl, faturasRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.FaturasModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.FaturasModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <FaturasModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}