import * as Yup from "yup";
import { useMemo } from "react";

export const useFormMedicamentoPrecoValidation = (valoresEncontrados: boolean = false) => {

  
  const FormMedicamentoPrecoYupValidation = useMemo(() => {
    return Yup.object().shape({
      vCompra: Yup.string().required('Informe o valor de compra').typeError('Informe o valor de compra'),
      vPreco: Yup.string().required('Informe o preço').required('Informe o preço'),
    });
  }, []);

  return {
    FormMedicamentoPrecoYupValidation,
  };
};