import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { ProdutoCategoriaModel } from '../../../../../../../model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { CardCategoria } from '../../../../../../components/cards/card-categoria/card-categoria';

export interface CategoriaGridProps {
    list: Array<ProdutoCategoriaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const CategoriaListData = ((props: CategoriaGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma categoria encontrada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((categoria, index) => {
                return (
                    <CardCategoria
                        selected={
                            props.selectedList.filter((item) => item === categoria.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={categoria}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
