import { EnumIndIEDest } from 'model/enums';
import { guidEmpty } from 'utils/guid-empty';
export class PessoaEnderecoModel{
    constructor(
        public id: string = guidEmpty(),
        public pessoaId: string =  guidEmpty(), //IdPessoa
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public referencia: string = '',
        public bairro: string = '',
        public cMun: string = '',
        public xMun: string = '',
        public uf: string = '',
        public cuf: number = 0,
        public ierg: string = '',
        public im: string = '',
        public indIEDest: EnumIndIEDest = EnumIndIEDest.CONTRIBUINTE_ICMS,
        public pessoaTransportadoraPadraoId: string = ''
    ){}
}