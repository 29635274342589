import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { CargaRecarregarDadosModel } from "model/api/gestao/carga/carga-recarregar-dados-model";

export function usePostCargaRecarregarDados() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postCargaRecarregarDados = useCallback(
    (carga: CargaRecarregarDadosModel) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/carga/configuracao/recarregar-dados`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(carga),
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postCargaRecarregarDados,
  };
}
