import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutAcessoApi() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAcessoApi = useCallback(
        () =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/usuario/meu-usuario/acesso-api`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAcessoApi,
    };
}
