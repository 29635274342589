import { Paper, Typography, useTheme } from '@material-ui/core';
import { CardDashboardAdminChartCategoriasProps } from '../card-dashboard-admin-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';

export const CardDashboardAdminChartCategorias = ({
  model,
  carregando
}: CardDashboardAdminChartCategoriasProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(model.map((obj) => [obj.categoria, obj]));

  const modsUnicosList = Array.from(modUnicos.values());

  const categoriasSerelizados = modsUnicosList.map((item) => {
    return {
      ...item,
      vVenda: 0,
      qItens: 0,
    };
  });

  categoriasSerelizados.map((item) => {
    model.map((i) => {
      if (i.categoria === item.categoria) {
        let novaQuantidade = (item.vVenda += i.vVenda);
        let novaQTDE = (item.qItens += i.qItens);
        return {
          ...item,
          vVenda: novaQuantidade,
          qItens: novaQTDE,
        };
      }
      return i;
    });
    return item;
  });

  const totalVendas = model.reduce((a, b) => a + b.vVenda, 0);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Categorias mais vendidas por período
            </Typography>
          </div>
          <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
            }}
          >
            {model.map((item) => item.qItens)[0] === 0 || model.length === 0 ? (
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                Não há dados para exibir
              </Typography>
            ) : (
              <>
                {categoriasSerelizados
                  .sort((a, b) => b.qItens - a.qItens)
                  .filter((item, index) => index < 10)
                  .map((item, index) => {
                    let dinheiro = item.vVenda;
                    let vendas = item.qItens;

                    const porcentagem = (
                      (dinheiro * 100) /
                      parseInt(totalVendas.toString())
                    ).toFixed(0);

                    return (
                      <Paper
                        key={index}
                        className={classNames('card')}
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: theme.spacing(2),
                            paddingRight: theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>
                            <Typography
                              style={{ fontWeight: 600, fontSize: '1.2rem' }}
                            >
                              {index + 1}°{' '}
                              {item.categoria !== null
                                ? item.categoria
                                : 'Sem categoria'}
                            </Typography>
                            <Typography variant="body2">
                              {vendas < 2
                                ? `${vendas} Item vendido`
                                : `${vendas} Itens vendidos`}
                            </Typography>
                          </Typography>
                          <div>
                            <div
                              style={{
                                textAlign: 'right',
                                padding: '10px 0',
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: '1.2rem',
                                }}
                              >
                                R$ {String(toDecimalString(dinheiro, 2))}
                              </Typography>
                              <Typography
                                style={{ fontWeight: 500, fontSize: '1rem' }}
                              >
                                {porcentagem}%
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: `${porcentagem}%`,
                            backgroundColor: `#${(
                              (Math.random() * 0xffffff) <<
                              0
                            )
                              .toString(16)
                              .padStart(6, '0')}`,
                            height: 5,
                            zIndex: '-1000',
                          }}
                        />
                      </Paper>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};
