import { EnumTpAmb } from "model/enums/enum-tpamb";
import { EnumTpHistoricoVenda } from "model/enums/enum-tphistorico-venda";
import { guidEmpty } from "utils/guid-empty";

export class ConfigInicialCardapioModel {
    constructor(
        //Cliente deve preencher 
        public empresaId: string = guidEmpty(), 
        public type: number = 0,
        public informacaoAdicional: string | null = null,
        public statusLoja: string = '',
        public permiteRetirada: boolean = true,
        
        //Cores do cardápio
        public primaryMain: string = '#000000', 
        public primaryLight: string = '',
        public primaryDark: string = '',
        public primaryContrast: string = '',
        public secondaryMain: string = '',
        public secondaryLight: string = '',
        public secondaryDark: string = '',
        public secondaryContrast: string = '',
        public textPrimary: string = '',
        public textSecondary: string = '',

        //Fixo no envio das informações 
        public autoAtendimento: boolean = false,   
        public permiteEntrega: boolean = true, 
        public distanciaMaxima: number = 0, 
        public tipoUsoCardapio: number = 1, 
        public serie: number = 0, 
        public tipoVenda: EnumTpHistoricoVenda = EnumTpHistoricoVenda.VendaSimples, 
        public ambiente: EnumTpAmb = EnumTpAmb.Producao, 
        public disponibilizarPagamentoFinalizacao: boolean = true,     
        
    ) { }
}