import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { guidEmpty } from "utils/guid-empty";
import { VariaveisAmbiente } from "config";

export class PutConfiguracoesPontosVendaProps {
    constructor(
        public id: string = guidEmpty(),
        public cod: number = 0,
        public vConfig: string = '',
    ) { }
}

export function usePatchConfiguracoesPontosVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchConfigPontosVenda = useCallback(
        (configuracoes: Array<PutConfiguracoesPontosVendaProps>, empresaId: String, id: String) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/caixa/${id}/configuracao`,
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(configuracoes),
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        patchConfigPontosVenda,
    };
}
