import * as Yup from 'yup';

export const useFormPerfilEditValidation = () => {

  const FormPerfilEditYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    nome: Yup.string().required('O campo Nome é obrigatório'),
  });

  return {
    FormPerfilEditYupValidation
  }
}

