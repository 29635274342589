import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetEmpresas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getEmpresas = useCallback(
        (queryBusca: string, page?: number) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page.toString();
            }
            const queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true
            })
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getEmpresas,
    };
}
