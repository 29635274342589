import { Grid, Typography } from "@material-ui/core";
import { DefaultCard } from "../components/default-card"
import { isPlanoComVendedor } from "utils/plano-utils";
import { useSessaoAtual } from 'services/app';
import { EnumStatusPerfil } from "model/enums/enum-status-perfil";
import { CardUsuarioPerfilEditProps } from "./card-usuario-perfil-edit-props";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { KeyValueModel } from "model";
import { useStyle } from "./card-usuario-perfil-edit-styles";
import { guidEmpty } from "utils/guid-empty";
import { StatusPerfilEditMock, StatusPerfilMock } from "data/mocks/status-perfil-mock";

export const CardUsuarioPerfilEdit = ({ model, onChangePermissao, onChangeStatus, perfis }: CardUsuarioPerfilEditProps) => {

    const { plano } = useSessaoAtual()
    const classes = useStyle();



    const planoComVendedor = isPlanoComVendedor(plano?.plano)

    return (
        <>
            <DefaultCard>
                <Grid container spacing={2} alignItems='center' justifyContent="space-between" className={classes.container}>
                    <Grid item xs={12} md={4}>
                        <Typography color="textPrimary" variant="caption">
                            Empresa
                        </Typography>
                        <Typography
                            className={"celula-grid-value"}
                            color="textPrimary"
                            variant="body1">
                            <strong>{model.empresaNome}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={planoComVendedor ? model.status === EnumStatusPerfil.Convidado ? 2 : 3 : 6}>
                        <Typography color="textPrimary" variant="caption">
                            Status
                        </Typography>
                        {model.status === EnumStatusPerfil.Convidado ? (
                            <Typography
                                className={"celula-grid-value"}
                                color="textPrimary"
                                variant="body1">
                                <strong>{StatusPerfilMock.find(item => item.Key === model.status)?.Value || ''}</strong>
                            </Typography>
                        ) : (
                            <SelectSaurus
                                conteudo={StatusPerfilEditMock}
                                fullWidth
                                variant="outlined"
                                size='small'
                                value={model.status}
                                onChange={(event) => {
                                    onChangeStatus({ ...model, status: Number(event.target.value) as EnumStatusPerfil })
                                }}
                            />
                        )}
                    </Grid>
                    {planoComVendedor && (
                        <Grid item xs={12} md={model.status === EnumStatusPerfil.Convidado ? 5 : 4}>
                            <Typography color="textPrimary" variant="caption">
                                Perfil
                            </Typography>
                            <SelectSaurus
                                conteudo={[
                                    ...perfis.map(perfil => new KeyValueModel(perfil.id, perfil.nome)),
                                    new KeyValueModel(guidEmpty(), 'Sem Permissão')
                                ]}
                                fullWidth
                                variant="outlined"
                                size='small'
                                value={model.perfilId}
                                onChange={(event) => {
                                    onChangePermissao({ ...model, perfilId: event.target.value })
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </DefaultCard>
        </>
    );
};