import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useStyles } from './form-pesquisa-header-styles';
import { useRef } from 'react';
import { isEmpty } from 'lodash';

export interface PessoaPesquisaGenericoFormModel {
  generico: string;
}

interface FormPesquisaHeaderProps {
  onSubmit: (model: PessoaPesquisaGenericoFormModel) => void;
  text: string;
  placeholder?: string;
  closeSearch: () => void;
}

export const FormPesquisaHeader = ({
  onSubmit,
  text,
  placeholder = 'Digite aqui...',
  closeSearch
}: FormPesquisaHeaderProps) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const { handleSubmit, control } = useForm<PessoaPesquisaGenericoFormModel>({
    defaultValues: {
      generico: text
    },
    criteriaMode: 'all',
    mode: 'onChange'
  });

  return (
    <>
      <Box className={classes.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <Controller
            name="generico"
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                placeholder={placeholder}
                className={classes.textfield}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                searchable
                size="small"
                onSearch={() => {
                  if (isEmpty(field.value)) {
                    handleSubmit(onSubmit)();
                    closeSearch();
                    return;
                  }
                  handleSubmit(onSubmit)();
                }}
                fullWidth
                allowSubmit
                autoComplete="new-password"
                inputRef={inputRef}
                {...field}
              />
            )}
          />
        </form>
      </Box>
    </>
  );
};
