import { Box, Button, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { LoadingButton } from 'views/components/utils';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../produto-estoque-styles';

type Props = {
  handleVoltar: () => void;
  valorEstoque: number;
  closeModal: () => void;
  carregando: boolean;
  handleSubmit: (value: number) => Promise<void>;
  depositoId: string;
  depositoNome: string;
};
export const SaidaEstoque = ({
  handleVoltar,
  valorEstoque,
  closeModal,
  carregando,
  handleSubmit,
  depositoId,
  depositoNome
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>('');
  const { showToast } = useToastSaurus();
  const { theme, isMobile } = useThemeQueries();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    !isMobile && inputRef.current?.focus();
  }, [isMobile]);

  const disabled = useCallback(() => {
    if (!value || Number(value) === 0) {
      return true;
    }
    if (carregando) return true;
    return false;
  }, [carregando, value]);

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        if (disabled()) {
          return;
        }
        await handleSubmit(valorEstoque - Number(value));
        closeModal();
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível alterar o estoque do produto. Detalhe: ${e.message}`
        );
      }
    },
    [closeModal, disabled, handleSubmit, showToast, valorEstoque, value]
  );

  return (
    <Box component="form" height="100%" onSubmit={onSubmit}>
      <Box className={classes.fieldContainer}>
        <Grid container spacing={1}>
          {!isEmpty(depositoId) && (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Depósito: {depositoNome}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              Informe a quatidade de saída de estoque:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              margin: '8px 0'
            }}
          >
            <TextFieldSaurus
              fullWidth
              tipo="NUMERO"
              className={classes.inputValorCenter}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              placeholder="0"
              allowSubmit
              inputRef={inputRef}
              value={value}
            />
            <Typography
              variant="body2"
              style={{ textAlign: 'center', fontWeight: 600 }}
            >
              Saldo Atual:{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {valorEstoque}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp; Novo Saldo:{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {valorEstoque - Number(value)}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              size="large"
              onClick={() => {
                handleVoltar();
              }}
            >
              <VoltarIcon tipo="BUTTON" />
              Voltar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              size="large"
              disabled={disabled()}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Salvar
              {carregando && <LoadingButton tipo="AZUL" />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
