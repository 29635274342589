import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: '100%',
        height: '100%',
        border: "1px solid #E0E0E0",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: '4px',
        borderBottomLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
    },
    cardContainerDescricao: {
        padding: '8px',
    },
    cardDescricao: {
        minHeight: '65px',
        maxHeight: '65px',
        display: 'flex',
        alignItems: 'center'
    },
    cardDescricaoValor: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),

    },
    cardContainerDesconto: {
        padding: '8px'
    },
    cardItemDesconto: {
        display: 'flex',
    },
    cardItemDescontoValor: {
        marginLeft: '0px',
        display: 'flex',
        textDecoration: 'line-through',
        textDecorationColor: theme.palette.grey[400],
    },
    cardPrincingDesconto: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },

    dividerGrid: {
        display: 'flex',
        justifyContent: 'center', padding: '8px'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        marginLeft: '8px',
        marginRight: '8px',
        width: '100%'
    },
    cardOpcoes: {
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    cardLimiteVendas: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:theme.spacing(1)
    },
    borderPlanSelect: {
        width: '100%',
        borderRadius: '1rem',
        border: "1px solid " + theme.palette.primary.main,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
    },
    borderMostPopularPlan: {
        width: '100%',
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    backGroundTitlePlanSelect: {
        width: '100%',
        zIndex: -1,
        top: '0',
        backgroundColor: theme.palette.success.main,
        display: "flex",
        justifyContent: "center",
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem'
    },
    backGroundTitleMostPopularPlan: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem'
    },
    backGroundTitle: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem'
    },
    cardTopTitle: {
        width: "100%",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
    },
    textTopTitle: {
        color: "#fff",
        textAlign: "center",
        paddingTop: '8px',
        paddingBottom: '8px',
        display: 'flex',
        alignItems: 'center',
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        padding: '8px',
        flexDirection: "column",
    },
    cardTitle: {
        fontWeight: "bold",
        marginBottom: 8,
        color: theme.palette.primary.main
    },
    cardSubTitle: {
        marginLeft: 0,
        marginBottom: 0,
        color: "#777",
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,

    },
    cardPricingFree: {
        textAlign: "center",
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
    },
    cardPricingR$: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
        marginRight: '8px',
        color: theme.palette.primary.main
    },
    cardPricing: {
        fontWeight: "bold",
        marginleft: 0,
        marginbottom: 0,
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary.main
    },
    cardPricingPromoR$: {
        marginleft: 0,
        marginbottom: 0,
        color: theme.palette.grey[400]
    },
    cardPricingPromo: {
        marginleft: 0,
        marginbottom: 0,
        display: "flex",
        flexDirection: "row",
        color: theme.palette.grey[400]
    },
    cardTextPerMonth: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    },
    textPerMonth: {
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    containerItem: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        overflowY: 'auto',
    },
    cardOptionItem: {
        marginLeft: 1,
        marginBottom: 1,
        marginTop: 1,
    },
    list: {
        display: "flex",
        alignItems: "center",
        marginBottom: "8px",
    },
    cardFooterText: {
        marginBottom: 8,
        fontWeight: "bold",
        textAlign: "center",
        marginleft: 1
    },
    buttonViewMore: {
        marginBottom: theme.spacing(1)
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textRiscado: {
        textDecoration: 'line-through',
        color: theme.palette.grey[400],
        display: 'flex',
        alignItems:'flex-start'
    },
    cardPlano:{
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius:'1rem',
    }

}));
