import { Button, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCadastros } from 'services/app';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { ModalHeader } from 'views/components/modals/components';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { CalcFragmentProps } from './calc-fragment-props';
import { useStyles } from './calc-fragment-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { roundTo } from 'utils/round-to';

const CalcFragment = ({ add, textChanged, submit, ...props }: CalcFragmentProps) => {

  // STATES E REFS
  const [soma, setSoma] = useState<Array<number>>([]);
  const [inicial, setInicial] = useState<boolean>(true);
  const digitado = useRef<boolean>(false)
  const textoAtual = useRef<string>('')
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  // HOOKS
  const { fecharCalculadora } = useCadastros();
  const { addHandler, removeHandler } = useEventTools()
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    backSpaceKey,
  } = useKeyboard({
    maxLength: 7,
    floatCases: 2,
    isNumeric: true,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual
  });

  // AUX
  const defaultClasses = useDefaultCadastroStyles();
  const classes = useStyles();

  const attValueDisplay = useCallback((any: any) => {
    const v = getFormattedText();
    if (valueRef.current) valueRef.current.textContent = v;
  }, [getFormattedText])

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay)

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
  }, [addHandler, attValueDisplay, removeHandler])


  const onCloseClick = useCallback(() => {
    fecharCalculadora();
  }, [fecharCalculadora]);

  const atualizaSoma = useCallback(() => {
    if (isEmpty(props.valores)) {
      setSoma([]);
      return;
    }

    if (props.valores.length === 0) {
      setSoma([]);
    } else {
      setSoma(props.valores);
    }
  }, [props.valores]);

  //EFFECT PARA ATUALIZAR A SOMA NO CARREGAMENTO DA TELA
  useEffect(() => {
    atualizaSoma();
  }, [atualizaSoma]);

  //EFFECT PARA PREENCHER O VALOR DO CAMPO NO CARREGAMENTO TELA
  useEffect(() => {
    if (inicial && props?.valor && props.valor > 0) {
      resetText(toDecimalString(props.valor * 100, 2));
      setInicial(false);
    }
  }, [inicial, resetText, props.valor]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue],
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText],
  );

  const handleAdd = useCallback(() => {
    if (props.valor && props.valor > 0) {
      addKey();
      setSoma((prev) => [...prev, Number(props.valor)]);
      resetText('');
    }
  }, [addKey, props.valor, resetText]);

  const header = useMemo(() => {
    return (
      <Grid className={defaultClasses.header}>
        <ModalHeader
          title={'Calculadora'}
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={onCloseClick}
            />
          }
        />
      </Grid>
    );
  }, [defaultClasses.header, onCloseClick]);

  const handleConfirmValue = useCallback(() => {
    const valorSomado = soma.reduce((acc, current) => acc + current, 0) + (props.valor ?? 0);
    props.setValue(props.index, toDecimalString(valorSomado, 2));
    onCloseClick();
  }, [onCloseClick, props, soma]);

  const somatorio = useMemo(() => {
    const digitadoInterno = toDecimal(getFormattedText());

    return (
      <>
        {(soma?.length || 0) > 0 && (
          <Grid className={classes.containerSomatorio}>
            <span>
              Histórico:{' '}
              {soma &&
                soma
                  .filter((item, index) => {
                    if (digitadoInterno > 0 && index === soma.length - 1) {
                      return false;
                    }
                    return true;
                  })
                  .map((item, index) => {
                    return roundTo(item, 2).toString().replace('.', ',') + ' + ';
                  })}
            </span>
          </Grid>
        )}
      </>
    );
  }, [soma, classes.containerSomatorio, getFormattedText]);

  const display = useMemo(() => {
    return (
      <Grid className={classes.contentTela}>
        <span
          style={{ marginBottom: '3em', fontSize: '1rem', fontWeight: '500' }}
        >
          R$
        </span>
        <Typography
          style={{
            fontSize: '3rem',
            fontWeight: 700,
          }}
          ref={valueRef}
        >
          0,00
        </Typography>
      </Grid>
    );
  }, [classes.contentTela]);

  const teclado = React.useMemo(() => {
    return (
      <>
        <Keyboard
          isButtonAddTopKeyboard
          isButtonKeyboardAdd
          handleAdd={handleAdd}
          handleBackSpace={handleBackSpace}
          handleAddValue={handleAddValue}
          handleSubmit={() => { }}
          handleText={handleText}
          isNumeric
          isButtonBackspace
        />
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleText]);

  const confirmarButton = useMemo(() => {
    return (
      <Grid item className={classes.containerItemFooter}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.textPreview}
          onClick={handleConfirmValue}
        >
          <OkIcon tipo="BUTTON_PRIMARY" />
          Confirmar
        </Button>
      </Grid>
    );
  }, [classes.containerItemFooter, classes.textPreview, handleConfirmValue]);

  return (
    <Grid className={defaultClasses.root}>
      {header}
      <Grid className={classNames(classes.containerTela, classes.unselectable)}>
        {somatorio}
        {display}
      </Grid>
      <Grid
        container
        className={classes.unselectable}
        style={{ flex: 1, overflowX: 'hidden' }}
      >
        {teclado}
      </Grid>
      <Grid
        className={classNames(classes.containerFooter, classes.unselectable)}
      >
        {confirmarButton}
      </Grid>
    </Grid>
  );
};

export default CalcFragment;
