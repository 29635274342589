import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginBottom: '8px',
    marginLeft: '8px',
    marginRight: '8px',
    marginTop: '8px',
  },
  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  celulaGrid: {
    marginLeft: theme.spacing(2),
    minWidth: 0,
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },
  celulaGridFull: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  },

}));

