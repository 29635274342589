export const ImpressorasMock = [
    'N_CHINA_POS',
    'N_CIS_PR1800',
    'N_ControlID_PrintID',
    'N_ControlID_PrintID_48',
    'N_DASCOM_DT230',
    'N_DIEBOLD_5970',
    'N_Diebold_TSP143MU',
    'N_DieboldIM453HU',
    'N_DR700',
    'N_DR700_CORTE',
    'N_Elgin_i9',
    'N_Elgin_VOX',
    'N_Epson_T88IV',
    'N_EPSON_TMT20',
    'N_Generica',
    'N_HTML',
    'N_MP4000',
    'N_MP4200',
    'N_MP4200_V2',
    'N_Nitere_Q3X',
    'N_POSTECH_891',
    'N_SWEDA_SI150',
    'N_SWEDA_SI300',
    'N_Tanca_PL2303',
    'N_Tanca_TP450',
    'N_Tanca_TP550',
    'N_Tanca_TP650',
    'POS_A8',
    'POS_A9X0',
    'POS_A930',
    'POS_BLUETOOTH',
    'POS_CLOUDPOS',
    'POS_CUSTOM_FUSION',
    'POS_ELGIN',
    'POS_GERTEC_M55',
    'POS_GPOS700',
    'POS_GPOS800',
    'POS_L400',
    'POS_M8',
    'POS_M10',
    'POS_NEXGO',
    'POS_ROCKCHIP',
    'POS_SUNMI',
    'POS_TXT'
]