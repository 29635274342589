import { KeyValueModel } from "model";
import { EnumTipoMedicamento } from "model/enums/enum-tipo-medicamento";

export const TipoMedicamentoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoMedicamento.Generico, "Genérico"),
    new KeyValueModel(EnumTipoMedicamento.Patente, "Patente"),
    new KeyValueModel(EnumTipoMedicamento.Referencia, "Referência"),
    new KeyValueModel(EnumTipoMedicamento.Similar, "Similar"),
    new KeyValueModel(EnumTipoMedicamento.Outros, "Outros"),
];
