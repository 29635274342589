import { PessoaDocumentoModel } from "model/api/gestao/pessoa/pessoa-documento-model";
import { RepresentanteCliente } from 'model/api/gestao/pessoa/pessoa-model';
import { EnumCadastroTipo } from "model/enums/enum-cadastro-tipo";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";

export class PessoaCadastroFormModel {
    constructor(
        public tpCadastro: EnumCadastroTipo = EnumCadastroTipo.CLIENTE,
        public cpfcnpj: string = '',
        public nome: string = '',
        public documento: PessoaDocumentoModel = new PessoaDocumentoModel(),
        public tipoDocumento: EnumTipoDocumento = EnumTipoDocumento.CarteiraRegistroProfissional,
        public representante: RepresentanteCliente | null = null,
        public representanteId: string = '',
    ) { }
}