import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { VariaveisAmbiente } from 'config';


export function usePutProdutoCategoria() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProdutoCategoria = useCallback(
    (produtoCategoria: ProdutoCategoriaModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Categoria/${produtoCategoria.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(produtoCategoria),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoCategoria,
  };
}
