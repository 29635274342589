import { useCallback } from 'react';
import {
  BarcodeIcon,
  CancelarIcon,
  DashboardIcon,
  DinheiroIcon,
  GarcomIcon,
  EntradaMercadoriaIcon,
  HistoricoIcon,
  ImpressorEtiquetaIcon,
  LogoutIcon,
  OkIcon,
  PermissaoIcon,
  ProdutoIcon,
  RelogioIcon,
  SetorProcessosAddIcon
} from '../icons';
import { EnumMenu } from 'model';
import { IconsHelperProps } from './icons-helper-props';
import { PessoasIcon } from '../icons/pessoas-icon';
import { FinalizadoraIcon } from '../icons/finalizadora-icon';
import { LojaIcon } from '../icons/loja-icon';
import { ConfiguracaoIcon } from '../icons/configuracao-icon';
import { OutrosIcon } from '../icons/outros-icon';
import { ChatIcon } from '../icons/chat-icon';
import { PdvIcon } from '../icons/pdv-icon';
import { RelatorioIcon } from '../icons/relatorio-icon';
import { RelatorioConfigIcon } from '../icons/relatorio-config-icon';
import { VendaIcon } from '../icons/venda-icon';
import { CatalogoIcon } from '../icons/catalogo-icon';
import { KeypadIcon } from '../icons/keypad-icon';
import { PessoaIcon } from '../icons/pessoa-icon';
import { VendedorIcon } from '../icons/vendedor-icon';
import { ContadorIcon } from '../icons/contador-icon';
import { CarrinhoIcon } from '../icons/carrinho-icon';
import { MesaIconNaoPreenchido } from '../icons/mesa-icon-nao-preenchido';
import { GerarComandaIcon } from '../icons/gerar-comanda-icon';
import { SalaoIcon } from '../icons/salao-icon';
import { RenderizacaoIcon } from '../icons/renderizacao-icon';
import { UltimaVendaIcon } from '../icons/ultima-venda-icon';
import { ComandasIcon } from '../icons/comanda-icon';
import { BalcaoIcon } from '../icons/balcao-icon';
import { AbrirRetaguardaIcon } from '../icons/abrir-retaguarda-icon';
import { SincronizarIcon } from '../icons/sincronizar-icon';
import { CrachaIcon } from '../icons/cracha-icon';
import { CardapioIcon } from '../icons/cardapio-icon';
import { NfceIcon } from '../icons/nfce-icon';
import { DeliveryIcon } from '../icons/delivery-icon';
import { AtendimentoIcon } from '../icons/atendimento-icon';
import { GloboPinIcon } from '../icons/globo-pin-icon';
import { useSessaoAtual } from 'services/app';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { MedicamentoIcon } from '../icons/medicamentos-icon';
import { PrateleiraIcon } from '../icons/prateleira-icon';
import { CestaIcon } from '../icons/cesta-icon';

export const IconsHelper = (props: IconsHelperProps) => {
  const { plano } = useSessaoAtual();
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const selectIcon = useCallback(() => {
    switch (props.codigoModulo) {
      case EnumMenu.MASTER_SAIR:
        return <LogoutIcon {...props} />;

      case EnumMenu.ADMINISTRATIVO_GERENCIAMENTO_DE_SESSOES:
        return <RelogioIcon {...props} />
      case EnumMenu.ADMINISTRATIVO_IMPRESSORAS:
        return <ImpressorEtiquetaIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_DADOS_EMPRESA:
        return <LojaIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_ACESSOS_PERMISSOES:
        return <PermissaoIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_AUTENTICACAO:
        return <CrachaIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_CONFIGURACOES:
        return <ConfiguracaoIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_PONTOS_VENDAS:
        return <PdvIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO:
        if (isFarmaceutico) {
          return <PrateleiraIcon {...props} />;
        }
        return <CardapioIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS:
        if (isFarmaceutico) {
          return <ComandasIcon {...props} />;
        }
        return <MesaIconNaoPreenchido {...props} />;
      case EnumMenu.ADMINISTRATIVO_SALOES:
        return <SalaoIcon {...props} />;
      case EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS:
        return <SetorProcessosAddIcon {...props} />;

      case EnumMenu.CADASTRO_PRODUTOS:
        if (isFarmaceutico) {
          return <MedicamentoIcon {...props} />;
        }
        return <ProdutoIcon {...props} />;
      case EnumMenu.CADASTRO_PESSOAS:
        return <PessoasIcon {...props} />;
      case EnumMenu.CADASTRO_FINALIZADORA:
        return <FinalizadoraIcon {...props} />;
      case EnumMenu.CADASTRO_OUTROS:
        return <OutrosIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA:
        return <AbrirRetaguardaIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS:
        return <SincronizarIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSOES:
        return <RelogioIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV:
        return <ConfiguracaoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSAO:
        return <LogoutIcon {...props} />;

      case EnumMenu.RELATORIOS_DASHBOARD:
      case EnumMenu.OPERACIONAL_DENTROVENDA_DASHBOARD:
        return <DashboardIcon {...props} />;
      case EnumMenu.RELATORIOS_GERAL:
        return <RelatorioIcon {...props} />;
      case EnumMenu.RELATORIOS_WIZARD:
        return <RelatorioConfigIcon {...props} />;
      case EnumMenu.RELATORIOS_HISTORICO_VENDAS:
      case EnumMenu.OPERACIONAL_DENTROVENDA_HISTORICO_VENDA:
        return <HistoricoIcon {...props} />;
      case EnumMenu.RELATORIOS_ENTRADA_MERCADORIA:
        return <EntradaMercadoriaIcon {...props} />
      case EnumMenu.RELATORIOS_MANIFESTOS:
        return <GloboPinIcon {...props} />;
      case EnumMenu.RELATORIOS_PERFORMANCE:
        return <RenderizacaoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
        return <KeypadIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR:
        return <CancelarIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE:
        return <PessoaIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA:
        return <OkIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO:
        return <CatalogoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO:
        return <CarrinhoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR:
        return <BarcodeIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_PAGAMENTO:
        return <DinheiroIcon {...props} />;
      case EnumMenu.OPERACIONAL_FORAVENDA_INICIAR_VENDA:
        return <VendaIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR:
        return <VendedorIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA:
        return <MesaIconNaoPreenchido {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS:
        if (isFarmaceutico) {
          return <CestaIcon {...props} />
        }
        return <GarcomIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA:
        return <ComandasIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY:
        return <DeliveryIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY:
        return <AtendimentoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_BALCAO:
        return <BalcaoIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA:
        return <UltimaVendaIcon {...props} />;
      case EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICADOR_PAGER:
        return <ComandasIcon {...props} />;

      case EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA:
        return <GerarComandaIcon {...props} />;

      case EnumMenu.FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS:
        return <ContadorIcon {...props} />;
      case EnumMenu.FISCAL_ATIVAR_REVISAR_DADOS_NFCE:
        return <NfceIcon {...props} />;

      case EnumMenu.SUPORTE_CHAT:
        return <ChatIcon {...props} />;
      default:
        return <></>;
    }
  }, [isFarmaceutico, props]);

  return <>{selectIcon()}</>;
};
