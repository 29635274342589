import { useCallback, useEffect } from 'react';
import { useMovAtual } from './mov-atual';
import { useHistory } from 'react-router-dom';
import {
  CredenciamentoSafra,
  FinalizadoraModel
} from 'model/api/gestao/finalizadora/finalizadora-model';
import { guidEmpty } from 'utils/guid-empty';
import { EnumPagTpTransacao } from 'model';
import { useDevice } from './device';
import { isEmpty } from 'lodash';
import { usePDV } from './pdv';
import { useToastSaurus } from './toast-saurus';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useConfirm } from 'material-ui-confirm';
import { useCadastros } from './cadastros';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from './events/event-tools';
import { toDecimalString } from 'utils/to-decimal';
import { stringNumeros } from 'utils/string-numeros';

export const usePayment = () => {
  // HOOKS
  const {
    getMov,
    iniciarPagamento,
    carregandoPgto,
    carregando: carregandoMov
  } = useMovAtual();
  const { tpPayments, getPayments, carregando: loadingDevice } = useDevice();
  const { selClienteDesativado } = usePDV();
  const { showToast } = useToastSaurus();
  const { push, location } = useHistory();
  const confirm = useConfirm();
  const { abrirCadastroFinalizadora, abrirParcelamento } = useCadastros();
  const { callEvent } = useEventTools();

  //   AUX
  const loading = carregandoMov || carregandoPgto || loadingDevice;

  useEffect(() => {
    if (isEmpty(tpPayments)) {
      getPayments();
    }
  }, [getPayments, tpPayments]);

  const inserirPagamento = useCallback(
    async (paymentMethod: FinalizadoraModel, valorPaga: number = 0, numeroParcelas: number = 0) => {
      try {
        const mov = getMov();
        if (!mov?.vNF || mov?.vNF === 0) {
          throw new Error(
            'Valor de nota zerado, não é possivel realizar um pagamento'
          );
        }

        const pagamento = {
          adquirente: '',
          bandeira: '',
          nomeCartao: '',
          numCartao: '',
          tid: guidEmpty(),
          tpTransacao: paymentMethod.tpTransacao,
          modPagamento: paymentMethod.tpMod,
          nParcelas: numeroParcelas,
          dhTransacao: new Date(),
          cAut: '',
          codNsu: '',
          descontoAplicado: 0,
          acrescimoAplicado: 0,
          vPag: valorPaga,
          envioAPI: '',
          retornoAPI: '',
          status: 0,
          viaCliente: '',
          viaLojista: '',
          pagamentoId: paymentMethod.id,
          vTroco: 0,
          cnpj: paymentMethod.cnpjCred,
          cancelado: false
        };

        const credenciais = paymentMethod.credenciais
          ? JSON.parse(paymentMethod.credenciais)
          : new CredenciamentoSafra();
        if (paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY) {
          push({
            pathname: '/venda-simples/processando-pagamento',
            state: {
              modoPagamento: pagamento,
              credenciais
            }
          });
          return;
        }

        await iniciarPagamento(
          pagamento,
          true,
          credenciais,
          paymentMethod.credenciado,
          tpPayments
        );

        const novaMov = getMov();

        if (novaMov!.vPago >= novaMov!.vNF) {
          if (!novaMov?.clienteIdentificado && !!selClienteDesativado()) {
            push('/venda-simples/identificar-cliente');
            return;
          }

          return push('/venda-simples/finalizar-venda');
        } else {
          return push('/venda-simples/novo-pagamento');
        }
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      getMov,
      iniciarPagamento,
      tpPayments,
      push,
      selClienteDesativado,
      showToast
    ]
  );

  const callPayment = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      const mov = getMov();
      const valorAPagar = (mov?.vNF ?? 0) - (mov?.vPago ?? 0);
      
      const arrayParcelamento = () => {
        const arrayParcelamento: number[] = [];
        for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
          arrayParcelamento.push(i);
        }
        return arrayParcelamento;
      };

      const arrayParc = arrayParcelamento().filter((item: number) => {
        return valorAPagar / item >= paymentMethod.vMinParc;
      });

      if (
        paymentMethod.qMaxParc > 1 &&
        paymentMethod.vMinParc < (mov?.vNF ?? 0) - (mov?.vPago ?? 0) &&
        arrayParc.length !== 1
      ) {
        abrirParcelamento(
          async (parcelas: number) => {
            await inserirPagamento(paymentMethod, valorAPagar, parcelas);
            callEvent(AppEventEnum.AlterarDisplayKeybordPayment, {valor: stringNumeros(toDecimalString(valorAPagar))});
          },
          1,
          valorAPagar,
          paymentMethod
        );
      } else {
        await inserirPagamento(paymentMethod, valorAPagar);
        callEvent(AppEventEnum.AlterarDisplayKeybordPayment, {valor: stringNumeros(toDecimalString(valorAPagar))});
      }
    },
    [abrirParcelamento, callEvent, getMov, inserirPagamento]
  );

  const startPayment = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      if (
        paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY &&
        VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR
      ) {
        const credenciais: CredenciamentoSafra | null =
          paymentMethod.credenciais
            ? JSON.parse(paymentMethod.credenciais)
            : null;

        if (
          !credenciais ||
          isEmpty(credenciais.merchantToken) ||
          isEmpty(credenciais.codigoAtivacao)
        ) {
          confirm({
            title: 'TEF Detectado',
            description:
              'Aparentemente você está com um dispositivo TEF conectado ao seu aparelho, porém a sua Forma de Pagamento não tem credenciais para usá-la, deseja configurar?',
            cancellationText: 'Usar outra forma'
          }).then(() =>
            abrirCadastroFinalizadora(
              paymentMethod.id,
              location.pathname,
              false,
              true
            )
          );
          return;
        }
      }
      await callPayment(paymentMethod);
    },
    [abrirCadastroFinalizadora, confirm, callPayment, location.pathname]
  );

  return {
    startPayment,
    loading,
    tpPayments
  };
};
