import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mov-em-andamento-styles';
import { CancelarIcon, VoltarIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { useCadastros } from 'services/app';

interface DialogMovEmAndamentoProps {
  openned: boolean;
  quantidadeProdutos: number;
}

export const DialogMovEmAndamento = ({
  openned,
  quantidadeProdutos
}: DialogMovEmAndamentoProps) => {
  const classes = useStyles();
  const { push: historyPush } = useHistory();
  const { fecharDialogMovEmAndamento } = useCadastros();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Movimentação em andamento"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Typography style={{ marginBottom: '16px' }}>
          Você possui uma venda em andamento com {quantidadeProdutos}{' '}
          {quantidadeProdutos === 1 ? 'produto' : 'produtos'}. Não será possivel
          lançar produtos diretamente no pedido atual. Por favor selecione uma
          das opções para continuar.
        </Typography>
        <Box className={classes.cardContent}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  historyPush('/venda-simples/carrinho');
                  fecharDialogMovEmAndamento();
                  return;
                }}
              >
                <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                Ir Para o Carrinho
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => {
                  historyPush('/venda-simples/cancelar');
                  fecharDialogMovEmAndamento();
                  return;
                }}
              >
                <CancelarIcon tipo="BUTTON_PRIMARY" />
                Cancelar venda
              </Button>
            </Grid>

            <Grid item xs={12}>
              {fecharDialogMovEmAndamento && (
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    fecharDialogMovEmAndamento();
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
