import { guidEmpty } from "utils/guid-empty";

export class ConviteFormModel {
    constructor(
        public id: string = guidEmpty(),
        public email: string = '',
        public telefone: string = '',
        public senha: string = '',
        public confirmarSenha: string = '',
    ) { }
}