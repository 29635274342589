import { makeStyles } from '@material-ui/core';

export const useStylesInicial = makeStyles(() => ({
    containerScann: {
        minWidth: '100%',
        background: 'black',

        "& .drawingBuffer": {
            display: 'none'
        },

        '& video': {
            minWidth: '100%',
            maxWidth: '100%',
            height: '100%',
            marginBottom: '-5px',
            objectFit: 'cover'
        }
    },
    containerPreviewInfo: {
        width: '100%',
        height: '60px',
        background: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px'
    },
    textPreview: {
        color: '#fff',
        fontSize: '16px',
        lineHeight: '31px',
        fontWeight: 600
    },
    semCameraIcon: {
        '& svg': {
            width: '70px',
            height: '70px',
        }
    }
}));

