import { Box, Grid } from '@material-ui/core';
import { makeUtilClasses } from '../../../../theme';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CloseIcon } from 'views/components/icons/close-icon';
import { useStyles } from './form-pesquisar-pedido-styles';

export interface DefaultFormProps {
  pesquisa: string;
  setPesquisa: (values: string) => void;
}

export const FormPesquisarPedido = (props: DefaultFormProps) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles()

  return (
    <>
      <div className={utilClasses.formContainer}>
        <Box my={2}>
          <Grid item xs={12}>
            <TextFieldSaurus
              label="Código do Pedido ou Nome do Cliente"
              fullWidth={true}
              tipo="TEXTO"
              size="small"
              value={props.pesquisa}
              variant="outlined"
              endAdornmentButton={props.pesquisa.length > 0 ? <CloseIcon class={classes.iconReset} tipo="INPUT" onClick={() => props.setPesquisa('')} /> : <></>}
              onChange={(event) => {
                if (event) {
                  props.setPesquisa(event.target.value);
                }
              }}
            />
          </Grid>
        </Box>
      </div>
    </>
  );
};
