import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormSetorEditValidation = () => {
  const FormSetorEditValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        descricao: Yup.string().required('Descrição do Setor é Obrigatória'),
      })
    )
  }, [])
  return {
    FormSetorEditValidationYup,
  }
}
