import {
  Grid,
  Typography,
  Divider,
  Tooltip,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import { FiltroComanda } from 'model/api/gestao/pedido/pedido-comanda-filtro';
import { memo, useState } from 'react';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { FiltroIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../footer-styles';
import classNames from 'classnames';

interface FooterComandaListProps {
  emAtendimento: number;
  clientes: number;
  ociosas: number;
  tooltipFiltro: string;
  setFiltroComanda: (value: FiltroComanda) => void;
  filtroComanda: FiltroComanda;
}

export const FooterComandaList = ({
  emAtendimento,
  ociosas,
  clientes,
  tooltipFiltro,
  setFiltroComanda,
  filtroComanda,
}: FooterComandaListProps) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  return (
    <Grid className={classes.footerContainer} style={{ height: '60px' }}>
      {isShowFilter && (
        <div
          className={classes.arrowDown}
          style={{ right: isMobile ? '3%' : '12px' }}
        >
          <div className={classes.containerArrow}>
            <div className={classes.filtroContainer}>
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Sem Filtro</p>
                <FormControlLabel
                  label=""
                  checked={filtroComanda.semFiltro}
                  onClick={() => {
                    setFiltroComanda({
                      semFiltro: true,
                      meusAtendimentos: false,
                      comandasOciosas: false,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
              <Divider className={classes.divider} style={{ height: '1px' }} />
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Meus Atendimentos</p>
                <FormControlLabel
                  label=""
                  checked={filtroComanda.meusAtendimentos}
                  onClick={() => {
                    setFiltroComanda({
                      semFiltro: false,
                      meusAtendimentos: true,
                      comandasOciosas: false,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
              <Divider className={classes.divider} style={{ height: '1px' }} />
              <div className={classes.filtroItem}>
                <p className={classes.textFiltroItem}>Comandas Ociosas</p>
                <FormControlLabel
                  label=""
                  checked={filtroComanda.comandasOciosas}
                  onClick={() => {
                    setFiltroComanda({
                      semFiltro: false,
                      meusAtendimentos: false,
                      comandasOciosas: true,
                    });
                    setIsShowFilter(false);
                  }}
                  control={<SaurusRadio />}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Divider className={classes.divider} />

      <Grid className={classes.itemsFooterContainer}>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Em Atendimento</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {emAtendimento}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Ociosas</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {ociosas}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Typography color="primary">Clientes</Typography>
          <Typography className={classes.textTotalInfo} color="textPrimary">
            {clientes}
          </Typography>
        </Grid>
        <Grid className={classes.itemsFooter}>
          <Tooltip arrow title={tooltipFiltro} placement="left">
            <Button
              variant="contained"
              className={classNames(classes.button, 'round')}
              onClick={() => setIsShowFilter(!isShowFilter)}
            >
              <Grid>
                <FiltroIcon tipo="BUTTON_PRIMARY" />
                <Typography
                  variant="caption"
                  className={classes.textButtonFiltro}
                  color="primary"
                >
                  Filtro
                </Typography>
              </Grid>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(FooterComandaList);
