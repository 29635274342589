import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "./events/event-tools";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { AppEventEnum } from "model/enums/enum-app-event";

interface useNFCProps{
    defaultNFCState?: boolean;
}

export const useNFC = ({
    defaultNFCState = false
}: Partial<useNFCProps> = {}) => {
    const { addHandler, callEvent, removeHandler } = useEventTools();

    const [temNFC, setTemNFC] = useState<boolean>(defaultNFCState)

    const verificarNFC = useCallback(() => {
        if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA) {
            callEvent(AppEventEnum.VerificarNFC, {})
        }
    }, [callEvent])

    useEffect(() => {
        addHandler(AppEventEnum.EnviarNFC, (resp: any) => {
            setTemNFC(resp.temNFC)
        })

        return () => {
            removeHandler(AppEventEnum.EnviarNFC, (resp: any) => {
                setTemNFC(resp.temNFC)
            })
        }
    }, [addHandler, removeHandler])

    return {
        verificarNFC,
        temNFC
    }
}