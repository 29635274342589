import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const CalendarioIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M12 0C10.9062 0 10 0.90625 10 2V4H4C3.47656 4 2.94531 4.19141 2.57031 4.57031C2.19141 4.94531 2 5.47656 2 6V46C2 46.5234 2.19141 47.0547 2.57031 47.4336C2.94531 47.8086 3.47656 48 4 48H46C46.5234 48 47.0547 47.8086 47.4336 47.4336C47.8086 47.0547 48 46.5234 48 46V6C48 5.47656 47.8086 4.94531 47.4336 4.57031C47.0547 4.19141 46.5234 4 46 4H40V2C40 0.90625 39.0938 0 38 0H36C34.9062 0 34 0.90625 34 2V4H16V2C16 0.90625 15.0938 0 14 0H12ZM12 2H14V8H12V2ZM36 2H38V8H36V2ZM4 6H10V8C10 9.09375 10.9062 10 12 10H14C15.0938 10 16 9.09375 16 8V6H34V8C34 9.09375 34.9062 10 36 10H38C39.0938 10 40 9.09375 40 8V6H46V13H4V6ZM4 15H46V46H4V15ZM10 19V42H40V19H10ZM12 21H17V26H12V21ZM19 21H24V26H19V21ZM26 21H31V26H26V21ZM33 21H38V26H33V21ZM12 28H17V33H12V28ZM19 28H24V33H19V28ZM26 28H31V33H26V28ZM33 28H38V33H33V28ZM12 35H17V40H12V35ZM19 35H24V40H19V35ZM26 35H31V40H26V35ZM33 35H38V40H33V35Z" />
      </DefaultIcon>
    </>
  );
};
