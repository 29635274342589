import { Grid } from '@material-ui/core';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { CardNaoEncontrado } from 'views/components/cards';
import CardBalcaoSelecionar from 'views/components/cards/card-balcao-selecionar/card-balcao-selecionar';
import CardMesaSelecionar from 'views/components/cards/card-mesa-selecionar/card-mesa-selecionar';
import { InformacaoIcon } from 'views/components/icons';

export interface SelecionarMesaGridProps {
  list: Array<MesasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardClicked: (model: MesasModel) => any;
  salao: SaloesModel | undefined;
  onClickBalcao: (balcao: SaloesModel) => void;
}

export const SelecionarMesaListData = (props: SelecionarMesaGridProps) => {
  const onCardClicked = (model: MesasModel) => {
    props.onCardClicked(model);
  };

  const onClickBalcao = (balcao: SaloesModel) => {
    props.onClickBalcao(balcao);
  };
  
  return (
    <>
        <Grid item xs={12}>
          {props.salao &&
            !props.carregando &&
            props.salao.balcao.codigo === EnumBalcaoSalao.UTILIZA && (
              <CardBalcaoSelecionar
                onClick={onClickBalcao}
                model={props.salao}
                key={props.salao.id}
              />
            )}
        </Grid>
      <Grid item xs={12}>
        {props.list.length === 0 && !props.carregando && (
          <CardNaoEncontrado
            mensagem="Nenhuma mesa foi encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
            key="MesaNaoEncontrada"
          />
        )}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.list.length > 0 &&
          props.list.map((mesa) => {
            return (
              <CardMesaSelecionar
                selected={
                  props.selectedList.filter((item) => item === mesa.id).length >
                  0
                    ? true
                    : false
                }
                onClick={onCardClicked}
                model={mesa}
                key={mesa.id}
              />
            );
          })}
      </Grid>
    </>
  );
};
