import { VariaveisAmbiente } from 'config';
import { EnviarSMSModel } from 'model/api/gestao/enviar-cupom/enviar-cupom-sms';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePostEnviarCupomVendaSMS() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postEnviarCupomVendaSMS = useCallback(
        (data: EnviarSMSModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/envio/sms`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(data),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEnviarCupomVendaSMS,
    };
}
