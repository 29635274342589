import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { DocReferenciadoEdit } from "./components/doc-referenciado-edit/doc-referenciado-edit";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";

export interface DocReferenciadoModalProps extends ModalProps {
  mov: VendaCompletaModel
}

export const DocReferenciadoModal = (props: DocReferenciadoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && <DocReferenciadoEdit mov={props.mov} id={props.id} />}
    </DefaultModal>
  );
};
