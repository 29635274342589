import { TipoDocumentoOrgaoExpedidorEnum } from "model/enums/enum-orgao-expedidor";
import { EnumSexo } from "model/enums/enum-sexo";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";

export class CompradorPacienteFormModel {
    constructor(
        public pessoaId: string | null = null,
        public nome: string = '',
        public cpfcnpj: string = '',
        public sexo: EnumSexo = EnumSexo.NaoInformado,
        public telefone: string = '',
        public dataNascimento: Date | string = '',
        public tipoDocumento: number = EnumTipoDocumento.CarteiraIdentidade,
        public documento: string = '',
        public orgaoExpedidor: number = TipoDocumentoOrgaoExpedidorEnum.SecretariaSegurancaPublica,
        public ufOrgaoExpedidor: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public uf: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
    ) { }
}

export class CompradorPacientePreCadastroFormModel {
    constructor(
        public paciente: CompradorPacienteFormModel = new CompradorPacienteFormModel(),
        public comprador: CompradorPacienteFormModel = new CompradorPacienteFormModel(),
        public compradorPacienteMesmaPessoa: boolean = false,
    ) { }
}
