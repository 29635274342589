import { makeStyles } from "@material-ui/core";

export const useButtonFabMenuStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tootipTitle: {
        whiteSpace: 'nowrap',
        color: theme.palette.grey[700]
    }
}))