import { VariaveisAmbiente } from "config";
import { useApiBase } from "data/api/base";
import { useCallback } from "react";

export function useGetMovPagamentoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMovPagamentoById = useCallback(
        (id: string, empresaId: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/pagamento/${id}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMovPagamentoById,
    };
}