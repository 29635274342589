import { isEmpty } from "lodash";
import { useCallback } from "react";
import { GestaoStorageKeys, useGestaoStorage } from ".";
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { useEventTools } from "./events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';
import { EnumMenuPrincipalModelo } from 'model/enums/enum-menu-principal-modelo';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';

export function useMenuStorage() {
    const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
    const { callEvent } = useEventTools()

    const get = useCallback((): MenuPrincipalModel => {
        let menu = getRegistro(GestaoStorageKeys.ConfiguracoesMenu, false) as MenuPrincipalModel;
        if (isEmpty(menu)) {
            menu = {
                situacao: EnumMenuPrincipalStatus.Fechado,
                modelo: EnumMenuPrincipalModelo.Completo,
                modo: EnumMenuPrincipalModo.Retaguarda
            };
            setRegistro(GestaoStorageKeys.ConfiguracoesMenu, menu, false);
            callEvent(AppEventEnum.MenuAlterado, menu)
        }
        return menu;
    }, [callEvent, getRegistro, setRegistro]);

    const persist = useCallback((model: MenuPrincipalModel) => {
        if (model === undefined) {
            delRegistro(GestaoStorageKeys.ConfiguracoesMenu, false);
        } else {
            setRegistro(GestaoStorageKeys.ConfiguracoesMenu, model, false);
            callEvent(AppEventEnum.MenuAlterado, model)
        }
    }, [callEvent, delRegistro, setRegistro]);

    return {
        get,
        persist
    };
}
