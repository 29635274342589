import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers"
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { MenuIcon } from "views/components/icons";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { useThemeQueries } from "views";
import { FinalizadoraListSearchProps } from "../finalizadora-list/finalizadora-list-search-props";

export interface PessoaHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: FinalizadoraListSearchProps) => any;
}

export const FinalizadoraHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
}: PessoaHeaderProps) => {

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () => (
      isMobile ?
        <ButtonPrivateHeader icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>} tooltip="Menu" onClick={abrirMenu}></ButtonPrivateHeader> :
        null
    ),
    [isMobile, abrirMenu]
  );

  // const rightArea = useCallback(
  //   () => (
  //     <ButtonPrivateHeader
  //       icon={
  //         openPesquisa ?
  //           <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
  //           :
  //           <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>}
  //       tooltip={!openPesquisa ? "Pesquisar" : "Voltar"}
  //       onClick={() => setOpenPesquisa(!openPesquisa)}
  //     ></ButtonPrivateHeader>
  //   ),
  //   [setOpenPesquisa, openPesquisa]
  // );

  // const closePesquisaWrapper = useCallback(() => {
  //   setOpenPesquisa(false);
  // }, [setOpenPesquisa]);

  // const onPesquisar = useCallback(
  //   (props: FinalizadoraListSearchProps) => {
  //     setSearchProps(props);
  //   },
  //   [setSearchProps]
  // );

  return (
    <>
      <PrivatePageHeader
        title="Formas de Pagamento"
        leftArea={leftArea()}
      />
    </>
  );
};
