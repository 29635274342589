import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const KeypadIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 5 3 C 3.90625 3 3 3.90625 3 5 L 3 9 C 3 10.09375 3.90625 11 5 11 L 13 11 C 14.09375 11 15 10.09375 15 9 L 15 5 C 15 3.90625 14.09375 3 13 3 Z M 21 3 C 19.90625 3 19 3.90625 19 5 L 19 9 C 19 10.09375 19.90625 11 21 11 L 29 11 C 30.09375 11 31 10.09375 31 9 L 31 5 C 31 3.90625 30.09375 3 29 3 Z M 37 3 C 35.90625 3 35 3.90625 35 5 L 35 9 C 35 10.09375 35.90625 11 37 11 L 45 11 C 46.09375 11 47 10.09375 47 9 L 47 5 C 47 3.90625 46.09375 3 45 3 Z M 5 5 L 13 5 L 13 9 L 5 9 Z M 21 5 L 29 5 L 29 9 L 21 9 Z M 37 5 L 45 5 L 45 9 L 37 9 Z M 5 15 C 3.90625 15 3 15.90625 3 17 L 3 21 C 3 22.09375 3.90625 23 5 23 L 13 23 C 14.09375 23 15 22.09375 15 21 L 15 17 C 15 15.90625 14.09375 15 13 15 Z M 21 15 C 19.90625 15 19 15.90625 19 17 L 19 21 C 19 22.09375 19.90625 23 21 23 L 29 23 C 30.09375 23 31 22.09375 31 21 L 31 17 C 31 15.90625 30.09375 15 29 15 Z M 37 15 C 35.90625 15 35 15.90625 35 17 L 35 21 C 35 22.09375 35.90625 23 37 23 L 45 23 C 46.09375 23 47 22.09375 47 21 L 47 17 C 47 15.90625 46.09375 15 45 15 Z M 5 17 L 13 17 L 13 21 L 5 21 Z M 21 17 L 29 17 L 29 21 L 21 21 Z M 37 17 L 45 17 L 45 21 L 37 21 Z M 5 27 C 3.90625 27 3 27.90625 3 29 L 3 33 C 3 34.09375 3.90625 35 5 35 L 13 35 C 14.09375 35 15 34.09375 15 33 L 15 29 C 15 27.90625 14.09375 27 13 27 Z M 21 27 C 19.90625 27 19 27.90625 19 29 L 19 33 C 19 34.09375 19.90625 35 21 35 L 29 35 C 30.09375 35 31 34.09375 31 33 L 31 29 C 31 27.90625 30.09375 27 29 27 Z M 37 27 C 35.90625 27 35 27.90625 35 29 L 35 33 C 35 34.09375 35.90625 35 37 35 L 45 35 C 46.09375 35 47 34.09375 47 33 L 47 29 C 47 27.90625 46.09375 27 45 27 Z M 5 29 L 13 29 L 13 33 L 5 33 Z M 21 29 L 29 29 L 29 33 L 21 33 Z M 37 29 L 45 29 L 45 33 L 37 33 Z M 21 39 C 19.90625 39 19 39.90625 19 41 L 19 45 C 19 46.09375 19.90625 47 21 47 L 29 47 C 30.09375 47 31 46.09375 31 45 L 31 41 C 31 39.90625 30.09375 39 29 39 Z M 21 41 L 29 41 L 29 45 L 21 45 Z" />
            </DefaultIcon>
        </>
    );
};
