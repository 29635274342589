import { UsuarioEditPutModel } from 'model/app/forms/perfil/perfil-usuario-edit-form-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutPerfilUsuario() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putPerfilUsuario = useCallback(
		(usuario: UsuarioEditPutModel) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/UsuarioEmpresaPerfil/${usuario.id}`,
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				data: JSON.stringify(usuario),
				enviarTokenUsuario: true,
			}),
		[invocarApi]
	);
	return {
		...outrasPropriedades,
		putPerfilUsuario
	}
}