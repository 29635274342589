import { EnumTipoDocumento } from "model/enums/enum-tp-documento";

export class PrescritorPreCadastroFormModel {
    constructor(
        public pessoaId: string | null = null,
        public nome: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public municipio: string = '',
        public codMunicipio: number = 0,
        public uf: string = '',
        public especialidade: string = '',
        public tipoDocumento: EnumTipoDocumento = 1,
        public documento: string = '',
        public orgaoExpedidor: number = 1,
        public ufOrgaoExpedidor: string = '',
        public cpfcnpj: string = '',
    ) { }
}
