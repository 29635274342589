import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoFiscalById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoFiscalById = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Fiscal/${id}`,
        method: 'GET',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoFiscalById
  };
}
