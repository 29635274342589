import { guidEmpty } from "utils/guid-empty";

export class PontosVendaEditConfiguracoesFormModel {
    constructor(
        public id: string = guidEmpty(),
        public cod: number = 0,
        public descConfig: string = "",
        public vConfig: string = "",
        public grupoConfig: string = '',
        public vCgrupoConfigonfig: string = '',
        public vMin: number = 0,
        public vMax: number = 0,
        public tpControle: number = 0,
        public tpTxt: string = '',
        public itemDrop: string = '',
        public ordem: number = 0,
        public editado: boolean = false,
    ) { }
}