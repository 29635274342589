
export class DashboardAdminVendedoresModel {
    constructor(
        public emitDoc: string = '',
        public emitNome: string = '' ,
        public data: number = 0,
        public hora: number = 0,
        public nome: string = '',
        public qtdVendas: number = 0,
        public vTotal: number = 0,
    ) { }
}