import { Button, Grid } from "@material-ui/core";
import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect } from "react";
import { useToastSaurus } from "services/app";
import { useCadastros } from "../../../../../../services/app/hooks/cadastros";
import { ModalHeader } from "../../../components/modal-header/modal-header";
import { useModalStyles } from "../../../utils/modal-styles";
import { VoltarIcon } from "../../../../icons/voltar-icon";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header/button-modal-header";
import { SalvarEditarIcon } from "../../../../icons/salvar-editar-icon";
import { SalvarNovoIcon } from "../../../../icons/salvar-novo-icon";
import { CircularLoading } from "../../../../utils";
import classNames from "classnames";
import { DepositoModel } from "model/api/gestao/deposito/deposito-model";
import { usePostDeposito, usePostDepositoEmpresa } from "data/api/gestao/deposito";
import { FormDepositoCadastro } from "views/components/form/deposito/form-deposito-cadastro/form-deposito-cadastro";
import { useSessaoAtual } from 'services/app';
import { DepositoEmpresaPostModel } from 'model/api/gestao/deposito/deposito-empresa-model-post';
import { guidEmpty } from 'utils/guid-empty';

export const DepositoCadastro = () => {

  const { postDeposito, carregando } = usePostDeposito();
  const { postDepositoEmpresa, carregando: carregandoPost } = usePostDepositoEmpresa();
  const { fecharCadastroDepositos, abrirCadastroDepositos } = useCadastros();


  const classes = useModalStyles();

  const cadDepositoRef =
    useRef<DefaultFormRefs<DepositoModel>>(null);
  const redirect = useRef<boolean>(true);
  const { getPessoa, usuario } = useSessaoAtual()

  const { showToast } = useToastSaurus();

  const saveDepositoEmpresa = useCallback(async (empresaId: string, depositoId: string) => {
    const res = await postDepositoEmpresa(new DepositoEmpresaPostModel(
      guidEmpty(),
      getPessoa().pessoa?.contratoId ?? '',
      empresaId,
      depositoId
    ))

    if (res.erro) throw res.erro

  }, [getPessoa, postDepositoEmpresa])

  const saveNewDeposito = useCallback(
    async (model: DepositoModel) => {
      try {
        const ret = await postDeposito(model);
        if (ret.erro) {
          throw ret.erro;
        }

        if(usuario?.empresa.length === 1) {
          await saveDepositoEmpresa(usuario?.empresa[0].Id, ret.resultado?.data.id)
        }

        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroDepositos(ret.resultado?.data.id, "", true);
          }, 1);
        }
        if (!redirect.current) {
          showToast("success", "Novo depósito adicionado!", 2000, "bottom-center", 60);
        }

        if (!redirect.current) {
          cadDepositoRef.current?.resetForm();
        }
      } catch (e: any) {
        showToast("error", e.message);
        cadDepositoRef.current?.fillForm(model);
      }
    },
    [postDeposito, usuario?.empresa, saveDepositoEmpresa, abrirCadastroDepositos, showToast]
  );
  const handleSubmit = useCallback(
    async (model: DepositoModel) => {

      model.contratoId = getPessoa()?.pessoa?.contratoId ?? ''
      return saveNewDeposito(model)
    },
    [saveNewDeposito, getPessoa]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadDepositoRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadDepositoRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroDepositos();
  }, [fecharCadastroDepositos]);

  return (
    <div className={classes.root}>
      {carregando || carregandoPost ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Cadastro de Depósito"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando || carregandoPost ? classes.contentFormsLoading : undefined
          )}
        >
          <FormDepositoCadastro
            ref={cadDepositoRef}
            showLoading={false}
            loading={carregando || carregandoPost}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando || carregandoPost}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando || carregandoPost}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default DepositoCadastro;
