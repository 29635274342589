export enum TipoDocumentoOrgaoExpedidorEnum {
    ConselhoRegionalAdministracao = 1,
    ConselhoRegionalDeEconomia,
    ConselhoRegionalEngenhariaAgronomia,
    ConselhoRegionalFarmacia,
    DiretoriaGeralPoliciaCivil,
    DepartamentoPoliciaFederal,
    InstitutoIdentificacaoRolandoMenezesPaiva,
    InstitutoFelixPacheco,
    ImprensaNacional,
    Junta,
    MinisterioAeronautica,
    MinisterioExercito,
    MinisterioMarinha,
    OrdemAdvogadosBrasil,
    SecretariaEstadualJusticaSegurancaPublica,
    SecretariaEstadoSeguranca,
    SecretariaEstadoSegurancaPublica,
    SecretariaJusticaSeguranca,
    SecretariaJusticaTrabalhoCidadania,
    SecretariaSegurancaInformacaoPoliciaTecnica,
    SecretariaSegurancaPublica,
    VaraCivil,
    VaraMenores,
    PoliciaMilitar,
    InstitutoTavaresBuril,
    ConselhoRegionalMedicina,
    CorpoBombeirosMilitar,
    DetranDiretoriaIdentificacaoCivil,
    ConselhoFederalPsicologia,
    ConselhoRegionalOdontologia,
    ConselhoRegionalEnfermagem,
    ConselhoFederalNutricionistas,
    MinisterioRelacoesExteriores,
    ConselhoRegionalCorretoresImoveis,
    ConselhoRegionalBiologia,
    ConselhoRegionalNutricao,
    ConselhoFederalEnfermagem,
    ConselhoRegionalContabilidade,
    ConselhoRegionalPsicologia,
    ConselhoRegionalQuimica,
    AgenciaNacionalVigilanciaSanitaria,
    GovernoEstado,
    Prefeitura,
    ConselhoRegionalBiomedicina,
    InstitutoPereiraFaustino,
    ConselhoRegionalFisioterapiaTerapiaOcupacional,
    ConselhoRegionalMedicinaVeterinaria,
    MinisterioTrabalhoEmprego,
    ConselhoRegionalFonoaudiologia,
    ConselhoRegionalEconomia,
    SecretariaDesenvolvimentoSocial,
    SecretariaReceitaFederal
}