
export class PagamentosFormModel {
    constructor(
        public vProds: number = 0,
        public vDesc: number = 0,
        public vOutros: number = 0,
        public vImpostos: number = 0,
        public vSeg: number = 0,
        public vFrete: number = 0,
        public vTotal: number = 0,
        public vPago: number = 0,
        public vTroco: number = 0,
        public vAReceber: number = 0,
    ) { }
}