import { KeyValueModel } from "model/api";
import { EnumCadastroStatus } from "model/enums";

export const StatusCadastroMock: KeyValueModel[] = [
    new KeyValueModel(EnumCadastroStatus.LIBERADO, "Ativado"),
    new KeyValueModel(EnumCadastroStatus.BLOQUEADO, "Bloqueado"),
    new KeyValueModel(EnumCadastroStatus.DESATIVADO, "Desativado"),
];

export const StatusCadastroMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumCadastroStatus.LIBERADO, "Ativado"),
    new KeyValueModel(EnumCadastroStatus.BLOQUEADO, "Bloqueado"),
    new KeyValueModel(EnumCadastroStatus.DESATIVADO, "Desativado"),
];

export const StatusCadastroMockFiltro: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumCadastroStatus.LIBERADO, "Ativado"),
    new KeyValueModel(EnumCadastroStatus.DESATIVADO, "Desativado"),
]