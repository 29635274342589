import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { MedicamentoPostModel } from "model/api/gestao/medicamento/medicamento-model";
import { VariaveisAmbiente } from "config";


export function usePutMedicamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putMedicamento= useCallback(
    (medicamento: MedicamentoPostModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/Medicamento`,
        method: 'PUT',
        enviarTokenUsuario: true,
        data: medicamento
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putMedicamento,
  };
}