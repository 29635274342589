import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useFormDepositoValidation } from './form-deposito-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';

export const FormDepositoCadastro = forwardRef<
  DefaultFormRefs<DepositoModel>,
  DefaultFormProps<DepositoModel>
>(
  (
    { loading, ...props }: DefaultFormProps<DepositoModel>,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputDescricao = useRef<HTMLInputElement>(null);
    const [initialValues, setInitialValues] =
      useState<DepositoModel>(
        new DepositoModel(),
      );
    const { FormDepositoYupValidation } =
      useFormDepositoValidation();
    const { isMobile } = useThemeQueries();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
    } = useForm<DepositoModel>({
      defaultValues: { ...initialValues },
      resolver: yupResolver(FormDepositoYupValidation),
      criteriaMode: 'all',
      mode: 'onChange',
    });

    const onSubmit = (values: DepositoModel) => {
      const model = picker<DepositoModel>(
        values,
        new DepositoModel(),
      );
      props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit)();
      },
      resetForm: () => {
        setInitialValues(new DepositoModel());
        reset(new DepositoModel());

        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
      fillForm: () => {
        if (!isMobile) {
          refInputDescricao.current?.focus();
        }
      },
    }));
    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {loading && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="NORMAL" />
              </div>
            ) : null}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        fullWidth
                        autoComplete='new-password'
                        label="Nome do Depósito"
                        variant="outlined"
                        error={Boolean(
                          errors.nome && errors.nome.message,
                        )}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
          </div>
        </Box>
      </>
    );
  },
);
