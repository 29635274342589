import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormSituacaoManifestoAcaoValidation = () => {
  const FormSituacaoManifestoAcaoValidation = useMemo(() => {
    return (
      Yup.object().shape({
        justificativa: Yup.string().required('Informe uma Justificativa.')
      })
    )
  }, [])
  return {
    FormSituacaoManifestoAcaoValidation,
  }
}