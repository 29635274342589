import { Button, Divider, Grid, Grow, Typography } from '@material-ui/core';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { LixoIcon, OkIcon, VoltarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../dialog-taxa-servico-carrinho-styles'
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { roundTo } from 'utils/round-to';
import { toDecimal } from 'utils/to-decimal';
import { useConfirm } from 'material-ui-confirm';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { setTimeout } from 'timers';

type Props = {
    closeModal: () => void
    vendedor: MovSimplesProdutoModel,
    voltar: (valor: number) => void;
    inativarProduto: (produto: MovSimplesProdutoModel) => void;
    reativarProduto: (produto: MovSimplesProdutoModel) => void;
}
export const AlterarTaxa = ({ closeModal, vendedor, ...props }: Props) => {

    const classes = useStyles();
    const [value, setValue] = useState<number>(vendedor.vFinal)
    const { showToast } = useToastSaurus()
    const { isMobile } = useThemeQueries()
    const inputRef = useRef<HTMLInputElement>()
    const { alterarProduto } = useMovAtual()
    const confirm = useConfirm()
    const [messageError, setMessageError] = useState<string>('')
    const { theme } = useThemeQueries()
    const { callEvent } = useEventTools();

    useEffect(() => {
        !isMobile && inputRef.current?.focus()
    }, [isMobile])

    const onSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            setMessageError('')

            if (toDecimal(value) < 0) {
                setMessageError('O valor deve ser 0 ou maior.')
                return
            }

            const movProd = vendedor

            movProd.vFinal = roundTo(toDecimal(value))
            movProd.vProd = roundTo(toDecimal(value))
            movProd.vUnCom = roundTo(toDecimal(value))
            movProd.indFin = roundTo(toDecimal(value)) === 0 ? false : true

            await alterarProduto(movProd, true)

            showToast('success', `Taxa de ${vendedor.vendedorNome} alterada.`)
            setTimeout(() => {
                callEvent(AppEventEnum.AtualizarTaxa, true)
            }, 1000)
            props.voltar(0);
            closeModal();
        } catch (e: any) {
            showToast(
                'error',
                `Não foi possível alterar a taxa do vendedor. Detalhe: ${e.message}`,
            );
        }
    }, [alterarProduto, closeModal, props, showToast, value, vendedor, callEvent])

    const removerTaxa = useCallback(() => {
        props.inativarProduto(vendedor)
        showToast('success', `Taxa de ${vendedor.vendedorNome} removida.`)
        setTimeout(() => {
            callEvent(AppEventEnum.AtualizarTaxa, true)
        }, 1000)
        props.voltar(0);
        closeModal();
    }, [callEvent, closeModal, props, showToast, vendedor])

    const reativarTaxa = useCallback(() => {
        props.reativarProduto(vendedor)
        showToast('success', `Taxa de ${vendedor.vendedorNome} reativada.`)
        setTimeout(() => {
            callEvent(AppEventEnum.AtualizarTaxa, true)
        }, 1000)                        
        props.voltar(0);
        closeModal();
    }, [callEvent, closeModal, props, showToast, vendedor])

    return (
        <Grow in={true}>
            <form onSubmit={onSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} container justifyContent='space-between'>
                        <Typography variant='body2' style={{ display: 'flex' }}>
                            Vendedor: <Typography color="primary" variant="body2" style={{
                                paddingLeft: 4
                            }}>{vendedor?.vendedorNome || '-'}</Typography>
                        </Typography>
                        {!vendedor.ativo && (
                            <Typography variant='body2' style={{
                                backgroundColor: theme.palette.error.light,
                                padding: '2px 10px',
                                color: "#FFF",
                                fontWeight: 500,
                                borderRadius: 8,
                                marginLeft: 4
                            }}>
                                Inativo
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' style={{ textAlign: 'initial' }}>
                            Digite abaixo o valor da taxa que vai ser cobrado.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{
                        margin: '8px 0'
                    }}>
                        <TextFieldSaurus
                            fullWidth
                            title="Valor da Taxa"
                            disabled={!vendedor.ativo}
                            tipo="DECIMAL"
                            manterMascara
                            className={classes.inputValorCenter}
                            onChange={e => setValue(e.target.value as any)}
                            variant="outlined"
                            error={messageError.length > 0}
                            helperText={messageError}
                            maxTexto={10}
                            placeholder='0'
                            allowSubmit
                            inputRef={inputRef}
                            value={value}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {vendedor.ativo ? (
                        <>
                            <Grid item xs={6}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    onClick={() => props.voltar(0)}
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Voltar
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    type="submit"
                                >
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    Confirmar
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    className={classes.buttonCancelar}
                                    onClick={() => confirm({
                                        title: 'Remover Taxa de Serviço',
                                        cancellationText: 'Cancelar',
                                        confirmationText: 'Confirmar',
                                        description:
                                            <>
                                                Deseja remover a taxa de <span style={{ color: theme.palette.primary.main }}>{vendedor.vendedorNome}</span>? Ainda poderá restaurar caso mudar de ideia antes de finalizar a venda.
                                            </>,
                                    })
                                        .then(() => {
                                            removerTaxa()
                                        })}
                                >
                                    <LixoIcon tipo="BUTTON_PRIMARY" />
                                    Remover Taxa
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    onClick={() => confirm({
                                        title: 'Reativar  Taxa',
                                        cancellationText: 'Cancelar',
                                        confirmationText: 'Confirmar',
                                        description:
                                            <>
                                                Deseja reativar a taxa?
                                            </>,
                                    })
                                        .then(() => {
                                            reativarTaxa()
                                        })}
                                >
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    Reativar Taxa
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    onClick={() => props.voltar(0)}
                                >
                                    <VoltarIcon tipo="BUTTON" />
                                    Voltar
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>
        </Grow>
    )
}