export class ConsultaEanModel {
    constructor(
 public produtos:Array<ProdutoTributarioModel>=new Array<ProdutoTributarioModel>(),
    ) {}
  }
  
  export class ProdutoTributarioModel {
    constructor(
 public ean:string="",
 public ncm:string="",
 public cest:string="",
 public descricao:string="",

    ) {}
  }


