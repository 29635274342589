import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { CardCarrinhoProps } from './card-carrinho-props';
import { useStyles } from './card-carrinho-styles';
import { useThemeQueries } from 'views';
import { toDecimalString } from 'utils/to-decimal';
import { RetornarIcon } from 'views/components/icons/retornar-icon';
import React, { useState } from 'react';
import ContentImage from './components/card-carrinho-imagem/card-carrinho-imagem';
import { BotoesAddESub } from './components/button-add-e-sub/button-add-e-sub';
import { EditarIcon } from 'views/components/icons';
import InfoProduto from './components/info-produto/info-produto';
import { isEmpty } from 'lodash';

import { ReticenciasIcon } from 'views/components/icons/reticencias-icon';
import { useCadastros } from 'services/app';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';


const CardCarrinho = ({
  model,
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  setProductEdit
}: CardCarrinhoProps) => {

  //AUX
  const classes = useStyles();
  const { theme, xs } = useThemeQueries();
  const { abrirDialogOpcoesProdutoCarrinho } = useCadastros();

  const [verMais, setVerMais] = useState<boolean>(false);

  const valorAcumulado = model.adicionais.reduce(
    (acc, current) => acc + (current.vUnCom * (current.qComModificador > 0 ? current.qComModificador : current.qCom) - current.vDescUsuario + current.vAcrescUsuario),
    0
  );

  const nomeECategoria = React.useMemo(() => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ lineHeight: '16px' }}
              >
                <Box fontWeight={600}>{model.xProd}</Box>
              </Typography>
              {model.cProd && (
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.textCod}
                >
                  Cod: {model.cProd}
                </Typography>
              </Grid>
            )}
            </Grid>
            
            <Grid item xs={12}>
              {!isEmpty(model.infAdic) && (
                <Grid item xs={12} className={classes.containerTexto}>
                  <Typography variant="caption" color="textSecondary" style={{lineHeight: '10px'}}>
                    <Box fontWeight={600}>Observação</Box>
                  </Typography>
                  <Typography variant="body2">
                    {model.infAdic.length > 60 && xs && !verMais
                      ? model.infAdic.substring(0, 60) + `...`
                      : model.infAdic}
                    {model.infAdic.length > 60 && xs && (
                      <span
                        className={classes.texto}
                        onClick={() => setVerMais((prev) => !prev)}
                      >
                        {verMais ? 'Ver menos' : 'Ver mais'}
                      </span>
                    )}
                  </Typography>
                </Grid>
              )}
              {model.vDescUsuario > 0 && (
                <Grid>
                  <Typography
                    variant="subtitle2"
                    className={classes.textVProdDescAcres}
                  >
                    Desconto: R${' '}
                    {toDecimalString(model.vDescUsuario + valorAcumulado, 2)}
                  </Typography>
                </Grid>
              )}
              {model.vAcrescUsuario > 0 && (
                <Grid>
                  <Typography
                    variant="subtitle2"
                    className={classes.textVProdDescAcres}
                  >
                    Acréscimo: R${' '}
                    {toDecimalString(model.vAcrescUsuario + valorAcumulado, 2)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }, [classes.containerTexto, classes.textCod, classes.textVProdDescAcres, classes.texto, model.cProd, model.infAdic, model.vAcrescUsuario, model.vDescUsuario, model.xProd, valorAcumulado, verMais, xs]);


  const valor = React.useMemo(() => {
    return (
      <Grid className={classes.containerValorUnidade}>
        <Typography variant="subtitle1" className={classes.textVTotal}>{model.uCom}</Typography>
        <Typography variant="subtitle1" className={classes.textVUnitario}>
          R$ {toDecimalString(model.vUnCom, 2)}
        </Typography>
      </Grid>
    );
  }, [classes.containerValorUnidade, classes.textVTotal, classes.textVUnitario, model.uCom, model.vUnCom]);

  return (
    <>
      <Grid item xs={12} className={model.ativo ? '' : classes.cardinativo}>
        <Paper
          className={`${classNames('card')} ${classes.containerInternoCard}`}
        >
          <Grid className={classes.containerInativar}>
            {model.ativo ? (
              <Button
                onClick={() =>
                  abrirDialogOpcoesProdutoCarrinho(model, inativarProduto)
                }
                className={classes.btnInativar}
              >
                <ReticenciasIcon
                  tipo="BUTTON_PRIMARY"
                  fill={theme.palette.grey[400]}
                  style={{
                    marginRight: '0px'
                  }}
                />
              </Button>
            ) : (
              <Button
                onClick={async () => await reativarProduto(model)}
                className={classes.btnRetornar}
              >
                <RetornarIcon
                  fill={theme.palette.primary.main}
                  tipo="BUTTON_PRIMARY"
                  style={{
                    marginRight: '0px'
                  }}
                />
              </Button>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} container spacing={1}>
              <Grid className={classes.containerInfo}>
                <ContentImage imgUrl={model.imgUrl} xProd={model.xProd} />
              </Grid>
              <Grid item xs={9}>{nomeECategoria}</Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 4 }}>
              <Divider variant="fullWidth" className={classes.divider} />
            </Grid>

            <Grid item xs={12} className={classes.containerValores}>
              <Grid>{valor}</Grid>
              <Grid>
                {model.subItens.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setProductEdit(model.id)}
                    size="small"
                    className="round"
                  >
                    {' '}
                    <EditarIcon
                      tipo="BUTTON_PRIMARY"
                      style={{ width: '22px', height: '22px' }}
                    />{' '}
                    Editar{' '}
                  </Button>
                ) : (
                  <BotoesAddESub
                    model={model}
                    alterarQtdeProduto={alterarQtdeProduto}
                    inativarProduto={inativarProduto}
                  />
                )}
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end'
                }}
              >
                <Typography variant="subtitle1" className={classes.textVTotal}>
                  Valor total
                </Typography>
                <Typography variant="subtitle1" className={classes.textVProd}>
                  R${' '}
                  {toDecimalString(
                    (model.tpProduto === EnumTpProduto.Combo
                      ? 0
                      : model.vFinal) + valorAcumulado,
                    2
                  )}
                </Typography>
              </Grid>
            </Grid>

            {/* {model.prodSubItem.length > 0 && (
              <Grid item xs={12}>
                <Divider variant='fullWidth' className={classes.divider} />
              </Grid>
            )} */}

            {model.tpProduto !== EnumTpProduto.Combo &&
              model.prodSubItem.length > 0 &&
              model.prodSubItem.filter((p) => p.qCom > 0).length > 0 && (
                <Grid item xs={12}>
                  <p className={classes.pAdicional}>Inclusos</p>
                </Grid>
              )}
            {model.tpProduto !== EnumTpProduto.Combo &&
              model.prodSubItem.length > 0 &&
              model.prodSubItem.map((p) => (
                <Grid item xs={12}>
                  <InfoProduto
                    key={p.id}
                    incluso
                    produto={{
                      ...p,
                      qCom:
                        p.qCom > p.infoSubItem!.qPadrao
                          ? p.infoSubItem!.qPadrao
                          : p.qCom
                    }}
                    qtdPai={model.qCom}
                    qtdTotal={model.prodSubItem.filter((p) => p.qCom > 0)}
                  />
                </Grid>
              ))}

            {model.adicionais.length > 0 && (
              <>
                <Grid item xs={12}>
                  <p className={classes.pAdicional}>
                    {model.tpProduto !== EnumTpProduto.Combo
                      ? 'Adicionais'
                      : 'Composição'}
                  </p>
                </Grid>
                {model.adicionais
                  .filter((p) => p.qCom > 0)
                  .map((p) => (
                    <Grid item xs={12}>
                      <InfoProduto
                        key={p.id}
                        produto={p}
                        qtdPai={
                          model.qComModificador > 0
                            ? model.qComModificador
                            : model.qCom
                        }
                        qtdTotal={model.adicionais.filter(
                          (p) => p.qCom > 0 && p.modificadorId
                        )}
                      />
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
export default React.memo(CardCarrinho);
