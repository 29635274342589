import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    splashscreen: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(4),
        background: theme.palette.background.default,
        padding: theme.spacing(1),
        zIndex: 9999999
    },
    imagem: {
        width: '75%',
        animation: `$growShrink 1500ms infinite`,
        borderRadius: '50%',
        [theme.breakpoints.up('xs')]: {
            width: 250
        }
    },
    "@keyframes growShrink": {
        "0%": {
            transform: "scale(100%)"
        },
        "50%": {
            transform: "scale(95%)"
        },
        "100%": {
            transform: "scale(100%)"
        }
    }
}));