import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const TipoMovimentacaoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M22.8792 0.89776C21.2475 1.24648 18.8174 2.50186 17.5502 3.67005C15.363 5.6403 13.9396 8.58695 13.7313 11.5162L13.6272 12.9982H9.94721C6.59704 12.9982 6.24987 13.0331 5.98949 13.3121C5.46874 13.8874 4.79176 12.9459 13.0717 23.3725C16.1094 27.2084 19.1298 31.0094 19.7721 31.8289L20.9698 33.3109V41.1571C20.9698 47.9745 21.0045 49.0555 21.2475 49.4042C21.3864 49.6134 21.6294 49.7878 21.7856 49.7878C22.0634 49.7878 29.0067 46.2832 29.4234 45.917C29.5969 45.7775 29.649 44.3652 29.649 39.6053V33.4853L31.923 30.6084C33.1901 29.0217 36.6097 24.7151 39.5259 21.0187C42.4595 17.3223 44.9071 14.1664 44.9765 13.9921C45.0459 13.8003 44.9591 13.5562 44.7508 13.3469C44.421 13.0157 44.1606 12.9982 40.7758 12.9982H37.1652L37.0437 11.708C36.8875 9.92951 36.5229 8.60439 35.8112 7.15722C33.4679 2.3798 28.0868 -0.235568 22.8792 0.89776ZM27.8264 2.53673C31.1939 3.40852 33.8497 5.8844 34.978 9.21464C35.6029 11.0977 35.6377 13.5039 35.0648 15.352C34.006 18.7869 31.1765 21.472 27.7396 22.3438C26.2294 22.7274 23.5562 22.6053 22.0634 22.0648C19.1645 21.0536 16.8385 18.6997 15.8144 15.7705C14.5298 12.0741 15.4845 8.01157 18.2619 5.23928C20.883 2.62391 24.3547 1.6475 27.8264 2.53673ZM14.0264 15.2997C15.0679 19.5018 18.6091 22.9715 22.8098 23.8956C24.5283 24.2966 27.3403 24.1746 28.9547 23.6689C32.5305 22.5182 35.6029 19.31 36.5924 15.7008L36.8875 14.6546L39.6995 14.6023C41.2271 14.5849 42.4942 14.6023 42.4942 14.6546C42.4942 14.7069 39.2656 18.8043 35.3426 23.7735L28.1735 32.7879L28.0868 38.8555L28 44.9232L25.3615 46.3006L22.7056 47.678V40.3899V33.0843L21.9419 32.073C21.5253 31.4976 18.2966 27.4002 14.7381 22.9366C11.197 18.4731 8.29816 14.7767 8.29816 14.6895C8.29816 14.6197 9.54797 14.5674 11.0755 14.5674H13.8355L14.0264 15.2997Z" />
        <path d="M24.1638 3.75724C21.4038 4.17569 18.9042 5.95415 17.6891 8.36029C13.8876 15.8926 21.6815 23.9479 29.2151 20.2515C30.6038 19.5541 31.8188 18.4556 32.652 17.1479C33.6068 15.631 33.8498 14.8115 33.9539 12.8936C34.0754 10.1039 33.3637 8.15106 31.489 6.26799C29.5102 4.28031 26.8023 3.35621 24.1638 3.75724ZM26.7155 5.3962C27.3577 5.53569 28.2083 5.81466 28.6249 6.02389C29.8053 6.63414 31.1592 8.04645 31.7841 9.31926C32.3049 10.4003 32.3396 10.5746 32.3396 12.388C32.3396 14.1839 32.3049 14.3756 31.7841 15.4915C30.4302 18.351 27.323 20.0074 24.32 19.5018C21.1608 18.9438 18.8868 16.6772 18.3314 13.5039C17.6891 9.8249 20.4664 5.97158 24.2159 5.32646C25.4136 5.13466 25.4483 5.13466 26.7155 5.3962Z" />
        <path d="M24.7887 6.37257C24.5977 6.56437 24.4415 6.89565 24.4415 7.10488C24.4241 7.36642 24.2158 7.64539 23.886 7.85462C22.9313 8.46487 22.6189 9.05769 22.6189 10.2608C22.6189 11.1151 22.7056 11.4638 23.0181 11.9172C23.5041 12.5972 24.563 13.1725 25.3094 13.1725C26.1079 13.1725 26.7675 13.9048 26.6286 14.6197C26.5071 15.2648 25.9517 15.7879 25.3962 15.7879C24.8754 15.7879 24.0943 15.1428 24.0943 14.7243C24.0943 14.1489 23.7645 13.6956 23.3479 13.6956C22.5321 13.6956 22.289 14.5325 22.7751 15.6659C23.0181 16.2936 23.9728 17.1828 24.3721 17.1828C24.5283 17.1828 24.6151 17.3571 24.6151 17.671C24.6151 17.9325 24.7019 18.2464 24.8234 18.3684C25.3094 18.8566 26.1773 18.3858 26.1773 17.6535C26.1773 17.392 26.3856 17.1653 26.8369 16.9212C27.566 16.5202 28.2603 15.3346 28.2603 14.4802C28.243 13.7131 27.6875 12.5797 27.0626 12.1438C26.7675 11.9346 26.1253 11.6905 25.6219 11.6208C24.6151 11.4464 24.0943 10.9931 24.0943 10.2259C24.0943 9.61564 24.7539 8.98795 25.3788 8.98795C25.969 8.98795 26.6981 9.70281 26.6981 10.2782C26.6981 11.1674 27.9826 11.3069 28.1909 10.4526C28.4339 9.49358 27.4271 7.92436 26.3162 7.54077C26.1426 7.47103 26.0037 7.24437 26.0037 7.03514C26.0037 6.19822 25.3094 5.8495 24.7887 6.37257Z" />
        <path d="M21.0045 25.2382C20.8656 25.3777 20.7962 26.1623 20.7962 27.592C20.7962 29.7192 20.7962 29.7541 21.2302 29.9458C21.5947 30.1028 21.7162 30.0853 22.0113 29.8238C22.3237 29.5448 22.3585 29.2833 22.3585 27.6094C22.3585 26.5633 22.2717 25.552 22.1849 25.3602C21.9766 24.9941 21.317 24.9243 21.0045 25.2382Z" />
        <path d="M24.4415 25.3602C24.2158 25.8136 24.2158 31.9161 24.4415 32.3694C24.6498 32.7356 25.3094 32.8053 25.6218 32.4915C25.9169 32.1951 25.9169 25.5346 25.6218 25.2382C25.3094 24.9243 24.6498 24.9941 24.4415 25.3602Z" />
        <path d="M28.1215 25.2382C27.9826 25.3777 27.9132 26.1274 27.9132 27.4874C27.9132 29.5797 28.0694 30.0853 28.6943 30.0853C29.3192 30.0853 29.4755 29.5797 29.4755 27.4874C29.4755 25.3428 29.3713 25.0289 28.6943 25.0289C28.486 25.0289 28.243 25.1161 28.1215 25.2382Z" />
      </DefaultIcon>
    </>
  );
};
