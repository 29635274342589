import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from "../../../../../../components/icons/informacao-icon";
import { NcmModel } from "model/api/gestao/ncm/ncm-model";
import { CardNcm } from "../../../../../../components/cards/card-ncm/card-ncm";

export interface CategoriaGridProps {
  list: Array<NcmModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const NcmListData = (props: CategoriaGridProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum NCM encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((ncm, index) => {
          return (
            <CardNcm
              selected={
                props.selectedList.filter((item) => item === ncm.id).length > 0 ? true : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={ncm}
              key={index}
            />
          );
        })}
    </>
  );
};
