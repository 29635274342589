import { AlterarNumeracaoModel } from "model/api/gestao/alterar-numeracao/alterar-numeracao-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutAlterarNumeracao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAlterarNumeracao = useCallback(
        (data: AlterarNumeracaoModel, id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Movimento/${id}/alterar-numeracao`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(data),
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putAlterarNumeracao,
    };
}
