import { EmpresaUsuarioModel } from "model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogUsuario } from "views/components/dialog/dialog-usuario/dialog-usuario";

export const CadastroUsuarioDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        model: new EmpresaUsuarioModel(),
        aberto: false,
    });


    const modalAlterado = useCallback(({ model, openned }: any) => {
        setModalState({
            model: model,
            aberto: openned,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogUsuario, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogUsuario, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <>
            {modalState.aberto && <DialogUsuario openned={modalState.aberto} key="mdlPessoa" model={modalState.model} />}
        </>
    )
}