import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormModificadorEditValidation = () => {

  const FormModificadorEditYupValidation = useMemo(() => {
    return (
      Yup.object().shape({
        nome: Yup.string().required('Nome é Obrigatório'),
        descricao: Yup
          .string()
          .nullable()
          .notRequired(),
        // .min(15, 'Mínimo de 15 caracteres')
        // .max(150, 'Máximo de 150 caracteres'),
        tpCalculo: Yup.number().required('Selecione uma opção'),
      })
    )
  }, [])

  return {
    FormModificadorEditYupValidation
  }
}

