import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from '@material-ui/core';
import { AtualizarIcon, VoltarIcon } from 'views/components/icons';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { picker } from 'utils/picker';
import { FormUsuarioEdit } from 'views/components/form/perfil/form-usuario-edit/form-usuario-edit';
import { UsuarioEditFormModel, UsuarioEditPostModel, UsuarioEditPutModel } from 'model/app/forms/perfil/perfil-usuario-edit-form-model';
import { usePutPerfilUsuario } from 'data/api/gestao/perfil/put-perfil-empresa-usuarios';
import { isEmpty, isEqual } from 'lodash';
import { EmpresaUsuarioModel, PerfilModel } from 'model';
import { CircularLoading } from 'views';
import { CardUsuarioPerfilEdit } from 'views/components/cards/card-usuario-perfil-edit/card-usuario-perfil-edit';
import { useGetPerfis } from 'data/api/gestao/perfil/get-perfis';
import { guidEmpty } from 'utils/guid-empty';
import { useDeletePerfilEmpresaUsuario } from 'data/api/gestao/perfil/delete-perfil-empresa-usuarios';
import { usePostPerfilUsuario } from 'data/api/gestao/perfil/post-perfil-empresa-usuario';

export const DialogUsuarioEdit = (props: { model: EmpresaUsuarioModel; aberto: boolean }) => {
  const { putPerfilUsuario, carregando: putPerfilUsuarioCarregando } =
    usePutPerfilUsuario();
  const { postPerfilUsuario, carregando: posttPerfilUsuarioCarregando } =
    usePostPerfilUsuario();
  const { deletePerfilEmpresaUsuario, carregando: carregandoDelete } = useDeletePerfilEmpresaUsuario();
  const { showToast } = useToastSaurus();
  const { fecharCadastroUsuario } = useCadastros();
  const { usuario } = useSessaoAtual();
  const classes = useModalStyles();
  const usuarioEditFormRef =
    useRef<DefaultFormRefs<UsuarioEditFormModel>>(null);
  const refEmpresaUsuarioModel = useRef<UsuarioEditPutModel>(
    new UsuarioEditPutModel(),
  );

  const [formModel, setFormModel] = useState<UsuarioEditFormModel | false>(false)
  const [multiModel, setMultiModel] = useState<UsuarioEditFormModel[]>(props.model.empresaPerfis.map(empresaPerfil => new UsuarioEditFormModel(
    empresaPerfil.id,
    empresaPerfil.empresa.id,
    empresaPerfil.empresa.nome,
    empresaPerfil.perfil.id,
    empresaPerfil.perfil.nome,
    props.model.usuario.email
  )));

  const recarregarForm = useCallback((model: UsuarioEditFormModel) => {
    usuarioEditFormRef.current?.fillForm(model);
  }, []);

  // const getPerfilUsuariodByIdWrapper = useCallback(async () => {
  //   const res = await getPerfilUsuarioById(props.id);
  //   if (res.erro) {
  //     throw res.erro;
  //   }
  //   const ret = res.resultado?.data as EmpresaUsuarioModel;
  //   let perfilUsuario = new UsuarioEditFormModel();

  //   perfilUsuario.id = ret.id;
  //   perfilUsuario.empresaId = ret.empresaId;
  //   perfilUsuario.contratoId = ret.contratoId;
  //   perfilUsuario.perfilId = ret.perfil.id;
  //   perfilUsuario.status = ret.status;
  //   perfilUsuario.usuarioId = ret.usuario.id;
  //   perfilUsuario.nome = ret.usuario.nome;
  //   perfilUsuario.email = ret.usuario.email;

  //   refEmpresaUsuarioModel.current = perfilUsuario;
  //   return perfilUsuario;
  // }, [getPerfilUsuarioById, props.id]);

  const criarPerfilUsuario = useCallback(
    async (perfilUsuarioForm: UsuarioEditFormModel) => {
      let perfilUsuarioUpdate = picker<UsuarioEditPostModel>(
        perfilUsuarioForm,
        refEmpresaUsuarioModel.current,
      );
      perfilUsuarioUpdate.usuarioId = props.model.usuario.id;
      const ret = await postPerfilUsuario(perfilUsuarioUpdate);
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [postPerfilUsuario, props.model.usuario.id],
  );

  const saveChangesPerfilUsuario = useCallback(
    async (perfilUsuarioForm: UsuarioEditFormModel) => {
      let perfilUsuarioUpdate = picker<UsuarioEditPutModel>(
        perfilUsuarioForm,
        refEmpresaUsuarioModel.current,
      );
      perfilUsuarioUpdate.usuarioId = props.model.usuario.id;
      const ret = await putPerfilUsuario(perfilUsuarioUpdate);
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [props.model.usuario.id, putPerfilUsuario],
  );

  const handleSubmit = useCallback(
    async (model: UsuarioEditFormModel, beforeModel: UsuarioEditFormModel) => {
      try {
        const perfilUsuarioEqual = isEqual(model, beforeModel);
        if (!perfilUsuarioEqual) {
          await saveChangesPerfilUsuario(model);
        }
        if (perfilUsuarioEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
        } else {
          showToast('success', 'Perfil do usuário atualizado com Sucesso!');
          fecharCadastroUsuario()
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [fecharCadastroUsuario, saveChangesPerfilUsuario, showToast],
  );

  useEffect(() => {
    if (formModel) {
      recarregarForm(formModel)
    }
  }, [formModel, recarregarForm])

  const [perfis, setPerfis] = useState<PerfilModel[]>([]);

  const { getPerfisDrop, carregando: carregandoDrop } = useGetPerfis();

  const carregando = putPerfilUsuarioCarregando || carregandoDrop || carregandoDelete || posttPerfilUsuarioCarregando;

  const handleGetPerfisDrop = useCallback(async () => {
    const res = await getPerfisDrop();
    if (res.erro) {
      throw res.erro;
    }

    return res.resultado?.data?.list;
  }, [getPerfisDrop]);

  useEffect(() => {
    if (usuario?.empresa.length === 1) {
      recarregarForm(new UsuarioEditFormModel(
        props.model.empresaPerfis[0].id,
        props.model.empresaPerfis[0].empresa.id,
        props.model.empresaPerfis[0].empresa.nome,
        props.model.empresaPerfis[0].perfil.id,
        props.model.empresaPerfis[0].perfil.nome,
        props.model.usuario.email,
        props.model.empresaPerfis[0].status,
      ))
    } else {
      (async () => {
        const usePerfis = await handleGetPerfisDrop();
        setPerfis(usePerfis);
        const perfilEmpresas = usuario?.empresa.map<UsuarioEditFormModel>(emp => {
          const configExistente = props.model.empresaPerfis.find(x => x.empresa.id === emp.Id);
          if (configExistente) {
            return new UsuarioEditFormModel(
              configExistente.id,
              configExistente.empresa.id,
              configExistente.empresa.nome,
              configExistente.perfil.id,
              configExistente.perfil.nome,
              props.model.usuario.email,
              configExistente.status,
            )
          }
          return new UsuarioEditFormModel(guidEmpty(), emp.Id, emp.Descricao, guidEmpty(), '', props.model.usuario.email, props.model.empresaPerfis[0].status)
        })
        setMultiModel(perfilEmpresas || [])
      })()
    }
  }, [handleGetPerfisDrop, props.model.empresaPerfis, props.model.usuario.email, recarregarForm, usuario?.empresa, usuario?.empresa.length])

  const onChangePermissao = useCallback((model: UsuarioEditFormModel) => {
    const index = multiModel.indexOf(multiModel.find(item => item.empresaId === model.empresaId) || new UsuarioEditFormModel());

    setMultiModel(prev => {
      prev[index].perfilId = model.perfilId;

      return [...prev]
    })
  }, [multiModel])

  const onChangeStatus = useCallback((model: UsuarioEditFormModel) => {
    const index = multiModel.indexOf(multiModel.find(item => item.empresaId === model.empresaId) || new UsuarioEditFormModel());

    setMultiModel(prev => {
      prev[index].status = model.status;

      return [...prev]
    })
  }, [multiModel])

  const submitMultipleEdit = useCallback(async () => {
    const perfisDiferentes = multiModel.filter(model => {
      const perfilExistente = props.model.empresaPerfis.find(x => x.id === model.id);
      if (perfilExistente) {
        return perfilExistente.perfil.id !== model.perfilId || perfilExistente.status !== model.status
      }
      if (model.perfilId !== guidEmpty() && !isEmpty(model.perfilId)) {
        return true
      }
      return false
    })

    try {
      for (let model of perfisDiferentes) {
        if (model.perfilId === guidEmpty() && model.id !== guidEmpty()) {
          await deletePerfilEmpresaUsuario(model.id)
          break;
        }
        if (model.id === guidEmpty()) {
          await criarPerfilUsuario(model);
        } else {
          await saveChangesPerfilUsuario(model);
        }
      }
      showToast('success', 'Usuários atualizados.')
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [criarPerfilUsuario, deletePerfilEmpresaUsuario, multiModel, props.model.empresaPerfis, saveChangesPerfilUsuario, showToast])

  return (
    <>
      {props.aberto && (
        <div className={classes.root}>
          {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
          <div className={classes.content}>
            <div
              className={classNames(
                classes.contentForms,
                carregando ? classes.contentFormsLoading : undefined,
              )}
              style={{ height: '100%' }}
            >
              {formModel || usuario?.empresa.length === 1 ? (
                <FormUsuarioEdit
                  ref={usuarioEditFormRef}
                  loading={carregando}
                  showLoading
                  onSubmit={handleSubmit}
                />
              ) : (
                <Grid container spacing={2}>
                  {multiModel.map(empresaPerfil => (
                    <Grid item xs={12}>
                      <CardUsuarioPerfilEdit
                        model={empresaPerfil}
                        perfis={perfis}
                        onChangePermissao={onChangePermissao}
                        onChangeStatus={onChangeStatus}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </div>
          <div className={classes.acoes}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={carregando}
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    if (formModel && props.model.empresaPerfis.length > 1) {
                      setFormModel(false)
                      return
                    }
                    fecharCadastroUsuario()
                  }}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    if (multiModel.length > 1) {
                      submitMultipleEdit()
                    } else {
                      usuarioEditFormRef.current?.submitForm()
                    }
                  }}
                >
                  <AtualizarIcon tipo="BUTTON_PRIMARY" />
                  Atualizar
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
