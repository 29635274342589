import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { SelecionarRastrosListDataProps } from './selecionar-rastros-list-data-props';
import { CardLote } from 'views/components/cards/card-lote/card-lote';

export const SelecionarRastrosListData = (props: SelecionarRastrosListDataProps) => {
  const { isMobile } = useThemeQueries();

  const medicamento = props.produtoMed
  const quantidadeRastroSelecionados = (medicamento.rastro ?? []).reduce((a, b) => a + (b.qLote ?? 0), 0)

  return (
    <>
      {(props?.list?.length ?? 0) === 0 && (
        <CardNaoEncontrado
          mensagem={`Nenhum lote encontrado.`}
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list?.length > 0 &&
        props.list.map((lote, index) => {
          return (
            <>
              <CardLote
                onClick={props.onCardClick}
                model={lote}
                key={lote.lote}
                isMobile={isMobile}
                disabled={props.disabled}
                quantidadeSelecionada={medicamento.rastro?.filter(x => x.nLote === lote.lote)[0]?.qLote ?? 0}
                quantidadeDisponivel={medicamento.qCom - quantidadeRastroSelecionados}
              />
            </>
          );
        })}
    </>
  );
};
