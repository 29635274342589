import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  pinButton: {
    width: '34px'
  },
  pinButtonIcon: {
    height: '18px',
    width: '18px',
    transform: "rotate(0deg)",
    "-webkit-transition": "-webkit-transform .4s ease-in-out",
    "-ms-transition": "-ms-transform .4s ease-in-out",
    transition: "transform .4s ease-in-out",
  },
  pinButtonMini: {
    transform: "rotate(180deg)"
  },
  drawerDefaultTopButtonsContainer: {

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "-10px",
  },
}));
