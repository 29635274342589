import { Box } from "@material-ui/core"
import { useStyles } from './cadastro-facilitado-comanda-styles'
import { FormComandasCadastroFacilitado } from "views/components/form/comandas/form-comandas-cadastro-facilitado/form-comandas-cadastro-facilitado";
import { useCallback, useEffect, useRef } from "react";
import { usePostComanda } from "data/api/gestao/comandas/post-comandas";
import { ComandasEditModel } from "model/api/gestao/comanda/comanda-edit-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { useSessaoAtual, useToastSaurus } from "services/app";

interface CadastroFacilitadoComandaProps{
    updateComandas: (newPage: number) => Promise<void>;
}

export const CadastroFacilitadoComanda = ({ updateComandas }: CadastroFacilitadoComandaProps) => {
    const classes = useStyles();

    const { postComanda, carregando } = usePostComanda();
    const { showToast } = useToastSaurus();
    const { getEmpresaSelecionada } = useSessaoAtual();

    const cadFormRef = useRef<DefaultFormRefs<ComandasEditModel>>(null)

    const handleSubmit = useCallback(async (model: ComandasEditModel) => {
        try {
            const res = await postComanda(model, getEmpresaSelecionada()!.Id)

            if (res.erro) throw res.erro

            showToast('success', `Comanda ${model.codigoComanda} cadastrada.`)

            await updateComandas(1);

            cadFormRef.current?.fillForm(new ComandasEditModel());
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getEmpresaSelecionada, postComanda, showToast, updateComandas])

    useEffect(() => {
        cadFormRef.current?.fillForm(new ComandasEditModel());
    }, [])

    return (
        <Box className={classes.card}>
            <FormComandasCadastroFacilitado
                showLoading
                loading={carregando}
                onSubmit={handleSubmit}
                ref={cadFormRef}
            />
        </Box>
    )
}