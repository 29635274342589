import { Grid, Typography, Button } from '@material-ui/core';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { DialogBarCodeCamComandaTransferirItens } from 'views/components/dialog/dialog-barcode-cam-transferir-itens/dialog-barcode-cam-transferir-itens';
import { DialogTransferirItem } from 'views/components/dialog/dialog-transferir-itens/dialog-transferir-itens';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { TransferirIcon } from 'views/components/icons/transferir-item';
import { TransferirItensListData } from './components/transferir-itens-list/transferir-itens-list-data';
import { useStyles } from './mov-transferir-itens-pedido-styles';
import { isEmpty } from 'lodash';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';

interface LocationProps {
  pedido: PedidoModel;
  transferirPedido: boolean;
  alterarVendedor: boolean;
  vendedor: PessoaModel | null;
}

export const MovTransferirItensPedidoPage = () => {
  // STATES E REFS
  const [itemSelecionado, setItemSelecionado] = useState<string[]>([]);
  const [dialogTransferir, setDialogTransferir] = useState<boolean>(false);
  const [dialogTransferirCam, setDialogTransferirCam] =
    useState<boolean>(false);

  const location = useLocation<LocationProps>();
  const classes = useStyles();
  const history = useHistory();

  const pedido = location.state;
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();

  const isTipoTrabalhoComanda =
    getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA;

  useEffect(() => {
    if (isEmpty(pedido?.pedido)) {
      history.push('/venda-simples/pedidos');
    }
  }, [history, pedido]);

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => {
          if (pedido?.alterarVendedor) {
            history.push({
              pathname: `/venda-simples/alterar-vendedor`,
              state: {
                pedido: pedido?.pedido
              }
            });
          } else {
            if (!pedido.pedido.mesaId && !pedido.pedido.comandaId) {
              history.push({
                pathname: `/venda-simples/visualizar-pedido/${pedido.pedido.id}`,
                search: history.location.search,
                state: {
                  pedido: pedido.pedido
                }
              });
              return;
            }
            if (isTipoTrabalhoComanda) {
              history.push({
                pathname: `/venda-simples/mesa-e-comanda/${pedido.pedido.mesaId}`,
                search: history.location.search,
                state: {
                  pedido: pedido?.pedido
                }
              });
              return;
            }
            history.push(
              {
                pathname: `/venda-simples/visualizar-pedido/${pedido.pedido.comandaId
                  ? pedido.pedido.comandaId
                  : pedido.pedido.mesaId
                  }`,
                search: history.location.search ? history.location.search : pedido.pedido?.comandaId ? '?modo=comanda' : '?modo=mesa',
                state: {
                  pedido: pedido?.pedido
                }
              }
            );
          }
        }}
      ></ButtonPrivateHeader>
    );
  }, [history, isTipoTrabalhoComanda, pedido?.alterarVendedor, pedido.pedido]);

  const onCardItemSelected = useCallback((produto: PedidoProdutoModel) => {
    setItemSelecionado((prev: string[]) => {
      const temEsseItemSelecionado = prev.filter(
        (prod) =>
          prod === produto.id ||
          produto.adicionais.map((p) => p.id).includes(prod)
      );

      if (temEsseItemSelecionado.length > 0) {
        return prev.filter((prod) => !temEsseItemSelecionado.includes(prod));
      }

      return [...prev, produto.id, ...produto.adicionais.map((p) => p.id)];
    });
  }, []);

  const produtosAgrupados = location.state?.pedido?.produtos.filter(
    (p) =>
      p.indFin &&
      (p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem ||
        p.tpProduto.codigo === EnumTpProduto.Produto)
  );

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <PrivatePageHeader
            title={
              location.state?.alterarVendedor
                ? `Alterar Vendedor`
                : `Transferir itens`
            }
            leftArea={leftArea()}
          />
        </Grid>

        <Grid className={classes.containerInfo}>
          <Typography
            style={{
              fontWeight: '500',
              backgroundColor: '#FFF',
              borderRadius: 4,
              padding: '4px 10px',
              boxShadow: '1px 1px 2px rgba(0,0,0,0.1)'
            }}
            variant="body2"
          >
            {produtosAgrupados?.length}{' '}
            {produtosAgrupados?.length === 1 ? 'Produto' : 'Produtos'}
          </Typography>
        </Grid>
        <Grid className={classes.list}>
          <TransferirItensListData
            itens={
              produtosAgrupados.filter((produto) => {
                if (location.state.alterarVendedor) {
                  if (produto.vendedorId === location.state.vendedor?.id) {
                    return false;
                  }
                  if (
                    produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
                  ) {
                    return true;
                  }

                  return false;
                }
                if (
                  produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
                ) {
                  return true;
                }
                return false;
              }) ?? []
            }
            carregando={false}
            onCardSelected={onCardItemSelected}
            itensSelecionados={itemSelecionado}
          />
        </Grid>
        <Grid className={classes.containerAcao}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="round"
                disabled={itemSelecionado.length === 0 ? true : false}
                onClick={() => setDialogTransferir(true)}
              >
                <TransferirIcon tipo="BUTTON_PRIMARY" />
                {location.state?.alterarVendedor
                  ? `Alterar Vendedor`
                  : 'Transferir Item'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {dialogTransferir && (
        <DialogTransferirItem
          aberto={dialogTransferir}
          dialogTransferirCam={setDialogTransferirCam}
          close={() => setDialogTransferir(false)}
          idsProdutos={itemSelecionado}
          pedido={location.state?.pedido}
          vendedor={location.state?.vendedor ?? null}
        />
      )}

      {dialogTransferirCam && (
        <DialogBarCodeCamComandaTransferirItens
          openned={dialogTransferirCam}
          closeModal={() => setDialogTransferirCam(false)}
          dialogTransferir={setDialogTransferir}
          idsProdutos={itemSelecionado}
          pedido={location.state?.pedido}
          vendedor={location.state?.vendedor ?? null}
        />
      )}
    </>
  );
};
