import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormDadosEntradaValidation = () => {

  const formDadosEntradaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        // transportadora: Yup.string().required('Nome do EntradaCadastro é Obrigatório'),
        // descricao: Yup.string().required('Descrição é Obrigatório'),
      })
    )
  }, [])
  return {
    formDadosEntradaValidationYup,
  }
}

