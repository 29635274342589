

import { useThemeQueries } from 'views/theme';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import ConfigInicial from './configuracoes-iniciais/config-inicial';
export interface DialogConfiguracoesIniciaisProps {
  aberto: boolean;
}

export const DialogConfiguracoesIniciais = ({
  aberto,
}: DialogConfiguracoesIniciaisProps) => {

 const {isMobile} = useThemeQueries()

  return (
    <DialogSaurus
      aberto={aberto || false}
      fullHeightContent
      fullHeight
      fullScreen={isMobile ? true : false}
      
    
    >
      <ConfigInicial
        />
    </DialogSaurus>
  );
};
