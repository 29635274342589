import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento, useValidationYupRazaoSocial } from '../../../form-validations'

export const useFormEmpresaValidation = () => {
    const { documentoYup } = useValidationYupDocumento(true)
    const { razaoSocialYup } = useValidationYupRazaoSocial()
    const FormEmpresaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                cpfcnpj: documentoYup(),
                razaoSocial: razaoSocialYup(),
                segmento: Yup.string().required("Informe o segmento")
            })
        )
    }, [documentoYup, razaoSocialYup])

    const FormEmpresaValidationYupParaFiscal = useMemo(() => {
        return (
            Yup.object().shape({
                cpfcnpj: documentoYup(),
                razaoSocial: razaoSocialYup(),
                nomeFantasia: Yup.string().required('Nome Fantasia é obrigatório.'),
                cep: Yup.string().required('CEP é obrigatório'),
                logradouro: Yup.string().required('Endereço é obrigatório'),
                numero: Yup.string().required('Número é obrigatório'),
                bairro: Yup.string().required('Bairro é obrigatório'),
                municipio: Yup.string().required('Municipio é obrigatório'),
                uf: Yup.string().required('UF é obrigatório'),
                cMunicipio: Yup.string().required('Código do Municipio é obrigatório'),
                ierg: Yup.number().required('Inscrição Estadual é obrigatória').typeError('Inscrição Estadual é obrigatória')
            })
        )
    }, [documentoYup, razaoSocialYup])
    return {
        FormEmpresaValidationYup,
        FormEmpresaValidationYupParaFiscal
    }
}

