import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: '100%'
    },
    acoes: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        cursor: "pointer",
        "& p": {
            fontWeight: 600,
        }
    },
    divider: {
        backgroundColor: '#E6E6E6'
    },
    editIcon: {
        width: 20
    }
}));
