import { PesquisaProdutosProps } from './pesquisa-produto-props';
import { useStyles } from './pesquisa-produto-styles';
import { Grid } from '@material-ui/core';
import { useState, useCallback, useRef, useEffect } from 'react';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { PesquisarProdutoListData } from './../produto-list/produto-list-data';
import { useThemeQueries } from 'views';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { TabResultadoPesquisa } from '../tab-resultado-pesquisa/tab-resultado-pesquisa';
import { PesquisarMedicamentoListData } from '../medicamentos-list/medicamentos-list-data';
import { PesquisarPrincipioAtivoListData } from '../principio-ativo-list/principio-ativo-list-data';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { MedicamentoPreco } from 'model/api/gestao/medicamento/medicamento-model';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CardNaoEncontrado } from 'views/components/cards';
import { TecladoIcon } from 'views/components/icons/teclado-icon';

export const PesquisaProduto = ({ isOpened }: PesquisaProdutosProps) => {
  // REFS
  const timer = useRef({} as NodeJS.Timeout);
  const firstRender = useRef<boolean>(true);

  // STATES
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const [aguardandoBusca, setAguardandoBusca] = useState(false);
  const [listProdutos, setListProdutos] = useState<ProdutoResumidoModel[]>([]);
  const [listProdutosVarejo, setListProdutosVarejo] = useState<ProdutoResumidoModel[]>([]);
  const [listMedicamentosSinc, setListMedicamentosSinc] = useState<MedicamentoPreco[]>([]);
  const [listPrincipioAtivo, setPrincipioAtivo] = useState<string[]>([]);
  const [path, setPath] = useState<string>('/produtos');

  // HOOKS
  const { plano } = useSessaoAtual();
  const { inserirProdutoFromSearch, inserirProdutoFromSearchDetailed, inserirProdutoFromSearchMedicamento } = useMovProd();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { pathname } = useLocation();

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const isFarma = isPlanoFarmaceutico(plano?.plano);

  useEffect(() => {
    if (isOpened && !isMobile) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false);
    } else {
      callEvent(AppEventEnum.PermiteEntradaDigitada, true);
    }
    setTermoPesquisar('');

    addHandler(AppEventEnum.TermoPesquisaProduto, setTermoPesquisar);

    return () =>
      removeHandler(AppEventEnum.TermoPesquisaProduto, setTermoPesquisar);
  }, [addHandler, callEvent, isMobile, isOpened, removeHandler]);

  const searchProducts = useCallback(
    async (termo: string) => {
      const res = await TouchoneDBPrimary.produtos
        .filter(
          (prod) =>
            prod.tipo === EnumTipoProduto.Produto ||
            prod.tipo === EnumTipoProduto.Combo ||
            prod.tipo === EnumTipoProduto.Medicamento
        )
        .filter((produto) => {
          if (termo.length === 0) {
            return false;
          }
          if (produto.medicamento?.nome?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.medicamento?.composicao?.toLowerCase().includes(termo.toLowerCase())) {
            return true
          }
          if (
            produto.nome?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.codigo?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.codigoAnvisa?.toLowerCase().includes(termo.toLowerCase())
          ) {
            return true;
          }
          return false;
        }).limit(100)
        .toArray();

      setListProdutos(res as ProdutoResumidoModel[]);
      const varejo = res.filter(p => !p.codigoAnvisa);
      const medicamentos = res.filter(p => p.medicamento).map(m => m.medicamento);
      setListProdutosVarejo(varejo as ProdutoResumidoModel[]);
      setListProdutos(res as ProdutoResumidoModel[]);
      setListMedicamentosSinc((medicamentos as MedicamentoPreco[]) ?? []);

      const principioAtivoSet = new Set();
      (medicamentos as MedicamentoPreco[]).forEach((produto: MedicamentoPreco) => {
        if (produto.composicao) {
          principioAtivoSet.add(produto.composicao);
        }
      });
      const principioAtivoArray = Array.from(principioAtivoSet);

      setPrincipioAtivo(principioAtivoArray as string[]);

      callEvent(AppEventEnum.QtdPrincipioAtivo, principioAtivoArray.length ?? 0);
      callEvent(AppEventEnum.QtdProdutos, res.length);
      callEvent(AppEventEnum.QtdVarejo, varejo.length);
      callEvent(AppEventEnum.QtdMedicamentos, medicamentos.length ?? 0);
    },
    [callEvent]
  );

  // const searchMedicamentos = useCallback(
  //   async (termo: string) => {
  //     const query =
  //       '' + (!isEmpty(termo) ? 'Generico=' + termo : '') + `&Uf=${UFMock.find(uf => uf.Value === getEmpresaAtual()?.uf)?.Key ?? 35}&PageSize=50`;

  //     const res = await getMedicamentoPreco(query);
  //     if (res.erro) {
  //       setListMedicamentos([]);
  //       callEvent(AppEventEnum.QtdMedicamentos, 0);
  //       return;
  //     }
  //     setListMedicamentos(
  //       res.resultado?.data?.list ?? ([] as MedicamentoPreco[])
  //     );

  //     callEvent(
  //       AppEventEnum.QtdRevista,
  //       res.resultado?.data?.list.length ?? 0
  //     );
  //   },
  //   [callEvent, getEmpresaAtual, getMedicamentoPreco]
  // );

  const search = useCallback(async () => {
    try {
      if (termoPesquisar.length > 0) {
        await searchProducts(termoPesquisar);
        // if(isFarma) {
        //   await searchMedicamentos(termoPesquisar);
        // }
      } else {
        if (isFarma) {
          setPrincipioAtivo([]);
          setListMedicamentosSinc([]);
          callEvent(AppEventEnum.QtdPrincipioAtivo, 0);
          callEvent(AppEventEnum.QtdMedicamentos, 0);
          callEvent(AppEventEnum.QtdVarejo, 0);
          callEvent(AppEventEnum.QtdRevista, 0);
        }
        setListProdutos([]);
        callEvent(AppEventEnum.QtdProdutos, 0);
      }
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setAguardandoBusca(false);
    }
  }, [callEvent, isFarma, searchProducts, showToast, termoPesquisar]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setAguardandoBusca(true);
    timer.current = setTimeout(() => {
      search();
    }, 1500);

    return () => {
      clearTimeout(timer.current);
    };
  }, [search]);

  const onCardClickedProdutos = useCallback(
    async (produto: ProdutoResumidoModel) => {
      await inserirProdutoFromSearch(produto);
    },
    [inserirProdutoFromSearch]
  );

  const onCardHoldedProdutos = useCallback(
    async (produto: ProdutoResumidoModel) => {
      await inserirProdutoFromSearchDetailed(produto);
    },
    [inserirProdutoFromSearchDetailed]
  );

  const onCardClickedMedicamentos = useCallback(
    async (medicamento: MedicamentoPreco) => {
      try {
        await inserirProdutoFromSearchMedicamento(medicamento.codigoAnvisa)
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [inserirProdutoFromSearchMedicamento, showToast]
  );

  const onCardHoldedMedicamentos = useCallback(
    async (medicamento: MedicamentoPreco) => {
      try {
        await inserirProdutoFromSearchMedicamento(medicamento.codigoAnvisa)
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [inserirProdutoFromSearchMedicamento, showToast]
  );

  const onCardClickedPrincipioAtivo = useCallback(async (principio: string) => {
    setTermoPesquisar(principio);
    callEvent(AppEventEnum.AttTermoPesquisaProduto, principio)
    setPath('/medicamentos');
  }, [callEvent]);

  return (
    <>
      {isOpened && (
        <Grid className={classNames(classes.root, `${pathname.includes('/carrinho') &&
          isMobile ? classes.rootHeightCartMobile : pathname.includes('/carrinho') &&
            !isMobile ? classes.rootHeightCartDesk : !pathname.includes('/carrinho') &&
              isMobile ? classes.rootHeightMobile : classes.rootHeightDesk}`)}>
          <Grid className={classes.tab}>
            <TabResultadoPesquisa setPath={setPath} path={path} planoFarmaceutico={isFarma} />
          </Grid>
          <Grid item xs={12} className={classes.list}>
            {isEmpty(termoPesquisar) && (
              <CardNaoEncontrado
                mensagem={`Digite alguma informação para localizarmos os produtos.`}
                icon={<TecladoIcon tipo="GERAL" />}
              />
            )}
            {!isEmpty(termoPesquisar) && (
              <>
                {path === '/produtos' && (
                  <PesquisarProdutoListData
                    carregando={aguardandoBusca}
                    list={listProdutos}
                    onCardClicked={onCardClickedProdutos}
                    onCardHolded={onCardHoldedProdutos}
                  />
                )}

                {isFarma && path === '/medicamentos' && (
                  <PesquisarMedicamentoListData
                    carregando={aguardandoBusca}
                    list={listMedicamentosSinc}
                    onCardClicked={onCardClickedMedicamentos}
                    onCardHolded={onCardHoldedMedicamentos}
                  />
                )}

                {isFarma && path === '/varejo' && (
                  <PesquisarProdutoListData
                    carregando={aguardandoBusca}
                    list={listProdutosVarejo}
                    onCardClicked={onCardClickedProdutos}
                    onCardHolded={onCardHoldedProdutos}
                  />
                )}

                {isFarma && path === '/principioAtivo' && (
                  <PesquisarPrincipioAtivoListData
                    carregando={aguardandoBusca}
                    list={listPrincipioAtivo}
                    onCardClicked={onCardClickedPrincipioAtivo}
                  />
                )}

                {/* {isFarma && path === '/revista' && (
              <PesquisarMedicamentoListData
                carregando={aguardandoBusca}
                list={listMedicamentos}
                onCardClicked={onCardClickedMedicamentos}
                onCardHolded={onCardHoldedMedicamentos}
              />
            )} */}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
