import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState, ChangeEvent, useMemo } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { CircularLoading, useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { PontosVendaModel } from 'model/api/gestao/pontos-venda';
import { MenuIcon, NovoIcon, VoltarIcon } from 'views/components/icons';
import React from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { PDVListData } from './components/pdv-list-data/pdv-list-data';
import { useStyles } from './mov-selecionar-pdv-styles';
import { ButtonPrivateHeader } from 'views/components/controles';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { usePDV } from 'services/app/hooks/pdv';
import { useGetPontosVenda } from 'data/api/gestao/pontos-venda';
import { useConfirm } from 'material-ui-confirm';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';


const paymentDevicePermisions: {
  paymentDevice: EnumDeviceType
  tpsCaixaAllowed: EnumPDVTpCaixa[]
}[] = [
    {
      paymentDevice: EnumDeviceType.CORDOVA,
      tpsCaixaAllowed: [EnumPDVTpCaixa.ANDROID]
    },
    {
      paymentDevice: EnumDeviceType.NAVIGATOR,
      tpsCaixaAllowed: [EnumPDVTpCaixa.WEBPDV]
    }
  ]

export const MovSelecionarPDVPage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { abrirMenu } = useMenuPrincipal();

  const leftArea = React.useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu],
  );

  const {
    vincularPDVExistente,
    getPDV,
    carregando: carregandoPDV,
  } = usePDV();
  const confirm = useConfirm()

  const { showToast } = useToastSaurus();
  const [searchPDV, setSearchPDV] = useState<string>('');
  const history = useHistory();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { abrirNovoPDVDialog } = useCadastros()
  const { getPontosVenda, carregando: carregandoGetPDVS } = useGetPontosVenda();
  const carregando = carregandoGetPDVS || carregandoPDV;

  const checkPDV = useCallback(async () => {
    const pdv = getPDV();
    if (pdv === undefined) return;
    return history.push('/venda-simples/landing');
  }, [getPDV, history]);

  useEffect(() => {
    checkPDV();
  }, [checkPDV]);

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PontosVendaModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: 100,
      list: Array<PontosVendaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const onCardSelected = async (pdv: PontosVendaModel) => {
    try {
      await vincularPDVExistente(pdv);
      return history.push('/venda-simples/landing?verificarCadastros=true');
    } catch (err: any) {
      showToast('error', err.message);
    }
  };

  const search = useCallback(
    async (newPage: number) => {
      const query =
        'pageSize=100' +
        '&TemFiltro=true';
      try {
        const res = await getPontosVenda(
          getEmpresaSelecionada()?.Id || '',
          query,
          newPage,
        );
        if (res.erro) throw res.erro;
        if (res.resultado?.data?.list.length === 0) {
          abrirNovoPDVDialog();
        }

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getPontosVenda, getEmpresaSelecionada, fillResult, abrirNovoPDVDialog, showToast],
  );

  const onChangeSearchPDV = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchPDV(event.target.value);
    },
    [],
  );

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  const listPdv = useMemo(() => {
    return queryStatus.list.filter((p) => {
      let flag = true
      const paymentDevicePermision = paymentDevicePermisions.find((item) => {
        return item.paymentDevice === VariaveisAmbiente.paymentDevice
      })

      if(!paymentDevicePermision?.tpsCaixaAllowed.includes(p.tpCaixa)) {
        flag = false
      }

      return flag && (p.descricao.toUpperCase().includes(searchPDV.toUpperCase()) || p.numCaixa.toString().toUpperCase().includes(searchPDV.toUpperCase()))
    })
  }, [queryStatus.list, searchPDV])


  const textPDV = useMemo(() => (
    <>
      <Typography variant="h5" color="primary">
        Novo Dispositivo Identificado!
      </Typography>
      <Typography variant="caption">
        Se você está substituindo algum ponto de venda antigo, selecione-o
        abaixo ou se for um novo, clique no botão abaixo.
      </Typography>
    </>
  ), [])

  const header = useMemo(() => (
    <Grid className={classes.header}>
      <PrivatePageHeader
        title={'Seleção de Ponto de Venda'}
        leftArea={leftArea()}
      />
    </Grid>
  ), [classes.header, leftArea])

  const novoIcon = useMemo(() => (
    <NovoIcon tipo="BUTTON_PRIMARY" />
  ), [])

  const btnBottom = useMemo(() => (
    <Grid className={classes.bottom}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={abrirNovoPDVDialog}
        className='round'
      >
        {novoIcon}
        Adicionar Novo Ponto de Venda
      </Button>
    </Grid>
  ), [abrirNovoPDVDialog, classes.bottom, novoIcon])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.root}>
        {header}
        <Grid className={classes.detail}>
          {textPDV}
          <TextFieldSaurus
            tipo="TEXTO"
            fullWidth
            style={{ marginTop: 5 }}
            size="small"
            allowSubmit={true}
            value={searchPDV}
            placeholder="Buscar Ponto de Venda..."
            onChange={onChangeSearchPDV}
            searchable
          />
        </Grid>
        <Grid className={classes.list}>
          <Grid style={{ padding: 8 }}>
            <PDVListData
              carregando={carregando}
              list={listPdv}
              onCardSelected={(e) => {
                confirm({
                  title: 'Deseja confirmar a seleção deste caixa?',
                  description: `Ao confirmar, esse caixa será vinculado em todas as venda
                  enquanto sua sessão estiver ativa.`,
                  confirmationText: <><ConfirmarIcon tipo="BUTTON_PRIMARY" />Confirmar</>,
                  cancellationText: <><VoltarIcon tipo="BUTTON" />Voltar</>,
                  confirmationButtonProps: {
                    className: 'round'
                  },
                  cancellationButtonProps: {
                    variant: 'outlined',
                    className: 'round'
                  },
                  titleProps: {
                    style: {
                      fontWeight: 700
                    },
                  }
                })
                  .then(() => {
                    onCardSelected(e)
                  })
              }}
            />
          </Grid>
        </Grid>
        {btnBottom}
      </Grid>
    </>
  );
};
