import { EnumComandas } from './../../../enums/enum-comandas';

export class StatusComanda {
    constructor(
        public codigo: EnumComandas = EnumComandas.ATIVO,
        public descricao: string = 'Ativo',
    ) { }
}
export class ComandasModel {
    constructor(
        public id: string = '',
        public codigoComanda: string = '',
        public rfId: string = '',
        public status: StatusComanda = new StatusComanda(),
    ) { }
}
