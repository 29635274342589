import { Grid } from '@material-ui/core';
import { useStyles } from './teclado-mesa-styles';
import React, { useCallback } from 'react';
import { TecladoMesaFragment } from '../teclado-mesa-fragment/teclado-mesa-fragment';
import { useToastSaurus } from 'services/app';

interface TecladoMesaProps {
  setCodigoMesa: (value: string) => void;
}

const TecladoMesa = ({ setCodigoMesa }: TecladoMesaProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        setCodigoMesa(text);
        return true;
      } catch (e: any) {
        showToast('error', e.message);
        return false;
      }
    },
    [setCodigoMesa, showToast],
  );

  return (
    <Grid className={classes.rootTeclado}>
      <Grid className={classes.gridTeclado}>
        <TecladoMesaFragment textChanged={textChanged} />
      </Grid>
    </Grid>
  );
};

export default React.memo(TecladoMesa);
