
import { guidEmpty } from 'utils/guid-empty';

export class ProdutoMedidaModel {
    constructor(
      public id: string = guidEmpty(),
      public contratoId: string = guidEmpty(),
      public empresaId: string = guidEmpty(),
      public descricao: string = "",
      public sigla: string = "",
      public fator: number = 0,

    ) {}
  }
