import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { VoltarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { useHistory } from 'react-router-dom';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';

type Props = {
  id: string
}

export const CancelarVendaHeader = ({ id }: Props) => {
  const { isMobile } = useThemeQueries();
  const history = useHistory()
  const voltarHistorico = useCallback(() => history.push('/historico'), [history])
  const { goToAdministrativo } = useOpenMovRota()

  const leftArea = useCallback(() =>
    isMobile ? (
      <ButtonPrivateHeader
        icon={
          id !== 'undefined'
            ? <VoltarIcon tipo="PRIVATE_HEADER" />
            : <MenuCirculadoIcon tipo="PRIVATE_HEADER" />
        }
        tooltip={
          id !== 'undefined'
            ? "Voltar ao Histórico"
            : 'Ir para Menu Administrativo'
        }
        onClick={
          id !== 'undefined'
            ? voltarHistorico
            : goToAdministrativo
        }
      />
    ) : null
    , [isMobile, id, voltarHistorico, goToAdministrativo]);

  return (
    <>
      <PrivatePageHeader title={'Cancelamento de Venda'} leftArea={leftArea()} />
    </>
  );
};
