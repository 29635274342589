import { EnumTipoReceita } from 'model/enums/enum-tipo-receita';

export class ReceitaPreCadastroFormModel {
  constructor(
    public receitaTipo: EnumTipoReceita = EnumTipoReceita.NotificacaoReceitaA,
    public dataHoraLancamento: Date | string = '',
    public dataReceita: Date | string = '',
    public numeroReceita: string = '',
    public uf: string = '',
    public cid: string = '',
  ) { }
}
