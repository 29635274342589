import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
        alignItems: 'center',
        justifyContent: 'center',
    },
    resumoContainer: {
        flex: '1 1 100%',
        width: '100%',
    },
    imagemContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'auto',
            marginLeft: theme.spacing(0),
            marginBottom: theme.spacing(4),
        },
    },
    accordion: {
        margin: `${theme.spacing(1, 2)} !important`,
        background: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(1)} !important`,
        }
    }
}));
