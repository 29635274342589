import { Tooltip, Typography, Grid } from '@material-ui/core';
import { CardEntradaProps } from './card-entrada-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useThemeQueries } from '../../..';
import { toDateString } from 'utils/to-date';
import { toDecimalString } from 'utils/to-decimal';
import { useStyles } from './card-entrada-styles';
import { EnumHistoricoVendaStatus } from 'model/enums/enum-historico-venda-status';
import { StatusHistoricoVendaMock } from 'data/mocks/status-historico-venda-mock';
import classNames from 'classnames';

export const CardEntrada = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardEntradaProps) => {
  const classes = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();
  const myClasses = useStyles()

  const retornarCorStatus = (status: EnumHistoricoVendaStatus) => ({
    [EnumHistoricoVendaStatus.Pendente]: theme.palette.warning.main,
    [EnumHistoricoVendaStatus.Finalizado]: theme.palette.success.main,
    [EnumHistoricoVendaStatus.Cancelado]: theme.palette.error.main,
    [EnumHistoricoVendaStatus.Abortado]: theme.palette.error.main,
    [EnumHistoricoVendaStatus.Rejeitado]: theme.palette.error.main,
    [EnumHistoricoVendaStatus.EmDigitacao]: theme.palette.warning.dark,
  })[status] || theme.palette.primary.main;

  const descricao = StatusHistoricoVendaMock.filter(
    (x) => x.Key === model.status,
  )[0]?.Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model);
        }}
        hasTagStatus={false}

      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus(model.status) }}
          ></div>
        </Tooltip>
        <Grid className={classNames(classes.cardContent, myClasses.cardContent)} >
          <Grid container spacing={1} >
            <Grid item xs={4}  >
              <Typography variant="body1" className={myClasses.cardTitle} >
                Emitente
              </Typography>
              <Typography
                variant="body1"
                className={'celula-grid-value'}
                noWrap
              >
                <strong>{model.emitNome}</strong>
              </Typography>
            </Grid>
            <Grid item xs={5} >
              <Typography variant="body1" className={myClasses.cardTitle}>
                Data de Competência
              </Typography>
              <Typography
                variant="body1"
                className={'celula-grid-value'}
              >
                <strong>{toDateString(new Date(model.dhCompetencia + 'Z' as string), 'DD/MM/yyyy HH:mm')}</strong>
              </Typography>
            </Grid>
            {isMobile &&
              <Grid item xs={3} alignItems='center' justifyContent='center' className={myClasses.lineContent} >
                <EditarIcon tipo="BUTTON" />
              </Grid>
            }


          </Grid>
          <Grid container spacing={1} className={myClasses.lineContent}>
            <Grid item xs={4}>
              <Typography variant="body1" className={myClasses.cardTitle}>
                Itens
              </Typography>
              <Typography
                variant="body1"
                className={'celula-grid-value'}
              >
                <strong>{model.totalQtCom}</strong>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1" className={myClasses.cardTitle}>
                Número
              </Typography>
              <Typography
                variant="body1"
                className={'celula-grid-value'}
              >
                <strong>{model.nnf}/{String(model.serie).padStart(3, '0')}</strong>
              </Typography>
            </Grid>
            <Grid item xs={3} alignItems='center' className={myClasses.lineContentColumn} >
              <Typography variant="body1" className={myClasses.cardTitle} >
                Valor Total
              </Typography>
              <Typography
                variant="body1"
                className={'celula-grid-value'}
              >
                <strong>R$ {toDecimalString(model.totalVNf, 2)}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Tooltip arrow title="Editar Entrada">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
