import React from "react";
import { useMenuStorage } from "services/app/hooks/menu-storage";
import { MenuPrincipalModelo, MenuPrincipalModo } from "./menu-principal-types";
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';

export const useMenuPrincipal = () => {
    const { get, persist } = useMenuStorage();

    const abrirMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();
        menuAtual.situacao = EnumMenuPrincipalStatus.Aberto;

        persist(menuAtual);

    }, [get, persist]);

    const fecharMenu = React.useCallback(async (): Promise<void> => {
        var menuAtual = get();
        menuAtual.situacao = EnumMenuPrincipalStatus.Fechado;

        persist(menuAtual);
    }, [get, persist]);

    const alterarModelo = React.useCallback(async (modelo: MenuPrincipalModelo): Promise<void> => {
        var menuAtual = get();
        menuAtual.modelo = modelo;

        persist(menuAtual);
    }, [get, persist]);

    const alterarModo = React.useCallback(async (modo: MenuPrincipalModo): Promise<void> => {
        var menuAtual = get();
        menuAtual.modo = modo;

        persist(menuAtual);
    }, [get, persist]);

    const isMenuAberto = React.useCallback(() => {
        const menuAtual = get();

        return menuAtual.situacao === EnumMenuPrincipalStatus.Aberto
    }, [get])

    return {
        abrirMenu,
        fecharMenu,
        alterarModelo,
        alterarModo,
        isMenuAberto
    }
};
