import { Grid } from '@material-ui/core';
import { SelecaoVendedorHeader } from './components/selecao-vendedor-header/selecao-vendedor-header';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SelecaoVendedorList } from './components/selecao-vendedor-list/selecao-vendedor-list';

export const MovSelecaoVendedorPage = () => {
  const classes = useDefaultCadastroStyles();

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.root}>
          <Grid className={classes.header}>
            <SelecaoVendedorHeader />
          </Grid>
          <Grid className={classes.list}>
            <SelecaoVendedorList />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
