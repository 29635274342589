import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogConfigResumoSessao } from 'views/components/dialog/dialog-config-resumo-sessao/dialog-config-resumo-sessao';

export interface ConfigResumoSessaoDialogProps {
  aberto: boolean;
  sessaoId: string;
}

export const ConfigResumoSessaoDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<ConfigResumoSessaoDialogProps>();
  consoleDev('ConfigResumoSessaoDialog');

  const modalAlterado = useCallback(
    ({ aberto, sessaoId }: ConfigResumoSessaoDialogProps) => {
      setModalState({
        aberto,
        sessaoId
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConfigResumoSessao, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogConfigResumoSessao, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState?.aberto && (
        <DialogConfigResumoSessao
          aberto={modalState.aberto}
          sessaoId={modalState.sessaoId}
        />
      )}
    </>
  );
};
