import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { CardHistoricoMobileOff } from 'views/components/cards/card-historico-venda/card-historico-mobile-off';
import { CardHistoricoVendaOff } from 'views/components/cards/card-historico-venda/card-historico-venda-off';
import { VendaOffModel } from 'model/api/gestao/venda/venda-off-model';

export interface VendaOffListDataProps {
  vendas: Array<VendaOffModel>;
  carregando: boolean;
  selectedList: Array<VendaOffModel>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const VendaOffListData = (props: VendaOffListDataProps) => {
  const { isMobile } = useThemeQueries();
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.vendas.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma movimentação encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.vendas.length > 0 &&
        props.vendas.map((historico, index) => {
          return isMobile ? (
            <CardHistoricoMobileOff
              selected={
                props.selectedList.filter((item) => item.id === historico.id!)
                  .length > 0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={historico}
              key={index}
            />
          ) : (
            <CardHistoricoVendaOff
              selected={
                props.selectedList.filter((item) => item.id === historico.id!)
                  .length > 0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={historico}
              key={index}
            />
          );
        })}
    </>
  );
};
