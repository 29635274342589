import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { ProdutoList } from './produto-list/produto-list';
import { ListProductsMedProps } from './list-products-med-props';

export const ListProductsMed = ({ refMedicamentos }: ListProductsMedProps) => {
    const classes = useDefaultCadastroStyles();

    return (
        <Grid className={classes.root} style={{ borderRadius: 8 }}>
            <Grid className={classes.list}>
                <ProdutoList {...{refMedicamentos}} />
            </Grid>
        </Grid>
    )
}