import { createContext, useCallback, useContext } from "react";
import { isEmpty } from 'lodash';
import { GestaoStorageKeys, useGestaoStorage } from "../hooks/gestao-storage";
import { TokenGestaoModel } from "../../../model/api/gestao/master";
import { decodeToken } from "react-jwt";
import { UsuarioConectadoModel } from "../../../model/app";
import { PerfilModel } from '../../../model/api/gestao/master/perfil';
import { PlanoAtualModel } from "model/api/gestao/plano/plano-atual-model";
import { EmpresaCompletaModel } from '../../../model/api/gestao/master/empresaCompleta';
import { consoleDev } from "utils/console-dev";
import { MeuUsuarioModel } from 'model/api/gestao/master/meu-usuario';
import { PessoaModel } from 'model/api/gestao/pessoa';


interface GestaoTokenContextProps {
    isTokenExpired: (token: TokenGestaoModel | undefined) => boolean
    convertToken: (token?: string) => TokenGestaoModel | undefined
    getTokenFromStorage: () => TokenGestaoModel | undefined
    isTokenValid: (token: TokenGestaoModel | undefined) => boolean
    persistToken: (token: TokenGestaoModel | undefined) => void
    persistPerfilPermissoes: (perfis: Array<PerfilModel> | undefined) => void
    getPerfilPermissoes: () => Array<PerfilModel>
    persistPessoaUsuario: (perfis: { pessoa: PessoaModel } | undefined) => void
    getPessoaUsuario: () => { pessoa: PessoaModel }
    persistPlanoUsuario: (plano: PlanoAtualModel | undefined) => void
    getPlanoUsuario: () => PlanoAtualModel | undefined
    persistTermosDeUso: (valid: boolean | undefined, statusApi: number | undefined) => void
    getTermosDeUso: () => boolean
    getEmpresaAtual: () => EmpresaCompletaModel | undefined
    removeConnectedUser: (usuarioId: string) => void
    getConnectedUsers: () => UsuarioConectadoModel[]
    addConnectedUser: (token: TokenGestaoModel) => void
    getMeuUsuarioStorage: () => MeuUsuarioModel | undefined,
    persistMeuUsuarioStorage: (usuario: MeuUsuarioModel | undefined) => void;
}

const GestaoTokenContext = createContext<GestaoTokenContextProps>({} as GestaoTokenContextProps);

export interface GestaoTokenProviderProps {
    children: React.ReactNode;
}

export const useGestaoToken = () => {
    return useContext(GestaoTokenContext);
};

export const GestaoTokenProvider = ({ children }: GestaoTokenProviderProps) => {
    const { setRegistro, delRegistro, getRegistro } = useGestaoStorage();
    const tokenFromSession: boolean = false;

    consoleDev('GestaoToken')

    const isTokenExpired = useCallback((token: TokenGestaoModel | undefined): boolean => {
        if (!token?.exp) {
            // consoleDev('isTokenExpired', 'token?.exp não definido');
            return true;
        }
        const dhAtual = new Date().getTime();
        // consoleDev('isTokenExpired', token?.exp * 1000 + 'vs' + dhAtual);
        return token?.exp * 1000 <= dhAtual;
    }, []);

    const convertToken = useCallback((token?: string): TokenGestaoModel | undefined => {
        let gtoken: TokenGestaoModel | undefined;
        try {
            const utf8 = require('utf8');
            gtoken = decodeToken(token!) as TokenGestaoModel;
            try {
                // consoleDev('convertToken-decode1', gtoken.saudacao)
                // consoleDev('convertToken-decode2', utf8.decode(gtoken.saudacao))
                gtoken.saudacao = utf8.decode(gtoken.saudacao);
            } catch { }
            gtoken.originalToken = token || '';
            gtoken.empresa = JSON.parse(JSON.parse(JSON.stringify(gtoken.empresa)))
            gtoken.modulos = JSON.parse(JSON.parse(JSON.stringify(gtoken.modulos)))
            gtoken.licenca = JSON.parse(JSON.parse(JSON.stringify(gtoken.licenca)))
            gtoken.usuarioId = gtoken.sub || gtoken.jti;
            // consoleDev('convertTokenParse-Final', gtoken);
        } catch (e) {
            // consoleDev('convertTokenParse-Catch', e);
            gtoken = undefined;
        }
        return gtoken;
    }, []);

    const getTokenFromStorage = useCallback((): TokenGestaoModel | undefined => {
        const token = getRegistro(GestaoStorageKeys.Token, tokenFromSession);
        if (isEmpty(token))
            return undefined;

        return (convertToken(token));
    }, [getRegistro, convertToken, tokenFromSession]);

    const isTokenValid = useCallback((token: TokenGestaoModel | undefined): boolean => {
        return !isTokenExpired(token);
    }, [isTokenExpired]);

    const persistToken = useCallback((token: TokenGestaoModel | undefined): void => {
        if (isEmpty(token)) {
            return delRegistro(GestaoStorageKeys.Token, tokenFromSession);
        } else {
            return setRegistro(GestaoStorageKeys.Token, token?.originalToken || "", tokenFromSession);
        }
    }, [delRegistro, setRegistro, tokenFromSession]);

    const persistPerfilPermissoes = useCallback((perfis: Array<PerfilModel> | undefined): void => {
        if (perfis)
            setRegistro(GestaoStorageKeys.PerfisDoUsuario, perfis, tokenFromSession);
        else
            delRegistro(GestaoStorageKeys.PerfisDoUsuario, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getPerfilPermissoes = useCallback((): Array<PerfilModel> => {
        const ret = getRegistro(GestaoStorageKeys.PerfisDoUsuario, tokenFromSession);
        if (isEmpty(ret))
            return new Array<PerfilModel>();

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistPessoaUsuario = useCallback((perfis: { pessoa: PessoaModel } | undefined): void => {
        if (perfis)
            setRegistro(GestaoStorageKeys.PessoaDoUsuario, perfis, tokenFromSession);
        else
            delRegistro(GestaoStorageKeys.PessoaDoUsuario, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getPessoaUsuario = useCallback((): { pessoa: PessoaModel } => {
        const ret = getRegistro(GestaoStorageKeys.PessoaDoUsuario, tokenFromSession);
        if (isEmpty(ret))
            return { pessoa: new PessoaModel() };

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistPlanoUsuario = useCallback((plano: PlanoAtualModel | undefined): void => {
        if (plano)
            setRegistro(GestaoStorageKeys.Plano, plano, tokenFromSession);
        else
            delRegistro(GestaoStorageKeys.Plano, tokenFromSession);
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getPlanoUsuario = useCallback((): PlanoAtualModel | undefined => {
        const ret = getRegistro(GestaoStorageKeys.Plano, tokenFromSession);
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro, tokenFromSession])

    const persistTermosDeUso = useCallback((valid: boolean | undefined, statusApi: number | undefined): void => {
        if (valid === undefined)
            delRegistro(GestaoStorageKeys.TermosDeUso, tokenFromSession)
        else {
            const termo = {
                termoAceito: valid,
                statusApi
            }
            setRegistro(GestaoStorageKeys.TermosDeUso, termo, tokenFromSession);
        }
    }, [setRegistro, delRegistro, tokenFromSession]);

    const getTermosDeUso = useCallback((): boolean => {
        const ret = getRegistro(GestaoStorageKeys.TermosDeUso, tokenFromSession);
        if (isEmpty(ret)) {
            return true;
        } if (ret.termoAceito === false && ret.statusApi === 400) {
            return false
        } else {
            return true;
        }
    }, [getRegistro, tokenFromSession])

    const getEmpresaAtual = useCallback((): EmpresaCompletaModel | undefined => {
        const ret = getRegistro(GestaoStorageKeys.EmpresaAtual, tokenFromSession) as EmpresaCompletaModel;
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro, tokenFromSession])

    const removeConnectedUser = useCallback((usuarioId: string) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(GestaoStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        conectados = conectados.filter(x => x.usuarioId !== usuarioId);
        setRegistro(GestaoStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    const getConnectedUsers = useCallback((): UsuarioConectadoModel[] => {
        let conectados: UsuarioConectadoModel[] = getRegistro(GestaoStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }
        return conectados;
    }, [getRegistro]);

    const addConnectedUser = useCallback((token: TokenGestaoModel) => {
        let conectados: UsuarioConectadoModel[] = getRegistro(GestaoStorageKeys.UsuariosConectados, false) as UsuarioConectadoModel[];
        if (isEmpty(conectados)) {
            conectados = new Array<UsuarioConectadoModel>();
        }

        conectados = conectados.filter(x => x.usuarioId !== token.usuarioId);

        let conUser = new UsuarioConectadoModel(token.usuarioId, token.empresa[0].Descricao, token.saudacao, token.originalToken);
        conectados.push(conUser);

        setRegistro(GestaoStorageKeys.UsuariosConectados, conectados, false);
    }, [setRegistro, getRegistro]);

    const getMeuUsuarioStorage = useCallback(() => {
        const usuario = getRegistro(GestaoStorageKeys.MeuUsuario, false) as MeuUsuarioModel;
        if (isEmpty(usuario)) {
            return undefined;
        }

        return usuario
    }, [getRegistro]);

    const persistMeuUsuarioStorage = useCallback((meuUsuario: MeuUsuarioModel | undefined): void => {
        if (meuUsuario)
            setRegistro(GestaoStorageKeys.MeuUsuario, meuUsuario, false);
        else
            delRegistro(GestaoStorageKeys.MeuUsuario, false);
    }, [setRegistro, delRegistro]);


    return (
        <GestaoTokenContext.Provider value={{
            isTokenExpired,
            isTokenValid,
            persistToken,
            getTokenFromStorage,
            convertToken,
            removeConnectedUser,
            addConnectedUser,
            getConnectedUsers,
            persistTermosDeUso,
            getTermosDeUso,
            persistPerfilPermissoes,
            getPerfilPermissoes,
            persistPessoaUsuario,
            getPessoaUsuario,
            getEmpresaAtual,
            getPlanoUsuario,
            persistPlanoUsuario,
            getMeuUsuarioStorage,
            persistMeuUsuarioStorage
        }}>
            {children}
        </GestaoTokenContext.Provider>
    )
}