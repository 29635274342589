import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { MedicamentoPostModel } from "model/api/gestao/medicamento/medicamento-model";
import { VariaveisAmbiente } from "config";


export function usePostMedicamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postMedicamento= useCallback(
    (medicamento: MedicamentoPostModel) =>
      {
        return invocarApi({
          url: `${VariaveisAmbiente.gatewayVersion}/Medicamento`,
          method: 'POST',
          enviarTokenUsuario: true,
          data: medicamento
        })
      },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postMedicamento,
  };
}