import { Paper, Typography } from "@material-ui/core"
import { CardDashboardAdminChartHeatMapProps } from "../card-dashboard-admin-chart-props"
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts'
import { useCallback } from "react";
import classNames from "classnames";
import { CircularLoading } from "views/components/utils";
import { toDecimalString } from "utils/to-decimal";

export const CardDashboardAdminChartHeatMap = ({ model, carregando }: CardDashboardAdminChartHeatMapProps) => {

    const classes = useDefaultCardStyles();
    const utilClasses = useStyles()

    const pegarDia = (item: String) => {
        let ano = item.slice(0, 4)
        let mes = item.slice(4, 6)
        let dia = item.slice(6, 8)
        return `${ano}-${mes}-${Number(dia) + 1}`
    }

    interface ArrayHoraProps {
        x: string;
        y: number
    }

    const getSeries = useCallback(() => {

        let dia = []

        if (!model) {
            return;
        }

        let horas = new Array<number>();

        for (let i = 0; i <= 23; i++) {
            horas[i] = 0;
        }

        let horaArray = Array.from(new Set<number>(
            model.map(item => item.hora)
        ))

        horaArray.forEach((item) => {
            horas[item]++;
        });

        let horasIniciais: number = 23;
        let horasFinais: number = 0;

        model.forEach(item => {
            if (item.hora < horasIniciais) {
                horasIniciais = item.hora
            }
            if (item.hora > horasFinais)
                horasFinais = item.hora;
        });

        let horarioLimite = 0

        for (let i = horasIniciais; i < horasFinais; i++) {
            horarioLimite++
        }

        for (let i = 0; i < 7; i++) {

            const arrayHora = new Array<ArrayHoraProps>();

            for (
                let hora = (horarioLimite > 10 ? 0 : horasIniciais);
                hora <= (horarioLimite > 10 ? horas.length : horasFinais);
                hora++
            ) {
                let registros = model.filter(item => item.hora === hora && new Date(pegarDia(String(item.data))).getDay() === i)

                let valorHora: number = 0;

                registros.forEach(item => valorHora += item.vVenda);

                if (horarioLimite > 10) {
                    if (horas[hora] > 0) {
                        arrayHora.push({
                            x: `${hora}h`,
                            y: valorHora,
                        });
                    }
                } else {
                    arrayHora.push({
                        x: `${hora}h`,
                        y: valorHora,
                    });
                }

            }

            dia[i] = arrayHora;
        }

        const serie: any[] = [
            {
                name: "Dom",
                data: dia[0]
            },
            {
                name: "Seg",
                data: dia[1]
            },
            {
                name: "Ter",
                data: dia[2]
            },
            {
                name: "Qua",
                data: dia[3]
            },
            {
                name: "Qui",
                data: dia[4]
            },
            {
                name: "Sex",
                data: dia[5]
            },
            {
                name: "Sáb",
                data: dia[6]
            },
        ]

        return serie.reverse();
    }, [model])

    const options = {
        plotOptions: {
            bar: {
                horizontal: true,
            },
            heatmap: {
                radius: 5,
            },
        },
        tooltip: {
            style: {
                fontSize: '24px'
            },
            y: {
                formatter: (val: any) => `R$ ${toDecimalString(val)}`
            },
            x: {
                formatter: (val: any) => `${val}`
            }
        },
        dataLabels: {
            enabled: true,
            fontSize: 16,
            height: 30,
            formatter: (val: any) => val > 0 ? parseInt(val) : val,
            style: {
                colors: ['#666']
            }
        },
        colors: ["#1F9CE4"],
    }

    return (
        <>
            <Paper className={classNames(utilClasses.cardContainer, 'card')}>
                <div className={utilClasses.cardContent}>
                    <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
                        <Typography color="primary" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
                            Receita por temperatura
                        </Typography>
                    </div>
                    <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>
                    <div className={utilClasses.alinharChart}>
                        {carregando ?
                            <CircularLoading tipo="FULLSIZED" />
                            :
                            <div style={{ display: 'flex',justifyContent: 'center', marginTop: 20 }}>
                                {model.length === 0 ?
                                    <Typography variant="h5" style={{ textAlign: 'center' }}>Não há dados para exibir</Typography>
                                    :
                                    <Chart options={options} series={getSeries()} type="heatmap" className={utilClasses.chartHeatmap} />
                                }
                            </div>
                        }
                    </div>
                </div>
            </Paper>
        </>
    )
}
