import { Box, Button } from "@material-ui/core";
import { useStyles } from '../../controles/radio-list/radio-list-styles'
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { Controller, useForm } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";

export interface PessoaPesquisaGenericoFormModel {
    generico: string;
}

interface FormPesquisaGenericoProps {
    onSubmit: (model: PessoaPesquisaGenericoFormModel) => void;
    text: string;
    label?: string;
    placeholder?: string;
    showBarcodeCam?: boolean;
}

export const FormPesquisaGenerico = ({
    onSubmit,
    text,
    label = 'Pesquisar',
    placeholder = 'Digite aqui...',
    showBarcodeCam = false
}: FormPesquisaGenericoProps) => {

    const classes = useStyles();

    const {
        handleSubmit,
        control,
    } = useForm<PessoaPesquisaGenericoFormModel>({
        defaultValues: {
            generico: text
        },
        criteriaMode: 'all',
        mode: 'onChange'
    });

    return (
        <Box className={classes.container}>
            <form onSubmit={() => handleSubmit(onSubmit)()} className={classes.text}>
                <Controller
                    name="generico"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            label={label}
                            placeholder={placeholder}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            showCamBarcode={showBarcodeCam}
                            fullWidth
                            allowSubmit
                            autoComplete="new-password"
                            {...field}
                        />
                    )}
                />
            </form>
            <Box className={classes.button}>
                <Button fullWidth variant='contained' color='primary' onClick={() => handleSubmit(onSubmit)()}>
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Box>
        </Box>
    )
}