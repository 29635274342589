import { emphasize, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '150px',
    height: '150px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiFab-primary': {
      right: 0,
      bottom: 0,
      '& svg': {
        width: 25,
        height: 25
      }
    }
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    border: '1px solid #eee',
    borderRadius: '0px',
    height: '100%',
    boxShadow: theme.shadows[4]
  },
  removerImagem: {
    marginTop: '10px',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: emphasize(theme.palette.error.main, 0.15)
    }
  }
}));
