import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    divider: {
        backgroundColor: theme.palette.grey[300],
        height: '2px',
        marginTop: '5px',
        marginBottom: '5px',
        marginleft: '8px',
        marginRight: '8px',
    },
    containerItens: {
        background: '#F7F7F7',
        border: '0.891228px solid #BDBDBD',
        borderRadius: '4.5px',
        margin: '4px',
        padding: '8px',
    },
    dot: {
        borderRadius: '50%',
        background: '#1F9CE4',
        width: '4px',
        height: '4px',
        content: "' '",
        marginLeft: '3px',
        marginRight: '3px'
    },
    textItem: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        fontWeight: 600,
    }
}));
