import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { MarcaModel } from "model/api/gestao/marca/marca-model";


export function usePostMarca() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postMarca = useCallback(
    (marca: MarcaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/marca`,
        method: 'POST',
        enviarTokenUsuario: true,
        data: marca
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postMarca,
  };
}