import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerGrid: {
        padding: theme.spacing(1),
        display: 'block',
        "& form": {
            marginTop: 0
        },

    },
    cardForm: {
        backgroundColor: "#FFF",
        padding: '35px 16px',
        borderRadius: 10,
        "& form": {
            marginTop: 0
        },
        boxShadow: "1px 1px 2px rgb(0 0 0 / 10%)"
    }
}));