import { Overrides } from "@material-ui/core/styles/overrides"
import { VariaveisAmbiente } from "config"

export const noAnimation = {
    animation: VariaveisAmbiente.isSlowDevice ? "none !important" : undefined,
    transition: VariaveisAmbiente.isSlowDevice ? "none !important" : undefined,
    "& *": {
        animation: VariaveisAmbiente.isSlowDevice ? "none" : undefined,
        transition: VariaveisAmbiente.isSlowDevice ? "none" : undefined,
    },
}

export const slowTheme: Overrides = {
    MuiAccordion: { root: { ...noAnimation, } },
    MuiAccordionActions: { root: { ...noAnimation, } },
    MuiAccordionDetails: { root: { ...noAnimation, } },
    MuiAccordionSummary: { root: { ...noAnimation, } },
    MuiAppBar: { root: { ...noAnimation, } },
    MuiAvatar: { root: { ...noAnimation, } },
    MuiBackdrop: { root: { ...noAnimation, } },
    MuiBadge: { root: { ...noAnimation, } },
    MuiBottomNavigation: { root: { ...noAnimation, } },
    MuiBottomNavigationAction: { root: { ...noAnimation, } },
    MuiBreadcrumbs: { root: { ...noAnimation, } },
    MuiButton: { root: { ...noAnimation, } },
    MuiButtonBase: { root: { ...noAnimation, } },
    MuiButtonGroup: { root: { ...noAnimation, } },
    MuiCard: { root: { ...noAnimation, } },
    MuiCardActionArea: { root: { ...noAnimation, } },
    MuiCardActions: { root: { ...noAnimation, } },
    MuiCardContent: { root: { ...noAnimation, } },
    MuiCardHeader: { root: { ...noAnimation, } },
    MuiCardMedia: { root: { ...noAnimation, } },
    MuiCheckbox: { root: { ...noAnimation, } },
    MuiChip: { root: { ...noAnimation, } },
    MuiCircularProgress: { root: { ...noAnimation, } },
    MuiCollapse: { root: { ...noAnimation, } },
    MuiContainer: { root: { ...noAnimation, } },
    MuiDialog: { root: { ...noAnimation, } },
    MuiDialogActions: { root: { ...noAnimation, } },
    MuiDialogContent: { root: { ...noAnimation, } },
    MuiDialogContentText: { root: { ...noAnimation, } },
    MuiDialogTitle: { root: { ...noAnimation, } },
    MuiDivider: { root: { ...noAnimation, } },
    MuiDrawer: { root: { ...noAnimation, } },
    MuiExpansionPanel: { root: { ...noAnimation, } },
    MuiExpansionPanelActions: { root: { ...noAnimation, } },
    MuiExpansionPanelDetails: { root: { ...noAnimation, } },
    MuiExpansionPanelSummary: { root: { ...noAnimation, } },
    MuiFab: { root: { ...noAnimation, } },
    MuiFilledInput: { root: { ...noAnimation, } },
    MuiFormControl: { root: { ...noAnimation, } },
    MuiFormControlLabel: { root: { ...noAnimation, } },
    MuiFormGroup: { root: { ...noAnimation, } },
    MuiFormHelperText: { root: { ...noAnimation, } },
    MuiFormLabel: { root: { ...noAnimation, } },
    MuiGrid: { root: { ...noAnimation, } },
    MuiIcon: { root: { ...noAnimation, } },
    MuiIconButton: { root: { ...noAnimation, } },
    MuiImageList: { root: { ...noAnimation, } },
    MuiImageListItem: { root: { ...noAnimation, } },
    MuiImageListItemBar: { root: { ...noAnimation, } },
    MuiInput: { root: { ...noAnimation, } },
    MuiInputAdornment: { root: { ...noAnimation, } },
    MuiInputBase: { root: { ...noAnimation, } },
    MuiInputLabel: { root: { ...noAnimation, } },
    MuiLinearProgress: { root: { ...noAnimation, } },
    MuiLink: { root: { ...noAnimation, } },
    MuiList: { root: { ...noAnimation, }, dense: { ...noAnimation, }, subheader: { ...noAnimation, } },
    MuiListItem: { root: { ...noAnimation, } },
    MuiListItemAvatar: { root: { ...noAnimation, } },
    MuiListItemIcon: { root: { ...noAnimation, } },
    MuiListItemSecondaryAction: { root: { ...noAnimation, } },
    MuiListItemText: { root: { ...noAnimation, } },
    MuiListSubheader: { root: { ...noAnimation, } },
    MuiMenu: { paper: { ...noAnimation, } },
    MuiMenuItem: { root: { ...noAnimation, } },
    MuiMobileStepper: { root: { ...noAnimation, } },
    MuiNativeSelect: { root: { ...noAnimation, } },
    MuiOutlinedInput: { root: { ...noAnimation, } },
    MuiPaper: { root: { ...noAnimation, } },
    MuiPopover: { root: { ...noAnimation, } },
    MuiRadio: { root: { ...noAnimation, } },
    MuiScopedCssBaseline: { root: { ...noAnimation, } },
    MuiSelect: { root: { ...noAnimation, } },
    MuiSlider: { root: { ...noAnimation, } },
    MuiSnackbar: { root: { ...noAnimation, } },
    MuiSnackbarContent: { root: { ...noAnimation, } },
    MuiStep: { root: { ...noAnimation, } },
    MuiStepButton: { root: { ...noAnimation, } },
    MuiStepConnector: { root: { ...noAnimation, } },
    MuiStepContent: { root: { ...noAnimation, } },
    MuiStepIcon: { root: { ...noAnimation, } },
    MuiStepLabel: { root: { ...noAnimation, } },
    MuiStepper: { root: { ...noAnimation, } },
    MuiSvgIcon: { root: { ...noAnimation, } },
    MuiSwitch: { root: { ...noAnimation, } },
    MuiTab: { root: { ...noAnimation, } },
    MuiTable: { root: { ...noAnimation, } },
    MuiTableBody: { root: { ...noAnimation, } },
    MuiTableCell: { root: { ...noAnimation, } },
    MuiTableContainer: { root: { ...noAnimation, } },
    MuiTableFooter: { root: { ...noAnimation, } },
    MuiTableHead: { root: { ...noAnimation, } },
    MuiTablePagination: { root: { ...noAnimation, } },
    MuiTableRow: { root: { ...noAnimation, } },
    MuiTableSortLabel: { root: { ...noAnimation, } },
    MuiTabs: { root: { ...noAnimation, } },
    MuiTextField: { root: { ...noAnimation, } },
    MuiToolbar: { root: { ...noAnimation, } },
    MuiTooltip: { tooltip: { ...noAnimation, } },
    MuiTouchRipple: { root: { ...noAnimation, } },
    MuiTypography: { root: { ...noAnimation, } },
}