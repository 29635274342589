import * as Yup from 'yup'
export const useFormRecuperarSenhaRetornoValidations = () => {
    const FormRecuperarSenhaRetornoYupValidations = Yup.object().shape({
        codigo: Yup.string().required("O campo Código é obrigatório").test("codigo", "O código deve possuir 6 caracteres ", (value) => {
            if (value) {
                const valueClean = value.replaceAll("-", "").replaceAll(' ', '');
                if (valueClean.length !== 6) {
                    return false
                } else {
                    return true
                }
            }
            return true
        }),
    });

    return {
        FormRecuperarSenhaRetornoYupValidations
    }
}

