import { useCallback } from 'react';
import { useApiBase } from 'data/api/base';
import { VariaveisAmbiente } from 'config';

export function useGetMedicamentoByCod() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getMedicamentoByCod = useCallback(
    (codigoAnvisa: string) => {

      return invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/Medicamento?CodigoAnvisa=${codigoAnvisa}`,
        method: 'GET',
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getMedicamentoByCod
  };
}
