import { useCallback, useRef, useState } from "react";
import { handleClickFeedbackTatil } from './feedback-tatil-fn';
import { useThemeQueries } from 'views';

const useOnPress = (
    onLongPress: (event: any) => void,
    onClick: (e: any) => void,
    defaultOption: { shouldPreventDefault: boolean, delay: number }
) => {
    const { isMobile } = useThemeQueries();
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const [scrollMove, setScrollMoved] = useState(false);
    const timeout = useRef<any>();
    const target = useRef<any>();

    const moved = useCallback(
        event => {
            setScrollMoved(true);
            timeout.current && clearTimeout(timeout.current);
        }
        , []);

    const start = useCallback(
        event => {
            setScrollMoved(false);

            if (defaultOption.shouldPreventDefault && event.target) {
                event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
                if(event.type !== 'mouseup' && event.type !== 'touchend' && isMobile) {
                    handleClickFeedbackTatil();
                } 
            }

            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, defaultOption.delay);
        },
        [defaultOption.shouldPreventDefault, defaultOption.delay, isMobile, onLongPress]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && !scrollMove && onClick(event);
            setLongPressTriggered(false);
            if (defaultOption.shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [longPressTriggered, scrollMove, onClick, defaultOption.shouldPreventDefault]
    );

    return {
        onTouchMove: (e: any) => moved(e),
        onMouseDown: (e: any) => start(e),
        onTouchStart: (e: any) => start(e),
        onMouseUp: (e: any) => clear(e),
        onMouseLeave: (e: any) => clear(e, false),
        onTouchEnd: (e: any) => clear(e)
    };
};

const isTouchEvent = (e: any) => {
    return "touches" in e;
};

const preventDefault = (e: any) => {
    if (!isTouchEvent(e)) return;

    if (e.touches.length < 2 && e.preventDefault) {
        e.preventDefault();
    }
};

export default useOnPress;
