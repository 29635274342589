import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ModalPropagandaPlano } from "views/components/modals/modal-propaganda-plano/modal-propaganda-plano";

export const CadastroModalPropagandaPlano = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
    });

    consoleDev('CadastroModalPropagandaPlano')

    const modalAlterado = useCallback(({ aberto }: any) => {
        setModalState({
            aberto: aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ModalPropagandaPlano, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ModalPropagandaPlano, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <ModalPropagandaPlano aberto={modalState.aberto} />
            )}
        </>
    )
}