import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetEnderecoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getEnderecoPessoa = useCallback(
    (id: string) =>
      invocarApi({
        url:`${VariaveisAmbiente.gatewayVersion}/pessoa/${id}/endereco`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
 
  return {
    ...outrasPropriedades,
    getEnderecoPessoa,
  }

}
