import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { MenuIcon } from 'views/components/icons';
import { useThemeQueries } from 'views';
import { isPlanoComControleDeAcesso } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';

export interface PerfisHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: any) => any;
}

export const PerfisHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();
  const { plano } = useSessaoAtual();

  const planoComControlleDeAcesso = isPlanoComControleDeAcesso(plano?.plano);

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const pesquisa = () => null;
  return (
    <>
      <PrivatePageHeader
        title={planoComControlleDeAcesso ? 'Perfis de acesso' : 'Usuários'}
        leftArea={leftArea()}
        bottomArea={pesquisa}
      />
    </>
  );
};
