import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupNome } from '../../../form-validations'


export const useFormPoliticaAceiteValidation = () => {

    const { nomeYup } = useValidationYupNome()
    const FormPoliticaAceiteValidationYup = useMemo(() => {
        return Yup.object().shape({
            nome: nomeYup(),
            rg: Yup.string().required('O RG é obrigatório.').min(8, 'O RG informado está inválido.')
        });
    }, [nomeYup])

    return {
        FormPoliticaAceiteValidationYup
    }
}

