import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { SessaoValorList } from '../components/sassao-valor-list/sessao-valor-list';
import { SessaNovoValor } from '../components/sessao-novo-valor/sessao-novo-valor';
import { useStyles } from '../components/styles/sessao-valor-page-styles';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { SessaoValorSearchProps } from '../components/sassao-valor-list/sessao-valor-search-props';
import { SessaoValorHeader } from '../components/sessao-valor-header/sessao-valor-header';

const SessaoReforcoPage = () => {
  // STATES E REFS
  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<SessaoValorSearchProps>({
    termo: '',
  });

  // AUX
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.header}>
          <SessaoValorHeader
            openPesquisa={openPesquisa}
            setSearchProps={setSearchProps}
            setOpenPesquisa={setOpenPesquisa}
            title="Reforço"
          />
        </Grid>

        <Grid className={classes.list}>
          <SessaoValorList
            searchProps={searchProps}
            tpEvent={AppEventEnum.SessaoReforco}
            tpLancamento={EnumTpLancamentoSessaoValor.SUPRIMENTO}
          />
        </Grid>

        <SessaNovoValor
          tpEvent={AppEventEnum.SessaoReforco}
          title="Novo Reforço"
          tpLancamento={EnumTpLancamentoSessaoValor.SUPRIMENTO}
        />
      </Grid>
    </>
  );
};

export default SessaoReforcoPage;
