
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupNome } from '../../../form-validations/yup/nome';
import { useValidationYupEmail } from '../../../form-validations/yup/email';

export const useFormDadosContaValidation = () => {

  const { nomeYup } = useValidationYupNome()
  const { emailYup } = useValidationYupEmail()

  const FormDadosContaValidationYup = useMemo(() => {
    return Yup.object().shape({
      nome: nomeYup(),
      email: emailYup(),
      apelido:
        Yup
          .string()
          .trim()
          .required('Seu apelido é obrigatório.')
          .min(3, 'No mínimo 3 caracteres.')
          .max(26, 'No máximo 26 caracteres.')
          .test({
            message: 'Não pode conter espaços',
            test: function (apelido) {
              const regexEspaco = /^\S+$/;

              return regexEspaco.test(apelido ?? '')
            }
          }),
    });
  }, [nomeYup, emailYup])

  return {
    FormDadosContaValidationYup,
  }
}

