import { EnumBalanca } from "model/enums/enum-balanca";
import { guidEmpty } from "utils/guid-empty";
import { ProdutoNovoSubItemModel } from "../../produto/produto-subitem/produto-subitem-model";
import { EnumIndDesperdicio } from "model/enums/enum-ind-desperdicio";
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { MovSimplesModificadoresProdutoModel } from './mov-simples-modificadores-produto-model';
import { EnumTpCalculoModificador } from 'model/enums/enum-tpcalculo-modificador';
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";

export interface RastroVenda {
        nLote: string | null;
        qLote: number | null,
        dFab: Date | string | null,
        dVal: Date | string | null,
        cAgreg: number | null
}

export interface Med {
        cProdANVISA: string | null,
        xMotivoIsencao: string | null,
        vPMC: number | null,
        nSeqReceitaMedica: number | null,
        vendaControlada: boolean
}

export class MovSimplesProdutoModel {
        constructor(
                public nSeq: number = 0,
                public produtoGradeId: string | null = null,
                public tabelaPrecoId: string | null = null,
                public vendedorId: string | null = null,
                public vendedorNome: string = '',
                public cProd: string = '',
                public cProdKit: string = '',
                public cEan: string = '',
                public xProd: string = '',
                public ativo: boolean = true,
                public uCom: string = '',
                public qCom: number = 0,
                public vUnCom: number = 0,
                public vProd: number = 0,
                public vFrete: number = 0,
                public vSeg: number = 0,
                public vDescUsuario: number = 0,
                public vAcrescUsuario: number = 0,
                public tpAcresDesc: EnumTpDescontoAcrescimo = EnumTpDescontoAcrescimo.Porcentagem,
                public vAcresDesc: number = 0,
                public vFinal: number = 0,
                public imgUrl: string = '',
                public categoria: string = '',
                public infAdic: string = '',
                public ncm: string = '',
                public indAcrescDesc: boolean = false,
                public temImposto: boolean = false,
                public balanca: EnumBalanca = EnumBalanca.Normal,
                public grupoImpostoId: string | null = null,
                public ncmId: string | null = null,
                public vTrib: number = 0,
                public pTribManual: number = 0,
                public pTribFederal: number = 0,
                public pTribEstadual: number = 0,
                public pTribMunicipal: number = 0,
                public produtoId: string | null = null,
                public id: string = guidEmpty(),
                public pedidoId: string = '',
                public comandaId: string = '',
                public mesaId: string = '',
                public setorId: string | null = null,
                public salaoId: string | null = null,
                public taxaServico: number | null = null,
                public valorServico: number | null = null,
                public cobraTaxaServico: boolean = true,
                public subItens: ProdutoNovoSubItemModel[] = [],
                public prodSubItem: MovSimplesProdutoModel[] = [],
                public infoSubItem: ProdutoNovoSubItemModel & { modificadorTipoCalculo: EnumTpCalculoModificador } | null = null,
                public idDoProdutoPaiInfoSubItem: string | null = null,
                public indFin: boolean = true,
                public tpProduto: EnumTpProduto = EnumTpProduto.Produto,
                public produtoPaiId: string | null = null,
                public validacaoSubItem: boolean = false,
                public idAdicional: string | null = null,
                public idGroup: string | null = null,
                public adicionais: MovSimplesProdutoModel[] = [],
                public indDesperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica,
                public nivel: number = 0,
                public produtoIdReferencia: string | null = null,
                public quantidadeMax: number = 0,
                public mesaCodigo: string | null = null,
                public codigoComanda: string | null = null,
                public modificadorId: string | null = null,
                public modificadores: MovSimplesModificadoresProdutoModel[] = [],
                public modificadorUnicoId: string = '',
                public vUnComOrig: number = 0,
                public codigoPedido: string = '',
                // prop para lidar com lidar com a alteração do vUnCom para modificadores (MAIOR)
                public qComModificador: number = 0,
                public ordem: number = 0,

                // Informações de medicamento
                public cProdANVISA: string | null = null,
                public nSeqReceitaMedica: number | null = null,
                public ignorarReceituario: boolean = false,
                public rastro: RastroVenda[] | null = null,
                public med: Med | null = null
        ) { }
}
