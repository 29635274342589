import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Button, Grid, GridSize } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ProdutoFiscalEditFormModel } from '../../../../../model/app/forms/produto-fiscal/produto-fiscal-edit-form-model';
import { useFormProdutoFiscalEditValidation } from './form-produto-fiscal-edit-validation';
import { CstIcmsMock } from '../../../../../data/mocks/cst-icms-mock';
import { CstPisCofinsMock } from '../../../../../data/mocks/cst-pis-mock';
import { AutocompleteCFOPs } from '../../../controles/autocompletes/autocomplete-cfops/autocomplete-cfops';
import { KeyValueModel } from '../../../../../model/api/utils/key-value';
import { picker } from '../../../../../utils/picker';
import { ModBcIcmsMock } from '../../../../../data/mocks/modbc-icms-mock';
import { ModBcIcmsStMock } from '../../../../../data/mocks/modbc-icmsst-mock';
import { CfopMock } from '../../../../../data/mocks/cst-cfop-mock';
import { MotDesIcmsMock } from '../../../../../data/mocks/motdes-icms-mock';
import { AccordionSaurus } from '../../../accordions/accordion-saurus/accordion-saurus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toDecimal } from 'utils/to-decimal';

export const FormProdutoFiscalEdit = forwardRef<
  DefaultFormRefs<ProdutoFiscalEditFormModel>,
  DefaultFormProps<ProdutoFiscalEditFormModel>
>((props: DefaultFormProps<ProdutoFiscalEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refDescricaoGrupo = useRef<HTMLInputElement>(null);
  const { FormProdutoFiscalEditValidationYup } =
    useFormProdutoFiscalEditValidation();
  const [initialValues, setInitialValues] =
    useState<ProdutoFiscalEditFormModel>(new ProdutoFiscalEditFormModel());
  const [expandedAccordionIcms, setExpandedAccordionIcms] = useState(false);
  const [expandedAccordionPis, setExpandedAccordionPis] = useState(false);
  const [expandedAccordionCofins, setExpandedAccordionCofins] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<ProdutoFiscalEditFormModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormProdutoFiscalEditValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ProdutoFiscalEditFormModel) => {

    values.icmsPIcms = toDecimal(values.icmsPIcms)
    values.icmsPredBcSt = toDecimal(values.icmsPredBcSt)
    values.icmsPMvaVUnidSt = toDecimal(values.icmsPMvaVUnidSt)
    values.icmsPRedBc = toDecimal(values.icmsPRedBc)
    values.icmsPIcmsSt = toDecimal(values.icmsPIcmsSt)
    values.icmsPCredSn = toDecimal(values.icmsPCredSn)
    values.icmsPredSn = toDecimal(values.icmsPredSn)
    values.icmsPFcp = toDecimal(values.icmsPFcp)
    values.icmsPFcpSt = toDecimal(values.icmsPFcpSt)

    const model = picker<ProdutoFiscalEditFormModel>(
      values,
      new ProdutoFiscalEditFormModel(),
    );
    props.onSubmit(model);
  };

  const exibirIcms = [0, 10, 20, 40, 41, 50, 51, 70, 90, 900].includes(
    getValues('icmsCst'),
  );
  const exibirReducao = [20, 70, 90].includes(getValues('icmsCst'));
  const exibirST = [10, 30, 70, 90, 201, 202, 203, 900].includes(getValues('icmsCst'));
  const exibirReducaoST = [10, 30, 51, 70, 90, 201, 202, 203, 900].includes(getValues('icmsCst'));
  const exibirDesoneracao = [10, 20, 30, 40, 41, 50, 51, 60, 70, 90, 101, 102, 103, 201, 202, 203, 300, 400, 500, 900].includes(getValues('icmsCst'));
  const blockDesoneracao = [10, 30, 60, 70, 90, 101, 102, 103, 201, 202, 203, 300, 400, 500, 900].includes(getValues('icmsCst'));
  const exibirCreditoSNs = [101, 201].includes(getValues('icmsCst'));
  const exibirRedSN = [201, 202, 203].includes(getValues('icmsCst'));
  const exibirEcfAliq = [900].includes(
    getValues('icmsCst'),
  );
  const { isMobile } = useThemeQueries();
  const exibirFCP = [0, 10, 20, 30, 40, 41, 50, 51, 60, 70, 90, 101, 102, 103, 201, 202, 203, 300, 400, 500, 900].includes(getValues('icmsCst'));
  const blockFCP = [30, 40, 41, 50, 60, 101, 102, 103, 201, 202, 203, 300, 400, 500, 900].includes(getValues('icmsCst'));
  const exibirFCPST = [0, 10, 20, 30, 40, 41, 50, 51, 60, 70, 90, 101, 102, 103, 201, 202, 203, 300, 400, 500, 900].includes(getValues('icmsCst'));
  const blockFCPST = [0, 20, 40, 41, 50, 51, 60, 101, 102, 103, 300, 400, 500, 900].includes(getValues('icmsCst'));

  const [mudarEst, setMudarEst] = useState<number>(getValues('icmsCst'))

  const [, setAtt] = useState<boolean>(false)

  const att = () => setAtt(prev => !prev)

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ProdutoFiscalEditFormModel());
      reset();
      if (!isMobile) refDescricaoGrupo.current?.focus();
    },
    fillForm: (model: ProdutoFiscalEditFormModel) => {
      const cfop = CfopMock.find((x) => x.Key === model.cfop);
      model.cfopDesc = cfop?.Value || '';
      if (model.icmsModBc === 0) {
        model.icmsModBc = 3
      }
      setInitialValues(model);
      reset({ ...model });
      if (!isMobile) refDescricaoGrupo.current?.focus();
    },
  }));

  const validarFCP = useCallback(() => {
    if ([0, 10, 20, 70, 90].includes(mudarEst))
      setValue('icmsPFcp', 0)

    if (blockFCP) {
      setValue('icmsPFcp', 0)
    }
  }, [blockFCP, mudarEst, setValue])

  const validarFCPST = useCallback(() => {
    if ([10, 30, 70, 90, 201, 202, 203].includes(mudarEst)) {
      setValue('icmsPFcpSt', 0)
    }

    if (blockFCPST) {
      setValue('icmsPFcpSt', 0)
    }
  }, [blockFCPST, mudarEst, setValue])

  useEffect(() => {
    validarFCP();
    validarFCPST();
  }, [validarFCP, validarFCPST])

  const mudarValor = (val: number) => setMudarEst(val)

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refDescricaoGrupo}
                      fullWidth
                      autoComplete='new-password'
                      disabled={props.loading}
                      label="Nome do Grupo"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cfop"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteCFOPs
                      allowSubmit={false}
                      placeholder="Ex: 5102"
                      label="CFOP"
                      error={Boolean(errors.cfop && errors.cfop.message)}
                      helperText={
                        touchedFields.cfop || errors.cfop
                          ? errors.cfop?.message
                          : undefined
                      }
                      {...field}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          let cfop = picker<KeyValueModel>(
                            retorno.value,
                            new KeyValueModel(),
                          );
                          setValue('cfop', cfop.Key.toString());
                          setValue('cfopDesc', cfop.Value.toString());
                        }
                      }}
                      value={getValues('cfopDesc')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <AccordionSaurus
                  expanded={expandedAccordionIcms}
                  onChange={() =>
                    setExpandedAccordionIcms(!expandedAccordionIcms)
                  }
                  labelPrimary={'ICMS'}
                  labelSecondary={
                    'Alíquotas, Substituição Tributária e Desoneração'
                  }
                  showDivider
                  noPaperRoot
                  noPaddingContent
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="icmsCst"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            fullWidth
                            variant="outlined"
                            label="CST/CSOSN Icms"
                            disabled={props.loading}
                            conteudo={CstIcmsMock}
                            {...field}
                            onChange={(event) => {
                              if (event) {
                                setValue(
                                  'icmsCst',
                                  CstIcmsMock.filter(
                                    (item) => item.Key === event.target.value,
                                  )[0]?.Key,
                                );
                                mudarValor(CstIcmsMock.filter(
                                  (item) => item.Key === event.target.value,
                                )[0]?.Key)
                              }
                              att()
                            }}
                            value={getValues('icmsCst')}
                          />
                        )}
                      />
                    </Grid>
                    {exibirIcms && (
                      <>
                        <Grid item xs={12} sm={exibirReducao ? 5 : 8}>
                          <Controller
                            name="icmsModBc"
                            control={control}
                            render={({ field }) => (
                              <SelectSaurus
                                fullWidth
                                variant="outlined"
                                label="Modalidade de Cálculo"
                                disabled={props.loading}
                                conteudo={ModBcIcmsMock}
                                {...field}
                                onChange={(event) => {
                                  if (event) {
                                    setValue(
                                      'icmsModBc',
                                      ModBcIcmsMock.filter(
                                        (item) =>
                                          item.Key === event.target.value,
                                      )[0]?.Key,
                                    );
                                  }
                                }}
                                value={getValues('icmsModBc')}
                              />
                            )}
                          />
                        </Grid>
                        {exibirReducao && (
                          <Grid item xs={12} sm={3}>
                            <Controller
                              name="icmsPRedBc"
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  autoComplete='new-password'
                                  fullWidth
                                  manterMascara
                                  tipo={'DECIMAL'}
                                  casasDecimais={2}
                                  disabled={props.loading}
                                  label="% Redução B.C"
                                  variant="outlined"
                                  maxTexto={5}
                                  error={Boolean(
                                    errors.icmsPRedBc &&
                                    errors.icmsPRedBc.message,
                                  )}
                                  helperText={
                                    touchedFields.icmsPRedBc ||
                                      errors.icmsPRedBc
                                      ? errors.icmsPRedBc?.message
                                      : undefined
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="icmsPIcms"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                autoComplete='new-password'
                                fullWidth
                                manterMascara
                                disabled={props.loading}
                                label="% ICMS"
                                tipo={'DECIMAL'}
                                casasDecimais={2}
                                variant="outlined"
                                maxTexto={5}
                                error={Boolean(
                                  errors.icmsPIcms && errors.icmsPIcms.message,
                                )}
                                helperText={
                                  touchedFields.icmsPIcms || errors.icmsPIcms
                                    ? errors.icmsPIcms?.message
                                    : undefined
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {/* {false && (
                      <>
                        <Grid item xs={12} sm={4}>
                        <Controller
                            name="icmsPIcms"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                fullWidth
                                manterMascara
                                disabled={props.loading}
                                label="% ICMS SAT"
                                tipo={'DECIMAL'}
                                casasDecimais={3}
                                variant="outlined"
                                error={Boolean(
                                  errors.icmsPIcms && errors.icmsPIcms.message,
                                )}
                                helperText={
                                  touchedFields.icmsPIcms || errors.icmsPIcms
                                    ? errors.icmsPIcms?.message
                                    : undefined
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                       </>
                    )} */}
                    {exibirST && (
                      <>
                        <Grid item xs={12} sm={5}>
                          <Controller
                            name="icmsModBcSt"
                            control={control}
                            render={({ field }) => (
                              <SelectSaurus
                                fullWidth
                                variant="outlined"
                                label="Modalidade BC - ST"
                                disabled={props.loading}
                                conteudo={ModBcIcmsStMock}
                                {...field}
                                onChange={(event) => {
                                  if (event) {
                                    setValue(
                                      'icmsModBcSt',
                                      ModBcIcmsStMock.filter(
                                        (item) =>
                                          item.Key === event.target.value,
                                      )[0]?.Key,
                                    );
                                  }
                                }}
                                value={getValues('icmsModBcSt')}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={exibirReducaoST ? 2 : 3}>
                          <Controller
                            name="icmsPMvaVUnidSt"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                autoComplete='new-password'
                                fullWidth
                                disabled={props.loading}
                                manterMascara
                                label="MVA/IVA"
                                tipo={'DECIMAL'}
                                casasDecimais={2}
                                variant="outlined"
                                maxTexto={5}
                                error={Boolean(
                                  errors.icmsPMvaVUnidSt &&
                                  errors.icmsPMvaVUnidSt.message,
                                )}
                                helperText={
                                  touchedFields.icmsPMvaVUnidSt ||
                                    errors.icmsPMvaVUnidSt
                                    ? errors.icmsPMvaVUnidSt?.message
                                    : undefined
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        {exibirReducaoST && (
                          <Grid item xs={12} sm={2}>
                            <Controller
                              name="icmsPredBcSt"
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  autoComplete='new-password'
                                  fullWidth
                                  disabled={props.loading}
                                  manterMascara
                                  label="% Redução ST"
                                  tipo={'DECIMAL'}
                                  casasDecimais={2}
                                  variant="outlined"
                                  maxTexto={5}
                                  error={Boolean(
                                    errors.icmsPredBcSt &&
                                    errors.icmsPredBcSt.message,
                                  )}
                                  helperText={
                                    touchedFields.icmsPredBcSt ||
                                      errors.icmsPredBcSt
                                      ? errors.icmsPredBcSt?.message
                                      : undefined
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={exibirReducaoST ? 3 : 4}>
                          <Controller
                            name="icmsPIcmsSt"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                autoComplete='new-password'
                                fullWidth
                                disabled={props.loading}
                                manterMascara
                                label="% ICMS ST"
                                tipo={'DECIMAL'}
                                casasDecimais={2}
                                variant="outlined"
                                maxTexto={5}
                                error={Boolean(
                                  errors.icmsPIcmsSt &&
                                  errors.icmsPIcmsSt.message,
                                )}
                                helperText={
                                  touchedFields.icmsPIcmsSt ||
                                    errors.icmsPIcmsSt
                                    ? errors.icmsPIcmsSt?.message
                                    : undefined
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {exibirDesoneracao && (
                      <>
                        <Grid item xs={12} sm={8}>
                          <Controller
                            name="icmsMotDesIcms"
                            control={control}
                            render={({ field }) => (
                              <SelectSaurus
                                fullWidth
                                variant="outlined"
                                label="Motivo da Desoneração"
                                disabled={blockDesoneracao}
                                conteudo={MotDesIcmsMock}
                                {...field}
                                onChange={(event) => {
                                  if (event) {
                                    setValue(
                                      'icmsMotDesIcms',
                                      MotDesIcmsMock.filter(
                                        (item) =>
                                          item.Key === event.target.value,
                                      )[0]?.Key,
                                    );
                                  }
                                }}
                                value={getValues('icmsMotDesIcms')}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="cBenef"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                autoComplete='new-password'
                                fullWidth
                                manterMascara
                                disabled={props.loading}
                                label="Cód Benefício Fiscal (cBenef)"
                                variant="outlined"
                                error={Boolean(
                                  errors.cBenef && errors.cBenef.message,
                                )}
                                helperText={
                                  touchedFields.cBenef || errors.cBenef
                                    ? errors.cBenef?.message
                                    : undefined
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {exibirCreditoSNs && (
                      <Grid item xs={12} sm={exibirRedSN ? 6 : 12}>
                        <Controller
                          name="icmsPCredSn"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              autoComplete='new-password'
                              fullWidth
                              manterMascara
                              disabled={props.loading}
                              label="% Crédito SN"
                              tipo={'DECIMAL'}
                              casasDecimais={2}
                              maxTexto={5}
                              variant="outlined"
                              error={Boolean(
                                errors.icmsPCredSn &&
                                errors.icmsPCredSn.message,
                              )}
                              helperText={
                                touchedFields.icmsPCredSn || errors.icmsPCredSn
                                  ? errors.icmsPCredSn?.message
                                  : undefined
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {exibirRedSN && (
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="icmsPredSn"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              autoComplete='new-password'
                              fullWidth
                              manterMascara
                              disabled={props.loading}
                              label="% ICMS Estadual"
                              tipo={'DECIMAL'}
                              casasDecimais={2}
                              maxTexto={5}
                              variant="outlined"
                              error={Boolean(
                                errors.icmsPredSn && errors.icmsPredSn.message,
                              )}
                              helperText={
                                touchedFields.icmsPredSn || errors.icmsPredSn
                                  ? errors.icmsPredSn?.message
                                  : undefined
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {exibirFCP && (
                      <Grid
                        item
                        xs={12}
                        sm={
                          (12 /
                            (1 +
                              (exibirFCPST ? 1 : 0) +
                              (exibirEcfAliq ? 1 : 0))) as GridSize
                        }
                      >
                        <Controller
                          name="icmsPFcp"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              autoComplete='new-password'
                              fullWidth
                              manterMascara
                              disabled={props.loading}
                              label="% F.C.P"
                              readOnly={blockFCP}
                              tipo={'DECIMAL'}
                              casasDecimais={2}
                              maxTexto={5}
                              variant="outlined"
                              error={Boolean(
                                errors.icmsPFcp && errors.icmsPFcp.message,
                              )}
                              helperText={
                                touchedFields.icmsPFcp || errors.icmsPFcp
                                  ? errors.icmsPFcp?.message
                                  : undefined
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {exibirFCPST && (
                      <Grid
                        item
                        xs={12}
                        sm={
                          (12 /
                            (1 +
                              (exibirFCP ? 1 : 0) +
                              (exibirEcfAliq ? 1 : 0))) as GridSize
                        }
                      >
                        <Controller
                          name="icmsPFcpSt"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              autoComplete='new-password'
                              fullWidth
                              manterMascara
                              disabled={props.loading}
                              label="% F.C.P ST"
                              tipo={'DECIMAL'}
                              readOnly={blockFCPST}
                              casasDecimais={2}
                              maxTexto={5}
                              variant="outlined"
                              error={Boolean(
                                errors.icmsPFcpSt && errors.icmsPFcpSt.message,
                              )}
                              helperText={
                                touchedFields.icmsPFcpSt || errors.icmsPFcpSt
                                  ? errors.icmsPFcpSt?.message
                                  : undefined
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {exibirEcfAliq && (
                      <Grid
                        item
                        xs={12}
                        sm={
                          (12 /
                            (1 +
                              (exibirFCP ? 1 : 0) +
                              (exibirFCPST ? 1 : 0))) as GridSize
                        }
                      >
                        <Controller
                          name="ecfAliq"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              autoComplete='new-password'
                              fullWidth
                              manterMascara
                              disabled={props.loading}
                              label="Alíquota do ICMS para ECF"
                              tipo={'DECIMAL'}
                              casasDecimais={3}
                              variant="outlined"
                              error={Boolean(
                                errors.ecfAliq && errors.ecfAliq.message,
                              )}
                              helperText={
                                touchedFields.ecfAliq || errors.ecfAliq
                                  ? errors.ecfAliq?.message
                                  : undefined
                              }
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionSaurus>
              </Grid>
              <Grid item xs={12}>
                <AccordionSaurus
                  expanded={expandedAccordionPis}
                  onChange={() =>
                    setExpandedAccordionPis(!expandedAccordionPis)
                  }
                  labelPrimary={'PIS'}
                  labelSecondary={'CST e Alíquota'}
                  showDivider
                  noPaperRoot
                  noPaddingContent
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="pisCst"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            fullWidth
                            variant="outlined"
                            label="CST Pis"
                            disabled={props.loading}
                            conteudo={CstPisCofinsMock}
                            {...field}
                            onChange={(event) => {
                              if (event) {
                                setValue(
                                  'pisCst',
                                  CstPisCofinsMock.filter(
                                    (item) => item.Key === event.target.value,
                                  )[0]?.Key,
                                );
                              }
                            }}
                            value={getValues('pisCst')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="pisPercVUnidPis"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            autoComplete='new-password'
                            fullWidth
                            disabled={props.loading}
                            label="Alíquota PIS"
                            manterMascara
                            tipo={'DECIMAL'}
                            casasDecimais={3}
                            variant="outlined"
                            error={Boolean(
                              errors.pisPercVUnidPis &&
                              errors.pisPercVUnidPis.message,
                            )}
                            helperText={
                              touchedFields.pisPercVUnidPis ||
                                errors.pisPercVUnidPis
                                ? errors.pisPercVUnidPis?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionSaurus>
              </Grid>
              <Grid item xs={12}>
                <AccordionSaurus
                  expanded={expandedAccordionCofins}
                  onChange={() =>
                    setExpandedAccordionCofins(!expandedAccordionCofins)
                  }
                  labelPrimary={'COFINS'}
                  labelSecondary={'CST e Alíquota'}
                  showDivider
                  noPaperRoot
                  noPaddingContent
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="cofinsCst"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            fullWidth
                            variant="outlined"
                            label="CST Cofins"
                            disabled={props.loading}
                            conteudo={CstPisCofinsMock}
                            {...field}
                            onChange={(event) => {
                              if (event) {
                                const item = CstPisCofinsMock.find(
                                  (item) => item.Key === event.target.value,
                                );
                                setValue('cofinsCst', item?.Key);
                              }
                            }}
                            value={getValues('cofinsCst')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="cofinsPercVUnidCofins"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            autoComplete='new-password'
                            fullWidth
                            disabled={props.loading}
                            manterMascara
                            label="Alíquota Cofins"
                            tipo={'DECIMAL'}
                            casasDecimais={3}
                            variant="outlined"
                            error={Boolean(
                              errors.cofinsPercVUnidCofins &&
                              errors.cofinsPercVUnidCofins.message,
                            )}
                            helperText={
                              touchedFields.cofinsPercVUnidCofins ||
                                errors.cofinsPercVUnidCofins
                                ? errors.cofinsPercVUnidCofins?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionSaurus>
              </Grid>
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
