import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
        "& .card": {
            margin: theme.spacing(1),
          }
    },
    containerButton: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 2px 8px 2px rgba(0, 0, 0, 0.15)',
    },
    button: {
        height: '50px'
    },
    containerResumo: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: '8px',
    },
    resumo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
        borderRadius: '7px',
        padding: '8px'
    },
    containerValorEmCaixa: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    textValorEmCaixa: {
        fontSize: '18px',
        fontWeight: 600
    },
    card: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divider: {
        backgroundColor: theme.palette.grey[300],
        height: '2px',
        marginTop: '5px',
        marginBottom: '5px',
        marginleft: '8px',
        marginRight: '8px',
    },
    alinharItensCentro: {
        display: 'flex',
        alignItems: 'center',
    },
    descricao: {
        fontWeight: 600
    },
    buttonCalc: {
        background: '#FFFFFF',
        boxShadow: '0px 1.8px 7.2px rgba(0, 0, 0, 0.08)',
        borderRadius: '5px',
    }
}));
