import { Box, Button } from "@material-ui/core"
import { CalendarioIcon, FiltroIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { useStyles } from '../historico-venda-filtro/historico-venda-filtro-modal-styles'
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { FormPersonalizado } from "views/components/form/form-personalizado/form-personalizado";
import { toDateString } from "utils/to-date";
import { isEmpty, isEqual } from "lodash";
import { KeyValueModel } from "model";
import { UltimaVendaIcon } from "views/components/icons/ultima-venda-icon";
import { ManifestosSearchProps } from "views/pages/private/manifestos/components/manifestos-list/manifestos-search-props";
import { ManifestoStatusMockTodos } from "data/mocks/acao-manifesto-mock";
import { descMonth, getCurrentMonth } from "utils/get-date";

const IntegradoMock = [
    new KeyValueModel('true', 'Importada'),
    new KeyValueModel('false', 'Não Importada'),
    new KeyValueModel('-1', 'Indiferente')
]

interface DatasForm {
    dInicial: string;
    dFinal: string;
}
interface ITelaState {
    status: boolean;
    datas: boolean;
    integrado: boolean;

}

interface ManifestoFiltroModalProps {
    openned: boolean;
    filtros: ManifestosSearchProps;
}
export const ManifestoFiltroModal = ({
    openned,
    filtros
}: ManifestoFiltroModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharManifestosFiltrosModal } = useFiltrosModais();
    const history = useHistory();

    const [telas, setTelas] = useState<ITelaState>({
        status: false,
        datas: false,
        integrado: false,
    })
    const [localProps, setLocalProps] = useState<ManifestosSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (Object.values(telas).some(x => x)) {
            setTelas(() => ({
                status: false,
                datas: false,
                integrado: false,
            }))
            return
        }
        fecharManifestosFiltrosModal(false)
    }, [fecharManifestosFiltrosModal, telas])

    const onSubmit = useCallback((localProps: ManifestosSearchProps) => {
        const searchs: Array<string | null> = [
            `dInicial=${localProps.dInicial}`,
            localProps.dFinal.length > 0 ? `dFinal=${localProps.dFinal}` : null,
            `integrado=${localProps.integrado}`,
        ]

        localProps.statusManifesto.forEach((tp) => {
            searchs.push(`status=${tp}`)
        })

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
            pathname: '/manifestos',
            search: query
        })
        fecharManifestosFiltrosModal(true)
    }, [fecharManifestosFiltrosModal, history])

    const retornarStringData = () => {
        if (isEmpty(localProps.dInicial) && !isEmpty(localProps.dFinal)) {
            return toDateString(localProps.dFinal, 'DD/MM')
        }
        if (!isEmpty(localProps.dInicial) && isEmpty(localProps.dFinal)) {
            return toDateString(localProps.dInicial, 'DD/MM')
        }
        if (!isEmpty(localProps.dInicial) && !isEmpty(localProps.dFinal)) {
            return `${toDateString(localProps.dInicial, 'DD/MM')} - ${toDateString(localProps.dFinal, 'DD/MM')}`
        }
        return ''
    }

    const labels = localProps.statusManifesto.map(t => ManifestoStatusMockTodos.find(item => item.Key === t)?.Value || '').join(', ');

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    {/*______________CARDS______________*/}
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<ManifestosSearchProps, ITelaState>
                            icon={<CalendarioIcon />}
                            nome="Data"
                            propName={['dInicial', 'dFinal']}
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="datas"
                            value={retornarStringData()}
                            valorPadrao={
                                [
                                    toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
                                    toDateString(new Date(), 'yyyy-MM-DD')!
                                ]
                            }
                            valorAtual={[localProps.dInicial, localProps.dFinal]}
                        />
                        <CardFiltro<ManifestosSearchProps, ITelaState>
                            icon={<FiltroIcon />}
                            nome="Situação do Manifesto"
                            propName="statusManifesto"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            value={labels}
                            valorPadrao={[-1]}
                            valorAtual={localProps.statusManifesto}
                        />
                        <CardFiltro<ManifestosSearchProps, ITelaState>
                            icon={<UltimaVendaIcon />}
                            nome="Nota Fiscal Importada"
                            propName="integrado"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="integrado"
                            value={IntegradoMock.find(x => x.Key === localProps.integrado)?.Value || ''}
                            valorPadrao={'-1'}
                            valorAtual={localProps.integrado}
                        />

                        {/*______________BOTÕES______________*/}
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => onSubmit({
                                dFinal: toDateString(new Date(), 'yyyy-MM-DD')!,
                                dInicial: toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD') || '',
                                statusManifesto: [-1],
                                integrado: '-1',
                            })}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>

                        {/*______________TELAS______________*/}
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (tp) => { }}
                                handleSubmitMultiCheck={(select: any) => {
                                    if (select) {
                                        const arrOriginal = ManifestoStatusMockTodos.filter(x => x.Key !== -1).map(x => x.Key);
                                        if (
                                            select.includes(-1) ||
                                            isEqual(arrOriginal, select)
                                        ) {
                                            select = [-1];
                                        }
                                        setLocalProps(prev => ({
                                            ...prev,
                                            statusManifesto: select
                                        }))
                                    }
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.statusManifesto}
                                mock={ManifestoStatusMockTodos}
                                multiCheck
                                minChecks={1}
                            />
                        )}
                        {telas.datas && (
                            <FormPersonalizado<DatasForm>
                                campos={[
                                    {
                                        propName: 'dInicial',
                                        grid: {
                                            md: 6,
                                            xs: 6
                                        },
                                        label: 'Data Inicial',
                                        tipoTextfield: 'DATA',
                                        tipo: 'TEXTFIELD',
                                        required: true
                                    },
                                    {
                                        propName: 'dFinal',
                                        grid: {
                                            md: 6,
                                            xs: 6
                                        },
                                        label: 'Data Final',
                                        tipoTextfield: 'DATA',
                                        tipo: 'TEXTFIELD',
                                    },
                                ]}
                                model={{
                                    dInicial: localProps.dInicial,
                                    dFinal: localProps.dFinal
                                }}
                                onSubmit={model => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        dInicial: model.dInicial,
                                        dFinal: model.dFinal
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        datas: false
                                    }))
                                }}
                            />
                        )}
                        {telas.integrado && (
                            <RadioList
                                handleSubmit={async (integrado) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        integrado
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        integrado: false
                                    }))
                                }}
                                selecionado={localProps.integrado}
                                mock={IntegradoMock}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal >
    )
}