import { useCallback, useEffect, useState, useRef } from 'react';
import {
  LixoIcon,
  NovoIcon,
  SalvarEditarIcon,
  VoltarIcon
} from 'views/components/icons';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, useSessaoAtual } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useGetFinalizadoraById } from 'data/api/gestao/finalizadora/get-finalizadora-by-id';
import { usePutFinalizadora } from 'data/api/gestao/finalizadora/put-finalizadora';
import { FinalizadoraEditFormModel } from 'model/app/forms/finalizadora/finalizadora-edit-form-model';

import { picker } from 'utils/picker';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { FormFinalizadoraEdit } from 'views/components/form/finalizadora/form-finalizadora-edit/form-finalizadora-edit';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';

import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { toDecimal } from 'utils/to-decimal';
import classNames from 'classnames';
import { stringNumeros } from 'utils/string-numeros';
import { consoleDev } from 'utils/console-dev';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { isEmpty, isEqualWith, toInteger } from 'lodash';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { guidEmpty } from 'utils/guid-empty';
import { useMenuOptionsStyles } from 'views/components/menu-options/menu-options-styles';
import { SwitchSaurus } from 'views/components/controles';
import { isPlanoPagIntegrado } from 'utils/plano-utils';
import { useDeleteFinalizadora } from 'data/api/gestao/finalizadora/delete-finalizadora';
import { useConfirm } from 'material-ui-confirm';

const tipoPagamentoIntegrado = [
  EnumPagTpMod.CARTAO_CREDITO,
  EnumPagTpMod.CARTAO_DEBITO,
  EnumPagTpMod.PAGAMENTO_INSTANTANEO,
  EnumPagTpMod.VALE_ALIMENTACAO,
  EnumPagTpMod.VALE_COMBUSTIVEL,
  EnumPagTpMod.VALE_PRESENTE,
  EnumPagTpMod.VALE_REFEICAO
];

export const FinalizadoraEdit = (props: {
  id: string;
  callbackUrl: string;
  atualizarDb?: boolean;
}) => {
  const reload = useRef<boolean>(false);
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const { plano } = useSessaoAtual();
  const confirm = useConfirm();

  const planoPagIntegrado = isPlanoPagIntegrado(plano?.plano);

  const classes = useModalStyles();
  const menuClasses = useMenuOptionsStyles({});

  const { fecharCadastroFinalizadora, abrirCadastroFinalizadora } =
    useCadastros();
  const { getFinalizadoraById, carregando: carregandoGet } =
    useGetFinalizadoraById();
  const { putFinalizadora, carregando: carregandoPut } = usePutFinalizadora();
  const { deleteFinalizadora, carregando: carregandoDelete } =
    useDeleteFinalizadora();

  const carregando =
    carregandoPut || carregandoGet || preenchendoTela || carregandoDelete;
  const [finalizadoraFormState, setFinalizadoraForm] =
    useState<FinalizadoraEditFormModel>(new FinalizadoraEditFormModel());

  const refEditForm = useRef<DefaultFormRefs<FinalizadoraEditFormModel>>(null);
  const refFinalizadoraModel = useRef<FinalizadoraModel>(
    new FinalizadoraModel()
  );

  const tpModIntegrado = tipoPagamentoIntegrado.includes(
    finalizadoraFormState.tpMod
  );

  consoleDev('finalizadoraFormState', finalizadoraFormState);

  const recarregarForm = useCallback((model: FinalizadoraEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(finalizadoraFormState);
  }, [finalizadoraFormState, recarregarForm]);

  const getFinalizadoraByIdWrapper = useCallback(async () => {
    const res = await getFinalizadoraById(props.id);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as FinalizadoraModel;
    refFinalizadoraModel.current = ret;
    const finalizadora = picker<FinalizadoraEditFormModel>(
      ret,
      new FinalizadoraEditFormModel()
    );
    if (!isEmpty(finalizadora.credenciais)) {
      finalizadora.credenciais = JSON.parse(ret.credenciais);
    }
    finalizadora.integrado = ret.tpTransacao === 4;

    return finalizadora;
  }, [props.id, getFinalizadoraById]);

  const deleteFinalizadoraWrapper = useCallback(async () => {
    try {
      const res = await deleteFinalizadora(props.id);

      if (res.erro) throw res.erro;

      showToast('success', 'Forma de Pagamento Excluída com sucesso.');
      fecharCadastroFinalizadora(props.callbackUrl);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    deleteFinalizadora,
    fecharCadastroFinalizadora,
    props.callbackUrl,
    props.id,
    showToast
  ]);

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true);
      const finalizadora = await getFinalizadoraByIdWrapper();
      setFinalizadoraForm(finalizadora);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setPreenchendoTela(false);
    }
  }, [getFinalizadoraByIdWrapper, showToast]);

  useEffect(() => {
    preencherTela();
    return () => {
      //funcao de limpeza
      setFinalizadoraForm(new FinalizadoraEditFormModel());
    };
  }, [preencherTela]);

  const saveChangesFinalizadora = useCallback(
    async (finalizadoraModelForm: FinalizadoraEditFormModel) => {
      if (!isEmpty(finalizadoraModelForm.credenciais)) {
        finalizadoraModelForm.credenciais.cnpj = stringNumeros(
          finalizadoraModelForm.credenciais.cnpj
        );
      }
      let finalizadora = picker<FinalizadoraModel>(
        finalizadoraModelForm,
        refFinalizadoraModel.current
      );

      try {
        if (!isEmpty(finalizadoraModelForm.credenciais))
          finalizadora.credenciais = JSON.stringify(
            finalizadoraModelForm.credenciais
          );
        finalizadora.cnpjCred = stringNumeros(finalizadoraModelForm.cnpjCred);
        finalizadora.pAcresc = toDecimal(finalizadoraModelForm.pAcresc, 2);
        finalizadora.qMaxParc = toInteger(finalizadoraModelForm.qMaxParc);
        finalizadora.vMinParc = toDecimal(finalizadoraModelForm.vMinParc, 2);
        finalizadora.pDesc = toDecimal(finalizadoraModelForm.pDesc, 2);
        finalizadora.tpTransacao =
          finalizadoraModelForm.tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO
            ? EnumPagTpTransacao.SAFRA_PIX
            : finalizadoraModelForm.integrado
            ? EnumPagTpTransacao.S2_PAY
            : 0;

        finalizadora.empresaId =
          finalizadoraModelForm.empresaId === guidEmpty() ||
          finalizadoraModelForm.empresaId === null
            ? null
            : finalizadoraModelForm.empresaId;

        const ret = await putFinalizadora(finalizadora);

        if (ret.erro) {
          throw ret.erro;
        }
        reload.current = true;
        if (props.atualizarDb) {
          const prod = await TouchoneDBPrimary.finalizadoras.get({
            id: finalizadora.id
          });
          if (prod) {
            await TouchoneDBPrimary.finalizadoras.update(prod?.idIndexed!, {
              ...finalizadora,
              idIndexed: prod?.idIndexed!
            });
          }
        }
        showToast(
          'success',
          'Registro de Forma de Pagamento atualizado com sucesso!',
          2000,
          'bottom-center',
          60
        );
        setFinalizadoraForm({ ...finalizadoraModelForm });
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [putFinalizadora, props.atualizarDb, showToast]
  );

  const handleSubmit = useCallback(
    async (
      model: FinalizadoraEditFormModel,
      beforeModel: FinalizadoraEditFormModel
    ) => {
      const finalizadoraEqual = isEqualWith(model, beforeModel);

      if (!finalizadoraEqual) {
        await saveChangesFinalizadora(model);
      }

      if (finalizadoraEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
      }
    },
    [saveChangesFinalizadora, showToast]
  );
  const onCloseClick = useCallback(() => {
    fecharCadastroFinalizadora(props.callbackUrl);
  }, [fecharCadastroFinalizadora, props.callbackUrl]);

  const changeStatus = useCallback(async () => {
    try {
      let model = await getFinalizadoraByIdWrapper();

      model.ativo = !model.ativo;

      saveChangesFinalizadora(model);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getFinalizadoraByIdWrapper, saveChangesFinalizadora, showToast]);

  const changeIntegrado = useCallback(async () => {
    try {
      await saveChangesFinalizadora({
        ...finalizadoraFormState,
        integrado: !finalizadoraFormState.integrado
      });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [finalizadoraFormState, saveChangesFinalizadora, showToast]);

  const confirmDelete = useCallback(() => {
    confirm({
      title: 'Tem certeza?',
      description:
        'Deseja excluir esta Forma de Pagamento? Esta ação não poderá ser desfeita futuramente.',
      cancellationText: 'Cancelar',
      confirmationText: 'Excluir'
    }).then(() => deleteFinalizadoraWrapper());
  }, [confirm, deleteFinalizadoraWrapper]);

  const menuOptions = useCallback((): any[] => {
    return [
      new MenuOptionsModel(
        (
          <Box className={menuClasses.menuItem}>
            <Typography>
              {finalizadoraFormState.ativo ? 'Desabilitar' : 'Habilitar'} Forma
              de Pagamento
            </Typography>
            <SwitchSaurus
              size="small"
              value={finalizadoraFormState.ativo}
              variant="DEFAULT"
              checked={finalizadoraFormState.ativo}
            />
          </Box>
        ),
        <></>,
        changeStatus
      ),
      tpModIntegrado && planoPagIntegrado
        ? new MenuOptionsModel(
            (
              <Box className={menuClasses.menuItem}>
                <Typography>
                  {finalizadoraFormState.integrado
                    ? 'Desabilitar'
                    : 'Habilitar'}{' '}
                  Integração com Maquininha
                </Typography>
                <SwitchSaurus
                  size="small"
                  value={finalizadoraFormState.integrado}
                  variant="DEFAULT"
                  checked={finalizadoraFormState.integrado}
                />
              </Box>
            ),
            <></>,
            changeIntegrado
          )
        : null,
      new MenuOptionsModel(
        (
          <Box
            className={classNames(menuClasses.menuItem, menuClasses.iconError)}
          >
            <Typography>Excluir Forma de Pagamento</Typography>
            <LixoIcon tipo="BUTTON" />
          </Box>
        ),
        <></>,
        confirmDelete
      )
    ];
  }, [
    changeIntegrado,
    changeStatus,
    confirmDelete,
    finalizadoraFormState.ativo,
    finalizadoraFormState.integrado,
    menuClasses.iconError,
    menuClasses.menuItem,
    planoPagIntegrado,
    tpModIntegrado
  ]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Edição de Forma de Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={<MenuOptions options={menuOptions()} divider />}
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : ''
          )}
        >
          <FormFinalizadoraEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  fecharCadastroFinalizadora();
                  abrirCadastroFinalizadora('', '', true);
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <NovoIcon tipo="BUTTON" />
                Novo
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={carregando}
                onClick={() => {
                  refEditForm.current?.submitForm();
                  reload.current = true;
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default FinalizadoraEdit;
