import { Dialog, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useCallback } from 'react';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './dialog-scann-chave-ativacao-styles';

interface DialogScannChaveAtivacaoProps {
  openned: boolean;
  closeModal: () => void;
  zIndex?: string;
  tamanho?: DialogProps['maxWidth'];
  setCodigo: (cod: string) => void;
}

export const DialogScannChaveAtivacao = (
  props: DialogScannChaveAtivacaoProps
) => {
  const classes = useStyles();

  const leituraRealizada = useCallback(
    (cod: string) => {
      if(cod.includes('?chaveAtivacao=')) {
        props.setCodigo(cod.split('?chaveAtivacao=')[1]);
        props.closeModal();
        return
      }
      props.setCodigo(cod);
      props.closeModal();
      return
    },
    [props]
  );

  return (
    <Dialog
      maxWidth={props.tamanho || 'md'}
      open={props.openned || false}
      fullWidth
      keepMounted
      className={classes.root}
      disableEscapeKeyDown
      ref={(el: any) => {
        if (el && (props.zIndex || '').length > 0) {
          el.style.setProperty('z-index', props.zIndex, 'important');
        }
      }}
    >
      <div className={classes.container}>
        <Typography className={classes.title}>
          Posicione o QrCode na marcação
        </Typography>
        <div className={classes.containerScanner}>
          {props.openned && (
            <Scanner
              onScan={leituraRealizada}
              inicialOrSecundario={false}
              openned={props.openned}
            />
          )}
        </div>
        <div className={classes.containericon}>
          <CloseIcon
            onClick={() => {
              props.closeModal();
            }}
            className={classes.iconClose}
          />
        </div>
      </div>
    </Dialog>
  );
};
