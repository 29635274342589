import { Typography } from "@material-ui/core";
import { CardSelecaoVendedorProps } from "./card-selecao-vendedor-props";


import { DefaultCard } from "../components/default-card";
import { useDefaultCardStyles } from "../components/default-card/default-card-styles";
import { PessoaModel } from "model/api/gestao/pessoa";
import { TpCadastroMock } from "data/mocks";

export const CardSelecaoVendedor = ({ model, onClick, onCheck, selected }: CardSelecaoVendedorProps) => {

  const classes = useDefaultCardStyles();

  
  const tpCadastro = TpCadastroMock.filter((x) => x.Key === model.tpCadastro)[0]
    .Value;

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model || PessoaModel);
        }}
      >
        <div className={classes.cardContent}>
          <div className={classes.celulaGridFull}>
            <Typography color="textPrimary" variant="caption">
              Nome
            </Typography>
            <Typography className={"celula-grid-value"} color="textPrimary" variant="body1">
              <strong>{model.nome}</strong>
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Tipo
            </Typography>
            <Typography className={"celula-grid-value"} color="textPrimary" variant="body1">
            <strong>{tpCadastro}</strong>
            </Typography>
          </div>
        </div>
      </DefaultCard>
    </>
  );
};
