import * as Yup from "yup";
import { useMemo } from "react";

export const useFormPrescritorPreCadastroValidation = () => {
    const FormYupValidationPrePrescritor = useMemo(() => {
        return Yup.object().shape({
            documento: Yup.string().required('Documento é obrigatório'),
            nome: Yup.string().required('Nome é obrigatório'),
            tipoDocumento: Yup.string().required('Escolha o tipo do documento'),
        });
    }, []);

    return {
        FormYupValidationPrePrescritor,
    };
};
