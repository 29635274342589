import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { CardSaloes } from 'views/components/cards/card-saloes';

export interface SaloesListDataProps {
  list: Array<SaloesModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const SaloesListData = (props: SaloesListDataProps) => {
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhum Salão encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
          key={0}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((salao, index) => {
          return (
            <CardSaloes
              model={salao}
              key={index}
              onClick={onCardSelected}
              onCheck={onCardChecked}
              selected={false}
            />
          );
        })}
    </>
  );
};
