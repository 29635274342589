import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useFormImpressoraCadastroValidation } from './form-impressora-cadastro-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { ImpressorasMock } from 'data/mocks/impressoras-mock';
import { KeyValueModel } from 'model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useStyles } from './form-impressora-cadastro-styles'
import { ToolTipImpressoras } from 'views/components/modals/impressora/components/impressora-cadastro/components/tooltip-saurus/tooltip-saurus';

export interface FormImpressoraCadastroProps
  extends DefaultFormProps<ImpressoraModel> {
  setCarregandoExterno(carregando: boolean): void;
}

export const FormImpressoraCadastro = forwardRef<
  DefaultFormRefs<ImpressoraModel>,
  FormImpressoraCadastroProps
>(({ loading, ...props }: FormImpressoraCadastroProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [initialValues, setInitialValues] = useState<ImpressoraModel>(
    new ImpressoraModel(),
  );


  const classes = useStyles()
  const { FormImpressoraCadastroYupValidation } = useFormImpressoraCadastroValidation();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<ImpressoraModel>({
    defaultValues: { ...initialValues },
    resolver: yupResolver(FormImpressoraCadastroYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const modelosMock: Array<KeyValueModel> = ImpressorasMock.map(x => new KeyValueModel(x, x))
  const onSubmit = (values: ImpressoraModel) => {
    const model = picker<ImpressoraModel>(
      values,
      new ImpressoraModel(),
    );

    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setInitialValues(new ImpressoraModel());
      if (!isMobile) refInputCodigo.current?.focus();
      reset();
    },
    fillForm: () => {
      if (!isMobile) refInputCodigo.current?.focus();
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      label="Descrição *"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Defina o nome da impressora.' />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="caminho"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      label="Caminho *"
                      variant="outlined"
                      placeholder='Ex: \\127.0.0'
                      error={Boolean(
                        errors.caminho && errors.caminho.message,
                      )}
                      helperText={
                        touchedFields.caminho || errors.caminho
                          ? errors.caminho?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Informe o número do IP e o número de compartilhamento da impressora.
                        Exemplo: IP: 127.0.0 e
                        Número de Compartilhamento: J2
                        Neste caso, o caminho seria: \\127.0.0\J2'
                />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="modelo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      fullWidth
                      allowSubmit
                      label="Modelo *"
                      variant="outlined"
                      conteudo={modelosMock}
                      placeholder='Ex: \\127.0.0'
                      error={Boolean(
                        errors.modelo && errors.modelo.message,
                      )}
                      helperText={
                        touchedFields.modelo || errors.modelo
                          ? errors.modelo?.message
                          : undefined
                      }
                      {...field}
                      onChange={ev => {
                        const item = modelosMock
                          .filter(item => item.Key === ev.target.value)

                        if (item[0].Key) {
                          setValue('modelo', item[0].Value)
                        }
                      }}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Selecione o modelo da sua impressora.' />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="colunas"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="NUMERO"
                      maxTexto={3}
                      label="Colunas *"
                      variant="outlined"
                      placeholder='Ex: 42'
                      error={Boolean(
                        errors.colunas && errors.colunas.message,
                      )}
                      helperText={
                        touchedFields.colunas || errors.colunas
                          ? errors.colunas?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Use este campo para definir a largura da impressão. Cada coluna é ocupada por um caractere (entre 32 e 80) e o padrão é 64.'
                />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="quantidadeVias"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="NUMERO"
                      maxTexto={3}
                      label="Vias *"
                      variant="outlined"
                      placeholder='Ex: 2'
                      error={Boolean(
                        errors.quantidadeVias && errors.quantidadeVias.message,
                      )}
                      helperText={
                        touchedFields.quantidadeVias || errors.quantidadeVias
                          ? errors.quantidadeVias?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Defina a quantidade de vias que serão impressas.' />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="usuario"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="TEXTO"
                      label="Usuário"
                      variant="outlined"
                      error={Boolean(
                        errors.usuario && errors.usuario.message,
                      )}
                      helperText={
                        touchedFields.usuario || errors.usuario
                          ? errors.usuario?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
                <ToolTipImpressoras descricao='Informe o usuário do computador que será utilizado.' />
              </Grid>
              <Grid className={classes.inputForm} item xs={12}>
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="PASSWORD"
                      label="Senha"
                      variant="outlined"
                      error={Boolean(
                        errors.senha && errors.senha.message,
                      )}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}

                    />
                  )}
                />
                <ToolTipImpressoras descricao='Informe a senha do computador que será utilizado.' />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
