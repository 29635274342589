import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function useGetModificadorById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const getModificadorById = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador/${id}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [getEmpresaSelecionada, invocarApi]
  );

  return {
    ...outrasPropriedades,
    getModificadorById,
  };




}
