import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { CarrinhoListData } from './carrinho-list-data';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import {
  GestaoStorageKeys,
  useGestaoStorage,
  useToastSaurus
} from 'services/app';

interface CarrinhoListProps {
  inativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  reativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  alterarQtdeProduto(
    model: MovSimplesProdutoModel,
    incremento: number,
    fator: 'sub' | 'add'
  ): Promise<void>;
  setProductEdit: (produtoId: string) => void;
}

export const CarrinhoList = ({
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  setProductEdit
}: CarrinhoListProps) => {
  const {
    getMov,
    removerPedidoDoCarrinho,
    carregando: carregandoRemocaoPedido
  } = useMovAtual();
  const mov = getMov();
  const { getRegistro } = useGestaoStorage();

  const getCancelados = useCallback(() => {
    const cancelados = getRegistro(
      GestaoStorageKeys.Cancelados,
      false
    ) as boolean;

    if (cancelados) {
      return cancelados;
    }

    return false;
  }, [getRegistro]);

  const [showCancelados, setShowCancelados] = useState(getCancelados());
  const [listStatus, setListStatus] = useState(mov?.produtos ?? []);
  const { addHandler, removeHandler } = useEventTools();
  const { showToast } = useToastSaurus();

  const movProdAlterada = useCallback(
    (mov: MovSimplesProdutoModel) => {
      setListStatus((previous) => {
        if (previous.length !== getMov()?.produtos.length)
          return getMov()?.produtos || [];

        const prods = previous.filter((x) => x.nSeq === mov.nSeq);
        if (prods.length > 0) {
          const index = previous.indexOf(prods[0]);
          previous[index] = mov;
          return [...previous];
        }
        return previous;
      });
    },
    [getMov]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterada);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterada);
    };
  }, [addHandler, movProdAlterada, removeHandler]);

  const removerPedido = useCallback(
    async (idPedido: string) => {
      try {
        await removerPedidoDoCarrinho(idPedido);
      } catch (err: any) {
        showToast(
          'error',
          `Erro ao tentar remover o pedido da venda atual, por favor tente novamente. Detalhes: ${err.message}`
        );
      }
    },
    [removerPedidoDoCarrinho, showToast]
  );

  
  const mostrarEsconderCancelados = useCallback((show: boolean) => {
    setShowCancelados(show);
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarCanceladosCarrinho, mostrarEsconderCancelados);
    return () => {
      removeHandler(AppEventEnum.AtualizarCanceladosCarrinho, mostrarEsconderCancelados);
    };
  }, [addHandler, mostrarEsconderCancelados, removeHandler]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Grid item xs={12} style={{ flex: 1 }}>
        {carregandoRemocaoPedido && <CircularLoading tipo="FULLSIZED" />}

        <CarrinhoListData
          inativarProduto={inativarProduto}
          reativarProduto={reativarProduto}
          alterarQtdeProduto={alterarQtdeProduto}
          showCancelados={showCancelados}
          list={listStatus}
          removerPedidoDoCarrinho={removerPedido}
          setProductEdit={setProductEdit}
        />
      </Grid>
    </div>
  );
};
