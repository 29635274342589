import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormPagamentoEditValidation } from './form-pagamento-edit-validation';
import { FormPagamentoEditProps } from './form-pagamento-edit-props';
import { picker } from 'utils/picker';
import { DupCobrModel } from 'model/api/gestao/venda/venda-completa-model';
import { isEqual } from 'lodash';
import { toDate } from 'utils/to-date';
import { DupCobrPagamentoModel } from 'views/components/modals/pagamento-edit/components/pagamento-edit-conteudo/pagamento-edit-conteudo';


export const FormPagamentoEdit = forwardRef<
    DefaultFormRefs<DupCobrPagamentoModel>,
    FormPagamentoEditProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formPagamentoEditValidationYup } = useFormPagamentoEditValidation();
    const [model, setModel] = useState<DupCobrModel>(new DupCobrModel())
    const modelFormRef = useRef<DupCobrModel>(new DupCobrModel())
    const {
        handleSubmit,
        control,
        formState,
        reset,
    } = useForm<DupCobrModel>({
        resolver: yupResolver(formPagamentoEditValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const dataFormatada = useCallback((val: string | Date) => {
        const data = toDate(val) ?? new Date(val);
        const dia = data?.getDate();
        const mes = data?.getMonth() + 1;
        const ano = data?.getFullYear();

        const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
            }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
        return dataFormated;
    }, []);

    useEffect(() => {
        if (isEqual(model, new DupCobrModel())) {
            const dup = picker<DupCobrModel>(props.model, new DupCobrModel())
            if (dup.dVenc) {
                dup.dVenc = dataFormatada(dup.dVenc)
            }
            reset({ ...dup })
            setModel({ ...dup })
            modelFormRef.current = { ...dup }
        }
    }, [dataFormatada, model, props.model, reset])

    const onSubmit = (values: DupCobrModel) => {
        const pag = picker<DupCobrModel>(values, new DupCobrModel())
        props.onSubmit(pag, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: DupCobrPagamentoModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        {props.numParcelas > 1 && <Grid item xs={6}>
                            <TextFieldSaurus
                                tipo={'NUMERO'}
                                fullWidth
                                manterMascara
                                variant="outlined"
                                label="Parcela"
                                allowSubmit
                                readOnly
                                value={props.model.index + 1}
                            />
                        </Grid>}
                        <Grid item xs={6}>
                            <Controller
                                name="vDup"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Valor"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vDup && errors.vDup.message)}
                                        helperText={
                                            touchedFields.vDup || errors.vDup
                                                ? errors.vDup?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="dVenc"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DATA'}
                                        fullWidth
                                        manterMascara
                                        variant="outlined"
                                        label="Data de Vencimento"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.dVenc && errors.dVenc.message)}
                                        helperText={
                                            touchedFields.dVenc || errors.dVenc
                                                ? errors.dVenc?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
