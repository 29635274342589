import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { makeUtilClasses, useThemeQueries } from 'views';

import { useFormPessoaValidation } from './form-pessoa-edit-validation';

import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import {
  PessoaEditContatoFormModel,
  PessoaEditDadosFormModel
} from 'model/app/forms/pessoa/pessoa-edit-form-model';
import { ConsultaCNPJModel, EnumCadastroTipo } from 'model';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConsultaCnpj } from 'data/api/wsmaster';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { ButtonIcon } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { guidEmpty } from 'utils/guid-empty';
import { useStyles } from './form-pessoa-edit-styles';
import classNames from 'classnames';
import { useToastSaurus } from 'services/app';
import { toDate } from 'utils/to-date';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { SexoMock } from 'data/mocks';
import { EnumSexo } from 'model/enums/enum-sexo';
import {
  AutocompletePessoas,
  EnumNomeCnpj
} from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { RepresentanteCliente } from 'model/api/gestao/pessoa/pessoa-model';

export interface FormPessoaEditRefs
  extends DefaultFormRefs<PessoaEditDadosFormModel> {
  getFields: () => Promise<PessoaEditDadosFormModel | false>;
}

interface FormPessoaEditProps
  extends DefaultFormProps<PessoaEditDadosFormModel> {
  onClickNovoContato: (tipo: EnumTipoPessoaContato) => void;
  addEndereco: (model: ConsultaCNPJModel) => void;
}

export const FormPessoaEdit = forwardRef<
  FormPessoaEditRefs,
  FormPessoaEditProps
>((props: FormPessoaEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  const { FormPessoaValidationYup } = useFormPessoaValidation();

  const { isMobile } = useThemeQueries();
  const { consultarCNPJ, carregando: carregandoCNPJ } = useConsultaCnpj();

  const carregando = carregandoCNPJ;

  //REFS E STATES
  const refInputCnpjCpf = useRef<HTMLInputElement>(null);
  const beforeModel = useRef<PessoaEditDadosFormModel>(
    new PessoaEditDadosFormModel()
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    trigger,
    getFieldState
  } = useForm<PessoaEditDadosFormModel>({
    defaultValues: new PessoaEditDadosFormModel(),
    resolver: yupResolver(FormPessoaValidationYup),
    criteriaMode: 'all',
    mode: 'onBlur'
  });

  const { fields } = useFieldArray({
    control,
    name: 'contatos'
  });

  const dataFormatada = useCallback((val) => {
    const data = toDate(val) ?? new Date(val);
    const dia = data?.getDate();
    const mes = data?.getMonth() + 1;
    const ano = data?.getFullYear();

    const dataFormated = `${ano}-${
      mes?.toString().length === 1 ? '0' + mes : mes
    }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
    return dataFormated;
  }, []);

  const onSubmit = (values: PessoaEditDadosFormModel) => {
    values.contatos = values.contatos.filter(
      (contato) => !isEmpty(contato.valor)
    );
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
    fillForm: (model: PessoaEditDadosFormModel) => {
      beforeModel.current = { ...model };
      beforeModel.current.contatos = [...model.contatos];

      if (model.contatos.length < 2) {
        for (let i = model.contatos.length; i < 2; i++) {
          model.contatos.push(
            new PessoaEditContatoFormModel(
              guidEmpty(),
              model.id,
              model.contatos.find((x) => x.tipo === EnumTipoPessoaContato.EMAIL)
                ? EnumTipoPessoaContato.TELEFONE
                : EnumTipoPessoaContato.EMAIL,
              ''
            )
          );
        }
      }
      if (model.dtNasc) model.dtNasc = dataFormatada(model.dtNasc);
      if (model.sexo === null) model.sexo = EnumSexo.NaoInformado;

      reset({ ...model });
      setTimeout(() => {
        if (!isMobile) refInputCnpjCpf.current?.focus();
      }, 500);
    },
    getFields: async () => {
      await trigger();
      if (!isEmpty(errors)) {
        return false;
      }

      const pessoaEditForm: PessoaEditDadosFormModel = {
        ...new PessoaEditDadosFormModel(),
        id: getValues('id'),
        tpCadastro: getValues('tpCadastro'),
        cpfcnpj: getValues('cpfcnpj'),
        ierg: getValues('ierg'),
        nome: getValues('nome'),
        infOperador: getValues('infOperador'),
        status: getValues('status'),
        contatos: getValues('contatos').filter(
          (contato) => !isEmpty(contato.valor)
        ),
        sexo: getValues('sexo'),
        dtNasc: getValues('dtNasc'),
        representante: getValues('representante'),
        representanteId: getValues('representanteId')
      };
      return pessoaEditForm;
    }
  }));

  const loading = carregando || props.loading;

  const retornarTipo = useCallback((tipo: EnumTipoPessoaContato) => {
    switch (tipo) {
      case EnumTipoPessoaContato.EMAIL:
        return 'EMAIL';
      case EnumTipoPessoaContato.TELEFONE:
        return 'TELEFONE';
    }
  }, []);

  const retornarLabel = useCallback((tipo: EnumTipoPessoaContato) => {
    switch (tipo) {
      case EnumTipoPessoaContato.EMAIL:
        return 'E-mail';
      case EnumTipoPessoaContato.TELEFONE:
        return 'Telefone';
    }
  }, []);

  const mostrarCamposContato =
    fields.filter((x) => x.tipo === EnumTipoPessoaContato.EMAIL).length < 2 &&
    fields.filter((x) => x.tipo === EnumTipoPessoaContato.TELEFONE).length < 2;

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <div className={classNames(utilClasses.formContainer, classes.container)}>
        {loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo={'NORMAL'} />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            loading && props.showLoading ? utilClasses.controlLoading : ''
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo={'TEXTO'}
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label="Nome Completo / Razão Social"
                    InputLabelProps={{
                      shrink: true
                    }}
                    autoComplete="new-password"
                    placeholder="Ex: João da Silva"
                    {...field}
                    error={Boolean(errors?.nome)}
                    helperText={
                      errors?.nome ? errors?.nome?.message : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="cpfcnpj"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="CNPJ_CPF"
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label="CPF/CNPJ"
                    InputLabelProps={{
                      shrink: true
                    }}
                    searchable
                    onSearch={async () => {
                      try {
                        const res = await consultarCNPJ(getValues('cpfcnpj'));

                        if (res.ie) {
                          setValue('ierg', res.ie);
                        }

                        setValue('nome', res.razaoSocial);
                        props.addEndereco(res);
                      } catch (e: any) {
                        showToast('error', e.message);
                      }
                    }}
                    autoComplete="new-password"
                    placeholder={
                      getValues('tpCadastro') === EnumCadastroTipo.CLIENTE ||
                      getValues('tpCadastro') === EnumCadastroTipo.PRESCRITOR ||
                      getValues('tpCadastro') === EnumCadastroTipo.FUNCIONARIO
                        ? 'Opcional'
                        : 'Informe o CPF ou CNPJ'
                    }
                    {...field}
                    error={Boolean(errors?.cpfcnpj)}
                    helperText={
                      errors?.cpfcnpj ? errors?.cpfcnpj?.message : undefined
                    }
                  />
                )}
              />
            </Grid>
            {mostrarCamposContato &&
              fields.map((contato, i) => {
                const mostrarBotao = Boolean(
                  beforeModel.current.contatos.find(
                    (x) => x.tipo === contato.tipo
                  )
                );
                return (
                  <Grid item xs={12} className={classes.contatoField}>
                    <Controller
                      name={`contatos.${i}.valor`}
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo={retornarTipo(contato.tipo)}
                          disabled={loading}
                          fullWidth
                          variant="outlined"
                          label={retornarLabel(contato.tipo)}
                          InputLabelProps={{
                            shrink: true
                          }}
                          autoComplete="new-password"
                          {...field}
                          error={Boolean(
                            getFieldState(`contatos.${i}.valor`).error
                          )}
                          helperText={
                            getFieldState(`contatos.${i}.valor`).error
                              ? getFieldState(`contatos.${i}.valor`).error
                                  ?.message
                              : undefined
                          }
                        />
                      )}
                    />
                    {mostrarBotao && (
                      <ButtonIcon
                        icon={<NovoIcon tipo="BUTTON" />}
                        onClick={() =>
                          props.onClickNovoContato(
                            getValues(`contatos.${i}.tipo`)
                          )
                        }
                        color="primary"
                        height="100%"
                      />
                    )}
                  </Grid>
                );
              })}
            <Grid item xs={6}>
              <Controller
                name="dtNasc"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="DATA"
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label="Data de Nascimento"
                    InputLabelProps={{
                      shrink: true
                    }}
                    autoComplete="new-password"
                    {...field}
                    error={Boolean(errors?.dtNasc)}
                    helperText={
                      errors?.dtNasc ? errors?.dtNasc?.message : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="sexo"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    conteudo={SexoMock}
                    disabled={loading}
                    fullWidth
                    variant="outlined"
                    label="Gênero"
                    InputLabelProps={{
                      shrink: true
                    }}
                    autoComplete="new-password"
                    {...field}
                    error={Boolean(errors?.sexo)}
                    helperText={
                      errors?.sexo ? errors?.sexo?.message : undefined
                    }
                  />
                )}
              />
            </Grid>
            {getValues('tpCadastro') === EnumCadastroTipo.CLIENTE && (
              <Grid item xs={12}>
                <Controller
                  name="representanteId"
                  control={control}
                  render={({ field }) => (
                    <AutocompletePessoas
                      allowSubmit
                      label={'Representante'}
                      {...field}
                      onChange={async (retorno) => {
                        if (!retorno.isString) {
                          const pessoa: PessoaModel = retorno.value;

                          if (pessoa) {
                            const representanteCliente =
                              new RepresentanteCliente(
                                pessoa.id,
                                pessoa.tpCadastro,
                                pessoa.cpfcnpj,
                                pessoa.nome,
                                pessoa.fantasia,
                                pessoa.status,
                                pessoa.dtNasc,
                                pessoa.sexo
                              );
                            setValue('representante', representanteCliente);
                            setValue(
                              'representanteId',
                              representanteCliente.id
                            );
                          } else {
                            setValue('representante', null);
                            setValue('representanteId', '');
                          }
                        } else {
                          setValue('representante', null);
                          setValue('representanteId', '');
                        }
                      }}
                      isRepresentantePreenhido={(field.value?.length ?? 0) > 0}
                      exibirTipo
                      nomeCnpj={EnumNomeCnpj.Nome}
                      fetchOnLoad={!isEmpty(field.value)}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
