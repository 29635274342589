import { useState } from 'react';
import { DefaultModal } from '../components';
import { ModalProps } from '../utils';
import { EntradaOpcoes } from './components/entrada-opcoes/entrada-opcoes';
import { CadastroEntradaManual } from './components/cadastro-entrada-manual/cadastro-entrada-manual';
import { EnumEntradaOpcoes } from './entrada-modal-props';
import { CadastroXML } from './components/cadastro-xml/cadastro-xml';

export const EntradaModal = (props: ModalProps) => {
  const [opcao, setOpcao] = useState<EnumEntradaOpcoes | null>(null);

  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned ?? false}
      variant="temporary"
      anchor="right"
    >
      {props.openned && opcao === null && <EntradaOpcoes setOpcao={setOpcao} />}
      {props.openned && opcao === EnumEntradaOpcoes.EntradaManual && (
        <CadastroEntradaManual voltarEntrada={() => setOpcao(null)} />
      )}
      {props.openned && opcao === EnumEntradaOpcoes.ImportarXML && (
        <CadastroXML voltarEntrada={() => setOpcao(null)} />
      )}
    </DefaultModal>
  );
};
