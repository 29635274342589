import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePostCompartilhamentoDadosAceitar() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postCompartilhamentoDadosAceitar = useCallback(
    (key: string) =>
      invocarApi({
        url: `v2/contrato/Compartilhamento/aceitar?key=${key}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postCompartilhamentoDadosAceitar,
  };
}
