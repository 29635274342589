import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { CardNaoEncontrado } from '../../../cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../icons/informacao-icon';
import { CardPagamentoMov } from '../../../cards/card-pagamento-mov/card-pagamento-mov';

export interface PagamentosGridProps {
  list: MovSimplesPagamentoModel[] | undefined;
}

export const PagamentosListData = (props: PagamentosGridProps) => {
  return (
    <>
      {props.list && props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhum pagamento realizado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list &&
        props.list.length > 0 &&
        props.list.map((pagamento, index) => {
          return <CardPagamentoMov pagamento={pagamento} key={index} />;
        })}
    </>
  );
};
