import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
    },
    containerInternoCard: {
        padding: theme.spacing(1),
        position: 'relative',
    },
    contentInformacoes: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
    },
    btnInativar: {
        height: '24px'
    },
    btnRetornar: {
        height: '24px'
    },
    containerInfo: {
        display: 'flex',
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    textDescricao: {
        "& h6": {
            fontWeight: 600,
            paddingRight: theme.spacing(3),
            color: theme.palette.primary.main,
        }
    },
    textCategoria: {
        marginTop: theme.spacing(1) * -1,
        "& span": {
            color: theme.palette.grey[700],
        }
    },
    containerValores: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: -5,
    },
    textVUnCom: {
        color: theme.palette.grey[700],
    },
    textVProd: {
        color: theme.palette.grey[700],
        fontWeight: 'bold',
    },
    textVUnitario: {
        color: theme.palette.grey[500],
        fontWeight: 'bold',
    },
    textVTotal: {
        fontSize: '10px',
        fontWeight: 400
    },
    textVProdDescAcres: {
        fontSize: '12px',
        paddingTop: '2px'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: '1px',
        marginTop: '4px',
        marginBottom: '8px'
    },
    pAdicional: {
        fontWeight: 600,
        fontSize: '16px',
        marginTop: '8px'
    },
    validarTexto: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'justify',
    },
    containerTexto: {
        padding: '4px 0px'
    },
    texto: {
        textDecoration: 'underline',
        display: 'flex'
    },
    containerInativar: {
        position: 'absolute',
        top: 4,
        right: 4
    },
    textCod: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '6px'
    },
    containerValorUnidade: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'fle'
    }
}));
