import { useCallback } from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { MenuModel } from 'model/app';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { ModulosDeliveryPadraoMock, ModulosFavoritosPadraoMock } from 'data/mocks/menu-pdv-favoritos-mock';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useSessaoAtual } from '../providers';
import { farmaceuticoMenuEnumPermissao } from 'data/mocks/farmaceutico-menu-enum-permissao';

export const useMenuFavoritos = () => {
    // hooks
    const { getRegistro, setRegistro, delRegistro, exists } = useGestaoStorage();
    const { callEvent } = useEventTools();
    const { plano } = useSessaoAtual()
    const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)
    const getFavoritos = useCallback((): MenuModel[] => {
        let menu = getRegistro(
            GestaoStorageKeys.MenuPDVFavorito,
            false
        ) as MenuModel[];

        if (!Array.isArray(menu)) {
            menu = ModulosFavoritosPadraoMock;
            setRegistro(GestaoStorageKeys.MenuPDVFavorito, menu, false);
        }
        return menu.filter((item) => {
            if (isFarmaceutico && (
                farmaceuticoMenuEnumPermissao.includes(item.codigo))) {
                return false
            }
            return true
        });
    }, [getRegistro, isFarmaceutico, setRegistro]);

    const removeFavorito = useCallback(
        (index: number) => {
            let menu = getRegistro(
                GestaoStorageKeys.MenuPDVFavorito,
                false
            ) as MenuModel[];
            menu.splice(index, 1);
            setRegistro(GestaoStorageKeys.MenuPDVFavorito, menu, false);
            callEvent(AppEventEnum.MenuPDVFavorito, false);
        },
        [callEvent, getRegistro, setRegistro]
    );

    const setFavorito = useCallback(
        (opcao: MenuModel) => {
            let menu = getRegistro(
                GestaoStorageKeys.MenuPDVFavorito,
                false
            ) as MenuModel[];

            const index = menu.findIndex((item) => item.codigo === opcao.codigo);

            if (index >= 0) {
                removeFavorito(index);
            } else {
                menu = [...menu, opcao];
                setRegistro(GestaoStorageKeys.MenuPDVFavorito, menu, false);
            }

            callEvent(AppEventEnum.MenuPDVFavorito, true);
        },
        [callEvent, getRegistro, removeFavorito, setRegistro]
    );

    const clearFavoritos = useCallback(
        () => {
            delRegistro(GestaoStorageKeys.MenuPDVFavorito, false);
            callEvent(AppEventEnum.MenuPDVFavorito, true);
        },
        [callEvent, delRegistro]
    );

    const setFavoritoBackup = useCallback(
        (menuAtual: MenuModel[]) => {
            setRegistro(GestaoStorageKeys.BackupMenuPDVFavorito, menuAtual, false);
        },
        [setRegistro]
    );

    const getFavoritosBackup = useCallback((): MenuModel[] => {
        let menu = getRegistro(
            GestaoStorageKeys.BackupMenuPDVFavorito,
            false
        ) as MenuModel[];

        if (exists(GestaoStorageKeys.BackupMenuPDVFavorito, false)) {
            setRegistro(GestaoStorageKeys.MenuPDVFavorito, menu, false);
        }
        return menu;
    }, [exists, getRegistro, setRegistro]);

    const delFavoritoBackup = useCallback(
        () => {
            getFavoritosBackup();
            callEvent(AppEventEnum.MenuPDVFavorito, true);
            delRegistro(GestaoStorageKeys.BackupMenuPDVFavorito, false);
        },
        [callEvent, delRegistro, getFavoritosBackup]
    );

    const backupMenuFavorito = useCallback(() => {
        const backupMenu = getRegistro(
            GestaoStorageKeys.MenuPDVFavorito,
            false
        ) as MenuModel[];

        setFavoritoBackup(backupMenu);

        setRegistro(GestaoStorageKeys.MenuPDVFavorito, ModulosDeliveryPadraoMock, false);

        callEvent(AppEventEnum.MenuPDVFavorito, true);
    }, [callEvent, getRegistro, setFavoritoBackup, setRegistro]);

    return {
        getFavoritos,
        setFavorito,
        clearFavoritos,

        // BACKUP MENU FAVORITO
        backupMenuFavorito,
        delFavoritoBackup
    };
};
