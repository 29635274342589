import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormSalaoValidation = () => {
  const FormSalaoValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        balcao: Yup.number().required('Selecione uma opção'),
        descricao: Yup.string().required('Descrição do Salão é Obrigatória'),
      })
    )
  }, [])
  return {
    FormSalaoValidationYup,
  }
}

