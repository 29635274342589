import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {

  const heightWin = window.innerHeight

  return {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      overflowX: 'hidden',
      background: theme.palette.grey[100],
      zIndex: 100
    },
    rootHeightMobile: {
      height: `calc(${heightWin}px - 205px)`,
    },
    rootHeightDesk: {
      height: `calc(${heightWin}px - 140px)`,
    },
    rootHeightCartMobile: {
      height: `calc(${heightWin}px - 335px)`,
    },
    rootHeightCartDesk: {
      height: `calc(${heightWin}px - 274px)`,
    },
    list: {
      flex: '1 1 100%',
      overflowX: 'hidden',
      overflow: 'auto',
      position: 'relative',
      padding: 8,
    },
    tab: {
      minHeight: '57px',
      display: 'flex',
      width: '100%'
    }
  }
});
