import { Grid, Typography, Button, Box } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './leitor-comanda-fragment-styles';
import Scanner from './components/scanner/scanner';
import { useToastSaurus } from 'services/app';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { ButtonKeyboardBackspace } from 'views/components/keyboard/components/button-keyboard-backspace/button-keyboard-backspace';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { LeitorComandaHeader } from './components/leitor-comanda-header/leitor-comanda-header';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { KeypadIcon } from 'views/components/icons/keypad-icon';
import { useThemeQueries } from 'views/theme';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { ArrowDownIcon } from 'views/components/icons/arrow-down-icon';
import classNames from 'classnames';
import { useDevice } from 'services/app/hooks/device';
import { EnumAcaoLeituraRfid } from 'model/enums/enum-acao-leitura-rfid';
import { EnumTemNFC } from 'model/enums/enum-tem-nfc';
import { useNFC } from 'services/app/hooks/nfc';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { EscaneandoComanda } from './components/escaneando-comanda/escaneando-comanda';
import scanAnim from 'assets/img/nfc-animation.gif';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { isEmpty } from 'lodash';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { useDigitarCodigo } from 'services/app/hooks/digitar-codigo';

interface LeitorComandaProps {
  handleConfime: () => Promise<void>;
  textChanged: (text: string, formattedText: string) => Promise<boolean>;
  submit: () => Promise<boolean>;
  add: () => Promise<boolean>;
}

const LeitorComanda = ({
  handleConfime,
  textChanged,
  submit,
  add
}: LeitorComandaProps) => {
  const digitado = useRef<boolean>(false);
  const textoAtual = useRef<string>('');
  const valueRef = useRef<HTMLParagraphElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  //PROVIDERS
  const {
    getFormattedText,
    addValue,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey
  } = useKeyboard({
    maxLength: 20,
    floatCases: 0,
    isNumeric: false,
    handleTextChanged: textChanged,
    handleAdd: add,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual
  });
  const { get, set } = useDigitarCodigo();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { searchComandas } = usePedidoLocal();
  const { setComandaId, getMov, setCodigoComanda } = useMovAtual();
  const { getComandaRfid, dispositivoTemNFC } = useDevice();
  const { temNFC, verificarNFC } = useNFC();
  //AUX E Outros
  const { push: historyPush, goBack } = useHistory();
  const classes = useDefaultCadastroStyles();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { getConfigByCod } = useEmpresaAtual();

  const tipoTrabalhoComanda = useMemo(
    () => getConfiguracoesMesaEComanda()!.tipoTrabalhoComanda,
    [getConfiguracoesMesaEComanda]
  );

  const classesLeitor = useStyles();
  const { showToast } = useToastSaurus();
  const digitos = getFormattedText();
  const { isMobile, theme } = useThemeQueries();

  const attValueDisplay = useCallback(
    (any: any) => {
      const v = getFormattedText();
      if (valueRef.current) {
        if (v.length === 0) {
          valueRef.current.textContent = 'Aguardando Digitação';
          valueRef.current.className = classesLeitor.textVisorAguardando;
          if (btnRef.current) {
            btnRef.current.classList.add('Mui-disabled');
          }
          return;
        }
        valueRef.current.textContent = v.replace(/\*/g, 'x');
        valueRef.current.className = classesLeitor.textVisor;
        if (btnRef.current) {
          btnRef.current.classList.remove('Mui-disabled');
        }
      }
    },
    [
      classesLeitor.textVisor,
      classesLeitor.textVisorAguardando,
      getFormattedText
    ]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay);
    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay);
  }, [addHandler, attValueDisplay, removeHandler]);

  //STATES E REFS
  const [digitarCodigo, setDigitarCodigo] = useState(get());
  const [escaneando, setEscaneando] = useState<boolean>(false);
  const [erro, setErro] = useState<string>('');

  const possuiScanner =
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO &&
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO_DEV;

  const exibirDigitarCodigo = digitarCodigo || !possuiScanner;

  useEffect(() => {
    if (!exibirDigitarCodigo)
      callEvent(AppEventEnum.PermiteEntradaDigitada, true);

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, false);
  }, [callEvent, exibirDigitarCodigo]);

  const handleClickDigitarCodigo = () => {
    setDigitarCodigo(!digitarCodigo);
    set(!digitarCodigo);
  };

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue]
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText]
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(async () => {
    await handleConfime();
    resetText('');
    submitKey();
  }, [handleConfime, resetText, submitKey]);

  const redirecionarPedido = useCallback(async () => {
    const mov = getMov();
    if (!mov) return;

    historyPush('/venda-simples/enviar-pedido');
    return;
  }, [getMov, historyPush]);

  const confirmarComanda = useCallback(
    async (codComanda: string) => {
      setErro('');
      try {
        const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
        const prefixo = buscaPrefixo?.toUpperCase();

        let comanda: any = codComanda;

        if (comanda?.startsWith(prefixo)) {
          comanda = comanda.slice(prefixo?.length);
        }

        if (!isEmpty(prefixo) && codComanda.includes(prefixo!)) {
          if (isOnlyHasNumber(comanda)) {
            comanda = Number(comanda);
          }
        }

        const comandas = await searchComandas(comanda);

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o codigo ${comanda}`
          );
        }

        await setComandaId(comandas[0].id);
        await setCodigoComanda(codComanda);

        redirecionarPedido();
      } catch (err: any) {
        setErro(err.message);
        showToast('error', err.message);
      } finally {
        resetText('');
      }
    },
    [
      getConfigByCod,
      redirecionarPedido,
      resetText,
      searchComandas,
      setCodigoComanda,
      setComandaId,
      showToast
    ]
  );

  const confirmarComandaByRfId = useCallback(
    async (rfId: string) => {
      try {
        setEscaneando(false);
        const comandas = await searchComandas(rfId, 'rfId');

        if (comandas.length === 0) {
          throw new Error(
            `Nenhuma comanda foi identificada com o RFID ${rfId}`
          );
        }

        await setComandaId(comandas[0].id);
        await redirecionarPedido();
      } catch (e: any) {
        setEscaneando(true);
        showToast('error', e.message);
      } finally {
        resetText('');
      }
    },
    [redirecionarPedido, resetText, searchComandas, setComandaId, showToast]
  );

  const escanearComanda = useCallback(async () => {
    let escaneandoComanda = true;
    while (escaneandoComanda) {
      try {
        const codigo = await getComandaRfid(EnumAcaoLeituraRfid.ATIVAR);
        showToast('success', `Leitura realizada com sucesso.`);
        setEscaneando(false);
        escaneandoComanda = false;
        await confirmarComandaByRfId(codigo);
        return;
      } catch (e: any) {
        if (e === EnumTemNFC.SEM_NFC) {
          setEscaneando(false);
          escaneandoComanda = false;
          return;
        }
        showToast('error', e);
      }
    }
  }, [confirmarComandaByRfId, getComandaRfid, showToast]);

  const teclado = useMemo(() => {
    return (
      <>
        <Grid style={{ flex: 1, display: 'flex', overflowX: 'hidden' }}>
          <Keyboard
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric={false}
          />
        </Grid>
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const butonBackspace = useMemo(() => {
    return (
      <Grid style={{ marginRight: 15 }}>
        <ButtonKeyboardBackspace alt="apagar" clickFunc={handleBackSpace} />
      </Grid>
    );
  }, [handleBackSpace]);

  const RenderFluxoInicialLeitor = memo(() => {
    return (
      <>
        <Button
          className={`${classesLeitor.button} round`}
          onClick={handleClickDigitarCodigo}
          variant="outlined"
          color="primary"
          style={{ height: isMobile ? '90px' : '60px' }}
        >
          <KeypadIcon tipo="BUTTON" fill={theme.palette.primary.main} />

          <Typography className={classesLeitor.textButton}>
            Inserir Código Manualmente
          </Typography>
        </Button>
      </>
    );
  });

  useEffect(() => {
    if (getConfiguracoesMesaEComanda()!.qtdeComandas < 1) {
      historyPush('/venda-simples/enviar-pedido');
      return;
    }
    if (
      temNFC &&
      !escaneando &&
      tipoTrabalhoComanda !== EnumTipoTrabalhoComanda.APENAS_RFID
    ) {
      setEscaneando(true);
      escanearComanda();
    } else if (!temNFC) {
      verificarNFC();
    }
    return () => {
      if (escaneando) {
        getComandaRfid(EnumAcaoLeituraRfid.DESATIVAR);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispositivoTemNFC,
    escanearComanda,
    getComandaRfid,
    getConfiguracoesMesaEComanda,
    historyPush,
    temNFC,
    tipoTrabalhoComanda,
    verificarNFC
  ]);

  const RenderEscaneandoComanda = memo(() => {
    return (
      <>
        <Box
          width="100%"
          bgcolor={theme.palette.background.paper}
          p={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gridGap={4}
        >
          <Typography align="center">
            Leitura de comanda RFID através do NFC disponível.
          </Typography>
          <img
            alt="Celular Escaneando NFC"
            src={scanAnim}
            className={classesLeitor.nfcAnim}
          />
        </Box>
      </>
    );
  });

  const renderInserirCodigoManual = () => {
    return (
      <>
        <Grid className={classesLeitor.visorCodigo}>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {possuiScanner && (
              <Grid>
                <Button
                  onClick={handleClickDigitarCodigo}
                  variant="outlined"
                  color="primary"
                  style={{
                    marginLeft: '8px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    padding: '0'
                  }}
                >
                  <ArrowDownIcon
                    tipo="GERAL"
                    fill={theme.palette.primary.main}
                  />
                </Button>
              </Grid>
            )}
            <Grid style={{ marginRight: 15, width: '32px' }} />
            <Grid className={classesLeitor.contentVisor}>
              <Typography className={classesLeitor.label}>
                Código da Comanda
              </Typography>
              <Typography variant="h6" color="primary" ref={valueRef}>
                Aguardando Digitação
              </Typography>
            </Grid>
            {butonBackspace}
          </Grid>
        </Grid>
        {teclado}
      </>
    );
  };

  const leituraRealizada = useCallback(
    (novoCod: string) => {
      confirmarComanda(digitos + novoCod);
    },
    [confirmarComanda, digitos]
  );

  const header = useMemo(() => {
    return (
      <Grid className={classes.header}>
        <LeitorComandaHeader />
      </Grid>
    );
  }, [classes.header]);

  const adicionarButton = useMemo(() => {
    return (
      <Grid item xs={12} className={classesLeitor.containerItemFooter}>
        <Button
          ref={btnRef}
          onClick={async () => {
            await handleConfime();
            resetText('');
          }}
          variant="contained"
          color="primary"
          fullWidth
          className={classNames(classesLeitor.textButton, 'round')}
        >
          Confirmar Comanda
        </Button>
      </Grid>
    );
  }, [
    classesLeitor.containerItemFooter,
    classesLeitor.textButton,
    handleConfime,
    resetText
  ]);

  return (
    <Grid
      className={classNames(
        classesLeitor.root,
        tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID
          ? classesLeitor.rootRfID
          : undefined
      )}
    >
      {header}
      {/* {possuiScanner && (
        <Scanner onScan={leituraRealizada} inicialOrSecundario={true} />
      )}
      {renderInserirCodigoManual()} */}
      {possuiScanner &&
        (!exibirDigitarCodigo &&
        tipoTrabalhoComanda !== EnumTipoTrabalhoComanda.APENAS_RFID ? (
          <>
            {escaneando && <RenderEscaneandoComanda />}
            <Scanner onScan={leituraRealizada} inicialOrSecundario={false} />
            <RenderFluxoInicialLeitor />
          </>
        ) : tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID ? (
          <EscaneandoComanda
            closeEscaneando={() => {
              goBack();
            }}
            confirmarComanda={confirmarComandaByRfId}
            erroExterno={erro}
            labelVoltar="Voltar"
            round
          />
        ) : (
          <>{renderInserirCodigoManual()}</>
        ))}

      {possuiScanner && exibirDigitarCodigo && (
        <>
          <Grid className={classesLeitor.containerFooter}>
            {adicionarButton}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LeitorComanda;
