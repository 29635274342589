import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    input: {
        maxWidth: 500,
        fontSize: 38
    },
    contentInput: {
        display: 'flex',
        justifyContent: 'center'
    },
    root: {
        '& input': {
            textAlign: 'center'
        }
    }
}))