import { KeyValueModel } from "model/api";

export const CfopMock: KeyValueModel[] = [
    new KeyValueModel("1101", '1101 - Compra p/ industrialização ou produção rural'),
    new KeyValueModel("1102", '1102 - Compra p/ comercialização'),
    new KeyValueModel("1111", '1111 - Compra p/ industrialização de mercadoria recebida anteriormente em consignação industrial'),
    new KeyValueModel("1113", '1113 - Compra p/ comercialização, de mercadoria recebida anteriormente em consignação mercantil'),
    new KeyValueModel("1116", '1116 - Compra p/ industrialização ou produção rural originada de encomenda p/ recebimento futuro'),
    new KeyValueModel("1117", '1117 - Compra p/ comercialização originada de encomenda p/ recebimento futuro'),
    new KeyValueModel("1118", '1118 - Compra de mercadoria p/ comercialização pelo adquirente originário, entregue pelo vendedor remetente ao destinatário, em venda à ordem.'),
    new KeyValueModel("1120", '1120 - Compra p/ industrialização, em venda à ordem, já recebida do vendedor remetente'),
    new KeyValueModel("1121", '1121 - Compra p/ comercialização, em venda à ordem, já recebida do vendedor remetente'),
    new KeyValueModel("1122", '1122 - Compra p/ industrialização em que a mercadoria foi remetida pelo fornecedor ao industrializador sem transitar pelo estabelecimento adquirente'),
    new KeyValueModel("1124", '1124 - Industrialização efetuada por outra empresa'),
    new KeyValueModel("1125", '1125 - Industrialização efetuada por outra empresa quando a mercadoria remetida p/ utilização no processo de industrialização não transitou pelo estabelecimento adquirente da mercadoria'),
    new KeyValueModel("1126", '1126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS'),
    new KeyValueModel("1128", '1128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("1131", '1131 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("1132", '1132 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("1135", '1135 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("1151", '1151 - Transferência p/ industrialização ou produção rural'),
    new KeyValueModel("1152", '1152 - Transferência p/ comercialização'),
    new KeyValueModel("1153", '1153 - Transferência de energia elétrica p/ distribuição'),
    new KeyValueModel("1154", '1154 - Transferência p/ utilização na prestação de serviço'),
    new KeyValueModel("1159", '1159 - Transferência p/ utilização na prestação de serviço'),
    new KeyValueModel("1201", '1201 - Devolução de venda de produção do estabelecimento'),
    new KeyValueModel("1202", '1202 - Devolução de venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("1203", '1203 - Devolução de venda de produção do estabelecimento, destinada à ZFM ou ALC'),
    new KeyValueModel("1204", '1204 - Devolução de venda de mercadoria adquirida ou recebida de terceiros, destinada à ZFM ou ALC'),
    new KeyValueModel("1205", '1205 - Anulação de valor relativo à prestação de serviço de comunicação'),
    new KeyValueModel("1206", '1206 - Anulação de valor relativo à prestação de serviço de transporte'),
    new KeyValueModel("1207", '1207 - Anulação de valor relativo à venda de energia elétrica'),
    new KeyValueModel("1208", '1208 - Devolução de produção do estabelecimento, remetida em transferência'),
    new KeyValueModel("1209", '1209 - Devolução de mercadoria adquirida ou recebida de terceiros, remetida em transferência'),
    new KeyValueModel("1212", '1212 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("1213", '1213 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("1214", '1214 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("1251", '1251 - Compra de energia elétrica p/ distribuição ou comercialização'),
    new KeyValueModel("1252", '1252 - Compra de energia elétrica por estabelecimento industrial'),
    new KeyValueModel("1253", '1253 - Compra de energia elétrica por estabelecimento comercial'),
    new KeyValueModel("1254", '1254 - Compra de energia elétrica por estabelecimento prestador de serviço de transporte'),
    new KeyValueModel("1255", '1255 - Compra de energia elétrica por estabelecimento prestador de serviço de comunicação'),
    new KeyValueModel("1256", '1256 - Compra de energia elétrica por estabelecimento de produtor rural'),
    new KeyValueModel("1257", '1257 - Compra de energia elétrica p/ consumo por demanda contratada'),
    new KeyValueModel("1301", '1301 - Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("1302", '1302 - Aquisição de serviço de comunicação por estabelecimento industrial'),
    new KeyValueModel("1303", '1303 - Aquisição de serviço de comunicação por estabelecimento comercial'),
    new KeyValueModel("1304", '1304 - Aquisição de serviço de comunicação por estabelecimento de prestador de serviço de transporte'),
    new KeyValueModel("1305", '1305 - Aquisição de serviço de comunicação por estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("1306", '1306 - Aquisição de serviço de comunicação por estabelecimento de produtor rural'),
    new KeyValueModel("1351", '1351 - Aquisição de serviço de transporte p/ execução de serviço da mesma natureza'),
    new KeyValueModel("1352", '1352 - Aquisição de serviço de transporte por estabelecimento industrial'),
    new KeyValueModel("1353", '1353 - Aquisição de serviço de transporte por estabelecimento comercial'),
    new KeyValueModel("1354", '1354 - Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação'),
    new KeyValueModel("1355", '1355 - Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("1356", '1356 - Aquisição de serviço de transporte por estabelecimento de produtor rural'),
    new KeyValueModel("1360", '1360 - Aquisição de serviço de transporte por contribuinte-substituto em relação ao serviço de transporte'),
    new KeyValueModel("1401", '1401 - Compra p/ industrialização ou produção rural de mercadoria sujeita a ST'),
    new KeyValueModel("1403", '1403 - Compra p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("1406", '1406 - Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita a ST'),
    new KeyValueModel("1407", '1407 - Compra de mercadoria p/ uso ou consumo cuja mercadoria está sujeita a ST'),
    new KeyValueModel("1408", '1408 - Transferência p/ industrialização ou produção rural de mercadoria sujeita a ST'),
    new KeyValueModel("1409", '1409 - Transferência p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("1410", '1410 - Devolução de venda de mercadoria, de produção do estabelecimento, sujeita a ST'),
    new KeyValueModel("1411", '1411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST'),
    new KeyValueModel("1414", '1414 - Retorno de mercadoria de produção do estabelecimento, remetida p/ venda fora do estabelecimento, sujeita a ST'),
    new KeyValueModel("1415", '1415 - Retorno de mercadoria adquirida ou recebida de terceiros, remetida p/ venda fora do estabelecimento em operação com mercadoria sujeita a ST'),
    new KeyValueModel("1451", '1451 - Retorno de animal do estabelecimento produtor'),
    new KeyValueModel("1452", '1452 - Retorno de insumo não utilizado na produção'),
    new KeyValueModel("1501", '1501 - Entrada de mercadoria recebida com fim específico de exportação'),
    new KeyValueModel("1503", '1503 - Entrada decorrente de devolução de produto, de fabricação do estabelecimento, remetido com fim específico de exportação'),
    new KeyValueModel("1504", '1504 - Entrada decorrente de devolução de mercadoria remetida com fim específico de exportação, adquirida ou recebida de terceiros'),
    new KeyValueModel("1505", '1505 - Entrada decorrente de devolução simbólica de mercadoria remetida p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.'),
    new KeyValueModel("1506", '1506 - Entrada decorrente de devolução simbólica de mercadoria, adquirida ou recebida de terceiros, remetida p/ formação de lote de exportação.'),
    new KeyValueModel("1551", '1551 - Compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("1552", '1552 - Transferência de bem do ativo imobilizado'),
    new KeyValueModel("1553", '1553 - Devolução de venda de bem do ativo imobilizado'),
    new KeyValueModel("1554", '1554 - Retorno de bem do ativo imobilizado remetido p/ uso fora do estabelecimento'),
    new KeyValueModel("1555", '1555 - Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso no estabelecimento'),
    new KeyValueModel("1556", '1556 - Compra de material p/ uso ou consumo'),
    new KeyValueModel("1557", '1557 - Transferência de material p/ uso ou consumo'),
    new KeyValueModel("1601", '1601 - Recebimento, por transferência, de crédito de ICMS'),
    new KeyValueModel("1602", '1602 - Recebimento, por transferência, de saldo credor do ICMS, de outro estabelecimento da mesma empresa, p/ compensação de saldo devedor do imposto.'),
    new KeyValueModel("1603", '1603 - Ressarcimento de ICMS retido por substituição tributária'),
    new KeyValueModel("1604", '1604 - Lançamento do crédito relativo à compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("1605", '1605 - Recebimento, por transferência, de saldo devedor do ICMS de outro estabelecimento da mesma empresa'),
    new KeyValueModel("1651", '1651 - Compra de combustível ou lubrificante p/ industrialização subseqüente'),
    new KeyValueModel("1652", '1652 - Compra de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("1653", '1653 - Compra de combustível ou lubrificante por consumidor ou usuário final'),
    new KeyValueModel("1658", '1658 - Transferência de combustível ou lubrificante p/ industrialização'),
    new KeyValueModel("1659", '1659 - Transferência de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("1660", '1660 - Devolução de venda de combustível ou lubrificante destinados à industrialização subseqüente'),
    new KeyValueModel("1661", '1661 - Devolução de venda de combustível ou lubrificante destinados à comercialização'),
    new KeyValueModel("1662", '1662 - Devolução de venda de combustível ou lubrificante destinados a consumidor ou usuário final'),
    new KeyValueModel("1663", '1663 - Entrada de combustível ou lubrificante p/ armazenagem'),
    new KeyValueModel("1664", '1664 - Retorno de combustível ou lubrificante remetidos p/ armazenagem'),
    new KeyValueModel("1901", '1901 - Entrada p/ industrialização por encomenda'),
    new KeyValueModel("1902", '1902 - Retorno de mercadoria remetida p/ industrialização por encomenda'),
    new KeyValueModel("1903", '1903 - Entrada de mercadoria remetida p/ industrialização e não aplicada no referido processo'),
    new KeyValueModel("1904", '1904 - Retorno de remessa p/ venda fora do estabelecimento'),
    new KeyValueModel("1905", '1905 - Entrada de mercadoria recebida p/ depósito em depósito fechado ou armazém geral'),
    new KeyValueModel("1906", '1906 - Retorno de mercadoria remetida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("1907", '1907 - Retorno simbólico de mercadoria remetida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("1908", '1908 - Entrada de bem por conta de contrato de comodato'),
    new KeyValueModel("1909", '1909 - Retorno de bem remetido por conta de contrato de comodato'),
    new KeyValueModel("1910", '1910 - Entrada de bonificação, doação ou brinde'),
    new KeyValueModel("1911", '1911 - Entrada de amostra grátis'),
    new KeyValueModel("1912", '1912 - Entrada de mercadoria ou bem recebido p/ demonstração'),
    new KeyValueModel("1913", '1913 - Retorno de mercadoria ou bem remetido p/ demonstração'),
    new KeyValueModel("1914", '1914 - Retorno de mercadoria ou bem remetido p/ exposição ou feira'),
    new KeyValueModel("1915", '1915 - Entrada de mercadoria ou bem recebido p/ conserto ou reparo'),
    new KeyValueModel("1916", '1916 - Retorno de mercadoria ou bem remetido p/ conserto ou reparo'),
    new KeyValueModel("1917", '1917 - Entrada de mercadoria recebida em consignação mercantil ou industrial'),
    new KeyValueModel("1918", '1918 - Devolução de mercadoria remetida em consignação mercantil ou industrial'),
    new KeyValueModel("1919", '1919 - Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, remetida anteriormente em consignação mercantil ou industrial'),
    new KeyValueModel("1920", '1920 - Entrada de vasilhame ou sacaria'),
    new KeyValueModel("1921", '1921 - Retorno de vasilhame ou sacaria'),
    new KeyValueModel("1922", '1922 - Lançamento efetuado a título de simples faturamento decorrente de compra p/ recebimento futuro'),
    new KeyValueModel("1923", '1923 - Entrada de mercadoria recebida do vendedor remetente, em venda à ordem'),
    new KeyValueModel("1924", '1924 - Entrada p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("1925", '1925 - Retorno de mercadoria remetida p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("1926", '1926 - Lançamento efetuado a título de reclassificação de mercadoria decorrente de formação de kit ou de sua desagregação'),
    new KeyValueModel("1931", '1931 - Lançamento efetuado pelo tomador do serviço de transporte, quando a responsabilidade de retenção do imposto for atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não-inscrito na UF onde se tenha iniciado o serviço.'),
    new KeyValueModel("1932", '1932 - Aquisição de serviço de transporte iniciado em UF diversa daquela onde esteja inscrito o prestador'),
    new KeyValueModel("1933", '1933 - Aquisição de serviço tributado pelo Imposto sobre Serviços de Qualquer Natureza'),
    new KeyValueModel("1934", '1934 - Entrada simbólica de mercadoria recebida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("1949", '1949 - Outra entrada de mercadoria ou prestação de serviço não especificada'),
    new KeyValueModel("2101", '2101 - Compra p/ industrialização ou produção rural'),
    new KeyValueModel("2102", '2102 - Compra p/ comercialização'),
    new KeyValueModel("2111", '2111 - Compra p/ industrialização de mercadoria recebida anteriormente em consignação industrial'),
    new KeyValueModel("2113", '2113 - Compra p/ comercialização, de mercadoria recebida anteriormente em consignação mercantil'),
    new KeyValueModel("2116", '2116 - Compra p/ industrialização ou produção rural originada de encomenda p/ recebimento futuro'),
    new KeyValueModel("2117", '2117 - Compra p/ comercialização originada de encomenda p/ recebimento futuro'),
    new KeyValueModel("2118", '2118 - Compra de mercadoria p/ comercialização pelo adquirente originário, entregue pelo vendedor remetente ao destinatário, em venda à ordem'),
    new KeyValueModel("2120", '2120 - Compra p/ industrialização, em venda à ordem, já recebida do vendedor remetente'),
    new KeyValueModel("2121", '2121 - Compra p/ comercialização, em venda à ordem, já recebida do vendedor remetente'),
    new KeyValueModel("2122", '2122 - Compra p/ industrialização em que a mercadoria foi remetida pelo fornecedor ao industrializador sem transitar pelo estabelecimento adquirente'),
    new KeyValueModel("2124", '2124 - Industrialização efetuada por outra empresa'),
    new KeyValueModel("2125", '2125 - Industrialização efetuada por outra empresa quando a mercadoria remetida p/ utilização no processo de industrialização não transitou pelo estabelecimento adquirente da mercadoria'),
    new KeyValueModel("2126", '2126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS'),
    new KeyValueModel("2128", '2128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("2131", '2131 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("2132", '2132 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("2135", '2135 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("2151", '2151 - Transferência p/ industrialização ou produção rural'),
    new KeyValueModel("2152", '2152 - Transferência p/ comercialização'),
    new KeyValueModel("2153", '2153 - Transferência de energia elétrica p/ distribuição'),
    new KeyValueModel("2154", '2154 - Transferência p/ utilização na prestação de serviço'),
    new KeyValueModel("2159", '2159 - Transferência p/ utilização na prestação de serviço'),
    new KeyValueModel("2201", '2201 - Devolução de venda de produção do estabelecimento'),
    new KeyValueModel("2202", '2202 - Devolução de venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("2203", '2203 - Devolução de venda de produção do estabelecimento destinada à ZFM ou ALC'),
    new KeyValueModel("2204", '2204 - Devolução de venda de mercadoria adquirida ou recebida de terceiros, destinada à ZFM ou ALC'),
    new KeyValueModel("2205", '2205 - Anulação de valor relativo à prestação de serviço de comunicação'),
    new KeyValueModel("2206", '2206 - Anulação de valor relativo à prestação de serviço de transporte'),
    new KeyValueModel("2207", '2207 - Anulação de valor relativo à venda de energia elétrica'),
    new KeyValueModel("2208", '2208 - Devolução de produção do estabelecimento, remetida em transferência.'),
    new KeyValueModel("2209", '2209 - Devolução de mercadoria adquirida ou recebida de terceiros e remetida em transferência'),
    new KeyValueModel("2212", '2212 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("2213", '2213 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("2214", '2214 - Devolução de venda no mercado interno de mercadoria industrializada e insumo importado sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("2251", '2251 - Compra de energia elétrica p/ distribuição ou comercialização'),
    new KeyValueModel("2252", '2252 - Compra de energia elétrica por estabelecimento industrial'),
    new KeyValueModel("2253", '2253 - Compra de energia elétrica por estabelecimento comercial'),
    new KeyValueModel("2254", '2254 - Compra de energia elétrica por estabelecimento prestador de serviço de transporte'),
    new KeyValueModel("2255", '2255 - Compra de energia elétrica por estabelecimento prestador de serviço de comunicação'),
    new KeyValueModel("2256", '2256 - Compra de energia elétrica por estabelecimento de produtor rural'),
    new KeyValueModel("2257", '2257 - Compra de energia elétrica p/ consumo por demanda contratada'),
    new KeyValueModel("2301", '2301 - Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("2302", '2302 - Aquisição de serviço de comunicação por estabelecimento industrial'),
    new KeyValueModel("2303", '2303 - Aquisição de serviço de comunicação por estabelecimento comercial'),
    new KeyValueModel("2304", '2304 - Aquisição de serviço de comunicação por estabelecimento de prestador de serviço de transporte'),
    new KeyValueModel("2305", '2305 - Aquisição de serviço de comunicação por estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("2306", '2306 - Aquisição de serviço de comunicação por estabelecimento de produtor rural'),
    new KeyValueModel("2351", '2351 - Aquisição de serviço de transporte p/ execução de serviço da mesma natureza'),
    new KeyValueModel("2352", '2352 - Aquisição de serviço de transporte por estabelecimento industrial'),
    new KeyValueModel("2353", '2353 - Aquisição de serviço de transporte por estabelecimento comercial'),
    new KeyValueModel("2354", '2354 - Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação'),
    new KeyValueModel("2355", '2355 - Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("2356", '2356 - Aquisição de serviço de transporte por estabelecimento de produtor rural'),
    new KeyValueModel("2401", '2401 - Compra p/ industrialização ou produção rural de mercadoria sujeita a ST'),
    new KeyValueModel("2403", '2403 - Compra p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("2406", '2406 - Compra de bem p/ o ativo imobilizado cuja mercadoria está sujeita a ST'),
    new KeyValueModel("2407", '2407 - Compra de mercadoria p/ uso ou consumo cuja mercadoria está sujeita a ST'),
    new KeyValueModel("2408", '2408 - Transferência p/ industrialização ou produção rural de mercadoria sujeita a ST'),
    new KeyValueModel("2409", '2409 - Transferência p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("2410", '2410 - Devolução de venda de produção do estabelecimento, quando o produto sujeito a ST'),
    new KeyValueModel("2411", '2411 - Devolução de venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST'),
    new KeyValueModel("2414", '2414 - Retorno de produção do estabelecimento, remetida p/ venda fora do estabelecimento, quando o produto sujeito a ST'),
    new KeyValueModel("2415", '2415 - Retorno de mercadoria adquirida ou recebida de terceiros, remetida p/ venda fora do estabelecimento em operação com mercadoria sujeita a ST'),
    new KeyValueModel("2501", '2501 - Entrada de mercadoria recebida com fim específico de exportação'),
    new KeyValueModel("2503", '2503 - Entrada decorrente de devolução de produto industrializado pelo estabelecimento, remetido com fim específico de exportação'),
    new KeyValueModel("2504", '2504 - Entrada decorrente de devolução de mercadoria remetida com fim específico de exportação, adquirida ou recebida de terceiros'),
    new KeyValueModel("2505", '2505 - Entrada decorrente de devolução simbólica de mercadoria remetida p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.'),
    new KeyValueModel("2506", '2506 - Entrada decorrente de devolução simbólica de mercadoria, adquirida ou recebida de terceiros, remetida p/ formação de lote de exportação.'),
    new KeyValueModel("2551", '2551 - Compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("2552", '2552 - Transferência de bem do ativo imobilizado'),
    new KeyValueModel("2553", '2553 - Devolução de venda de bem do ativo imobilizado'),
    new KeyValueModel("2554", '2554 - Retorno de bem do ativo imobilizado remetido p/ uso fora do estabelecimento'),
    new KeyValueModel("2555", '2555 - Entrada de bem do ativo imobilizado de terceiro, remetido p/ uso no estabelecimento'),
    new KeyValueModel("2556", '2556 - Compra de material p/ uso ou consumo'),
    new KeyValueModel("2557", '2557 - Transferência de material p/ uso ou consumo'),
    new KeyValueModel("2603", '2603 - Ressarcimento de ICMS retido por substituição tributária'),
    new KeyValueModel("2651", '2651 - Compra de combustível ou lubrificante p/ industrialização subseqüente'),
    new KeyValueModel("2652", '2652 - Compra de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("2652", '2652 - Compra de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("2653", '2653 - Compra de combustível ou lubrificante por consumidor ou usuário final'),
    new KeyValueModel("2658", '2658 - Transferência de combustível ou lubrificante p/ industrialização'),
    new KeyValueModel("2659", '2659 - Transferência de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("2660", '2660 - Devolução de venda de combustível ou lubrificante destinados à industrialização subseqüente'),
    new KeyValueModel("2661", '2661 - Devolução de venda de combustível ou lubrificante destinados à comercialização'),
    new KeyValueModel("2662", '2662 - Devolução de venda de combustível ou lubrificante destinados a consumidor ou usuário final'),
    new KeyValueModel("2663", '2663 - Entrada de combustível ou lubrificante p/ armazenagem'),
    new KeyValueModel("2664", '2664 - Retorno de combustível ou lubrificante remetidos p/ armazenagem'),
    new KeyValueModel("2901", '2901 - Entrada p/ industrialização por encomenda'),
    new KeyValueModel("2902", '2902 - Retorno de mercadoria remetida p/ industrialização por encomenda'),
    new KeyValueModel("2903", '2903 - Entrada de mercadoria remetida p/ industrialização e não aplicada no referido processo'),
    new KeyValueModel("2904", '2904 - Retorno de remessa p/ venda fora do estabelecimento'),
    new KeyValueModel("2905", '2905 - Entrada de mercadoria recebida p/ depósito em depósito fechado ou armazém geral'),
    new KeyValueModel("2906", '2906 - Retorno de mercadoria remetida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("2907", '2907 - Retorno simbólico de mercadoria remetida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("2908", '2908 - Entrada de bem por conta de contrato de comodato'),
    new KeyValueModel("2909", '2909 - Retorno de bem remetido por conta de contrato de comodato'),
    new KeyValueModel("2910", '2910 - Entrada de bonificação, doação ou brinde'),
    new KeyValueModel("2911", '2911 - Entrada de amostra grátis'),
    new KeyValueModel("2912", '2912 - Entrada de mercadoria ou bem recebido p/ demonstração'),
    new KeyValueModel("2913", '2913 - Retorno de mercadoria ou bem remetido p/ demonstração'),
    new KeyValueModel("2914", '2914 - Retorno de mercadoria ou bem remetido p/ exposição ou feira'),
    new KeyValueModel("2915", '2915 - Entrada de mercadoria ou bem recebido p/ conserto ou reparo'),
    new KeyValueModel("2916", '2916 - Retorno de mercadoria ou bem remetido p/ conserto ou reparo'),
    new KeyValueModel("2917", '2917 - Entrada de mercadoria recebida em consignação mercantil ou industrial'),
    new KeyValueModel("2918", '2918 - Devolução de mercadoria remetida em consignação mercantil ou industrial'),
    new KeyValueModel("2919", '2919 - Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, remetida anteriormente em consignação mercantil ou industrial'),
    new KeyValueModel("2920", '2920 - Entrada de vasilhame ou sacaria'),
    new KeyValueModel("2921", '2921 - Retorno de vasilhame ou sacaria'),
    new KeyValueModel("2922", '2922 - Lançamento efetuado a título de simples faturamento decorrente de compra p/ recebimento futuro'),
    new KeyValueModel("2923", '2923 - Entrada de mercadoria recebida do vendedor remetente, em venda à ordem'),
    new KeyValueModel("2924", '2924 - Entrada p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("2925", '2925 - Retorno de mercadoria remetida p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("2931", '2931 - Lançamento efetuado pelo tomador do serviço de transporte, quando a responsabilidade de retenção do imposto for atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não-inscrito na UF onde se tenha iniciado o serviço'),
    new KeyValueModel("2932", '2932 - Aquisição de serviço de transporte iniciado em UF diversa daquela onde esteja inscrito o prestador'),
    new KeyValueModel("2933", '2933 - Aquisição de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza'),
    new KeyValueModel("2934", '2934 - Entrada simbólica de mercadoria recebida p/ depósito fechado ou armazém geral'),
    new KeyValueModel("2949", '2949 - Outra entrada de mercadoria ou prestação de serviço não especificado'),
    new KeyValueModel("3101", '3101 - Compra p/ industrialização ou produção rural'),
    new KeyValueModel("3102", '3102 - Compra p/ comercialização'),
    new KeyValueModel("3126", '3126 - Compra p/ utilização na prestação de serviço sujeita ao ICMS'),
    new KeyValueModel("3127", '3127 - Compra p/ industrialização sob o regime de drawback'),
    new KeyValueModel("3128", '3128 - Compra p/ utilização na prestação de serviço sujeita ao ISSQN'),
    new KeyValueModel("3129", '3129 - Compra para industrialização sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("3201", '3201 - Devolução de venda de produção do estabelecimento'),
    new KeyValueModel("3202", '3202 - Devolução de venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("3205", '3205 - Anulação de valor relativo à prestação de serviço de comunicação'),
    new KeyValueModel("3206", '3206 - Anulação de valor relativo à prestação de serviço de transporte'),
    new KeyValueModel("3207", '3207 - Anulação de valor relativo à venda de energia elétrica'),
    new KeyValueModel("3211", '3211 - Devolução de venda de produção do estabelecimento sob o regime de drawback'),
    new KeyValueModel("3212", '3212 - Devolução de venda no mercado externo de mercadoria industrializada sob o Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("3251", '3251 - Compra de energia elétrica p/ distribuição ou comercialização'),
    new KeyValueModel("3301", '3301 - Aquisição de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("3351", '3351 - Aquisição de serviço de transporte p/ execução de serviço da mesma natureza'),
    new KeyValueModel("3352", '3352 - Aquisição de serviço de transporte por estabelecimento industrial'),
    new KeyValueModel("3353", '3353 - Aquisição de serviço de transporte por estabelecimento comercial'),
    new KeyValueModel("3354", '3354 - Aquisição de serviço de transporte por estabelecimento de prestador de serviço de comunicação'),
    new KeyValueModel("3355", '3355 - Aquisição de serviço de transporte por estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("3356", '3356 - Aquisição de serviço de transporte por estabelecimento de produtor rural'),
    new KeyValueModel("3503", '3503 - Devolução de mercadoria exportada que tenha sido recebida com fim específico de exportação'),
    new KeyValueModel("3551", '3551 - Compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("3553", '3553 - Devolução de venda de bem do ativo imobilizado'),
    new KeyValueModel("3556", '3556 - Compra de material p/ uso ou consumo'),
    new KeyValueModel("3651", '3651 - Compra de combustível ou lubrificante p/ industrialização subseqüente'),
    new KeyValueModel("3652", '3652 - Compra de combustível ou lubrificante p/ comercialização'),
    new KeyValueModel("3653", '3653 - Compra de combustível ou lubrificante por consumidor ou usuário final'),
    new KeyValueModel("3930", '3930 - Lançamento efetuado a título de entrada de bem sob amparo de regime especial aduaneiro de admissão temporária'),
    new KeyValueModel("3949", '3949 - Outra entrada de mercadoria ou prestação de serviço não especificado'),
    new KeyValueModel("5101", '5101 - Venda de produção do estabelecimento'),
    new KeyValueModel("5102", '5102 - Venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("5103", '5103 - Venda de produção do estabelecimento efetuada fora do estabelecimento'),
    new KeyValueModel("5104", '5104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento'),
    new KeyValueModel("5105", '5105 - Venda de produção do estabelecimento que não deva por ele transitar'),
    new KeyValueModel("5106", '5106 - Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("5109", '5109 - Venda de produção do estabelecimento destinada à ZFM ou ALC'),
    new KeyValueModel("5110", '5110 - Venda de mercadoria, adquirida ou recebida de terceiros, destinada à ZFM ou ALC'),
    new KeyValueModel("5111", '5111 - Venda de produção do estabelecimento remetida anteriormente em consignação industrial'),
    new KeyValueModel("5112", '5112 - Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação industrial'),
    new KeyValueModel("5113", '5113 - Venda de produção do estabelecimento remetida anteriormente em consignação mercantil'),
    new KeyValueModel("5114", '5114 - Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil'),
    new KeyValueModel("5115", '5115 - Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil'),
    new KeyValueModel("5116", '5116 - Venda de produção do estabelecimento originada de encomenda p/ entrega futura'),
    new KeyValueModel("5117", '5117 - Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda p/ entrega futura'),
    new KeyValueModel("5118", '5118 - Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem'),
    new KeyValueModel("5119", '5119 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem'),
    new KeyValueModel("5120", '5120 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem'),
    new KeyValueModel("5122", '5122 - Venda de produção do estabelecimento remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("5123", '5123 - Venda de mercadoria adquirida ou recebida de terceiros remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("5124", '5124 - Industrialização efetuada p/ outra empresa'),
    new KeyValueModel("5125", '5125 - Industrialização efetuada p/ outra empresa quando a mercadoria recebida p/ utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria'),
    new KeyValueModel("5129", '5129 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("5131", '5131 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("5132", '5132 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("5151", '5151 - '),
    new KeyValueModel("5152", '5152 - Transferência de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("5153", '5153 - Transferência de energia elétrica'),
    new KeyValueModel("5155", '5155 - Transferência de produção do estabelecimento, que não deva por ele transitar'),
    new KeyValueModel("5156", '5156 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("5159", '5159 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("5160", '5160 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("5201", '5201 - Devolução de compra p/ industrialização ou produção rural'),
    new KeyValueModel("5202", '5202 - Devolução de compra p/ comercialização'),
    new KeyValueModel("5205", '5205 - Anulação de valor relativo a aquisição de serviço de comunicação'),
    new KeyValueModel("5206", '5206 - Anulação de valor relativo a aquisição de serviço de transporte'),
    new KeyValueModel("5207", '5207 - Anulação de valor relativo à compra de energia elétrica'),
    new KeyValueModel("5208", '5208 - Devolução de mercadoria recebida em transferência p/ industrialização ou produção rural'),
    new KeyValueModel("5209", '5209 - Devolução de mercadoria recebida em transferência p/ comercialização'),
    new KeyValueModel("5210", '5210 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("5213", '5213 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("5214", '5214 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("5215", '5215 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("5251", '5251 - Venda de energia elétrica p/ distribuição ou comercialização'),
    new KeyValueModel("5252", '5252 - Venda de energia elétrica p/ estabelecimento industrial'),
    new KeyValueModel("5253", '5253 - Venda de energia elétrica p/ estabelecimento comercial'),
    new KeyValueModel("5254", '5254 - Venda de energia elétrica p/ estabelecimento prestador de serviço de transporte'),
    new KeyValueModel("5255", '5255 - Venda de energia elétrica p/ estabelecimento prestador de serviço de comunicação'),
    new KeyValueModel("5256", '5256 - Venda de energia elétrica p/ estabelecimento de produtor rural'),
    new KeyValueModel("5257", '5257 - Venda de energia elétrica p/ consumo por demanda contratada'),
    new KeyValueModel("5258", '5258 - Venda de energia elétrica a não contribuinte'),
    new KeyValueModel("5301", '5301 - Prestação de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("5302", '5302 - Prestação de serviço de comunicação a estabelecimento industrial'),
    new KeyValueModel("5303", '5303 - Prestação de serviço de comunicação a estabelecimento comercial'),
    new KeyValueModel("5304", '5304 - Prestação de serviço de comunicação a estabelecimento de prestador de serviço de transporte'),
    new KeyValueModel("5305", '5305 - Prestação de serviço de comunicação a estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("5306", '5306 - Prestação de serviço de comunicação a estabelecimento de produtor rural'),
    new KeyValueModel("5307", '5307 - Prestação de serviço de comunicação a não contribuinte'),
    new KeyValueModel("5351", '5351 - Prestação de serviço de transporte p/ execução de serviço da mesma natureza'),
    new KeyValueModel("5352", '5352 - Prestação de serviço de transporte a estabelecimento industrial'),
    new KeyValueModel("5353", '5353 - Prestação de serviço de transporte a estabelecimento comercial'),
    new KeyValueModel("5354", '5354 - Prestação de serviço de transporte a estabelecimento de prestador de serviço de comunicação'),
    new KeyValueModel("5355", '5355 - Prestação de serviço de transporte a estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("5356", '5356 - Prestação de serviço de transporte a estabelecimento de produtor rural'),
    new KeyValueModel("5357", '5357 - Prestação de serviço de transporte a não contribuinte'),
    new KeyValueModel("5359", '5359 - Prestação de serviço de transporte a contribuinte ou a não-contribuinte, quando a mercadoria transportada esteja dispensada de emissão de Nota Fiscal'),
    new KeyValueModel("5360", '5360 - Prestação de serviço de transporte a contribuinte-substituto em relação ao serviço de transporte'),
    new KeyValueModel("5401", '5401 - Venda de produção do estabelecimento quando o produto esteja sujeito a ST'),
    new KeyValueModel("5402", '5402 - Venda de produção do estabelecimento de produto sujeito a ST, em operação entre contribuintes substitutos do mesmo produto'),
    new KeyValueModel("5403", '5403 - Venda de mercadoria, adquirida ou recebida de terceiros, sujeita a ST, na condição de contribuinte-substituto'),
    new KeyValueModel("5405", '5405 - Venda de mercadoria, adquirida ou recebida de terceiros, sujeita a ST, na condição de contribuinte-substituído'),
    new KeyValueModel("5408", '5408 - Transferência de produção do estabelecimento quando o produto sujeito a ST'),
    new KeyValueModel("5409", '5409 - Transferência de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST'),
    new KeyValueModel("5410", '5410 - Devolução de compra p/ industrialização de mercadoria sujeita a ST'),
    new KeyValueModel("5411", '5411 - Devolução de compra p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("5412", '5412 - Devolução de bem do ativo imobilizado, em operação com mercadoria sujeita a ST'),
    new KeyValueModel("5413", '5413 - Devolução de mercadoria destinada ao uso ou consumo, em operação com mercadoria sujeita a ST.'),
    new KeyValueModel("5414", '5414 - Remessa de produção do estabelecimento p/ venda fora do estabelecimento, quando o produto sujeito a ST'),
    new KeyValueModel("5415", '5415 - Remessa de mercadoria adquirida ou recebida de terceiros p/ venda fora do estabelecimento, em operação com mercadoria sujeita a ST'),
    new KeyValueModel("5451", '5451 - Remessa de animal e de insumo p/ estabelecimento produtor'),
    new KeyValueModel("5501", '5501 - Remessa de produção do estabelecimento, com fim específico de exportação'),
    new KeyValueModel("5502", '5502 - Remessa de mercadoria adquirida ou recebida de terceiros, com fim específico de exportação'),
    new KeyValueModel("5503", '5503 - Devolução de mercadoria recebida com fim específico de exportação'),
    new KeyValueModel("5504", '5504 - Remessa de mercadoria p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.'),
    new KeyValueModel("5505", '5505 - Remessa de mercadoria, adquirida ou recebida de terceiros, p/ formação de lote de exportação.'),
    new KeyValueModel("5551", '5551 - Venda de bem do ativo imobilizado'),
    new KeyValueModel("5552", '5552 - Transferência de bem do ativo imobilizado'),
    new KeyValueModel("5553", '5553 - Devolução de compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("5554", '5554 - Remessa de bem do ativo imobilizado p/ uso fora do estabelecimento'),
    new KeyValueModel("5555", '5555 - Devolução de bem do ativo imobilizado de terceiro, recebido p/ uso no estabelecimento'),
    new KeyValueModel("5556", '5556 - Devolução de compra de material de uso ou consumo'),
    new KeyValueModel("5557", '5557 - Transferência de material de uso ou consumo'),
    new KeyValueModel("5601", '5601 - Transferência de crédito de ICMS acumulado'),
    new KeyValueModel("5602", '5602 - Transferência de saldo credor do ICMS, p/ outro estabelecimento da mesma empresa, destinado à compensação de saldo devedor do ICMS'),
    new KeyValueModel("5603", '5603 - Ressarcimento de ICMS retido por substituição tributária'),
    new KeyValueModel("5605", '5605 - Transferência de saldo devedor do ICMS de outro estabelecimento da mesma empresa'),
    new KeyValueModel("5606", '5606 - Utilização de saldo credor do ICMS p/ extinção por compensação de débitos fiscais'),
    new KeyValueModel("5651", '5651 - Venda de combustível ou lubrificante de produção do estabelecimento destinados à industrialização subseqüente'),
    new KeyValueModel("5652", '5652 - Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à comercialização'),
    new KeyValueModel("5653", '5653 - Venda de combustível ou lubrificante, de produção do estabelecimento, destinados a consumidor ou usuário final'),
    new KeyValueModel("5654", '5654 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à industrialização subseqüente'),
    new KeyValueModel("5655", '5655 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à comercialização'),
    new KeyValueModel("5656", '5656 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados a consumidor ou usuário final'),
    new KeyValueModel("5657", '5657 - Remessa de combustível ou lubrificante, adquiridos ou recebidos de terceiros, p/ venda fora do estabelecimento'),
    new KeyValueModel("5658", '5658 - Transferência de combustível ou lubrificante de produção do estabelecimento'),
    new KeyValueModel("5659", '5659 - Transferência de combustível ou lubrificante adquiridos ou recebidos de terceiros'),
    new KeyValueModel("5660", '5660 - Devolução de compra de combustível ou lubrificante adquiridos p/ industrialização subseqüente'),
    new KeyValueModel("5661", '5661 - Devolução de compra de combustível ou lubrificante adquiridos p/ comercialização'),
    new KeyValueModel("5662", '5662 - Devolução de compra de combustível ou lubrificante adquiridos por consumidor ou usuário final'),
    new KeyValueModel("5663", '5663 - Remessa p/ armazenagem de combustível ou lubrificante'),
    new KeyValueModel("5664", '5664 - Retorno de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("5665", '5665 - Retorno simbólico de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("5666", '5666 - Remessa, por conta e ordem de terceiros, de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("5667", '5667 - Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra UF'),
    new KeyValueModel("5901", '5901 - Remessa p/ industrialização por encomenda'),
    new KeyValueModel("5902", '5902 - Retorno de mercadoria utilizada na industrialização por encomenda'),
    new KeyValueModel("5903", '5903 - Retorno de mercadoria recebida p/ industrialização e não aplicada no referido processo'),
    new KeyValueModel("5904", '5904 - Remessa p/ venda fora do estabelecimento'),
    new KeyValueModel("5905", '5905 - Remessa p/ depósito fechado ou armazém geral'),
    new KeyValueModel("5906", '5906 - Retorno de mercadoria depositada em depósito fechado ou armazém geral'),
    new KeyValueModel("5907", '5907 - Retorno simbólico de mercadoria depositada em depósito fechado ou armazém geral'),
    new KeyValueModel("5908", '5908 - Remessa de bem por conta de contrato de comodato'),
    new KeyValueModel("5909", '5909 - Retorno de bem recebido por conta de contrato de comodato'),
    new KeyValueModel("5910", '5910 - Remessa em bonificação, doação ou brinde'),
    new KeyValueModel("5911", '5911 - Remessa de amostra grátis'),
    new KeyValueModel("5912", '5912 - Remessa de mercadoria ou bem p/ demonstração'),
    new KeyValueModel("5913", '5913 - Retorno de mercadoria ou bem recebido p/ demonstração'),
    new KeyValueModel("5914", '5914 - Remessa de mercadoria ou bem p/ exposição ou feira'),
    new KeyValueModel("5915", '5915 - Remessa de mercadoria ou bem p/ conserto ou reparo'),
    new KeyValueModel("5916", '5916 - Retorno de mercadoria ou bem recebido p/ conserto ou reparo'),
    new KeyValueModel("5917", '5917 - Remessa de mercadoria em consignação mercantil ou industrial'),
    new KeyValueModel("5918", '5918 - Devolução de mercadoria recebida em consignação mercantil ou industrial'),
    new KeyValueModel("5919", '5919 - Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, recebida anteriormente em consignação mercantil ou industrial'),
    new KeyValueModel("5920", '5920 - Remessa de vasilhame ou sacaria'),
    new KeyValueModel("5921", '5921 - Devolução de vasilhame ou sacaria'),
    new KeyValueModel("5922", '5922 - Lançamento efetuado a título de simples faturamento decorrente de venda p/ entrega futura'),
    new KeyValueModel("5923", '5923 - Remessa de mercadoria por conta e ordem de terceiros, em venda à ordem ou em operações com armazém geral ou depósito fechado.'),
    new KeyValueModel("5924", '5924 - Remessa p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("5925", '5925 - Retorno de mercadoria recebida p/ industrialização por conta e ordem do adquirente da mercadoria, quando aquela não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("5926", '5926 - Lançamento efetuado a título de reclassificação de mercadoria decorrente de formação de kit ou de sua desagregação'),
    new KeyValueModel("5927", '5927 - Lançamento efetuado a título de baixa de estoque decorrente de perda, roubo ou deterioração'),
    new KeyValueModel("5928", '5928 - Lançamento efetuado a título de baixa de estoque decorrente do encerramento da atividade da empresa'),
    new KeyValueModel("5929", '5929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal – ECF'),
    new KeyValueModel("5931", '5931 - Lançamento efetuado em decorrência da responsabilidade de retenção do imposto por substituição tributária, atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não inscrito na UF onde iniciado o serviço'),
    new KeyValueModel("5932", '5932 - Prestação de serviço de transporte iniciada em UF diversa daquela onde inscrito o prestador'),
    new KeyValueModel("5933", '5933 - Prestação de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza'),
    new KeyValueModel("5934", '5934 - Remessa simbólica de mercadoria depositada em armazém geral ou depósito fechado.'),
    new KeyValueModel("5949", '5949 - Outra saída de mercadoria ou prestação de serviço não especificado'),
    new KeyValueModel("6101", '6101 - Venda de produção do estabelecimento'),
    new KeyValueModel("6102", '6102 - Venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("6103", '6103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento'),
    new KeyValueModel("6104", '6104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento'),
    new KeyValueModel("6105", '6105 - Venda de produção do estabelecimento que não deva por ele transitar'),
    new KeyValueModel("6106", '6106 - Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("6107", '6107 - Venda de produção do estabelecimento, destinada a não contribuinte'),
    new KeyValueModel("6108", '6108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte'),
    new KeyValueModel("6109", '6109 - Venda de produção do estabelecimento destinada à ZFM ou ALC'),
    new KeyValueModel("6110", '6110 - Venda de mercadoria, adquirida ou recebida de terceiros, destinada à ZFM ou ALC'),
    new KeyValueModel("6111", '6111 - Venda de produção do estabelecimento remetida anteriormente em consignação industrial'),
    new KeyValueModel("6112", '6112 - Venda de mercadoria adquirida ou recebida de Terceiros remetida anteriormente em consignação industrial'),
    new KeyValueModel("6113", '6113 - Venda de produção do estabelecimento remetida anteriormente em consignação mercantil'),
    new KeyValueModel("6114", '6114 - Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil'),
    new KeyValueModel("6115", '6115 - Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil'),
    new KeyValueModel("6116", '6116 - Venda de produção do estabelecimento originada de encomenda p/ entrega futura'),
    new KeyValueModel("6117", '6117 - Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda p/ entrega futura'),
    new KeyValueModel("6118", '6118 - Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem'),
    new KeyValueModel("6119", '6119 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem'),
    new KeyValueModel("6120", '6120 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente, em venda à ordem'),
    new KeyValueModel("6122", '6122 - Venda de produção do estabelecimento remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("6123", '6123 - Venda de mercadoria adquirida ou recebida de terceiros remetida p/ industrialização, por conta e ordem do adquirente, sem transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("6124", '6124 - Industrialização efetuada p/ outra empresa'),
    new KeyValueModel("6125", '6125 - Industrialização efetuada p/ outra empresa quando a mercadoria recebida p/ utilização no processo de industrialização não transitar pelo estabelecimento adquirente da mercadoria'),
    new KeyValueModel("6129", '6129 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("6131", '6131 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("6132", '6132 - Venda de insumo importado e de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("6151", '6151 - Transferência de produção do estabelecimento'),
    new KeyValueModel("6152", '6152 - Transferência de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("6153", '6153 - Transferência de energia elétrica'),
    new KeyValueModel("6155", '6155 - Transferência de produção do estabelecimento, que não deva por ele transitar'),
    new KeyValueModel("6156", '6156 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("6159", '6159 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("6160", '6160 - Transferência de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("6201", '6201 - Devolução de compra p/ industrialização ou produção rural'),
    new KeyValueModel("6202", '6202 - Devolução de compra p/ comercialização'),
    new KeyValueModel("6205", '6205 - Anulação de valor relativo a aquisição de serviço de comunicação'),
    new KeyValueModel("6206", '6206 - Anulação de valor relativo a aquisição de serviço de transporte'),
    new KeyValueModel("6207", '6207 - Anulação de valor relativo à compra de energia elétrica'),
    new KeyValueModel("6208", '6208 - Devolução de mercadoria recebida em transferência p/ industrialização ou produção rural'),
    new KeyValueModel("6209", '6209 - Devolução de mercadoria recebida em transferência p/ comercialização'),
    new KeyValueModel("6210", '6210 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("6213", '6213 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("6214", '6214 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("6215", '6215 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("6251", '6251 - Venda de energia elétrica p/ distribuição ou comercialização'),
    new KeyValueModel("6252", '6252 - Venda de energia elétrica p/ estabelecimento industrial'),
    new KeyValueModel("6253", '6253 - Venda de energia elétrica p/ estabelecimento comercial'),
    new KeyValueModel("6254", '6254 - Venda de energia elétrica p/ estabelecimento prestador de serviço de transporte'),
    new KeyValueModel("6255", '6255 - Venda de energia elétrica p/ estabelecimento prestador de serviço de comunicação'),
    new KeyValueModel("6256", '6256 - Venda de energia elétrica p/ estabelecimento de produtor rural'),
    new KeyValueModel("6257", '6257 - Venda de energia elétrica p/ consumo por demanda contratada'),
    new KeyValueModel("6258", '6258 - Venda de energia elétrica a não contribuinte'),
    new KeyValueModel("6301", '6301 - Prestação de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("6302", '6302 - Prestação de serviço de comunicação a estabelecimento industrial'),
    new KeyValueModel("6303", '6303 - Prestação de serviço de comunicação a estabelecimento comercial'),
    new KeyValueModel("6304", '6304 - Prestação de serviço de comunicação a estabelecimento de prestador de serviço de transporte'),
    new KeyValueModel("6305", '6305 - Prestação de serviço de comunicação a estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("6306", '6306 - Prestação de serviço de comunicação a estabelecimento de produtor rural'),
    new KeyValueModel("6307", '6307 - Prestação de serviço de comunicação a não contribuinte'),
    new KeyValueModel("6351", '6351 - Prestação de serviço de transporte p/ execução de serviço da mesma natureza'),
    new KeyValueModel("6352", '6352 - Prestação de serviço de transporte a estabelecimento industrial'),
    new KeyValueModel("6353", '6353 - Prestação de serviço de transporte a estabelecimento comercial'),
    new KeyValueModel("6354", '6354 - Prestação de serviço de transporte a estabelecimento de prestador de serviço de comunicação'),
    new KeyValueModel("6355", '6355 - Prestação de serviço de transporte a estabelecimento de geradora ou de distribuidora de energia elétrica'),
    new KeyValueModel("6356", '6356 - Prestação de serviço de transporte a estabelecimento de produtor rural'),
    new KeyValueModel("6357", '6357 - Prestação de serviço de transporte a não contribuinte'),
    new KeyValueModel("6359", '6359 - Prestação de serviço de transporte a contribuinte ou a não-contribuinte, quando a mercadoria transportada esteja dispensada de emissão de Nota Fiscal'),
    new KeyValueModel("6360", '6360 - Prestação de serviço de transporte a contribuinte substituto em relação ao serviço de transporte'),
    new KeyValueModel("6401", '6401 - Venda de produção do estabelecimento quando o produto sujeito a ST'),
    new KeyValueModel("6402", '6402 - Venda de produção do estabelecimento de produto sujeito a ST, em operação entre contribuintes substitutos do mesmo produto'),
    new KeyValueModel("6403", '6403 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita a ST, na condição de contribuinte substituto'),
    new KeyValueModel("6404", '6404 - Venda de mercadoria sujeita a ST, cujo imposto já tenha sido retido anteriormente'),
    new KeyValueModel("6408", '6408 - Transferência de produção do estabelecimento quando o produto sujeito a ST'),
    new KeyValueModel("6409", '6409 - Transferência de mercadoria adquirida ou recebida de terceiros, sujeita a ST'),
    new KeyValueModel("6410", '6410 - Devolução de compra p/ industrialização ou ptrodução rural quando a mercadoria sujeita a ST'),
    new KeyValueModel("6411", '6411 - Devolução de compra p/ comercialização em operação com mercadoria sujeita a ST'),
    new KeyValueModel("6412", '6412 - Devolução de bem do ativo imobilizado, em operação com mercadoria sujeita a ST'),
    new KeyValueModel("6413", '6413 - Devolução de mercadoria destinada ao uso ou consumo, em operação com mercadoria sujeita a ST'),
    new KeyValueModel("6414", '6414 - Remessa de produção do estabelecimento p/ venda fora do estabelecimento, quando o produto sujeito a ST'),
    new KeyValueModel("6415", '6415 - Remessa de mercadoria adquirida ou recebida de terceiros p/ venda fora do estabelecimento, quando a referida ração com mercadoria sujeita a ST'),
    new KeyValueModel("6501", '6501 - Remessa de produção do estabelecimento, com fim específico de exportação'),
    new KeyValueModel("6502", '6502 - Remessa de mercadoria adquirida ou recebida de terceiros, com fim específico de exportação'),
    new KeyValueModel("6503", '6503 - Devolução de mercadoria recebida com fim específico de exportação'),
    new KeyValueModel("6504", '6504 - Remessa de mercadoria p/ formação de lote de exportação, de produto industrializado ou produzido pelo próprio estabelecimento.'),
    new KeyValueModel("6505", '6505 - Remessa de mercadoria, adquirida ou recebida de terceiros, p/ formação de lote de exportação.'),
    new KeyValueModel("6551", '6551 - Venda de bem do ativo imobilizado'),
    new KeyValueModel("6552", '6552 - Transferência de bem do ativo imobilizado'),
    new KeyValueModel("6553", '6553 - Devolução de compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("6554", '6554 - Remessa de bem do ativo imobilizado p/ uso fora do estabelecimento'),
    new KeyValueModel("6555", '6555 - Devolução de bem do ativo imobilizado de terceiro, recebido p/ uso no estabelecimento'),
    new KeyValueModel("6556", '6556 - Devolução de compra de material de uso ou consumo'),
    new KeyValueModel("6557", '6557 - Transferência de material de uso ou consumo'),
    new KeyValueModel("6603", '6603 - Ressarcimento de ICMS retido por substituição tributária'),
    new KeyValueModel("6651", '6651 - Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à industrialização subseqüente'),
    new KeyValueModel("6652", '6652 - Venda de combustível ou lubrificante, de produção do estabelecimento, destinados à comercialização'),
    new KeyValueModel("6653", '6653 - Venda de combustível ou lubrificante, de produção do estabelecimento, destinados a consumidor ou usuário final'),
    new KeyValueModel("6654", '6654 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à industrialização subseqüente'),
    new KeyValueModel("6655", '6655 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados à comercialização'),
    new KeyValueModel("6656", '6656 - Venda de combustível ou lubrificante, adquiridos ou recebidos de terceiros, destinados a consumidor ou usuário final'),
    new KeyValueModel("6657", '6657 - Remessa de combustível ou lubrificante, adquiridos ou recebidos de terceiros, p/ venda fora do estabelecimento'),
    new KeyValueModel("6658", '6658 - Transferência de combustível ou lubrificante de produção do estabelecimento'),
    new KeyValueModel("6659", '6659 - Transferência de combustível ou lubrificante adquiridos ou recebidos de terceiros'),
    new KeyValueModel("6660", '6660 - Devolução de compra de combustível ou lubrificante adquiridos p/ industrialização subseqüente'),
    new KeyValueModel("6661", '6661 - Devolução de compra de combustível ou lubrificante adquiridos p/ comercialização'),
    new KeyValueModel("6662", '6662 - Devolução de compra de combustível ou lubrificante adquiridos por consumidor ou usuário final'),
    new KeyValueModel("6663", '6663 - Remessa p/ armazenagem de combustível ou lubrificante'),
    new KeyValueModel("6664", '6664 - Retorno de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("6665", '6665 - Retorno simbólico de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("6666", '6666 - Remessa, por conta e ordem de terceiros, de combustível ou lubrificante recebidos p/ armazenagem'),
    new KeyValueModel("6667", '6667 - Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra UF diferente da que ocorrer o consumo'),
    new KeyValueModel("6901", '6901 - Remessa p/ industrialização por encomenda'),
    new KeyValueModel("6902", '6902 - Retorno de mercadoria utilizada na industrialização por encomenda'),
    new KeyValueModel("6903", '6903 - Retorno de mercadoria recebida p/ industrialização e não aplicada no referido processo'),
    new KeyValueModel("6904", '6904 - Remessa p/ venda fora do estabelecimento'),
    new KeyValueModel("6905", '6905 - Remessa p/ depósito fechado ou armazém geral'),
    new KeyValueModel("6906", '6906 - Retorno de mercadoria depositada em depósito fechado ou armazém geral'),
    new KeyValueModel("6907", '6907 - Retorno simbólico de mercadoria depositada em depósito fechado ou armazém geral'),
    new KeyValueModel("6908", '6908 - Remessa de bem por conta de contrato de comodato'),
    new KeyValueModel("6909", '6909 - Retorno de bem recebido por conta de contrato de comodato'),
    new KeyValueModel("6910", '6910 - Remessa em bonificação, doação ou brinde'),
    new KeyValueModel("6911", '6911 - Remessa de amostra grátis'),
    new KeyValueModel("6912", '6912 - Remessa de mercadoria ou bem p/ demonstração'),
    new KeyValueModel("6913", '6913 - Retorno de mercadoria ou bem recebido p/ demonstração'),
    new KeyValueModel("6914", '6914 - Remessa de mercadoria ou bem p/ exposição ou feira'),
    new KeyValueModel("6915", '6915 - Remessa de mercadoria ou bem p/ conserto ou reparo'),
    new KeyValueModel("6916", '6916 - Retorno de mercadoria ou bem recebido p/ conserto ou reparo'),
    new KeyValueModel("6917", '6917 - Remessa de mercadoria em consignação mercantil ou industrial'),
    new KeyValueModel("6918", '6918 - Devolução de mercadoria recebida em consignação mercantil ou industrial'),
    new KeyValueModel("6919", '6919 - Devolução simbólica de mercadoria vendida ou utilizada em processo industrial, recebida anteriormente em consignação mercantil ou industrial'),
    new KeyValueModel("6920", '6920 - Remessa de vasilhame ou sacaria'),
    new KeyValueModel("6921", '6921 - Devolução de vasilhame ou sacaria'),
    new KeyValueModel("6922", '6922 - Lançamento efetuado a título de simples faturamento decorrente de venda p/ entrega futura'),
    new KeyValueModel("6923", '6923 - Remessa de mercadoria por conta e ordem de terceiros, em venda à ordem ou em operações com armazém geral ou depósito fechado'),
    new KeyValueModel("6924", '6924 - Remessa p/ industrialização por conta e ordem do adquirente da mercadoria, quando esta não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("6925", '6925 - Retorno de mercadoria recebida p/ industrialização por conta e ordem do adquirente da mercadoria, quando aquela não transitar pelo estabelecimento do adquirente'),
    new KeyValueModel("6929", '6929 - Lançamento efetuado em decorrência de emissão de documento fiscal relativo a operação ou prestação também registrada em equipamento Emissor de Cupom Fiscal – ECF'),
    new KeyValueModel("6931", '6931 - Lançamento efetuado em decorrência da responsabilidade de retenção do imposto por substituição tributária, atribuída ao remetente ou alienante da mercadoria, pelo serviço de transporte realizado por transportador autônomo ou por transportador não inscrito na UF onde iniciado o serviço'),
    new KeyValueModel("6932", '6932 - Prestação de serviço de transporte iniciada em UF diversa daquela onde inscrito o prestador'),
    new KeyValueModel("6933", '6933 - Prestação de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza'),
    new KeyValueModel("6934", '6934 - Remessa simbólica de mercadoria depositada em armazém geral ou depósito fechado'),
    new KeyValueModel("6949", '6949 - Outra saída de mercadoria ou prestação de serviço não especificado'),
    new KeyValueModel("7101", '7101 - Venda de produção do estabelecimento'),
    new KeyValueModel("7102", '7102 - Venda de mercadoria adquirida ou recebida de terceiros'),
    new KeyValueModel("7105", '7105 - Venda de produção do estabelecimento, que não deva por ele transitar'),
    new KeyValueModel("7106", '7106 - Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar'),
    new KeyValueModel("7127", '7127 - Venda de produção do estabelecimento sob o regime de drawback'),
    new KeyValueModel("7129", '7129 - Venda de produção do estabelecimento ao mercado externo de mercadoria industrializada sob o amparo do Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("7201", '7201 - Devolução de compra p/ industrialização ou produção rural'),
    new KeyValueModel("7202", '7202 - Devolução de compra p/ comercialização'),
    new KeyValueModel("7205", '7205 - Anulação de valor relativo à aquisição de serviço de comunicação'),
    new KeyValueModel("7206", '7206 - Anulação de valor relativo a aquisição de serviço de transporte'),
    new KeyValueModel("7207", '7207 - Anulação de valor relativo à compra de energia elétrica'),
    new KeyValueModel("7210", '7210 - Devolução de compra p/ utilização na prestação de serviço'),
    new KeyValueModel("7211", '7211 - Devolução de compras p/ industrialização sob o regime de drawback'),
    new KeyValueModel("7212", '7212 - Devolução de compras para industrialização sob o regime de Regime Aduaneiro Especial de Entreposto Industrial (Recof-Sped)'),
    new KeyValueModel("7251", '7251 - Venda de energia elétrica p/ o exterior'),
    new KeyValueModel("7301", '7301 - Prestação de serviço de comunicação p/ execução de serviço da mesma natureza'),
    new KeyValueModel("7358", '7358 - Prestação de serviço de transporte'),
    new KeyValueModel("7501", '7501 - Exportação de mercadorias recebidas com fim específico de exportação'),
    new KeyValueModel("7504", '7504 - Exportação de mercadoria que foi objeto de formação de lote de exportação'),
    new KeyValueModel("7551", '7551 - Venda de bem do ativo imobilizado'),
    new KeyValueModel("7553", '7553 - Devolução de compra de bem p/ o ativo imobilizado'),
    new KeyValueModel("7556", '7556 - Devolução de compra de material de uso ou consumo'),
    new KeyValueModel("7651", '7651 - Venda de combustível ou lubrificante de produção do estabelecimento'),
    new KeyValueModel("7654", '7654 - Venda de combustível ou lubrificante adquiridos ou recebidos de terceiros'),
    new KeyValueModel("7667", '7667 - Venda de combustível ou lubrificante a consumidor ou usuário final'),
    new KeyValueModel("7930", '7930 - Lançamento efetuado a título de devolução de bem cuja entrada tenha ocorrido sob amparo de regime especial aduaneiro de admissão temporária'),
    new KeyValueModel("7949", '7949 - Outra saída de mercadoria ou prestação de serviço não especificado')
];