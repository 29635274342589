import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { DesfazerIcon, OkIcon } from '../../icons';
import { CircularLoading } from '../../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { isEqual } from 'lodash';
import {
  ProcessosFormModel
} from 'model/api/gestao/processos/processos-model';
import { FormEditProcessos } from 'views/components/form/processos/form-edit-processos/form-processos-gerar';
import { DeletarProcessoIcon } from 'views/components/icons/deletar-processo-icon';
import { useStyles } from './dialog-editar-processos-styles';
import { EnumProcessoStatus } from 'model/enums/enum-processos-status';
import { useDeleteProcesso } from 'data/api/gestao/processos/delete-remover-processo';
import { usePutEditarProcessos } from 'data/api/gestao/processos/put-editar-processo';
import { DialogEditarProcessosProps } from './dialog-editar-processos-props';

export const DialogEditarProcessos = ({
  close,
  carregando,
  aberto,
  processoSetor,
  processo,
  onlyImpressor = false,
  processoAdicionado
}: DialogEditarProcessosProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { putEditarProcessos, carregando: carregandoEditarProcesso } =
    usePutEditarProcessos();
  const { deleteProcesso, carregando: carregandoDelete } = useDeleteProcesso();
  const refEditForm = useRef<DefaultFormRefs<ProcessosFormModel>>(null);
  const classesComponent = useStyles();
  const loading = carregando || carregandoEditarProcesso || carregandoDelete;
  const classes = useStyles();
  const [processoForm, setProcessoForm] = useState<ProcessosFormModel>(new ProcessosFormModel())

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  const recarregarForm = useCallback((model: ProcessosFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    const objetoForm: ProcessosFormModel = new ProcessosFormModel(
      processo.id,
      processo.descricao,
      processo.equipamentos.map(x => x?.id),
      processo.processoProducao,
      processo.status.codigo ?? EnumProcessoStatus.AGUARDANDO,
    );
    setProcessoForm(objetoForm)
    recarregarForm(objetoForm);
  }, [processo, recarregarForm]);

  const saveChangesProcessos = useCallback(
    async (processosModelForm: ProcessosFormModel) => {
      const ret = await putEditarProcessos(
        processosModelForm,
        getEmpresaSelecionada()?.Id ?? '',
        processoSetor.setor.id,
        processoSetor.id,
        processosModelForm.id,
      );

      if (ret.erro) {
        throw ret.erro;
      }

      processoAdicionado.current = true
    },
    [getEmpresaSelecionada, processoAdicionado, processoSetor.id, processoSetor.setor.id, putEditarProcessos],
  );

  const handleSubmit = useCallback(
    async (model: ProcessosFormModel, beforeModel: ProcessosFormModel) => {
      try {
        const processosEqual = isEqual(
          model,
          beforeModel as ProcessosFormModel,
        );
        if (processosEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
          return;
        }
        await saveChangesProcessos(model);
        onCloseClick();
        showToast('success', 'Setor atualizado com Sucesso!');
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [onCloseClick, saveChangesProcessos, showToast],
  );

  const removerProcessos = useCallback(async () => {
    try {
      const ret = await deleteProcesso(
        getEmpresaSelecionada()?.Id || '',
        processoSetor.setor.id,
        processoSetor.id,
        processo.id,
      );

      if (ret.erro) {
        throw ret.erro;
      }
      processoAdicionado.current = true

      onCloseClick();
      showToast('success', 'Processo deletado');
    } catch (e: any) {
      showToast('error', e.message);
      refEditForm.current?.resetForm();
    }
  }, [
    deleteProcesso,
    getEmpresaSelecionada,
    onCloseClick,
    processo.id,
    processoSetor.id,
    processoSetor.setor.id,
    showToast,
    processoAdicionado
  ]);

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Editar Processo"
      tamanho="sm"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Grid className={classesComponent.cardContent}>
          <FormEditProcessos
            ref={refEditForm}
            showLoading={false}
            onlyImpressor={onlyImpressor}
            loading={loading ?? false}
            onSubmit={handleSubmit}
            model={processoForm}
          />
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box width="100%" display="flex">
                <Button
                  disabled={loading}
                  onClick={onCloseClick}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  size="large"
                >
                  <DesfazerIcon tipo="BUTTON" />
                  Voltar
                </Button>
                {!onlyImpressor && (
                  <Button
                    disabled={loading}
                    onClick={removerProcessos}
                    variant="contained"
                    className={classes.buttonExcluir}
                    size="large"
                  >
                    <DeletarProcessoIcon tipo="BUTTON_PRIMARY" />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
