import { EnumBalanca } from "model/enums/enum-balanca";
import { guidEmpty } from "../../../../../utils/guid-empty";
import { MovSimplesProdutoModel } from "../../movimentacao/simples/mov-simples-produto-model";
import { ProdutoCodigoModel } from "../produto-codigo/produto-codigo-model";
import { ProdutoNovoSubItemModel } from "../produto-subitem/produto-subitem-model";
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";
import { MedicamentoPreco } from '../../medicamento/medicamento-model';
export class ProdutoResumidoModel {
  constructor(
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public codigo: string = "",
    public nome: string = "",
    public infAdic: string = "",
    public ncm: string = "",
    public categoriaId: string = guidEmpty(),
    public categoriaDescricao: string = '',
    public medida: string = "",
    public medidaDesc: string = "",
    public vPreco: number = 0,
    public imagemUrl: string = "",
    public ativo: boolean = true,
    public favorito: boolean = false,
    public temImposto: boolean = false,
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public grupoImpostoId: string | null = null,
    public ncmId: string | null = null,
    public codigos: Array<ProdutoCodigoModel> = [],
    public cobraTaxaServico: boolean = true,
    // a propriedade id aqui é exclusiva para o uso do carrinho e não é um id real do produto, utilizado somente para eliminar o render
    public id: string = guidEmpty(),
    public setorId: string | null = null,

    public vTrib: number = 0,
    public pTribManual: number = 0,
    public pTribFederal: number = 0,
    public pTribEstadual: number = 0,
    public pTribMunicipal: number = 0,
    public subItens: ProdutoNovoSubItemModel[] = [],
    public prodSubItem: MovSimplesProdutoModel[] = [],
    public idGroup: string | null = null,
    public numCodigos: number = 0,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
    public tpAcresDesc: EnumTpDescontoAcrescimo = EnumTpDescontoAcrescimo.Porcentagem,
    public vAcresDesc: number = 0,
    public estendido: string = '',
    public codigoAnvisa: string | null = null,
    public medicamento: MedicamentoPreco | null = null
  ) { }
}
