import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-dados-faturas-styles';
import { VoltarIcon } from '../../icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormEmpresaEdit, FormEmpresaEditRef } from 'views/components/form/master/empresa-edit/form-empresa-edit';
import { EmpresaEditFormModel } from 'model/app/forms/master/empresa-edit-form-model';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useGetEmpresaId, usePutEmpresa } from 'data/api/gestao/empresa';
import { EmpresaModel } from 'model';
import { picker } from 'utils/picker';
import { toDateString } from 'utils/to-date';
import { isEqual } from 'lodash';

interface DialogDadosFaturasProps {
  openned: boolean;
  closeModal: () => void;
  error: string;
}

export const DialogDadosFaturas = ({
  openned,
  closeModal,
  error
}: DialogDadosFaturasProps) => {
  const classes = useStyles();

  const empresaFormRef = useRef<FormEmpresaEditRef>(null);
  const refEmpresaModel = useRef<EmpresaModel>(new EmpresaModel());
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [empresaFormState, setEmpresaFormState] =
    useState<EmpresaEditFormModel>(new EmpresaEditFormModel());

  const { getEmpresaSelecionada } = useSessaoAtual();


  const { getEmpresaId, carregando: carregandoGetEmpresa } = useGetEmpresaId();
  const { putEmpresa, carregando: carregandoPutEmpresa } = usePutEmpresa();

  const carregando =
    carregandoGetEmpresa ||
    carregandoPutEmpresa ||
    preenchendoTela ||
    carregandoFromForms

  const { showToast } = useToastSaurus();

  const getEmpresaByIdWrapper = useCallback(async () => {
    const empresaId = getEmpresaSelecionada()?.Id || '';
    const res = await getEmpresaId(empresaId);
    if (res.erro) {
      throw res.erro;
    }
    const ret = res.resultado?.data as EmpresaModel;
    refEmpresaModel.current = ret;
    const empresa = picker<EmpresaEditFormModel>(
      ret,
      new EmpresaEditFormModel(),
    );
    return empresa;
  }, [getEmpresaId, getEmpresaSelecionada]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        try {
          setPreenchendoTela(true);
          const empresa = await getEmpresaByIdWrapper();
          setEmpresaFormState(empresa);
          setPreenchendoTela(false);
        } catch (e: any) {
          showToast('error', e.message);
          setPreenchendoTela(false);
        }
      })();
      return () => {
        //funcao de limpeza
        setEmpresaFormState(new EmpresaEditFormModel());
      };
    }, 300);
  }, [getEmpresaByIdWrapper, showToast]);

  const recarregarForm = useCallback((model: EmpresaEditFormModel) => {
    empresaFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(empresaFormState);
  }, [empresaFormState, recarregarForm]);

  const saveChangesEmpresa = useCallback(
    async (empresaModel: EmpresaEditFormModel) => {
      const empresa = picker<EmpresaModel>(
        empresaModel,
        refEmpresaModel.current,
      );
      empresa.dNascimento = toDateString(
        empresaModel.dNascimento,
        'yyyy-MM-DDTHH:mm:ss',
      );

      try {
        const ret = await putEmpresa(empresa);
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Dados da empresa alterados com sucesso!');
        setEmpresaFormState(empresaModel);
      } catch (e: any) {
        showToast('error', e.message);
        empresaFormRef.current?.resetForm();
      }
    },
    [putEmpresa, showToast],
  );

  const handleSubmit = useCallback(
    async (model: EmpresaEditFormModel, beforeModel: EmpresaEditFormModel) => {
      let empresaEqual = isEqual(model, beforeModel);
      if (!empresaEqual) {
        saveChangesEmpresa(model);
      }

      if (empresaEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
      }
    },
    [saveChangesEmpresa, showToast],
  );

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Preencha os campos necessários"
      tamanho="md"
      isButtonTitleClose
    >
      <Typography variant="body2" style={{marginBottom: 15}}>{error}</Typography>
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <FormEmpresaEdit
            setCarregandoExterno={setCarregandoFromForms}
            showLoading={carregando}
            onSubmit={handleSubmit}
            loading={carregando}
            ref={empresaFormRef}
          />
        </Box>
      </Box>
      <Grid container spacing={2} style={{
        marginTop: 18,
        position: 'sticky',
        bottom: '-7px',
        backgroundColor: '#FFFFFF',
        zIndex: 2
      }}>
        <Grid item xs={4}>
          <Button
            disabled={carregandoFromForms}
            variant="outlined"
            fullWidth
            color="primary"
            size="large"
            onClick={() => {
              closeModal();
            }}
          >
            <VoltarIcon tipo="BUTTON" />
            Voltar
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            disabled={carregandoFromForms}
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            onClick={() => empresaFormRef.current?.submitForm()}
          >
            {'Salvar'}
          </Button>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
