import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { EmpresaModel } from '../../../../model/api/gestao/master/empresa';
import { VariaveisAmbiente } from 'config';

export function usePostEmpresa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postEmpresa = useCallback(
        (empresaModel: EmpresaModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(empresaModel),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEmpresa,
    };
}
