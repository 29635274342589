import * as Yup from "yup";
import { useMemo } from "react";

export const useDialogProdutoGenericoPesavelValidation = () => {
  const DialogProdutoGenericoPesavelValidation = useMemo(() => {
    return Yup.object().shape({
      xProd: Yup.string().notRequired(),
      vUnCom: Yup.string().required('Adicione um valor.'),
      qCom: Yup.string().required('Adiciona uma quantidade.').test({
        message: "Insira 3 numeros após a vírgula",
        test: (value) => {
          const decimais = value?.split(',')
          if (decimais && decimais[1]) {
            const decimaisLength = decimais[1].length >= 3
            return decimaisLength
          }
          return false
        }
      }),
    });
  }, []);

  return {
    DialogProdutoGenericoPesavelValidation,
  };
};
