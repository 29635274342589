import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: 5,
        background: '#F8F8F8',
        borderRadius: '15px',
    },
    gridContainer: {
        '& p': {
            fontSize: 13
        }
    },
    nome: {
        borderBottom: '2px solid #E6E6E6',
        [theme.breakpoints.up('md')]: {
            borderBottom: 'none'
        }
    },
    editIcon: {
        borderLeft: '2px solid #E6E6E6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px',
        margin: '10px 0',
    }
}))