import { useCallback } from 'react';
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { MenuIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';

export const AtivarRevisarNFCEHeader = () => {
  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const leftArea = useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER" />}
          tooltip="Menu"
          onClick={abrirMenu}
        />
      ) : null,
    [isMobile, abrirMenu],
  );

  return (
    <PrivatePageHeader
      title="Revisar dados de NFC-e"
      leftArea={leftArea()}
    />
  );
};
