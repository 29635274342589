export const getCurrentMonth = () => {
    const month = new Date().getMonth() + 1
    const year = new Date().getFullYear()
    return new Date(`${month}/01/${year}`).toString()
  }

  //não testei essa função direito ainda use com cuidado, criei só pra ter (dps q testar tira esse comment pf)
export const incMonth = (date: Date, inc: number) => {
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    if(month + inc > 12){
        const [actualYear, actualMonth] = checkDifferenceInc(month, year, inc)

        return new Date(`${actualMonth}/01/${actualYear}`).toString()
    }

    return new Date(`${month + inc}/01/${year}`).toString()
}

export const descMonth = (date: Date, desc: number) => {
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    if(month - desc < 1){
        const [actualYear, actualMonth] = checkDifferenceDesc(month, year, desc);

        return new Date(`${actualMonth}/01/${actualYear}`).toString()
    }

    return new Date(`${month - desc}/01/${year}`).toString()
}

function checkDifferenceInc(month: number, year: number, inc: number){
    let actualYear = year;
    let actualMonth = month + inc;
    let actualInc = inc;
    if(month === 12 && inc === 1){
        actualMonth = 1;
        actualYear++
    }else{
        while(actualMonth + actualInc > 12){
            actualYear++;
            actualMonth = (actualMonth + (actualInc -12)) - 12;
            actualInc -= 12;
        }
    }

    return [actualYear, actualMonth]
}

function checkDifferenceDesc(month: number, year: number, desc: number){
    let actualYear = year;
    let actualMonth = month;
    let actualDesc = desc;
    if(month === 1 && desc === 1){
        actualMonth = 12;
        actualYear--;
    }else{
        while(actualMonth - actualDesc < 1){
            actualYear--;
            actualDesc = actualDesc - 12;
        }
    }
    actualMonth -= actualDesc
    return [actualYear, actualMonth]
}