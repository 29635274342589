import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    btnGrid: {
        marginTop: 16
    }
}));
