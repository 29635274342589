import { TabelaNumeracao } from 'database/interfaces/interface-tabela-numeracao';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumMovModelo } from 'model';
import { useCallback } from 'react';
import { useSessaoAtual } from '../providers';
import { useToastSaurus } from './toast-saurus';

export function useNumeracao() {
    // HOOKS
    const { getEmpresaSelecionada } = useSessaoAtual();

    // AUX
    const { showToast } = useToastSaurus();

    const verificarUltimaNumeracao = useCallback((numeracoes: TabelaNumeracao[]) => {
        let numeracao: TabelaNumeracao | undefined = undefined;

        for (let value of numeracoes) {
            if (value.numero > (numeracao?.numero ?? 0)) {
                numeracao = value
            }
        }

        if (numeracao?.data && numeracao.data) {
            const dataCriacao = new Date(numeracao.data);
            dataCriacao.setDate(dataCriacao.getDate() + 5);

            const dataAtual = new Date();

            if (dataAtual.getTime() > dataCriacao.getTime()) {
                return undefined
            }
        }

        return numeracao;
    }, []);

    const getNumeracao = useCallback(
        async (mod: EnumMovModelo, serie: number, tpAmb: number) => {
            try {
                const numeracao = await TouchoneDBPrimary.numeracao
                    .filter(
                        (numeracao) =>
                            numeracao.empresaId === getEmpresaSelecionada()!.Id &&
                            numeracao.mod === mod &&
                            numeracao.serie === serie &&
                            numeracao.tpAmb === tpAmb,
                    ).toArray();

                if (numeracao.length === 0) {
                    return undefined;
                }

                const ultimaNumerecao = verificarUltimaNumeracao(numeracao);

                return ultimaNumerecao;
            } catch (e: any) {
                showToast('error', `Erro ao buscar ultima numeração.`);
                return undefined;
            }
        },
        [getEmpresaSelecionada, showToast, verificarUltimaNumeracao],
    );

    const setNumeracao = useCallback(async (mod: EnumMovModelo, serie: number, tpAmb: number, numero: number) => {
        try {
            const numeracao: TabelaNumeracao = {
                data: new Date(),
                empresaId: getEmpresaSelecionada()!.Id,
                mod,
                serie,
                numero,
                tpAmb
            }

            await TouchoneDBPrimary.numeracao.add(numeracao);

            return numeracao;
        } catch (err: any) {
            showToast('error', err.message);
            return undefined;
        }
    }, [getEmpresaSelecionada, showToast]);

    return {
        getNumeracao,
        setNumeracao
    };
}
