import { EnumRegime } from '../../enums'
export class ConsultaCNPJModel {
    constructor(
        public cnpj: string = '',
        public ie: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public cnae: string = '',
        public regime: EnumRegime = EnumRegime.NAOSEAPLICA,
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public uf: string = '',
        public indCredNFe: string = '',
    ) { }
}