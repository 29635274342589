import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useFormCompradorPreCadastroValidation } from './form-comprador-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { useGetEnderecoPessoa } from 'data/api/gestao/pessoa';
import { CompradorPacientePreCadastroFormModel } from 'model/app/forms/receita/comprador-paciente-form-model';
import { picker } from 'utils/picker';
import { stringNumeros } from 'utils/string-numeros';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpDocumentoMock } from 'data/mocks/tp-documento-mock';
import { TipoDocumentoOrgaoExpedidorMock } from 'data/mocks/orgao-expedidor-mock';
import { EnumCadastroStatus, KeyValueModel } from 'model';
import { SexoMock, UFMock } from 'data/mocks';
import { toDateString } from 'utils/to-date';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';

export interface FormCompradorPacienteProps
    extends DefaultFormProps<CompradorPacientePreCadastroFormModel> {
}

const documentosPessoais = [
    EnumTipoDocumento.CarteiraIdentidade,
    EnumTipoDocumento.CarteiraTrabalhoPrevidenciaSocial,
    EnumTipoDocumento.Passaporte,
    EnumTipoDocumento.CarteiraRegistroProfissional
]

export const FormCompradorPreCadastro = forwardRef<
    DefaultFormRefs<CompradorPacientePreCadastroFormModel>,
    FormCompradorPacienteProps
>(({ loading,
    ...props }: FormCompradorPacienteProps, ref,) => {

    const utilClasses = makeUtilClasses();
    const [, setAtt] = useState<boolean>(false)
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa()
    const { showToast } = useToastSaurus()
    const { theme } = useThemeQueries()
    const { getEmpresaAtual } = useEmpresaAtual()
    const [initialValues, setInitialValues] =
        useState<CompradorPacientePreCadastroFormModel>(
            new CompradorPacientePreCadastroFormModel(),
        );
    const { FormYupValidationPreComprador } =
        useFormCompradorPreCadastroValidation();

    const carregando = [carregandoCEP, carregandoEndereco].includes(true)

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<CompradorPacientePreCadastroFormModel>({
        defaultValues: { ...initialValues },
        resolver: yupResolver(FormYupValidationPreComprador),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: CompradorPacientePreCadastroFormModel) => {
        values = picker<CompradorPacientePreCadastroFormModel>(values, new CompradorPacientePreCadastroFormModel())
        values.comprador.telefone = stringNumeros(values.comprador.telefone)
        props.onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setInitialValues(new CompradorPacientePreCadastroFormModel());
            reset();
        },
        fillForm: (model: CompradorPacientePreCadastroFormModel) => {
            setInitialValues(model);
            model.comprador.ufOrgaoExpedidor = getEmpresaAtual()?.uf ?? ''
            // model.paciente.ufOrgaoExpedidor = getEmpresaAtual()?.uf ?? ''
            reset({ ...model })
        },
    }));

    const handleAtt = () => setAtt(prev => !prev)

    const buscarEnderecoPessoa = useCallback(async (pessoaId: string) => {
        try {
            const res = await getEnderecoPessoa(pessoaId)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data
            return resultado as PessoaEnderecoModel[]
        } catch (error: any) {
            showToast('error', 'Ocorreu um problema ao buscar o endereço, tente novamente ou preencha manualmente. Detalhe: ' + error.message)
            return null
        }
    }, [getEnderecoPessoa, showToast])

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AutocompletePessoas
                                        allowSubmit
                                        disabled={loading}
                                        label={"Selecione o Comprador"}
                                        nomeCnpj={EnumNomeCnpj.Todos}
                                        onChange={async (retorno) => {
                                            if (!retorno.isString) {
                                                const pessoa: PessoaModel = retorno.value
                                                const enderecos = await buscarEnderecoPessoa(pessoa.id)
                                                setValue('comprador.nome', pessoa.nome)
                                                setValue('comprador.cpfcnpj', pessoa.cpfcnpj)
                                                setValue('comprador.dataNascimento', pessoa.dtNasc
                                                    ? (toDateString(pessoa.dtNasc, 'yyyy-MM-DD') ?? '')
                                                    : '')
                                                setValue('comprador.pessoaId', pessoa.id)
                                                setValue('comprador.sexo', pessoa.sexo)

                                                const telefone = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '';
                                                setValue('comprador.telefone', telefone);

                                                const [endereco] = enderecos ?? []

                                                setValue('comprador.cep', stringNumeros(endereco?.cep ?? ''))
                                                setValue('comprador.codMunicipio', endereco?.cMun ? Number(endereco.cMun) : 0)
                                                setValue('comprador.municipio', endereco?.xMun)
                                                setValue('comprador.logradouro', endereco?.logradouro)
                                                setValue('comprador.numero', endereco?.numero)
                                                setValue('comprador.complemento', endereco?.complemento)
                                                setValue('comprador.uf', endereco?.uf)

                                                const [documento] = pessoa.documentos
                                                setValue('comprador.tipoDocumento', documento?.tipo ?? EnumTipoDocumento.CarteiraIdentidade)
                                                setValue('comprador.documento', documento?.documento)
                                                setValue('comprador.orgaoExpedidor', documento?.orgaoExpedicao ?? TipoDocumentoOrgaoExpedidorEnum.SecretariaReceitaFederal)
                                                setValue('comprador.ufOrgaoExpedidor', documento?.ufExpedicao)
                                            }
                                        }}
                                        status={[EnumCadastroStatus.LIBERADO]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box width="100%" my={1}>
                                        <Divider />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.nome"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Nome"}
                                                tipo={"TEXTO"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.nome && errors.comprador?.nome.message)}
                                                helperText={
                                                    touchedFields.comprador?.nome || errors.comprador?.nome
                                                        ? errors.comprador?.nome?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.cpfcnpj"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label="CPF/CNPJ"
                                                tipo="CNPJ_CPF"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.cpfcnpj && errors.comprador?.cpfcnpj.message)}
                                                helperText={
                                                    touchedFields.comprador?.cpfcnpj || errors.comprador?.cpfcnpj
                                                        ? errors.comprador?.cpfcnpj?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.tipoDocumento"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={TpDocumentoMock}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Tipo de Documento"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.tipoDocumento && errors.comprador?.tipoDocumento.message)}
                                                helperText={
                                                    touchedFields.comprador?.tipoDocumento || errors.comprador?.tipoDocumento
                                                        ? errors.comprador?.tipoDocumento?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = TpDocumentoMock.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('comprador.tipoDocumento', item.Key);
                                                        }
                                                    }
                                                    handleAtt()
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.documento"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label="Documento"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.documento && errors.comprador?.documento.message)}
                                                helperText={
                                                    touchedFields.comprador?.documento || errors.comprador?.documento
                                                        ? errors.comprador?.documento?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                {documentosPessoais.includes(Number(getValues('comprador.tipoDocumento')) || 1) && (
                                    <>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="comprador.orgaoExpedidor"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value))}
                                                        allowSubmit
                                                        disabled={loading}
                                                        fullWidth
                                                        variant="outlined"
                                                        label={"Órgão Expedidor"}
                                                        placeholder='Ex: SSP'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.comprador?.orgaoExpedidor && errors.comprador?.orgaoExpedidor.message)}
                                                        helperText={
                                                            touchedFields.comprador?.orgaoExpedidor || errors.comprador?.orgaoExpedidor
                                                                ? errors.comprador?.orgaoExpedidor?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            if (event) {
                                                                const item = TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value)).filter(
                                                                    (item) => item.Key === event.target.value,
                                                                )?.[0];
                                                                if (item) {
                                                                    setValue('comprador.orgaoExpedidor', item.Key);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="comprador.dataNascimento"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        tipo={"DATA"}
                                                        allowSubmit
                                                        disabled={loading}
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Data de nascimento"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.comprador?.dataNascimento && errors.comprador?.dataNascimento.message)}
                                                        helperText={
                                                            touchedFields.comprador?.dataNascimento || errors.comprador?.dataNascimento
                                                                ? errors.comprador?.dataNascimento?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="comprador.ufOrgaoExpedidor"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                                        allowSubmit
                                                        disabled={loading}
                                                        fullWidth
                                                        variant="outlined"
                                                        label={"UF do Órgão Expedidor"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.comprador?.ufOrgaoExpedidor && errors.comprador?.ufOrgaoExpedidor.message)}
                                                        helperText={
                                                            touchedFields.comprador?.ufOrgaoExpedidor || errors.comprador?.ufOrgaoExpedidor
                                                                ? errors.comprador?.ufOrgaoExpedidor?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            if (event) {
                                                                const item = UFMock.filter(
                                                                    (item) => item.Value === event.target.value,
                                                                )[0];
                                                                if (item) {
                                                                    setValue('comprador.ufOrgaoExpedidor', item.Value);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <Controller
                                        name="comprador.telefone"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Telefone"}
                                                tipo={"TELEFONE"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.telefone && errors.comprador?.telefone.message)}
                                                helperText={
                                                    touchedFields.comprador?.telefone || errors.comprador?.telefone
                                                        ? errors.comprador?.telefone?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="comprador.sexo"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={SexoMock}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Gênero"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.sexo && errors.comprador?.sexo.message)}
                                                helperText={
                                                    touchedFields.comprador?.sexo || errors.comprador?.sexo
                                                        ? errors.comprador?.sexo?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = SexoMock.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('comprador.sexo', item.Key);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                        Endereço
                                    </Typography>
                                    <Divider color={theme.palette.secondary.main} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.cep"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo="CEP"
                                                fullWidth
                                                searchable
                                                autoComplete={'off'}
                                                label="CEP"
                                                placeholder=""
                                                onSearch={async (value: string) => {
                                                    try {
                                                        let res = await consultaCEP(value);

                                                        setValue('comprador.cep', res.CEP);
                                                        setValue('comprador.logradouro', res.Logradouro);
                                                        setValue('comprador.uf', res.UF);
                                                        setValue('comprador.codMunicipio', res.CMun ? Number(res.CMun) : 0);
                                                        setValue('comprador.municipio', res.Municipio)
                                                    } catch (e: any) {
                                                        showToast('error', e.message);
                                                    }
                                                }}
                                                error={Boolean(errors.comprador?.cep && errors.comprador?.cep.message)}
                                                helperText={
                                                    errors.comprador?.cep
                                                        ? errors.comprador?.cep?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="comprador.logradouro"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Logradouro"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.logradouro && errors.comprador?.logradouro.message)}
                                                helperText={
                                                    touchedFields.comprador?.logradouro || errors.comprador?.logradouro
                                                        ? errors.comprador?.logradouro?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Controller
                                        name="comprador.numero"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Número"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.numero && errors.comprador?.numero.message)}
                                                helperText={
                                                    touchedFields.comprador?.numero || errors.comprador?.numero
                                                        ? errors.comprador?.numero?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="comprador.complemento"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Complemento"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.complemento && errors.comprador?.complemento.message)}
                                                helperText={
                                                    touchedFields.comprador?.complemento || errors.comprador?.complemento
                                                        ? errors.comprador?.complemento?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="comprador.municipio"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Município"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.municipio && errors.comprador?.municipio.message)}
                                                helperText={
                                                    touchedFields.comprador?.municipio || errors.comprador?.municipio
                                                        ? errors.comprador?.municipio?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Controller
                                        name="comprador.uf"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"UF"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.comprador?.uf && errors.comprador?.uf.message)}
                                                helperText={
                                                    touchedFields.comprador?.uf || errors.comprador?.uf
                                                        ? errors.comprador?.uf?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = UFMock.filter(
                                                            (item) => item.Value === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('comprador.uf', item.Value);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
},
);
