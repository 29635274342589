import { useCallback, useEffect, useRef, useState } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { VoltarIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { PesquisaProduto } from './components/pesquisa-produtos/pesquisa-produto';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ReticenciasIcon } from 'views/components/icons/reticencias-icon';
import { useThemeQueries } from 'views/theme';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useStyles } from './produto-header-styles';
import { useCadastros } from 'services/app';

interface VendaHeaderProps {
  title?: string;
  id?: string;
}

export const VendaHeader = ({ title, id }: VendaHeaderProps) => {
  const textRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>('');
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const { goToAdministrativo } = useOpenMovRota();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const classes = useStyles();
  const { abrirDialogDefinicoes } = useCadastros();

  // AUX
  const { isMobile } = useThemeQueries();

  useEffect(() => {
    // MANIPULANDO EVENTO DIRETAMENTE DO WINDOW PARA OBTER MAXIMA PERFORMANCE E NAO CHAMAR A LIB DO TOAST
    function handleKeyPress(event: any) {
      if (event.keyCode === 27 && openSearch) {
        callEvent(AppEventEnum.TermoPesquisaProduto, '');
        setSearch('')
        setOpenSearch((prev) => !prev);
        callEvent(AppEventEnum.TabCategoria, false);
        callEvent(AppEventEnum.TermoPesquisaProduto, '');

        const inputElement = document.activeElement as HTMLInputElement;
        if (inputElement && inputElement.tagName === 'INPUT') {
          inputElement.blur();
        }
      }
    }
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [callEvent, openSearch, isMobile]);

  useEffect(() => {
    addHandler(AppEventEnum.AttTermoPesquisaProduto, setSearch);
    return () => {
      removeHandler(AppEventEnum.AttTermoPesquisaProduto, setSearch)
    }
  }, [addHandler, removeHandler])

  const handleBack = async () => {
    callEvent(AppEventEnum.TermoPesquisaProduto, '');
    setSearch('')
    setOpenSearch((prev) => !prev);
    callEvent(AppEventEnum.TabCategoria, false);
  };

  const leftArea = () => (
    <ButtonPrivateHeader
      id={id}
      icon={
        openSearch ? (
          <VoltarIcon tipo="PRIVATE_HEADER" />
        ) : (
          <MenuCirculadoIcon tipo="PRIVATE_HEADER" />
        )
      }
      tooltip="Ir para Menu Administrativo"
      onClick={() => (openSearch ? handleBack() : goToAdministrativo())}
    />
  );

  const rightArea = useCallback(
    () => (
      <Grid style={{ display: 'flex' }}>
        <ButtonPrivateHeader
          icon={<ReticenciasIcon tipo="PRIVATE_HEADER" />}
          tooltip={'Opções de pesquisa'}
          onClick={() =>
            abrirDialogDefinicoes()
          }
        ></ButtonPrivateHeader>
      </Grid>
    ),
    [abrirDialogDefinicoes]
  );

  const pesquisa = <PesquisaProduto isOpened={openSearch} />;

  return (
    <>
      <PrivatePageHeader
        title={title || 'Venda'}
        leftArea={leftArea()}
        rightArea={rightArea()}
        middleArea={
          <TextFieldSaurus
            ref={textRef}
            fullWidth
            size="small"
            searchable
            className={classes.textField}
            placeholder="Pesquisar produtos no catálogo..."
            onFocus={() => {
              setOpenSearch(true);
              callEvent(AppEventEnum.TabCategoria, true);
            }}
            onChange={(e: any) => {
              callEvent(AppEventEnum.TermoPesquisaProduto, e.target.value);
              setSearch(e.target.value)
            }}
            value={search}
          />
        }
        bottomArea={openSearch ? pesquisa : null}
      />
    </>
  );
};
