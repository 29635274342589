import { guidEmpty } from "utils/guid-empty";

export class SetoresModel {
    constructor(
        public id: string = guidEmpty(),
        public descricao: string = '',
        public uriImage: string = '',
    ) { }
}

export class NewSetorModel {
    constructor(
        public descricao: string = '',
        public uriImage: string = '',
    ) { }
}