import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogMovIndicadorDesperdicio } from 'views/components/dialog/dialog-mov-indicador-desperdicio/dialog-mov-indicador-desperdicio';

class IndicacaoDeDesperdicioProps {
  constructor(
    public aberto: boolean = false,
    public handleConfirme: (
      produto: PedidoProdutoModel | undefined,
      desperdicio: EnumIndDesperdicio
    ) => void = (
      produto: PedidoProdutoModel | undefined,
      desperdicio: EnumIndDesperdicio
    ) => {},
    public produto:
      | React.MutableRefObject<PedidoProdutoModel>
      | undefined = undefined
  ) {}
}

export const DialogIndicacaoDeDesperdicio = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<IndicacaoDeDesperdicioProps>(
    new IndicacaoDeDesperdicioProps()
  );

  consoleDev('DialogIndicacaoDeDesperdicio');

  const modalAlterado = useCallback(
    ({ aberto, produto, handleConfirme }: any) => {
      setModalState({
        aberto,
        handleConfirme,
        produto
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.IndicacaoDeDesperdicio, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.IndicacaoDeDesperdicio, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogMovIndicadorDesperdicio
          openned={modalState.aberto}
          handleConfirme={modalState.handleConfirme}
          produto={modalState.produto}
        />
      )}
    </>
  );
};
