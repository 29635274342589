import { isEmpty } from "lodash";

import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { ImpressoraCadastro } from "./components/impressora-cadastro/impressora-cadastro";
import { ImpressoraEdit } from "./components/impressora-edit/impressora-edit";

export const ImpressoraModal = (props: ModalProps) => {

  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (<ImpressoraCadastro />)}
      {props.openned && !isEmpty(props.id) && <ImpressoraEdit id={props.id} />}
    </DefaultModal>
  );
};
