import * as Yup from "yup";
import { useMemo } from "react";

export const useFormNovoProdutoSubItemValidation = () => {

  const FormNovoProdutoSubItemYupValidation = useMemo(() => {
    return Yup.object().shape({
      produtoNome: Yup.string().required('Selecione um produto.'),
      valor: Yup.string().notRequired()
    });
  }, []);

  return {
    FormNovoProdutoSubItemYupValidation,
  };
};
