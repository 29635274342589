import { Box, Grid, Typography } from '@material-ui/core';
import { AutenticacaoHeader } from "./components/autenticacao-header.tsx/autenticacao-header";
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { QRCodeSVG } from 'qrcode.react';
import { useSessaoAtual } from 'services/app';
import { useStyles } from './autenticacao-styles';
import classNames from 'classnames';
import { useMemo } from 'react';

const AutenticacaoPage = () => {

    const classes = useDefaultCadastroStyles();
    const pageClasses = useStyles();

    const { usuario } = useSessaoAtual();

    const valorQRCode = useMemo(() => {
        return JSON.stringify({
            refreshTokenId: usuario!.jwrefresh,
            usuarioId: usuario!.usuarioId
        })
    }, [usuario])


    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <AutenticacaoHeader />
            </Grid>
            <Grid className={classNames(classes.content, pageClasses.content)}>

                <Box className={pageClasses.svgContainer}>
                    <Typography align='center' variant='h6'>Utilize o QRCode abaixo para conceder permissões para outros usuários com menos privilégios.</Typography>
                    <Typography align='center' variant='body2'>Atenção: Cuidado com a adulteração deste acesso e caso precise gerar outro QRCode, realize o login novamente.</Typography>
                    <QRCodeSVG style={{ marginTop: 32 }} value={valorQRCode} height='50%' width='50%' />
                </Box>
            </Grid>
        </Grid>

    );
};

export default AutenticacaoPage
