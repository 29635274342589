import { StringBuilder } from "utils/stringBuilder";
import { codigoUf } from "./utils/codigoUF";

class DadosChaveFiscal {
    constructor(public chave: string, public digitoVerificador: string) { }
}

export const useGenerateIdeId = () => {

    const ObterDigitoVerificador = (chave: string) => {
        var soma = 0; // Vai guardar a Soma
        var mod = -1; // Vai guardar o Resto da divisão
        var dv = -1; // Vai guardar o DigitoVerificador
        var peso = 2; // vai guardar o peso de multiplicação

        //percorrendo cada caractere da chave da direita para esquerda para fazer os cálculos com o peso
        for (var i = chave.length - 1; i !== -1; i--) {
            var ch = Number(chave[i].toString());
            soma += ch * peso;
            //sempre que for 9 voltamos o peso a 2
            if (peso < 9)
                peso += 1;
            else
                peso = 2;
        }

        //Agora que tenho a soma vamos pegar o resto da divisão por 11
        mod = soma % 11;
        //Aqui temos uma regrinha, se o resto da divisão for 0 ou 1 então o dv vai ser 0
        if (mod === 0 || mod === 1)
            dv = 0;
        else
            dv = 11 - mod;

        return dv.toString();
    }

    const ObterChave = (uf: string, dataEmissao: Date, cnpjEmitente: string, modelo: number, serie: number, numero: number, tipoEmissao: number, cNf: number) => {
        var chave = new StringBuilder();

        const codigoUfEmpresa = codigoUf.find(codUf => codUf.sigla === uf);

        chave.append(codigoUfEmpresa?.codigo.toString());
        chave.append(dataEmissao.getTime().toString())
        chave.append(cnpjEmitente)
        chave.append(modelo.toString())
        chave.append(serie.toString())
        chave.append(numero.toString())
        chave.append(tipoEmissao.toString())
        chave.append(cNf.toString());

        var digitoVerificador = ObterDigitoVerificador(chave.toString());

        chave.append(digitoVerificador);

        return new DadosChaveFiscal(chave.toString(), digitoVerificador);
    }

    return {
        ObterChave
    }

}