import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    option: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        backgroundColor: `${theme.palette.primary.main}40`,
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
        userSelect: 'none'
    },
    text: {
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: 18
    }
}))