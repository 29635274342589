import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useSessaoAtual, useToastSaurus } from "services/app";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { ImpressoraIcon } from "views/components/icons/impressora-icon";
import { CancelarIcon, ConfiguracaoIcon, NovoIcon, OkIcon } from "views/components/icons";
import { CardEdicaoGerenciamentoSessao } from "views/components/cards/card-edicao-gerenciamento-sessao/card-edicao-gerenciamento-sessao";
import { SessaoGerenciamentoModel, SessaoValoresModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { useGetSessaoValor } from "data/api/gestao/sessao/get-sessao-valor";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CircularLoading } from "views/components/utils";
import { usePDV } from "services/app/hooks/pdv";
import { useGetSessaoImpressao } from "data/api/gestao/sessao/get-impressao";
import { ImpressaoSaurus } from "services/app/hooks/impressao-saurus";
import { ConteudoLeitura } from "views/pages/private/gerenciar-sessao/sessao-leitura-caixa/components/conteudo-leitura/conteudo-leitura";
import { useStyles } from "./dialog-editar-gerenciamento-sessao-styles";
import { CardEdicaoGerenciamentoSessaoValor } from "views/components/cards/card-edicao-gerenciamento-sessao-valor/card-edicao-gerenciamento-sessao-valor";
import { useSessaoPDV } from "services/app/hooks/sessao-pdv";
import { CardAdicionarGerenciamentoSessaoValor } from "views/components/cards/card-adicionar-gerenciamento-sessao-valor/card-adicionar-gerenciamento-sessao-valor";
import { AccordionTipoLancamento } from "./components/accordion-edicao-gerenciamento-sessao-valor-mobile/accordion-edicao-gerenciamento-sessao-valor-mobile";
import { AccordionEdicaoGerenciamentoSessaoImpressaoMobile } from "./components/accordion-edicao-gerenciamento-sessao-impressao-mobile/accordion-edicao-gerenciamento-sessao-impressao-mobile";
import { isEmpty } from "lodash";
import { ModuloResumoSessaoProps } from "../dialog-config-resumo-sessao/dialog-config-resumo-sessao";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { SessaoPostConferenciaModel } from "model/api/gestao/sessao/sessao-post-conferencia-model";
import { toDateStringApi } from "utils/to-date";
import { usePostSessaoConferencia } from "data/api/gestao/sessao/post-sessao-conferencia";
import { useGetSessaoById } from "data/api/gestao/sessao/get-sessao-by-id";
import { RetornarIcon } from "views/components/icons/retornar-icon";

export interface DialogEditarGerenciamentoSessaoProps {
    aberto: boolean;
    model: SessaoGerenciamentoModel;
}

export const DialogEditarGerenciamentoSessao = ({
    aberto,
    model,
}: DialogEditarGerenciamentoSessaoProps) => {

    //AUX
    const { acoes } = useModalStyles()
    const classesGerenciamento = useStyles();
    const { theme, isMobile } = useThemeQueries();
    const { showToast } = useToastSaurus();
    const { addHandler, removeHandler, callEvent } = useEventTools();

    //PROVIDERS
    const {
        fecharDialogEditarGerenciamentoSessao,
        abrirDialogEdicaoGerenciamentoSessao,
        abrirConfigResumoSessao,
    } = useCadastros();
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { getPDV, getImpressoraPdv } = usePDV();
    const { imprimirResumo } = useSessaoPDV();
    const { getRegistro } = useGestaoStorage();

    //STATES E REFS
    const [sessaoValores, setSessaoValores] = useState<SessaoValoresModel[]>([]);
    const [impressao, setImpressao] = useState('');
    const [webPdvImpressao, setWebPdvImpressao] = useState('');
    const [conteudotela, setConteudoTela] = useState('');
    const [error, setError] = useState<boolean>(false);
    const [addValor, setAddValor] = useState<boolean>(false);
    const [sessao, setSessao] = useState<SessaoGerenciamentoModel>(model);

    //CHAMADAS DA API
    const { getSessaoById, carregando: carregandoSessaoById } = useGetSessaoById();
    const { getSessaoValor, carregando: carregandoSessaoValor } = useGetSessaoValor();
    const { getSessaoImpressao, carregando: carregendoSessaoImpressao } = useGetSessaoImpressao();
    const { postSessaoConferencia, carregando: carregandoSessaoConferencia } = usePostSessaoConferencia();

    const carregando = carregandoSessaoValor || carregendoSessaoImpressao || carregandoSessaoConferencia || carregandoSessaoById;

    const getSessaoValorWrapper = useCallback(async () => {
        try {
            const res = await getSessaoValor(getEmpresaSelecionada()!.Id, sessao.caixaId, sessao.id);

            if (res.erro)
                throw res.erro;

            setSessaoValores(res.resultado?.data);
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getEmpresaSelecionada, getSessaoValor, sessao.caixaId, sessao.id, showToast])

    const getResumo = useCallback(async () => {
        try {
            const query = getRegistro(GestaoStorageKeys.ResumoSessao, false) as ModuloResumoSessaoProps[]
            const filtros = query.map(filtro => { return `&${filtro.query}=${filtro.selecionado}`.toString() })
            const queryTratada = filtros.join('');

            const qtdColuna = getPDV()?.configuracoes.find((p) => p.cod === 57);
            const res = await getSessaoImpressao(
                getEmpresaSelecionada()!.Id,
                sessao.caixaId,
                sessao.id,
                qtdColuna?.vConfig ?? 62,
                queryTratada
            );

            if (res.erro) {
                throw new Error(res.erro);
            }

            if (getImpressoraPdv()) {
                setWebPdvImpressao(res?.resultado?.data?.texto ?? '')
            }

            const resConteudo = await getSessaoImpressao(
                getEmpresaSelecionada()!.Id,
                sessao.caixaId,
                sessao.id,
                40,
                queryTratada,
            );

            if (resConteudo.erro) {
                throw new Error(
                    'Não foi possível realizar a leitura, tente novamente mais tarde.'
                );
            }

            const renderizarNaTela = new ImpressaoSaurus('HTML_SIMPLES');

            const resumoTraduzido2 = renderizarNaTela.Traduz(
                resConteudo?.resultado?.data?.texto ?? ''
            );
            setConteudoTela(
                '<style>\nh1{font-size:26px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh2{font-size:22px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh3{font-size:20px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh4{font-size:16px;font-weight:normal;white-space:pre-wrap;margin:0;}\nh5{font-size: 12px;font-weight: normal;white-space: pre-wrap;margin: 0;display: flex;flex-direction: column;justify-content: center;align-items: flex-end;text-align: end;}\n</style>\n' +
                resumoTraduzido2 ?? ''
            );
            setImpressao(res?.resultado?.data?.texto ?? '');
            setError(false);
        } catch (e: any) {
            setError(true);
        }
    },
        [
            getEmpresaSelecionada,
            getImpressoraPdv,
            getPDV,
            getSessaoImpressao,
            getRegistro,
            sessao.caixaId,
            sessao.id,
        ],
    );

    const getGerenciamentoWrapper = useCallback(async () => {
        try {
            const res = await getSessaoById(getEmpresaSelecionada()!.Id, sessao.caixaId, sessao.id);

            if (res.erro) {
                throw res.erro
            }

            setSessao(res.resultado?.data);
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getEmpresaSelecionada, getSessaoById, sessao.caixaId, sessao.id, showToast])

    const enviarConferencia = useCallback(async () => {
        try {

            const payload = {
                dataAcao: toDateStringApi(new Date()),
                responsavelId: sessao?.operadorId,
                responsavelNome: sessao?.operadorNome,
            } as SessaoPostConferenciaModel

            const res = await postSessaoConferencia(
                getEmpresaSelecionada()!.Id,
                sessao?.caixaId,
                sessao?.id,
                payload,
            );

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'Conferência realizada com sucesso.');
            await getSessaoValorWrapper();
            await getGerenciamentoWrapper();

        } catch (err: any) {
            showToast('error', err.message);
        }
    },
        [
            getEmpresaSelecionada,
            postSessaoConferencia,
            getSessaoValorWrapper,
            getGerenciamentoWrapper,
            showToast,
            sessao?.caixaId,
            sessao?.id,
            sessao?.operadorId,
            sessao?.operadorNome,
        ],
    );

    const chamarResumo = useCallback(({ aberto }: any) => {
        if (!aberto) {
            const resumo = async () => {
                await getResumo();
            }
            resumo()
        }
    }, [getResumo])

    useEffect(() => {
        addHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo)

        return () => {
            removeHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo)
        }

    }, [addHandler, chamarResumo, removeHandler])

    useEffect(() => {
        const gtResumo = async () => {
            await getResumo();
            await getSessaoValorWrapper();
        }
        gtResumo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buttonAddClick = useCallback(() => {
        setAddValor(!addValor);
    }, [addValor])

    const isConferida = !isEmpty(model.dConferencia);

    const buttonOrForm = useMemo(() => {
        return (
            <>
                <Grid container spacing={2}>
                    {!addValor ? (
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={buttonAddClick}
                                >
                                    <NovoIcon tipo="BUTTON_PRIMARY" />
                                    Adicionar uma Correção
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <CardAdicionarGerenciamentoSessaoValor
                                    model={sessao}
                                    setAddValor={setAddValor}
                                    getSessaoValorWrapper={getSessaoValorWrapper}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </>
        )
    }, [addValor, buttonAddClick, getSessaoValorWrapper, sessao])

    const validarConferencia = useCallback(() => {
        if (isEmpty(sessao.dFechamento)) {
            return false;
        }
        else if (isEmpty(sessao.dConferencia)) {
            return true;
        }
        else {
            return false;
        }
    }, [sessao.dConferencia, sessao.dFechamento])

    const clickImpressao = useCallback(async () => {
        if (error) {
            getResumo()
            return
        }

        fecharDialogEditarGerenciamentoSessao();
        await imprimirResumo(webPdvImpressao
            ? webPdvImpressao
            : impressao,
            getPDV()?.numCaixa.toString() ?? '',
            webPdvImpressao
                ? false
                : true
        );

        setTimeout(() => {
            abrirDialogEdicaoGerenciamentoSessao(model);
        }, 1000)
    }, [abrirDialogEdicaoGerenciamentoSessao, error, fecharDialogEditarGerenciamentoSessao, getPDV, getResumo, impressao, imprimirResumo, model, webPdvImpressao])

    const fecharDialog = () => {
        callEvent(AppEventEnum.DialogEditarGerenciamentoSessao, false)
        fecharDialogEditarGerenciamentoSessao()
    }
    
    return (
        <DialogSaurus
            aberto={aberto}
            titulo="Edição Sessão de Usuários"
            colorTitulo={theme.palette.primary.main}
            handleClickClose={fecharDialog}
            isButtonTitleClose
            tamanho='md'
            fullScreen={isMobile}
            bottomArea={
                <div className={acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={isMobile ? 12 : 3}>
                            <Button
                                variant='outlined'
                                color="primary"
                                fullWidth={isMobile}
                                onClick={clickImpressao}
                            >
                                <ImpressoraIcon tipo='BUTTON' />
                                Imprimir Sessão
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={isMobile ? 12 : 2}>
                            <Button
                                variant='outlined'
                                color='primary'
                                fullWidth={isMobile}
                                onClick={() => abrirConfigResumoSessao(sessao.id)}
                            >
                                <ConfiguracaoIcon tipo='BUTTON' />
                                Configurações
                            </Button>
                        </Grid>
                        {validarConferencia() && (
                            <Grid item xs={12} md={isMobile ? 12 : 7} style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={isMobile}
                                    onClick={async () => {
                                        // abrirDialogConfirmarConferenciaSessao(model)
                                        await enviarConferencia();
                                    }}
                                >
                                    <OkIcon tipo='BUTTON_PRIMARY' />
                                    Confirmar Conferência
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </div>
            }
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid container spacing={2}>
                {isMobile ? (
                    <>
                        <Grid item xs={12}>
                            <CardEdicaoGerenciamentoSessao
                                sessao={sessao}
                                sessaoValor={sessaoValores}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AccordionTipoLancamento
                                sessao={sessao}
                                sessaoValores={sessaoValores}
                                buttonOrForm={buttonOrForm}
                                getSessaoValorWrapper={getSessaoValorWrapper}
                            />
                        </Grid>
                        {!isEmpty(conteudotela) &&
                            <Grid item xs={12}>
                                <AccordionEdicaoGerenciamentoSessaoImpressaoMobile
                                    conteudotela={conteudotela}
                                    error={error}
                                    getResumo={getResumo}
                                />
                            </Grid>
                        }
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <CardEdicaoGerenciamentoSessao
                                sessao={sessao}
                                sessaoValor={sessaoValores}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {!isEmpty(sessaoValores) ? sessaoValores.map((item) => {
                                        return (
                                            <CardEdicaoGerenciamentoSessaoValor
                                                sessao={sessao}
                                                model={item}
                                                getSessaoValorWrapper={getSessaoValorWrapper}
                                            />
                                        )
                                    }) : null}
                                </Grid>
                                {!isConferida && (
                                    <Grid item xs={12}>
                                        {buttonOrForm}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classesGerenciamento.list}>
                            <Grid className={classesGerenciamento.containerResumo}>
                                {error ? (
                                    <Grid className={classesGerenciamento.resumo}>
                                        <Grid className={classesGerenciamento.containerError}>
                                            <Grid className={classesGerenciamento.imageError}>
                                                <CancelarIcon tipo="GERAL" fill="#D22" />
                                            </Grid>
                                            <Typography
                                                variant="body1"
                                                className={classesGerenciamento.textError}
                                            >
                                                Dados não sincronizados, por favor tente novamente mais tarde.
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={async () => {
                                                    await getResumo();
                                                }}
                                            >
                                                <RetornarIcon tipo="BUTTON_PRIMARY" />
                                                Tentar Novamente
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid className={classesGerenciamento.resumo}>
                                        {conteudotela && <ConteudoLeitura text={conteudotela} />}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </DialogSaurus>
    )
}