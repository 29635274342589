import { guidEmpty } from "utils/guid-empty";

export class NcmModel {
  constructor(
    public id: string = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public ncmId: string = guidEmpty(),
    public codigo: string = "",
    public descricao: string = "",
    public cest: string = "",
    public ativo: boolean = true,
    public pTribManual: number | null = null
  ) {}
}
