import { Typography } from '@material-ui/core';
import { CardEmpresaSelectProps } from './card-empresa-select-props';
import React, { useCallback } from 'react';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useDeleteDepositoEmpresa,  usePostDepositoEmpresa } from 'data/api/gestao/deposito';
import { DepositoEmpresaPostModel } from 'model/api/gestao/deposito/deposito-empresa-model-post';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { guidEmpty } from 'utils/guid-empty';
import { SwitchSaurus } from 'views/components/controles';
import { useStyles } from './card-empresa-select-styles'
import { LoadingButton } from 'views/components/utils';
import { isEmpty } from 'lodash';

export const CardEmpresaSelect = ({
  model,
  depositoId,
  index,
  temEmpresa,
  getDepositoWrapper
}: CardEmpresaSelectProps) => {

  const classes = useDefaultCardStyles();
  const { postDepositoEmpresa, carregando: carregandoPost } = usePostDepositoEmpresa()
  const { deleteDepositoEmpresa, carregando: carregandoDelete } = useDeleteDepositoEmpresa()
  const { getPessoa } = useSessaoAtual()
  const { showToast } = useToastSaurus()
  const utilClasses = useStyles()


  const carregando =
    carregandoDelete ||
    carregandoPost


  const delDepositoEmpresaId = useCallback(async () => {
    const res = await deleteDepositoEmpresa(temEmpresa.id ?? '')

    if (res.erro) throw res.erro

  }, [deleteDepositoEmpresa, temEmpresa])

  const saveDepositoEmpresa = useCallback(async () => {
    const res = await postDepositoEmpresa(new DepositoEmpresaPostModel(
      guidEmpty(),
      getPessoa().pessoa?.contratoId ?? '',
      model.Id,
      depositoId
    ))

    if (res.erro) throw res.erro

  }, [depositoId, getPessoa, model.Id, postDepositoEmpresa])

  const click = useCallback(async () => {
    try {
      if (!isEmpty(temEmpresa)) {
        await delDepositoEmpresaId()
        return;
      }

      await saveDepositoEmpresa()
    } catch (e: any) {
      showToast('error', e.message)
    } finally {
      await getDepositoWrapper()
    }
  }, [delDepositoEmpresaId, getDepositoWrapper, saveDepositoEmpresa, showToast, temEmpresa])
  
  return (
    <>
      <div className={`${classes.cardContent} ${index % 2 === 0 ? utilClasses.backgroundPar : ''}`}>
        <div className={classes.celulaGridFull}>
          <Typography
            variant="body1"
          >
            {model.Descricao}
          </Typography>
        </div>
        {carregando && (
          <div className={classes.celulaGrid}>
            <LoadingButton tipo="AZUL" />
          </div>
        )}
        <SwitchSaurus
          variant='LIGHT'
          checked={!isEmpty(temEmpresa)}
          onClick={click}
        />
      </div>
    </>
  );
};

export default React.memo(CardEmpresaSelect);
