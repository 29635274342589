import { guidEmpty } from 'utils/guid-empty';
export class NcmEditFormModel {
  constructor(
    public ncmId: string = guidEmpty(),
    public codigo: string = "",
    public descricao: string = "",
    public cest: string = "",
    public ativo: boolean = true,
    public pTribManual: string | null = null
  ) {}
}
