import * as Yup from "yup";

export const useFormMarcaEditValidation = () => {
  const FormMarcaEditYupValidation = Yup.object().shape({
    nome: Yup.string().required('O Nome é obrigatório.'),
  });

  return {
    FormMarcaEditYupValidation,
  };
};
