import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    height: theme.breakpoints.down('sm') ? 485 : 450,
    borderRadius: 4,
    border: '1px solid #E0E0E0',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  cardContainerDescricao: {
    padding: '8px'
  },
  cardDescricao: {
    minHeight: '65px',
    maxHeight: '65px'
  },
  cardDescricaoEscondida: {
    minHeight: 'unset'
  },
  cardDescricaoValor: {
    minHeight: '50px',
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardContainerDesconto: {
    paddingBottom: '8px'
  },
  cardItemDesconto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardItemDescontoValor: {
    marginLeft: '0px',
    display: 'flex',
    textDecoration: 'line-through',
    textDecorationColor: theme.palette.grey[400]
  },
  cardPrincingDesconto: {
    marginLeft: '0px',
    display: 'flex'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: '8px',
    marginRight: '8px'
  },
  cardOpcoes: {
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  cardLimiteVendas: {
    display: 'flex',
    justifyContent: 'center'
  },
  borderPlanSelect: {
    width: '100%',
    height: 485,
    borderRadius: 4,
    border: '1px solid ' + theme.palette.primary.main,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  borderMostPopularPlan: {
    width: '100%',
    height: 485,
    borderRadius: 4,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  backGroundTitlePlanSelect: {
    width: '100%',
    height: 30,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  backGroundTitleMostPopularPlan: {
    width: '100%',
    height: 30,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center'
  },
  backGroundTitle: {
    width: '100%',
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  cardTopTitle: {
    width: '100%',
    height: 30,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center'
  },
  textTopTitle: {
    color: '#fff',
    textAlign: 'center',
    paddingTop: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    padding: '8px',
    flexDirection: 'column'
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: 8
  },
  cardSubTitle: {
    marginLeft: 0,
    marginBottom: 0,
    color: '#777',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3
  },
  cardPricingFree: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginleft: 0,
    marginbottom: 0
  },
  cardPricingR$: {
    fontWeight: 'bold',
    marginleft: 0,
    marginbottom: 0,
    color: theme.palette.grey[400]
  },
  cardPricing: {
    fontWeight: 'bold',
    marginleft: 0,
    marginbottom: 0,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.grey[400]
  },
  cardTextPerMonth: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  textPerMonth: {
    fontWeight: 600
  },
  containerItem: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight:
      theme.breakpoints.down('sm') && theme.breakpoints.down('md')
        ? '165px'
        : '160px',
    overflowY: 'auto'
  },
  containerItemHideValor: {
    maxHeight: '250px'
  },
  cardOptionItem: {
    marginLeft: 1,
    marginBottom: 1,
    marginTop: 1
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  cardFooterText: {
    marginBottom: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    marginleft: 1
  },
  buttonViewMore: {
    margin: 1
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textRiscado: {
    textDecoration: 'line-through',
    color: theme.palette.grey[400],
    marginRight: '94px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginRight: 102
    }
  }
}));
