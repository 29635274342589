import { EnumMovModelo } from "model";
import { KeyValueModel } from "model/api";

export const MovModeloMock: KeyValueModel[] = [
    new KeyValueModel(EnumMovModelo.NOTAMANUAL, "Nota Manual"),
    new KeyValueModel(EnumMovModelo.ENTRADA, "Entrada"),
    new KeyValueModel(EnumMovModelo.NFCE, "NFCe"),
    new KeyValueModel(EnumMovModelo.NFE, "NFe"),
    new KeyValueModel(EnumMovModelo.ORCAMENTO, "Orçamento"),
    new KeyValueModel(EnumMovModelo.SAT, "SAT"),
    new KeyValueModel(EnumMovModelo.TROCA, "Troca"),

];

export const MovModeloTodosMock: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumMovModelo.ORCAMENTO, "Venda Simples"),
    new KeyValueModel(EnumMovModelo.NFCE, "NFCe"),
    new KeyValueModel(EnumMovModelo.NOTAMANUAL, "Nota Manual"),
    // new KeyValueModel(EnumMovModelo.ENTRADA, "Entrada"),
    new KeyValueModel(EnumMovModelo.NFE, "NFe"),
    new KeyValueModel(EnumMovModelo.SAT, "SAT"),
    new KeyValueModel(EnumMovModelo.TROCA, "Troca"),

];
