import { useCallback } from "react";
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { ButtonFab } from "views/components/controles";
import { Grid } from "@material-ui/core";
import { useCadastros } from "services/app";
import { ImpostoAddIcon } from '../../../../components/icons/imposto-add-icon';
import { ImpostoList } from './components/imposto-list/imposto-list';
import { ImpostoHeader } from './components/imposto-header/imposto-header';

export const ImpostosPage = () => {
    const classes = useDefaultCadastroStyles();
    const { abrirCadastroProdutoFiscal } = useCadastros();

    const onClickAdd = useCallback(() => {
        abrirCadastroProdutoFiscal("", "", true);
    }, [abrirCadastroProdutoFiscal]);

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <ImpostoHeader/>
            </Grid>
            <Grid className={classes.list}>
                <ImpostoList />
            </Grid>
            <ButtonFab
                tooltip="Adicionar Grupo de Impostos"
                icon={<ImpostoAddIcon tipo="BUTTON_FAB" />}
                onClick={onClickAdd}
            />
        </Grid>
    );
};

export default ImpostosPage;
