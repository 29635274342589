import { guidEmpty } from "utils/guid-empty";
import { EnumTpCalculoModificador } from "../../../enums/enum-tpcalculo-modificador";

export class ModificadorModel {
  constructor(
    public id: string | null = guidEmpty(),
    public contratoId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public tpCalculo: EnumTpCalculoModificador = EnumTpCalculoModificador.Soma
  ) { }
}
