import { Tooltip, Typography } from '@material-ui/core';
import { CardProdutoProps } from './card-produto-props';
import { memo, useCallback } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views';
import { toDecimalString } from 'utils/to-decimal';
import useOnPress from 'utils/useOnPress';

const CardProduto = ({ model, onClick, onHold }: CardProdutoProps) => {
  const classes = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.ativo ? theme.palette.success.main : theme.palette.error.main;
  }, [model.ativo, theme.palette.error.main, theme.palette.success.main]);

  const handlePress = () => {
    onHold(model);
  };

  const handleClick = async () => {
    onClick(model);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700
  };

  const EventPressAndClick = useOnPress(
    handlePress,
    handleClick,
    defaultOptions
  );

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return <img src={url} className={classes.cardImg} alt={model.nome} />;
      }

      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    } catch (err) {
      return (
        <img src={SemImagem} className={classes.cardImg} alt={model.nome} />
      );
    }
  };

  return (
    <>
      <div {...EventPressAndClick}>
        <DefaultCard>
          <Tooltip
            arrow
            title={model.ativo ? 'Habilitado' : 'Desabilitado'}
            placement="right"
          >
            <div
              className={classes.tagStatus}
              style={{ background: retornarCorStatus() }}
            ></div>
          </Tooltip>
          <div className={classes.cardContent}>
            <div style={{ width: '100%', padding: '4px 0' }}>
              {model.codigo && model.codigo.length > 0 && (
                <div style={{ minWidth: 100, display: 'flex', marginLeft: 8 }}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                    style={{ fontSize: 10 }}
                  >
                    Código: <strong>{model.codigo}</strong>
                  </Typography>
                </div>
              )}

              <div className={classes.cardContent}>
                <div className={classes.celulaGrid} style={{ marginLeft: 8 }}>
                  {img(model?.imagemUrl ?? '')}
                </div>

                <div className={classes.celulaGridFull}>
                  <Typography color="textPrimary" variant="caption">
                    Nome
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                    style={{
                      maxWidth: isMobile ? '155px' : '400px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {model.nome}
                  </Typography>
                </div>

                <div
                  className={classes.celulaGrid}
                  style={{ textAlign: 'right' }}
                >
                  <Typography color="textPrimary" variant="caption">
                    Valor de Venda
                  </Typography>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                  >
                    <Tooltip
                      arrow
                      title={
                        'R$ ' +
                        toDecimalString(model.vPreco) +
                        ' a cada ' +
                        model.medidaDesc
                      }
                      placement="right"
                    >
                      <strong>
                        R$ {toDecimalString(model.vPreco)} {model.medida}
                      </strong>
                    </Tooltip>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </DefaultCard>
      </div>
    </>
  );
};

export default memo(CardProduto, (prev, next) => {
  if (prev !== next) return false;
  return true;
});
