import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { DialogDefinicoes } from './dialog-modal/dialog/dialog-definicoes';

export const DialogDefinicoesVenda = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
      
    >
      {props.openned && <DialogDefinicoes />}
    </DefaultModal>
  );
};
