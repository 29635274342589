export class ComandasGerarModel {
    constructor(
        public comandaInicial: number = 0,
        public comandaFinal: number = 0,
        public prefixoRfId: string = '',
        public inicialRfId: number = 0,
        public finalRfId: number = 0,
        public substituir: boolean = true,
        public temRFID: boolean = false,
    ) { }
}

export class ComandasGerarFormModel extends ComandasGerarModel {
    constructor(
        public comandaInicial: number = 0,
        public comandaFinal: number = 0,
        public prefixoRfId: string = '',
        public inicialRfId: number = 0,
        public finalRfId: number = 0,
        public substituir: boolean = true,
        public temRFID: boolean = false,
        public prefixo: string = '',
    ) {
        super(comandaInicial, comandaFinal, prefixoRfId, inicialRfId, finalRfId, substituir, temRFID)
    }
}
