import { isEmpty } from 'lodash';
import { DefaultModal } from 'views/components/modals/components/default-modal';
import { useThemeQueries } from 'views/theme';
import UploadCargaCadastro from './components/upload-carga-cadastro/upload-carga-cadastro';
import UploadCargaItemVisualizacao from './components/upload-carga-item-visualizacao/upload-carga-item-visualizacao';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { UploadCargaPessoa, UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
export interface ModalProps {
  model?: UploadCargaPessoa | UploadCargaProduto;
  openned?: boolean;
  tipo: EnumUploadCargaTipo
}

export const UploadCargaModal = (props: ModalProps) => {
  const { isMobile } = useThemeQueries();
  return (
    <DefaultModal
      minWidth={isEmpty(props.model) || isMobile ? '400px' : '800px'}
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && isEmpty(props.model) && <UploadCargaCadastro />}
      {props.openned && !isEmpty(props.model) && (
        <UploadCargaItemVisualizacao tipo={props.tipo} model={props.model} />
      )}
    </DefaultModal>
  );
};
