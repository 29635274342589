import { Box, Button } from "@material-ui/core";
import { useStyles } from '../../controles/radio-list/radio-list-styles'
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { Controller, useForm } from "react-hook-form";
import { AutocompleteProdutoCategorias } from "views/components/controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias";
import { ProdutoCategoriaModel } from "model/api/gestao/produto/produto-categoria/produto-categoria-model";
import { guidEmpty } from "utils/guid-empty";

export interface PesquisaCategoriaFormModel {
    categoria: string;
}

interface FormPesquisaGenericoProps {
    onSubmit: (model: PesquisaCategoriaFormModel) => void;
    text: string;
    label?: string;
    placeholder?: string;
}

export const FormPesquisaCategoria = ({
    onSubmit,
    text,
    label = 'Pesquisar Categoria',
    placeholder = 'Digite aqui...',
}: FormPesquisaGenericoProps) => {

    const classes = useStyles();

    const {
        handleSubmit,
        control,
        setValue
    } = useForm<PesquisaCategoriaFormModel>({
        defaultValues: {
            categoria: text
        },
        criteriaMode: 'all',
        mode: 'onChange'
    });

    return (
        <Box className={classes.container}>
            <form onSubmit={() => handleSubmit(onSubmit)()} className={classes.text}>
                <Controller
                    name="categoria"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteProdutoCategorias
                            label={label}
                            placeholder={placeholder}
                            allowSubmit
                            {...field}
                            onChange={(value) => {
                                if (!value?.isString) {
                                    const categoria = value?.value as ProdutoCategoriaModel | null
                                    if (categoria?.id === guidEmpty()) {
                                        setValue('categoria', '')
                                        return
                                    }
                                    setValue('categoria', categoria?.nome ?? '')
                                }
                            }}
                        />
                    )}
                />
            </form>
            <Box className={classes.button}>
                <Button fullWidth variant='contained' color='primary' onClick={() => handleSubmit(onSubmit)()}>
                    <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    Confirmar
                </Button>
            </Box>
        </Box>
    )
}