import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useDeleteRetirada() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteRetirada = useCallback(
    (id: string,  documento: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documento}/retirada/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    deleteRetirada,
  };
}
