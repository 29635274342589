import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const FalhaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.3413 9.40034L41.1652 6.22429C40.2892 5.34605 38.8652 5.34605 37.9892 6.22429L25.2827 18.9307L12.5763 6.22429C11.7003 5.34605 10.2785 5.34605 9.40028 6.22429L6.22423 9.40034C5.34599 10.2763 5.34599 11.6981 6.22423 12.5764L18.9307 25.2828L6.22423 37.9892C5.34599 38.8652 5.34599 40.287 6.22423 41.1653L9.40028 44.3413C10.2763 45.2196 11.6981 45.2196 12.5763 44.3413L25.2827 31.6349L37.9892 44.3413C38.8652 45.2196 40.287 45.2196 41.1652 44.3413L44.3413 41.1653C45.2195 40.2893 45.2195 38.8652 44.3413 37.9892L31.6348 25.2828L44.3413 12.5764C45.2195 11.7004 45.2195 10.2786 44.3413 9.40034Z"
        />
      </DefaultIcon>
    </>
  );
};
