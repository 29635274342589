import * as Yup from "yup";

export const useFormProdutoResumoEditValidation = () => {

  const FormProdutoResumoEditYupValidation = Yup.object().shape({
    nome: Yup.string()
      .required("O nome é obrigatório")
      .min(3, "O tamanho mínimo do nome é de 3 caracteres"),
  });

  return {
    FormProdutoResumoEditYupValidation,
  };
};
