import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ModalLimiteProdutoAtingido } from "views/components/modals/modal-limite-produto-atingido/modal-limite-produto-atingido";

export const CadastroLimiteProdutoAtingidoModal = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
    });

    const modalAlterado = useCallback(({ openned }: any) => {
        setModalState({
            aberto: openned,
        });
    }, [])

    consoleDev('CadastroLimiteProdutoAtingidoModal')

    useEffect(() => {
        addHandler(AppEventEnum.ModalLimiteProdutoAtingido, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ModalLimiteProdutoAtingido, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <ModalLimiteProdutoAtingido aberto={modalState.aberto} key="mdlPessoa" />
    )
}