import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        margin:0,
        padding: theme.spacing(2),
        minHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            padding: 0
        }
    },
    cardContainer: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        transition: "0.3s ease-in-out border",
        width:'100%',
        maxWidth: 663,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    cardContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(2),
        '& div': {
            width: '50%',
        }
    },
    submitButton1: {
        boxShadow: theme.shadows[8],
        marginBottom: theme.spacing(1),
        zIndex: 2,
        paddingRight: '1rem',
    },
    submitButton2: {
        boxShadow: theme.shadows[8],
        marginBottom: theme.spacing(1),
        zIndex: 2,
        paddingRight:0
    },
}));