import { useCallback } from "react";
import { ConfigEmpresaModel } from "model/api/gestao/config-empresa/config-empresa-model";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { isEmpty } from "lodash";
import { useEventTools } from "./events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useToastSaurus } from "./toast-saurus";
import { ContratoConfigCompletoModel } from "model/api/gestao/master/contrato-completo-config";
import { useGetConfigContrato } from "data/api/gestao/contrato/get-contrato-configuracao";
import { PutConfigContratoProps, usePutConfigContrato } from "data/api/gestao/contrato/put-contrato-configuracao";
import { useSessaoAtual } from "../providers";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";

export const useContratoAtual = () => {
    const { getRegistro, setRegistro } = useGestaoStorage()
    const { callEvent } = useEventTools()

    const { getConfigContrato, carregando: carregandoConfigContrato } =
        useGetConfigContrato();
    const { putConfigContrato, carregando: carregandoPutConfig } = usePutConfigContrato()
    const { showToast } = useToastSaurus()
    const { getPessoa } = useSessaoAtual();

    const carregando =
        carregandoConfigContrato ||
        carregandoPutConfig

    const getConfigCompleto = useCallback((): ContratoConfigCompletoModel | undefined => {
        const ret = getRegistro(GestaoStorageKeys.ConfigContrato, false) as ContratoConfigCompletoModel;
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro])


    const getContratoConfig = useCallback(
        async () => {
            try {
                const res = await getConfigContrato(getPessoa().pessoa?.contratoId || '');

                if (res.erro) {
                    throw res.erro;
                }

                const data = res.resultado?.data as ConfigEmpresaModel[];

                return data
            } catch (e: any) {
                showToast('error', e.message)
            }
        },
        [getConfigContrato, getPessoa, showToast],
    );
    const refreshContrato = useCallback(async () => {
        const contrato = getConfigCompleto();
        const contratoConfig = await getContratoConfig()
        if (contratoConfig) {
            const novoObj = { ...contrato, configuracoes: [...contratoConfig] }
            setRegistro(GestaoStorageKeys.ConfigContrato, novoObj, false)
            callEvent(AppEventEnum.ContratoAtualizado, novoObj)
        }
    }, [callEvent, getConfigCompleto, getContratoConfig, setRegistro])

    const getConfigByCod = useCallback((cod: EnumContratoConfig): string | undefined => {
        const contratoAtual = getConfigCompleto();

        if (isEmpty(contratoAtual))
            return undefined;
        if (isEmpty(contratoAtual))
            return undefined;

        const contratoConfig =
            contratoAtual
                ?.configuracoes
                .find(x => x.cod === cod)
                ?.vConfig

        if (isEmpty(contratoConfig))
            return undefined

        return contratoConfig
    }, [getConfigCompleto])

    const putContratoConfig = useCallback(async (array: PutConfigContratoProps[]) => {
        const res = await putConfigContrato(array, getPessoa().pessoa?.contratoId || '')

        if (res.erro) {
            throw res.erro
        }

        try {
            await refreshContrato()
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getPessoa, putConfigContrato, refreshContrato, showToast])

    return {
        carregando,
        getConfigByCod,
        putContratoConfig,
        getConfigCompleto,
        getConfigContrato,
        refreshContrato,
        getContratoConfig
    }
}