import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const SalvarEditarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <g id="surface220190356">
                    <path d="M 36.800781 4 C 37.066406 4 37.320312 4.105469 37.507812 4.292969 L 45.707031 12.492188 C 45.894531 12.679688 46 12.933594 46 13.199219 L 46 24.972656 C 45.589844 24.890625 45.167969 24.851562 44.75 24.851562 C 44.5 24.851562 44.25 24.863281 44 24.894531 L 44 13.613281 L 37 6.613281 L 37 18 C 37 19.644531 35.644531 21 34 21 L 15 21 C 13.355469 21 12 19.644531 12 18 L 12 6 L 7 6 C 6.445312 6 6 6.445312 6 7 L 6 43 C 6 43.554688 6.445312 44 7 44 L 12 44 L 12 29 C 12 27.355469 13.355469 26 15 26 L 35 26 C 36.644531 26 38 27.355469 38 29 L 38 29.101562 L 36 31.121094 L 36 29 C 36 28.445312 35.554688 28 35 28 L 15 28 C 14.445312 28 14 28.445312 14 29 L 14 44 L 25.8125 44 L 25.246094 46 L 7 46 C 5.355469 46 4 44.644531 4 43 L 4 7 C 4 5.355469 5.355469 4 7 4 Z M 46 43 C 46 44.644531 44.644531 46 43 46 L 38.316406 46 L 40.332031 44 L 43 44 C 43.554688 44 44 43.554688 44 43 L 44 40.363281 L 46 38.378906 Z M 14 18 C 14 18.554688 14.445312 19 15 19 L 34 19 C 34.554688 19 35 18.554688 35 18 L 35 6 L 14 6 Z M 32 8 C 32.550781 8 33 8.449219 33 9 L 33 16 C 33 16.550781 32.550781 17 32 17 L 29 17 C 28.449219 17 28 16.550781 28 16 L 28 9 C 28 8.449219 28.449219 8 29 8 Z M 30 15 L 31 15 L 31 10 L 30 10 Z M 10 40 L 10 42 L 8 42 L 8 40 Z M 10 40 " />
                    <path d="M 44.632812 26.863281 C 43.832031 26.863281 43.132812 27.164062 42.433594 27.5625 C 42.234375 27.761719 42.03125 27.863281 41.832031 28.0625 L 41.53125 28.363281 L 41.234375 28.664062 L 28.832031 41.164062 C 28.734375 41.261719 28.632812 41.460938 28.53125 41.5625 L 26.832031 47.5625 C 26.632812 48.0625 26.933594 48.664062 27.53125 48.761719 C 27.734375 48.863281 27.933594 48.863281 28.03125 48.761719 L 34.03125 47.164062 C 34.234375 47.164062 34.332031 47.0625 34.53125 46.863281 L 46.933594 34.5625 L 47.632812 33.863281 C 47.832031 33.664062 47.933594 33.460938 48.132812 33.164062 C 48.234375 32.960938 48.433594 32.761719 48.53125 32.460938 C 48.832031 31.761719 48.933594 30.863281 48.734375 30.164062 C 48.734375 29.960938 48.632812 29.863281 48.53125 29.664062 C 48.53125 29.5625 48.53125 29.460938 48.433594 29.363281 C 48.332031 29.164062 48.234375 28.863281 48.03125 28.664062 C 47.933594 28.460938 47.734375 28.261719 47.53125 28.0625 C 46.933594 27.460938 46.234375 27.164062 45.53125 26.960938 L 45.433594 26.960938 C 45.234375 26.863281 44.933594 26.863281 44.632812 26.863281 C 44.734375 26.863281 44.632812 26.863281 44.632812 26.863281 Z M 42.53125 30.164062 L 45.53125 33.164062 L 34.03125 44.664062 L 31.03125 41.761719 Z M 30.03125 43.664062 L 32.132812 45.761719 L 29.234375 46.5625 Z M 30.03125 43.664062 " />
                </g>
            </DefaultIcon>
        </>
    );
};

