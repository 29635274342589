
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    position: "relative",
  },

  containerFinal: {
    display: "flex",
    justifyContent: "space-between",

  },
  btnPesquisa: {
    marginLeft: theme.spacing(1),
    width: "40px",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      margin: 0,
    }
  },
  campo: {
    backgroundColor: '#FFF'
  }

}));
