import { guidEmpty } from "../../../../utils/guid-empty";

export class PerfilUsuarioModel {
  constructor(
    public id: string = guidEmpty(),
    public email: string = '',
    public imagem: string = '',
    public nome: string = ''
  ) {}
}
