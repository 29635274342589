import { makeStyles } from '@material-ui/core'

export const useStylesSecundario = makeStyles(() => ({
    containerScann: {
        minWidth: '100%',
        background: 'black',

        "& .drawingBuffer": {
            display: 'none'
        },

        '& video': {
            minWidth: '100%',
            maxWidth: '100%',
            height: '150px',
            marginBottom: '-5px',
            objectFit: 'cover'
        }
    },
    semCameraIcon: {
        '& svg': {
            width: '70px',
            height: '70px',
        }
    }
}));

