import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoExisteCodigo() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getProdutoExisteCodigo = useCallback(
        (
            codigo: string
        ) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Codigo/${codigo}/Existe`,
                method: 'GET',
                enviarTokenUsuario: true
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getProdutoExisteCodigo
    };
}