export const dateParaCompetencia = (date: Date): number => {
  const ano = date.getFullYear();
  const mes = date.getMonth() + 1;
  let mesStr;
  if(mes < 10){
    mesStr = `0${mes}`
  }else{
    mesStr = `${mes}`
  }
 
  return Number(`${ano}${mesStr}`)
}