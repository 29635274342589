import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { DefaultCard, useDefaultCardStyles } from '../../../components';
import { CardOperadoresProps } from './card-operadores-props';
import { useCallback, useEffect } from 'react';
import { EditarIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useStyles } from './card-operadores-styles';
import { toDateString } from 'utils/to-date';
import { toDecimalString } from 'utils/to-decimal';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { isEmpty } from 'lodash';
import { useThemeQueries } from 'views/theme';
import { usePDV } from 'services/app/hooks/pdv';
import { useGetSessaoImpressao } from 'data/api/gestao/sessao/get-impressao';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { CircularLoading } from 'views/components/utils';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { ModuloResumoSessaoProps } from 'views/components/dialog/dialog-config-resumo-sessao/dialog-config-resumo-sessao';
import { AppEventEnum } from 'model/enums/enum-app-event';
import classNames from 'classnames';

export const CardOperadores = ({
  model,
  pontoDeVenda
}: CardOperadoresProps) => {
  //AUX
  const classes = useDefaultCardStyles();
  const classesOperador = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();

  //PROVIDERS
  const { getPDV, getImpressoraPdv } = usePDV();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { imprimirResumo } = useSessaoPDV();
  const { getRegistro } = useGestaoStorage();
  const { abrirDialogEdicaoGerenciamentoSessao, abrirConfigResumoSessao } =
    useCadastros();

  //CHAMADAS DA API
  const { getSessaoImpressao, carregando } = useGetSessaoImpressao();

  const getImpressaoSessaoWrapper = useCallback(async () => {
    try {
      const query = getRegistro(
        GestaoStorageKeys.ResumoSessao,
        false
      ) as ModuloResumoSessaoProps[];
      const filtros = query.map((filtro) => {
        return `&${filtro.query}=${filtro.selecionado}`.toString();
      });
      const queryTratada = filtros.join('');

      const qtdColuna = getPDV()?.configuracoes.find((p) => p.cod === 57);

      const res = await getSessaoImpressao(
        getEmpresaSelecionada()!.Id,
        model.caixaId,
        model.id,
        qtdColuna?.vConfig ?? 62,
        queryTratada
      );

      if (res.erro) {
        throw new Error(res.erro);
      }

      if (getImpressoraPdv()) {
        imprimirResumo(
          res?.resultado?.data?.texto ?? '',
          getPDV()?.numCaixa.toString() ?? ''
        );
      }

  
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [
    getEmpresaSelecionada,
    getImpressoraPdv,
    getPDV,
    getSessaoImpressao,
    imprimirResumo,
    showToast,
    getRegistro,
    model.caixaId,
    model.id
  ]);

  const retornarDescricaoStatus = useCallback(() => {
    if (isEmpty(model.dFechamento)) {
      return 'Sessâo Aberta';
    } else if (isEmpty(model.dConferencia)) {
      return 'Sessão Fechada';
    } else {
      return 'Sessão Conferida';
    }
  }, [model.dConferencia, model.dFechamento]);

  const ImpressaoClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      abrirConfigResumoSessao(model.id);
    },
    [abrirConfigResumoSessao, model.id]
  );

  const chamarResumo = useCallback(
    ({ aberto, sessaoId }: any) => {
      if (!aberto) {
        if (sessaoId === model.id) {
          const resumo = async () => {
            await getImpressaoSessaoWrapper();
          };
          resumo();
        }
      }
    },
    [getImpressaoSessaoWrapper, model.id]
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);

    return () => {
      removeHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);
    };
  }, [addHandler, chamarResumo, removeHandler]);
const valorTotal = model.valorVendido - model.valorCancelado
  return (
    <DefaultCard onClick={() => abrirDialogEdicaoGerenciamentoSessao(model)}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Tooltip arrow title={retornarDescricaoStatus()} placement="right">
        <div
          className={classNames(
            classes.tagStatus,
            isEmpty(model.dFechamento)
              ? classesOperador.statusPendente
              : isEmpty(model.dConferencia)
                ? classesOperador.statusFechada
                : classesOperador.statusConferida
          )}
        ></div>
      </Tooltip>

      {isMobile ? (
        <>
          <Grid container className={classesOperador.root}>
            <Grid item xs={3}>
              <Typography variant="caption" color="textPrimary">
                Caixa
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {pontoDeVenda.numCaixa}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="caption" color="textPrimary">
                Data de Lançamento
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {toDateString(model?.dAbertura, 'DD/MM/yyyy HH:mm')}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classesOperador.buttonImpressora}>
              <Tooltip arrow title="Imprimir Sessão">
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classesOperador.buttons}
                  onClick={ImpressaoClick}
                >
                  <ImpressoraIcon tipo="BUTTON" />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" color="textPrimary">
                Qtd de Itens
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {model.qtdItens}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" color="textPrimary">
                Cancelados
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {model.qtdCancelamentos}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" color="textPrimary">
                Valor Total
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {`R$ ${toDecimalString(valorTotal, 2)}`}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div className={classes.cardContent}>
            <div className={classes.celulaGrid}>
              <Typography variant="caption" color="textPrimary">
                Caixa
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {pontoDeVenda.numCaixa}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography variant="caption" color="textPrimary">
                Data de Lançamento
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {toDateString(model?.dAbertura, 'DD/MM/yyyy HH:mm')}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography variant="caption" color="textPrimary">
                Qtd de Itens
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {model.qtdItens}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography variant="caption" color="textPrimary">
                Cancelados
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {model.qtdCancelamentos}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Typography variant="caption" color="textPrimary">
                Valor Total
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {`R$ ${toDecimalString(valorTotal, 2)}`}
              </Typography>
            </div>
            <div className={classes.celulaGrid}>
              <Tooltip arrow title="Imprimir Sessão">
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classesOperador.buttons}
                  onClick={ImpressaoClick}
                >
                  <ImpressoraIcon tipo="BUTTON" />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Tooltip arrow title="Editar Sessão">
            <div className={classes.cardRightButton}>
              <EditarIcon tipo="BUTTON" />
            </div>
          </Tooltip>
        </>
      )}
    </DefaultCard>
  );
};
