import { DialogSaurus } from "views/components/dialog/dialog-saurus/dialog-saurus";
import { CircularLoading } from "views/components/utils";
import { EscaneandoComanda } from "views/pages/private/movimentacao/mov-leitor-comanda/components/escaneando-comanda/escaneando-comanda";

interface DialogScannRFIDProps {
    openned: boolean;
    closeDialog: () => void;
    confirmarComandaRFID: (rfid: string) => Promise<void>;
    erro?: string;
    loading?: boolean;
}

export const DialogScannRFID = ({ closeDialog, openned, confirmarComandaRFID, erro, loading }: DialogScannRFIDProps) => {

    return (
        <DialogSaurus
            aberto={openned}
            titulo="Leitura Comanda"
            tamanho='md'
        >
            {loading && <CircularLoading tipo='FULLSIZED' />}
            <EscaneandoComanda
                closeEscaneando={closeDialog}
                labelVoltar="Fechar"
                round
                confirmarComanda={confirmarComandaRFID}
                erroExterno={erro}
            />
        </DialogSaurus>
    )
}