import { useMemo } from 'react';
import { useValidationYupEmail } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormGerarComandaValidation = () => {

    const { emailYup } = useValidationYupEmail();

    const FormGerarComandaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                comandaId: Yup.string().required('Número da comanda é obrigatório.').typeError('Número da comanda é obrigatório'),
                cliente: Yup.object({
                    nomeFantasia: Yup.string().required('Nome Completo é obrigatório.'),
                    email: emailYup().notRequired(),
                    cpfCnpj: Yup.string().required('Documento é obrigatório.'),
                }),
                enderecoEntrega: Yup.object({
                    logradouro: Yup.string().notRequired(),
                    cep: Yup.string().notRequired(),
                    numero: Yup.string().notRequired(),
                    codigoMunicipio: Yup.string().notRequired(),
                    uf: Yup.string().notRequired(),
                    bairro: Yup.string().notRequired()
                })
            })
        )
    }, [emailYup])

    return {
        FormGerarComandaValidationYup,
    }
}

