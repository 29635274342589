import { EnumBalanca } from "../../../../enums/enum-balanca";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";

export class ProdutoCompletoPreCadastroFormModel {
  constructor(
    public nomeCategoria: string = "",
    public categoriaId: string | null = null,
    public codigo = "",
    public nome: string = "",
    public infAdic: string = "",
    public medidaId: string | null = null,
    public marcaId: string | null = null,
    public medida: string = "",
    public vCompra: string = "",
    public vPreco: string = "",
    public pLucro: string = "0",
    public codigoNcm: string = "",
    public ncmId: string | null = null,
    public impostoId: string | null = null,
    public imposto: string = "",
    public imagemUrl: string = "",
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public setorId: string | null = null,
    public nomeSetor: string = '',
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto
  ) { }
}
