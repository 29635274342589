import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  containerStatus: {
    borderRadius: '5px',
    padding: '8px',
    color: '#FFF',
    width: '160px',
    textAlign: 'center'
  },
  containerRoot: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default'
  },
  containerMobile: {
    padding: '8px'
  }
}));
