import { KeyValueModel } from "model/api";
import { EnumRegime } from '../../model/enums/enum-regime';

export const RegimeMock: KeyValueModel[] = [
    new KeyValueModel(EnumRegime.NAOSEAPLICA, "Não se Aplica"),
    new KeyValueModel(EnumRegime.MEI, "MEI"),
    new KeyValueModel(EnumRegime.SIMPLES_NACIONAL, "Simples Nacional"),
    new KeyValueModel(EnumRegime.SIMPLEX_NACIONAL_EX_SUBLIMITE_RECEITA_BRUTA, "Simples Nacional - Excesso de Sublimite"),
    new KeyValueModel(EnumRegime.LUCRO_REAL, "Lucro Real"),
    new KeyValueModel(EnumRegime.LUCRO_PRESUMIDO, "Lucro Presumido"),
];
