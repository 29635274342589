// TODO -> verificar endpoint se ele aceita email ou telefone e corrigir a logica para atender essa solicitação
import { Box, Button, Grid } from '@material-ui/core';
import { useRef, useState } from 'react';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { RecuperarSenhaConfirmarFormModel } from 'model/app';
import { useStyles } from './form-recuperar-senha-confirmar-styles';
import { useFormRecuperarSenhaConfirmarValidations } from './form-recuperar-senha-confirmar-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
export const FormRecuperarSenhaConfirmar = React.forwardRef<
  DefaultFormRefs<RecuperarSenhaConfirmarFormModel>,
  DefaultFormProps<RecuperarSenhaConfirmarFormModel>
>((props: DefaultFormProps<RecuperarSenhaConfirmarFormModel>, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const { FormRecuperarSenhaConfirmarYupValidations } =
    useFormRecuperarSenhaConfirmarValidations();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new RecuperarSenhaConfirmarFormModel('', '', ''),
  });
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<RecuperarSenhaConfirmarFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormRecuperarSenhaConfirmarYupValidations),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (form: RecuperarSenhaConfirmarFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({
        modeloForm: new RecuperarSenhaConfirmarFormModel('', '', ''),
      });
      reset();
      if (!isMobile) txtEmail?.current?.focus();
    },
    fillForm: (model: RecuperarSenhaConfirmarFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtEmail?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="EMAIL"
                    disabled={true}
                    fullWidth={true}
                    showStartAdornment={false}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="E-mail para Recuperação"
                    placeholder="usuario@empresa.com.br"
                    error={Boolean(errors.email && errors.email.message)}
                    helperText={
                      touchedFields.email || errors.email
                        ? errors.email?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={3}>
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit={false}
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      autoComplete={'off'}
                      label="Senha"
                      placeholder="Digite sua nova Senha"
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={3}>
                <Controller
                  name="confirmar"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      allowSubmit={true}
                      disabled={props.loading}
                      showStartAdornment={true}
                      fullWidth={true}
                      autoComplete={'off'}
                      label="Confirmação da Senha"
                      placeholder="Confirme sua nova Senha"
                      error={Boolean(
                        errors.confirmar && errors.confirmar.message,
                      )}
                      helperText={
                        touchedFields.confirmar || errors.confirmar
                          ? errors.confirmar?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
