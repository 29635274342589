import { Divider, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-acoes-pedido-styles';
import { useCallback, useMemo, useState } from 'react';
import { ArrowRigthIcon } from 'views/components/icons/arrow-rigth-icon';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { CircularLoading } from 'views/components/utils';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useThemeQueries } from 'views/theme';
import { isEmpty } from 'lodash';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { guidEmpty } from 'utils/guid-empty';
import { StatusPedidoMock } from 'data/mocks/status-pedido-mock';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { isPlanoFarmaceutico } from 'utils/plano-utils';

interface DialogAcoesPedidoProps {
  aberto: boolean;
  pedido: PedidoModel;
  carregarInfoPedido: () => Promise<void>;
  titulo?: string;
}

export const DialogAcoesPedido = ({
  aberto,
  pedido,
  carregarInfoPedido,
  titulo
}: DialogAcoesPedidoProps) => {
  // HOOKS
  const { fecharDialogAcoesPedidos, abrirDialogAlterarMesaPedido } = useCadastros();

  // CHAMADAS API
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();

  // AUX
  const { getEmpresaSelecionada, getPermissaoBoolean, plano } = useSessaoAtual();
  const { removerItensdoPedidodaVendaSimples } = useMovAtual();
  const classesComponent = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { showToast } = useToastSaurus();
  const carregando = carregandoPutPedidoStatus;
  const { theme } = useThemeQueries()
  const { abrirSolicitarPermissao } = useCadastros();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { getEmpresaAtual } = useEmpresaAtual()
  const isFarma = isPlanoFarmaceutico(plano?.plano);

  const onCloseClick = useCallback(() => {
    fecharDialogAcoesPedidos();
  }, [fecharDialogAcoesPedidos]);

  const cancelarPedido = useCallback(async () => {
    try {
      await removerItensdoPedidodaVendaSimples(pedido.id);
      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        EnumStatusPedido.CANCELADO,
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao alterar status do pedido para Cancelado. Detalhe: ${respostaPutStatus.erro}`,
        );
      }
      showToast('success', 'Pedido cancelado com sucesso.');
      fecharDialogAcoesPedidos();
      return history.push('/venda-simples/pedidos')
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [fecharDialogAcoesPedidos, getEmpresaSelecionada, history, pedido.id, putPedidoStatus, removerItensdoPedidodaVendaSimples, showToast]);

  const handleClickCancelarPedido = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Cancelar Pedido',
      description:
        'Deseja mesmo cancelar o pedido? Essa alteração não poderá ser desfeita.',
    }).then(cancelarPedido);
  }, [cancelarPedido, confirm]);

  const checarPermissao = useCallback(() => {
    const permitido = getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_CUPOM)

    //caso não tenha permissão
    if (!permitido) {
      abrirSolicitarPermissao(
        async () => {
          handleClickCancelarPedido();
        }, EnumCodigosPermissoes.CANCELAMENTO_CUPOM, 'cancelar o pedido')
      return
    }
    //

    handleClickCancelarPedido();
  }, [getPermissaoBoolean, handleClickCancelarPedido, abrirSolicitarPermissao])

  const reabrirPedido = useCallback(async () => {
    try {
      await removerItensdoPedidodaVendaSimples(pedido.id);

      if (pedido.statusPedido.codigo === EnumStatusPedido.CONFIRMADO) {
        throw new Error(`O pedido já está aberto.`);
      }

      const respostaPutStatus = await putPedidoStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        EnumStatusPedido.CONFIRMADO,
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao tentar reabrir o pedido. Detalhe: ${respostaPutStatus.erro}`,
        );
      }
      showToast('success', 'O pedido foi reaberto com sucesso.');
      await carregarInfoPedido()
      onCloseClick()
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [carregarInfoPedido, getEmpresaSelecionada, onCloseClick, pedido.id, pedido.statusPedido.codigo, putPedidoStatus, removerItensdoPedidodaVendaSimples, showToast]);

  const handleClickReabrirPedido = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Reabrir Pedido',
      description: 'Deseja reabrir o pedido atual?',
    }).then(reabrirPedido);
  }, [confirm, reabrirPedido]);

  const handleAlterarMesa = useCallback(() => {
    abrirDialogAlterarMesaPedido(pedido, carregarInfoPedido);
  }, [abrirDialogAlterarMesaPedido, carregarInfoPedido, pedido]);

  const handleTransferirItens = useCallback(() => {
    fecharDialogAcoesPedidos();
    return history.push({
      pathname: '/venda-simples/transferir-itens',
      search: history.location.search,
      state: {
        pedido,
        transferirPedido: true,
        alterarVendedor: false,
        vendedor: null,
      },
    });
  }, [fecharDialogAcoesPedidos, history, pedido]);

  const handleAlterarVendedorItem = useCallback(() => {
    fecharDialogAcoesPedidos();
    return history.push({
      pathname: '/venda-simples/alterar-vendedor',
      search: history.location.search,
      state: { pedido },
    });
  }, [fecharDialogAcoesPedidos, history, pedido]);

  const handleFinalizacaoParcialComanda = useCallback(() => {
    fecharDialogAcoesPedidos();
    return history.push({
      pathname: '/venda-simples/finalizacao-parcial',
      search: history.location.search,
      state: { pedido },
    });
  }, [fecharDialogAcoesPedidos, history, pedido]);

  const isDelivery = useMemo(() => {
    const hasComandaId = !isEmpty(pedido.comandaId) && pedido.comandaId !== guidEmpty()
    const hasMesaId = !isEmpty(pedido.mesaId) && pedido.mesaId !== guidEmpty()
    if (hasMesaId || hasComandaId) {
      return false
    }

    return true
  }, [pedido.comandaId, pedido.mesaId])


  const handleStatusClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const wrapperClick = (click: () => any) => {
    click();
    handleClose();
  };

  const alterarStatus = useCallback(async (status: EnumStatusPedido) => {
    try {

      const res = await putPedidoStatus(getEmpresaAtual()?.id ?? '', pedido.id, status)
      if (res.erro) throw res.erro
      showToast('success', `Pedido #${pedido.codigoPedido} alterado para o status ${StatusPedidoMock.find(x => x.Key === status)?.Value ?? ''}`)
    } catch (err: any) {
      showToast('error', "Problema ao alterar o status.Detalhe: " + err.message)
    }
  }, [getEmpresaAtual, pedido.codigoPedido, pedido.id, putPedidoStatus, showToast])

  const statusOptions = useMemo(() => {
    let options: MenuOptionsModel[] = StatusPedidoMock.filter(x => pedido.statusPedido.codigo !== x.Key).map((x) => {
      return new MenuOptionsModel(
        x.Value,
        <ArrowRigthIcon tipo="BUTTON" class={classesComponent.editIcon} />,
        () => alterarStatus(x.Key),
      )
    })

    return options
  }, [alterarStatus, classesComponent.editIcon, pedido.statusPedido.codigo])

  // const handleAlterarEndereco = useCallback(() => {
  //   history.push({
  //     pathname: '/venda-simples/identificar-cliente-delivery',
  //     state: {
  //       pedido: pedido
  //     }
  //   })
  //   fecharDialogAcoesPedidos()
  // }, [fecharDialogAcoesPedidos, history, pedido])

  return (
    <>
      <DialogSaurus
        aberto={aberto || false}
        isButtonTitleClose
        handleClickClose={onCloseClick}
        titulo={titulo ? titulo : "Opções"}
        colorTitulo={theme.palette.primary.main}
        tamanho="xs"
      >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid className={classesComponent.cardContainer}>
          <Grid className={classesComponent.cardContent}>
            {(!isFarma && !isDelivery) && <>
              <Grid
                className={classesComponent.acoes}
                onClick={handleAlterarMesa}
              >
                <Typography>Alterar Mesa</Typography>
                <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
              </Grid>
              <Divider className={classesComponent.divider} />
            </>}
            <Grid
              className={classesComponent.acoes}
              onClick={handleTransferirItens}
            >
              <Typography>Transferir Itens</Typography>
              <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
            </Grid>
            <Divider className={classesComponent.divider} />
            <Grid
              className={classesComponent.acoes}
              onClick={checarPermissao}
            >
              <Typography>Cancelar Pedido</Typography>
              <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
            </Grid>
            <Divider className={classesComponent.divider} />
            <Grid
              className={classesComponent.acoes}
              onClick={handleClickReabrirPedido}
            >
              <Typography>Reabrir Pedido</Typography>
              <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
            </Grid>
            <Divider className={classesComponent.divider} />
            <Grid
              className={classesComponent.acoes}
              onClick={handleAlterarVendedorItem}
            >
              <Typography>Alterar Vendedor</Typography>
              <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
            </Grid>
            {
              !isEmpty(pedido.produtos)
              && !isDelivery
              && (
                <>
                  <Divider className={classesComponent.divider} />
                  <Grid
                    className={classesComponent.acoes}
                    onClick={handleFinalizacaoParcialComanda}
                  >
                    <Typography>Finalização Parcial</Typography>
                    <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
                  </Grid>
                </>
              )}
            {isDelivery && (
              <>
                <Divider className={classesComponent.divider} />
                <Grid
                  className={classesComponent.acoes}
                  onClick={handleStatusClick}
                  aria-label="more" aria-haspopup="true"
                >
                  <Typography>Alterar Status</Typography>
                  <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
                </Grid>
                <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                  {statusOptions.map((option, index) => option ? (
                    <MenuItem key={index} onClick={() => wrapperClick(option.click!)}>
                      {option.icon}
                      {option.label}
                    </MenuItem>
                  ) : null)}
                </Menu>
                {/* <Divider className={classesComponent.divider} />
                <Grid
                  className={classesComponent.acoes}
                  onClick={handleAlterarEndereco}
                  aria-label="more" aria-haspopup="true"
                >
                  <Typography>Alterar Dados do Cliente</Typography>
                  <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
                </Grid> */}
              </>
            )}
          </Grid>
        </Grid>
      </DialogSaurus>
    </>
  );
};
