import { useStyles } from "./loading-finalizacao-styles";

export const LoadingFinalizacao = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.background}>
                    <div className={classes.cLoader}>
                        <div className={classes.backgroundLoader}></div>
                    </div>
            </div>
        </>
    )
}
