import { Box, Typography } from '@material-ui/core';
import { useStyles } from './icon-card-styles';
import React from 'react';
import DragDropCard from './components/drag-drop-card';
import classNames from 'classnames';

export interface IconCardProps<T> {
  icon: React.ReactNode;
  title: string;
  onClick: (model: T) => any;
  model: T;
  children?: React.ReactNode;
  description?: string;
  index?: number;
  onDrop?: (current: number) => void;
  draggable?: boolean;
  setDraggingIndex?: (index: number) => void;
  size?: 'normal' | 'small';
  wrapText?: boolean;
  fontSize?: number;
  isOpacity?: boolean;
}

export function IconCard<T>(props: IconCardProps<T>) {
  const classes = useStyles();

  const renderChildren = [
    Boolean(props.children) && (
      <Box className={classes.childrenItem}>{props.children}</Box>
    ),
    props.icon,
    props.index !== undefined && (
      <Typography align="center" variant="caption">
        {(props.index || 0) + 1}
      </Typography>
    ),
    <Typography color="primary" align="center">
      {props.title}
    </Typography>,
    Boolean(props.description) && (
      <Typography color="textSecondary" variant="caption" align="center">
        {props.description}
      </Typography>
    )
  ];

  return (
    <DragDropCard<T>
      {...props}
      childs={renderChildren}
      card={
        <div
          className={classNames(
            classes.card,
            Boolean(props.children) ? classes.cardFlexStart : undefined,
            props.size === 'small' ? classes.cardSmall : undefined
          )}
          onClick={() => props.onClick(props.model)}
          style={{ opacity: props.isOpacity ? 1 : 0.6 }}
        >
          {Boolean(props.children) && (
            <Box className={classes.childrenItem}>{props.children}</Box>
          )}
          {props.icon}
          {props.index !== undefined && (
            <Typography
              style={{
                whiteSpace: props.wrapText ? 'unset' : 'nowrap',
                fontSize: props.fontSize || 'unset'
              }}
              align="center"
              variant="caption"
            >
              {(props.index || 0) + 1}
            </Typography>
          )}
          <Typography
            color="primary"
            align="center"
            style={{
              whiteSpace: props.wrapText ? 'unset' : 'nowrap',
              fontSize: props.fontSize || 'unset'
            }}
          >
            {props.title}
          </Typography>
          {Boolean(props.description) && (
            <Typography
              color="textSecondary"
              variant="caption"
              align="center"
              style={{
                whiteSpace: props.wrapText ? 'nowrap' : 'unset',
                fontSize: props.fontSize || 'unset'
              }}
            >
              {props.description}
            </Typography>
          )}
        </div>
      }
    />
  );
}
