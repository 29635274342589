import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    button: {
        marginTop: "10px",
        marginBottom: "8px",
        width: "250px"
    },
    iconArea: {
        width: "130px",
        height: "130px",
        marginBottom: "100px"
    },
    message: {
        color: theme.palette.text.secondary,
        fontWeight: 600,
        marginBottom: "50px"
    }
}));
