import { VariaveisAmbiente } from "config";
import { SessaoSincronizar } from "model/api/gestao/sessao/sessao-valor-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { guidEmpty } from "utils/guid-empty";

export function usePostSincronizarValores() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postSincronizarValores = useCallback(
        (empresaId: string, caixaId: string, valores: SessaoSincronizar[]) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${caixaId}/sincronizacao`,
                method: "POST",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(valores.map((item) => {
                    let portadorId = item.portadorId === guidEmpty() ? null : item.portadorId
                    return {
                        ...item,
                        portadorId
                    }

                })),
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postSincronizarValores,
    };
}
