import { Box, Grid, Typography } from "@material-ui/core";
import { StatusPedidoMock } from "data/mocks/status-pedido-mock";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { CardDeliveryAtendimento } from "views/components/cards/card-delivery-atendimento/card-delivery-atendimento";
import { AccordionListStatusProps } from "./accordion-list-status-props";
import { useStyles } from "./accordion-list-status-styles";
import { useState } from "react";
import { useThemeQueries } from "views/theme";

export const AccordionListStatus = ({ onCardHolded, onCardSelected, pedidos, statusPedido, atualizarLista, index, ...props }: AccordionListStatusProps) => {
    const classes = useStyles()
    const { theme } = useThemeQueries()
    const [open, setOpen] = useState<boolean>(index === 0)

    const pedidosMesmoStatus = pedidos.filter(pedido => pedido.statusPedido.codigo === statusPedido)
    const statusValue = StatusPedidoMock.find(status => status.Key === statusPedido)?.Value ?? 'Novo'

    return (
        <AccordionSaurus
            labelPrimary={statusValue}
            labelSecondary={
                <Box className={classes.numeroPedidos}>
                    <Typography variant="body1">
                        {pedidosMesmoStatus.length}
                    </Typography>
                </Box>}
            noPaddingContent
            tipoExpand="bold"
            colorExpand={theme.palette.primary.main}
            noPaperRoot
            expanded={open}
            className={classes.accordion}
            onChange={() => setOpen(prev => !prev)}
        >
            <Grid item xs={12} spacing={2} className={classes.containerListCard}>
                {pedidosMesmoStatus.map((pedido) => {
                    const key = `card-delivery-atendimento-${statusValue}-${pedido.id}`
                    return (
                        <CardDeliveryAtendimento
                            model={pedido}
                            key={key}
                            onClick={onCardSelected}
                            onHold={onCardHolded}
                            atualizarLista={atualizarLista}
                        />
                    );
                })}
            </Grid>
        </AccordionSaurus>
    )
}