import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { MarcaModel } from "model/api/gestao/marca/marca-model";

export function usePutMarca() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putMarca = useCallback(
    (marca: MarcaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/marca/${marca.id}`,
        method: 'PUT',
        enviarTokenUsuario: true,
        data: marca
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putMarca,
  };
}