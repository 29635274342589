export function validaEan(numero: string) {
    let factor = 3;
    let sum = 0;
    let numlen = numero.length;

    if (numlen === 8 || numlen === 12 || numlen === 13 || numlen === 14) {

        for (let index = numlen - 1; index > 0; --index) {
            if (index !== 13) {
                sum = sum + Number(numero.substring(index - 1, index)) * factor;
                factor = 4 - factor;
            }
        }
        const cc = ((1000 - sum) % 10);

        const ca = Number(numero.substring(numlen - 1));

        if (cc === ca) {
            return true
        }
        return false
    }
}