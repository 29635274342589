import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';

export const ModulosFavoritosPadraoMock: MenuModel[] = [
    // MODULOS FILHOS
    new MenuModel(
        'Lançamento Avulso',
        '',
        EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO,
        [],
        '/venda-simples/avulso',
        0,
        EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS,
        EnumMenu.PDV,
        undefined,
        true
    ),
    new MenuModel(
        'Catálogo de Produtos',
        '',
        EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO,
        [],
        '/venda-simples/catalogo',
        0,
        EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS,
        EnumMenu.PDV,
        undefined,
        true
    ),
    new MenuModel(
        'Retaguarda',
        '',
        EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA,
        [],
        '/dashboard',
        0,
        EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO,
        EnumMenu.PDV,
        undefined,
        true
    )
];

export const ModulosDeliveryPadraoMock: MenuModel[] = [
    // MODULOS FILHOS
    new MenuModel(
        'Catálogo de Produtos',
        '',
        EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO,
        [],
        '/venda-simples/catalogo',
        0,
        EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS,
        EnumMenu.PDV,
        undefined,
        true
    ),
    new MenuModel(
        'Revisão',
        '',
        EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO,
        [],
        '/venda-simples/carrinho',
        0,
        EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS,
        EnumMenu.PDV,
        undefined,
        true
    ),

];
