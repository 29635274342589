import * as Yup from "yup";
import { useMemo } from "react";

export const useFormMedicamentoProdutoValidation = (valoresEncontrados: boolean = false) => {

  const FormMedicamentoProdutoYupValidation = useMemo(() => {
    return Yup.object().shape({
      codigoAnvisa: Yup.string().required('Código ANVISA é obrigatório').typeError('Codigo ANVISA é obrigatório'),
      codigoEan: Yup.string().required('Código EAN é obrigatório').typeError('Codigo EAN é obrigatório'),
      qtdEmbalagem: Yup.number().required('Informe a Qtde. Embalagem').typeError('Informe a Qtde. Embalagem'),
      nome: Yup.string().required('Informe o nome do medicamento').typeError('Informe o nome do medicamento')
    });
  }, []);

  return {
    FormMedicamentoProdutoYupValidation,
  };
};