import { guidEmpty } from "utils/guid-empty";
import { EnumIndEscala } from "../../../../enums/enum-ind-escala";
import { EnumOrigemMercadoria } from "../../../../enums/enum-origem-mercadoria";
import { EnumBalanca } from "../../../../enums/enum-balanca";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
export class ProdutoPrincipalEditFormModel {
  constructor(
    public categoriaId: string | null = null,
    public nomeCategoria: string = "",
    public medidaEntradaId: string = guidEmpty(),
    public medidaEntrada: string = "",
    public medidaSaidaId: string = guidEmpty(),
    public medidaSaida: string = "",
    public nome: string = "",
    public descricao: string = "",
    public infAdic: string = "",
    public ncmId: string | null = null,
    public codigoNcm: string = "",
    public marca: string = "",
    public marcaId: string | null = null,
    public cnpjFab: string = "",
    public orig: EnumOrigemMercadoria = EnumOrigemMercadoria.OM_NACIONAL,
    public indEscala: EnumIndEscala = EnumIndEscala.N,
    public vCompra: string = "",
    public vPreco: string = "",
    public pLucro: string = "0",
    public imagemUrl: string = "",
    public codigo: string = "",
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public qSaldoTotal: number = 0,
    public ativo: boolean = true,
    public impostoId: string | null = null,
    public imposto: string = "",
    public setorId: string | null = null,
    public nomeSetor: string = '',
    public cobraTaxaServico: boolean = true,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
    public codigoAnvisa: string | null = null,
    public precoFabricacao: number = 0,
    public precoMaximoConsumidor: number = 0,
  ) { }
}
