import {
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ModalHeader } from 'views/components/modals/components';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import { CircularLoading } from 'views/components/utils';
import { useStyles } from './pessoa-edicao-styles';
import { FormPessoaEdit } from 'views/components/form/pessoa/form-pessoa-edit';
import { PessoaEditDadosFormModel } from 'model/app/forms/pessoa/pessoa-edit-form-model';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import {
  useDeleteEnderecoPessoa,
  useGetEnderecoPessoa,
  useGetPessoaById,
  usePostEnderecoPessoa,
  usePutEnderecoPessoa,
  usePutPessoa
} from 'data/api/gestao/pessoa';
import {
  PessoaContatosModel,
  PessoaEnderecoModel,
  PessoaModel
} from 'model/api/gestao/pessoa';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import {
  EditarIcon,
  InformacaoIcon,
  LixoIcon,
  NovoIcon,
  SalvarEditarIcon,
  VoltarIcon
} from 'views/components/icons';
import classNames from 'classnames';
import {
  FormPessoaEndereco,
  FormPessoaEnderecoRefs
} from 'views/components/form/pessoa/form-pessoa-endereco/form-pessoa-endereco';
import { FormPessoaEditRefs } from 'views/components/form/pessoa/form-pessoa-edit/form-pessoa-edit';
import { picker } from 'utils/picker';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { CardPessoaEndereco } from 'views/components/cards/card-pessoa-endereco/card-pessoa-endereco';
import { guidEmpty } from 'utils/guid-empty';
import { TpCadastroFarmaciaMock, UFMock } from 'data/mocks';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { CardPessoaContato } from 'views/components/cards/card-pessoa-contato/card-pessoa-contato';
import { usePostContatoPessoa } from 'data/api/gestao/pessoa/post-contato-pessoa';
import { usePutContatoPessoa } from 'data/api/gestao/pessoa/put-contato-pessoa';
import { FormPessoaContato } from 'views/components/form/pessoa/form-pessoa-contato/form-pessoa-contato';
import { useDeleteContatoPessoa } from 'data/api/gestao/pessoa/delete-contato-pessoa';
import { FormPessoaDocumento } from 'views/components/form/pessoa/form-pessoa-documento/form-pessoa-documento';
import { DefaultFormRefs } from 'views/components/form/utils';
import { PessoaDocumentoModel } from 'model/api/gestao/pessoa/pessoa-documento-model';
import { usePostDocumentoPessoa } from 'data/api/gestao/pessoa/post-documento-pessoa';
import { usePutDocumentoPessoa } from 'data/api/gestao/pessoa/put-documento-pessoa';
import { CardPessoaDocumento } from 'views/components/cards/card-pessoa-documento/card-pessoa-documento';
import { useDeleteDocumentoPessoa } from 'data/api/gestao/pessoa/delete-documento-pessoa';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { ConsultaCNPJModel, EnumCadastroStatus, EnumCadastroTipo } from 'model';
import { SwitchSaurus } from 'views/components/controles';
import { RadioList } from '../../../../controles/radio-list/radio-list';
import { CardNaoEncontrado } from 'views/components/cards';
import { isEmpty } from 'lodash';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { PutConfigContratoProps } from 'data/api/gestao/contrato/put-contrato-configuracao';
import { useMenuOptionsStyles } from 'views/components/menu-options/menu-options-styles';
import { GenericForm } from 'views/components/form/components/generic-form';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { stringNumeros } from 'utils/string-numeros';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useHistory } from 'react-router-dom';
import { useThemeQueries } from 'views/theme';
import { useConfirm } from 'material-ui-confirm';

enum EnumAbaEdicaoPessoa {
  Dados,
  Documentos
}

interface AccordionsStateProps {
  emails: boolean;
  telefones: boolean;
  enderecos: boolean;
}

interface FormComponentModel {
  component: React.ReactNode;
  title: string;
}

interface PessoaEdicaoProps {
  id: string;
}

export const PessoaEdicao = ({ id }: PessoaEdicaoProps) => {
  //HOOKS E ESTILOS
  const modalClasses = useModalStyles();
  const classes = useStyles();
  const menuClasses = useMenuOptionsStyles({});
  const { showToast } = useToastSaurus();
  const { fecharCadastroPessoa, abrirCadastroPessoa } = useCadastros();
  const { plano } = useSessaoAtual();

  //REFS E STATES
  const refEditForm = useRef<FormPessoaEditRefs>(null);
  const refEnderecoForm = useRef<FormPessoaEnderecoRefs>(null);
  const refDocumentoForm = useRef<DefaultFormRefs<PessoaDocumentoModel>>(null);
  const [abaSelecionada, setAbaSelecionada] = useState<EnumAbaEdicaoPessoa>(
    EnumAbaEdicaoPessoa.Dados
  );
  const [pessoaModel, setPessoaModel] = useState<PessoaModel>(
    new PessoaModel()
  );
  const [enderecos, setEnderecos] = useState<PessoaEnderecoModel[]>([]);
  const [telefones, setTelefones] = useState<PessoaContatosModel[]>([]);
  const [emails, setEmails] = useState<PessoaContatosModel[]>([]);
  const [formComponent, setFormComponent] = useState<FormComponentModel | null>(
    null
  );
  const [accordions, setAccordions] = useState<AccordionsStateProps>({
    emails: false,
    enderecos: false,
    telefones: false
  });
  const [changeTipoDialog, setChangeTipoDialog] = useState<boolean>(false);
  const { theme } = useThemeQueries()

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
  const confirm = useConfirm()

  //HOOKS DE CHAMADA À API
  const { putPessoa, carregando: carregandoPutPessoa } = usePutPessoa();
  const { putEnderecoPessoa, carregando: carregandoPutEndereco } =
    usePutEnderecoPessoa();
  const { postEnderecoPessoa, carregando: carregandoPostEndereco } =
    usePostEnderecoPessoa();
  const { getPessoaById, carregando: carregandoGet } = useGetPessoaById();
  const { getEnderecoPessoa, carregando: carregandoGetEndereco } =
    useGetEnderecoPessoa();
  const { postContatoPessoa, carregando: carregandoPostContato } =
    usePostContatoPessoa();
  const { putContatoPessoa, carregando: carregandoPutContato } =
    usePutContatoPessoa();
  const { deleteContatoPessoa, carregando: carregandoDeleteContato } =
    useDeleteContatoPessoa();
  const { deleteEnderecoPessoa, carregando: carregandoDeleteEndereco } =
    useDeleteEnderecoPessoa();
  const { postDocumentoPessoa, carregando: carregandoPostDocumento } =
    usePostDocumentoPessoa();
  const { putDocumentoPessoa, carregando: carregandoPutDocumento } =
    usePutDocumentoPessoa();
  const { deleteDocumentoPessoa, carregando: carregandoDeleteDocumento } =
    useDeleteDocumentoPessoa();
  const {
    putContratoConfig,
    getConfigByCod,
    carregando: carregandoContratoAtual
  } = useContratoAtual();

  const carregando =
    carregandoPutPessoa ||
    carregandoGet ||
    carregandoGetEndereco ||
    carregandoPutEndereco ||
    carregandoPostEndereco ||
    carregandoPostContato ||
    carregandoPutContato ||
    carregandoDeleteContato ||
    carregandoDeleteEndereco ||
    carregandoPostDocumento ||
    carregandoPutDocumento ||
    carregandoDeleteDocumento ||
    carregandoContratoAtual;

  const fillEditDadosForm = useCallback((model: PessoaEditDadosFormModel) => {
    refEditForm.current?.fillForm({ ...model });
  }, []);

  const getPessoaWrapper = useCallback(async () => {
    try {
      const res = await getPessoaById(id);

      if (res.erro) throw res.erro;

      const data = res.resultado?.data as PessoaModel;
      setPessoaModel(data);

      const telefone = data.contatos.filter(
        (contato) => contato.tipo === EnumTipoPessoaContato.TELEFONE
      );
      const emails = data.contatos.filter(
        (contato) => contato.tipo === EnumTipoPessoaContato.EMAIL
      );

      if (data.documentos.length === 1) {
        refDocumentoForm.current?.fillForm(data.documentos[0]);
      }

      setTelefones(telefone);
      setEmails(emails);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getPessoaById, id, showToast]);

  const getEnderecos = useCallback(async () => {
    try {
      const res = await getEnderecoPessoa(id);

      if (res.erro) throw res.erro;

      setEnderecos(res.resultado?.data);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEnderecoPessoa, id, showToast]);

  const saveEndereco = useCallback(
    async (model: PessoaEnderecoModel) => {
      const res = await putEnderecoPessoa(model);

      if (res.erro) throw res.erro;
    },
    [putEnderecoPessoa]
  );

  const criarEndereco = useCallback(
    async (model: PessoaEnderecoModel) => {
      const res = await postEnderecoPessoa(model);

      if (res.erro) throw res.erro;
      await getEnderecos();
    },
    [getEnderecos, postEnderecoPessoa]
  );

  const excluirEndereco = useCallback(
    async (id: string) => {
      try {
        const res = await deleteEnderecoPessoa(pessoaModel.id, id);

        if (res.erro) throw res.erro;

        getEnderecos();
        setFormComponent(null);

        showToast('success', 'Endereço removido com sucesso.');
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [deleteEnderecoPessoa, getEnderecos, pessoaModel.id, showToast]
  );

  const criarContato = useCallback(
    async (model: PessoaContatosModel) => {
      const res = await postContatoPessoa(model);

      if (res.erro) throw res.erro;

      return res.resultado?.data;
    },
    [postContatoPessoa]
  );

  const saveContato = useCallback(
    async (model: PessoaContatosModel) => {
      const res = await putContatoPessoa(model);

      if (res.erro) throw res.erro;
    },
    [putContatoPessoa]
  );

  const excluirContato = useCallback(
    async (id: string) => {
      try {
        const res = await deleteContatoPessoa(pessoaModel.id, id);

        if (res.erro) throw res.erro;

        getPessoaWrapper();
        setFormComponent(null);

        showToast('success', 'Contato removido com sucesso.');
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [deleteContatoPessoa, getPessoaWrapper, pessoaModel.id, showToast]
  );

  const criarDocumento = useCallback(
    async (model: PessoaDocumentoModel) => {
      const res = await postDocumentoPessoa(model);

      if (res.erro) throw res.erro;
    },
    [postDocumentoPessoa]
  );

  const saveDocumento = useCallback(
    async (model: PessoaDocumentoModel) => {
      const res = await putDocumentoPessoa(model);

      if (res.erro) throw res.erro;
    },
    [putDocumentoPessoa]
  );

  const excluirDocumento = useCallback(
    async (id: string) => {
      try {
        const res = await deleteDocumentoPessoa(pessoaModel.id, id);

        if (res.erro) throw res.erro;

        if (pessoaModel.documentos.length === 1) {
          refDocumentoForm.current?.fillForm(new PessoaDocumentoModel())
        }
        getPessoaWrapper();
        setFormComponent(null);
        showToast('success', 'Documento removido com sucesso.');
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [deleteDocumentoPessoa, getPessoaWrapper, pessoaModel.documentos.length, pessoaModel.id, showToast]
  );

  const saveDados = useCallback(
    async (model: PessoaModel) => {
      const res = await putPessoa(model);

      if (res.erro) throw res.erro;
    },
    [putPessoa]
  );

  const handleDocumento = useCallback(
    async (model: PessoaDocumentoModel) => {
      try {
        model.pessoaId = id;
        if (model.id === guidEmpty() || model.id === '') {
          await criarDocumento(model);
          showToast('success', 'Documento Cadastrado.');
        } else {
          await saveDocumento(model);
          showToast('success', 'Documento Atualizado.');
        }
        setFormComponent(null);
        getPessoaWrapper();
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [criarDocumento, getPessoaWrapper, id, saveDocumento, showToast]
  );

  const submitMainForm = useCallback(async () => {
    try {
      const dadosModel = await refEditForm.current?.getFields();
      if (!dadosModel) return;
      if (enderecos.length < 2 && !refEnderecoForm.current?.isEqual()) {
        const enderecoModel = await refEnderecoForm.current?.getFields();
        if (!enderecoModel) return;
        enderecoModel.pessoaId = pessoaModel.id;
        if (enderecos.length > 0) {
          await saveEndereco({
            ...enderecoModel,
            cep: stringNumeros(enderecoModel.cep)
          });
        } else {
          await criarEndereco({
            ...enderecoModel,
            cep: stringNumeros(enderecoModel.cep)
          });
        }
      }

      const newPessoaModel = picker<PessoaModel>(dadosModel, pessoaModel);
      newPessoaModel.contatos = dadosModel.contatos;

      if (dadosModel.contatos.length <= 2) {
        const contatos = dadosModel.contatos.map((c) => {
          if (
            dadosModel.contatos.filter((contact) => contact.tipo === c.tipo)
              .length > 0
          ) {
            return { ...c, responsavel: newPessoaModel.nome };
          }

          return c;
        });

        newPessoaModel.contatos = contatos;
      }

      newPessoaModel.documentos = pessoaModel.documentos;

      newPessoaModel.contatos = newPessoaModel.contatos.filter(
        (x) => !isEmpty(x.valor)
      );

      await saveDados(newPessoaModel);

      setPessoaModel({ ...newPessoaModel });

      showToast(
        'success',
        `${TpCadastroFarmaciaMock.find(
          (x) => x.Key === newPessoaModel.tpCadastro
        )?.Value
        } Atualizado(a).`
      );

      const isIerg = newPessoaModel.documentos.find(
        (doc) => doc.tipo === EnumTipoDocumento.InscricaoEstadual
      );

      if (dadosModel.ierg && !isIerg) {
        await handleDocumento(
          new PessoaDocumentoModel(
            guidEmpty(),
            newPessoaModel.id,
            EnumTipoDocumento.InscricaoEstadual,
            dadosModel.ierg
          )
        );
      }
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    criarEndereco,
    enderecos.length,
    handleDocumento,
    pessoaModel,
    saveDados,
    saveEndereco,
    showToast
  ]);

  const handleEndereco = useCallback(
    async (model: PessoaEnderecoModel) => {
      try {
        if (model.id === guidEmpty()) {
          model.pessoaId = id;
          await criarEndereco(model);
          showToast('success', 'Endereço Cadastrado.');
        } else {
          await saveEndereco(model);
          showToast('success', 'Endereço Atualizado');
        }

        setFormComponent(null);

        getEnderecos();
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [criarEndereco, getEnderecos, id, saveEndereco, showToast]
  );

  const handleContato = useCallback(
    async (model: PessoaContatosModel) => {
      try {
        if (model.id === guidEmpty()) {
          model.pessoaId = id;
          await criarContato(model);
          showToast('success', 'Contato Cadastrado.');
        } else {
          await saveContato(model);
          showToast('success', 'Contato Atualizado');
        }

        setFormComponent(null);

        getPessoaWrapper();
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [criarContato, getPessoaWrapper, id, saveContato, showToast]
  );

  //RENDERIZO ESSE FORMULÁRIO SE EXISTIR APENAS 1 ou 0 ENDEREÇO
  const enderecoForm = useMemo(() => {
    return (
      <>
        <Grid className={classes.dividerGrid}>
          <Typography
            className={classes.dividerText}
            color="primary"
            variant="body1"
          >
            Endereço
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <FormPessoaEndereco
          onSubmit={() => { }}
          loading={false}
          showLoading={false}
          model={enderecos[0]}
          ref={refEnderecoForm}
        />
        {enderecos.length > 0 && (
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => {
              setFormComponent({
                component: (
                  <GenericForm<PessoaEnderecoModel>
                    buttonLabel={'Cadastrar'}
                    children={
                      <FormPessoaEndereco
                        loading={false}
                        showLoading={false}
                        onSubmit={handleEndereco}
                      />
                    }
                    model={new PessoaEnderecoModel()}
                  />
                ),
                title: 'Cadastrar Endereço'
              });
            }}
          >
            <NovoIcon tipo="BUTTON" />
            Novo Endereço
          </Button>
        )}
      </>
    );
  }, [
    classes.divider,
    classes.dividerGrid,
    classes.dividerText,
    enderecos,
    handleEndereco
  ]);


  //RENDERIZO ESSA LISTAGEM QUANDO TEM + DE 1 ENDEREÇO
  const enderecoList = useMemo(() => {
    return (
      <Box>
        <AccordionSaurus
          expanded={accordions.enderecos}
          onChange={() =>
            setAccordions((prev) => ({ ...prev, enderecos: !prev.enderecos }))
          }
          labelPrimary="Endereços"
          sombreado
          heightDivider={0}
          noPaperRoot={false}
          tipoExpand="bold"
          showDivider
        >
          <Box className={classes.listContainer}>
            {enderecos.map((model) => {
              return (
                <CardPessoaEndereco
                  model={model}
                  onClick={() => {
                    setFormComponent({
                      component: (
                        <GenericForm<PessoaEnderecoModel>
                          buttonLabel={'Salvar'}
                          children={
                            <FormPessoaEndereco
                              loading={false}
                              showLoading={false}
                              onSubmit={handleEndereco}
                            />
                          }
                          model={model}
                          deleteItem={{
                            buttonLabel: 'Excluir',
                            handleDelete: excluirEndereco,
                            id: model.id
                          }}
                        />
                      ),
                      title: 'Edição de Endereço'
                    });
                  }}
                />
              );
            })}
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => {
                setFormComponent({
                  component: (
                    <GenericForm<PessoaEnderecoModel>
                      buttonLabel={'Cadastrar'}
                      children={
                        <FormPessoaEndereco
                          loading={false}
                          showLoading={false}
                          onSubmit={handleEndereco}
                        />
                      }
                      model={new PessoaEnderecoModel()}
                    />
                  ),
                  title: 'Cadastrar Endereço'
                });
              }}
            >
              <NovoIcon tipo="BUTTON" />
              Adicionar Endereço
            </Button>
          </Box>
        </AccordionSaurus>
      </Box>
    );
  }, [
    accordions.enderecos,
    classes.listContainer,
    enderecos,
    excluirEndereco,
    handleEndereco
  ]);

  const novoContato = useCallback(
    (tipo: EnumTipoPessoaContato) => {
      setFormComponent({
        title:
          tipo === EnumTipoPessoaContato.EMAIL
            ? 'Cadastrar E-mail'
            : 'Cadastrar Telefone',
        component: (
          <GenericForm<PessoaContatosModel>
            buttonLabel={'Cadastrar'}
            children={
              <FormPessoaContato
                loading={false}
                onSubmit={handleContato}
                showLoading={false}
              />
            }
            model={new PessoaContatosModel(guidEmpty(), guidEmpty(), tipo)}
          />
        )
      });
    },
    [handleContato]
  );

  const novoDocumento = useCallback(() => {
    setFormComponent({
      title: 'Cadastrar Documento',
      component: (
        <GenericForm<PessoaDocumentoModel>
          buttonLabel={`Cadastrar`}
          children={
            <FormPessoaDocumento
              tipoPessoa={pessoaModel.tpCadastro}
              loading={false}
              onSubmit={handleDocumento}
              showLoading={false}
              ref={refDocumentoForm}
            />
          }
          model={new PessoaDocumentoModel()}
        />
      )
    });
  }, [handleDocumento, pessoaModel.tpCadastro]);

  //RENDERIZO ESSA LISTAGEM QUANDO TEM + DE 1 CONTATO DO MESMO TIPO
  const emailList = useMemo(() => {
    return (
      <Box>
        <AccordionSaurus
          expanded={accordions.emails}
          onChange={() =>
            setAccordions((prev) => ({ ...prev, emails: !prev.emails }))
          }
          labelPrimary="E-mails"
          sombreado
          heightDivider={0}
          noPaperRoot={false}
          tipoExpand="bold"
          showDivider
        >
          <Box className={classes.listContainer}>
            {emails.length === 0 && (
              <CardNaoEncontrado
                mensagem="Nenhum E-mail Cadastrado."
                icon={<InformacaoIcon tipo="BUTTON" />}
                iconSize="small"
              />
            )}
            {emails.map((model) => {
              return (
                <CardPessoaContato
                  model={model}
                  onClick={() => {
                    setFormComponent({
                      component: (
                        <GenericForm<PessoaContatosModel>
                          buttonLabel={'Salvar'}
                          children={
                            <FormPessoaContato
                              loading={false}
                              showLoading={false}
                              onSubmit={handleContato}
                            />
                          }
                          model={model}
                          deleteItem={{
                            buttonLabel: 'Excluir',
                            handleDelete: excluirContato,
                            id: model.id
                          }}
                        />
                      ),
                      title: 'Cadastrar E-mail'
                    });
                  }}
                />
              );
            })}
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => {
                novoContato(EnumTipoPessoaContato.EMAIL);
              }}
            >
              <NovoIcon tipo="BUTTON" />
              Adicionar E-mail
            </Button>
          </Box>
        </AccordionSaurus>
      </Box>
    );
  }, [
    accordions.emails,
    classes.listContainer,
    emails,
    excluirContato,
    handleContato,
    novoContato
  ]);

  //RENDERIZO ESSA LISTAGEM QUANDO TEM + DE 1 CONTATO DO MESMO TIPO
  const telefoneList = useMemo(() => {
    return (
      <Box>
        <AccordionSaurus
          expanded={accordions.telefones}
          onChange={() =>
            setAccordions((prev) => ({ ...prev, telefones: !prev.telefones }))
          }
          labelPrimary="Telefones"
          sombreado
          heightDivider={0}
          noPaperRoot={false}
          tipoExpand="bold"
          showDivider
        >
          <Box className={classes.listContainer}>
            {telefones.length === 0 && (
              <CardNaoEncontrado
                mensagem="Nenhum Telefone Cadastrado."
                icon={<InformacaoIcon tipo="BUTTON" />}
                iconSize="small"
              />
            )}
            {telefones.map((model) => {
              return (
                <CardPessoaContato
                  model={model}
                  onClick={() => {
                    setFormComponent({
                      component: (
                        <GenericForm<PessoaContatosModel>
                          buttonLabel={'Salvar'}
                          children={
                            <FormPessoaContato
                              loading={false}
                              showLoading={false}
                              onSubmit={handleContato}
                            />
                          }
                          model={model}
                          deleteItem={{
                            buttonLabel: 'Excluir',
                            handleDelete: excluirContato,
                            id: model.id
                          }}
                        />
                      ),
                      title: 'Editar Telefone'
                    });
                  }}
                />
              );
            })}
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={() => {
                novoContato(EnumTipoPessoaContato.TELEFONE);
              }}
            >
              <NovoIcon tipo="BUTTON" />
              Adicionar Telefone
            </Button>
          </Box>
        </AccordionSaurus>
      </Box>
    );
  }, [
    accordions.telefones,
    classes.listContainer,
    excluirContato,
    handleContato,
    novoContato,
    telefones
  ]);

  //RENDERIZO ESSA LISTAGEM QUANDO TEM + DE 1 DOCUMENTO
  const documentoList = useMemo(() => {
    return (
      <Box className={classes.listContainer}>
        {pessoaModel.documentos.map((model) => {
          return (
            <CardPessoaDocumento
              model={model}
              onClick={() => {
                setFormComponent({
                  component: (
                    <GenericForm<PessoaDocumentoModel>
                      buttonLabel={'Salvar'}
                      children={
                        <FormPessoaDocumento
                          tipoPessoa={pessoaModel.tpCadastro}
                          ref={refDocumentoForm}
                          loading={false}
                          showLoading={false}
                          onSubmit={handleDocumento}
                        />
                      }
                      model={model}
                      deleteItem={{
                        buttonLabel: 'Excluir',
                        handleDelete: excluirDocumento,
                        id: model.id
                      }}
                    />
                  ),
                  title: 'Editar Documento'
                });
              }}
            />
          );
        })}
        <Button
          variant="outlined"
          fullWidth
          color="primary"
          onClick={() => {
            novoDocumento();
          }}
        >
          <NovoIcon tipo="BUTTON" />
          Adicionar Documento
        </Button>
      </Box>
    );
  }, [
    classes.listContainer,
    excluirDocumento,
    handleDocumento,
    novoDocumento,
    pessoaModel.documentos,
    pessoaModel.tpCadastro
  ]);

  useEffect(() => {
    const pessoaEditForm = picker<PessoaEditDadosFormModel>(
      pessoaModel,
      new PessoaEditDadosFormModel()
    );
    pessoaEditForm.contatos = [...pessoaModel.contatos];
    fillEditDadosForm(pessoaEditForm);
  }, [
    fillEditDadosForm,
    pessoaModel,
    pessoaModel.contatos,
    pessoaModel.cpfcnpj,
    pessoaModel.id,
    pessoaModel.ierg,
    pessoaModel.infOperador,
    pessoaModel.nome,
    pessoaModel.status,
    pessoaModel.tpCadastro
  ]);

  useEffect(() => {
    getPessoaWrapper();
    getEnderecos();
  }, [getEnderecos, getPessoaWrapper]);

  const changeTipo = useCallback(
    async (tipo: EnumCadastroTipo) => {
      try {
        setChangeTipoDialog(false);
        const newModel = { ...pessoaModel };
        newModel.tpCadastro = tipo;
        await saveDados(newModel);
        showToast('success', 'Tipo alterado com sucesso.');
        setPessoaModel(newModel);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [pessoaModel, saveDados, showToast]
  );

  const changeStatus = useCallback(async () => {
    try {
      const newModel = { ...pessoaModel };
      newModel.status =
        newModel.status !== EnumCadastroStatus.LIBERADO
          ? EnumCadastroStatus.LIBERADO
          : EnumCadastroStatus.DESATIVADO;
      await saveDados(newModel);

      showToast(
        'success',
        `${TpCadastroFarmaciaMock.find((x) => x.Key === newModel.tpCadastro)
          ?.Value
        } ${newModel.status === EnumCadastroStatus.LIBERADO
          ? 'Ativado.'
          : 'Desativado.'
        }`
      );

      setPessoaModel(newModel);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [pessoaModel, saveDados, showToast]);

  const menuOptions = useCallback((): any[] => {
    return [
      new MenuOptionsModel(
        (
          <Box className={menuClasses.menuItem}>
            <Typography variant="body1">Ativo</Typography>
            <SwitchSaurus
              size="small"
              value={pessoaModel.status === EnumCadastroStatus.LIBERADO}
              variant="DEFAULT"
              checked={pessoaModel.status === EnumCadastroStatus.LIBERADO}
            />
          </Box>
        ),
        <></>,
        () => changeStatus()
      ),
      new MenuOptionsModel(
        (
          <Box className={menuClasses.menuItem}>
            <Typography variant="body1">Tipo</Typography>
            <Grid className={classes.menuOpcoesGrid} alignItems="center" justifyContent="flex-end">
              <Typography className={classes.tipoClienteText}>
                {TpCadastroFarmaciaMock.find(
                  (x) => x.Key === pessoaModel.tpCadastro
                )?.Value || 'Pessoa'}
              </Typography>
              <EditarIcon tipo="BUTTON" />
            </Grid>
          </Box>
        ),
        <></>,
        () => setChangeTipoDialog(true)
      ),
      getConfigByCod(EnumContratoConfig.ClientePadrao) !== id
        ? new MenuOptionsModel(
          <Grid alignItems='center' className={classes.menuOpcoesGrid}>
            <Typography variant="body2">Definir como Cliente Padrão</Typography>
            <Tooltip title='O cliente padrão é o registro que será vinculado nas vendas em que não for identificado o consumidor. Geralmente o nome é definido de "CONSUMIDOR" para fácil entendimento.'>
              <Box className={classes.menuOpcoesGrid} alignItems='center'>
                <InformacaoIcon tipo="BUTTON" fill={theme.palette.primary.main} class={classes.infoIcon} />
              </Box>
            </Tooltip>
          </Grid>,
          <></>,
          async () => {
            try {
              await putContratoConfig([
                new PutConfigContratoProps(
                  null,
                  EnumContratoConfig.ClientePadrao,
                  id
                )
              ]);
              showToast('success', 'Cliente Padrão atualizado com sucesso.');
              setPessoaModel((prev) => ({ ...prev }));
              //seto estado pra atualizar o menu
            } catch (e: any) {
              showToast('error', e.message);
            }
          },
          false,
          (
            <Tooltip title="Informação">
              <InformacaoIcon tipo="GERAL" />
            </Tooltip>
          )
        )
        : null
    ];
  }, [menuClasses.menuItem, pessoaModel.status, pessoaModel.tpCadastro, classes.menuOpcoesGrid, classes.tipoClienteText, classes.infoIcon, getConfigByCod, id, theme.palette.primary.main, changeStatus, putContratoConfig, showToast]);

  const addNovoEndereco = (dadosConsulta: ConsultaCNPJModel) => {
    if (enderecos.length > 1) return;

    const enderecoModel = new PessoaEnderecoModel();
    enderecoModel.id = enderecos.length === 1 ? enderecos[0].id : guidEmpty();
    enderecoModel.pessoaId = id;
    enderecoModel.bairro = dadosConsulta.bairro;
    enderecoModel.cMun = dadosConsulta.cMunicipio;
    enderecoModel.xMun = dadosConsulta.municipio;
    enderecoModel.cep = dadosConsulta.cep;
    enderecoModel.complemento = dadosConsulta.complemento;
    enderecoModel.logradouro = dadosConsulta.logradouro;
    enderecoModel.numero = dadosConsulta.numero;
    enderecoModel.uf = dadosConsulta.uf;
    enderecoModel.cuf = UFMock.find((uf) => uf.Value === dadosConsulta.uf)?.Key;
    enderecoModel.ierg = dadosConsulta.ie

    if (refEnderecoForm.current) {
      refEnderecoForm.current.fillForm(enderecoModel);
    }
  };

  const abas = [
    new TabSaurusLabel('Dados Pessoais', EnumAbaEdicaoPessoa.Dados),
    new TabSaurusLabel('Documentos', EnumAbaEdicaoPessoa.Documentos)
  ]
  // .filter((aba) => {
  //   if (
  //     pessoaModel.tpCadastro !== EnumCadastroTipo.PRESCRITOR &&
  //     pessoaModel.tpCadastro !== EnumCadastroTipo.RESPONSAVEL_TECNICO &&
  //     aba.index === EnumAbaEdicaoPessoa.Documentos
  //   ) {
  //     return false;
  //   }
  //   return true;
  // });

  const getTitulo = () => {
    switch (pessoaModel.tpCadastro) {
      case undefined:
        return 'Edição de Pessoa';
      case EnumCadastroTipo.PRESCRITOR:
        return 'Edição de Prescritor';
      case EnumCadastroTipo.CLIENTE:
        return 'Edição de Cliente';
      case EnumCadastroTipo.FORNECEDOR:
        return 'Edição de Fornecedor';
      case EnumCadastroTipo.FUNCIONARIO:
        return 'Edição de Funcionário';
      case EnumCadastroTipo.REPRESENTANTE:
        return 'Edição de Representante';
      case EnumCadastroTipo.RESPONSAVEL_TECNICO:
        return 'Edição de Responsável Técnico';
      default:
        return 'Edição de Transportadora';
    }
  };

  const { location } = useHistory();
  const abrirNovoCadastro = () => {
    fecharCadastroPessoa();
    abrirCadastroPessoa('', location.pathname, true)
  };

  const confirmarExclusaoDocumento = (id: string) => {
    confirm({
      title: 'Confirmar Ação',
      description: 'Deseja prosseguir com a exclusão? Esta ação não poderá ser desfeita.',
      confirmationText: "Excluir",
      cancellationText: 'Cancelar'
    }).then(async () => {
      await excluirDocumento(id)
    })
  }

  return (
    <>
      <Box className={classNames(modalClasses.root, classes.root)}>
        <ModalHeader
          title={
            carregando ? 'Carregando...' : formComponent?.title || getTitulo()
          }
          leftArea={
            <ButtonModalHeader
              tooltip="Voltar"
              icon={<VoltarIcon tipo="MODAL_HEADER" />}
              onClick={() => {
                if (formComponent) {
                  setFormComponent(null);
                  return;
                }
                if (changeTipoDialog) {
                  setChangeTipoDialog(false);
                  return;
                }
                fecharCadastroPessoa();
              }}
            />
          }
          rightArea={
            <MenuOptions
              options={menuOptions()}
              disable={carregando}
              labels={['Opções', '', 'Avançado']}
            />
          }
        />
        <Box className={classNames(modalClasses.content, classes.relative)}>
          {carregando && (
            <Box className={classes.loadingContainer}>
              <CircularLoading tipo="FULLSIZED" />
            </Box>
          )}
          <TabsSaurus
            tabsLabel={abas}
            selectedTabIndex={abaSelecionada}
            onChange={(index) => {
              setAbaSelecionada(index);
              if (
                index === EnumAbaEdicaoPessoa.Documentos &&
                pessoaModel.documentos.length === 1
              ) {
                refDocumentoForm.current?.fillForm(pessoaModel.documentos[0]);
              }
            }}
            tabsContent={[
              //PESSOA
              new TabSaurusContent(
                EnumAbaEdicaoPessoa.Dados,
                (
                  <Box className={classes.container}>
                    <FormPessoaEdit
                      loading={false}
                      showLoading={false}
                      ref={refEditForm}
                      onSubmit={() => { }}
                      onClickNovoContato={novoContato}
                      addEndereco={addNovoEndereco}
                    />
                    {enderecos.length < 2 ? enderecoForm : enderecoList}
                    {(emails.length > 1 || telefones.length > 1) && (
                      <>
                        {emailList}
                        {telefoneList}
                      </>
                    )}
                    <Box className={classes.buttonContainer}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Button
                            onClick={abrirNovoCadastro}
                            fullWidth
                            size="large"
                            variant="outlined"
                            color="primary"
                          >
                            <NovoIcon tipo="BUTTON" />
                            Novo
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            onClick={submitMainForm}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )
              ),
              //DOCUMENTO
              new TabSaurusContent(
                EnumAbaEdicaoPessoa.Documentos,
                (
                  <Box className={classes.container}>
                    {pessoaModel.documentos.length < 2 ? (
                      <>
                        <FormPessoaDocumento
                          tipoPessoa={pessoaModel.tpCadastro}
                          loading={false}
                          showLoading={false}
                          ref={refDocumentoForm}
                          onSubmit={handleDocumento}
                        />
                        {pessoaModel.documentos.length === 1 && (
                          <Button
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={() => {
                              novoDocumento();
                            }}
                          >
                            <NovoIcon tipo="BUTTON" />
                            Adicionar Documento
                          </Button>
                        )}
                      </>
                    ) : (
                      documentoList
                    )}
                    <Box className={classes.buttonContainer}>
                      <Grid container spacing={2}>
                        {pessoaModel.documentos.length === 1 && (
                          <Grid item xs={6}>
                            <Button
                              onClick={() => {
                                confirmarExclusaoDocumento(pessoaModel.documentos?.[0].id ?? '')
                              }}
                              disabled={pessoaModel.documentos.length > 1}
                              variant="outlined"
                              fullWidth
                              className={classes.btnDelete}
                              color="primary"
                            >
                              <LixoIcon tipo="BUTTON_PRIMARY" />
                              Excluir
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={pessoaModel.documentos.length === 1 ? 6 : 12}>
                          <Button
                            onClick={() => {
                              refDocumentoForm.current?.submitForm();
                            }}
                            disabled={pessoaModel.documentos.length > 1}
                            variant="contained"
                            fullWidth
                            color="primary"
                          >
                            <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                            Salvar Documento
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )
              )
            ]}
          />
          {formComponent && formComponent.component}
          {changeTipoDialog && (
            <RadioList
              handleSubmit={changeTipo}
              selecionado={pessoaModel.tpCadastro}
              mock={TpCadastroFarmaciaMock.filter((item) => {
                if (
                  (item.Key === EnumCadastroTipo.PRESCRITOR ||
                    item.Key === EnumCadastroTipo.RESPONSAVEL_TECNICO) &&
                  !isFarmaceutico
                ) {
                  return null;
                }

                return item;
              })}
              text="Para alterar o tipo do cadastro, selecione um tipo abaixo e confirme:"
            />
          )}
        </Box>
      </Box>
    </>
  );
};
