import { useGetVendaCompleta } from "data/api/gestao/venda/get-venda-completa";
import { usePostVendaCompleta } from "data/api/gestao/venda/post-venda-completa";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov";
import { useCallback } from "react";
import { guidEmpty } from "utils/guid-empty";

export const useEntrada = (movAtual: VendaCompletaModel) => {

    const { getVendaCompleta: getVenda, carregando: carregandoGet } = useGetVendaCompleta()
    const { postVendaCompleta, carregando: carregandoPost } = usePostVendaCompleta()

    const carregando = [carregandoGet, carregandoPost].includes(true)

    const getVendaCompleta = useCallback(async (id: string): Promise<VendaCompletaModel> => {
        const res = await getVenda(id)

        if (res.erro) throw res.erro

        return res.resultado?.data as VendaCompletaModel
    }, [getVenda])

    const saveChangesVendaCompleta = async (model: VendaCompletaModel, useValidFinalizado = true) => {
        const mov = structuredClone(model) as VendaCompletaModel
        if (useValidFinalizado && movAtual.status === EnumTpStatusMov.Finalizado) {
            throw new Error('A entrada está com status "Finalizado". Precisa estar com status "Em Digitação" para poder alterá-la.')
        }
        mov.infMov.UFSaidaPais =
            mov.infMov.UFSaidaPais &&
                mov.infMov.UFSaidaPais > 0
                ? mov.infMov.UFSaidaPais
                : null
        mov.terminalId = mov.terminalId === guidEmpty() ? null : mov.terminalId

        const res = await postVendaCompleta(mov)

        if (res.erro) throw res.erro
    }

    return {
        getVendaCompleta,
        saveChangesVendaCompleta,
        carregando
    }

}