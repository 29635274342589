import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonCancel: {
    backgroundColor: theme.palette.error.main,
    margin: 0,
    marginLeft: 8,
    width: '40px',
    height: '40px',
    '& svg': {
      fill: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        fill: theme.palette.common.white
      }
    },
    '&:hover': {
      backgroundColor: 'rgb(173 23 12)'
    }
  },
  button: {
    margin: 0,
    width: '40px',
    height: '40px',
    '& svg': {
      fill: theme.palette.primary.contrastText,
      [theme.breakpoints.up('md')]: {
        fill: theme.palette.text.primary
      }
    }
  }
}));
