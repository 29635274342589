import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { PessoaDocumentoModel } from "model/api/gestao/pessoa/pessoa-documento-model";
export function usePutDocumentoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putDocumentoPessoa = useCallback(
    (documento: PessoaDocumentoModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${documento.pessoaId}/documento/${documento.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data:JSON.stringify(documento),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putDocumentoPessoa,
  };
}