import { Grid, Typography, useTheme, Divider, Button } from '@material-ui/core';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { PedidoMesaProcessadaModel } from 'model/api/gestao/pedido/pedido-mesa-processada';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { toDecimalString } from 'utils/to-decimal';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { PessoasMesaIcon } from 'views/components/icons/pessoas-mesa-icon';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { ComandasMesaListData } from './components/comanda-mesa-list/comandas-mesa-list-data';
import { useStyles } from './mov-visualizar-mesa-e-comanda-styles';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { useGetPedidoSalaoProcessado } from 'data/api/gestao/pedido/get-pedido-salao-processado';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { CircularLoading } from 'views/components';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useConfirm } from 'material-ui-confirm';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { EnumMesas } from 'model/enums/enum-mesas';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePDV } from 'services/app/hooks/pdv';
import classNames from 'classnames';
import { ProdutosListData } from '../mov-visualizar-pedido/components/produtos-list/produtos-list-data';
import {
  PedidoProdutoModel,
  PedidoProdutoModelPost
} from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { ReticenciasIcon } from 'views/components/icons/reticencias-icon';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { useThemeQueries } from 'views/theme';
import { guidEmpty } from 'utils/guid-empty';
import { isPlanoControleProducao } from 'utils/plano-utils';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { isEmpty } from 'lodash';
import { usePutProdutoPedido } from 'data/api/gestao/pedido/put-produto-pedido';
interface mesaProps {
  mesaId: string;
}

export const MovVisualizarMesaEComandaPage = () => {
  // STATES E REFS
  const [mesa, setMesa] = useState<PedidoMesaProcessadaModel>(
    new PedidoMesaProcessadaModel()
  );
  const [pedidoNaMesa, setPedidoNaMesa] = useState<PedidoModel | undefined>(
    undefined
  );
  const pedidoParaImportar = useRef<PedidoModel | undefined>(undefined);
  const refDialogIndicadorDesperdicio = useRef<boolean>(false);
  const refProduto = useRef<PedidoProdutoModel>(new PedidoProdutoModel());

  // CHAMADAS API
  const {
    getPedidoSalaoProcessado,
    carregando: carregandoGetPedidosProcessado
  } = useGetPedidoSalaoProcessado();
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();
  const { putProdutoPedido, carregando: carregandoPutproduto } =
    usePutProdutoPedido();

  // HOOKS
  const { getEmpresaSelecionada, getPermissaoBoolean, plano } =
    useSessaoAtual();
  const { transformarPedidosEmVendaSimples } = useMovAtual();
  const { removeHandler, addHandler } = useEventTools();
  const { getPDV, getConfigByCod } = usePDV();
  const {
    abrirImportarPedidorDialog,
    abrirFechamentoPedidorDialog,
    abrirVincularComandaDialog,
    abrirSolicitarPermissao,
    abrirDialogAcoesPedidos,
    abrirDialogIndicacaoDeDesperdicio
  } = useCadastros();

  // AUX
  const carregando =
    carregandoGetPedidosProcessado ||
    carregandoPutPedidoStatus ||
    carregandoPutproduto;
  const { serializarPedidoComandaNaMesa, carregarInfoPedido } =
    usePedidoLocal();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const theme = useTheme();
  const history = useHistory();
  const { mesaId } = useParams<mesaProps>();
  const confirm = useConfirm();
  const isSomentePedido =
    getPDV()?.configuracoes.find((p) => p.cod === 101)?.vConfig ===
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS;
  const modeloTrabalho = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho);
  const isApenasCaixa = modeloTrabalho === EnumModeloDeTrabalho.APENAS_CAIXA;
  const caixaLancamento = modeloTrabalho === EnumModeloDeTrabalho.APENAS_CAIXA || modeloTrabalho === EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS;
  const mobile = useThemeQueries();
  const isControleProducao = isPlanoControleProducao(plano?.plano);

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar ao Atendimento"
        onClick={() => {
          history.push('/venda-simples/pedidos/mesas');
        }}
      ></ButtonPrivateHeader>
    );
  }, [history]);

  const getPedidosInfoWrapper = useCallback(async () => {
    const pedido = await carregarInfoPedido(mesaId, false);
    setPedidoNaMesa(pedido);
  }, [carregarInfoPedido, mesaId]);

  const rightArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        disabled={isApenasCaixa}
        icon={
          <ReticenciasIcon
            tipo="PRIVATE_HEADER"
            fill={mobile.isMobile ? '#fff' : theme.palette.grey[600]}
          />
        }
        tooltip="Opções para pedido na Mesa"
        onClick={() => {
          if (pedidoNaMesa?.id && pedidoNaMesa.id !== guidEmpty())
            abrirDialogAcoesPedidos(
              pedidoNaMesa,
              getPedidosInfoWrapper,
              'Opções do pedido na mesa'
            );
        }}
      ></ButtonPrivateHeader>
    );
  }, [
    abrirDialogAcoesPedidos,
    getPedidosInfoWrapper,
    isApenasCaixa,
    mobile.isMobile,
    pedidoNaMesa,
    theme.palette.grey
  ]);

  const importarPedido = useCallback(
    async (pedido: PedidoModel) => {
      try {
        if (pedido.statusPedido.codigo === EnumStatusPedido.IMPORTADO) {
          throw new Error(`O pedido já foi importado.`);
        }

        const respostaPutStatus = await putPedidoStatus(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          EnumStatusPedido.IMPORTADO
        );

        if (respostaPutStatus.erro) {
          throw new Error(
            `Erro ao alterar status do pedido para Importado. Detalhe: ${respostaPutStatus.erro}`
          );
        }

        await transformarPedidosEmVendaSimples([pedido]);
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      getEmpresaSelecionada,
      putPedidoStatus,
      showToast,
      transformarPedidosEmVendaSimples
    ]
  );

  const getComandasSerializadas = useCallback(async () => {
    try {
      const respostaGetMesaProcessada = await getPedidoSalaoProcessado(
        getEmpresaSelecionada()?.Id ?? '',
        `mesaId=${mesaId}`
      );

      if (respostaGetMesaProcessada.erro) {
        throw new Error(respostaGetMesaProcessada.erro);
      }

      const getMesa = await TouchoneDBPrimary.mesas.get({ id: mesaId });

      if (!getMesa) {
        showToast('error', 'Mesa não encontrada');
      }

      if (getMesa && getMesa.status?.codigo !== EnumMesas.ATIVO) {
        showToast('error', `A mesa está com status de INATIVA.`);
      }

      const salao = await TouchoneDBPrimary.saloes.get({
        id: getMesa?.salaoId ?? ''
      });

      if (!respostaGetMesaProcessada?.resultado?.data?.list || respostaGetMesaProcessada?.resultado?.data?.list?.length === 0) {
        // nesse get se não tiver list é porque a mesa não tem pedido
        setMesa({
          ...new PedidoMesaProcessadaModel(),
          codigoMesa: getMesa?.codigo ?? '',
          idMesa: getMesa?.id ?? guidEmpty(),
          salaoId: salao?.id ?? guidEmpty(),
          nomeSalao: salao?.descricao ?? ''
        })
        return;
      }

      let mesaProcessada = respostaGetMesaProcessada.resultado?.data
        .list[0] as PedidoMesaProcessadaModel;

      const comandas = await serializarPedidoComandaNaMesa(
        mesaProcessada.pedidos
      );

      mesaProcessada.pedidos = comandas;
      mesaProcessada.codigoMesa = getMesa?.codigo ?? '';
      mesaProcessada.nomeSalao = salao?.descricao ?? '';
      mesaProcessada.idMesa = mesaId;
      setMesa(mesaProcessada);
      if (
        mesaProcessada.pedidos.filter((pedido) => !pedido.comandaId).length > 0
      ) {
        setPedidoNaMesa(
          mesaProcessada.pedidos.find((pedido) => !pedido.comandaId)
        );
      }
    } catch (err: any) {
      showToast(
        'error',
        `Erro ao buscar as informações da mesa ${err.message}`
      );
    }
  }, [
    getEmpresaSelecionada,
    getPedidoSalaoProcessado,
    mesaId,
    serializarPedidoComandaNaMesa,
    showToast
  ]);

  const atualizarStatus = useCallback((idPedido: string) => {
    setMesa((prev) => {
      return {
        ...prev,
        pedidos: prev.pedidos.map((pedido) => {
          if (pedido.id === idPedido) {
            return {
              ...pedido,
              statusPedido: {
                codigo: EnumStatusPedido.IMPORTADO,
                descricao: 'Importado'
              }
            };
          }
          return pedido;
        })
      };
    });
  }, []);

  const onCardInativarProduto = useCallback(
    async (
      produto: PedidoProdutoModel,
      desperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica
    ) => {
      try {
        if (isControleProducao && !refDialogIndicadorDesperdicio.current) {
          refProduto.current = produto;
          abrirDialogIndicacaoDeDesperdicio(onCardInativarProduto, refProduto);
          refDialogIndicadorDesperdicio.current = true;
          return;
        }

        const prod = {
          ...produto,
          status: EnumStatusProdutoPedido.DESISTENCIA,
          indDesperdicio: desperdicio,
          tpProduto: produto.tpProduto.codigo,
          subItens: []
        } as PedidoProdutoModelPost;

        const prodsGroup = pedidoNaMesa!.produtos
          .filter((x) => x.groupId === prod.codigoReferencia)
          .map((x) => {
            const prod = {
              ...x,
              status: EnumStatusProdutoPedido.DESISTENCIA,
              indDesperdicio: desperdicio,
              tpProduto: x.tpProduto.codigo,
              subItens: []
            } as PedidoProdutoModelPost;

            return prod;
          });

        if (!isEmpty(prodsGroup)) {
          const prodsInativar = [prod, ...prodsGroup];
          let produtosAtualizados: PedidoProdutoModel[] = [];
          for await (const produto of prodsInativar) {
            const respostaPut = await putProdutoPedido(
              getEmpresaSelecionada()?.Id ?? '',
              pedidoNaMesa?.id ?? '',
              produto
            );

            if (respostaPut.erro) {
              throw new Error(respostaPut.erro);
            }

            const produtoAtualizado = respostaPut.resultado
              ?.data as PedidoProdutoModel;

            produtosAtualizados.push(produtoAtualizado);
          }

          setPedidoNaMesa((prev) => {
            if (!prev) {
              return undefined;
            }

            return {
              ...prev,
              produtos: prev!.produtos.map((prod) => {
                const mesmoProduto = produtosAtualizados.find(
                  (x) => x.id === prod.id
                );
                if (mesmoProduto && !isEmpty(mesmoProduto)) {
                  return mesmoProduto;
                }

                return prod;
              })
            };
          });

          return;
        }

        const respostaPut = await putProdutoPedido(
          getEmpresaSelecionada()?.Id ?? '',
          pedidoNaMesa?.id ?? '',
          prod
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        const produtoAtualizado = respostaPut.resultado
          ?.data as PedidoProdutoModel;

        setPedidoNaMesa((prev) => {
          if (!prev) {
            return undefined;
          }

          return {
            ...prev,
            produtos: prev!.produtos.map((prod) => {
              if (prod.id === produtoAtualizado.id) {
                return produtoAtualizado;
              }

              return prod;
            })
          };
        });
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      abrirDialogIndicacaoDeDesperdicio,
      getEmpresaSelecionada,
      isControleProducao,
      pedidoNaMesa,
      putProdutoPedido,
      showToast
    ]
  );

  const checarPermissaoInativar = useCallback(
    async (
      produto: PedidoProdutoModel,
      desperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica
    ) => {
      //checa se tem permissão para cancelar itens, se tiver envia um callback pro dialog pedir permissão e continuar o processo
      if (!getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_ITENS)) {
        abrirSolicitarPermissao(
          async () => {
            await onCardInativarProduto(produto, desperdicio);
          },
          EnumCodigosPermissoes.CANCELAMENTO_ITENS,
          'cancelar o produto'
        );
        return;
      }
      //caso tenha permissão segue o fluxo normalmente
      onCardInativarProduto(produto, desperdicio);
    },
    [abrirSolicitarPermissao, getPermissaoBoolean, onCardInativarProduto]
  );

  useEffect(() => {
    addHandler(AppEventEnum.ComandaVinculada, getComandasSerializadas);
    addHandler(AppEventEnum.RecarregarPedidosComanda, atualizarStatus);
    return () => {
      removeHandler(AppEventEnum.ComandaVinculada, getComandasSerializadas);
      removeHandler(AppEventEnum.RecarregarPedidosComanda, atualizarStatus);
    };
  }, [addHandler, atualizarStatus, getComandasSerializadas, removeHandler]);

  useEffect(() => {
    getComandasSerializadas();
  }, [getComandasSerializadas]);

  const header = () => {
    return (
      <PrivatePageHeader
        title={`Mesa ${mesa?.codigoMesa ?? ''}`}
        leftArea={leftArea()}
        rightArea={
          pedidoNaMesa?.id && pedidoNaMesa.id !== guidEmpty()
            ? rightArea()
            : null
        }
      />
    );
  };

  const onCardComandaSelected = useCallback(
    (pedido: PedidoModelComanda) => {
      return history.push({
        pathname: `/venda-simples/visualizar-pedido/${pedido.comandaId}`,
        state: { pedido },
        search: '?modo=comanda'
      });
    },
    [history]
  );

  const onHoldedCard = (pedido: PedidoModel) => {
    pedidoParaImportar.current = pedido;
    if (isSomentePedido) return;
    abrirImportarPedidorDialog(
      pedidoParaImportar.current,
      importarPedido,
      AppEventEnum.RecarregarPedidosComanda,
    );
  };

  const tudoFechado =
    mesa?.pedidos.filter(
      (item) => item.statusPedido.codigo === EnumStatusPedido.FECHADO
    ).length === mesa?.pedidos.length;

  const tudoImportado =
    mesa?.pedidos.filter(
      (item) => item.statusPedido.codigo === EnumStatusPedido.IMPORTADO
    ).length === mesa?.pedidos.length;

  const disponivelParaFechamento =
    mesa?.pedidos.filter(
      (item) =>
        item.statusPedido.codigo !== EnumStatusPedido.IMPORTADO &&
        item.statusPedido.codigo !== EnumStatusPedido.FECHADO
    ) ?? [];

  const handleImportarPedidos = useCallback(() => {
    confirm({
      confirmationText: 'Finalizar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Finalizar Pedidos',
      description: 'Deseja mesmo finalizar os pedidos neste momento?'
    }).then(() =>
      history.push({
        pathname: '/venda-simples/importar-pedidos',
        state: {
          pedidos: mesa?.pedidos.filter(
            (item) => item.statusPedido.codigo === EnumStatusPedido.FECHADO
          )
        }
      })
    );
  }, [confirm, history, mesa?.pedidos]);

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.root}>
        <Grid className={classes.header}>{header()}</Grid>

        <Grid className={classes.containerInfo}>
          <Grid
            style={{
              display: 'flex',
              background: '#fff',
              padding: '8px',
              borderRadius: '5px'
            }}
          >
            <Grid className={classes.containerIcon}>
              <PessoasMesaIcon fill={theme.palette.primary.main} tipo="GERAL" />
            </Grid>
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: '5px'
              }}
            >
              <Typography className={classes.textTitle}>Pessoas</Typography>
              <Typography>
                <strong>{mesa?.qtdePessoas ?? 0}</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              flex: 1,
              marginLeft: '16px',
              background: '#fff',
              padding: '8px',
              borderRadius: '5px'
            }}
          >
            <Grid className={classes.containerIcon}>
              <SacoDinheiroIcon
                fill={theme.palette.primary.main}
                tipo="GERAL"
              />
            </Grid>
            <Grid>
              <Typography className={classes.textTitle}>Valor total</Typography>
              <Typography>
                <strong>
                  R$ {toDecimalString(mesa?.valorTotalPedido ?? 0)}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {pedidoNaMesa?.id && pedidoNaMesa?.id !== guidEmpty() && (
          <>
            <Grid style={{ padding: '5px 8px' }}>
              <Typography variant="body1">
                <b>Produtos na Mesa</b>
              </Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid className={classes.list}>
              <div className={classes.defaultContainer}>
                <Grid className={classes.containerListCard}>
                  <ProdutosListData
                    produtos={pedidoNaMesa.produtos ?? []}
                    carregando={false}
                    inativarProduto={checarPermissaoInativar}
                    statusPedido={pedidoNaMesa.statusPedido.codigo}
                  />
                </Grid>
              </div>
            </Grid>
          </>
        )}

        <Grid style={{ padding: '5px 8px' }}>
          <Typography variant="body1">
            <b>Comandas</b>
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        <Grid className={classes.list}>
          <div className={classes.defaultContainer}>
            <Grid className={classes.containerListCard}>
              <ComandasMesaListData
                comandas={
                  (mesa?.pedidos.filter(
                    (pedidos) => pedidos.comandaId
                  ) as PedidoModelComanda[]) ?? []
                }
                carregando={carregando}
                onCardSelected={onCardComandaSelected}
                codigoMesa={mesa?.codigoMesa ?? ''}
                onCardHolded={onHoldedCard}
                onClickVincularComanda={() =>
                  abrirVincularComandaDialog(mesaId)
                }
              />
            </Grid>
          </div>
        </Grid>
        {!tudoImportado && (
          <Grid className={classes.containerAcao}>
            <Grid container spacing={1}>
              <Grid item xs={!caixaLancamento ? 12 : 6}>
                {disponivelParaFechamento?.length > 0 && (
                  <Button
                    fullWidth
                    variant="contained"
                    className={classNames(classes.buttonfechamento, 'round')}
                    onClick={() =>
                      abrirFechamentoPedidorDialog(
                        ([
                          ...disponivelParaFechamento,
                          pedidoNaMesa ?? null
                        ].filter((p) => p !== null) as PedidoModelComanda[]) ??
                        []
                      )
                    }
                    style={{ height: '57px' }}
                  >
                    <ImpressoraIcon tipo="BUTTON_PRIMARY" />
                    Fechamento
                  </Button>
                )}
              </Grid>
              {caixaLancamento && <Grid item xs={disponivelParaFechamento?.length === 0 ? 12 : 6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!tudoFechado}
                  onClick={handleImportarPedidos}
                  style={{ height: '57px' }}
                  className="round"
                >
                  <CarrinhoIcon tipo="BUTTON_PRIMARY" />
                  Finalizar
                </Button>
              </Grid>}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
