import { VariaveisAmbiente } from "config";
import { ProcessosSetorPutModel } from "model/api/gestao/processos/processos-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutProcessoSetor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putProcessoSetor = useCallback(
        (model: ProcessosSetorPutModel, empresaId: string, setorId: string, processoSetorId: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/setor/${setorId}/processo/${processoSetorId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: model,
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        putProcessoSetor,
    };
}