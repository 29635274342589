import { EnumIndEscala } from "model/enums";
import { guidEmpty } from "utils/guid-empty";

export class MarcaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public nome: string = '',
        public cnpjFab: string = '',
        public indEscala: EnumIndEscala = EnumIndEscala.S,
    ) { }
}
