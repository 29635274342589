import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { FinalizadoraModel } from "model/api/gestao/finalizadora/finalizadora-model";
import { VariaveisAmbiente } from "config";

export function usePostFinalizadora() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postFinalizadora = useCallback(
    (finalizadora: FinalizadoraModel) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Finalizadora`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(finalizadora),
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postFinalizadora,
  };
}
