import { guidEmpty } from "utils/guid-empty";

export class ProdutoModificadorFormModel {
  constructor(
    public id: string | null = null,
    public produtoNome: string = '',
    public produtoGradeId: string = guidEmpty(),
    public ordem: number = 0,
    public valor: string = ''
  ) { }
}
