import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme)=>({
    valores:{
        fontWeight:500
    },
    content:{
        display:'flex', 
        flexDirection:'column',
        
    },
    cardBorder:{
        boxShadow: '0px 2.5px 4px 0px #28293D40',
        borderRadius: theme.shape.borderRadius,
        marginBottom:theme.spacing(1),
        padding:theme.spacing(1)
    }
}))