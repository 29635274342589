import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { EntradaFiltroModal } from "views/components/modals/entrada-filtro/entrada-filtro-modal";
import { EntradaListSearchProps } from "views/pages/private/entrada/components/entrada-list/entrada-list-search-props";

export const EntradaFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            dFinal: '',
            dInicial: '',
            status: -1,
            termo: ''
        } as EntradaListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.EntradaFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.EntradaFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <EntradaFiltroModal openned={modalState.openned} filtros={modalState.filtros} key="mdlEntradaFiltro" />
    )
}