import { useMemo } from 'react';
import * as Yup from 'yup';
import { FieldProps } from './form-personalizado';

interface FormPersonalizadoValidationProps {
    campos: FieldProps[];
}

export const useFormPersonalizadoValidation = ({ campos }: FormPersonalizadoValidationProps) => {
    const formPersonalizadoValidation = useMemo(() => {
        let obj: any = {};
        campos.forEach(campo => {
            if (campo.required) {
                const message = `Campo ${campo.label} é obrigatório`
                const tp = campo.tipoTextfield === 'NUMERO' ? Yup.number().required(message).typeError(message) : Yup.string().required(message)
                obj[campo.propName] = tp
            }
        })
        return Yup.object().shape({
            ...obj
        })
    }, [campos])
    return {
        formPersonalizadoValidation,
    }
}