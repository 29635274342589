import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetEquipamentos() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getEquipamentos = useCallback((empresaId: string) =>
    invocarApi({
      url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/equipamento`,
      method: "GET",
      enviarTokenUsuario: true
    }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getEquipamentos,
  }

}
