import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const DeletarProcessoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M41.7002 6.2999C39.0846 6.2999 36.4691 6.2999 33.8536 6.2999C32.195 6.2999 32.195 6.2999 32.1631 4.64715C32.1312 2.613 31.1105 1.30987 29.2924 1.27808C26.3898 1.21452 23.5191 1.21452 20.6165 1.27808C19.1812 1.30987 18.0967 2.35873 17.9053 3.78899C17.8415 4.29753 17.8096 4.80607 17.8415 5.3146C17.9053 6.07741 17.682 6.33168 16.8846 6.2999C13.8863 6.23633 10.8561 6.26811 7.85785 6.26811C7.18802 6.26811 6.64577 6.4906 6.61388 7.22162C6.55008 7.88908 7.02853 8.36583 7.60267 8.30227C8.78285 8.20691 8.68716 8.84259 8.68716 9.63718C8.68716 21.27 8.68716 32.9346 8.68716 44.5674C8.68716 46.8876 9.77164 47.9682 12.1001 48C20.7122 48 29.2924 48 37.9045 48C40.2967 48 41.3493 46.8876 41.3493 44.5038C41.3493 38.751 41.3493 32.9664 41.3493 27.2135C41.3493 21.2382 41.3493 15.2947 41.3493 9.31934C41.3493 8.93794 41.0303 8.30227 41.8916 8.33405C42.689 8.36583 43.4226 8.14335 43.3907 7.25341C43.3588 6.26811 42.4657 6.2999 41.7002 6.2999ZM22.0837 3.24867C24.2208 3.24867 26.3579 3.24867 28.495 3.24867C29.0691 3.24867 29.6751 3.1851 29.9622 3.78899C30.3131 4.52001 30.1217 5.34639 30.0579 6.1092C30.026 6.39525 29.5795 6.26811 29.3243 6.26811C27.857 6.26811 26.3898 6.26811 24.9225 6.26811C23.4872 6.26811 22.0837 6.23633 20.6484 6.26811C20.0424 6.2999 19.851 6.1092 19.851 5.47352C19.8191 3.40759 19.9148 3.24867 22.0837 3.24867ZM39.3398 44.2496C39.3398 45.8705 39.1484 46.0612 37.4898 46.0612C29.1648 46.0612 20.8398 46.0612 12.5148 46.0612C10.888 46.0612 10.6966 45.8705 10.6966 44.2178C10.6966 38.4967 10.6966 32.7757 10.6966 27.0228C10.6966 21.1746 10.7285 15.3264 10.6647 9.47826C10.6647 8.58832 10.8561 8.36583 11.7811 8.36583C20.6165 8.39762 29.4519 8.39762 38.2872 8.36583C39.2441 8.36583 39.3717 8.68367 39.3717 9.51004C39.3079 21.1111 39.3398 32.6803 39.3398 44.2496Z"/>
                <path d="M18.894 13.483C18.0328 13.4512 17.7776 14.0869 17.8095 14.8497C17.8095 15.0086 17.8095 15.1675 17.8095 15.3265C17.8095 23.2724 17.8095 31.1865 17.8095 39.1324C17.8095 39.4502 17.8095 39.7681 17.8414 40.0859C17.9371 40.6898 18.2879 40.9759 18.9259 40.9441C19.5 40.9123 19.7871 40.5945 19.8509 40.0541C19.8828 39.7363 19.8509 39.4185 19.8509 39.1006C19.8509 35.1595 19.8509 31.2183 19.8509 27.2453C19.8509 25.7515 19.8509 24.2577 19.8509 22.7321C19.8509 20.094 19.8509 17.456 19.8509 14.7861C19.8828 14.0869 19.6914 13.483 18.894 13.483Z"/>
                <path d="M26.0389 15.2947C26.0389 14.945 26.0708 14.5636 25.9751 14.2458C25.8475 13.8008 25.5285 13.483 25.0501 13.483C24.5078 13.4512 24.157 13.8008 23.9975 14.2776C23.9018 14.5954 23.9337 14.9768 23.9337 15.3264C23.9337 19.2676 23.9337 23.2406 23.9337 27.1817C23.9337 31.1865 23.9337 35.1594 23.9337 39.1642C23.9337 39.5138 23.9018 39.8952 23.9975 40.213C24.1251 40.658 24.4121 40.9441 24.9225 40.9758C25.4966 41.0076 25.8475 40.6898 25.9751 40.213C26.0708 39.8634 26.0389 39.5138 26.0389 39.1642C26.0389 31.1547 26.0389 23.2406 26.0389 15.2947Z"/>
                <path d="M32.0992 14.2458C31.9716 13.7372 31.5888 13.4194 31.0466 13.483C30.5363 13.5148 30.1535 13.8326 30.1216 14.3729C30.0897 14.6908 30.1216 15.0086 30.1216 15.3264C30.1216 19.2676 30.1216 23.2088 30.1216 27.1817C30.1216 31.0275 30.1216 34.8416 30.1216 38.6874C30.1216 39.037 30.1216 39.3866 30.1216 39.768C30.1216 40.4355 30.313 40.944 31.0785 40.9758C31.9397 41.0076 32.1311 40.4673 32.163 39.7363C32.163 39.5138 32.163 39.2595 32.163 39.037C32.163 31.1865 32.163 23.3359 32.163 15.4536C32.163 15.0086 32.1949 14.6272 32.0992 14.2458Z"/>
            </DefaultIcon>
        </>
    );
};