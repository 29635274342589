import { useCallback } from "react";
import * as Yup from "yup";
import { stringNumeros } from "utils/string-numeros";

export const useValidationYupCest = () => {
  const cestYup = useCallback(() => {
    return Yup.string()
      .optional()
      .test("cest", "O cest deve possuir 7 Dígitos", (value: any) => {
        let valueNumber = stringNumeros(value);
        return valueNumber.length === 7||valueNumber.length===0;
      });
  }, []);

  return {
    cestYup,
  };
};
