export enum EnumPorDia {
    HOJE = 0,
    SETEDIAS = 1,
    QUATORZEDIAS = 2,
    PERIODO = 3
}

export enum EnumPorMes {
    MESATUAL = 0,
    MESANTERIOR = 1,
    TRESMESES = 2,
    PERIODO = 3
}