import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPerfilValidation = () => {
  const FormPerfilValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        nome: Yup.string().required('Nome do Perfil é Obrigatório'),
        descricao: Yup.string().required('Descrição é Obrigatório'),
      })
    )
  }, [])
  return {
    FormPerfilValidationYup,
  }
}

