import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from './card-comanda-na-mesa-atendimento-styles';
import { useThemeQueries } from 'views';
import { CardComandaNaMesaAtendimentoProps } from './card-comanda-na-mesa-atendimento-props';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { toDecimalString } from 'utils/to-decimal';
import { useCallback } from 'react';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import useOnPress from 'utils/useOnPress';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import classNames from 'classnames';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';

export const CardComandaNaMesaAtendimento = ({
  model,
  onClick,
  codigoMesa,
  onHold,
  modeloTrabalho
}: CardComandaNaMesaAtendimentoProps) => {
  const classes = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const auxActionOnClick = useCallback(() => ({
    [EnumModeloDeTrabalho.APENAS_CAIXA]: () => onHold(model),
    [EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS]: () => onHold(model),
    [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS]: () => onClick(model),
  }), [model, onClick, onHold])

  const auxActionOnHold = useCallback(() => ({
    [EnumModeloDeTrabalho.APENAS_CAIXA]: () => onClick(model),
    [EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS]: () => onClick(model),
    [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS]: () => onHold(model),
  }), [model, onClick, onHold])

  const handlePress = useCallback(() => {
    auxActionOnHold()[modeloTrabalho]();
  }, [auxActionOnHold, modeloTrabalho]);

  const handleClick = useCallback(() => {
    auxActionOnClick()[modeloTrabalho]();
  }, [auxActionOnClick, modeloTrabalho]);

  const EventPressAndClick = useOnPress(handlePress, handleClick, {
    shouldPreventDefault: true,
    delay: 700
  });

  const ComandaOcupada = useCallback(
    (balcaoPedido: PedidoModelComanda) => {
      // DATA CRIACAO PEDIDO
      const horaCriacaoPedido = new Date(balcaoPedido?.dataCriacao).getHours();
      const minutosCriacaoPedido = new Date(
        balcaoPedido?.dataCriacao
      ).getMinutes();
      const pedidoDate = `${horaCriacaoPedido}:${minutosCriacaoPedido >= 10
        ? minutosCriacaoPedido
        : '0' + minutosCriacaoPedido
        }`;

      const hora = new Date(balcaoPedido?.systemUpdateDate ?? '').getHours();
      const minutos = new Date(
        balcaoPedido?.systemUpdateDate ?? ''
      ).getMinutes();
      const horaPedido = `${hora}:${minutos >= 10 ? minutos : '0' + minutos}`;

      const fillColor = balcaoPedido.statusPedido.codigo === EnumStatusPedido.FECHADO
        ? theme.palette.warning.main
        : balcaoPedido.statusPedido.codigo ===
          EnumStatusPedido.IMPORTADO
          ? theme.palette.grey[500]
          : balcaoPedido.isOciosa
            ? theme.palette.error.main
            : ''

      return (
        <Grid
          {...EventPressAndClick}
          className={classes.cardContainer}
          style={{
            borderColor: fillColor,
            boxShadow: balcaoPedido.isOciosa
              ? ''
              : '0px 3px 4px rgba(0, 0, 0, 0.09)'
          }}
        >
          <div className={classes.cardContent}>
            <div className={classes.topContent}>
              <Box display='flex' alignItems='center'>
                <ComandasIcon
                  tipo="INPUT"
                  fill={fillColor}
                />
                <h4 className={classes.codigoComanda}>
                  {balcaoPedido?.codigoComanda}
                </h4>
              </Box>
              <Box display='flex' alignItems='center'>
                <PessoaIcon
                  tipo="INPUT"
                  fill={fillColor}
                  style={{ width: isMobile ? '20px' : undefined }}
                />
                <h4 className={classNames(classes.codigoComanda, classes.pessoaLabel)}>
                  {balcaoPedido.cliente.nomeFantasia.split(' ')[0]}
                </h4>
              </Box>
            </div>
            <div className={classes.totalContainer}>
              <SacoDinheiroIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>
                R${' '}
                {toDecimalString(
                  balcaoPedido?.produtos
                    .filter(
                      (p) =>
                        (p.status.codigo ===
                          EnumStatusProdutoPedido.DISPONIVEL ||
                          p.status.codigo ===
                          EnumStatusProdutoPedido.TROCADO) &&
                        p.indFin
                    )
                    .reduce((acc, current) => acc + current.valorTotal, 0),
                  2
                )}
              </Typography>
            </div>
            <div className={classes.mesaContainer}>
              <MesaIcon
                tipo="INPUT"
                fill={fillColor}
                style={{ width: '20px' }}
              />
              <Typography className={classes.textInfo}>{codigoMesa}</Typography>
            </div>
            <div className={classes.footer}>
              <div className={classes.hourContent}>
                <Typography variant="body1">{pedidoDate}</Typography>
              </div>
              {balcaoPedido.statusPedido.codigo === EnumStatusPedido.FECHADO ? (
                <Grid className={classes.fechamentoContainer}>
                  <Typography variant="body1">Fechada</Typography>
                </Grid>
              ) : balcaoPedido.statusPedido.codigo ===
                EnumStatusPedido.IMPORTADO ? (
                <Grid className={classes.importadoContainer}>
                  <Typography variant="body1">Importado</Typography>
                </Grid>
              ) : (
                <>
                  <Typography
                    className={classes.textBottomInfo}
                    variant="body2"
                  >
                    Último produto às {horaPedido}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </Grid>
      );
    },
    [
      theme.palette.warning.main,
      theme.palette.grey,
      theme.palette.error.main,
      EventPressAndClick,
      classes.cardContainer,
      classes.cardContent,
      classes.topContent,
      classes.codigoComanda,
      classes.pessoaLabel,
      classes.totalContainer,
      classes.textInfo,
      classes.mesaContainer,
      classes.footer,
      classes.hourContent,
      classes.fechamentoContainer,
      classes.importadoContainer,
      classes.textBottomInfo,
      isMobile,
      codigoMesa
    ]
  );

  return <>{ComandaOcupada(model)}</>;
};
