import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogInformacaoAdicional } from 'views/components/dialog/dialog-informacao-adicional/dialog-informacao-adicional';

class DialogInformacaoAdicionalProps {
    constructor(
        public aberto: boolean = false,
        public permiteFechar: boolean = true
    ) { }
}

export const DialogInformacaoAdicionalRender = () => {

    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<DialogInformacaoAdicionalProps>(new DialogInformacaoAdicionalProps());

    const modalAlterado = useCallback(({ aberto, permiteFechar }: DialogInformacaoAdicionalProps) => {
        setModalState({
            aberto,
            permiteFechar
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.DialogInformacaoAdicional, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.DialogInformacaoAdicional, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogInformacaoAdicional
                    aberto={modalState.aberto}
                    permiteFechar={modalState.permiteFechar}
                />
            )}
        </>
    );
};