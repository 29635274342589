import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-enviar-cupom-venda-por-email-styles';
import { VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useCallback, useRef, useState } from 'react';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { useValidationYupEmail } from 'views/components/form-validations';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

interface DialogDialogeEnviarCupomVendaPorEmailProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarEmail: () => void;
  email: string;
  setEmail: (email: string) => void;
}

export const DialogEnviarCupomEmail = ({
  openned,
  closeModal,
  enviarEmail,
  loading,
  email,
  setEmail,
}: DialogDialogeEnviarCupomVendaPorEmailProps) => {
  const classes = useStyles();
  const showContratar = enviarEmail;
  const refInput = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { emailYup } = useValidationYupEmail()
  const [erro, setErro] = useState<string>('')

  let schema = Yup.object({
    email: emailYup(),
  });

  const onClickEmail = useCallback(async (ev: any) => {
    ev.preventDefault()

    schema.isValid({
      email: email
    }).then((valid) => {
      if (!valid) {
        setErro('O email é inválido.')
        return
      }
      setErro('')
      enviarEmail()
    })

  }, [email, enviarEmail, schema])

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Enviar por E-mail"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ flex: 1 }}>
              <Typography className={classes.label}>
                Informe o E-mail para envio do conteúdo:
              </Typography>
              <TextFieldSaurus
                inputRef={refInput}
                tipo="EMAIL"
                fullWidth
                size="medium"
                allowSubmit={true}
                value={email}
                placeholder="email@exemplo.com"
                error={!isEmpty(erro)}
                helperText={!isEmpty(erro) && erro}
                onChange={(e) => setEmail(e.target.value)}
                adornmentColor={theme.palette.text.disabled}
              />
            </Box>
            <form onSubmit={onClickEmail}>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                <Grid item xs={4}>
                  {closeModal && (
                    <Button
                      disabled={loading}
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                      className='round'
                    >
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={!showContratar || loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    className='round'
                  >
                    <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                    {'Enviar cupom'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
