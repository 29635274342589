import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerPessoa: {
    display: 'flex',
    flex: 1,
    padding: 8,
    margin: '16px 16px 24px 16px',
    background: theme.palette.primary.main,
    borderRadius: 8
  },
  labelPessoa: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '18px',
    marginRight: 8,
    color: '#fff'
  },
  labelInfoPessoa: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
    color: '#fff'
  },
  containerIconPessoa: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerIconEditar: {
    background: '#fff',
    marginLeft: 8,
    width: 48,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  content: {
    flex: 1,
    padding: 16
  },
  sessaoHeader: {
    flex: 1,
    marginBottom: 16
  },
  sessaoText: {
    fontSize: 16,
    fontWeight: 700
  },
  sessaoTextItem: {
    fontSize: 14,
    fontWeight: 600
  },
  labelRadio: {
    margin: 0
  },
  gridCheck: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 90,
    heigth: 60,
    "& .MuiSwitch-root": {
      margin: 0,
    },
  },
  contentOpcao: {
    marginBottom: 8,
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    height: 50
  }
}));
