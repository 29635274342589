import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCadastros } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useMovInsercaoStorage } from 'services/app/hooks/mov-insercao-storage';
import { IdentificacaoCliente } from './components/identificacao-fragment/identificacao-fragment';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { isEmpty } from 'lodash';
import { DialogFinalizarVendaGarcom } from 'views/components/dialog/finalizar-venda-garcom/dialog-finalizar-venda-garcom';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';

export const MovClienteIdentificacaoPage = () => {
  //STATES E REFS

  //PROVIDERS
  const { getMov, setTipoMovimentacao, verificaProdutosControlados, naoInformarDadosDaReceita, getSeUsaIdentificador, carregando } = useMovAtual();
  const { getInsercaoFavorita } = useMovInsercaoStorage();
  const { getConfigByCod } = usePDV();
  const { abrirDialogControlado } = useCadastros();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { isPedidoDelivery } = usePedidoDelivery()

  const getInsercao = useCallback(() => {
    if (!isPedidoDelivery() && getInsercaoFavorita() === EnumInsercaoFavorita.ATENDIMENTO_DELIVERY) {
      return '/venda-simples/catalogo'
    }
    return getInsercaoFavorita()
  }, [getInsercaoFavorita, isPedidoDelivery])

  const [dialogConfirmar, setDialogConfirmar] = useState<boolean>(false);

  const modeloTrabalho = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho);

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS,
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const isLancador = [
    EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho)

  const isSomenteCaixa = modeloTrabalho as EnumModeloDeTrabalho === EnumModeloDeTrabalho.APENAS_CAIXA;

  const isMesaOuComanda = (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA ||
    getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA)

  //AUX
  const history = useHistory();

  const redirecionarVenda = useCallback(async () => {
    const mov = getMov();
    if (!mov) return;

    const medicamentos = await verificaProdutosControlados();
    if (medicamentos && medicamentos.length > 0) {
      const qtd = medicamentos.reduce((acc, current) => acc + current.qCom, 0);
      abrirDialogControlado(qtd, naoInformarDadosDaReceita, redirecionarVenda)
      return
    }
    const redirectOptions = {
      1: async () => {
        await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO)
        history.push('/venda-simples/enviar-pedido')
      },
      2: () => {
        setDialogConfirmar(true)
      },
      3: async () => {
        await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO)
        if (getConfigByCod(EnumPDVConfigCod.MomentoSolicitacaoMesaComanda) === '0' &&
          isMesaOuComanda &&
          (isEmpty(mov.informacoesGeraisPedido.mesaId) || isEmpty(mov.informacoesGeraisPedido.comandaId))) {
          redirectOptions[5]()
          return
        }
        history.push('/venda-simples/catalogo')
      },
      4: async () => {
        if (getConfigByCod(EnumPDVConfigCod.MomentoSolicitacaoMesaComanda) === '0' &&
          isMesaOuComanda &&
          (isEmpty(mov.informacoesGeraisPedido.mesaId) || isEmpty(mov.informacoesGeraisPedido.comandaId)) &&
          isLancador) {
          redirectOptions[5]()
          return
        }

        history.push('/venda-simples/catalogo')
      },
      5: async () => {
        await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO)
        if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA) {
          history.push('/venda-simples/leitor-comanda')
        } else if ((getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA)) {
          history.push('/venda-simples/vincular-mesa')
        } else if (!getSeUsaIdentificador() && !mov.informacoesGeraisPedido.identificador) {
          history.push('/venda-simples/identificador-pedido')
        } else {
          history.push('/venda-simples/enviar-pedido')
        }
      },
      6: async () => {
        await setTipoMovimentacao(EnumTipoMovimentacao.VENDA)
        if (mov.vPago < mov.vNF) {
          history.push('/venda-simples/novo-pagamento');
          return;
        } else if (mov.produtos.length > 0) {
          history.push('/venda-simples/finalizar-venda');
          return;
        } else {
          const rota = getInsercao();
          history.push(rota);
          return;
        }
      }
    }
    if (isEmpty(mov.produtos.filter(prod => prod.ativo))) {
      redirectOptions[isSomentePedido ? 3 : 4]();
      return
    } else if (isEmpty(mov.informacoesGeraisPedido.mesaId) && isEmpty(mov.informacoesGeraisPedido.comandaId)) {
      redirectOptions[isSomenteCaixa || mov.tipoMovimentacao === EnumTipoMovimentacao.VENDA ? 6 : 5]();
      return
    } else {
      redirectOptions[isSomenteCaixa || mov.tipoMovimentacao === EnumTipoMovimentacao.VENDA ? 6 : 1]();
      return
    }

  }, [getMov, verificaProdutosControlados, abrirDialogControlado, naoInformarDadosDaReceita, setTipoMovimentacao, history, getConfigByCod, isMesaOuComanda, isLancador, getConfiguracoesMesaEComanda, getSeUsaIdentificador, getInsercao, isSomentePedido, isSomenteCaixa]);

  return (
    <>
      <IdentificacaoCliente
        redirecionarVenda={redirecionarVenda}
        loading={carregando}
      />
      {dialogConfirmar &&
        <DialogFinalizarVendaGarcom
          closeModal={() => setDialogConfirmar(false)}
          openned={dialogConfirmar}
        />
      }
    </>
  );
};
