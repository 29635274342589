import CSS from 'csstype';

export enum EnumTipoRetornoArquivo{
    Base64,
    String
}

export interface ArquivoInputProps {
    tipoRetorno?: EnumTipoRetornoArquivo;
    nameArquivo?: string;
    accept?: string;
    value?: string;
    label?: string;
    disabled?:boolean;
    className?: string;
    loadErrorImage?: string;
    onChange?: (dados?: ImagemInputPropsResposta) => any;
    helperText?: string;
    error?: boolean;
    name?: string;
    onBlur?: (name: string) => any;
    width?: CSS.Properties['width'];
    height?: CSS.Properties['height'];
    mode?: CSS.Properties['objectFit'];
}

export interface ImagemInputPropsResposta {
    arquivo?: File | undefined;
    base64?: string;
    tipoRetorno?: EnumTipoRetornoArquivo;
}