import { ModalProps } from '../utils/modal-props';
import { DefaultModal } from "../components"
import { isEmpty } from 'lodash';
import { MesasReconfigurar } from './mesas-modal/mesas-reconfigurar/mesas-reconfigurar';
import { MesasEdit } from './mesas-modal/meas-edit/mesas-edit';
import { useCallback } from 'react';
import { EnumCadastrosMesas } from 'model/enums/enum-cadastros-mesas';
import { MesasAdicionar } from './mesas-modal/mesas-adicionar/mesas-adicionar';
import { GestaoStorageKeys, useGestaoStorage } from 'services/app';

export const MesasModal = (props: ModalProps) => {

    const { getRegistro } = useGestaoStorage();
    const valor = getRegistro(GestaoStorageKeys.modoCadastroMesa, false);

    const switchModoCadastro = useCallback(() => {
        switch (valor.modo) {
            case EnumCadastrosMesas.Reconfigurar:
                return (
                    <MesasReconfigurar temMesa={valor.temMesa} salaoId={valor.salaoId} />
                );
            case EnumCadastrosMesas.Adicionar:
                return (
                    <MesasAdicionar salaoId={valor.salaoId} />
                );
        }
    }, [valor.modo, valor.salaoId, valor.temMesa])

    return (
        <DefaultModal
            minWidth="400px"
            open={props.openned || false}
            variant={'temporary'}
            anchor="right"
        >
            {props.openned && isEmpty(props.id) && switchModoCadastro()}
            {props.openned && !isEmpty(props.id) && <MesasEdit id={props.id} salaoId={valor.salaoId} />}
        </DefaultModal>
    )
}