import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useDeleteProdutoCodigo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteProdutoCodigo = useCallback(
    (produtoId: string, variacaoId: string, id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/Codigo/${id}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        enviarTokenUsuario: true,
      })
    ,
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    deleteProdutoCodigo,
  };
}
