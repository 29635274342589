import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { useStyles } from './accordion-upload-carga-styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Upload } from 'views/components/icons/upload';
import { UploadCargaIcon } from 'views/components/icons/upload-carga-icon';
import { BaixarIcon } from 'views/components/icons';
// import { BaixarIcon } from 'views/components/icons';

export const AccordionUploadCarga = () => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);

  // AUX
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const classes = useStyles();
  const history = useHistory();

  return (
    <AccordionSaurus
      labelPrimary={`Conversão de dados`}
      tituloChildren={
        <Upload tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classNames(classesModal.root, classes.root)}>
        <div className={classesModal.content}>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', fontWeight: '500' }}
                >
                  Faça o Upload de produtos ou pessoas para facilitar o
                  cadastramento. O arquivo deve ser em (.CSV/.TXT)
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  fullWidth
                  className={classes.button}
                  disabled={false}
                  href="/modelosDeCarga/csvModeloPessoas.csv"
                  variant="outlined"
                  color="primary"
                  download
                >
                  <BaixarIcon tipo='BUTTON' />
                  Baixar CSV Modelo para Pessoas
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  fullWidth
                  className={classes.button}
                  disabled={false}
                  href="/modelosDeCarga/csvModeloProdutos.csv"
                  variant="outlined"
                  color="primary"
                  download
                >
                  <BaixarIcon tipo='BUTTON' />
                  Baixar CSV Modelo para Produtos
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  fullWidth
                  className={classes.button}
                  disabled={false}
                  onClick={() => history.push('/upload-carga')}
                  variant="contained"
                  color="primary"
                >
                  <UploadCargaIcon tipo='BUTTON_PRIMARY' />
                  Conversões
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </AccordionSaurus>
  );
};
