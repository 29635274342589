import { EnumBalanca } from '../../../../enums/enum-balanca';
export class ProdutoNomeMedidaPreCadastroFormModel {
  constructor(
    public nome: string = "",
    public infAdic: string = "",
    public medidaId: string|null = null,
    public medida: string = "",
    public balanca: EnumBalanca=EnumBalanca.Normal
  ) {}
}
