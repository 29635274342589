import { Grid, Typography, Fade, Button } from "@material-ui/core";
import { VariaveisAmbiente } from "config";
import { isEmpty } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { useState } from "react";
import { GestaoStorageKeys, useGestaoStorage } from "services/app";
import { CloseIcon } from "views/components/icons/close-icon";
import { FacilIcon } from "views/components/icons/facil-icon";
import { useStyles } from "./card-propaganda-url-styles";


export const CardPropagandaUrl = () => {
    const { getRegistro, setRegistro } = useGestaoStorage();
    const confirm = useConfirm();

    const mustShow = isEmpty(getRegistro(GestaoStorageKeys.SugestaoMelhorias, true)) &&
        isEmpty(getRegistro(GestaoStorageKeys.SugestaoMelhorias, false));

    const [showCard, setShowCard] = useState<boolean>(mustShow);
    const classes = useStyles();
    return (
        <>
            {VariaveisAmbiente.paymentDevice !== EnumDeviceType.NAVIGATOR && (
                <Fade
                    in={showCard}
                    mountOnEnter
                    unmountOnExit>
                    <Grid className={classes.container}>
                        <Grid className={classes.content}>

                            <Grid className={classes.containerInterno}>
                                <Grid className={classes.header}>
                                    <Grid className={classes.containerTitulo}>
                                        <Grid className={classes.img}>
                                            <FacilIcon tipo="BUTTON_PRIMARY" />
                                        </Grid>
                                        <Typography variant="h6" className={classes.textTitle}>
                                            Precisa de mais agilidade?
                                        </Typography>
                                    </Grid>
                                    <Button
                                        color="secondary"
                                        className={classes.buttonClose}
                                        onClick={() => {
                                            confirm({
                                                title: `Lembrete de Melhorias`,
                                                description: `Gostaria de ocultar as sugestões de seu ambiente?`,
                                                cancellationText: 'Nunca Sugerir',
                                                cancellationButtonProps: {
                                                    color: 'default',
                                                },
                                                confirmationText: 'Lembrar Depois',
                                            })
                                                .then(() => {
                                                    setRegistro(GestaoStorageKeys.SugestaoMelhorias, 'false', true);
                                                    setShowCard(!showCard)
                                                })
                                                .catch(() => {
                                                    setRegistro(GestaoStorageKeys.SugestaoMelhorias, 'false', false);
                                                    setShowCard(!showCard)
                                                })


                                        }}
                                    >
                                        <CloseIcon tipo="BUTTON_PRIMARY"></CloseIcon>
                                    </Button>
                                </Grid>
                                <Grid className={classes.contentSubtitle}>
                                    <Grid className={classes.textInfoAndButton}>
                                        <Grid >
                                            <Typography variant="body2" className={classes.text}>
                                                Acesse nossa versão online e ganhe muita performance!
                                            </Typography>
                                            <Typography variant="body1" className={classes.text}>
                                                <b>
                                                    {VariaveisAmbiente.frontUrl.replaceAll("https://", "").replaceAll("/", "")}
                                                </b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade>
            )
            }
        </>
    );
};
