import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import CardMedicamento from '../card-medicamento/card-medicamento';
import { MedicamentoPreco } from 'model/api/gestao/medicamento/medicamento-model';

export interface ProdutoGridProps {
  list: Array<MedicamentoPreco>;
  carregando: boolean;
  onCardClicked: (produto: MedicamentoPreco) => void;
  onCardHolded: (produto: MedicamentoPreco) => void;
}

export const PesquisarMedicamentoListData = (props: ProdutoGridProps) => {
  const onCardChecked = (produto: MedicamentoPreco) => {
    props.onCardHolded(produto);
  };

  const onCardHolded = (produto: MedicamentoPreco) => {
    props.onCardHolded(produto);
  };

  return (
    <>
      {props.list.length > 0 && props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem={props.carregando ? `Procurando produtos` : "Nenhum Produto encontrado."}
          icon={props.carregando
            ? <div
              style={{
                marginRight: 30,
                marginTop: 0,
                position: 'relative',
              }}
            > <CircularLoading tipo="ONAUTOCOMPLETE" />
            </div>
            : <InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((produto) => {
          return (
            <CardMedicamento onClick={onCardChecked} qtdeCarrinho={undefined} onHold={onCardHolded} model={produto} key={produto.id} />
          );
        })}
    </>
  );
};
