import { Button, Grid } from '@material-ui/core';
import { useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import { useStyles } from './form-politica-aceite-styles';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { PoliticaAceiteFormModel } from '../../../../../model/app/forms/master/politica-aceite-form';
import { useFormPoliticaAceiteValidation } from './form-politica-aceite-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

export const FormPoliticaAceite = React.forwardRef<
  DefaultFormRefs<PoliticaAceiteFormModel>,
  DefaultFormProps<PoliticaAceiteFormModel>
>((props: DefaultFormProps<PoliticaAceiteFormModel>, refs) => {
  const txtRg = useRef<HTMLInputElement>();
  const { FormPoliticaAceiteValidationYup } = useFormPoliticaAceiteValidation();
  const utilClasses = makeUtilClasses();
  const [model, setModel] = useState({
    modeloForm: new PoliticaAceiteFormModel('', ''),
  });
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<PoliticaAceiteFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormPoliticaAceiteValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (form: PoliticaAceiteFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new PoliticaAceiteFormModel('', '') });
      reset();
      if (!isMobile) txtRg?.current?.focus();
    },
    fillForm: (model: PoliticaAceiteFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtRg?.current?.focus();
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${
            props.loading ? utilClasses.controlLoading : ''
          }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="rg"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    inputRef={txtRg}
                    autoComplete={'off'}
                    label="RG do Responsável"
                    placeholder=""
                    error={Boolean(errors.rg && errors.rg.message)}
                    helperText={
                      touchedFields.rg || errors.rg
                        ? errors.rg?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={true}
                    autoComplete={'off'}
                    label="Nome Completo (Pessoa Física)"
                    placeholder="Ex: João da Silva"
                    error={Boolean(errors.nome && errors.nome.message)}
                    helperText={
                      touchedFields.nome || errors.nome
                        ? errors.nome?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
