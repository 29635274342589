import { Box, Tooltip } from '@material-ui/core';
import { useStyles } from './menu-pdv-styles';
import { useMenuFavoritos } from 'services/app/hooks/menu-favoritos';
import { CardItemMenu } from './components/card-item-menu/card-item-menu';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MenuModel } from 'model/app';
import { MenuPdvProps } from './menu-pdv-props';
import classNames from 'classnames';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumMenu } from 'model';
import { DeliveryIcon } from "views/components/icons/delivery-icon"
import { usePedidoDelivery } from "services/app/hooks/pedido-delivery"

export const MenuPdv = ({ ocultar }: MenuPdvProps) => {
  const classes = useStyles();
  const { getFavoritos } = useMenuFavoritos();
  const { addHandler, removeHandler } = useEventTools();
  const [menus, setMenus] = useState<MenuModel[]>(getFavoritos() ?? []);
  const [active, setActive] = useState<boolean>(true);
  const { getConfigByCod } = useEmpresaAtual()
  const { isPedidoDelivery } = usePedidoDelivery()
  const { getConfigByCod: getConfigPDV } = usePDV()
  const modeloTrabalho = getConfigPDV(EnumPDVConfigCod.ModeloTrabalho)
  const solicitarClientePedido = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClientePedido)
  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClienteVenda)

  const soliClientesPorModeloTrabalho = useCallback(() => {
    if (modeloTrabalho === '2') {
      return Number(solicitarClientePedido)
    }

    return Number(solicitarClienteVenda)
  }, [modeloTrabalho, solicitarClientePedido, solicitarClienteVenda])

  const checkMenuPDV = useCallback(() => {
    setMenus(getFavoritos());
  }, [getFavoritos]);

  useEffect(() => {
    addHandler(AppEventEnum.MenuPDVFavorito, checkMenuPDV);
    addHandler(AppEventEnum.AlterarMenuPDV, setActive);
    return () => {
      removeHandler(AppEventEnum.MenuPDVFavorito, checkMenuPDV);
      removeHandler(AppEventEnum.AlterarMenuPDV, setActive);
    };
  }, [addHandler, checkMenuPDV, getFavoritos, removeHandler]);

  return active ? (
    <Box
      className={classNames(
        classes.drawer,
        ocultar || menus.length === 0 ? classes.ocultar : null
      )}
    >
      {isPedidoDelivery() && (
        <Box className={classes.contentDelivery}>
          <Tooltip title="A venda está no modo delivery">
            <div>
              <DeliveryIcon tipo="GERAL" class={classes.iconDelivery} />
            </div>
          </Tooltip>
        </Box>
      )}
      {menus.filter(m => {
        if (soliClientesPorModeloTrabalho() === 0) {
          return m.codigo !== EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE
        }

        return m
      }).map((menu) => {
        return <CardItemMenu key={menu.id} model={menu} />;
      })}
    </Box>
  ) : (
    <></>
  );
};
