import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetPerfis() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPerfis = useCallback(
    (nome: string, page?: number) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/perfil?Nome=${nome}&page=${page?page:1}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  const getPerfisDrop = useCallback(
    () => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/perfil?page=1&pageSize=0`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getPerfis,
    getPerfisDrop
  };
}
