import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { CardComandaFechamento } from 'views/components/cards/card-comanda-fechamento/card-comanda-fechamento';

export interface ComandasFechamentoListDataProps {
  comandas: Array<PedidoModelComanda>;
  carregando: boolean;
  onCardSelected: (comanda: PedidoModelComanda) => any;
  comandaSeleciona: Array<PedidoModelComanda>;
}

export const ComandasFechamentoListData = (
  props: ComandasFechamentoListDataProps,
) => {
  const onCardSelected = (comanda: PedidoModelComanda) => {
    props.onCardSelected(comanda);
  };

  return (
    <>
      {props.comandas.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Comanda encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}

      {props.comandas.length > 0 &&
        props.comandas.map((comanda, index) => {
          return (
            <CardComandaFechamento
              model={comanda}
              key={index}
              onClick={onCardSelected}
              selecionado={
                props.comandaSeleciona.filter(
                  (c) => c.comandaId === comanda.comandaId,
                ).length === 1
                  ? true
                  : false
              }
            />
          );
        })}
    </>
  );
};
