import { useThemeQueries } from "views/theme";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { Button, Divider, Grid } from "@material-ui/core";
import { useCallback, useRef } from "react";
import { OkIcon, VoltarIcon } from "views/components/icons";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { AdicionarDescontoAcrescimoFormModel } from "model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import { EnumDecontoOuAcrescimo } from "model/enums/enum-desconto-ou-acrescimo";
import { EnumTpPermissaoUsuario } from "model/enums/enum-tp-permissao-usuario";
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";
import { useCarrinho } from "services/app/hooks/carrinho";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { FormAdicionarDescontoAcrescimo } from "views/components/form/adicionar-desconto-acrescimo/form-adicionar-desconto-acrescimo";

export interface DialogAdicionarAcrescDescProps {
    aberto: boolean;
    tipo: EnumDecontoOuAcrescimo;
    model?: MovSimplesProdutoModel;
    valorTotal?: number;
}

export const DialogAdicionarAcrescDesc = ({
    aberto,
    model,
    tipo,
    valorTotal,
}: DialogAdicionarAcrescDescProps) => {

    //AUX
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();

    //PROVIDERS
    const { fecharDialogAdicionarAcrescDesc, abrirSolicitarPermissao } = useCadastros();
    const { adicionarDescontoProduto, adicionarAcrescimoProduto } = useCarrinho();
    const { getPermissaoBoolean, getPermissaoDesconto } = useSessaoAtual();
    const { adicionarDescontoVenda, adicionarAcrescimoVenda } = useMovAtual();

    //REFS
    const refFormDescontoAcrescimo = useRef<DefaultFormRefs<AdicionarDescontoAcrescimoFormModel>>(null);

    const handleSubmmit = useCallback((value: AdicionarDescontoAcrescimoFormModel) => {
        const valor = value.tpCampo === EnumTpDescontoAcrescimo.Porcentagem ? value.vPorcentagem : value.vValorFixo

        const limitePorcentagem = 100;

        switch (tipo) {
            case EnumDecontoOuAcrescimo.AcrescimoItem:
                //caso não tenha permissão
                if (!getPermissaoBoolean(EnumCodigosPermissoes.ACRESCIMO_ITEM)) {
                    abrirSolicitarPermissao(
                        async () => {
                            adicionarAcrescimoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                        }, EnumCodigosPermissoes.ACRESCIMO_ITEM, 'aplicar acréscimo no produto', EnumTpPermissaoUsuario.Numero, valor)
                    return;
                }

                //caso tenha permissão segue o fluxo normalmente
                adicionarAcrescimoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                return
            case EnumDecontoOuAcrescimo.DescontoItem:

                if (value.tpCampo === EnumTpDescontoAcrescimo.Porcentagem && (value.vPorcentagem ?? 0) >= limitePorcentagem) {
                    showToast('error', 'O valor da porcentagem não pode ser maior ou igual que 100%.')
                    return
                }

                if (value.tpCampo === EnumTpDescontoAcrescimo.ValorFixo && (value.vValorFixo ?? 0) >= (model?.vFinal ?? 0)) {
                    showToast('error', 'O valor não pode ser maior ou igual ao valor do produto')
                    return
                }

                //caso não tenha permissão
                if (!getPermissaoDesconto(EnumCodigosPermissoes.DESCONTO_ITEM, valor!, value.tpCampo, ((model?.qCom ?? 0) * (model?.vUnCom ?? 0)))) {
                    abrirSolicitarPermissao(
                        async () => {
                            adicionarDescontoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                        }, EnumCodigosPermissoes.DESCONTO_ITEM, 'aplicar desconto no produto', EnumTpPermissaoUsuario.Numero, valor)
                    return;
                }

                //caso tenha permissão segue o fluxo normalmente
                adicionarDescontoProduto(model, value, fecharDialogAdicionarAcrescDesc);
                return
            case EnumDecontoOuAcrescimo.AcrescimoVenda:
                // caso não tenha permissão
                if (!getPermissaoBoolean(EnumCodigosPermissoes.ACRESCIMO_VENDA)) {
                    abrirSolicitarPermissao(
                        async () => {
                            adicionarAcrescimoVenda(value, fecharDialogAdicionarAcrescDesc);
                        }, EnumCodigosPermissoes.ACRESCIMO_VENDA, 'aplicar acréscimo na venda total')
                    return;
                }

                // caso tenha permissão segue o fluxo normalmente
                adicionarAcrescimoVenda(value, fecharDialogAdicionarAcrescDesc);
                return
            case EnumDecontoOuAcrescimo.DescontoVenda:

                if (value.tpCampo === EnumTpDescontoAcrescimo.Porcentagem && (value.vPorcentagem ?? 0) >= limitePorcentagem) {
                    showToast('error', 'O valor da porcentagem não pode ser maior ou igual que 100%.')
                    return
                }

                if (value.tpCampo === EnumTpDescontoAcrescimo.ValorFixo && (value.vValorFixo ?? 0) >= (valorTotal ?? 0)) {
                    showToast('error', 'O valor não pode ser maior ou igual ao valor total da venda.')
                    return
                }

                //caso não tenha permissão
                if (!getPermissaoDesconto(EnumCodigosPermissoes.DESCONTO_VENDA, valor!, value.tpCampo, ((model?.qCom ?? 0) * (model?.vUnCom ?? 0)))) {
                    abrirSolicitarPermissao(
                        async () => {
                            adicionarDescontoVenda(value, fecharDialogAdicionarAcrescDesc);
                        }, EnumCodigosPermissoes.DESCONTO_VENDA, 'desconto na venda total',
                        EnumTpPermissaoUsuario.Numero, valor)
                    return;
                }

                //caso tenha permissão segue o fluxo normalmente
                adicionarDescontoVenda(value, fecharDialogAdicionarAcrescDesc);
                return
        }
    },
        [
            abrirSolicitarPermissao,
            adicionarAcrescimoProduto,
            adicionarAcrescimoVenda,
            adicionarDescontoProduto,
            adicionarDescontoVenda,
            fecharDialogAdicionarAcrescDesc,
            getPermissaoBoolean,
            getPermissaoDesconto,
            showToast,
            model,
            tipo,
            valorTotal,
        ],
    );

    const switchTitulo = useCallback(() => {
        switch (tipo) {
            case EnumDecontoOuAcrescimo.AcrescimoItem:
                return 'Acréscimo no Produto'
            case EnumDecontoOuAcrescimo.AcrescimoVenda:
                return 'Acréscimo na Venda'
            case EnumDecontoOuAcrescimo.DescontoItem:
                return 'Desconto no Produto'
            case EnumDecontoOuAcrescimo.DescontoVenda:
                return 'Desconto na Venda'
        }
    }, [tipo])

    return (
        <>
            <DialogSaurus
                aberto={aberto}
                titulo={switchTitulo()}
                isButtonTitleClose
                handleClickClose={fecharDialogAdicionarAcrescDesc}
                tamanho='xs'
                colorTitulo={theme.palette.primary.main}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormAdicionarDescontoAcrescimo
                            ref={refFormDescontoAcrescimo}
                            loading={false}
                            showLoading={false}
                            onSubmit={handleSubmmit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={fecharDialogAdicionarAcrescDesc}
                        >
                            <VoltarIcon tipo="BUTTON" />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => refFormDescontoAcrescimo.current?.submitForm()}
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Aplicar
                        </Button>
                    </Grid>
                </Grid>
            </DialogSaurus>
        </>
    )
}