import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetNcms() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getNcms = useCallback(
    (query: string, page?: number) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/ncm?&page=${page ? page : 1}&${query}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getNcms,
  };
}
