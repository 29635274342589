import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { useMemo } from 'react';
import { validarEmail } from 'utils/valida-email';
import * as Yup from 'yup';

export const useFormPessoaContatoValidation = () => {

    const FormPessoaContatoValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                valor: Yup.string().test({
                    message: 'Formato Inválido',
                    test: (value, context) => {
                        switch (context.parent.tipo) {
                            case EnumTipoPessoaContato.EMAIL:
                                return Boolean(value && validarEmail(value));
                            default:
                                return Boolean(value && (value.length === 14 || value.length === 15))
                        }
                    }
                }).required('Campo é obrigatório.')
            })
        )
    }, [])
    return {
        FormPessoaContatoValidationYup,
    }
}

