import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { guidEmpty } from "../../../../../utils/guid-empty";
import { EnumBalanca } from "../../../../enums/enum-balanca";

export class ProdutoNovoModel {
  constructor(
    public id: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public impostoId: null = null,
    public categoriaId: string | null = null,
    public codigo: string = "",
    public nome: string = "",
    public infAdic: string = "",
    public grade: string = "",
    public vPreco: number = 0,
    public vCompra: number = 0,
    public medidaEntradaId: string | null = null,
    public medidaSaidaId: string | null = null,
    public ncmId: string | null = null,
    public marcaId: string | null = null,
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public imagemUrl: string = "",
    public favorito: boolean = false,
    public setorId: string | null = null,
    public cobraTaxaServico: boolean = true,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
    public indAdic: string = "",
    public codigoAnvisa: string | null = null,
  ) { }
}
