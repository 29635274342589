import { Button, Grid, Typography } from '@material-ui/core';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { isEmpty } from 'lodash';
import { ConfiguracaoMesaEComanda } from 'model/app/mov-pedido-local/configuracao-mesa-e-comanda';
import { EnumComandas } from 'model/enums/enum-comandas';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useToastSaurus
} from 'services/app';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal/menu-principal';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useMovInsercaoStorage } from 'services/app/hooks/mov-insercao-storage';
import { usePDV } from 'services/app/hooks/pdv';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { CircularLoading, useThemeQueries } from 'views';
import { ButtonPrivateHeader } from 'views/components/controles';
import { DialogAlteracaoCadastros } from 'views/components/dialog/dialog-alteracao-cadastros/dialog-alteracao-cadastros';
import { PrivatePageHeader } from 'views/components/headers';
import { AtualizarIcon, CancelarIcon, MenuIcon } from 'views/components/icons';
import { useDefaultMovimentacaoStyles } from '../components/styles/default-movimentacao-styles';
import { PDVDiferenteConta } from './components/pdv-diferente-conta';
import { PDVDiferenteEmpresa } from './components/pdv-diferente-empresa';
import { useStyles } from './mov-simples-landing-page-styles';
import { useSessaoAtual } from 'services/app';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';
import { useGetConfigContratoByCod } from 'data/api/gestao/contrato/get-contrato-config-by-cod';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';

export const MovSimplesLandingPage = () => {
  const [alteracaoCadastros, setAlteracaoCadastros] = useState<boolean>(false);
  const { getSessao } = useSessaoPDV();
  const { getUltimaSincronizacao } = useSincronizacaoCadastros();
  const {
    restaurarMov,
    getMov,
    iniciarMov,
    alterarSessaoId,
    setTipoMovimentacao,
    setSalaoId,
    setarSeUsaIdentificador,
    carregando: carregandoProvider
  } = useMovAtual();
  const { getConsumidor, carregando: carregandoCadastros } =
    useCadastroPadrao();
  const { getInsercaoFavorita } = useMovInsercaoStorage();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [error, setError] = useState<Error>();
  const movStyles = useDefaultMovimentacaoStyles();
  const { showToast } = useToastSaurus();
  const {
    procurarMeuPDV,
    getPDV,
    getConfigByCod,
    carregando: carregandoPDV,
    selClienteDesativado,
  } = usePDV();
  const { setConfiguracaoMesaEComanda, getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { usuario, getEmpresaSelecionada, getPessoa } = useSessaoAtual();
  const { getConfigContratoByCod, carregando: carregandoContrato } = useGetConfigContratoByCod()
  const [pdvDiff, setPdvDiff] = useState<number>(0);

  const carregando = [
    carregandoProvider,
    carregandoPDV,
    carregandoCadastros,
    carregandoContrato
  ].includes(true);

  const { abrirMenu } = useMenuPrincipal();
  const { isMobile } = useThemeQueries();

  const verificarCadastros = useMemo(() => {
    const urlQuery = new URLSearchParams(location.search);
    return urlQuery.get('verificarCadastros')
  }, [location.search])

  const modeloTrabalho = useMemo(() => getConfigByCod(EnumPDVConfigCod.ModeloTrabalho), [getConfigByCod])
  const solicitacaoInfoPedidos = useMemo(() => getConfigByCod(EnumPDVConfigCod.MomentoSolicitacaoMesaComanda), [getConfigByCod])
  const isLancadorPedidos = useMemo(() => ([
    EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho)), [modeloTrabalho])

  const switchRedirecionamento = useCallback(async (rota: EnumInsercaoFavorita) => {
    if (isLancadorPedidos && (isEmpty(getMov()?.informacoesGeraisPedido?.comandaId) ||
      isEmpty(getMov()?.informacoesGeraisPedido?.mesaId))) {
      const mesas = getConfiguracoesMesaEComanda()?.qtdeMesa || 0
      const saloes = await TouchoneDBPrimary.saloes.toArray()
      const comandas = getConfiguracoesMesaEComanda()?.qtdeComandas || 0
      const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA && salao.status?.codigo === EnumStatusSalao.ATIVO)

      if (mesas > 0 || comandas > 0 || balcoes.length > 0) {
        await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
      }

      if (navigator.onLine) {
        if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA && !isEmpty(getMov()?.informacoesGeraisPedido.mesaId)) {
          await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
          history.push(rota)
          return
        }
        const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA && salao.status?.codigo === EnumStatusSalao.ATIVO)
        if (isEmpty(getMov()?.informacoesGeraisPedido.comandaId) && comandas > 0 && (solicitacaoInfoPedidos ?? '1') === '0') {
          await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
          return history.push('/venda-simples/leitor-comanda');
        }
        else if ((balcoes.length > 1 || mesas > 0) && isEmpty(getMov()?.informacoesGeraisPedido.codigoMesa) &&
          isEmpty(getMov()?.informacoesGeraisPedido.salaoId) && (solicitacaoInfoPedidos ?? '1') === '0') {
          await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
          return history.push('/venda-simples/vincular-mesa');
        } else if (balcoes.length === 1 &&
          !isEmpty(getMov()?.informacoesGeraisPedido.comandaId) &&
          isEmpty(getMov()?.informacoesGeraisPedido.salaoId) &&
          getMov()!.produtos.length > 0) {

          await setSalaoId(balcoes[0].id || '')
          history.push('/venda-simples/enviar-pedido')
          return
        }
      }
    }

    return history.push(rota);
  }, [getConfiguracoesMesaEComanda, getMov, history, isLancadorPedidos, setSalaoId, setTipoMovimentacao, solicitacaoInfoPedidos])

  const leftArea = React.useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
          tooltip="Menu"
          onClick={abrirMenu}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, abrirMenu]
  );

  const tentarAbrirMov = React.useCallback(async () => {
    try {
      let redirect = true;
      const rota = getInsercaoFavorita() as EnumInsercaoFavorita;
      const insercaoDelivery = rota === EnumInsercaoFavorita.ATENDIMENTO_DELIVERY
      let mov = getMov();
      if (!mov) {
        await restaurarMov();
        mov = getMov();

        try {
          if (!mov) {
            await iniciarMov();
          }
        } catch (e: any) {
          showToast('error', e.message);
          redirect = false;
        }
      } else {
        await alterarSessaoId();
      }

      const url = new URLSearchParams(history.location.search).get('url')

      if (url) {
        history.push(url)
        return
      }

      if (!insercaoDelivery && !getMov()?.clienteIdentificado && selClienteDesativado() === '1') {
        history.push('/venda-simples/identificar-cliente');
        return
      }

      if (redirect) {
        switchRedirecionamento(rota)
      }
    } catch (e: any) {
      showToast('error', e.message);
      setError(e);
    }
  }, [alterarSessaoId, getInsercaoFavorita, getMov, history, iniciarMov, restaurarMov, selClienteDesativado, showToast, switchRedirecionamento]);

  const [situacao, setSituacao] = useState<number>(0);

  const verificarSincronizacao = useCallback(() => {
    if (getUltimaSincronizacao()?.dataSucesso) {
      setSituacao(3);
      return;
    } else if (getUltimaSincronizacao()?.dataUltima) {
      return history.push('/sincronizar-resumo');
    }
    return history.push('/venda-simples/sincronizar-dados');
  }, [getUltimaSincronizacao, history]);

  const verificarAlteracoesCadastrais = useCallback(async () => {
    if (verificarCadastros) {
      let res = undefined;
      if (navigator.onLine) {
        res = await getConfigContratoByCod(getPessoa().pessoa?.contratoId || '', EnumContratoConfig.DataUltimoCadastro);

        if (res.erro) {
          verificarSincronizacao();
          return;
        }
      }
      const ultimaSincronizacao = getUltimaSincronizacao()?.dataSucesso;
      const dataAlteracao = res?.resultado?.data?.valor ?? undefined;

      if (!dataAlteracao || !ultimaSincronizacao) {
        verificarSincronizacao();
        return;
      }

      const dAlteracao = new Date(dataAlteracao).getTime();
      const uSincronizacao = new Date(ultimaSincronizacao).getTime();

      if (dAlteracao > uSincronizacao) {
        setAlteracaoCadastros(true);
        return;
      }
    }

    setSituacao(3);
  }, [getConfigContratoByCod, getPessoa, getUltimaSincronizacao, verificarCadastros, verificarSincronizacao]);

  const iniciarLoading = useCallback(async () => {
    switch (situacao) {
      case 0:
        try {
          const acheiPdv = await procurarMeuPDV(false);
          if (!acheiPdv) {
            history.push('/venda-simples/selecionar-pdv');
            return;
          }
          setSituacao(1);
        } catch (e: any) {
          const pdv = getPDV();
          if (!pdv && e.message !== undefined) {
            setError(e);
            return;
          }
          setSituacao(1);
        }
        break;
      case 1:
        const cli = await getConsumidor();
        if (!cli) {
          setError(new Error('Erro ao Identificar o Cliente Padrão.'));
          return;
        }
        setSituacao(2);
        break;
      case 2:
        await verificarAlteracoesCadastrais();
        break;
      case 3:

        setarSeUsaIdentificador(false);

        const configMesaEComanda = getConfiguracoesMesaEComanda();

        if (!configMesaEComanda) {
          const saloesCadastrados = await TouchoneDBPrimary.saloes
            .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
            .toArray();
          const comandas = await TouchoneDBPrimary.comandas
            .filter((comanda) => comanda.status?.codigo === EnumComandas.ATIVO)
            .toArray();

          const comandasCadastradas = comandas.length
          const rfidIguais = comandas.filter(comanda => comanda.rfId === comanda.codigoComanda).length === comandas.length
          const temRFID = comandas.filter(comanda => !isEmpty(comanda.rfId)).length > 0

          const tipoTrabalhoComanda = rfidIguais ? EnumTipoTrabalhoComanda.APENAS_RFID : temRFID ? EnumTipoTrabalhoComanda.HIBRIDO : EnumTipoTrabalhoComanda.APENAS_CODIGO

          const mesasCadastradas = await TouchoneDBPrimary.mesas
            .filter((mesa) => {
              if (
                saloesCadastrados.filter((x) => x.id === mesa.salaoId).length >
                0
              ) {
                if (mesa.status?.codigo === EnumMesas.ATIVO) {
                  return true;
                }
              }
              return false;
            })
            .count();

          const configuracaoMesaComanda = new ConfiguracaoMesaEComanda(
            undefined,
            saloesCadastrados.length,
            mesasCadastradas,
            comandasCadastradas,
            tipoTrabalhoComanda
          );
          if (comandasCadastradas > 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.COMANDA
            });
          } else if (mesasCadastradas > 0 && comandasCadastradas < 1) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.MESA
            });
          }
          else if (comandasCadastradas === 0 && mesasCadastradas === 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.BALCAO
            });
          }
        }

        setSituacao(4);
        break;
      case 4:
        const sessao = await getSessao();

        if (!sessao) {
          return history.push('/venda-simples/abrir-caixa');
        }

        setSituacao(5);
        break;
      case 5:
        await tentarAbrirMov();
        break;
    }
  }, [getConfiguracoesMesaEComanda, getConsumidor, getPDV, getSessao, history, procurarMeuPDV, setConfiguracaoMesaEComanda, setarSeUsaIdentificador, situacao, tentarAbrirMov, verificarAlteracoesCadastrais]);

  const pdvDiferente = useCallback(async () => {
    const empresas = usuario?.empresa;
    const pdv = getPDV();
    const hasPDV = empresas?.filter((item) => item.Id === pdv?.empresaId);
    const isDeviceMobile = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA
    if (!isEmpty(pdv) && (((hasPDV?.length || 0) < 1) || (!isDeviceMobile && pdv.chave !== getPessoa().pessoa?.id))) {
      return setPdvDiff(1);
    }
    if (
      !isEmpty(pdv) &&
      (hasPDV?.length || 0) > 0 &&
      pdv?.empresaId !== getEmpresaSelecionada()?.Id
    ) {
      return setPdvDiff(2);
    }
    await iniciarLoading();
  }, [getEmpresaSelecionada, getPDV, getPessoa, iniciarLoading, usuario?.empresa]);

  useEffect(() => {
    (async () => {
      await pdvDiferente();
    })();
  }, [pdvDiferente]);

  return (
    <>
      <Grid className={movStyles.root}>
        <Grid className={movStyles.header}>
          <PrivatePageHeader title="Nova Venda" leftArea={leftArea()} />
        </Grid>
        {!error && (
          <Grid
            className={classes.containerLoading}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {pdvDiff === 0 ? (
              <>
                <CircularLoading tipo="NORMAL" />
                <Typography variant="h5" color="primary">
                  {situacao === 0 && <>Procurando PDV</>}
                  {situacao === 1 && <>Procurando Consumidor Padrão</>}
                  {situacao === 2 && <>Procurando atualizações</>}
                  {situacao === 3 && <>Verificando Sincronização</>}
                  {situacao === 4 && <>Verificando Sessão</>}
                  {situacao === 5 && <>Iniciando Venda</>}
                </Typography>
              </>
            ) : pdvDiff === 1 ? (
              <>
                <PDVDiferenteConta
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              </>
            ) : (
              pdvDiff === 2 && (
                <PDVDiferenteEmpresa
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              )
            )}
          </Grid>
        )}
        {!carregando && error && (
          <Grid
            container
            className={classes.containerError}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={10} sm={8} md={4}>
              <Grid className={classes.imageError}>
                <CancelarIcon tipo="GERAL" fill="#D22" />
              </Grid>
              <Typography variant="h5" align="center">
                {error.message}
              </Typography>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={carregando}
                className={classNames(classes.btnTentarNovamente, 'round')}
                onClick={() => {
                  iniciarLoading();
                }}
              >
                <AtualizarIcon tipo="BUTTON_PRIMARY" />
                Tentar Novamente
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      {alteracaoCadastros && (
        <DialogAlteracaoCadastros
          openned={alteracaoCadastros}
          closeModal={() => {
            setAlteracaoCadastros(false);
            verificarSincronizacao();
          }}
          handleClick={() => {
            return history.push('/venda-simples/sincronizar-dados');
          }}
        />
      )}
    </>
  );
};

export default MovSimplesLandingPage;
