import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { CardNaoEncontrado } from 'views/components/cards';
import CardProduto from './../card-produto/card-produto';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading } from 'views';

export interface ProdutoGridProps {
  list: Array<ProdutoResumidoModel>;
  carregando: boolean;
  onCardClicked: (produto: ProdutoResumidoModel) => void;
  onCardHolded: (produto: ProdutoResumidoModel) => void;
}

export const PesquisarProdutoListData = (props: ProdutoGridProps) => {
  const onCardChecked = (produto: ProdutoResumidoModel) => {
    props.onCardHolded(produto);
  };

  const onCardHolded = (produto: ProdutoResumidoModel) => {
    props.onCardHolded(produto);
  };

  return (
    <>
      {props.list.length > 0 && props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem={props.carregando ? `Procurando produtos` : "Nenhum Produto encontrado."}
          icon={props.carregando
            ? <div
              style={{
                marginRight: 30,
                marginTop: 0,
                position: 'relative',
              }}
            > <CircularLoading tipo="ONAUTOCOMPLETE" />
            </div>
            : <InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((produto) => {
          return (
            <CardProduto onClick={onCardChecked} qtdeCarrinho={undefined} onHold={onCardHolded} model={produto} key={produto.id} />
          );
        })}
    </>
  );
};
