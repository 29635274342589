
import { makeStyles } from "@material-ui/core";


export const useStyles = makeStyles((theme) => ({
  
    formularioUnico:{
        marginTop: theme.spacing(2)
        
    },
    descricaoForm: {
        marginBottom: theme.spacing(2)
    },
    card:{
        margin: theme.spacing(2)
    },
    inputContainer:{
        display:"flex",   
    },
    inputLeft:{
        marginRight:'4px'
    },
    inputRight:{
        marginLeft:'4px'
    }

}))