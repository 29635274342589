import * as Yup from "yup";
import { useMemo } from "react";
import { useValidationYupNcmCodigo } from "views/components/form-validations/yup/ncm-codigo";
import { useValidationYupCest } from "../../../form-validations/yup/cest";
export const useFormNcmCadastroValidation = () => {
  const { ncmCodigoYup } = useValidationYupNcmCodigo();
  const { cestYup } = useValidationYupCest();
  const FormNcmCadastroYupValidation = useMemo(() => {
    return Yup.object().shape({
      codigo: ncmCodigoYup(),
      cest: cestYup(),
    });
  }, [cestYup, ncmCodigoYup]);

  return {
    FormNcmCadastroYupValidation,
  };
};
