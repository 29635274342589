import { KeyValueModel } from "model/api";

export const MotDesIcmsMock: KeyValueModel[] = [
    new KeyValueModel(0, "00 - Nulo"),
    new KeyValueModel(1, "01 – Táxi"),
    new KeyValueModel(2, "02 - Deficiente físico"),
    new KeyValueModel(3, "03 – Produto agropecuário/Uso na agropecuária"),
    new KeyValueModel(4, "04 – Frotista/Locadora"),
    new KeyValueModel(5, "05 – Diplomático/Consular"),
    new KeyValueModel(6, "06 – Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio"),
    new KeyValueModel(7, "07 – SUFRAMA"),
    new KeyValueModel(8, "08 – Venda a Órgão Público"),
    new KeyValueModel(9, "09 – Outros"),
    // new KeyValueModel(10, "10 – Deficiente Condutor"),
    // new KeyValueModel(11, "11 – Deficiente Não Condutor"),
    // new KeyValueModel(12, "12 – Órgão de fomento e desenvolvimento agropecuário"),

];
