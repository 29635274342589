import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    celulaGrid: {
        marginLeft: theme.spacing(2),
        minWidth: 0,
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    cardContent: {
        "& span": {
            fontSize: "0.7em",
        },
        "& p": {
            fontSize: "1.0em",
        },
        alignItems: 'center',
        paddingRight: theme.spacing(1)
    },
    contentFinal: {
        justifyContent: 'flex-end', display: 'flex'
    },
    codigo: {
        minWidth: 120,
        maxWidth: 120
    },
    removeIcon: {
        paddingRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& svg": {
            margin: 0,
            width: "22px",
            height: "22px",
            fill: theme.palette.error.main,
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(0, 1),
            borderLeft: 'solid 1px ' + theme.palette.grey[500]
        }
    }
}))