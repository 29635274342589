import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPlanoLicenca() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPlanoLicenca = useCallback(
        () =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/licenca`,
                method: 'GET',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPlanoLicenca,
    };
}