import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';

export const ErroFinalizaHeader = () => {
  const { goToAdministrativo } = useOpenMovRota();

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={goToAdministrativo}
      />
    ), [goToAdministrativo]);

  return (
    <>
      <PrivatePageHeader title={'Erro de Finalização'} leftArea={leftArea()} />
    </>
  );
};
