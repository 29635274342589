import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostDeposito() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postDeposito = useCallback(
    (data: DepositoModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Deposito`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(data),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postDeposito
  };
}
