import { useTheme } from "@material-ui/core"

export const BackgroundFooterRight = () => {

    const theme = useTheme()

    return (
        <>
            <svg width="246" height="234" viewBox="0 0 146 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.9474 128.088C25.8577 103.579 32.6805 80.2749 42.4158 58.1743C52.1511 36.0737 63.2146 18.6202 75.6065 5.81362L134.266 44.0009C123.166 56.1264 113.45 70.6905 105.12 87.6931C96.789 104.696 91.2436 121.847 88.4834 139.147C83.239 172.017 88.8343 189.763 105.269 192.385C113.919 193.765 121.022 190.02 126.576 181.149C132.374 172.612 139.235 158.332 147.161 138.308C155.696 116.312 163.923 98.11 171.839 83.7024C180.044 69.3408 191.22 57.6708 205.366 48.6922C219.513 39.7137 237.11 36.9036 258.158 40.2619C276.612 43.2061 292.386 50.8971 305.481 63.335C318.864 75.8188 328.293 92.6983 333.769 113.973C339.199 135.537 339.591 160.879 334.944 190.001C331.77 209.896 326.335 229.135 318.64 247.717C311.232 266.345 302.031 282.322 291.034 295.647L231.666 259.12C251.837 232.771 264.084 206.045 268.408 178.942C271.122 161.93 270.506 149.118 266.558 140.505C262.898 131.938 257.032 127.01 248.958 125.722C240.885 124.434 234.094 128.081 228.585 136.664C223.076 145.246 216.526 159.428 208.935 179.21C200.353 201.495 191.983 219.674 183.824 233.747C175.907 248.154 164.876 259.847 150.729 268.826C136.825 278.139 119.349 281.116 98.3008 277.758C80.1358 274.86 64.5058 267.192 51.411 254.754C38.3161 242.316 28.9096 225.292 23.1915 203.683C17.7618 182.119 17.3471 156.921 21.9474 128.088Z" fill="url(#paint0_radial_2850_8081)" fillOpacity="0.6" />
                <defs>
                    <radialGradient id="paint0_radial_2850_8081" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.7311 60.5822) rotate(89.2272) scale(265.346 192.249)">
                        <stop offset="0.001777" stopColor={theme.palette.primary.main} stopOpacity="0.14" />
                        <stop offset="0.901579" stopColor={theme.palette.primary.main} stopOpacity="0" />
                    </radialGradient>
                </defs>
            </svg>


        </>
    )
}
