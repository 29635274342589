import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ImpressorEtiquetaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        onClick={props.onClick}
        style={{ ...props.style }}
        fill={props.fill}
      >
        <path d="M12 3C11.7348 3.00003 11.4805 3.10539 11.2929 3.29292C11.1054 3.48045 11 3.73479 11 4V30H10C8.895 30 8 30.895 8 32C8 33.105 8.895 34 10 34H12H38H40C41.105 34 42 33.105 42 32C42 30.895 41.105 30 40 30H39V4C39 3.73479 38.8946 3.48045 38.7071 3.29292C38.5195 3.10539 38.2652 3.00003 38 3H12ZM13 5H37V15H13V5ZM17 7C16.7348 7.00003 16.4805 7.10539 16.2929 7.29292C16.1054 7.48045 16 7.73479 16 8V12C16 12.2652 16.1054 12.5195 16.2929 12.7071C16.4805 12.8946 16.7348 13 17 13H21C21.2652 13 21.5195 12.8946 21.7071 12.7071C21.8946 12.5195 22 12.2652 22 12V8C22 7.73479 21.8946 7.48045 21.7071 7.29292C21.5195 7.10539 21.2652 7.00003 21 7H17ZM25 7V9H31V7H25ZM18 9H20V11H18V9ZM25 11V13H34V11H25ZM13 17H37V27H13V17ZM16 19V25H19V19H16ZM20 19V25H22V19H20ZM23 19V25H27V19H23ZM29 19V25H31V19H29ZM32 19V25H34V19H32ZM7 21C4.80267 21 3 22.8027 3 25V45C3 46.0931 3.90694 47 5 47H45C46.0931 47 47 46.0931 47 45V25C47 22.8027 45.1973 21 43 21H41V23H43C44.1167 23 45 23.8833 45 25V45H5V25C5 23.8833 5.88333 23 7 23H9V21H7ZM13 29H37V32H13V29ZM40 37C38.355 37 37 38.355 37 40C37 41.645 38.355 43 40 43C41.645 43 43 41.645 43 40C43 38.355 41.645 37 40 37ZM40 39C40.5641 39 41 39.4359 41 40C41 40.5641 40.5641 41 40 41C39.4359 41 39 40.5641 39 40C39 39.4359 39.4359 39 40 39Z" />
      </DefaultIcon>
    </>
  );
};
