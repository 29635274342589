import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetSessaoFechamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getSessaoFechamento = useCallback(
    (empresaId: string, caixaId: string, sessaoId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${caixaId}/Sessao/${sessaoId}/pagamentos`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getSessaoFechamento
  };
}
