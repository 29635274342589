import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const ValeCombustivelIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <g id="surface226922803">
          <path d="M 29.6875 0 C 32.617188 0 35 2.382812 35 5.3125 L 35 25.09375 C 36.8125 25.394531 38.34375 26.496094 39.214844 28.027344 C 38.46875 28.074219 37.738281 28.191406 37.035156 28.371094 C 36.515625 27.78125 35.8125 27.355469 35 27.15625 L 35 29.097656 C 34.292969 29.425781 33.625 29.816406 33 30.269531 L 33 5.3125 C 33 3.441406 31.558594 2 29.6875 2 L 9.3125 2 C 7.441406 2 6 3.441406 6 5.3125 L 6 44 L 28.691406 44 C 28.9375 44.699219 29.25 45.367188 29.617188 46 L 4 46 L 4 48 L 31.074219 48 C 31.757812 48.761719 32.535156 49.433594 33.386719 50 L 3 50 C 2.449219 50 2 49.550781 2 49 L 2 45 C 1.996094 44.480469 2.390625 44.046875 2.90625 44 C 2.9375 44 3.96875 44 4 44 L 4 5.3125 C 4 2.382812 6.382812 0 9.3125 0 Z M 48 28.4375 L 43.28125 23.71875 C 42.484375 22.921875 42 21.789062 42 20.59375 L 42 12.4375 L 36.28125 6.71875 L 37.71875 5.28125 L 48.71875 16.28125 C 49.515625 17.078125 50 18.210938 50 19.40625 L 50 33.386719 C 49.433594 32.535156 48.761719 31.757812 48 31.074219 Z M 28.09375 4 C 29.695312 4 31 5.304688 31 6.90625 L 31 17.09375 C 31 18.695312 29.695312 20 28.09375 20 L 10.90625 20 C 9.304688 20 8 18.695312 8 17.09375 L 8 6.90625 C 8 5.304688 9.304688 4 10.90625 4 Z M 10 6.90625 L 10 17.09375 C 10 17.492188 10.507812 18 10.90625 18 L 28.09375 18 C 28.492188 18 29 17.492188 29 17.09375 L 29 6.90625 C 29 6.507812 28.492188 6 28.09375 6 L 10.90625 6 C 10.507812 6 10 6.507812 10 6.90625 Z M 44 20.59375 C 44 21.199219 44.316406 21.878906 44.71875 22.28125 L 48 25.5625 L 48 19.40625 C 48 18.800781 47.683594 18.121094 47.28125 17.71875 L 44 14.4375 Z M 44 20.59375 " />
          <path d="M 40 30 C 34.5 30 30 34.5 30 40 C 30 45.5 34.5 50 40 50 C 45.5 50 50 45.5 50 40 C 50 34.5 45.5 30 40 30 Z M 40 48 C 35.601562 48 32 44.398438 32 40 C 32 35.601562 35.601562 32 40 32 C 44.398438 32 48 35.601562 48 40 C 48 44.398438 44.398438 48 40 48 Z M 40 48 " />
          <path d="M 43.449219 41.753906 C 43.449219 40.3125 42.695312 39.507812 40.972656 39.140625 L 39.902344 38.910156 C 38.941406 38.699219 38.539062 38.390625 38.539062 37.855469 C 38.539062 37.210938 39.097656 36.789062 39.9375 36.789062 C 40.765625 36.789062 41.375 37.261719 41.441406 37.945312 L 43.292969 37.945312 C 43.253906 36.5625 42.289062 35.546875 40.851562 35.238281 L 40.851562 34.027344 L 39.148438 34.027344 L 39.148438 35.195312 C 37.558594 35.453125 36.558594 36.523438 36.558594 38.046875 C 36.558594 39.46875 37.351562 40.359375 38.921875 40.695312 L 40.054688 40.941406 C 41.082031 41.164062 41.480469 41.46875 41.480469 42.015625 C 41.480469 42.660156 40.867188 43.113281 39.988281 43.113281 C 39.011719 43.113281 38.347656 42.660156 38.277344 41.957031 L 36.375 41.957031 C 36.414062 43.480469 37.464844 44.5 39.148438 44.726562 L 39.148438 45.980469 L 40.851562 45.980469 L 40.851562 44.6875 C 42.480469 44.394531 43.449219 43.339844 43.449219 41.753906 Z M 43.449219 41.753906 " />
        </g>
      </DefaultIcon>
    </>
  );
};
