import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CircularLoading, useThemeQueries } from 'views';
import { Grid } from '@material-ui/core';
import { UploadCargaPessoa, UploadCargaProduto } from 'model/api/gestao/upload-carga/upload-carga-model';
import { CardCargaParceiros } from 'views/components/cards/card-carga-parceiro/card-carga-parceiros';
import { CardCargaParceirosMobile } from 'views/components/cards/card-carga-parceiro/card-carga-parceiros-mobile';

export interface UploadCargasProdutosListDataProps {
  list: Array<UploadCargaPessoa>;
  carregando: boolean;
  onCardChecked: (model: UploadCargaPessoa | UploadCargaProduto) => any;
}

export const ParceirosListData = (props: UploadCargasProdutosListDataProps) => {
  const { isMobile } = useThemeQueries();
  const onCardChecked = (model: UploadCargaPessoa) => {
    props.onCardChecked(model);
  };

  return (
    <>
      {props.carregando && <CircularLoading tipo="FULLSIZED" />}
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma Carga encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      <Grid item xs={12} spacing={2}>
        {props.list.length > 0 &&
          props.list.map((parceiro) => {
            return (
              <>
                {isMobile ? (
                  <CardCargaParceirosMobile
                    key={parceiro.id}
                    model={parceiro}
                    onClick={onCardChecked}
                  />
                ) : (
                  <CardCargaParceiros
                    key={parceiro.id}
                    model={parceiro}
                    onClick={onCardChecked}
                  />
                )}
              </>
            );
          })}
      </Grid>
    </>
  );
};
