import { memo, useCallback, useEffect, useState } from 'react';
import { useStyles } from './fast-payment-styles';
import { Typography, useTheme } from '@material-ui/core';
import { ArrowTopIcon } from 'views/components/icons/arrow-top-icon';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { CardFastPayment } from 'views/components/cards/card-payment/card-payment';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCadastros, useToastSaurus } from 'services/app';
import { HeaderPayment } from './components/HeaderPayment/header-payment';
import { usePayment } from 'services/app/hooks/payment';
import { CircularLoading } from 'views/components';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useHistory } from 'react-router-dom';
import { useDevice } from 'services/app/hooks/device';
import { isEmpty } from 'lodash';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

const FastPayment = () => {
  const [active, setActive] = useState(false);
  const [payments, setPayments] = useState<
    Array<{
      name: string;
      type: EnumPagTpMod;
      methods: FinalizadoraModel[];
    }>
  >([]);

  // HOOKS
  const { startPayment, loading } = usePayment();
  const { getMov, verificaProdutosControlados, naoInformarDadosDaReceita, carregando } = useMovAtual();
  const { showToast } = useToastSaurus();
  const { abrirPagamentosDialog, abrirDialogControlado } = useCadastros();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = useContratoAtual()
  const { push } = useHistory();
  const { tpPayments, getPayments } = useDevice();

  useEffect(() => {
    if (isEmpty(tpPayments)) {
      getPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayments, tpPayments]);

  // AUX
  const classes = useStyles({ removerMargem: false, fontSize: 10 });
  const { palette } = useTheme();

  const groupByTpMod = useCallback((methods: FinalizadoraModel[]) => {
    const tpModArray = [
      EnumPagTpMod.DINHEIRO,
      EnumPagTpMod.CARTAO_CREDITO,
      EnumPagTpMod.CARTAO_DEBITO,
      EnumPagTpMod.PAGAMENTO_INSTANTANEO
    ];

    const groupPayments = [
      {
        name: 'Dinheiro',
        type: EnumPagTpMod.DINHEIRO,
        methods: [] as FinalizadoraModel[],
        ordem: 1
      },
      {
        name: 'Crédito',
        type: EnumPagTpMod.CARTAO_CREDITO,
        methods: [] as FinalizadoraModel[],
        ordem: 2
      },
      {
        name: 'Débito',
        type: EnumPagTpMod.CARTAO_DEBITO,
        methods: [] as FinalizadoraModel[],
        ordem: 3
      },
      {
        name: 'PIX',
        type: EnumPagTpMod.PAGAMENTO_INSTANTANEO,
        methods: [] as FinalizadoraModel[],
        ordem: 4
      }
    ];

    return methods
      .filter((p) => tpModArray.includes(p.tpMod)).filter((item) => item.ativo === true)
      .filter((item) => {
        if (
          item.tpTransacao === EnumPagTpTransacao.S2_PAY &&
          (tpPayments ?? []).find((x) => x === item.tpMod)
        ) {
          return item;
        } else if (item.tpTransacao !== EnumPagTpTransacao.S2_PAY) {
          return item;
        }

        return null;
      })
      .reduce((group, payment) => {
        const groupIndex = group.find((g) => g.type === payment.tpMod);

        if (payment.tpMod === groupIndex?.type) {
          return group.map((x) => {
            if (x.type === groupIndex.type) {
              return {
                ...x,
                methods: [...x.methods, payment]
              };
            }

            return x;
          });
        }
        return group;
      }, groupPayments);
  }, [tpPayments]);

  const getPaymentMethods = useCallback(async () => {
    const result =
      (await TouchoneDBPrimary.finalizadoras.toArray()) as FinalizadoraModel[];
    const group = groupByTpMod(result);
    setPayments(group.map(g => ({
      ...g,
      methods: g.methods.sort()
    })).filter(groupPayment => groupPayment.methods.length > 0).map(g => ({
      ...g,
      ordem: g.methods[0]?.ordem ?? 999
    })).sort((a, b) => a.ordem - b.ordem));
  }, [groupByTpMod]);

  const onClick = useCallback(
    async (model: {
      type: EnumPagTpMod;
      name: string;
      methods: FinalizadoraModel[];
    }) => {
      if (model.methods.length === 0) {
        showToast(
          'info',
          'Você não possui nenhuma forma de pagamento com o tipo selecionado ou a forma não é permitida no ambiente atual, por favor verifique seus cadastros.'
        );
        return;
      }

      const medicamentos = await verificaProdutosControlados();
      if (medicamentos && medicamentos.length > 0) {
        const qtd = medicamentos.reduce((acc, current) => acc + current.qCom, 0);
        abrirDialogControlado(qtd, naoInformarDadosDaReceita, async () => await onClick(model))
        return
      }

      if (model.methods.length > 1) {
        abrirPagamentosDialog(model.methods);
        return;
      }

      await startPayment(model.methods[0]);
    },
    [abrirDialogControlado, abrirPagamentosDialog, naoInformarDadosDaReceita, showToast, startPayment, verificaProdutosControlados]
  );

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const movProdAlterado = useCallback(
    (any: any) => {
      const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
      const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))

      if ((produtos?.length ?? 0) > 0) {
        setActive(true);
      } else {
        setActive(false);
      }
    },
    [getConfigByCod, getMov]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);

  useEffect(() => {
    const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico)
    const produtos = getMov()?.produtos.filter((p) => p.ativo && !(p.produtoId === prodTaxaServicoId))
    if ((produtos?.length ?? 0) > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [getConfigByCod, getMov]);

  return (
    <>
      {active && (
        <div className={classes.container}>
          {(loading || carregando) && <CircularLoading tipo="FULLSIZED" />}
          <HeaderPayment />

          <div className={classes.paymentArea}>
            {payments.map((pay) => (
              <CardFastPayment
                model={pay}
                key={pay.type}
                onClick={onClick}
                selected={false}
              />
            ))}
          </div>

          <div className={classes.moreArea} onClick={() => push('/venda-simples/novo-pagamento')}>
            <ArrowTopIcon
              tipo="BUTTON"
              style={{ width: 14, height: 14, margin: 0 }}
              fill={palette.secondary.main}
            />
            <Typography className={classes.labelMoreActions}>
              Mais Opções
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(FastPayment);
