import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormProdutoFiscalEditValidation = () => {

  const FormProdutoFiscalEditValidationYup = useMemo(() => {
    return (

      Yup.object().shape({
        descricao: Yup.string().required('Descrição do Grupo Necessária.'),
        cfop: Yup.string().required('CFOP é obrigatório.').min(4, 'CFOP informado está inválido').max(4, 'CFOP informado está inválido'),
        icmsCst: Yup.number(),
        pisCst: Yup.number(),
        cofinsCst: Yup.number(),
        icmsPIcms: Yup.string()
          .test("icmsPIcms", "O ICMS não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPredBcSt: Yup.string()
          .test("icmsPredBcSt", "A Redução ST não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPMvaVUnidSt: Yup.string()
          .test("icmsPMvaVUnidSt", "O MVA/IVA não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPRedBc: Yup.string()
          .test("icmsPRedBc", "A Redução BC não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPIcmsSt: Yup.string()
          .test("icmsPIcmsSt", "O ICMS ST não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPCredSn: Yup.string()
          .test("icmsPCredSn", "O Crédito SN não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPredSn: Yup.string()
          .test("icmsPredSn", "O ICMS Estadual não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPFcp: Yup.string()
          .test("icmsPFcp", "O F.C.P não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          }),
        icmsPFcpSt: Yup.string()
          .test("icmsPFcpSt", "O F.C.P S.T não pode ser negativo.", val => {
            if (val && val.includes('-')) {
              return false
            }
            return true
          })
      })
    )
  }, [])
  return {
    FormProdutoFiscalEditValidationYup,
  }
}

