import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { PerfilModel } from "../../../../../../../../model/api/gestao/perfil/perfil-model";
import { CircularLoading } from "views";
import { CardPerfil } from "views/components/cards/card-perfil/card-perfil";

export interface PerfilListDataProps {
    list: Array<PerfilModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const PerfilListData = ((props: PerfilListDataProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.carregando && <CircularLoading tipo="FULLSIZED" />}
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhum Perfil encontrado." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((perfil, index) => {
                return (
                    <CardPerfil model={perfil}
                        key={index} 
                        onClick={onCardSelected}
                        onCheck={onCardChecked}
                        selected={false}
                    />
                );
            })}

        </>
    );
});
