import { DialogSaurus } from '../dialog-saurus/dialog-saurus'
import { useCadastros, useToastSaurus } from 'services/app';
import { Box, Button, Grid } from '@material-ui/core';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useGetObterCupomVenda } from 'data/api/gestao/venda/get-obter-cupom-venda';
import { CircularLoading } from 'views/components/utils';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { useGetVendaCompleta } from 'data/api/gestao/venda/get-venda-completa';
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { EmailIcon } from 'views/components/icons';
import { useRef, useState } from 'react';
import { usePostEnviarCupomVenda } from 'data/api/gestao/venda/post-enviar-cupom-venda';

interface DialogProps {
    openned: boolean;
    movId: string;
}

enum EnumTpDanfe {
    Simples,
    Completo
}

export const DialogImpressaoNfe = ({
    openned,
    movId
}: DialogProps) => {
    const [showForm, setShowForm] = useState(false);
    const [email, setEmail] = useState('');
    const { fecharDialogImpressaoNfe } = useCadastros();
    const { showToast } = useToastSaurus();
    const { getObterCupomVenda, carregando: carregandoCupom } = useGetObterCupomVenda()
    const { imprimirCupom, carregando: carregandoMovSimples } = useMovSimples()
    const { getVendaCompleta, carregando: carregandoVenda } = useGetVendaCompleta()
    const { postEnviarCupomVenda, carregando: postEnviarVendaCarregando } =
    usePostEnviarCupomVenda();

    const linkPdf = useRef<string>('')

    const carregando = [carregandoCupom, carregandoMovSimples, carregandoVenda].includes(true)

    const handleImpressao = async (tipo: EnumTpDanfe) => {
        try {
            if (tipo === EnumTpDanfe.Completo) {
                const res = await getObterCupomVenda(movId)
                if (res.erro) throw res.erro

                const link = res.resultado?.data.url as string
                if (!(link.length > 0)) {
                    throw new Error('Não conseguimos identificar a url do PDF da NFE');
                }
                linkPdf.current = link

                if (VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR) {
                    window.open(link, '_blank', `title=${"Nota NFE"}`);
                    fecharDialogImpressaoNfe()
                } else {
                    setShowForm(true)
                }
                return
            }

            const resVenda = await getVendaCompleta(movId)
            if (resVenda.erro) throw resVenda.erro
            const vendaCompleta = resVenda.resultado?.data as VendaCompletaModel
            if (!vendaCompleta) {
                return
            }
            fecharDialogImpressaoNfe()

            setTimeout(() => {
                try {
                    imprimirCupom(vendaCompleta.id ?? '', vendaCompleta.infMov.tpEmis, vendaCompleta.infMov.mod)
                } catch (err: any) {
                    showToast('error', err.message)
                }
            }, 500)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }

    const handleEnviarEmail = async () => {
        try {
            const response = await postEnviarCupomVenda(
                movId,
                email
            );
            
            if (response.erro) {
                throw new Error(response.erro)
            }
            
        } catch (e: any) {
            showToast('error', e.message)
        }
        finally {
            fecharDialogImpressaoNfe();
        }
    }

    return (
        <DialogSaurus
            aberto={openned}
            titulo={'Impressão NF-e'}
            isButtonTitleClose
            tamanho='sm'
            handleClickClose={() => fecharDialogImpressaoNfe()}
        >
            {(carregando || postEnviarVendaCarregando) && <CircularLoading tipo="FULLSIZED" />}
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
                <Grid container >
                    {showForm ? (
                        <Grid container>
                            <TextFieldSaurus disabled={postEnviarVendaCarregando} fullWidth tipo="TEXTO" label="E-mail" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Button disabled={postEnviarVendaCarregando} variant='contained' color='primary' fullWidth onClick={handleEnviarEmail} style={{ marginTop: '24px' }}>
                                <EmailIcon tipo='BUTTON_PRIMARY' />
                                Enviar por E-mail
                            </Button>
                        </Grid>) : (
                        <Grid container spacing={2} direction='row'>
                            <Grid item xs={6}>
                                <Button fullWidth variant='contained' color='primary' onClick={() => handleImpressao(EnumTpDanfe.Simples)}>
                                    <ImpressoraIcon tipo='BUTTON_PRIMARY' />
                                    DANFE Simples
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant='contained' color='primary' onClick={() => handleImpressao(EnumTpDanfe.Completo)}>
                                    <ImpressoraIcon tipo='BUTTON_PRIMARY' />
                                    DANFE Completo
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </DialogSaurus>
    )
}