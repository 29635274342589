import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { CardEmpresa } from "views/components/cards/card-empresa";
import { EmpresaModel } from "model";

export interface ListaEmpresasGridProps {
    list: Array<EmpresaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const ListaEmpresasListData = ((props: ListaEmpresasGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma Empresa encontrada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((empresa, index) => {
                return (
                    <CardEmpresa
                        selected={
                            props.selectedList.filter((item) => item === empresa.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={empresa}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
