import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const MaisIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={{ ...props.style }} fill={props.fill}>
                <svg  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.375 0V9.375H0V10.625H9.375V20H10.625V10.625H20V9.375H10.625V0H9.375Z" fill={props.fill} />
                </svg>
            </DefaultIcon>
        </>
    );
};