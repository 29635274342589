import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { HorarioModel } from "model/api/gestao/horarios-funcionamento/horario-model";
import { VariaveisAmbiente } from "config";

export function usePostHorario() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postHorario = useCallback(
    (empresaId: string, horario: HorarioModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/horario`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(horario),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postHorario,
  };
}
