import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { DepositoModel } from "model/api/gestao/deposito/deposito-model";

export function usePutDeposito() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putDeposito = useCallback(
        (data: DepositoModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Deposito/${data.id}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(data),
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putDeposito,
    };
}
