import { KeyValueModel } from "model";
import { EnumTpEmis } from "model/enums/enum-tp-emis";

export const TpEmisMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpEmis.NORMAL, 'Emissão normal (não em contingência)'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_EPEC, 'Contingência EPEC (Evento Prévio da Emissão em Contingência)'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_FS_DA, 'Contingência FS-DA, com impressão do DANFE em Formulário de Segurança - Documento Auxiliar'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_FS_IA, 'Contingência FS-IA, com impressão do DANFE em Formulário de Segurança - Impressor Autônomo'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_SCAN, 'Contingência SCAN (Sistema de Contingência do Ambiente Nacional)'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_SVC_AN, 'Contingência SVC-AN (SEFAZ Virtual de Contingência do AN)'),
    new KeyValueModel(EnumTpEmis.CONTINGENCIA_SVC_RS, 'Contingência SVC-RS (SEFAZ Virtual de Contingência do RS)'),
    new KeyValueModel(EnumTpEmis.OFFLINE, 'Contingência off-line da NFC-e'),
]