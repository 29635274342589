import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';

export const CentralHeader = () => {
  // AUX
  const history = useHistory()

  const handleClickGoBack = useCallback(() => history.goBack(), [history])

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={handleClickGoBack}
      ></ButtonPrivateHeader>
    );
  }, [handleClickGoBack]);

  return (
    <>
      <PrivatePageHeader title={'Funções PDV'} leftArea={leftArea()} />
    </>
  );
};
