import { Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../card-performance-relatorio/card-performance-relatorio-styles'
import { DefaultCard, useDefaultCardStyles } from 'views/components/cards/components';
import { RelatorioComponents } from 'services/app/providers/relatorio-performance-provider/relatorio-performance-provider';
import { useMemo } from 'react';

interface Props {
    model: RelatorioComponents;
}

export const CardComponentRelatorio = ({
    model,
}: Props) => {
    const classes = useDefaultCardStyles();
    const pessoaClasses = useStyles();
    const { md } = useThemeQueries()

    const fase = useMemo(() => {
        switch (model.phase) {
            case 'mount':
                return 'Criação'
            case 'update':
                return 'Atualização'
            case 'nested-update':
                return 'Atualização Encadeada'
        }
    }, [model.phase])

    return (
        <>
            <DefaultCard
                className={pessoaClasses.card}
            >
                <Grid container spacing={md ? 1 : 2} className={`${classes.cardContent} ${pessoaClasses.gridContainer}`}>
                    <Grid item xs={12} md={2} className={pessoaClasses.nome}>
                        <Typography color="textPrimary" variant="caption">
                            Fase
                        </Typography>
                        <Typography
                            color='textPrimary'
                            variant="body1"
                        >
                            <b>{fase}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Tempo de Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.actualDuration} ms
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Tempo de Renderização Sem Optimização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.baseDuration} ms
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Início da Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.startTime}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography color="textPrimary" variant="caption">
                            Fim da Renderização
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {model.commitTime}
                        </Typography>
                    </Grid>
                </Grid>
            </DefaultCard>
        </>
    );
};