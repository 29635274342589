import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetHorario() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getHorario = useCallback(
    (empresaId: string, tipo: number) =>

      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/horario?tipo=${tipo}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getHorario,
  }

}
