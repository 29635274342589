import * as Yup from 'yup';
import { useValidationYupSenha, useValidationYupSenhaConfirmacao } from '../../../form-validations'

export const useFormConviteValidation = () => {
    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

    const FormConviteYupValidation = Yup.object().shape({
        senha: senhaYup(),
        confirmarSenha: senhaConfirmacaoYup('senha'),
    });

    return {
        FormConviteYupValidation
    }
}

