import { Box, Grid } from '@material-ui/core';
import { makeUtilClasses } from '../../../../theme';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { guidEmpty } from 'utils/guid-empty';

export interface DefaultFormProps {
  model?: SaloesModel;
  saloes: SaloesModel[];
  setSalaoAtual: (values: string) => void;
  setSalaoAtualId: (values: string) => void;
  isTodosSaloes?: boolean;
}

export const FormSelecionarSalao = (props: DefaultFormProps) => {
  const utilClasses = makeUtilClasses();

  const saloesOptions: SaloesModel[] = [...props.saloes];

  if (props.isTodosSaloes) {
    saloesOptions.push(new SaloesModel(guidEmpty(), 'Todos os Salões'));
  }

  return (
    <>
      <div className={utilClasses.formContainer}>
        <Box my={2}>
          <Grid item xs={12}>
            <SelectSaurus
              label="Selecione o Salão"
              conteudo={saloesOptions.map((valor: any) => {
                return new KeyValueModel(valor.id.toString(), valor.descricao);
              })}
              style={{ height: '40px' }}
              fullWidth={true}
              variant="outlined"
              onChange={(event) => {
                if (event) {
                  if (event.target.value !== guidEmpty()) {
                    props.setSalaoAtual(event.target.value);
                  }
                  props.setSalaoAtualId(event.target.value);
                }
              }}
              defaultValue={() =>
                props.saloes.find((salao) => salao.id === props.model?.id)
                  ?.id ?? guidEmpty()
              }
            />
          </Grid>
        </Box>
      </div>
    </>
  );
};
