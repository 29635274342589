import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    root: {
        cursor: 'default',
    },
    containerRoot: {
        padding: '8px',
    },
    containerData: {
        display: 'flex',
        alignItems: 'center'
    },
    containerButtonDeletar: {
        display: 'flex',
        alignItems: 'center'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        height: '30px',
        width: '30px',
        '& svg': {
            marginRight: '0px',
        }
    }
}))