import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(() => ({
    formContainer: {
        marginTop: '60px',
    },
    txtCodigo: {
        letterSpacing: '20%'
    }
}));
