import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogAcresDesc } from 'views/components/modals/acrescimo-desconto/dialog-acres-desc';

export const AcrescimoDescontoModal = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<{aberto: boolean, tipo: 'acresc' | 'desc'}>({
        aberto: false,
        tipo: 'acresc'
    });

    const modalAlterado = useCallback(({ openned, tipo }: any) => {
        setModalState({
            aberto: openned,
            tipo
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogAcrescimoDesconto, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogAcrescimoDesconto, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DialogAcresDesc openned={modalState.aberto} id='acrescDesc' tipo={modalState.tipo}/>
    )
}