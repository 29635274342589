import { useCallback } from "react";

import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";

import { ModificadoresList } from "./components/modificadores-list/modificadores-list";
import { ButtonFab } from "views/components/controles";
import { Grid } from "@material-ui/core";
import { ModificadoresHeader } from "./components/modificadores-header/modificadores-header";
import { NovoIcon } from "views/components/icons";
import { useCadastros } from "services/app";

export const ModificadoresPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirCadastroModificador } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroModificador("", "", true);
  }, [abrirCadastroModificador]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ModificadoresHeader/>
      </Grid>
      <Grid className={classes.list}>
        <ModificadoresList />
      </Grid>
      <ButtonFab
        tooltip="Adicionar Modificador"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default ModificadoresPage;
