import { ManifestoModel } from "model/api/gestao/manifesto/manifesto-model";
import { DefaultModal } from "../components";
import { SituacaoManifesto } from "./components/situacao-manifesto";

interface SituacaoManifestoModalProps{
    model: ManifestoModel;
    openned: boolean;
}

export const SituacaoManifestoModal = (props: SituacaoManifestoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      <SituacaoManifesto model={props.model}/>
    </DefaultModal>
  );
};