import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetHistoricos() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getHistoricos = useCallback(
        (id: string) => {

            return invocarApi({
                url: `v2/faturas/${id}/historicos`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getHistoricos,
    };
}
