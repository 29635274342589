import { Paper, Typography } from '@material-ui/core';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { TpModMock } from 'data/mocks';
import { CardDashboardAdminChartDonutProps } from '../card-dashboard-admin-chart-props';
import { toDecimalString } from 'utils/to-decimal';
import { CircularLoading } from 'views/components/utils';

export const CardDashboardAdminChartDonut = ({
  model,
  carregando
}: CardDashboardAdminChartDonutProps) => {
  const utilClasses = useStyles();

  let modUnicos = new Map(
    model.map((obj) => [obj.modPagamento, obj]),
  );

  const modsUnicosList = Array.from(modUnicos.values());

  const pagamentosSerelizados = modsUnicosList.map((pagamento) => {
    return {
      ...pagamento,
      vVenda: 0,
      qtde: 0
    };
  });

  pagamentosSerelizados.map((pagamento) => {
    model.map((pag) => {
      if (pag.modPagamento === pagamento.modPagamento) {
        let novaQuantidade = (pagamento.vVenda += pag.vVenda);
        let novaQTDE = pagamento.qtde += 1
        return {
          ...pagamento,
          vPag: novaQuantidade,
          qtde: novaQTDE
        };
      }
      return pag;
    });
    return pagamento;
  });

  const arraySeries = useCallback(() => {
    const modUnique = Array.from(
      new Set<number>(model?.map((item) => item?.modPagamento)),
    );

    const retornaSeries = modUnique.map((item) => {
      return model
        ?.filter((x) => x?.modPagamento === item && x)
        .reduce((a, b) => a + b.vVenda, 0);
    });
    return retornaSeries;
  }, [model]);

  const labelsText = model?.map((tp) => {
    return TpModMock.filter((x) => x?.Key === tp?.modPagamento)[0]?.Value;
  });

  const unique = Array.from(
    new Set<string>(labelsText?.map((item) => String(item))),
  );

  const options = useCallback(() => {
    const options = {
      labels: unique,
      tooltip: {
        style: {
          fontSize: '24px',
        },
        y: {
          formatter: function (val: any) {
            return `R$ ${toDecimalString(val)}`;
          },
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                formatter: function (val: any) {
                  return `R$ ${toDecimalString(val)}`;
                },
              }
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            legend: {
              position: 'top',
            },
          },
        },
      ],
      legend: {
        show: true,
      },
    };
    return options;
  }, [unique]);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 13px',
            }}
          >
            <div style={{ padding: '10px 0' }}>
              <Typography
                color="primary"
                style={{ fontSize: '1.1rem', fontWeight: 500 }}
              >
                Modelos de pagamentos
              </Typography>
            </div>
          </div>
          <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>
          {carregando ?
            <div style={{ position: 'relative' }}>
              <CircularLoading tipo='FULLSIZED' />
            </div>
            :
            <>
              {
                model.map(item => item.modPagamento)[0] === 0 || model.length === 0 ? (
                  <div className={utilClasses.alinharChart}>
                    <div style={{ display: 'block', marginTop: 20 }}>
                      <Typography variant="h5" style={{ textAlign: 'center' }}>
                        Não há dados para exibir
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className={utilClasses.alinharChartDonut}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <div>
                        <Chart
                          options={options()}
                          series={arraySeries()}
                          type="donut"
                          className={utilClasses.chartDonut}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
            </>
          }
        </div>
      </Paper>
    </>
  );
};
