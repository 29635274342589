import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import { guidEmpty } from "utils/guid-empty";
export class PessoaContatosModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public tipo: EnumTipoPessoaContato = EnumTipoPessoaContato.EMAIL,
    public valor: string = "",
    public responsavel: string = "",
  ) { }
}
