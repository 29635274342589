import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumDecontoOuAcrescimo } from "model/enums/enum-desconto-ou-acrescimo";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogAdicionarAcrescDesc, DialogAdicionarAcrescDescProps } from "views/components/dialog/dialog-adicionar-acresc-desc/dialog-adicionar-acresc-desc";

export const AdicionarAcrescDescDialog = () => {
    const { addHandler, removeHandler } = useEventTools();

    const [modalState, setModalState] = useState<DialogAdicionarAcrescDescProps>({
        aberto: false,
        model: new MovSimplesProdutoModel(),
        tipo: EnumDecontoOuAcrescimo.DescontoItem,
        valorTotal: 0,
    });

    consoleDev('AdicionarAcrescDescDialog')

    const modalAlterado = useCallback(({ aberto, model, tipo, valorTotal }: any) => {
        setModalState({
            aberto: aberto,
            model: model,
            tipo: tipo,
            valorTotal: valorTotal,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogAdicionarAcrescDesc, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogAdicionarAcrescDesc, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogAdicionarAcrescDesc
                    aberto={modalState.aberto}
                    tipo={modalState.tipo}
                    model={modalState.model}
                    valorTotal={modalState.valorTotal}
                />
            )}
        </>
    )
}