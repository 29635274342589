import { Button, Grid } from "@material-ui/core";
import { picker } from "utils/picker";
import { PerfilCadastroFormModel } from "model/app/forms/perfil/perfil-cadastro-form-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { useRef, useCallback, useEffect } from "react";
import { useToastSaurus } from "services/app";
import { useCadastros } from "../../../../../../services/app/hooks/cadastros";
import { ModalHeader } from "../../../components/modal-header/modal-header";
import { useModalStyles } from "../../../utils/modal-styles";
import { VoltarIcon } from "../../../../icons/voltar-icon";
import { ButtonModalHeader } from "../../../../controles/buttons/button-modal-header/button-modal-header";
import { SalvarEditarIcon } from "../../../../icons/salvar-editar-icon";
import { SalvarNovoIcon } from "../../../../icons/salvar-novo-icon";
import { CircularLoading } from "../../../../utils";
import classNames from "classnames";
import { FormPerfilCadastro } from "../../../../form/perfil/form-perfil-cadastro/form-perfil-cadastro";
import { PerfilModel } from "model/api/gestao/perfil/perfil-model";
import { usePostPerfil } from "data/api/gestao/perfil/post-perfil";
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { EnumTour } from "model/enums/enum-tour";

export const PerfilCadastro = () => {
  const { postPerfil, carregando } = usePostPerfil();
  const { fecharCadastroPerfil, abrirCadastroPerfil } = useCadastros();

  const classes = useModalStyles();

  const cadPerfilFormRef =
    useRef<DefaultFormRefs<PerfilCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const { showToast } = useToastSaurus();
  const {callTour} = useTourSaurus();
  const saveNewPerfil = useCallback(
    async (model: PerfilModel) => {
      try {
        const ret = await postPerfil(model);
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroPerfil(ret.resultado?.data.id, "", true);
          }, 1);
        }
        if (!redirect.current) {
          showToast("success", "Perfil adicionado com sucesso!", 2000, "bottom-center", 60);
        }

        if (!redirect.current) {
          cadPerfilFormRef.current?.resetForm();
        }
      } catch (e: any) {
        showToast("error", e.message);
        cadPerfilFormRef.current?.fillForm(model);
      }
    },
    [postPerfil, showToast, abrirCadastroPerfil]
  );
  const handleSubmit = useCallback(
    async (modelP: PerfilCadastroFormModel) => {
      const perfilToCreate = picker<PerfilModel>(modelP, new PerfilModel());
      perfilToCreate.horarios = []
      return saveNewPerfil(perfilToCreate);
    },
    [saveNewPerfil]
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadPerfilFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadPerfilFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroPerfil();
  }, [fecharCadastroPerfil]);

  useEffect(()=>{
        setTimeout(()=>{callTour(EnumTour.FORMPERFILDEACESSO)},500)
  },[callTour])

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={"Cadastro de Perfil"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined
          )}
        >
          <FormPerfilCadastro
            ref={cadPerfilFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON" />
                Salvar e Adicionar Outro
              </Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
                Salvar e Editar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default PerfilCadastro;
