import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormComandaValidation = () => {
  const FormComandaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        comandaInicial: Yup.number().test("comandaInicial", 'Por favor, insira um número válido', (value) => {
          if(value === undefined) {
            return false
          }
          return !isNaN(value);
        }).required('Comanda inical é obrigatória').typeError('Comanda inical é obrigatória'),
        comandaFinal: Yup.number().required('Comanda final é obrigatória').typeError('Comanda final é obrigatória'),
        temRFID: Yup.boolean(),
        prefixoRfId: Yup.string().when('temRFID', {
          is: true,
          then: Yup.string().required('Prefixo rfId é obrigatório'),
          otherwise: Yup.string(),
        }),
        inicialRfId: Yup.number().when('temRFID', {
          is: true,
          then: Yup.number().required('RFID inicial é obrigatório'),
          otherwise: Yup.number(),
        }),
        finalRfId: Yup.number().when('temRFID', {
          is: true,
          then: Yup.number().required('RFID final é obrigatório'),
          otherwise: Yup.number(),
        }),
      })
    )
  }, [])
  return {
    FormComandaValidationYup,
  }
}

