import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetWhitelabel() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getWhitelabel = useCallback(
        (token: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Whitelabel`,
                method: "GET",
                enviarTokenUsuario: true,
                token
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getWhitelabel,
    };
}