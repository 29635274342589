import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogPagarPix } from "views/components/dialog/dialog-pagar-pix/dialog-pagar-pix";

class PixDialogProps {
    constructor(
        public aberto: boolean = false,
        public pagamento: MovSimplesPagamentoModel = new MovSimplesPagamentoModel(),
        public finalizar: () => Promise<any> = async () => {},
        public cancelar: () => void = () => {},
    ) { }
}

export const PixDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<PixDialogProps>(
        new PixDialogProps()
    );

    const modalAlterado = useCallback(({aberto , cancelar, finalizar, pagamento }: PixDialogProps) => {
        setModalState({
            aberto: aberto,
            pagamento,
            cancelar,
            finalizar
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogPix, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogPix, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogPagarPix
                    aberto={modalState.aberto}
                    cancelar={modalState.cancelar}
                    finalizar={modalState.finalizar}
                    pagamento={modalState.pagamento}
                />
            )}
        </>
    )
}