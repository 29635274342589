import { Grid } from '@material-ui/core';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { memo, useEffect } from 'react';
import { useDevice } from 'services/app/hooks/device';
import { CircularLoading } from 'views/components';
import { CardNaoEncontrado } from 'views/components/cards';
import CardFinalizadoraMov from '../../../../../../components/cards/card-finalizadora-mov/card-finalizadora-mov';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { isEmpty } from 'lodash';
import { useStyles } from './novo-pagamento-list-styles';
import { useToastSaurus } from 'services/app';

export interface NovoPagamentoGridProps {
  list: Array<FinalizadoraModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardClicked: (model: FinalizadoraModel) => any;
  onCardChecked: (model: FinalizadoraModel) => any;
  setTpPayments?: React.Dispatch<
    React.SetStateAction<EnumPagTpMod[] | undefined>
  >;
  paymentScreen?: boolean;
  entrada?: boolean
}

export const NovoPagamentoListData = ({
  carregando: loading,
  list,
  selectedList,
  onCardChecked: onCheck,
  onCardClicked: onClick,
  setTpPayments,
  paymentScreen = false,
  ...props
}: NovoPagamentoGridProps) => {
  const { tpPayments, getPayments, carregando } = useDevice();
  const classes = useStyles({ paymentScreen });
  const { showToast } = useToastSaurus()

  useEffect(() => {
    if (isEmpty(tpPayments)) {
      getPayments();
    } else if (
      VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA &&
      !isEmpty(tpPayments)
    ) {
      setTpPayments?.(tpPayments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayments, setTpPayments, tpPayments]);

  const onCardClicked = (model: FinalizadoraModel) => {
    try {
      onClick(model);
    } catch (error: any) {
      showToast('error', error.message)
    }
  };

  const onCardChecked = (model: FinalizadoraModel) => {
    onCheck(model);
  };

  const payments = list
    .filter((item) => item.ativo === true)
    .filter((item) => {
      if (
        item.tpTransacao === EnumPagTpTransacao.S2_PAY &&
        (tpPayments ?? []).find((x) => x === item.tpMod)
      ) {
        return item;
      } else if (item.tpTransacao !== EnumPagTpTransacao.S2_PAY) {
        return item;
      }

      return null;
    })
    .sort((a, b) => a.ordem - b.ordem);

  const divider = Math.ceil(payments.length / 2);
  const lineOne = payments.length <= 8 ? payments : payments.slice(0, divider);
  const lineTwo = payments.length <= 8 ? [] : payments.slice(divider);


  const fillBreakpoint = (value: number) => {
    let resultado;
    switch (value) {
      case 1:
        resultado = 12;
        break;
      case 2:
        resultado = 6;
        break;
      case 3:
        resultado = 4;
        break;
      default:
        resultado = 3;
    }
    return resultado as any
  }

  return (
    <>
      <Grid item xs={12}>
        {list.length === 0 && !loading && (
          <CardNaoEncontrado
            mensagem="Nenhuma forma de pagamento encontrada."
            icon={<></>}
          />
        )}
      </Grid>
      {carregando && (
        <>
          <CircularLoading tipo="FULLSIZED" />
        </>
      )}
      {!carregando && (
        <Grid item xs={12} className={classes.container}>
          <Grid className={classes.row}>
            {lineOne.map((finalizadora, index, arr) => {
              return (
                <Grid xs={fillBreakpoint(arr.length)}>
                  <CardFinalizadoraMov
                    selected={
                      selectedList.filter((item) => item === finalizadora.id)
                        .length > 0
                        ? true
                        : false
                    }
                    onCheck={onCardChecked}
                    onClick={onCardClicked}
                    model={finalizadora}
                    key={index}
                    paymentScreen
                  />
                </Grid>
              );
            })}
          </Grid>
          {payments.length >= 8 && (
            <Grid className={classes.row}>
              {lineTwo.map((finalizadora, index) => {
                return (
                  <CardFinalizadoraMov
                    selected={
                      selectedList.filter((item) => item === finalizadora.id)
                        .length > 0
                        ? true
                        : false
                    }
                    onCheck={onCardChecked}
                    onClick={onCardClicked}
                    model={finalizadora}
                    key={index}
                    paymentScreen
                  />
                );
              })}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default memo(NovoPagamentoListData);
