import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { useStyles } from './dialog-alteracao-cadastros-styles';

interface DialogAlteracaoCadastrosProps {
  openned: boolean;
  handleClick: () => void;
  closeModal: () => void;
}

export const DialogAlteracaoCadastros = ({
  openned,
  closeModal,
  handleClick
}: DialogAlteracaoCadastrosProps) => {
  const classes = useStyles();

  return (
    <DialogSaurus
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Alteração cadastral"
      tamanho="xs"
    >
      <Grid>
        <Typography className={classes.title}>
          Identificamos alterações cadastrais, deseja refazer a sincronização
          dos seus cadastros?
        </Typography>

        <Grid container spacing={2} direction='row'>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              className='round'
              onClick={closeModal}
            >
              <VoltarIcon tipo="BUTTON" />
              Não Sincronizar
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className='round'
              onClick={handleClick}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Sincronizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
