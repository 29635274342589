import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useDeleteMarca() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteMarca = useCallback(
        (id: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/marca/${id}`,
                method: 'DELETE',
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteMarca,
    };
}