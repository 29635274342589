import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1),
        '& p': {
            fontSize: 13
        }
    },
    weight: {
        fontWeight: 600
    }
}));