import * as Yup from 'yup'
import { useValidationYupEmail, useValidationYupFone } from '../../../form-validations'

export const useFormRecuperarSenhaValidations = (tipo: "EMAIL" | "TELEFONE") => {
    const { emailYup } = useValidationYupEmail()
    const { foneYup } = useValidationYupFone();

    const FormRecuperarSenhaYupValidations = Yup.object().shape({
        email: Yup.string().required('campo obrigatório').when(tipo, {
            is: (a: "EMAIL" | "TELEFONE") => a === 'EMAIL',
            then: emailYup(),
            otherwise: foneYup()
        })
    });

    return {
        FormRecuperarSenhaYupValidations
    }
}

