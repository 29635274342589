import { Button, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-com-subitem-styles';
import { AvancarIcon, VoltarIcon } from '../../icons';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useNavigateSubItens } from 'services/app/hooks/navigate-subItens';
import { CardSubitem } from 'views/components/cards/card-produto-subitem/card-subitem';
import { useCallback, useEffect, useState } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useThemeQueries } from 'views/theme';
// import { toDecimalString } from 'utils/to-decimal';
import { AddESub } from 'views/components/cards/card-produto-subitem/components/add-e-sub/button-add-e-sub';
import { useCadastros } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { CardSubitemModificador } from 'views/components/cards/card-produto-subitem-modificador/card-subitem-modificador';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { MovSimplesModificadoresProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-modificadores-produto-model';
import { CircularLoading } from 'views/components/utils';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
export interface DialogAdicionarProdutoComSubItensProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
  edit?: boolean;
}

export const DialogAdicionarProdutoComSubItem = ({
  aberto,
  produto,
  edit
}: DialogAdicionarProdutoComSubItensProps) => {
  // Hooks
  const {
    currentProduct,
    productFinal,
    next,
    goBack,
    productsAdicionais,
    handleAlterQuantity,
    isGoback,
    productInitial,
    carregando
  } = useNavigateSubItens(produto, edit);
  const theme = useTheme();
  const { fecharAdicionarProdutoSubItem } = useCadastros();
  const { adicionarProdutoComSubItem, editarProdutoComSubItem } = useMovProd();
  const { callEvent, addHandler, removeHandler } = useEventTools();

  const [validButton, setValidButton] = useState<boolean>((currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? []).length > 0 ? true : false);

  useEffect(() => {
    if (aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false);
    }
    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, addHandler, callEvent, produto, removeHandler]);

  useEffect(() => {
    const validarModificadores = currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? [];
    setValidButton(validarModificadores.length > 0 ? true : false)
  }, [currentProduct])

  // STATES E REFS
  const [qtd, setQtd] = useState<number>(productInitial.qCom);
  const [infoAdicional, setinfoAdicional] = useState<string>(productInitial.infAdic);

  const handleSubItem = useCallback(
    (
      produto: MovSimplesProdutoModel,
      adicionais: MovSimplesProdutoModel[],
      valorFinal: number
    ) => {
      if (!edit) adicionarProdutoComSubItem({ ...produto, infAdic: infoAdicional }, adicionais, valorFinal);
      else editarProdutoComSubItem({ ...produto, infAdic: infoAdicional }, adicionais, valorFinal);

      fecharAdicionarProdutoSubItem();
    },
    [adicionarProdutoComSubItem, edit, editarProdutoComSubItem, fecharAdicionarProdutoSubItem, infoAdicional]
  );

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  const imgInfo = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImgInfo}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, classes.cardImgInfo, produto.xProd]
  );

  return (
    <DialogSaurus
      aberto={aberto || false}
      tamanho="xs"
      titulo={isMobile ? '' : 'Personalize seu Produto'}
      isButtonTitleClose
      handleClickClose={fecharAdicionarProdutoSubItem}
      fullScreen={isMobile}
    >
      {carregando && <CircularLoading tipo='FULLSIZED'/>}
      <Grid className={classes.cardContainer}>
        {isMobile ? (
          <Typography className={classes.titulo}>
            Personalize seu Produto
          </Typography>
        ) : null}
        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
          {isGoback && currentProduct !== null && (
            <div style={{ display: 'flex', marginBottom: 12 }}>
              {imgInfo(productFinal.current.imgUrl)}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1
                }}
              >
                <Grid>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '400',
                      fontSize: '10px',
                      marginLeft: 8
                    }}
                  >
                    Descrição
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '600',
                      fontSize: '18px',
                      marginLeft: 8,
                      lineHeight: '18px',
                      marginBottom: '8px'
                    }}
                  >
                    {productFinal.current.xProd}
                  </Typography>
                </Grid>

                {/* <Grid>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '400',
                      fontSize: '10px',
                      marginLeft: 8
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      marginLeft: 8,
                      lineHeight: '18px'
                    }}
                  >
                    {toDecimalString(
                      productFinal.current.vUnCom +
                        productsAdicionais.reduce(
                          (acc, current) => acc + current.vFinal,
                          0
                        ),
                      2
                    )}
                  </Typography>
                </Grid> */}
              </div>
            </div>
          )}

          <div style={{ display: 'flex', marginBottom: 8 }}>
            {img(currentProduct?.imgUrl ?? productFinal.current.imgUrl)}
            <div style={{ flex: 1 }}>
              <Typography
                variant="body1"
                style={{ fontWeight: '400', fontSize: '10px', marginLeft: 8 }}
              >
                Descrição
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: '600',
                  fontSize: '18px',
                  marginLeft: 8,
                  lineHeight: '18px',
                  marginBottom: '8px'
                }}
              >
                {currentProduct?.xProd ?? productFinal.current.xProd}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontWeight: '400',
                  fontSize: currentProduct === null ? '12px' : '10px',
                  marginLeft: 8
                }}
              >
                {currentProduct === null ? '' : 'Categoria'}
              </Typography>

              {/* <Typography
                variant="body1"
                style={{
                  fontWeight: '600',
                  fontSize: '14px',
                  marginLeft: 8,
                  lineHeight: '18px'
                }}
              >
                {currentProduct !== null
                  ? currentProduct?.categoria
                  : toDecimalString(
                      ((productInitial.tpProduto === EnumTpProduto.Combo ? 0 : productInitial.vUnCom) +
                        productsAdicionais.reduce(
                          (acc, current) => acc + current.vFinal,
                          0
                        )) *
                        qtd,
                      2
                    )}
              </Typography> */}
            </div>

            {currentProduct === null && !edit && (
              <AddESub
                model={productFinal.current}
                qtd={qtd}
                setQtd={setQtd}
                alterarQtdeProduto={(
                  tpProdutoPai: EnumTpProduto,
                  valorProdutoPai: number,
                  produto: MovSimplesProdutoModel,
                  qtd: number,
                  modificador?: MovSimplesModificadoresProdutoModel,
                  prodGradeId?: string,
                  fator?: 'add' | 'sub'
                ) => {
                  productFinal.current = {
                    ...productFinal.current,
                    qCom: qtd
                  };
                }}
                valorProdutoPai={productFinal.current.vProd}
                noZeroMin
                tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
              />
            )}
          </div>

          {currentProduct === null && (
            <Grid container style={{ marginBottom: 8 }}>
              <TextFieldSaurus
                tipo="TEXTO"
                fullWidth
                size="small"
                allowSubmit={true}
                value={infoAdicional}
                placeholder="Informação adicional"
                onChange={(event) => setinfoAdicional(event.target.value)}
              />
            </Grid>
          )}

          {currentProduct === null &&
            productInitial.prodSubItem
              .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
              .filter((p) => p.qCom > 0).length > 0 && (
              <>
                <Typography variant="h6">Inclusos no Produto</Typography>
                <Divider className={classes.divider} />
              </>
            )}

          <div>
            {currentProduct?.prodSubItem &&
              currentProduct?.prodSubItem?.length > 0 &&
              currentProduct?.prodSubItem
                .filter((prod) => !prod.modificadorId)
                .map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    edit={edit}
                    qtdPai={productInitial.qCom}
                    valorProdutoPai={currentProduct.vProd}
                    tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                  />
                ))}
          </div>

          {currentProduct?.modificadores &&
            currentProduct?.modificadores?.length > 0 &&
            currentProduct?.modificadores.map((modificador) => (
              <Grid style={{ marginRight: 4, marginTop: 16 }}>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid style={{ alignItems: 'center', flex: 1 }}>
                    <Typography variant="h6">{modificador.nome}</Typography>
                    <Typography variant="caption">
                      {modificador.descricao}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    {!(modificador.qMin === 1 && modificador.qMax === 1) && modificador.qMin !== 0 && (
                      <Grid
                        className={classes.badgeInfoModificador}
                        style={{ marginBottom: 4 }}
                      >
                        <Typography
                          variant="caption"
                          style={{ color: theme.palette.common.white }}
                        >
                          Minímo de {modificador.qMin}
                        </Typography>
                      </Grid>
                    )}
                    <Grid className={classes.badgeInfoModificador}>
                      <Typography
                        variant="caption"
                        style={{ color: theme.palette.common.white }}
                      >
                        {modificador.qMin === 1 && modificador.qMax === 1
                          ? 'Obrigatório'
                          : `${modificador.qAtual} de ${modificador.qMax}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                {currentProduct?.prodSubItem
                  .filter((p) =>
                    p.modificadorId === modificador.id &&
                    p.modificadorUnicoId === modificador.unicoId
                  )
                  .sort((a, b) => a.ordem - b.ordem)
                  .map((p) => (
                    <CardSubitemModificador
                      key={p.id}
                      produto={p}
                      handleClickAddOrSub={handleAlterQuantity}
                      edit={edit}
                      qtdPai={productInitial.qCom}
                      modificador={modificador}
                      qtdModificador={currentProduct?.prodSubItem
                        .filter(
                          (p) =>
                            p.modificadorId === modificador.id &&
                            p.modificadorUnicoId === modificador.unicoId &&
                            p.qCom > 0
                        )
                        .reduce((acc, current) => acc + current.qCom, 0)}
                      valorProdutoPai={currentProduct.vProd}
                      tpProdutoPai={currentProduct.tpProduto}
                    />
                  ))}
              </Grid>
            ))}

          {currentProduct === null && (
            <div>
              {productInitial.prodSubItem &&
                productInitial.prodSubItem?.length > 0 &&
                productInitial.prodSubItem
                  .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
                  .filter((p) => p.qCom > 0)
                  .map((p) => {
                    return (
                      <CardSubitem
                        key={p.id}
                        produto={{
                          ...p,
                          qCom:
                            p.qCom > p.infoSubItem!.qPadrao
                              ? p.infoSubItem!.qPadrao
                              : p.qCom
                        }}
                        handleClickAddOrSub={handleAlterQuantity}
                        verificar
                        edit={edit}
                        qtdPai={productInitial.qCom}
                        valorProdutoPai={productInitial.vProd}
                        tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                      />
                    );
                  })}
            </div>
          )}
          {currentProduct === null && productsAdicionais.length > 0 && (
            <>
              <Typography variant="h6">Adicionais</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {currentProduct === null && (
            <div>
              {productsAdicionais.length > 0 &&
                productsAdicionais.map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    showValue
                    verificar
                    edit={edit}
                    qtdPai={1}
                    qtdModificador={productsAdicionais
                      .filter(
                        (prod) =>
                          prod.modificadorId === p.modificadorId &&
                          prod.qCom > 0
                      )
                      .reduce((acc, current) => acc + current.qCom, 0)}
                    valorProdutoPai={productFinal.current.vProd}
                    tpProdutoPai={currentProduct ? (currentProduct as MovSimplesProdutoModel).tpProduto : productFinal.current.tpProduto}
                  />
                ))}
            </div>
          )}
        </div>

        <Grid
          container
          spacing={2}
          style={{ marginTop: '8px', marginBottom: '8px' }}
        >
          {isGoback && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={goBack}
                fullWidth
                className="round"
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
          )}
          <Grid item xs={isGoback ? 6 : 12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="round"
              disabled={validButton}
              onClick={() => {
                if (currentProduct === null) {
                  handleSubItem(
                    productFinal.current,
                    productsAdicionais,
                    productFinal.current.vUnCom +
                    productsAdicionais.reduce(
                      (acc, current) => acc + current.vFinal,
                      0
                    )
                  );
                } else {
                  next();
                }
              }}
            >
              {currentProduct === null ? (
                <ConfirmarIcon tipo="BUTTON_PRIMARY" />
              ) : (
                <AvancarIcon tipo="BUTTON_PRIMARY" />
              )}
              {currentProduct === null ? 'Confirmar' : 'Próximo'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
