export enum EnumOrigemMercadoria {
  OM_NACIONAL = 0,
  OM_ESTRANGEIRA_IMPORTACAO_DIRETA = 1,
  OM_ESTRANGEIRA_ADQUIRIDA_BRASIL = 2,
  OM_NACIONAL_CONTEUDO_IMPORTACAO_SUPERIOR_40 = 3,
  OM_NACIONAL_PROCESSOS_BASICOS = 4,
  OM_NACIONAL_CONTEUDO_IMPORTACAO_INFERIOR_IGUAL_40 = 5,
  OM_ESTRANGEIRA_IMPORTACAO_DIRETAS_EM_SIMILAR = 6,
  OM_ESTRANGEIRA_ADQUIRIDA_BRASIL_SEM_SIMILAR = 7,
  OM_NACIONAL_CONTEUDO_IMPORTACAO_SUPERIOR_70 = 8,
}
