import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup';

export const useFormMarcaValidation = () => {
    const { documentoYup } = useValidationYupDocumento(true)
    const FormMarcaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                nome: Yup.string().required('Informe o nome do laboratório.').typeError('Informe o nome do laboratório.'),
                cnpjFab: documentoYup('CNPJ')
      })
        )
    }, [documentoYup])

    return {
        FormMarcaValidationYup,
    }
}