import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { useStyles } from "./landing-page-styles";
import { useHistory } from "react-router-dom";
import { PublicPageHeader } from "../../../components/headers";
import { CarouselSaurus } from "../../../components/carousel-saurus";
import { GestaoStorageKeys, useGestaoStorage } from "services/app";
import { AvancarIcon } from "views/components/icons";
import LandingItem01 from "./components/items/landing-item-01";
import LandingItem02 from "./components/items/landing-item-02";
import { useThemeQueries } from "views/theme";
import { useEffect } from "react";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { useSessaoAtual } from 'services/app';

const LandingPage = () => {
    const { usuario } = useSessaoAtual();
    const { isMobile } = useThemeQueries();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { setRegistro } = useGestaoStorage()
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1 }
    ];

    useEffect(() => {
        if (VariaveisAmbiente.paymentDevice !== EnumDeviceType.CORDOVA)
            history.push('/login')
    }, [history])

    return (
        <>
            <div className={classes.bgColor}></div>
            <PublicPageHeader variant="white" hideInfo />

            <Grid className={classes.content}>
                <Grid className={classes.contentCarousel}>
                    <CarouselSaurus noMinHeight fullScreen={true} isEscuro={true} outerSpacing={0} breakPoints={breakPoints} isRTL={false} showArrows={false} enableAutoPlay>
                        <LandingItem01 />
                        <LandingItem02 />
                    </CarouselSaurus>
                </Grid>
                <div className={classes.textFlex}>
                    <Typography align={!isMobile ? 'center' : undefined} className={`${classes.texts} ${classes.welcomeText}`} >
                        <strong>Bem-vindo ao</strong>
                    </Typography>
                    <Typography align={!isMobile ? 'center' : undefined} className={`${classes.texts} ${classes.titleText}`}>
                        <strong>TouchOne</strong>
                    </Typography>
                    <Typography align={!isMobile ? 'center' : undefined} className={`${classes.texts} ${classes.descriptionText}`}>
                        Controle suas vendas, acompanhe todas as atividades e receba atualizações de qualquer lugar.
                    </Typography>
                    <br />
                </div>
                <Grid className={classes.contentButtons} >
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} md={8} lg={6} container justifyContent="center" spacing={2}>
                            {usuario && (
                                <Grid item xs={8}>
                                    <Button
                                        onClick={() => {
                                            setRegistro(GestaoStorageKeys.LandingPage, {
                                                visto: true
                                            }, false)
                                            history.push("/")
                                        }}
                                        fullWidth={true}
                                        variant="contained"
                                        style={{
                                            color: theme.palette.primary.main,
                                            background: theme.palette.primary.contrastText,
                                        }}
                                    >
                                        <AvancarIcon tipo="BUTTON" />
                                        Continuar Sessão
                                    </Button>
                                </Grid>
                            )}
                            {!usuario && (
                                <>
                                    <Grid item xs={10}>
                                        <Button
                                            variant="contained"
                                            className={classes.loginButton}
                                            fullWidth
                                            onClick={() => {
                                                setRegistro(GestaoStorageKeys.LandingPage, {
                                                    visto: true
                                                }, false)
                                                history.push("/login");
                                            }}
                                        >
                                            Já sou cliente
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button
                                            onClick={() => {
                                                setRegistro(GestaoStorageKeys.LandingPage, {
                                                    visto: true
                                                }, false)
                                                history.push("/novo-contrato")
                                            }}
                                            fullWidth={true}
                                            className={classes.signupButton}
                                        >
                                            Cadastrar
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default LandingPage;