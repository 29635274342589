import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    infoIconExt: {
        height: 20,
        width: 20,
        fill: theme.palette.primary.main,
        marginLeft: theme.spacing(1)
    },
    tooltipText: {
        padding: theme.spacing(1)
    },
    
    iconDiv: {
        display: "flex",
        alignItems: "center",
        padding: "8px"
    }
}))