import { CompartilhamentoDadosWebHookModel } from "model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogWebhook, DialogWebhookProps } from "views/components/dialog/dialog-webhook/dialog-webhook";


export const AdicionarWebhookDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogWebhookProps>({
        modelo: new CompartilhamentoDadosWebHookModel(),
        aberto: false,
    });

    consoleDev('AdicionarWebhookDialog')

    const modalAlterado = useCallback(({ aberto, modelo }: any) => {
        setModalState({
            aberto: aberto,
            modelo: modelo
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.AdicionarWebhookDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.AdicionarWebhookDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogWebhook aberto={modalState.aberto} modelo={modalState.modelo}  />
            )}
        </>
    )
}