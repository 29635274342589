import { VendaModel } from 'model/api/gestao/venda/venda-model';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostNovaVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postNovaVenda = useCallback(
        (venda: VendaModel, timeout: number = 30000) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Movimento/simplificado`,
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(venda),
                enviarTokenUsuario: true,
                timeout: timeout
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postNovaVenda,
    };
}
