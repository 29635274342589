import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogTaxaServicoCarrinho } from 'views/components/dialog/dialog-taxa-servico-carrinho/dialog-taxa-servico-carrinho';

class TaxaServicoCarrinhoProps {
  constructor(
    public aberto: boolean = false,
  ) { }
}

export const TaxaServicoDialogCarrinhoRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<TaxaServicoCarrinhoProps>(
    new TaxaServicoCarrinhoProps()
  );

  consoleDev('TaxaServicoDialog');

  const modalAlterado = useCallback(
    ({ aberto }: any) => {
      setModalState({
        aberto
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogTaxaServicoCarrinho, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogTaxaServicoCarrinho, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogTaxaServicoCarrinho
          openned={modalState.aberto}
        />
      )}
    </>
  );
};
