import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid } from '@material-ui/core';
import { ConfiguracaoIcon, SalvarIcon } from 'views/components/icons';
// import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { ConfigEmpresaModel } from 'model/api/gestao/config-empresa/config-empresa-model';
import {
  PutConfigEmpresaProps,
  usePutConfigEmpresa,
} from 'data/api/gestao/empresa/put-config-empresa';
import { FormConfigEmpresaEdit } from 'views/components/form/master/config-empresa-edit/form-config-empresa-edit';
import { useGetConfigEmpresaId } from 'data/api/gestao/empresa/get-config-empresa-id';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';

interface Props {
  id: string;
}

export const AccordionConfiguracoesEmpresaa = ({ id }: Props) => {
  // STATES E REFS
  const configFormRef =
    useRef<DefaultFormRefs<ConfigEmpresaModel[]>>(null);
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [configFormState, setConfigFormState] = useState<ConfigEmpresaModel[]>(new Array<ConfigEmpresaModel>());

  // PROVIDERS & HOOKS
  const { getEmpresaAtual, refreshEmpresa } = useEmpresaAtual()

  // CHAMADAS API
  const { getConfigEmpresaId, carregando: carregandoGetConfigEmpresa } =
    useGetConfigEmpresaId();
  const { putConfigEmpresa, carregando: carregandoPutEmpresa } =
    usePutConfigEmpresa();

  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();

  const carregando =
    carregandoFromForms ||
    carregandoGetConfigEmpresa ||
    preenchendoTela ||
    carregandoPutEmpresa;

  const getConfigByIdWrapper = useCallback(async () => {
    const res = await getConfigEmpresaId(getEmpresaAtual()?.id || '');
    if (res.erro) {
      throw res.erro;
    }
    return (res.resultado?.data as ConfigEmpresaModel[])
  }, [getConfigEmpresaId, getEmpresaAtual]);

  const preencherTela = useCallback(async () => {
    setPreenchendoTela(true);
    try {
      const config = await getConfigByIdWrapper();
      setConfigFormState(config);
    } catch (e: any) {
      showToast('error', e.message);
    }
    finally {
      setPreenchendoTela(false);
    }
  }, [getConfigByIdWrapper, showToast]);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        await preencherTela();
      })();
      return () => {
        //funcao de limpeza
        setConfigFormState(new Array<ConfigEmpresaModel>());
      };
    }, 200);
  }, [getConfigByIdWrapper, preencherTela, showToast]);

  const recarregarForm = useCallback((model: ConfigEmpresaModel[]) => {
    configFormRef.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(configFormState);
  }, [recarregarForm, configFormState]);

  const saveChangesConfigEmpresa = useCallback(
    async (model: ConfigEmpresaModel[], id: string) => {
      const ret = await putConfigEmpresa(
        model.map(config => {
          return new PutConfigEmpresaProps(config.empresaId, config.cod, config.vConfig.toString())
        }),
        id,
      );

      if (ret.erro) {
        throw ret.erro;
      }

      return true
    },
    [putConfigEmpresa],
  );

  const handleSubmit = useCallback(
    async (model: ConfigEmpresaModel[]) => {
      try {
        const pendente = model.filter(item => item.editado)
        if (pendente.length < 1) {
          return showToast('info', "Nenhuma alteração para salvar.")
        }

        await saveChangesConfigEmpresa(pendente, id)

        if (id === getEmpresaAtual()?.id) {
          await refreshEmpresa();
        }


        showToast('success', "Configurações atualizadas.")
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getEmpresaAtual, id, refreshEmpresa, saveChangesConfigEmpresa, showToast],
  );

  const form = useMemo(() => {
    return (
      <div
        className={classNames(
          classesModal.contentForms,
          preenchendoTela ? classesModal.contentFormsLoading : '',
        )}
        style={{ height: '100%' }}
      >
        <FormConfigEmpresaEdit
          showLoading={false}
          ref={configFormRef}
          setCarregandoExterno={setCarregandoFromForms}
          loading={carregando}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }, [
    carregando,
    classesModal.contentForms,
    classesModal.contentFormsLoading,
    handleSubmit,
    preenchendoTela,
  ]);

  const buttonSalvar = useMemo(() => {
    return (
      <div className={classesModal.acoes}>
        <Grid item xs={12}>
          <Button
            disabled={carregando}
            onClick={() => configFormRef.current?.submitForm()}
            variant="contained"
            color="primary"
            fullWidth
          >
            <SalvarIcon tipo="BUTTON_PRIMARY" />
            Salvar
          </Button>
        </Grid>
      </div>
    );
  }, [carregando, classesModal.acoes]);

  return (
    <AccordionSaurus
      labelPrimary={'Configurações da Empresa'}
      tituloChildren={
        <ConfiguracaoIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          {form}
          {buttonSalvar}
        </div>
      </div>
    </AccordionSaurus>
  );
};
