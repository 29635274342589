import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeUtilClasses } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import {
    TextFieldSaurus,
    ImagemInput,
} from 'views/components/controles/inputs';
import { AutocompleteMedidas } from '../../../../controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { guidEmpty } from '../../../../../../utils/guid-empty';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { usePostProdutoMedida } from 'data/api/gestao/produto/produto-medida/post-produto-medida';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { retornoAutoComplete } from '../../../../controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { AutocompleteProdutoCategorias } from '../../../../controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { AutocompleteNcmsGestao } from '../../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useConfirm } from 'material-ui-confirm';
import { AutocompleteGrupoImpostos } from '../../../../controles/autocompletes/autocomplete-grupo-impostos/autocomplete-grupo-impostos';
import { ProdutoFiscalModel } from 'model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { useThemeQueries } from '../../../../../theme/util-styles';
import { isPlanoFiscal } from 'utils/plano-utils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSessaoAtual } from 'services/app';
import { MedicamentoCompletoFormModel } from 'model/app/forms/produto/medicamento/medicamento-completo-form-model';
import { useFormMedicamentoCompletoValidation } from './form-medicamento-completo-validation';
import { InformacaoIcon } from 'views/components/icons';
import { useStyles } from '../../form-produto-edit/produto-edit-principal/form-produto-principal-edit-styles'

interface FormMedicamentoCompletoProps extends DefaultFormProps<MedicamentoCompletoFormModel> {
    setCarregandoExterno(carregando: boolean): void;
    desabilitarCampos?: boolean;
    desabilitarPrecos?: boolean;
    empresaId: string;
    contratoId: string;
    imageSize?: 'normal' | 'small',
}

export const FormMedicamentoCompleto = forwardRef<
    DefaultFormRefs<MedicamentoCompletoFormModel>,
    FormMedicamentoCompletoProps
>(
    (
        {
            loading,
            desabilitarCampos,
            desabilitarPrecos,
            setCarregandoExterno,
            ...props
        }: FormMedicamentoCompletoProps,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const classes = useStyles();
        const { isMobile } = useThemeQueries();
        const refInputValorVenda = useRef<HTMLInputElement>(null);
        const refInputCodigo = useRef<HTMLInputElement>(null);
        const [initialValues, setInitialValues] =
            useState<MedicamentoCompletoFormModel>(
                new MedicamentoCompletoFormModel(),
            );
        const { showToast } = useToastSaurus();
        const confirm = useConfirm();
        const { postProdutoMedida } = usePostProdutoMedida();
        const { FormMedicamentoCompletoYupValidation } =
            useFormMedicamentoCompletoValidation();
        const { plano } = useSessaoAtual();
        const { getEmpresaAtual } = useGestaoToken();

        const isFiscal = isPlanoFiscal(plano?.plano);
        const empresaFiscal = getEmpresaAtual()?.isFiscal;

        const showConfirm = useCallback(
            (campo: string) => {
                return confirm({
                    title: `Campo "${campo}" sem valor!`,
                    description: `Deseja manter o campo "${campo}" sem valor?`,
                    cancellationText: 'Não',
                    confirmationText: 'Sim',
                });
            },
            [confirm],
        );

        const {
            handleSubmit,
            control,
            formState: { errors, touchedFields },
            getValues,
            setValue,
            reset,
        } = useForm<MedicamentoCompletoFormModel>({
            defaultValues: { ...initialValues },
            resolver: yupResolver(FormMedicamentoCompletoYupValidation),
            criteriaMode: 'all',
            mode: 'onChange',
        });

        const onSubmit = async (values: MedicamentoCompletoFormModel) => {
            // const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/

            // if (regexCaracEspeciais.test(values.infAdic)) {
            //     setError('infAdic', { type: "error", message: 'Não pode conter caracteres especiais.' })
            //     return
            // }

            // if (regexCaracEspeciais.test(values.nome)) {
            //     setError('nome', { type: "error", message: 'Não pode conter caracteres especiais.' })
            //     return
            // }

            const model = picker<MedicamentoCompletoFormModel>(
                values,
                new MedicamentoCompletoFormModel(),
            );

            if (model.vPreco !== initialValues.vPreco) {
                let confirmVPreco = true;

                if (toDecimal(model.vPreco) === 0) {
                    await showConfirm('Valor Venda').catch(() => {
                        confirmVPreco = false;
                        if (!isMobile) refInputValorVenda.current?.focus();
                        return;
                    });
                    if (!confirmVPreco) {
                        return;
                    }
                }
            }
            props.onSubmit(model);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(onSubmit)();
            },
            resetForm: () => {
                setInitialValues(new MedicamentoCompletoFormModel());
                if (!isMobile) refInputCodigo.current?.focus();
                reset();
            },
            fillForm: (model: MedicamentoCompletoFormModel) => {
                model.medida = 'UN'
                model.vCompra = toDecimal(model.vCompra);
                model.vPreco = toDecimal(model.vPreco);
                model.pLucro = calcularValorReal(
                    toDecimal(model.vPreco),
                    toDecimal(model.vCompra),
                );

                setInitialValues(model);
                reset({ ...model });
                setTimeout(() => {
                    if (!isMobile) refInputCodigo.current?.focus();
                }, 500);
            },
        }));

        const addNovaMedida = useCallback(
            async (value: string) => {
                setCarregandoExterno(true);

                const novaMedida = new ProdutoMedidaModel(
                    guidEmpty(),
                    props.contratoId,
                    props.empresaId,
                    value,
                    value,
                );
                const ret = await postProdutoMedida(novaMedida);
                if (ret.erro) {
                    throw ret.erro;
                }
                const medidaRet = ret.resultado?.data as ProdutoMedidaModel;
                setValue('medida', medidaRet.sigla);
                setValue('medidaId', medidaRet.id);
                setCarregandoExterno(false);
            },
            [
                setCarregandoExterno,
                props.contratoId,
                props.empresaId,
                postProdutoMedida,
                setValue,
            ],
        );

        const onChangeMedidaWrapper = useCallback(
            async (retorno: retornoAutoComplete) => {
                if (retorno.isNewVal) {
                    const ultimaMedida = getValues('medida');
                    try {
                        setValue('medida', retorno.value);
                        addNovaMedida(retorno.value);
                    } catch (e: any) {
                        setValue('medida', ultimaMedida);
                        setCarregandoExterno(false);
                        showToast(
                            'error',
                            'Erro ao cadastrar a medida. Tente novamente em alguns instantes. Detalhe: ' +
                            e.message,
                        );
                    }
                } else if (!retorno.isString) {
                    const medida = picker<ProdutoMedidaModel>(
                        retorno.value,
                        new ProdutoMedidaModel(),
                    );

                    setValue('medida', medida.sigla);
                    setValue('medidaId', medida.id);
                }
            },
            [addNovaMedida, getValues, setCarregandoExterno, setValue, showToast],
        );

        const onChangeCategoria = useCallback(
            async (retorno: retornoAutoComplete | null) => {
                const ultimaCategoria = getValues('nomeCategoria');
                if (!retorno) {
                    setValue('nomeCategoria', ultimaCategoria)
                    return
                }
                if (!retorno.isString) {
                    let categoria = picker<ProdutoCategoriaModel>(
                        retorno.value,
                        new ProdutoCategoriaModel()
                    );
                    setValue('categoriaId', categoria.id);
                    setValue('nomeCategoria', categoria.nome);
                }
            }, [getValues, setValue]);

        const calcularValorReal = useCallback((vPreco: number, vCompra: number) => {
            const pLucro = ((vPreco - vCompra) / vCompra) * 100;

            return toDecimal(isNaN(pLucro) ? 0 : pLucro);
        }, []);

        const calcularValorFinal = useCallback(
            (vCompra: number, pLucro: number) => {
                return toDecimal((vCompra * pLucro) / 100 + vCompra);
            },
            [],
        );

        const calcularValores = useCallback(
            (event: any) => {
                setValue(event.target.name, event.target.value);
                const vCompra = toDecimal(
                    event.target.name === 'vCompra'
                        ? event.target.value
                        : getValues('vCompra'),
                );
                const vPreco = toDecimal(
                    event.target.name === 'vPreco'
                        ? event.target.value
                        : getValues('vPreco'),
                );
                const pLucro = toDecimal(
                    event.target.name === 'pLucro'
                        ? event.target.value
                        : getValues('pLucro'),
                );

                if (event.target.name === 'pLucro') {
                    setValue('vPreco', calcularValorFinal(vCompra, pLucro));
                } else {
                    setValue('pLucro', calcularValorReal(vPreco, vCompra));
                }
            },
            [calcularValorFinal, calcularValorReal, getValues, setValue],
        );

        return (
            <>
                <div className={utilClasses.formContainer}>
                    {loading && props.showLoading ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="NORMAL" />
                        </div>
                    ) : null}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={loading ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={2}>
                            <Grid item container justifyContent="center" xs={12}>
                                <Box my={props.imageSize === 'small' ? 2 : 5}>
                                    <Controller
                                        name="imagemUrl"
                                        control={control}
                                        render={({ field }) => (
                                            <ImagemInput
                                                disabled={loading}
                                                loadErrorImage={SemImagem}
                                                imageRadius="5%"
                                                width={isMobile ? '150px' : (props.imageSize === 'small' ? '150px' : '220px')}
                                                height={isMobile ? '150px' : (props.imageSize === 'small' ? '150px' : '220px')}
                                                accept="image/*"
                                                error={Boolean(
                                                    errors.imagemUrl && errors.imagemUrl.message,
                                                )}
                                                helperText={
                                                    errors.imagemUrl
                                                        ? errors.imagemUrl?.message
                                                        : undefined
                                                }
                                                {...field}
                                                value={getValues('imagemUrl')}
                                                onChange={({ base64 }: any) => {
                                                    setValue('imagemUrl', base64);
                                                }}
                                                onBlur={() => {
                                                    setValue('imagemUrl', '');
                                                }}
                                                semImagemRepresentante
                                                mode='contain'
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="nome"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label={`Nome do Medicamento`}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={`Informe o nome do Medicamento`}
                                            error={Boolean(errors.nome && errors.nome.message)}
                                            helperText={
                                                errors.nome
                                                    ? errors.nome?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="infAdic"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            id="outlined-textarea"
                                            allowSubmit
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Descrição"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder='Limite de 400 caracteres'
                                            error={Boolean(errors.infAdic && errors.infAdic.message)}
                                            helperText={
                                                errors.infAdic
                                                    ? errors.infAdic?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="nomeCategoria"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompleteProdutoCategorias
                                            disabled={loading}
                                            modo="categoria"
                                            placeholder="Ex: Eletrônicos"
                                            loadingExterno={loading}
                                            label="Categoria"
                                            error={Boolean(
                                                errors.nomeCategoria && errors.nomeCategoria.message,
                                            )}
                                            helperText={
                                                errors.nomeCategoria
                                                    ? errors.nomeCategoria?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={onChangeCategoria}
                                            permiteAdicionar
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="medida"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompleteMedidas
                                            disabled={loading}
                                            loadingExterno={loading}
                                            label={isMobile ? "Medida" : 'Medida de Venda'}
                                            error={Boolean(errors.medida && errors.medida.message)}
                                            helperText={
                                                errors.medida
                                                    ? errors.medida?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={onChangeMedidaWrapper}
                                            value={getValues('medida')}
                                            permiteAdicionar
                                        />
                                    )}
                                />
                            </Grid>
                            {(isFiscal && empresaFiscal) && <>
                                <Grid item xs={6}>
                                    <Controller
                                        name="codigoNcm"
                                        control={control}
                                        render={({ field }) => (
                                            <AutocompleteNcmsGestao
                                                disabled={loading}
                                                allowSubmit
                                                modo="campoFormatado"
                                                loadingExterno={loading}
                                                label="NCM do Produto"
                                                placeholder="Ex: 84109000"
                                                error={Boolean(
                                                    errors.codigoNcm && errors.codigoNcm.message,
                                                )}
                                                helperText={
                                                    errors.codigoNcm
                                                        ? errors.codigoNcm?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(retorno) => {
                                                    if (!retorno.isString) {
                                                        const ncm = picker<NcmModel>(
                                                            retorno.value,
                                                            new NcmModel(),
                                                        );
                                                        setValue('codigoNcm', ncm.codigo);
                                                    }
                                                }}
                                                value={getValues('codigoNcm')}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="imposto"
                                        control={control}
                                        render={({ field }) => (
                                            <AutocompleteGrupoImpostos
                                                allowSubmit
                                                loadingExterno={loading}
                                                label="Grupo de Imposto"
                                                placeholder="-Selecione-"
                                                error={Boolean(
                                                    errors.imposto && errors.imposto.message,
                                                )}
                                                helperText={
                                                    errors.imposto
                                                        ? errors.imposto?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(retorno) => {
                                                    if (!retorno.isString) {
                                                        const imposto = picker<ProdutoFiscalModel>(
                                                            retorno.value,
                                                            new ProdutoFiscalModel(),
                                                        );
                                                        setValue('imposto', imposto.descricao);
                                                        setValue('impostoId', imposto.id);
                                                    }
                                                }}
                                                value={getValues('imposto')}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={4}>
                                <Controller
                                    name="vCompra"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="DECIMAL"
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            showStartAdornment
                                            label="Valor Compra"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            endAdornmentButton={
                                                <Tooltip
                                                    title={
                                                        <Typography
                                                            className={classes.tooltipText}
                                                            align="center"
                                                        >
                                                            Preço Fabricação:
                                                            {getValues('precoFabricacao') ? (
                                                                ' R$ ' + toDecimalString(
                                                                    getValues('precoFabricacao')
                                                                )
                                                            ) : ' Não encontrado'}
                                                        </Typography>
                                                    }
                                                >
                                                    <div>
                                                        <InformacaoIcon
                                                            tipo="GERAL"
                                                            class={classes.infoIcon}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            }
                                            inputProps={{ maxLength: 12 }}
                                            placeholder=""
                                            error={Boolean(errors.vCompra && errors.vCompra.message)}
                                            helperText={
                                                touchedFields.vCompra || errors.vCompra
                                                    ? errors.vCompra?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => calcularValores(event)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="pLucro"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="DECIMAL"
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label="Margem de Lucro"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder=""
                                            error={Boolean(errors.pLucro && errors.pLucro.message)}
                                            helperText={
                                                touchedFields.pLucro || errors.pLucro
                                                    ? errors.pLucro?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => calcularValores(event)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="vPreco"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            inputRef={refInputValorVenda}
                                            tipo="DECIMAL"
                                            manterMascara
                                            disabled={loading}
                                            showStartAdornment
                                            fullWidth
                                            endAdornmentButton={
                                                <Tooltip
                                                    title={
                                                        <Typography
                                                            className={classes.tooltipText}
                                                            align="center"
                                                        >
                                                            Preço Máximo Consumidor:
                                                            {getValues('precoMaximoConsumidor') ? (
                                                                ' R$ ' + toDecimalString(
                                                                    getValues('precoMaximoConsumidor')
                                                                )
                                                            ) : ' Não encontrado'}
                                                        </Typography>
                                                    }
                                                >
                                                    <div>
                                                        <InformacaoIcon
                                                            tipo="GERAL"
                                                            class={classes.infoIcon}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            }
                                            variant="outlined"
                                            label="Valor Venda"
                                            inputProps={{ maxLength: 12 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder=""
                                            error={Boolean(errors.vPreco && errors.vPreco.message)}
                                            helperText={
                                                errors.vPreco
                                                    ? errors.vPreco?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => calcularValores(event)}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </>
        );
    },
);  