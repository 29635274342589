import { Button, Card, Grid, Typography } from "@material-ui/core"
import { useThemeQueries } from "views/theme"
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles"
import { ConfiguracoesHeader } from "./components/adicionar-empresa-header/configuracoes-header"
import { useStyles } from './configuracoes-styles'
import { useToastSaurus } from "services/app"
import { useCallback, useEffect, useRef, useState } from "react"
import { useGetEmpresaId, usePutEmpresa } from "data/api/gestao/empresa"
import { EmpresaModel } from "model"
import { useParams } from "react-router-dom"
import { CircularLoading } from "views/components"
import { picker } from "utils/picker"
import { EmpresaEditFormModel } from "model/app/forms/master/empresa-edit-form-model"
import { FormEmpresaEdit, FormEmpresaEditRef, InpuEmpresaConfigEdit } from "views/components/form/master/empresa-edit/form-empresa-edit"
import { toDateString } from "utils/to-date"
import { isEqual } from "lodash"
import { AccordionConfiguracoesNfce } from "../../configuracoes/components/accordion-configuracoes-nfce/accordion-configuraces-nfce"
import { AccordionSAT } from "../../configuracoes/components/accordion-sat/accordion-sat"
import { isPlanoFiscal } from "utils/plano-utils"
import { AccordionConfiguracoesEmpresaa } from "../../configuracoes/components/accordion-configuracoes-empresa/accordion-configuracoes-empresa"
import { HorariosFuncionamento } from "../../configuracoes/components/horarios-funcionamento/horarios-funcionamento"
import { useSessaoAtual } from 'services/app'
import { SalvarEditarIcon } from "views/components/icons"
import { useEventTools } from "services/app/hooks/events/event-tools"
import { AppEventEnum } from "model/enums/enum-app-event"

const ConfiguracoesEmpresaPage = () => {

    const classes = useDefaultCadastroStyles()
    const classesAdd = useStyles()
    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus()
    const { usuario, plano, refreshEmpresa, getEmpresaSelecionada } = useSessaoAtual()
    const isFiscal = isPlanoFiscal(plano?.plano);
    const { addHandler, removeHandler } = useEventTools();

    const refEmpresaModel = useRef<EmpresaModel>(new EmpresaModel());
    const empresaFormRef = useRef<FormEmpresaEditRef>(null);
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const [empresaFormState, setEmpresaFormState] =
        useState<EmpresaEditFormModel>(new EmpresaEditFormModel());

    const { id } = useParams<{ id: string }>()

    const { getEmpresaId, carregando: carregandoGetEmpresa } = useGetEmpresaId();
    const { putEmpresa, carregando: carregandoPutEmpresa } = usePutEmpresa();

    const carregando = carregandoGetEmpresa || preenchendoTela || carregandoPutEmpresa

    const empresaId = usuario!.empresa.find(x => x.Id === id);

    const getEmpresaByIdWrapper = useCallback(async () => {

        const res = await getEmpresaId(empresaId!.Id);
        if (res.erro) {
            throw res.erro;
        }
        const ret = res.resultado?.data as EmpresaModel;

        refEmpresaModel.current = ret;

        const empresa = picker<EmpresaEditFormModel>(
            ret,
            new EmpresaEditFormModel(),
        );
        return empresa;
    }, [empresaId, getEmpresaId]);

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                try {
                    setPreenchendoTela(true);
                    const empresa = await getEmpresaByIdWrapper();
                    setEmpresaFormState(empresa);
                    setPreenchendoTela(false);
                } catch (e: any) {
                    showToast('error', e.message);
                    setPreenchendoTela(false);
                }
            })();
            return () => {
                //funcao de limpeza
                setEmpresaFormState(new EmpresaEditFormModel());
            };
        }, 300);
    }, [getEmpresaByIdWrapper, showToast]);

    const recarregarForm = useCallback((model: EmpresaEditFormModel) => {
        empresaFormRef.current?.fillForm(model);
    }, []);

    useEffect(() => {
        recarregarForm(empresaFormState);
    }, [empresaFormState, recarregarForm]);

    const saveChangesEmpresa = useCallback(
        async (empresaModel: EmpresaEditFormModel) => {
            const empresa = picker<EmpresaModel>(
                empresaModel,
                refEmpresaModel.current,
            );
            empresa.dNascimento = toDateString(
                empresaModel.dNascimento,
                'yyyy-MM-DDTHH:mm:ss',
            );

            try {
                const ret = await putEmpresa(empresa);
                if (ret.erro) {
                    throw ret.erro;
                }
                if ((getEmpresaSelecionada()?.Id || '') === empresa.id) {
                    refreshEmpresa()
                }

                showToast('success', 'Dados da empresa alterados com sucesso!');
                setEmpresaFormState(empresaModel);
            } catch (e: any) {
                showToast('error', e.message);
                empresaFormRef.current?.resetForm();
            }
        },
        [getEmpresaSelecionada, putEmpresa, refreshEmpresa, showToast],
    );

    const handleSubmit = useCallback(
        async (model: EmpresaEditFormModel, beforeModel: EmpresaEditFormModel) => {
            let empresaEqual = isEqual(model, beforeModel);
            if (!empresaEqual) {
                saveChangesEmpresa(model);
            }

            if (empresaEqual) {
                showToast('info', 'Nenhuma informação foi alterada');
            }
        },
        [saveChangesEmpresa, showToast],
    );

    useEffect(() => {
        const openAccordion = ({ focusField = 'uf' }: { focusField?: InpuEmpresaConfigEdit }) => {
            setTimeout(() => {
                empresaFormRef.current?.focusField(focusField);
            }, 500)
        }
        addHandler(AppEventEnum.AbrirConfiguracoesEmpresa, openAccordion);
        return () => {
            removeHandler(AppEventEnum.AbrirConfiguracoesEmpresa, openAccordion);
        }
    }, [addHandler, removeHandler])

    return (
        <Grid className={classes.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <Grid className={classes.header}>
                <ConfiguracoesHeader title={`Empresa: ${empresaId?.Descricao || ''}`} />
            </Grid>
            <Grid
                className={classes.defaultContainer}
                container
                alignContent="flex-start"
                justifyContent="center"
                style={{ padding: theme.spacing(1) }}
            >
                <Grid
                    container
                    spacing={1}
                    style={{ maxWidth: 1200, paddingTop: 16 }}
                    className={classesAdd.containerGrid}
                >
                    <Grid className={classesAdd.cardForm}>
                        <Card style={{ padding: 16 }}>
                            <Typography variant="h6" style={{ marginBottom: 16 }}>Dados da Empresa</Typography>
                            <FormEmpresaEdit
                                ref={empresaFormRef}
                                loading={false}
                                setCarregandoExterno={() => false}
                                onSubmit={handleSubmit}
                                showLoading={false}
                            />
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                style={{ marginTop: 16 }}
                                onClick={() => empresaFormRef.current?.submitForm()}
                                fullWidth
                            >
                                <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                Salvar configurações
                            </Button>
                        </Card>
                    </Grid>
                    <div style={{ margin: '8px 0' }}>
                        <HorariosFuncionamento empresaId={empresaId!.Id} hasEmpresaId={true} />
                    </div>
                    <div style={{ margin: '8px 0' }}>
                        <AccordionConfiguracoesEmpresaa id={empresaId!.Id} />
                    </div>
                    {isFiscal && (
                        <div style={{ margin: '8px 0' }}>
                            <AccordionConfiguracoesNfce documento={empresaId!.Documento} hasId={true} />
                        </div>
                    )}
                    {refEmpresaModel.current.uf === "SP" && isFiscal && (
                        <div>
                            <AccordionSAT cnpj={empresaId?.Documento} hasCnpj={true} />
                        </div>
                    )
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConfiguracoesEmpresaPage