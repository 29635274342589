import { KeyValueModel } from "model";
import { EnumHistoricoVendaStatus } from "model/enums/enum-historico-venda-status";

export const StatusHistoricoVendaMock: KeyValueModel[] = [
    new KeyValueModel(EnumHistoricoVendaStatus.EmDigitacao, "Digitado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Cancelado, "Cancelado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Finalizado, "Finalizado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Pendente, "Pendente"),
    new KeyValueModel(EnumHistoricoVendaStatus.Abortado, "Abortado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Rejeitado, "Rejeitado"),
];

export const StatusHistoricoVendaMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumHistoricoVendaStatus.EmDigitacao, "Digitado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Cancelado, "Cancelado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Finalizado, "Finalizado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Pendente, "Pendente"),
    new KeyValueModel(EnumHistoricoVendaStatus.Abortado, "Abortado"),
    new KeyValueModel(EnumHistoricoVendaStatus.Rejeitado, "Rejeitado"),
];