import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePostProcessosIniciar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postProcessosIniciar = useCallback(
        (empresaId: string, setorId: string, salaoId: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/setor/${setorId}/processo/salao/${salaoId}/iniciar`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        postProcessosIniciar,
    };
}