import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const NotaFiscalIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M47 3.85759C47 3.09217 46.8644 2.84614 46.0509 2.87348C39.0007 2.90082 31.9776 2.90082 24.9274 2.90082C17.85 2.90082 10.7998 2.90082 3.72248 2.87348C3.04457 2.87348 2.85476 3.0375 2.88187 3.72091C2.90899 17.9085 2.90899 32.096 2.88187 46.2836C2.88187 46.9123 3.01746 47.1037 3.66825 47.1037C14.1894 47.0764 24.6833 47.0764 35.2044 47.1037C35.7197 47.1037 36.0451 46.967 36.3976 46.6117C39.7329 43.2493 43.0682 39.9142 46.4035 36.5792C46.8373 36.1418 47 35.7044 47 35.0757C46.9729 24.6606 46.9729 14.2727 47 3.85759ZM36.506 43.304C36.506 41.1444 36.506 39.0395 36.506 36.9072C36.506 36.6886 36.5603 36.5519 36.8314 36.5519C38.9465 36.5519 41.0616 36.5519 43.2037 36.5519C40.9531 38.8208 38.7838 41.0077 36.506 43.304ZM44.0443 34.4743C41.0616 34.447 38.0788 34.4743 35.096 34.447C34.5537 34.447 34.3638 34.5563 34.3638 35.1577C34.391 38.1921 34.3638 41.1991 34.391 44.2334C34.391 44.8348 34.2825 45.0261 33.6317 45.0261C24.3037 44.9988 15.0028 44.9988 5.67485 45.0261C5.02406 45.0261 4.94271 44.8074 4.94271 44.2334C4.96983 31.4126 4.96983 18.5919 4.94271 5.77113C4.94271 5.1424 5.13252 5.00572 5.72908 5.00572C12.1285 5.03305 18.528 5.00572 24.9003 5.00572C31.2726 5.00572 37.6449 5.00572 43.9901 4.97838C44.7223 4.97838 44.8849 5.16973 44.8849 5.88048C44.8578 15.1475 44.8578 24.3872 44.8849 33.6542C44.9121 34.3376 44.6951 34.4743 44.0443 34.4743Z" />
                <path d="M25.497 20.7788C29.239 20.7788 32.9811 20.7788 36.696 20.7788C37.2925 20.7788 37.5637 20.8608 37.5366 21.5716C37.4824 22.911 37.5366 22.911 36.2079 22.911C28.8594 22.911 21.538 22.911 14.1895 22.9384C13.4574 22.9384 13.2675 22.747 13.3218 22.0363C13.376 20.8061 13.3489 20.8061 14.5962 20.8061C18.2569 20.7788 21.8634 20.7788 25.497 20.7788Z" />
                <path d="M25.4155 29.1984C21.6734 29.1984 17.9314 29.1711 14.2165 29.2257C13.5657 29.2257 13.2403 29.0891 13.3487 28.3783C13.3759 28.187 13.3759 27.9956 13.3487 27.8042C13.2674 27.2302 13.4843 27.0662 14.0538 27.0662C21.6463 27.0935 29.2117 27.0935 36.8043 27.0662C37.428 27.0662 37.5636 27.2575 37.5636 27.8589C37.5364 29.1984 37.5636 29.1984 36.2349 29.1984C32.6284 29.1984 29.0219 29.1984 25.4155 29.1984Z" />
                <path d="M25.4968 14.4641C29.2388 14.4641 32.9537 14.4641 36.6958 14.4641C37.2923 14.4641 37.5635 14.5461 37.5364 15.2569C37.4821 16.5963 37.5364 16.5963 36.2077 16.5963C28.8863 16.5963 21.5378 16.5963 14.2164 16.6237C13.4843 16.6237 13.2944 16.4323 13.3216 15.7216C13.4029 14.3001 13.1589 14.5188 14.5147 14.4914C18.2025 14.4641 21.8361 14.4641 25.4968 14.4641Z" />
            </DefaultIcon>
        </>
    );
};