import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { DepositoModel } from "model/api/gestao/deposito/deposito-model";
import { CardDeposito } from "views/components/cards/card-deposito";

export interface DepositoGridProps {
    list: Array<DepositoModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const DepositoListData = ((props: DepositoGridProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma depósito encontrada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((deposito, index) => {
                return (
                    <CardDeposito
                        selected={
                            props.selectedList.filter((item) => item === deposito.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        model={deposito}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
