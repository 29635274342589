import { Button, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { KeyValueModel } from 'model';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros } from 'services/app';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';

interface DialogSelecionarSalaoProps {
  close: () => void;
  aberto: boolean;
}

export const DialogSelecionarSalao = ({
  close,
  aberto,
}: DialogSelecionarSalaoProps) => {

  const [saloesMock, setSaloesMock] = useState<KeyValueModel[]>([])
  const [salao, setSalao] = useState<string>('')
  const { abrirCadastroBarCodeCam } = useCadastros()

  const {
    getSaloesCadastrados,
  } = usePedidoLocal();

  const getSaloes = useCallback(async () => {
    const res = await getSaloesCadastrados()

    setSaloesMock(res.map(x => new KeyValueModel(x.id, x.descricao)))
  }, [getSaloesCadastrados])

  useEffect(() => {
    getSaloes()
  }, [getSaloes])

  const handleClick = useCallback(() => {
    abrirCadastroBarCodeCam(salao)
    close()
  }, [abrirCadastroBarCodeCam, close, salao])

  return (
    <DialogSaurus
      aberto={aberto || false}
      isButtonTitleClose
      handleClickClose={close}
      titulo="Selecionar Salão"
      tamanho="xs"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectSaurus
            conteudo={saloesMock}
            onChange={ev => {
              setSalao(ev.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={handleClick} variant="contained" color="primary" size="large" disabled={isEmpty(salao)}>Continuar</Button>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
