import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const Upload = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1972 23.4695C21.1972 20.7859 21.182 18.0871 21.2124 15.4035C21.2276 14.8122 21.0148 14.7212 20.4978 14.7364C18.0955 14.7667 15.7084 14.7515 13.3061 14.7364C13.1389 14.7364 12.8956 14.8425 12.8196 14.6302C12.7436 14.4331 12.9869 14.3422 13.1085 14.2209C17.1072 10.2333 21.106 6.2458 25.0895 2.2431C25.4392 1.89438 25.6065 1.93987 25.9258 2.25826C29.9093 6.26096 33.9233 10.2333 37.9068 14.236C38.0285 14.3573 38.2717 14.4483 38.1653 14.6454C38.0741 14.8273 37.846 14.7212 37.6635 14.7212C35.2461 14.7212 32.8286 14.7364 30.4111 14.706C29.9093 14.706 29.7725 14.8425 29.7877 15.3428C29.8029 20.7252 29.8029 26.1077 29.8029 31.4901C29.8029 32.1723 29.6965 32.794 29.2099 33.3246C28.7386 33.825 28.1608 34.0221 27.4918 34.0372C26.169 34.0372 24.8463 34.0524 23.5387 34.0372C22.0639 34.0221 21.1972 33.1427 21.1972 31.672C21.1972 28.9277 21.1972 26.1986 21.1972 23.4695Z"
            fill={props.fill}
          />
          <path
            d="M25.4696 44.7263C18.5516 44.7263 11.6336 44.7263 4.71564 44.7414C4.18348 44.7414 3.98583 44.6353 4.00103 44.0591C4.04664 43.0281 4.03144 41.982 4.00103 40.951C3.98583 40.5416 4.13787 40.4052 4.51798 40.4506C4.60921 40.4658 4.71564 40.4506 4.82207 40.4506C18.6124 40.4506 32.418 40.4506 46.2084 40.4506C46.9838 40.4506 46.9838 40.4507 46.9838 41.2087C46.9838 42.1791 46.9534 43.1646 46.999 44.135C47.0142 44.6201 46.8621 44.7263 46.3908 44.7263C39.4272 44.7263 32.4484 44.7263 25.4696 44.7263Z"
            fill={props.fill}
          />
        </svg>
      </DefaultIcon>
    </>
  );
};
