import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const CloseIconAlt = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
        onClick={props.onClick}
      >
        <path d="M13.7 13.7L36.4 36.3" stroke={props.fill} stroke-width="2" stroke-miterlimit="10" />
        <path d="M36.4 13.7L13.7 36.3" stroke={props.fill} stroke-width="2" stroke-miterlimit="10" />
      </DefaultIcon>
    </>
  );
};
