import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        background: "#fff",
        height: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        fontSize: '22px',
        lineHeight: '30px'
    },
    inputArea: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 260,
        maxWidth: 395,
        borderBottom: '2px solid#D8D8D8',
    },
    inputText: {
        fontSize: '38px',
        lineHeight: '50px',
        fontWeight: 600
    },
    placeholder: {
        fontSize: '16px',
        lineHeight: '50px',
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    buscaCadastrados: {
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '42px',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    }
}));