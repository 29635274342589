import { Box, Button, Typography } from "@material-ui/core"
import classNames from "classnames"
import { OkIcon } from "views/components/icons"
import { ButtonSituacaoProps } from "./button-situacao-props"
import { useStyles } from "./button-situacao-styles"

export const ButtonSituacao = (props: ButtonSituacaoProps) => {
    const { statusDestaque, onClick, status, titulo } = props
    const classes = useStyles()

    return (
        <Button
            fullWidth
            variant="contained"
            color="default"
            className={classNames(
                classes.button,
                statusDestaque === status ? classes.btnDestaque : ''
            )}
            onClick={() => onClick(status)}
        >
            <Box className={statusDestaque === status ? "" : classes.cards}>
                <props.icon tipo="BUTTON" class={classes.icon} />
                <Typography className={classes.title}>
                    {titulo}
                </Typography>
            </Box>
            {statusDestaque === status && (
                <OkIcon class={classes.iconDestaque} />
            )}
        </Button>
    )
}