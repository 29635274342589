import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ImpressoraModel } from "model/api/gestao/impressora/impressora-model";
import { VariaveisAmbiente } from "config";

export function usePutEquipamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putEquipamento = useCallback(
    (equipamento: ImpressoraModel, empresaId: string, equipamentoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/equipamento/${equipamentoId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(equipamento),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putEquipamento,
  };
}
