import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@material-ui/core';
import { SetorEProcessoEditFormModel } from 'model/app/forms/setor/setor-edit-form-model';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { useFormSetorEditValidation } from './form-setor-edit-validation';

export const FormSetorEdit = forwardRef<
  DefaultFormRefs<SetorEProcessoEditFormModel>,
  DefaultFormProps<SetorEProcessoEditFormModel>
>(({ loading, ...props }: DefaultFormProps<SetorEProcessoEditFormModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNumSetor = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<SetorEProcessoEditFormModel>(
    new SetorEProcessoEditFormModel(),
  );
  const { FormSetorEditValidationYup } = useFormSetorEditValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<SetorEProcessoEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormSetorEditValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: SetorEProcessoEditFormModel) => {
    const setor = picker<SetorEProcessoEditFormModel>(values, new SetorEProcessoEditFormModel());
    props.onSubmit({ ...setor }, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...modelForm });
      refInputNumSetor.current?.focus();
    },
    fillForm: (model: SetorEProcessoEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      setTimeout(() => {
        refInputNumSetor.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'TEXTO'}
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 70 }}
                      placeholder="Digite o nome"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
