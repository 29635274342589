import { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { PublicPageHeader } from "views/components/headers";
import { CircularLoading } from "views";
import { useStyles } from "./convite-page-styles";
import { useToastSaurus } from "services/app";
import { makeUtilClasses } from '../../../theme/util-make-styles';
import { useConfirm } from "material-ui-confirm";
import { FormConvite } from "views/components/form/master/convite/form-convite";
import { DefaultFormRefs } from "views/components/form/utils";
import { ConviteFormModel } from "model/app/forms/master/convite-form-model";
import { picker } from "utils/picker";
import { usePostIdentidadeConfirmarUsuario } from "data/api/gestao/identidade/post-confirmar-usuario";
import { isEmpty } from "lodash";
import { OkIcon } from "views/components/icons";
import { RemoveIcon } from "views/components/icons/remove-icon";

const ConvitePage = () => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { push: historyPush } = useHistory();
    const confirm = useConfirm();
    const search = useLocation().search;
    const { postIdentidadeConfirmarUsuario, carregando } = usePostIdentidadeConfirmarUsuario();
    const conviteRefs =
        useRef<DefaultFormRefs<ConviteFormModel>>(null);
    const redirect = useRef<boolean>(true);

    const pegarParams = new URLSearchParams(search).get('chave');

    const decodarChave = atob(pegarParams || '').split('|')

    const [id, email, telefone] = decodarChave

    useEffect(() => {
        if (conviteRefs)
            conviteRefs.current?.fillForm(new ConviteFormModel('', ''));
    }, []);

    const atualizarNovoUsuario = useCallback(
        async (model: ConviteFormModel) => {
            try {
                const ret = await postIdentidadeConfirmarUsuario(model);
                if (ret.erro) {
                    throw ret.erro;
                }
                showToast("success", "Convite enviado com sucesso!");

                historyPush('/convite/concluido')

                if (!redirect.current) {
                    conviteRefs.current?.resetForm();
                }
            } catch (e: any) {
                showToast("error", e.message);
                conviteRefs.current?.fillForm(model);
            }
        },
        [historyPush, postIdentidadeConfirmarUsuario, showToast]
    )

    const handleSubmit = useCallback(
        async (modelP: ConviteFormModel) => {
            const conviteToUpdate = picker<ConviteFormModel>(modelP, new ConviteFormModel());
            conviteToUpdate.id = id
            conviteToUpdate.email = email
            return atualizarNovoUsuario(conviteToUpdate);
        },
        [atualizarNovoUsuario, email, id]
    )

    const rejeitarConvite = useCallback(() => {
        confirm({
            title: "Deseja recusar o convite?",
            cancellationText: "Recusar",
            confirmationText: "Continuar",
        }).catch(() => {
            historyPush('/')
        });
    }, [confirm, historyPush]
    );

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>
                    {
                        carregando &&
                        <CircularLoading tipo="FULLSIZED" />
                    }
                    <Grid item xs={12}>
                        <Grid container alignItems="center" direction="column">
                            <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h3" color="primary">
                                    Aceitar Convite
                                </Typography>
                                <Box mt={3} mb={3}>
                                    <Typography align="center" variant="body1" >
                                        Informe sua senha de acesso para confirmar seu convite
                                    </Typography>
                                </Box>
                                <Box mt={3} mb={3}>
                                    <Typography align="center" variant="body1" >
                                        {!isEmpty(email) ?
                                            email : telefone
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} container>
                                <FormConvite
                                    ref={conviteRefs}
                                    loading={carregando}
                                    showLoading={false}
                                    onSubmit={handleSubmit}
                                />
                                <Box mt={2} width="100%">
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={carregando}
                                            className={classes.button}
                                            onClick={() => conviteRefs.current?.submitForm()}
                                        >
                                            <OkIcon tipo="BUTTON_PRIMARY" />
                                            Aceitar Convite
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.button}
                                            fullWidth
                                            disabled={carregando}
                                            onClick={rejeitarConvite}
                                        >
                                            <RemoveIcon tipo="BUTTON" />
                                            Recusar Convite
                                        </Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default ConvitePage;