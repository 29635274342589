import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export enum LocalErroEnum
{
    Emitente,
    Destinatario,
    Transportadora,
    Produto,
    NotaImportada,
    Ambiente,
    EmpresaLogada
}

export class ValidacaoRetornoModel{
    constructor(
        public local: LocalErroEnum = LocalErroEnum.Ambiente,
        public mensagem: string = '',
        public obrigatorio: boolean = false
    ){}
}

export function usePostValidarEntradaXML() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postValidarEntradaXML = useCallback(
        (id: string, empresaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Entrada/${id}/validar`,
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postValidarEntradaXML,
    };
}