import * as Yup from "yup";
import { useMemo } from "react";
import { useSessaoAtual } from "services/app";
import { isPlanoFiscal } from "utils/plano-utils";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";

export const useFormMedicamentoCompletoValidation = () => {

    const { plano } = useSessaoAtual();
    const { getEmpresaAtual } = useEmpresaAtual();
    const isFiscal = isPlanoFiscal(plano?.plano);
    const empresaFiscal = getEmpresaAtual()?.isFiscal

    const validarFiscal = isFiscal && empresaFiscal;

    const FormMedicamentoCompletoYupValidation = useMemo(() => {
        return Yup.object().shape({
            nome: Yup.string().required('Nome é obrigatório.').typeError('Nome é obrigatório'),
            precoMaximoConsumidor: Yup.number().required('Informe o PMC').typeError('Informe o PMC'),
            imposto: validarFiscal ? Yup.string().required("Nos informe o grupo de imposto.") : Yup.string().notRequired(),
            codigoNcm: validarFiscal ? Yup.string().required("Nos informe o código NCM.") : Yup.string().notRequired(),
            vCompra: Yup.number().required('Informe o valor de compra').typeError('Informe o valor de compra'),
            vPreco: Yup.number().required('Informe o preço').required('Informe o preço'),
        });
    }, [validarFiscal]);

    return {
        FormMedicamentoCompletoYupValidation,
    };
};