export class FormStep {
  constructor(
    public formTitle: string,
    public formDesc: string,
    public formTitleIcon: JSX.Element,
    public stepLabel: string | null,
    public stepIcon: JSX.Element,
    public form: JSX.Element,
    public previousButton: JSX.Element | undefined,
    public nextButton: JSX.Element | undefined,
    public id?: number | undefined,
    public habilitaModulo?: boolean | null 
  ) {}
}

export class SimpleFormStep {
  constructor(
    public formTitle: string,
    public stepLabel: string,
    public stepIcon: JSX.Element
  ) {}
}
