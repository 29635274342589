import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetRetiradaDocumento() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getRetiradaDocumento = useCallback(
    (documento: string, queryBusca: string, page?: number) => {
      let queryPage = "";
      if (page) {
        queryPage = "page=" + page.toString();
      }
      let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
        }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documento}/retirada${queryFinal}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    getRetiradaDocumento,
  };
}
