import classNames from "classnames";
import Carousel, { ReactElasticCarouselProps } from "react-elastic-carousel";
import { useStyles } from "./carousel-saurus-styles";

interface CarouselSaurusProps extends ReactElasticCarouselProps {
  children: React.ReactNode;
  isEscuro: boolean;
  fullScreen: boolean;
  noMinHeight?: boolean;
  style?: React.CSSProperties;
}

export const CarouselSaurus = ({ isEscuro, fullScreen, noMinHeight, ...props }: CarouselSaurusProps) => {
  const classes = useStyles();
  return (
    <div className={classNames(fullScreen ? classes.carrouselFullscreen : classes.carrouselNormal, !noMinHeight ? classes.carrouselContainer : classes.carrouselContainerNoMinHeight, isEscuro ? classes.carrouselContainerEscuro : classes.carrouselContainerClaro)}>
      <Carousel {...props}>{props.children}</Carousel>
    </div>
  );
};

