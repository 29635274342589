import { isEmpty } from 'lodash';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { toDecimal } from 'utils/to-decimal';
import { CircularLoading } from 'views';
import MovProdAvulsoFragment from './components/mov-prod-avulso-fragment';
import { consoleDev } from 'utils/console-dev';

const MovProdAvulsoPage = () => {
  consoleDev('MovProdAvulsoPage');

  const { getUltimoProduto, retornaFluxoVenda } = useMovAtual();
  const { inserirProdutoAvulso, alterarProdutoAvulso } = useMovProd();
  const { showToast } = useToastSaurus();
  const [produtoAvulso, setProdutoAvulso] = useState<
    ProdutoResumidoModel | undefined
  >();
  const { getProdutoAvulso, carregando } = useCadastroPadrao();
  const { push: historyPush } = useHistory();
  const adicionarProximo = useRef<boolean>(false);
  const { addHandler, removeHandler } = useEventTools();


  const produtoAlterado = React.useCallback(
    (produto: MovSimplesProdutoModel) => {
      if (!produto) return;
      if (!produtoAvulso) return;

      if (produto.produtoGradeId === produtoAvulso.produtoGradeId) {
        produtoAvulso.grupoImpostoId = produto.grupoImpostoId;
        produtoAvulso.ncmId = produto.ncmId;
        produtoAvulso.ncm = produto.ncm;
        setProdutoAvulso(produtoAvulso);
      }
    },
    [produtoAvulso],
  );

  useEffect(() => {
    addHandler(AppEventEnum.ProdutoAlterado, produtoAlterado);
    return () => {
      removeHandler(AppEventEnum.ProdutoAlterado, produtoAlterado);
    };
  }, [addHandler, produtoAlterado, removeHandler]);

  //EFFECT INICIAL
  useEffect(() => {
    (async () => {
      try {
        const res = await getProdutoAvulso()

        if (isEmpty(res)) throw new Error("Produto avulso não localizado.")

        setProdutoAvulso(res)

      } catch (e: any) {
        showToast(
          'error',
          'Erro ao Localizar o Produto Avulso. Detalhe: ' + e.message,
        );
        historyPush('/venda-simples/landing');
      }
    })();
  }, [getProdutoAvulso, historyPush, showToast]);

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        const avulso = await getUltimoProduto(produtoAvulso?.produtoGradeId!);
        if (avulso && !adicionarProximo.current) {
          await alterarProdutoAvulso(toDecimal(formattedText, 2));
        } else {
          await inserirProdutoAvulso(toDecimal(formattedText, 2));
          adicionarProximo.current = false;
        }
        return true;
      } catch (e: any) {
        showToast(
          'error',
          'Erro ao inserir o valor avulso. Detalhe: ' + e.message,
        );
        return false;
      }
    },
    [alterarProdutoAvulso, getUltimoProduto, inserirProdutoAvulso, produtoAvulso?.produtoGradeId, showToast],
  );

  const submit = useCallback(async () => {
    try {
      const ret = await retornaFluxoVenda();
      if (ret.error) {
        showToast('error', ret.msg);
      }
      historyPush(ret.url);
      return true;

    } catch (e: any) {
      showToast('error', e.message);
      return false;
    }
  }, [historyPush, retornaFluxoVenda, showToast]);

  const add = useCallback(async () => {
    try {
      adicionarProximo.current = true;
      return true;
    } catch (e: any) {
      showToast(
        'error',
        'Erro ao inserir o valor avulso. Detalhe: ' + e.message,
      );
      return false;
    }
  }, [showToast]);

  const fragmento = useMemo(() => {
    return <MovProdAvulsoFragment add={add} submit={submit} textChanged={textChanged} produtoAvulso={produtoAvulso} />;
  }, [add, produtoAvulso, submit, textChanged]);

  return (
    <>
      {(carregando || !produtoAvulso) && <CircularLoading tipo="FULLSIZED" />}
      {produtoAvulso && <>{fragmento}</>}
    </>
  );
};

export default MovProdAvulsoPage;

