import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpPermissaoUsuario } from 'model/enums/enum-tp-permissao-usuario';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogSolicitarPermissao } from 'views/components/dialog/dialog-solicitar-permissao/dialog-solicitar-permissao';

class SolicitarPermissaoDialogProps {
    constructor(
        public aberto: boolean = false,
        public acao: (...args: any[]) => Promise<void> = async () => { },
        public cod: number = 0,
        public tipoPermissao: EnumTpPermissaoUsuario = EnumTpPermissaoUsuario.SimNao,
        public valor?: any,
        public label?: string,
        public cancelAction?: () => void
    ) { }
}

export const SolicitarPermissaoDialog = () => {

    const { addHandler, removeHandler } = useEventTools();
    const { showToast } = useToastSaurus();

    const [modalState, setModalState] = useState<SolicitarPermissaoDialogProps>(new SolicitarPermissaoDialogProps());

    const { fecharSolicitarPermissao } = useCadastros()

    const modalAlterado = useCallback(({ aberto, acao, cod, label, tipoPermissao, valor, cancelAction }: SolicitarPermissaoDialogProps) => {
        setModalState({
            aberto: aberto,
            acao,
            cod,
            label,
            tipoPermissao,
            valor,
            cancelAction
        });
    }, []);

    useEffect(() => {
        addHandler(AppEventEnum.SolicitarPermissao, modalAlterado);

        return () => {
            removeHandler(AppEventEnum.SolicitarPermissao, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler, showToast]);

    return (
        <>
            {modalState.aberto && (
                <DialogSolicitarPermissao
                    closeModal={fecharSolicitarPermissao}
                    acao={modalState.acao}
                    cod={modalState.cod}
                    openned={modalState.aberto}
                    label={modalState.label}
                    valor={modalState.valor}
                    tipoPermissao={modalState.tipoPermissao}
                    cancelAction={modalState.cancelAction}
                />
            )}
        </>
    );
};