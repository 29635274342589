import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { CardMesa } from 'views/components/cards/card-mesa/card-mesa';
import { CardNaoEncontrado } from 'views/components/cards/card-naoencontrado';
import { InformacaoIcon } from 'views/components/icons';

export interface MesasListDataProps {
  mesas: Array<MesasModel>;
  onClick: (mesa: MesasModel) => void;
}

export const MesasListData = (props: MesasListDataProps) => {
  return (
    <>
      {props.mesas.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhuma mesa encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.mesas?.length > 0 &&
        props.mesas.map((mesa, index) => {
          return (
            <CardMesa
              onClick={props.onClick}
              model={mesa}
              key={index}
              selected={false}
            />
          );
        })}
    </>
  );
};
