import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetSessaoImpressao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSessaoImpressao = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string, qtdeColunas?: string | number, query?:string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/caixa/${caixaId}/sessao/${sessaoId}/impressao${qtdeColunas ? `?qtdColunas=${qtdeColunas}` : ''}${query ? query : ''}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSessaoImpressao,
    };
}