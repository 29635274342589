import { useCallback } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { useStyles } from './mov-comprovante-detalhe-page-styles';
import { useThemeQueries } from 'views';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { TpModMock } from 'data/mocks';
import { formatDecimalInteger, toCurrency, toDecimalString } from 'utils/to-decimal';
import { useMovAnteriorStorage } from 'services/app/hooks/mov-anterior-storage';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles';
import { VoltarIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';

export const MovComprovanteDetalhePage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { get } = useMovAnteriorStorage();
  const { push: historyPush } = useHistory();

  const detalhes = get();


  let pagamentosUnicos = new Map(
    detalhes?.pags.map((obj) => [obj.modPagamento, obj]),
  );

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar ao Comprovante"
        onClick={() => {
          historyPush('/venda-simples/finalizar-venda/comprovante');
        }}
      ></ButtonPrivateHeader>
    );
  }, [historyPush]);

  const pagamentosUnicosList = Array.from(pagamentosUnicos.values());

  const pagamentosSerelizados = pagamentosUnicosList.map((pagamento) => {
    return {
      ...pagamento,
      vPag: 0,
    };
  });

  pagamentosSerelizados.map((pagamento) => {
    detalhes?.pags.map((pag) => {
      if (pag.modPagamento === pagamento.modPagamento) {
        let novaQuantidade = (pagamento.vPag += pag.vPag);
        return {
          ...pagamento,
          vPag: novaQuantidade,
        };
      }
      return pag;
    });
    return pagamento;
  });

  const produtosAtivos = detalhes?.produtos.filter(
    (prod) => prod.ativo === true,
  );

  interface InfoProdInterface {
    produto: MovSimplesProdutoModel;
    qtdPai: number
  }

  const InfoProduto = ({ produto, qtdPai }: InfoProdInterface) => {
    if (produto.infoSubItem?.qPadrao === 0 && produto.qCom < 1) {
      return null;
    }

    return (
      <>
        {produto.idAdicional && produto.indFin ?
          <>
            <div style={{ marginLeft: '10px' }}>
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {produto.qCom <= 1 ? produto.qCom : (produto.qCom / qtdPai)}x {produto.xProd} <strong style={{ textAlign: 'end', fontWeight: 500 }}>{toCurrency(produto.vProd)}</strong>
              </p>
              {produto.prodSubItem.length > 0 &&
                produto.prodSubItem.map((p, index) => (
                  <InfoProduto
                    key={index}
                    produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                    qtdPai={qtdPai}
                  />
                ))}
            </div>
          </>
          :
          <div style={{ marginLeft: '10px', }}>
            {produto.qCom}x {produto.xProd}
            {produto.prodSubItem.length > 0 &&
              produto.prodSubItem.filter(prod => !prod.idAdicional).map((p, index) => (
                <InfoProduto
                  key={index}
                  produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
                  qtdPai={qtdPai}
                />
              ))}
          </div>
        }
      </>
    );
  };

  return (
    <>
      <Grid
        className={`${classes.container} ${isMobile ? '' : classes.containerBackgroundColor
          }`}
      >
        <Grid className={classes.header}>
          <PrivatePageHeader title="Detalhes da Venda" leftArea={leftArea()} />
        </Grid>
        <Grid className={classes.containerInfo}>
          <Grid className={classes.containerInfoWidth}>
            <Grid className={isMobile ? '' : classes.containerInfoGeralPC}>
              <Grid className={classes.containerInfoPreview}>
                <Grid className={classes.containerItemPreview}>
                  <Typography className={classes.labelSemi}>
                    CPF/CNPJ
                  </Typography>
                  <Typography className={classes.label}>
                    {formatarCPFCNPJ(detalhes?.documento || '') ??
                      'Não informado'}
                  </Typography>
                </Grid>

                <Grid className={classes.containerItemPreview}>
                  <Typography className={classes.labelSemi}>
                    Telefone
                  </Typography>
                  <Typography className={classes.label}>
                    {'Não informado'}
                  </Typography>
                </Grid>

                <Grid className={classes.containerItemPreview}>
                  <Typography className={classes.labelSemi}>Nome</Typography>
                  <Typography className={classes.label}>
                    {detalhes?.cliente?.nome ?? 'CONSUMIDOR'}
                  </Typography>
                </Grid>

                {pagamentosSerelizados.map((pag) => (
                  <Grid className={classes.containerItemPreview}>
                    <Typography className={classes.labelSemi}>
                      {
                        TpModMock.filter((x) => x.Key === pag.modPagamento)[0]
                          .Value
                      }
                    </Typography>
                    <Typography className={classes.label}>
                      R$ {toDecimalString(pag.vPag, 2)}
                    </Typography>
                  </Grid>
                ))}

                <Grid className={classes.containerItemPreview}>
                  <Typography className={classes.labelSemi}>
                    Valor Total
                  </Typography>
                  <Typography className={classes.label}>
                    R$ {toDecimalString(detalhes?.vNF, 2)}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={classes.label}>Produtos</Typography>
              <Divider
                classes={{ root: classes.divider }}
                style={{ marginBottom: 16 }}
              />
              {produtosAtivos?.map((produto, index) => {
                return (
                  <>
                    {produto.idGroup === null ?
                      <Grid
                        key={index}
                        className={
                          index % 2 === 0
                            ? classes.containerInfoPreviewBlue
                            : classes.containerInfoPreview
                        }
                      >
                        <Grid className={classes.containerItemPreview}>
                          <Typography className={classes.label}>
                            Nº {index + 1}
                          </Typography>
                          <Typography className={classes.label}>
                            Cod. {produto.cProd ?? produto.cEan ?? 'Não informado'}
                          </Typography>
                        </Grid>

                        <Grid className={classes.containerItemPreview}>
                          <Typography className={classes.labelSemi}>
                            {produto.xProd}
                          </Typography>
                          <Typography className={classes.labelSemi}>
                            {formatDecimalInteger(produto.qCom, 3)}x {produto.uCom}
                          </Typography>
                        </Grid>

                        <Grid className={classes.containerItemPreview}>
                          <Typography className={classes.labelSemi}>
                            Valor unitário:
                          </Typography>
                          <Typography className={classes.labelSemi}>
                            R$ {toDecimalString(produto.vUnCom, 2)}
                          </Typography>
                        </Grid>
                        <Grid className={classes.containerItemPreview}>
                          <Typography className={classes.label}>
                            Valor total:
                          </Typography>
                          <Typography className={classes.label}>
                            {toCurrency(
                              produto.adicionais.reduce((prev, curr) => prev + curr.vFinal, 0) + produto.vProd
                            )}
                          </Typography>
                        </Grid>
                        {produto.cobraTaxaServico && (
                          <>
                            {produto.prodSubItem.filter((p) => p.qCom > 0).length > 0 && (
                              <>
                                <div>
                                  <p
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      marginTop: '8px'
                                    }}
                                  >
                                    Inclusos:
                                  </p>
                                </div>
                                {produto.prodSubItem
                                  .filter((p) => p.qCom > 0)
                                  .map((p, index) => (
                                    <>
                                      <InfoProduto key={index} produto={{ ...p, qCom: p.qCom > p.infoSubItem!.qPadrao ? p.infoSubItem!.qPadrao : p.qCom }} qtdPai={(produto.qCom)} />
                                    </>
                                  ))}
                              </>
                            )}
                            {produto.adicionais.length > 0 && (
                              <>
                                <div>
                                  <p
                                    style={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      marginTop: '8px'
                                    }}
                                  >
                                    Adicionais:
                                  </p>
                                </div>
                                {produto.adicionais
                                  .filter((p) => p.qCom > 0)
                                  .map((p, index) => (
                                    <>
                                      <InfoProduto key={index} produto={p} qtdPai={produto.qCom} />
                                    </>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                      :
                      null
                    }
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
