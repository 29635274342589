import { PesquisaHeaderProps } from './pesquisa-dashboard-header-props';
import { useStyles } from './pesquisa-dashboard-admin-styles';
import { Button, Grid, Fade, useTheme } from '@material-ui/core';
import { useState, useCallback, ChangeEvent, useRef, useEffect } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { ProcurarIcon } from '../../../../../../../components/icons';
import { useThemeQueries } from 'views';

export const PesquisaDashboardAdmin = (props: PesquisaHeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useThemeQueries();
  const [termoPesquisar, setTermoPesquisar] = useState('');
  const refInput = useRef<HTMLDivElement | null>(null);

  //FOCO AO FICAR VISIVEL
  useEffect(() => {
    if (props.isOpened && !isMobile) refInput.current?.focus();
    else {
      setTermoPesquisar('');
    }
  }, [isMobile, props.isOpened]);

  //CHAMADA DO PESQUISAR
  const pesquisar = useCallback(
    (event: any) => {
      event.preventDefault();
      props.onPesquisar(termoPesquisar);
    },
    [props, termoPesquisar],
  );

  //ALTERAR A PESQUISA
  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} md={5}>
            <div className={classes.containerFinal}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                tipo="CNPJ_CPF"
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Digite o CPF/CNPJ"
                onChange={onChangePesquisa}
                searchable
                adornmentColor={theme.palette.text.disabled}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.btnPesquisa}
                fullWidth={true}
              >
                <ProcurarIcon tipo="BUTTON_PRIMARY" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Fade>
  );
};
