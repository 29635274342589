import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { ConfigInicialModel } from "model/api/gestao/config-inicial/config-inicial-model";

export function usePostConfigInicial() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postConfigInicial  = useCallback(
    (configInicial: ConfigInicialModel, empresaId:string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/wizard`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(configInicial),
        enviarTokenUsuario: true,
        timeout: 300000
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postConfigInicial,
  };
}
