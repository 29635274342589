export enum EnumCodigosPermissoes {
    //ADMIN
    HISTORICO_VENDAS = 5000,
    CONFIGURACOES = 5001,
    RELATORIOS = 5002,
    FATURAS = 5003,
    ADMINISTRACAO_PDV = 5004,
    ENTRADA_MERCADORIA = 5005,

    //CADASTROS
    PESSOAS = 2000,
    FORNECEDORES = 2001,
    PRODUTOS = 2002,
    TRANSPORTADORAS = 2004,
    ESTOQUE = 2005,
    REPRESENTANTES = 2007,
    FUNCIONARIOS = 2008,
    FINALIZADORAS = 2009,
    NCMS = 2010,
    CATEGORIAS_PRODUTOS = 2011,
    MODIFICADORES = 2012,
    PERFIS = 2013,
    REGIMES_TRIBUTARIOS = 2015,
    IMPOSTOS = 2016,
    PONTOS_DE_VENDA = 2017,

    //VENDA
    CANCELAMENTO_CUPOM = 530104,
    CANCELAMENTO_ITENS = 530105,
    DESCONTO_ITEM = 530106,
    DESCONTO_VENDA = 530107,
    ACRESCIMO_ITEM = 540113,
    ACRESCIMO_VENDA = 540114,
    REALIZAR_PEDIDOS = 5302,
    REALIZAR_VENDAS = 5301,
}