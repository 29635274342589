import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormEditProcessosValidation = () => {
  const FormEditProcessosValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        descricao: Yup.string().required('descrição é obrigatório'),
      })
    )
  }, [])
  return {
    FormEditProcessosValidationYup,
  }
}

