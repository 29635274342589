import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { Box, Grid, Typography } from '@material-ui/core';
import { DashboardHeader } from './components/dashboard-header';
import { DashboardContent } from './components/dashboard-content/dashboard-content';
import { useSessaoAtual } from "services/app";
import { EnumCodigosPermissoes } from "model/enums/enum-codigos-permissoes";
import TouchoneLogo from 'assets/img/toutchOne 1.svg'
import { useStyles } from './dashboard-styles'


const DashboardPage = () => {
  const classes = useDefaultCadastroStyles();
  const dashboardClasses = useStyles()
  const { getPermissaoBoolean } = useSessaoAtual();


  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <DashboardHeader />
      </Grid>
      <Grid className={classes.list}>
        {getPermissaoBoolean(EnumCodigosPermissoes.RELATORIOS) ? (
          <DashboardContent />
        ) : (
          <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'
            flexDirection='column' gridGap={16}>
            <img src={TouchoneLogo} alt='Logo do Touchone' className={dashboardClasses.logo} />
            <Typography align='center' variant="h6">Bem-vindo ao TouchOne, comece a explorar o sistema!</Typography>
          </Box>
        )}
      </Grid>
    </Grid>

  );
};

export default DashboardPage;
