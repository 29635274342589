import { UsuariosConvidarModel } from "model/api/gestao/perfil/usuarios-convidar-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePostUsuariosConvidar() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postUsuariosConvidar = useCallback(
    (usuario: UsuariosConvidarModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Usuario/convidar`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(usuario),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return { 
    ...outrasPropriedades,
    postUsuariosConvidar,
  };
} 
