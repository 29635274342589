import { CredenciamentoSafra } from "model/api/gestao/finalizadora/finalizadora-model";
import { EnumPagTpMod } from "model/enums";
import { guidEmpty } from "utils/guid-empty";

export class  FinalizadoraCadastroFormModel {
  constructor(
    public descricao: string = "",
    public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
    public empresaId: string | null = guidEmpty(),
    public qMaxParc: number = 1,
    public vMinParc: number = 0,
    public ordem: number = 0,
    public credenciais?: CredenciamentoSafra,
  ) { }
}
