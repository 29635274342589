import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const CategoriaAddIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 28.625 2 C 28.285156 2.003906 27.941406 2.019531 27.59375 2.0625 C 26.902344 2.152344 26.144531 2.386719 25.5625 2.96875 L 2.84375 25.6875 C 1.722656 26.808594 1.722656 28.660156 2.84375 29.78125 L 20.21875 47.15625 C 21.328125 48.265625 23.15625 48.277344 24.28125 47.1875 L 24.3125 47.15625 L 29.03125 42.40625 C 30.667969 45.710938 34.074219 48 38 48 C 43.511719 48 48 43.511719 48 38 C 48 34.078125 45.703125 30.699219 42.40625 29.0625 L 47.03125 24.4375 C 47.609375 23.859375 47.847656 23.09375 47.9375 22.40625 C 48.027344 21.71875 48 21.039063 48 20.375 L 48 5.5 C 48 3.578125 46.421875 2 44.5 2 L 29.625 2 C 29.300781 2 28.964844 1.996094 28.625 2 Z M 28.65625 4 C 28.949219 3.992188 29.285156 4 29.625 4 L 44.5 4 C 45.339844 4 46 4.65625 46 5.5 L 46 20.375 C 46 21.070313 45.996094 21.699219 45.9375 22.15625 C 45.878906 22.613281 45.785156 22.839844 45.625 23 L 40.34375 28.28125 C 39.589844 28.097656 38.808594 28 38 28 C 32.488281 28 28 32.488281 28 38 C 28 38.808594 28.097656 39.589844 28.28125 40.34375 L 22.90625 45.75 C 22.550781 46.105469 21.980469 46.105469 21.625 45.75 L 4.375 28.46875 L 4.25 28.375 C 3.894531 28.019531 3.894531 27.449219 4.25 27.09375 L 27 4.375 C 27.15625 4.21875 27.414063 4.121094 27.875 4.0625 C 28.105469 4.03125 28.363281 4.007813 28.65625 4 Z M 39 7 C 36.800781 7 35 8.800781 35 11 C 35 13.199219 36.800781 15 39 15 C 41.199219 15 43 13.199219 43 11 C 43 8.800781 41.199219 7 39 7 Z M 39 9 C 40.117188 9 41 9.882813 41 11 C 41 12.117188 40.117188 13 39 13 C 37.882813 13 37 12.117188 37 11 C 37 9.882813 37.882813 9 39 9 Z M 38 30 C 42.429688 30 46 33.570313 46 38 C 46 42.429688 42.429688 46 38 46 C 33.570313 46 30 42.429688 30 38 C 30 33.570313 33.570313 30 38 30 Z M 37 33 L 37 37 L 33 37 L 33 39 L 37 39 L 37 43 L 39 43 L 39 39 L 43 39 L 43 37 L 39 37 L 39 33 Z" />
            </DefaultIcon>
        </>
    );
};
