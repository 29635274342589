import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const BluetoothIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 24 0 C 18.515625 0 13.652344 1.328125 10.25 5.21875 C 6.847656 9.109375 5 15.359375 5 25 C 5 34.640625 6.847656 40.890625 10.25 44.78125 C 13.652344 48.671875 18.515625 50 24 50 C 29.484375 50 34.347656 48.671875 37.75 44.78125 C 41.152344 40.890625 43 34.640625 43 25 C 43 15.359375 41.152344 9.109375 37.75 5.21875 C 34.347656 1.328125 29.484375 0 24 0 Z M 24 2 C 29.171875 2 33.316406 3.175781 36.25 6.53125 C 39.183594 9.886719 41 15.617188 41 25 C 41 34.382813 39.183594 40.113281 36.25 43.46875 C 33.316406 46.824219 29.171875 48 24 48 C 18.828125 48 14.683594 46.824219 11.75 43.46875 C 8.816406 40.113281 7 34.382813 7 25 C 7 15.617188 8.816406 9.886719 11.75 6.53125 C 14.683594 3.175781 18.828125 2 24 2 Z M 23 4.5 L 23 22.1875 L 15.5625 14.84375 L 14.15625 16.25 L 22.5 24.46875 L 14.15625 32.6875 L 15.5625 34.125 L 23 26.78125 L 23 44.4375 L 24.71875 42.65625 L 33.28125 33.6875 L 33.96875 32.96875 L 33.25 32.28125 L 25.34375 24.5 L 33.25 16.6875 L 33.96875 15.96875 L 33.28125 15.28125 L 24.71875 6.3125 Z M 25 9.5 L 31.15625 15.9375 L 25 22 Z M 25 26.96875 L 31.15625 33.03125 L 25 39.46875 Z" />
            </DefaultIcon>
        </>
    );
};


