import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1)
  },
  cardRightButton: {
    width: '50px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      margin: 0,
      width: '22px',
      height: '22px',
      fill: theme.palette.text.primary
    }
  },
  gridProdutoItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0  !important`
  },
  gridProdutoName: {
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px !important`
  }
}));

export default useStyles;
