import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: (props: any) => {
    return {
      minHeight: 45,
      width: '100%',
      padding: theme.spacing(0.5)
    };
  },
  btnContent: (props: any) => {
    const { location } = props;

    return {
      width: '100%',
      minWidth: 100,
      backgroundColor: location ? `${theme.palette.primary.main}30` : undefined,
      '&:hover': {
        backgroundColor: location
          ? `${theme.palette.primary.main}30`
          : undefined
      },
    };
  },
  btnLabel: {
    display: 'flex',
  },
  icon: (props: any) => {
    return {
      fill: theme.palette.primary.main,
      width: 20,
      height: 20,
    };
  },
  textPrimary: (props: any) => {
    return {
      fontSize: 10,
      fontWeight: 600
    };
  },
  btnText: {
    padding: theme.spacing(1)
  },
  btnRoot: {
    height: 'inherit'
  },
  badgeQtde: {
    top: 0,
    "& span": {
      fontSize: 10,
    }
  },
}));
