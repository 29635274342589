import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    textSubtitle: {
        fontWeight: 500,
        lineHeight: "23px",
        fontSize: "1rem",
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
    },
}));
