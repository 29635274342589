import { EnumPagTpMod } from 'model';
import { useCallback } from 'react';
import * as Yup from 'yup';

interface FinalizadoraValidationProps {
  tipo: EnumPagTpMod;
}

export const useFormFinalizadoraCadastroValidation = ({ tipo }: FinalizadoraValidationProps) => {

  const credenciais = Yup.object().shape({
    merchantId: Yup.string().required('Merchant ID é obrigatório.').typeError('Merchant ID é obrigatório.'),
    merchantToken: Yup.string().required('Merchant Token é obrigatório.').typeError('Merchant Token é obrigatório.'),
    ambiente: Yup.number().required('Ambiente é obrigatório').typeError('Ambiente é obrigatório').typeError('Campo Obrigatório.'),
  })

  const validarCredenciais = useCallback(() => {
    switch (tipo) {
      case EnumPagTpMod.PAGAMENTO_INSTANTANEO:
        return credenciais
      default:
        return Yup.object().shape({}).nullable(true)
    }
  }, [credenciais, tipo])

  const FormFinalizadoraCadastroYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    tpMod: Yup.number().required('O campo Modelo de pagamento é obrigatório'),
    credenciais: validarCredenciais(),
    qMaxParc: Yup.number().required('Parcelamento Máximo é obrigatório').min(1, 'Parcelamento Máximo inválido').max(60, 'Parcelamento Máximo inválido').typeError('Parcelamento Máximo é obrigatório.'),
  });

  return {
    FormFinalizadoraYupValidation: FormFinalizadoraCadastroYupValidation
  }
}

