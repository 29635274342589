import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: '4px 8px',
        background: '#D9EEFB',
        boxShadow: 'none',
        color: theme.palette.primary.main
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center'
    },
    moduloNome: {
        marginRight: 4,
        fontWeight: 600
    },
    dividerCompartilhar: {
        marginBottom: 16,
        height: 3,
        background: '#F0F0F0'
    },
    cardCompartilhar: {
        padding: 8,
        background: '#F9F9F9'
    },
    resumoContainer: {
        flex: '1 1 100%',
        width: '100%',
    },
    imagemContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'auto',
            marginLeft: theme.spacing(0),
            marginBottom: theme.spacing(4),
        },
    },
    root: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16
    },
}))