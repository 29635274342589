import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useDeleteDepositoEmpresa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteDepositoEmpresa = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/DepositoEmpresa/${id}`,
        method: 'DELETE',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    deleteDepositoEmpresa
  };
}
