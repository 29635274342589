import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const EstrelaFavoritoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          d="M25 35.9792L37.875 43.75L34.4584 29.1042L45.8334 19.25L30.8542 17.9792L25 4.16669L19.1459 17.9792L4.16669 19.25L15.5417 29.1042L12.125 43.75L25 35.9792Z"
        />
      </DefaultIcon>
    </>
  );
};
