import { guidEmpty } from "utils/guid-empty";
// comentei pra testar
export class ImpressoraModel {
    constructor(
        public id: string = guidEmpty(),
        public descricao: string = '',
        public caminho: string = '',
        public modelo: string = '',
        public colunas: number = 48,
        public usuario: string = '',
        public senha: string = '',
        public quantidadeVias: number = 1,
        public titulo: string | null = null,
        public codigo: string | null = null,
    ) { }
}