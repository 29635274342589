import { guidEmpty } from "utils/guid-empty";

export class FaturaHistoricoModel {
    constructor(
        public id: string = guidEmpty(),
        public dInserido: Date = new Date(),
        public faturaId: string = guidEmpty(),
        public descricao: string = '',
        public valor: number = 0
    ) { }
}
