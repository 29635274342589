import { useCallback } from "react";
import { ProdutoPrecoModel } from "../../../../../model/api/gestao/produto/produto-preco/produto-preco-model";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutProdutoPreco() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProdutoPreco= useCallback(
    (produtoId:string,variacaoId:string,produtoPreco:ProdutoPrecoModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/preco/${produtoPreco.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(produtoPreco),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoPreco,
  };
}
