import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const OlhoAbertoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
            <path d="M 25 12 C 11.667969 12 1.25 24.34375 1.25 24.34375 C 0.921875 24.71875 0.921875 25.28125 1.25 25.65625 C 1.25 25.65625 11.667969 38 25 38 C 38.332031 38 48.75 25.65625 48.75 25.65625 C 49.078125 25.28125 49.078125 24.71875 48.75 24.34375 C 48.75 24.34375 38.332031 12 25 12 Z M 25 14 C 27.628906 14 30.140625 14.542969 32.46875 15.375 C 34.03125 17.140625 35 19.449219 35 22 C 35 27.535156 30.535156 32 25 32 C 19.464844 32 15 27.535156 15 22 C 15 19.449219 15.9375 17.140625 17.5 15.375 C 19.835938 14.539063 22.363281 14 25 14 Z M 14.1875 16.84375 C 13.4375 18.40625 13 20.15625 13 22 C 13 28.617188 18.382813 34 25 34 C 31.617188 34 37 28.617188 37 22 C 37 20.164063 36.582031 18.40625 35.84375 16.84375 C 41.492188 19.714844 45.554688 23.878906 46.59375 25 C 44.96875 26.757813 35.972656 36 25 36 C 14.027344 36 5.03125 26.757813 3.40625 25 C 4.445313 23.875 8.527344 19.714844 14.1875 16.84375 Z M 25 17 C 22.238281 17 20 19.238281 20 22 C 20 24.761719 22.238281 27 25 27 C 27.761719 27 30 24.761719 30 22 C 30 19.238281 27.761719 17 25 17 Z" />
            </DefaultIcon>
        </>
    );
};

