import { VariaveisAmbiente } from "config"
import { EnumDeviceType } from "model/enums/enum-device-type"
import { consoleDev } from "utils/console-dev"
import { CadastroBarCodeCamDialog } from "./components/cadastro-bar-code-cam-dialog"
import { CadastroCategoriaModal } from "./components/cadastro-categoria-modal"
import { CadastroComandasModal } from "./components/cadastro-comandas-modal"
import { CadastroConversaoEmAndamentoDialog } from "./components/cadastro-conversao-andamento-dialog"
import { CadastroDepositoModal } from "./components/cadastro-depositos-modal"
import { CadastroDocumentoFiscalModal } from "./components/cadastro-documento-fiscal-modal"
import { CadastroFaturasModal } from "./components/cadastro-faturas-modal"
import { CadastroFinalizadoraModal } from "./components/cadastro-finalizadora-modal"
import { CadastroImpressoraModal } from "./components/cadastro-impressora-modal"
import { CadastroLimiteProdutoAtingidoModal } from "./components/cadastro-limite-produto-atingido-modal"
import { CadastroNcmModal } from "./components/cadastro-ncm-modal"
import { CadastroPerfilModal } from "./components/cadastro-perfil-modal"
import { CadastroPessoaModal } from "./components/cadastro-pessoa-modal"
import { CadastroPontosVendaModal } from "./components/cadastro-pontos-venda-modal"
import { CadastroProdutoFiscalModal } from "./components/cadastro-produto-fiscal-modal"
import { CadastroProdutoModal } from "./components/cadastro-produto-modal"
import { CadastroSaloesModal } from "./components/cadastro-saloes-modal"
import { CadastroSetoresModal } from "./components/cadastro-setores-modal"
import { CadastroUploadCargaModal } from "./components/cadastro-upload-carga-modal"
import { CadastroUsuarioDialog } from "./components/cadastro-usuario-dialog"
import { CadastroVincularContratosDialog } from './components/cadastro-vincular-contratos-dialog';
import { Calculadora } from './components/calculadora-modal';
import { AdicionarProdutoDialog } from "./components/adicionar-produto-dialog"
import { AdicionarProdutoGenericoDialog } from "./components/adicionar-produto-generico-dialog"
import { AdicionarProdutoSemPrecoDialog } from "./components/adicionar-produto-sem-preco-dialog"
import { AdicionarProdutoBalancaDialog } from "./components/adicionar-produto-balanca-dialog"
import { AdicionarProdutoFiscaisDialog } from "./components/adicionar-produto-fiscais-dialog"
import { CadastroModalLimiteVendaAtingido } from "./components/modal-limite-venda-atingido"
import { AdicionarProdutoSubItemDialog } from "./components/adicionar-produto-subitem-dialog"
import { CadastroModalPropagandaPlano } from "./components/modal-propaganda-plano"
import { AvisoCobrancaDialog } from "./components/aviso-cobranca-dialog"
import { AdicionarNovoPDVDialog } from "./components/novo-pdv-dialog"
import { CadastroMesasModal } from "./components/cadastro-mesas-modal"
import { DialogCodigoProduto } from './components/dialog-codigo-produto'
import { SelecaoProdutosCodigosDialog } from "./components/selecao-produtos-codigos-dialog"
import { EnviarEmailDialog } from "./components/enviar-email-dialog"
import { EnviarSmsDialog } from "./components/enviar-sms-dialog"
import { SolicitarPermissaoDialog } from "./components/solicitar-permissao-dialog"
import { TrocarClienteDialog } from "./components/trocar-cliente-dialog"
import { ProdutoFacilitadoDialog } from "./components/produto-facilitado-dialog"
import { ImportarDialog } from './components/dialog-importar-pedido'
import { FechamentoDialog } from './components/dialog-fechamento-pedido'
import { MovFechamentoDialog } from './components/dialog-mov-fechamento'
import { VincularComandaDialog } from './components/dialog-vincular-comanda'
import { DialogBarCodeCamComandaDialog } from './components/dialog-barcode-cam-comanda'
import { AlterarQuantidadeClientesDialog } from './components/dialog-alterar-quantidade-cliente'
import { DialogMovEmFechamento } from './components/dialog-mov-em-andamento'
import { DialogIndicacaoDeDesperdicio } from './components/dialog-indicacao-de-desperdicio'
import { TaxaServicoDialog } from './components/dialog-taxa-servico'
import { DialogPedidosAcoes } from './components/dialog-pedidos-acoes'
import { DialogAlterarMesaPedido } from './components/dialog-alterar-mesa-pedido'
import { PixDialog } from "./components/pix-dialog"
import { CredenciarPixDialog } from "./components/credenciar-pix-dialog"
import { QrCodeDialog } from './components/dialog-qr-code'
import { DialogOpcoesProdutoCarrinho } from "./components/dialog-opcoes-produto-carrinho"
import { PlanoCustomizadoDialog } from "./components/plano-customizado-dialog"
import { CadastroModificadorModal } from "./components/cadastro-modificador-modal"
import { AdicionarWebhookDialog } from "./components/adicionar-webhook-render"
import { AdicionarProdutoGenericoPesavelDialog } from "./components/adicionar-produto-generico-pesavel-dialog"
import { DialogEditarGerenciamentoSessaoRender } from "./components/dialog-editar-gerencimento-sessao"
import { ConfigResumoSessaoDialog } from "./components/config-resumo-sessao"
import { AdicionarAcrescDescDialog } from "./components/adicionar-acresc-desc-dialog"
import { ConfirmarConfereciaSessaoDialog } from "./components/confirmar-conferencia-sessao-dialog"
import { EntradaFiltroModalRender } from "./components/entrada-filtro-modal-render"
import { EntradaModalRender } from "./components/entrada-modal-render"
import { CadastroVolumeTransportadoModalRender } from "./components/cadastro-volume-transportado-modal-render"
import { CadastroDocReferenciadoModalRender } from "./components/cadastro-doc-referenciado-modal-render"
import { ConfirmDialog } from "./components/confirm-dialog"
import { ModalSituacaoManifesto } from "./components/modal-situacao-manifesto"
import { FormasDePagamentoDialog } from './components/dialog-formas-de-pagamentos'
import { ParcelamentoModal } from './components/modal-parcelamento'
import { AcrescimoDescontoModal } from './components/modal-Acrescimo-desconto'
import { ConfirmAcaoManifestoDialog } from "./components/confirmar-acao-manifesto-dialog"
import { CadastroReceitaModal } from './components/cadastro-receita-modal'
import { CadastroDadosDoPagamentoModal } from './components/cadasto-dados-pagamento'
import { PagamentoEditModalRender } from "./components/pagamento-edit-modal-render"
import { DialogProdutosMedicamentosControlados } from './components/dialog-produtos-medicamentos-controlados'
import { CadastroRastroMedicamentoModalRender } from "./components/cadastro-rastro-medicamento-modal-render"
import { DialogInformacaoAdicionalRender } from "./components/dialog-informacao-adicional-render"
import { ManifestoFiltroModalRender } from "./components/manifesto-filtro-modal-render"
import { DialogAlterarQuantidadeClientesVendaRender } from "./components/dialog-alterar-quantidade-cliente-venda-render"
import { TaxaServicoDialogCarrinhoRender } from "./components/dialog-taxa-servico-carrinho-render"
import { DialogImpressaoNfeRender } from "./components/dialog-impressao-nfe"
import { AdicionarConfigIniciaisDialog } from "./components/adicionar-config-iniciais-dialog"
import { Definicoes } from './components/definicoes'
import { CategoriaFiltroModalRender } from "./components/categoria-filtro-modal-render"
import { NcmFiltroModalRender } from "./components/ncm-filtro-modal-render"
import { ImpostoFiltroModalRender } from "./components/impostos-filtro-modal-render"
import { PDVFiltroModalRender } from "./components/pdv-filtro-modal-render"
import { HistoricoVendailtroModalRender } from "./components/historico-venda-filtro-modal-render"
import { ProdutoFiltroModalRender } from "./components/produto-filtro-modal-render"
import { PessoaFiltroModalRender } from "./components/pessoa-filtro-modal-render"
import { DocumentoFiscalFiltroModalRender } from "./components/documento-fiscal-filtro-modal-render"
import { MarcaDialog } from "./components/marca-dialog"
import { CadastroMarcaModalRender } from "./components/cadastro-marca-modal-render"

export const RenderModais = () => {

  consoleDev('RenderModais');
  return (
    <>
      <CadastroPessoaModal />
      <CadastroBarCodeCamDialog />
      <CadastroFinalizadoraModal />
      <CadastroDepositoModal />
      <CadastroUploadCargaModal />
      <CadastroCategoriaModal />
      <CadastroNcmModal />
      <CadastroProdutoModal />
      <CadastroLimiteProdutoAtingidoModal />
      <CadastroConversaoEmAndamentoDialog />
      <CadastroProdutoFiscalModal />
      <CadastroPontosVendaModal />
      <CadastroPerfilModal />
      <CadastroUsuarioDialog />
      <CadastroVincularContratosDialog />
      <CadastroComandasModal />
      <CadastroMesasModal />
      {VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO && (
        <CadastroFaturasModal />
      )}
      <CadastroSaloesModal />

      {VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO && (
        <CadastroDocumentoFiscalModal />
      )}
      <CadastroImpressoraModal />
      <Calculadora />
      <CadastroSetoresModal />
      <AvisoCobrancaDialog />
      <AdicionarProdutoDialog />
      <AdicionarProdutoGenericoDialog />
      <AdicionarProdutoSemPrecoDialog />
      <AdicionarProdutoBalancaDialog />
      <AdicionarProdutoFiscaisDialog />
      <CadastroModalLimiteVendaAtingido />
      <AdicionarProdutoSubItemDialog />
      <CadastroModalPropagandaPlano />
      <AdicionarNovoPDVDialog />
      <DialogCodigoProduto />
      <SelecaoProdutosCodigosDialog />
      <EnviarEmailDialog />
      <EnviarSmsDialog />
      <SolicitarPermissaoDialog />
      <TrocarClienteDialog />
      <ProdutoFacilitadoDialog />
      <ImportarDialog />
      <FechamentoDialog />
      <MovFechamentoDialog />
      <VincularComandaDialog />
      <DialogBarCodeCamComandaDialog />
      <AlterarQuantidadeClientesDialog />
      <DialogMovEmFechamento />
      <DialogIndicacaoDeDesperdicio />
      <TaxaServicoDialog />
      <DialogPedidosAcoes />
      <DialogAlterarMesaPedido />
      <PixDialog />
      <CredenciarPixDialog />
      <QrCodeDialog />
      <DialogOpcoesProdutoCarrinho />
      <PlanoCustomizadoDialog />
      <CadastroModificadorModal />
      <AdicionarWebhookDialog />
      <ConfigResumoSessaoDialog />
      <AdicionarProdutoGenericoPesavelDialog />
      <DialogEditarGerenciamentoSessaoRender />
      <ConfirmarConfereciaSessaoDialog />
      <EntradaFiltroModalRender />
      <EntradaModalRender />
      <CadastroVolumeTransportadoModalRender />
      <CadastroDocReferenciadoModalRender />
      <FormasDePagamentoDialog />
      <ParcelamentoModal />
      <AdicionarAcrescDescDialog />
      <AcrescimoDescontoModal />
      <MarcaDialog />
      <CadastroReceitaModal />
      <CadastroDadosDoPagamentoModal />
      <PagamentoEditModalRender />
      <DialogProdutosMedicamentosControlados />
      <CadastroRastroMedicamentoModalRender />
      <DialogInformacaoAdicionalRender />
      <DialogAlterarQuantidadeClientesVendaRender />
      <TaxaServicoDialogCarrinhoRender />
      <DialogImpressaoNfeRender />
      <AdicionarConfigIniciaisDialog/>
      <Definicoes />
      <CategoriaFiltroModalRender />
      <NcmFiltroModalRender />
      <ImpostoFiltroModalRender />
      <PDVFiltroModalRender />
      <HistoricoVendailtroModalRender />
      <ProdutoFiltroModalRender />
      <PessoaFiltroModalRender />
      <DocumentoFiscalFiltroModalRender />
      <CadastroMarcaModalRender />
      <ModalSituacaoManifesto/>
      <ConfirmAcaoManifestoDialog/>
      <ConfirmDialog/>
      <ManifestoFiltroModalRender/>
    </>
  );
};
