import { EnumTpEmis } from "model/enums/enum-tp-emis";
import { PessoaModel } from "../pessoa";
import { VendaPagsModel } from "./venda-pags-model";
import { VendaProdutoModel } from "./venda-produto-model";

export class VendaOffModel {
    constructor(
        public id: string = '',
        public empresaId: string = '',
        public operadorId: string = '',
        public sessaoId: string = '',
        public status: number = 0,
        public tpAmb: number = 0,
        public dEmi: string | Date = '',
        public serie: number = 0,
        public nnf: number = 0,
        public mod: number = 0,
        public pessoaId: string = '',
        public documento: string = '',
        public regimeTributarioId: string = '',
        public produtos: VendaProdutoModel[] = [],
        public pags: VendaPagsModel[] = [],
        public cliente: PessoaModel | null = null,
        public dataSincronizacao: Date | string | null = null,
        public vNF: number = 0,
        public tpEmis: EnumTpEmis = EnumTpEmis.OFFLINE,
        public mesaCodigo: string | null = null,
        public codigoComanda: string | null = null
    ) { }
}