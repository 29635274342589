import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const NfeIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M5.70037 1.92291C5.18603 2.12671 5.21514 0.573994 5.21514 24.4469V46.5536L5.43835 46.7768L5.66155 47H22.984H40.3161L40.5005 46.7671C40.6751 46.5536 40.6848 46.3498 40.6848 43.7587V40.9833L43.111 38.5474L45.5371 36.1213V28.5034V20.8757L45.3139 20.6719C45.1004 20.4681 44.9936 20.4584 42.9169 20.439L40.7334 20.4099L40.6848 15.3344L40.6363 10.2687L36.4149 6.04729L32.1935 1.82587L19.044 1.83557C11.8142 1.83557 5.80712 1.87439 5.70037 1.92291ZM31.0969 7.21183C31.126 10.7345 31.1357 11.0548 31.3007 11.2101C31.4559 11.375 31.7956 11.3847 35.328 11.4333L39.1807 11.4818V15.9458V20.4099L24.4008 20.4584L9.61125 20.5069L9.42687 20.7398C9.25219 20.9533 9.24248 21.225 9.24248 28.4742V35.9758L9.47539 36.2475L9.7083 36.5192L24.4687 36.5483L39.2195 36.5677L39.2292 41.0318V45.4958H22.9743H6.71933V24.4372V3.37857H18.8984H31.0678L31.0969 7.21183ZM37.9676 9.89996C37.9676 9.94848 36.7643 9.97759 35.2989 9.97759H32.6302V7.24094V4.49459L35.2989 7.1633C36.7643 8.62867 37.9676 9.86114 37.9676 9.89996ZM44.0038 28.6586L44.0329 35.4032L42.3637 37.0724L40.6848 38.7512V37.674V36.5871L41.2671 36.5483C41.9949 36.4998 42.2764 36.2669 42.2764 35.7332C42.2764 35.4808 42.2084 35.3256 42.0532 35.1994C41.8397 35.0247 41.3253 35.015 26.3126 35.015H10.7952V28.5325C10.7952 24.9612 10.8243 22.0111 10.8631 21.982C10.8922 21.9432 18.3646 21.914 27.4577 21.914H43.9843L44.0038 28.6586Z" />
        <path d="M11.9112 6.14431C11.7074 6.3384 11.6686 6.47426 11.6686 6.91096C11.6686 7.49323 11.7171 7.44471 10.9407 7.59998C10.5817 7.67761 9.95087 8.15313 9.68885 8.56071C9.03865 9.56027 9.1551 10.7345 9.97998 11.5691C10.3099 11.8893 10.9019 12.2096 11.1736 12.2096C11.6297 12.2096 11.6686 12.2872 11.6686 13.1412V13.9564H11.1445C10.4458 13.9564 10.1158 14.2087 10.1158 14.7327C10.1158 15.2568 10.4458 15.5091 11.1445 15.5091H11.6686V16.0331C11.6686 16.7318 11.9209 17.0618 12.4449 17.0618C12.9689 17.0618 13.2213 16.7318 13.2213 16.0331C13.2213 15.6644 13.2601 15.5091 13.3474 15.5091C13.8132 15.5091 14.5411 15.1694 14.9292 14.7618C16.2879 13.3741 15.5406 11.1033 13.6191 10.7927L13.2213 10.7248V9.87081V9.00712H13.8035C14.5022 9.00712 14.774 8.80333 14.774 8.28899C14.774 7.75525 14.5217 7.54175 13.8229 7.48352L13.2213 7.4253V6.90126C13.2213 6.27047 12.9398 5.9017 12.4449 5.9017C12.2605 5.9017 12.0567 5.98904 11.9112 6.14431ZM11.6686 9.88052C11.6686 10.6083 11.6394 10.7539 11.5133 10.7539C11.2513 10.7539 10.7952 10.2105 10.7952 9.90963C10.7952 9.48264 11.1833 9.04594 11.6006 9.01682C11.6394 9.00712 11.6686 9.3953 11.6686 9.88052ZM13.852 12.5492C14.1917 12.8792 14.182 13.4032 13.8423 13.7138C13.6968 13.8496 13.5027 13.9564 13.4056 13.9564C13.2407 13.9564 13.2213 13.869 13.2213 13.1315C13.2213 12.3649 13.231 12.3066 13.4153 12.3066C13.5318 12.3066 13.7259 12.4134 13.852 12.5492Z" />
        <path d="M18.6655 7.56115C18.1511 7.76494 18.025 8.30839 18.3743 8.7548L18.5684 9.00711H22.5473C26.3902 9.00711 26.5261 8.99741 26.7105 8.81302C26.9628 8.56071 26.9628 8.03667 26.7202 7.73583L26.5358 7.50292L22.7122 7.48351C20.3832 7.47381 18.811 7.50292 18.6655 7.56115Z" />
        <path d="M18.4131 10.9965C17.9958 11.4041 18.1413 11.9961 18.6945 12.2096C19.073 12.3454 27.642 12.3454 28.0593 12.1999C28.6124 12.0058 28.7483 11.307 28.3019 10.9577C28.0496 10.7539 27.9525 10.7539 23.3429 10.7539H18.646L18.4131 10.9965Z" />
        <path d="M18.5878 14.0534C18.1317 14.2378 18.0347 14.8977 18.4132 15.2665L18.6461 15.5091H21.6641C24.7404 15.5091 24.9539 15.4897 25.2257 15.1306C25.4683 14.8104 25.2936 14.2475 24.8957 14.0631C24.6143 13.937 18.9081 13.9273 18.5878 14.0534Z" />
        <path d="M16.4069 24.108C15.9993 24.302 15.9799 24.5155 16.009 28.9796L16.0381 33.2301L16.271 33.4145C16.5622 33.6474 16.9698 33.6474 17.2609 33.4145C17.4938 33.2301 17.4938 33.2107 17.5423 30.7167L17.5908 28.2129L18.3575 29.7171C19.6094 32.182 20.1819 33.2495 20.3372 33.4242C20.5313 33.6571 21.0844 33.6474 21.3174 33.4145C21.4241 33.3078 21.5308 33.036 21.5697 32.8031C21.5988 32.5702 21.6182 30.6002 21.5988 28.4264L21.5697 24.4573L21.2979 24.2244C20.9486 23.9236 20.6089 23.9236 20.3081 24.2341L20.0655 24.467V26.9708C20.0655 28.669 20.0364 29.4357 19.9684 29.3678C19.9102 29.3095 19.3085 28.1353 18.6195 26.7476C17.62 24.7193 17.3191 24.205 17.1153 24.108C16.8048 23.9721 16.6883 23.9721 16.4069 24.108Z" />
        <path d="M24.3785 24.1261C24.0097 24.4076 24 24.5725 24 28.9007V33.1318L24.2426 33.3647C24.524 33.6559 24.9607 33.6753 25.2713 33.423C25.4945 33.2386 25.5042 33.1901 25.5333 31.4336L25.5624 29.6285H26.7561C28.0953 29.6285 28.4834 29.5315 28.6484 29.1821C28.8037 28.8328 28.7843 28.6193 28.5514 28.3281C28.3573 28.0758 28.3476 28.0758 26.9501 28.0758H25.5527V26.8143V25.5527H26.9113C28.2311 25.5527 28.2894 25.543 28.5126 25.3101C28.6678 25.1645 28.7552 24.9607 28.7552 24.7763C28.7552 24.5919 28.6678 24.3881 28.5126 24.2426C28.2797 24 28.27 24 26.4067 24C25.0869 24.0097 24.4852 24.0388 24.3785 24.1261Z" />
        <path d="M37.8609 25.4464C37.2884 25.6502 36.8031 25.9899 36.5023 26.4072C36.0365 27.0574 35.9297 27.5911 35.9297 29.3476C35.9297 30.6868 35.9589 31.0071 36.1238 31.4438C36.3956 32.1619 36.8031 32.6277 37.5019 32.9674C38.055 33.2488 38.1618 33.2682 39.1807 33.2682C40.19 33.2682 40.2773 33.2488 40.4714 33.045C40.7431 32.7539 40.7529 32.2686 40.4908 32.0066C40.2967 31.8125 40.2191 31.7931 39.5204 31.764C39.336 31.764 38.9769 31.7446 38.7149 31.7349C38.3462 31.7155 38.1909 31.6573 37.9095 31.3952C37.6086 31.1235 37.5504 30.9876 37.5116 30.5801L37.463 30.0754L39.2681 30.0463C41.5777 30.0075 41.5195 30.0463 41.5001 28.5324C41.4807 27.232 41.2769 26.6789 40.5782 26.0481C39.8212 25.3688 38.744 25.1262 37.8609 25.4464ZM39.4233 27.0476C39.7339 27.2611 40.0056 27.7949 40.0056 28.1928V28.513H38.7343H37.4533L37.5019 28.0278C37.5989 27.0088 38.6179 26.4945 39.4233 27.0476Z" />
        <path d="M31.199 28.2124C30.9273 28.4841 30.937 29.0858 31.2087 29.3381C31.3737 29.4837 31.5581 29.5225 32.1403 29.5225C32.7905 29.5225 32.8876 29.4934 33.0526 29.2896C33.3049 28.9791 33.2952 28.4939 33.0137 28.2318C32.6935 27.931 31.4901 27.9213 31.199 28.2124Z" />
      </DefaultIcon>
    </>
  );
};
