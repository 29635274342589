import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { VendaCompletaXMLModel } from 'model/api/gestao/venda/venda-completa-xml-model';


export function usePutAtualizarEntradaXML() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putAtualizarEntradaXML = useCallback(
        (venda: VendaCompletaXMLModel, id: string, empresaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Entrada/${id}`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(venda),
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putAtualizarEntradaXML,
    };
}