import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { useHistory } from 'react-router-dom';
import { VoltarIcon } from 'views/components/icons';

export const DetalhesVendaHeader = () => {
  const { push, location } = useHistory()
  const voltarHistorico = useCallback(() => push({
    pathname: '/historico',
    search: location.search
  }), [location.search, push])

  const leftArea = useCallback(
    () => {
      return (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={voltarHistorico}
        />
      )
    },
    [voltarHistorico],
  );

  return (
    <>
      <PrivatePageHeader title={'Histórico da Venda'} leftArea={leftArea()} />
    </>
  );
};
