import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            backgroundColor: '#FFF0!important'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: "none"
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(255 255 255 / 90%)'
        }
    }
}));
