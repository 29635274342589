import * as Yup from "yup";
import { useValidationYupDocumento } from '../../../../form-validations/yup/documento';
import { isEmpty } from "lodash";
import { useGestaoToken, useSessaoAtual } from "services/app";
import { isPlanoFiscal } from "utils/plano-utils";


export const useFormProdutoPrincipalEditValidation = () => {
  const { documentoYup } = useValidationYupDocumento(true);
  const { plano } = useSessaoAtual();
  const {getEmpresaAtual} = useGestaoToken()
  const isFiscal = isPlanoFiscal(plano?.plano);
  const empresaFiscal = getEmpresaAtual()?.isFiscal

  const fiscal = isFiscal && empresaFiscal

  const FormProdutoPrincipalEditYupValidation = Yup.object().shape({

    cnpjFab: documentoYup("CNPJ"),
    imposto: fiscal ? Yup.string().test({
      message: 'Informe o Grupo de Imposto',
      test: (value, context) => {
        if (isEmpty(context.parent.impostoId) && !isEmpty(context.parent.ncmId)) {
          return false
        }
        return true
      }
    }) : Yup.string().notRequired(),
    codigoNcm: fiscal ? Yup.string().test({
      message: 'Informe o NCM',
      test: (value, context) => {
        if (isEmpty(context.parent.ncmId) && !isEmpty(context.parent.impostoId)) {
          return false
        }
        return true
      }
    }) : Yup.string().notRequired()
  });

  return {
    FormProdutoPrincipalEditYupValidation,
  };
};
