import { Grid } from "@material-ui/core"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeCofinsProps } from "./form-pe-cofins-props"
import { Controller } from "react-hook-form"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { CstPisCofinsMock } from "data/mocks/cst-pis-mock"
import { useState } from "react"
import { ProdutoEntradaFormModel } from "model/app/forms/entrada/produto-entrada-form-model"

export const FormPeCofins = ({ control, formState, setValue, getValues, ...props }: FormPeCofinsProps) => {

    const { errors } = formState
    const [, setAtt] = useState<boolean>(false)
    const produtoPadrao = new ProdutoEntradaFormModel()

    const todosCampos = ["cofins.vBc", "cofins.pCofins", "cofins.qBcProd", "cofins.vAliqProd", "cofins.vCofins"]

    const camposHabilitados = (value?: number) => ({
        1: ["cofins.vBc", "cofins.pCofins", "cofins.vCofins"],
        2: ["cofins.vBc", "cofins.pCofins", "cofins.vCofins"],
        3: ["cofins.qBcProd", "cofins.vAliqProd", "cofins.vCofins"],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        49: todosCampos,
        50: todosCampos,
        51: todosCampos,
        52: todosCampos,
        53: todosCampos,
        54: todosCampos,
        55: todosCampos,
        56: todosCampos,
        60: todosCampos,
        61: todosCampos,
        62: todosCampos,
        63: todosCampos,
        64: todosCampos,
        65: todosCampos,
        66: todosCampos,
        67: todosCampos,
        70: todosCampos,
        71: todosCampos,
        72: todosCampos,
        73: todosCampos,
        74: todosCampos,
        75: todosCampos,
        98: todosCampos,
        99: todosCampos,
    })[value || getValues('cofins.cst')] || []

    const manterHabilitado = (name: string) => camposHabilitados().filter(x => x === name).length === 0

    const resetaCampos = (value: number) => {
        const camposString = camposHabilitados(value).map(y => y.split('.')[1])
        const objIcms = Object.entries(produtoPadrao.cofins).filter(x => !camposString.includes(x[0]))
        const naoRemover = ['cst']
        objIcms.forEach(x => {
            if (!naoRemover.includes(x[0])) {
                if (x?.[1] !== undefined) {
                    setValue((`cofins.${x[0]}`) as any, x[1])
                }
            }
        })
    }

    return (
        <AccordionSaurus
            labelPrimary="COFINS"
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.cst"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={CstPisCofinsMock}
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="CST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.cst && errors.cofins.cst.message)}
                                helperText={
                                    errors.cofins?.cst
                                        ? errors.cofins?.cst?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = CstPisCofinsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("cofins.cst", value)
                                    resetaCampos(value)
                                    setAtt(prev => !prev)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.vBc"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.vBc && errors.cofins.vBc.message)}
                                helperText={
                                    errors.cofins?.vBc
                                        ? errors.cofins?.vBc?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.pCofins"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Aliquota COFINS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.pCofins && errors.cofins.pCofins.message)}
                                helperText={
                                    errors.cofins?.pCofins
                                        ? errors.cofins?.pCofins?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.qBcProd"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Quantidade de B.C."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.qBcProd && errors.cofins.qBcProd.message)}
                                helperText={
                                    errors.cofins?.qBcProd
                                        ? errors.cofins?.qBcProd?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.vAliqProd"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor Unitário"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.vAliqProd && errors.cofins.vAliqProd.message)}
                                helperText={
                                    errors.cofins?.vAliqProd
                                        ? errors.cofins?.vAliqProd?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="cofins.vCofins"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor COFINS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.cofins?.vCofins && errors.cofins.vCofins.message)}
                                helperText={
                                    errors.cofins?.vCofins
                                        ? errors.cofins?.vCofins?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}