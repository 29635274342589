import { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './sessao-valor-list-styles';
import { SessaoValorListData } from './sessao-valor-list-data';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { SessaoValorSearchProps } from './sessao-valor-search-props';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { SessaoValorModel } from 'model/api/gestao/sessao/sessao-valor-model';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useHistory } from 'react-router-dom';

export interface SessaoValorListProps {
  searchProps: SessaoValorSearchProps;
  tpLancamento: EnumTpLancamentoSessaoValor;
  tpEvent: AppEventEnum;
}

export const SessaoValorList = (props: SessaoValorListProps) => {
  // STATES E REFS
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sessaoValores, setSessaoValores] = useState<SessaoValorModel[]>([]);

  // HOOKS
  const { addHandler, removeHandler } = useEventTools();
  const { getSessao } = useSessaoPDV();

  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { push: historyPush } = useHistory();

  const getValores = useCallback(
    async (reload: boolean) => {
      try {
        if (reload) {
          setIsLoading(true);
        }

        const sessao = await getSessao();

        if (!sessao) {
          throw new Error('Nenhuma sessão foi identificada');
        }

        const res = await TouchoneDBPrimary.sessaoValores
          .filter(
            (valor) =>
              valor.sessaoId === sessao.id &&
              valor.tpLancamento === props.tpLancamento,
          )
          .filter((valor) => {
            if (props.searchProps.termo) {
              return (
                (valor.tpLancamento === props.tpLancamento &&
                  valor.observacao
                    .toString()
                    .toLowerCase()
                    .includes(props.searchProps.termo.toLowerCase())) ||
                valor.valor
                  .toString()
                  .toLowerCase()
                  .includes(props.searchProps.termo.toLowerCase()) ||
                valor.portador?.nome
                  .toString()
                  .toLowerCase()
                  .includes(props.searchProps.termo.toLowerCase()) ||
                valor.pagamento?.descricao
                  .toString()
                  .toLowerCase()
                  .includes(props.searchProps.termo.toLowerCase())
              );
            } else {
              return valor.tpLancamento === props.tpLancamento;
            }
          })
          .toArray();

        setSessaoValores(res.reverse());
      } catch (e: any) {
        showToast(
          'error',
          `Erro ao buscar sessao valores. Detalhe: ${e.message}`,
        );
        historyPush('/dashboard');
      } finally {
        setIsLoading(false);
      }
    },
    [
      getSessao,
      historyPush,
      props.searchProps.termo,
      props.tpLancamento,
      showToast,
    ],
  );

  const refetch = useCallback(() => {
    getValores(true);
  }, [getValores]);

  useEffect(() => {
    addHandler(props.tpEvent, refetch);
    return () => removeHandler(props.tpEvent, refetch);
  }, [addHandler, props.tpEvent, refetch, removeHandler]);

  useEffect(() => {
    getValores(false);
  }, [getValores]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {isLoading && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <SessaoValorListData list={sessaoValores} carregando={isLoading} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
