import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    list: {
        flex: 1,
        overflowX: "hidden",
        overflow: "auto",
        position: "relative",
        padding: '0 8px',
    },
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainer: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        margin: '2px 0',
        height: '2px',
        marginBottom: theme.spacing(1),
    },
    containerInfo: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    containerIcon: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '5px',
        alignItems: 'center',
    },
    textTitle: {
        fontSize: '14px'
    },
    textInfo: {
        fontSize: '16px'
    },
    containerAcao: {
        display: 'flex',
        padding: theme.spacing(1)
    },
    buttonfechamento: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
    whiteCard: {
        display: 'flex',
        background: '#fff',
        padding: theme.spacing(.5),
        borderRadius: theme.shape.borderRadius,
    },
    buttonTaxaServico: {
        background: '#fff',
        borderRadius: theme.shape.borderRadius,
        display: 'relative'
    },
    taxaServicoContainer: {

    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
}));
