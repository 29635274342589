import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
    return {
        contentProdList: {
            boxShadow: '0px 5px 5px rgba(0,0,0,0.05) inset',
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
            height: 2
        },
        content: {
            background: '#FAFAFA'
        }
    }
})