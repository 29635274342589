import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback } from "react";
import { useEventTools } from "./events/event-tools";
import { stringNumeros } from "utils/string-numeros";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useGestaoToken } from "../providers/gestao-token";
import { AutenticacaoPendenciaContratoModel } from "model/api/gestao/autenticacao-pendencia/autenticacao-pendencia";
import { isEmpty } from "lodash";

export function useChatSuporteSaurus() {
    const { callEvent } = useEventTools()
    const { getRegistro } = useGestaoStorage()
    const { convertToken } = useGestaoToken()

    const jwtToken = getRegistro(GestaoStorageKeys.Token, false)
    const token = convertToken(jwtToken)
    const pendencia: AutenticacaoPendenciaContratoModel = isEmpty(getRegistro(GestaoStorageKeys.PendenciaContrato)) ? new AutenticacaoPendenciaContratoModel() : getRegistro(GestaoStorageKeys.PendenciaContrato)
  

    const abrirChat = useCallback((email: string, documento: string, saudacao: string): void => {
        const message = `Ol%C3%A1!%20Estou%20precisando%20de%20uma%20ajudinha%20aqui.%20Pode%20me%20Ajudar%3F%20Meu%20usu%C3%A1rio%20%C3%A9%20${email}%20e%20estou%20acessando%20a%20loja%20${documento}%20-%20${saudacao.replaceAll(' ', '%20')}`
        callEvent(AppEventEnum.SuporteChamada, `https://api.whatsapp.com/send?phone=55${stringNumeros(token?.licenca?.RepresentanteTelefoneSuporte ?? pendencia.TelefoneSuporteRepresentante)}&text=${message}`)
    }, [callEvent, pendencia.TelefoneSuporteRepresentante, token?.licenca?.RepresentanteTelefoneSuporte]); 

    return {
        abrirChat
    };
}