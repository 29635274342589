import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.grey[100],
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflowX: "hidden"
    },
    header: {
        flex: "0 1 auto"
    },
    contentAcoes: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    contentCima: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flex: "1 1 100%",
        padding: 32,
        "& svg": {
            width: 70,
            height: 70
        },
        "& h3": {
            fontSize: 24,
            color: theme.palette.primary.main,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            fontWeight: "bold",
        },
        "& span": {
            fontSize: 14,
        }
    },
    contentGridBotoes: {
        padding: 4
    },
    contentBotoes: {
        flex: "0 1 auto",
        padding: 4,
    },
    buttonGrid: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",

        "& span": {
            fontSize: '11px',
            fontWeight: "normal",
            lineHeight: '11px',
            textAlign: 'left',
            color: theme.palette.text.primary
        },
        "& h6": {
            fontSize: '17px',
            fontWeight: 700,
            lineHeight: '22px',
            textAlign: 'left',
            color: theme.palette.primary.main
        }
    },
    buttonGridPequeno: {
        "& h6": {
            fontSize: '15px',
            lineHeight: '15px',
            textAlign: 'left',
            color: theme.palette.text.primary
        }
    },
    button: {
        background: '#fff',
        height: 100,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        "& svg": {
            width: '45px',
            height: '45px',
            alignItems: 'center',
            marginRight: 16,
            marginTop: 4,
        },
    },
    content: {
        flex: '1 1 100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
    }
}));
