import React from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { PerfilEditPermissaoFormModel } from 'model/app/forms/perfil/perfil-edit-permissao-form-model';
import { SwitchSaurus } from 'views/components/controles/switches/switch-saurus';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { EnumTpControle } from 'model/enums/enum-tpcontrole';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { toDecimal } from 'utils/to-decimal';
import { useStyles } from './accordion-perfil-controle-styles';

export interface SwitchAccordionPerfilProps {
  list: Array<PerfilEditPermissaoFormModel>;
  onPermissaoChanced(cod: number, novoValor: any): void;
}

export default function AccordionPerfilControle(
  props: Readonly<SwitchAccordionPerfilProps>
) {
  const classes = useStyles();
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const isChecked = event.target.checked;
    props.onPermissaoChanced(
      parseInt(name.replace('switch_', '')),
      isChecked.toString()
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    props.onPermissaoChanced(parseInt(name), value);
  };

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = toDecimal(event.target.value);
    props.onPermissaoChanced(parseInt(name), value);
  };

  if (isEmpty(props.list)) {
    return <></>;
  }

  const retControle = (permi: PerfilEditPermissaoFormModel) => {
    //CHECK
    if (permi.tipo === EnumTpControle.SimNao) {
      const isOn = permi.valor === 'true' || permi.valor === '1';
      return (
        <SwitchSaurus
          variant="LIGHT"
          checked={isOn}
          onChange={handleChangeCheckBox}
          name={'switch_' + permi.codigo}
        />
      );
      //TEXTBOX
    } else if (permi.tipo === EnumTpControle.Texto) {
      return (
        <TextFieldSaurus
          variant="outlined"
          size="small"
          onChange={handleChange}
          value={permi.valor}
          tipo={'TEXTO'}
          name={permi.codigo}
        />
      );
      //TEXTBOX NUMERO
    } else if (permi.tipo === EnumTpControle.Numero && !permi.opcoes) {
      return (
        <TextFieldSaurus
          variant="outlined"
          size="small"
          onChange={handleChangeNumber}
          value={permi.valor}
          name={permi.codigo}
          tipo="NUMERO"
        />
      );
      //SELECT
    } else if (permi.tipo === EnumTpControle.Numero && permi.opcoes) {
      let items: any[] = permi.opcoes.opcoesValor
        ? permi.opcoes.opcoesValor
        : permi.opcoes.opcoesTexto || [];
      if (
        (items || []).length === 0 &&
        permi.opcoes.intervalo &&
        permi.opcoes.intervalo.maximo !== permi.opcoes.intervalo.minimo
      ) {
        for (
          let i = permi.opcoes.intervalo.minimo;
          i <= permi.opcoes.intervalo.maximo;
          i++
        ) {
          items.push(i.toString());
        }
      }

      const mock: Array<KeyValueModel> = items.map((valor, iSplit) => {
        //VALUE DO DROP É INTEIRO
        if (
          permi.opcoes &&
          permi.opcoes.intervalo &&
          permi.opcoes.intervalo.maximo !== permi.opcoes?.intervalo.minimo
        ) {
          return new KeyValueModel(
            (iSplit + permi.opcoes.intervalo.minimo).toString(),
            valor
          );
        } else {
          return new KeyValueModel(valor, valor);
        }
      });
      return (
        <SelectSaurus
          fullWidth
          variant="outlined"
          value={permi.valor}
          conteudo={mock}
          onChange={handleChange}
          name={permi.codigo}
        />
      );
    }
    return <></>;
  };

  const sortedList = [...props.list].sort(function (a, b) {
    if (a.titulo < b.titulo) {
      return -1;
    }
    if (a.titulo > b.titulo) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={classes.defaultList}>
      {sortedList.map((permi, index) => {
        const key = `accordion-sorted-list-${permi.titulo}__${index}`;
        return (
          <Grid className={classes.content} key={key}>
            <div className={classes.descrContent}>
              <div className={classes.titulo}>{permi.titulo}</div>
              <div>{permi.descricao}</div>
            </div>
            <div>{retControle(permi)}</div>
          </Grid>
        );
      })}
    </div>
  );
}
