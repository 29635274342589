import { makeStyles } from '@material-ui/core';

export const useTabStyles = makeStyles((theme) => ({
  tabContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tabsContainer: {
    flex: '0 0 auto',
    background: 'none',
    boxShadow: 'none',
    '& .Mui-selected': {}
  },
  swipeDiv: {
    display: 'grid',
    height: '100%'
  },
  tabContent: {
    flex: '1 1 100%',
    overflow: 'auto'
  },
  iconLabelWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    }
  },
  labelIcon: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 48
    }
  },
  hideTabs:{
    display:'none'
  }
}));
