import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    warningText:{
        '& svg': {
            fill: theme.palette.error.main,
            width: 25,
            height: 25
        },
        '& span': {
            borderBottom: '1px solid ' + theme.palette.error.main,
            cursor: 'pointer'
        },
        color: theme.palette.error.main,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1, 0)
    },
    cEanContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    prodFormContainer: {
        background: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
    },
    noPadding: {
        padding: 0,
    }
}))