import { EnumTpAmb } from "model/enums/enum-tpamb";

export class EmissorModel {
    constructor(
        public pfxLocalUrl: string = "",
        public dValidade: string = "",
        public tpCertificado: string = "",
        public cIdToken: string = "",
        public csc: string = "",
        public tpAmb: EnumTpAmb = 2,
        public cnpj: string = "",
        public xContato: string = "",
        public email: string = "",
        public fone: string = "",
        public idCSRT: number = 0,
        public hashCSRT: string = "",
    ) { }
}
