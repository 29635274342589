import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { useStyles } from './accordion-sinc-dados-styles';
import { useCallback, useState } from 'react';
import { SincronizarIcon } from 'views/components/icons/sincronizar-icon';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { useToastSaurus } from 'services/app';
import { EnumStatusSincronizacaoGeral } from 'model/enums/enum-status-sincronizacao-geral';

export const AccordionSincDados = () => {

  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);
  const [carregandoInt, setCarregandoInt] = useState<boolean>(false)

  // AUX
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const classes = useStyles();
  const { showToast } = useToastSaurus()

  // HOOKS
  const { iniciarSincronizacaoGeral } = useSincronizacaoGeral()


  const handleClick = useCallback(async () => {
    setCarregandoInt(true)
    const res = await iniciarSincronizacaoGeral()
    if (res === EnumStatusSincronizacaoGeral.Erro)
      showToast('error', 'Ocorreu algum problema ao sincronizar, tente novamente mais tarde.')
    else if (res === EnumStatusSincronizacaoGeral.Sucesso)
      showToast('success', 'Sincronizado com sucesso!')
    else
      showToast('info', 'Não há nada para sincronizar.')

    setCarregandoInt(false)
  }, [iniciarSincronizacaoGeral, showToast])

  return (
    <AccordionSaurus
      labelPrimary={`Sincronização de Dados`}
      tituloChildren={
        <SincronizarIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classNames(classesModal.root, classes.root)}>
        <div className={classesModal.content}>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', fontWeight: '500' }}
                >
                  Clique no botão para sincronizar suas vendas, sessões e valores e deixe tudo em dia!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={carregandoInt}
                >
                  <SincronizarIcon tipo="BUTTON_PRIMARY" />
                  Iniciar Sincronização
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </AccordionSaurus>
  );
};
