import { EnumTpAmb } from "model/enums/enum-tpamb";
export class ConfigNfceFormModel {
  constructor(
    public pfxBase64: string = "",
    public senha: string = "",
    public tpCertificado: number = 0,
    public cIdToken: string = "",
    public csc: string = "",
    public tpAmb: EnumTpAmb = 2,
    public cnpj: string = "",
    public xContato: string = "",
    public email: string = "",
    public fone: string = "",
    public idCSRT: number | null = null,
    public hashCSRT: string | null = null,
  ) { }
}
