import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#9D9D9D',
        lineHeight: '14,1px',
        textAlign: 'justify',
        marginRight: '3px'
    },
    buttomBottom: {
        padding: '8px'
    },
    qrcode: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1)
    },
    qrText: {
        textAlign: 'center',
    },
    iconOferta: {
        width: '55px',
        height: '55px',
        marginLeft: '8px',
        marginBottom: '0'
    }
}));
