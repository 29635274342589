import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetConfigEmpresaId() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConfigEmpresaId = useCallback(
        (empresaId: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Empresa/${empresaId}/configuracao/detalhado`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getConfigEmpresaId,
    };
}
