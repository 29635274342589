import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    celulaGrid: {
        marginLeft: theme.spacing(2),
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    celulaGridFull: {
        marginLeft: theme.spacing(2),
        flex: "1 1 auto",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    cardContent: {
        flex: "1 1 100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        "& span": {
            fontSize: "0.7em",
        },
        "& p": {
            fontSize: "1.0em",
        },
    },
    topStatusContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderRadius: '5px',
        height: '25px',
        background: '#EEEEEE',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '5px',
    }
}));

