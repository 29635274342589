import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const WhatsappIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          d="M40.5364 10.4562C36.5447 4.0428 30.6694 0.387596 23.1121 0.0288034C14.9045 -0.352414 8.31146 3.05612 3.84885 9.91803C-0.882867 17.1836 -1.17439 24.9425 2.54818 32.7911C2.88456 33.4862 2.95184 34.0693 2.72759 34.8093C1.87543 37.5451 1.06812 40.3033 0.283242 43.0615C-0.142836 44.5864 0.57477 45.2816 2.12211 44.8555C4.99253 44.0706 7.88538 43.3082 10.7334 42.5009C11.2716 42.3439 11.6977 42.3888 12.2134 42.6355C15.2857 44.1828 18.5373 45.0349 21.9908 44.99C22.5066 44.99 23.0448 45.0125 23.5606 44.99C24.0764 44.9452 24.5921 44.8555 25.1079 44.7882C30.8712 43.9585 35.6477 41.29 39.2133 36.7154C45.0663 29.1135 45.6045 18.5963 40.5364 10.4562ZM33.1361 29.1359C32.6203 31.8717 29.9517 33.7329 27.171 33.2396C21.5872 32.2529 17.3039 29.2256 14.0523 24.651C12.6171 22.6103 11.1146 20.5921 10.9128 17.9684C10.7558 15.7708 11.4734 13.9545 13.1329 12.4969C13.5814 12.0932 14.142 12.0259 14.7699 12.0708C15.7566 11.8914 16.3172 12.452 16.676 13.349C17.1694 14.5823 17.6403 15.8605 18.1785 17.0715C18.6719 18.2151 17.9767 18.9776 17.3264 19.6279C16.4294 20.5024 16.5415 21.22 17.1918 22.1618C18.8961 24.6958 21.0938 26.6467 23.897 27.9025C24.637 28.2389 25.1752 28.2165 25.6013 27.4989C25.7582 27.2522 25.9825 27.0504 26.1619 26.8037C27.5971 24.9649 27.171 24.8528 29.6826 25.9964C30.5348 26.3777 31.3421 26.871 32.1943 27.2298C33.0464 27.6334 33.2931 28.2389 33.1361 29.1359Z"
        />
      </DefaultIcon>
    </>
  );
};
