
import { guidEmpty } from "utils/guid-empty";

export class DepositoEmpresaPostModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public depositoId: string = guidEmpty(),
    ) { }
}
