import { useCallback, FocusEvent, useState, useEffect } from "react";
import {
  retornoAutoComplete,
  AutocompleteSaurus,
  NovoItemModel,
} from "../autocomplete-saurus/autocomplete-saurus";
import { useGetProdutoVariacaoModificador } from "data/api/gestao/produto/produto-variacao/get-produto-variacao-modificador";
import { ModificadorModel } from "model/api/gestao/modificador/modificador-model";
import { useGetModificadores } from "data/api/gestao/modificador/get-modificadores";
import { guidEmpty } from "utils/guid-empty";

export interface AutocompleteProdModificadorProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  empresaId?: string;
  permiteAdicionar?: boolean;
  textNovoItem?: NovoItemModel[];
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  produtoId?: string
  produtoGradeId?: string
}

export const AutocompleteProdModificador = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteProdModificadorProps) => {
  const [modificadores, setModificadores] = useState<Array<ModificadorModel>>([]);
  const { getProdutoVariacaoModificador, carregando: carregandoGetProdMod } = useGetProdutoVariacaoModificador()
  const { getModificadores, carregando: carregandoGetMod } = useGetModificadores()

  const carregando = [carregandoGetMod, carregandoGetProdMod].includes(true)

  const getProdutosWrapper = useCallback(
    async () => {
      try {
        let modificadores: Array<ModificadorModel> = [];
        if (props.produtoId && props.produtoId !== guidEmpty()) {
          const ret = await getProdutoVariacaoModificador(props.produtoId ?? '', props.produtoGradeId ?? '');
          if (ret.erro) throw ret.erro;
          if (ret.resultado?.data?.length > 0) {
            modificadores = ret.resultado?.data as Array<ModificadorModel>;
          }
        } else {
          const query = `&pageSize=100`
          const ret = await getModificadores(query, 1);
          if (ret.erro) throw ret.erro;
          if (ret.resultado?.data?.list.length > 0) {
            modificadores = ret.resultado?.data.list as Array<ModificadorModel>;
          }
        }

        setModificadores([
          {
            ...new ModificadorModel(),
            nome: 'Sem Modificador'
          },
          ...modificadores
        ]);
      } catch (e: any) { }
    },
    [getModificadores, getProdutoVariacaoModificador, props.produtoGradeId, props.produtoId]
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    getProdutosWrapper()
  }, [getProdutosWrapper])

  return (
    <AutocompleteSaurus
      inputRef={props.inputRef}
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      opcoes={modificadores}
      optionLabelKey="nome"
      optionValueKey="nome"
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={props.permiteAdicionar}
    />
  );
};
