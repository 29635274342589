import { useCallback } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from '../../../../utils';
import { usePostComanda } from 'data/api/gestao/comandas/post-comandas';
import { EscaneandoComanda } from 'views/pages/private/movimentacao/mov-leitor-comanda/components/escaneando-comanda/escaneando-comanda';
import { ComandasEditModel } from 'model/api/gestao/comanda/comanda-edit-model';

interface ComandasCadastroRfidProps {
    cadastrarCodigo?: boolean;
}

export const ComandasCadastroRfid = ({ cadastrarCodigo }: ComandasCadastroRfidProps) => {
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { postComanda, carregando } = usePostComanda();
    const { fecharCadastroComandas } = useCadastros();

    const classes = useModalStyles();
    const { showToast } = useToastSaurus();

    const saveNewComandas = useCallback(
        async (model: ComandasEditModel) => {
            try {
                const ret = await postComanda(model, getEmpresaSelecionada()!.Id)
                if (ret.erro) {
                    throw ret.erro;
                }
                showToast('success', 'Comanda criada com sucesso!');

                return true

            } catch (e: any) {
                throw e.message
            }
        },
        [postComanda, getEmpresaSelecionada, showToast],
    );

    const handleSubmit = useCallback(
        async (rfId: string, codigo?: string) => {
            const model = new ComandasEditModel()
            model.rfId = rfId
            model.codigoComanda = !codigo ? rfId : codigo
            return await saveNewComandas(model)
        },
        [saveNewComandas],
    );

    const onCloseClick = useCallback(() => {
        fecharCadastroComandas();
    }, [fecharCadastroComandas]);

    return (
        <div className={classes.root}>
            {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
            <ModalHeader
                title={'Gerar Comandas'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={onCloseClick}
                    />
                }
            />
            <EscaneandoComanda
                closeEscaneando={() => fecharCadastroComandas()}
                labelVoltar='Fechar'
                confirmarComanda={handleSubmit}
                formCodigo={cadastrarCodigo}
            />
        </div>
    );
};