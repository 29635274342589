import { Box, Grid, Typography } from "@material-ui/core"
import { DefaultCard } from "../components"
import { PessoaEnderecoModel } from "model/api/gestao/pessoa"
import { EditarIcon } from "views/components/icons";
import { useStyles } from './card-pessoa-endereco-styles'
import classNames from "classnames";

interface CardPessoaEnderecoProps {
    model: PessoaEnderecoModel;
    onClick: (model: PessoaEnderecoModel) => void;
}

export const CardPessoaEndereco = ({
    model,
    onClick
}: CardPessoaEnderecoProps) => {
    const classes = useStyles();
    return (
        <DefaultCard onClick={() => onClick(model)} className={classes.card}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography color="textPrimary" variant="caption">
                        Logradouro
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.overflowText)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.logradouro}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography color="textPrimary" variant="caption">
                        Número
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.numero}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography color="textPrimary" variant="caption">
                        Bairro
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.overflowText)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.bairro || '-'}
                    </Typography>
                </Grid>
            </Grid>
            <Box className={classes.iconContainer}>
                <EditarIcon tipo='BUTTON' />
            </Box>
        </DefaultCard>
    )
}