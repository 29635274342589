import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const SATIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M21.6259 47.6798C18.732 47.5704 15.8868 47.169 13.2859 45.7946C10.5629 44.3472 8.97549 42.0727 8.37716 39.0928C8.18179 38.132 8.09631 37.1589 8.09631 36.1737C8.09631 35.7359 8.34053 35.4926 8.79233 35.4805C9.50056 35.4683 10.221 35.4683 10.9292 35.4683C11.4421 35.4683 11.6741 35.7116 11.6863 36.2102C11.7107 37.4873 11.8573 38.728 12.3701 39.9078C13.0783 41.5133 14.3361 42.5228 15.9235 43.1796C17.1568 43.6904 18.4389 43.9702 19.7577 44.1526C21.1741 44.3472 22.5906 44.4323 24.0193 44.4445C26.3637 44.4688 28.6838 44.335 30.9794 43.8242C32.347 43.5201 33.6536 43.0458 34.7892 42.1822C36.1202 41.1849 36.8406 39.8348 37.1581 38.2293C37.2924 37.5482 37.3413 36.8549 37.3413 36.1616C37.3413 35.7237 37.5855 35.4683 38.0251 35.4683C38.7577 35.4561 39.4904 35.4561 40.2352 35.4561C40.6382 35.4561 40.9191 35.7116 40.9191 36.0886C40.9313 37.9617 40.6504 39.7861 39.8567 41.5133C38.9409 43.4958 37.4512 44.9189 35.5097 45.904C33.9467 46.7068 32.2616 47.1325 30.5398 47.4001C29.5263 47.5582 28.5006 47.6433 27.4627 47.6798C27.4139 47.6798 27.365 47.6677 27.3406 47.7285C25.802 47.7285 24.2757 47.7285 22.7371 47.7285C22.6761 47.6555 22.5906 47.6798 22.5173 47.6798C22.2243 47.6798 21.9312 47.6798 21.6259 47.6798Z" />
                <path d="M21.6259 47.6798C21.9189 47.6798 22.2242 47.6798 22.5172 47.6798C22.5905 47.6798 22.676 47.6555 22.737 47.7285C22.3707 47.7285 21.9922 47.7285 21.6259 47.7285C21.6259 47.7163 21.6381 47.7042 21.6381 47.692C21.6381 47.692 21.6381 47.6798 21.6259 47.6798Z" />
                <path d="M24.5077 3.00557C25.8142 2.99341 27.1208 3.00557 28.4274 3.10287C30.7596 3.27316 33.0431 3.66237 35.1922 4.67189C38.1716 6.07062 39.9666 8.40589 40.6382 11.6047C40.8336 12.5656 40.9191 13.5386 40.9313 14.5238C40.9313 15.0225 40.6993 15.2536 40.1986 15.2658C39.4904 15.2658 38.7944 15.2779 38.0861 15.2779C37.6099 15.2779 37.3535 15.0225 37.3535 14.5481C37.3413 13.2954 37.1947 12.0548 36.6941 10.8871C36.0591 9.41541 34.9602 8.41805 33.5315 7.73693C32.1761 7.0923 30.7352 6.77606 29.2577 6.5693C27.8412 6.37469 26.4126 6.28955 24.9717 6.27739C22.4563 6.25306 19.9652 6.39902 17.5231 7.04365C16.131 7.40853 14.8367 7.96803 13.7621 8.96538C12.7731 9.8776 12.2114 11.0209 11.9305 12.3223C11.7718 13.0521 11.7107 13.8062 11.7107 14.5481C11.6985 15.0225 11.4665 15.2658 11.0025 15.2658C10.2943 15.2658 9.57382 15.2779 8.86559 15.2779C8.37716 15.2779 8.12073 15.0225 8.12073 14.536C8.13294 12.5048 8.46263 10.5466 9.4395 8.73429C10.4896 6.77606 12.1137 5.45031 14.1285 4.55026C15.6426 3.8813 17.23 3.51641 18.8663 3.28532C20.7101 3.0299 22.6028 2.98125 24.5077 3.00557Z" />
                <path d="M44.7532 30.1288C44.5212 30.1288 44.3625 30.0072 44.2159 29.8612C42.5797 28.2314 40.9434 26.6137 39.3194 24.9839C39.1484 24.8136 39.0752 24.8379 38.9164 24.9961C37.2924 26.6259 35.6683 28.2435 34.0443 29.8612C33.6658 30.2383 33.3361 30.2383 32.9575 29.8734C32.4691 29.399 31.9929 28.9125 31.5044 28.4381C31.1503 28.0854 31.1503 27.7449 31.4922 27.3921C33.8489 25.0447 36.2178 22.6851 38.5745 20.3377C38.9408 19.9728 39.2705 19.9728 39.6369 20.3255C41.9936 22.6729 44.3502 25.0082 46.7069 27.3556C47.0977 27.7449 47.0977 28.0611 46.7069 28.4503C46.2185 28.9368 45.7301 29.4233 45.2416 29.8977C45.1073 30.0436 44.9486 30.1409 44.7532 30.1288Z" />
                <path d="M9.91568 30.3234C9.67146 30.3356 9.51272 30.2139 9.35398 30.068C7.2415 27.9638 5.14124 25.8718 3.02876 23.7676C2.77234 23.5122 2.5037 23.2568 2.24727 23.0013C1.91758 22.6729 1.91758 22.3202 2.24727 21.9918C2.7357 21.5053 3.23635 21.0188 3.72478 20.5201C4.0789 20.1674 4.4208 20.1674 4.77491 20.5201C6.41117 22.1499 8.04742 23.7798 9.68367 25.4218C9.84241 25.5799 9.91568 25.5799 10.0744 25.4218C11.6985 23.7919 13.3225 22.1743 14.9465 20.5566C15.3495 20.1552 15.6548 20.1674 16.0577 20.5688C16.5462 21.0553 17.0346 21.5418 17.523 22.0283C17.8283 22.3324 17.8283 22.6851 17.5352 22.9892C15.1786 25.3366 12.8219 27.6962 10.453 30.0437C10.3064 30.1774 10.1477 30.3356 9.91568 30.3234Z" />
            </DefaultIcon>
        </>
    );
};

