import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { PessoaEnderecoModel } from "model/api/gestao/pessoa/pessoa-endereco-model";
import { VariaveisAmbiente } from "config";
export function usePostEnderecoPessoa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postEnderecoPessoa = useCallback(
    (endereco: PessoaEnderecoModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/pessoa/${endereco.pessoaId}/endereco`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data:JSON.stringify(endereco),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postEnderecoPessoa,
  };
}
