import { useEffect, useState, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { isEmpty } from "lodash";
import { MovGerenciamentoSessoesSearchProps } from "./mov-gerenciamento-sessoes-search-props";
import { useStyles } from "./mov-gerenciamento-sessoes-styles";
import { MovGerenciamentoSessoesListData } from "./mov-gerenciamento-sessoes-list-data";
import { useGetSessaoGerenciamento } from "data/api/gestao/sessao/get-sessao-gerenciamento";
import { SessaoGerenciamentoModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";

export interface MovGerenciamentoSessoesListProps {
  searchProps: MovGerenciamentoSessoesSearchProps;
}

export const MovGerenciamentoSessoesList = (props: MovGerenciamentoSessoesListProps) => {

  //AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const {addHandler, removeHandler} = useEventTools()

  //PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();

  //CHAMADAS DA API
  const { getSessaoGerenciamento, carregando } = useGetSessaoGerenciamento();

  //STATES
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<SessaoGerenciamentoModel>(),
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<SessaoGerenciamentoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        "&pageSize=-1" +
        "" +
        (!isEmpty(props.searchProps.dInicial)
          ? "&dtInicial=" + props.searchProps.dInicial
          : "") +
        (!isEmpty(props.searchProps.dFinal)
          ? "&dtFinal=" + props.searchProps.dFinal
          : "") +
        (props.searchProps.status > -1
          ? "&statusSessao=" + props.searchProps.status
          : "");
      try {
        const res = await getSessaoGerenciamento(newPage, query, getEmpresaSelecionada()?.Id!);
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [
      props.searchProps.dInicial,
      props.searchProps.dFinal,
      props.searchProps.status,
      getSessaoGerenciamento,
      getEmpresaSelecionada,
      fillResult,
      showToast,
    ],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    search(queryStatus.page);
  }, [queryStatus.page, search]);

  const atualizar = useCallback((aberto:boolean)=>{
if(!aberto){
  search(queryStatus.page);
}
  },[queryStatus.page, search])

  useEffect(()=>{
    addHandler(AppEventEnum.DialogEditarGerenciamentoSessao, atualizar)
    return () => removeHandler(AppEventEnum.DialogEditarGerenciamentoSessao, atualizar)
  },[addHandler, atualizar, removeHandler])

  const operadoresUnicos = Array.from(new Set<string>(queryStatus.list.map((item) => item.operadorId)));

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={operadoresUnicos.length}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <MovGerenciamentoSessoesListData
              carregando={carregando}
              list={queryStatus.list}
              operadoresUnicos={operadoresUnicos}
              searchProps={props.searchProps}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
