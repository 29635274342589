import { Grid, useTheme } from '@material-ui/core';
import Cart from '../Cart/cart';
import FastPayment from '../fast-payment/fast-payment';
import { FinalizarVendaButton } from '../../../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { isPlanoControleMesasComandas } from 'utils/plano-utils';
import { usePDV } from 'services/app/hooks/pdv';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumComandas } from 'model/enums/enum-comandas';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

interface CartWrapperProps {
  componentTaxa: JSX.Element;
  isPageCarrinho?: boolean;
}

export const CartWrapper = ({
  componentTaxa,
  isPageCarrinho = false
}: CartWrapperProps) => {
  // STATES E REFS
  const firstRender = useRef<boolean>(true);
  const [validateFlow, setValidateFlow] = useState<'fastPayment' | 'order' | 'validate'>(
    'fastPayment'
  );

  // HOOKS
  const { getMov, setTipoMovimentacao } = useMovAtual();
  const { getConfigByCod } = usePDV();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { plano } = useSessaoAtual();
  const { showToast } = useToastSaurus();

  // AUX
  const { palette } = useTheme();
  const configAtendimento = getConfiguracoesMesaEComanda();

  const hasConfigAtendimento =
    (configAtendimento?.qtdeComandas ?? 0) > 0 ||
    (configAtendimento?.qtdeMesa ?? 0) > 0 ||
    (configAtendimento?.qtdeSaloes ?? 0) > 0;

  const modeloTrabalho = getConfigByCod(101);
  const isLancadorPedidos = [
    EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const valideteFlow = useCallback(async () => {
    try {

      if (getMov()!.vNF > 0 && (getMov()!.vPago > 0 && getMov()!.vPago >= getMov()!.vNF)) {
        setValidateFlow('validate');
        return;
      }
      if (
        !((getMov()?.informacoesGeraisPedido?.pedidos?.length || 0) > 0) &&
        isPlanoControleMesasComandas(plano?.plano)
      ) {
        if (
          isLancadorPedidos &&
          getMov()?.id &&
          (getMov()?.informacoesGeraisPedido?.comandaId ||
            getMov()?.informacoesGeraisPedido?.mesaId ||
            getMov()?.informacoesGeraisPedido.balcaoIdentificado)
        ) {
          setValidateFlow('order');
          return;
        }

        // aqui verifico se o tipo de caixa é apenas lancador de pedidos
        if (isSomentePedido && navigator.onLine) {
          setValidateFlow('order');
          return;
        }

        const quantidadeMesa = await TouchoneDBPrimary.mesas.toArray();
        const mesasAtivas = quantidadeMesa.filter(
          (mesa) => mesa.status?.codigo === EnumMesas.ATIVO
        );
        const quantidadeComandas = await TouchoneDBPrimary.comandas.toArray();
        const comandasAtivas = quantidadeComandas.filter(
          (comanda) => comanda.status!.codigo === EnumComandas.ATIVO
        );

        if (
          hasConfigAtendimento &&
          isLancadorPedidos &&
          getMov()?.vNF &&
          getMov()!.vNF > 0 &&
          (mesasAtivas.length > 0 || comandasAtivas.length > 0) &&
          navigator.onLine
        ) {
          if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.COMANDA
          ) {
            await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO, true);
            setValidateFlow('order');
            return;
          } else {
            await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO, true);
            setValidateFlow('order');
            return;
          }
        }
      }

      setValidateFlow('fastPayment');
      return;
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    getConfiguracoesMesaEComanda,
    getMov,
    hasConfigAtendimento,
    isLancadorPedidos,
    isSomentePedido,
    plano?.plano,
    setTipoMovimentacao,
    showToast
  ]);

  const movProdAlterado = useCallback(
    (any: any) => {
      valideteFlow();
    },
    [valideteFlow]
  );

  useEffect(() => {
    if (firstRender.current) {
      valideteFlow();
      firstRender.current = false;
    }

    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler, valideteFlow]);

  return (
    <>
      <Cart />
      {validateFlow === 'fastPayment' && <FastPayment />}
      {((isPageCarrinho && validateFlow === 'order') || validateFlow === 'validate') && (
        <Grid
          style={{
            background: palette.grey[100],
            width: '100%'
          }}
        >
          <Grid style={{ display: 'flex', width: '100%' }}>
            <div
              style={{
                width: '100%'
              }}
            >
              <Grid>{componentTaxa}</Grid>
              <FinalizarVendaButton paddingLeft label={validateFlow === 'validate' ? 'Validar' : ''} />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
