import { ModulosRetaguardaMock } from 'data/mocks';
import { EnumMenu } from 'model';
import { useCallback } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useSessaoAtual } from 'services/app';
import { consoleDev } from 'utils/console-dev';
import { isPlanoComVendedor, isPlanoControleMesasComandas, isPlanoControleProducao, isPlanoFiscal, isPlanoRelatoriosBasicos } from 'utils/plano-utils';

export const PlanoValidationRoute: React.FC<RouteProps> = ({
  children,
  ...otherProps
}) => {
  consoleDev('PlanoValidationRoute');

  const { plano } = useSessaoAtual()
  const history = useHistory()
  const pathname = history.location.pathname

  const rotaMockPorGroup = useCallback((enumMenu: EnumMenu) => {
    return ModulosRetaguardaMock
      .filter(x => x.codigoPai === enumMenu)
      .map(item => item.rota).includes(pathname)
  }, [pathname])

  const rotaMockPorCodigo = useCallback((enumMenu: EnumMenu) => {
    return ModulosRetaguardaMock
      .find(x =>
        x.codigo === enumMenu)?.rota === pathname
  }, [pathname])

  const rotaDashboard = useCallback(() => {
    return ModulosRetaguardaMock
      .filter(x => x.codigoPai === EnumMenu.RELATORIOS_GROUP &&
        x.codigo !== EnumMenu.RELATORIOS_HISTORICO_VENDAS)
      .map(item => item.rota).includes(pathname)
  }, [pathname])


  const route = () => {
    return !isPlanoFiscal(plano?.plano) && rotaMockPorGroup(EnumMenu.FISCAL_GROUP) ? (
      <Redirect to="/" />
    ) : !isPlanoRelatoriosBasicos(plano?.plano) && rotaDashboard() ? (
      <Redirect to="/" />
    ) : !isPlanoComVendedor(plano?.plano) && rotaMockPorCodigo(EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR) ? (
      <Redirect to="/venda-simples/landing?verificarCadastros=true" />
    ) : !isPlanoControleProducao(plano?.plano) && rotaMockPorCodigo(EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS) ? (
      <Redirect to="/" />
    ) : !isPlanoControleMesasComandas(plano?.plano) && rotaMockPorCodigo(EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS) ? (
      <Redirect to="/" />
    ) : !isPlanoControleMesasComandas(plano?.plano) && rotaMockPorCodigo(EnumMenu.ADMINISTRATIVO_SALOES) ? (
      <Redirect to="/" />
    ) : !isPlanoControleMesasComandas(plano?.plano) && (rotaMockPorCodigo(EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS) || rotaMockPorCodigo(EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA)) ? (
      <Redirect to="/venda-simples/landing?verificarCadastros=true" />
    ) : children;
  };
  return <Route {...otherProps}>{route()}</Route>;
};
