import React, { useCallback, useRef, useState } from 'react'
import { Button, FormHelperText } from "@material-ui/core";
import { ArquivoInputProps, EnumTipoRetornoArquivo } from './arquivo-input-props';
import { isEmpty } from 'lodash';
import classNames from "classnames";
import { useStyles } from './arquivo-input-styles';

function ArquivoInput({ ...props }: ArquivoInputProps) {
    const ArquivoBlobParaBase64 = (arquivo: File): Promise<string> => {
        return new Promise((resolve) => {
            if (!arquivo) {
                resolve("");
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                resolve((event.target?.result?.toString() || "").replace("data:application/x-pkcs12;base64,", ""));
            };

            reader.readAsDataURL(arquivo);
        });
    };
    const classes = useStyles();
    const { accept, onChange, value, className, helperText, error, name, onBlur } = props;

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [carregandoImportacao, setCarregandoImportacao] = useState(false);
    const [nomeArquivo, setNomeArquivo] = useState("")

    const abrirSelecaoDeArquivo = useCallback(() => {
        inputFileRef.current?.click();
    }, []);

    const carregarArquivo = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (isEmpty(event.target.files) || carregandoImportacao) {
                return;
            }

            setCarregandoImportacao(true);

            const arquivo = event.target.files![0];

            let retorno: string = ''

            switch (props.tipoRetorno) {
                case EnumTipoRetornoArquivo.String:
                    const retornarArquivo = () => new Promise<string>((resolve) => {
                        const reader = new FileReader();

                        reader.onload = (ev: ProgressEvent<FileReader>) => {
                            if (ev.target && ev.target?.result) {
                                const content = ev.target?.result as string;

                                resolve(content)
                            }
                        }

                        reader.readAsText(arquivo, 'ISO-8859-4')
                    })
                    retorno = await retornarArquivo();
                    break;
                default:
                    retorno = await ArquivoBlobParaBase64(arquivo);
            }


            const dados = {
                arquivo,
                base64: retorno,
                tipoRetorno: props.tipoRetorno ? EnumTipoRetornoArquivo.Base64 : props.tipoRetorno
            };

            if (onChange) {
                onChange(dados);
                setNomeArquivo(dados.arquivo.name)
            }

            setTimeout(() => {
                setCarregandoImportacao(false);
            }, 500);
        },
        [carregandoImportacao, onChange, props.tipoRetorno]
    );

    const onBlurWrap = useCallback(
        (event?: any) => {
            if (onBlur) {
                onBlur(name || "");
            }
        },
        [onBlur, name]
    );

    return (
        <div className={classNames(className)} onBlur={onBlurWrap}>
            <input
                disabled={props.disabled}
                ref={inputFileRef}
                name={name}
                type="file"
                multiple={false}
                onChange={carregarArquivo}
                accept={accept}
                hidden
                value=''
            />
            {!value && (
                <>
                    <Button
                        disabled={props.disabled}
                        variant="contained"
                        color="primary"
                        className={classes.selecionarArquivo}
                        fullWidth
                        component="label"
                        onClick={abrirSelecaoDeArquivo}
                    >
                        Selecione um <span style={{ color: '#1F9CE4', marginLeft: 4 }}>arquivo</span>
                    </Button>
                </>
            )}
            {value && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.selecionarArquivo}
                        fullWidth
                        component="label"
                        onClick={abrirSelecaoDeArquivo}
                    >
                        <span style={{ color: '#1F9CE4', marginLeft: 4 }}>
                            {nomeArquivo || props.nameArquivo}
                        </span>
                    </Button>

                    <div className={classes.trocarArquivo}>
                        <Button
                            component="label"
                            onClick={abrirSelecaoDeArquivo}
                        >Trocar arquivo</Button>
                    </div>

                </>
            )}
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>
    )
}

export default ArquivoInput
