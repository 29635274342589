
import { Grid } from '@material-ui/core';
import { PessoaCadastroCard } from './pessoa-cadastro-card';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { EnumCadastroTipo } from 'model';
import { useStyles } from '../pessoa-cadastro-steps-styles';
import { FornecedorIcon } from 'views/components/icons/fornecedor-icon';
import { VendedorIcon2 } from 'views/components/icons/vendedor-icon-2';
import { TransportadoraIcon } from 'views/components/icons/transportadora-icon';
import { PrescritorIcon } from 'views/components/icons/prescritor-icon';
import { ResponsavelTecnicoIcon } from 'views/components/icons/responsavel-tecnico-icon';
import { useSessaoAtual } from 'services/app';
import { isPlanoComVendedor, isPlanoFarmaceutico } from 'utils/plano-utils';
import { useThemeQueries } from 'views/theme';
import { FuncionarioIcon } from 'views/components/icons/funcionario-icon';
import { useEffect, useMemo } from "react"
import { EnumTour } from "model/enums/enum-tour"
import { useTourSaurus } from "services/app/hooks/tour-saurus"

interface PessoaCadastroTipoProps {
  onClick: (tipo: EnumCadastroTipo) => void;
}

export const PessoaCadastroTipo = ({ onClick }: PessoaCadastroTipoProps) => {
  const classes = useStyles({});
  const { plano } = useSessaoAtual();
  const { theme } = useThemeQueries();
  const planoComVendedor = isPlanoComVendedor(plano?.plano);
  const isFarmaceutico = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

  const { callTour } = useTourSaurus()

  useEffect(() => {
    if (!isFarmaceutico) {
      setTimeout(() => { callTour(EnumTour.CADASTROPESSOASCARDS) }, 1000)
    }
  }, [callTour, isFarmaceutico])


  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={classes.cardContainer}>
        <PessoaCadastroCard
          icon={
            <PessoaIcon
              tipo="BUTTON"
              fill={theme.palette.secondary.main}
              style={{ margin: 0 }}
            />
          }
          title="Cliente"
          tipo={EnumCadastroTipo.CLIENTE}
          onClick={onClick}
        />
      </Grid>
      <Grid item xs={4} className={classes.cardContainer}>
        <PessoaCadastroCard
          icon={
            <FuncionarioIcon
              tipo="BUTTON"
              fill={theme.palette.secondary.main}
            />
          }
          title="Funcionário"
          tipo={EnumCadastroTipo.FUNCIONARIO}
          onClick={onClick}
        />
      </Grid>
      {planoComVendedor && (
        <Grid item xs={4} className={classes.cardContainer}>
          <PessoaCadastroCard
            icon={
              <VendedorIcon2
                tipo="BUTTON"
                fill={theme.palette.secondary.main}
              />
            }
            title="Representante"
            tipo={EnumCadastroTipo.REPRESENTANTE}
            onClick={onClick}
          />
        </Grid>
      )}
      <Grid item xs={4} className={classes.cardContainer}>
        <PessoaCadastroCard
          icon={
            <FornecedorIcon tipo="BUTTON" fill={theme.palette.secondary.main} />
          }
          title="Fornecedor"
          tipo={EnumCadastroTipo.FORNECEDOR}
          onClick={onClick}
        />
      </Grid>
      <Grid item xs={4} className={classes.cardContainer}>
        <PessoaCadastroCard
          icon={
            <TransportadoraIcon
              tipo="BUTTON"
              fill={theme.palette.secondary.main}
            />
          }
          title="Transportadora"
          tipo={EnumCadastroTipo.TRANSPORTADORA}
          onClick={onClick}
        />
      </Grid>
      {isFarmaceutico && (
        <Grid item xs={4} className={classes.cardContainer}>
          <PessoaCadastroCard
            icon={
              <ResponsavelTecnicoIcon
                tipo="BUTTON"
                fill={theme.palette.secondary.main}
              />
            }
            title="Responsável Técnico"
            tipo={EnumCadastroTipo.RESPONSAVEL_TECNICO}
            onClick={onClick}
          />
        </Grid>
      )}
      {isFarmaceutico && (
        <Grid item xs={4} className={classes.cardContainer}>
          <PessoaCadastroCard
            icon={
              <PrescritorIcon
                tipo="BUTTON"
                fill={theme.palette.secondary.main}
              />
            }
            title="Prescritor"
            tipo={EnumCadastroTipo.PRESCRITOR}
            onClick={onClick}
          />
        </Grid>
      )}
    </Grid>
  );
};
