import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Fade,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./accordion-saurus-secondary-styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { SwitchSaurus } from "views/components/controles";

interface AccordionSaurusSecondaryProps extends AccordionProps {
  tituloChildren?: React.ReactNode;
  expandChildren?: React.ReactNode;
  labelPrimary?: string;
  labelSecondary?: React.ReactNode;
  colorDivider?: string;
  heightDivider?: number;
  noIconRotation?: boolean;
  noPaperRoot?: boolean;
  noPaddingContent?: boolean;
  tipoExpand?: "padrao" | "bold";
  colorExpand?: string;
  expandSize?: number;
  showDivider?: boolean;
  sombreado?: boolean;
  betweenTitles?: boolean;
  labelColor?: string;
  titleId?: string;
  botaoChecked?: boolean,
  labelIcon?: JSX.Element,
  handleBotao?: any
  titleBold?:boolean

}

export const AccordionSaurusSecondary = ({
  tituloChildren,
  expandChildren,
  labelPrimary,
  labelSecondary,
  colorDivider,
  showDivider,
  heightDivider,
  noPaperRoot,
  noPaddingContent,
  tipoExpand,
  colorExpand,
  expandSize,
  sombreado,
  labelColor,
  botaoChecked,
  labelIcon,
  handleBotao,
  titleBold,
  ...props
}: AccordionSaurusSecondaryProps) => {
  const classes = useStyles({
    labelColor
  });

  return (
    <>
      <Accordion
        {...props}
        className={classes.sombreado}
      >
        <AccordionSummary
          className={classes.cardContainer}
        >
              {labelPrimary && (
                <Grid xs={12} className={classes.cardHeader} >
                  {labelIcon &&
                    labelIcon
                  }
                  <Typography variant="h6" display="inline" className={titleBold ? classes.titleBold : classes.title}>
                   
                    {labelPrimary}
                  </Typography>

                </Grid>
              )}

          
            <SwitchSaurus variant='LIGHT' color="primary" onChange={handleBotao} checked={botaoChecked}></SwitchSaurus>
           
        </AccordionSummary>
        <Fade in={showDivider} mountOnEnter unmountOnExit>
          <Grid xs={12} className={classes.gridDivider}>
            <div className={classes.divider} style={{ background: colorDivider }} />
          </Grid>
        </Fade>
        <AccordionDetails
          classes={{
            root: noPaddingContent
              ? classes.accordionContentNoPadding
              : classes.accordionContent,
          }}
        >
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};