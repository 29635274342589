import { KeyValueModel } from "model/api";
import { EnumInsercaoFavorita } from "model/enums/enum-insercao-favorita";


export const InsercaoFavoritaMock: KeyValueModel[] = [
  new KeyValueModel(EnumInsercaoFavorita.ATENDIMENTO, "Pedidos"),
  new KeyValueModel(EnumInsercaoFavorita.CATALOGO, "Catálogo de Produtos"),
  new KeyValueModel(EnumInsercaoFavorita.AVULSO, "Lançamento Avulso"),
  new KeyValueModel(EnumInsercaoFavorita.LEITOR, "Leitor de Códigos"),
  new KeyValueModel(EnumInsercaoFavorita.ATENDIMENTO_DELIVERY, "Atendimento Delivery"),
  new KeyValueModel(EnumInsercaoFavorita.CARRINHO, "Carrinho"),
];
