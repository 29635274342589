import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormNcmEditValidation } from './form-ncm-edit-validation';
import { NcmEditFormModel } from 'model/app/forms/ncm/ncm-edit-form-model';
import { AutocompleteNcmsMaster } from '../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-master/autocomplete-ncms-master';
import { NcmMasterSummaryModel } from 'model/api/ncm-master/ncm-master-model';
import { SituacaoGeralMock } from 'data/mocks/situacao-geral-mock';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

export interface FormNcmEditProps extends DefaultFormProps<NcmEditFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}
export const FormNcmEdit = forwardRef<
  DefaultFormRefs<NcmEditFormModel>,
  FormNcmEditProps
>(({ loading, ...props }: FormNcmEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<NcmEditFormModel>(
    new NcmEditFormModel(),
  );
  const { FormNcmEditYupValidation } = useFormNcmEditValidation();
  const { isMobile } = useThemeQueries();

  const [focus, setFocus] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset,
  } = useForm<NcmEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormNcmEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: NcmEditFormModel) => {
    const ncm = picker<NcmEditFormModel>(values, new NcmEditFormModel());

    props.onSubmit(ncm, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInputCodigo.current?.focus();
    },
    fillForm: (model: NcmEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInputCodigo.current?.focus();
      }, 500);
    },
  }));
  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteNcmsMaster
                      inputRef={refInputCodigo}
                      modo="campoFormatado"
                      loadingExterno={loading}
                      label="Código NCM"
                      placeholder="NCM:"
                      ncmId={getValues('ncmId')}
                      error={Boolean(errors.codigo && errors.codigo.message)}
                      helperText={
                        touchedFields.codigo || errors.codigo
                          ? errors.codigo?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('codigo')}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          let ncm = picker<NcmMasterSummaryModel>(
                            retorno.value,
                            new NcmMasterSummaryModel(),
                          );
                          setValue('codigo', ncm.codigo);
                          setValue('cest', ncm.cest);
                          setValue('descricao', ncm.descricao);
                          setValue('ncmId', ncm.id);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cest"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      label="Cest"
                      tipo='NUMERO'
                      variant="outlined"
                      error={Boolean(errors.cest && errors.cest.message)}
                      helperText={
                        touchedFields.cest || errors.cest
                          ? errors.cest?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      fullWidth
                      readOnly
                      label="Descrição"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="ativo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      label={'Situação'}
                      readOnly
                      fullWidth={true}
                      variant="outlined"
                      error={Boolean(errors.ativo && errors.ativo.message)}
                      helperText={
                        touchedFields.ativo || errors.ativo
                          ? errors.ativo?.message
                          : undefined
                      }
                      {...field}
                      value={
                        SituacaoGeralMock.filter(
                          (item) => getValues('ativo') === item.Key,
                        )[0]?.Value
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="pTribManual"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      fullWidth
                      allowSubmit
                      manterMascara
                      label="% Trib. Manual"
                      placeholder={(!focus && getValues('pTribManual') === null) ? 'Utilizando IBPT' : ''}
                      variant="outlined"
                      error={Boolean(
                        errors.pTribManual && errors.pTribManual.message,
                      )}
                      helperText={
                        getValues('pTribManual') !== null ? (
                          `ATENÇÃO: Você está usando Tributo Manual de ${getValues('pTribManual')}%`
                        ) : undefined
                      }
                      {...field}
                      onChange={(e: any) => {
                        const value = e.target.value
                        setValue('pTribManual', value === '' ? null : value)
                      }}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});