import { Button } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToastSaurus } from 'services/app/hooks';
import { useFormStepper } from '../../../../../form-stepper/form-step';
import { FormStep } from '../../../../../../../model/app/form-stepper/form-step';
import { ProdutoCategoriaPreCadastroFormModel } from '../../../../../../../model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-categoria-form-model';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import {
  OkIcon,
  VoltarIcon,
  AvancarIcon,
  BarcodeIcon,
  ProdutoIcon,
  DinheiroIcon,
  SalvarEditarIcon,
  ImpostoIcon,
  NovoIcon
} from 'views/components/icons';
import { ProdutoNovoModel } from '../../../../../../../model/api/gestao/produto/produto/produto-novo-model';
import { picker } from 'utils/picker';
import { ProdutoNomeMedidaPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-nome-medida-form-model';
import { FormProdutoNomeMedidaPreCadastro } from '../../../../../form/produto/produto-pre-cadastro/form-produto-nome-medida/form-produto-nome-medida-pre-cadastro';
import { ProdutoValorVendaCompraPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-valor-compra-venda-form-model';
import { FormProdutoValorVendaCompraPreCadastro } from '../../../../../form/produto/produto-pre-cadastro/form-produto-valor-compra-venda/form-produto-valor-compra-venda-pre-cadastro';
import { usePostProduto } from 'data/api/gestao/produto/produto/post-produto';
import { toDecimal } from '../../../../../../../utils/to-decimal';
import { ProdutoCodigoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-codigo-form-model';
import {
  EnumFormCodigo,
  FormProdutoCodigoPreCadastro
} from '../../../../../form/produto/produto-pre-cadastro/form-produto-codigo/form-produto-codigo-pre-cadastro';
import { ProdutoImpostoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imposto-form-model';
import { ProdutoImagemPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imagem-form-model';
import { imagemForUpload } from '../../../../../../../utils/imagem-for-upload';
import { EnumRetornoApiBase } from '../../../../../../../data/api/base/api-base-response';
import { usePostImagemBase64 } from '../../../../../../../data/api/imagem/post-imagem';
import { useGetInfoEanProduto } from 'data/api/tributario/get-info-ean-produto';
import { ConsultaEanModel } from 'model/api/tributario/consulta-ean-model';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { useGetNcms } from 'data/api/gestao/ncm/get-ncms';

import { ProdutoCompletoPreCadastroFormModel } from '../../../../../../../model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { FormProdutoCompletoPreCadastro } from '../../../../../form/produto/produto-pre-cadastro/form-produto-completo/form-produto-completo-pre-cadastro';
import { NcmMasterSummaryModel } from '../../../../../../../model/api/ncm-master/ncm-master-model';
import { guidEmpty } from '../../../../../../../utils/guid-empty';
import { useGetNcmsMaster } from 'data/api/ncm-master/get-ncms-master';
import { usePostNcm } from 'data/api/gestao/ncm/post-ncm';
import { newGuid } from 'utils/new-guid';
import { useCadastros, useSessaoAtual } from 'services/app';
import { useHistory } from 'react-router';
import { EnumTelaProduto } from 'model/enums/enum-aba-tela-produto';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { FormProdutoNcmPreCadastro } from 'views/components/form/produto/produto-pre-cadastro/form-produto-imposto/form-produto-imposto-pre-cadastro';
import { isPlanoFiscal } from 'utils/plano-utils';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useShowAviso } from 'services/app/hooks/show-aviso';
import { useConfirm } from 'material-ui-confirm';
import { useGetProdutos } from 'data/api/gestao/produto/produto/get-produtos';
import { useGetProdutoExisteCodigo } from 'data/api/gestao/produto/produto-codigo/get-produto-existe-codigo';

export const useProdutoCadastro = (
  contratoId: string,
  empresaId: string,
  voltarTela: () => void
) => {
  const { showToast } = useToastSaurus();
  const { showAviso } = useShowAviso();
  const confirm = useConfirm();
  const { abrirCadastroProduto, fecharCadastroProduto } = useCadastros();
  const [carregandoFromForms, setCarregandoFromForms] = useState(false);
  const { currentStep, nextStep, prevStep, resetSteps } = useFormStepper(7);
  const { postProduto, carregando: carregandoProduto } = usePostProduto();
  const { getProdutos, carregando: carregandoGet } = useGetProdutos();
  const { getProdutoExisteCodigo, carregando: carregandoExiste } =
    useGetProdutoExisteCodigo();
  const { postImagemBase64, carregando: carregandoPostImagem } =
    usePostImagemBase64();
  const { getInfoEanProduto, carregando: carregandoBuscaEan } =
    useGetInfoEanProduto();
  const { getNcms, carregando: carregandoNcms } = useGetNcms();
  const { getNcmsMaster, carregando: carregandoNcmsMaster } =
    useGetNcmsMaster();
  const { plano } = useSessaoAtual();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { postNcm, carregando: carregandoPostNcm } = usePostNcm();
  const loading: boolean =
    carregandoFromForms ||
    carregandoProduto ||
    carregandoPostImagem ||
    carregandoBuscaEan ||
    carregandoNcms ||
    carregandoNcmsMaster ||
    carregandoPostNcm ||
    carregandoGet ||
    carregandoExiste;

  const refPreCadastroCodigo =
    useRef<DefaultFormRefs<ProdutoCodigoPreCadastroFormModel>>(null);
  const refPreCadastroNome =
    useRef<DefaultFormRefs<ProdutoNomeMedidaPreCadastroFormModel>>(null);
  const refPreCadastroValorCompraVenda =
    useRef<DefaultFormRefs<ProdutoValorVendaCompraPreCadastroFormModel>>(null);
  const refPreCadastroImposto =
    useRef<DefaultFormRefs<ProdutoImpostoPreCadastroFormModel>>(null);
  const refPreCadastroProdutoCompleto =
    useRef<DefaultFormRefs<ProdutoCompletoPreCadastroFormModel>>(null);

  //-----------------------------------------refs para os forms no  vai e volta do stepper
  const refCategoriaModelForm = useRef<ProdutoCategoriaPreCadastroFormModel>(
    new ProdutoCategoriaPreCadastroFormModel()
  );
  const refCodigoModelForm = useRef<ProdutoCodigoPreCadastroFormModel>(
    new ProdutoCodigoPreCadastroFormModel()
  );
  const refNomeMedidaModelForm = useRef<ProdutoNomeMedidaPreCadastroFormModel>(
    new ProdutoNomeMedidaPreCadastroFormModel()
  );
  const refValorCompraVendaModelForm =
    useRef<ProdutoValorVendaCompraPreCadastroFormModel>(
      new ProdutoValorVendaCompraPreCadastroFormModel()
    );
  const refNcmModelForm = useRef<ProdutoImpostoPreCadastroFormModel>(
    new ProdutoImpostoPreCadastroFormModel()
  );
  const refImagemModelForm = useRef<ProdutoImagemPreCadastroFormModel>(
    new ProdutoImagemPreCadastroFormModel()
  );

  const redirectToEdit = useRef(false);
  const { location } = useHistory();
  const newPathName = location.pathname.replace('/adicionar', '');

  const tipoProduto = useCallback(() => {
    if (newPathName.includes('produtos')) {
      return EnumTipoProduto.Produto;
    }

    if (newPathName.includes('insumos')) {
      return EnumTipoProduto.Insumo;
    }

    return EnumTipoProduto.Combo;
  }, [newPathName]);

  const redirect = useCallback(() => {
    const url = location.pathname.split('/')[1]
    fecharCadastroProduto('/' + url)
  }, [fecharCadastroProduto, location.pathname])

  const limparValoresRefs = useCallback(() => {
    // refCategoriaModelForm.current = new ProdutoCategoriaPreCadastroFormModel();
    refCodigoModelForm.current = new ProdutoCodigoPreCadastroFormModel();
    refNomeMedidaModelForm.current =
      new ProdutoNomeMedidaPreCadastroFormModel();
    refValorCompraVendaModelForm.current =
      new ProdutoValorVendaCompraPreCadastroFormModel();
    // refNcmModelForm.current = new ProdutoImpostoPreCadastroFormModel();
    refImagemModelForm.current = new ProdutoImagemPreCadastroFormModel();
    resetSteps();
  }, [resetSteps]);

  const getProdutoCompleto = useCallback(() => {
    let produtoCompleto = new ProdutoCompletoPreCadastroFormModel();
    //categoria
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refCategoriaModelForm.current,
      produtoCompleto
    );
    //codigo
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refCodigoModelForm.current,
      produtoCompleto
    );
    //medida e nome
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refNomeMedidaModelForm.current,
      produtoCompleto
    );

    //valores venda e compra
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refValorCompraVendaModelForm.current,
      produtoCompleto
    );
    //ncm
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refNcmModelForm.current,
      produtoCompleto
    );
    //imagem
    produtoCompleto = picker<ProdutoCompletoPreCadastroFormModel>(
      refImagemModelForm.current,
      produtoCompleto
    );
    return produtoCompleto;
  }, []);

  const sendImage = useCallback(
    async (modelo: ProdutoCompletoPreCadastroFormModel) => {
      try {
        let imagem = modelo.imagemUrl;
        let imgUpload = imagemForUpload(modelo.imagemUrl);
        if (imgUpload.length > 0) {
          let path = '';

          switch (modelo.tipo) {
            case EnumTipoProduto.Produto:
              path = 'produtos';
              break;
            case EnumTipoProduto.Combo:
              path = 'combos';
              break;
            case EnumTipoProduto.Insumo:
              path = 'insumos';
              break;
          }
          const retImagem = await postImagemBase64(
            imgUpload,
            `${path}/${contratoId}/`,
            newGuid()
          );
          if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
            throw new Error('Erro ao enviar a Imagem selecionada.');
          }
          if (retImagem.resultado?.data.data.status === 2) {
            throw new Error(
              'Erro ao enviar a Imagem selecionada.Detalhe: ' +
                retImagem.resultado?.data?.data?.retorno
            );
          }
          imagem =
            retImagem.resultado?.data?.data?.url_blob +
            '?timestamp=' +
            new Date().getTime();
        }

        modelo.imagemUrl = btoa(imagem);
        return modelo;
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [contratoId, postImagemBase64, showToast]
  );
  //para ver se o ncm retornado quando acha pelo ean esta cadastrado na nossa base, se não busca na api de ncms e cadastra
  const searchNcm = useCallback(
    async (modelo: ProdutoCompletoPreCadastroFormModel) => {
      if (modelo.ncmId === null && modelo.codigoNcm.length > 0) {
        setCarregandoFromForms(true);
        try {
          //primeiro vou buscar na minha base
          const retNcmGestao = await getNcms(
            `Codigo=${modelo.codigoNcm}&TemFiltro=true`
          );
          if (retNcmGestao.erro) throw retNcmGestao.erro;
          if (retNcmGestao.resultado?.data.list.length > 0) {
            const ncm = retNcmGestao.resultado?.data?.list[0] as NcmModel;
            modelo.codigoNcm = ncm.codigo;
            modelo.ncmId = ncm.id;
          }
        } catch (e: any) {}
        //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado
        if (modelo.ncmId === null) {
          //entao busco na api de ncms master
          const retNcmGestao = await getNcmsMaster(modelo.codigoNcm);
          if (retNcmGestao.erro) throw retNcmGestao.erro;
          if (retNcmGestao.resultado?.data.list.length > 0) {
            const ncm = retNcmGestao.resultado?.data
              ?.list[0] as NcmMasterSummaryModel;
            const ncmModel = new NcmModel(
              guidEmpty(),
              contratoId,
              ncm.id,
              ncm.codigo,
              ncm.descricao,
              ncm.cest
            );
            //cadastro em nossa base
            const ret = await postNcm(ncmModel);
            if (ret.erro) {
              throw ret.erro;
            }
            const novoNcm = ret.resultado?.data as NcmModel;
            modelo.codigoNcm = novoNcm.codigo;
            modelo.ncmId = novoNcm.id;
          }
        }
      }
      setCarregandoFromForms(false);
    },
    [contratoId, getNcms, getNcmsMaster, postNcm]
  );
  const finalizarCadastro = useCallback(
    async (produtoCompleto: ProdutoCompletoPreCadastroFormModel) => {
      try {
        //vou verificar se o ncm existe parta pegar o id dele, ou cadastrar um novo
        await searchNcm(produtoCompleto);
        //vamos upar a imagem na api de upar imagens
        await sendImage(produtoCompleto);

        let produtoFinal = picker<ProdutoNovoModel>(
          produtoCompleto,
          new ProdutoNovoModel()
        );

        produtoFinal.medidaEntradaId = produtoCompleto.medidaId;
        produtoFinal.medidaSaidaId = produtoCompleto.medidaId;
        produtoFinal.vCompra = toDecimal(produtoCompleto.vCompra);
        produtoFinal.vPreco = toDecimal(produtoCompleto.vPreco);
        produtoFinal.empresaId = empresaId;
        produtoFinal.tipo = tipoProduto();

        if (produtoFinal.categoriaId === guidEmpty()) {
          produtoFinal.categoriaId = null;
        }

        const ret = await postProduto(produtoFinal, produtoFinal.empresaId);
        if (ret.erro) throw ret.erro;

        if (!redirectToEdit.current) {
          limparValoresRefs();
          showToast('success', 'Cadastro realizado com Sucesso!');
        } else {
          abrirCadastroProduto(
            ret.resultado?.data.id,
            ret.resultado?.data.tipo,
            newPathName,
            true
          );
        }
      } catch (e: Error | any) {
        showAviso(
          'error',
          `Erro ao cadastrar o produto. Tente novamente em alguns instantes. Detalhe:/n ` +
            e.message,
          undefined,
          true,
          true
        );
      }
    },
    [
      searchNcm,
      sendImage,
      empresaId,
      tipoProduto,
      postProduto,
      limparValoresRefs,
      showToast,
      abrirCadastroProduto,
      newPathName,
      showAviso
    ]
  );

  const getFormPreCadastroCodigo = useCallback((): JSX.Element => {
    const buscarEan = async (codigo: string) => {
      if (codigo.length > 0) {
        setCarregandoFromForms(true);
        const res = await getInfoEanProduto(codigo);
        if (res.erro) {
          throw res.erro;
        }

        const ret = picker<ConsultaEanModel>(
          res.resultado?.data?.data,
          new ConsultaEanModel()
        );
        if (ret.produtos[0]) {
          const prod = ret.produtos[0];

          let ncm = { ...refNcmModelForm.current };
          ncm.codigoNcm = prod.ncm;

          let nomeMedida = { ...refNomeMedidaModelForm.current };
          nomeMedida.nome = prod.descricao;

          let imagem = { ...refImagemModelForm.current };
          imagem.imagemUrl = `https://saurusean.blob.core.windows.net/eanimagens/${prod.ean}.jpg`;

          refNcmModelForm.current = ncm;
          refNomeMedidaModelForm.current = nomeMedida;
          refImagemModelForm.current = imagem;
        }
      }
    };

    const handleSubmitFormPreCadastroCodigo = async (
      modelo: ProdutoCodigoPreCadastroFormModel
    ) => {
      try {
        await buscarEan(modelo.codigo);
      } catch (e: any) {
        // showToast("info", `Não foi possível buscar o código em nossa base. Detalhe: ${e.message}`);
      }

      if (modelo.codigo.length > 0) {
        //CHECANDO SE O EAN JÁ EXISTE

        try {
          const codEAN = modelo.codigo;
          const res = await getProdutoExisteCodigo(codEAN || '');

          if (res.statusCode !== 202) {
            try {
              const resProd = await getProdutos(
                `Codigo=${codEAN}`,
                getEmpresaAtual()?.id || ''
              );

              if (resProd.erro) throw resProd.erro;

              const ret = resProd.resultado?.data.list;

              if (ret.length < 1) {
                throw new Error();
              }

              const prod = ret[0];

              await confirm({
                content: `Encontramos um produto com mesmo código EAN em seus cadastros. (${prod.nome}).
                        Deseja ir a tela do produto já cadastrado?`,
                confirmationText: 'Ir ao Produto',
                cancellationText: 'Cancelar Cadastro'
              }).then(() => {
                  abrirCadastroProduto(
                    prod.produtoId,
                    prod.tipo,
                    newPathName,
                    true
                  );
                })
                .catch(() => {
                  if (tipoProduto() === EnumTipoProduto.Combo || tipoProduto() === EnumTipoProduto.Insumo) {
                    redirect();
                    return
                  }
                  voltarTela();
                  return;
                });
            } catch {
              showAviso(
                'error',
                `Já existe um medicamento com EAN ${codEAN} nos seus cadastros.`,
                undefined,
                true,
                true
              );
            }
          } else {
            setCarregandoFromForms(false);
            refCodigoModelForm.current = modelo;
            nextStep();
            return
          }
        } catch (e: any) {}
      }

      refCodigoModelForm.current = modelo;
      setCarregandoFromForms(false);
      nextStep();
    };
    return (
      <FormProdutoCodigoPreCadastro
        showLoading={false}
        ref={refPreCadastroCodigo}
        loading={loading}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroCodigo}
        placeholder="(Opcional)"
        tipoForm={EnumFormCodigo.EAN}
      />
    );
  }, [
    abrirCadastroProduto,
    confirm,
    getEmpresaAtual,
    getInfoEanProduto,
    getProdutoExisteCodigo,
    getProdutos,
    loading,
    newPathName,
    nextStep,
    showAviso,
    voltarTela,
    redirect,
    tipoProduto
  ]);
  const getFormPreCadastroNomeMedida = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroNomeMedida = async (
      modelo: ProdutoNomeMedidaPreCadastroFormModel
    ) => {
      refNomeMedidaModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormProdutoNomeMedidaPreCadastro
        showLoading={false}
        ref={refPreCadastroNome}
        loading={loading}
        contratoId={contratoId}
        empresaId={empresaId}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroNomeMedida}
      />
    );
  }, [contratoId, empresaId, loading, nextStep]);
  const getFormPreCadastroValorCompraVenda = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroValorCompraVenda = async (
      modelo: ProdutoValorVendaCompraPreCadastroFormModel
    ) => {
      refValorCompraVendaModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormProdutoValorVendaCompraPreCadastro
        showLoading={false}
        ref={refPreCadastroValorCompraVenda}
        loading={loading}
        onSubmit={handleSubmitFormPreCadastroValorCompraVenda}
      />
    );
  }, [loading, nextStep]);

  const getFormPreCadastroNcm = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroNcm = async (
      modelo: ProdutoImpostoPreCadastroFormModel
    ) => {
      refNcmModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormProdutoNcmPreCadastro
        showLoading={false}
        ref={refPreCadastroImposto}
        loading={loading}
        onSubmit={handleSubmitFormPreCadastroNcm}
      />
    );
  }, [loading, nextStep]);

  const getFormProdutoCompleto = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroProdutoCompleto = async (
      modelo: ProdutoCompletoPreCadastroFormModel
    ) => {
      await finalizarCadastro(modelo);
    };
    return (
      <FormProdutoCompletoPreCadastro
        showLoading={false}
        ref={refPreCadastroProdutoCompleto}
        loading={loading}
        contratoId={contratoId}
        tipo={tipoProduto()}
        empresaId={empresaId}
        setCarregandoExterno={setCarregandoFromForms}
        onSubmit={handleSubmitFormPreCadastroProdutoCompleto}
      />
    );
  }, [contratoId, empresaId, finalizarCadastro, loading, tipoProduto]);

  const retTelaIndex = useCallback(
    (index: number): EnumTelaProduto | undefined => {
      const isFiscal = isPlanoFiscal(plano?.plano);
      const empresaFiscal = getEmpresaAtual()?.isFiscal;
      if (isFiscal && empresaFiscal) {
        switch (index) {
          //case 0: return EnumTelaProduto.Categoria;
          case 0:
            return EnumTelaProduto.Codigo;
          case 1:
            return EnumTelaProduto.Produto;
          case 2:
            return EnumTelaProduto.Preco;
          case 3:
            return EnumTelaProduto.Tributacao;
          case 4:
            return EnumTelaProduto.Revisao;
        }
      } else {
        switch (index) {
          //case 0: return EnumTelaProduto.Categoria;
          case 0:
            return EnumTelaProduto.Codigo;
          case 1:
            return EnumTelaProduto.Produto;
          case 2:
            return EnumTelaProduto.Preco;
          case 3:
            return EnumTelaProduto.Revisao;
        }
      }

      return undefined;
    },
    [getEmpresaAtual, plano?.plano]
  );

  useEffect(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelaProduto.Codigo:
        refPreCadastroCodigo.current?.fillForm(refCodigoModelForm.current);
        break;
      case EnumTelaProduto.Produto:
        refPreCadastroNome.current?.fillForm(refNomeMedidaModelForm.current);
        break;
      case EnumTelaProduto.Preco:
        refPreCadastroValorCompraVenda.current?.fillForm(
          refValorCompraVendaModelForm.current
        );
        break;
      case EnumTelaProduto.Revisao:
        refPreCadastroProdutoCompleto.current?.fillForm(getProdutoCompleto());
        break;
      case EnumTelaProduto.Tributacao:
        refPreCadastroImposto.current?.fillForm(refNcmModelForm.current);
    }
  }, [currentStep, finalizarCadastro, getProdutoCompleto, retTelaIndex]);

  const avancarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelaProduto.Codigo:
        refPreCadastroCodigo.current?.submitForm();
        break;
      case EnumTelaProduto.Produto:
        refPreCadastroNome.current?.submitForm();
        break;
      case EnumTelaProduto.Preco:
        refPreCadastroValorCompraVenda.current?.submitForm();
        break;
      case EnumTelaProduto.Revisao:
        refPreCadastroProdutoCompleto.current?.submitForm();
        break;
      case EnumTelaProduto.Tributacao:
        refPreCadastroImposto.current?.submitForm();
    }
  }, [currentStep, retTelaIndex]);

  const voltarStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const voltarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={voltarStep}
      >
        <VoltarIcon tipo="BUTTON" />
        Voltar
      </Button>
    );
  }, [voltarStep, loading]);

  const avancarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={avancarStep}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Avançar
      </Button>
    );
  }, [loading, avancarStep]);
  const cadastrarNovoButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          redirectToEdit.current = false;
          avancarStep();
        }}
        variant="outlined"
        color="primary"
        size="large"
        fullWidth
      >
        <NovoIcon tipo="BUTTON" />
        Salvar e Adicionar Outro
      </Button>
    );
  }, [loading, avancarStep]);
  const cadastrarEditarButton = useMemo(() => {
    return (
      <Button
        disabled={loading}
        onClick={() => {
          redirectToEdit.current = true;
          avancarStep();
        }}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
      >
        <SalvarEditarIcon tipo="BUTTON_PRIMARY" />
        Salvar e Editar
      </Button>
    );
  }, [loading, avancarStep]);

  const getFormArray = useMemo(() => {
    const ret = [];

    let i = 0;
    while (retTelaIndex(i)) {
      const tela = retTelaIndex(i);
      let nomeProd = '';
      switch (newPathName) {
        case '/produtos':
          nomeProd = 'Produto';
          break;
        case '/insumos':
          nomeProd = 'Insumo';
          break;
        case '/combos':
          nomeProd = 'Combo';
          break;
      }

      switch (tela) {
        case EnumTelaProduto.Codigo:
          ret.push(
            new FormStep(
              'Código',
              `Insira o Código do ${nomeProd}. Pode ser EAN ou um código de referência apenas.`,
              <BarcodeIcon tipo="GERAL" />,
              'Código',
              <BarcodeIcon tipo="GERAL" />,
              getFormPreCadastroCodigo(),
              undefined,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Preco:
          ret.push(
            new FormStep(
              'Preço',
              `Informe os preços do ${nomeProd}!`,
              <DinheiroIcon tipo="GERAL" />,
              'Preço',
              <DinheiroIcon tipo="GERAL" />,
              getFormPreCadastroValorCompraVenda(),
              voltarButton,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Produto:
          ret.push(
            new FormStep(
              `${nomeProd}`,
              `Informe os dados do ${nomeProd}.`,
              <ProdutoIcon tipo="GERAL" />,
              `${nomeProd}`,
              <ProdutoIcon tipo="GERAL" />,
              getFormPreCadastroNomeMedida(),
              voltarButton,
              avancarButton
            )
          );
          break;
        case EnumTelaProduto.Revisao:
          ret.push(
            new FormStep(
              'Revisão',
              `Revise as informações do ${nomeProd}.`,
              <OkIcon tipo="GERAL" />,
              'Revisão',
              <OkIcon tipo="GERAL" />,
              getFormProdutoCompleto(),
              cadastrarNovoButton,
              cadastrarEditarButton
            )
          );
          break;
        case EnumTelaProduto.Tributacao:
          ret.push(
            new FormStep(
              'Tributação',
              `Informe agora as informações tributárias do ${nomeProd}.`,
              <ImpostoIcon tipo="GERAL" />,
              'Tributação',
              <ImpostoIcon tipo="GERAL" />,
              getFormPreCadastroNcm(),
              voltarButton,
              avancarButton
            )
          );
          break;
      }
      i++;
    }

    // if (ret.length > 0) {
    //   ret[ret.length - 1].previousButton = cadastrarNovoButton;
    //   ret[ret.length - 1].nextButton = cadastrarEditarButton;
    //   ret[0].previousButton = undefined;
    // }

    return ret;
  }, [
    retTelaIndex,
    newPathName,
    getFormPreCadastroCodigo,
    voltarButton,
    avancarButton,
    getFormPreCadastroValorCompraVenda,
    getFormPreCadastroNomeMedida,
    getFormProdutoCompleto,
    cadastrarNovoButton,
    cadastrarEditarButton,
    getFormPreCadastroNcm
  ]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep
    },
    formArray: getFormArray,
    carregando: loading
  };
};
