import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { useStyles } from './components/list-styles';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus,
} from 'views/components/tabs/tabs-saurus';
import { SucessoFalhaListData } from './components/sucesso-falha-list-data';
import { IStatusType, useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { CircularLoading } from 'views/components';

export const SincronizacaoSucessoErroTabs = () => {
  // rotas
  const rotas = useMemo(
    () => [
      `/sincronizar-resumo/todos`,
      `/sincronizar-resumo/sucesso`,
      `/sincronizar-resumo/falha`,
    ],
    [],
  );

  const retornaAba = useCallback(
    (path: string) => {
      const rotaAtual = rotas.findIndex((rota) => rota === path);
      if (rotaAtual > -1) {
        return rotaAtual;
      }
      return 0;
    },
    [rotas],
  );

  // AUX
  const { replace, location } = useHistory();
  const classes = useStyles();
  const { getUltimaSincronizacao, carregando } = useSincronizacaoCadastros();
  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname),
  );

  const tabChange = (index: number) => {
    replace(rotas[index]);
  };

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname, retornaAba]);

  const onCardClick = async (model: IStatusType) => { };

  // serializando e agrupando os resultados
  let grupoSincSucesso = new Map(
    (getUltimaSincronizacao()?.statusUltima ?? [])
      .filter((sinc) => sinc.sucesso === true)
      .map((obj) => [obj.tipo, obj]),
  );

  let grupoSincFalha = new Map(
    (getUltimaSincronizacao()?.statusUltima ?? [])
      .filter((sinc) => sinc.sucesso === false)
      .map((obj) => [obj.tipo, obj]),
  );

  const sincUnicaSucesso = Array.from(grupoSincSucesso.values());

  const sincUnicaFalha = Array.from(grupoSincFalha.values());

  const sincSerelizadosSucesso = sincUnicaSucesso.map((sinc) => {
    return {
      ...sinc,
      qtdSincronizado: 0,
    };
  });

  const sincSerelizadosFalha = sincUnicaFalha.map((sinc) => {
    return {
      ...sinc,
      qtdSincronizado: 0,
    };
  });

  const sincComSucesso = sincSerelizadosSucesso.map((sincronizacao) => {
    (getUltimaSincronizacao()?.statusUltima ?? [])
      .filter((sinc) => sinc.sucesso === true)
      .map((sinc) => {
        if (sinc.tipo === sincronizacao.tipo) {
          let novaQuantidade = (sincronizacao.qtdSincronizado +=
            sinc.qtdSincronizado);
          return {
            ...sincronizacao,
            qtdSincronizado: novaQuantidade,
          };
        }
        return sinc;
      });
    return sincronizacao;
  });

  const sincComFalha = sincSerelizadosFalha.map((sincronizacao) => {
    (getUltimaSincronizacao()?.statusUltima ?? [])
      .filter((sinc) => sinc.sucesso === false)
      .map((sinc) => {
        if (sinc.tipo === sincronizacao.tipo) {
          let novaQuantidade = (sincronizacao.qtdSincronizado +=
            sinc.qtdSincronizado);
          return {
            ...sincronizacao,
            qtdSincronizado: novaQuantidade,
          };
        }
        return sinc;
      });
    return sincronizacao;
  });

  return (
    <>
      <TabsSaurus
        selectedTabIndex={abaSelecionada}
        onChange={tabChange}
        tabsLabel={[
          new TabSaurusLabel(
            `Todos (${(sincComSucesso.length ?? 0) + (sincComFalha.length ?? 0)
            })`,
            0,
          ),
          new TabSaurusLabel(`Sucessos (${sincComSucesso.length ?? 0})`, 1),
          new TabSaurusLabel(`Falhas (${sincComFalha.length ?? 0})`, 2),
        ]}
        tabsContent={[
          new TabSaurusContent(
            0,
            (
              <div>
                {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                <div className={classes.defaultContainer}>
                  <Grid item xs={12} className={classes.listContainer}>
                    <SucessoFalhaListData
                      erro={false}
                      list={[...sincComFalha, ...sincComSucesso]}
                      onCardChecked={onCardClick}
                      onCardSelected={() => { }}
                    ></SucessoFalhaListData>
                  </Grid>
                </div>
              </div>
            ),
          ),
          new TabSaurusContent(
            1,
            (
              <div>
                {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                <div className={classes.defaultContainer}>
                  <Grid item xs={12} className={classes.listContainer}>
                    <SucessoFalhaListData
                      erro={false}
                      list={sincComSucesso}
                      onCardChecked={onCardClick}
                      onCardSelected={() => { }}
                    ></SucessoFalhaListData>
                  </Grid>
                </div>
              </div>
            ),
          ),
          new TabSaurusContent(
            2,
            (
              <div>
                {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                <div
                  className={classes.defaultContainer}
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} className={classes.listContainer}>
                      <SucessoFalhaListData
                        erro={true}
                        list={sincComFalha}
                        onCardChecked={onCardClick}
                        onCardSelected={() => { }}
                      ></SucessoFalhaListData>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ),
          ),
        ]}
      />
    </>
  );
};
