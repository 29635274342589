import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listContainer: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    icon: {
        height: 22,
        width: 22,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
        }
    }
}));
