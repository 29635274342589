import * as Yup from "yup";
import { useMemo } from "react";

export const useFormProdutoNomeMedidaPreCadastroValidation = () => {

  const FormProdutoNomeMedidaPreCadastroYupValidation = useMemo(() => {
    return Yup.object().shape({
      nome: Yup.string().required("O nome é obrigatório").min(3, "O tamanho mínimo do nome é de 3 caracteres"),
      medida: Yup.string().required("Nos informe a medida do produto")
    });
  }, []);

  return {
    FormProdutoNomeMedidaPreCadastroYupValidation,
  };
};
