import { TokenGestaoPerfilModel } from "./token-gestao-perfil";

export class TokenGestaoEmpresaModel {
    constructor(
        public Id: string = "",
        public ContratoId: string = "",
        public Documento: string = "",
        public Descricao: string = "",
        public RefreshToken: string = "",
        public Perfil: TokenGestaoPerfilModel = new TokenGestaoPerfilModel(),
    ) { }
}
