import { useCallback, FocusEvent, useState } from 'react';

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../../autocomplete-saurus/autocomplete-saurus';

import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { useGetNcmsMaster } from 'data/api/ncm-master/get-ncms-master';

export interface AutocompleteNcmsGestaoProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  ncmId?: string;
  allowSubmit?: boolean;
  modo: 'campoFormatado' | 'cest' | 'codigo';
  disabled?: boolean;
}
interface NcmPesquisa extends NcmModel {
  campoFormatado: string;
}

export const AutocompleteNcmsGestao = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteNcmsGestaoProps) => {
  const { getNcmsMaster, carregando: carregandoNcmsMaster } =
    useGetNcmsMaster();
  const [ncmsState, setNcmsState] = useState<Array<NcmPesquisa>>(
    new Array<NcmPesquisa>(),
  );

  const getNcmsWrapper = useCallback(
    async (termo: string) => {
      try {
        const ret = await getNcmsMaster(termo);
        if (ret.erro) throw ret.erro;
        let ncms: Array<NcmPesquisa> = new Array<NcmPesquisa>();
        if (ret.resultado?.data?.list?.length > 0) {
          ncms = ret.resultado?.data?.list as Array<NcmPesquisa>;
        }
        ncms.forEach((item) => {
          item.campoFormatado = `${item.codigo} - ${item.descricao}`;
        });

        setNcmsState(ncms);
      } catch (e: any) {}
    },
    [getNcmsMaster],
  );

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );
  const onPesquisa = useCallback(
    (termo: string) => {
      let termoPesq = termo.length > 0 ? termo.split('-')[0].trim() : termo;
      getNcmsWrapper(termoPesq);
    },
    [getNcmsWrapper],
  );
  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      tipoTextField="NUMERO"
      loading={carregandoNcmsMaster}
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={ncmsState}
      optionLabelKey={props.modo}
      optionValueKey="codigo"
      disabledValueKey="id"
      disabledValues={[props.ncmId]}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
