import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetEquipamentoById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getEquipamentoById = useCallback((empresaId: string, equipamentoId: string) =>
    invocarApi({
      url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/equipamento/${equipamentoId}`,
      method: "GET",
      enviarTokenUsuario: true,
    }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getEquipamentoById,
  }

}
