
export class SessaoValorPostModel {
    constructor(
        public id: string = '',
        public dLancamento: string = '',
        public pagamentoId: string = '',
        public valor: number = 0 ,
        public tpLancamento: number = 0,
        public observacao: string = '',
        public operadorId: string = '',
        public operadorNome: string = '',
        public responsavelId: string = '',
        public responsavelNome: string = '',
        public portadorId: string = '',
        public portadorNome: string = '',
    ) { }
}
