import { useCallback, FocusEvent, useState, useEffect, useRef } from "react";

import {
  retornoAutoComplete,
  AutocompleteSaurus,
  NovoItemModel,
} from "../autocomplete-saurus/autocomplete-saurus";
import { useGetMarcas } from "data/api/gestao/marca/get-marcas";
import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { useGetMarcaById } from "data/api/gestao/marca/get-marca-by-id";

export interface AutocompleteMarcasProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: React.ReactNode | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  empresaId?: string;
  permiteAdicionar?: boolean;
  textNovoItem?: NovoItemModel[];
  readOnly?: boolean;
  valueIsID?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
}

export const AutocompleteMarcas = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteMarcasProps) => {
  const [MarcasState, setMarcasState] = useState<Array<MarcaModel>>(
    new Array<MarcaModel>()
  );
  const allowReq = useRef<boolean>(true);

  const { getMarcas, carregando: carregandoGet } = useGetMarcas();
  const { getMarcaById, carregando: carregandoById } = useGetMarcaById();

  const carregando = carregandoGet || carregandoById;

  const getMarcasWrapper = useCallback(
    async (nomeProduto: string) => {
      try {
        const ret = await getMarcas('Nome=' + nomeProduto)
        if (ret.erro) throw ret.erro;
        let Marcas: Array<MarcaModel> = new Array<MarcaModel>();
        if (ret.resultado?.data?.list?.length > 0) {
          Marcas = ret.resultado?.data?.list as Array<MarcaModel>;
        }

        setMarcasState(Marcas);

        return Marcas

      } catch (e: any) { }
    },
    [getMarcas]
  );



  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      allowReq.current = true
      if (onChange) onChange(value);
    },
    [onChange]
  );
  const onPesquisa = useCallback((termo: string) => {
    getMarcasWrapper(termo);
  }, [getMarcasWrapper]);

  const getById = useCallback(
    async (id) => {
      try {
        const res = await getMarcaById(id)
        if (res.erro) throw res.erro

        const data = [
          res.resultado?.data
        ] as MarcaModel[];

        setMarcasState(data);
        if (onChange) {
          onChange({
            isNewVal: false,
            isString: false,
            value: data[0],
          });
        }
      } catch (e: any) { }
    }, [getMarcaById, onChange]
  )

  useEffect(() => {
    if ((props.permiteAdicionar || props.valueIsID) && props.value && allowReq.current) {
      if (props.valueIsID) {
        getById(props.value);
        allowReq.current = false;
        return
      }
      getMarcasWrapper(props.value);
      allowReq.current = false;
    }
  }, [getById, getMarcasWrapper, props.permiteAdicionar, props.value, props.valueIsID])

  return (
    <AutocompleteSaurus
      inputRef={props.inputRef}
      disabled={props.disabled}
      name={props.name}
      loading={carregando}
      onChange={onChangeWrapper}
      opcoes={MarcasState}
      optionLabelKey="nome"
      optionValueKey="id"
      onBlur={props.onBlur}
      onPesquisa={onPesquisa}
      value={props.value}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
      permiteNovo={props.permiteAdicionar}
      textoNovoItem={props.textNovoItem ? props.textNovoItem : "Cadastrar: "}
      readOnly={props.readOnly}
      externalInput={props.readOnly || props.valueIsID}
    />
  );
};