import { useValidationYupSenhaConfirmacao, useValidationYupSenha } from '../../../form-validations';
import * as Yup from 'yup';

export const useFormRecuperarSenhaConfirmarValidations = () => {
    const { senhaYup } = useValidationYupSenha()
    const { senhaConfirmacaoYup } = useValidationYupSenhaConfirmacao()

    const FormRecuperarSenhaConfirmarYupValidations = Yup.object().shape({
        senha: senhaYup(),
        confirmar: senhaConfirmacaoYup('senha')
    });

    return {
        FormRecuperarSenhaConfirmarYupValidations
    }
}

