import { Grid } from "@material-ui/core"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { ButtonFab } from "views/components/controles"
import { AdicionarEmpresaIcon } from "views/components/icons"
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles"
import { ListaEmpresasHeader } from "./components/lista-empresas-header/lista-empresas-header"
import { ListaEmpresasList } from "./components/lista-empresas-list/lista-empresas-list"
import { ListaEmpresasListSearchProps } from "./components/lista-empresas-list/lista-empresas-list-search-props"

const ListaEmpresasPage = () => {

    const [openPesquisa, setOpenPesquisa] = useState(false);
    const [searchProps, setSearchProps] = useState<ListaEmpresasListSearchProps>({
        termo: '',
    });

    const classes = useDefaultCadastroStyles()
    const history = useHistory()

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <ListaEmpresasHeader
                    openPesquisa={openPesquisa}
                    setOpenPesquisa={setOpenPesquisa}
                    setSearchProps={setSearchProps}
                />
            </Grid>
            <Grid
                className={classes.defaultContainer}
                container
                alignContent="flex-start"
                justifyContent="center"
            >
                <ListaEmpresasList searchProps={searchProps} />
            </Grid>
            <ButtonFab
                tooltip="Adicionar Nova Empresa"
                icon={<AdicionarEmpresaIcon tipo="BUTTON_FAB" />}
                onClick={() => history.push('/adicionar-empresa')}
            />
        </Grid>
    )
}

export default ListaEmpresasPage