
export class DashboardAdminCategoriasModel {
    constructor(
        public emitDoc: string = '',
        public emitNome: string = '',
        public data: number = 0,
        public hora: number = 0,
        public categoria: string = '',
        public vCusto: number = 0,
        public vVenda: number = 0,
        public qItens: number = 0,
        public qCom: number = 0,
        public status: number = 0,
    ) { }
}