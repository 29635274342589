import { useCallback } from "react"
import { useToastSaurus } from "services/app"

// Dicionário para mostrar de onde vieram as recomendações
export type SugestaoSources = 'Fluxo de venda atual' | 'Receita' | 'Clipboard' | 'Vazio'

export const useClienteIdentificacaoUtils = () => {
    const { showToast } = useToastSaurus()

    const showSuggestionToast = useCallback((data: {
        source: SugestaoSources,
        key: 'CPF/CNPJ' | 'Nome' | 'Telefone' | 'Sexo' | 'Data de Nascimento'
    }) => {
        if(data.source !== 'Fluxo de venda atual' && data.source !== 'Vazio') {
            showToast('success', `Sugerindo valor ${data.key} - ${data.source}`)
        }
    }, [showToast])

    return {
        showSuggestionToast
    }
}