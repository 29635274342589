import moment from 'moment';

export function toDate(value: any): Date | undefined {
    try {
        return moment(value).isValid() ? moment(value).toDate() : undefined;
    } catch {
        return undefined;
    }
}

export function toDateString(value: any, mask: string = 'DD/MM/yyyy'): string | undefined {
    if (!value) return undefined;
    return moment(value).format(mask).toString();
}

export function formatarTimeZone(date: Date) {
    // Obtém o deslocamento do fuso horário em minutos
    const timezoneOffset = date.getTimezoneOffset();

    // Converte o deslocamento para horas e minutos
    const hours = Math.floor(Math.abs(timezoneOffset) / 60);
    const minutes = Math.abs(timezoneOffset) % 60;

    // Formata a string com o sinal correto (+ ou -)
    const sign = timezoneOffset > 0 ? '-' : '+';

    // Formata as horas e minutos com dois dígitos
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${sign}${formattedHours}:${formattedMinutes}`;
}
export function toDateStringApi(data: Date) {
    const ano = data.getUTCFullYear();
    const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
    const dia = String(data.getUTCDate()).padStart(2, '0');
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getUTCMinutes()).padStart(2, '0');
    const segundo = String(data.getUTCSeconds()).padStart(2, '0');
    const timezone = formatarTimeZone(data);
    const dataHoraFormatada = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}${timezone}`;

    return dataHoraFormatada;
}

export const dataAtual = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }