import { useEffect, useState, useCallback, useRef, ChangeEvent } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useStyles } from './selecao-vendedor-styles';
import { VendedorListData } from './vendedor-list-data';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { DialogSelecionarVendedor } from 'views/components/dialog/dialog-selecionar-vendedor/dialog-selecionar-vendedor';
import { useHistory } from 'react-router-dom';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useToastSaurus } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { TouchoneDBPrimary } from 'database/touchone-database';

export const SelecaoVendedorList = () => {
  const classes = useStyles();
  const { setVendedor } = useMovAtual();
  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState<Array<PessoaModel>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const refInput = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const theme = useTheme();


  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [vendedorTela, setVendedorTela] = useState<PessoaModel | undefined>();
  const [termoPesquisar, setTermoPesquisar] = useState<string>('');

  const handleClose = () => {
    setVendedorTela(undefined);
    setShowModal(false);
  };

  const search = useCallback(async () => {
    try {
      const res = await TouchoneDBPrimary.clientes
        .filter((vendedor) => {
          return vendedor.status === 0 && vendedor.tpCadastro === 1;
        })
        .toArray();
      setQueryStatus(res as PessoaModel[]);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setLoading(false)
    }
  }, [showToast]);

  const timer = useRef({} as NodeJS.Timeout);
  const ultimoTermoPesquisado = useRef('');

  const queryStatusFiltered = queryStatus.filter((item) =>
    item.nome.toLowerCase().includes(termoPesquisar.toLowerCase()),
  );

  useEffect(() => {
    (async () => {
      clearTimeout(timer.current);
      setLoading(true)
      if (
        ultimoTermoPesquisado.current !== termoPesquisar ||
        termoPesquisar.length === 0
      ) {
        timer.current = setTimeout(async () => {
          await search();
        }, 500);
      }
      return () => {
        clearTimeout(timer.current);
      };
    })();
  }, [search, termoPesquisar]);

  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTermoPesquisar(event.target.value);
    },
    [],
  );

  const substituirTodosProdutos = () => {
    if (vendedorTela) {
      setVendedor(vendedorTela, true);
    }
    history.push('/venda-simples/catalogo');
  };

  const substituirProximosProdutos = () => {
    if (vendedorTela) {
      setVendedor(vendedorTela, false);
    }
    history.push('/venda-simples/catalogo');
  };

  const onCardSelected = async (model: PessoaModel) => {
    setVendedorTela(model);
    setShowModal(true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  }; 

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <Grid item style={{ margin: '10px 10px' }}>
              <TextFieldSaurus
                inputRef={refInput}
                fullWidth
                size="small"
                allowSubmit={true}
                value={termoPesquisar}
                placeholder="Pesquisar vendedor"
                onChange={onChangePesquisa}
                searchable
                adornmentColor={theme.palette.text.disabled}
                variant='outlined'
              />
            </Grid>
            <Grid
              container
              direction="column"
              style={{
                display: 'flex',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                
              }}
            >
              <Typography className={classes.title}>
                Selecione um vendedor para continuar em uma venda.
              </Typography>
            </Grid>
            <VendedorListData
              carregando={loading}
              list={
                termoPesquisar.length > 0 ? queryStatusFiltered : queryStatus
              }
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
            {showModal && (
              <DialogSelecionarVendedor
                openned={showModal}
                loading={false}
                nomeVendedor={vendedorTela?.nome || ''}
                closeModal={handleClose}
                substituirTodosProdutos={substituirTodosProdutos}
                substituirProximosProdutos={substituirProximosProdutos}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
