import { merge } from 'lodash';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { IVariaveisAmbiente, IAmbienteUnico } from './types';

const AmbienteEnviado = (process.env.REACT_APP_ENV ||
  'mpProd') as keyof IVariaveisAmbiente;
const AmbientePadrao: keyof IVariaveisAmbiente = AmbienteEnviado;

const LabelEnviado = (process.env.REACT_APP_LABEL || "Touchone") as keyof IVariaveisAmbiente;
const API = (process.env.REACT_APP_API || "staging") as `prod` | `staging`;

const performanceDebugMode = Boolean(process.env.REACT_APP_DEBUGGER);

const gatewayVersion = `v4`
const apiUrlNew = `https://api-gateway-${API}.touchone.com.br`;
const apiNcmUrlNew = `https://api-ncms-${API}.touchone.com.br`;
const apiCarga = `https://api-conversoes-${API}.touchone.com.br`;
const apiSetoresURL = `https://api-setores-${API}.touchone.com.br`;
const apiPedidosURL = `https://api-pedidos-${API}.touchone.com.br`;
const apiEquipamentosURL = `https://api-equipamentos-${API}.touchone.com.br`
const apiLocalizacaoURL = `https://api-localizacao-${API}.touchone.com.br`;
const apiMedicamentos = `https://api-medicamentos-${API}.touchone.com.br`;
const apiCardapioURL = `https://api-cardapio-${API}.touchone.com.br`;
const apiImagemUrl = `https://api-imagens.saurus.net.br`;
const apiSMSUrl = `https://api-sms.touchone.com.br`;
const apiTributarioUrl = `https://api-tributario.retaguarda.app`;
const apiImpressaoLocalURL = `http://127.0.0.1:20001`;
const apiTouchFarma = `https://touchpharma-api.azurewebsites.net`;
const apiImportacaoXmlURL = 'https://api-importacaoxml-staging.touchone.com.br';

export const chaveRevendaSafra = `OWE0YTUzYzMtYjQwZi00YmMxLTkwODgtMzBiMWIyNzQ3YzQ1fDMyMjcwNjA4MDAwMjAz`;
export const sistemaId = `bd2d7c0c-1383-444f-8ddb-70047aade745`

const _variaveisAmbiente: IVariaveisAmbiente = {
  all: {
    version: "1.52.00.4",
    isDev: (["prodWeb", "cordova", "cordovaSlow", "mpProd", "prodSafraWeb"]
      .find(x => x === AmbientePadrao.toString())?.length || 0) === 0,
    environment: AmbienteEnviado,
    googleAppId: "2727919z1635-5kdincvmbm92lihatr0k4tm1o4kon8ue.apps.googleusercontent.com",
    googleMapsKey: `AIzaSyCDoIa4tkrK_R2B7br7AqiVKk7eYEBdjoo`,
    saurusUtilsWebService: "https://wsmaster.saurus.net.br/v004/serviceMaster.asmx",
    label: LabelEnviado,
  },
  devWeb: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiUrlCarga: apiCarga,
    apiImagemUrl: apiImagemUrl,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    apiEquipamentos: apiEquipamentosURL,
    isSlowDevice: false,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  prodWeb: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiUrlCarga: apiCarga,
    apiImagemUrl: apiImagemUrl,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '1880946222051906',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: false,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  prodSafraWeb: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.NAVIGATOR,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiUrlCarga: apiCarga,
    apiImagemUrl: apiImagemUrl,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '1880946222051906',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: false,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  cordova: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.CORDOVA,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiImagemUrl: apiImagemUrl,
    apiUrlCarga: apiCarga,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: false,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  cordovaSlow: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.CORDOVA,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiImagemUrl: apiImagemUrl,
    apiUrlCarga: apiCarga,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: true,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  cordovaSafra: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.CORDOVA,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiImagemUrl: apiImagemUrl,
    apiUrlCarga: apiCarga,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: true,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  mpProd: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.MERCADOPAGO,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiUrlCarga: apiCarga,
    apiImagemUrl: apiImagemUrl,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: true,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  },
  mpDev: {
    apiUrl: apiUrlNew,
    paymentDevice: EnumDeviceType.MERCADOPAGO_DEV,
    apiUrlNcms: apiNcmUrlNew,
    apiUrlSMS: apiSMSUrl,
    apiUrlCarga: apiCarga,
    apiImagemUrl: apiImagemUrl,
    apiTributarioUrl: apiTributarioUrl,
    apiUrlSetores: apiSetoresURL,
    apiUrlImpressaoLocal: apiImpressaoLocalURL,
    apiUrlImportacaoXML: apiImportacaoXmlURL,
    apiEquipamentos: apiEquipamentosURL,
    apiUrlPedidos: apiPedidosURL,
    apiUrlCardapio: apiCardapioURL,
    apiUrlLocalizacao: apiLocalizacaoURL,
    facebookAppId: '661218711269424',
    frontUrl: LabelEnviado as string === 'FARMA' ? 'https://farma.touchone.com.br' : 'https://app.touchone.com.br',
    isSlowDevice: true,
    performanceDebugMode,
    gatewayVersion: gatewayVersion,
    apiMedicamentos: apiMedicamentos,
    apiTouchFarma: apiTouchFarma
  }
};

const _ambienteUnico =
  (_variaveisAmbiente[AmbientePadrao] as IAmbienteUnico) ||
  _variaveisAmbiente.devWeb;
export const VariaveisAmbiente = merge(_variaveisAmbiente.all, _ambienteUnico);
