import { KeyValueModel } from "model";
import { EnumStatusEntrada } from "model/enums/enum-status-entrada";

export const StatusMovimentacaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusEntrada.EmDigitacao, "Em Digitação"),
    new KeyValueModel(EnumStatusEntrada.Cancelado, "Cancelado"),
    new KeyValueModel(EnumStatusEntrada.Finalizado, "Finalizado"),
    new KeyValueModel(EnumStatusEntrada.Pendente, "Pendente"),
    new KeyValueModel(EnumStatusEntrada.Abortado, "Abortado"),
    new KeyValueModel(EnumStatusEntrada.Rejeitado, "Rejeitado"),
];

export const StatusMovimentacaoMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    ...StatusMovimentacaoMock,
];