import { useCallback, useMemo, useState } from 'react';
import { useToastSaurus } from 'services/app';
import { toDecimal } from 'utils/to-decimal';
import CalcFragment from './components/calc-fragment';

interface CalcModalProps {
  value: string;
  index: number;
  setValue: (index: number, value: string) => void;
}

const CalcModal = ({ value, index, setValue }: CalcModalProps) => {
  //  STATES E REFS
  const [valor, setValor] = useState<number | undefined>(
    value ? Number(value.replace(',', '.')) : undefined,
  );
  const [valores, setValores] = useState<number[]>([]);
  // AUX
  const { showToast } = useToastSaurus();

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        setValor(toDecimal(formattedText, 2));
        return true;
      } catch (e: any) {
        showToast('error', 'Erro ao inserir o valor. Detalhe: ' + e.message);
        return false;
      }
    },
    [showToast],
  );

  const submit = useCallback(async () => {
    return true;
  }, []);

  const add = useCallback(async () => {
    try {
      if (!valor || valor === 0) {
        throw new Error('Insira um valor maior que 0.');
      }

      setValores((prev) => [...prev, valor]);
      return true;
    } catch (e: any) {
      showToast('error', 'Erro ao inserir o valor. Detalhe: ' + e.message);
      return false;
    }
  }, [showToast, valor]);

  const fragmento = useMemo(() => {
    return (
      <CalcFragment
        valor={valor}
        valores={valores}
        setValue={setValue}
        index={index}
        add={add}
        submit={submit}
        textChanged={textChanged}
      />
    );
  }, [valor, valores, setValue, index, add, submit, textChanged]);

  return (
    <>
      {fragmento}
    </>
  );
};

export default CalcModal;
