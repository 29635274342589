import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    status: {
        display: 'flex',
        padding: theme.spacing(1),
        marginRight: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        justifyContent: 'center',
        background: theme.palette.primary.main,
        fontSize: 12,
        '&.darkStatus': {
            background: '#666666'
        }
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    cardTitle: {
        color: theme.palette.grey[500]
    },
    cardContent:{
        padding: '8px'
    },
    lineContent:{
        display: 'flex'
    },
    lineContentColumn:{
        display:"flex",
        flexDirection:'column'

    }
}));
