import { Grid } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { ToastLeitor } from '../components/toast-leitor/toast-leitor';
import { CartWrapper } from './components/cart-wrapper/cart-wrapper';
import { CarrinhoValoresPorTaxa } from './components/carrinho-informacoes/carrinho-informacoes';
import { VendaHeader } from '../components/produtos/produto-header/produto-header';

export const MovCarrinhoPage = () => {
  // STATES E REFS

  // PROVIDERS & HOOKS
  const classes = useDefaultCadastroStyles();
  const { callEvent } = useEventTools();

  useEffect(() => {
    callEvent(AppEventEnum.PermiteEntradaDigitada, true);
  }, [callEvent]);

  const carrinhoValoresTaxa = useMemo(
    () => (
      <CarrinhoValoresPorTaxa />
    ),
    []
  );

  const toastLeitor = useMemo(() => <ToastLeitor />, []);

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <VendaHeader
            title="Carrinho"
          />
        </Grid>
        <CartWrapper componentTaxa={carrinhoValoresTaxa} isPageCarrinho />
      </Grid>
      {toastLeitor}
    </>
  );
};
