import { useCallback, useEffect, useRef } from 'react';
import { LixoIcon, OkIcon, VoltarIcon } from 'views/components/icons';
import { Button, Grid } from '@material-ui/core';
import { isEqual } from 'utils/is-equal';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';

import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';

import classNames from 'classnames';
import { DocReferenciadoFormModel } from 'model/app/forms/entrada/doc-referenciado-form-model';
import { EnumTipoDocRef, NFrefModel, VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { DocReferenciadoEditProps } from './doc-referenciado-edit-props';
import { FormDocReferenciado } from 'views/components/form/entrada/doc-referenciado/form-doc-referenciado';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { useConfirm } from 'material-ui-confirm';
import { isEqualWith } from 'lodash';
import { useEntrada } from 'views/pages/private/entrada/hooks/entrada';

export const DocReferenciadoEdit = ({ mov, id }: DocReferenciadoEditProps) => {
  const { showToast } = useToastSaurus();


  const classes = useModalStyles();
  const confirm = useConfirm()

  const { fecharDocReferenciadoCadastro } = useCadastros();
  const { saveChangesVendaCompleta, carregando } = useEntrada(mov)

  const formRef = useRef<DefaultFormRefs<DocReferenciadoFormModel>>(null);


  useEffect(() => {
    if (mov) {
      if (id) {
        const docMov = mov.infMov.nFref.find(x => x.id === id)
        const docForm = picker<DocReferenciadoFormModel>(docMov, new DocReferenciadoFormModel())
        if (docMov?.refCTe) docForm.tipo = EnumTipoDocRef.ConhecimentoTE
        if (docMov?.refECF) docForm.tipo = EnumTipoDocRef.ECF
        if (docMov?.refNF) docForm.tipo = EnumTipoDocRef.NfmTalonario
        if (docMov?.refNFP) docForm.tipo = EnumTipoDocRef.NfPR
        if (docMov?.refNFe) docForm.tipo = EnumTipoDocRef.NfeNfcSat
        formRef.current?.fillForm(docForm);
        return
      }
      formRef.current?.fillForm(new DocReferenciadoFormModel());
    }
  }, [id, mov]);

  const saveChanges = useCallback(
    async (modelForm: DocReferenciadoFormModel) => {
      const movAtual = structuredClone(mov) as VendaCompletaModel

      let docModel = new NFrefModel()

      if (id) {
        const docMov = movAtual.infMov.nFref.find((x) => x.id === id)
        docModel = picker<NFrefModel>(modelForm, docMov)
        const index = movAtual.infMov.nFref.indexOf(docMov!)
        movAtual.infMov.nFref[index] = docModel
      } else {
        docModel = picker<NFrefModel>(modelForm, new NFrefModel())
        movAtual.infMov.nFref.push(docModel)
      }

      await saveChangesVendaCompleta(movAtual)
    }, [id, mov, saveChangesVendaCompleta]);

  const handleSubmit = useCallback(async (
    model: DocReferenciadoFormModel,
    beforeModel: DocReferenciadoFormModel,
  ) => {
    try {
      const modelEqualPadrao = isEqualWith(model, new NFrefModel())
      const formEqual = isEqual(model, beforeModel);

      if (modelEqualPadrao) {
        showToast('info', 'Preencha os campos!')
        return
      }
      if (formEqual) {
        showToast('info', 'Nenhuma informação foi alterada');
        return
      }

      await saveChanges(model);
      showToast('success', `Volume ${id ? 'alterado' : 'adicionado'} com sucesso!`)

      fecharDocReferenciadoCadastro(true)

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [saveChanges, showToast, id, fecharDocReferenciadoCadastro]);

  const handleRemoveVolume = async () => {
    try {
      const movAtual = structuredClone(mov)

      const docs = movAtual.infMov.nFref.filter((x: any) => x.id !== id)
      movAtual.infMov.nFref = docs

      await saveChangesVendaCompleta(movAtual)

      showToast('info', 'Documento removido com sucesso')
      fecharDocReferenciadoCadastro(true)
    } catch (error: any) {
      showToast('error', error.message)
    }
  }

  const onCloseClick = useCallback(() => {
    fecharDocReferenciadoCadastro(false);
  }, [fecharDocReferenciadoCadastro]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={id ? 'Editar Documento' : "Novo Documento"}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={!id ? null : (
          <MenuOptions options={[
            new MenuOptionsModel(
              `Remover Documento`,
              <LixoIcon tipo="BUTTON" />,
              () => confirm({
                title: 'Tem certeza?',
                description: 'Deseja remover este documento? Esta ação não poderá ser desfeita futuramente.',
                cancellationText: 'Cancelar',
                confirmationText: 'Confirmar'
              }).then(() => handleRemoveVolume())
            )
          ]} />
        )}
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormDocReferenciado
            ref={formRef}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                disabled={carregando}
                onClick={() => formRef.current?.submitForm()}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

