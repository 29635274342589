import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonCancelar: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
}))