import { Slide } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { forwardRef, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { useThemeQueries } from 'views/theme';
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from 'views/components/tabs/tabs-saurus';
import { EstabelecimentoHorarios } from './components/estabelecimento-horarios/estabelecimento-horarios';
import { DeliveryHorarios } from './components/delivery-horarios/delivery-horarios';

interface DialogHorariosFuncionamentoProps {
  openned: boolean;
  closeModal: () => void;
  hasEmpresaId?: boolean
  empresaId?: string
}

const Transition = forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const DialogHorariosFuncionamento = ({
  openned,
  closeModal,
  empresaId,
  hasEmpresaId = false
}: DialogHorariosFuncionamentoProps) => {

  const { isMobile } = useThemeQueries()
  const [abaSelecionada, setAbaSelecionada] = useState<number>(0)


  const tabChange = (index: number) => {
    if (index === 0) {
      setAbaSelecionada(0)
    } else {
      setAbaSelecionada(1)
    }
  };

  return (
    <DialogSaurus
      aberto={openned}
      titulo="Horários de Funcionamento"
      removerPaddingContent={isMobile}
      fullScreen={isMobile}
      fullHeightContent
      TransitionComponent={isMobile ? Transition : undefined}
    >
      {openned && (
        <TabsSaurus
          selectedTabIndex={abaSelecionada}
          onChange={tabChange}
          tabsLabel={[
            new TabSaurusLabel('Estabelecimento', 0),
            new TabSaurusLabel('Delivery', 1),
          ]}
          tabsContent={[
            new TabSaurusContent(
              0,
              (
                <>
                  {abaSelecionada === 0 && (
                    <EstabelecimentoHorarios
                      empresaId={empresaId}
                      hasEmpresaId={hasEmpresaId}
                      fecharDialog={closeModal}
                    />
                  )}
                </>
              ),
            ),
            new TabSaurusContent(
              1,
              (
                <>
                  {abaSelecionada === 1 && (
                    <DeliveryHorarios
                      empresaId={empresaId}
                      hasEmpresaId={hasEmpresaId}
                      fecharDialog={closeModal}
                    />
                  )}
                </>
              ),
            ),
          ]}
        />
      )}
    </DialogSaurus>
  );
};
