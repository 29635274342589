import { ReactNode } from "react";

export class MenuOptionsModel {
  constructor(
    public label: React.ReactNode | string,
    public icon: ReactNode,
    public click: () => any,
    public disabled: boolean = false,
    public tooltip: NonNullable<React.ReactNode> | null = null,
  ) { }
}
