import { isEmpty } from "lodash";
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import DepositoCadastro from "./components/deposito-cadastro/deposito-cadastro";
import DepositoEdit from "./components/deposito-edit/deposito-edit";

export const DepositoModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (<DepositoCadastro />)}
      {props.openned && !isEmpty(props.id) && (<DepositoEdit id={props.id} />)}
    </DefaultModal>
  );
};
