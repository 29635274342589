import { Grid, Typography } from "@material-ui/core";
import { PublicPageHeader } from "views/components/headers";
import { SelecionarEmpresaList } from "./components/selecionar-contrato-list/selecionar-contrato-list";
import { useStyles } from "./selecionar-contrato-styles";

const SelecionarContratoPage = () => {

    const classes = useStyles()

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <Grid className={classes.container}>
                <Grid className={classes.content}>
                    <Grid className={classes.infoContainer}>
                        <Typography className={classes.textTitle}>
                            Contratos
                        </Typography>
                        <Typography className={classes.textSubtitle}>
                            Selecione o contrato para prosseguir.
                        </Typography>
                        <SelecionarEmpresaList />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SelecionarContratoPage