import { VariaveisAmbiente } from "config";
import { ProcessosFormModel } from "model/api/gestao/processos/processos-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutEditarProcessos() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putEditarProcessos = useCallback(
        (model: ProcessosFormModel, empresaId: string, setorId: string, processoSetorId: string, processoId: string) =>
            invocarApi({
                url: `/v5/empresa/${empresaId}/setor/${setorId}/processo/${processoSetorId}/atualizar-processo/${processoId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: model,
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
            }),
        [invocarApi],
    );

    return {
        ...outrasPropriedades,
        putEditarProcessos,
    };
}