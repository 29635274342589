import { KeyValueModel } from "model";
import { EnumStatusProcessoTipo } from "model/enums/enum-processo-tipo";

export const StatusProcessoTipo: KeyValueModel[] = [
    new KeyValueModel(EnumStatusProcessoTipo.AguardandoInicio, "Aguardando Inicio"),
    new KeyValueModel(EnumStatusProcessoTipo.EmProducao, "Em Produção"),
    new KeyValueModel(EnumStatusProcessoTipo.Finalizado, "Finalizado")
];

export const StatusProcessoTipoSelect: KeyValueModel[] = [
    new KeyValueModel(EnumStatusProcessoTipo.EmProducao, "Em Produção"),
    new KeyValueModel(EnumStatusProcessoTipo.AguardandoRetirada, "Aguardando Retirada"),
    new KeyValueModel(EnumStatusProcessoTipo.EmEntrega, "Em Entrega")
];
