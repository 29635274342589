import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetPontosVendaById() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getPontosVendaById = useCallback(
    (empresaId: string, id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${id}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );



  return {
    ...outrasPropriedades,
    getPontosVendaById,
  };




}
