import { Button, Card, Divider, Grid, Typography } from "@material-ui/core"
import { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { useGestaoToken, useToastSaurus } from "services/app"
import { CardEmpresaConfig } from "views/components/cards/card-empresa/card-empresa-config"
import { DialogOpcoesEmpresa } from "views/components/dialog/dialog-opcoes-empresa/dialog-opcoes-empresa"
import { useStyles } from './card-empresas-styles'
import { useSessaoAtual } from 'services/app'

export const CardEmpresas = () => {

    const { usuario } = useSessaoAtual()
    const { getEmpresaAtual } = useGestaoToken()
    const classes = useStyles()
    const history = useHistory()
    const [modal, setModal] = useState<boolean>(false)
    const [isClick, setIsClick] = useState<boolean>(false)
    const { showToast } = useToastSaurus()

    const empresaModalId = useRef<string>('')

    const click = (id: string) => {
        history.push(`/configuracoes-empresa/${id}`)
    }

    const idEmpresaAtual = getEmpresaAtual()?.id

    return (
        <>
            {usuario!.empresa.length > 1 && (
                <Card>
                    <div className={classes.defaultContainer}>
                        <Typography className={classes.title} variant="h6">Empresa</Typography>
                        <Grid item xs={12}>
                            {usuario!.empresa.length <= 5 ? (
                                <>
                                    {usuario?.empresa.sort((a, b) => {
                                        let variavel = 0
                                        if (a.Id === idEmpresaAtual && b.Id !== idEmpresaAtual) {
                                            variavel -= 100
                                        }
                                        if (a.Id !== idEmpresaAtual && b.Id === idEmpresaAtual) {
                                            variavel += 100
                                        }
                                        return variavel
                                    }).map((empresa, index) => {
                                        return (
                                            <>
                                                <CardEmpresaConfig
                                                    model={empresa}
                                                    onClick={empresa.Id === idEmpresaAtual ? click : () => {
                                                        empresaModalId.current = empresa.Id
                                                        setModal(!modal)
                                                    }}
                                                    onCheck={() => { }}
                                                    selected={false}
                                                    edicao={empresa.Id === idEmpresaAtual}
                                                />

                                                {empresa.Id === idEmpresaAtual && (
                                                    <>
                                                        <Grid className={classes.containerSubCard}>
                                                            <Typography variant="body2" className={classes.subcard}>
                                                                Essa é a empresa que está sendo utilizada neste momento, clique caso quiser alterar suas configurações.
                                                            </Typography>
                                                        </Grid>
                                                        <Divider style={{ backgroundColor: '#CACACA', height: 2, marginBottom: 16 }} />
                                                    </>
                                                )}
                                            </>
                                        )
                                    })}
                                    <DialogOpcoesEmpresa
                                        id={empresaModalId.current}
                                        openned={modal}
                                        closeModal={() => setModal(!modal)}
                                    />
                                    <Divider style={{ margin: '16px 0 8px 0', backgroundColor: '#CACACA' }} />
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={isClick}
                                        onClick={() => {
                                            if (usuario?.confirmado.toLowerCase() === "false") {
                                                setIsClick(!isClick)
                                                return showToast('error', "E-mail não confirmado. Favor, verifique sua caixa de entrada e confirme.")
                                            }
                                            history.push('/adicionar-empresa')
                                        }}
                                    >
                                        Adicionar nova empresa
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {usuario?.empresa
                                        .filter(x => x.Id === idEmpresaAtual)
                                        .map(empresa => {
                                            return (
                                                <CardEmpresaConfig
                                                    model={empresa}
                                                    onClick={click}
                                                    onCheck={() => { }}
                                                    selected={false}
                                                    edicao={empresa.Id === idEmpresaAtual}
                                                />
                                            )
                                        })}
                                    <Grid className={classes.containerSubCard}>
                                        <Typography variant="body2" className={classes.subcard}>
                                            Essa é a empresa que está sendo utilizada neste momento, clique caso quiser alterar suas configurações.
                                        </Typography>
                                    </Grid>
                                    <Button variant="contained" fullWidth color="primary" onClick={() => history.push('/lista-empresas')}>
                                        Ver outras empresas
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </div>
                </Card>
            )}
        </>
    )
}