import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: '#FFF0!important'
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(255 255 255 / 90%)'
    }
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    background: 'rgb(0 0 0 / 50%)',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
  container: {
    padding: theme.spacing(1),
  },
  containerScanner: {
    borderRadius: theme.shape.borderRadius,
    overflowY: 'hidden'
  },
  containericon: {
    justifyContent: 'center',
    display: 'flex'
  },
  iconClose: {
    fontSize: 50, 
    marginTop: theme.spacing(2),
    cursor: 'pointer' 
  }
}));
