import { forwardRef, useImperativeHandle } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ComandasEditModel } from 'model/api/gestao/comanda/comanda-edit-model';
import { useFormComandaFacilitadoValidation } from './form-comanda-facilitado-validation';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { SalvarNovoIcon } from 'views/components/icons';
import {useStyles} from './form-comanda-facilitado-styles'

export const FormComandasCadastroFacilitado = forwardRef<
    DefaultFormRefs<ComandasEditModel>,
    DefaultFormProps<ComandasEditModel>
>((props: DefaultFormProps<ComandasEditModel>, ref) => {
    const utilClasses = makeUtilClasses();
    const { FormComandaFacilitadoYupValidation } = useFormComandaFacilitadoValidation();
    const { getConfigByCod } = useEmpresaAtual();

    const { isMobile } = useThemeQueries();
    const classes = useStyles();

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
    } = useForm<ComandasEditModel>({
        defaultValues: { ...props.model },
        resolver: yupResolver(FormComandaFacilitadoYupValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: ComandasEditModel) => {
        props.onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: () => {
            reset(new ComandasEditModel())
        },
    }));

    return (
        <>
            <div className={utilClasses.formContainer}>
                {props.showLoading && props.loading && <CircularLoading tipo="FULLSIZED" />}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3} md={1}>
                            <Box display='flex' alignItems='center' flexDirection='column'>
                                <Typography variant='caption'>
                                    Prefixo
                                </Typography>
                                <Typography variant='caption' style={{ fontWeight: 600 }}>
                                    {getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda) || 'Sem Prefixo'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={7} md={4}>
                            <Controller
                                name="codigoComanda"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={props.loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Código"
                                        size='small'
                                        placeholder='Código da Comanda'
                                        autoComplete='new-password'
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(
                                            errors.codigoComanda && errors.codigoComanda.message,
                                        )}
                                        helperText={
                                            touchedFields.codigoComanda || errors.codigoComanda
                                                ? errors.codigoComanda?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} md={7} className={classes.buttonContainer}>
                            <Button variant='contained' color='primary' type="submit" size='small' style={{ height: 40 }}>
                                <SalvarNovoIcon class={classes.button} tipo='BUTTON_PRIMARY' />
                                {isMobile ? '' : 'Gerar Comanda'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
});