import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpNFMock } from 'data/mocks/tp-nf-mock';
import { IndPresMock } from 'data/mocks/ind-pres-mock';
import { FinNFeMock } from 'data/mocks/fin-nfe-mock';
import { IndFinalMock } from 'data/mocks/ind-final-mock';

export default function FormEntradaCadastroNFE(): React.ReactElement {
  const {
    control,
    setValue,
    formState: { errors, touchedFields }
  } = useFormContext();

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="infMov.nnf"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              variant="outlined"
              label="Número"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.nnf?.message)}
              helperText={
                touchedFields.nnf || errors.nnf
                  ? errors.nnf?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.serie"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              variant="outlined"
              label="Série"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.serie?.message)}
              helperText={
                touchedFields.serie || errors.serie
                  ? errors.serie?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="infMov.tpNF"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              fullWidth
              autoComplete="new-password"
              variant="outlined"
              label="Tipo de Operação"
              conteudo={TpNFMock}
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.tpNF?.message)}
              helperText={
                touchedFields.tpNF || errors.tpNF
                  ? errors.tpNF?.message
                  : undefined
              }
              {...field}
              onChange={(event) => {
                const value = TpNFMock.filter(
                  (x) => x.Key === event.target.value
                )[0]?.Key;
                setValue('infMov.tpNF', value);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="infMov.finNFe"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              fullWidth
              conteudo={FinNFeMock}
              variant="outlined"
              label="Finalidade"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.finNFe?.message)}
              helperText={
                touchedFields.finNFe || errors.finNFe
                  ? errors.finNFe?.message
                  : undefined
              }
              {...field}
              onChange={(event) => {
                const item = FinNFeMock.filter(
                  (item) => item.Key === event.target.value
                )[0]?.Key;
                setValue('infMov.finNFe', item);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name="infMov.indFinal"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              fullWidth
              autoComplete="new-password"
              variant="outlined"
              label="Consumidor Final"
              conteudo={IndFinalMock}
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.indFinal?.message)}
              helperText={
                touchedFields.indFinal || errors.indFinal
                  ? errors.indFinal?.message
                  : undefined
              }
              {...field}
              onChange={(event) => {
                const value = IndFinalMock.filter(
                  (x) => x.Key === event.target.value
                )[0]?.Key;
                setValue('infMov.indFinal', value);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.dhEmi"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="DATA_HORA"
              fullWidth
              variant="outlined"
              label="Data/Hora da Emissão"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.dataEmissao?.message)}
              helperText={
                touchedFields.dataEmissao || errors.dataEmissao
                  ? errors.dataEmissao?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="infMov.dhSaiEnt"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo={'DATA_HORA'}
              fullWidth
              autoComplete="new-password"
              variant="outlined"
              label="Data/Hora de Saída"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.dhSaiEnt?.message)}
              helperText={
                touchedFields.dhSaiEnt || errors.dhSaiEnt
                  ? errors.dhSaiEnt?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="infMov.indPres"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              fullWidth
              autoComplete="new-password"
              variant="outlined"
              label="Presença do Comprador"
              conteudo={IndPresMock}
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.indPres?.message)}
              helperText={
                touchedFields.indPres || errors.indPres
                  ? errors.indPres?.message
                  : undefined
              }
              {...field}
              onChange={(event) => {
                const value = IndPresMock.filter(
                  (x) => x.Key === event.target.value
                )[0]?.Key;
                setValue('infMov.indPres', value);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="infMov.natOp"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              tipo="TEXTO"
              fullWidth
              variant="outlined"
              label="Natureza da Operação"
              allowSubmit
              InputLabelProps={{
                shrink: true
              }}
              placeholder=""
              error={Boolean(errors?.natOp?.message)}
              helperText={
                touchedFields.natOp || errors.natOp
                  ? errors.natOp?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );
}
