import { PessoaModel } from "model/api/gestao/pessoa";
import { EmitModel } from "model/api/gestao/venda/venda-completa-model";
import { EnumFinNFe } from "model/enums/enum-fin-nfe";
import { EnumModFrete } from "model/enums/enum-mod-frete";

export class EntradaCadastroFormModel {
    constructor(
        public emitCnpj: string = '',
        public emit: EmitModel = new EmitModel(),
        public transpNomeTel: string = '',
        public transp: PessoaModel | null = null,
        public modFrete: EnumModFrete = EnumModFrete.SEM_TRANSPORTE,
        public natOp: string = '',
        public finNFe: EnumFinNFe = EnumFinNFe.NORMAL
    ) { }
}