import { KeyValueModel } from "model/api";

export const SituacaoGeralMock: KeyValueModel[] = [
  new KeyValueModel(-1, "Todos"),
  new KeyValueModel(true, "Habilitado"),
  new KeyValueModel(false, "Desabilitado"),
];
export const SituacaoGeralMockTodos: KeyValueModel[] = [
  new KeyValueModel(-1, "Todos"),
  new KeyValueModel(1, "Habilitado"),
  new KeyValueModel(0, "Desabilitado"),
];
