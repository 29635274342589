import * as Yup from "yup";
import { useMemo } from "react";
import { useValidationYupDocumento } from "views/components/form-validations";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";


const documentosPessoais = [
    EnumTipoDocumento.CarteiraIdentidade,
    EnumTipoDocumento.CarteiraTrabalhoPrevidenciaSocial,
    EnumTipoDocumento.Passaporte,
    EnumTipoDocumento.CarteiraRegistroProfissional
]

export const useFormPacientePreCadastroValidation = () => {

    const { documentoYup } = useValidationYupDocumento(true)

    const FormYupValidationPrePaciente = useMemo(() => {
        return Yup.object().shape({
            paciente: Yup.object().shape({
                documento: Yup.string().required('Documento é obrigatório'),
                nome: Yup.string().required('Nome é obrigatório'),
                tipoDocumento: Yup.string().required('Escolha o tipo do documento'),
                dataNascimento: Yup.string().test({
                    message: 'Data de Nascimento é obrigatória',
                    test: (value, ctx) => {
                        const tipoDocumento = ctx.parent.tipoDocumento

                        if (documentosPessoais.includes(Number(tipoDocumento))) {
                            if (!value) {
                                return false
                            }
                        }

                        return true
                    }
                }).typeError('Deve ser uma data válida'),
                cpfcnpj: documentoYup(),
            })
        });
    }, [documentoYup]);

    return {
        FormYupValidationPrePaciente,
    };
};
