import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DesfazerIcon, OkIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { usePDV } from 'services/app/hooks/pdv';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { TouchoneBacupkup } from 'database/touchone-backup';

type Props = {
  onClick: () => void;
};

export const PDVDiferenteConta = ({ onClick }: Props) => {
  // STATES E REFS
  const [dadosMovimentacao, setDadosMovimentacao] = useState<
    { qtdeSessoes: number; qtdeMovimentacao: number, vendas: number } | undefined
  >(undefined);

  // HOOKS
  const { delRegistro } = useGestaoStorage();
  const { limparCadastros } = useSincronizacaoCadastros();
  const { getPDV } = usePDV();
  const { getEmpresaSelecionada } = useSessaoAtual();

  // AUX
  const history = useHistory();
  const { isMobile } = useThemeQueries();

  const pdv = getPDV();

  const clickContinuar = useCallback(async () => {
    try{
      await limparCadastros();

    await TouchoneBacupkup.sessao.bulkAdd(
      await TouchoneDBPrimary.sessao
        .filter(
          (sessao) => !sessao.dataFechamento && sessao.caixaId === pdv?.id,
        )
        .toArray(),
    );

    await TouchoneBacupkup.sessaoValores.bulkAdd(
      await TouchoneDBPrimary.sessaoValores
        .filter(
          (valor) => !valor.dataSincronizacao && valor.caixaId === pdv?.id,
        )
        .toArray(),
    );

    await TouchoneBacupkup.vendas.bulkAdd(
      await TouchoneDBPrimary.vendas
        .filter(
          (valor) => !valor.dataSincronizacao && valor.empresaId === getEmpresaSelecionada()!.Id
        )
        .toArray(),
    );

    await TouchoneDBPrimary.limparDadosSessao();

    delRegistro(GestaoStorageKeys.PDV, false);
    delRegistro(GestaoStorageKeys.MovSimplesAnterior, false);
    delRegistro(GestaoStorageKeys.MovSimplesAtual, false);
    
    setTimeout(() => {
      onClick();
    }, 500);
    }catch(e: any){
      await TouchoneDBPrimary.limparDadosSessao();
      onClick();
    }
  }, [delRegistro, getEmpresaSelecionada, limparCadastros, onClick, pdv?.id]);

  useEffect(() => {
    const getDadosMovimentacao = async () => {
      const sessoes = await TouchoneDBPrimary.sessao
        .filter(
          (sessao) => !sessao.dataFechamento && sessao.caixaId === pdv?.id,
        )
        .count();
      const movimentacoes = await TouchoneDBPrimary.sessaoValores
        .filter(
          (valor) => !valor.dataSincronizacao && valor.caixaId === pdv?.id,
        )
        .count();

        const vendas = await TouchoneDBPrimary.vendas
        .filter(
          (valor) => !valor.dataSincronizacao
        )
        .count();

      setDadosMovimentacao({
        qtdeMovimentacao: movimentacoes,
        qtdeSessoes: sessoes,
        vendas: vendas
      });
    };

    getDadosMovimentacao();
  }, [pdv?.id]);

  return (
    <Grid
      item
      style={{
        maxWidth: 600,
        textAlign: 'center',
        padding: 16,
        borderRadius: 10,
        background: isMobile ? 'none' : '#FFF',
      }}
    >
      <Typography variant="h4" color="primary">
        PDV já Vinculado!
      </Typography>
      <Divider
        style={{
          marginBottom: 16,
          background: 'rgb(85 85 85 / 24%)',
          marginTop: 8,
        }}
      />
      <Typography variant="body1" style={{ marginBottom: 16 }}>
        Identificamos que este dispositivo está vinculado em outra conta.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 16 }}>
        CNPJ: {formatarCPFCNPJ(pdv?.dominio ?? '')} - PDV nº {pdv?.numCaixa}.
      </Typography>
      {((dadosMovimentacao?.qtdeSessoes ?? 0) > 0 ||
        (dadosMovimentacao?.qtdeMovimentacao ?? 0) > 0) && (
        <>
          <Typography variant="body1" style={{ marginBottom: 16 }}>
            Identificamos movimentações não sincronizadas.
          </Typography>
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            Sessões: {`${dadosMovimentacao?.qtdeSessoes ?? 0}, `} Movimentações:
            {` ${dadosMovimentacao?.qtdeMovimentacao ?? 0}, `} Vendas: {` ${dadosMovimentacao?.vendas ?? 0}`}
          </Typography>
        </>
      )}
      <Typography variant="body1" style={{ marginBottom: 32 }}>
        Para fazer vendas nesta conta, teremos que desvincular este ponto de
        venda da conta anterior e vincular nesta conta com novos dados.
      </Typography>

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={4}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
            onClick={() => history.push('/dashboard')}
          >
            <DesfazerIcon tipo="BUTTON" />
            Voltar
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={clickContinuar}
          >
            <OkIcon tipo="BUTTON_PRIMARY" />
            Vincular nesta Conta
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
