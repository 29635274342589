import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const DadosNfeIcon = ({
  class: className,
  fill,
  style,
  tipo,
  viewBox
}: DefaultIconProps) => {
  return (
    <DefaultIcon
      tipo={tipo}
      class={className}
      style={style}
      fill={fill}
      viewBox={viewBox}
    >
      <path d="M21.4931 7.75198C21.4931 7.50138 21.4237 7.3204 21.2435 7.13941C19.302 5.20427 17.3744 3.26216 15.4468 1.32005C15.2804 1.15299 15.114 1.07642 14.8713 1.07642C11.2103 1.08338 7.54931 1.08338 3.88136 1.07642C3.57627 1.07642 3.5 1.15299 3.5 1.45927C3.51387 5.16946 3.50693 8.87965 3.50693 12.5968C3.50693 16.3209 3.51387 20.045 3.5 23.7622C3.5 24.0824 3.5832 24.1659 3.90216 24.159C9.63636 24.152 15.3636 24.152 21.0978 24.159C21.4168 24.159 21.5 24.0754 21.5 23.7552C21.4861 18.4231 21.4861 13.091 21.4931 7.75198ZM15.5092 2.91411C16.8891 4.3063 18.2758 5.69849 19.6556 7.09069C19.6071 7.12549 19.51 7.09069 19.4129 7.09069C18.2203 7.09069 17.0208 7.08372 15.8282 7.09765C15.5786 7.09765 15.4884 7.05588 15.4884 6.77744C15.5023 5.56624 15.4954 4.35503 15.4954 3.14382C15.4954 3.06029 15.4607 2.9698 15.5092 2.91411ZM20.4877 22.8225C20.4877 23.08 20.4253 23.1566 20.1618 23.1566C15.0586 23.1496 9.95532 23.1496 4.85901 23.1566C4.58166 23.1566 4.51233 23.087 4.51233 22.8085C4.51926 19.4046 4.51926 16.0077 4.51926 12.6038C4.51926 9.21378 4.51926 5.82379 4.51233 2.4338C4.51233 2.16233 4.5678 2.07879 4.85208 2.07879C7.95146 2.09272 11.0508 2.08576 14.1502 2.07879C14.4206 2.07879 14.5108 2.14144 14.5039 2.42684C14.49 4.181 14.5108 5.94213 14.49 7.69629C14.4831 8.0513 14.6009 8.10699 14.9129 8.10002C16.6602 8.0861 18.4076 8.10002 20.1618 8.0861C20.4183 8.0861 20.4946 8.14179 20.4946 8.41327C20.4807 13.2163 20.4807 18.0194 20.4877 22.8225Z" />
      <path d="M21.4931 7.75198C21.4931 7.50138 21.4237 7.3204 21.2435 7.13941C19.302 5.20427 17.3744 3.26216 15.4468 1.32005C15.2804 1.15299 15.114 1.07642 14.8713 1.07642C11.2103 1.08338 7.54931 1.08338 3.88136 1.07642C3.57627 1.07642 3.5 1.15299 3.5 1.45927C3.51387 5.16946 3.50693 8.87965 3.50693 12.5968C3.50693 16.3209 3.51387 20.045 3.5 23.7622C3.5 24.0824 3.5832 24.1659 3.90216 24.159C9.63636 24.152 15.3636 24.152 21.0978 24.159C21.4168 24.159 21.5 24.0754 21.5 23.7552C21.4861 18.4231 21.4861 13.091 21.4931 7.75198ZM15.5092 2.91411C16.8891 4.3063 18.2758 5.69849 19.6556 7.09069C19.6071 7.12549 19.51 7.09069 19.4129 7.09069C18.2203 7.09069 17.0208 7.08372 15.8282 7.09765C15.5786 7.09765 15.4884 7.05588 15.4884 6.77744C15.5023 5.56624 15.4954 4.35503 15.4954 3.14382C15.4954 3.06029 15.4607 2.9698 15.5092 2.91411ZM20.4877 22.8225C20.4877 23.08 20.4253 23.1566 20.1618 23.1566C15.0586 23.1496 9.95532 23.1496 4.85901 23.1566C4.58166 23.1566 4.51233 23.087 4.51233 22.8085C4.51926 19.4046 4.51926 16.0077 4.51926 12.6038C4.51926 9.21378 4.51926 5.82379 4.51233 2.4338C4.51233 2.16233 4.5678 2.07879 4.85208 2.07879C7.95146 2.09272 11.0508 2.08576 14.1502 2.07879C14.4206 2.07879 14.5108 2.14144 14.5039 2.42684C14.49 4.181 14.5108 5.94213 14.49 7.69629C14.4831 8.0513 14.6009 8.10699 14.9129 8.10002C16.6602 8.0861 18.4076 8.10002 20.1618 8.0861C20.4183 8.0861 20.4946 8.14179 20.4946 8.41327C20.4807 13.2163 20.4807 18.0194 20.4877 22.8225Z" />
      <path d="M7.36873 12.1164C9.15764 12.1025 10.9535 12.1094 12.7424 12.1094C14.4481 12.1094 16.1469 12.1094 17.8526 12.1094C18.4974 12.1094 18.4766 12.1094 18.5043 11.476C18.5182 11.1558 18.4003 11.1001 18.1091 11.1001C14.6284 11.1071 11.1407 11.1071 7.65995 11.1071C7.00125 11.1071 7.02205 11.1071 6.99431 11.7614C6.98045 12.0746 7.09139 12.1164 7.36873 12.1164Z" />
      <path d="M7.36873 12.1164C9.15764 12.1025 10.9535 12.1094 12.7424 12.1094C14.4481 12.1094 16.1469 12.1094 17.8526 12.1094C18.4974 12.1094 18.4766 12.1094 18.5043 11.476C18.5182 11.1558 18.4003 11.1001 18.1091 11.1001C14.6284 11.1071 11.1407 11.1071 7.65995 11.1071C7.00125 11.1071 7.02205 11.1071 6.99431 11.7614C6.98045 12.0746 7.09139 12.1164 7.36873 12.1164Z" />
      <path d="M7.35479 10.1186C9.15757 10.1047 10.9603 10.1116 12.7631 10.1116C14.4688 10.1116 16.1676 10.1116 17.8733 10.1116C18.4904 10.1116 18.4696 10.1116 18.4973 9.50603C18.5112 9.18583 18.4141 9.10229 18.0952 9.10229C14.6075 9.11622 11.1268 9.10926 7.63908 9.10926C6.99424 9.10926 7.00811 9.10926 6.9873 9.7427C6.9873 10.0351 7.05664 10.1256 7.35479 10.1186Z" />
      <path d="M7.35479 10.1186C9.15757 10.1047 10.9603 10.1116 12.7631 10.1116C14.4688 10.1116 16.1676 10.1116 17.8733 10.1116C18.4904 10.1116 18.4696 10.1116 18.4973 9.50603C18.5112 9.18583 18.4141 9.10229 18.0952 9.10229C14.6075 9.11622 11.1268 9.10926 7.63908 9.10926C6.99424 9.10926 7.00811 9.10926 6.9873 9.7427C6.9873 10.0351 7.05664 10.1256 7.35479 10.1186Z" />
      <path d="M7.34142 6.10224C9.16499 6.08832 10.9886 6.09528 12.8052 6.09528C13.5124 6.09528 13.5055 6.09528 13.5055 5.37134C13.5055 5.14859 13.4431 5.08594 13.2282 5.08594C12.2436 5.0929 11.2659 5.0929 10.2813 5.0929C9.40767 5.0929 8.53402 5.0929 7.6673 5.0929C7.00166 5.0929 7.01553 5.0929 7.00166 5.74723C6.9878 6.02567 7.05713 6.10224 7.34142 6.10224Z" />
      <path d="M7.34142 6.10224C9.16499 6.08832 10.9886 6.09528 12.8052 6.09528C13.5124 6.09528 13.5055 6.09528 13.5055 5.37134C13.5055 5.14859 13.4431 5.08594 13.2282 5.08594C12.2436 5.0929 11.2659 5.0929 10.2813 5.0929C9.40767 5.0929 8.53402 5.0929 7.6673 5.0929C7.00166 5.0929 7.01553 5.0929 7.00166 5.74723C6.9878 6.02567 7.05713 6.10224 7.34142 6.10224Z" />
      <path d="M13.1583 7.0907C11.3417 7.10462 9.53199 7.09766 7.71535 7.09766C6.98037 7.09766 6.99424 7.09766 6.9873 7.8216C6.9873 8.03739 7.04277 8.107 7.26465 8.10004C8.26311 8.08612 9.25464 8.09308 10.2531 8.09308C11.1129 8.09308 11.9796 8.09308 12.8394 8.09308C13.4981 8.09308 13.4842 8.09308 13.4981 7.43875C13.512 7.17423 13.4426 7.08374 13.1583 7.0907Z" />
      <path d="M13.1583 7.0907C11.3417 7.10462 9.53199 7.09766 7.71535 7.09766C6.98037 7.09766 6.99424 7.09766 6.9873 7.8216C6.9873 8.03739 7.04277 8.107 7.26465 8.10004C8.26311 8.08612 9.25464 8.09308 10.2531 8.09308C11.1129 8.09308 11.9796 8.09308 12.8394 8.09308C13.4981 8.09308 13.4842 8.09308 13.4981 7.43875C13.512 7.17423 13.4426 7.08374 13.1583 7.0907Z" />
      <path d="M12.507 13.3834C12.507 13.1537 12.4307 13.1119 12.2296 13.1189C10.725 13.1258 9.22729 13.1258 7.72267 13.1258C6.97382 13.1258 6.98076 13.1258 6.99463 13.8846C6.99463 14.1004 7.0709 14.1421 7.26504 14.1352C8.09016 14.1282 8.92221 14.1282 9.74732 14.1282C10.4199 14.1282 11.0925 14.1282 11.765 14.1282C12.5139 14.1282 12.5 14.1282 12.507 13.3834Z" />
      <path d="M12.507 13.3834C12.507 13.1537 12.4307 13.1119 12.2296 13.1189C10.725 13.1258 9.22729 13.1258 7.72267 13.1258C6.97382 13.1258 6.98076 13.1258 6.99463 13.8846C6.99463 14.1004 7.0709 14.1421 7.26504 14.1352C8.09016 14.1282 8.92221 14.1282 9.74732 14.1282C10.4199 14.1282 11.0925 14.1282 11.765 14.1282C12.5139 14.1282 12.5 14.1282 12.507 13.3834Z" />
      <path d="M15.5097 14.1282C13.3048 14.1212 11.509 15.9032 11.502 18.1168C11.4882 20.3165 13.2771 22.1333 15.4751 22.1472C17.6661 22.1611 19.4689 20.3583 19.4758 18.1516C19.4897 15.9311 17.7147 14.1352 15.5097 14.1282ZM15.4543 21.1448C13.7902 21.124 12.4728 19.7526 12.5005 18.0611C12.5282 16.4253 13.908 15.0958 15.5375 15.1306C17.2085 15.1654 18.512 16.5088 18.4912 18.1795C18.4635 19.8362 17.1114 21.1657 15.4543 21.1448Z" />
      <path d="M15.5097 14.1282C13.3048 14.1212 11.509 15.9032 11.502 18.1168C11.4882 20.3165 13.2771 22.1333 15.4751 22.1472C17.6661 22.1611 19.4689 20.3583 19.4758 18.1516C19.4897 15.9311 17.7147 14.1352 15.5097 14.1282ZM15.4543 21.1448C13.7902 21.124 12.4728 19.7526 12.5005 18.0611C12.5282 16.4253 13.908 15.0958 15.5375 15.1306C17.2085 15.1654 18.512 16.5088 18.4912 18.1795C18.4635 19.8362 17.1114 21.1657 15.4543 21.1448Z" />
      <path d="M16.7916 17.6297C15.9942 17.6297 15.9942 17.6297 15.9942 16.8431C15.9942 16.0565 15.9942 16.0635 15.1968 16.1192C15.0373 16.1331 14.9888 16.1818 14.9888 16.335C14.9957 16.69 14.9819 17.045 14.9957 17.4C15.0027 17.574 14.9472 17.6367 14.7669 17.6297C14.4341 17.6158 14.1013 17.6367 13.7754 17.6227C13.5535 17.6088 13.498 17.6854 13.498 17.9081C13.498 18.6321 13.4911 18.6321 14.1984 18.6321C14.2192 18.6321 14.24 18.6321 14.2677 18.6321C14.9957 18.6321 14.9957 18.6321 14.9957 19.356C14.9957 19.3769 14.9957 19.3978 14.9957 19.4256C14.9957 20.1635 14.9957 20.1565 15.7307 20.1496C15.9249 20.1496 16.0081 20.1008 16.0011 19.892C15.9873 19.5788 16.0081 19.2725 15.9942 18.9592C15.9803 18.7156 16.0427 18.6182 16.2993 18.6321C16.6044 18.653 16.9164 18.6251 17.2284 18.639C17.4225 18.646 17.4988 18.5903 17.4988 18.3815C17.5127 17.6297 17.5196 17.6297 16.7916 17.6297Z" />
      <path d="M16.7916 17.6297C15.9942 17.6297 15.9942 17.6297 15.9942 16.8431C15.9942 16.0565 15.9942 16.0635 15.1968 16.1192C15.0373 16.1331 14.9888 16.1818 14.9888 16.335C14.9957 16.69 14.9819 17.045 14.9957 17.4C15.0027 17.574 14.9472 17.6367 14.7669 17.6297C14.4341 17.6158 14.1013 17.6367 13.7754 17.6227C13.5535 17.6088 13.498 17.6854 13.498 17.9081C13.498 18.6321 13.4911 18.6321 14.1984 18.6321C14.2192 18.6321 14.24 18.6321 14.2677 18.6321C14.9957 18.6321 14.9957 18.6321 14.9957 19.356C14.9957 19.3769 14.9957 19.3978 14.9957 19.4256C14.9957 20.1635 14.9957 20.1565 15.7307 20.1496C15.9249 20.1496 16.0081 20.1008 16.0011 19.892C15.9873 19.5788 16.0081 19.2725 15.9942 18.9592C15.9803 18.7156 16.0427 18.6182 16.2993 18.6321C16.6044 18.653 16.9164 18.6251 17.2284 18.639C17.4225 18.646 17.4988 18.5903 17.4988 18.3815C17.5127 17.6297 17.5196 17.6297 16.7916 17.6297Z" />
    </DefaultIcon>
  );
};
