import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#BCBCBC',
        lineHeight: '14,1px'
    },
    subtitle: {
        fontWeight: 600,
        color: '#8b8b8b'
    },
    cancelar: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
    inputValorCenter: {
        "& input": {
            fontWeight: 600,
            fontSize: 40,
            padding: '12px 14px',
            textAlign: 'center',
        },
    },
    buttonCancelar: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
    cardInativo: {
        opacity: 0.6,
        // filter: 'grayscale(1)',
    },
}));
