import * as Yup from 'yup';

export const useFormSaloesEditValidation = () => {

  const FormSaloesEditYupValidation = Yup.object().shape({
    descricao: Yup.string().required('O campo descrição é obrigatório'),
    balcao: Yup.string().required('Selecione uma opção'),
  });

  return {
    FormSaloesEditYupValidation
  }
}

