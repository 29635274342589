import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';

export const MovHistoricoPagamentoHeader = () => {

  const history = useHistory()

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => {
          history.push('/venda-simples/novo-pagamento')
        }}
      />
    );
  }, [history]);

  return (
    <PrivatePageHeader leftArea={leftArea()} title={'Histórico de Pagamento'} />
  );
};
