import { Button } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCadastros, useToastSaurus } from 'services/app/hooks';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import {
  VoltarIcon,
  AvancarIcon,
} from 'views/components/icons';
import { useFormStepper } from 'views/components/form-stepper';
import { EnumTelaReceita } from 'model/enums/enum-aba-tela-receita';
import { FormStep } from 'model/app';
import { ReceitaStepIcon } from 'views/components/icons/receita-step-icon';
import { PrescritortepIcon } from 'views/components/icons/prescritor-step-icon';
import { ReceitaIcon } from 'views/components/icons/receita-icon';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { FormReceitaPreCadastro } from 'views/components/form/receita/form-receita/form-receita';
import { ReceitaPreCadastroFormModel } from 'model/app/forms/receita/receita-form-model';
import { toDateString } from 'utils/to-date';
import { PrescritorPreCadastroFormModel } from 'model/app/forms/receita/prescritor-form-model';
import { FormPrescritorPreCadastro } from 'views/components/form/receita/form-prescritor/form-prescritor';
import { CompradorPacientePreCadastroFormModel } from 'model/app/forms/receita/comprador-paciente-form-model';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { ListProductsMed } from 'views/components/form/receita/list-products/list-products-med';
import { CompradorModel, PacienteModel, PrescritorModel, ReceitaMedicaModel } from 'model/api/gestao/venda/venda-completa-model';
import { picker } from 'utils/picker';
import { guidEmpty } from 'utils/guid-empty';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { FormPacientePreCadastro } from 'views/components/form/receita/form-paciente/form-paciente';
import { FormCompradorPreCadastro } from 'views/components/form/receita/form-comprador/form-comprador';
import { stringNumeros } from 'utils/string-numeros';
import { useMovAtual } from 'services/app/hooks/mov-atual';

export const useReceitaCadastro = () => {
  const { getMov } = useMovAtual()
  const { showToast } = useToastSaurus();
  const { currentStep, nextStep, prevStep } = useFormStepper(4);
  const { abrirRastroMedicamentoModal } = useCadastros()
  const loading: boolean = false;

  const refPreCadastroReceita =
    useRef<DefaultFormRefs<ReceitaPreCadastroFormModel>>(null);
  const refPreCadastroPrescritor =
    useRef<DefaultFormRefs<PrescritorPreCadastroFormModel>>(null);
  const refPreCadastroCompradorPaciente =
    useRef<DefaultFormRefs<CompradorPacientePreCadastroFormModel>>(null);

  //-----------------------------------------refs para os forms no  vai e volta do stepper
  const refReceitaModel = useRef<ReceitaPreCadastroFormModel>(
    new ReceitaPreCadastroFormModel(),
  );
  const refPrescritorModelForm = useRef<PrescritorPreCadastroFormModel>(
    new PrescritorPreCadastroFormModel(),
  );
  const refCompradorPacienteModelForm =
    useRef<CompradorPacientePreCadastroFormModel>(
      new CompradorPacientePreCadastroFormModel(),
    );

  const pularPaciente = useRef<boolean>(false)
  const refMedicamentos = useRef<MovSimplesProdutoModel[]>([])

  const getFormPreCadastroReceita = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroReceita = (
      modelo: ReceitaPreCadastroFormModel,
    ) => {
      refReceitaModel.current = { ...modelo, dataHoraLancamento: toDateString(modelo.dataHoraLancamento, 'yyyy-MM-DDTHH:mm')!, dataReceita: toDateString(new Date(modelo.dataReceita), 'yyyy-MM-DD')! };
      nextStep();
    };

    return (
      <FormReceitaPreCadastro
        loading={false}
        ref={refPreCadastroReceita}
        onSubmit={handleSubmitFormPreCadastroReceita}
        showLoading={false}
      />
    );
  }, [nextStep]);

  const getFormPreCadastroPrescritor = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroPrescritor = async (
      modelo: PrescritorPreCadastroFormModel,
    ) => {
      refPrescritorModelForm.current = modelo;
      nextStep();
    };
    return (
      <FormPrescritorPreCadastro
        showLoading={false}
        ref={refPreCadastroPrescritor}
        loading={false}
        onSubmit={handleSubmitFormPreCadastroPrescritor}
      />
    );
  }, [nextStep]);

  const getFormPreCadastroComprador = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroComprador = async (
      modelo: CompradorPacientePreCadastroFormModel,
    ) => {
      refCompradorPacienteModelForm.current = {
        ...modelo,
        comprador: {
          ...modelo.comprador,
          dataNascimento: toDateString(new Date(modelo.comprador.dataNascimento), 'yyyy-MM-DD')!
        }
      };
      nextStep();
    };
    return (
      <FormCompradorPreCadastro
        showLoading={false}
        ref={refPreCadastroCompradorPaciente}
        loading={false}
        onSubmit={handleSubmitFormPreCadastroComprador}
      />
    );
  }, [nextStep]);

  const getFormPreCadastroPaciente = useCallback((): JSX.Element => {
    const handleSubmitFormPreCadastroPaciente = async (
      modelo: CompradorPacientePreCadastroFormModel,
    ) => {
      refCompradorPacienteModelForm.current = { ...modelo, paciente: { ...modelo.paciente, dataNascimento: toDateString(new Date(modelo.paciente.dataNascimento), 'yyyy-MM-DD')! } }

      nextStep();
    };

    return (
      <FormPacientePreCadastro
        showLoading={false}
        ref={refPreCadastroCompradorPaciente}
        loading={false}
        onSubmit={handleSubmitFormPreCadastroPaciente}
      />
    );
  }, [nextStep]);

  const handleSubmitMedicamentos = useCallback(async () => {
    if (refMedicamentos.current.length <= 0) {
      showToast('info', 'Selecione um medicamento para avançar.')
      return
    }

    const receita = refReceitaModel.current
    const comprador = picker<CompradorModel>(refCompradorPacienteModelForm.current.comprador, new CompradorModel())
    const paciente = picker<PacienteModel>(refCompradorPacienteModelForm.current.paciente, new PacienteModel())
    const prescritor = picker<PrescritorModel>(refPrescritorModelForm.current, new PrescritorModel())
    paciente.cpfcnpj = stringNumeros(paciente.cpfcnpj)
    comprador.cpfcnpj = stringNumeros(comprador.cpfcnpj)
    prescritor.cpfcnpj = stringNumeros(prescritor.cpfcnpj)
    const receitaMed: ReceitaMedicaModel = {
      ...new ReceitaMedicaModel(),
      cid: receita.cid,
      dataHoraLancamento: receita.dataHoraLancamento,
      dataReceita: receita.dataReceita,
      numeroReceita: receita.numeroReceita,
      receitaTipo: receita.receitaTipo,
      comprador,
      prescritor,
      paciente,
      id: guidEmpty(),
      ideId: guidEmpty(),
      compradorPacienteMesmaPessoa: refCompradorPacienteModelForm.current.compradorPacienteMesmaPessoa,
      uf: receita.uf,
    }
    receitaMed.comprador.dataNascimento = comprador.dataNascimento ?? new Date('0000-00-00')
    receitaMed.paciente.dataNascimento = paciente.dataNascimento ?? new Date('0000-00-00')
    abrirRastroMedicamentoModal(refMedicamentos.current, receitaMed)
  }, [abrirRastroMedicamentoModal, showToast]);

  const getPreSelecaoMedicamentos = useCallback((): JSX.Element => {
    return (
      <ListProductsMed  {...{ refMedicamentos }} />
    );
  }, []);

  const retTelaIndex = useCallback(
    (index: number): EnumTelaReceita | undefined => {
      switch (index) {
        case 0:
          return EnumTelaReceita.Receita;
        case 1:
          return EnumTelaReceita.Prescritor;
        case 2:
          return EnumTelaReceita.Comprador;
        case 3:
          return EnumTelaReceita.Paciente;
        case 4:
          return EnumTelaReceita.Medicamentos;
      }
    },
    [],
  );

  useEffect(() => {
    const mov = getMov()
    let compradorToSet = undefined
    let pacienteToSet = undefined
    let prescritorToSet = undefined

    const prescritorDefault = new PrescritorPreCadastroFormModel()
    const compradorPacienteDefault = new CompradorPacientePreCadastroFormModel()

    if (mov) {
      if (mov?.receitasMedicas.length > 0) {
        const prevPrescritor = {
          ...mov.receitasMedicas[mov.receitasMedicas.length - 1].prescritor,
        }
        const prevComprador = {
          ...mov.receitasMedicas[mov.receitasMedicas.length - 1].comprador,
        }
        const prevPaciente = {
          ...mov.receitasMedicas[mov.receitasMedicas.length - 1].paciente,
        }

        // Compare if the prescritor is different from the default
        if (JSON.stringify(refPrescritorModelForm.current) !== JSON.stringify(prescritorDefault)) {
          if (JSON.stringify(prevPrescritor) !== JSON.stringify(refPrescritorModelForm.current)) {
            prescritorToSet = refPrescritorModelForm.current
          } else {
            prescritorToSet = prevPrescritor
          }
        } else {
          prescritorToSet = prevPrescritor
        }

        // Compare if the comprador is different from the default
        if (JSON.stringify(refCompradorPacienteModelForm.current.comprador) !== JSON.stringify(compradorPacienteDefault.comprador)) {
          if (JSON.stringify(prevComprador) !== JSON.stringify(refCompradorPacienteModelForm.current.comprador)) {
            compradorToSet = refCompradorPacienteModelForm.current.comprador
          } else {
            compradorToSet = prevComprador
          }
        } else {
          compradorToSet = prevComprador
        }

        // Compare if the paciente is different from the default

        if (JSON.stringify(refCompradorPacienteModelForm.current.paciente) !== JSON.stringify(compradorPacienteDefault.paciente)) {
          if (JSON.stringify(prevPaciente) !== JSON.stringify(refCompradorPacienteModelForm.current.paciente)) {
            pacienteToSet = refCompradorPacienteModelForm.current.paciente
          } else {
            pacienteToSet = prevPaciente
          }
        } else {
          pacienteToSet = prevPaciente
        }
      }
    }

    switch (retTelaIndex(currentStep)) {
      case EnumTelaReceita.Receita:
        refPreCadastroReceita.current?.fillForm(refReceitaModel.current);
        break;
      case EnumTelaReceita.Prescritor:
        refPreCadastroPrescritor.current?.fillForm(prescritorToSet);
        break;
      case EnumTelaReceita.Comprador:
        //Verifica se algum campo é diferente do new        
        refPreCadastroCompradorPaciente.current?.fillForm(
          {
            comprador: compradorToSet ?? refCompradorPacienteModelForm.current.comprador,
            compradorPacienteMesmaPessoa: refCompradorPacienteModelForm.current.compradorPacienteMesmaPessoa,
            paciente: pacienteToSet ?? refCompradorPacienteModelForm.current.paciente,
          }
        );
        break;
      case EnumTelaReceita.Paciente:
        refPreCadastroCompradorPaciente.current?.fillForm(
          {
            comprador: compradorToSet ?? refCompradorPacienteModelForm.current.comprador,
            compradorPacienteMesmaPessoa: refCompradorPacienteModelForm.current.compradorPacienteMesmaPessoa,
            paciente: pacienteToSet ?? refCompradorPacienteModelForm.current.paciente,
          }
        );
        if (pularPaciente.current) {
          pularPaciente.current = false
          nextStep()
        }
        break;
      case EnumTelaReceita.Medicamentos:
        break;
    }
  }, [currentStep, getMov, nextStep, retTelaIndex]);

  const avancarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelaReceita.Receita:
        refPreCadastroReceita.current?.submitForm();
        break;
      case EnumTelaReceita.Prescritor:
        refPreCadastroPrescritor.current?.submitForm();
        break;
      case EnumTelaReceita.Comprador:
        refPreCadastroCompradorPaciente.current?.submitForm();
        break;
      case EnumTelaReceita.Paciente:
        refPreCadastroCompradorPaciente.current?.submitForm();
        break;
      case EnumTelaReceita.Medicamentos:
        handleSubmitMedicamentos()
        break;
    }
  }, [currentStep, handleSubmitMedicamentos, retTelaIndex]);

  const voltarStep = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const voltarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={voltarStep}
      >
        <VoltarIcon tipo="BUTTON" />
        Voltar
      </Button>
    );
  }, [voltarStep, loading]);

  const avancarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        fullWidth={true}
        disabled={loading}
        onClick={() => avancarStep()}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Avançar
      </Button>
    );
  }, [loading, avancarStep]);

  const getFormArray = useMemo(() => {
    const ret = [];

    let i = 0;
    while (retTelaIndex(i)) {
      const tela = retTelaIndex(i);

      switch (tela) {
        case EnumTelaReceita.Receita:
          ret.push(
            new FormStep(
              'Receita',
              `Informe os dados da receita.`,
              <ReceitaStepIcon tipo="GERAL" />,
              'Receita',
              <ReceitaStepIcon tipo="GERAL" />,
              getFormPreCadastroReceita(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
        case EnumTelaReceita.Prescritor:
          ret.push(
            new FormStep(
              'Prescritor',
              `Informe os dados do prescritor`,
              <PrescritortepIcon tipo="GERAL" />,
              'Prescritor',
              <PrescritortepIcon tipo="GERAL" />,
              getFormPreCadastroPrescritor(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
        case EnumTelaReceita.Comprador:
          ret.push(
            new FormStep(
              `Comprador`,
              `Informe os dados do Comprador.`,
              <ReceitaIcon tipo="GERAL" />,
              `Comprador`,
              <ReceitaIcon tipo="GERAL" />,
              getFormPreCadastroComprador(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
        case EnumTelaReceita.Paciente:
          ret.push(
            new FormStep(
              `Paciente`,
              `Informe os dados do Paciente.`,
              <PessoaIcon tipo="GERAL" />,
              `Paciente`,
              <PessoaIcon tipo="GERAL" />,
              getFormPreCadastroPaciente(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
        case EnumTelaReceita.Medicamentos:
          ret.push(
            new FormStep(
              'Medicamentos',
              `Selecione os medicamentos da receita`,
              <MedicamentoIcon tipo="GERAL" />,
              'Medicamentos',
              <MedicamentoIcon tipo="GERAL" />,
              getPreSelecaoMedicamentos(),
              voltarButton,
              avancarButton,
            ),
          );
          break;
      }
      i++;
    }

    if (ret.length > 0) {
      ret[ret.length - 1].previousButton = voltarButton;
      ret[0].previousButton = undefined;
    }

    return ret;
  }, [retTelaIndex, getFormPreCadastroReceita, voltarButton, avancarButton, getFormPreCadastroPrescritor, getFormPreCadastroComprador, getFormPreCadastroPaciente, getPreSelecaoMedicamentos]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep,
    },
    formArray: getFormArray,
    carregando: loading,
  };
};
