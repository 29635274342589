
export class DashboardVendasModel {
    constructor(
        public data: number = 0,
        public hora: number = 0,
        public mod: number = 0,
        public qClientes: number = 0,
        public qCom: number = 0,
        public qItens: number = 0,
        public qVendas: number = 0,
        public status: number = 0,
        public vCusto: number = 0,
        public vVenda: number = 0,
        public empresa: string = '',
        public empresaId: string = '',
        public contratoId: string = '',
    ) { }
}