import { useCallback } from "react";
import { useApiBase } from "../../base/api-base"
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function useDeleteModificador() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const deleteModificador = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador/${id}`,
        method: "DELETE",
        enviarTokenUsuario: true,
      }),
    [getEmpresaSelecionada, invocarApi]
  );

  return {
    ...outrasPropriedades,
    deleteModificador,
  };




}