import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { guidEmpty } from "../../../../../utils/guid-empty";
import { EnumIndEscala } from "../../../../enums/enum-ind-escala";
import { EnumOrigemMercadoria } from "../../../../enums/enum-origem-mercadoria";
import { ProdutoGradeModel } from "../produto-grade/produto-grade-model";


export class ProdutoCompletoModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public infAdic: string = "",
    public categoriaId: string = guidEmpty(),
    public ncmId: string = guidEmpty(),
    public marcaId: string = "",
    public cnpjFab: string = "",
    public orig: EnumOrigemMercadoria = EnumOrigemMercadoria.OM_NACIONAL,
    public indEscala: EnumIndEscala = EnumIndEscala.N,
    public grades: Array<ProdutoGradeModel> = new Array<ProdutoGradeModel>(),
    public codigoNcm: string = "",
    public impostoId: null = null,
    public imposto: string = "",
    public setorId: string | null = null,
    public cobraTaxaServico: boolean = true,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto
  ) { }
}
