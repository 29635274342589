import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovInsercaoStorage } from 'services/app/hooks/mov-insercao-storage';
import { PadraoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';

interface EscolhaPadraoProps {
  parent: EnumInsercaoFavorita;
}

export const EscolhaPadrao = ({ parent }: EscolhaPadraoProps) => {
  // HOOKS
  const { setInsercaoFavorita, getInsercaoFavorita } = useMovInsercaoStorage();
  const { addHandler, removeHandler } = useEventTools();
  const { theme } = useThemeQueries()

  // STATES E REFS
  const [padrao, setPadrao] = useState<string>(getInsercaoFavorita());

  const handleEvent = useCallback(
    (rota: EnumInsercaoFavorita) => {
      setPadrao(rota);
      setInsercaoFavorita(rota);
    },
    [setInsercaoFavorita]
  );

  useEffect(() => {
    addHandler(AppEventEnum.EstrelaFavorito, handleEvent);

    return () => removeHandler(AppEventEnum.EstrelaFavorito, handleEvent);
  }, [addHandler, handleEvent, removeHandler]);

  return (
    <PadraoIcon fill={padrao === parent ? theme.palette.primary.main : '#B7B7B7'} />
  );
};
