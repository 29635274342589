
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { MesasNovaModel } from "model/api/gestao/mesa/mesa-nova-model";

export function usePutMesa() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putMesa = useCallback(
		(mesa: MesasNovaModel, empresaId: string, salaoId: string) =>
			invocarApi({
				url: `/v2/empresa/${empresaId}/salao/${salaoId}/mesa/${mesa.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify(mesa),
				enviarTokenUsuario: true,
				baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
			}),
		[invocarApi],
	);

	return {
		...outrasPropriedades,
		putMesa,
	};
}
