import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';


export class PutPerfilPermissaoProps {
	constructor(
			public perfilId: string = '',
			public codigo: string = '',
			public valor: string = '',
	) { }
}

export function usePutPerfilPermissao() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putPerfilPermissao = useCallback(
		(permissoes: Array<PutPerfilPermissaoProps>, perfilId: string) =>
	      invocarApi({
	        url: `/${VariaveisAmbiente.gatewayVersion}/perfil/${perfilId}/permissao`,
	        method: "PUT",
	        headers: { "Content-Type": "application/json" },
	        data: JSON.stringify(permissoes),
	        enviarTokenUsuario: true,
	      }),
	    [invocarApi]
	);
	return {
		...outrasPropriedades,
		putPerfilPermissao
	}
}