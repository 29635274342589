import { Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-codigo-styles';
import { CircularLoading } from '../../..';
import { FormCodigoEdit } from 'views/components/form/produto/form-produto-edit/produto-edit-codigos/components/form-codigo-edit';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';

interface DialogAdicionarCodigoProps {
  close: () => void;
  carregando?: boolean;
  aberto: boolean;
  onSubmit: (model: ProdutoCodigoModel, beforeModel?: ProdutoCodigoModel) => Promise<boolean>;
}

export const DialogAdicionarCodigo = ({
  close,
  carregando,
  aberto,
  onSubmit
}: DialogAdicionarCodigoProps) => {
  const classes = useStyles();

  return (
    <>
      {aberto && (
        <DialogSaurus aberto={aberto || false} titulo="Adicionar Código" tamanho="sm" isButtonTitleClose handleClickClose={close}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Grid className={classes.cardContainer}>
            <Grid className={classes.cardContent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormCodigoEdit
                    edit={false}
                    carregando={false}                    
                    model={new ProdutoCodigoModel()}
                    onSubmit={onSubmit}
                    loading={false}
                    showLoading={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogSaurus>
      )}
    </>
  );
};
