import { Box, Card, Divider, Grid, Typography } from "@material-ui/core"
import { isEmpty } from "lodash";
import { useThemeQueries } from "views/theme";
import { CardDadosClienteDetalheVendaProps } from "./card-dados-cliente-detahes-venda-props";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { phoneMask } from "utils/phone-mask";

export const CardDadosClienteDetalheVenda = (props: CardDadosClienteDetalheVendaProps) => {

    const { theme } = useThemeQueries();
    
    return (
        <Card style={{ boxShadow: theme.shadows[1] }}>
            <Grid container spacing={2} style={{ padding: '8px' }}>
                <Grid item xs={12}>
                    <Typography
                        color="textPrimary"
                        style={{
                            fontSize: '20px'
                        }}
                    >
                        <Box fontWeight={600}>
                            Dados do Cliente
                        </Box>
                    </Typography>
                    <Divider variant="fullWidth" style={{ background: theme.palette.grey[400] }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Nome
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.dest.xNome) ? props.model.infMov.dest.xNome : 'Consumidor'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                CPF/CNPJ
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.dest.documento) ? formatarCPFCNPJ(props.model.infMov.dest.documento) : 'Não Informado'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Telefone
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.dest.fone) ? phoneMask(props.model.infMov.dest.fone) : 'Sem Telefone'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                E-mail
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                            >
                                {!isEmpty(props.model.infMov.dest.email) ? props.model.infMov.dest.email : 'Sem e-mail'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}