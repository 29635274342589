import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogMovEmAndamento } from 'views/components/dialog/dialog-mov-em-andamento/dialog-mov-em-andamento';

class MovEmAndamentoProps {
  constructor(
    public aberto: boolean = false,
    public quantidadeProdutos: number = 0,
  ) {}
}

export const DialogMovEmFechamento = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<MovEmAndamentoProps>(
    new MovEmAndamentoProps()
  );

  consoleDev('DialogMovEmFechamento');

  const modalAlterado = useCallback(
    ({ aberto, quantidadeProdutos}: any) => {
      setModalState({
        aberto,
        quantidadeProdutos
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovEmAndamento, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.MovEmAndamento, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogMovEmAndamento
          openned={modalState.aberto}
          quantidadeProdutos={modalState.quantidadeProdutos}
        />
      )}
    </>
  );
};
