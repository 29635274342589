import { Button, Grid, Typography } from '@material-ui/core';

import { useStyles } from './button-add-e-sub-parcial-styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { PedidoProdutoModel } from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumFinalizacaoParcial } from 'model/enums/enum-finalizacao-parcial';
import { toDecimalString } from 'utils/to-decimal';





interface BotoesAddESubParcialProps {
  model: PedidoProdutoModel;
  alterarQtdeProduto: (model: PedidoProdutoModel, qtde: number, tipo: EnumFinalizacaoParcial) => void;
  qtd: number;
  setQtd: React.Dispatch<React.SetStateAction<number>>;
  tipoDeFinalizacao: EnumFinalizacaoParcial
  infinito?: boolean;

}

export const BotoesAddESubParcial = ({
  model,
  qtd,
  setQtd,
  alterarQtdeProduto,
  infinito,
  tipoDeFinalizacao
}: BotoesAddESubParcialProps) => {
  const classes = useStyles();



  const valorQtd = (model.quantidade / qtd)
  
  let partes = ()=>{
    if(qtd > 1){
      return 'Partes'
    } else {
      return 'Parte'
    }
  }

  return (
    <div className={classes.containerQtde}>
      <Button
        variant="contained"
        color="primary"
        disabled={qtd === 0}
        onClick={() => {
          setQtd((prev) => prev - 1);
          alterarQtdeProduto(model, qtd - 1, tipoDeFinalizacao);
        }}
        className={classes.btnAlterarQuantidade}
      >
        <RemoveIcon />
      </Button>
      <Grid>

        <Grid className={classes.textQCom}>
          <Typography variant='h6'>
            {tipoDeFinalizacao === EnumFinalizacaoParcial.PARTES ? `${qtd} ${partes()}` : `${(qtd)} ${model.unidadeComercial}`}
          </Typography>
        </Grid>
        <Grid className={classes.textQCom}>
          {tipoDeFinalizacao === EnumFinalizacaoParcial.PARTES
            ? <Typography>
              Qtde: {qtd === 0 ? qtd : toDecimalString(valorQtd, 3)}
            </Typography> : <></>}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        disabled={!infinito && qtd === model.quantidade}
        onClick={() => {
          setQtd((prev) => {
            const novaQtd = prev + 1
            alterarQtdeProduto(model, novaQtd, tipoDeFinalizacao);
            return novaQtd
          });
        }}
        className={tipoDeFinalizacao === EnumFinalizacaoParcial.QUANTIDADEPRODUTOS ? classes.btnAlterarQuantidadeInt : classes.btnAlterarQuantidade}
      >
        <AddIcon />
      </Button>
    </div>
  );
};
