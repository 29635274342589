import { guidEmpty } from "utils/guid-empty";

export class DadosContaFormModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public email: string = "",
    public imagem: string = "",
    public apelido: string = "",
    public telefone: string = ""
  ) { }
}
