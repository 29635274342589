import { KeyValueModel } from "model/api";

export const PerfilGrupoPermissaoMock: KeyValueModel[] = [
    new KeyValueModel("compra", "Compras"),
    new KeyValueModel("venda", "Vendas"),
    new KeyValueModel("pdv", "Pontos de Venda"),
    new KeyValueModel("cadastro", "Cadastros em Geral"),
    new KeyValueModel("financeiro", "Financeiro"),
    new KeyValueModel("administrativo", "Administrativo"),
];
