import { Button, Grid, Typography } from '@material-ui/core';
import { CardFinalizacaoParcialComandaProps } from './card-finalizacao-parcial-comanda-props';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { DefaultCard } from '../components';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { BotoesAddESubParcial } from './components/button-add-e-sub/button-add-e-sub-parcial';
import { useState } from 'react';
import { FinalizacaoParcialMock } from 'data/mocks/finalizacao-parcial';
import { EnumFinalizacaoParcial } from 'model/enums/enum-finalizacao-parcial';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { toDecimal, toDecimalString, } from 'utils/to-decimal';
import { useStyles } from './card-finalizacao-parcial-comanda-styles'
import classNames from 'classnames';


export const CardFinalizacaoParcialComanda = ({
  model,
  alterarQtd,

}: CardFinalizacaoParcialComandaProps) => {
  // STATES E REFS
  const classes = useStyles()
  const [qtd, setQtd] = useState<number>(0);
  const [qtdStr, setQtdStr] = useState<string>(toDecimalString(qtd))
  // AUX
  const classesCardDefault = useDefaultCardStyles();
  const [tipoDeFinalizacao, setTipoFinalizacao] = useState((model.quantidade % 1 > 0) ? EnumFinalizacaoParcial.QUANTIDADEFRACIONADA : EnumFinalizacaoParcial.QUANTIDADEPRODUTOS)

  const img = (imagemUrl: string) => {
    try {
      if (imagemUrl.length > 0) {
        const url = atob(imagemUrl);
        return (
          <img
            src={url}
            className={classesCardDefault.cardImg}
            alt={model.descricao}
          />
        );
      }

      return (
        <img
          src={SemImagem}
          className={classesCardDefault.cardImg}
          alt={model.descricao}
        />
      );
    } catch (err) {
      return (
        <img
          src={SemImagem}
          className={classesCardDefault.cardImg}
          alt={model.descricao}
        />
      );
    }
  };


  return (
    <>

      <DefaultCard>
        <div
          className={classes.cardFinalizacao}
        >
          <Grid
            container
            spacing={2}
            className={classesCardDefault.cardContent}
          >
            <Grid item style={{ display: 'flex', marginLeft: 8 }} sm={4} xs={12} >
              {img('')}
              <Grid style={{ marginLeft: 8 }}>
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                >
                  <strong
                    className={classes.descricaoProduto}
                  >
                    {model.descricao}
                  </strong>
                </Typography>
                <Grid
                  container
                  className={classes.gridQtd}
                >
                  <Typography
                    className={classes.qtd}
                    color="textPrimary"
                    variant="caption"
                  >
                    Qtd:
                  </Typography>
                  <Typography
                    className={classNames('celula-grid-value', classes.qtdDetalhada)}
                    color="textPrimary"
                    variant="body1"
                  >
                    {model.quantidade % 1 > 0
                      ? toDecimalString(model.quantidade, 3)
                      : model.quantidade
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classes.botoesAgrupados}
              wrap='nowrap'
              xs={6}
              sm={4}
              container
            >
              {FinalizacaoParcialMock.map(opcao => ((model.quantidade % 1 > 0 && opcao.Key === EnumFinalizacaoParcial.QUANTIDADEPRODUTOS)
              ) ?
                null
                :
                <Button
                  className={tipoDeFinalizacao === opcao.Key ? classes.botaoSelecionado : classes.botaoNaoSelecionado}
                  key={opcao.Key}
                  fullWidth
                  color='primary'
                  variant={tipoDeFinalizacao === opcao.Key ? 'outlined' : 'contained'}
                  onClick={() => {
                    setTipoFinalizacao(opcao.Key)
                    setQtd(0)
                    if(tipoDeFinalizacao === EnumFinalizacaoParcial.QUANTIDADEFRACIONADA){
                      setQtdStr('')
                    }
                    alterarQtd(model, 0, opcao.Key)
                  }}
                >
                  {opcao.Value}
                </Button>
              )
              }
            </Grid>
            {
              [
                EnumFinalizacaoParcial.QUANTIDADEPRODUTOS,
                EnumFinalizacaoParcial.PARTES
              ]
                .includes(tipoDeFinalizacao) ? (
                <Grid
                  item
                  sm={3}
                  xs={5}
                  className={classes.buttonAddSubDiv}
                >
                  <BotoesAddESubParcial
                    model={model}
                    alterarQtdeProduto={alterarQtd}
                    qtd={qtd}
                    setQtd={setQtd}
                    tipoDeFinalizacao={tipoDeFinalizacao}
                    infinito={EnumFinalizacaoParcial.PARTES === tipoDeFinalizacao}
                  />
                </Grid>
              ) : null}
            {tipoDeFinalizacao === EnumFinalizacaoParcial.QUANTIDADEFRACIONADA &&
              <Grid
                item
                xs={5}
                sm={3}
                className={classes.buttonAddSubDiv}
              >
                <TextFieldSaurus
                  tipo="DECIMAL"
                  placeholder='0,000'
                  manterMascara
                  casasDecimais={3}
                  className={classes.textfieldFracionado}
                  onChange={(e) => {
                    let valor = toDecimal(e.target.value, 3)
                    setQtdStr(e.target.value)
                    setQtd(valor);
                    alterarQtd(model, valor, EnumFinalizacaoParcial.QUANTIDADEFRACIONADA)
                  }}
                  value={qtdStr}
                  helperText={model.quantidade < qtd ? "Quantidade incorreta" : null}
                  error={model.quantidade < qtd}
                />
              </Grid>
            }
          </Grid>
        </div>
      </DefaultCard>
    </>
  );
};
