import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const CartaoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
      <path d="M 7 9 C 4.25 9 2 11.25 2 14 L 2 36 C 2 38.75 4.25 41 7 41 L 43 41 C 45.75 41 48 38.75 48 36 L 48 14 C 48 11.25 45.75 9 43 9 Z M 7 11 L 43 11 C 44.667969 11 46 12.332031 46 14 L 46 16 L 4 16 L 4 14 C 4 12.332031 5.332031 11 7 11 Z M 4 21 L 46 21 L 46 36 C 46 37.667969 44.667969 39 43 39 L 7 39 C 5.332031 39 4 37.667969 4 36 Z M 9 24 L 9 26 L 25 26 L 25 24 Z"/>
      </DefaultIcon>
    </>
  );
};
