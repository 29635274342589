import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const BarcodeIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 5 5 L 5 17 L 3 17 C 1.355469 17 0 18.355469 0 20 L 0 42 C 0 43.644531 1.355469 45 3 45 L 47 45 C 48.644531 45 50 43.644531 50 42 L 50 20 C 50 18.355469 48.644531 17 47 17 L 45 17 L 45 5 L 43 5 L 43 17 L 41 17 L 41 5 L 37 5 L 37 17 L 35 17 L 35 5 L 33 5 L 33 17 L 31 17 L 31 5 L 28 5 L 28 17 L 26 17 L 26 5 L 24 5 L 24 17 L 22 17 L 22 5 L 14 5 L 14 17 L 12 17 L 12 5 L 9 5 L 9 17 L 7 17 L 7 5 Z M 3 19 L 47 19 C 47.554688 19 48 19.445313 48 20 L 48 42 C 48 42.554688 47.554688 43 47 43 L 3 43 C 2.445313 43 2 42.554688 2 42 L 2 20 C 2 19.445313 2.445313 19 3 19 Z M 5 21 L 5 35 L 7 35 L 7 21 Z M 9 21 L 9 35 L 12 35 L 12 21 Z M 14 21 L 14 35 L 22 35 L 22 21 Z M 24 21 L 24 35 L 26 35 L 26 21 Z M 28 21 L 28 35 L 31 35 L 31 21 Z M 33 21 L 33 35 L 35 35 L 35 21 Z M 37 21 L 37 35 L 41 35 L 41 21 Z M 43 21 L 43 35 L 45 35 L 45 21 Z" />
            </DefaultIcon>
        </>
    );
};


