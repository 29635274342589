import { SessaoGerenciamentoModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogConfirmarConferenciaSessao } from "views/components/dialog/dialog-confirmar-conferencia-sessao/dialog-confirmar-conferencia-sessao";

interface ConfirmarConferenciaSessaoDialogProps {
    aberto: boolean;
    sessao: SessaoGerenciamentoModel;
}

export const ConfirmarConfereciaSessaoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<ConfirmarConferenciaSessaoDialogProps>();

    const modalAlterado = useCallback(({ aberto, sessao }: ConfirmarConferenciaSessaoDialogProps) => {
        setModalState({
            aberto: aberto,
            sessao: sessao,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogConfirmarConferenciaSessao, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogConfirmarConferenciaSessao, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState?.aberto && (
                <DialogConfirmarConferenciaSessao
                    aberto={modalState?.aberto}
                    sessao={modalState.sessao}
                />
            )}
        </>
    )
}