import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetFaturasById() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getFaturasById = useCallback(
        (id: string) =>
            invocarApi({
                url: `v2/faturas/${id}`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );



    return {
        ...outrasPropriedades,
        getFaturasById,
    };




}
