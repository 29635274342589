import { isEmpty } from "lodash";

import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { useSessaoAtual } from "services/app";
import NcmCadastro from "./components/ncm-cadastro/ncm-cadastro";
import NcmEdit from "./components/ncm-edit/ncm-edit";

export const NcmModal = (props: ModalProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (
        <NcmCadastro contratoID={getEmpresaSelecionada()?.ContratoId || ""} />
      )}
      {props.openned && !isEmpty(props.id) && <NcmEdit id={props.id} />}
    </DefaultModal>
  );
};
