import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { ProdutoImagemModel } from 'model/api/gestao/produto/produto-imagem/produto-imagem';
import { VariaveisAmbiente } from 'config';

export function usePutProdutoImagem() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProdutoImagem = useCallback(
    (
      produtoId: string,
      variacaoId: string,
      produtoImagem: ProdutoImagemModel
    ) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/imagem/${produtoImagem.id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtoImagem),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoImagem
  };
}
