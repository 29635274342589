import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base"
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function useDeleteProdutoModificador() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const deleteProdutoModificador = useCallback(
    (modificadorId: string, idModificadorProduto: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador/${modificadorId}/produto/${idModificadorProduto}`,
        method: "DELETE",
        enviarTokenUsuario: true,
      }),
    [getEmpresaSelecionada, invocarApi]
  );



  return {
    ...outrasPropriedades,
    deleteProdutoModificador,
  };




}