import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetSessaoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSessaoById = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Sessao/${sessaoId}`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getSessaoById,
    };
}
