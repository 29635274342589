import { guidEmpty } from "utils/guid-empty";

export class VendaSimplificadaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public dhCompetencia: string = '',
        public idNFe: string = '',
        public nnf: number = 0,
        public serie: number = 0,
        public mod: number = 0,
        public tpAmb: number = 0,
        public status: number = 0,
        public origemEmissao: number = 0,
        public tpNFe: number = 0,
        public dhEmi: string = '',
        public emitDoc: string = '',
        public emitNome: string = '',
        public emitEndereco: string = '',
        public destDoc: string = '',
        public destNome: string = '',
        public destEndereco: string = '',
        public totalQtItens: number = 0,
        public totalQtCom: number = 0,
        public totalVProd: number = 0,
        public totalVNf: number = 0,
        public pagamento: string = '',
    ) { }
}