import { KeyValueModel } from "model";
import { MesasModel } from "model/api/gestao/mesa/mesa-model";
import { CircularLoading } from "views/components";
import { CardNaoEncontrado } from "views/components/cards";
import { CardGerenciarMesa } from "views/components/cards/card-gerenciar-mesa/card-gerenciar-mesa";
import { InformacaoIcon } from "views/components/icons";

export interface MesasListDataProps {
    list: Array<MesasModel>;
    salao: KeyValueModel[];
    salaoId: string;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const MesasListData = (props: MesasListDataProps) => {

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.carregando && <CircularLoading tipo="FULLSIZED" />}
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhuma mesa encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                    key={0}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((mesa, index) => {
                    const salao = props.salao.filter(x => x.Key === mesa.salaoId)[0]
                    return (
                        <CardGerenciarMesa
                            model={mesa}
                            onCheck={onCardChecked}
                            onClick={onCardSelected}
                            selected={false}
                            salao={salao}
                        />
                    );
                })}
        </>
    )
}