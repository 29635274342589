import { Box, Grid } from "@material-ui/core"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeIcmsUfDestinoProps } from "./form-pe-icms-uf-destino-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { useState } from "react"
import { IcmsModel } from "model/api/gestao/venda/venda-completa-model"
import { SwitchSaurus } from "views/components/controles"
import { isEqual } from "lodash"

export const FormPeIcmsUfDestino = ({ control, formState, setValue, getValues, ...props }: FormPeIcmsUfDestinoProps) => {

    const ufDest = {
        vBcUfDest: getValues("icms.vBcUfDest"),
        vBcFcpUfDest: getValues("icms.vBcFcpUfDest"),
        pIcmsUfDest: getValues("icms.pIcmsUfDest"),
        pIcmsInter: getValues("icms.pIcmsInter"),
        pIcmsInterPart: getValues("icms.pIcmsInterPart"),
        vFcpUfDest: getValues("icms.vFcpUfDest"),
        vIcmsUfRemet: getValues("icms.vIcmsUfRemet"),
        vIcmsUfDest: getValues("icms.vIcmsUfDest"),
    }

    const ufDestPadrao = {
        vBcUfDest: 0,
        vBcFcpUfDest: 0,
        pIcmsUfDest: 0,
        pIcmsInter: 0,
        pIcmsInterPart: 0,
        vFcpUfDest: 0,
        vIcmsUfRemet: 0,
        vIcmsUfDest: 0,
    }

    const ufDestEqual = isEqual(ufDestPadrao, ufDest)

    const { errors } = formState
    const [disabled, setDisabled] = useState<boolean>(ufDestEqual)
    const [openned, setOpenned] = useState<boolean>(false)

    const handleDisabled = () => {
        const icmsPadrao = new IcmsModel()
        setDisabled(prev => {
            if (!prev === true) {
                setValue('icms.vBcUfDest', icmsPadrao.vBcUfDest)
                setValue('icms.vBcFcpUfDest', icmsPadrao.vBcFcpUfDest)
                setValue('icms.pIcmsUfDest', icmsPadrao.pIcmsUfDest)
                setValue('icms.pIcmsInter', icmsPadrao.pIcmsInter)
                setValue('icms.pIcmsInterPart', icmsPadrao.pIcmsInterPart)
                setValue('icms.vFcpUfDest', icmsPadrao.vFcpUfDest)
                setValue('icms.vIcmsUfRemet', icmsPadrao.vIcmsUfRemet)
                setValue('icms.vIcmsUfDest', icmsPadrao.vIcmsUfDest)
                return !prev
            }
            return !prev
        })
    }
    return (
        <AccordionSaurus
            labelPrimary="ICMS UF de Destino"
            expanded={openned}
            betweenTitles
            labelSecondary={(
                <Box width="100%" display="flex" justifyContent="flex-end" mr={1}>
                    <SwitchSaurus
                        variant="LIGHT"
                        checked={!disabled}
                        onClick={() => {
                            if (openned) {
                                setOpenned(prev => !prev)
                            }
                            handleDisabled()
                        }}
                    />
                </Box>
            )}
            onChange={() => setOpenned(prev => !prev)}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBcUfDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcUfDest && errors.icms.vBcUfDest?.message)}
                                helperText={
                                    errors.icms?.vBcUfDest
                                        ? errors.icms?.vBcUfDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBcFcpUfDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="B.C. F.C.P."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcFcpUfDest && errors.icms.vBcFcpUfDest?.message)}
                                helperText={
                                    errors.icms?.vBcFcpUfDest
                                        ? errors.icms?.vBcFcpUfDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsUfDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota Interna"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsUfDest && errors.icms.pIcmsUfDest?.message)}
                                helperText={
                                    errors.icms?.pIcmsUfDest
                                        ? errors.icms?.pIcmsUfDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsInter"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota Inter."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsInter && errors.icms.pIcmsInter?.message)}
                                helperText={
                                    errors.icms?.pIcmsInter
                                        ? errors.icms?.pIcmsInter?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsInterPart"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% de Partilha"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsInterPart && errors.icms.pIcmsInterPart?.message)}
                                helperText={
                                    errors.icms?.pIcmsInterPart
                                        ? errors.icms?.pIcmsInterPart?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vFcpUfDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor F.C.P."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vFcpUfDest && errors.icms.vFcpUfDest?.message)}
                                helperText={
                                    errors.icms?.vFcpUfDest
                                        ? errors.icms?.vFcpUfDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsUfRemet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS Emitente"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsUfRemet && errors.icms.vIcmsUfRemet?.message)}
                                helperText={
                                    errors.icms?.vIcmsUfRemet
                                        ? errors.icms?.vIcmsUfRemet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsUfDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || disabled}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS Destina."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsUfDest && errors.icms.vIcmsUfDest?.message)}
                                helperText={
                                    errors.icms?.vIcmsUfDest
                                        ? errors.icms?.vIcmsUfDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}