import { ButtonFiltroProps } from "./button-filtro-props";
import { Box, Button, Typography } from "@material-ui/core";
import { MaisIcon } from "views/components/icons";
import { useStyles } from "./button-filtro-styles";
import classNames from "classnames";
import { FecharIconCircle } from "views/components/icons/fechar-icon-circle";

export const ButtonFiltro = ({
    handleRemove,
    titulo,
    vFiltro,
    entrarPagina,
    obrigatorio,
    ...props
}: ButtonFiltroProps) => {
    const classes = useStyles()
    return (
        <Button fullWidth variant="text" classes={{
            label: classes.btnLabel
        }}>
            <Box width="100%" height="100%" onClick={entrarPagina} alignItems="center" justifyContent="space-between" display="flex">
                <Box display="flex" alignItems="center">
                    <props.icon class={classes.icon} />
                    <Typography>
                        {titulo}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    {vFiltro && (
                        <Typography>
                            {vFiltro}
                        </Typography>
                    )}

                </Box>
            </Box>
            <Button className={classes.btnLixeira} onClick={vFiltro ? handleRemove : entrarPagina}>
                {vFiltro ? (
                    <FecharIconCircle tipo='GERAL' class={classes.icon} />
                ) : (
                    <MaisIcon tipo="BUTTON" class={classNames(classes.icon, classes.iconMais)} />
                )}
            </Button>
        </Button>
    )
}