import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { PagamentoEditModal } from "views/components/modals/pagamento-edit/pagamento-edit-modal";

export const PagamentoEditModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        mov: new VendaCompletaModel()
    });

    consoleDev('PagamentoEditModalRender')

    const modalAlterado = useCallback(({ id, aberto, mov }: any) => {
        setModalState({
            id,
            aberto,
            mov
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PagamentoEditModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PagamentoEditModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <PagamentoEditModal openned={modalState.aberto} key="mdlPagamentoEdit" id={modalState.id} mov={modalState.mov} />
    )
}