import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const RenderizacaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M11.8122 6.84998C9.77323 6.84998 8.00128 6.84998 6.20505 6.84998C5.7924 6.84998 5.40403 6.82567 5.13703 6.48539C4.89429 6.19371 4.87002 5.85342 4.99139 5.53744C5.1613 5.07562 5.54967 4.92978 6.01086 4.92978C7.58863 4.92978 9.1664 4.92978 10.7442 4.92978C11.9821 4.92978 13.22 4.92978 14.4823 4.92978C15.3075 4.92978 15.6959 5.29437 15.6959 6.1451C15.6716 8.94033 15.6959 11.7356 15.6959 14.5308C15.6959 15.1871 15.4532 15.6975 14.725 15.6975C13.9968 15.6975 13.7298 15.1627 13.7298 14.5308C13.7055 12.4161 13.7298 10.2772 13.7298 8.01668C12.0792 8.94033 10.7442 10.0827 9.5305 11.3953C5.20985 16.0864 3.48644 21.6525 4.09327 27.9722C4.14182 28.4826 4.14182 28.9444 3.68063 29.2604C3.04952 29.6736 2.32132 29.2847 2.19995 28.4097C1.83585 25.5902 1.95722 22.7706 2.66115 19.9997C4.069 14.6037 7.07889 10.3015 11.8122 6.84998Z" />
                <path d="M41.9599 14.7009C39.1927 10.3987 35.576 7.50622 30.8913 5.95061C27.9785 4.97836 24.9686 4.73529 21.9101 5.12419C21.2062 5.22142 20.6722 4.97836 20.5994 4.22486C20.5266 3.49567 21.012 3.2283 21.6674 3.15538C30.2116 2.25605 37.081 5.27003 42.4211 11.9543C42.6153 12.173 42.7852 12.4161 43.1008 12.8293C43.1008 10.9334 43.1008 9.25627 43.1008 7.57913C43.1008 7.28746 43.0765 6.97148 43.125 6.6798C43.2221 6.21798 43.5134 5.9263 43.9989 5.9263C44.4843 5.902 44.8242 6.14506 44.9455 6.60688C44.9941 6.84995 45.0183 7.09301 45.0183 7.33607C45.0183 9.98546 45.0183 12.6105 45.0183 15.2599C45.0183 16.451 44.7513 16.694 43.5619 16.694C40.9162 16.694 38.2946 16.694 35.6488 16.694C34.9692 16.694 34.3381 16.6211 34.241 15.7704C34.1682 15.1141 34.6779 14.7252 35.6246 14.7252C37.7363 14.6766 39.751 14.7009 41.9599 14.7009Z" />
                <path d="M34.2894 43.5039C35.7458 42.8234 36.9352 41.9483 38.0275 40.9518C43.3191 36.1391 45.6251 30.1597 44.9211 23.0137C44.8726 22.5276 44.8483 22.1143 45.261 21.7741C45.5523 21.531 45.8678 21.5067 46.2076 21.6282C46.6688 21.7984 46.7902 22.163 46.863 22.6248C47.5427 28.2152 46.329 33.3439 43.222 38.0107C41.7413 40.2226 39.9208 42.0942 37.7605 43.6741C37.6149 43.7713 37.4935 43.8928 37.2265 44.1359C38.8771 44.1359 40.3578 44.1602 41.8142 44.1359C42.4938 44.1359 43.1006 44.306 43.1249 45.0595C43.1492 45.886 42.5424 46.0804 41.8142 46.0804C39.0955 46.0804 36.3527 46.0804 33.634 46.0804C32.6631 46.0804 32.3718 45.7887 32.3718 44.8408C32.3718 42.0699 32.3718 39.3232 32.3718 36.5523C32.3718 35.8474 32.5903 35.3127 33.367 35.337C34.1438 35.3613 34.338 35.9204 34.3137 36.6009C34.2894 38.8371 34.2894 41.0976 34.2894 43.5039Z" />
                <path d="M5.88958 38.1565C5.88958 40.2468 5.88958 42.0455 5.88958 43.8685C5.88958 44.4275 5.76821 44.9136 5.16138 45.0595C4.45745 45.2296 3.97199 44.7435 3.97199 43.7713C3.94771 41.5351 3.97199 39.2746 3.97199 37.0384C3.94771 34.073 3.94771 34.3161 6.6906 34.3161C8.92374 34.3161 11.1812 34.3161 13.4143 34.3161C14.094 34.3161 14.6765 34.4619 14.7008 35.2397C14.7251 36.0904 14.094 36.2849 13.39 36.2849C11.3268 36.2849 9.26357 36.2849 7.07897 36.2849C8.00136 37.9377 9.1422 39.2746 10.453 40.4656C14.7979 44.4761 19.9196 46.3477 25.818 46.0074C25.9636 46.0074 26.1093 45.9831 26.2792 45.9831C26.9345 45.9588 27.3715 46.2748 27.42 46.8825C27.4686 47.5873 27.0074 47.9276 26.3277 47.9519C23.8761 48.0735 21.4245 47.9519 19.0214 47.3443C13.6328 45.9345 9.33639 42.8962 5.88958 38.1565Z" />
            </DefaultIcon>
        </>
    );
};