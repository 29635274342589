import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormWebhookValidation = () => {
  const FormWebhookValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        webhook: Yup.string().required('URL do Webhook é obrigatório'),
      })
    )
  }, [])
  return {
    FormWebhookValidationYup,
  }
}
