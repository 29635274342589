import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './entrada-list-styles';
import { EntradaListData } from './entrada-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { useHistory } from 'react-router-dom';
import { useGetHistoricoVenda } from 'data/api/gestao/historico-venda/get-historico-venda';
import { HistoricoVendaModel } from 'model/api/gestao/historico-vendas/historico-vendas-model';
import { isEmpty } from 'lodash';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMovModelo } from 'model';
import { useGetManifesto } from 'data/api/gestao/empresa-documento/get-manifesto';
import { ManifestoModel, ManifestoNNFModel } from 'model/api/gestao/manifesto/manifesto-model';
import { toDateString } from 'utils/to-date';
import { descMonth, getCurrentMonth } from 'utils/get-date';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { getNNFManifesto } from 'utils/get-nnf';
import { EnumStatusEntrada } from 'model/enums/enum-status-entrada';
import { EnumTpNf } from 'model/enums/enum-tp-nf';

interface ManifestoStatus {
  manifestos: ManifestoNNFModel[];
  dataInicial: string;
}

export interface EntradaListProps {
}

export const EntradaList = ({ ...props }) => {
  const chavePdvRef = useRef<string | undefined>(undefined);
  const classes = useStyles();
  const { getHistoricoVenda, carregando: carregandoMov } = useGetHistoricoVenda()
  const { getManifesto, carregando: carregandoManifesto } = useGetManifesto();
  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<HistoricoVendaModel>()
  });
  const { getEmpresaAtual } = useEmpresaAtual()

  const carregando = carregandoMov || carregandoManifesto;

  const [selectedList, setSelectedList] = useState<string[]>([]);


  const [, setAtt] = useState<boolean>(false)
  const { addHandler, removeHandler } = useEventTools()
  const history = useHistory();
  const urlParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search])
  const filtros = useMemo(() => ({
    dInicial: urlParams.get('dInicial') || toDateString(descMonth(new Date(getCurrentMonth()), 3), 'yyyy-MM-DD')!,
    dFinal: urlParams.get('dFinal'),
    status: urlParams.get('status') || EnumStatusEntrada.EmDigitacao,
    nnf: urlParams.get('nnf')
  }), [urlParams])

  const manifestos = useRef<ManifestoStatus>({
    manifestos: [],
    dataInicial: filtros.dInicial
  });

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<HistoricoVendaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    }, []);

  const getManifestoWrapper = useCallback(async () => {
    try {
      const query =
        'integrado=true&' +
        (isEmpty(filtros.dInicial)
          ? ''
          : `&dataInicio=${filtros.dInicial}T00:00:00`) +
        (isEmpty(filtros.dFinal)
          ? ''
          : `&dataFinal=${filtros.dFinal}T23:59:59`)

      const res = await getManifesto(getEmpresaAtual()?.cpfcnpj || '', query)

      if (res.erro) throw res.erro;

      const data = (res.resultado?.data as ManifestoModel[]).filter((manifesto: ManifestoModel) => {
        return manifesto.chave && manifesto.chave.length >= 40
      }).map((manifesto: ManifestoModel) => {
        const numeroSerie = getNNFManifesto(manifesto);
        return {
          ...manifesto,
          nnf: numeroSerie?.numero || '',
          serie: numeroSerie?.serie || ''
        }
      })

      manifestos.current = {
        dataInicial: filtros.dInicial,
        manifestos: data
      }
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [filtros.dFinal, filtros.dInicial, getEmpresaAtual, getManifesto, showToast])

  const search = useCallback(
    async (newPage: number) => {
      const query =
        `TpNFe=${EnumTpNf.ENTRADA}` +
        ((filtros.status ? Number(filtros.status) : -1) === -1
          ? ''
          : '&Status=' + filtros.status) +
        (isEmpty(urlParams.get('dInicial'))
          ? ''
          : `&DInicial=${filtros.dInicial}T00:00:00`) +
        (isEmpty(filtros.dFinal)
          ? ''
          : `&DFinal=${filtros.dFinal}T23:59:59`) +
        ((filtros.nnf ? Number(filtros.nnf) : 0) <= 0
          ? ''
          : `&NNF=${filtros.nnf}`)
        + ('&mod=' + EnumMovModelo.NFE)
      try {
        const res = await getHistoricoVenda(query, newPage);

        if (res.erro) throw res.erro;

        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.status, filtros.dInicial, filtros.dFinal, filtros.nnf, urlParams, getHistoricoVenda, fillResult, showToast],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  useEffect(() => {
    search(queryStatus.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const dInicial = urlParams.get('dInicial') || toDateString(descMonth(new Date(getCurrentMonth()), 3), 'yyyy-MM-DD');

    if (dInicial !== manifestos.current.dataInicial || manifestos.current.manifestos.length < 1) {
      getManifestoWrapper();
    }
  }, [getManifestoWrapper, history.location.search])

  const attFiltro = ({ att }: any) => {
    if (att) {
      setAtt(prev => !prev)
    }
  }

  useEffect(() => {
    addHandler(AppEventEnum.EntradaFiltroModal, attFiltro)

    return () => removeHandler(AppEventEnum.EntradaFiltroModal, attFiltro)
  }, [addHandler, removeHandler])

  const onCardClick = (model: HistoricoVendaModel) => {
    const manifesto = manifestos.current.manifestos.find(manifesto => {
      const chave1 = manifesto.chave.split('NFe')[1] ? manifesto.chave.split('NFe')[1] : manifesto.chave;
      const chave2 = model.idNFe.split('NFe')[1] ? model.idNFe.split('NFe')[1] : model.idNFe;
      return chave1 === chave2;
    })
    history.push({
      pathname: `/entrada-mercadoria/visualizar/${model.id}`,
      state: {
        manifesto: manifesto,
        dtManifesto: manifesto?.dhDocumento || filtros.dInicial
      }
    })
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <EntradaListData
              chavePdvRef={chavePdvRef}
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardClick={onCardClick}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
