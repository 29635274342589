import { KeyValueModel } from "model";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";


export const TpDocumentoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTipoDocumento.CarteiraRegistroProfissional, "Carteira de Registro Profissional"),
    new KeyValueModel(EnumTipoDocumento.CarteiraIdentidade, "Carteira de Identidade"),
    new KeyValueModel(EnumTipoDocumento.CertidaoNascimento, "Certidao de Nascimento"),
    new KeyValueModel(EnumTipoDocumento.CertidaoCasamento, "Certidao de Casamento"),
    new KeyValueModel(EnumTipoDocumento.Passaporte, "Passaporte"),
    new KeyValueModel(EnumTipoDocumento.Outro, "Outro")
];