import { guidEmpty } from "../../../../../utils/guid-empty";
import { EnumTpSubItem } from "../../../../enums/enum-tpsubitem";
export class ProdutoNovoSubItemFormModel {
  constructor(
    public id: string | null = null,
    public produtoSubGradeId: string | null = guidEmpty(),
    public produtoSubId: string | null = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produtoNome: string = "",
    public modificadorId: string = '',
    public modificadorNome: string = '',
    public tipo: EnumTpSubItem = EnumTpSubItem.InsumoProducao,
    public ordem: number = 0,
    public fator: number = 1,
    public qPadrao: number = 0,
    public qMin: number = 0,
    public qMax: number = 0,
  ) { }
}
