import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  button: {
    height: 32,
    background: 'none',
    '& svg': {
      margin: 0
    }
  }
}));
