import { Typography } from '@material-ui/core';
import { CardProdutoProps } from './card-principio-ativo-props';
import { memo } from 'react';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views';
import useOnPress from 'utils/useOnPress';
import { AddIcon } from 'views/components/icons/add-icon';

const CardPrincipioAtivo = ({ model, onClick }: CardProdutoProps) => {
  const classes = useDefaultCardStyles();
  const { isMobile } = useThemeQueries();

  const handlePress = () => {};

  const handleClick = async () => {
    onClick(model);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700
  };

  const EventPressAndClick = useOnPress(
    handlePress,
    handleClick,
    defaultOptions
  );

  return (
    <>
      <div {...EventPressAndClick}>
        <DefaultCard isSelected>
          <div className={classes.cardContent}>
            <div className={classes.celulaGridFull}>
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                variant="body1"
                style={{
                  maxWidth: isMobile ? '290px' : '400px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {model}
              </Typography>
            </div>

            <div className={classes.celulaGrid} style={{ textAlign: 'right' }}>
              <AddIcon tipo="BUTTON" />
            </div>
          </div>
        </DefaultCard>
      </div>
    </>
  );
};

export default memo(CardPrincipioAtivo, (prev, next) => {
  if (prev !== next) return false;
  return true;
});
