import { EnumStatusManifesto } from "model/enums/enum-status-manifesto";

export class ManifestoModel {
  constructor(
    public id: string = '',
    public cnpj: string = '',
    public referencia: string = '',
    public chave: string = '',
    public tipo: number = 0,
    public mod: number = 55,
    public tpEmis: number = 0,
    public status: number = 0,
    public statusManifesto: EnumStatusManifesto = EnumStatusManifesto.ResumoNFe,
    public integrado: boolean = true,
    public dhDocumento: string = '',
    public documento: string = '',
    public digVal: string = '',
    public ie: string = '',
    public tpNF: number = 0,
    public versao: string = '',
    public vnf: number = 0,
    public nome: string = '',
    public sitNFe: number = 0,
    public protocolo: string = ''
  ) { }
}

export class ManifestoNNFModel {
  constructor(
    public id: string = '',
    public cnpj: string = '',
    public referencia: string = '',
    public chave: string = '',
    public tipo: number = 0,
    public mod: number = 55,
    public tpEmis: number = 0,
    public status: number = 0,
    public statusManifesto: EnumStatusManifesto = EnumStatusManifesto.ResumoNFe,
    public integrado: boolean = true,
    public dhDocumento: string = '',
    public documento: string = '',
    public digVal: string = '',
    public ie: string = '',
    public tpNF: number = 0,
    public versao: string = '',
    public vnf: number = 0,
    public nome: string = '',
    public sitNFe: number = 0,
    public protocolo: string = '',
    public nnf: string = '',
    public serie: string = '',
  ) { }
}
