import { Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-mesa-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoDadosMesa } from 'data/api/gestao/pedido-dados/put-pedido-dados-mesas';
import { useHistory } from 'react-router-dom';
import { EnumMesas } from 'model/enums/enum-mesas';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { TabelaSaloes } from 'database/interfaces/interface-tabela-saloes';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useConfirm } from 'material-ui-confirm';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';

interface DialogAlterarMesaProps {
  aberto: boolean;
  pedido: PedidoModel;
  carregarInfoPedido: () => Promise<void>;
}

export const DialogAlterarMesa = ({
  aberto,
  pedido,
  carregarInfoPedido
}: DialogAlterarMesaProps) => {
  // STATES E REFS
  const [codMesa, setCodMesa] = useState('');
  const [saloesList, setSaloesList] = useState<TabelaSaloes[]>([]);
  const [salaoId, setSalaoId] = useState<string | null>('');

  // CHAMADAS API
  const { putPedidoDadosMesa, carregando: carregandoPutPedidoDados } =
    usePutPedidoDadosMesa();

  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { fecharDialogAlterarMesaPedido, fecharDialogAcoesPedidos } = useCadastros();


  // AUX
  const classesComponent = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const carregando = carregandoPutPedidoDados;
  const confirm = useConfirm();

  useEffect(() => {
    const getSaloes = async () => {
      try {
        const saloes = (await TouchoneDBPrimary.saloes.toArray()).filter(x => x.status?.codigo === EnumStatusSalao.ATIVO);

        if (saloes.length > 1) {
          setSaloesList(saloes);
        } else if (saloes.length === 1) {
          setSalaoId(saloes[0].id);
        }
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível buscar os salões, Detalhes: ${e.message}`
        );
      }
    };

    getSaloes();
  }, [showToast]);

  const onCloseClick = useCallback(() => {
    fecharDialogAlterarMesaPedido();
  }, [fecharDialogAlterarMesaPedido]);

  const handleConfirme = useCallback(
    async (codigoMesa: string, salaoId: string) => {
      try {
        const mesa = await TouchoneDBPrimary.mesas.get({
          codigo: codigoMesa,
          salaoId: salaoId
        });

        if (!mesa) {
          throw new Error(`Mesa com o código ${codigoMesa} não encontrada.`);
        }

        if (mesa && mesa.status?.codigo !== EnumMesas.ATIVO) {
          throw new Error(
            `A mesa com o código ${codigoMesa} está com status de INATIVA.`
          );
        }

        const respostaPut = await putPedidoDadosMesa(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          mesa.id ?? ''
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast(
          'success',
          `O pedido foi alterado para a mesa com o código: ${codigoMesa} com sucesso.`
        );

        if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA) {
          history.goBack()
        } else {
          await carregarInfoPedido()
          fecharDialogAlterarMesaPedido();
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [carregarInfoPedido, fecharDialogAlterarMesaPedido, getConfiguracoesMesaEComanda, getEmpresaSelecionada, history, pedido.id, putPedidoDadosMesa, showToast]
  );

  return (
    <DialogSaurus aberto={aberto || false} titulo="Alterar Mesa" tamanho="xs">
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Typography
          style={{
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            fontWeight: '600',
            fontSize: '20px',
            color: theme.palette.grey[400]
          }}
        >
          Digite o código da mesa para qual deseja alterar.
        </Typography>
        <Grid className={classesComponent.cardContent} spacing={2}>
          {saloesList.length > 1 && (
            <Grid item xs={12} style={{ marginBottom: '10px' }}>
              <SelectSaurus
                label="Selecione o Salão"
                conteudo={saloesList.map((valor: any) => {
                  return new KeyValueModel(valor.id.toString(), valor.descricao);
                })}
                fullWidth={true}
                variant="outlined"
                onChange={(event) => {
                  if (event) {
                    setSalaoId(event.target.value);
                  }
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextFieldSaurus
              tipo="TEXTO"
              disabled={carregando}
              fullWidth
              variant="outlined"
              label="Código Mesa"
              InputLabelProps={{
                shrink: true
              }}
              placeholder={'EX: 1'}
              value={codMesa}
              onChange={(e) => setCodMesa(e.target.value)}
            />
          </Grid>
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={5}>
              <Button
                disabled={carregando}
                onClick={onCloseClick}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                disabled={carregando || !salaoId}
                onClick={() => confirm(
                  {
                    title: 'Alterar mesa',
                    description: `Realmente deseja alterar o pedido da mesa ${pedido.mesaCodigo} para a mesa ${codMesa}?`,
                    confirmationText: 'Confirmar',
                    cancellationText: 'Voltar',
                  }).then(() => {
                    handleConfirme(codMesa, salaoId ?? '').finally(() => {
                      fecharDialogAlterarMesaPedido()
                      fecharDialogAcoesPedidos()
                    });

                  })
                }
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
