import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useFormPacientePreCadastroValidation } from './form-paciente-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { useGetEnderecoPessoa } from 'data/api/gestao/pessoa';
import { picker } from 'utils/picker';
import { stringNumeros } from 'utils/string-numeros';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { AutocompletePessoas } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpDocumentoMock } from 'data/mocks/tp-documento-mock';
import { TipoDocumentoOrgaoExpedidorMock } from 'data/mocks/orgao-expedidor-mock';
import { EnumCadastroStatus, KeyValueModel } from 'model';
import { SexoMock, UFMock } from 'data/mocks';
import { CompradorPacienteFormModel, CompradorPacientePreCadastroFormModel } from 'model/app/forms/receita/comprador-paciente-form-model';
import { SwitchSaurus } from 'views/components/controles';
import { useStyles } from './form-paciente-styles';
import { toDateString } from 'utils/to-date';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';

export interface FormCompradorPacienteProps
    extends DefaultFormProps<CompradorPacientePreCadastroFormModel> {
}

const documentosPessoais = [
    EnumTipoDocumento.CarteiraIdentidade,
    EnumTipoDocumento.CarteiraTrabalhoPrevidenciaSocial,
    EnumTipoDocumento.Passaporte,
    EnumTipoDocumento.CarteiraRegistroProfissional
]

export const FormPacientePreCadastro = forwardRef<
    DefaultFormRefs<CompradorPacientePreCadastroFormModel>,
    FormCompradorPacienteProps
>(({ loading,
    ...props }: FormCompradorPacienteProps, ref,) => {

    const utilClasses = makeUtilClasses();
    const [, setAtt] = useState<boolean>(false)
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa()
    const { showToast } = useToastSaurus()
    const { theme } = useThemeQueries()
    const { getEmpresaAtual } = useEmpresaAtual()
    const [initialValues, setInitialValues] =
        useState<CompradorPacientePreCadastroFormModel>(
            new CompradorPacientePreCadastroFormModel(),
        );
    const { FormYupValidationPrePaciente } =
        useFormPacientePreCadastroValidation();

    const classes = useStyles()

    const carregando = [carregandoCEP, carregandoEndereco].includes(true)

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<CompradorPacientePreCadastroFormModel>({
        defaultValues: { ...initialValues },
        resolver: yupResolver(FormYupValidationPrePaciente),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: CompradorPacientePreCadastroFormModel) => {
        values = picker<CompradorPacientePreCadastroFormModel>(values, new CompradorPacientePreCadastroFormModel())
        values.paciente.telefone = stringNumeros(values.paciente.telefone)
        props.onSubmit(values);
    };
    
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setInitialValues(new CompradorPacientePreCadastroFormModel());
            reset();
        },
        fillForm: (model: CompradorPacientePreCadastroFormModel) => {
            setInitialValues(model);
            model.paciente.ufOrgaoExpedidor = getEmpresaAtual()?.uf ?? ''
            reset({ ...model })
        },
    }));

    const preencherComComprador = (value: boolean) => {
        if (value) {
            reset({
                ...initialValues,
                paciente: initialValues.comprador,
                compradorPacienteMesmaPessoa: value
            })
            return
        }
        reset({
            ...initialValues,
            paciente: new CompradorPacienteFormModel(),
            compradorPacienteMesmaPessoa: value
        })
    }

    const handleAtt = () => setAtt(prev => !prev)

    const buscarEnderecoPessoa = useCallback(async (pessoaId: string) => {
        try {
            const res = await getEnderecoPessoa(pessoaId)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data
            return resultado as PessoaEnderecoModel[]
        } catch (error: any) {
            showToast('error', 'Ocorreu um problema ao buscar o endereço, tente novamente ou preencha manualmente. Detalhe: ' + error.message)
            return null
        }
    }, [getEnderecoPessoa, showToast])

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AutocompletePessoas
                                        allowSubmit
                                        disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                        label={"Selecione o Paciente"}
                                        onChange={async (retorno) => {
                                            if (!retorno.isString) {
                                                const pessoa: PessoaModel = retorno.value
                                                const enderecos = await buscarEnderecoPessoa(pessoa.id)
                                                setValue('paciente.nome', pessoa.nome)
                                                setValue('paciente.dataNascimento', pessoa.dtNasc
                                                    ? (toDateString(pessoa.dtNasc, 'yyyy-MM-DD') ?? '')
                                                    : '')
                                                setValue('paciente.pessoaId', pessoa.id)
                                                setValue('paciente.cpfcnpj', pessoa.cpfcnpj)
                                                setValue('paciente.sexo', pessoa.sexo)

                                                const telefone = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '';
                                                setValue('comprador.telefone', telefone);

                                                const [endereco] = enderecos ?? []

                                                setValue('paciente.cep', stringNumeros(endereco?.cep ?? ''))
                                                setValue('paciente.codMunicipio', endereco?.cMun ? Number(endereco.cMun) : 0)
                                                setValue('paciente.municipio', endereco?.xMun)
                                                setValue('paciente.logradouro', endereco?.logradouro)
                                                setValue('paciente.numero', endereco?.numero)
                                                setValue('paciente.complemento', endereco?.complemento)
                                                setValue('paciente.uf', endereco?.uf)

                                                const [documento] = pessoa.documentos
                                                setValue('paciente.tipoDocumento', documento?.tipo ?? EnumTipoDocumento.CarteiraIdentidade)
                                                setValue('paciente.documento', documento?.documento ?? '')
                                                setValue('paciente.orgaoExpedidor', documento?.orgaoExpedicao ?? TipoDocumentoOrgaoExpedidorEnum.SecretariaReceitaFederal)
                                                setValue('paciente.ufOrgaoExpedidor', documento?.ufExpedicao ?? '')
                                            }
                                        }}
                                        status={[EnumCadastroStatus.LIBERADO]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box width="100%" my={1}>
                                        <Divider />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        onClick={() => {
                                            const novoValor = getValues('compradorPacienteMesmaPessoa')
                                            setValue('compradorPacienteMesmaPessoa', !novoValor)
                                            preencherComComprador(!novoValor)
                                            handleAtt()
                                        }}
                                        className={classes.option}
                                    >
                                        <SwitchSaurus
                                            variant='LIGHT'
                                            disabled={carregando}
                                            checked={getValues('compradorPacienteMesmaPessoa')}
                                        />
                                        <Typography variant="body1" className={classes.text}>
                                            Paciente e Comprador são os mesmos?
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.nome"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Nome"}
                                                tipo={"TEXTO"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.nome && errors.paciente?.nome.message)}
                                                helperText={
                                                    touchedFields.paciente?.nome || errors.paciente?.nome
                                                        ? errors.paciente?.nome?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.cpfcnpj"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label="CPF/CNPJ"
                                                tipo={"CNPJ_CPF"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.cpfcnpj && errors.paciente?.cpfcnpj.message)}
                                                helperText={
                                                    touchedFields.paciente?.cpfcnpj || errors.paciente?.cpfcnpj
                                                        ? errors.paciente?.cpfcnpj?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.tipoDocumento"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={TpDocumentoMock}
                                                allowSubmit
                                                disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Tipo de Documento"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.tipoDocumento && errors.paciente?.tipoDocumento.message)}
                                                helperText={
                                                    touchedFields.paciente?.tipoDocumento || errors.paciente?.tipoDocumento
                                                        ? errors.paciente?.tipoDocumento?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = TpDocumentoMock.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('paciente.tipoDocumento', item.Key);
                                                        }
                                                    }
                                                    handleAtt()
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.documento"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label="Documento"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.documento && errors.paciente?.documento.message)}
                                                helperText={
                                                    touchedFields.paciente?.documento || errors.paciente?.documento
                                                        ? errors.paciente?.documento?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                {documentosPessoais.includes(Number(getValues('paciente.tipoDocumento')) || 2) && (
                                    <>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="paciente.orgaoExpedidor"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value))}
                                                        allowSubmit
                                                        disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                                        fullWidth
                                                        variant="outlined"
                                                        label={"Órgão Expedidor"}
                                                        placeholder='Ex: SSP'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.paciente?.orgaoExpedidor && errors.paciente?.orgaoExpedidor.message)}
                                                        helperText={
                                                            touchedFields.paciente?.orgaoExpedidor || errors.paciente?.orgaoExpedidor
                                                                ? errors.paciente?.orgaoExpedidor?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            if (event) {
                                                                const item = TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value)).filter(
                                                                    (item) => item.Key === event.target.value,
                                                                )?.[0];
                                                                if (item) {
                                                                    setValue('paciente.orgaoExpedidor', item.Key);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="paciente.ufOrgaoExpedidor"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectSaurus
                                                        conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                                        allowSubmit
                                                        disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                                        fullWidth
                                                        variant="outlined"
                                                        label={"UF do Órgão Expedidor"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.paciente?.ufOrgaoExpedidor && errors.paciente?.ufOrgaoExpedidor.message)}
                                                        helperText={
                                                            touchedFields.paciente?.ufOrgaoExpedidor || errors.paciente?.ufOrgaoExpedidor
                                                                ? errors.paciente?.ufOrgaoExpedidor?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                        onChange={(event) => {
                                                            if (event) {
                                                                const item = UFMock.filter(
                                                                    (item) => item.Value === event.target.value,
                                                                )[0];
                                                                if (item) {
                                                                    setValue('paciente.ufOrgaoExpedidor', item.Value);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="paciente.dataNascimento"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextFieldSaurus
                                                        tipo={"DATA"}
                                                        allowSubmit
                                                        readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                        disabled={loading}
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Data de nascimento"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={Boolean(errors.paciente?.dataNascimento && errors.paciente?.dataNascimento.message)}
                                                        helperText={
                                                            touchedFields.paciente?.dataNascimento || errors.paciente?.dataNascimento
                                                                ? errors.paciente?.dataNascimento?.message
                                                                : undefined
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <Controller
                                        name="paciente.telefone"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                allowSubmit
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Telefone"}
                                                tipo={"TELEFONE"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.telefone && errors.paciente?.telefone.message)}
                                                helperText={
                                                    touchedFields.paciente?.telefone || errors.paciente?.telefone
                                                        ? errors.paciente?.telefone?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="paciente.sexo"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={SexoMock}
                                                allowSubmit
                                                disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Gênero"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.sexo && errors.paciente?.sexo.message)}
                                                helperText={
                                                    touchedFields.paciente?.sexo || errors.paciente?.sexo
                                                        ? errors.paciente?.sexo?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = SexoMock.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('paciente.sexo', item.Key);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                        Endereço
                                    </Typography>
                                    <Divider color={theme.palette.secondary.main} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.cep"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo="CEP"
                                                fullWidth
                                                searchable
                                                autoComplete={'off'}
                                                label="CEP"
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                placeholder=""
                                                onSearch={async (value: string) => {
                                                    try {
                                                        let res = await consultaCEP(value);

                                                        setValue('paciente.cep', res.CEP);
                                                        setValue('paciente.logradouro', res.Logradouro);
                                                        setValue('paciente.uf', res.UF);
                                                        setValue('paciente.codMunicipio', res.CMun ? Number(res.CMun) : 0);
                                                        setValue('paciente.municipio', res.Municipio)
                                                    } catch (e: any) {
                                                        showToast('error', e.message);
                                                    }
                                                }}
                                                error={Boolean(errors.paciente?.cep && errors.paciente?.cep.message)}
                                                helperText={
                                                    errors.paciente?.cep
                                                        ? errors.paciente?.cep?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="paciente.logradouro"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Logradouro"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.logradouro && errors.paciente?.logradouro.message)}
                                                helperText={
                                                    touchedFields.paciente?.logradouro || errors.paciente?.logradouro
                                                        ? errors.paciente?.logradouro?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Controller
                                        name="paciente.numero"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Número"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.numero && errors.paciente?.numero.message)}
                                                helperText={
                                                    touchedFields.paciente?.numero || errors.paciente?.numero
                                                        ? errors.paciente?.numero?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="paciente.complemento"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Complemento"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.complemento && errors.paciente?.complemento.message)}
                                                helperText={
                                                    touchedFields.paciente?.complemento || errors.paciente?.complemento
                                                        ? errors.paciente?.complemento?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Controller
                                        name="paciente.municipio"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                tipo={"TEXTO"}
                                                allowSubmit
                                                disabled={loading}
                                                readOnly={getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"Município"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.municipio && errors.paciente?.municipio.message)}
                                                helperText={
                                                    touchedFields.paciente?.municipio || errors.paciente?.municipio
                                                        ? errors.paciente?.municipio?.message
                                                        : undefined
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Controller
                                        name="paciente.uf"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                                allowSubmit
                                                disabled={loading || getValues('compradorPacienteMesmaPessoa')}
                                                fullWidth
                                                variant="outlined"
                                                label={"UF"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.paciente?.uf && errors.paciente?.uf.message)}
                                                helperText={
                                                    touchedFields.paciente?.uf || errors.paciente?.uf
                                                        ? errors.paciente?.uf?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = UFMock.filter(
                                                            (item) => item.Value === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('paciente.uf', item.Value);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
},
);
