import { useMemo } from 'react';
import * as Yup from 'yup';
import { useValidationYupDocumento, useValidationYupFone, useValidationYupRazaoSocial } from '../../../form-validations'

export const useFormEmpresaValidation = (segmentoObrigatorio: boolean) => {
    const { documentoYup } = useValidationYupDocumento(true)
    const { razaoSocialYup } = useValidationYupRazaoSocial();
    const {foneYup} = useValidationYupFone();

    const FormEmpresaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                cpfcnpj: documentoYup(),
                razaoSocial: razaoSocialYup(),
                nomeFantasia: Yup.string().required('O nome fantasia é obrigatório.'),
                fone: foneYup().required('Telefone é obrigatório.'),
                segmento: segmentoObrigatorio ? Yup.string().required('Informe o Segmento').typeError('Informe o Segmento') : Yup.string().notRequired(),
                segmentoId: segmentoObrigatorio ? Yup.string().required('Informe o Segmento').typeError('Informe o Segmento') : Yup.string().required(),
            })
        )
    }, [documentoYup, foneYup, razaoSocialYup, segmentoObrigatorio])
    return {
        FormEmpresaValidationYup,
    }
}

