
import { useMemo } from 'react';
import { useValidationYupDocumento } from 'views/components/form-validations';
import * as Yup from 'yup'


export const useFormConfigNfceValidation = () => {
    const { documentoYup } = useValidationYupDocumento(true);

    const FormConfigNfceValidationYup = useMemo(() => {
        return Yup.object().shape({
            senha: Yup.string().required('A senha é obrigatória.').min(4, 'Senha inválida. (4-32 caracteres)').max(32, 'Senha inválida. (4-32 caracteres)'),
            pfxBase64: Yup.string().required('O Certificado Digital é obrigatório.'),
            csc: Yup.string().required('Id do CSC é obrigatório.'),
            cIdToken: Yup.string().required('Código de Segurança é obrigatório.'),
            cnpj: documentoYup(),          
        });
    }, [documentoYup])

    return {
        FormConfigNfceValidationYup,
    }
}
