import { EnumTipoCalculo } from "model/enums/enum-tipo-calculo";
import { EnumTipoControleModulo } from "model/enums/enum-tipo-controle-modulo";
import { guidEmpty } from "utils/guid-empty";

export class ModuloPlanoModel {
  constructor(
    public id: string = guidEmpty(),
    public moduloId: string = guidEmpty(),
    public codigo: number = 0,
    public nome: string = '',
    public quantidadeContratada: number = 0,
    public quantidadeMinima: number = 0,
    public quantidadeMaxima: number = 0,
    public quantidadePadrao: number = 0,
    public valorOriginal: number = 0,
    public percAdicional: number = 0,
    public valorFinal: number = 0,
    public tipoControle: EnumTipoControleModulo = EnumTipoControleModulo.NaoControla,
    public tipoCalculo: EnumTipoCalculo = EnumTipoCalculo.Fixo,
    public codModuloBaseCalculo: string = '',
  ) { }
}

export class ModuloPlanoPutModel{
  constructor(
    public id: string = guidEmpty(),
    public moduloId: string = guidEmpty(),
    public codigo: number = 0,
    public quantidadeContratada: number = 0,
  ) { }
}