import { EnumAcaoManifesto } from "model/enums/enum-acao-manifesto";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogConfirmarAcaoManifesto } from "views/components/dialog/dialog-confirmar-acao-manifesto/dialog-confirmar-acao-manifesto";

class DialogAcaoManifestoDialogProps {
    constructor(
        public openned: boolean = false,
        public cnpj: string = '',
        public chave: string = '',
        public acao: EnumAcaoManifesto = EnumAcaoManifesto.EventosDeConfirmacaoDeOperacao,
    ) { }
}

export const ConfirmAcaoManifestoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogAcaoManifestoDialogProps>(new DialogAcaoManifestoDialogProps());

    const modalAlterado = useCallback(({
        openned,
        cnpj,
        chave,
        acao
    }: DialogAcaoManifestoDialogProps) => {
        setModalState({
            openned,
            cnpj,
            chave,
            acao
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.ConfirmarSituacaoManifesto, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ConfirmarSituacaoManifesto, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.openned && (
                <DialogConfirmarAcaoManifesto
                    acao={modalState.acao}
                    chave={modalState.chave}
                    cnpj={modalState.cnpj}
                    openned={modalState.openned}
                />
            )}
        </>
    )
}