import { Grid } from "@material-ui/core";
import { FormRefECFProps } from "./form-ref-ecf-props";
import { Controller } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { ModeloECFMock } from "data/mocks/modelo-ecf-mock";

export const FormRefECF = ({ control, formState, setValue, ...props }: FormRefECFProps) => {

    const { errors, touchedFields } = formState

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="refECF.mod"
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            disabled={props.loading}
                            conteudo={ModeloECFMock}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Modelo do ECF"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refECF?.mod && errors.refECF.mod.message)}
                            helperText={
                                touchedFields.refECF || errors.refECF?.mod
                                    ? errors.refECF?.mod?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refECF.nECF"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Número do Cupom"
                            allowSubmit
                            placeholder="Ex: 1234567"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refECF?.mod && errors.refECF?.mod.message)}
                            helperText={
                                touchedFields.refECF || errors.refECF?.mod
                                    ? errors.refECF?.mod?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refECF.nCOO"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Número do COO"
                            allowSubmit
                            placeholder="Ex: 1234567"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refECF?.nCOO && errors.refECF?.nCOO.message)}
                            helperText={
                                touchedFields.refECF || errors.refECF?.nCOO
                                    ? errors.refECF?.nCOO?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
