import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { useModalStyles } from "../../utils/modal-styles";
import { ModalHeader } from "../../components";
import { useCadastros, useToastSaurus } from "services/app";
import { CancelarIcon, NotaFiscalIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DefaultFormRefs } from "views/components/form/utils";
import { FormSituacaoManifesto } from "views/components/form/manifesto/situacao-manifesto/form-situacao-manifesto";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useStyles } from './situacao-manifesto-styles'
import { IconCard } from "views/components/cards/components";
import { EnumAcaoManifesto } from "model/enums/enum-acao-manifesto";
import { InterrogacaoIcon } from "views/components/icons/interrogacao-icon";
import { RoundConfirmIcon } from "views/components/icons/round-confirm-icon";
import { ManifestoModel } from "model/api/gestao/manifesto/manifesto-model";
import { SincronizarIcon } from "views/components/icons/sincronizar-icon";
import { EnumStatusManifesto } from "model/enums/enum-status-manifesto";
import { useGetManifestoChave } from "data/api/gestao/empresa-documento/get-manifesto-chave";
import { CircularLoading } from "views/components/utils";
import { ManifestoXMLModel } from "model/api/gestao/manifesto/manifesto-xml-model";
import { usePostNovaEntradaXML } from "data/api/gestao/venda/post-nova-entrada-xml";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { useHistory } from "react-router-dom";
import { useGetHistoricoVenda } from "data/api/gestao/historico-venda/get-historico-venda";
import { EnumMovModelo } from "model";
import { HistoricoVendaModel } from "model/api/gestao/historico-vendas/historico-vendas-model";
import { toDateString } from "utils/to-date";
import { descMonth, getCurrentMonth } from "utils/get-date";
interface SituacaoManifestoProps {
    model: ManifestoModel;
}

export const SituacaoManifesto = ({
    model
}: SituacaoManifestoProps) => {

    const modalClasses = useModalStyles();
    const classes = useStyles();
    const { push } = useHistory();
    const { showToast } = useToastSaurus();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { getManifestoChave, carregando: carregandoChave } = useGetManifestoChave();
    const { postNovaEntradaXML, carregando: carregandoXML } = usePostNovaEntradaXML();
    const { getHistoricoVenda, carregando: carregandoHistorico } = useGetHistoricoVenda();

    const { fecharModalSituacaoManifesto, abrirConfirmarSituacaoManifesto } = useCadastros();

    const [xml, setXML] = useState<ManifestoXMLModel | null>(null)

    const formRef = useRef<DefaultFormRefs<any>>(null)

    const carregando = carregandoChave || carregandoXML || carregandoHistorico;

    const urlParams = useMemo(() => new URLSearchParams(window.location.search), [])
    const filtros = useMemo(() => (
        {
            dInicio: urlParams.get('dInicial') || toDateString(descMonth(new Date(getCurrentMonth()), 1), 'yyyy-MM-DD')!,
        }
    ), [urlParams])

    const onClose = () => {
        fecharModalSituacaoManifesto();
    }

    const getManifestoChaveWrapper = useCallback(async () => {
        try {
            const res = await getManifestoChave(model.cnpj, model.chave);

            if (res.erro) throw res.erro;

            const data = res.resultado!.data.find((item: ManifestoXMLModel) => item.tpEvento === 'Emissao');

            setXML(data);
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getManifestoChave, model.chave, model.cnpj, showToast])

    const importarXML = useCallback(async () => {
        try {

            const base64 = btoa(xml!.xml);
            const res = await postNovaEntradaXML({
                xml: base64,
                id: getEmpresaAtual()?.id || '',
                refazer: false
            })
            if (res.erro) throw res.erro

            push(
                {
                    pathname: `/entradas/visualizar/${res.resultado?.data.id}/importacao-xml`,
                    search: '?integrar=true',
                    state: {
                        dtManifesto: filtros.dInicio
                    }
                }
            );

            fecharModalSituacaoManifesto();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [fecharModalSituacaoManifesto, filtros.dInicio, getEmpresaAtual, postNovaEntradaXML, push, showToast, xml])

    const abrirEntrada = async () => {
        try {
            const query = `${'mod=' + EnumMovModelo.NFE}${'&IdNFe' + model.chave}`
            const res = await getHistoricoVenda(query);

            if (res.erro) throw res.erro;

            const data: HistoricoVendaModel = res.resultado?.data.list.find((mov: HistoricoVendaModel) => {
                if(!mov.idNFe) return false
                const chave = mov.idNFe.split('NFe')[1];
                return model.chave === chave
            });
            if(!data){
                throw new Error('Entrada não encontrada');
            }
            push({
                pathname: `/entrada-mercadoria/visualizar/${data.id}`,
                search: window.location.search.includes('?') ? window.location.search + '&origem=manifesto' : '?origem=manifesto',
                state: {
                    dtManifesto: filtros.dInicio
                }
            });
            fecharModalSituacaoManifesto();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }

    useEffect(() => {
        if (formRef.current) {
            formRef.current?.fillForm(model);
        }
        if (model.statusManifesto &&
            !window.location.pathname.includes('entrada') &&
            !model.integrado
        ) {
            getManifestoChaveWrapper();
        }
    }, [getManifestoChaveWrapper, model])

    const confirmarManifesto = (acao: EnumAcaoManifesto) => {
        abrirConfirmarSituacaoManifesto(
            model.cnpj,
            model.chave,
            acao
        )
    }

    const operacaoRealizada = (
        <Grid item xs={6}>
            <IconCard<EnumAcaoManifesto>
                icon={<RoundConfirmIcon />}
                model={EnumAcaoManifesto.EventosDeConfirmacaoDeOperacao}
                title="Esta Operação Foi Realizada"
                onClick={(acao) => confirmarManifesto(acao)}
                wrapText
                fontSize={13}
                isOpacity
            />
        </Grid>
    );

    const cienciaOperacao = (
        <Grid item xs={6}>
            <IconCard<EnumAcaoManifesto>
                icon={<RoundConfirmIcon />}
                model={EnumAcaoManifesto.EventosDeCienciaDaOperacao}
                title="Tenho Ciência da Operação"
                onClick={(acao) => confirmarManifesto(acao)}
                wrapText
                fontSize={13}
                isOpacity
            />
        </Grid>
    )

    const operacaoNaoRealizada = (
        <Grid item xs={6}>
            <IconCard<EnumAcaoManifesto>
                icon={<CancelarIcon />}
                model={EnumAcaoManifesto.EventosDeOperacaoNaoRealizada}
                title="Esta Operação Não Foi Realizada"
                onClick={(acao) => confirmarManifesto(acao)}
                fontSize={13}
                wrapText
                isOpacity
            />
        </Grid>
    )

    const desconhecoOperacao = (
        <Grid item xs={6}>
            <IconCard<EnumAcaoManifesto>
                icon={<InterrogacaoIcon />}
                model={EnumAcaoManifesto.EventosDeOperacaoNaoRealizada}
                title="Desconheço Esta Operação"
                onClick={(acao) => confirmarManifesto(acao)}
                fontSize={13}
                wrapText
                isOpacity
            />
        </Grid>
    )

    const retOpcoes = () => {
        switch (model.statusManifesto) {
            case EnumStatusManifesto.ResumoEvento:
                return <>
                    {cienciaOperacao}
                    {desconhecoOperacao}
                </>;
            case EnumStatusManifesto.ResumoNFe:
                return <>
                    {cienciaOperacao}
                    {desconhecoOperacao}
                </>;
            case EnumStatusManifesto.EventosDeCienciaDaOperacao:
                return <>
                    {operacaoRealizada}
                    {operacaoNaoRealizada}
                </>;
            case EnumStatusManifesto.EventosDeDesconhecimentoDaOperacao:
                return <>
                    {cienciaOperacao}
                    {desconhecoOperacao}
                </>;
            case EnumStatusManifesto.EventosDeConfirmacaoDeOperacao:
                return <>
                    {operacaoRealizada}
                    {operacaoNaoRealizada}
                </>;
            case EnumStatusManifesto.EventosDeOperacaoNaoRealizada:
                return <>
                    {operacaoRealizada}
                    {operacaoNaoRealizada}
                </>;
            default:
                return <>
                    {cienciaOperacao}
                    {desconhecoOperacao}
                </>;
        }
    }

    return (
        <Box className={modalClasses.root} style={{ maxHeight: '99vh', overflow: 'hidden' }}>
            <ModalHeader
                title="Situação do Manifesto"
                leftArea={<ButtonModalHeader
                    icon={<VoltarIcon tipo='MODAL_HEADER' />}
                    onClick={onClose}
                    tooltip="Fechar"
                />}
            />
            <Box className={classes.container}>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <Box>
                    <Typography variant='h6' className={classes.subtitle}>Dados da NF-e</Typography>
                    <Divider className={classes.divider} />
                </Box>
                <FormSituacaoManifesto
                    loading={false}
                    showLoading={false}
                    onSubmit={() => { }}
                    ref={formRef}
                />
                <Box>
                    <Typography variant='h6' className={classes.subtitle}>Manifestar Nota Fiscal</Typography>
                    <Divider className={classes.divider} />
                </Box>
                <Grid container spacing={2}>
                    {retOpcoes()}
                </Grid>
                {(xml && !model.integrado) && <Button variant='outlined' color='primary' onClick={importarXML}>
                    <SincronizarIcon tipo='BUTTON' />
                    Importar NF-e
                </Button>}
                {(model.integrado && !window.location.pathname.includes('entrada')) && <Button variant='outlined' color='primary' onClick={abrirEntrada}>
                    <NotaFiscalIcon tipo='BUTTON' />
                    Abrir NF-e Importada
                </Button>}
            </Box>
        </Box >
    )
}