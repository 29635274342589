import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { NcmModel } from '../../../../model/api/gestao/ncm/ncm-model';
import { VariaveisAmbiente } from "config";


export function usePostNcm() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postNcm= useCallback(
    (ncm: NcmModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/ncm`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(ncm),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postNcm,
  };
}
