import { Grid, Typography, Button, Card, Box } from '@material-ui/core';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import {
  PedidoProdutoFechamentoParcialGroupIDModelPost,
  PedidoProdutoFechamentoParcialModelPost,
  PedidoProdutoModel
} from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { CircularLoading } from 'views/components';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { TransferirIcon } from 'views/components/icons/transferir-item';
import { FinalizacaoParcialComandaListData } from './components/finalizacao-parcial-comanda-list/finalizacao-parcial-comanda-list-data';
import { useStyles } from './mov-finalizacao-parcial-comanda-styles';
import { usePutFechamentoParcialPedido } from 'data/api/gestao/pedido/put-fechamento-parcial-pedido';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { EnumFinalizacaoParcial } from 'model/enums/enum-finalizacao-parcial';
import { roundTo } from 'utils/round-to';
import { toDecimalString } from 'utils/to-decimal';
import { isEmpty } from 'lodash';



interface LocationProps {
  pedido: PedidoModel;
}

export const MovFinalizacaoParcialComandaPage = () => {
  // STATES E REFS
  const [itemsSelecionados, setItemsSelecionados] = useState<
  PedidoProdutoFechamentoParcialGroupIDModelPost[]
  >([]);
  const location = useLocation<LocationProps>();
  const [pedido, setPedido] = useState<PedidoModel>(
    location?.state?.pedido ?? new PedidoModel()
  );
  // CHAMADAS API
  const {
    putFechamentoParcialPedido,
    carregando: carregandoFechamentoParcial
  } = usePutFechamentoParcialPedido();
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();

  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { transformarPedidosEmVendaSimples } = useMovAtual();
  const { goBack } = useHistory();
  const { showToast } = useToastSaurus();

  // AUX
  const classes = useStyles();
  const history = useHistory();

  const carregando = carregandoPutPedidoStatus || carregandoFechamentoParcial;

  useEffect(() => {
    const pedidoAtual = {
      ...location?.state?.pedido,
      produtos: location.state.pedido.produtos.map((x) => ({
        ...x,
        quantidadeMax: x.quantidade
      }))
    };

    setPedido(pedidoAtual ?? new PedidoModel());
  }, [location?.state?.pedido]);

  const produtos =
    pedido.produtos.filter(
      (produto) => produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
    ) ?? [];

  const agruparProdutosSubItens = () => {

    const produtosAgrupados: PedidoProdutoModel[] = produtos.filter(
      (p) => ((
        p.tpProduto.codigo === EnumTpProduto.ProdutoComSubItem ||
        p.tpProduto.codigo === EnumTpProduto.Produto ||
        p.tpProduto.codigo === EnumTpProduto.Combo))

    );

    return produtosAgrupados.map((prodAgrupado) => {

      const subitens = produtos.filter(
        (produto) => [EnumTpProduto.Produto, EnumTpProduto.Insumo].includes(produto.tpProduto.codigo) &&
          !isEmpty(prodAgrupado.groupId) &&
          produto.groupId === prodAgrupado.groupId
      );
      const adicionais = produtos.filter(
        (produto) => produto.tpProduto.codigo === EnumTpProduto.Adicional &&
          produto.groupId === prodAgrupado.groupId
      );
      if (subitens.length > 0 || adicionais.length > 0) {
        return {
          ...prodAgrupado,
          subItens: subitens ? subitens : [],
          adicionais: adicionais ? adicionais : []
        };
      }

      return {
        ...prodAgrupado,
        subitens: [],
        adicionais: []
      };
    });
  };

  const products = agruparProdutosSubItens();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => {
          goBack();
        }}
      ></ButtonPrivateHeader>
    );
  }, [goBack]);

  const header = () => {
    return (
      <PrivatePageHeader title={`Selecionar itens`} leftArea={leftArea()} />
    );
  };

  const importarPedido = useCallback(
    async (pedidoAtual: PedidoModel) => {

      try {
        const respostaPutStatus = await putPedidoStatus(
          getEmpresaSelecionada()?.Id ?? '',
          pedidoAtual.id,
          EnumStatusPedido.IMPORTADO
        );

        if (respostaPutStatus.erro) {
          throw new Error(
            `Erro ao alterar status do pedido para Importado. Detalhe: ${respostaPutStatus.erro}`
          );
        }

        await transformarPedidosEmVendaSimples([pedidoAtual]);
        return history.push('/venda-simples/carrinho');
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      getEmpresaSelecionada,
      history,
      putPedidoStatus,
      showToast,
      transformarPedidosEmVendaSimples
    ]
  );

  const finalizar = useCallback(async () => {

    try {

      const pedidoParaFechamento = await putFechamentoParcialPedido(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        itemsSelecionados as PedidoProdutoFechamentoParcialModelPost[]
      );

      if (pedidoParaFechamento.erro) {
        throw pedidoParaFechamento.erro;
      }
      return await importarPedido(
        pedidoParaFechamento.resultado?.data as PedidoModel
      );
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [
    getEmpresaSelecionada,
    importarPedido,
    itemsSelecionados,
    pedido.id,
    putFechamentoParcialPedido,
    showToast
  ]);

  const quantidadeProduto = (tipo: EnumFinalizacaoParcial, produto: PedidoProdutoModel, quantidade: number) => {
    const calculoPorTipo: any = {
      [EnumFinalizacaoParcial.PARTES]: () => roundTo(produto.quantidade / quantidade, 3),
      [EnumFinalizacaoParcial.QUANTIDADEPRODUTOS]: () => { return produto.quantidade },
      default: () => { return quantidade }
    }
    return (calculoPorTipo[tipo] || calculoPorTipo.default)()
  }

  const alterarQtd = useCallback(
    (produto: PedidoProdutoModel, quantidade: number, tipo: EnumFinalizacaoParcial) => {
      setItemsSelecionados((prev) => {


        if (quantidade <= 0) {
          return prev.filter(
            (prod) =>
              prod.quantidade > 0
              &&
              (prod.groupId !== produto.groupId && prod.produtoId !== produto.id)
          );
        }


        const temEsseItemsSelecionados = prev.find(
          (prod) =>
            prod.produtoId === produto.id || (prod.groupId !== null && prod.groupId === produto.groupId)
        );


        if (!temEsseItemsSelecionados) {
          const subItensValidos = produto.subItens?.filter((p) => (p?.quantidade ?? 0) > 0) ?? []
          const adicionaisValidos = produto.adicionais?.filter((p) => (p?.quantidade ?? 0) > 0) ?? []
          const subitensEadicionais = [...subItensValidos, ...adicionaisValidos]
          const itensAdicionais = subitensEadicionais.map((p) => ({
            produtoId: p.id,
            quantidade: quantidadeProduto(tipo, p, quantidade),
            groupId: p.groupId
          }));

          return [
            ...prev,
            { produtoId: produto.id, quantidade: quantidadeProduto(tipo, produto, quantidade), groupId: produto.groupId },
            ...itensAdicionais
          ];
        }
        const updatedProducts = prev.map((item) => {
          if (item.produtoId === produto.id) {
            return {
              ...item,
              quantidade: quantidadeProduto(tipo, produto, quantidade)
            };
          }
          return item;
        });

        let updatedSubItems: PedidoProdutoFechamentoParcialGroupIDModelPost[] = [];
        let updatedAdicionais: PedidoProdutoFechamentoParcialGroupIDModelPost[] = [];
        if (produto.subItens?.length > 0 || produto.adicionais?.length > 0) {
          updatedSubItems = produto.subItens?.map((subItem) => ({
            produtoId: subItem.id,
            quantidade: quantidadeProduto(tipo, subItem, quantidade),
            groupId: subItem.groupId

          }));
          updatedAdicionais = produto.adicionais?.map((adicional) => ({
            produtoId: adicional.id,
            quantidade: quantidadeProduto(tipo, adicional, quantidade),
            groupId: adicional.groupId
          }));


          const finalProducts = updatedProducts.map((item) => {
            const foundSubItem = updatedSubItems?.find(
              (sub) => sub.produtoId === item.produtoId
            );
            if (foundSubItem) {
              return { ...item, quantidade: foundSubItem.quantidade };
            }
            const foundAdicional = updatedAdicionais?.find(
              (ad) => ad.produtoId === item.produtoId
            );

            if (foundAdicional) {
              return { ...item, quantidade: foundAdicional.quantidade };
            }
            return item;
          });


          updatedSubItems.forEach((subItem) => {
            if (subItem.quantidade > 0) {
              if (!finalProducts.some((prod) => prod.produtoId === subItem.produtoId)) {
                finalProducts.push(subItem);
              }
            }
          });
          updatedAdicionais.forEach((adicional) => {
            if (adicional.quantidade > 0) {
              if (!finalProducts.some((prod) => prod.produtoId === adicional.produtoId)) {
                finalProducts.push(adicional);
              }
            }
          });

          return finalProducts;
        }


        return prev.map((prod) => {
          if (prod.produtoId === produto.id) {
            return { ...prod, quantidade: quantidadeProduto(tipo, produto, quantidade) };
          }
          return prod;
        });
      });
    },
    []
  );
  const validadorDeCampo = () => {
    const quantidade = itemsSelecionados.map(item => item.quantidade)
    if (quantidade.length === 0) {
      return true
    }
    return false
  }

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>{header()}</Grid>

        <Grid className={classes.containerInfo}>
          <Card>
            <Box p={1}>
              <Typography style={{ fontWeight: '500' }}>
                {toDecimalString(products.reduce((acc, current) => acc + current.quantidade, 0), 3)}{' '}
                {products.length === 1 ? 'Produto' : 'Produtos'}
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid className={classes.list}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <FinalizacaoParcialComandaListData
            itens={products}
            alterarQtd={alterarQtd}
            carregando={false}
          />
        </Grid>
        <Grid className={classes.containerAcao}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                onClick={finalizar}
                fullWidth
                variant="contained"
                color="primary"
                disabled={validadorDeCampo() || carregando}
              >
                <TransferirIcon tipo="BUTTON_PRIMARY" />
                {'Finalizar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  );
};
