import { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './lista-empresas-list-styles';
import { ListaEmpresasListData } from './lista-empresas-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { ListaEmpresasListSearchProps } from './lista-empresas-list-search-props';
import { isEmpty } from 'lodash';
import { EmpresaModel } from 'model';
import { useGetEmpresas } from 'data/api/gestao/empresa';
import { DialogOpcoesEmpresa } from 'views/components/dialog/dialog-opcoes-empresa/dialog-opcoes-empresa';

export interface PontosVendaListProps {
  searchProps?: ListaEmpresasListSearchProps;
}

export const ListaEmpresasList = (props: PontosVendaListProps) => {
  const classes = useStyles();
  const { getEmpresas, carregando } = useGetEmpresas();
  const { showToast } = useToastSaurus();
  const [modal, setModal] = useState<boolean>(false)
  const [id, setId] = useState<string>("")
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<EmpresaModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<EmpresaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query =
        '' +
        (!isEmpty(props.searchProps?.termo)
          ? '&generico=' + props.searchProps?.termo
          : '')
      try {
        const res = await getEmpresas(query,
          newPage,
        );
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [props.searchProps?.termo, getEmpresas, fillResult, showToast],
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    setModal(!modal)
    setId(id)
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    search(1);
  }, [search]);

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <ListaEmpresasListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
      <DialogOpcoesEmpresa
        id={id}
        openned={modal}
        closeModal={() => setModal(!modal)}
      />
    </>
  );
};
