import { EnumTipoTrabalho } from "model/enums/enum-tipo-trabalho";
import { EnumTipoTrabalhoComanda } from "model/enums/enum-tipo-trabalho-comanda";

export class ConfiguracaoMesaEComanda {
    constructor(
        public tipoTrabalho: EnumTipoTrabalho | undefined = undefined,
        public qtdeSaloes: number = 0,
        public qtdeMesa: number = 0,
        public qtdeComandas: number = 0,
        public tipoTrabalhoComanda: EnumTipoTrabalhoComanda = EnumTipoTrabalhoComanda.APENAS_CODIGO
    ) { }
}