import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const MedalhaFillIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 12 0 C 11.449219 0 11 0.449219 11 1 L 11 4.78125 C 11 6.136719 11.988281 7.867188 13.28125 8.8125 C 13.308594 8.832031 13.316406 8.859375 13.34375 8.875 L 19.09375 12.21875 C 19.640625 11.476563 20.511719 11 21.5 11 L 28.5 11 C 29.488281 11 30.359375 11.480469 30.90625 12.21875 L 36.65625 8.875 C 36.683594 8.859375 36.691406 8.832031 36.71875 8.8125 C 38.011719 7.867188 39 6.136719 39 4.78125 L 39 1 C 39 0.449219 38.554688 0 38 0 Z M 21.5 13 C 20.949219 13 20.5 13.449219 20.5 14 L 20.5 15 C 20.5 16.652344 21.734375 18 23.25 18 L 26.75 18 C 28.265625 18 29.5 16.652344 29.5 15 L 29.5 14 C 29.5 13.449219 29.054688 13 28.5 13 Z M 23.15625 20 L 19.25 27.75 L 9.1875 29.1875 C 8.671875 29.261719 8.222656 29.609375 8.0625 30.09375 C 7.902344 30.585938 8.03125 31.140625 8.40625 31.5 L 15.71875 38.46875 L 14 48.34375 C 13.910156 48.851563 14.109375 49.386719 14.53125 49.6875 C 14.957031 49.992188 15.503906 50.019531 15.96875 49.78125 L 25 45.125 L 34.03125 49.78125 C 34.230469 49.886719 34.4375 49.9375 34.65625 49.9375 C 34.941406 49.9375 35.230469 49.859375 35.46875 49.6875 C 35.890625 49.386719 36.085938 48.847656 36 48.34375 L 34.28125 38.46875 L 41.59375 31.5 C 41.96875 31.144531 42.097656 30.585938 41.9375 30.09375 C 41.777344 29.605469 41.328125 29.261719 40.8125 29.1875 L 30.75 27.75 L 26.84375 20 Z" />
            </DefaultIcon>
        </>
    );
};
