import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ReceitaStepIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M16 0V2H6.5C5.11719 2 4 3.11719 4 4.5V47.5C4 48.8828 5.11719 50 6.5 50H43.5C44.8828 50 46 48.8828 46 47.5V4.5C46 3.11719 44.8828 2 43.5 2H34V0H16ZM18 2H32V2.8125C32 2.84375 32 2.875 32 2.90625V3C31.9922 3.07422 31.9922 3.14453 32 3.21875V6.8125C32 6.84375 32 6.875 32 6.90625V7C31.9922 7.07422 31.9922 7.14453 32 7.21875V8H18V7.1875C18.0273 7.05469 18.0273 6.91406 18 6.78125V3.1875C18.0273 3.05469 18.0273 2.91406 18 2.78125V2ZM6.5 4H16V6H9V45H41V6H34V4H43.5C43.7148 4 44 4.28516 44 4.5V47.5C44 47.7148 43.7148 48 43.5 48H6.5C6.28516 48 6 47.7148 6 47.5V4.5C6 4.28516 6.28516 4 6.5 4ZM11 8H16V10H34V8H39V43H11V8ZM26 13V15H35V13H26ZM18 15V18H15V20H18V23H20V20H23V18H20V15H18ZM26 18V20H35V18H26ZM26 23V25H35V23H26ZM15 28V30H35V28H15ZM15 33V35H35V33H15ZM15 38V40H35V38H15Z" />
      </DefaultIcon>
    </>
  );
};
