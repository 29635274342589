import { TipoField } from "./text-field-saurus-types";
function validador(value: string, tipo?: TipoField) {
  switch (tipo) {
    case "CPF":
      return ValidaCpf(value);
    case "CNPJ":
      return ValidaCnpj(value);
    case "CNPJ_CPF":
      return ValidaCnpjCpf(value);
    case "CNPJ_CPF_GERAL":
      return ValidaCnpjCpfGeral(value);
    case "HORA":
      return validaHora(value);
    default:
      return true;
  }
}

function ValidaCpf(value: string) {
  let v = value;
  let mask = /^\d{3}[.]\d{3}[.]\d{3}[-]\d{2}$/;
  if (v.length === 0) {
    return true;
  } else if (mask.test(v)) {
    v = v.replace(/\D/g, "");
    if (CpfValido(v) === false || v.length < 11) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function ValidaCnpj(value: string) {
  var v = value;
  var mask = /^\d{2}[.]\d{3}[.]\d{3}[/]\d{4}[-]\d{2}$/;

  if (v.length === 0) {
    return true;
  } else if (mask.test(v)) {
    v = v.replace(/\D/g, "");
    if (CnpjValido(v) === false || v.length < 14) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
function ValidaCnpjCpf(value: string) {
  var v = value;
  var maskcpf = /^\d{3}[.]\d{3}[.]\d{3}[-]\d{2}$/;
  var maskcnpj = /^\d{2}[.]\d{3}[.]\d{3}[/]\d{4}[-]\d{2}$/;
  if (v.length === 0) {
    return true;
  } else if (maskcpf.test(v)) {
    return ValidaCpf(value);
  } else if (maskcnpj.test(v)) {
    return ValidaCnpj(value);
  } else {
    return false;
  }
}
function validaHora(value: string) {
  var v = value;
  var mask = /^[0-2][0-3]:[0-5][0-9]:[0-5][0-9]/;

  if (mask.test(v)) {
    return true;
  } else if (v !== null && v !== "") {
    return false;
  }
}

function ValidaCnpjCpfGeral(value: string) {
  var v = value;
  v = v.replace(/\D/g, "");
  if (v.length === 11 || v.length === 14) {
    return ValidaCnpjCpf(value);
  } else {
    return true;
  }
}

function CpfValido(cpf: string) {
  if (cpf.length !== 11) {
    return false;
  }
  if (cpf === "00000000000") {
    return true;
  }

  let numeros: any,
    digitos: any,
    soma: any,
    i: any,
    resultado: any,
    digitos_iguais: any;
  digitos_iguais = 1;
  if (cpf.length < 11) return false;
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitos_iguais = 0;
      break;
    }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9);
    digitos = cpf.substring(9);
    soma = 0;
    for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(0)) {
      return false;
    }

    numeros = cpf.substring(0, 10);
    soma = 0;

    for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(1)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function CnpjValido(cnpj: string) {
  if (cnpj.length !== 14) {
    return false;
  }
  if (cnpj === "00000000000000") {
    return true;
  }

  var numeros: any,
    digitos: any,
    soma: any,
    i: any,
    resultado: any,
    pos: any,
    tamanho: any,
    digitos_iguais: any;
  digitos_iguais = 1;

  if (cnpj.length < 14 && cnpj.length < 15) {
    return false;
  }

  for (i = 0; i < cnpj.length - 1; i++)
    if (cnpj.charAt(i) !== cnpj.charAt(i + 1)) {
      digitos_iguais = 0;
      break;
    }
  if (!digitos_iguais) {
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(1)) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
export { validador };
