import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { DialogParcelamento as Parcelamento} from './dialog-modal/dialog/dialog-parcelmento'

interface DialogParcelamentoProps {
  callback: (qtdeParcelas: number) => void;
  qtdePessoas: number;
  vRestante: number;
  paymentMethod: FinalizadoraModel;
}

export const DialogParcelamento = (props: ModalProps & DialogParcelamentoProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && <Parcelamento callback={props.callback} qtdePessoas={props.qtdePessoas} vRestante={props.vRestante} paymentMethod={props.paymentMethod}/>}
    </DefaultModal>
  );
};
