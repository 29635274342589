import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        display: 'flex',
        background: '#FFF',
        flex: 1,
        flexDirection: 'column',
        padding: 0
    },
    containerAcoes: {
        padding: 0,
    },
    inputInfo:{
        minHeight:115
    }
}))