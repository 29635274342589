import { useCallback } from 'react';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';

interface SelecaoVendedorProps {
}

export const SelecaoVendedorHeader = ({ ...props
}: SelecaoVendedorProps) => {
  const { goToAdministrativo } = useOpenMovRota()

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={goToAdministrativo}
      />
    );
  }, [goToAdministrativo]);

  return (
    <>
      <PrivatePageHeader
        title={'Seleção de vendedor'}
        leftArea={leftArea()}
      />
    </>
  );
};
