import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
    },
    topHeader: {
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "space-between",
        '@media (max-height: 580px)': {
            '& h5': {
                fontSize: '1rem'
            }
        }
    },
    title: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "18px",
        color: theme.palette.secondary.main
    },
    middleContainer: {
        minHeight: "90px",
        display: "flex",
        padding: theme.spacing(1),
        background: "#FFF"
    },
    middleContent: {
        flexWrap: 'nowrap',
        height: "100%",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },

    numpadContainer: {
        flexGrow: 1,
        height: "100%",
        background:
            "linear-gradient(335.37deg, rgba(246, 248, 250, 0.6) -3.52%, rgba(252, 251, 252, 0.6) 117.74%);",
    },
    numpadValueContainer: {
        background: theme.palette.primary.contrastText,
    },
    numpadValue: {
        fontWeight: "bold",
        color: "#8D8D8D",
    },
    setarValorPadraoContainer: {
        display: "flex",
    },
    setarValorPadraoItem: {
        flexBasis: "20%",
    },
    valorContainer: {
        "& .valorMonetario": {
            display: "flex",
            color: "#696969",
            "& .unidade": {
                fontWeight: "600",
                marginRight: "1.5vw",
                [theme.breakpoints.up("sm")]: {
                    marginRight: "1vw",
                },
                [theme.breakpoints.up("md")]: {
                    marginRight: "0.7vw",
                },
            },
            "& .valor": {
                fontWeight: "bold",
            },
        },
    },

    parcelasLabel: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '22px'
    },
    parcelasValue: {
        textAlign: "left",
    },
    buttonContainer: {
        marginBottom: theme.spacing(2),
    },
    buttonContent: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    mediaQueryTop: {
        '@media (max-height: 580px)': {
            display: 'none'
        }
    }
}));