import { useCallback, useEffect, useState, useRef } from 'react';
import { useStylesInicial } from './scanner-inicial-styles';
import { useStylesSecundario } from './scanner-secundario-styles';
import { useToastSaurus } from 'services/app';
import { Box, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views';
import { isEmpty } from 'lodash';
import { SemCameraIcon } from 'views/components/icons';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { VariaveisAmbiente } from 'config';
import { consoleDev } from 'utils/console-dev';
interface ScannerProps {
    inicialOrSecundario: boolean;
    onScan: (codigo: string) => void;
}


const Scanner = ({
    onScan,
    inicialOrSecundario,
}: ScannerProps) => {
    const { showToast } = useToastSaurus();
    const { isMobile } = useThemeQueries();

    const classesInicial = useStylesInicial();
    const classesSecundario = useStylesSecundario();
    const [erro, setErro] = useState('')
    const ultimaLeitura = useRef(new Date());

    let html5QrCode: any;

    const onDetected = useCallback(
        (result) => {
            try {
                if (ultimaLeitura.current < new Date(new Date().getTime() - 3000)) {
                    ultimaLeitura.current = new Date();
                    let codigoValidacao = result.substring(0, 1);
                    let codigoInicial = '0'
                    if (codigoValidacao === codigoInicial) {
                        onScan(Number(result).toString());
                    }
                    else {
                        onScan(result);
                    }
                }
            } catch (e: any) {
                showToast(
                    'error',
                    'Erro ao Interpretar o Código. Detalhe: ' + e.message,
                );
            }
        },
        [onScan, showToast],
    );

    const pegarCameras = useCallback(async () => {
        let deuErro = false

        let formatos = [
            Html5QrcodeSupportedFormats.CODE_128,
            Html5QrcodeSupportedFormats.CODE_93,
            Html5QrcodeSupportedFormats.CODE_39,
            Html5QrcodeSupportedFormats.QR_CODE,
            Html5QrcodeSupportedFormats.EAN_8,
            Html5QrcodeSupportedFormats.EAN_13,
        ];

        await Html5Qrcode.getCameras().then(devices => {
            var cameraId: any = {
                facingMode: 'environment'
            }
            if (!isEmpty(devices)) {
                let cameraBackList = []
                cameraBackList = devices
                    .filter(device => device.label.match(/back/) !== null)
                if (cameraBackList.length > 0 && cameraBackList[cameraBackList.length - 1]['id'] !== undefined) {
                    cameraId = {
                        deviceId: cameraBackList[cameraBackList.length - 1]["id"]
                    }
                } else {
                    cameraId = {
                        facingMode: 'environment'
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
                html5QrCode = new Html5Qrcode("video", { verbose: false, formatsToSupport: formatos });
                html5QrCode.start(
                    cameraId,
                    {
                        fps: 30,
                    },
                    (decodedText: string) => {
                        onDetected(decodedText);
                    },
                )
                deuErro = false
            }
        })
            .catch((err: any) => {
                if (err.toString().indexOf('Permission denied') > -1) {
                    setErro('Erro ao iniciar a câmera, a permissão de uso é necessaria!')
                    deuErro = false
                }
                if (isEmpty(err)) {
                    deuErro = true
                }
            })

        return deuErro
    }, [])

    const scannerCamera = useCallback(async () => {
        const value = await pegarCameras()
        if (value && VariaveisAmbiente.environment === 'cordovaSlow') {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            html5QrCode = new Html5Qrcode("video", { verbose: true });
            html5QrCode.start(
                {
                    facingMode: 'environment'
                },
                {
                    fps: 30,
                },
                (decodedText: string) => {
                    onDetected(decodedText);
                },
            )
        }
    }, [html5QrCode, onDetected]);

    const stopScanner = useCallback(() => {
        try {
            if (html5QrCode) {
                html5QrCode.stop().then(() => {
                    html5QrCode.clear()
                }).catch((err: any) => {
                    consoleDev(err.message)
                });
            }
        } catch (err: any) {
            consoleDev(err)
        }
    }, [html5QrCode])

    useEffect(() => {
        setTimeout(() => {
            scannerCamera();
        }, 1000)
        return () => {
            stopScanner();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopScanner]);


    const renderIncialScanner = () => {
        return (
            <Grid
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: isMobile ? '75%' : '65%',
                }}
            >
                <Grid
                    id="video"
                    className={
                        inicialOrSecundario
                            ? classesSecundario.containerScann
                            : classesInicial.containerScann
                    }
                />
                <Grid
                    style={{
                        width: '100%',
                        height: '500px',
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {
                                !isEmpty(erro) ? (
                                    <Box width="100%" justifyContent={"center"} display="flex">
                                        <div className={classesInicial.semCameraIcon}>
                                            <SemCameraIcon tipo="BUTTON_PRIMARY" />
                                            <Typography>
                                                <Box fontWeight={600} color="#FFF">
                                                    {erro}
                                                </Box>
                                            </Typography>
                                        </div>
                                    </Box>
                                ) : (
                                    <div style={{ borderTop: '1px solid red', width: '100%' }} />
                                )
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderSecundaryScanner = () => {
        return (
            <>
                <Grid
                    style={{ display: 'flex', justifyContent: 'center', height: '150px' }}
                >
                    <Grid
                        id="video"
                        className={
                            inicialOrSecundario
                                ? classesSecundario.containerScann
                                : classesInicial.containerScann
                        }
                    />
                    <Grid
                        style={{
                            width: '100%',
                            height: '150px',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '80%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {
                                    !isEmpty(erro) ? (
                                        <Box width="100%" justifyContent={"center"} display="flex">
                                            <div className={classesSecundario.semCameraIcon}>
                                                <SemCameraIcon tipo="BUTTON_PRIMARY" />
                                                <Typography>
                                                    <Box fontWeight={600} color="#FFF">
                                                        {erro}
                                                    </Box>
                                                </Typography>
                                            </div>
                                        </Box>
                                    ) : (
                                        <div style={{ borderTop: '1px solid red', width: '100%' }} />
                                    )
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            {inicialOrSecundario ? renderSecundaryScanner() : renderIncialScanner()}
        </>
    );
};

export default Scanner;
