
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetComandaById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComandaById = useCallback(
        (comandaId: string, empresaId: string) =>
            invocarApi({
                url: `/v2/empresa/${empresaId}/comanda/${comandaId}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComandaById,
    };
}
