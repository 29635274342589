import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { ProdutoMedidaModel } from '../../../../../model/api/gestao/produto/produto-medida/produto-medida-model';
import { VariaveisAmbiente } from 'config';

export function usePostProdutoMedida() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutoMedida = useCallback(
    (produtoMedida: ProdutoMedidaModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Medida`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtoMedida),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoMedida
  };
}
