import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'default',
  },
  containerRoot: {
    padding: '8px'
  },
  operadorContainer: {
    width: '200px',
  },
  valorTotalContainer: {
    width: '100px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttons: {
    width: "40px",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      display: 'flex',
      alignItems: 'center',
      marginRight: '0px',
    }
  },
  buttonOperadores: {
    marginRight: '4px',
  },
  buttonFormaPagamento: {
    marginLeft: '4px',
  },
}));
