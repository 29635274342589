import { KeyValueModel } from "model";
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";

export const AdicionarTpCorrecaoGerenciamentoSessaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpLancamentoSessaoValor.DESPESA, "Despesa"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.GORJETA, "Gorjeta"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.RECEITA, "Receita"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SANGRIA, "Sangria"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SUPRIMENTO, "Suprimento"),
];

export const EditarTpCorrecaoGerenciamentoSessaoMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpLancamentoSessaoValor.ABERTURA, "Aberura de Caixa"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.DESPESA, "Despesa"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.FECHAMENTO, "Fechamento de Caixa"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.GORJETA, "Gorjeta"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.RECEITA, "Receita"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SANGRIA, "Sangria"),
    new KeyValueModel(EnumTpLancamentoSessaoValor.SUPRIMENTO, "Suprimento"),
];
