import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';

import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useHistory } from 'react-router';
import { useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCallback, useEffect } from 'react';
import { LimiteAtingidoVendaIcon } from 'views/components/icons/LimiteAtingidoVenda';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

export interface modalLimiteAtingidoProps {
  aberto: boolean;
}

export const ModalLimiteVendaAtingido = ({
  ...props
}: modalLimiteAtingidoProps) => {
  const classes = useModalStyles();
  const history = useHistory();
  const { plano } = useSessaoAtual();
  const { carregando } = useMovProd()
  const { fecharModalLimiteVendaAtingido } = useCadastros()

  const { callEvent } = useEventTools()

  useEffect(() => {
    if (props.aberto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [callEvent, props.aberto])

  const onCloseClick = useCallback(() => {
    fecharModalLimiteVendaAtingido();
  }, [fecharModalLimiteVendaAtingido]);

  const handleClick = () => {
    fecharModalLimiteVendaAtingido();
    history.push('/alterar-plano');
  };

  const total = plano?.plano?.opcoes.find((item) => item.codigo === 100100);

  return (
    <>
      {props.aberto && (
        <DialogSaurus
          aberto={props.aberto}
          carregando={carregando}
          titulo="Plano insuficiente!"
          tamanho="xs"
          isButtonTitleClose
          handleClickClose={onCloseClick}
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                  carregando ? classes.contentFormsLoading : undefined,
                )}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    height: '70%',
                    width: '100%',
                  }}
                >
                  <LimiteAtingidoVendaIcon />
                </div>

                <Typography
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    color: '#6C6C6C',
                    marginTop: '24px',
                  }}
                >
                  Você possui{' '}
                  <strong
                    style={{ fontWeight: 800, lineHeight: 2, color: '#F44336' }}
                  >
                    {total?.valor} vendas!
                  </strong>
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#B7B7B7',
                  }}
                >
                  Para continuar efetuando suas vendas, faça um upgrade no seu
                  plano, clicando no botão a baixo.
                </Typography>
              </div>
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    disabled={carregando}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                  >
                    Ver planos
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogSaurus>
      )}
    </>
  );
};
