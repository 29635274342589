import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ArrowRigthIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M13.5208 41.9375L17.2083 45.625L37.8333 25L17.2083 4.375L13.5208 8.0625L30.4583 25L13.5208 41.9375Z" />
      </DefaultIcon>
    </>
  );
};
