import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import CardProdMedicamento from '../../../../cards/card-produto-medicamento/card-prod-medicamento';
import { useThemeQueries } from 'views/theme';

export interface ProdutoGridProps {
  list: Array<MovSimplesProdutoModel>;
  selectedList: Array<MovSimplesProdutoModel>;
  onCardSelected: (prod: MovSimplesProdutoModel) => any;
  onCardClick: (prod: MovSimplesProdutoModel) => any;
}

export const ProdutosMedListData = (props: ProdutoGridProps) => {
  const { isMobile } = useThemeQueries();

  const onCardSelected = (prod: MovSimplesProdutoModel) => {
    props.onCardSelected(prod);
  };

  const onCardClick = (prod: MovSimplesProdutoModel) => {
    props.onCardClick(prod);
  };

  return (
    <>
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem={`Nenhum medicamento encontrado.`}
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((produto, index)  => {
          return (
            <>
              <CardProdMedicamento
                selecionado={
                  props.selectedList.filter(
                    (item) => item.id === produto.id,
                  ).length > 0
                    ? true
                    : false
                }
                onClick={onCardClick}
                onSelect={onCardSelected}
                model={produto}
                key={produto.id}
                isMobile={isMobile}
              />
            </>
          );
        })}
    </>
  );
};
