import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerBody: {
        display: "flex",
        position: "relative",
        width: "100%",
        height: "100%",
        padding: "0px",
        margin: "0px",
    },
    flexContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        }
    }
}));