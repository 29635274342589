import { KeyValueModel } from "model/api";
import { EnumPagTpMod } from "model/enums";

export const TpModMock: KeyValueModel[] = [
    new KeyValueModel(EnumPagTpMod.CARTAO_CREDITO, "Cartão de Crédito"),
    new KeyValueModel(EnumPagTpMod.CARTAO_DEBITO, "Cartão de Débito"),
    new KeyValueModel(EnumPagTpMod.DINHEIRO, "Dinheiro"),
    new KeyValueModel(EnumPagTpMod.PAGAMENTO_INSTANTANEO, "PIX"),
    new KeyValueModel(EnumPagTpMod.DEPOSITO_BANCARIO, "Depósito Bancário"),
    new KeyValueModel(EnumPagTpMod.DUPLICATA_MERCANTIL, "Duplicata Mercantil"),
    new KeyValueModel(EnumPagTpMod.PROGRAMA_FIDELIDADE, "Programa Fidelidade"),
    new KeyValueModel(EnumPagTpMod.TRANSFERENCIA_BANCARIA, "Transferência Bancária"),
    new KeyValueModel(EnumPagTpMod.VALE_ALIMENTACAO, "Vale Alimentação"),
    new KeyValueModel(EnumPagTpMod.VALE_COMBUSTIVEL, "Vale Combustível"),
    new KeyValueModel(EnumPagTpMod.VALE_PRESENTE, "Vale Presente"),
    new KeyValueModel(EnumPagTpMod.VALE_REFEICAO, "Vale Refeição"),
    new KeyValueModel(EnumPagTpMod.BOLETO_BANCARIO, "Boleto Bancário"),
    new KeyValueModel(EnumPagTpMod.CHEQUE, "Cheque"),
    new KeyValueModel(EnumPagTpMod.CREDITO_LOJA, "Crédito Loja"),
    new KeyValueModel(EnumPagTpMod.SEM_PAGAMENTO, "Sem Pagamento"),
    new KeyValueModel(EnumPagTpMod.OUTRO, "Outro"),
];
