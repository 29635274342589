import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useThemeQueries } from '../../../../../theme/util-styles';
import { useCallback, useEffect, useState } from 'react';
import { useConsultaAssinaturaACSAT } from 'data/api/wsmaster/consultar-assinaturaACSAT';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Button, Typography } from '@material-ui/core';
import { CopiarIcon, SATIcon } from 'views/components/icons';

interface Props {
  cnpj?: string;
  hasCnpj?: boolean;
  aberto?: boolean;
}

export const AccordionSAT = ({ cnpj, hasCnpj = false, aberto }: Props) => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(aberto ?? false);
  const [assinatura, setAssinatura] = useState<string>('');

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { consultarAssinaturaACSAT } = useConsultaAssinaturaACSAT();

  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();

  const getACSAT = useCallback(async () => {
    const empresaCnpj = hasCnpj
      ? cnpj || ''
      : getEmpresaSelecionada()?.Documento || '';
    try {
      const res = await consultarAssinaturaACSAT(empresaCnpj);
      setAssinatura(res);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [
    cnpj,
    consultarAssinaturaACSAT,
    getEmpresaSelecionada,
    hasCnpj,
    showToast,
  ]);

  const copiar = () => {
    const tempInput = document.createElement('input');
    tempInput.value = assinatura;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    showToast('success', 'Código copiado!');
  };

  useEffect(() => {
    getACSAT();
  }, [getACSAT]);

  return (
    <AccordionSaurus
      labelPrimary={'SAT CF-e (SP)'}
      tituloChildren={
        <SATIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classesModal.root}>
        <div className={classesModal.content}>
          <Typography variant="body1" style={{ marginBottom: 16 }}>
            Utilize o código abaixo para realizar a ativação do SAT.
          </Typography>
          <TextFieldSaurus
            multiline
            value={assinatura}
            style={{ marginBottom: 16 }}
          />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={copiar}
          >
            <CopiarIcon tipo="BUTTON" fill="white" />
            Copiar Código
          </Button>
        </div>
      </div>
    </AccordionSaurus>
  );
};
