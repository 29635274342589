import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    cardValores: {
        display: 'flex',
        background: '#fff',
        borderRadius: theme.shape.borderRadius,
        minHeight: 56,
        height: 'auto',
        padding: theme.spacing(0, 1),
        width: '100%',
        '&.MuiButton-text': {
            padding: theme.spacing(0, .5),
        }
    },
    contentValor: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    alinharContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    fontStrong: {
        fontWeight: 700
    },
    infAdicional: {
        fontWeight: 600,
        maxWidth: "150px!important"
    },
    containerInfo: {
        display: 'flex',
        height: '70px',
        padding: '4px 0',
        alignItems: 'center',
    },
    btnLabel: {
        justifyContent: 'normal',
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap'
    },
}));
