import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Grid, Typography, useTheme, Button } from '@material-ui/core';
import { useStyles } from './abrir-caixa-page-styles';
import { AbrirCaixaHeader } from './components/abrir-caixa-header/abrir-caixa-header';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useHistory } from 'react-router-dom';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useGetSessaoAtiva } from 'data/api/gestao/sessao/get-sessao-ativa';
import { usePDV } from 'services/app/hooks/pdv';
import { CircularLoading } from 'views/components';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { isEmpty } from 'lodash';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { Keyboard } from 'views/components/keyboard/keyboard';
import { useKeyboard } from 'services/app/hooks/keyboard';
import { toDecimal } from 'utils/to-decimal';
import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { SessaoSincronizar, SessaoValorModel } from 'model/api/gestao/sessao/sessao-valor-model';
import { usePostSincronizarValores } from 'data/api/gestao/pontos-venda/post-sincronizar-valores';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';

export const AbrirCaixa = () => {
  // STATES E REFS
  const valorInicial = useRef<string>('');
  const digitado = useRef<boolean>(false);
  const textoAtual = useRef<string>('');
  const valueRef = useRef<HTMLParagraphElement | null>(null);

  // HOOKS
  const { setSessao, setValor, converterDados } = useSessaoPDV();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { getPDV, getConfigByCod } = usePDV();
  const { getEmpresaSelecionada, getPessoa } = useSessaoAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { setTipoMovimentacao, getMov } = useMovAtual();
  const { postSincronizarValores } = usePostSincronizarValores()
  const { iniciarSincronizacaoGeral } = useSincronizacaoGeral();

  // AUX
  const classes = useStyles();
  const theme = useTheme();
  const { showToast } = useToastSaurus();
  const history = useHistory();

  const modeloTrabalho = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho);
  const pedirMesaComandaInicio =
    getConfigByCod(EnumPDVConfigCod.MomentoSolicitacaoMesaComanda) === '0';
  const isLancadorPedidos = [
    EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  // CHAMADAS API
  const { getSessaoAtiva, carregando: carregandoSessaoAtiva } =
    useGetSessaoAtiva();
  const { getFinalizadoras, carregando: carregandoGet } = useGetFinalizadoras();

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      valorInicial.current = String(toDecimal(formattedText, 2));
      return true;
    },
    []
  );

  const switchRedirecionamento = useCallback(async () => {
    if (
      isLancadorPedidos &&
      (isEmpty(getMov()?.informacoesGeraisPedido?.comandaId) ||
        isEmpty(getMov()?.informacoesGeraisPedido?.mesaId)) &&
      pedirMesaComandaInicio
    ) {
      if (navigator.onLine) {
        if (
          getConfiguracoesMesaEComanda()?.tipoTrabalho ===
          EnumTipoTrabalho.MESA ||
          (!isEmpty(getMov()?.informacoesGeraisPedido.comandaId) &&
            isEmpty(getMov()?.informacoesGeraisPedido.mesaId))
        ) {
          await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
          return history.push('/venda-simples/vincular-mesa');
        } else {
          await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
          return history.push('/venda-simples/leitor-comanda');
        }
      }
    }

    return history.push('/venda-simples/landing');
  }, [
    getConfiguracoesMesaEComanda,
    getMov,
    history,
    isLancadorPedidos,
    pedirMesaComandaInicio,
    setTipoMovimentacao
  ]);

  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    };
  }, [callEvent]);

  useEffect(() => {
    const getSessaoAtivaOperador = async () => {
      const existemSessoes = await TouchoneDBPrimary.sessao.toArray();

      if (existemSessoes.length > 0) {
        return;
      }

      const response = await getSessaoAtiva(
        getEmpresaSelecionada()!.Id,
        getPDV()!.id,
        getPessoa().pessoa?.id ?? ''
      );

      if (response?.resultado?.data?.length > 0) {
        await setSessao(response?.resultado?.data);
        callEvent(AppEventEnum.SessaoPDV, 1);
        switchRedirecionamento();
      }
    };

    getSessaoAtivaOperador();
  }, [
    callEvent,
    getEmpresaSelecionada,
    getPDV,
    getPessoa,
    getSessaoAtiva,
    history,
    setSessao,
    switchRedirecionamento
  ]);


  const sincronizarValores = useCallback(
    async (caixaId: string, valores: SessaoSincronizar[]) => {
      try {
        const respostaPost = await postSincronizarValores(
          getEmpresaSelecionada()!.Id,
          caixaId,
          valores,
        );

        if (respostaPost.erro) {
          throw respostaPost.erro;
        }

      } catch (e: any) {

      }
    },
    [getEmpresaSelecionada, postSincronizarValores],
  );

  const handleClickAbrirCaixa = useCallback(async () => {
    try {
      const sessao = await setSessao(undefined);

      if (!sessao) return;

      const res = await TouchoneDBPrimary.finalizadoras.toArray();

      let pagamento = res.find((pag) => pag.tpMod === 1) as FinalizadoraModel;

      if (!pagamento) {
        const query = '&pageSize=50';
        try {
          const res = await getFinalizadoras(query);
          if (res.erro) throw res.erro;

          const pagamentos = res.resultado?.data?.list ?? [];

          pagamento = pagamentos(
            (pag: FinalizadoraModel) => pag.tpMod === 1
          ) as FinalizadoraModel;

          if (!pagamento) {
            throw new Error(
              'O modelo de pagamento Dinheiro não foi encontrado'
            );
          }
        } catch (e: any) {
          throw new Error('O modelo de pagamento Dinheiro não foi encontrado');
        }
      }

      const sessaoValor = await setValor(
        Number(
          valorInicial.current.length > 0
            ? valorInicial.current.replace(',', '.')
            : '0'
        ),
        EnumTpLancamentoSessaoValor.ABERTURA,
        {
          pagamentoId: pagamento.id,
          descricao: pagamento.descricao,
          tpMod: pagamento.tpMod,
          tpTransacao: pagamento.tpTransacao,

        },
        'Abertura de Caixa',
        undefined,

      );

      iniciarSincronizacaoGeral();

      if (sessaoValor) {
        let valores: SessaoValorModel[] = [sessaoValor]
        const valoresConvertidos = converterDados(valores);
        callEvent(AppEventEnum.SessaoPDV, 1);
        await sincronizarValores(sessaoValor.caixaId, valoresConvertidos)
        switchRedirecionamento();
      }
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [callEvent, converterDados, getFinalizadoras, iniciarSincronizacaoGeral, setSessao, setValor, showToast, sincronizarValores, switchRedirecionamento]);

  const submit = useCallback(async () => {
    handleClickAbrirCaixa();
    return true;
  }, [handleClickAbrirCaixa]);

  const {
    getFormattedText,
    addValue,
    sendText,
    addKey,
    submitKey,
    backSpaceKey
  } = useKeyboard({
    handleTextChanged: textChanged,
    handleSubmit: submit,
    digitado: digitado,
    textoAtual: textoAtual,
    maxLength: 7,
    floatCases: 2,
    isNumeric: true
  });

  const attValueDisplay = useCallback(
    (any: any) => {
      const v = getFormattedText();
      if (valueRef.current) {
        if (v.length === 0) {
          valueRef.current.className = classes.placeholder;
          valueRef.current.textContent = 'Aguardando Digitação';
          return;
        }
        valueRef.current.className = classes.inputText;
        valueRef.current.textContent = v;
      }
    },
    [classes.inputText, classes.placeholder, getFormattedText]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AlterarVersao, attValueDisplay);

    return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay);
  }, [addHandler, attValueDisplay, removeHandler]);

  const handleAddValue = useCallback(
    (value: number) => {
      addValue(value);
    },
    [addValue]
  );

  const handleBackSpace = useCallback(() => {
    backSpaceKey();
  }, [backSpaceKey]);

  const handleText = useCallback(
    (value: string) => {
      sendText(value);
    },
    [sendText]
  );

  const handleAdd = useCallback(() => {
    addKey();
  }, [addKey]);

  const handleSubmit = useCallback(() => {
    submitKey();
  }, [submitKey]);

  const teclado = useMemo(() => {
    return (
      <>
        <Grid container style={{ flex: 1 }}>
          <Keyboard
            handleAdd={handleAdd}
            handleBackSpace={handleBackSpace}
            handleAddValue={handleAddValue}
            handleSubmit={handleSubmit}
            handleText={handleText}
            isNumeric
            isButtonBackspace
            isButtonKeyboard00
          />
        </Grid>
      </>
    );
  }, [handleAdd, handleAddValue, handleBackSpace, handleSubmit, handleText]);

  const buttonsAction = useMemo(() => {
    return (
      <Grid
        container
        direction="column"
        style={{ flexWrap: 'nowrap', paddingBottom: theme.spacing(1) }}
      >
        <Grid
          item
          xs={12}
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
          }}
        >
          <Grid
            container
            xs={12}
            style={{
              marginTop: theme.spacing(1)
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                type="button"
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => history.push('/dashboard')}
                style={{ marginRight: theme.spacing(1) }}
                className="round"
              >
                <CancelarIcon tipo="BUTTON"></CancelarIcon>
                Voltar
              </Button>
            </Grid>

            <Grid container xs={6}>
              <Button
                fullWidth
                size="large"
                type="button"
                variant="contained"
                color="primary"
                onClick={submit}
                style={{ marginLeft: theme.spacing(1) }}
                className="round"
              >
                <OkIcon tipo="BUTTON_PRIMARY"></OkIcon>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [history, submit, theme]);

  const renderKeyboard = () => {
    return (
      <>
        <Grid className={classes.content}>
          <Grid className={classes.inputArea}>
            <Typography className={classes.placeholder} ref={valueRef}>
              (opcional)
            </Typography>
          </Grid>
        </Grid>
        {teclado}
        {buttonsAction}
      </>
    );
  };

  return (
    <>
      {(carregandoSessaoAtiva || carregandoGet) && (
        <CircularLoading tipo="FULLSIZED" />
      )}
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <AbrirCaixaHeader />
        </Grid>
        <Grid className={classes.container}>
          <Grid className={classes.containerTitle}>
            <Typography className={classes.title}>
              Informe o valor em caixa para iniciar
            </Typography>
          </Grid>
          {renderKeyboard()}
        </Grid>
      </Grid>
    </>
  );
};
