import { KeyValueModel } from "model/api";

export const CstIcmsMock: KeyValueModel[] = [
    new KeyValueModel(-1, "-Selecione-"),
    new KeyValueModel(0, "00 - Tributada integralmente"),
    new KeyValueModel(10, "10 - Tributada e com cobrança do ICMS por substituição tributária"),
    new KeyValueModel(20, "20 - Com redução de base de cálculo"),
    new KeyValueModel(30, "30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária"),
    new KeyValueModel(40, "40 - Isenta"),
    new KeyValueModel(41, "41 - Não tributada"),
    new KeyValueModel(50, "50 - Suspensão"),
    new KeyValueModel(51, "51 - Diferimento"),
    new KeyValueModel(60, "60 - ICMS cobrado anteriormente por substituição tributária"),
    new KeyValueModel(70, "70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária"),
    new KeyValueModel(90, "90 - Outras"),
    new KeyValueModel(101, "101 - Tributada pelo Simples Nacional com permissão de crédito"),
    new KeyValueModel(102, "102 - Tributada pelo Simples Nacional sem permissão de crédito"),
    new KeyValueModel(103, "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta"),
    new KeyValueModel(201, "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária"),
    new KeyValueModel(202, "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária"),
    new KeyValueModel(203, "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária"),
    new KeyValueModel(300, "300 - Imune"),
    new KeyValueModel(400, "400 - Não tributada pelo Simples Nacional"),
    new KeyValueModel(500, "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação"),
    new KeyValueModel(900, "900 - Outros"),
];