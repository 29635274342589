import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogPagamentos } from 'views/components/dialog/dialog-pagamentos/dialog-pagamentos';

class FormasDePagamentoProps {
  constructor(
    public open: boolean = false,
    public payments: FinalizadoraModel[] = []
  ) {}
}

export const FormasDePagamentoDialog = () => {
  const { addHandler, removeHandler } = useEventTools();

  const [modalState, setModalState] = useState<FormasDePagamentoProps>(
    new FormasDePagamentoProps()
  );

  const { fecharPagamentosDialog } = useCadastros();

  const modalAlterado = useCallback(({ open, payments }: any) => {
    setModalState({
      open,
      payments
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.DialogFormasDePagamento, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogFormasDePagamento, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.open && (
        <DialogPagamentos
          openned={modalState.open}
          payments={modalState.payments}
          closeModal={fecharPagamentosDialog}
        />
      )}
    </>
  );
};
