import { guidEmpty } from "utils/guid-empty";

export class NcmMasterSummaryModel {
  constructor(
    public id: string = guidEmpty(),
    public codigo: string = "",
    public descricao: string = "",
    public cest: string = "",
  ) {}
}export class NcmMasterCompleteModel {
  constructor(
    public id: string = guidEmpty(),
    public codigo: string = "",
    public descricao: string = "",
    public cest: string = "",
    public situacao: number = 0,
    public tribs: Array<TribNcmModel> = new Array<TribNcmModel>()
  ) {}
}
export class TribNcmModel {
  constructor(
    public id: string = guidEmpty(),
    public ncmId: string = guidEmpty(),
    public dInicial: Date = new Date(),
    public dFinal: Date = new Date(),
    public uf: string = "",
    public pTribNac: number = 0,
    public pTribImp: number = 0,
    public pTribEst: number = 0,
    public pTribMun: number = 0,
    public versao: string = ""
  ) {}
}
