import { Grid } from "@material-ui/core";
import PersonsSaurus from '../../../../../../assets/img/person-6.png';
import { useStyles } from "./landing-item-styles";

const LandingItem02 = () => {
    const classes = useStyles();
    return (
        <>
            <Grid className={classes.container}>
                <div className={classes.imgFlex} style={{
                    overflow: "hidden",
                    width: "100%",
                }}>
                    <div className={classes.imgFixFlex}>
                        <img className={classes.img}
                            height={233}
                            src={PersonsSaurus}
                            alt="img01"
                        // style={{
                        //     marginRight: isMobile ? "-80%" : "0%",
                        //}}
                        />
                    </div>
                </div>
            </Grid>
        </>
    )
}

export default LandingItem02;