import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
    },
    naoEncontrado: {
        "& .card": {
            background: theme.palette.grey[50],
            "& svg": {
                width: 50,
                height: 50,
            },
            "& h4": {
                fontSize: 13,
            },
            "& .cardCelula": {
                margin: 10,
            }
        }
    },
    button: {
        marginBottom: theme.spacing(1)
    }
}));
