import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function useGetModificadoresParaCatalogo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const getModificadoresParaCatalagos = useCallback(
    (queryBusca: string, page?: number) => {
      let queryPage = "";
      if (page) {
        queryPage = "page=" + page.toString();
      }
      let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
        }${queryPage}${queryBusca}`;

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador/catalogo${queryFinal}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [getEmpresaSelecionada, invocarApi]
  );

  return {
    ...outrasPropriedades,
    getModificadoresParaCatalagos,
  };
}
