import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-mesa-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { MesasNovaModel } from 'model/api/gestao/mesa/mesa-nova-model';
import { usePostMesa } from 'data/api/gestao/mesas/post-mesa';
import { FormMesaNova } from 'views/components/form/mesa/form-mesa-nova/form-mesa-nova';
interface DialogAdicionarMesaProps {
  close: () => void;
  carregando?: boolean;
  aberto: boolean;
  salaoId: string;
}

export const DialogAdicionarMesa = ({
  close,
  carregando,
  aberto,
  salaoId,
}: DialogAdicionarMesaProps) => {
  const refInputXProd = useRef<HTMLInputElement>(null);
  const classesComponent = useStyles();

  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postMesa, carregando: carregandoPost } = usePostMesa();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();

  const loading = carregandoPost || carregando;

  useEffect(() => {
    if (aberto && !isMobile) {
      setTimeout(() => {
        refInputXProd.current?.focus();
      }, 50);
    }
  }, [aberto, isMobile]);

  const cadMesasFormRef = useRef<DefaultFormRefs<MesasNovaModel>>(null);
  const redirect = useRef<boolean>(true);

  const saveMesa = useCallback(
    async (model: MesasNovaModel) => {
      try {
        const ret = await postMesa(
          { ...model, status: 1 },
          getEmpresaSelecionada()?.Id || '',
          salaoId,
        );
        if (ret.erro) {
          throw ret.erro;
        }

        showToast('success', 'Mesas criada com sucesso!');

        if (!redirect.current) {
          close();
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadMesasFormRef.current?.fillForm(model);
      }
    },
    [postMesa, getEmpresaSelecionada, salaoId, showToast, close],
  );

  const handleSubmit = useCallback(
    async (model: MesasNovaModel) => {
      return saveMesa(model);
    },
    [saveMesa],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadMesasFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    cadMesasFormRef.current?.resetForm();
  }, []);

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  return (
    <DialogSaurus aberto={aberto || false} titulo="Adicionar Mesa" tamanho="xs">
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Grid className={classesComponent.cardContent}>
          <FormMesaNova
            ref={cadMesasFormRef}
            showLoading={false}
            loading={loading ?? false}
            onSubmit={handleSubmit}
          />
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={5}>
              <Button
                disabled={loading}
                onClick={onCloseClick}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                disabled={loading}
                onClick={() => submitForm(false)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
