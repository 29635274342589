
import { isEmpty } from "lodash";
import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import DocumentoFiscalCadastro from "./components/documento-fiscal-cadastro/documento-fiscal-cadastro";

export const DocumentoFiscalModal = (props: ModalProps) => {

  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && <DocumentoFiscalCadastro />}
    </DefaultModal>
  );
};
