import { isEmpty } from "lodash";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { Box, Button, Card, Typography } from "@material-ui/core";
import { useStyles } from './dialog-trocar-cliente-styles'
import { useCallback, useEffect, useState } from "react";
import { RetornarIcon } from "views/components/icons/retornar-icon";
import { VoltarIcon } from "views/components/icons";
import { ConfirmarIcon } from "views/components/icons/confirmar-icon";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { useGetPessoas } from "data/api/gestao/pessoa";
import { CircularLoading } from "views/components/utils";
import { PedidoClienteModel } from "model/api/gestao/pedido/pedido-cliente-model";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { useToastSaurus } from "services/app";
import { MovSimplesPessoaModel } from "model/api/gestao/movimentacao/simples/mov-simples-pessoa-model";

interface DialogTrocarClienteProps {
    finalizar: () => Promise<void>,
    openned: boolean,
    closeDialog: () => void;
}

export const DialogTrocarCliente = ({ openned, closeDialog, finalizar }: DialogTrocarClienteProps) => {

    const classes = useStyles()

    const { getPessoas, carregando } = useGetPessoas();

    const { setClientePessoa, getMov } = useMovAtual();
    const { showToast } = useToastSaurus();

    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [mov, setMov] = useState<MovSimplesModel>(getMov() || new MovSimplesModel())
    const [cliente, setCliente] = useState<MovSimplesPessoaModel>(
        mov?.cliente || new MovSimplesPessoaModel()
    )

    const trocarCliente = useCallback(async (cliente: PedidoClienteModel) => {
        try {
            const searchParams = new URLSearchParams()
            searchParams.append('Nome', cliente.nomeFantasia)
            if (!isEmpty(cliente.cpfCnpj)) {
                searchParams.append('CPFCNPJ', cliente.cpfCnpj)
            }

            const query = searchParams.toString()

            const res = await getPessoas(query)

            if (res.erro) throw res.erro

            if (res.resultado?.data.list.length > 0) {
                setCliente(res.resultado?.data.list[0])
                await setClientePessoa({ ...res.resultado?.data.list[0], endereco: null })
            } else {
                const novoCliente = new MovSimplesPessoaModel()
                novoCliente.cpfcnpj = cliente.cpfCnpj
                novoCliente.nome = cliente.nomeFantasia
                setCliente(novoCliente)
                await setClientePessoa({ ...novoCliente, endereco: null })
            }

            setMov(getMov()!)

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getMov, getPessoas, setClientePessoa, showToast])

    useEffect(() => {
        /*
            AQUI VERIFICA SE EXISTE APENAS 1 CLIENTE SELECIONADO DENTRE OS PEDIDOS, CASO SIM EU JÁ SETO
            ESTE CLIENTE DIRETO NA MOV  NEM MOSTRO O DIALOG
            (FIZ ESSA VERIFICAÇÃO AQUI PRA NÃO PRECISAR COPIAR DUPLICAR ESSA FUNÇÃO TROCARCLIENTE FORA DO DIALOG).
        */
        const verificar = async () => {
            if (
                getMov()!.cliente?.cpfcnpj === '99999999000191' &&
                getMov()!.informacoesGeraisPedido.pedidos.filter(pedido => pedido.cliente.cpfCnpj !== '99999999000191').length < 2
            ) {
                const clienteQueExiste = getMov()!.informacoesGeraisPedido.pedidos.find(pedido => pedido.cliente.cpfCnpj !== '99999999000191')!.cliente
                await trocarCliente(clienteQueExiste)
                finalizar()
                closeDialog()
            } else {
                setShowDialog(true)
            }
        }

        verificar()
    }, [closeDialog, finalizar, getMov, showDialog, trocarCliente])

    if (!showDialog) return null

    return (
        <DialogSaurus
            aberto={openned || false}
            titulo='Selecionar Cliente'
            tamanho="sm"
        >
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Box display='flex' flexDirection='column' gridGap={8}>
                <Typography>Você possui mais de um cliente em seus pedidos. Deseja trocar o cliente vigente na venda?</Typography>
                <Box display='flex' gridGap={8}>
                    <Box display='flex' gridGap={8} alignItems='center'>
                        <Typography variant='body2' style={{ fontWeight: 500 }}>Cliente Atual: </Typography>

                        <Card className={classes.card}>
                            <div className={classes.cardContent}>
                                <Typography className={classes.moduloNome} variant="body2">
                                    {cliente.cpfcnpj === '99999999000191' ? 'Sem Cliente' : cliente.nome}
                                </Typography>
                            </div>
                        </Card>
                    </Box>
                </Box>
                {mov.informacoesGeraisPedido.pedidos.filter(pedido => (
                    pedido.cliente.nomeFantasia !== 'Consumidor' &&
                    pedido.cliente.nomeFantasia !== mov.cliente?.nome
                )).map(pedido => {
                    return (
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography style={{ fontWeight: 500 }} variant='body2'>{pedido.cliente.nomeFantasia} / {!isEmpty(pedido.cliente.cpfCnpj) && formatarCPFCNPJ(pedido.cliente.cpfCnpj)}</Typography>
                            <Button variant='outlined' size='small' onClick={() => trocarCliente(pedido.cliente)}>
                                <RetornarIcon tipo='BUTTON' />
                                Trocar
                            </Button>
                        </Box>
                    )
                })}
                <Box width='100%' display='flex' justifyContent='flex-end' gridGap={8} mt={1}>
                    <Button variant='outlined' color='primary' size='small' onClick={closeDialog}>
                        <VoltarIcon tipo='BUTTON' />
                        Voltar
                    </Button>
                    <Button variant='contained' color='primary' size='small' onClick={() => {
                        finalizar();
                        closeDialog();
                    }}>
                        <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                        Confirmar
                    </Button>
                </Box>
            </Box>
        </DialogSaurus>
    );
};