import { CardNaoEncontrado } from '../../../../../../components/cards/card-naoencontrado/card-naoencontrado';
import { InformacaoIcon } from '../../../../../../components/icons/informacao-icon';
import { CardProduto } from '../../../../../../components/cards/card-produto/card-produto';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { CardProdutoMobile } from 'views/components/cards/card-produto/card-produto-mobile';
import { useThemeQueries } from 'views';

export interface ProdutoGridProps {
  list: Array<ProdutoResumidoModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
  title: string;
}

export const ProdutoListData = (props: ProdutoGridProps) => {
  const { isMobile } = useThemeQueries();
  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem={`Nenhum ${props.title} encontrado.`}
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((produto, index)  => {
          return (
            <>
              {isMobile ? (
                <CardProdutoMobile
                  selected={
                    props.selectedList.filter(
                      (item) => item === produto.produtoId,
                    ).length > 0
                      ? true
                      : false
                  }
                  onCheck={onCardChecked}
                  onClick={onCardSelected}
                  model={produto}
                  key={produto.id}
                />
              ) : (
                <CardProduto
                  selected={
                    props.selectedList.filter(
                      (item) => item === produto.produtoId,
                    ).length > 0
                      ? true
                      : false
                  }
                  onCheck={onCardChecked}
                  onClick={onCardSelected}
                  model={produto}
                  key={produto.id}
                />
              )}
            </>
          );
        })}
    </>
  );
};
