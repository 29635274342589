import { useCallback } from 'react';
import * as Yup from 'yup';

export const useValidationYupRazaoSocial = () => {
    const razaoSocialYup = useCallback((validationText?: string) => {
        return (
            Yup.string().trim().required(validationText ? validationText : 'A razão social é obrigatória.').test('is-valid', 'Deve conter caracteres válidos', (value) => {
                if (value) {
                    if (/\s/g.test(value) && !(/[a-zA-z]/g.test(value))) {
                        return false
                    }

                    return true
                }
                return false
            })
        )
    }, [])

    return {
        razaoSocialYup
    }
}
