import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useStyles } from './identificacao-fragment-styles';
import { useToastSaurus } from 'services/app';
import { CircularLoading } from 'views';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { Paginacao } from 'views/components/paginacao';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CustumerListData } from '../custumer-list-data/custumer-list-data';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { IdentificacaoClienteHeader } from '../identificacao-cliente-header/identificacao-cliente-header';
import { Controller, useForm } from 'react-hook-form';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useClienteIdentificacao } from './components/use-cliente-identificacao';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { Stepper } from 'views/components/stepper';
import classNames from 'classnames';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumTelaIdentificacao } from 'model/enums/enum-aba-tela-identificacao';

interface IdentificacaoClienteProps {
  redirecionarVenda: () => void;
  loading?: boolean
}

export function IdentificacaoCliente({
  redirecionarVenda,
  loading
}: IdentificacaoClienteProps) {
  //STATES E REFS
  const [searchCustumer, setSearchCustumer] = useState('');
  const [buttonVoltar, setButtonVoltar] = useState<boolean>(false);
  const { getConfigByCod } = useEmpresaAtual()
  const { getConfigByCod: getConfigPDV } = usePDV()
  const modeloTrabalho = getConfigPDV(EnumPDVConfigCod.ModeloTrabalho)
  const solicitarClientePedido = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClientePedido)
  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClienteVenda)

  const soliClientesPorModeloTrabalho = useCallback(() => {
    if (modeloTrabalho === '2') {
      return Number(solicitarClientePedido)
    }

    return Number(solicitarClienteVenda)
  }, [modeloTrabalho, solicitarClientePedido, solicitarClienteVenda])

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PessoaModel>()
  });
  const timer = useRef({} as NodeJS.Timeout);
  const ultimoTermoPesquisado = useRef('');
  const textFieldPesquisa = useRef<HTMLInputElement>(null)
  const { showToast } = useToastSaurus();
  const stepperClasses = useStepperStyles();

  //PROVIDERS
  const { setClientePessoa, carregando: carregandoVenda } = useMovAtual();
  const { carregando: carregandoStepper, formArray, formStepper } = useClienteIdentificacao({ redirecionarVenda })
  const theme = useTheme();

  //AUX E OUTROS
  const classesDefault = useDefaultCadastroStyles();
  const classes = useStyles()


  // const isFiscal = isPlanoFiscal(plano?.plano);
  const { control, setValue, getValues } = useForm<{ generico: string }>({
    mode: 'onChange'
  });

  const carregando = [carregandoVenda, carregandoStepper, loading].includes(true);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoaModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const handleBuscarCadastro = useCallback(() => {
    setButtonVoltar(!buttonVoltar);
  }, [buttonVoltar]);

  const onCardSelected = async (cliente: any) => {
    try {
      await setClientePessoa(cliente);
      showToast('success', `Cliente ${cliente.nome} adicionado`);
      redirecionarVenda();
    } catch (err: any) {
      showToast('error', err.message);
    }
  };

  const search = useCallback(
    async (newPage: number) => {
      try {
        const res = await TouchoneDBPrimary.clientes
          .filter((cliente) => {
            if (
              (cliente.nome &&
                cliente.nome
                  .toLowerCase()
                  .includes(searchCustumer.toLowerCase())) ||
              (cliente.cpfcnpj &&
                cliente.cpfcnpj
                  .toLowerCase()
                  .includes(searchCustumer.toLowerCase()))
            ) {
              return true;
            }
            return false;
          })
          .toArray();

        fillResult(1, 1, res.length, res as PessoaModel[]);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [showToast, fillResult, searchCustumer]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  useEffect(() => {
    clearTimeout(timer.current);
    if (searchCustumer.length > 0) {
      if (
        ultimoTermoPesquisado.current !== searchCustumer ||
        searchCustumer.length === 0
      ) {
        timer.current = setTimeout(() => {
          search(queryStatus.page);
        }, 500);
      }
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [queryStatus.page, search, searchCustumer]);

  useEffect(() => {
    if (buttonVoltar) {
      setTimeout(() => {
        if (textFieldPesquisa) {
          textFieldPesquisa.current?.focus()
        }
      }, 100)
    }
  }, [buttonVoltar])

  const renderSearchCustumer = () => {
    return (
      <>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid>
          <Typography className={classes.buscaCadastrados}>
            Buscar Cadastrados
          </Typography>

          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(1)
              }}
            >
              <Controller
                name="generico"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    fullWidth
                    size="small"
                    inputRef={textFieldPesquisa}
                    allowSubmit={true}
                    placeholder="Digite o nome ou documento do cliente"
                    searchable
                    adornmentColor={theme.palette.text.disabled}
                    {...field}
                    onChange={(event) => {
                      setValue('generico', event.target.value);
                      setSearchCustumer(getValues('generico'));
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Paginacao
              pageChanged={pageChanged}
              totalPages={queryStatus.totalPages}
              totalRegisters={queryStatus.totalResults}
              currentPage={queryStatus.page}
            />
            <Grid item xs={12} className={classes.listContainer}>
              <CustumerListData
                carregando={!!carregando}
                list={queryStatus.list}
                onCardSelected={onCardSelected}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep]?.previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}
            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={!formArray[formStepper.currentStep]?.previousButton ? 12 : 6}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, carregando, formArray, formStepper.currentStep]);

  const renderIdentificarClienteEtapas = () => {

    const steps = [
      EnumTelaIdentificacao.CpfCnpj,
      EnumTelaIdentificacao.Telefone,
      EnumTelaIdentificacao.Nome,
      EnumTelaIdentificacao.DataNascimento,
      EnumTelaIdentificacao.Sexo
    ].filter(step => Boolean(step & soliClientesPorModeloTrabalho()))

    return (
      <div className={classes.content}>
        <div className={classNames(classes.contentForms)}>
          {steps.length > 1 && (
            <div className={(stepperClasses.stepper, classes.stepper)}>
              <Stepper
                linear={true}
                steps={formArray}
                stepAtual={formStepper.currentStep}
              />
            </div>
          )}
          <Box display="flex" height="100%">
            <div className={classNames(stepperClasses.stepFix)}>
              <div
                className={classNames(
                  stepperClasses.step,
                  stepperClasses.stepContent,
                  classes.step
                )}
              >
                <div className={classNames(stepperClasses.stepForm, classes.stepForm)}>
                  {formArray[formStepper.currentStep].form}
                </div>
              </div>
            </div>
          </Box>
        </div>
        <div className={classes.acoes}>
          {getButtons}
        </div>
      </div>
    )
  }

  const header = useMemo(() => {
    return (
      <Grid className={classesDefault.header}>
        <IdentificacaoClienteHeader
          showButtonVoltar={buttonVoltar}
          handleClickVoltar={setButtonVoltar}
          handleClickBuscar={handleBuscarCadastro}
          handleCustomClickVoltar={formStepper.currentStep > 0 ? () => {
            formStepper.prevStep()
          } : undefined}
        />
      </Grid>
    );
  }, [buttonVoltar, classesDefault.header, handleBuscarCadastro, formStepper]);

  return (
    <>
      <Grid className={classesDefault.root}>
        {header}
        <Grid
          style={{
            flex: '1 1 100%',
            minHeight: '451px',
            overflow: 'auto',
            position: 'relative'
          }}
        >
          <Grid container direction="column" className={classes.container}>
            {buttonVoltar
              ? renderSearchCustumer()
              : renderIdentificarClienteEtapas()}
          </Grid>
        </Grid>
        {/* {!isFiscal && (
          <Grid style={{ background: '#fff' }}>
            <CardControleCotaVendas />
          </Grid>
        )} */}
      </Grid>
    </>
  );
}
