import { isEmpty } from 'lodash';
import { DefaultModal } from '../components/default-modal/default-modal';
import { ModalProps } from '../utils/modal-props';
import { Pagamento } from './pagamento-modal/pagamento/pagamento';
import { PagsModel } from 'model/api/gestao/venda/venda-completa-model';

export interface DadosDoPagamentoModalProps extends ModalProps {
  pagCb?: {
    valor: number,
    callback: (pag: PagsModel) => Promise<boolean>
  }
}

export const DadosDoPagamentoModal = (props: DadosDoPagamentoModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={'temporary'}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && <Pagamento pagCb={props.pagCb} />}
    </DefaultModal>
  );
};
