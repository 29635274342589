import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

export const ModulosRetaguardaMock: MenuModel[] = [
  new MenuModel('Vender', '', EnumMenu.OPERACIONAL_FORAVENDA_INICIAR_VENDA, [], '/venda-simples/landing?verificarCadastros=true', 0, EnumMenu.RELATORIOS_GROUP, EnumMenu.RETAGUARDA),

  new MenuModel('Cadastros', '', EnumMenu.CADASTRO_GROUP, [], '', 7, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Relatórios', '', EnumMenu.RELATORIOS_GROUP, [], '', 8, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Entradas', '', EnumMenu.ENTRADA_GROUP, [], '', 9, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Salão', '', EnumMenu.SALAO_GROUP, [EnumCodigosPermissoes.REALIZAR_PEDIDOS], '', 10, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Administrativo', '', EnumMenu.ADMINISTRATIVO_GROUP, [], '', 11, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Fiscal', '', EnumMenu.FISCAL_GROUP, [], '', 12, 0, EnumMenu.RETAGUARDA),
  new MenuModel('Suporte Especializado', '', EnumMenu.SUPORTE_GROUP, [], '', 13, 0, EnumMenu.RETAGUARDA),

  new MenuModel('Produtos', '', EnumMenu.CADASTRO_PRODUTOS, [EnumCodigosPermissoes.PRODUTOS], '/produtos', 1, EnumMenu.CADASTRO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Pessoas', '', EnumMenu.CADASTRO_PESSOAS, [EnumCodigosPermissoes.PESSOAS, EnumCodigosPermissoes.FUNCIONARIOS, EnumCodigosPermissoes.FORNECEDORES, EnumCodigosPermissoes.REPRESENTANTES, EnumCodigosPermissoes.TRANSPORTADORAS], '/pessoas', 2, EnumMenu.CADASTRO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Formas de Pagamento', '', EnumMenu.CADASTRO_FINALIZADORA, [EnumCodigosPermissoes.FINALIZADORAS], '/formas-de-pagamento', 3, EnumMenu.CADASTRO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Outros Cadastros', '', EnumMenu.CADASTRO_OUTROS, [], '/outros-cadastros', 4, EnumMenu.CADASTRO_GROUP, EnumMenu.RETAGUARDA),

  //RELATORIOS
  new MenuModel('Dashboard', '', EnumMenu.RELATORIOS_DASHBOARD, [EnumCodigosPermissoes.RELATORIOS], '/dashboard', 1, EnumMenu.RELATORIOS_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Histórico de Vendas', '', EnumMenu.RELATORIOS_HISTORICO_VENDAS, [EnumCodigosPermissoes.HISTORICO_VENDAS], '/historico', 1, EnumMenu.RELATORIOS_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Relatório de Renderizações', '', EnumMenu.RELATORIOS_PERFORMANCE, [EnumCodigosPermissoes.RELATORIOS], '/relatorio-performance', 1, EnumMenu.RELATORIOS_GROUP, EnumMenu.RETAGUARDA),

  //SALAO
  new MenuModel('Mesas e Comandas', '', EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS, [], '/gerenciar/mesas', 4, EnumMenu.SALAO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Salões', '', EnumMenu.ADMINISTRATIVO_SALOES, [], '/saloes', 5, EnumMenu.SALAO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Meu Cardápio', '', EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO, [], '', 6, EnumMenu.SALAO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Setor e Processos', '', EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS, [], '/setor/setores', 6, EnumMenu.SALAO_GROUP, EnumMenu.RETAGUARDA),

  //ENTRADAS
  new MenuModel('Manifestos', '', EnumMenu.RELATORIOS_MANIFESTOS, [EnumCodigosPermissoes.ENTRADA_MERCADORIA], '/manifestos', 3, EnumMenu.ENTRADA_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Entrada de Mercadoria', '', EnumMenu.RELATORIOS_ENTRADA_MERCADORIA, [EnumCodigosPermissoes.ENTRADA_MERCADORIA], '/entrada-mercadoria', 2, EnumMenu.ENTRADA_GROUP, EnumMenu.RETAGUARDA),

  //ADMINISTRATIVO
  new MenuModel('Gerenciamento de Sessões','',EnumMenu.ADMINISTRATIVO_GERENCIAMENTO_DE_SESSOES,[],'/gerenciamento-sessoes',0,EnumMenu.ADMINISTRATIVO_GROUP,EnumMenu.RETAGUARDA),
  new MenuModel('Acessos e Permissões', '', EnumMenu.ADMINISTRATIVO_ACESSOS_PERMISSOES, [], '/permissoes/perfil', 3, EnumMenu.ADMINISTRATIVO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Crachá de Autenticação', '', EnumMenu.ADMINISTRATIVO_AUTENTICACAO, [], '/autenticacao', 6, EnumMenu.ADMINISTRATIVO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Impressoras', '', EnumMenu.ADMINISTRATIVO_IMPRESSORAS, [5003], '/impressoras', 1, EnumMenu.ADMINISTRATIVO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Pontos de Venda', '', EnumMenu.ADMINISTRATIVO_PONTOS_VENDAS, [], '/pontos-de-venda', 2, EnumMenu.ADMINISTRATIVO_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Configurações', '', EnumMenu.ADMINISTRATIVO_CONFIGURACOES, [EnumCodigosPermissoes.CONFIGURACOES], '/configuracoes', 6, EnumMenu.ADMINISTRATIVO_GROUP, EnumMenu.RETAGUARDA),

  // FISCAL
  new MenuModel('Revisar Dados NFC-e', '', EnumMenu.FISCAL_ATIVAR_REVISAR_DADOS_NFCE, [EnumCodigosPermissoes.CONFIGURACOES], '/ativar-revisar-nfc-e', 1, EnumMenu.FISCAL_GROUP, EnumMenu.RETAGUARDA),
  new MenuModel('Retirada de XMLs', '', EnumMenu.FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS, [EnumCodigosPermissoes.CONFIGURACOES], '/documentos', 2, EnumMenu.FISCAL_GROUP, EnumMenu.RETAGUARDA),

  // SUPORTE
  new MenuModel('Suporte Técnico', '', EnumMenu.SUPORTE_CHAT, [], '', 1, EnumMenu.SUPORTE_GROUP, EnumMenu.RETAGUARDA)
];
