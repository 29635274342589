import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutRevogarAcessoSuporte() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putRevogarAcessoSuporte = useCallback(
    () =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/usuario/meu-usuario/revogar-acesso-suporte`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putRevogarAcessoSuporte
  };
}
