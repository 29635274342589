import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%'
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    numeroPedidos: {
        backgroundColor: theme.palette.primary.main,
        height: 35,
        width: 35,
        color: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "50%",
        '& p': {
            fontWeight: 600
        }
    }
}))