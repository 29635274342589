export const PlanoCorMock = [
    {
        id: "21306e44-365c-4401-816d-c6201267b268",
        cor: "#ad7a53",
    },
    {
        id: "48fd87dc-1f1f-434c-bd44-d0a908993809",
        cor: "#909090",
    },
    {
        id: "c1e01a08-24d4-4707-84c0-557ccab1af7e",
        cor: "#c2ab6c",
    },
];
