import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btn: {
        height: '100%',
        minHeight: 155,
        background: '#FFF',
        padding: theme.spacing(0.8, 1.2),
        boxShadow: '0px 2.5px 4px 0px rgba(40, 41, 61, 0.25)',
    },
    icon: {
        height: 45,
        width: 45,
        margin: 0,
    },
    title: {
        fontSize: 12,
        fontWeight: 700
    },
    buttonContent:{
        display:'flex', 
        height:118
    },
    iconContainer:{
        display:'flex',
        height:'60%'
    },
    titleContainer:{
        display:'flex', 
        height:'40%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: theme.spacing(1)
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    loadingContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: 32,
        height: '100%'
    },
    container:{
        width:'100%'
    }

}))