import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { useFormConfigNfceValidation } from './form-configuracoes-nfce-validation';
import { makeUtilClasses, useThemeQueries } from 'views';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import { ConfigNfceFormModel } from '../../../../../model/app/forms/master/configuracoes-nfce-form-model';
import ArquivoInput from 'views/components/controles/inputs/arquivo-input/arquivo-input';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpAmbMock } from 'data/mocks/tp-amb-mock';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToastSaurus } from 'services/app';

export interface DefaultFormProps<T> {
  loading: boolean;
  showLoading: boolean;
  onSubmit(model: T, beforeModel?: T | null, permissoes?: T | null): void;
}

export const FormConfigNfce = forwardRef<
  DefaultFormRefs<ConfigNfceFormModel>,
  DefaultFormProps<ConfigNfceFormModel>
>(({ loading, ...props }: DefaultFormProps<ConfigNfceFormModel>, ref) => {
  // STATES E REFS
  const refInputConfigNfce = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<ConfigNfceFormModel>(
    new ConfigNfceFormModel(),
  );
  const [valuePfx, setValuePfx] = useState<any>();

  // AUX
  const utilClasses = makeUtilClasses();
  const { FormConfigNfceValidationYup } = useFormConfigNfceValidation();
  const { isMobile } = useThemeQueries();
  const {showToast} = useToastSaurus()
  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    getFieldState,
    reset,
  } = useForm<ConfigNfceFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormConfigNfceValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ConfigNfceFormModel) => {
    const model = picker<ConfigNfceFormModel>(
      values,
      new ConfigNfceFormModel(),
    );
    props.onSubmit(model, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: (verificarToast = false) => {
      if(verificarToast) {
        const arquivoCertificadoDigital = getFieldState('pfxBase64')
        if(arquivoCertificadoDigital.invalid){
         showToast('error','Preencha o formulário ou clique no botão "Configurar mais tarde"')
        }
      }
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) {
        refInputConfigNfce.current?.focus();
      }
    },
    fillForm: (model: ConfigNfceFormModel) => {
      setModelForm(model);
      reset({ ...model });
      if (!isMobile) {
        refInputConfigNfce.current?.focus();
      }
    },
  }));

  const index = getValues('pfxBase64').indexOf(
    'https://saurus.blob.core.windows',
  );

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Controller
                  name="cIdToken"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="NUMERO"
                      disabled={loading}
                      id="cIdToken"
                      label="Id do CSC"
                      fullWidth={true}
                      error={Boolean(
                        errors.cIdToken && errors.cIdToken.message
                      )}
                      helperText={
                        touchedFields.cIdToken || errors.cIdToken
                          ? errors.cIdToken?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  name="csc"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      tipo="TEXTO"
                      id="csc"
                      label="Código de Segurança do Contribuinte (CSC)"
                      fullWidth={true}
                      error={Boolean(errors.csc && errors.csc.message)}
                      helperText={
                        touchedFields.csc || errors.csc
                          ? errors.csc?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="caption">
                  Certificado Digital(.pfx)
                </Typography>
                <Controller
                  name="pfxBase64"
                  control={control}
                  render={({ field }) => (
                    <ArquivoInput
                      disabled={loading}
                      nameArquivo={getValues('pfxBase64')}
                      error={Boolean(
                        (errors.pfxBase64 && errors.pfxBase64.message) ||
                          valuePfx === undefined
                      )}
                      helperText={
                        touchedFields.pfxBase64 || errors.pfxBase64
                          ? errors.pfxBase64?.message
                          : valuePfx === undefined && index === 0
                          ? 'Precisa subir o arquivo para poder salvar'
                          : undefined
                      }
                      {...field}
                      onChange={({ base64 }: any) => {
                        setValuePfx(base64);
                        setValue('pfxBase64', base64);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      tipo="PASSWORD"
                      id="senha"
                      label="Senha do Certificado Digital"
                      fullWidth={true}
                      error={Boolean(errors.senha && errors.senha.message)}
                      helperText={
                        touchedFields.senha || errors.senha
                          ? errors.senha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="tpAmb"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      style={{ minWidth: 300 }}
                      disabled={loading}
                      conteudo={TpAmbMock}
                      fullWidth
                      variant="outlined"
                      label={'Ambiente de emissão de NFC-e'}
                      {...field}
                      onChange={(event) => {
                        if (event) {
                          setValue(
                            'tpAmb',
                            TpAmbMock.filter(
                              (item) => item.Key === event.target.value
                            )[0]?.Key
                          );
                        }
                      }}
                      value={getValues('tpAmb')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      autoComplete="off"
                      tipo="CNPJ"
                      fullWidth
                      label="CNPJ"
                      placeholder="CNPJ da Software House"
                      error={Boolean(errors.cnpj && errors.cnpj.message)}
                      helperText={
                        touchedFields.cnpj || errors.cnpj
                          ? errors.cnpj?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="EMAIL"
                      disabled={loading}
                      fullWidth={true}
                      showStartAdornment={false}
                      allowSubmit={false}
                      autoComplete={'off'}
                      label="E-mail"
                      placeholder="E-mail do responsável"
                      error={Boolean(errors.email && errors.email.message)}
                      helperText={
                        touchedFields.email || errors.email
                          ? errors.email?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="fone"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      tipo="TELEFONE"
                      fullWidth
                      autoComplete="off"
                      label="Telefone"
                      placeholder="Telefone da Software House"
                      error={Boolean(errors.fone && errors.fone.message)}
                      helperText={
                        touchedFields.fone || errors.fone
                          ? errors.fone?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="xContato"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading}
                      fullWidth
                      tipo="TEXTO"
                      autoComplete="off"
                      label="Contato"
                      placeholder="Nome do responsável"
                      error={Boolean(
                        errors.xContato && errors.xContato.message
                      )}
                      helperText={
                        touchedFields.xContato || errors.xContato
                          ? errors.xContato?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
              <Controller
                name="idCSRT"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={loading}
                    fullWidth={true}
                    autoComplete='off'
                    showStartAdornment={false}
                    allowSubmit={false}
                    label="Id do Código"
                    placeholder=""
                    error={Boolean(errors.idCSRT && errors.idCSRT.message)}
                    helperText={
                      touchedFields.idCSRT || errors.idCSRT
                        ? errors.idCSRT?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="hashCSRT"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="NUMERO"
                    disabled={loading}
                    fullWidth={true}
                    autoComplete='off'
                    showStartAdornment={false}
                    allowSubmit={false}
                    label="Código de Segurança"
                    placeholder=""
                    error={Boolean(errors.hashCSRT && errors.hashCSRT.message)}
                    helperText={
                      touchedFields.hashCSRT || errors.hashCSRT
                        ? errors.hashCSRT?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid> */}
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
