import { Button, Grid } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { EnumPagTpTransacao } from "model";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMovAtual } from "services/app/hooks/mov-atual";
import { PagamentosListData } from "views/components/dialog/dialog-historico-pagamento/pagamentos-list-data/pagamentos-list-data";
import { AvancarIcon, SATIcon } from "views/components/icons";
import { MovHistoricoPagamentoHeader } from "./components/historico-pagamento-header";
import { useStyles } from './mov-historico-pagamento-styles'

const MovHistoricoPagamentoPage = () => {

    const classes = useStyles()

    const { getMov, refazerPagamento } = useMovAtual()
    const { push } = useHistory()
    const confirm = useConfirm()

    const confirmar = useCallback(() => {
        confirm({
            title: "Refazer Pagamentos",
            description: "Isso irá resetar todos os pagamentos não integrados, deseja continuar?",
            cancellationText: "Cancelar",
            confirmationText: "Refazer",
            confirmationButtonProps: {
                variant: "contained",
                color: 'primary',
                className: 'round'
            },
            cancellationButtonProps: {
                className: 'round'
            }
        }).then(() => refazerPagamento())
    }, [confirm, refazerPagamento])

    const disabledRefazer = useCallback(() => {
        if (getMov()!.pags.length < 1) return true

        if (
            getMov()!.pags
                .filter(i => i.tpTransacao === EnumPagTpTransacao.NORMAL).length < 1
        ) return true

        return false
    }, [getMov])

    return (
        <Grid className={classes.container}>
            <Grid className={classes.header}>
                <MovHistoricoPagamentoHeader />
            </Grid>
            <Grid className={classes.content}>
                <Grid container>
                    <Grid item xs={12}>
                        <PagamentosListData list={getMov()?.pags} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{ padding: 8 }} xs={5}>
                    <Button size="large" variant="contained" color="primary" disabled={disabledRefazer()} onClick={confirmar} className="round" fullWidth>
                        <SATIcon tipo="BUTTON_PRIMARY" />
                        Refazer
                    </Button>
                </Grid>
                <Grid item xs={7} style={{ padding: 8 }}>
                    <Button size="large" variant="contained" color="primary" disabled={getMov()!.vPago < getMov()!.vNF} onClick={() => push('/venda-simples/identificar-cliente')} className="round" fullWidth>
                        Continuar <AvancarIcon tipo="BUTTON_PRIMARY" />
                    </Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MovHistoricoPagamentoPage;