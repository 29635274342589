import { KeyValueModel } from "model/api";

export const CstPisCofinsMock: KeyValueModel[] = [
    new KeyValueModel(-1, '-Selecione-'),
    new KeyValueModel(1, '01 - Operação Tributável com Alíquota Básica'),
    new KeyValueModel(2, '02 - Operação Tributável com Alíquota Diferenciada'),
    new KeyValueModel(3, '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto'),
    new KeyValueModel(4, '04 - Operação Tributável Monofásica – Revenda a Alíquota Zero'),
    new KeyValueModel(5, '05 - Operação Tributável por Substituição Tributária'),
    new KeyValueModel(6, '06 - Operação Tributável a Alíquota Zero'),
    new KeyValueModel(7, '07 - Operação Isenta da Contribuição'),
    new KeyValueModel(8, '08 - Operação sem Incidência da Contribuição'),
    new KeyValueModel(9, '09 - Operação com Suspensão da Contribuição'),
    new KeyValueModel(49, '49 - Outras Operações de Saída'),
    new KeyValueModel(50, '50 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Tributada no Mercado Interno'),
    new KeyValueModel(51, '51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'),
    new KeyValueModel(52, '52 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação'),
    new KeyValueModel(53, '53 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'),
    new KeyValueModel(54, '54 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'),
    new KeyValueModel(55, '55 - Operação com Direito a Crédito – Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'),
    new KeyValueModel(56, '56 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'),
    new KeyValueModel(60, '60 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'),
    new KeyValueModel(61, '61 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'),
    new KeyValueModel(62, '62 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação'),
    new KeyValueModel(63, '63 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'),
    new KeyValueModel(64, '64 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'),
    new KeyValueModel(65, '65 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'),
    new KeyValueModel(66, '66 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação'),
    new KeyValueModel(67, '67 - Crédito Presumido – Outras Operações'),
    new KeyValueModel(70, '70 - Operação de Aquisição sem Direito a Crédito'),
    new KeyValueModel(71, '71 - Operação de Aquisição com Isenção'),
    new KeyValueModel(72, '72 - Operação de Aquisição com Suspensão'),
    new KeyValueModel(73, '73 - Operação de Aquisição a Alíquota Zero'),
    new KeyValueModel(74, '74 - Operação de Aquisição sem Incidência da Contribuição'),
    new KeyValueModel(75, '75 - Operação de Aquisição por Substituição Tributária'),
    new KeyValueModel(98, '98 - Outras Operações de Entrada'),
    new KeyValueModel(99, '99 - Outras Operações')
];