import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const LeituraCaixaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 0.8125 0 C 0.335938 0.0898438 -0.0078125 0.511719 0 1 L 0 13 C 0 13.550781 0.449219 14 1 14 L 13 14 C 13.550781 14 14 13.550781 14 13 L 14 1 C 14 0.449219 13.550781 0 13 0 L 1 0 C 0.96875 0 0.9375 0 0.90625 0 C 0.875 0 0.84375 0 0.8125 0 Z M 2 2 L 12 2 L 12 12 L 2 12 Z M 10.25 3.34375 L 6.40625 7.625 L 3.65625 5.25 L 2.34375 6.75 L 5.84375 9.75 L 6.59375 10.40625 L 7.25 9.65625 L 11.75 4.65625 Z M 20 6.03125 L 20 7.96875 L 50 7.96875 L 50 6.03125 Z M 0.8125 18 C 0.335938 18.089844 -0.0078125 18.511719 0 19 L 0 31 C 0 31.550781 0.449219 32 1 32 L 13 32 C 13.550781 32 14 31.550781 14 31 L 14 19 C 14 18.449219 13.550781 18 13 18 L 1 18 C 0.96875 18 0.9375 18 0.90625 18 C 0.875 18 0.84375 18 0.8125 18 Z M 2 20 L 12 20 L 12 30 L 2 30 Z M 10.25 21.34375 L 6.40625 25.625 L 3.65625 23.25 L 2.34375 24.75 L 5.84375 27.75 L 6.59375 28.40625 L 7.25 27.65625 L 11.75 22.65625 Z M 20 24.03125 L 20 25.96875 L 50 25.96875 L 50 24.03125 Z M 0.8125 36 C 0.335938 36.089844 -0.0078125 36.511719 0 37 L 0 49 C 0 49.550781 0.449219 50 1 50 L 13 50 C 13.550781 50 14 49.550781 14 49 L 14 37 C 14 36.449219 13.550781 36 13 36 L 1 36 C 0.96875 36 0.9375 36 0.90625 36 C 0.875 36 0.84375 36 0.8125 36 Z M 2 38 L 12 38 L 12 48 L 2 48 Z M 20 42.03125 L 20 43.96875 L 50 43.96875 L 50 42.03125 Z"></path>
      </DefaultIcon>
    </>
  );
};
