import { Button, Grid } from '@material-ui/core';
import { useStyles } from './dashboard-admin-content-styles';
import {
  CardDashboard,
  CardDashboardAdminChartBar,
  CardDashboardAdminChartCategorias,
  CardDashboardAdminChartDonut,
  CardDashboardAdminChartHeatMap,
  CardDashboardAdminChartLine,
  CardDashboardAdminChartLucros,
  CardDashboardAdminChartVendas,
  CardDashboardAdminChartVendedores
} from 'views/components/cards/card-dashboard/';
import {
  // useGetDashboardAdminPagamentos,
  // useGetDashboardAdminCategorias,
  // useGetDashboardAdminVendas,
  // useGetDashboardAdminVendedores
} from 'data/api/gestao/relatorios/dashboard-admin/';
import {
  DashboardAdminCategoriasModel,
  DashboardAdminPagamentosModel,
  DashboardAdminVendasModel,
  DashboardAdminVendedoresModel
} from 'model/api/gestao/dashboard-admin';
import {
  DinheiroIcon,
  PessoasIcon,
  TicketIcon,
  LucroIcon,
  TotalVendidoIcon,
  QuantidadeItensIcon,
  ProcurarIcon,
} from 'views/components/icons';
import { useToastSaurus } from 'services/app';
import { useCallback, useEffect, useState } from 'react';
import { CircularLoading } from 'views';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PorDiaMock, PorMesMock } from 'data/mocks/ultimos-mock';
import { PeriodoDashboardMock } from 'data/mocks/periodo-dashboard-mock';
import { stringNumeros } from 'utils/string-numeros';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';

interface Props {
  titulo?: (titulo: string) => void;
  pesquisa: string;
  removeTermo: boolean;
}

export const DashboardAdminContent = ({ titulo, pesquisa, removeTermo }: Props) => {
  const { showToast } = useToastSaurus();
  const classes = useStyles();

  const [dataPagamentos, setDataPagamentos] = useState<Array<DashboardAdminPagamentosModel>>([
    new DashboardAdminPagamentosModel()
  ]);
  const [dataVendas, setDataVendas] = useState<Array<DashboardAdminVendasModel>>([
    new DashboardAdminVendasModel(),
  ]);
  const [dataVendedores, setDataVendedores] = useState<Array<DashboardAdminVendedoresModel>>([
    new DashboardAdminVendedoresModel(),
  ]);
  const [dataCategorias, setDataCategorias] = useState<Array<DashboardAdminCategoriasModel>>([
    new DashboardAdminCategoriasModel(),
  ]);

  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true);
  const [periodoPesquisar, setPeriodoPesquisar] = useState<Number>(0);
  const [mudarPeriodos, setMudarPeriodos] = useState<Number>();
  // const [dataFinalSelect, setDataFinalSelect] = useState<any>(new Date());
  // const [dataInicialSelect, setDataInicialSelect] = useState<any>(
  //   new Date().setDate(new Date().getDate() - 7),
  // );
  const { addHandler, removeHandler } = useEventTools()
  const [ocultarDatas, setOcultarDatas] = useState<boolean>(false);
  const [termo, setTermo] = useState('')
  const [modalEditAberto, setModalEditAberto] = useState(false);
  // const { getDashboardAdminPagamentos, carregando: carregandoPagamentos
  // } = useGetDashboardAdminPagamentos();
  // const { getDashboardAdminVendas, carregando: carregandoVendas } =
  //   useGetDashboardAdminVendas();
  // const { getDashboardAdminCategorias, carregando: carregandoCategorias } =
  //   useGetDashboardAdminCategorias();
  // const { getDashboardAdminVendedores, carregando: carregandoVendedores } =
  //   useGetDashboardAdminVendedores();

  const carregando =
    // carregandoPagamentos ||
    // carregandoVendas ||
    // carregandoCategorias ||
    // carregandoVendedores ||
    preenchendoTela;

  const dataFormatada = useCallback((val) => {
    const data = new Date(val);
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    return `${ano}-${mes.toString().length === 1 ? '0' + mes : mes}-${dia.toString().length === 1 ? '0' + dia : dia
      }`;
  }, []);

  // const query =
  //   '?DInicial=' +
  //   dataFormatada(dataInicialSelect) +
  //   '&DFinal=' +
  //   dataFormatada(dataFinalSelect);

  let periodos = PorDiaMock;

  switch (periodoPesquisar) {
    case 0:
      periodos = PorDiaMock;
      break;
    case 1:
      periodos = PorMesMock;
      break;
  }

  const queryPesquisaPeriodos = useCallback(() => {
    if (periodos === PorDiaMock) {
      if (mudarPeriodos === 3) {
        setOcultarDatas(true);
      } else {
        setOcultarDatas(false);
      }
    }
    if (periodos === PorMesMock) {
      if (mudarPeriodos === 3) {
        setOcultarDatas(true);
      } else {
        setOcultarDatas(false);
      }
    }
  }, [mudarPeriodos, periodos]);

  const queryPesquisa = useCallback(() => {
    let data = new Date();
    if (periodos === PorDiaMock) {
      if (mudarPeriodos === 0) {
        data.setDate(data.getDate());
        // setDataInicialSelect(dataFormatada(data));
      }
      if (mudarPeriodos === 1) {
        data.setDate(data.getDate() - 7);
        // setDataInicialSelect(dataFormatada(data));
      }
      if (mudarPeriodos === 2) {
        data.setDate(data.getDate() - 14);
        // setDataInicialSelect(dataFormatada(data));
      }
    }
    if (periodos === PorMesMock) {
      if (mudarPeriodos === 0) {
        data.setDate(data.getDate());
        // let ano = data.getFullYear();
        // let mes = data.getMonth() + 1;
        // let dia = '01';
        // let dataInicial = `${ano}-${mes}-${dia}`;
        // setDataInicialSelect(dataInicial);
        // setDataFinalSelect(new Date());
      }
      if (mudarPeriodos === 1) {
        data.setDate(data.getDate());
        // let ano = data.getFullYear();
        // let mes = data.getMonth() + 1;
        // let dia = '01';
        // let dataInicial = `${mes === 1 ? ano - 1 : ano}-${mes === 1 ? (mes = 12) : mes - 1
        //   }-${dia}`;
        // setDataFinalSelect(new Date());
        // setDataInicialSelect(dataInicial);
      }
      if (mudarPeriodos === 2) {
        // data.setDate(data.getDate());
        // let ano = data.getFullYear();
        // let mes = data.getMonth() + 1;
        // let dia = '01';
        // let dataInicial = `${mes === 1 || mes === 2 ? ano - 1 : ano}-${mes === 1
        //   ? (mes = 10)
        //   : mes === 2
        //     ? (mes = 11)
        //     : mes === 3
        //       ? (mes = 12)
        //       : mes - 3
        //   }-${dia}`;
        // setDataFinalSelect(new Date());
        // setDataInicialSelect(dataInicial);
      }
    }
  }, [mudarPeriodos, periodos]);

  // const getPagamentos = useCallback(async () => {
  //   // const res = await getDashboardAdminPagamentos(query);
  //   // if (res.erro) {
  //   //   throw res.erro;
  //   // }

  //   // return res.resultado?.data as Array<DashboardAdminPagamentosModel>;
  // }, []);

  // const getVendas = useCallback(async () => {
  //   // const res = await getDashboardAdminVendas(query);
  //   // if (res.erro) {
  //   //   throw res.erro;
  //   // }
  //   // return res.resultado?.data as Array<DashboardAdminVendasModel>;
  // }, []);

  // const getVendedores = useCallback(async () => {
  //   // const res = await getDashboardAdminVendedores(query);
  //   // if (res.erro) {
  //   //   throw res.erro;
  //   // }
  //   // return res.resultado?.data as Array<DashboardAdminVendedoresModel>;
  // }, []);

  // const getCategorias = useCallback(async () => {
    // const res = await getDashboardAdminCategorias(query);
    // if (res.erro) {
    //   throw res.erro;
    // }
    // return res.resultado?.data as Array<DashboardAdminCategoriasModel>;
  // }, []);

  const preencherTela = useCallback(async () => {
    try {
      // const dashboardPagamentos = await getPagamentos();
      // setDataPagamentos(dashboardPagamentos.filter(item => item.status === 2));
      // const dashboardVendas = await getVendas();
      // setDataVendas(dashboardVendas.filter(item => item.status === 2));
      // const dashboardCategorias = await getCategorias()
      // setDataCategorias(dashboardCategorias)
      // const dashboardVendedores = await getVendedores()
      // setDataVendedores(dashboardVendedores)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [showToast]);

  useEffect(() => {

    if (termo.length === 0) {
      (async () => {
        try {
          await preencherTela();
          if (titulo) titulo('')
          setTimeout(() => {
            setPreenchendoTela(false);
          }, 1000);
        } catch (e: any) {
          showToast('error', e.message);
          setPreenchendoTela(false);
        }
      })();
    }
    return () => {
      if (removeTermo) setTermo('')
    };
  }, [preencherTela, removeTermo, showToast, termo.length, titulo]);

  useEffect(() => {
    setTermo(stringNumeros(pesquisa))
  }, [pesquisa])

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    if (!modalEditAberto) {
      preencherTela()
    }

    addHandler(AppEventEnum.DialogVincularContratos, modalEdit)

    return () => removeHandler(AppEventEnum.DialogVincularContratos, modalEdit)
  }, [addHandler, modalEdit, modalEditAberto, preencherTela, removeHandler]);

  useEffect(() => {

    if (termo.length !== 0) {
      setPreenchendoTela(true)
      setDataPagamentos(prevState => {
        // eslint-disable-next-line array-callback-return
        const res = prevState.filter(item => {
          if (item.status === 2 &&
            (
              item.emitDoc.includes(termo)
            )) {
            return item
          }
        }
        )
        return res
      }
      )
      setDataVendas(prevState => {
        // eslint-disable-next-line array-callback-return
        const res = prevState.filter(item => {
          if (item.status === 2 &&
            (
              item.emitDoc.includes(termo)
            )) {
            return item
          }
        }
        )
        if (titulo) titulo(res[0]?.emitNome)
        return res
      }
      )
      setDataCategorias(prevState =>
        prevState.filter(item =>
          item.emitDoc.includes(termo)
        )
      )
      setDataVendedores(prevState =>
        prevState.filter(item =>
          item.emitDoc.includes(termo)
        )
      )

      setPreenchendoTela(false)
      return;
    }
  }, [preencherTela, removeTermo, termo, titulo])

  useEffect(() => {
    queryPesquisa();
    queryPesquisaPeriodos();
  }, [queryPesquisa, queryPesquisaPeriodos]);

  const getLucro = () => {
    const venda = dataVendas?.reduce((a, b) => a + b.vVenda, 0);
    const custo = dataVendas?.reduce((a, b) => a + b.vCusto, 0);
    return venda - custo;
  };

  const getTicketMedio = () => {
    const venda = dataVendas?.reduce((a, b) => a + b.vVenda, 0);
    const clientes = dataVendas?.reduce((a, b) => a + b.qClientes, 0);
    return venda / clientes || 0.0;
  };

  const apenasMesAno = () => {
    const data = new Date();
    const mes = data.getMonth();
    const ano = data.getFullYear();
    return `${ano}-${mes}`;
  };

  // const getUltimoDia = (val: any) => {
  //   const ano = val.slice(0, 4);
  //   const mes = val.slice(5, 7);
  //   return new Date(ano, mes, 0);
  // };

  const onSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      dataInicial: { value: string }
      dataFinal: { value: string }
    }

    if (target.dataInicial.value === undefined || target.dataFinal === undefined) return

    // periodoPesquisar === 1
    //   ? setDataInicialSelect(target.dataInicial.value)
    //   : setDataInicialSelect(target.dataInicial.value)

    // periodoPesquisar === 1
    //   ? setDataFinalSelect(getUltimoDia(target.dataFinal.value))
    //   : setDataFinalSelect(target.dataFinal.value)
  }, [])

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={6}>
            <SelectSaurus
              style={{ height: '40px' }}
              variant="outlined"
              conteudo={PeriodoDashboardMock}
              allowSubmit
              onChange={(event) => {
                if (event) {
                  const newStatus = PeriodoDashboardMock.filter(
                    (item) => item.Key === event.target.value,
                  )[0]?.Key;
                  setPeriodoPesquisar(newStatus);
                }
              }}
              value={periodoPesquisar}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectSaurus
              style={{ height: '40px' }}
              variant="outlined"
              conteudo={periodos}
              allowSubmit
              onChange={(event) => {
                if (event) {
                  const newStatus = periodos.filter(
                    (item) => item.Key === event.target.value,
                  )[0]?.Key;
                  setMudarPeriodos(newStatus);
                }
              }}
              value={mudarPeriodos ?? 1}
            />
          </Grid>
          {ocultarDatas && (
            <form onSubmit={onSubmit} style={{ width: "100%" }}>
              <Grid container spacing={1} className={classes.gridInputDate}>
                <Grid item>
                  <input
                    name="dataInicial"
                    type={periodoPesquisar === 1 ? 'month' : 'date'}
                    className={classes.inputDate}
                    defaultValue={
                      periodoPesquisar === 1
                        ? apenasMesAno()
                        : dataFormatada(new Date())
                    }
                  />
                </Grid>
                <Grid item>
                  <input
                    name="dataFinal"
                    type={periodoPesquisar === 1 ? 'month' : 'date'}
                    className={classes.inputDate}
                    defaultValue={
                      periodoPesquisar === 1
                        ? apenasMesAno()
                        : dataFormatada(new Date())
                    }
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" color="primary" variant="contained" className={classes.btnPesquisa}>
                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
        {preenchendoTela && carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : (
          <Grid item>
            <Grid
              container
              spacing={1}
              className={classes.containerCardDashboard}
            >
              <Grid item>
                <CardDashboard
                  titulo="Custo"
                  icone={<DinheiroIcon tipo="BUTTON" />}
                  conteudo={
                    dataVendas?.reduce((a, b) => a + b.vCusto, 0) || 0
                  }
                  tipo="dinheiro"
                />
              </Grid>
              <Grid item>
                <CardDashboard
                  titulo="Vendas"
                  icone={<PessoasIcon tipo="BUTTON" />}
                  conteudo={
                    dataVendas?.reduce((a, b) => a + b.qClientes, 0) || 0
                  }
                />
              </Grid>
              <Grid item>
                <CardDashboard
                  titulo="Itens"
                  icone={<QuantidadeItensIcon tipo="BUTTON" />}
                  conteudo={
                    dataVendas?.reduce((a, b) => a + b.qCom, 0) || 0
                  }
                />
              </Grid>
              <Grid item>
                <CardDashboard
                  titulo="Total vendido"
                  icone={<TotalVendidoIcon tipo="BUTTON" />}
                  conteudo={
                    dataVendas?.reduce((a, b) => a + b.vVenda, 0) || 0
                  }
                  tipo="dinheiro"
                />
              </Grid>
              <Grid item>
                <CardDashboard
                  titulo="Lucro"
                  icone={<LucroIcon tipo="BUTTON" />}
                  conteudo={getLucro()}
                  tipo="dinheiro"
                />
              </Grid>
              <Grid item>
                <CardDashboard
                  titulo="Ticket médio"
                  icone={<TicketIcon tipo="BUTTON" />}
                  conteudo={getTicketMedio()}
                  tipo="dinheiro"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className={classes.containerCardDashboardChart}
            >
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
                className={classes.listContainerChart}
              >
                <CardDashboardAdminChartDonut model={dataPagamentos} />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
                className={classes.listContainerChart}
              >
                <CardDashboardAdminChartLine model={dataVendas} />
              </Grid>
              {periodoPesquisar === 0 && mudarPeriodos === 0 ? (
                ''
              ) : (
                <Grid
                  item
                  lg={6}
                  md={12}
                  xs={12}
                  className={classes.listContainerChart}
                >
                  <CardDashboardAdminChartBar model={dataVendas} />
                </Grid>
              )}
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                className={classes.listContainerChart}
              >
                <CardDashboardAdminChartHeatMap model={dataVendas} />
              </Grid>
              {termo.length === 0 && (
                <>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    md={12}
                    className={classes.listContainerChart}
                  >
                    <CardDashboardAdminChartVendas onClick={setTermo} model={dataVendas} />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    md={12}
                    className={classes.listContainerChart}
                  >
                    <CardDashboardAdminChartLucros onClick={setTermo} model={dataVendas} />
                  </Grid>
                </>
              )}
              {termo.length > 0 && (
                <>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    md={12}
                    className={classes.listContainerChart}
                  >
                    <CardDashboardAdminChartCategorias model={dataCategorias} />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    md={12}
                    className={classes.listContainerChart}
                  >
                    <CardDashboardAdminChartVendedores model={dataVendedores} />
                  </Grid>
                </>

              )}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};
