import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoCodigo() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoCodigo = useCallback(
    (produtoId: string, variacaoId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/codigo`,
        method: 'GET',
        enviarTokenUsuario: true
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoCodigo
  };
}
