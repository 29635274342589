import { EnumCadastroTipo } from "model";
import { KeyValueModel } from "model/api";

export const TpCadastroMock: KeyValueModel[] = [
    new KeyValueModel(EnumCadastroTipo.FUNCIONARIO, "Funcionário"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "Representante"),
    new KeyValueModel(EnumCadastroTipo.CLIENTE, "Cliente"),
    new KeyValueModel(EnumCadastroTipo.FORNECEDOR, "Fornecedor"),
    new KeyValueModel(EnumCadastroTipo.TRANSPORTADORA, "Transportadora"),
    new KeyValueModel(EnumCadastroTipo.RESPONSAVEL_TECNICO, "Responsável Técnico"),
    new KeyValueModel(EnumCadastroTipo.PRESCRITOR, "Prescritor"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "Representante"),
];

export const TpCadastroMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumCadastroTipo.FUNCIONARIO, "Funcionário"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "Representante"),
    new KeyValueModel(EnumCadastroTipo.CLIENTE, "Cliente"),
    new KeyValueModel(EnumCadastroTipo.FORNECEDOR, "Fornecedor"),
    new KeyValueModel(EnumCadastroTipo.TRANSPORTADORA, "Transportadora"),
];

export const TpCadastroFarmaciaMock: KeyValueModel[] = [
    new KeyValueModel(EnumCadastroTipo.FUNCIONARIO, "Funcionário"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "Representante"),
    new KeyValueModel(EnumCadastroTipo.CLIENTE, "Cliente"),
    new KeyValueModel(EnumCadastroTipo.FORNECEDOR, "Fornecedor"),
    new KeyValueModel(EnumCadastroTipo.TRANSPORTADORA, "Transportadora"),
    new KeyValueModel(EnumCadastroTipo.RESPONSAVEL_TECNICO, "Responsável Técnico"),
    new KeyValueModel(EnumCadastroTipo.PRESCRITOR, "Prescritor"),
];

export const TpCadastroFarmaciaMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumCadastroTipo.FUNCIONARIO, "Funcionário"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "Representante"),
    new KeyValueModel(EnumCadastroTipo.CLIENTE, "Cliente"),
    new KeyValueModel(EnumCadastroTipo.FORNECEDOR, "Fornecedor"),
    new KeyValueModel(EnumCadastroTipo.TRANSPORTADORA, "Transportadora"),
    new KeyValueModel(EnumCadastroTipo.RESPONSAVEL_TECNICO, "Responsável Técnico"),
    new KeyValueModel(EnumCadastroTipo.PRESCRITOR, "Prescritor"),
];

export const tpCadastroAutocomplete: KeyValueModel[] = [
    new KeyValueModel(EnumCadastroTipo.FUNCIONARIO, "[FUNCIONARIO]"),
    new KeyValueModel(EnumCadastroTipo.REPRESENTANTE, "[REPRESENTANTE]"),
    new KeyValueModel(EnumCadastroTipo.CLIENTE, "[CLIENTE]"),
    new KeyValueModel(EnumCadastroTipo.FORNECEDOR, "[FORNECEDOR]"),
    new KeyValueModel(EnumCadastroTipo.TRANSPORTADORA, "[TRANSPORTADORA]"),
    new KeyValueModel(EnumCadastroTipo.RESPONSAVEL_TECNICO, "[RESPONSÁVEL TECNICO]"),
    new KeyValueModel(EnumCadastroTipo.PRESCRITOR, "[PRESCRITOR]"),
]