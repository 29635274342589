import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { VoltarIcon } from "views/components/icons";
import { useHistory } from 'react-router-dom'

interface HeaderProps {
    title: string
}

export const ConfiguracoesHeader = ({ title }: HeaderProps) => {

    const history = useHistory()

    const leftArea = useCallback(
        () =>
            <ButtonPrivateHeader
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Voltar"
                onClick={() => history.goBack()}
            />,
        [history]
    );

    return (
        <>
            <PrivatePageHeader title={title} leftArea={leftArea()} />
        </>
    );
};
