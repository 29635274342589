import { EnumIndIEDest } from "model/enums";

export class DestFormModel {
  constructor(
    public documento: string = '',
    public xNome: string = '',
    public indIEDest: EnumIndIEDest = EnumIndIEDest.CONTRIBUINTE_ICMS,
    public ie: string = '',
    public isuf: string = '',
    public im: string = '',
    public email: string = '',
    public xLgr: string = '',
    public nro: string = '',
    public xCpl: string = '',
    public xBairro: string = '',
    public cMun: number = 0,
    public xMun: string = '',
    public uf: string = '',
    public cep: string = '',
    public cPais: number = 0,
    public xPais: string = '',
    public fone: string = '0',
  ) { }
}
