import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { ReceitaMedicaModel } from 'model/api/gestao/venda/venda-completa-model';

export function usePostPedidoReceita() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postPedidoReceita = useCallback(
    (
      empresaId: string,
      pedidoId: string,
      receitas: ReceitaMedicaModel[]
    ) => {
      return invocarApi({
        url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/receita-medica`,
        method: 'POST',
        enviarTokenUsuario: true,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(receitas),
        baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postPedidoReceita
  };
}
