import { Box, Typography } from "@material-ui/core"
import { RadioControlLabelProps } from "./radio-control-label-props"
import { SaurusRadio } from "views/components/controles/radio/saurus-radio"
import { useStyles } from "./radio-control-label-styles"

export const RadioControlLabel = ({ checked, onClick, label }: RadioControlLabelProps) => {

    const classes = useStyles()
    return (
        <Box width="100%" className={classes.content} alignItems="center" display="flex" justifyContent={"space-between"} onClick={onClick}>
            <Typography>
                {label}
            </Typography>
            <SaurusRadio checked={checked} />
        </Box>
    )
}