import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const InsumosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 4.8125 7 C 4.335938 7.089844 3.992188 7.511719 4 8 L 4 42 C 4 42.550781 4.449219 43 5 43 L 45 43 C 45.550781 43 46 42.550781 46 42 L 46 8 C 46 7.449219 45.550781 7 45 7 L 5 7 C 4.96875 7 4.9375 7 4.90625 7 C 4.875 7 4.84375 7 4.8125 7 Z M 6 9 L 44 9 L 44 41 L 6 41 Z M 21 12 C 19.355469 12 18 13.355469 18 15 C 18 16.644531 19.355469 18 21 18 L 29 18 C 30.644531 18 32 16.644531 32 15 C 32 13.355469 30.644531 12 29 12 Z M 21 14 L 29 14 C 29.554688 14 30 14.445313 30 15 C 30 15.554688 29.554688 16 29 16 L 21 16 C 20.445313 16 20 15.554688 20 15 C 20 14.445313 20.445313 14 21 14 Z M 32 28 L 30 31 L 31 31 L 31 34 L 33 34 L 33 31 L 34 31 Z M 38 28 L 36 31 L 37 31 L 37 34 L 39 34 L 39 31 L 40 31 Z M 30 35 L 30 37 L 40 37 L 40 35 Z" />
            </DefaultIcon>
        </>
    );
};
