import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const TacaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M14.3125 2L14.0625 2.65625C14.0625 2.65625 11 10.75 11 19C11 26.2266 16.957 31.5039 24 31.9688V38.0938C24 39.043 23.5547 39.8398 22.75 40.5938C21.9453 41.3477 20.7773 41.9961 19.5937 42.5C17.2266 43.5078 14.8437 43.9062 14.8437 43.9062C14.832 43.9062 14.8242 43.9062 14.8125 43.9062C14.0937 44.0508 13.4219 44.4219 12.9062 44.9375C12.3906 45.4531 12 46.168 12 47C12 47.5508 12.4492 48 13 48H37C37.5508 48 38 47.5508 38 47C38 46.168 37.6094 45.4531 37.0937 44.9375C36.5781 44.4219 35.9062 44.0508 35.1875 43.9062C35.1758 43.9062 35.168 43.9062 35.1562 43.9062C35.1562 43.9062 32.7734 43.5078 30.4062 42.5C29.2227 41.9961 28.0547 41.3477 27.25 40.5938C26.4453 39.8398 26 39.043 26 38.0938V31.9688C33.043 31.5039 39 26.2266 39 19C39 10.75 35.9375 2.65625 35.9375 2.65625L35.6875 2H14.3125ZM15.75 4H34.25C34.457 4.57422 35.4258 7.31641 36.1562 11C36.1367 11 36.1133 11 36.0937 11H13.8437C14.5742 7.31641 15.543 4.57422 15.75 4ZM36.4687 12.9375C36.4805 12.9375 36.4883 12.9375 36.5 12.9375C36.7969 14.8398 37 16.8906 37 19C37 25.4297 31.5234 30 25 30C18.4766 30 13 25.4297 13 19C13 16.9141 13.207 14.8867 13.5 13C13.5625 13.0078 13.625 13.0078 13.6875 13H36.0937C36.2227 13.0039 36.3477 12.9805 36.4687 12.9375ZM25 40.9062C25.2734 41.3047 25.5273 41.7031 25.875 42.0312C26.9453 43.0312 28.2773 43.7852 29.5937 44.3438C32.2109 45.457 34.7852 45.8711 34.8125 45.875H34.8437C34.9102 45.8906 34.9609 45.9727 35.0312 46H14.9687C15.0391 45.9727 15.0898 45.8906 15.1562 45.875H15.1875C15.2148 45.8711 17.7891 45.457 20.4062 44.3438C21.7227 43.7852 23.0547 43.0312 24.125 42.0312C24.4727 41.7031 24.7266 41.3047 25 40.9062Z" />

            </DefaultIcon>
        </>
    );
};
