import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  stepContent: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  stepperSide: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  conteudoStep: {
    display:'flex',
  },
  stepperInicial:{
    width: '300px',
    display:'flex',
    justifyContent:'center',
    margin:'0px auto',
  },
  stepperDefault:{
    width:'100%',
    margin: '0 auto',
  },

  mobileBarProgress:{
    width: '100%',
    display:'flex', 
    justifyContent:'center'
  },
  botoes: {
    bottom: '1rem',
    margin:'auto',
    width:'100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]:{
      marginBottom:'2rem',
    }
  },
  stepTitle:{
    display:'flex',

  },
  formDesc:{
    margin:'16px auto',
    alignItems:'center',
    maxHeight: '70px',
    [theme.breakpoints.down('sm')]:{
      display:'flex',
      margin:'16px 0 0 0',
      alignItems:'flex-start',
    }
  },
  containerStepper: {
    display:'flex',
    height:'100%',
  },
  closeButton:{
    position: 'absolute', top: 0, right: 0 
  },
  stepper:{
    maxHeight:'50px',
    marginBottom: theme.spacing(2),
  },
  formDescEmpty:{
    maxHeight:'16px'
  }


}));
