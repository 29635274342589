import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { EnumTpAmb } from "model/enums/enum-tpamb";
import { VariaveisAmbiente } from 'config';

export class PatchConfigEmissorProps {
    constructor(
        public pfxBase64: string = "",
        public senha: string = "",
        public tpCertificado: number = 0,
        public cIdToken: string = "",
        public csc: string = "",
        public tpAmb: EnumTpAmb = 2,
        public cnpj: string = "",
        public xContato: string = "",
        public email: string = "",
        public fone: string = "",
        public idCSRT: number | null = null,
        public hashCSRT: string | null = null,
    ) { }
}

export function usePatchConfigEmissor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const patchConfigEmissor = useCallback(
        (model: PatchConfigEmissorProps, documento: String) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documento}/fiscal/configuracao-fiscal`,
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(model),
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        patchConfigEmissor,
    };
}
