import { useCallback } from 'react';
import { ProdutoFiscalModel } from '../../../../model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostProdutoFiscal() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutoFiscal = useCallback(
    (produtoFiscal: ProdutoFiscalModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Fiscal`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtoFiscal),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoFiscal
  };
}
