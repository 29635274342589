export const enum EnumTelasConfigIniciais {
    Saudacao = 1,
    Segmento = 2,
    MesasEcomandas = 3,
    Setores = 4, 
    Cardapio = 5, 
    HorarioDeFuncionamento = 6,
    Perfil = 7,
    NFCE = 8, 
    Suporte = 9, 
    ResumoDasConfiguracoes = 10,
    Config = 11
}