import { useEffect, useMemo, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './sincronizacao-page-styles';
import { SincronizacaoHeader } from '../components/sincronizacao-header';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { TpSincronizacaoMock } from 'data/mocks/tp-sincronizacao';
import { useSessaoAtual } from 'services/app';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';

const SincronizacaoPage = () => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { callEvent } = useEventTools();
  const {
    iniciarSincronizacao,
    sincAtual,
  } = useSincronizacaoCadastros();
  const classes = useStyles();
  const soUmaVez = useRef<boolean>(false);
  
  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    }
  }, [callEvent]);

  useEffect(() => {
    if (
      !soUmaVez.current
    ) {
      (async () => {
        await iniciarSincronizacao(getEmpresaSelecionada()!.Id);
      })();
      soUmaVez.current = true;
      return;
    }
  }, [iniciarSincronizacao, getEmpresaSelecionada]);

  const tpSincronizacao = TpSincronizacaoMock.filter(
    (x) => x.Key === sincAtual?.sincAtual,
  );

  const header = useMemo(() => {
    return (
      <Grid className={classes.header}>
        <SincronizacaoHeader />
      </Grid>
    );
  }, [classes.header]);

  const middleContainerLoadEInfo = useMemo(() => {
    return (
      <>
        <Grid className={classes.containerLoader}>
          <LoadingFinalizacao />
        </Grid>
        <Typography className={classes.textTitle}>Sincronizando...</Typography>

        <Typography variant="subtitle1" className={classes.textSubtitle}>
          Aguarde enquanto estamos realizando a sincronização dos seus
          cadastros.
        </Typography>
      </>
    );
  }, [classes.containerLoader, classes.textSubtitle, classes.textTitle]);

  const calcularSincronizacao = (
    totalResults: number,
    pageAtual: number,
    totalPage: number,
  ) => {
    return totalResults < 100
      ? pageAtual
      : pageAtual === totalPage
        ? totalResults
        : pageAtual * 100;
  };

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        {header}
        <Grid className={classes.content}>
          <Grid className={classes.infoContainer}>
            {middleContainerLoadEInfo}
            {sincAtual && (
              <>
                <Grid className={classes.containerSincAtual}>
                  <Grid className={classes.textInfo}>
                    Sincronizando{' '}
                    {tpSincronizacao ? tpSincronizacao[0].Value : '...'}
                  </Grid>
                </Grid>

                {sincAtual?.totalResults &&
                  sincAtual?.totalPage &&
                  sincAtual?.pageAtual && (
                    <Typography className={classes.textASincronizar}>
                      Total a sincronizar: (
                      {calcularSincronizacao(
                        sincAtual.totalResults,
                        sincAtual.pageAtual,
                        sincAtual.totalPage,
                      )}
                      /{sincAtual?.totalResults})
                    </Typography>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SincronizacaoPage;
