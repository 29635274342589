import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogMarca } from "views/components/dialog/dialog-marca/dialog-marca";

class MarcaDialogProps {
    constructor(
        public aberto: boolean = false,
        public marca: MarcaModel = new MarcaModel(),
        public finalizar: (produto: MarcaModel) => Promise<void> = async (produto: MarcaModel) => { },
        public isMarca?: boolean
    ) { }
}

export const MarcaDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<MarcaDialogProps>(
        new MarcaDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, marca, finalizar, isMarca }: MarcaDialogProps) => {
        setModalState({
            aberto: aberto,
            marca,
            finalizar,
            isMarca
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogMarca, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogMarca, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogMarca finalizar={modalState.finalizar} model={modalState.marca} openned={modalState.aberto} isMarca={modalState.isMarca}/>
            )}
        </>
    )
}