import { Grid } from "@material-ui/core";
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { SetoresProcessosHeader } from "./components/setores-processos-header/setores-processos-header";
import { SetoresProcessosTabs } from "./components/setores-processos-tabs/setores-processos-tabs";

export const SetoresProcessosPage = () => {
    const classes = useDefaultCadastroStyles();
  
    return (
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <SetoresProcessosHeader/>
        </Grid>
        <Grid className={classes.list}>
          <SetoresProcessosTabs/>
        </Grid>
      </Grid>
    );
  };
  
  export default SetoresProcessosPage;