import { SaloesModel } from "model/api/gestao/saloes/saloes-model";
import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function usePutSalao() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putSalao = useCallback(
		(salao: SaloesModel, empresaId: string) =>
			invocarApi({
				url: `/v5/empresa/${empresaId}/salao/${salao.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify(salao),
				enviarTokenUsuario: true,
				baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
			}),
		[invocarApi],
	);
	return {
		...outrasPropriedades,
		putSalao,
	};
}
