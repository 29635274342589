import { EnumStatusProcessoTipo } from 'model/enums/enum-processo-tipo';
import { guidEmpty } from "utils/guid-empty";
import { ImpressoraModel } from "../impressora/impressora-model";
import { SaloesModel } from "../saloes/saloes-model";
import { SetoresModel } from "../setores/setores-model";

export class StatusProcessos {
    constructor(
        public codigo: number | null = null,
        public descricao: string | null = null,
    ) { }
}

export class ProcessosModel {
    constructor(
        public id: string = guidEmpty(),
        public descricao: string = '',
        public equipamentos: Array<ImpressoraModel> = [],
        public processoProducao: number = 0,
        public status: StatusProcessos = new StatusProcessos(),
    ) { }
}

export class ProcessosFormModel {
    constructor(
        public id: string = guidEmpty(),
        public descricao: string = '',
        public equipamentosId: Array<string> = [],
        public processoProducao: number = 0,
        public status: EnumStatusProcessoTipo = EnumStatusProcessoTipo.EmProducao,
    ) { }
}

export class ProcessosSetorModel {
    constructor(
        public id: string = guidEmpty(),
        public minutosDePreparo: number = 0,
        public setor: SetoresModel = new SetoresModel(),
        public salao: SaloesModel = new SaloesModel(),
        public processos: Array<ProcessosModel> = [],
    ) { }
}

export class ProcessosSetorPutModel {
    constructor(
        public id: string = guidEmpty(),
        public minutosDePreparo: number = 0,
        public setorId: string = guidEmpty(),
        public salaoId: string | null = null,
        public processos: Array<ProcessosFormModel> = [],
    ) { }
}

export class ProcessosSetorNovoModel {
    constructor(
        public minutosDePreparo: number = 0,
        public setorId: string = guidEmpty(),
        public salaoId: string = guidEmpty(),
        public processos: Array<ProcessosFormModel> = [],
    ) { }
}