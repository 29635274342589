import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import { PublicPageHeader } from "views/components/headers";
import { FormRecuperarSenha } from "views/components/form/master/recuperar-senha/form-recuperar-senha";
import { RecuperarSenhaFormModel } from "model/app";
import { CircularLoading, makeUtilClasses } from "views";
import { DefaultFormRefs } from "views/components/form/utils/form-default-props";
import { AtualizarIcon, CadeadoIcon, VoltarIcon } from "views/components/icons";
import { useStyles } from "./recuperar-senha-page-styles";
import { usePostIdentidadeRedefinirSenha } from "data/api/gestao/identidade";
import { GestaoStorageKeys, useGestaoStorage, useToastSaurus } from "services/app";

const RecuperarSenhaPage = () => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { setRegistro } = useGestaoStorage()
    const theme = useTheme();
    const history = useHistory()

    const { postIdentidadeRedefinirSenha, carregando } = usePostIdentidadeRedefinirSenha();

    const recuperarSenhaRefs = useRef<DefaultFormRefs<RecuperarSenhaFormModel>>(null);

    useEffect(() => {
        recuperarSenhaRefs.current?.fillForm(new RecuperarSenhaFormModel(''));
    }, []);

    const recuperarSenha = async (model: RecuperarSenhaFormModel) => {
        try {
            var ret = await postIdentidadeRedefinirSenha({email: model.email});
            if (ret.erro)
                throw ret.erro;

            showToast("info", "Um e-mail foi enviado para você com um código de redefinição, acesse-o para continuar a redefinição de senha.");

            setRegistro(GestaoStorageKeys.EmailRecuperacaoSenha, model.email, true);
            history.replace('/recuperar-senha/codigo');


        } catch (e: any) {
            showToast("error", e.message);
            recuperarSenhaRefs.current?.resetForm();
        }
    };

    return (
        <>
            <PublicPageHeader topOpacity="0.8" />
            <div className={utilClasses.contentCenter}>
                <Grid container className={utilClasses.rootContainer}>
                    {
                        carregando ?
                            <CircularLoading tipo="FULLSIZED" />
                            : null
                    }
                    <Grid item xs={12}>
                        <Grid container alignItems="center" direction="column">
                            <Grid item xs={12}>
                                <Typography align="center" variant="h3" color="primary">
                                    <CadeadoIcon tipo="GERAL" class={classes.imgTitulo} />
                                    Recuperar Senha
                                </Typography>
                                <Box mt={3}>
                                    <Typography align="center" variant="body1" >
                                        Informe seu email para redefinir sua senha.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} style={{ width: "100%" }}>
                                <FormRecuperarSenha showLoading={false} ref={recuperarSenhaRefs} loading={carregando} onSubmit={(model) => { recuperarSenha(model); }} />
                                <Box mt={5}>
                                    <Grid spacing={2} container>
                                        <Grid item xs={4}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="outlined"
                                                fullWidth={true}
                                                disabled={carregando}
                                                onClick={() => {
                                                    history.push('/login')
                                                }}>
                                                <VoltarIcon fill={theme.palette.primary.main} tipo="BUTTON" />
                                                Voltar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                fullWidth={true}
                                                disabled={carregando}
                                                onClick={() => { recuperarSenhaRefs.current?.submitForm(); }}
                                            >
                                                <AtualizarIcon tipo="BUTTON_PRIMARY" />
                                                Recuperar Senha
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default RecuperarSenhaPage;