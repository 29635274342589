import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginBottom: theme.spacing(2),
    },

    multiButton: {

        '&:disabled': {
          color: '#fff',
          backgroundColor: 'rgba(218, 218, 218, 0.3)'
        }
      },
      botoesAgrupados: {
        alignItems: 'center', 
        justifyContent: 'space-between',
        backgroundColor: '#1F9CE4',
        borderRadius: '30px',
        height: '3rem',
        [theme.breakpoints.down('xs')]: {
          margin: `0 ${theme.spacing(1)}px`
        }
    
    
      },
      botaoSelecionado: {
        '&:hover': {
          backgroundColor: '#fff'
        },
        backgroundColor: '#fff',
        color: '#1F9CE4',
        borderRadius: '30px',
        height: '3rem',
        boxShadow: 'none'
      },
      botaoNaoSelecionado: {
        color: '#fff',
        backgroundColor: ' #1F9CE4',
        borderRadius: '30px',
        height: '3rem',
        boxShadow: 'none'
      },
      cardOpcao: {
        margin: '.5rem 0',
        justifyContent: 'space-between'
      },
      opcoes: {
        margin: '1rem 0'
      },
      botoesGrid: {
        display: 'flex', 
        justifyContent: 'space-around',
      },
      botaoFechar: {
        marginRight: '2rem'
      }

    
}));
