export enum EnumEmpresaConfig {
    AlertaClienteInativo = 812,
    AguardarIniciarVenda = 924,
    NroSequencialMovimentoValores = 930,
    TaxaServicoRecomendada = 800,
    TaxaServicoMinima = 830,
    TaxaServicoMaxima = 831,
    TpSincronizacaoValores = 5,
    TempoSincronizacao = 6,
    MerchantToken = 3,
    MerchantId = 4,
    MerchantCredential = 2,
    TipoSincronizacao = 5,
    PrefixoDeComanda = 804,
    IdentificacaoClienteAbertura = 1806,
    CamposSolicitarClientePedido = 8021,
    CamposSolicitarClienteVenda = 8022,
    ValorBandeira = 8002,
    ValorPorKM = 8004,
    MaximoKM = 8003,
    TipoFinalizacaoVendaPDV = 8023
}