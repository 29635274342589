import { Button, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useGetTema } from "data/api/gestao/config-cardapio/get-tema";
import { usePutTema } from "data/api/gestao/config-cardapio/put-tema";
import { useGetEmpresaId } from "data/api/gestao/empresa";
import { isEmpty } from "lodash";
import { EmpresaModel } from "model";
import { TemaPutModel } from "model/api/gestao/cardapio/tema-put-model";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { formatarCEP } from "utils/formatar-cep";
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormConfiguracoesCardapio } from "views/components/form/master/configuracoes-cardapio/form-configuracoes-cardapio";
import { DefaultFormRefs } from "views/components/form/utils";
import { SalvarIcon } from "views/components/icons";
import { CardapioIcon } from "views/components/icons/cardapio-icon";
import { PrateleiraIcon } from "views/components/icons/prateleira-icon";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import { useThemeQueries } from "views/theme"

export const AccordionConfiguracaoCardapio = () => {
    //PROVIDERS
    const { getEmpresaSelecionada, plano  } = useSessaoAtual();

    //AUX
    const { theme } = useThemeQueries();
    const classesModal = useModalStyles();
    const { showToast } = useToastSaurus();
    const isFarmaceutico =  isPlanoFarmaceutico(plano?.plano)

    //STATES E REFS
    const [openAccordion, setOpenAccordion] = useState<boolean>(false);
    const refConfigCardapio = useRef<DefaultFormRefs<TemaPutModel>>(null);
    const [empresa, setEmpresa] = useState<EmpresaModel>(new EmpresaModel());

    const retornaEndereco = useMemo(() => {
        const { bairro, cep, municipio, numero, complemento, logradouro, uf } = empresa ?? new
            EmpresaModel();
        if (isEmpty(empresa)) {
            return ''
        }

        return `${logradouro ?? ''}${!isEmpty(numero) ? `, ${numero}` : ''}${!isEmpty(bairro) ? (' - ' + bairro) : ''}${municipio ? `, ${municipio}` : ''}${uf ? ` - ${uf}` : ''}${cep ? `, ${formatarCEP(cep ?? '')}` : ''}${complemento ? `, ${complemento}` : ''}`
    }, [empresa])

    //CHAMADAS DA API
    const { getTema, carregando: carregandoGet } = useGetTema();
    const { putTema, carregando: carregandoPut } = usePutTema();
    const { getEmpresaId, carregando: carregandoEmpresa } = useGetEmpresaId();

    const carregando = carregandoGet || carregandoPut || carregandoEmpresa;

    const getTemaWrapper = useCallback(async () => {
        try {
            const res = await getTema(getEmpresaSelecionada()?.Id ?? '');
            if (res.erro) throw res.erro;
            return refConfigCardapio.current?.fillForm(res.resultado?.data);
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getEmpresaSelecionada, getTema, showToast])

    const getEmpresaIdWrapper = useCallback(async () => {
        try {
            const res = await getEmpresaId(getEmpresaSelecionada()?.Id!);
            if (res.erro) throw res.erro;
            return setEmpresa(res.resultado?.data);
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getEmpresaId, getEmpresaSelecionada, showToast])

    useEffect(() => {
        if (openAccordion) {
            getTemaWrapper();
            getEmpresaIdWrapper();
        }
    }, [getEmpresaIdWrapper, getTemaWrapper, openAccordion, showToast])

    const putTemaWrapper = useCallback(async (value: TemaPutModel) => {
        const res = await putTema(getEmpresaSelecionada()?.Id!, value);
        if (res.erro) throw res.erro;
        return
    }, [getEmpresaSelecionada, putTema])

    const salvarAlteracoes = useCallback(async (value: TemaPutModel) => {
        try {
            await putTemaWrapper(value);
            return showToast('success', 'Atualizado com sucesso');
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [putTemaWrapper, showToast])

    const buttonSalvar = useMemo(() => {
        return (
            <>
                <div className={classesModal.acoes}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => refConfigCardapio.current?.submitForm()}
                            >
                                <SalvarIcon tipo="BUTTON_PRIMARY" />
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }, [classesModal.acoes])

    const form = useMemo(() => {
        return (
            <>
                <div
                    className={classNames(classesModal.contentForms)}
                    style={{ height: '100%' }}
                >
                    <FormConfiguracoesCardapio
                        loading={false}
                        showLoading={false}
                        ref={refConfigCardapio}
                        onSubmit={salvarAlteracoes}
                        endreco={retornaEndereco}
                    />
                </div>
            </>
        );
    }, [classesModal.contentForms, retornaEndereco, salvarAlteracoes])

    return (
        <AccordionSaurus
            labelPrimary={isFarmaceutico ? 'Configurações da Vitrine' : "Configurações do Cardápio"}
            tipoExpand="bold"
            tituloChildren={
                isFarmaceutico ? 
                <PrateleiraIcon tipo="BUTTON" fill={theme.palette.text.primary} /> 
                :
                <CardapioIcon tipo="BUTTON" fill={theme.palette.text.primary} />
            }
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(!openAccordion)}
        >
            <div className={classesModal.root}>
                {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                <div className={classesModal.content}>
                    {form}
                    {buttonSalvar}
                </div>
            </div>
        </AccordionSaurus>
    )
}