import * as Yup from "yup";
import { useMemo } from "react";

export const useDialogProdutoGenericoValidation = () => {
  const DialogProdutoGenericoValidation = useMemo(() => {
    return Yup.object().shape({
      xProd: Yup.string().notRequired(),
      vUnCom: Yup.string().required('Adicione um valor.'),
      qCom: Yup.string().required('Adiciona uma quantidade.'),
    });
  }, []);

  return {
    DialogProdutoGenericoValidation,
  };
};
