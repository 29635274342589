import { Box, Button, Grid } from "@material-ui/core"
import { EnumRetornoApiBase } from "data/api/base/api-base-response"
import { useGetNcms, usePostNcm } from "data/api/gestao/ncm"
import { usePostImagemBase64 } from "data/api/imagem/post-imagem"
import { useGetNcmsMaster } from "data/api/ncm-master/get-ncms-master"
import { NcmModel } from "model/api/gestao/ncm/ncm-model"
import { ProdutoNovoModel } from "model/api/gestao/produto/produto/produto-novo-model"
import { NcmMasterSummaryModel } from "model/api/ncm-master/ncm-master-model"
import { ProdutoCompletoPreCadastroFormModel } from "model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model"
import { useCallback, useEffect, useRef, useState } from "react"
import { useCadastros, useGestaoToken, useSessaoAtual, useToastSaurus } from "services/app"
import { guidEmpty } from "utils/guid-empty"
import { imagemForUpload } from "utils/imagem-for-upload"
import { newGuid } from "utils/new-guid"
import { picker } from "utils/picker"
import { FormProdutoCompletoPreCadastro } from "views/components/form/produto/produto-pre-cadastro/form-produto-completo/form-produto-completo-pre-cadastro"
import { DefaultFormRefs } from "views/components/form/utils"
import { CancelarIcon } from "views/components/icons"
import { ConfirmarIcon } from "views/components/icons/confirmar-icon"
import { useStyles } from './cadastro-produto-facilitado-styles'
import { CircularLoading } from "views/components/utils"
import { usePutProduto } from "data/api/gestao/produto/produto/put-produto"
import { usePutProdutoVariacao } from "data/api/gestao/produto/produto-variacao/put-produto-variacao"
import { ProdutoAtualizarModel } from "model/api/gestao/produto/produto/produto-atualizar-model"
import { ProdutoAtualizarVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-atualizar-variacao-model"
import { useGetProdutoVariacao } from "data/api/gestao/produto/produto-variacao/get-produto-variacao"
import { ProdutoVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-variacao"
import { usePutProdutoPreco } from "data/api/gestao/produto/produto-preco/put-produto-preco"
import { ProdutoPrecoModel } from "model/api/gestao/produto/produto-preco/produto-preco-model"
import { removeGuidEmpty } from "utils/remove-guid-empty"
import { toDecimal } from "utils/to-decimal"
import { usePostProdutoImagem } from "data/api/gestao/produto/produto-imagem/post-produto-imagem"
import { ProdutoImagemModel } from "model/api/gestao/produto/produto-imagem/produto-imagem"
import { isEmpty } from "lodash"

interface CadastroProdutoFacilitadoProps {
    finalizar: (produto: ProdutoCompletoPreCadastroFormModel) => Promise<void>;
    produto: ProdutoNovoModel;
    gradeId: string;
}

export const CadastroProdutoFacilitado = ({ finalizar, produto, gradeId }: CadastroProdutoFacilitadoProps) => {

    const { getEmpresaAtual } = useGestaoToken();
    const { showToast } = useToastSaurus();
    const { fecharCadastroProdutoFacilitado } = useCadastros();
    const { getPessoa } = useSessaoAtual();
    const classes = useStyles()

    const cadProdutoRef = useRef<DefaultFormRefs<ProdutoCompletoPreCadastroFormModel>>(null)
    const produtoVariacaoRef = useRef<ProdutoVariacaoModel>(new ProdutoVariacaoModel())

    useEffect(() => {
        const editAdicional = picker<ProdutoCompletoPreCadastroFormModel>(produto, new ProdutoCompletoPreCadastroFormModel())
        cadProdutoRef.current?.fillForm(editAdicional)
    }, [produto])

    const { getNcmsMaster, carregando: carregandoGetNcmMaster } = useGetNcmsMaster();
    const { getProdutoVariacao, carregando: carregandoGetVariacao } = useGetProdutoVariacao();
    const { getNcms, carregando: carregandoGetNcm } = useGetNcms();
    const { postNcm, carregando: carregandoPostNcm } = usePostNcm();
    const { postImagemBase64, carregando: carregandoPostImagem } = usePostImagemBase64();
    const { putProdutoPreco, carregando: carregandoPutProdutoPreco } =
        usePutProdutoPreco();
    const { postProdutoImagem, carregando: carregandoPostProdutoImagem } =
        usePostProdutoImagem();

    const { putProduto, carregando: carregandoPut } = usePutProduto();
    const { putProdutoVariacao, carregando: carregandoPutVariacao } = usePutProdutoVariacao();

    const [carregandoExterno, setCarregandoExterno] = useState<boolean>(false)

    const carregando = carregandoExterno || carregandoGetNcmMaster || carregandoGetNcm ||
        carregandoPostNcm || carregandoPostImagem || carregandoPut || carregandoPutVariacao ||
        carregandoGetVariacao || carregandoPutProdutoPreco || carregandoPostImagem || carregandoPostProdutoImagem


    const getProdutoVariacaoWrapper = useCallback(async () => {
        try {
            const res = await getProdutoVariacao(produto.id, gradeId)
            if (res.erro) throw res.erro

            produtoVariacaoRef.current = res.resultado?.data
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getProdutoVariacao, gradeId, produto.id, showToast])

    useEffect(() => {
        getProdutoVariacaoWrapper();
    }, [getProdutoVariacaoWrapper])

    const sendImage = useCallback(
        async (modelo: ProdutoCompletoPreCadastroFormModel) => {
            try {
                let imagem = modelo.imagemUrl;
                let imgUpload = imagemForUpload(modelo.imagemUrl);
                if (imgUpload.length > 0) {
                    const retImagem = await postImagemBase64(
                        imgUpload,
                        `produtos/${getPessoa()?.pessoa?.contratoId}/`,
                        newGuid(),
                    );
                    if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                        throw new Error('Erro ao enviar a Imagem selecionada.');
                    }
                    if (retImagem.resultado?.data.data.status === 2) {
                        throw new Error(
                            'Erro ao enviar a Imagem selecionada.Detalhe: ' +
                            retImagem.resultado?.data?.data?.retorno,
                        );
                    }
                    imagem =
                        retImagem.resultado?.data?.data?.url_blob +
                        '?timestamp=' +
                        new Date().getTime();
                }

                modelo.imagemUrl = btoa(imagem);
                return modelo;
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [getPessoa, postImagemBase64, showToast],
    );

    const searchNcm = useCallback(
        async (modelo: ProdutoCompletoPreCadastroFormModel) => {
            if (modelo.ncmId === null && modelo.codigoNcm.length > 0) {
                try {
                    //primeiro vou buscar na minha base
                    const retNcmGestao = await getNcms(
                        `Codigo=${modelo.codigoNcm}&TemFiltro=true`,
                    );
                    if (retNcmGestao.erro) throw retNcmGestao.erro;
                    if (retNcmGestao.resultado?.data.list.length > 0) {
                        const ncm = retNcmGestao.resultado?.data?.list[0] as NcmModel;
                        modelo.codigoNcm = ncm.codigo;
                        modelo.ncmId = ncm.id;
                    }
                } catch (e: any) { }
                //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado
                if (modelo.ncmId === null) {
                    //entao busco na api de ncms master
                    const retNcmGestao = await getNcmsMaster(modelo.codigoNcm);
                    if (retNcmGestao.erro) throw retNcmGestao.erro;
                    if (retNcmGestao.resultado?.data.list.length > 0) {
                        const ncm = retNcmGestao.resultado?.data
                            ?.list[0] as NcmMasterSummaryModel;
                        const ncmModel = new NcmModel(
                            guidEmpty(),
                            getPessoa().pessoa?.contratoId,
                            ncm.id,
                            ncm.codigo,
                            ncm.descricao,
                            ncm.cest,
                        );
                        //cadastro em nossa base
                        const ret = await postNcm(ncmModel);
                        if (ret.erro) {
                            throw ret.erro;
                        }
                        const novoNcm = ret.resultado?.data as NcmModel;
                        modelo.codigoNcm = novoNcm.codigo;
                        modelo.ncmId = novoNcm.id;
                    }
                }
            }
        },
        [getNcms, getNcmsMaster, getPessoa, postNcm],
    );

    const finalizarCadastro = useCallback(
        async (produtoCompleto: ProdutoCompletoPreCadastroFormModel) => {
            try {
                //vou verificar se o ncm existe parta pegar o id dele, ou cadastrar um novo
                await searchNcm(produtoCompleto);
                //vamos upar a imagem na api de upar imagens
                await sendImage(produtoCompleto);

                let prodAtualizar = picker<ProdutoAtualizarModel>(produtoCompleto, new ProdutoAtualizarModel())

                prodAtualizar.id = produto.id

                prodAtualizar = removeGuidEmpty(prodAtualizar, true)

                const resProd = await putProduto(prodAtualizar, getEmpresaAtual()!.id)

                if (resProd.erro) throw resProd.erro

                const prodVariacao = new ProdutoAtualizarVariacaoModel(
                    produtoVariacaoRef.current.id,
                    produtoVariacaoRef.current.contratoId,
                    produtoVariacaoRef.current.empresaId,
                    produtoVariacaoRef.current.produtoId,
                    produtoVariacaoRef.current.medidaEntradaId,
                    produtoVariacaoRef.current.medidaSaidaId,
                    produtoVariacaoRef.current.grade,
                    produtoCompleto.codigo,
                    produtoVariacaoRef.current.qMinimo,
                    produtoVariacaoRef.current.qMaximo,
                    produtoVariacaoRef.current.qSaldoTotal,
                    produtoVariacaoRef.current.precos[0].tabelaPrecoId,
                    toDecimal(produtoCompleto.vCompra),
                    produtoVariacaoRef.current.infAdic,
                    produtoVariacaoRef.current.ativo,
                    produtoVariacaoRef.current.balanca
                )

                prodVariacao.id = produtoVariacaoRef.current.id
                prodVariacao.tabelaPrecoId = produtoVariacaoRef.current.precos[0].tabelaPrecoId

                const resProdAtualizar = await putProdutoVariacao(produto.id, prodVariacao)

                if (resProdAtualizar.erro) throw resProdAtualizar.erro

                const prodPreco = new ProdutoPrecoModel(
                    produtoVariacaoRef.current.precos[0].id,
                    produto.id,
                    gradeId,
                    produtoVariacaoRef.current.precos[0].tabelaPrecoId,
                    toDecimal(produtoCompleto.vPreco)
                )

                const retPreco = await putProdutoPreco(
                    produtoVariacaoRef.current.precos[0].id,
                    gradeId,
                    prodPreco
                );

                if (retPreco.erro) throw retPreco.erro

                const prodImagem = new ProdutoImagemModel(
                    undefined,
                    produto.id,
                    gradeId,
                    produtoCompleto.imagemUrl
                )

                if (!isEmpty(produtoCompleto.imagemUrl)) {
                    const retImagem = await postProdutoImagem(
                        produto.id,
                        produtoVariacaoRef.current.id,
                        prodImagem
                    )

                    if (retImagem.erro) throw retImagem.erro
                }

                showToast('success', 'Produto atualizado com sucesso!');

                await finalizar(produtoCompleto)

                fecharCadastroProdutoFacilitado();

            } catch (e: Error | any) {
                showToast(
                    'error',
                    'Erro ao cadastrar o produto. Tente novamente em alguns instantes. Detalhe: ' +
                    e.message,
                );
            }
        },
        [searchNcm, sendImage, produto.id, putProduto, getEmpresaAtual, putProdutoVariacao, gradeId, putProdutoPreco, postProdutoImagem, showToast, finalizar, fecharCadastroProdutoFacilitado],
    );

    return (
        <Grid container spacing={2} className={classes.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid item>
                <FormProdutoCompletoPreCadastro
                    showLoading={false}
                    ref={cadProdutoRef}
                    loading={false}
                    contratoId={getPessoa()?.pessoa?.contratoId ?? ''}
                    empresaId={getEmpresaAtual()!.id}
                    setCarregandoExterno={setCarregandoExterno}
                    onSubmit={finalizarCadastro}
                    imageSize="small"
                    tipo={produto.tipo}
                />
            </Grid>
            <Grid item>
                <Box display='flex' justifyContent='flex-end' width='100%' gridGap={16} flexWrap='wrap'>
                    <Button variant='outlined'
                        color='primary'
                        onClick={fecharCadastroProdutoFacilitado}
                        className={classes.button}>
                        <CancelarIcon tipo='BUTTON' />
                        Cancelar
                    </Button>
                    <Button variant='contained'
                        color='primary'
                        onClick={() => cadProdutoRef.current?.submitForm()}
                        className={classes.button}>
                        <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                        Editar
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}