import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ProdutosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M1 3V15H3V48H47V15H49V3H1ZM3 5H47V13H3V5ZM5 15H45V46H5V15ZM17.5 19C15.5781 19 14 20.5781 14 22.5C14 24.4219 15.5781 26 17.5 26H32.5C34.4219 26 36 24.4219 36 22.5C36 20.5781 34.4219 19 32.5 19H17.5ZM17.5 21H32.5C33.3398 21 34 21.6602 34 22.5C34 23.3398 33.3398 24 32.5 24H17.5C16.6602 24 16 23.3398 16 22.5C16 21.6602 16.6602 21 17.5 21Z" />
            </DefaultIcon>
        </>
    );
};

