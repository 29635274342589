import * as Yup from "yup";

export const useFormImpressoraEditValidation = () => {
  const FormImpressoraEditYupValidation = Yup.object().shape({
    descricao: Yup.string().required('A Descrição é obrigatória.'),
    caminho: Yup.string().required('O Caminho é obrigatório.'),
    modelo: Yup.string().required('O Modelo é obrigatório.'),
    colunas: Yup.number().min(1, 'Deve ser maior que 0').required('Colunas é obrigatório.').typeError('Colunas é obrigatório'),
    quantidadeVias: Yup.number().min(1, 'Deve ser maior que 0').required('Quantidade Vias é obrigatório.').typeError('Quantidade Vias é obrigatório'),
  });

  return {
    FormImpressoraEditYupValidation,
  };
};
