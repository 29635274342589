import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '100%',
        display: 'flex',
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        backgroundColor: '#fafafa',
        '&::-webkit-scrollbar': {
            height: theme.spacing(0.5),
            width: theme.spacing(0.5)
        }
    },
    icon: {
        width: 20,
        height: 20,
        margin: 0
    }
}))