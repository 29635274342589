import { CofinsModel, IcmsModel, IiModel, IpiModel, PisModel, RastroModel } from "model/api/gestao/venda/venda-completa-model";
import { guidEmpty } from "utils/guid-empty";

export class ProdutoEntradaFormModel {
  constructor(
    public produtoGradeId: string = guidEmpty(),
    public categoriaId: string = guidEmpty(),
    public categoriaNome: string = '',
    public nItem: number = 0,
    public cProd: string = '',
    public cEan: string = '',
    public xProd: string = '',
    public ncm: string = '',
    public nve: string | null = null,
    public cest: string = '',
    public cfopNome: string = '',
    public indEscala: number = 78,
    public cnpjFab: string = '',
    public cBenef: string = '',
    public extIpi: string = '',
    public cfop: number = 0,
    public uCom: string = '',
    public qCom: number = 0,
    public vUnCom: number = 0,
    public vProd: number = 0,
    public cEanTrib: string = '',
    public uTrib: string = '',
    public qTrib: number = 0,
    public vUnTrib: number = 0,
    public vFrete: number = 0,
    public vSeg: number = 0,
    public vDesc: number = 0,
    public vOutro: number = 0,
    public indTot: number = 0,
    public xPed: string = '',
    public nItemPed: string = '',
    public nFci: string = '',
    public nrecopi: string | null = null,
    public infAdProd: string = '',
    public vTotTrib: number = 0,
    public cancelado: boolean = false,
    public vCusto: number = 0,
    public pTribFed: number = 0,
    public pTribEst: number = 0,
    public pTribMun: number = 0,
    public rastro: RastroModel[] = [],
    public icms: IcmsModel = new IcmsModel(),
    public pis: PisModel = new PisModel(),
    public cofins: CofinsModel = new CofinsModel(),
    public ipi: IpiModel = new IpiModel(),
    public ii: IiModel = new IiModel(),
    public taxaServico: number = 0,
    public valorServico: number = 0,
    public indFin: boolean = true,
    public indEstoque: boolean = true,
    public cProdANVISA: string = '',
    public vPMC: number = 0,
    public vendaControlada: boolean = false,
    public pDevol: number = 0,
    public vIPIDevol: number = 0,
    public xMotivoIsencao: string = '',
  ) { }
}
