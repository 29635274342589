
export class DashboardAdminPagamentosModel {
    constructor(
        public data: number = 0,
        public hora: number = 0,
        public modPagamento: number = 0,
        public tpTransacao: number = 0,
        public vVenda: number = 0,
        public status: number = 0,
        public emitDoc: string = '',
        public emitNome: string = ''
    ) { }
}