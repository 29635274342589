import { Box, Button, Grid } from '@material-ui/core';
import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { useFormConviteValidation } from './form-convite-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { ConviteFormModel } from '../../../../../model/app/forms/master/convite-form-model';
import { picker } from 'utils/picker';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const FormConvite = forwardRef<
  DefaultFormRefs<ConviteFormModel>,
  DefaultFormProps<ConviteFormModel>
>(({ loading, ...props }: DefaultFormProps<ConviteFormModel>, ref) => {
  const txtSenha = useRef<HTMLInputElement>();
  const { FormConviteYupValidation } = useFormConviteValidation();
  const utilClasses = makeUtilClasses();
  const [modelForm, setModelForm] = useState<ConviteFormModel>(
    new ConviteFormModel(),
  );
  const { isMobile } = useThemeQueries();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<ConviteFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormConviteYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = async (values: ConviteFormModel) => {
    const model = picker<ConviteFormModel>(values, new ConviteFormModel());
    props.onSubmit(model, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) txtSenha.current?.focus();
    },
    fillForm: (model: ConviteFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) txtSenha.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="senha"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="PASSWORD"
                    fullWidth
                    showPasswordVisibilityButton
                    placeholder="(6-32 caracteres)"
                    disabled={loading}
                    label="Senha"
                    variant="outlined"
                    error={Boolean(errors.senha && errors.senha.message)}
                    helperText={
                      touchedFields.senha || errors.senha
                        ? errors.senha?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                mb={Boolean(errors.senha && errors.senha.message) ? 0 : 1.5}
              >
                <Controller
                  name="confirmarSenha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="PASSWORD"
                      fullWidth
                      showPasswordVisibilityButton
                      placeholder="(6-32 caracteres)"
                      allowSubmit
                      disabled={loading}
                      label="Confirmar senha"
                      variant="outlined"
                      error={Boolean(
                        errors.confirmarSenha && errors.confirmarSenha.message,
                      )}
                      helperText={
                        touchedFields.confirmarSenha || errors.confirmarSenha
                          ? errors.confirmarSenha?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
