import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useDeleteSessaoValor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteSessaoValor = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string, valorId: string) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Sessao/${sessaoId}/Valor/${valorId}`,
                method: "DELETE",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        deleteSessaoValor,
    };
}
