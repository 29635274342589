import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { PessoaListSearchProps } from "views/pages/private/cadastros/pessoa/components/pessoa-list/pessoa-list-search-props";
import { PessoaPesquisaModal } from "views/pages/private/cadastros/pessoa/components/pessoa-pesquisa-modal/pessoa-pesquisa-modal";

export const PessoaFiltroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            status: -1,
            termo: '',
            tpCadastro: [-1]
        } as PessoaListSearchProps
    });

    const modalAlterado = useCallback(({ openned, filtros }: any) => {
        setModalState({
            openned,
            filtros
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.PessoasFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.PessoasFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <PessoaPesquisaModal
            filtros={modalState.filtros}
            openned={modalState.openned}
        />
    )
}