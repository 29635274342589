import { useSessaoAtual } from 'services/app';
import { isPlanoComVendedor } from 'utils/plano-utils';
import * as Yup from 'yup';
import { useValidationYupEmail } from '../../../form-validations';

export const useFormConvidarValidation = () => {
    const { emailYup } = useValidationYupEmail()
    const { plano } = useSessaoAtual()
    const planoComVendedor = isPlanoComVendedor(plano?.plano)


    const FormConvidarYupValidation = Yup.object().shape({
        email: emailYup(),
        perfilId: planoComVendedor ? Yup.string().required('O perfil é obrigatório.') : Yup.string().notRequired()
    });

    return {
        FormConvidarYupValidation
    }
}

