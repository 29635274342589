import { EnumTipoExibicao } from "model/enums/enum-tipo-exibicao";
import { guidEmpty } from "utils/guid-empty";

export class CategoriaEditFormModel {
  constructor(
    public id: string = guidEmpty(),
    public categoriaPaiId: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public cor: string = "#000",
    public ativo: boolean = true,
    public breadCrumbs: string = "",
    public foto: string = '',
    public tipoExibicao: EnumTipoExibicao = EnumTipoExibicao.Todos,
    public ordem: number = 0

  ) { }
}
