import { Grid, Typography } from '@material-ui/core';
import { CardCargaParceiroProps } from './card-carga-parceiro-props';
import { useCallback } from 'react';
import { DefaultCard } from 'views/components/cards/components';
import { Styles } from './card-carga-parceiros-styles';
import { useThemeQueries } from 'views';
import { TpUploadCargaStatusMock } from 'data/mocks/tp-carga-tipo-mock';
import { TpCadastroMock } from 'data/mocks';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { EnumUploadCargaStatus } from 'model/enums/enum-upload-carga-status';

export const CardCargaParceiros = ({
  model,
  onClick,
}: CardCargaParceiroProps) => {
  const classes = Styles();
  const { theme } = useThemeQueries();

  const status = TpUploadCargaStatusMock.filter((x) => x.Key === model.status.codigo)[0]?.Value;

  const tipoParceiro = TpCadastroMock.filter((x) => x.Key === model.tipo)[0]?.Value;

  const retornarCorStatus = useCallback((): any => {
    switch (model.status.codigo) {
      case EnumUploadCargaStatus.Processado:
        return '#10C822';
      case EnumUploadCargaStatus.Aguardando:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Analisando:
        return '#4A4A4A';
      case EnumUploadCargaStatus.Erro:
        return '#F44336';
      case EnumUploadCargaStatus.Processando:
        return theme.palette.warning.dark;
      case EnumUploadCargaStatus.AguardandoProcessamento:
        return '#1F9CE4';
      case EnumUploadCargaStatus.Cancelado:
        return '#4A4A4A';
      default:
        return '#28EC87';
    }
  }, [model.status, theme.palette.warning.dark]);

  return (
    <>
      <DefaultCard onClick={() => onClick(model)}>
        <Grid container spacing={2} className={classes.cardContent}>
          <Grid item xs={3}>
            <Typography color="textPrimary" variant="caption">
              CPF/CNPJ
            </Typography>

            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>
                {model.documento
                  ? formatarCPFCNPJ(model.documento)
                  : 'Sem Documento'}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textPrimary" variant="caption">
              Razão Social
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.razaoSocial || 'Sem Razão Social'}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="textPrimary" variant="caption">
              Telefone
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {model.telefone || 'Sem Telefone'}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="textPrimary" variant="caption">
              Tipo
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {tipoParceiro}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Grid style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
              <div
                style={{
                  display: 'flex',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: 4,
                  justifyContent: 'center',
                  background: retornarCorStatus(),
                }}
              >
                <Typography
                  className={'celula-grid-value'}
                  color="textPrimary"
                  variant="body1"
                  style={{ color: '#fff' }}
                >
                  {status}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DefaultCard>
    </>
  );
};
