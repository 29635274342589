import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const ABIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={props.style} fill={props.fill}>
                <path opacity="0.77" d="M16.5854 19.1H8.13544L6.52344 23H2.20744L10.3194 4.8H14.4794L22.6174 23H18.1974L16.5854 19.1ZM15.2594 15.902L12.3734 8.934L9.48745 15.902H15.2594ZM43.1997 13.536C44.2397 13.8653 45.0544 14.42 45.6437 15.2C46.233 15.9627 46.5277 16.9073 46.5277 18.034C46.5277 19.6287 45.9037 20.8593 44.6557 21.726C43.425 22.5753 41.6224 23 39.2477 23H29.8357V4.8H38.7277C40.9464 4.8 42.645 5.22467 43.8237 6.074C45.0197 6.92333 45.6177 8.076 45.6177 9.532C45.6177 10.416 45.401 11.2047 44.9677 11.898C44.5517 12.5913 43.9624 13.1373 43.1997 13.536ZM34.0217 7.972V12.262H38.2077C39.2477 12.262 40.0364 12.08 40.5737 11.716C41.111 11.352 41.3797 10.8147 41.3797 10.104C41.3797 9.39333 41.111 8.86467 40.5737 8.518C40.0364 8.154 39.2477 7.972 38.2077 7.972H34.0217ZM38.9357 19.828C40.045 19.828 40.877 19.646 41.4317 19.282C42.0037 18.918 42.2897 18.3547 42.2897 17.592C42.2897 16.084 41.1717 15.33 38.9357 15.33H34.0217V19.828H38.9357ZM26.3338 47.312C24.4792 47.312 22.7978 46.9133 21.2898 46.116C19.7992 45.3013 18.6205 44.1833 17.7538 42.762C16.9045 41.3233 16.4798 39.7027 16.4798 37.9C16.4798 36.0973 16.9045 34.4853 17.7538 33.064C18.6205 31.6253 19.7992 30.5073 21.2898 29.71C22.7978 28.8953 24.4878 28.488 26.3598 28.488C27.9372 28.488 29.3585 28.7653 30.6238 29.32C31.9065 29.8747 32.9812 30.672 33.8478 31.712L31.1438 34.208C29.9132 32.7867 28.3878 32.076 26.5678 32.076C25.4412 32.076 24.4358 32.3273 23.5518 32.83C22.6678 33.3153 21.9745 34 21.4718 34.884C20.9865 35.768 20.7438 36.7733 20.7438 37.9C20.7438 39.0267 20.9865 40.032 21.4718 40.916C21.9745 41.8 22.6678 42.4933 23.5518 42.996C24.4358 43.4813 25.4412 43.724 26.5678 43.724C28.3878 43.724 29.9132 43.0047 31.1438 41.566L33.8478 44.062C32.9812 45.1193 31.9065 45.9253 30.6238 46.48C29.3412 47.0347 27.9112 47.312 26.3338 47.312Z"/>
            </DefaultIcon>
        </>
    );
};