import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const VarejoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M36.197 2C36.7708 2.02249 37.0464 2.31644 37.2189 2.75497C37.9426 4.58937 38.6664 6.42217 39.3982 8.25176C42.2254 15.3195 45.0559 22.3841 47.8864 29.4518C47.9154 29.5257 47.946 29.5996 47.9751 29.6751C48.3039 30.5056 48.1475 30.8879 47.3206 31.2156C43.6326 32.6789 39.943 34.139 36.255 35.5992C33.8065 36.5694 31.3581 37.5412 28.908 38.5114C28.1278 38.8198 27.7104 38.6431 27.396 37.856C23.8193 28.9137 20.2425 19.9714 16.6673 11.0275C16.3288 10.1825 16.498 9.80024 17.354 9.4597C23.5033 7.01329 29.6527 4.56688 35.8037 2.12369C35.952 2.06586 36.1083 2.02891 36.197 2ZM28.8725 36.5646C34.6189 34.2868 40.2976 32.038 46.0069 29.7747C44.4031 25.7364 42.8283 21.7721 41.247 17.79C35.499 20.0742 29.8187 22.3327 24.0868 24.6104C25.6842 28.6005 27.2655 32.552 28.8725 36.5646ZM23.3986 22.8804C29.1304 20.6155 34.8269 18.3651 40.5587 16.1018C38.9452 12.07 37.3591 8.1104 35.7666 4.13478C30.0234 6.40932 24.3544 8.65494 18.6402 10.9182C20.2344 14.9244 21.8076 18.8791 23.3986 22.8804Z"/>
        <path d="M26.6416 41.6952C27.8263 41.2374 28.9788 40.7957 30.1281 40.3476C35.4409 38.2722 40.7537 36.1936 46.0648 34.1183C46.8869 33.797 47.3963 33.9239 47.6187 34.5022C47.8395 35.0789 47.5623 35.519 46.7386 35.8402C40.0476 38.4505 33.3551 41.0559 26.6609 43.6614C25.8791 43.965 25.4697 43.7867 25.1538 42.9899C22.873 37.2377 20.597 31.4839 18.321 25.7317C15.5179 18.6479 12.7116 11.5705 9.92463 4.48508C9.75216 4.04656 9.55551 3.91645 9.08483 3.9807C7.54225 4.19595 6.09155 4.62644 4.9052 5.67536C4.48933 6.0432 4.13472 6.48976 3.8075 6.94113C3.40775 7.49531 2.89678 7.68646 2.42611 7.37965C1.9361 7.0616 1.86034 6.54116 2.24236 5.95004C3.21594 4.44171 4.57799 3.40564 6.23501 2.74706C7.44232 2.26838 8.70604 2.0451 10.002 2.00976C10.7612 1.98888 10.9885 2.1463 11.2689 2.85468C13.4611 8.39966 15.6517 13.9462 17.8438 19.4912C20.6953 26.7068 23.5499 33.9207 26.403 41.1362C26.4723 41.3129 26.5513 41.4848 26.6416 41.6952Z"/>
        <path d="M19.5057 38.8118C22.0429 38.7974 24.1254 40.847 24.1448 43.3754C24.1641 45.8796 22.0783 47.9823 19.5573 47.9999C17.0331 48.0176 14.9425 45.9535 14.9231 43.4268C14.9022 40.9 16.9622 38.8263 19.5057 38.8118ZM22.2846 43.3834C22.2782 41.8446 21.0596 40.6334 19.5251 40.6398C17.9728 40.6462 16.751 41.8847 16.7639 43.438C16.7752 44.956 18.0212 46.1671 19.5557 46.1559C21.0967 46.1446 22.2911 44.9303 22.2846 43.3834Z"/>
        <path d="M30.3232 26.0401C29.809 26.0304 29.4527 25.7316 29.356 25.2947C29.2658 24.8819 29.4673 24.4594 29.8944 24.2779C30.602 23.9759 31.3225 23.7029 32.0382 23.4202C33.605 22.8017 35.1734 22.1865 36.7401 21.5697C36.8304 21.5344 36.919 21.4974 37.0125 21.4733C37.5348 21.3384 38.0135 21.589 38.165 22.0741C38.3101 22.5367 38.0812 23.0041 37.5976 23.1969C35.3329 24.0996 33.0666 24.9976 30.7987 25.8939C30.623 25.9646 30.4344 26.0063 30.3232 26.0401Z"/>
        <path d="M32.6798 8.91677C32.5637 9.04688 32.3913 9.4083 32.1156 9.52074C29.7526 10.4893 27.3718 11.4178 24.9911 12.3446C24.4898 12.539 23.9885 12.29 23.8112 11.8113C23.6323 11.3278 23.8466 10.8524 24.3641 10.6452C26.6771 9.72153 28.9902 8.79951 31.3065 7.88712C32.0302 7.6028 32.6911 8.01081 32.6798 8.91677Z"/>
      </DefaultIcon>
    </>
  );
};
