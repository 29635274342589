import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { DesfazerIcon, FiltroIcon, VoltarIcon } from 'views/components/icons';
import { SessaoValorSearchProps } from '../sassao-valor-list/sessao-valor-search-props';
import { PesquisaValor } from './components/pesquisa-valor/pesquisa-valor';

export interface ValorHeaderProps {
  openPesquisa: boolean;
  setOpenPesquisa: (state: boolean) => any;
  setSearchProps: (props: SessaoValorSearchProps) => any;
  title: string;
}

export const SessaoValorHeader = ({
  openPesquisa,
  setOpenPesquisa,
  setSearchProps,
  title,
}: ValorHeaderProps) => {
  const { push: historyPush } = useHistory();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Menu"
        onClick={() => historyPush('/venda-simples/gerenciar-sessao')}
      ></ButtonPrivateHeader>
    );
  }, [historyPush]);

  const rightArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={
          openPesquisa ? (
            <DesfazerIcon tipo="PRIVATE_HEADER"></DesfazerIcon>
          ) : (
            <FiltroIcon tipo="PRIVATE_HEADER"></FiltroIcon>
          )
        }
        tooltip={!openPesquisa ? 'Pesquisar' : 'Voltar'}
        onClick={() => setOpenPesquisa(!openPesquisa)}
      ></ButtonPrivateHeader>
    ),
    [setOpenPesquisa, openPesquisa],
  );

  const closePesquisaWrapper = useCallback(() => {
    setOpenPesquisa(false);
  }, [setOpenPesquisa]);

  const onPesquisar = useCallback(
    (props: SessaoValorSearchProps) => {
      setSearchProps(props);
    },
    [setSearchProps],
  );

  const pesquisa = (
    <PesquisaValor
      isOpened={openPesquisa!}
      onCloseSearch={closePesquisaWrapper}
      onPesquisar={onPesquisar}
    />
  );

  return (
    <>
      <PrivatePageHeader
        title={title}
        leftArea={leftArea()}
        bottomArea={pesquisa}
        rightArea={rightArea()}
      />
    </>
  );
};
