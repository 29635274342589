import { stringNumeros } from './../../../utils/string-numeros';
import data from './utils/impHomologadas.json'

type Modelos = {
    modelo: Array<string>
    titulo: string
    codigo: string
}

type Conversoes = {
    de: string
    para: string
}

type BarCode = {
    minAltura: number
    maxAltura: number
    maxLargura: number
    minLargura: number
    tamanhoSoma: number
    tamanhoEmChar: boolean
    colunas: number
    base64: boolean
}

type QrCode = {
    tamanhoSoma: number
    modoImagem?: boolean
}

export type Codigos = {
    codigo: string
    conversoes: Array<Conversoes>
    startDoc: string
    endDoc: string
    beep: string
    gaveta: string
    barCode: BarCode
    qrCode: QrCode
}

export interface impHomologadas {
    modelos: Array<Modelos>
    codigos: Array<Codigos>
}


export class ImpressaoSaurus {
    nomeCodigo: string


    constructor(
        nomeCodigo: string
    ) {
        this.nomeCodigo = nomeCodigo
    }

    codigoModelo() {
        const pegarCodigo = data.codigos.filter(item => item.codigo.toLowerCase() === this.nomeCodigo.toLowerCase())[0]
        
        return pegarCodigo
    }

    IsEncoded64() {
        return this.codigoModelo()!.barCode.base64
    }

    RepresentarTamanhoEmChar() {
        return this.codigoModelo()!.barCode.tamanhoEmChar
    }

    TraduzComando(comando: string) {
        let retorno: string = ''

        let xRetTextoSplit = comando.split(";")

        for (let xVal of xRetTextoSplit) {
            retorno += `${isNaN(parseInt(xVal)) ? xVal : String.fromCharCode(parseInt(xVal))}`
        }
        return retorno
    }

    ReplaceAll(valor: string, de: string, para: string) {
        while (valor.indexOf(de) > -1) {
            valor = valor.replace(de, para);
        }
        return valor;
    }


    RetQRCODE(qrCodeDados: string) {
        //POR CAUSA DO CARACTERE &NBSP DO HTML, removendo o &nbsp de dentro da tag
        qrCodeDados = qrCodeDados.replace("&nbsp", " ")

        let xRetConversaoQrCodeInicial = this.RetornaConversao("<qrCode>")
        let xRetConversaoQrCodeFinal = this.RetornaConversao("</qrCode>")

        if (xRetConversaoQrCodeInicial === null || (xRetConversaoQrCodeInicial?.para === "" || xRetConversaoQrCodeInicial?.para === null)) {
            return ""
        }

        let tamanho = qrCodeDados.length + (this.codigoModelo()!.qrCode.tamanhoSoma === null ? 0 : this.codigoModelo()!.qrCode.tamanhoSoma)

        let minByte = (tamanho & 0xff)
        // eslint-disable-next-line no-mixed-operators
        let maxByte = (tamanho >> 8 & 0xff)

        let xComandoQr: string | undefined = ""

        xComandoQr = xRetConversaoQrCodeInicial?.para.replace(("qrCode.minByte").toLowerCase(), String.fromCharCode(minByte))

        xComandoQr = xComandoQr?.replace("qrCode.maxByte", String.fromCharCode(maxByte))

        if (this.IsEncoded64()) {
            qrCodeDados = btoa(qrCodeDados)
        }
        if (this.codigoModelo()!.qrCode.modoImagem !== undefined && this.codigoModelo()!.qrCode.modoImagem) {

        }

        xComandoQr = xComandoQr?.replace("@qrCode.value", qrCodeDados)

        xComandoQr = this.TraduzComando(xComandoQr!)

        var appendEnd = this.TraduzComando(xRetConversaoQrCodeFinal?.para || '')

        return xComandoQr + appendEnd
    }

    NumberToASCII(valor: string) {
        let retorno: Array<number> = []

        for (let i = 0; i < valor.length; i++) {
            let code = valor.charCodeAt(i)

            retorno.push(code)
        }

        return retorno.join(';')
    }

    RetBarCode(barCode: string, xAltura: number, xLargura: number, xLegenda: number) {
        try {
            let xValorBarCode = ''

            let xRetBarCodeConversao = this.RetornaConversao('<barcode>')
            let xRetBarCodeConversaoFinal = this.RetornaConversao("</barcode>")

            if (xRetBarCodeConversao === null) return null

            xValorBarCode += xRetBarCodeConversao.para

            xAltura = (this.codigoModelo()!.barCode.maxAltura * xAltura / 100)

            if (xAltura < this.codigoModelo()!.barCode.minAltura) {
                xAltura = this.codigoModelo()!.barCode.minAltura
            }

            xLargura = (this.codigoModelo()!.barCode.maxLargura * xLargura / 100)

            if (xLargura < this.codigoModelo()!.barCode.minLargura) {
                xLargura = this.codigoModelo()!.barCode.minLargura
            }

            let altura = xAltura.toString()
            let largura = xLargura.toString()

            //pois o html tem que mandar em base64 para a API
            if (this.IsEncoded64()) {
                barCode = btoa(stringNumeros(barCode).toString())
            }

            // no html para funcionar a largura e altura precisam ser splitados e representados digito a digito em ascii
            if (this.RepresentarTamanhoEmChar()) {
                altura = this.NumberToASCII(altura)
                largura = this.NumberToASCII(largura)
            }

            xValorBarCode = xValorBarCode.replace('@barcode.altura', altura)
            xValorBarCode = xValorBarCode.replace('@barcode.largura', largura)
            xValorBarCode = xValorBarCode.replace('@barcode.value', barCode)
            xValorBarCode = xValorBarCode.replace('@barcode.legenda', xLegenda.toString())
            xValorBarCode = xValorBarCode.replace('@barcode.resultadosoma',
                (barCode.length * this.codigoModelo()!.barCode.tamanhoSoma).toString())

            xValorBarCode = this.TraduzComando(xValorBarCode!)

            var appendEnd = this.TraduzComando(xRetBarCodeConversaoFinal?.para || '')

            return xValorBarCode + appendEnd
        } catch {

        }
    }

    RetornaConversao(tag: string) {
        try {
            let res = this.codigoModelo()!.conversoes.filter(item =>
                item.de.toLowerCase() === tag.toLowerCase()
            )

            if (res.length === 0) throw new Error("Não encontrado a conversão")

            return res[0]
        } catch (e: any) {
            return null
        }
    }

    Traduz(xImpressao: string, danfe: boolean = false) {
        try {
            if (xImpressao.length === 0 || xImpressao === undefined) {
                return xImpressao
            }

            let xRetorno = xImpressao
            let startDoc = this.TraduzComando(this.codigoModelo()!.startDoc)

            for (let xMapa of this.codigoModelo()!.conversoes) {
                if (
                    xImpressao.indexOf(xMapa.de) > -1 &&
                    !(xMapa.de.indexOf("barcode") > -1) &&
                    !(xMapa.de.indexOf("qrCode") > -1)
                ) {
                    xRetorno = this.ReplaceAll(xRetorno, xMapa.de, this.TraduzComando(xMapa.para))
                }
            }

            xRetorno = startDoc + xRetorno

          
            let xRegexbarCode: RegExp | null = new RegExp("<barcode.*?>(.*?)</barcode>")
            let xRetMatchBarCode = xRetorno.match(xRegexbarCode)
            while (xRetMatchBarCode !== null) {
                const barcode = this.RetBarCode(xRetMatchBarCode![1], 25, 80, Number(stringNumeros(xRetMatchBarCode![1])))
                xRetorno = xRetorno.replace(xRetMatchBarCode![0], barcode ?? '')
                xRetMatchBarCode = xRetorno.match(xRegexbarCode)
            }
        
            let xRegex = new RegExp("<qrCODE>(.*?)</qrCODE>")
            let xRetMatch = xRetorno.match(xRegex);
            while (xRetMatch !== null) {
                const qr = this.RetQRCODE(xRetMatch![1])
                xRetorno = xRetorno.replace(xRetMatch![0], qr)
                xRetMatch = xRetorno.match(xRegex)
            }

            xRetorno += this.TraduzComando(this.codigoModelo()!.endDoc)

            return xRetorno

        } catch (e: any) {

        }
    }
}
