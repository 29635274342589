import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutFinalizarVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putFinalizarVenda = useCallback(
        (id: string, timeout: number = 30000) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Movimento/${id}/finalizar`,
                method: "PUT",
                enviarTokenUsuario: true,
                timeout: timeout
            }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putFinalizarVenda,
    };
}
