import { HistoricoVendaModel } from "model/api/gestao/historico-vendas/historico-vendas-model";
import { CardNaoEncontrado } from "../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from '../../../../../components/icons/informacao-icon';
import { CardEntrada } from "views/components/cards/card-entrada";

export interface EntradaGridProps {
    list: Array<HistoricoVendaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardClick: (model: HistoricoVendaModel) => any;
    onCardChecked: (id: string) => any;
    chavePdvRef: React.MutableRefObject<string | undefined>
}

export const EntradaListData = ((props: EntradaGridProps) => {

    const onCardClick = (model: HistoricoVendaModel) => {
        props.onCardClick(model);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado mensagem="Nenhuma Entrada encontrada." icon={<InformacaoIcon tipo="GERAL" />} />
            )}
            {props.list.length > 0 && props.list.map((pdv, index) => {
                return (
                    <CardEntrada
                        selected={
                            props.selectedList.filter((item) => item === pdv.id)
                                .length > 0
                                ? true
                                : false
                        }
                        onCheck={onCardChecked}
                        onClick={onCardClick}
                        model={pdv}
                        key={index}
                    />
                );
            })}

        </>
    );
}
);
