import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { CardSaloesProps } from './card-saloes-props';
import { EditarIcon } from '../../icons/editar-icon';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { TpBalcaoSalaoMock } from 'data/mocks/tp-balcao-salao-mock';
import { useCallback } from 'react';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { TpStatusSalaoMock } from 'data/mocks/tp-status-salao-mock';

export const CardSaloes = ({
  model,
  onClick,
  onCheck,
  selected,
}: CardSaloesProps) => {
  const classes = useDefaultCardStyles();
  const theme = useTheme();

  const descricao = TpStatusSalaoMock.filter(
    (x) => x.Key === model.status.codigo,
  )[0].Value;


  const retornarCorStatus = useCallback((): string => {
    switch (model.status.codigo) {
      case EnumStatusSalao.ATIVO:
        return theme.palette.success.main;
      case EnumStatusSalao.INATIVO:
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [
    theme.palette.success.main,
    theme.palette.error.main, 
    theme.palette.primary.main,
    model.status,
  ]);

  const tpBalcao = TpBalcaoSalaoMock.filter(
    (x) => x.Key === model.balcao.codigo,
  );

  return (
    <>
      <DefaultCard
        isSelected={selected}
        onClick={() => {
          onClick(model?.id ?? '');
        }}
      >
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        <div className={classes.cardContent}>
          <div className={classes.celulaGridFullDescriptionFull}>
            <Typography color="textPrimary" variant="caption">
              Descrição
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>{model.descricao}</strong>
            </Typography>
          </div>
          <div className={classes.celulaGrid}>
            <Typography color="textPrimary" variant="caption">
              Balcão
            </Typography>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {tpBalcao?.[0].Value ?? ""}
            </Typography>
          </div>
        </div>
        <Tooltip arrow title="Editar Salão">
          <div className={classes.cardRightButton}>
            <EditarIcon tipo="BUTTON" />
          </div>
        </Tooltip>
      </DefaultCard>
    </>
  );
};
