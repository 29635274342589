import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  divField: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  infoIcon: {
    height: 20,
    width: 20,
    fill: theme.palette.primary.main
  },
  tooltipText: {
    padding: theme.spacing(1),
    fontSize: '14px'
  }
}));