import { Box, Grid, Typography } from "@material-ui/core"
import { DefaultCard } from "../components"
import { EditarIcon } from "views/components/icons";
import { useStyles } from '../card-pessoa-endereco/card-pessoa-endereco-styles'
import { PessoaDocumentoModel } from "model/api/gestao/pessoa/pessoa-documento-model";
import { TpDocumentoMock } from "data/mocks/tp-documento-mock";
import classNames from "classnames";
import { isEmpty } from "lodash";

interface CardPessoaDocumentoProps {
    model: PessoaDocumentoModel;
    onClick: (model: PessoaDocumentoModel) => void;
}

export const CardPessoaDocumento = ({
    model,
    onClick,
}: CardPessoaDocumentoProps) => {
    const classes = useStyles();

    return (
        <DefaultCard onClick={() => onClick(model)} className={classes.card}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography color="textPrimary" variant="caption">
                        Documento
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {TpDocumentoMock.find(tipo => tipo.Key === model.tipo)?.Value || 'Outro'}
                    </Typography>
                </Grid>
                <Grid item xs={!isEmpty(model.ufExpedicao) ? 4 : 6}>
                    <Typography color="textPrimary" variant="caption">
                        Número
                    </Typography>
                    <Typography
                        className={classNames('celula-grid-value', classes.overflowText)}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.documento}
                    </Typography>
                </Grid>
                {!isEmpty(model.ufExpedicao) && <Grid item xs={2}>
                    <Typography color="textPrimary" variant="caption">
                        UF
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.ufExpedicao}
                    </Typography>
                </Grid>}
            </Grid>
            <Box className={classes.iconContainer}>
                <EditarIcon tipo='BUTTON' />
            </Box>
        </DefaultCard>
    )
}