import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';

interface LocationProps {
  pedido?: PedidoModel;
}

export const SelecaoVendedorHeader = () => {
  const history = useHistory();
  const location = useLocation<LocationProps>();

  const {
    getConfiguracoesMesaEComanda,
  } = usePedidoLocal();

  const isTipoTrabalhoComanda =
    getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA;
    
  const pedido = location.state?.pedido

  const handleClickVoltar = useCallback(() => {
    if(isTipoTrabalhoComanda){
      history.push(`/venda-simples/mesa-e-comanda/${pedido?.mesaId}`)
      return;
    }

    history.push({
      pathname: `/venda-simples/visualizar-pedido/${pedido?.comandaId ? pedido.comandaId : pedido?.mesaId}`,
      search: history.location.search ? history.location.search : pedido?.comandaId ? '?modo=comanda' : '?modo=mesa'
    });
  }, [history, isTipoTrabalhoComanda, pedido?.comandaId, pedido?.mesaId]);

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => handleClickVoltar()}
      ></ButtonPrivateHeader>
    );
  }, [handleClickVoltar]);

  return (
    <>
      <PrivatePageHeader title={'Selecionar Vendedor'} leftArea={leftArea()} />
    </>
  );
};
