import { useStyles } from './button-keyboard-add-styles';
import { ButtonKeyboardAddProps } from './button-keyboard-add-props';
import { AddIcon } from 'views/components/icons/add-icon';

export function ButtonKeyboardAdd(props: ButtonKeyboardAddProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.buttonKeyboardAddContainer}
      onClick={() => {
        if (props.clickFunc != null) props.clickFunc();
      }}
    >
      <AddIcon tipo="BUTTON" />
    </div>
  );
}
