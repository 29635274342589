import { ModulosPai } from 'data/mocks/modulos-pai-mock';
import { EnumMenu } from 'model';

export const retornaNomeModuloPai = (codigo: EnumMenu) => {
    return (
        ModulosPai.filter(
            (item) =>
                item.Key === codigo
        )?.[0]?.Value ?? ''
    );
};
