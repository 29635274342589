import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogVincularComanda } from 'views/components/dialog/dialog-vincular-comanda/dialog-vincular-comanda';

class DialogVincularComandaProps {
  constructor(public aberto: boolean = false, public mesaId: string = '') {}
}

export const VincularComandaDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogVincularComandaProps>(
    new DialogVincularComandaProps()
  );

  consoleDev('VincularComandaDialog');

  const modalAlterado = useCallback(({ aberto, mesaId }: any) => {
    setModalState({
      aberto,
      mesaId
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.VincularComandaDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.VincularComandaDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogVincularComanda
          aberto={modalState.aberto}
          mesaId={modalState.mesaId}
        />
      )}
    </>
  );
};
