import { CardNaoEncontrado } from "../../../../../../components/cards/card-naoencontrado/card-naoencontrado";
import { InformacaoIcon } from "../../../../../../components/icons/informacao-icon";
import { MarcaModel } from "model/api/gestao/marca/marca-model";
import { CardMarca } from "views/components/cards/card-marca";

export interface MarcaGridProps {
    list: Array<MarcaModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const MarcaListData = (props: MarcaGridProps) => {
    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhuma Marca Encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((marca, index) => {
                    return (
                        <CardMarca
                            selected={
                                props.selectedList.filter((item) => item === marca.id).length > 0 ? true : false
                            }
                            onCheck={onCardChecked}
                            onClick={onCardSelected}
                            model={marca}
                            key={index}
                        />
                    );
                })}
        </>
    );
};
