import { Box, Grid, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import classNames from 'classnames';
import { useStepperStyles } from 'views/components/stepper/stepper-styles';
import { useReceitaCadastro } from './use-receita-cadastro';
import { Stepper } from 'views/components/stepper';

export const VincularReceita = () => {
  const { fecharReceita } = useCadastros();
  const { formArray, formStepper } = useReceitaCadastro();

  const classes = useModalStyles();
  const stepperClasses = useStepperStyles();

  const onCloseClick = useCallback(() => {
    fecharReceita();
  }, [fecharReceita]);

  const getButtons = useMemo(() => {
    return (
      <div className={stepperClasses.stepButtons}>
        <Box mt={3}>
          <Grid spacing={1} container>
            {formArray[formStepper.currentStep].previousButton && (
              <Grid item xs={6} sm={6}>
                {formArray[formStepper.currentStep].previousButton}
              </Grid>
            )}

            {formArray[formStepper.currentStep].nextButton && (
              <Grid
                item
                xs={formArray[formStepper.currentStep].previousButton ? 6 : 12}
                sm={formArray[formStepper.currentStep].previousButton ? 6 : 12}
              >
                {formArray[formStepper.currentStep].nextButton}
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
    );
  }, [stepperClasses.stepButtons, formArray, formStepper.currentStep]);

  return (
    <div className={classes.root}>
      <ModalHeader
        title={'Dispensação'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div className={classNames(classes.contentForms)}>
          <div className={stepperClasses.stepper}>
            <Stepper
              linear={true}
              steps={formArray}
              stepAtual={formStepper.currentStep}
            />
          </div>
          <div className={stepperClasses.stepFix}>
            <div
              className={classNames(
                stepperClasses.step,
                stepperClasses.stepContent
              )}
            >
              <div className={stepperClasses.stepTitleContent}>
                <Typography
                  align="center"
                  className={stepperClasses.stepTitle}
                  variant="h4"
                  color="primary"
                >
                  {formArray[formStepper.currentStep].formTitle}
                </Typography>
                <Box mt={3}>
                  <Typography align="center" variant="body1">
                    {formArray[formStepper.currentStep].formDesc}
                  </Typography>
                </Box>
              </div>
              <div className={stepperClasses.stepForm}>
                {formArray[formStepper.currentStep].form}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.acoes}>
          {getButtons}
        </div>
      </div>
    </div>
  );
};
