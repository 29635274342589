import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ImpressoraModel } from "model/api/gestao/impressora/impressora-model";
import { VariaveisAmbiente } from "config";

export function usePostEquipamento() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postEquipamento = useCallback(
    (equipamento: ImpressoraModel, empresaId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/equipamento`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(equipamento),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postEquipamento,
  };
}
