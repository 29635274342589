import { Box, Button, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { isPlanoControleMesasComandas, isPlanoFarmaceutico } from 'utils/plano-utils';
import { qualPercent } from 'utils/qual-percent';
import { roundTo } from 'utils/round-to';
import { toDecimalString } from 'utils/to-decimal';
import { EditarIcon, GarcomIcon } from 'views/components/icons';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { useThemeQueries } from 'views/theme';
import { useStyles } from '../../mov-carrinho-page-styles';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { isEmpty } from 'lodash';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { useSessaoAtual } from 'services/app';
import { useStyles as myStyles } from './carrinho-informacoes-styles';
import { consoleDev } from 'utils/console-dev';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

type Props = {
};

export const CarrinhoValoresPorTaxa = ({ ...props }: Props) => {
  const { theme } = useThemeQueries();
  const useClasses = useStyles();
  const classes = myStyles();
  const { addHandler, removeHandler } = useEventTools();
  const { getMov, hasTaxaServicoProduto } = useMovAtual();
  const { getConfigByCod } = useContratoAtual();
  const { showToast } = useToastSaurus();
  const { plano } = useSessaoAtual();
  const { getProdutoServico } = useCadastroPadrao();
  const [produtoServico, setProdutoServico] = useState<boolean>(false);
  const {
    // abrirDialogInformacaoAdicional,
    abrirDialogTaxaServicoCarrinho
  } = useCadastros()
  const { getConfigByCod: getConfigPDV } = usePDV();
  const { isPedidoDelivery } = usePedidoDelivery()
  const modeloTrabalho = getConfigPDV(EnumPDVConfigCod.ModeloTrabalho) ?? '';
  const valorTaxa = useRef<HTMLParagraphElement | null>(null);
  const valorVenda = useRef<HTMLParagraphElement | null>(null);
  // const infAdicional = useRef<HTMLParagraphElement | null>(null);
  // const valorEntrega = useRef<HTMLParagraphElement | null>(null);
  // const history = useHistory()
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  consoleDev('CarrinhoValoresPorTaxa');
  const retTxtTaxa = useCallback(() => {
    const mov = getMov();

    let valorTaxa = 0;
    let valorProdutos = 0;

    mov?.produtos
      .filter((x) => x.ativo && x.indFin)
      .forEach((x) => {
        if (x.produtoId === getConfigByCod(EnumContratoConfig.ProdutoServico))
          valorTaxa += x.vFinal;

        if (x.cobraTaxaServico)
          valorProdutos += x.vFinal;
      });

    const valorTotal = valorProdutos ?? 0;
    return `R$ ${toDecimalString(roundTo(valorTaxa))} (${roundTo(qualPercent(valorTaxa, valorTotal), 0) ?? 0
      }%)`;
  }, [getConfigByCod, getMov]);

  const retTxtVenda = useCallback(() => {
    const valor = getMov()?.vNF || 0;

    return `R$ ${toDecimalString(roundTo(valor ?? 0))}`;
  }, [getMov]);

  // const retTxtEntrega = useCallback(() => {
  //   const valor = getMov()
  //     ?.produtos.filter(
  //       (x) =>
  //         x.ativo &&
  //         x.produtoId === getConfigByCod(EnumContratoConfig.ProdutoEntrega) &&
  //         x.indFin
  //     )
  //     .reduce((a, b) => a + b.vFinal, 0);

  //   return `R$ ${toDecimalString(roundTo(valor ?? 0))}`;

  // }, [getConfigByCod, getMov])

  // const retTxtInfAdicional = useCallback(() => {
  //   return getMov()?.infAdicional || '-'
  // }, [getMov])

  const controleMesasComandas = isPlanoControleMesasComandas(plano?.plano);

  const temProdutoCobraTaxa =
    (getMov()?.produtos.filter((x) => x.cobraTaxaServico && x.ativo).length ??
      0) > 0;

  const movProdAlterado = useCallback(() => {
    const venda = retTxtVenda();
    // const informacao = retTxtInfAdicional()
    // const entrega = retTxtEntrega()

    if (valorTaxa.current) {
      valorTaxa.current.textContent = retTxtTaxa();
    }

    if (valorVenda.current) {
      valorVenda.current.textContent = venda;
    }
    // if (infAdicional.current) {
    //   infAdicional.current.textContent = informacao;
    // }
    // if (valorEntrega.current) {
    //   valorEntrega.current.textContent = entrega;
    // }
  }, [retTxtTaxa, retTxtVenda]);

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    addHandler(AppEventEnum.MovAtualAlterada, movProdAlterado);
    return () => {
      if (valorTaxa.current) {
        valorTaxa.current = null;
      }
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
      removeHandler(AppEventEnum.MovAtualAlterada, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler, getMov]);

  const produtoTaxaWrapper = useCallback(async () => {
    const produto = await getProdutoServico();

    if (produto) {
      setProdutoServico(produto.ativo);
    }
  }, [getProdutoServico]);

  useEffect(() => {
    produtoTaxaWrapper();
  }, [produtoTaxaWrapper]);

  const lancandoProduto = useMemo(() => {
    const mov = getMov();
    if (
      !isEmpty(mov?.informacoesGeraisPedido.comandaId) ||
      !isEmpty(mov?.informacoesGeraisPedido.mesaId)
    ) {
      return true;
    }

    return false;
  }, [getMov]);

  return isPedidoDelivery() || (modeloTrabalho !== EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS &&
    produtoServico &&
    !lancandoProduto) ? (
    <Box
      style={{
        display:
          controleMesasComandas && temProdutoCobraTaxa ? 'inherit' : 'none'
      }}
    >
      <Divider style={{ backgroundColor: theme.palette.primary.main }} />
      <Box paddingX={1} pt={1}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={6} md={'auto'}>
            <Grid className={classes.cardValores}>
              <Grid className={useClasses.containerIcon}>
                <SacoDinheiroIcon
                  fill={theme.palette.primary.main}
                  style={{ height: '40px' }}
                />
              </Grid>
              <Grid className={classes.contentValor}>
                <Typography className={useClasses.textTitle}>
                  Valor da Venda
                </Typography>
                <Grid className={classes.alinharContent}>
                  <Box paddingRight={1}>
                    <Typography ref={valorVenda} className={classes.fontStrong}>
                      {retTxtVenda()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {(!isPedidoDelivery() || !isFarmaceutico) && hasTaxaServicoProduto() && (
            <Grid item sm={6} xs={6} md={'auto'}>
              <Button
                className={classes.cardValores}
                fullWidth
                onClick={() => {
                  if (
                    (
                      getMov()?.produtos.filter(
                        (x) =>
                          x.produtoId ===
                          getConfigByCod(EnumContratoConfig.ProdutoServico)
                      ) ?? []
                    ).length <= 0
                  ) {
                    showToast('info', 'Não há taxa.');
                    return;
                  }
                  abrirDialogTaxaServicoCarrinho();
                }}
                classes={{
                  label: classes.btnLabel
                }}
              >
                <Grid className={useClasses.containerIcon}>
                  <GarcomIcon
                    fill={theme.palette.primary.main}
                    style={{ height: '40px' }}
                  />
                </Grid>
                <Grid className={classes.contentValor}>
                  <Typography className={useClasses.textTitle}>
                    Taxa de Serviço
                  </Typography>
                  <Grid className={classes.alinharContent}>
                    <Box paddingRight={1}>
                      <Tooltip title="Este valor é calculado pelos produtos que cobram a taxa de serviço." arrow>
                        <Typography ref={valorTaxa} className={classes.fontStrong}>
                          {retTxtTaxa()}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <EditarIcon
                      fill={theme.palette.primary.main}
                      style={{ height: '16px', margin: 0 }}
                    />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          )}
          {/* {isPedidoDelivery() && (
            <>
              <Grid item sm={6} xs={6} md={'auto'}>
                <Button
                  fullWidth
                  className={classes.cardValores}
                  onClick={() => history.push('/venda-simples/entrega-frete')}
                  classes={{
                    label: classes.btnLabel
                  }}
                >
                  <Grid className={useClasses.containerIcon}>
                    <DeliveryIcon
                      fill={theme.palette.primary.main}
                      style={{ height: '40px' }}
                    />
                  </Grid>
                  <Grid className={classes.contentValor}>
                    <Typography className={useClasses.textTitle}>
                      Taxa de Entrega
                    </Typography>
                    <Grid className={classes.alinharContent}>
                      <Box paddingRight={1}>
                        <Tooltip title="Taxa calculada para entrega" arrow>
                          <Typography ref={valorEntrega} className={classes.fontStrong}>
                            {retTxtEntrega()}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <EditarIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '16px', margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item sm={12} xs={12} md={'auto'}>
                <Button
                  fullWidth
                  className={classes.cardValores}
                  onClick={() => abrirDialogInformacaoAdicional('', false)}
                  classes={{
                    label: classes.btnLabel
                  }}
                >
                  <Grid className={useClasses.containerIcon}>
                    <InformacaoIcon
                      fill={theme.palette.primary.main}
                      style={{ height: '40px' }}
                    />
                  </Grid>
                  <Grid className={classes.contentValor}>
                    <Typography className={useClasses.textTitle}>
                      Informação Adicional
                    </Typography>
                    <Grid className={classes.alinharContent}>
                      <Box paddingRight={1}>
                        <Tooltip title="Informação Adicional para a entrega" arrow>
                          <Typography ref={infAdicional} className={classNames(classes.infAdicional, classes.ellipsis)}>
                            {getMov()?.infAdicional || '-'}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <EditarIcon
                        fill={theme.palette.primary.main}
                        style={{ height: '16px', margin: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </>
          )} */}
        </Grid>
      </Box>
    </Box>
  ) : (
    <></>
  );
};
