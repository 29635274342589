import { Button, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useStyles } from './dialog-definicoes-styles';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useThemeQueries } from 'views/theme';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { usePDV } from 'services/app/hooks/pdv';
import { useHistory } from 'react-router-dom';
import { PutConfiguracoesPontosVendaProps, usePatchConfiguracoesPontosVenda } from 'data/api/gestao/pontos-venda/put-configuracoes-pontos-venda';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { toDecimalString } from 'utils/to-decimal';
import { roundTo } from 'utils/round-to';
import { qualPercent } from 'utils/qual-percent';
import { CircularLoading } from 'views/components/utils';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { EditarIcon, GarcomIcon, HistoricoIcon, LixoIcon, MaisIcon, OlhoAbertoIcon } from 'views/components/icons';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { SwitchSaurus } from 'views/components/controles';
import { DescontoIcon } from 'views/components/icons/desconto-icon';
import { AcrescimoIcon } from 'views/components/icons/acrescimo-icon';
import { DividirIcon } from 'views/components/icons/dividir-icon';
import { isPlanoControleMesasComandas, isPlanoFarmaceutico } from 'utils/plano-utils';
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { EnumMovModelo } from 'model';
import { PontosVendaConfiguracoesModel } from 'model/api/gestao/pontos-venda/pontos-venda-configuracoes-model';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { NfceIcon } from 'views/components/icons/nfce-icon';
import { NfeIcon } from 'views/components/icons/nfe-icon';

export const DialogDefinicoes = () => {
  const refTaxa = useRef<HTMLParagraphElement>(null);
  const { getRegistro, setRegistro, } = useGestaoStorage();
  const { abrirVendaAcrescDesc, abrirDialogAlterarQuantidadeClientesVenda, abrirDialogTaxaServicoCarrinho } = useCadastros();
  // HOOKS
  const { getEmpresaSelecionada, plano } = useSessaoAtual();
  const { getConfigByCod } = useContratoAtual()
  const { getConsumidor } = useCadastroPadrao();
  const { getMov, alterarTpMod, hasTaxaServicoProduto } = useMovAtual();
  const { getPDV, procurarMeuPDV } = usePDV();
  const { push, location } = useHistory();
  const { callEvent } = useEventTools();

  const getCancelados = useCallback(() => {
    const cancelados = getRegistro(
      GestaoStorageKeys.Cancelados,
      false
    ) as boolean;

    if (cancelados) {
      return cancelados;
    }

    return false;
  }, [getRegistro]);

  // STATES E REFS
  const [tipoVenda, setTipoVEnda] = useState<
    undefined | EnumMovModelo
  >(undefined);
  const [tipoDeVendaText, setTipoDeVendaText] = useState<string>('')
  const [canceladosCarrinho, setCanceladosCarrinho] = useState(getCancelados());
  const [formTipoDeVenda, setFormTipoDeVenda] = useState<boolean>(false)

  // CALL API
  const { patchConfigPontosVenda, carregando: loadingPutConfig } =
    usePatchConfiguracoesPontosVenda();

  // AUX
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();
  const { fecharDialogDefinicoes } = useCadastros();
  const cadastroClasses = useDefaultCadastroStyles();
  const classes = useStyles();
  const carregando = loadingPutConfig;
  const { isMobile, theme: { palette } } = useThemeQueries();

  const retTxtTaxa = useCallback(() => {
    const mov = getMov();

    let valorTaxa = 0;
    let valorProdutos = 0;

    mov?.produtos
      .filter((x) => x.ativo && x.indFin)
      .forEach((x) => {
        if (x.produtoId === getConfigByCod(EnumContratoConfig.ProdutoServico))
          valorTaxa += x.vFinal;

        if (x.cobraTaxaServico)
          valorProdutos += x.vFinal;
      });

    const valorTotal = valorProdutos ?? 0;
    return `R$ ${toDecimalString(roundTo(valorTaxa))} (${roundTo(qualPercent(valorTaxa, valorTotal), 0) ?? 0
      }%)`;
  }, [getConfigByCod, getMov]);

  const saveChangesConfiguracoes = useCallback(
    async (configuracoes: Array<PontosVendaConfiguracoesModel>, id: string) => {
      const ret = await patchConfigPontosVenda(
        configuracoes.map(
          (x) => new PutConfiguracoesPontosVendaProps(x.id, x.cod, x.vConfig)
        ),
        getEmpresaSelecionada()?.Id || '',
        id
      );
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [patchConfigPontosVenda, getEmpresaSelecionada]
  );

  const movModeloString: any = useMemo(() => ({
    [EnumMovModelo.ORCAMENTO]: 'Venda Simples',
    [EnumMovModelo.NFCE]: 'NFC-e',
    [EnumMovModelo.NFE]: 'NF-e',
  }), [])

  const handleClickTypeOrder = useCallback(
    async (type: EnumMovModelo) => {
      const config =
        getPDV()?.configuracoes.map((c) => {
          if (c.cod === EnumPDVConfigCod.ModeloVenda) {
            if (c.vConfig === (movModeloString?.[type] || 'Venda Simples')) {
              return c;
            }

            return {
              ...c,
              vConfig: movModeloString?.[type] || 'Venda Simples'
            };
          }

          return c;
        }) ?? [];

      await saveChangesConfiguracoes(config, getPDV()?.id ?? '');
      setTipoVEnda(type);
      alterarTpMod(type);
      procurarMeuPDV(true);
      setTipoDeVendaText(movModeloString?.[type])

    }, [getPDV, saveChangesConfiguracoes, alterarTpMod, procurarMeuPDV, movModeloString]);

  const setCancelados = useCallback(
    (cancelados: boolean) => {
      setRegistro(GestaoStorageKeys.Cancelados, cancelados, false);
      setCanceladosCarrinho(cancelados);
    },
    [setRegistro]
  );

  useEffect(() => {
    const mov = getMov()

    if (!mov) return

    setTipoVEnda(mov.mod);
    setTipoDeVendaText(movModeloString?.[mov.mod])
  }, [getMov, movModeloString]);

  const handleCancel = useCallback(async () => {
    const consumidorPadrao = await getConsumidor();
    if (
      consumidorPadrao &&
      getMov()!.produtos.length <= 0 &&
      (consumidorPadrao.id === getMov()!.cliente?.id ||
        consumidorPadrao?.cpfcnpj === getMov()!.cliente?.cpfcnpj)
    ) {
      showToast('info', 'Não há produtos na venda.');
      return;
    }
    fecharDialogDefinicoes();
    push('/venda-simples/cancelar');
  }, [fecharDialogDefinicoes, getConsumidor, getMov, push, showToast]);

  const validarRota = (termo: string) => {
    return location.pathname.toLowerCase().includes(termo.toLowerCase()) ?? '/venda-simples/catalogo';
  };

  const handleClickEdit = useCallback(() => {
    fecharDialogDefinicoes()
    push('/venda-simples/identificar-cliente');
  }, [push, fecharDialogDefinicoes]);

  const movProdAlterado = useCallback(
    (any: any) => {
      if (refTaxa.current)
        refTaxa.current.textContent = retTxtTaxa();
    },
    [retTxtTaxa]
  );

  useEffect(() => {
    addHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    return () => {
      removeHandler(AppEventEnum.AtualizarTaxa, movProdAlterado)
    }
  }, [addHandler, removeHandler, movProdAlterado])

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  return (
    <Grid className={cadastroClasses.root} style={{ background: '#fff' }}>
      <ModalHeader
        title={!formTipoDeVenda ? 'Definições da Venda' : 'Tipo de Venda'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={!formTipoDeVenda ? fecharDialogDefinicoes : () => { setFormTipoDeVenda(false) }}
          />
        }
      />
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      {!formTipoDeVenda ? (

        <>
          <Grid className={cadastroClasses.list}>
            <Grid className={classes.content} >
              <Grid xs={10} alignItems='center' className={classes.gridFlex} >
                <PessoaIcon tipo="GERAL" fill={palette.primary.main} style={{ width: 26, height: 26, marginRight: 8 }} />
                <Grid className={classes.userDataContent}>
                  <Grid>
                    <Typography >
                      Nome: <strong>{getMov()?.cliente?.nome}</strong>
                    </Typography>
                  </Grid>
                  <Grid style={{ display: 'flex' }} >
                    <Typography >
                      Documento: <strong>{getMov()?.documento ? formatarCPFCNPJ(getMov()?.documento || '') : ''}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={2} justifyContent='flex-end' className={classes.gridFlex}>
                <Button
                  onClick={handleClickEdit}
                >
                  <EditarIcon
                    tipo="GERAL"
                    fill={palette.primary.main}
                    style={{ width: 26, height: 26 }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container className={classes.contentOpcao} onClick={() => { setFormTipoDeVenda(true) }} alignItems='center'>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid className={classes.gridFlex} alignItems='center'>
                  <ImpressoraIcon
                    tipo="GERAL"
                    style={{ width: 26, height: 26, marginRight: 8 }}
                    fill={palette.primary.main}
                  />
                  <Typography className={classes.sessaoTextItem}>
                    Tipo de Venda
                  </Typography>
                </Grid>
                <Grid className={classes.gridFlex}>
                  <Grid className={classes.tipoDeVenda}>{tipoDeVendaText}
                  </Grid>
                  <Grid>
                    <MaisIcon tipo='INPUT' fill={palette.secondary.main} style={{ margin: '0' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {validarRota('carrinho') && (
              <>
                <Grid
                  container
                  className={classes.contentOpcao}
                >
                  <Grid container alignItems="center">
                    <OlhoAbertoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Visualizar produtos cancelados
                    </Typography>
                  </Grid>
                  <Grid className={classes.gridCheck} justifyContent='flex-end'>
                    <SwitchSaurus
                      variant={isMobile ? 'DARK' : 'LIGHT'}
                      checked={canceladosCarrinho}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCancelados(event.target.checked);
                        callEvent(AppEventEnum.AtualizarCanceladosCarrinho, event.target.checked);
                      }}
                      className={classes.switchSelect}
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </>

            )}

            {validarRota('pagamento') && (
              <>
                <Grid
                  container
                  className={classes.contentOpcao}
                  onClick={() => abrirVendaAcrescDesc('desc')}
                >
                  <Grid container alignItems="center">
                    <DescontoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Desconto
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                  container
                  className={classes.contentOpcao}
                  onClick={() => abrirVendaAcrescDesc('acresc')}
                >
                  <Grid container alignItems="center">
                    <AcrescimoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Acréscimo
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                  container
                  className={classes.contentOpcao}
                  onClick={() => abrirDialogAlterarQuantidadeClientesVenda()}
                >
                  <Grid container alignItems="center">
                    <DividirIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Dividir Conta
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                  container
                  className={classes.contentOpcao}
                  onClick={() => {
                    push('/venda-simples/pagamentos')
                  }}
                >
                  <Grid container alignItems="center">
                    <HistoricoIcon
                      tipo="GERAL"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      fill={palette.primary.main}
                    />
                    <Typography className={classes.sessaoTextItem}>
                      Histórico de Pagamento
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </>
            )}
            {isPlanoControleMesasComandas(plano?.plano) && validarRota('carrinho') && (
              <>
                {!isFarmaceutico && hasTaxaServicoProduto() && (
                  <>
                    <Grid
                      container
                      className={classes.contentOpcao}
                      onClick={() => {
                        if (
                          (
                            getMov()?.produtos.filter(
                              (x) =>
                                x.produtoId ===
                                getConfigByCod(EnumContratoConfig.ProdutoServico)
                            ) ?? []
                          ).length <= 0
                        ) {
                          showToast('info', 'Não há taxa.');
                          return;
                        }
                        abrirDialogTaxaServicoCarrinho()
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                      >
                        <GarcomIcon
                          tipo="GERAL"
                          style={{ width: 24, height: 24, marginRight: 8 }}
                          fill={palette.primary.main}
                        />
                        <Typography
                          className={classes.sessaoTextItem}
                        >
                          Taxa de Serviço
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ width: 100, display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <div>
                          <Typography
                            className={classes.sessaoTextItem}
                            align="right"
                            style={{ fontWeight: 600, fontSize: 12 }}
                          >
                            Valor
                          </Typography>
                          <Typography
                            ref={refTaxa}
                            className={classes.sessaoTextItem}
                            style={{ fontWeight: 600, fontSize: 12, textAlign: 'right' }}
                            color="secondary"
                          >
                            {retTxtTaxa()}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                  </>
                )}
              </>

            )}

            <Grid
              container
              className={classes.contentOpcao}
              onClick={handleCancel}
            >
              <Grid container alignItems="center">
                <LixoIcon
                  tipo="GERAL"
                  style={{ width: 24, height: 24, marginRight: 8 }}
                  fill={'red'}
                />
                <Typography
                  className={classes.sessaoTextItem}
                >
                  Cancelar venda
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <CarrinhoIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Venda simples
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.ORCAMENTO}
              onClick={() => handleClickTypeOrder(EnumMovModelo.ORCAMENTO)}
              control={<SaurusRadio />}
            />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <NfceIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Nota Fiscal Consumidor
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.NFCE}
              onClick={() => handleClickTypeOrder(EnumMovModelo.NFCE)}
              control={<SaurusRadio />}
            />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.contentOpcao}
          >
            <Grid container alignItems="center">
              <NfeIcon
                tipo="GERAL"
                style={{ width: 24, height: 24, marginRight: 8 }}
                fill={palette.secondary.main}
              />
              <Typography className={classes.sessaoTextItem}>
                Nota Fiscal Eletrônica
              </Typography>
            </Grid>
            <FormControlLabel
              className={classes.labelRadio}
              label=""
              checked={tipoVenda === EnumMovModelo.NFE}
              onClick={() => handleClickTypeOrder(EnumMovModelo.NFE)}
              control={<SaurusRadio />}
            />
          </Grid>
        </>
      )
      }
    </Grid >
  );
};
