import { useCallback, FocusEvent, useState, useEffect } from "react";
import {
    retornoAutoComplete,
    AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";
import { useGetPessoaById, useGetPessoas } from "data/api/gestao/pessoa";
import { PessoaModel } from "model/api/gestao/pessoa";
import { EnumCadastroStatus, EnumCadastroTipo } from "model";
import { isEmpty } from "lodash";
import { guidEmpty } from "utils/guid-empty";
import { tpCadastroAutocomplete } from "data/mocks";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { stringNumeros } from "utils/string-numeros";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";

export enum EnumNomeCnpj {
    Nome,
    Cnpj,
    Todos
}


export interface AutocompletePessoaProps {
    label?: string;
    loadingExterno?: boolean;
    name?: string;
    value?: string;
    error?: boolean;
    helperText?: string | undefined;
    placeholder?: string;
    allowSubmit?: boolean;
    disabled?: boolean;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
    onChange?: (value: retornoAutoComplete) => any;
    tipo?: EnumCadastroTipo
    nomeCnpj?: EnumNomeCnpj
    fetchOnLoad?: boolean;
    permiteNovo?: boolean
    textoNovoItem?: string
    isRepresentantePreenhido?: boolean;
    exibirTipo?: boolean;
    mensagemNaoEncontrado?: string;
    status?: EnumCadastroStatus[];
    listarValorSelecionado?: boolean;
}

export interface PessoaDadoAdicional extends PessoaModel {
    estendido: string
}

export const AutocompletePessoas = ({
    onChange,
    loadingExterno,
    ...props
}: AutocompletePessoaProps) => {
    const { getPessoas, carregando: carregandoGet } = useGetPessoas()
    const { getPessoaById, carregando: carregandoById } = useGetPessoaById();
    const [pessoas, setPessoas] = useState<Array<PessoaDadoAdicional>>([]);

    const carregando = carregandoGet || carregandoById;

    const getPessoasWrapper = useCallback(
        async (termo: string) => {
            try {
                const temDoc = termo.includes(' - ') ? termo.split(' - ')[0].trimEnd() : '';
                if(temDoc && (validarCPFCNPJ(stringNumeros(temDoc)) || temDoc === 'Sem Documento')){
                    termo = termo.split(' - ')[1].trimStart();
                }
                const query = `${termo ? 'Generico=' + termo : ''}${props.tipo ? '&TpCadastro=' + props.tipo : ''}`
                const ret = await getPessoas(query);
                if (ret.erro) throw ret.erro;
                let pessoasArray: Array<PessoaDadoAdicional> = [];
                if (ret.resultado?.data?.list?.length > 0) {
                    pessoasArray = (ret.resultado?.data?.list as PessoaModel[])
                        .filter(x => {
                            if (props.status && props.status.length > 0) {
                                if (!props.status.includes(x.status)) return false;
                            }
                            return props.nomeCnpj === EnumNomeCnpj.Cnpj
                                ? x.cpfcnpj
                                : props.nomeCnpj === EnumNomeCnpj.Nome ?
                                    x.nome
                                    : x
                        }
                        )
                        .map(pessoa => {
                            const estendido = (
                                `${pessoa.cpfcnpj.includes(termo) || pessoa.nome.includes(termo) || pessoa.documentos.some(x => x.documento.includes(termo))
                                    ? pessoa.cpfcnpj
                                        ? (formatarCPFCNPJ(pessoa.cpfcnpj) + ' - ')
                                        : ''
                                    : pessoa.documentos.find(doc => doc.documento.includes(termo))?.documento
                                        ? pessoa.documentos.find(doc => doc.documento.includes(termo))?.documento + ' - '
                                        : ''
                                }${pessoa.nome
                                }`)
                            return {
                                ...pessoa,
                                estendido
                            }
                        });
                }
                setPessoas(pessoasArray);
            } catch (e: any) { }
        }, [getPessoas, props.nomeCnpj, props.status, props.tipo]);

    const getPessoaByIdWrapper = useCallback(async (id: string) => {
        try {
            const res = await getPessoaById(id);
            if (res.erro) throw res.erro

            const data = res.resultado?.data as PessoaModel;

            setPessoas([{
                ...data,
                estendido: (`${data.cpfcnpj ? (data.cpfcnpj + ' - ') : ''}${data.nome}`)
            }]);
        } catch (e: any) { }
    }, [getPessoaById])

    const onChangeWrapper = useCallback(
        (value: retornoAutoComplete) => {
            if (onChange) onChange(value);
        },
        [onChange]
    );
    const onPesquisa = useCallback((termo: string) => {
        getPessoasWrapper(termo)
    }, [getPessoasWrapper]);

    const optionKey = (value: EnumNomeCnpj | undefined) => ({
        [EnumNomeCnpj.Nome]: 'nome',
        [EnumNomeCnpj.Cnpj]: 'cpfcnpj',
        [EnumNomeCnpj.Todos]: 'estendido',
    })[value || EnumNomeCnpj.Nome]

    useEffect(() => {
        if (props.fetchOnLoad && isEmpty(pessoas) && !isEmpty(props.value) && props.value !== guidEmpty()) {
            getPessoaByIdWrapper(props.value || '')
        }
    }, [getPessoaByIdWrapper, getPessoasWrapper, pessoas, props.fetchOnLoad, props.value]);

    return (
        <AutocompleteSaurus
            disabled={props.disabled}
            name={props.name}
            loading={(carregando)}
            onPesquisa={onPesquisa}
            fullWidth
            onChange={onChangeWrapper}
            opcoes={pessoas}
            optionLabelKey={optionKey(props.nomeCnpj)}
            optionValueKey={'id'}
            onBlur={props.onBlur}
            value={props.value !== guidEmpty() ? props.value : ''}
            allowSubmit={props.allowSubmit}
            noOptionsText={props.isRepresentantePreenhido || pessoas.length > 0 ? '' : props.mensagemNaoEncontrado ?? "Pessoa não Localizada"}
            label={props.label}
            helperText={props.helperText}
            error={props.error}
            placeholder={props.placeholder}
            permiteNovo={props.permiteNovo}
            textoNovoItem={props.textoNovoItem}
            tipoComplete="PESSOA"
            externalInput={props.fetchOnLoad}
            categoryMock={props.exibirTipo ? tpCadastroAutocomplete : undefined}
            optionCategoryKey={props.exibirTipo ? 'tpCadastro' : undefined}
            listarValorSelecinado
        />
    );
};
