import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { ComandasModal } from "views/components/modals/comandas/comanda-modal";

export const CadastroComandasModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        rfid: false,
        rfidECod: false
    });

    const comandasRoute = `/gerenciar/comandas/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroComandasModal')

    useEffect(() => {
        if (location.pathname.indexOf(comandasRoute) === 0) {
            const posrota = location.pathname.replace(comandasRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: comandasRoute,
                    rfid: prev.rfid,
                    rfidECod: prev.rfidECod
                }));
                return;
            }
        }
    }, [location.pathname, comandasRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl, rfid, rfidECodigo }: any) => {
        let url = callbackUrl.length === 0 ? comandasRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            rfid: rfid,
            rfidECod: rfidECodigo
        });
        if (!openned) {
            push(comandasRoute)
        }
        if (trocarUrl) {
            push(comandasRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
    }, [modalState.aberto, modalState.ultimaUrl, comandasRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.ComandasModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.ComandasModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <ComandasModal openned={modalState.aberto}
            key="mdlPessoa"
            id={modalState.id}
            rfid={modalState.rfid}
            rfidECod={modalState.rfidECod}
        />
    )
}