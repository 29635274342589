import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
export const AddOutlineIcon = ({
  class: className,
  fill,
  style,
  tipo,
  viewBox
}: DefaultIconProps) => {
  return (
    <DefaultIcon
      tipo={tipo}
      class={className}
      style={style}
      fill={fill}
      viewBox={viewBox}
    >
      <g clipPath="url(#clip0_3227_11509)">
        <path d="M3.6611 3.66117C1.299 6.02327 -0.011588 9.16258 0.00365141 12.5C-0.011588 15.8374 1.299 18.9767 3.6611 21.3388C6.0232 23.7009 9.16251 25.0115 12.4999 24.9963C15.8526 24.9963 18.9767 23.7009 21.3388 21.3388C23.7009 18.9767 25.0115 15.8374 24.9962 12.5C24.9962 9.14734 23.7009 6.02327 21.3388 3.66117C18.9767 1.29906 15.8374 -0.0115224 12.4999 0.00371689C9.16251 -0.0115222 6.0232 1.29906 3.6611 3.66117ZM4.57546 4.57553C6.69373 2.45726 9.49778 1.29883 12.4999 1.28359C15.5021 1.26835 18.3061 2.45726 20.4244 4.57553C22.5427 6.6938 23.7011 9.49784 23.7163 12.5C23.7011 15.5022 22.5427 18.3062 20.4244 20.4245C18.3061 22.5427 15.5021 23.7012 12.4999 23.7164C9.49778 23.7317 6.69373 22.5427 4.57546 20.4245C2.45719 18.3062 1.29876 15.5022 1.28352 12.5C1.26828 9.49784 2.45719 6.6938 4.57546 4.57553ZM7.93765 12.0333C7.82145 12.1495 7.75293 12.3096 7.76055 12.4848C7.76055 12.8506 8.04998 13.14 8.40048 13.1248L11.86 13.1399L11.8448 16.5994C11.8448 16.9652 12.1342 17.2546 12.4848 17.2394C12.6676 17.2394 12.8353 17.1633 12.9419 17.0566C13.0486 16.95 13.1247 16.7823 13.1247 16.5994L13.1399 13.1399L16.5842 13.1399C16.7671 13.1399 16.9347 13.0639 17.0414 12.9572C17.1481 12.8505 17.2241 12.6829 17.2241 12.5C17.2241 12.1343 16.9347 11.8448 16.5842 11.8601L13.1399 11.8601V8.41573C13.1399 8.04998 12.8504 7.76068 12.4999 7.74544C12.1342 7.74544 11.8448 8.03486 11.86 8.38537V11.8601L8.40048 11.8449C8.21761 11.8449 8.05385 11.9171 7.93765 12.0333Z" />
        <path
          d="M3.6611 3.66117C1.299 6.02327 -0.011588 9.16258 0.00365141 12.5C-0.011588 15.8374 1.299 18.9767 3.6611 21.3388C6.0232 23.7009 9.16251 25.0115 12.4999 24.9963C15.8526 24.9963 18.9767 23.7009 21.3388 21.3388C23.7009 18.9767 25.0115 15.8374 24.9962 12.5C24.9962 9.14734 23.7009 6.02327 21.3388 3.66117C18.9767 1.29906 15.8374 -0.0115224 12.4999 0.00371689C9.16251 -0.0115222 6.0232 1.29906 3.6611 3.66117ZM4.57546 4.57553C6.69373 2.45726 9.49778 1.29883 12.4999 1.28359C15.5021 1.26835 18.3061 2.45726 20.4244 4.57553C22.5427 6.6938 23.7011 9.49784 23.7163 12.5C23.7011 15.5022 22.5427 18.3062 20.4244 20.4245C18.3061 22.5427 15.5021 23.7012 12.4999 23.7164C9.49778 23.7317 6.69373 22.5427 4.57546 20.4245C2.45719 18.3062 1.29876 15.5022 1.28352 12.5C1.26828 9.49784 2.45719 6.6938 4.57546 4.57553ZM7.93765 12.0333C7.82145 12.1495 7.75293 12.3096 7.76055 12.4848C7.76055 12.8506 8.04998 13.14 8.40048 13.1248L11.86 13.1399L11.8448 16.5994C11.8448 16.9652 12.1342 17.2546 12.4848 17.2394C12.6676 17.2394 12.8353 17.1633 12.9419 17.0566C13.0486 16.95 13.1247 16.7823 13.1247 16.5994L13.1399 13.1399L16.5842 13.1399C16.7671 13.1399 16.9347 13.0639 17.0414 12.9572C17.1481 12.8505 17.2241 12.6829 17.2241 12.5C17.2241 12.1343 16.9347 11.8448 16.5842 11.8601L13.1399 11.8601V8.41573C13.1399 8.04998 12.8504 7.76068 12.4999 7.74544C12.1342 7.74544 11.8448 8.03486 11.86 8.38537V11.8601L8.40048 11.8449C8.21761 11.8449 8.05385 11.9171 7.93765 12.0333Z"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3227_11509">
          <rect width="25" height="25" />
        </clipPath>
      </defs>
    </DefaultIcon>
  );
};
