import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const PrimeiroIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 13.984375 3.9863281 A 1.0001 1.0001 0 0 0 13 5 L 13 45 A 1.0001 1.0001 0 1 0 15 45 L 15 25.015625 L 15 5 A 1.0001 1.0001 0 0 0 13.984375 3.9863281 z M 15 25.015625 A 1.0001 1.0001 0 0 0 15.292969 25.707031 L 35.292969 45.707031 A 1.0001 1.0001 0 1 0 36.707031 44.292969 L 17.414062 25 L 36.707031 5.7070312 A 1.0001 1.0001 0 0 0 35.980469 3.9902344 A 1.0001 1.0001 0 0 0 35.292969 4.2929688 L 15.292969 24.292969 A 1.0001 1.0001 0 0 0 15 25.015625 z" />
            </DefaultIcon>
        </>
    );
};


