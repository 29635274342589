import * as Yup from 'yup';

export const useFormInformacaoAdicionalValidation = () => {

  const formInformacaoAdicionalYupValidation = Yup.object().shape({
    infAdicional: Yup.string().notRequired(),
  });

  return {
    formInformacaoAdicionalYupValidation
  }
}

