import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogFechamento } from 'views/components/dialog/dialog-fechamento/dialog-fechamento';

class DialogFechamentoProps {
  constructor(
    public aberto: boolean = false,
    public pedidos: PedidoModelComanda[] = []
  ){}
}

export const FechamentoDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogFechamentoProps>(
    new DialogFechamentoProps()
  );

  consoleDev('FechamentoDialog');

  const modalAlterado = useCallback(
    ({ aberto, pedidos }: any) => {
      setModalState({
        aberto,
        pedidos,
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.FechamentoDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.FechamentoDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogFechamento
          openned={modalState.aberto}
          pedidos={modalState.pedidos}
        />
      )}
    </>
  );
};
