import {
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { useSessaoAtual } from 'services/app';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-plano-styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { CircularLoading, useThemeQueries } from 'views';
import { VoltarIcon } from 'views/components/icons';
interface ModalPlanoProps {
  openned: boolean;
  loading: boolean;
  model: PlanoModel;
  closeModal: () => void;
  changePlanoAtual: (plano: PlanoModel | undefined) => void;
}

export const DialogPlano = ({
  openned,
  closeModal,
  model,
  changePlanoAtual,
  loading,
}: ModalPlanoProps) => {
  const classes = useStyles();
  const { plano } = useSessaoAtual();
  const { theme, isMobile } = useThemeQueries();
  const showContratar = plano?.plano?.id !== model.id && changePlanoAtual;

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo={`Detalhe do Plano ${model.nome}`}
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box key={model.id} className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ flex: 1 }}>
              <Tooltip title={model!.descricao} placement="top">
                <Typography
                  variant="caption"
                  color="secondary"
                  className={classes.cardSubTitle}
                  paragraph
                >
                  {model?.descricao}.<br />
                  <br />
                  Verifique todas as funcionalidades abaixo:
                </Typography>
              </Tooltip>

              <Box
                className={
                  isMobile ? classes.containerItemMobile : classes.containerItem
                }
              >
                {!!model?.opcoes &&
                  model.opcoes.length > 0 &&
                  model?.opcoes
                    .filter((item) => item.exibir > 0)
                    .sort(function (a, b) {
                      if (a.nome < b.nome) {
                        return -1;
                      }
                      if (a.nome > b.nome) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((itemOpcoes, index) => {
                      return (
                        <Grid className={classes.list} key={index}>
                          {parseInt(itemOpcoes.valor) > 0 ? (
                            <CheckIcon color="primary" fontSize="small" />
                          ) : (
                            <CloseIcon color="error" fontSize="small" />
                          )}
                          <Tooltip title={itemOpcoes.descricao} placement="top">
                            <Typography
                              variant={'body2'}
                              color={
                                itemOpcoes.exibir === 2
                                  ? 'textSecondary'
                                  : 'textPrimary'
                              }
                              className={classes.cardOptionItem}
                              noWrap
                            >
                              &nbsp;&nbsp;{itemOpcoes.nome}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      );
                    })}
              </Box>

              <Typography
                align="center"
                variant="h6"
                color="primary"
                className={classes.cardPricingR$}
              >
                Valor do Plano
              </Typography>
              <Divider
                style={{ backgroundColor: theme.palette.primary.main }}
              />

              {model?.valor === 0 && model?.valorPromo === 0 && (
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.cardPricingFree}
                >
                  Grátis
                </Typography>
              )}

              {model?.valor !== 0 && model?.valorPromo === model?.valor && (
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.cardPricingR$}
                  >
                    R$
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.cardPricing}
                  >
                    {model?.valor.toFixed(2).replace('.', ',')}
                  </Typography>
                  <Box className={classes.cardTextPerMonth}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.textPerMonth}
                    >
                      /mês
                    </Typography>
                  </Box>
                </Box>
              )}

              {model?.valorPromo > 0 && model?.valorPromo !== model?.valor && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {model?.valorPromo < model?.valor && <div className={classes.cardContentDesconto}>
                      <div className={classes.cardPricingDesconto}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classes.cardPricingR$}
                        >
                          R$
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classes.cardPricing}
                        >
                          {model?.valor.toFixed(2).replace('.', ',')}
                        </Typography>
                      </div>
                    </div>}
                    <div className={classes.cardValorDesconto}>
                      <Typography
                        variant="h5"
                        color="primary"
                        className={classes.cardPricingR$}
                      >
                        R$
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                        className={classes.cardPricing}
                      >
                        {model?.valorPromo.toFixed(2).replace('.', ',')}
                      </Typography>
                      <Box className={classes.cardTextPerMonth}>
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.textPerMonth}
                        >
                          /mês
                        </Typography>
                      </Box>
                    </div>
                  </Grid>

                </Grid>
              )}
            </Box>

            <Grid
              container
              spacing={2}
              className={classes.cardAcoes}
              style={{
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              {/* O MELHOR DIA ESTÁ POR PADRÃO FIXADO NO DIA 5, SE ALGUM DIA SER NECESSÁRIO PERSONALIZAR O MELHOR DIA NOVAMENTE VOU DEIXAR O CÓDIGO COMENTADO AQUI */}
              {/* {model?.valor !== 0 && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '0px',
                  }}
                >
                  <Grid>
                    <Typography>Escolha o seu melhor dia.</Typography>
                  </Grid>
                  <MelhorDia setMelhorDia={setMelhorDia} />
                </Grid>
              )} */}

              <Grid item xs={isMobile || !showContratar ? 12 : 4}>
                {closeModal && (
                  <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>

              {showContratar && (
                <Grid item xs={isMobile ? 12 : 8}>
                  <Button
                    disabled={loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      changePlanoAtual(model);
                    }}
                  >
                    <UpgradeIcon tipo="BUTTON_PRIMARY"></UpgradeIcon>
                    {'Contratar Plano'}
                  </Button>
                  {/* {!showContratar && (
                  <Button
                    disabled={
                      loading
                    }
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      changePlanoAtual(model);
                    }}
                  >
                    <UpgradeIcon tipo="BUTTON_PRIMARY"></UpgradeIcon>
                    {'Atualizar Plano'}
                  </Button>
                )} */}
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};