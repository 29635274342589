import { guidEmpty } from "utils/guid-empty";
import { EnumTpSubItem } from "../../../../enums/enum-tpsubitem";

export class ProdutoSubItemModel {
  constructor(
    public id: string | null = null,
    public produtoGradeId: string = guidEmpty(),
    public produtoSubGradeId: string = guidEmpty(),
    public produtoSubId: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public modificadorId: string | null = null,
    public tipo: EnumTpSubItem = EnumTpSubItem.InsumoProducao,
    public ordem: number = 0,
    public fator: number = 1,
    public qPadrao: number = 0,
    public qMin: number = 0,
    public qMax: number = 0,
    public produtoSubGrade: ProdutoSubGradeModel = new ProdutoSubGradeModel(),
    public modificador: ProdutoSubItemModificador = new ProdutoSubItemModificador(),
  ) { }
}
export class ProdutoNovoSubItemModel {
  constructor(
    public id: string | null = null,
    public produtoGradeId: string = guidEmpty(),
    public produtoSubGradeId: string | null = guidEmpty(),
    public modificadorId: string | null = null,
    public tipo: EnumTpSubItem = EnumTpSubItem.InsumoProducao,
    public ordem: number = 0,
    public fator: number = 1,
    public qPadrao: number = 0,
    public qMin: number = 0,
    public qMax: number = 0,
  ) { }
}
export class ProdutoSubGradeModel {
  constructor(
    public id: string = guidEmpty(),
    public descricao: string = ""
  ) { }
}

export class ProdutoSubItemModificador {
  constructor(
    public id: string = guidEmpty(),
    public descricao: string = ''
  ) { }
}
