import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export interface IdentidadeValidarRedefinicaoProps {
  email?: string | null;
  telefone?: string | null;
  redefinirCodigo: string;
}

export function usePostIdentidadeValidarRedefinicao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postIdentidadeValidarRedefinicao = useCallback(
    (redefinicaoProps: IdentidadeValidarRedefinicaoProps) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/Auth/validar-redefinir-senha`,
        method: 'POST',
        data: redefinicaoProps,
        enviarTokenUsuario: false
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postIdentidadeValidarRedefinicao
  };
}
