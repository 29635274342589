import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { TutorialPostModel } from 'model/api/gestao/tutorial/tutorial-post-model';

export function usePostTutorial() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postTutorial = useCallback(
    (payload: TutorialPostModel) => {
      return invocarApi({
        
        url: `/${VariaveisAmbiente.gatewayVersion}/Tutorial`,
        method: 'POST',
        enviarTokenUsuario: true,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(payload),
        baseURL: `${VariaveisAmbiente.apiUrl}/api`
      });
    },
    [invocarApi]
  );
  
  return {
    ...outrasPropriedades,
    postTutorial
  };
}
