import { usePostEquipamentoImpressaoLocal } from 'data/api/gestao/equipamento/post-equipamento-impressao-local';
import {
  ImpressaoLocalModel,
  Equipamento,
  DadosImpressao
} from 'model/api/gestao/impressora/impressao-local-model';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import React from 'react';
import { usePDV } from './pdv';
import { useToastSaurus } from './toast-saurus';
import { useSessaoAtual } from '../providers';
import { picker } from 'utils/picker';
import { newGuid } from 'utils/new-guid';

export const useImpressaoLocal = () => {
  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getImpressoraPdv } = usePDV();
  const { showToast } = useToastSaurus();

  // CALL API
  const {
    postEquipamentoImpressaoLocal,
    carregando: carregandoImpressaoLocal
  } = usePostEquipamentoImpressaoLocal();

  const impressaoLocalDataWrapper = (
    externalId: string, 
    externalCode: string, 
    conteudoImpressao: string,
    tipoImpressao: string,
    impressaoModel: ImpressoraModel
  ) => {

    const equipamento = picker<Equipamento>(impressaoModel, new Equipamento());

    const impressao = new DadosImpressao(
      newGuid(),
      equipamento.id,
      conteudoImpressao, 
      tipoImpressao,
      externalCode,
      externalId,
      equipamento.quantidadeVias
    );

    const modelImpressor = new ImpressaoLocalModel(
      impressao,
      equipamento
    );

    return modelImpressor;
  };

  const enviarImpressaoLocal = React.useCallback(
    async (externalId: string, externalCode: string, cupomTraduzido: string, tipo: string, qtdColunas?: number | null) => {
      const impressora = getImpressoraPdv();

      if (!impressora) {
        return;
      }

      const modelImpressor = impressaoLocalDataWrapper(
        externalId,
        externalCode,
        cupomTraduzido,
        tipo,
        { ...impressora, colunas: qtdColunas ? qtdColunas : impressora.colunas },

      );

      const res = await postEquipamentoImpressaoLocal(
        getEmpresaSelecionada()?.Id ?? '',
        modelImpressor
      );

      if (res.erro) {
        throw new Error(res.erro);
      }

      showToast('success', 'Impressão enviada');
    },
    [getEmpresaSelecionada, getImpressoraPdv, postEquipamentoImpressaoLocal, showToast]
  );

  return {
    carregandoImpressaoLocal,
    enviarImpressaoLocal,
  };
};
