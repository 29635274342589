import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minWidth: '130px',
    height: '70px',
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'transparent',
    transition: '0.3s ease-in-out border',
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    cursor: 'pointer',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '75px',
    position: 'relative'
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  buttonCalc: {
    background: '#FFFFFF',
    boxShadow: '0px 1.8px 7.2px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px'
  },
  textInput: {
    '& input': {
      textAlign: 'right',
      fontSize: '15px'
    }
  },
  descricao: {
    fontWeight: 600
  },
  alinharItensCentro: {
    display: 'flex',
    alignItems: 'center'
  }
}));
