import { Box, Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-vincular-contratos-styles';
import { CircularLoading } from '../../..';
import { useEffect, useRef } from 'react';
import { FormLogin } from 'views/components/form/master/login/form-login';
import { useCadastros, useToastSaurus } from 'services/app';
import { LoginFormModel } from 'model/app';
import { DefaultFormRefs } from 'views/components/form/utils';
// import { usePostDashboardAdminVincularContrato } from 'data/api/gestao/relatorios/dashboard-admin/post-dashboard-admin-vincular-contratos';
import { IdentidadeAutenticarEmailProps, usePostIdentidadeAutenticarEmail } from 'data/api/gestao/identidade';
// import { VincularContratosModel } from 'model/api/gestao/contratos/vincular-contrato-model';
// import { guidEmpty } from 'utils/guid-empty';

interface DialogNovoPDVProps {
  openned: boolean;
}

export const DialogVincularContratos = ({
  openned
}: DialogNovoPDVProps) => {
  const classes = useStyles();

  const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);
  // const { postDashboardAdminVincularContrato, carregando: postVincularContrato } = usePostDashboardAdminVincularContrato()
  const { postIdentidadeAutenticarEmail, carregando: postIdentidadeAutenticarCarregando } = usePostIdentidadeAutenticarEmail()
  // const { convertToken } = useGestaoToken()
  const { showToast } = useToastSaurus()
  const { fecharCadastroContratos } = useCadastros()


  const carregando = postIdentidadeAutenticarCarregando;

  const vincularLogin = async (model: LoginFormModel) => {
    try {

      const retApi = await postIdentidadeAutenticarEmail(
        new IdentidadeAutenticarEmailProps(model.usuario, model.senha),
      );

      if (retApi.erro) throw retApi.erro

      // const token = convertToken(retApi.resultado?.data?.accessToken);

      // const res = await postDashboardAdminVincularContrato(new VincularContratosModel('123456', [token?.contratoId || guidEmpty()]))

      // if (res.erro) throw res.erro

      showToast('success', 'Contrato vinculado!')

      fecharCadastroContratos()

    } catch (e: any) {
      showToast('error', e.message)
    }
  };

  useEffect(() => {
    if (loginRefs) loginRefs.current?.fillForm(new LoginFormModel('', ''));
  }, []);

  return (
    <>
      {openned &&
        <DialogSaurus
          aberto={openned || false}
          titulo="Vincular Contrato"
          tamanho="xs"
          handleClickClose={fecharCadastroContratos}
          isButtonTitleClose
        >
          {carregando && <CircularLoading tipo="FULLSIZED" />}
          <Box className={classes.cardContainer}>
            <Box className={classes.cardContent}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <FormLogin
                  loading={false}
                  showLoading={false}
                  ref={loginRefs}
                  onSubmit={vincularLogin}
                />
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  <Grid item xs={12}>
                    <Button color="primary" variant="contained" type="submit" onClick={() => loginRefs.current?.submitForm()} fullWidth size="large">
                      Vincular
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogSaurus>
      }
    </>
  );
};
