import { Grid, Button, useTheme } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AtualizarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SincronizacaoHeader } from '../components/sincronizacao-header';
import { SincronizacaoSucessoErroTabs } from './components/Sucesso-erro-tabs/sincronizacao-sucesso-erro-tabs';

export const SincronizacaoSucessoErroPage = () => {
  const classes = useDefaultCadastroStyles();
  const theme = useTheme();
  const confirm = useConfirm();
  const { push } = useHistory();
  const header = useMemo(() => {
    return (
      <Grid className={classes.header}>
        <SincronizacaoHeader />
      </Grid>
    );
  }, [classes.header]);

  const handleClickRessincronizar = useCallback(() => {
    confirm({
      title: `Nova Sincronização.`,
      description: `Deseja iniciar uma nova sincronização? Obs: Este processo pode demorar alguns instantes.`,
      cancellationText: 'Voltar',
      cancellationButtonProps: {
        color: 'default',
        className: 'round'
      },
      confirmationButtonProps: {
        className: 'round'
      },
      confirmationText: 'Confirmar',
    }).then(() => {
      push('/venda-simples/sincronizar-dados');
    });
  }, [confirm, push]);

  const buttons = useMemo(() => {
    return (
      <Grid style={{ margin: theme.spacing(1) }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClickRessincronizar}
          style={{ height: '52px' }}
          className='round'
        >
          <AtualizarIcon tipo="BUTTON_PRIMARY" />
          Sincronizar Novamente
        </Button>
      </Grid>
    );
  }, [handleClickRessincronizar, theme]);

  return (
    <Grid className={classes.root}>
      {header}
      <Grid className={classes.list}>
        <SincronizacaoSucessoErroTabs />
      </Grid>
      {buttons}
    </Grid>
  );
};

export default SincronizacaoSucessoErroPage;
