import { useCallback } from 'react';
import { useApiBase } from 'data/api/base';
import { VariaveisAmbiente } from 'config';

export function useGetMedicamentoPreco() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMedicamentoPreco = useCallback(
        (queryBusca: string) => {
            let queryPage = "";
       
        let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
          }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/MedicamentoPreco${queryFinal}`,
                method: 'GET',
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMedicamentoPreco
    };
}
