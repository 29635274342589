import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '100%',
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 4,
    margin: 0,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    color: '',
    fontWeight: 700
  },
  subTitle: {
    color: '',
    fontWeight: 500
  },
  titleCentralizado: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  dialogContent: {
    padding: '',
    height: ''
  }
}));
