import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ManifestoAcaoModel } from "model/api/gestao/manifesto/manifesto-acao-model";
import { EnumAcaoManifesto } from "model/enums/enum-acao-manifesto";
import { VariaveisAmbiente } from "config";

export function usePostManifestoAcao() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postManifestoAcao = useCallback(
        (cnpj: string, chave: string, acao: EnumAcaoManifesto, model: ManifestoAcaoModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${cnpj}/Documento/${chave}/evento/${acao}`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: model,
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postManifestoAcao,
    };
}