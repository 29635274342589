import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export class PoliticaAceiteProps {
  constructor(
    public referencia: string = '',
    public termoId: string = '',
    public localizacao: string = '',
    public aceito: boolean = false,
    public nome: string = '',
    public documento: string = '',
    public ip: string = ''
  ) {}
}

export function usePutPoliticaAceitar() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putPoliticaAceitar = useCallback(
    (props: PoliticaAceiteProps) =>
      invocarApi({
        url: `/v3/TermoAceite`,
        method: 'PUT',
        data: props,
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putPoliticaAceitar
  };
}
