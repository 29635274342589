import * as Yup from 'yup';

export const useDialogNovoPdvValidation = () => {

    const FormDialogNovoPdvYupValidation = Yup.object().shape({
        descricao: Yup.string().required('Este campo é obrigatório'),
        numero: Yup.string().required('Este campo é obrigatório'),
    });

    return {
        FormDialogNovoPdvYupValidation
    }
}

