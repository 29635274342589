import { KeyValueModel } from "model/api";
import { EnumBalanca } from '../../model/enums/enum-balanca';


export const BalancaMock: KeyValueModel[] = [
  new KeyValueModel(EnumBalanca.Normal, "Não Pesável"),  
  new KeyValueModel(EnumBalanca.Unitario, "Carga Unitária"),
  new KeyValueModel(EnumBalanca.PesadoVenda, "Pesado na Venda"),
  new KeyValueModel(EnumBalanca.Pesavel, "Pesado na Venda + Carga"),
  new KeyValueModel(EnumBalanca.Glaciado, "Glaciado"),
];
