import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(() => ({
    containerStatus: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        minWidth: '200px',
        alignItems: 'right',
        justifyContent: 'flex-end',
    }
}))