import { Box, Button, Card, Grid } from "@material-ui/core"
import { FormPeRastroCamposProps } from "./form-pe-rastro-campos-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { LixoIcon, OkIcon } from "views/components/icons"
import { useState } from "react"
import { useStyles } from "./form-pe-rastro-campos-styles"
import CardRastro from "views/components/cards/card-rastro/card-rastro"
import { useToastSaurus } from "services/app"

export const FormPeRastroCampos = ({ control, formState, index, item, remove, getValues, getFieldState, ...props }: FormPeRastroCamposProps) => {
    const classes = useStyles()
    const value = getValues(`rastro.${index}`)
    const [openned, setOpenned] = useState<boolean>(!(value.nLote.length !== 0 && value.qLote !== 0))
    const { showToast } = useToastSaurus()

    const handleConfirmar = () => {
        if (!(value.nLote.length !== 0 && value.qLote && value.qLote !== 0)) {
            showToast('error', 'Precisa preencher o formulário de lote')
            return
        }
        setOpenned(false)
    }
    return (
        <Grid item xs={12}>
            {openned ? (
                <Card>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name={`rastro.${index}.nLote`}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="NUMERO"
                                            label="Lote"
                                            {...field}
                                            error={Boolean(
                                                getFieldState(`rastro.${index}.nLote`)?.error
                                            )}
                                            helperText={
                                                getFieldState(`rastro.${index}.nLote`)?.error ?
                                                    getFieldState(`rastro.${index}.nLote`).error?.message
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name={`rastro.${index}.qLote`}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="DECIMAL"
                                            manterMascara
                                            casasDecimais={3}
                                            label="Quantidade"
                                            {...field}
                                            error={Boolean(
                                                getFieldState(`rastro.${index}.qLote`)?.error
                                            )}
                                            helperText={
                                                getFieldState(`rastro.${index}.qLote`)?.error ?
                                                    getFieldState(`rastro.${index}.qLote`).error?.message
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name={`rastro.${index}.cAgreg`}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="NUMERO"
                                            manterMascara
                                            casasDecimais={3}
                                            label="Código de Agregação"
                                            {...field}
                                            error={Boolean(
                                                getFieldState(`rastro.${index}.cAgreg`)?.error
                                            )}
                                            helperText={
                                                getFieldState(`rastro.${index}.cAgreg`)?.error ?
                                                    getFieldState(`rastro.${index}.cAgreg`).error?.message
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name={`rastro.${index}.dFab`}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="DATA"
                                            label="Data de Fabricação"
                                            {...field}
                                            error={Boolean(
                                                getFieldState(`rastro.${index}.dFab`)?.error
                                            )}
                                            helperText={
                                                getFieldState(`rastro.${index}.dFab`)?.error ?
                                                    getFieldState(`rastro.${index}.dFab`).error?.message
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name={`rastro.${index}.dVal`}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="DATA"
                                            label="Data de Validade"
                                            {...field}
                                            error={Boolean(
                                                getFieldState(`rastro.${index}.dVal`)?.error
                                            )}
                                            helperText={
                                                getFieldState(`rastro.${index}.dVal`)?.error ?
                                                    getFieldState(`rastro.${index}.dVal`).error?.message
                                                    : undefined
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" className={classes.btnRemover} fullWidth variant="outlined" onClick={() => remove(index)}>
                                    <LixoIcon tipo="BUTTON" />
                                    Remover
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" fullWidth variant="contained" onClick={handleConfirmar}>
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            ) : (
                <CardRastro model={getValues(`rastro.${index}`)} onClick={() => setOpenned(true)} />
            )}
        </Grid >
    )
}