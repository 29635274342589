import { VariaveisAmbiente } from 'config';
import { useApiBase } from 'data/api/base';
import { useCallback } from 'react';

export class IdentidadeAutenticarEmailProps {
    public constructor(
        public email: string = '',
        public senha: string = '',
        public filtros?: {
            contratoId?: string
        }
    ) { }
}

export function usePostIdentidadeAutenticarEmail() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeAutenticarEmail = useCallback(
        (autenticacaoProps: IdentidadeAutenticarEmailProps) => {
            
            return invocarApi({
                method: 'POST',
                url: `/${VariaveisAmbiente.gatewayVersion}/Auth/token-email`,
                data: autenticacaoProps,
                enviarTokenUsuario: false,
            })
        },
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeAutenticarEmail,
    };
}
