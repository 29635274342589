import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const BandeiraIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M15.4017 5C11.3832 5 8.90524 5.39677 7.4022 5.82477C6.64912 6.03722 6.13978 6.2684 5.8023 6.4496C5.69293 6.50896 5.63356 6.57145 5.55231 6.62456C5.53356 6.63705 5.49607 6.63705 5.47732 6.64955C5.39295 6.69329 5.31483 6.75265 5.25233 6.8245C5.24295 6.8245 5.23671 6.8245 5.22733 6.8245L5.20233 6.87449C5.17421 6.89636 5.14921 6.92135 5.12734 6.94947L5.00234 7.04944V7.24938C4.99922 7.28375 4.99922 7.31499 5.00234 7.34936V44.1893C4.99922 44.4767 5.14921 44.7454 5.3992 44.8922C5.64918 45.0359 5.95541 45.0359 6.2054 44.8922C6.45538 44.7454 6.60537 44.4767 6.60225 44.1893V29.5183C6.93661 29.384 7.4397 29.1965 8.35215 28.9684C9.87706 28.5842 12.2144 28.1937 15.4017 28.1937C18.5234 28.1937 20.8389 28.9622 23.6513 29.7682C26.4636 30.5743 29.7384 31.3928 34.6006 31.3928C40.219 31.3928 44.6 28.8935 44.6 28.8935L45 28.6685V6.84949L43.8251 7.49932C43.1689 7.85547 39.4097 9.79869 34.6006 9.79869C30.779 9.79869 28.1135 8.674 25.3012 7.47432C22.4888 6.27465 19.5296 5 15.4017 5ZM15.4017 6.59956C19.2046 6.59956 21.8639 7.72426 24.6762 8.92393C27.4885 10.1236 30.4602 11.3983 34.6006 11.3983C38.9066 11.3983 41.8908 10.1236 43.4001 9.3988V27.6688C42.8532 27.9625 39.3597 29.7932 34.6006 29.7932C29.9196 29.7932 26.8511 29.0122 24.0762 28.2187C21.3014 27.4251 18.7859 26.5941 15.4017 26.5941C12.0832 26.5941 9.62707 27.0034 7.97717 27.4189C7.0866 27.6438 7.02723 27.725 6.60225 27.8937V7.82423C6.83036 7.70551 7.22096 7.55243 7.85218 7.37435C9.1646 7.00258 11.4895 6.59956 15.4017 6.59956Z" />
            </DefaultIcon>
        </>
    );
};