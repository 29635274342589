import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    body: {
        position: 'fixed',
        zIndex: 9999,
        pointerEvents: "none",
        top: '60px',
        bottom: '60px',
        left: '16px',
        right: '16px',
        inset: '40px 8px',
    },
    main: {
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
    },
    card: {
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#363636',
        boxShadow: '0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%)',
        maxWidth: 485,
        pointerEvents: 'auto',
        padding: '8px 16px',
        borderRadius: 8
    },
    close: {
        height: "100%",
        display: 'flex',
        justifyContent: 'end',
        width: '50px'
    },
    typeValor: {
        height: 20,
        borderRight: ".13em solid #666666",
        animation: "$blinkCaret 1s step-end infinite"
    },
    "@keyframes blinkCaret": {
        "from, to": { borderColor: "transparent" },
        "50%": { borderColor: "#666666" }
    }
}));
