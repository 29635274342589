import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const AcrescimoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          d="M15.0402 27.1371C14.1705 27.1371 13.3186 27.1549 12.4489 27.1192C12.023 27.1014 11.9165 27.2261 11.9165 27.6538C11.9342 29.3465 11.9165 31.0214 11.9342 32.7141C11.9342 33.1418 11.8455 33.3021 11.384 33.3021C10.1772 33.2665 8.97029 33.2665 7.76341 33.3021C7.3197 33.32 7.24871 33.1596 7.24871 32.7676C7.26645 31.0749 7.24871 29.4 7.26645 27.7072C7.26645 27.2618 7.15996 27.1371 6.69851 27.1371C4.99468 27.1727 3.29085 27.1371 1.58702 27.1549C1.17881 27.1549 0.983576 27.0836 1.00132 26.6203C1.03682 25.4978 1.03682 24.3931 1.00132 23.2705C0.983576 22.8251 1.14331 22.7538 1.53377 22.7538C3.2376 22.7716 4.94143 22.7538 6.64527 22.7716C7.10672 22.7716 7.26645 22.6647 7.24871 22.1836C7.21321 20.4909 7.24871 18.816 7.23096 17.1233C7.23096 16.6956 7.33745 16.5887 7.76341 16.5887C8.95254 16.6244 10.1239 16.6244 11.3131 16.5887C11.7745 16.5709 11.9342 16.6956 11.9165 17.1767C11.881 18.8694 11.9165 20.5443 11.8987 22.2371C11.8987 22.6647 12.0052 22.7716 12.4312 22.7716C14.1528 22.7538 15.8921 22.7716 17.6137 22.7538C18.0574 22.7538 18.1994 22.8429 18.1816 23.3062C18.1461 24.4287 18.1639 25.5334 18.1816 26.656C18.1816 27.048 18.0751 27.1727 17.6847 27.1549C16.815 27.1192 15.9276 27.1371 15.0402 27.1371Z"
          fill={props.fill}
        />
        <path
          d="M24.1805 37.5785C26.4523 34.2287 28.6531 31.0036 30.8539 27.7785C34.2438 22.8073 37.6515 17.836 41.0236 12.8469C41.2898 12.4371 41.5738 12.2945 42.0708 12.3124C43.2244 12.348 44.378 12.3302 45.5494 12.3302C45.6027 12.6153 45.4074 12.74 45.3009 12.9004C39.7812 20.9898 34.2615 29.0792 28.7418 37.1687C28.5999 37.3647 28.5111 37.5963 28.1916 37.5963C26.896 37.5785 25.6181 37.5785 24.1805 37.5785Z"
          fill={props.fill}
        />
        <path
          d="M49.1168 27.5647C48.0874 25.1592 46.1529 23.9476 43.5261 23.9476C40.9171 23.9476 39.0181 25.1236 37.9177 27.5291C37.474 28.5091 37.2965 29.5603 37.261 30.6472C37.261 31.449 37.2965 32.233 37.4917 33.017C38.1306 35.4938 39.5683 37.2221 42.1772 37.7567C44.7862 38.2912 47.2887 37.3469 48.6021 35.2087C50.1107 32.7676 50.2172 30.1483 49.1168 27.5647ZM45.7269 33.3378C45.3009 34.2821 44.6443 34.8879 43.5616 34.9058C42.55 34.9236 41.698 34.3712 41.3076 33.3378C40.6864 31.6985 40.6864 30.0414 41.3253 28.42C41.6803 27.5112 42.3902 26.9589 43.4019 26.9054C44.4135 26.852 45.1235 27.3687 45.6027 28.2418C46.0641 29.0792 46.1351 30.0058 46.1351 30.9501C46.1351 31.752 46.0641 32.5716 45.7269 33.3378Z"
          fill={props.fill}
        />
        <path
          d="M32.4334 17.052C31.9365 14.4684 30.0019 12.5084 27.5349 12.0985C24.7484 11.6353 22.2104 12.8291 20.9681 15.1811C20.0096 16.9985 19.9564 18.9407 20.2936 20.9007C21.0923 25.4622 26.47 27.5647 30.1439 24.7672C32.0075 23.3418 32.5931 21.2927 32.6109 19.012C32.5931 18.3705 32.5754 17.7113 32.4334 17.052ZM28.5111 21.6134C28.0851 22.4865 27.4107 22.9854 26.4168 23.0032C25.4229 23.0211 24.6774 22.54 24.287 21.6669C23.5061 19.9207 23.506 18.1211 24.2692 16.3749C24.6774 15.4484 25.4229 14.9316 26.4878 14.9673C27.5704 15.0207 28.2271 15.6622 28.6175 16.6065C28.937 17.3727 29.008 18.1924 29.008 19.0654C29.008 19.9207 28.9193 20.7938 28.5111 21.6134Z"
          fill={props.fill}
        />
      </DefaultIcon>
    </>
  );
};
