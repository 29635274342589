import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        width: "100%"
    },
    content: {
        padding: 16,
        width: "100%"
    },
    card: {
        padding: '12px 6px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width:'100%',
        justifyContent: 'space-around',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
            alignItems:'start'
        },

    },
    cardContent: {
        display: 'flex'
    },
    imagem: {
        marginRight: 8
    },
    nome: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
  
    },
    btnDashed: {
        borderStyle: 'dashed',
        borderColor: '#A8A8A8',
        color: '#A8A8A8'
    },
    gridBtn: {
        marginBottom: 16
    },
    containerCards: {
        position: "relative"
    },
    cardImg: {
        display: "flex",
        objectFit: "cover",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: "50px",
        height: "50px",
        borderRadius: "5px",
        transition: "0.2s ease-in-out transform",
        "&:hover": {
            transform: "scale(1.2)",
        }
    },
    legendaText: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            alignItems: 'start'
        },
    },
    legenda: {
        height:'100%',
        textAlign: 'left',
        justifyContent: 'space-between',


    },
 
    botoesEdicao: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing:'border-box',
        margin:'0 1rem',
      
        [theme.breakpoints.down("xs")]: {
            margin: '1rem auto'
        },
    },
    botaoEdicao: {
        display: 'flex',
        justifyContent: 'space-around',
        margin:0,
        padding: 0
    },

    botaoPrincipal: {
            height: '100%',
            display:'flex',
            justifyContent: 'center', 
            alignItems:'center',
    },
    botaoCopy: {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        borderRight: `3px solid rgba(0, 0, 0, 0.23)`,
        margin:'0 1rem',
        [theme.breakpoints.down("xs")]: {
            margin:'0',
        },
    },
    link : {
            display: 'flex',
            alignItems: 'center'
             
    }
    

}))