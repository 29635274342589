import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnNovo: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    list: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto"
    },
    containerBtn: {
        justifyContent: 'flex-end'
    }
}))