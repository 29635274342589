import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogBarCodeCamComanda } from 'views/components/dialog/dialog-barcode-cam-comanda/dialog-barcode-cam-comanda';

class DialogBarCodeCamComandaProps {
  constructor(
    public aberto: boolean = false,
    public mesaId: string = '',
  ){}
}

export const DialogBarCodeCamComandaDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<DialogBarCodeCamComandaProps>(
    new DialogBarCodeCamComandaProps()
  );

  consoleDev('DialogBarCodeCamComanda');

  const modalAlterado = useCallback(
    ({ aberto, mesaId }: any) => {
      setModalState({
        aberto,
        mesaId,
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogBarCodeCamComanda, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogBarCodeCamComanda, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogBarCodeCamComanda
          openned={modalState.aberto}
          mesaId={modalState.mesaId}
          
        />
      )}
    </>
  );
};
