import { VariaveisAmbiente } from 'config';
import { isEmpty } from "lodash";
import { PessoaModel } from "model/api/gestao/pessoa";
import { AutorizacaoModel, EmitModel, VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { Alinhamento, Orientacao } from "utils/alinhamento";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { StringBuilder } from "utils/stringBuilder";
import { toDateString, toDate } from "utils/to-date";
import { toDecimalString } from "utils/to-decimal";
import { validaCaracter } from "utils/valida-caracter";

export function useImpressaoSAT() {
    const retImpCabecalho = (venda: VendaCompletaModel, qtd_colunas: number): string => {
        const xLayout = new StringBuilder();

        let xNomeEmpresa = validaCaracter(venda.infMov.emit?.xNome!) ?? '';

        if (!isEmpty(venda.infMov.emit?.xFant)) {
            xNomeEmpresa = validaCaracter(venda.infMov.emit?.xFant!) ?? '';
        }

        xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xNomeEmpresa, qtd_colunas, " "));
        xLayout.appendWithln();
        xLayout.append(
            Alinhamento.AlinhamentoMultiLinhas(
                Orientacao.Centro,
                validaCaracter(venda.infMov.emit?.xLgr ?? '') ?? '' +
                (" " + venda.infMov.emit?.nro) +
                (" " + validaCaracter(venda.infMov.emit?.xCpl ?? '') ?? '') +
                (" " + validaCaracter(venda.infMov.emit?.xBairro ?? '') ?? ''), qtd_colunas, " "
            )
        );
        xLayout.appendWithln();
        xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "CNPJ: " + formatarCPFCNPJ(venda.infMov.emit?.doc ?? ''), qtd_colunas, " "));
        xLayout.appendWithln();
        if (venda?.infMov.emit?.ie) {
            xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "Inscricao Estadual: " + venda.infMov.emit?.ie ?? '', qtd_colunas, " "))
            xLayout.appendWithln();
        }
        xLayout.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xLayout.appendWithln();
        xLayout.append("<n>")
        xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "Extrato No. " + venda.infMov.emit?.nro, qtd_colunas, " "))
        xLayout.appendWithln();
        xLayout.append("</n>")
        xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "Cupom Fiscal Eletrônico - SAT", qtd_colunas, " "))
        xLayout.appendWithln();
        xLayout.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xLayout.appendWithln();

        return xLayout.toString();
    };

    const retImpItens2Linhas = (
        venda: VendaCompletaModel,
        qtd_colunas: number,
        casas_qtde: number,
        casas_valor: number,
        maiorCodProd: number
    ): string => {
        let xRetorno = new StringBuilder()
        let xStrAuxiliar: string
        xRetorno.append(Alinhamento.AlinhaEsquerda("###|" + Alinhamento.AlinhaCentro("COD", " ", qtd_colunas - 32) + "|QTD|UN|VL UN R$|(VL TR R$)*", " ", qtd_colunas))
        xRetorno.appendWithln();
        xRetorno.append(Alinhamento.AlinhaEsquerda("DESCRICAO", " ", qtd_colunas - 12))
        xRetorno.append("| VL ITEM R$")
        xRetorno.appendWithln();
        xRetorno.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xRetorno.appendWithln();

        let xNItem = 1

        const productsForPrinting = venda.infMov.prod.filter(p => p.cancelado === false && p.indFin);
        for (let xItem of productsForPrinting) {

            let xVUnCom = xItem.vUnCom;
            if ((xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc) !== 0) {
                xVUnCom = (xItem.vProd + xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc) / xItem.qCom;
            }

            let finalLinha = " " + toDecimalString(xItem.qCom, casas_qtde) + " " + xItem.uCom + " x " + toDecimalString(xVUnCom.toString(), casas_valor);

            let inicioLinha = Alinhamento.AlinhaDireita(String(xNItem), "0", 3) + " " + Alinhamento.AlinhaEsquerda(xItem.cProd, " ", maiorCodProd) + " "
            xRetorno.append(inicioLinha)
            xRetorno.append(Alinhamento.AlinhaDireita(finalLinha, " ", qtd_colunas - inicioLinha.length))
            xRetorno.appendWithln();
            let baixodireita = "  " + toDecimalString((xItem.vProd + xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc)).replace("R$ ", "")
            xRetorno.append(Alinhamento.AlinhaEsquerda(" " + validaCaracter(xItem.xProd), " ", qtd_colunas - baixodireita.length))
            xRetorno.append(baixodireita);
            xRetorno.appendWithln();

            if ((xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc) !== 0) {
                xStrAuxiliar = (xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc) > 0 ? "Acréscimo sobre item" : "Desconto sobre item";
                xRetorno.append(xStrAuxiliar)
                xRetorno.append(
                    Alinhamento.AlinhaDireita(
                        "- " + toDecimalString((xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc), 2),
                        " ",
                        qtd_colunas - xStrAuxiliar.length
                    )
                )
                xRetorno.appendWithln();
            }
            xNItem++
        }
        xRetorno.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xRetorno.appendWithln();
        return xRetorno.toString()
    };

    const retImpLei12741 = (xVTrib: number, qtd_colunas: number): string => {
        return Alinhamento.AlinhamentoMultiLinhas(
            Orientacao.Centro,
            "Valor aproximado dos tributos deste cupom (conforme Lei Fed. 12.741/2012) " + toDecimalString(xVTrib),
            qtd_colunas, " "
        ) +
            '\n' +
            Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas) +
            '\n'
    };

    const retImpInfCpl = (venda: VendaCompletaModel, qtd_colunas: number): string => {
        try {

            if (isEmpty(venda?.infMov.infCpl)) {
                return ""
            }

            let xLayout = new StringBuilder()
            xLayout.append(Alinhamento.AlinhaCentro("INFORMACOES COMPLEMENTARES", " ", qtd_colunas))
            xLayout.appendWithln();
            for (let xlinha of venda.infMov.infCpl.split("\n")) {
                xLayout.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Esquerda, xlinha, qtd_colunas, " "))
                xLayout.appendWithln();
            }
            xLayout.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
            xLayout.appendWithln();
            return xLayout.toString()
        } catch (e: any) {
            return e.message
        }
    };

    const retImpTotal = (
        venda: VendaCompletaModel, qtd_colunas: number,
        xQtdProdutos: number, xVTotal: number, xDesconto: number,
        xCasasQtd: number, xCasasValor: number
    ): string => {
        let xLayout = new StringBuilder();

        let xStrAuxiliar: string;

        if (xQtdProdutos > 0) {
            xStrAuxiliar = "Qtd. Total de Itens"
            xLayout.append(xStrAuxiliar)
            xLayout.append(Alinhamento.AlinhaDireita(toDecimalString(xQtdProdutos, 3), " ", qtd_colunas - xStrAuxiliar.length))
            xLayout.appendWithln();
        }

        xStrAuxiliar = "Valor Total R$"
        xLayout.append("<n>")
        xLayout.append(xStrAuxiliar)
        xLayout.append(Alinhamento.AlinhaDireita(toDecimalString(xVTotal).replace("R$ ", ""), " ", qtd_colunas - xStrAuxiliar.length))
        xLayout.appendWithln();

        if (xDesconto > 0) {
            xStrAuxiliar = "Valor Descontos R$"
            xLayout.append("</n>")
            xLayout.append(xStrAuxiliar)
            xLayout.append(Alinhamento.AlinhaDireita(toDecimalString(xDesconto).replace("R$ ", ""), " ", qtd_colunas - xStrAuxiliar.length))
            xLayout.appendWithln();
        }

        xLayout.append("</n>")
        xLayout.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xLayout.appendWithln();
        xStrAuxiliar = "FORMA PAGAMENTO"
        xLayout.append(xStrAuxiliar)
        xLayout.append(Alinhamento.AlinhaDireita("VALOR PAGO", " ", qtd_colunas - xStrAuxiliar.length))
        xLayout.appendWithln();

        var xVTroco = 0
        for (let xFatura of venda!!.infMov.pag) {
            xVTroco = xVTroco + xFatura.vTroco
            let xpag = validaCaracter(xFatura.descricao)
            let pagamento = `${xpag}${xFatura.qtdeParcela > 1 ? `(${xFatura.qtdeParcela}x)` : ''}`
            xLayout.append(`${xpag}${xFatura.qtdeParcela > 1 ? `(${xFatura.qtdeParcela}x)` : ''}`)
            xLayout.append(Alinhamento.AlinhaDireita(toDecimalString(xFatura.vPag.toString()).replace("R$ ", ""), " ", qtd_colunas - (pagamento.length)))
            xLayout.appendWithln();
        }
        if (xVTroco > 0) {
            xStrAuxiliar = "Troco R$"
            xLayout.append(xStrAuxiliar)
            xLayout.append(Alinhamento.AlinhaDireita(toDecimalString(xVTroco).replace("R$ ", ""), " ", qtd_colunas - xStrAuxiliar.length))
            xLayout.appendWithln();
        }
        xLayout.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xLayout.appendWithln();
        return xLayout.toString()
    };

    const retImpRodape = (
        venda: VendaCompletaModel, xLoja: EmitModel,
        xClientePadrao: PessoaModel,
        qtd_colunas: number, xContingencia: boolean,
        XChave: string, xQrCode: string, xNProt: string, xDhRecbto: string
    ): string => {
        var xChave = XChave
        let xRetorno = new StringBuilder();

        xRetorno.append("<n>")
        if (venda!!.infMov.tpAmb === 2) {
            xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL", qtd_colunas, " "))
            xRetorno.appendWithln();
        }
        if (xContingencia) {
            xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "EMITIDA EM CONTINGENCIA", qtd_colunas, " "))
            xRetorno.appendWithln();
        }
        xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "SAT No.  ", qtd_colunas, " "))
        xRetorno.appendWithln();
        xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, toDateString(toDate(venda.infMov.dhEmi), 'DD/MM/yyyy HH:mm')!, qtd_colunas, " "))
        xRetorno.appendWithln();

        xRetorno.appendWithln();
        xRetorno.append("</n>")
        xRetorno.appendWithln();

        if (isEmpty(xChave)) {
            try {
                xChave = venda.infMov?.idNFe || ''
            } catch (e: any) {
                e.printStackTrace()
            }
        }
        var xChaveFormatada = new StringBuilder()
        var xIndex = 1
        do {
            xChaveFormatada.append(xChave.substring(xIndex - 1, xIndex))
            if (xIndex % 4 === 0) xChaveFormatada.append(" ")
            xIndex++
        } while (xIndex <= xChave.length);

        xChaveFormatada = new StringBuilder(xChaveFormatada.toString().trim())

        xRetorno.append("<n>");

        xRetorno.append(Alinhamento.AlinhaCentro("CHAVE DE ACESSO", " ", qtd_colunas))
        xRetorno.appendWithln();
        xRetorno.append("</n>")
        xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xChaveFormatada.toString(), qtd_colunas, " "))
        xRetorno.appendWithln();
        xRetorno.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
        xRetorno.appendWithln();
        if (venda.infMov.dest?.documento !== xClientePadrao.cpfcnpj && !isEmpty(venda.infMov.dest?.documento)) {
            xRetorno.append("<n>")
            xRetorno.append(Alinhamento.AlinhaCentro("CONSUMIDOR", " ", qtd_colunas))
            xRetorno.appendWithln();
            xRetorno.append("</n>")
            var xCliente = formatarCPFCNPJ(venda.infMov.dest?.documento ?? '')
            xCliente += " " + (venda.infMov.dest?.pessoaId !== xClientePadrao.id) ? venda.infMov.dest?.xNome : "CONSUMIDOR"
            xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Esquerda, xCliente, qtd_colunas, " "))
            xRetorno.appendWithln();
            xRetorno.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
            xRetorno.appendWithln();
        }
        xRetorno.append("<centro>")
        xRetorno.append("<qrCODE>")
        xRetorno.append(xQrCode)
        xRetorno.append("</qrCODE>")
        xRetorno.appendWithln();

        if (!isEmpty(xNProt)) {
            xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, "Protocolo de Autorização", qtd_colunas, " "))
            xRetorno.appendWithln();
            xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xNProt, qtd_colunas, " "))
            xRetorno.appendWithln();
            try {
                xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xDhRecbto || '', qtd_colunas, " "))
            } catch (e: any) {
                throw new Error('Erro ao realizar a Impressão. Detalhe: ' + e.message);
            }
            xRetorno.appendWithln();
        }
        xRetorno.append("<centro>")

        return xRetorno.toString();
    }

    const getLevel = (venda: VendaCompletaModel) => {
        const vendaProps = ['autorizacao', "cancelamento", "inutilizacao", "denegacao"]
        const propComValor = Object.entries(venda).find(x => vendaProps.includes(x[0]) && x)?.[1] as AutorizacaoModel | null
        return propComValor
    }

    const getQrCode = (venda: VendaCompletaModel): string => {
        return getLevel(venda)?.['urlQRCode'] ?? '';
    }

    const getNProt = (venda: VendaCompletaModel): string => {
        return getLevel(venda)?.['nProt'] ?? ''
    }

    const getDhRec = (venda: VendaCompletaModel): string => {
        return getLevel(venda)?.['dhRegistro'] ?? ''
    }

    const retImpPagsClienteLojista = (venda: VendaCompletaModel, qtd_colunas: number) => {
        let xRetorno = new StringBuilder();

        const temPagClienteLojista = venda.infMov.pag.filter(x => !isEmpty(x.viaEstabelecimento) || !isEmpty(x.viaConsumidor)).length > 0
        if (venda.infMov.pag && temPagClienteLojista) {
            xRetorno.append("<cond>")
            xRetorno.appendWithln();

            for (let xPag of venda.infMov.pag) {
                if (xPag.viaEstabelecimento) {
                    const viaEstabelecimentoArr = xPag.viaEstabelecimento.split(',')
                    for (let xViaLojista of viaEstabelecimentoArr) {
                        xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xViaLojista, qtd_colunas, " "))
                        xRetorno.appendWithln()
                    }
                    xRetorno.appendWithln();
                }
            }

            xRetorno.appendWithln();
            xRetorno.append("<guilhotina>")
            xRetorno.appendWithln();
            xRetorno.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
            xRetorno.appendWithln();
            xRetorno.appendWithln();

            for (let xPag of venda.infMov.pag) {
                if (xPag.viaConsumidor) {
                    const viaConsumidorArr = xPag.viaConsumidor.split(',')
                    for (let xViaConsumidor of viaConsumidorArr) {
                        xRetorno.append(Alinhamento.AlinhamentoMultiLinhas(Orientacao.Centro, xViaConsumidor, qtd_colunas, " "))
                        xRetorno.appendWithln()
                    }
                    xRetorno.appendWithln();
                }
            }
            xRetorno.append("<guilhotina>")
            xRetorno.append("</cond>")
            xRetorno.appendWithln();
        }

        return xRetorno.toString()
    }

    const impressaoSAT = (venda: VendaCompletaModel, qtd_colunas: number, casas_qtde: number, casas_valor: number, clientePadrao: PessoaModel): string => {
        let xLayoutImpressao = new StringBuilder()
        xLayoutImpressao.append("<cond><centro>")
        xLayoutImpressao.append(retImpCabecalho(venda, qtd_colunas))

        if (venda.status !== 30) {
            xLayoutImpressao.append("<n>")
            xLayoutImpressao.append(Alinhamento.AlinhaCentro("C U P O M   C A N C E L A D O", " ", qtd_colunas))
            xLayoutImpressao.appendWithln();
            xLayoutImpressao.append("</n>")
            xLayoutImpressao.append(Alinhamento.AlinhaEsquerda("-", "-", qtd_colunas))
            xLayoutImpressao.appendWithln();
        }
        let maiorCodProd = 0
        let xVtotal = 0
        let xVTrib = 0
        let xQtd = 0
        let xDesconto = 0

        const productsForPrinting = venda.infMov.prod.filter(p => p.cancelado === false && p.indFin);

        for (let xItem of productsForPrinting) {
            xVtotal = xVtotal + (xItem.vProd + xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc);

            xVTrib += xItem.vTotTrib ?? 0

            xQtd = xQtd + 1

            xDesconto += (xItem.vFrete + xItem.vSeg + xItem.vOutro - xItem.vDesc)
            if (xItem.cProd.length > maiorCodProd) {
                maiorCodProd = xItem.cProd.length
            }
        }
        xLayoutImpressao.append(retImpItens2Linhas(venda, qtd_colunas, casas_qtde, casas_valor, maiorCodProd))
        if (venda.status === 30) {
            xLayoutImpressao.append(retImpLei12741(xVTrib, qtd_colunas))
            xLayoutImpressao.append(retImpInfCpl(venda, qtd_colunas))
        }
        xLayoutImpressao.append(retImpTotal(venda, qtd_colunas, xQtd, xVtotal, xDesconto, casas_qtde, casas_valor))
        xLayoutImpressao.append(retImpRodape(venda, venda.infMov.emit ?? new EmitModel(), clientePadrao, qtd_colunas, venda.infMov.tpEmis === 9, venda.infMov?.idNFe, getQrCode(venda), getNProt(venda), getDhRec(venda)))
        xLayoutImpressao.append("<br>")
        xLayoutImpressao.append("<br>")
        xLayoutImpressao.append("<guilhotina>")
        xLayoutImpressao.append("</cond>")
        if (VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR) {
            xLayoutImpressao.append(retImpPagsClienteLojista(venda, qtd_colunas))
        }
        xLayoutImpressao.appendWithln();


        return xLayoutImpressao.toString();
    }

    return {
        impressaoSAT
    }
}