import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const UploadCloudIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <polyline fill="none" stroke={props.fill} stroke-width="2" stroke-miterlimit="10" points="19,26 25,20 31,26 " /><polyline fill="none" stroke={props.fill} stroke-width="2" stroke-miterlimit="10" points="19,26 25,20 31,26 " /><line fill="none" stroke={props.fill} stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" x1="25" y1="34" x2="25" y2="20" /><path fill="none" stroke={props.fill} stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M43,24.3c0-0.1,0-0.2,0-0.3c0-5.5-4.5-10-10-10c-1.2,0-2.3,0.2-3.4,0.6C27.7,11.3,24.1,9,20,9C13.9,9,9,13.9,9,20c0,0.1,0,0.1,0,0.2c-4.6,0.9-8,5-8,9.8c0,5.5,4.5,10,10,10c5.2,0,26.3,0,30,0c4.4,0,8-3.6,8-8C49,28.3,46.4,25.1,43,24.3z" />
            </DefaultIcon>
        </>
    );
};
