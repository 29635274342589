import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogAlterarQuantidadeClientesVenda } from 'views/components/dialog/dialog-alterar-quantidade-clientes-venda/dialog-alterar-quantidade-clientes-venda';

class AlterarQuantidadeClientesVendaProps {
  constructor(
    public aberto: boolean = false,
  ) { }
}

export const DialogAlterarQuantidadeClientesVendaRender = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<AlterarQuantidadeClientesVendaProps>(
    new AlterarQuantidadeClientesVendaProps()
  );

  consoleDev('AlterarQuantidadeClientesVendaDialog');

  const modalAlterado = useCallback(
    ({ aberto }: any) => {
      setModalState({
        aberto,
      });
    }, []);

  useEffect(() => {
    addHandler(AppEventEnum.DialogAlterarQuantidadeClientesVenda, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogAlterarQuantidadeClientesVenda, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogAlterarQuantidadeClientesVenda
          openned={modalState.aberto}
        />
      )}
    </>
  );
};
