import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { CarouselPlanosSaurus } from '../components';

import { useStyles } from './alterar-plano-page-styles';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

const AlterarPlanoPage = () => {
  const classes = useDefaultCadastroStyles();
  const pageClasses = useStyles();
  const [isPlanoAvulso, setIsPlanoAvulso] = useState<boolean>(false)
  const history = useHistory()
  const leftArea = useCallback(
    () =>
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => history.push('/configuracoes')}
      ></ButtonPrivateHeader>
    , [history],
  );

  return (
    <Grid className={classes.root} style={{}}>
      <Grid className={classes.header}>
        <PrivatePageHeader title="Alterar Plano" leftArea={leftArea()} />
      </Grid>
      <Grid style={{ padding: '1rem', flex: 1, overflow: 'auto' }}>
        <Grid className={pageClasses.root} >
          <Typography display="inline" variant="h4" color="primary">
            <strong>{!isPlanoAvulso ? "Planos Disponíveis" : 'Plano Avulso'}</strong>
          </Typography>
          <Grid>
            <div className={classNames(pageClasses.alert, classes.content)}>
              <Typography
                className={pageClasses.textAlert}
                variant="body1"
                color="textPrimary"
              >
                {
                  !isPlanoAvulso
                    ? "Escolha abaixo o plano que mais se adequa ao seu negócio e sua necessidade."
                    : "Identificamos que atualmente você está utilizando um plano personalizado e não é possivel fazer alteração nesta situação. Por favor entre em contato com seu representante para personalizar os módulos."
                }
              </Typography>
            </div>
          </Grid>
          <Grid>
            {isPlanoAvulso && (
              <Button
                variant='outlined'
                color="primary"
                onClick={() => history.push('/configuracoes')}
                size="large"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            )}
          </Grid>
          <CarouselPlanosSaurus setIsPlanoAvulso={setIsPlanoAvulso} pathToRedirect="/configuracoes" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlterarPlanoPage;
