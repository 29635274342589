import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { DepositoEmpresaPostModel } from 'model/api/gestao/deposito/deposito-empresa-model-post';
import { VariaveisAmbiente } from 'config';

export function usePostDepositoEmpresa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postDepositoEmpresa = useCallback(
    (data: DepositoEmpresaPostModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/DepositoEmpresa`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(data),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postDepositoEmpresa
  };
}
