import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { SetoresModel } from "model/api/gestao/setores/setores-model";

export function usePutSetor() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putSetor = useCallback(
    (setor: SetoresModel, setorId: string, empresaId: string) =>
      invocarApi({
        url: `/v5/empresa/${empresaId}/setor/${setorId}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(setor),
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlSetores}/api`,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    putSetor,
  };
}
