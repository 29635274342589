import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
export interface RedefinirSenhaProps {
    email: string;
} 

export function usePostIdentidadeRedefinirSenha() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postIdentidadeRedefinirSenha = useCallback(
    (props: RedefinirSenhaProps) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/Auth/redefinir-senha`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(props),
        enviarTokenUsuario: false
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postIdentidadeRedefinirSenha
  };
}
