import { Button, Typography } from "@material-ui/core"
import { useCallback, useState } from "react"
import { DialogHorariosFuncionamento } from "views/components/dialog/dialog-horarios-funcionamento/dialog-horarios-funcionamento"
import { ExpandBoldRoundIcon, RelogioIcon } from "views/components/icons"

interface Props {
    empresaId?: string
    hasEmpresaId?: boolean
}
export const HorariosFuncionamento = ({ empresaId, hasEmpresaId = false }: Props) => {
    const [dialog, setDialog] = useState<boolean>(false)

    const abrirFecharDialog = useCallback(() => {
        setDialog(prev => !prev)
    }, [])


    return (
        <>
            <Button
                fullWidth
                color="secondary"
                onClick={abrirFecharDialog}
                style={{
                    backgroundColor: '#FFF',
                    boxShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    fontSize: '1.25rem',
                    fontWeight: 500,
                    display: 'flex',
                    minHeight: 49
                }}>
                <div style={{
                    flexGrow: 1
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <RelogioIcon tipo="BUTTON" fill='rgb(102, 102, 102)' />
                        <Typography style={{
                            fontSize: 20,
                            fontWeight: 500,
                            textAlign: 'initial',
                            color: '#666666'
                        }}>
                            Horário de Funcionamento
                        </Typography>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <ExpandBoldRoundIcon
                        tipo="BUTTON"
                        style={{
                            transform: 'rotate(-90deg)'
                        }}
                    />
                </div>
            </Button>
            <DialogHorariosFuncionamento
                openned={dialog}
                closeModal={abrirFecharDialog}
                empresaId={empresaId}
                hasEmpresaId={hasEmpresaId}
            />
        </>
    )
}