import { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { PontosHeader } from './components/pontos-venda-header/pontos-venda-header';
import { ButtonFab } from 'views/components/controles';
import { useCadastros } from 'services/app';
import { PontosVendaList } from './components/pontos-venda-list/pontos-venda-list';
import { NovoPdvIcon } from '../../../components/icons/novo-pdv-icon';

const PontosVendaPage = () => {
  const classes = useDefaultCadastroStyles();

  const { abrirCadastroPontosVenda } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroPontosVenda('', '', true);
  }, [abrirCadastroPontosVenda]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <PontosHeader/>
      </Grid>
      <Grid className={classes.list}>
        <PontosVendaList />
      </Grid>
      <ButtonFab
        tooltip="Adicionar Novo Ponto de Venda"
        icon={<NovoPdvIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default PontosVendaPage;
