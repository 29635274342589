import { Grid } from "@material-ui/core";
import { DetalhesVendaHeader } from "./componets/detalhes-venda-header/detalhes-venda-header";
import { useParams } from "react-router";
import { DetalhesVendaData } from "./componets/detalhes-venda-data";
import { useDefaultCadastroStyles } from "../cadastros/components/default-cadastro-styles";

type Params = {
    id: string;
};

export const DetalhesVendaPage = () => {

    const classes = useDefaultCadastroStyles();

    const { id } = useParams<Params>();

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <DetalhesVendaHeader />
                </Grid>
                <Grid className={classes.list}>
                    <DetalhesVendaData id={id} />
                </Grid>
            </Grid>
        </>
    )
}