import { EnumModFrete } from "model/enums/enum-mod-frete";

export class TranspFormModel {
    constructor(
        public doc: string = '',
        public xNome: string = '',
        public ie: string = '',
        public xEnder: string = '',
        public xMun: string = '',
        public uf: string = '',
        public placa: string = '',
        public ufVeiculo: string = '',
        public rntc: string = '',
        public modFrete: EnumModFrete = EnumModFrete.SEM_TRANSPORTE
    ) { }
}