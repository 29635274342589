import { useCallback } from "react";

import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useDeleteFinalizadora() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
 
  const deleteFinalizadora = useCallback(
    (finalizadoraId: string) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Finalizadora/${finalizadoraId}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    deleteFinalizadora,
  };
}
