import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minHeight: "80px",
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    position: "relative",
    overflow: "hidden",

    "& span": {
      fontSize: "0.7em",
      textOverflow: 'ellipsis'
    },
    "& p": {
      fontSize: "1.0em",
      textOverflow: 'ellipsis'
    },

  },
  topContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    alignItems: 'center',
  },
  totalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textInfo: {
    fontWeight: 600,
    fontSize: '16px',
    textOverflow: 'ellipsis',
  },
  divider: {
    marginTop: '4px',
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary}`,
  },
}));

