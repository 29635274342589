import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    containerAvatar: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '10px',
    },
    cardImg: {
        background: theme.palette.common.white,
        objectFit: 'contain',
        width: '40px',
        height: '40px',
        border: '2px solid rgba(240, 240, 240, 0.5)',
        borderRadius: '5px',
    },
    container1: {
        display: 'block',
        flex: '1 1 100%',
        paddingRight: 35,
        width: '100%',
        textOverflow: 'ellipsis'
    },
    qtde: {
        flex: '0',
        whiteSpace: 'nowrap',
        fontSize: 13,
    },
    desc: {
        display: 'block',
        flex: '1 1 100%',
        fontSize: 15,
    },
    valor: {
        flex: '0',
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: 21,
        whiteSpace: 'nowrap',
        color: theme.palette.primary.main,
    }

}));
