import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "./events/event-tools";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { AppEventEnum } from "model/enums/enum-app-event";

export const useUSB = () => {
    const { addHandler, callEvent, removeHandler } = useEventTools();

    const [temUSB, setTemUSB] = useState<boolean>(false)

    const verificarUSB = useCallback(() => {
        if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA) {
            callEvent(AppEventEnum.VerificarUSB, {})
        }
    }, [callEvent])

    useEffect(() => {
        addHandler(AppEventEnum.EnviarUSB, (resp: any) => {
            setTemUSB(resp.temUSB)
        })

        return () => {
            removeHandler(AppEventEnum.EnviarUSB, (resp: any) => {
                setTemUSB(resp.temUSB)
            })
        }
    }, [addHandler, removeHandler])

    return {
        verificarUSB,
        temUSB
    }
}