import { useCallback } from "react";
import { ProdutoAtualizarVariacaoModel } from "../../../../../model/api/gestao/produto/produto-variacao/produto-atualizar-variacao-model";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutProdutoVariacao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProdutoVariacao = useCallback(
    (produtoId: string, produtoVariacao: ProdutoAtualizarVariacaoModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${produtoVariacao.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(produtoVariacao),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoVariacao,
  };
}
