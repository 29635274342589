import { Box, Grid, Typography } from '@material-ui/core';
import { DefaultCard, useDefaultCardStyles } from '../components';
import { CardEdicaoGerenciamentoSessaoProps } from './card-edicao-gerenciamento-sesso-props';
import { isEmpty } from 'lodash';
import { toDateString } from 'utils/to-date';
import { useCallback } from 'react';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './card-edicao-gerenciamento-sessao-styles';
import classNames from 'classnames';
import { EnumTpLancamentoSessaoValor } from 'model/enums/enum-tipo-lancamento-sessao-valor';
import { toDecimalString } from 'utils/to-decimal';

export const CardEdicaoGerenciamentoSessao = ({
  sessao,
  sessaoValor
}: CardEdicaoGerenciamentoSessaoProps) => {
  const classes = useDefaultCardStyles();
  const { isMobile } = useThemeQueries();
  const classesEdicao = useStyles({
    sessao
  });

  const retornarDescricaoStatus = useCallback(() => {
    if (isEmpty(sessao.dFechamento)) {
      return 'Sessão Aberta';
    } else if (isEmpty(sessao.dConferencia)) {
      return 'Sessão Fechada';
    } else {
      return 'Sessão Conferida';
    }
  }, [sessao.dConferencia, sessao.dFechamento]);

  const valorDiferenca = useCallback(() => {
    let valorCaixa = 0;
    sessaoValor
      .filter((x) => x.tpLancamento === EnumTpLancamentoSessaoValor.FECHAMENTO)
      .forEach((item) => {
        valorCaixa += item.valor;
      });

    if (valorCaixa === 0) {
      return sessao.valorVendido;
    }

    return Math.abs(valorCaixa - sessao.valorVendido || 0);
  }, [sessao.valorVendido, sessaoValor]);

    return (
        <DefaultCard>
            <Grid container spacing={1} className={classNames(isMobile ? classesEdicao.containerMobile : '', classesEdicao.containerRoot)}>
                {isMobile ? (
                    <>
                        <Grid item xs={6}>
                            <Typography
                                variant="body1"
                                className={classesEdicao.containerStatus}
                            >
                                <Box fontWeight={600}>
                                    {retornarDescricaoStatus()}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Data da Conferência
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                {!isEmpty(sessao.dConferencia) ? toDateString(sessao.dConferencia) : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Responsável
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                {sessao.operadorNome || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="caption"
                                color="textPrimary"
                            >
                                Diferença
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                R$ {toDecimalString(valorDiferenca(), 2)}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <div className={classes.cardContent}>
                                <div className={classes.celulaGrid}>
                                    <Typography
                                        variant="body1"
                                        className={classesEdicao.containerStatus}
                                    >
                                        <Box fontWeight={600}>
                                            {retornarDescricaoStatus()}
                                        </Box>
                                    </Typography>
                                </div>
                                <div className={classes.celulaGrid}>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                    >
                                        Data da Conferência
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        {!isEmpty(sessao.dConferencia) ? toDateString(sessao.dConferencia) : '-'}
                                    </Typography>
                                </div>
                                <div className={classes.celulaGrid}>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                    >
                                        Responsável
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        {sessao.operadorNome}
                                    </Typography>
                                </div>
                                <div className={classes.celulaGrid}>
                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                    >
                                        Diferença
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                       R$ {toDecimalString(valorDiferenca(), 2)} 
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </>
                )}
      </Grid>
    </DefaultCard>
  );
};
