import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const SalvarNovoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <g id="surface220195695">
                    <path d="M 36.800781 4 C 37.066406 4 37.320312 4.105469 37.507812 4.292969 L 45.707031 12.492188 C 45.894531 12.679688 46 12.933594 46 13.199219 L 46 29.421875 C 45.386719 28.882812 44.714844 28.402344 44 27.996094 L 44 13.613281 L 37 6.613281 L 37 18 C 37 19.644531 35.644531 21 34 21 L 15 21 C 13.355469 21 12 19.644531 12 18 L 12 6 L 7 6 C 6.445312 6 6 6.445312 6 7 L 6 43 C 6 43.554688 6.445312 44 7 44 L 12 44 L 12 29 C 12 27.355469 13.355469 26 15 26 L 35 26 C 35.617188 26 36.195312 26.191406 36.671875 26.515625 C 35.058594 26.707031 33.539062 27.226562 32.179688 28 L 15 28 C 14.445312 28 14 28.445312 14 29 L 14 44 L 27.472656 44 C 27.851562 44.710938 28.296875 45.382812 28.800781 46 L 7 46 C 5.355469 46 4 44.644531 4 43 L 4 7 C 4 5.355469 5.355469 4 7 4 Z M 14 18 C 14 18.554688 14.445312 19 15 19 L 34 19 C 34.554688 19 35 18.554688 35 18 L 35 6 L 14 6 Z M 32 8 C 32.550781 8 33 8.449219 33 9 L 33 16 C 33 16.550781 32.550781 17 32 17 L 29 17 C 28.449219 17 28 16.550781 28 16 L 28 9 C 28 8.449219 28.449219 8 29 8 Z M 30 15 L 31 15 L 31 10 L 30 10 Z M 10 40 L 10 42 L 8 42 L 8 40 Z M 10 40 " />
                    <path d="M 43.996094 38.429688 C 43.996094 39.03125 43.59375 39.429688 42.996094 39.429688 L 39.09375 39.429688 L 39.09375 43.332031 C 39.09375 43.929688 38.695312 44.332031 38.09375 44.332031 C 37.496094 44.332031 37.09375 43.929688 37.09375 43.332031 L 37.09375 39.429688 L 33.195312 39.429688 C 32.59375 39.429688 32.195312 39.03125 32.195312 38.429688 C 32.195312 37.832031 32.59375 37.429688 33.195312 37.429688 L 37.09375 37.429688 L 37.09375 33.53125 C 37.09375 32.929688 37.496094 32.53125 38.09375 32.53125 C 38.695312 32.53125 39.09375 32.929688 39.09375 33.53125 L 39.09375 37.429688 L 42.996094 37.429688 C 43.59375 37.429688 43.996094 37.832031 43.996094 38.429688 Z M 48.09375 38.429688 C 48.09375 43.929688 43.59375 48.429688 38.09375 48.429688 C 32.59375 48.429688 28.09375 43.929688 28.09375 38.429688 C 28.09375 32.929688 32.59375 28.429688 38.09375 28.429688 C 43.59375 28.429688 48.09375 32.929688 48.09375 38.429688 Z M 46.09375 38.429688 C 46.09375 34.03125 42.496094 30.429688 38.09375 30.429688 C 33.695312 30.429688 30.09375 34.03125 30.09375 38.429688 C 30.09375 42.832031 33.695312 46.429688 38.09375 46.429688 C 42.496094 46.429688 46.09375 42.832031 46.09375 38.429688 Z M 46.09375 38.429688 " />
                </g>
            </DefaultIcon>
        </>
    );
};

