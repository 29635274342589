import { useMemo } from 'react';
import * as Yup from 'yup';
export const useFormPagerIdentificadorValidation = (semIdentificacao: boolean) => {
    
    const FormPagerIdentificadorValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                identificador: !semIdentificacao ? Yup.string().required('Identificação do cliente é obrigatório.').typeError('Identificação do cliente é obrigatório') : Yup.string().notRequired().nullable(true),
            })
        
        )
    },[semIdentificacao])
        
            

        

    return {
        FormPagerIdentificadorValidationYup,
    }
}