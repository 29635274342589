export enum EnumTpHistoricoVenda {
  NotaManual = 1,
  VendaSimples = 10,
  NFe = 55,
  MDFe = 58,
  NFCe = 65,
  CTe = 57,
  CTeOS = 67,
  SAT = 70,
  Entrada = 50,
  Troca = 60,
}


