
import { guidEmpty } from "utils/guid-empty";
import { EnumPagTpMod, EnumPagTpTransacao } from 'model/enums';
import { CredenciamentoSafra } from "model/api/gestao/finalizadora/finalizadora-model";

export class FinalizadoraEditFormModel {
  constructor(
    public id: string = guidEmpty(),
    public descricao: string = "",
    public empresaId: string | null  =  guidEmpty(),
    public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
    public qMaxParc: string = '1',
    public integrado: boolean = false,
    public pDesc: number = 0,
    public pAcresc: number = 0,
    public ativo: boolean = true,
    public vMinParc: number = 0,
    public cnpjCred: string = "",
    public credenciais: CredenciamentoSafra = new CredenciamentoSafra(),
    public credenciado: boolean = false,
    public dataCredenciamento: string = '',
    public tpTransacao: EnumPagTpTransacao = EnumPagTpTransacao.NORMAL,
  ) { }
}


