import { KeyValueModel } from "model/api";
import { EnumStatusPerfil } from "model/enums/enum-status-perfil";

export const StatusPerfilMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusPerfil.Ativado, "Ativado"),
    new KeyValueModel(EnumStatusPerfil.Bloqueado, "Bloqueado"),
    new KeyValueModel(EnumStatusPerfil.Convidado, "Convidado"),
];

export const StatusPerfilEditMock: KeyValueModel[] = [
    new KeyValueModel(EnumStatusPerfil.Ativado, "Ativado"),
    new KeyValueModel(EnumStatusPerfil.Bloqueado, "Bloqueado"),
];

