import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useDeleteCancelarVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteCancelarVenda = useCallback(
        (id: string, motivo: string) => {

            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Movimento/${id}`,
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({ motivo: motivo ?? 'Não informado pelo o operador!' }),
                enviarTokenUsuario: true
            })
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        deleteCancelarVenda,
    };
}
