import { KeyValueModel } from "model";
import { EnumTpImp } from "model/enums/enum-tp-imp";

export const TpImpMock: KeyValueModel[] = [
    new KeyValueModel(EnumTpImp.SEM_GERACAO_DANFE, 'Sem Geração de DANFE'),
    new KeyValueModel(EnumTpImp.DANFE_NORMAL_RETRATO, 'DANFE Normal, Retrato'),
    new KeyValueModel(EnumTpImp.DANFE_NORMAL_PAISAGEM, 'DANFE Normal, Paisagem'),
    new KeyValueModel(EnumTpImp.DANFE_SIMPLIFICADO, 'DANFE Simplificado'),
    new KeyValueModel(EnumTpImp.DANFE_NFCE, 'DANFE NFC-e'),
    new KeyValueModel(EnumTpImp.DANFE_SIMPLIFICADO, 'DANFE NFC-e em Mensagem Eletrônica'),
]