import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { Grid, Typography } from '@material-ui/core';
import CardMesaAtendimento from 'views/components/cards/card-mesa-atendimento/card-mesa-atendimento';
import { PedidoMesaProcessadaModel } from 'model/api/gestao/pedido/pedido-mesa-processada';
import { guidEmpty } from 'utils/guid-empty';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useStyles } from '../list-styles';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';

export interface MesasListDataProps {
  list: Array<PedidoMesaProcessadaModel>;
  carregando: boolean;
  onCardSelected: (mesa: PedidoMesaProcessadaModel) => any;
  onCardHolded: (pedido: PedidoModel) => void;
  salaoAtualId: string;
  saloes: SaloesModel[];
}

export const MesasListData = (props: MesasListDataProps) => {
  const classes = useStyles();

  const onCardSelected = (mesa: PedidoMesaProcessadaModel) => {
    props.onCardSelected(mesa);
  };

  const onCardHolded = async (pedido: PedidoModel) => {
    props.onCardHolded(pedido);
  };

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <Grid item xs={12}>
          <CardNaoEncontrado
            mensagem="Nenhuma Mesa encontrada."
            icon={<InformacaoIcon tipo="GERAL" />}
          />
        </Grid>
      )}
      <Grid item spacing={2} className={classes.containerListCard}>
        {props.list.length > 0 &&
          props.list.map((mesa, index) => {
            return (
              <>
                {props.salaoAtualId === guidEmpty() &&
                  (index === 0 ||
                    props.list[index - 1].salaoId !== mesa.salaoId) && (
                    <>
                      <Grid xs={12} style={{ margin: '8px 8px' }}>
                        <Typography>
                          Salão - <b>{mesa.nomeSalao}</b>
                        </Typography>
                      </Grid>
                      <br />
                    </>
                  )}
                <CardMesaAtendimento
                  model={mesa}
                  key={mesa.idMesa}
                  onClick={onCardSelected}
                  selected={false}
                  onHold={onCardHolded}
                />
              </>
            );
          })}
      </Grid>
    </>
  );
};
