import { Grid } from "@material-ui/core";
import { GerarComandaHeader } from "../mov-controle-salao/mov-gerar-comanda/components/gerar-comanda-header/gerar-comanda-header";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";
import { GerarComandaCadastro } from "../mov-controle-salao/mov-gerar-comanda/components/gerar-comanda-cadastro/gerar-comanda-cadastro";
import { usePedidoLocal } from "services/app/hooks/pedido-local";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMovAtual } from "services/app/hooks/mov-atual";

const MovInformacoesClientePage = () => {
    const classes = useDefaultCadastroStyles();

    const {getConfiguracoesMesaEComanda} = usePedidoLocal();
    const {push} = useHistory();
    const {getMov} = useMovAtual();

    useEffect(() => {
        if(getMov()?.clienteIdentificado){
            push('/venda-simples/enviar-pedido')
        }
    }, [getMov, push])

    return (
        <Grid className={classes.root}>
            <Grid className={classes.header}>
                <GerarComandaHeader title={"Identificação do Cliente"} />
            </Grid>
            <Grid className={classes.list}>
                <GerarComandaCadastro fluxoPedido tipoTrabalho={getConfiguracoesMesaEComanda()!.tipoTrabalho!}/>
            </Grid>
        </Grid>
    );
};

export default MovInformacoesClientePage;