import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    headerContainer: {
        cursor: 'pointer',
        marginLeft: 8,
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    }
}));