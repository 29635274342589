import { guidEmpty } from 'utils/guid-empty';

export class VendaPagsModel {
    constructor(
        public id: string = guidEmpty(),
        public pagamentoId: string = guidEmpty(),
        public vPag: number = 0,
        public vTroco: number = 0,
        public cAut: string = '',
        public modPagamento: number = 1,
        public cnpj: string = '',
        public cancelado: boolean = true,
        public viaCliente: string = '',
        public viaLojista: string = '',
        public viaConsumidor: string = '',
        public viaEstabelecimento: string = '',
        public qtdeParcela: number = 0
    ) { }
}