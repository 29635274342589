import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MenuPrincipal } from './menu-principal';
import { useStyles } from './main-page-styles';
import { useVerificarDispositivo } from 'services/app/hooks/verificar-dispositivo';
import { MenuPdv } from './menu-pdv/menu-pdv';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { isArray, isEmpty, isEqual } from 'lodash';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { useLocation } from 'react-router-dom';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import classNames from 'classnames';
import { Tour } from 'views/components/tour/tour-wrapper';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useGetTutorial } from 'data/api/gestao/tutorial/get-tutorial';
import { sistemaId } from 'config';
import { TutorialResModel } from 'model/api/gestao/tutorial/tutorial-post-model';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useVerificarAbas } from 'services/app/hooks/verificar-abas';
import { EnumTipoCobranca } from 'model/enums/enum-tipo-combranca';

export const MainPage: FC = ({ children }) => {

    const { dispararVerificacao } = useVerificarDispositivo();
    useEffect(() => {
        setTimeout(() => {
            dispararVerificacao();
        }, 7500)
    }, [dispararVerificacao])

    const { plano, deslogar, usuario, meuUsuario, getEmpresaSelecionada, isPlanoAvulso } = useSessaoAtual();
    const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

    const deslogarUser = useCallback(() => {
        deslogar(usuario?.usuarioId || '')
    }, [deslogar, usuario?.usuarioId])

    useVerificarAbas(deslogarUser);

    const { get } = useMenuStorage();
    const { alterarModo } = useMenuPrincipal()
    const [menuAtual, setMenuAtual] = useState(get());
    const modoPDV = menuAtual.modo === EnumMenuPrincipalModo.PDV
    const classes = useStyles();
    const { addHandler, removeHandler } = useEventTools()
    const location = useLocation()
    const { callTour } = useTourSaurus()
    const { getRegistro, setRegistro } = useGestaoStorage()
    const { getTutorial } = useGetTutorial()
    const { callEvent } = useEventTools()
    const { showToast } = useToastSaurus()
    const menu = useMemo(() => <MenuPrincipal ocultar={modoPDV} />, [modoPDV]);
    const menuPdv = useMemo(() => <MenuPdv ocultar={!modoPDV} />, [modoPDV])

    const getTutoriais = useCallback(async (tipoDeReferencia: string) => {
        try {
            const res = await getTutorial(tipoDeReferencia, sistemaId)

            if (res.erro) throw res.erro
            return res.resultado?.data as TutorialResModel[]
        } catch (e: any) {

            showToast('error', e.message)
        }
    }, [getTutorial, showToast])

    const iniciarWizard = useCallback(async () => {
        const contratoId = getEmpresaSelecionada()?.ContratoId ?? ""
        const tutoriaisContrato = await getTutoriais(contratoId)
        if (typeof tutoriaisContrato === 'object') {
            const wizard = tutoriaisContrato.find((x: TutorialResModel) => x.tipo === EnumTour.WIZARD)

            if (wizard && isEmpty(wizard.tutorialStatus)) {
                callEvent(AppEventEnum.DialogConfigIniciais, { aberto: true });

            }
        }
    }, [callEvent, getEmpresaSelecionada, getTutoriais])


    const carregarTutoriais = useCallback(async () => {
        let tutorialStorage = getRegistro(GestaoStorageKeys.Tutoriais, false)

        let tutoriais: TutorialResModel[] = []
        let tutoriaisAtualizados = await getTutoriais(meuUsuario?.id ?? "") as TutorialResModel[]

        if (!tutorialStorage || !isArray(tutorialStorage)) {
            tutoriais = tutoriaisAtualizados
            setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
            return tutoriais
        }
        else if (!isEqual(tutorialStorage, tutoriaisAtualizados)) {
            tutoriais = tutorialStorage
            setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
            return tutoriais
        } else {
            tutoriais = tutorialStorage
            setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
            return tutoriais
        }

    }, [getRegistro, getTutoriais, meuUsuario?.id, setRegistro])


    const pesquisasDeTutoriais = useCallback(async () => {
        const tipoDefaturamento = usuario?.licenca.RepresentanteTipoCobranca
        if (!isPlanoAvulso() || tipoDefaturamento === EnumTipoCobranca.ClienteFinal) {
            await iniciarWizard()
            await carregarTutoriais()
        }
        await carregarTutoriais()

    }, [carregarTutoriais, iniciarWizard, isPlanoAvulso, usuario?.licenca.RepresentanteTipoCobranca])
    useEffect(() => {
        if (!isFarma) {
            pesquisasDeTutoriais()
        }


    }, [carregarTutoriais, iniciarWizard, isFarma, pesquisasDeTutoriais])


    useEffect(() => {
        if (location.pathname === '/venda-simples/catalogo' || location.pathname === '/venda-simples/avulso') {
            callTour(EnumTour.VENDA)
        }
    }, [callTour, location.pathname])

    const retMenuAlterado = useCallback((menu: MenuPrincipalModel) => {
        setMenuAtual((prev) => {
            const menuEqual = isEqual(prev, menu);
            if (!menuEqual) return menu;
            return prev;
        });
    }, []);

    useEffect(() => {
        location.pathname.indexOf('/venda-simples/') > -1 && !location.pathname.includes('selecionar-pdv')
            ? alterarModo(EnumMenuPrincipalModo.PDV)
            : alterarModo(EnumMenuPrincipalModo.Retaguarda);
    }, [alterarModo, location.pathname, location.search]);

    useEffect(() => {
        addHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
        return () => removeHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
    }, [addHandler, removeHandler, retMenuAlterado]);


    return (
        <>
            <div className={classNames(classes.containerBody, modoPDV ? classes.flexContainer : null)}>
                {menu}
                {menuPdv}
                {children}
                <Tour />
            </div>
        </>
    );
};
