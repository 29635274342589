import { guidEmpty } from "utils/guid-empty";

export class ProdutoPrecoModel {
  constructor(
    public id: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public tabelaPrecoId: string = guidEmpty(),
    public vPreco: number = 0
  ) {}
}
