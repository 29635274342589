import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerCarrinhoVazio: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  cardCarrinhoVazio: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 50,
    borderRadius: 10,
    maxWidth: 700,
    minWidth: 100,
    width: '100%',
    whiteSpace: 'normal',
    flex: 1,
    '& .celula-grid-value': {
      whiteSpace: 'normal',
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem'
      }
    },

    '& svg': {
      width: '150px',
      height: '150px',
      margin: theme.spacing(4),
      fill: theme.palette.primary.dark
    }
  },
  marginCard: {
    margin: theme.spacing(4)
  },
  textCenter: {
    textAlign: 'center'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '56px',
    width: '100%',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
  },
  textInfo: {
    fontSize: '36px',
    lineHeight: '36px',
    fontWeight: 'normal',
    color: theme.palette.primary.dark,
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px'
    },
  }
}));
