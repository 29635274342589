import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-selecionar-vendedor-styles';
import {  AvancarIcon, OkIcon, VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views';

interface DialogSelecionarProps {
  nomeVendedor: string;
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  substituirTodosProdutos: () => void;
  substituirProximosProdutos: () => void;
}

export const DialogSelecionarVendedor = ({
  openned,
  closeModal,
  substituirTodosProdutos: substituirTodasProdutos,
  substituirProximosProdutos: substituirProxProdutos,
  loading,
  ...props
}: DialogSelecionarProps) => {
  const classes = useStyles();
  const showContratar = substituirTodasProdutos || substituirProxProdutos;

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Seleção de Vendedor"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography className={classes.label} variant='subtitle1'>
                Deseja definir o vendedor <b>'{props.nomeVendedor}'</b> para:
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 16, marginBottom: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!showContratar || loading}
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={substituirTodasProdutos}
                  className='round'
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Todos os Produtos da Venda
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!showContratar || loading}
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={substituirProxProdutos}
                  className='round'
                >
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Apenas os Próximos Produtos
                </Button>
              </Grid>
              <br/>
              <br/>
              <Grid item xs={12}>
                {closeModal && (
                  <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                    className='round'
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
