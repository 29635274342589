import { WhitelabelModel } from "model/api/gestao/whitelabel/whitelabel-model";
import { useSessaoAtual } from "../providers"
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";

export const useImagemWhitelabel = () => {
    const { usuario } = useSessaoAtual();
    const { getRegistro } = useGestaoStorage();

    const storageWL = getRegistro(GestaoStorageKeys.Whitelabel, false) as WhitelabelModel;

    return {
        imagemWhitelabel: storageWL.urlImagemPrimaria ? storageWL.urlImagemPrimaria : usuario?.licenca.RepresentanteLogo,
    }
}