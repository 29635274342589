import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPontosVendaValidation = () => {
    const FormPontosVendaValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                numCaixa: Yup.number().min(1, 'o valor minimo é 1').required('o número do caixa é obrigatório.'),
                descricao: Yup.string().required('A descrição é obrigatória.'),
                tpCaixa: Yup.number().required('Selecione o tipo do PDV')
            })
        )
    }, [])
    return {
        FormPontosVendaValidationYup,
    }
}

