import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetRastroLoteCodigoAnvisa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getRastroLoteCodigoAnvisa = useCallback(
    (empresaId: string, query?: string) => {

      return invocarApi({
        url: `/api/v1/empresa/${empresaId}/Estoque/buscar-lote-codigo-anvisa${query}`,
        method: 'GET',
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiTouchFarma
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getRastroLoteCodigoAnvisa
  };
}
