import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { EntradaModal } from "views/components/modals/entrada/entrada-modal";

export const EntradaModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
    });

    const modalAlterado = useCallback(({ openned }: any) => {
        setModalState({
            openned,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.EntradaModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.EntradaModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <EntradaModal openned={modalState.openned} id={""} key="mdlEntrada" />
    )
}