import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const SuporteRemotoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M1 32.1583C1.28527 31.7738 1.67564 31.6623 2.14108 31.6756C2.81839 31.6956 3.4957 31.6806 4.2681 31.6806C3.5274 30.2175 3.80099 28.7362 3.79432 27.2898C3.76929 21.2429 3.78264 15.1943 3.78431 9.14737C3.78598 6.50757 5.2924 5.00125 7.94158 5.00125C19.2373 4.99958 30.533 4.99958 41.8287 5.00125C44.3044 5.00125 45.8659 6.54918 45.8709 9.01255C45.8776 12.3847 45.8726 15.7585 45.8726 19.1307C45.8726 19.2738 45.8559 19.417 45.8459 19.5884C45.252 19.3221 44.6848 19.0924 44.1459 18.8045C44.0291 18.7412 43.9741 18.4716 43.9724 18.2968C43.9624 15.2892 43.9657 12.2832 43.9657 9.27553C43.9657 9.04251 43.9724 8.80949 43.9491 8.57813C43.8573 7.67434 43.2034 7.01689 42.2975 6.91369C42.0656 6.88706 41.8321 6.88872 41.5985 6.88872C30.3862 6.88706 19.1739 6.88706 7.9616 6.89205C7.6463 6.89205 7.32099 6.90204 7.02071 6.98193C6.19159 7.2033 5.68945 7.91402 5.68945 8.87107C5.68444 15.8151 5.68444 22.7608 5.69112 29.7049C5.69278 30.9682 6.46185 31.6772 7.8148 31.6789C12.4192 31.6839 17.0252 31.6806 21.6296 31.6806C24.0152 31.6806 26.4008 31.6806 28.788 31.6806C28.9665 31.6806 29.145 31.6806 29.3235 31.6806C29.3469 31.7172 29.3702 31.7555 29.3919 31.7921C28.8097 32.3663 28.2392 32.9539 27.6369 33.5048C27.5302 33.6013 27.2883 33.5681 27.1081 33.5681C19.2823 33.5714 11.4583 33.5714 3.6325 33.5714C3.45066 33.5714 3.26882 33.5714 2.99189 33.5714C3.08531 33.7861 3.13202 33.9559 3.22211 34.0973C3.72759 34.883 4.43326 35.3673 5.37748 35.4655C5.65942 35.4938 5.94302 35.4938 6.22662 35.4938C12.4008 35.4955 18.5733 35.4938 24.7475 35.4938C24.9944 35.4938 25.2397 35.4938 25.4866 35.4938C25.5099 35.5338 25.5333 35.5737 25.5583 35.6137C24.9777 36.1846 24.4039 36.7654 23.8066 37.3197C23.7182 37.4013 23.5214 37.383 23.3745 37.383C17.5006 37.3863 11.6284 37.3913 5.75451 37.3813C3.51739 37.3763 1.79909 36.0963 1.10176 33.9725C1.07674 33.8959 1.03336 33.826 1 33.7528C1 33.2219 1 32.6909 1 32.1583Z" />
                <path d="M44.5898 21.1313C43.9876 21.7605 43.4153 22.3597 42.8431 22.9572C41.7721 24.0741 40.7061 25.1959 39.6234 26.3028C39.3899 26.5408 39.2948 26.7522 39.4115 27.09C39.6234 27.6992 39.6151 28.4932 40.0171 28.901C40.4158 29.3054 41.2133 29.3054 41.8188 29.5268C42.1858 29.6616 42.4161 29.5817 42.6913 29.3137C44.2078 27.8407 45.7459 26.3926 47.279 24.9363C47.4458 24.7765 47.6193 24.6233 47.8946 24.372C48.1565 25.2908 48.4601 26.113 48.6186 26.9602C49.5428 31.8786 45.5574 36.5441 40.5293 36.4226C39.8737 36.4076 39.2047 36.2445 38.5774 36.0414C38.102 35.8883 37.8317 35.9931 37.4997 36.3277C34.979 38.8676 32.4416 41.3893 29.9025 43.9109C28.2827 45.5204 25.7486 45.3373 24.4957 43.5297C23.5365 42.1466 23.715 40.2774 24.9378 39.0574C27.5286 36.4708 30.1244 33.8893 32.7252 31.3111C32.9421 31.0964 33.0639 30.9183 32.9354 30.587C31.1687 26.0148 34.8756 20.2525 40.596 20.2542C41.9756 20.2525 43.2569 20.6437 44.5898 21.1313ZM40.9347 22.1866C40.6561 22.165 40.5109 22.1367 40.3675 22.145C38.806 22.2199 37.4413 22.7841 36.3186 23.8644C34.3734 25.7319 33.8563 28.4283 34.984 30.9133C35.1942 31.376 35.1358 31.6306 34.7755 31.9685C33.8046 32.8806 32.8787 33.8377 31.9361 34.7781C30.061 36.6456 28.1876 38.5148 26.3091 40.3789C25.8287 40.8566 25.6402 41.4109 25.8654 42.0567C26.0739 42.6576 26.5227 43.0104 27.1549 43.097C27.7271 43.1752 28.1842 42.9405 28.5863 42.5394C31.3656 39.7631 34.1616 37.0051 36.9242 34.2139C37.3129 33.821 37.5831 33.7545 38.1003 33.9842C42.1108 35.7668 46.58 33.0321 46.8302 28.6679C46.8403 28.4965 46.8136 28.3234 46.7985 28.0521C46.595 28.2352 46.4732 28.3417 46.3548 28.4532C45.2921 29.4619 44.2244 30.4639 43.1734 31.4842C42.9416 31.7089 42.7397 31.7705 42.4227 31.6772C41.2099 31.3161 39.9838 30.9982 38.7726 30.627C38.5824 30.5687 38.3706 30.339 38.3105 30.1443C37.9568 29.0025 37.6649 27.8424 37.3095 26.7022C37.1794 26.2845 37.2378 26.0198 37.5531 25.7036C38.6692 24.5851 39.7452 23.4299 40.9347 22.1866Z" />
            </DefaultIcon>
        </>
    );
};
