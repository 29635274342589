import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    rastroErroAccordion: {
        color: theme.palette.common.white,
        fontWeight: 600,
        backgroundColor: theme.palette.error.main,
        padding: theme.spacing(.5, 1),
        borderRadius: theme.shape.borderRadius,
        fontSize: 11,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 20
        }
    }
}))