import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetObterVersao() {

    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getObterVersao = useCallback(
        (empresaId: string, id: string) => {
            return invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Caixa/${id}/versao`,
                method: "GET",
                enviarTokenUsuario: true,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getObterVersao,
    };
}
