import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette, { Palette, PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Shadows } from "@material-ui/core/styles/shadows";
import { useCallback } from "react";
import tinycolor from 'tinycolor2';
import { noAnimation, slowTheme } from "views/theme/slowTheme";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    teste?: Palette["primary"];
  }
  interface PaletteOptions {
    teste?: Palette["primary"];
  }
}

const defaultPaletteObject: PaletteOptions = {
  primary: {
    main: "#1F9CE4",
    contrastText: "#FFFFFF",
    light: '#7ec4ed'
  },
  common: {
    black: "#000",
    white: "#FEFEFE",
  },
  secondary: {
    main: "#666666",
    light: "#DDD",
  },

  success: {
    main: "#10C822",
  },
  error: {
    main: "#F44336",
    light: "rgba(244, 67, 54, 0.1);",
  },

  warning: {
    main: "#FFBC00",
    dark: "#EF9000",
  },

  info: {
    main: "#37ADF1",
  },

  text: {
    primary: "#666",
    secondary: "#999",
    disabled: "#999",
  },
  action: {
    disabled: "rgba(218, 218, 218, 0.3)"
  },
  divider: "rgba(85,85,85, 0.5)",
}

const defaultPalette = createPalette(defaultPaletteObject);

const breakpoints = createBreakpoints({});

export interface ColorsModel {
  primary: string;
  secondary: string;
  success: string;
  warning: string;
  info: string;
  error: string;
  textPrimary: string;
  textSecondary: string
  textDisabled: string;
  divider: string;
  backgroundDefault: string;
  backgroundPaper: string;
}

export class ChangeColorsProps {
  constructor(
    public primary: string = '#1F9CE4',
    public secondary: string = '#666666',
    public success: string = '#10C822',
    public warning: string = '#FFBC00',
    public info: string = '#37ADF1',
    public error: string = '#F44336',
    public textPrimary: string = '#666',
    public textSecondary: string = '#999',
    public textDisabled: string = '#999',
    public divider: string = '#E6E6E6',
    public backgroundDefault: string = '#fafafa',
    public backgroundPaper: string = '#fff'
  ) { }

  mudarCor(
    campo: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'error' | 'textPrimary' | 'textSecondary' | 'textDisabled' | 'divider' | 'backgroundDefault' | 'backgroundPaper',
    cor: string
  ) {
    this[campo] = cor
  }

  mudarPrimariaSecundaria(primary: string, secondary: string) {
    this.primary = primary;
    this.secondary = secondary;
  }

  mudarPadrao() {
    this.primary = '#C3AC6C';
    this.secondary = '#666666';
    this.success = '#10C822';
    this.warning = '#FFBC00';
    this.info = '#37ADF1';
    this.error = '#F44336';
    this.textPrimary = '#666';
    this.textSecondary = '#999';
    this.textDisabled = '#999';
    this.divider = '#E6E6E6';
    this.backgroundDefault = '#fafafa';
    this.backgroundPaper = '#fff';
  }

  setarPorPaleta(palette: Palette) {
    this.primary = palette.primary.main;
    this.secondary = palette.secondary.main;
    this.success = palette.success.main;
    this.warning = palette.warning.main;
    this.info = palette.info.main;
    this.error = palette.error.main;
    this.divider = palette.divider;
    this.textPrimary = palette.text.primary;
    this.textSecondary = palette.text.secondary;
    this.textDisabled = palette.text.disabled;
    this.backgroundDefault = palette.background.default;
    this.backgroundPaper = palette.background.paper;
  }

  setarDarkMode() {
    this.backgroundDefault = '#121212';
    this.backgroundPaper = '#121212';
    this.divider = 'rgba(255, 255, 255, 0.12)';
    this.textPrimary = '#fff';
    this.textSecondary = 'rgba(255, 255, 255, 0.7)';
    this.textDisabled = 'rgba(255, 255, 255, 0.5)';
  }
}

export const useWhitelabelTheme = () => {
  const { getRegistro, setRegistro } = useGestaoStorage()

  const defaultTheme = (() => {
    return createTheme({
      breakpoints: breakpoints,
      typography: {

        button: {
          textTransform: "none",
          fontWeight: 600,
        },
        fontFamily: `'Montserrat', sans-serif;`,
      },
      shadows: [
        "none",
        "1px 1px 2px rgba(0,0,0,0.1)",
        "2px 2px 3px rgba(0,0,0,0.1)",
        "2px 2px 5px rgba(0,0,0,0.1)",
        "3px 3px 5px rgba(0,0,0,0.1)",
        "4px 4px 5px rgba(0,0,0,0.1)",
        "1px 1px 2px rgba(0,0,0,0.2)",
        "2px 2px 3px rgba(0,0,0,0.2)",
        "2px 2px 5px rgba(0,0,0,0.2)",
        "3px 3px 5px rgba(0,0,0,0.2)",
        "4px 4px 5px rgba(0,0,0,0.2)",
        "1px 1px 2px rgba(0,0,0,0.3)",
        "2px 2px 3px rgba(0,0,0,0.3)",
        "2px 2px 5px rgba(0,0,0,0.3)",
        "3px 3px 5px rgba(0,0,0,0.3)",
        "4px 4px 5px rgba(0,0,0,0.3)",
        "1px 1px 2px rgba(0,0,0,0.4)",
        "2px 2px 3px rgba(0,0,0,0.4)",
        "2px 2px 5px rgba(0,0,0,0.4)",
        "3px 3px 5px rgba(0,0,0,0.4)",
        "4px 4px 5px rgba(0,0,0,0.4)",
        "1px 1px 2px rgba(0,0,0,0.5)",
        "2px 2px 3px rgba(0,0,0,0.5)",
        "2px 2px 5px rgba(0,0,0,0.5)",
        "3px 3px 5px rgba(0,0,0,0.5)"
      ] as Shadows,
      shape: {
        borderRadius: 8,
      },
      palette: defaultPalette,
      overrides: {
        ...slowTheme,
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor:
                "linear-gradient(335.37deg, rgba(246, 248, 250, 0.6) -3.52%, rgba(252, 251, 252, 0.6) 117.74%);",
            },
            /* width */
            "*::-webkit-scrollbar": {
              width: "8px",
            },
            /* Track */
            "*::-webkit-scrollbar-track": {
              background: defaultPalette.grey[200],
            },

            /* Handle */
            "*::-webkit-scrollbar-thumb": {
              background: defaultPalette.grey[400],
            },

            /* Handle on hover */
            "*::-webkit-scrollbar-thumb:hover": {
              background: defaultPalette.grey[500],
            },
          },
        },
        MuiButton: {
          root: {
            ...noAnimation,
            minWidth: 'none',
            height: 56,
            '&.round': {
              borderRadius: 28
            }
          },
          sizeLarge: {
            height: 64
          },
          sizeSmall: {
            height: 48
          }
        },
        MuiTextField: {
          root: {
            ...noAnimation,
            "& input": {
              fontWeight: 0,
              "&::placeholder": {
                color: defaultPalette.text.secondary,
                fontWeight: 0,
                opacity: 1,
              },
              "&:disabled": {
                color: defaultPalette.text.disabled,
              },
            },
            "& .MuiInput-underline:before": {
              borderColor: defaultPalette.text.disabled,
            },
          },
        },
        MuiFormControlLabel: {
          root: {
            ...noAnimation,
            color: defaultPalette.text.primary,
          },
        },
        MuiFormLabel: {
          root: {
            ...noAnimation,
            color: defaultPalette.text.disabled,
          },
        },
      },
      props: {
        MuiTextField: {
          InputLabelProps: {
            shrink: true,
          },
          variant: "outlined",

          fullWidth: true,
        },
      },
    })
  })()

  const mudarTema = useCallback((colors: ChangeColorsProps) => {
    let secondary = colors.secondary
    if (secondary === new ChangeColorsProps().secondary) {
      secondary = tinycolor(colors.primary).monochromatic()[3].toHexString()
    }
    const newPaletteOptions: PaletteOptions = {
      ...defaultPaletteObject,
      primary: {
        ...defaultPaletteObject.primary,
        light: tinycolor(colors.primary).lighten().toString(),
        dark: tinycolor(colors.primary).darken().toString(),
        main: colors.primary,
        contrastText: tinycolor(colors.primary).getBrightness() > 200 ? tinycolor(colors.primary).darken(50).toString() : '#fff'
      },
      secondary: {
        ...defaultPaletteObject.secondary,
        main: secondary,
        light: tinycolor(secondary).lighten().toHexString(),
        dark: tinycolor(secondary).darken().toHexString(),
      },
      success: {
        main: colors.success,
      },
      error: {
        ...defaultPalette.error,
      },
      info: {
        main: colors.info,
      },
      background: {
        paper: colors.backgroundPaper,
        default: colors.backgroundDefault,
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
        disabled: colors.textDisabled,
      }
    }

    setRegistro(GestaoStorageKeys.PaletaWhitelabel, newPaletteOptions, false)

  }, [setRegistro])

  const getTemaAtual = useCallback(() => {

    const cores = getRegistro(GestaoStorageKeys.PaletaWhitelabel, false) as PaletteOptions

    const palette = createPalette(cores)

    return createTheme({
      breakpoints: breakpoints,
      typography: {

        button: {
          textTransform: "none",
          fontWeight: 600,
        },
        fontFamily: `'Montserrat', sans-serif;`,
      },
      shadows: [
        "none",
        "1px 1px 2px rgba(0,0,0,0.1)",
        "2px 2px 3px rgba(0,0,0,0.1)",
        "2px 2px 5px rgba(0,0,0,0.1)",
        "3px 3px 5px rgba(0,0,0,0.1)",
        "4px 4px 5px rgba(0,0,0,0.1)",
        "1px 1px 2px rgba(0,0,0,0.2)",
        "2px 2px 3px rgba(0,0,0,0.2)",
        "2px 2px 5px rgba(0,0,0,0.2)",
        "3px 3px 5px rgba(0,0,0,0.2)",
        "4px 4px 5px rgba(0,0,0,0.2)",
        "1px 1px 2px rgba(0,0,0,0.3)",
        "2px 2px 3px rgba(0,0,0,0.3)",
        "2px 2px 5px rgba(0,0,0,0.3)",
        "3px 3px 5px rgba(0,0,0,0.3)",
        "4px 4px 5px rgba(0,0,0,0.3)",
        "1px 1px 2px rgba(0,0,0,0.4)",
        "2px 2px 3px rgba(0,0,0,0.4)",
        "2px 2px 5px rgba(0,0,0,0.4)",
        "3px 3px 5px rgba(0,0,0,0.4)",
        "4px 4px 5px rgba(0,0,0,0.4)",
        "1px 1px 2px rgba(0,0,0,0.5)",
        "2px 2px 3px rgba(0,0,0,0.5)",
        "2px 2px 5px rgba(0,0,0,0.5)",
        "3px 3px 5px rgba(0,0,0,0.5)"
      ] as Shadows,
      shape: {
        borderRadius: 8,
      },
      palette: palette,
      overrides: {
        ...slowTheme,
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor:
                "linear-gradient(335.37deg, rgba(246, 248, 250, 0.6) -3.52%, rgba(252, 251, 252, 0.6) 117.74%);",
            },
            /* width */
            "*::-webkit-scrollbar": {
              width: "8px",
            },
            /* Track */
            "*::-webkit-scrollbar-track": {
              background: palette.grey[200],
            },

            /* Handle */
            "*::-webkit-scrollbar-thumb": {
              background: palette.grey[400],
            },

            /* Handle on hover */
            "*::-webkit-scrollbar-thumb:hover": {
              background: palette.grey[500],
            },
          },
        },
        MuiButton: {
          root: {
            ...noAnimation,
            minWidth: 'none',
            height: 56,
            '&.round': {
              borderRadius: 28
            }
          },
          sizeLarge: {
            height: 64
          },
          sizeSmall: {
            height: 48
          }
        },
        MuiTextField: {
          root: {
            ...noAnimation,
            "& input": {
              fontWeight: 0,
              "&::placeholder": {
                color: palette.text.secondary,
                fontWeight: 0,
                opacity: 1,
              },
              "&:disabled": {
                color: palette.text.disabled,
              },
            },
            "& .MuiInput-underline:before": {
              borderColor: palette.text.disabled,
            },
          },
        },
        MuiFormControlLabel: {
          root: {
            ...noAnimation,
            color: palette.text.primary,
          },
        },
        MuiFormLabel: {
          root: {
            ...noAnimation,
            color: palette.text.disabled,
          },
        },
      },
      props: {
        MuiTextField: {
          InputLabelProps: {
            shrink: true,
          },
          variant: "outlined",

          fullWidth: true,
        },
      },
    })
  }, [getRegistro])

  return {
    mudarTema,
    getTemaAtual,
    defaultTheme
  }
}