import { EnumSituacaoContrato } from 'model/enums/enum-situacao-contrato';
import { useCallback, useEffect, useState } from 'react';
import { DialogMensagemOk } from '../dialog/dialog-mensagem-ok/dialog-mensagem-ok';
import { useSessaoAtual } from 'services/app';

export const MensagemRepresentanteOk = () => {
  const [isModalMensagemOk, setIsModalMensagemOk] = useState(false);
  const { plano } = useSessaoAtual();

  const closeModal = () => {
    setIsModalMensagemOk(false);
  };

  const verificarAvisoRepresentante = useCallback(() => {
    if (
      plano?.contratado.avisoCobrancaRepresentante &&
      plano?.contratado.status === EnumSituacaoContrato.OK
    ) {
      setIsModalMensagemOk(true);
    }
  }, [plano?.contratado.avisoCobrancaRepresentante, plano?.contratado.status]);

  useEffect(() => {
    verificarAvisoRepresentante();
  }, [verificarAvisoRepresentante]);

  return (
    <>
      <DialogMensagemOk
        loading={false}
        openned={isModalMensagemOk}
        closeModal={closeModal}
      />
    </>
  );
};
