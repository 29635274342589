import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormMesaValidation = () => {
  const FormMesaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        mesaInicial: Yup.string().required('Mesa inical é obrigatória'),
        mesaFinal: Yup.string().required('Mesa final é obrigatória'),
      })
    )
  }, []);

  return {
    FormMesaValidationYup,
  }
}

