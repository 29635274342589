
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';
import { MesasGerarModel } from "model/api/gestao/mesa/mesa-gerar-model";

export function usePostGerarMesa() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postGerarMesa = useCallback(
    (mesa: MesasGerarModel, empresaId: string, salaoId: string) =>
      invocarApi({
        url: `/v2/empresa/${empresaId}/salao/${salaoId}/mesa/gerar`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(mesa),
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
      }),
    [invocarApi],
  );
  return {
    ...outrasPropriedades,
    postGerarMesa,
  };
}
