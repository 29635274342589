import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 16
    },
    listContainer: {
        "& .card": {
            margin: theme.spacing(1),
        }
    },
    title: {
        color: '#666',
        fontSize: '1.25rem',
        fontWeight: 500
    },
    subcard: {
        color: '#666',
    },
    containerSubCard: {
        padding: theme.spacing(2)
    }
}));
