import { Grid } from "@material-ui/core";
import { GerarPagerIdentificadorHeader } from "./components/gerar-pager-identificador-header/gerar-pager-identificador-header";
import { GerarPagerIdentificador } from "./components/gerar-pager-identificador/gerar-pager-identificador";
import { useDefaultCadastroStyles } from "../../cadastros/components/default-cadastro-styles";

export const MovGerarPagerIdentificadorPage = () => {
  const classes = useDefaultCadastroStyles();



  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <GerarPagerIdentificadorHeader title={"Identificação do Pedido"} />
      </Grid>
      <Grid>
        <GerarPagerIdentificador />
      </Grid> 
    </Grid>
  );
};

export default MovGerarPagerIdentificadorPage;
