import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const DesfazerIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 16.8125 3.09375 C 16.59375 3.132813 16.398438 3.242188 16.25 3.40625 L 5.34375 14.28125 L 4.65625 15 L 5.34375 15.71875 L 16.25 26.59375 C 16.492188 26.890625 16.878906 27.027344 17.253906 26.941406 C 17.625 26.855469 17.917969 26.5625 18.003906 26.191406 C 18.089844 25.816406 17.953125 25.429688 17.65625 25.1875 L 8.46875 16 L 24 16 C 30.007813 16 34.242188 17.519531 36.96875 19.96875 C 39.695313 22.417969 41 25.835938 41 30 C 41 38.347656 37.15625 44.46875 37.15625 44.46875 C 36.933594 44.769531 36.894531 45.171875 37.0625 45.507813 C 37.230469 45.847656 37.570313 46.0625 37.945313 46.066406 C 38.320313 46.070313 38.667969 45.863281 38.84375 45.53125 C 38.84375 45.53125 43 38.964844 43 30 C 43 25.40625 41.5 21.332031 38.3125 18.46875 C 35.125 15.605469 30.347656 14 24 14 L 8.46875 14 L 17.65625 4.8125 C 17.980469 4.511719 18.066406 4.035156 17.871094 3.640625 C 17.679688 3.242188 17.246094 3.023438 16.8125 3.09375 Z" />
            </DefaultIcon>
        </>
    );
};

