import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { VoltarIcon } from 'views/components/icons';
import { useHistory } from 'react-router-dom';

export const UploadCargaHeader = () => {
  const history = useHistory();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={() => history.push('/configuracoes')}
      ></ButtonPrivateHeader>
    );
  }, [history]);

  return (
    <>
      <PrivatePageHeader title="Conversão de Dados" leftArea={leftArea()} />
    </>
  );
};
