import { Grid } from "@material-ui/core";
import { FormRefNFProps } from "./form-ref-nf-props";
import { Controller } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { UFMock } from "data/mocks";
import { ModNF } from "data/mocks/mod-nf";

export const FormRefNF = ({ control, formState, setValue, ...props }: FormRefNFProps) => {

    const { errors, touchedFields } = formState

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="refNF.cnpj"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'CNPJ_CPF'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="CPF/CNPJ"
                            allowSubmit
                            placeholder="Ex: 31.063.584/0001-20"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNF?.cnpj && errors.refNF?.cnpj.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.cnpj
                                    ? errors.refNF?.cnpj?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNF.mod"
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            conteudo={ModNF}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Modelo"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNF?.mod && errors.refNF?.mod.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.mod
                                    ? errors.refNF?.mod?.message
                                    : undefined
                            }
                            {...field}
                            onChange={(event) => {
                                const value = ModNF.filter(
                                    (x) => x.Key === event.target.value,
                                )[0]?.Key;
                                setValue("refNF.mod", value)
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNF.cUF"
                    control={control}
                    render={({ field }) => (
                        <SelectSaurus
                            disabled={props.loading}
                            conteudo={UFMock}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="UF"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNF?.cUF && errors.refNF?.cUF.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.cUF
                                    ? errors.refNF?.cUF?.message
                                    : undefined
                            }
                            {...field}
                            onChange={(event) => {
                                const value = UFMock.filter(
                                    (x) => x.Key === event.target.value,
                                )[0]?.Key;
                                setValue("refNF.cUF", value)
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="refNF.nNF"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Número"
                            placeholder="Ex: 12"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNF?.nNF && errors.refNF?.nNF.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.nNF
                                    ? errors.refNF?.nNF?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNF.serie"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'TEXTO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Série"
                            placeholder="Ex: 001"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.refNF?.serie && errors.refNF?.serie.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.serie
                                    ? errors.refNF?.serie?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="refNF.aamm"
                    control={control}
                    render={({ field }) => (
                        <TextFieldSaurus
                            tipo={'NUMERO'}
                            disabled={props.loading}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            label="Ano/Mês"
                            allowSubmit
                            InputLabelProps={{
                                shrink: true,
                            }}
                            maxTexto={4}
                            error={Boolean(errors.refNF?.aamm && errors.refNF?.aamm.message)}
                            helperText={
                                touchedFields.refNF || errors.refNF?.aamm
                                    ? errors.refNF?.aamm?.message
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
