import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    position: 'relative'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& svg': {
        fill: '#000',
        height: 25,
        width: 25
      }
    },
    '& svg': {
      fill: theme.palette.secondary.main,
      height: 25
    },
    '& p': {
      color: '#000'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  greyText: {
    color: `${theme.palette.grey[700]} !important`,
    fontSize: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 50
  },
  maisIcon: {
    fill: theme.palette.secondary.main
  }
}));