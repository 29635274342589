import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const NovaPermissaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <g id="surface14304773">
                    <path d="M 32.59375 2.9375 C 35.664062 4.519531 39.785156 6.429688 46.21875 7.84375 L 47 8.03125 L 47 8.8125 C 47 16.597656 45.515625 23.105469 43.328125 28.472656 C 42.691406 28.289062 42.03125 28.152344 41.359375 28.078125 C 43.410156 23.058594 44.835938 16.9375 44.9375 9.5625 C 38.859375 8.128906 34.667969 6.25 31.6875 4.71875 C 28.550781 3.105469 26.644531 2 25 2 C 23.355469 2 21.449219 3.105469 18.3125 4.71875 C 15.332031 6.25 11.140625 8.128906 5.0625 9.5625 C 5.246094 23.960938 10.222656 33.503906 15.25 39.4375 C 20.019531 45.066406 24.347656 47.230469 24.96875 47.53125 C 25.3125 47.347656 26.882812 46.484375 29.003906 44.792969 C 29.269531 45.398438 29.585938 45.980469 29.945312 46.53125 C 27.324219 48.636719 25.46875 49.59375 25.46875 49.59375 L 25.03125 49.8125 L 24.59375 49.59375 C 24.59375 49.59375 19.128906 47.066406 13.75 40.71875 C 8.371094 34.371094 3 24.132812 3 8.8125 L 3 8.03125 L 3.78125 7.84375 C 10.214844 6.429688 14.335938 4.519531 17.40625 2.9375 C 20.476562 1.355469 22.546875 0 25 0 C 27.453125 0 29.523438 1.355469 32.59375 2.9375 Z M 34.375 15.6875 L 33.84375 16.53125 L 25.0625 30.15625 L 24.40625 31.15625 L 23.53125 30.375 L 17.34375 24.75 L 16.59375 24.0625 L 17.9375 22.59375 L 18.65625 23.25 L 24.03125 28.09375 L 32.15625 15.46875 L 32.6875 14.625 Z M 34.375 15.6875 " />
                    <path d="M 45.898438 40 C 45.898438 40.601562 45.5 41 44.898438 41 L 41 41 L 41 44.898438 C 41 45.5 40.601562 45.898438 40 45.898438 C 39.398438 45.898438 39 45.5 39 44.898438 L 39 41 L 35.101562 41 C 34.5 41 34.101562 40.601562 34.101562 40 C 34.101562 39.398438 34.5 39 35.101562 39 L 39 39 L 39 35.101562 C 39 34.5 39.398438 34.101562 40 34.101562 C 40.601562 34.101562 41 34.5 41 35.101562 L 41 39 L 44.898438 39 C 45.5 39 45.898438 39.398438 45.898438 40 Z M 50 40 C 50 45.5 45.5 50 40 50 C 34.5 50 30 45.5 30 40 C 30 34.5 34.5 30 40 30 C 45.5 30 50 34.5 50 40 Z M 48 40 C 48 35.601562 44.398438 32 40 32 C 35.601562 32 32 35.601562 32 40 C 32 44.398438 35.601562 48 40 48 C 44.398438 48 48 44.398438 48 40 Z M 48 40 " />
                </g>
            </DefaultIcon>
        </>
    );
};

