import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetProdutoCategoriaById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoCategoriaById = useCallback(
    (id: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/categoria/${id}`,
        method: 'GET',
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoCategoriaById
  };
}
