import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { PagamentoEditConteudo } from "./components/pagamento-edit-conteudo/pagamento-edit-conteudo";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";

export interface PagamentoEditModalProps extends ModalProps {
  mov: VendaCompletaModel
}

export const PagamentoEditModal = (props: PagamentoEditModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && <PagamentoEditConteudo id={props.id} mov={props.mov} />}
    </DefaultModal>
  );
};
