import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.spacing(1),
    minWidth: '60px',
    height: '60px',
    flex: 1,
    padding: theme.spacing(1),
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "transparent",
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    minHeight: "60px",
    position: "relative",

    "&:hover": {
      background: theme.palette.primary.light,
      color: '#fff'
    }
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: 0,
      width: "30px",
      height: "30px",
    },
  },
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    flexWrap: "wrap",
  },
  celulaGrid: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    "& p": {
      textAlign: "center",
      lineHeight: 1,
    }
  },
  textPayment: {
    fontWeight: 700,
    fontSize: 12
  },
  textPaymentMain: {
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  textPaymentDisabled: {
    color: theme.palette.action.disabledBackground
  },
  iconCadeado: {
    position: 'absolute',
    right: 0,
    top: 2,
    '& svg': {
      padding: 0,
      margin: 0
    }
  },
}));

