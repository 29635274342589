import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { PontosVendaModel } from "model/api/gestao/pontos-venda/pontos-venda-model";
import { VariaveisAmbiente } from "config";

export function usePutPontosVenda() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putPontosVenda = useCallback(
    (pontoVenda: PontosVendaModel) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${pontoVenda.empresaId}/caixa/${pontoVenda.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(pontoVenda),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    putPontosVenda,
  };
}
