import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-enviar-por-email-styles';
import { VoltarIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useRef } from 'react';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';

interface DialogeEnviarPorEmailProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarEmail: () => void;
  email: string;
  setEmail: (email: string) => void;
}

export const DialogEnviarPorEmail = ({
  openned,
  closeModal,
  enviarEmail,
  loading,
  email,
  setEmail,
}: DialogeEnviarPorEmailProps) => {
  const classes = useStyles();
  const showContratar = enviarEmail;
  const refInput = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Enviar por e-mail"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ flex: 1 }}>
              <Typography className={classes.label}>
                Digite o E-mail.
              </Typography>
              <TextFieldSaurus
                inputRef={refInput}
                tipo="EMAIL"
                fullWidth
                size="small"
                allowSubmit={true}
                value={email}
                placeholder="E-mail.."
                onChange={(e) => setEmail(e.target.value)}
                adornmentColor={theme.palette.text.disabled}
              />
            </Box>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 16, marginBottom: 8 }}
            >
              <Grid item xs={4}>
                {closeModal && (
                  <Button
                    disabled={loading}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Button
                  disabled={!showContratar || loading}
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={enviarEmail}
                >
                  <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                  {'Enviar'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
