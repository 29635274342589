import { Box, Grid, Typography, useTheme, Button } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-controlado-styles';
import { MedicamentoIcon } from 'views/components/icons/medicamentos-icon';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useCadastros } from 'services/app';

interface DialogControladosProps {
  openned: boolean;
  qtd: number;
  handleClickNaoInformar: Function;
  callback?: () => Promise<any>;
}

export const DialogControlados = ({
  openned,
  qtd,
  handleClickNaoInformar,
  callback
}: DialogControladosProps) => {
  const { abrirReceita, fecharDialogControlado } = useCadastros();
  const classes = useStyles();
  const theme = useTheme();

  const handleClickInformar = async () => {
    abrirReceita();
    fecharDialogControlado();
  }

  return (
    <DialogSaurus
      icone={
        <MedicamentoIcon
          tipo="BUTTON"
          fill={`${theme.palette.secondary.main}`}
        />
      }
      colorTitulo={`${theme.palette.secondary.main}`}
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Controlados"
      tamanho="xs"
    >
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box
              sx={{ flex: 1, justifyContent: 'center', textAlign: 'center' }}
            >
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                Identificamos que essa venda
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                possui {qtd} {`${qtd === 1 ? 'medicamento' : 'medicamentos'}`}
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
              {`${qtd === 1 ? 'controlado' : 'controlados'}`}, deseja informar
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 500 }}>
                os dados da receita?
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ marginTop: 24 }}>
              <Grid item xs={6}>
                <Button fullWidth variant="outlined" onClick={async () => {
                  fecharDialogControlado();
                  if(callback) {
                   setTimeout(async () => {
                    await callback?.();
                   }, 500);
                  }
                  handleClickNaoInformar();
                }}>
                  <CancelarIcon tipo="BUTTON" />
                  <Box color={theme.palette.primary.main}>Não informar</Box>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant="contained" color="primary" onClick={handleClickInformar}>
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Informar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
