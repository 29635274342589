import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { SessaoValorPostModel } from "model/api/gestao/sessao/sessao-valor-post-model";

export function usePostSessaoValor() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postSessaoValor = useCallback(
        (empresaId: string, caixaId: string, sessaoId: string, payload: SessaoValorPostModel) => {
            return invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Sessao/${sessaoId}/Valor`,
                method: "POST",
                enviarTokenUsuario: true,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(payload),
                baseURL: `${VariaveisAmbiente.apiUrl}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postSessaoValor,
    };
}
