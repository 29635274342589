import { useCallback } from 'react';

import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";
import { CategoriaHeader } from "./components/categoria-header/categoria-header";

import { CategoriaList } from "./components/categoria-list/categoria-list";
import { ButtonFab } from "views/components/controles";
import { CategoriaAddIcon } from "views/components/icons";
import { Grid } from "@material-ui/core";
import { useCadastros } from 'services/app';

export const CategoriaPage = () => {

  const classes = useDefaultCadastroStyles();

  const { abrirCadastroCategoria } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroCategoria('', '', true );
  }, [abrirCadastroCategoria]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <CategoriaHeader/>
      </Grid>
      <Grid className={classes.list}>
        <CategoriaList />
      </Grid>
      <ButtonFab tooltip="Adicionar Categoria" icon={<CategoriaAddIcon tipo="BUTTON_FAB" />} onClick={onClickAdd} />

    </Grid>
  );
};

export default CategoriaPage;
