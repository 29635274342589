import { ProdutoVincularFiscalModel } from 'model/api/gestao/produto/produto-imposto/produto-vincular-fiscal';
import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutProdutoVincularFiscal() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putVincularFiscal = useCallback(
    (
      informacaoFiscal: ProdutoVincularFiscalModel,
      empresaId: string,
      produtoId: string
    ) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/produto/${produtoId}/variacoes/vincular-fiscal`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(informacaoFiscal),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putVincularFiscal
  };
}
