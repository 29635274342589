import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const ScannerIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 4 4 L 4 10 A 1.0001 1.0001 0 1 0 6 10 L 6 6 L 10 6 A 1.0001 1.0001 0 1 0 10 4 L 4 4 z M 40 4 A 1.0001 1.0001 0 1 0 40 6 L 44 6 L 44 10 A 1.0001 1.0001 0 1 0 46 10 L 46 4 L 40 4 z M 13 10 L 13 32 L 5 32 A 1.0001 1.0001 0 1 0 5 34 L 13.832031 34 A 1.0001 1.0001 0 0 0 14.158203 34 L 35.832031 34 A 1.0001 1.0001 0 0 0 36.158203 34 L 45 34 A 1.0001 1.0001 0 1 0 45 32 L 37 32 L 37 10 L 13 10 z M 15 12 L 35 12 L 35 32 L 15 32 L 15 12 z M 13 36 L 13 40 L 37 40 L 37 36 L 35 36 L 35 38 L 15 38 L 15 36 L 13 36 z M 4.984375 38.986328 A 1.0001 1.0001 0 0 0 4 40 L 4 46 L 10 46 A 1.0001 1.0001 0 1 0 10 44 L 6 44 L 6 40 A 1.0001 1.0001 0 0 0 4.984375 38.986328 z M 44.984375 38.986328 A 1.0001 1.0001 0 0 0 44 40 L 44 44 L 40 44 A 1.0001 1.0001 0 1 0 40 46 L 46 46 L 46 40 A 1.0001 1.0001 0 0 0 44.984375 38.986328 z" />
            </DefaultIcon>
        </>
    );
};