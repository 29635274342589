import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useGetComandas() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getComandas = useCallback(
        (query: string, empresaId: string) =>
            invocarApi({
                url: `/v2/empresa/${empresaId}/comanda`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getComandas,
    };
}
