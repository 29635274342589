import { useCallback } from 'react';
import * as Yup from 'yup';

function CPFValido(cpf: string) {
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    ) {
        return false
    }
    let soma = 0
    let resto
    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11)) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
}

const ConstruirDigito = (cnpjArray: number[]) => {
    const total =
        [...cnpjArray]
            .reverse()
            .map((current, index) => {
                const fator = index + 2 > 9 ? index - 6 : index + 2;

                return current * fator;
            })
            .reduce((previous, current) => previous + current, 0) % 11;

    return total < 2 ? 0 : 11 - total;
};

export function CnpjValido(cnpj: string): boolean {
    const cnpjApenasComNumeros = (cnpj || '').replace(/\D+/g, '');

    if (cnpjApenasComNumeros.length !== 14) {
        return false;
    }

    const cnpjArray = cnpjApenasComNumeros.split('').map((numero) => Number.parseInt(numero, 10));
    const todosOsDigitosSaoIguais = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].find((atual) =>
        cnpjArray.every((digito) => digito === atual),
    );

    if (Boolean(todosOsDigitosSaoIguais) || todosOsDigitosSaoIguais === 0) {
        return false;
    }

    const cnpjSemDigito = cnpjArray.slice(0, cnpjArray.length - 2);
    const primeiroDigito = ConstruirDigito(cnpjSemDigito);
    const segundoDigito = ConstruirDigito([...cnpjSemDigito, primeiroDigito]);

    return `${cnpjSemDigito.join('')}${primeiroDigito}${segundoDigito}` === cnpjApenasComNumeros;
}

export const useValidationYupDocumento = (nullable: boolean) => {
    const documentoYup = useCallback((type?: 'CPF' | 'CNPJ') => {
        if (nullable) {
            return (Yup.string()
                .test('cnpj', 'O CNPJ está inválido.',
                    (value) => {
                        if (type === 'CNPJ') {
                            const valueString = value as string
                            if (valueString !== undefined && valueString.length > 0) {
                                if (valueString.length >= 15) {
                                    return CnpjValido(valueString)
                                }
                            }
                        }
                        return true
                    })
                .test('cpf', 'O CPF está inválido', (value) => {
                    if (type === 'CPF') {
                        const valueString = value as string
                        if (valueString !== undefined && valueString.length > 0) {
                            if (valueString.length < 15) {
                                return CPFValido(valueString)
                            }
                        }
                    }
                    return true
                }).test('documento', 'Documento inválido', (value) => {
                    let isValid = true
                    if (value !== undefined && value.length > 0) {
                        isValid = CnpjValido(value as string)
                        if (!isValid) {
                            isValid = CPFValido(value as string)
                        }
                    }
                    return isValid
                })
            )
        } else {
            return (Yup.string().required(`O ${type !== undefined ? type : 'documento'} é obrigatório.`)
                .test('cnpj', 'O CNPJ está inválido.',
                    (value) => {
                        if (type === 'CNPJ') {
                            const valueString = value as string
                            if (valueString !== undefined) {
                                if (valueString.length >= 15) {
                                    return CnpjValido(valueString)
                                }
                            }
                        }
                        return true
                    })
                .test('cpf', 'O CPF está inválido', (value) => {
                    if (type === 'CPF') {
                        const valueString = value as string
                        if (valueString !== undefined) {
                            if (valueString.length < 15) {
                                return CPFValido(valueString)
                            }
                        }
                    }
                    return true
                }).test('documento', 'Documento inválido', (value) => {
                    let isValid = false
                    if (value !== undefined) {
                        isValid = CnpjValido(value as string)
                        if (!isValid) {
                            isValid = CPFValido(value as string)
                        }
                    }
                    return isValid
                })
            )
        }
    }, [nullable])
    return {
        documentoYup,
    }
}