import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { NcmModal } from "views/components/modals/ncm/ncm-modal";

export const CadastroNcmModal = () => {
    const { push, location } = useHistory();
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        id: '',
        aberto: false,
        ultimaUrl: '',
        search: ''
    });

    const ncmRoute = `/ncms/`;
    const novoCadastroRoute = 'adicionar';

    consoleDev('CadastroNcmModal')

    useEffect(() => {
        if (location.pathname.indexOf(ncmRoute) === 0) {
            const posrota = location.pathname.replace(ncmRoute, '');
            if (posrota.length > 0) {
                setModalState(prev => ({
                    id: posrota.replace(novoCadastroRoute, ''),
                    aberto: true,
                    ultimaUrl: ncmRoute,
                    search: prev.search
                }));
                return;
            }
        }
    }, [location.pathname, ncmRoute]);

    const modalAlterado = useCallback(({ id, openned, callbackUrl, trocarUrl }: any) => {
        let url = callbackUrl.length === 0 ? ncmRoute : callbackUrl;
        if (modalState.aberto) {
            url = modalState.ultimaUrl;
        }
        const prevSearch = modalState.search;
        setModalState({
            id: id,
            aberto: openned,
            ultimaUrl: url,
            search: window.location.search
        });
        if (!openned) {
            push(ncmRoute + prevSearch)
        }
        if (trocarUrl) {
            push(ncmRoute + (id?.length === 0 ? novoCadastroRoute : id));
        }
    }, [modalState.aberto, modalState.search, modalState.ultimaUrl, ncmRoute, push])

    useEffect(() => {
        addHandler(AppEventEnum.NcmModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.NcmModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <NcmModal openned={modalState.aberto} key="mdlPessoa" id={modalState.id} />
    )
}