import { useCallback } from "react";
import { ProdutoNovoSubItemModel } from "../../../../../model/api/gestao/produto/produto-subitem/produto-subitem-model";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutProdutoSubItem() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProdutoSubItem = useCallback(
    (
      produtoId: string,
      variacaoId: string,
      produtoSubItem: ProdutoNovoSubItemModel
    ) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/sub-itens/${produtoSubItem.id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(produtoSubItem),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoSubItem,
  };
}
