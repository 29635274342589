import { isEmpty } from "lodash";
import { MovSimplesModel } from "model/api/gestao/movimentacao/simples/mov-simples-model";
import React from "react";
import { GestaoStorageKeys, useGestaoStorage } from ".";

export function useMovAtualStorage() {

    const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
    
    const persist = React.useCallback((model: MovSimplesModel | undefined) => {
        if (model) {
            setRegistro(GestaoStorageKeys.MovSimplesAtual, model, false);
        } else {
            delRegistro(GestaoStorageKeys.MovSimplesAtual, false);
        }
    }, [delRegistro, setRegistro]);

    const get = React.useCallback((): MovSimplesModel | undefined => {
        return isEmpty(getRegistro(GestaoStorageKeys.MovSimplesAtual, false))
            ? undefined
            : getRegistro(GestaoStorageKeys.MovSimplesAtual, false);
    }, [getRegistro]);

    return {
        persist,
        get
    };
}
