
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormCodigoSuporteRemotoValidationYup = () => {

  const FormCodigoSporteRemotoValidationYup = useMemo(() => {
    return Yup.object().shape({
      codigo: Yup.string().required(),
    });
  }, [])

  return {
    FormCodigoSporteRemotoValidationYup,
  }
}
