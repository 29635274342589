import { useCallback, useState } from "react"
import { EntradaFiltroConteudoProps } from "./entrada-filtro-conteudo-props"
import { ModalHeader } from "../../components"
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header"
import { VoltarIcon } from "views/components/icons"
import { useHistory } from "react-router-dom"
import { EntradaFiltroData, EntradaFiltroNNF, EntradaFiltroStatus } from "./components"
import { EntradaFiltroInicial } from "./components/entrada-filtro-inicial"
import { useFiltrosModais } from "services/app/hooks/filtros-modais"
import { useModalStyles } from "../../utils/modal-styles"
import { isDateEmpty } from "utils/is-date-empty"

export enum EnumEntradaFiltroPagina {
    Inicial,
    Data,
    Status,
    NNF,
}

export const EntradaFiltroConteudo = (props: EntradaFiltroConteudoProps) => {

    const [pagina, setPagina] = useState<EnumEntradaFiltroPagina>(EnumEntradaFiltroPagina.Inicial)
    const classes = useModalStyles();
    const history = useHistory()
    const [vFiltros, setVFiltros] = useState(props.filtros)
    const { fecharEntradaFiltroModal } = useFiltrosModais()

    const entrarPagina = useCallback((fase: EnumEntradaFiltroPagina) => {
        setPagina(fase)
    }, [])

    const alterarFiltro = useCallback((key: string, value: string | number) => {
        setVFiltros(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }, [])

    const limparFiltros = useCallback(() => {
        fecharEntradaFiltroModal()
        history.push('/entrada-mercadoria')
    }, [fecharEntradaFiltroModal, history])

    const handleSubmit = useCallback(() => {
        const searchs: Array<string | null> = [
            !isDateEmpty(vFiltros.dInicial) ? `dInicial=${vFiltros.dInicial}` : null,
            !isDateEmpty(vFiltros.dFinal) ? `dFinal=${vFiltros.dFinal}` : null,
            vFiltros.nnf > 0 ? `nnf=${vFiltros.nnf}` : null,
            `status=${vFiltros.status}`,
            vFiltros.termo.length > 0 ? `termo=${vFiltros.termo}` : null,
        ]

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
            pathname: '/entrada-mercadoria',
            search: query
        })
        fecharEntradaFiltroModal(true)
    }, [fecharEntradaFiltroModal, history, vFiltros.dFinal, vFiltros.dInicial, vFiltros.nnf, vFiltros.status, vFiltros.termo])

    const switchPagina = (filtro: EnumEntradaFiltroPagina) => ({
        [EnumEntradaFiltroPagina.Inicial]: <EntradaFiltroInicial
            vFiltros={vFiltros}
            limparFiltros={limparFiltros}
            fecharModal={fecharEntradaFiltroModal}
            entrarPagina={entrarPagina}
            submit={handleSubmit}
            setVFiltros={setVFiltros}
        />,
        [EnumEntradaFiltroPagina.Data]: <EntradaFiltroData
            vFiltro={{ dInicial: vFiltros.dInicial, dFinal: vFiltros.dFinal }}
            alterarFiltro={alterarFiltro}
            entrarPagina={entrarPagina}
        />,
        [EnumEntradaFiltroPagina.NNF]: <EntradaFiltroNNF
            vFiltro={vFiltros.nnf}
            alterarFiltro={alterarFiltro}
            entrarPagina={entrarPagina}
        />,
        [EnumEntradaFiltroPagina.Status]: <EntradaFiltroStatus
            vFiltro={vFiltros.status}
            alterarFiltro={alterarFiltro}
            entrarPagina={entrarPagina}
        />,
    })[filtro] || <EntradaFiltroInicial
            vFiltros={vFiltros}
            limparFiltros={limparFiltros}
            fecharModal={fecharEntradaFiltroModal}
            entrarPagina={entrarPagina}
            submit={handleSubmit}
            setVFiltros={setVFiltros}
        />

    return (
        <div className={classes.root}>
            <ModalHeader
                title={'Filtrar'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={
                            pagina !== EnumEntradaFiltroPagina.Inicial
                                ? () => entrarPagina(EnumEntradaFiltroPagina.Inicial)
                                : fecharEntradaFiltroModal
                        }
                    />
                }
            />
            {switchPagina(pagina)}
        </div>
    )
}