import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormPlanoValidation = () => {
  const FormPlanoValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        modulos: Yup.array().of(
            Yup.object().shape({
                quantidadeContratada: Yup.number().test({
                  message: 'Quantidade Inválida.',
                  test: (val, context) => {
                    if (val !== undefined && (val < context.parent.quantidadeMinima || val > context.parent.quantidadeMaxima)) {
                      return false;
                    }
                    return true;
                  }
                })
              })
        )
      })
    )
  }, [])
  return {
    FormPlanoValidationYup,
  }
}

