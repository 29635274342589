import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogQRCode } from 'views/components/dialog/dialog-qrcode/dialog-qrcode';

class QrCodeDialogProps {
  constructor(
    public aberto: boolean = false,
    public texto: string = '',
  ) {}
}

export const QrCodeDialog = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<QrCodeDialogProps>(
    new QrCodeDialogProps()
  );

  consoleDev('QrCodeDialog');

  const modalAlterado = useCallback(({ aberto, texto}: any) => {
    setModalState({
      aberto,
      texto
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.DialogQrCode, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogQrCode, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogQRCode
          openned={modalState.aberto}
          text={modalState.texto}
        />
      )}
    </>
  );
};
