import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetDashboardPagamentosSessao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getDashboardPagamentosSessao = useCallback((sessaoId: string, query: string) => {

    let queryPage = "";
    let queryFinal = `${queryPage?.length > 0 || query?.length > 0 ? "?" : ""
      }${queryPage}${query}`;


    return invocarApi({
      url: `/${VariaveisAmbiente.gatewayVersion}/Dashboard/Sessao/${sessaoId}/pagamentos${queryFinal}`,
      method: "GET",
      enviarTokenUsuario: true,
    });
  }, [invocarApi]);

  return {
    ...outrasPropriedades,
    getDashboardPagamentosSessao,
  };
}
