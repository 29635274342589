import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const SetorProcessosAddIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2929 3.29292C10.4805 3.10539 10.7348 3.00003 11 3H39C39.2652 3.00003 39.5195 3.10539 39.7071 3.29292C39.8946 3.48045 40 3.73479 40 4V8H41C41.2652 8.00003 41.5195 8.10539 41.7071 8.29292C41.8946 8.48045 42 8.73479 42 9V13H43C43.2652 13 43.5195 13.1054 43.7071 13.2929C43.8946 13.4805 44 13.7348 44 14V27.6054C43.3676 27.2395 42.6986 26.9298 42 26.6828V15H41.1582C41.0502 15.0178 40.94 15.0178 40.832 15H9.1582C9.05021 15.0178 8.94002 15.0178 8.83203 15H8V29H19C19.2652 29 19.5195 29.1054 19.7071 29.2929C19.8946 29.4805 20 29.7348 20 30C20 32.7737 22.2263 35 25 35C25.4986 35 25.9796 34.928 26.4331 34.7939C26.2434 35.4798 26.1131 36.1903 26.048 36.9196C25.7072 36.9725 25.3573 37 25 37C21.4999 37 18.7095 34.3631 18.2031 31H5V45H28.2521C28.7793 45.7329 29.3871 46.4039 30.0625 47H4C3.73479 47 3.48045 46.8946 3.29292 46.7071C3.10539 46.5195 3.00003 46.2652 3 46V30C3.00003 29.7348 3.10539 29.4805 3.29292 29.2929C3.48045 29.1054 3.73479 29 4 29H6V14C6.00003 13.7348 6.10539 13.4805 6.29292 13.2929C6.48045 13.1054 6.73479 13 7 13H8V9C8.00003 8.73479 8.10539 8.48045 8.29292 8.29292C8.48045 8.10539 8.73479 8.00003 9 8H10V4C10 3.73479 10.1054 3.48045 10.2929 3.29292ZM38 5H12V8H38V5ZM10.832 10H10V13H40V10H39.1582C39.0502 10.0178 38.94 10.0178 38.832 10H11.1582C11.0502 10.0178 10.94 10.0178 10.832 10Z"
        />
        <path d="M15.9,10c0,0.6 -0.4,1 -1,1h-3.9v3.9c0,0.6 -0.4,1 -1,1c-0.6,0 -1,-0.4 -1,-1v-3.9h-3.9c-0.6,0 -1,-0.4 -1,-1c0,-0.6 0.4,-1 1,-1h3.9v-3.9c0,-0.6 0.4,-1 1,-1c0.6,0 1,0.4 1,1v3.9h3.9c0.6,0 1,0.4 1,1zM20,10c0,5.5 -4.5,10 -10,10c-5.5,0 -10,-4.5 -10,-10c0,-5.5 4.5,-10 10,-10c5.5,0 10,4.5 10,10zM18,10c0,-4.4 -3.6,-8 -8,-8c-4.4,0 -8,3.6 -8,8c0,4.4 3.6,8 8,8c4.4,0 8,-3.6 8,-8z" transform="translate(28, 28)"></path>

      </DefaultIcon>
    </>
  );
};