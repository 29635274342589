import { SessaoGerenciamentoModel } from 'model/api/gestao/sessao/sessao-gerenciamento-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogEditarGerenciamentoSessao } from 'views/components/dialog/dialog-editar-gerenciamento-sessao/dialog-editar-gerenciamento-sessao';

export interface DialogEditarGerenciamentoSessaoRenderProps {
    aberto: boolean;
    model: SessaoGerenciamentoModel;
}

export const DialogEditarGerenciamentoSessaoRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogEditarGerenciamentoSessaoRenderProps>();

    consoleDev('DialogEditarGerenciamentoSessaoRender');

    const modalAlterado = useCallback(
        ({
            aberto,
            model,
        }: DialogEditarGerenciamentoSessaoRenderProps) => {
            setModalState({
                aberto,
                model,
            });
        },
        []
    );

    useEffect(() => {
        addHandler(AppEventEnum.DialogEditarGerenciamentoSessao, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogEditarGerenciamentoSessao, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState?.aberto && (
                <DialogEditarGerenciamentoSessao
                    aberto={modalState.aberto}
                    model={modalState.model}
                />
            )}
        </>
    );
};
