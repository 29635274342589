import { EnumFluxoTutorial } from "model/enums/enum-fluxo-tutorial";
import { EnumTour } from "model/enums/enum-tour";

export class TutorialPostModel {
    constructor(
        public tutorialId: string = '',
        public referencia: string = '',
        public passo: number = 0,
        public concluido: boolean = true,
        public fluxo: EnumFluxoTutorial = EnumFluxoTutorial.BOASVINDAS
    ) { }
}

export class TutorialStatusModel{
    constructor(
    public id: string = "",
    public tutorialId: string = "",
    public referencia: string = "",
    public passo: number = 0,
    public concluido: boolean = true
    ){}
    
}
export class TutorialResModel {
    constructor(
    public tutorialStatus: TutorialPostModel | null = null,
    public sistemaNome: string | null = null,
    public id: string = "",
    public qtdePassos: number | null = null,
    public json: string | null = null,
    public nome: string | null = null,
    public dataInicial: string | null = null,
    public dataFinal: string | null = null,
    public sistemaId: string | null = null,
    public ativo: boolean | null = null,
    public ordem: number | null = null,
    public fluxo: EnumFluxoTutorial = EnumFluxoTutorial.BOASVINDAS,
    public fluxoDescricao: string | null = null,
    public tipo: EnumTour = EnumTour.INICIO,
    public tipoDescricao: number | null = null
    ){}
    
}
