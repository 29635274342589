import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'calc(100% - 8px)',
    overflow: 'auto',
    paddingBottom: theme.spacing(1)
  },
  stepperOuterContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  rootContainer: {
    flex: '1 1 100%',
    display: 'block'
  },
  divForm: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  Buttons: {
    flex: '0 0 auto'
  },
  Content: {
    display: 'block',
    flex: '0 0 auto'
  },
  Fix: {
    flex: '1 1 100%',
    display: 'block',
    position: 'relative'
  },
  Form: {
    minHeight: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  Title: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3)
    },
    "& svg": {
      width: '40px',
      height: '40px',
      marginBottom: -6,
      marginRight: 8,
      fill: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        width: '40px',
        height: '40px',
        marginBottom: -6,
      },
      [theme.breakpoints.up('md')]: {
        width: '42px',
        height: '42px',
        marginBottom: -6,

      },
      [theme.breakpoints.up('lg')]: {
        width: '48px',
        height: '48px',
        marginBottom: -8,
      }
    },
  },
}));
