import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetConfigContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getConfigContrato = useCallback(
        (contratoId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Contrato/${contratoId}/configuracao/detalhado`,
                method: "GET",
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getConfigContrato,
    };
}
