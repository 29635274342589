import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogNovoPDV } from "views/components/dialog/dialog-novo-pdv/dialog-novo-pdv";

class AdicionarNovoPDVDialogProps {
    constructor(
        public aberto: boolean = false,
    ) { }
}

export const AdicionarNovoPDVDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<AdicionarNovoPDVDialogProps>(
        new AdicionarNovoPDVDialogProps()
    );

    consoleDev('NovoPDVDialog')

    const modalAlterado = useCallback(({ aberto }: any) => {
        setModalState({
            aberto: aberto,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.NovoPDVDialog, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.NovoPDVDialog, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogNovoPDV openned={modalState.aberto} />
            )}
        </>
    )
}