import { PlanoModel } from "model/api/gestao/plano/plano-model"
import { isPlanoFarmaceutico } from "./plano-utils"

export const nomeMarcaAmbiente = (plano: PlanoModel | undefined, maiusculoInicio?: boolean, plural?: boolean) => {
    const isFarmaceutico = isPlanoFarmaceutico(plano)

    if (isFarmaceutico) {
        return (maiusculoInicio ? `Laboratório${(plural ? 's' : '')} e Marca${(plural ? 's' : '')}` : `laboratório${(plural ? 's' : '')} e marca${(plural ? 's' : '')}`)
    }

    return (maiusculoInicio ? 'Marca' : 'marca') + (plural ? 's' : '')
}