import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { useHistory } from 'react-router-dom';
import { VoltarIcon } from 'views/components/icons';

export const LeitorComandaHeader = () => {
  const { push } = useHistory();

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={() => push('/venda-simples/catalogo')}
      ></ButtonPrivateHeader>
    ),
    [push],
  );

  return (
    <>
      <PrivatePageHeader title="Leitor de Comanda" leftArea={leftArea()} />
    </>
  );
};
