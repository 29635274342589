import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetDashboardPagamentos() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getDashboardPagamentos = useCallback(
    (
      query: string
      ) => {

      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/dashboard/pagamentos${query}`,
        method: "GET",
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getDashboardPagamentos,
  };
}
