import { ApagarIcon } from 'views/components/icons/apagar-icon';
import { useStyles } from './button-keyboard-backspace-styles';
import { ButtonKeyboardBackspaceProps } from './button-keyboard-backspace-props';
import { useThemeQueries } from 'views/theme';

export function ButtonKeyboardBackspace(props: ButtonKeyboardBackspaceProps) {
  const classes = useStyles();
  const {theme} = useThemeQueries()
  return (
    <div
      className={classes.buttonKeyboardBackspaceContainer}
      onClick={() => {
        if (props.clickFunc != null) props.clickFunc();
      }}
    >
      <ApagarIcon tipo="BUTTON_FAB" fill={theme.palette.primary.main} style={{height:60, width:60}} />
    </div>
  );
}
