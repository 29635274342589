import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
    cardContainer: {
        padding: 0,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "transparent",
        transition: "0.3s ease-in-out border",
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        cursor: "pointer",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        minHeight: "60px",
        position: "relative",
        marginTop: "5px"
    },
    cardSelected: {
        borderColor: theme.palette.primary.main,
    },
    tagStatus: {
        width: "5px",
        borderRadius: "5px",
        margin: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cardIcon: {
        width: "50px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            margin: 0,
            width: "22px",
            height: "22px",
            fill: theme.palette.text.primary,
        },
    },
    cardColor: {
        width: "25px",
        height: "25px",
        borderRadius: "5px",
        borderColor: theme.palette.grey[700],
        borderStyle: "solid",
        marginBottom: 10,
        margin: 0,
        boxShadow: theme.shadows[6],
    },
    cardRightButton: {
        width: "50px",
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        borderLeftColor: theme.palette.text.primary,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        "& svg": {
            margin: 0,
            width: "22px",
            height: "22px",
            fill: theme.palette.text.primary,
        },
    },
    cardContent: {
        flex: "1 1 100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        "& div:last-child": {
            marginRight: theme.spacing(1),
        },
        "& span": {
            fontSize: "0.7em",
        },
        "& p": {
            fontSize: "1.0em",
        },
    },
    celulaGridNomeCliente: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    celulaGrid: {
        marginLeft: theme.spacing(1),
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    celulaGridFull: {
        marginLeft: theme.spacing(2),
        flex: "1 1 auto",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        "& p": {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        }
    },
    cardImg: {
        display: "flex",
        objectFit: "contain",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: "50px",
        height: "50px",
        borderRadius: "5px",
        transition: "0.2s ease-in-out transform",
        "&:hover": {
            transform: "scale(1.5)",
        }
    },
    containerStatus: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        minWidth: '200px',
        alignItems: 'right',
        justifyContent: 'flex-end',
    }
}));

