import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0px -1px 8px 0px #60617029 ',
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
        '& p': {
            fontSize: '14px',
        }
    },
    iconContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(1)
    },
    overflowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    weight: {
        fontWeight: 500
    }
}));