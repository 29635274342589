import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-editar-mesa-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading } from '../../..';
import { useCallback, useEffect, useRef } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { usePutMesa } from 'data/api/gestao/mesas/put-mesa';
import { MesasNovaModel } from 'model/api/gestao/mesa/mesa-nova-model';
import { isEqual } from 'lodash';
import { FormEditMesa } from 'views/components/form/mesa/form-edit-mesa/form-mesa-gerar';

interface DialogEditarMesaProps {
  close: () => void;
  carregando?: boolean;
  aberto: boolean;
  salaoId: string;
  mesa: MesasNovaModel;
}

export const DialogEditarMesa = ({
  close,
  carregando,
  aberto,
  salaoId,
  mesa,
}: DialogEditarMesaProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { putMesa, carregando: carregandoPut } = usePutMesa();
  const refEditForm = useRef<DefaultFormRefs<MesasNovaModel>>(null);
  const classesComponent = useStyles();
  const loading = carregando || carregandoPut;

  const recarregarForm = useCallback((model: MesasNovaModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(mesa);
  }, [mesa, recarregarForm]);

  const saveChangesMesa = useCallback(
    async (mesaModelForm: MesasNovaModel) => {
      const ret = await putMesa(
        mesaModelForm,
        getEmpresaSelecionada()!.Id,
        salaoId,
      );
      if (ret.erro) {
        throw ret.erro;
      }
    },
    [getEmpresaSelecionada, putMesa, salaoId],
  );

  const handleSubmit = useCallback(
    async (model: MesasNovaModel, beforeModel: MesasNovaModel) => {
      try {
        const mesaEqual = isEqual(model, beforeModel);
        if (!mesaEqual) {
          await saveChangesMesa(model);
        }

        if (mesaEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
        } else {
          showToast('success', 'Salao atualizado com Sucesso!');
        }
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [saveChangesMesa, showToast],
  );

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  return (
    <DialogSaurus aberto={aberto || false} titulo="Editar Mesa" tamanho="xs">
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classesComponent.cardContainer}>
        <Grid className={classesComponent.cardContent}>
          <FormEditMesa
            ref={refEditForm}
            showLoading={false}
            model={mesa}
            loading={loading ?? false}
            onSubmit={handleSubmit}
          />
          <Grid container className={classesComponent.acoes} spacing={2}>
            <Grid item xs={5}>
              <Button
                disabled={loading}
                onClick={onCloseClick}
                variant="outlined"
                color="primary"
                fullWidth
              >
                <VoltarIcon tipo="BUTTON" />
                Voltar
              </Button>
            </Grid>
            <Grid item xs={7}>
              <Button
                disabled={loading}
                onClick={() => {
                  refEditForm.current?.submitForm();
                  onCloseClick();
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                <OkIcon tipo="BUTTON_PRIMARY" />
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
