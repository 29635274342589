import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { IStatusType } from 'services/app/hooks/sincronizacao-cadastros';
import { CardSincronizacao } from 'views/components/cards/card-sinc';

export interface SucessoFalhaListDataProps {
  list: Array<IStatusType>;
  onCardSelected?: (id: string) => any;
  onCardChecked?: (model: IStatusType) => Promise<void>;
  erro?: boolean;
}

export const SucessoFalhaListData = (props: SucessoFalhaListDataProps) => {
  const onCardSelected = (id: string) => {
    if (props.onCardSelected) props?.onCardSelected(id);
  };

  const onCardChecked = async (model: IStatusType): Promise<void> => {
    if (props.onCardChecked) return await props.onCardChecked(model);
    return undefined;
  };

  return (
    <>
      {props.list.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhuma sincronização encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((status, index) => {
          return (
            <CardSincronizacao
              model={status}
              key={index}
              onClick={onCardSelected}
              onCheck={onCardChecked}
              erro={props.erro}
            />
          );
        })}
    </>
  );
};
