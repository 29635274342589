import { Box, Fade, Slide, Typography } from '@material-ui/core';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { CodigoBarrasIcon } from 'views/components/icons/codigo-barras-icon';
import { useStyles } from './toast-leitor-styles'
import CloseIcon from '@material-ui/icons/Close';
import useEventListener from '@use-it/event-listener';
import { focusableElement } from 'utils/focusable-element';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { useKeyDownHandler } from 'services/app/hooks/keydown-handler';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { stopPropagationWithFeedback } from 'utils/custom-stop-propagation';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { useHistory } from 'react-router';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { MovSimplesPessoaModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pessoa-model';
import { isOnlyHasNumber } from 'utils/to-decimal';

export function ToastLeitor() {

    //AUX
    const classes = useStyles()
    const history = useHistory();

    //STATES E REFS
    const valorRef = useRef<string>('')
    const [erro, setErro] = useState('')
    const permiteUsar = useRef<boolean>(false)
    const valorDiv = useRef<HTMLDivElement | null>(null)
    const [nomeProd, setNomeProd] = useState<string>('')
    const [aberto, setAberto] = useState<boolean>(false)
    const [isCpfCnpj, setIsCpfCnpj] = useState<boolean>(false);
    const [status, setStatus] = useState<"pesquisa" | "encontrado" | "erro">('pesquisa')
    const [pessoa, setPessoa] = useState<MovSimplesPessoaModel | string | undefined>(new MovSimplesPessoaModel());

    //PROVIDERS
    const { setClienteByDoc } = useMovAtual();
    const { handleKeyDown } = useKeyDownHandler()
    const { searchComandas } = usePedidoLocal();
    const { getConfigByCod } = useEmpresaAtual();
    const { getPedidoSalao } = useGetPedidoSalao();
    const { inserirProdutoByCodigo } = useMovProd();
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { addHandler, removeHandler } = useEventTools()

    const podeSerAberto = useCallback((aberto: boolean) => {
        permiteUsar.current = aberto
    }, [])

    const getPedidoSalaoWrapper = useCallback(async (comandaId: string) => {

        const query = `comandaId=${comandaId}`

        const res = await getPedidoSalao(getEmpresaSelecionada()?.Id!, query);

        if (res.erro) throw res.erro

        return res.resultado?.data.list as PedidoModel[]
    }, [getEmpresaSelecionada, getPedidoSalao])

    useEffect(() => {
        addHandler(AppEventEnum.PermiteEntradaDigitada, podeSerAberto)
        return () => {
            removeHandler(AppEventEnum.PermiteEntradaDigitada, podeSerAberto)
        }
    }, [addHandler, podeSerAberto, removeHandler])

    const alteraValorDiv = useCallback((value: string) => {
        if (valorDiv.current) {
            valorDiv.current.textContent = value
        }
    }, [])

    useEffect(() => {
        if (aberto && status === 'pesquisa')
            setTimeout(() => {
                alteraValorDiv('')
                valorRef.current = '';
                setAberto(false);
            }, 20000)
    }, [aberto, alteraValorDiv, status])


    const handleAddByCodigo = useCallback(async () => {
        let valorDigitado = valorRef.current.toUpperCase()

        try {
            setIsCpfCnpj(false)
            if (valorDigitado.length === 0) return;

            const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
            const prefixo = buscaPrefixo?.toUpperCase();

            if (!isEmpty(prefixo) && valorDigitado.startsWith(prefixo!)) {

                let codComanda: any = valorDigitado;

                if(codComanda?.startsWith(prefixo)) {
                  codComanda = valorDigitado.slice(prefixo?.length);
                }

                if (isOnlyHasNumber(codComanda)) {
                    codComanda = Number(codComanda);
                }

                const comanda = await searchComandas(codComanda);
                if (isEmpty(comanda)) {
                    setStatus('erro')
                    setErro('Comanda não localizada')
                    return;
                } else {
                    const resposta = await getPedidoSalaoWrapper(comanda[0].id);
                    const pedidos = resposta.filter(pedido => pedido.statusPedido.codigo !== EnumStatusPedido.FECHADO)

                    if (isEmpty(pedidos)) {
                        setStatus('erro')
                        setErro('Nenhum Pedido encontrado na comanda ' + prefixo + codComanda + '.')
                        return;
                    }
                    history.push({
                        pathname: `/venda-simples/visualizar-pedido/${comanda[0].id}`,
                        search: `?modo=comanda`
                    })
                    return
                }

            }

            if (validarCPFCNPJ(valorRef.current)) {
                setIsCpfCnpj(true)
                const res = await setClienteByDoc(valorRef.current);
                setPessoa(res);
                setStatus('encontrado')
                valorRef.current = ''

                return
            }

            const res: unknown = await inserirProdutoByCodigo(valorRef.current)

            const ret = res as ProdutoResumidoModel

            setNomeProd(ret.nome)
            setStatus('encontrado')
            valorRef.current = ''

        } catch (err: any) {
            setStatus('erro')
            setErro(err.message)
            valorRef.current = ''

        } finally {
            setTimeout(() => {
                valorRef.current = ''
                alteraValorDiv('')
                setAberto(false)
            }, 5000)
        }
    }, [alteraValorDiv, getConfigByCod, getPedidoSalaoWrapper, history, inserirProdutoByCodigo, searchComandas, setClienteByDoc]);

    useEventListener('keydown', (event: any) => {
        if (!event.defaultPrevented && !focusableElement(document.activeElement) && permiteUsar.current) {
            const ev = event as KeyboardEvent;
            if (!ev.key) return

            if (ev.key === 'Enter' && aberto) {
                handleAddByCodigo()
                return;
            }

            if (ev.key === "Escape") {
                setAberto(false)
                valorRef.current = ''
                alteraValorDiv('')
                return;
            }

            const newValue = handleKeyDown(ev, valorRef.current || '', true, true, true, 0, 1000);

            if (newValue !== valorRef.current) {

                if (aberto && status === 'encontrado') {
                    setStatus('pesquisa');
                }

                if (!aberto) {
                    setAberto(p => !p)
                    setStatus('pesquisa')
                }

                let vKey = valorRef.current;
                valorRef.current = vKey + valorRef.current;
                valorRef.current = newValue;
                alteraValorDiv(newValue)

                stopPropagationWithFeedback(event)
                ev.preventDefault();
            }

            if (valorRef.current.length === 0) {
                setTimeout(() => setAberto(false), 5000)
            }
        }
    });

    const descricaoStatus = useMemo(() => {
        return (
            <>
                {isCpfCnpj ? (
                    <>
                        <Typography
                            variant='body2'
                            color='secondary'
                            style={{
                                fontWeight: 600
                            }}
                        >
                            Cliente identificado na venda!
                        </Typography>
                        <Typography
                            variant='body2'
                            color='secondary'
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {typeof pessoa === 'object'
                                ? `${pessoa.nome} - ${formatarCPFCNPJ(pessoa.cpfcnpj ?? '')}`
                                : `Não Cadastrado - ${formatarCPFCNPJ(pessoa ?? '')}`
                            }
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            variant='body2'
                            color='secondary'
                            style={{
                                fontWeight: 600
                            }}
                        >
                            Produto "{nomeProd}" adicionado no carrinho
                        </Typography>
                    </>
                )}
            </>
        )
    }, [isCpfCnpj, nomeProd, pessoa])

    const camposStatus = useMemo(() => {
        switch (status) {
            case 'pesquisa':
                return (
                    <Fade in={true}>
                        <Box display={"flex"} width={"100%"}>
                            <div style={{
                                marginRight: 16
                            }}>
                                <CodigoBarrasIcon style={{ width: 45, height: 45 }} tipo="INPUT" />
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                <Typography variant='body2' style={{ fontWeight: 600 }} color="secondary">Código Digitado:</Typography>
                                <div style={{ display: 'flex', borderBottom: '2px solid #1F9CE4' }}>
                                    {aberto && (
                                        <div ref={valorDiv} className={classes.typeValor}>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Fade>
                )

            case "encontrado":
                return (
                    <Fade in={true}>
                        <Box display={"flex"} width={"100%"}>
                            <div style={{
                                marginRight: 16
                            }}>
                                <OkIcon style={{ width: 45, height: 45 }} tipo="INPUT" fill="#4CD964" />
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                {descricaoStatus}
                            </div>
                        </Box>
                    </Fade>
                )

            case "erro":
                return (
                    <Fade in={true}>
                        <Box display={"flex"} width={"100%"}>
                            <div style={{
                                marginRight: 16
                            }}>
                                <CancelarIcon style={{ width: 45, height: 45 }} tipo="INPUT" fill="#F44336" />
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                <Typography variant='body2' style={{ fontWeight: 600 }} color="secondary">{erro}</Typography>
                            </div>
                        </Box>
                    </Fade>
                )
        }
    }, [aberto, classes.typeValor, descricaoStatus, erro, status])

    return (
        <Slide in={aberto} direction="up">
            <div className={classes.body}>
                <div className={classes.main}>
                    <div className={classes.card}>
                        {camposStatus}
                        <div className={classes.close}>
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => {
                                valorRef.current = ''
                                alteraValorDiv('')
                                setAberto(false)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    )
} 