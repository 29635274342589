import { Box, Button, Grid } from "@material-ui/core";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DefaultFormProps, DefaultFormRefs } from "../utils";
import { SessaoValorPostModel } from "model/api/gestao/sessao/sessao-valor-post-model";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { AdicionarTpCorrecaoGerenciamentoSessaoMock, EditarTpCorrecaoGerenciamentoSessaoMock } from "data/mocks/tp-correcao-gerenciamento-sessao-mock";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { isEqual } from "lodash";
import { useToastSaurus } from "services/app";
import { useFormGerenciamentoSessaoValorValidation } from "./form-gerenciamento-sessao-valor-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { toDecimal } from "utils/to-decimal";
import { AutocompleteFinalizadora } from "views/components/controles/autocompletes/autocomplete-finalizadoras/autocomplete-finalizadoras";
import { TabelaFinalizadoras } from "database/interfaces/interface-tabela-finalizadoras";
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor";

export interface FormGerenciamentoSessaoValorProps extends DefaultFormProps<SessaoValorPostModel> {
    editar: boolean;
    finalizadoras: TabelaFinalizadoras[];
}

export const FormGerenciamentoSessaoValor = forwardRef<
    DefaultFormRefs<SessaoValorPostModel>,
    FormGerenciamentoSessaoValorProps
>((props: FormGerenciamentoSessaoValorProps, ref) => {

    //AUX
    const { showToast } = useToastSaurus();

    //SATETS
    const [modelForm, setModelForm] = useState<SessaoValorPostModel>(props.model ?? new SessaoValorPostModel());

    //VALIDATION
    const { formGerenciamentoSessaoValorValidation } = useFormGerenciamentoSessaoValorValidation();

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
    } = useForm<SessaoValorPostModel>({
        defaultValues: { ...modelForm },
        resolver: yupResolver(formGerenciamentoSessaoValorValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = useCallback((beforeModel: SessaoValorPostModel) => {
        beforeModel.valor = toDecimal(beforeModel.valor);

        const modelIgual = isEqual(modelForm, beforeModel);

        if (modelIgual) {
            showToast('info', 'Nenhuma informação alterada.')
        }

        props.onSubmit(beforeModel);
    }, [modelForm, props, showToast])

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {

        },
        fillForm: (model: SessaoValorPostModel) => {
            setModelForm(model);
        },
    }));

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box my={2}>
                    <Grid container spacing={2}>
                        {modelForm.tpLancamento === EnumTpLancamentoSessaoValor.ABERTURA ? null :
                            <Grid item xs={12} md={12}>
                                <Controller
                                    name="tpLancamento"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            label='Tipo de Correção'
                                            conteudo={
                                                props.editar
                                                    ? EditarTpCorrecaoGerenciamentoSessaoMock
                                                    : AdicionarTpCorrecaoGerenciamentoSessaoMock
                                            }
                                            error={Boolean(
                                                errors.tpLancamento && errors.tpLancamento.message,
                                            )}
                                            helperText={
                                                touchedFields.tpLancamento || errors.tpLancamento
                                                    ? errors.tpLancamento?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onChange={(event) => {
                                                setValue('tpLancamento', Number(event.target.value))
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={8}>
                            <Controller
                                name="pagamentoId"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteFinalizadora
                                        modo="descricao"
                                        label='Forma de Pagamento'
                                        placeholder="EX: Dinheiro"
                                        error={Boolean(
                                            errors.pagamentoId && errors.pagamentoId.message,
                                        )}
                                        helperText={
                                            touchedFields.pagamentoId || errors.pagamentoId
                                                ? errors.pagamentoId?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={props.finalizadoras?.filter(x => x.id === getValues('pagamentoId'))[0]?.descricao ?? ''}
                                        onChange={(event) => {
                                            setValue("pagamentoId", event.value.id);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="valor"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Valor'
                                        manterMascara
                                        tipo="DECIMAL"
                                        error={Boolean(
                                            errors.valor && errors.valor.message,
                                        )}
                                        helperText={
                                            touchedFields.valor || errors.valor
                                                ? errors.valor?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="observacao"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        label='Observação'
                                        tipo="TEXTO"
                                        placeholder="Observarção"
                                        error={Boolean(
                                            errors.observacao && errors.observacao.message,
                                        )}
                                        helperText={
                                            touchedFields.observacao || errors.observacao
                                                ? errors.observacao?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
        </>
    )
})