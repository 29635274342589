import { EnumSituacaoContrato } from "model/enums/enum-situacao-contrato";
import { guidEmpty } from "utils/guid-empty";
export class PlanoContratadoModel {
    constructor(
        public id: string = guidEmpty(),
        public planoId: string = guidEmpty(),
        public melhorDia: number = 0,
        public tpFatura: number = 0,
        public dataUltimaFatura: string = "",
        public dExpiracao: string = "",
        public representanteId: string = guidEmpty(),
        public dInserido: string = "",
        public status: EnumSituacaoContrato = EnumSituacaoContrato.OK,
        public avisoCobrancaRepresentante: string = '',
        public avisoCobranca: string = '',
        public linkCobranca: string = '',
        public faturas30Dias: number = 0
    ) { }
}
