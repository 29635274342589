import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  btnPesquisa: {
    marginLeft: theme.spacing(1),
    width: "40px",
    minWidth: "40px",
    height: "40px",
    padding: 0,
    "& svg": {
      margin: 0,
    }
  }

}));