import { Paper, Typography, useTheme } from '@material-ui/core';
import { useStyles } from '../card-dashboard-chart-styles';
import { BandeiraIcon } from 'views/components/icons';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { TpModMock } from 'data/mocks';
import { CardDashboardChartDonutProps } from '../card-dashboard-chart-props';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { CircularLoading } from 'views/components/utils';

export const CardDashboardChartDonut = ({
  model,
  carregando
}: CardDashboardChartDonutProps) => {
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(
    model.map((obj) => [obj.modPagamento, obj]),
  );

  const modsUnicosList = Array.from(modUnicos.values());

  const pagamentosSerelizados = modsUnicosList.map((pagamento) => {
    return {
      ...pagamento,
      vVenda: 0,
      qtde: 0
    };
  });

  pagamentosSerelizados.map((pagamento) => {
    model.map((pag) => {
      if (pag.modPagamento === pagamento.modPagamento) {
        let novaQuantidade = (pagamento.vVenda += pag.vVenda);
        let novaQTDE = pagamento.qtde += 1
        return {
          ...pagamento,
          vPag: novaQuantidade,
          qtde: novaQTDE
        };
      }
      return pag;
    });
    return pagamento;
  });

  const arraySeries = useCallback(() => {
    const modUnique = Array.from(
      new Set<number>(model?.map((item) => item?.modPagamento)),
    );

    const retornaSeries = modUnique.map((item) => {
      return model
        ?.filter((x) => x?.modPagamento === item && x)
        .reduce((a, b) => a + b.vVenda, 0);
    });
    return retornaSeries;
  }, [model]);

  const labelsText = model?.map((tp) => {
    return TpModMock.filter((x) => x?.Key === tp?.modPagamento)[0]?.Value;
  });

  const unique = Array.from(
    new Set<string>(labelsText?.map((item) => String(item))),
  );

  const options = useCallback(() => {
    const options = {
      labels: unique,
      tooltip: {
        style: {
          fontSize: '24px',
        },
        y: {
          formatter: function (val: any) {
            return `R$ ${toDecimalString(val)}`;
          },
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                formatter: function (val: any) {
                  return `R$ ${toDecimalString(val)}`;
                },
              }
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            legend: {
              position: 'top',
            },
          },
        },
      ],
      legend: {
        show: true,
      },
    };
    return options;
  }, [unique]);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 13px',
            }}
          >
            <div style={{ padding: '10px 0' }}>
              <Typography
                color="primary"
                style={{ fontSize: '1.1rem', fontWeight: 500 }}
              >
                Modelos de pagamentos
              </Typography>
            </div>
            <div>
              <Typography className={'celula-grid-value'} color="textPrimary">
                <BandeiraIcon tipo="BUTTON" />
              </Typography>
            </div>
          </div>
          <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>
          {carregando ?
            <div style={{ position: 'relative' }}>
              <CircularLoading tipo='FULLSIZED' />
            </div>
            :
            <>
              {
                model.map(item => item.modPagamento)[0] === 0 || model.length === 0 ? (
                  <div className={utilClasses.alinharChart}>
                    <div style={{ display: 'block', marginTop: 20 }}>
                      <Typography variant="body1" style={{ textAlign: 'center' }}>
                        Não há dados para exibir
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <>
                    {VariaveisAmbiente.paymentDevice === EnumDeviceType.MERCADOPAGO ||
                      VariaveisAmbiente.paymentDevice ===
                      EnumDeviceType.MERCADOPAGO_DEV ? (
                      <>
                        <div className={utilClasses.alinharChart}>
                          <div style={{ display: 'block', marginTop: 20 }}>
                            {pagamentosSerelizados.sort((a, b) => b.qtde - a.qtde).filter(item => item.modPagamento !== 0).map((item, index) => {
                              const pegaVendaTotal = model.reduce(
                                (a, b) => a + b.vVenda,
                                0,
                              );
                              const porcentagem = (
                                (item.vVenda * 100) /
                                pegaVendaTotal
                              ).toFixed(0);

                              return (
                                <Paper
                                  key={index}
                                  style={{
                                    marginLeft: theme.spacing(1),
                                    marginRight: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                    position: 'relative',
                                    overflow: 'hidden'
                                  }}
                                >
                                  <div style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                  >
                                    <Typography>
                                      <Typography
                                        style={{
                                          fontWeight: 600,
                                          fontSize: '1.2rem',
                                          filter: 'contrast(5)',
                                        }}
                                      >
                                        {item !== null
                                          ? `${index + 1}° ${TpModMock.filter(
                                            (x) => x?.Key === item.modPagamento,
                                          )[0]?.Value
                                          }`
                                          : 'Sem vendas para mostrar'}
                                      </Typography>
                                    </Typography>
                                    <div
                                      style={{
                                        textAlign: 'right',
                                        padding: '10px 0',
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 600,
                                          fontSize: '1.2rem',
                                          filter: 'invert(50%)'
                                        }}
                                      >
                                        R$ {toDecimalString(
                                          item.vVenda,
                                          2,
                                        )}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '1rem',
                                          filter: 'invert(50%)'
                                        }}
                                      >
                                        {parseInt(porcentagem.toString())}%
                                      </Typography>
                                    </div>
                                  </div>
                                  <div style={{
                                    width: `${porcentagem}%`,
                                    backgroundColor: `#${((Math.random() * 0xffffff) << 0)
                                      .toString(16)
                                      .padStart(6, '0')}`,
                                    height: 5,
                                    zIndex: '-1000'
                                  }} />
                                </Paper>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={utilClasses.alinharChartDonut}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 20,
                          }}
                        >
                          <div>
                            <Chart
                              options={options()}
                              series={arraySeries()}
                              type="donut"
                              className={utilClasses.chartDonut}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }
            </>
          }
        </div>
      </Paper>
    </>
  );
};