import { useState, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './upload-carga-list-styles';
import { UploadCargaListData } from './upload-carga-list-data';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useToastSaurus } from 'services/app';
import { useHistory } from 'react-router';
import { UploadCargaModel } from 'model/api/gestao/upload-carga/upload-carga-model';
import { useGetCargas } from 'data/api/gestao/carga/get-cargas';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';

export const UploadCargaList = () => {
  const classes = useStyles();
  const { getCargas, carregando } = useGetCargas();
  const { showToast } = useToastSaurus();
  const { push: historyPush, location } = useHistory();
  const { addHandler, removeHandler } = useEventTools()

  const [modalEditAberto, setModalEditAberto] = useState(false);

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<UploadCargaModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<UploadCargaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  const search = useCallback(
    async (newPage: number) => {
      const query = '&pageSize=10';

      try {
        const res = await getCargas(query, newPage);

        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [getCargas, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  useEffect(() => {
    if (!modalEditAberto) {
      let page = Number(new URLSearchParams(location.search).get('page'));
      search(page || queryStatus.page);
      location.search = ''
    }
    addHandler(AppEventEnum.UploadCargaModal, modalEdit)
    return () => removeHandler(AppEventEnum.UploadCargaModal, modalEdit)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHandler,modalEdit, modalEditAberto, removeHandler, search]);

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  const onCardSelected = (id: string) => {
    historyPush({
      pathname: `/upload-carga/detalhes/${id}`,
      state: {
        from: {
          page: queryStatus.page
        }
      }
    });
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <UploadCargaListData
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
