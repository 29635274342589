import { useCallback } from "react";

import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { FinalizadoraOrdemModel } from "model/api/gestao/finalizadora/finalizadora-ordem-model";

export function usePutFinalizadoraOrdem() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
 
  const putFinalizadoraOrdem = useCallback(
    (list: FinalizadoraOrdemModel[]) => {
      return invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Finalizadora`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: list,
        enviarTokenUsuario: true,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putFinalizadoraOrdem,
  };
}