import { Grid } from "@material-ui/core"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeICMSProps } from "./form-pe-icms-props"
import { Controller } from "react-hook-form"
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus"
import { OrigemMercadoriaMock } from "data/mocks/origem-mercadoria-mock"
import { CstIcmsMock } from "data/mocks/cst-icms-mock"
import { ModBcIcmsMock } from "data/mocks/modbc-icms-mock"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { ModBcIcmsStMock } from "data/mocks/modbc-icmsst-mock"
import { UFMock } from "data/mocks"
import { useState } from "react"
import { MotDesIcmsMock } from "data/mocks/motdes-icms-mock"
import { KeyValueModel } from "model"
import { IndIcmsMock } from "data/mocks/ind-icms-mock"
import { ProdutoEntradaFormModel } from "model/app/forms/entrada/produto-entrada-form-model"

export const FormPeICMS = ({ control, formState, setValue, getValues, ...props }: FormPeICMSProps) => {

    const { errors } = formState
    const [, setAtt] = useState<boolean>(false)
    const produtoPadrao = new ProdutoEntradaFormModel()

    const camposHabilitados = (value?: number) => ({
        0: ["icms.modBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.tipoIcms"],
        10: ["icms.modBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.tipoIcms"],
        20: ["icms.modBc", "icms.pRedBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.vIcmsDeson", "icms.motDesIcms", "icms.tipoIcms"],
        30: ["icms.vIcmsDeson", "icms.motDesIcms", "icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt"],
        40: ["icms.vIcmsDeson", "icms.motDesIcms"],
        41: ["icms.vIcmsDeson", "icms.motDesIcms"],
        50: ["icms.vIcmsDeson", "icms.motDesIcms"],
        51: ["icms.modBc", "icms.pRedBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.vIcmsOp", "icms.pDif"],
        60: ["icms.vBcStRet", "icms.vIcmsStRet", "icms.pRedBcEfet", "icms.vbcEfet", "pIcmsEfet", "icms.vIcmsEfet", "icms.vIcmsSubstituto"],
        70: ["icms.modBc", "icms.pRedBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.vIcmsDeson", "icms.motDesIcms", "icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.tipoIcms"],
        90: ["icms.modBc", "icms.pRedBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.vIcmsDeson", "icms.motDesIcms", "icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.tipoIcms"],
        101: ["icms.pCredSn", "icms.vCredIcmsSn"],
        201: ["icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt"],
        202: ["icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.pIcmsInter"],
        203: ["icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.pIcmsInter"],
        300: [],
        400: [],
        500: ["icms.vBcStRet", "icms.vIcmsStRet", "icms.pRedBcEfet", "icms.vbcEfet", "icms.pIcmsEfet", "icms.vIcmsEfet", "icms.vIcmsSubstituto"],
        900: ["icms.modBc", "icms.pRedBc", "icms.vBc", "icms.pIcms", "icms.vIcms", "icms.modBcSt", "icms.pMvaSt", "icms.pRedBcSt", "icms.vBcSt", "icms.pIcmsSt", "icms.vIcmsSt", "icms.pIcmsInter", "icms.tipoIcms"],
    })[value || getValues('icms.cst')] || []

    const manterHabilitado = (name: string) => camposHabilitados().filter(x => x === name).length === 0

    const ufMockSiglas = UFMock.map(x => new KeyValueModel(x.Value, x.Value))

    const resetaCampos = (value: number) => {
        const camposString = camposHabilitados(value).map(y => y.split('.')[1])
        const objIcms = Object.entries(produtoPadrao.icms).filter(x => !camposString.includes(x[0]))
        const naoRemover = ['cst', 'vBcUfDest', 'vBcFcpUfDest', 'pIcmsUfDest', 'pIcmsInter', 'pIcmsInterPart', 'vFcpUfDest', 'vIcmsUfRemet', 'vIcmsUfDest', 'vBcFcp', 'pFcp', 'vFcp', 'vBcFcpSt', 'pFcpSt', 'vFcpSt', 'pSt', 'vBcFcpStRet', 'pFcpStRet', 'vFcpStRet']
        objIcms.forEach(x => {
            if (!naoRemover.includes(x[0])) {
                if (x?.[1] !== undefined) {
                    setValue((`icms.${x[0]}`) as any, x[1])
                }
            }
        })
    }

    return (
        <AccordionSaurus
            labelPrimary="ICMS"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="icms.orig"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading}
                                conteudo={OrigemMercadoriaMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Origem"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.orig && errors.icms.orig.message)}
                                helperText={
                                    errors.icms?.orig
                                        ? errors.icms?.orig?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = OrigemMercadoriaMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.orig", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.cst"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading}
                                conteudo={CstIcmsMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="CST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.cst && errors.icms.cst.message)}
                                helperText={
                                    errors.icms?.cst
                                        ? errors.icms?.cst?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = CstIcmsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.cst", value)
                                    setAtt(prev => !prev)
                                    resetaCampos(value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.modBc"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading || manterHabilitado(field.name)}
                                conteudo={ModBcIcmsMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Modalidade de Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.modBc && errors.icms.modBc.message)}
                                helperText={
                                    errors.icms?.modBc
                                        ? errors.icms?.modBc?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = ModBcIcmsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.modBc", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pRedBc"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% Redução B.C."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pRedBc && errors.icms.pRedBc.message)}
                                helperText={
                                    errors.icms?.pRedBc
                                        ? errors.icms?.pRedBc?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBc"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBc && errors.icms.vBc.message)}
                                helperText={
                                    errors.icms?.vBc
                                        ? errors.icms?.vBc?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcms"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota ICMS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcms && errors.icms.pIcms.message)}
                                helperText={
                                    errors.icms?.pIcms
                                        ? errors.icms?.pIcms?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcms"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcms && errors.icms.vIcms.message)}
                                helperText={
                                    errors.icms?.vIcms
                                        ? errors.icms?.vIcms?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.tipoIcms"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading || manterHabilitado(field.name)}
                                conteudo={IndIcmsMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Tipo ICMS"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.tipoIcms && errors.icms.tipoIcms.message)}
                                helperText={
                                    errors.icms?.tipoIcms
                                        ? errors.icms?.tipoIcms?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = IndIcmsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.tipoIcms", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsDeson"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="ICMS Deson."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsDeson && errors.icms.vIcmsDeson.message)}
                                helperText={
                                    errors.icms?.vIcmsDeson
                                        ? errors.icms?.vIcmsDeson?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.motDesIcms"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={MotDesIcmsMock}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Motivo da Deson."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.motDesIcms && errors.icms.motDesIcms.message)}
                                helperText={
                                    errors.icms?.motDesIcms
                                        ? errors.icms?.motDesIcms?.message
                                        : undefined
                                }
                                {...field}
                                value={[0, null].includes(field.value) ? 0 : field.value}
                                onChange={(event) => {
                                    const value = MotDesIcmsMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.motDesIcms", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsOp"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="ICMS OP"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsOp && errors.icms.vIcmsOp.message)}
                                helperText={
                                    errors.icms?.vIcmsOp
                                        ? errors.icms?.vIcmsOp?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pDif"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% Dif."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pDif && errors.icms.pDif.message)}
                                helperText={
                                    errors.icms?.pDif
                                        ? errors.icms?.pDif?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsDif"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor Dif."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsDif && errors.icms.vIcmsDif.message)}
                                helperText={
                                    errors.icms?.vIcmsDif
                                        ? errors.icms?.vIcmsDif?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.modBcSt"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                disabled={props.loading || manterHabilitado(field.name)}
                                conteudo={ModBcIcmsStMock}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Modalidade de Base de Cálculo ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.modBcSt && errors.icms.modBcSt.message)}
                                helperText={
                                    errors.icms?.modBcSt
                                        ? errors.icms?.modBcSt?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = ModBcIcmsStMock.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.modBcSt", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pMvaSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="MVA ou Valor Tabelado"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pMvaSt && errors.icms.pMvaSt.message)}
                                helperText={
                                    errors.icms?.pMvaSt
                                        ? errors.icms?.pMvaSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pRedBcSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% Redução da B.C. ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pRedBcSt && errors.icms.pRedBcSt.message)}
                                helperText={
                                    errors.icms?.pRedBcSt
                                        ? errors.icms?.pRedBcSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBcSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcSt && errors.icms.vBcSt.message)}
                                helperText={
                                    errors.icms?.vBcSt
                                        ? errors.icms?.vBcSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota ICMS ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsSt && errors.icms.pIcmsSt.message)}
                                helperText={
                                    errors.icms?.pIcmsSt
                                        ? errors.icms?.pIcmsSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsSt && errors.icms.vIcmsSt.message)}
                                helperText={
                                    errors.icms?.vIcmsSt
                                        ? errors.icms?.vIcmsSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBcStRet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="B.C. ST Retido"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcStRet && errors.icms.vBcStRet.message)}
                                helperText={
                                    errors.icms?.vBcStRet
                                        ? errors.icms?.vBcStRet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsStRet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor de ST Retido"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsStRet && errors.icms.vIcmsStRet.message)}
                                helperText={
                                    errors.icms?.vIcmsStRet
                                        ? errors.icms?.vIcmsStRet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pBcOp"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% B.C. da Operação"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pBcOp && errors.icms.pBcOp.message)}
                                helperText={
                                    errors.icms?.pBcOp
                                        ? errors.icms?.pBcOp?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.ufSt"
                        control={control}
                        render={({ field }) => (
                            <SelectSaurus
                                conteudo={ufMockSiglas}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="UF da ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.ufSt && errors.icms.ufSt.message)}
                                helperText={
                                    errors.icms?.ufSt
                                        ? errors.icms?.ufSt?.message
                                        : undefined
                                }
                                {...field}
                                onChange={(event) => {
                                    const value = ufMockSiglas.filter(
                                        (x) => x.Key === event.target.value,
                                    )[0]?.Key;
                                    setValue("icms.ufSt", value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsInter"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota Interna"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsInter && errors.icms.pIcmsInter.message)}
                                helperText={
                                    errors.icms?.pIcmsInter
                                        ? errors.icms?.pIcmsInter?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vBcStDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálc. ST - Destino"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcStDest && errors.icms.vBcStDest.message)}
                                helperText={
                                    errors.icms?.vBcStDest
                                        ? errors.icms?.vBcStDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsStDest"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS ST - Destino"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsStDest && errors.icms.vIcmsStDest.message)}
                                helperText={
                                    errors.icms?.vIcmsStDest
                                        ? errors.icms?.vIcmsStDest?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pCredSn"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                casasDecimais={4}
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota Crédito SN"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pCredSn && errors.icms.pCredSn.message)}
                                helperText={
                                    errors.icms?.pCredSn
                                        ? errors.icms?.pCredSn?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vCredIcmsSn"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor de Crédito SN"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vCredIcmsSn && errors.icms.vCredIcmsSn.message)}
                                helperText={
                                    errors.icms?.vCredIcmsSn
                                        ? errors.icms?.vCredIcmsSn?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.pRedBcEfet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="% Redução da B.C. ICMS Efet."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pRedBcEfet && errors.icms.pRedBcEfet.message)}
                                helperText={
                                    errors.icms?.pRedBcEfet
                                        ? errors.icms?.pRedBcEfet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vbcEfet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="B.C. ICMS Efet."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vbcEfet && errors.icms.vbcEfet.message)}
                                helperText={
                                    errors.icms?.vbcEfet
                                        ? errors.icms?.vbcEfet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pIcmsEfet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                casasDecimais={4}
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota ICMS Efet."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pIcmsEfet && errors.icms.pIcmsEfet.message)}
                                helperText={
                                    errors.icms?.pIcmsEfet
                                        ? errors.icms?.pIcmsEfet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsEfet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS Efet."
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsEfet && errors.icms.vIcmsEfet.message)}
                                helperText={
                                    errors.icms?.vIcmsEfet
                                        ? errors.icms?.vIcmsEfet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vIcmsSubstituto"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading || manterHabilitado(field.name)}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor ICMS Substituto"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vIcmsSubstituto && errors.icms.vIcmsSubstituto.message)}
                                helperText={
                                    errors.icms?.vIcmsSubstituto
                                        ? errors.icms?.vIcmsSubstituto?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}