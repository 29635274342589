import { useEffect, useState, useCallback, useRef } from 'react';

import { useGetPontosVenda } from 'data/api/gestao/pontos-venda';
import { Grid } from '@material-ui/core';
import { useStyles } from './pontos-venda-list-styles';
import { PontosVendaListData } from './pontos-venda-list-data';
import { PontosVendaModel } from 'model/api/gestao/pontos-venda/pontos-venda-model';
import { Paginacao } from 'views/components/paginacao';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { useSessaoAtual } from 'services/app';
import { useDevice } from 'services/app/hooks/device';

export const PontosVendaList = () => {
  const chavePdvRef = useRef<string|undefined>(undefined);
  const classes = useStyles();
  const { getSerialNumber } = useDevice();
  const { abrirCadastroPontosVenda } = useCadastros();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getPontosVenda, carregando } = useGetPontosVenda();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<PontosVendaModel>(),
  });
  const [modalEditAberto, setModalEditAberto] = useState(false);
  const { addHandler, removeHandler } = useEventTools()
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const urlParams = new URLSearchParams(history.location.search)
  const filtros = {
    status: isEmpty(urlParams.get('status')) ? 0 : Number(urlParams.get('status')),
    generico: urlParams.get('generico') || ''
  }

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PontosVendaModel>,
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    [],
  );

  // query -> minhaChavePDV nno get de caixas é pra priorizar o pdv vinculado
  const search = useCallback(
    async (newPage: number, chavePdv?: string) => {
      const query =
        '' +
        (!isEmpty(filtros.generico)
          ? '&generico=' + filtros.generico
          : '') +
        (filtros.status > -1
          ? '&status=' + filtros.status
          : '') + !!chavePdv ? 'minhaChavePDV=' + chavePdv : '';
      try {
        consoleDev('search-ponto-venda-list', query);

        const res = await getPontosVenda(
          getEmpresaSelecionada()!.Id || '',
          query,
          newPage,
        );
        if (res.erro) throw res.erro;

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          search(res.resultado?.data?.totalPages, chavePdv);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list,
        );
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [filtros.generico, filtros.status, getPontosVenda, getEmpresaSelecionada, fillResult, showToast],
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned)
  }, [])

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        await search(newPage);
      }
    },
    [search, queryStatus.totalPages],
  );

  useEffect(() => {
    (async() => {
      chavePdvRef.current = await getSerialNumber();

      if (!modalEditAberto) {
        search(queryStatus.page, chavePdvRef.current);
      }
  
      addHandler(AppEventEnum.PontosVendaModal, modalEdit)
    })();
    
    return () => removeHandler(AppEventEnum.PontosVendaModal, modalEdit)
  }, [addHandler, getSerialNumber, modalEdit, modalEditAberto, queryStatus.page, removeHandler, search]);

  const onCardSelected = (id: string) => {
    abrirCadastroPontosVenda(id, history.location.pathname, true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid container>
          <Paginacao
            pageChanged={pageChanged}
            totalPages={queryStatus.totalPages}
            totalRegisters={queryStatus.totalResults}
            currentPage={queryStatus.page}
          />
          <Grid item xs={12} className={classes.listContainer}>
            <PontosVendaListData
              chavePdvRef={chavePdvRef}
              carregando={carregando}
              list={queryStatus.list}
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
