import { useCallback } from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { useToastSaurus } from './toast-saurus';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { PedidoDadosEnderecoModel, PedidoDadosModelPost, PedidoDadosPagamentoModel } from 'model/api/gestao/pedido/pedido-dados-model';
import { PedidoClienteModel } from 'model/api/gestao/pedido/pedido-cliente-model';
import { PedidoDadosIntegracaoModel } from 'model/api/gestao/pedido/pedido-integracao-model';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { PedidoProdutoModelPost } from 'model/api/gestao/pedido/pedido-produto-model';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useGetPedidoCupom } from 'data/api/gestao/pedido/get-pedido-cupom';
import { usePDV } from './pdv';
import { ImpressaoSaurus } from './impressao-saurus';
import { useDevice } from './device';
import { roundTo } from 'utils/round-to';
import { useSessaoAtual } from '../providers';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useImpressaoLocal } from './impressao-local';
import { calcPercentFloor } from 'utils/calcPercentFloor';
import { isEmpty } from 'lodash';
import { useGetPedidoSalaoById } from 'data/api/gestao/pedido/get-pedido-salao-by-id';
import { useMovAtual } from './mov-atual';
import { MovSimplesPessoaEnderecoModel, MovSimplesPessoaModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pessoa-model';
import { useHistory } from 'react-router-dom';
import { EnumPedidoPagamentoSituacao } from 'model/enums/enum-pedido-pagamento-situacao';
import { EnumTipoPedido } from 'model/enums/enum-tipo-pedido';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { completarEndereco } from 'utils/completar-endereco';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { newGuid } from 'utils/new-guid';
import { EnumPagTpMod } from 'model';
import { toDecimalString } from 'utils/to-decimal';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

export function usePedidoDelivery() {
  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getConfigByCod } = useContratoAtual()
  const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
  const { getPedidoSalaoById, carregando: carregandoGetPedidos } =
    useGetPedidoSalaoById();
  const { showToast } = useToastSaurus();
  const { printHtml, printNative, printType } = useDevice();
  const { getConfigByCod: getConfigPDV, getPDV, getImpressoraPdv } = usePDV();
  const { carregandoImpressaoLocal, enviarImpressaoLocal } = useImpressaoLocal();
  const { criarTaxaEntrega, getMov } = useMovAtual()
  const history = useHistory()

  // CHAMADAS API
  const { getPedidoCupom, carregando: carregandoGetCupom } =
    useGetPedidoCupom();

  // AUX
  const carregando =
    carregandoImpressaoLocal ||
    carregandoGetCupom ||
    carregandoGetPedidos;

  const pedidoClienteWrapper = (clienteMovSimples: MovSimplesPessoaModel) => {
    const cliente = new PedidoClienteModel();

    cliente.referenceId = clienteMovSimples?.id ?? '';
    cliente.nomeFantasia = clienteMovSimples?.nome ?? '';
    cliente.razaoSocial = clienteMovSimples?.nome ?? '';
    cliente.cpfCnpj = clienteMovSimples?.cpfcnpj ?? '';
    cliente.ieRg = clienteMovSimples?.ierg ?? '';
    cliente.telefone = clienteMovSimples.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || ''
    cliente.email = clienteMovSimples.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)?.valor || ''

    return cliente;
  };

  const pedidoEnderecoWrapper = (enderecoEntrega: MovSimplesPessoaEnderecoModel) => {
    const endereco = new PedidoDadosEnderecoModel();

    endereco.bairro = enderecoEntrega.bairro
    endereco.logradouro = enderecoEntrega.logradouro
    endereco.cep = enderecoEntrega.cep
    endereco.codigoMunicipio = enderecoEntrega.cMun
    endereco.complemento = enderecoEntrega.complemento
    endereco.municipio = enderecoEntrega.xMun
    endereco.numero = enderecoEntrega.numero
    endereco.pais = "Brasil"
    endereco.uf = enderecoEntrega.uf
    endereco.enderecoCompleto = completarEndereco({
      rua: enderecoEntrega.logradouro,
      bairro: enderecoEntrega.bairro,
      municipio: enderecoEntrega.xMun,
      uf: enderecoEntrega.uf,
      complemento: enderecoEntrega.complemento,
      numero: enderecoEntrega.numero,
      cep: enderecoEntrega.cep
    })

    return endereco;
  };

  const pedidoIntegradorWrapper = () => {
    const integrador = new PedidoDadosIntegracaoModel();

    integrador.nomeIntegrador = 'TouchOne';
    integrador.cnpjIntegrador = '11914993000123';
    integrador.credencialCliente = getEmpresaSelecionada()?.Id ?? '';
    integrador.codigoAplicacao = 990009859;

    return integrador;
  };

  const pedidoDadosWrapper = async (mov: MovSimplesModel) => {
    const pedido = new PedidoDadosModelPost();
    pedido.tipoPedido = EnumTipoPedido.ENTREGA
    // atribuições do cliente
    if (mov.cliente) {
      pedido.cliente = pedidoClienteWrapper(mov.cliente);
      if (mov.cliente.endereco) {
        pedido.enderecoEntrega = pedidoEnderecoWrapper(mov.cliente.endereco)
      }
    }
    pedido.codigoReferencia = newGuid()
    const finalizadoras: FinalizadoraModel[] = await TouchoneDBPrimary.finalizadoras.toArray() as FinalizadoraModel[];

    pedido.pagamentos = mov.pags.map(pag => {
      return {
        pagamentoAReceber: true,
        pagamentoDescricao: pag.modPagamento === EnumPagTpMod.DINHEIRO
          ? `Dinheiro: ${pag.vTroco > 0 && `Levar R$ ${toDecimalString(pag.vTroco)} de troco`} `
          : pag.modPagamento === EnumPagTpMod.PAGAMENTO_INSTANTANEO ? 'Pagamento pelo PIX'
            : `Levar Maquininha: ${finalizadoras.find(x => x.id === pag.pagamentoId)?.descricao || ''}`,
        valorPago: pag.vPag,
        valorTroco: pag.vTroco,
        pagamentoPessoa: mov.cliente?.id || '',
        situacao: EnumPedidoPagamentoSituacao.APROVADO,
        tpMod: pag.modPagamento || '',
        referenceId: pag.pagamentoId || '',
        transacoes: []
      } as PedidoDadosPagamentoModel
    })

    // por padrão a quantidade de pessoas é 1
    pedido.quantidadePessoas = 1;
    pedido.informacaoAdicional = mov.infAdicional || ''

    // documento da loja
    pedido.documentoLoja = getEmpresaSelecionada()?.Documento ?? '';

    // dados integrador
    pedido.dadosIntegracao = pedidoIntegradorWrapper();

    return pedido;
  };

  const pedidoProdutosWrapper = (
    produtosMovSimples: MovSimplesProdutoModel[],
    salaoId: string | null = null
  ) => {
    const produtoSerializados = produtosMovSimples.map((prod) => {
      return {
        setorEmpresaId: null,
        plu: prod.cProd,
        descricao: prod.xProd,
        observacao: !isEmpty(prod.infAdic) ? prod.infAdic : '',
        pesoVariavel: prod.balanca,
        codigoBarra: prod.cEan,
        status: prod.ativo
          ? EnumStatusProdutoPedido.DISPONIVEL
          : EnumStatusProdutoPedido.DESISTENCIA,
        nItem: prod.nSeq,
        posicaoMesa: '',
        codigoReferencia: prod.id,
        quantidade: prod.qCom,
        valorTotal: Math.round((prod.vFinal + Number.EPSILON) * 100) / 100,
        valorTotalFrete: prod.vFrete,
        valorTotalDesconto: roundTo(prod.vDescUsuario),
        valorUnitario: roundTo(prod.vUnCom),
        valorTotalAdicional: 0,
        vendedor: prod.vendedorNome,
        vendedorId: prod.vendedorId,
        setorId: prod.setorId,
        salaoId: salaoId,
        indFin: prod.indFin,
        groupId: prod.idGroup,
        adicionalId: prod.idAdicional,
        subItens: pedidoProdutosWrapper(prod.prodSubItem, salaoId),
        produtoPai: prod.produtoPaiId,
        tpProduto: prod.tpProduto,
        taxaServico: 0,
        valorTotalServico: 0,
        produtoIdReferencia: prod.produtoId,
        quantidadeMax: prod.qCom,
        unidadeComercial: prod.uCom
      } as unknown;
    }) as PedidoProdutoModelPost[];

    return produtoSerializados;
  };

  const calcularTaxaEntrega = useCallback(async (valor: number, tipoEntrega: 'propria' | 'terceirizada') => {
    const produto = await criarTaxaEntrega(valor, tipoEntrega)

    if (!produto) {
      throw new Error('A venda não está aberta ou não foi configurado o Produto de Entrega.')
    }
  }, [criarTaxaEntrega])

  const imprimirConsumo = useCallback(
    async (pedidoId: string, codigoReferencia: string, impressorLocal: boolean = true) => {
      const qtdColunasPDV = getConfigPDV(57);
      const impressora = getImpressoraPdv()

      const qtdColunasCalc = calcPercentFloor(Number(qtdColunasPDV ?? '64'), 75)

      const respostaImpressao = await getPedidoCupom(
        getEmpresaSelecionada()?.Id ?? '',
        pedidoId,
        qtdColunasCalc
      );

      if (respostaImpressao.erro) {
        throw new Error(
          `Erro ao imprimir os dados de consumo do pedido. Detalhe: ${respostaImpressao.erro}`
        );
      }

      const cupom = respostaImpressao.resultado?.data?.cupom;

      const imprimir = new ImpressaoSaurus('HTML');

      try {
        if (cupom) {
          const cupomTraduzido = imprimir.Traduz(cupom);

          if (!cupomTraduzido) {
            throw new Error(`Erro ao realizar impressão de consumo.`);
          }

          if (printType() === 'HTML') {
            const pdv = getPDV();

            if (pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV && impressora && impressorLocal) {
              await enviarImpressaoLocal(pedidoId, codigoReferencia, respostaImpressao.resultado?.data?.cupom, 'Consumo', qtdColunasCalc);
              return
            }

            return await printHtml(
              cupomTraduzido as string,
              Number(qtdColunasCalc || '0')
            );
          } else if (printType() === 'NATIVE') {
            const caminho = getConfigPDV(52);
            const modelo = getConfigPDV(51);

            return await printNative(
              cupom,
              Number(qtdColunasCalc || '0'),
              caminho ?? '',
              modelo ?? '',
              1
            );
          }
        } else {
          throw new Error(`Erro ao realizar impressão de consumo.`);
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [enviarImpressaoLocal, getConfigPDV, getEmpresaSelecionada, getImpressoraPdv, getPDV, getPedidoCupom, printHtml, printNative, printType, showToast]
  );

  const isPedidoDelivery = useCallback(() => {
    const configuracaoDelivery = getRegistro(GestaoStorageKeys.IsDelivery, false)

    if (typeof configuracaoDelivery !== "boolean") {
      return false;
    }

    return configuracaoDelivery;
  }, [getRegistro]);

  const setPedidoDelivery = useCallback(
    (isDelivery: boolean) => {
      setRegistro(
        GestaoStorageKeys.IsDelivery,
        isDelivery,
        false
      );
    },
    [setRegistro]
  );

  const deletePedidoDelivery = useCallback(
    () => {
      delRegistro(
        GestaoStorageKeys.IsDelivery,
        false
      );
    },
    [delRegistro]
  );

  const carregarInfoPedido = useCallback(async (pedidoId: string): Promise<PedidoModel | undefined> => {
    try {

      const pedido = await getPedidoSalaoById(
        getEmpresaSelecionada()?.Id ?? '',
        pedidoId
      );

      if (pedido.erro) {
        throw new Error(pedido.erro);
      }

      return pedido.resultado?.data ?? new PedidoModel();
    } catch (err: any) {
      showToast('error', `Erro ao buscar pedido. Detalhe: ${err.message}`);
      return undefined;
    }
  }, [getEmpresaSelecionada, getPedidoSalaoById, showToast]);

  const handleFluxoDelivery = useCallback((noCarrinho?: boolean) => {
    const mov = getMov()
    const idenCliente = getConfigPDV(EnumPDVConfigCod.SolicitarCliente)
    const produtoEntrega = getConfigByCod(EnumContratoConfig.ProdutoEntrega)
    if (!mov) {
      throw new Error(
        `Não existe venda em aberto para definir o cliente selecionado.`,
      );
    }

    if (isEmpty(produtoEntrega)) {
      showToast('error', 'O Produto do tipo Entrega não foi selecionado. Selecione um dos seus produtos para tipo entrega ou crie um novo.')
      return
    }
    if (idenCliente === '1') {
      if (!mov.clienteIdentificado || isEmpty(mov.cliente?.endereco)) {
        history.push('/venda-simples/identificar-cliente-delivery')
        return
      }

      if (mov.produtos.length <= 0) {
        history.push('/venda-simples/catalogo')
        return
      }

    } else {
      if (mov.produtos.length <= 0) {
        history.push('/venda-simples/catalogo')
        return
      }

      if (!mov.clienteIdentificado || isEmpty(mov.cliente?.endereco)) {
        history.push('/venda-simples/identificar-cliente-delivery')
        return
      }
    }

    const hasProdEntrega = mov.produtos.filter(x => x.produtoId === produtoEntrega).length > 0

    if (history.location.pathname !== "/venda-simples/carrinho") {
      if (history.location.pathname !== '/venda-simples/novo-pagamento') {
        history.push('/venda-simples/carrinho')
        return
      }
    }

    if (!hasProdEntrega) {
      history.push('/venda-simples/entrega-frete')
      return
    }

    if (isEmpty(mov.pags)) {
      history.push('/venda-simples/novo-pagamento')
      return
    }

    history.push('/venda-simples/enviar-delivery')

  }, [getConfigByCod, getConfigPDV, getMov, history, showToast])

  const hasProdutoEntrega = useCallback(() => {
    const mov = getMov()
    const prodEntrega = getConfigByCod(EnumContratoConfig.ProdutoEntrega)
    const temProd = mov?.produtos.find(x => x.produtoId === prodEntrega)

    return !isEmpty(temProd)
  }, [getConfigByCod, getMov])

  return {
    // SESSÃO PEDIDO
    pedidoDadosWrapper,
    pedidoProdutosWrapper,
    carregarInfoPedido,

    // impressão
    imprimirConsumo,
    carregando,

    // configuracao
    isPedidoDelivery,
    setPedidoDelivery,
    deletePedidoDelivery,
    calcularTaxaEntrega,
    handleFluxoDelivery,
    hasProdutoEntrega
  };
}
