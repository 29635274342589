import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from 'config';

export function useGetCompartilhamentoDadosWebHook() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getCompartilhamentoDadosWebHook = useCallback((contratoId: string) =>
    invocarApi({
      url: `/${VariaveisAmbiente.gatewayVersion}/contrato/${contratoId}`,
      method: "GET",
      enviarTokenUsuario: true,
    }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getCompartilhamentoDadosWebHook,
  }

}
