import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    planosContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
    },
}));
