import { Grid } from '@material-ui/core';
import { useRef, useCallback, useEffect, useState } from 'react';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { toDecimal } from 'utils/to-decimal';
import { PagamentoParcialEfetuarFragment } from 'views/pages/private/movimentacao/mov-pagamento-novo/components/pagamento-parcial/pagamento-parcial-efetuar-fragment/pagamento-efetuar-fragment';
import NovoPagamentoListData from 'views/pages/private/movimentacao/mov-pagamento-novo/components/novo-pagamento-list/novo-pagamento-list-data';
import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { isEmpty } from 'lodash';
import { CardModel, PagsModel } from 'model/api/gestao/venda/venda-completa-model';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { PagamentoProps } from './pagamento-props';
import { roundTo } from 'utils/round-to';

export const Pagamento = ({ pagCb }: PagamentoProps) => {
  const valorDigitadoConfirmado = useRef<number>(pagCb?.valor || 0);
  const digitado = useRef<number>(pagCb?.valor || 0);
  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FinalizadoraModel>()
  });

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const { getFinalizadoras, carregando: carregandoFinalizadoras } =
    useGetFinalizadoras();
  const {
    fecharDadosPagamento,
    abrirParcelamento
  } = useCadastros();
  const { getEmpresaSelecionada } = useSessaoAtual();

  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  const search = useCallback(async () => {
    try {
      const query =
        (!isEmpty(getEmpresaSelecionada()?.Id)
          ? '&EmpresaId=' + getEmpresaSelecionada()?.Id
          : '') + '&pageSize=30';
      const res = await getFinalizadoras(query, 1);
      fillResult(
        1,
        1,
        res?.resultado?.data.list.length ?? 0,
        res?.resultado?.data.list ?? ([] as FinalizadoraModel[])
      );
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getEmpresaSelecionada, getFinalizadoras, fillResult, showToast]);

  useEffect(() => {
    (async () => {
      await search();
    })();
  }, [search]);

  const onCloseClick = useCallback(() => {
    fecharDadosPagamento();
  }, [fecharDadosPagamento]);

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        digitado.current = toDecimal(formattedText, 2);
        return true;
      } catch (e: any) {
        showToast(
          'error',
          'Erro ao inserir o valor avulso. Detalhe: ' + e.message
        );
        return false;
      }
    },
    [showToast]
  );

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      try {
        // Parcelamento do pagamento
        if (digitado.current === 0) {
          throw new Error(
            'Não é possível realizar um pagamento com valor zerado.'
          );
        }
        if (pagCb && digitado.current > pagCb.valor && ![EnumPagTpMod.DINHEIRO, EnumPagTpMod.OUTRO].includes(paymentMethod.tpMod)) {
          throw new Error('O valor não pode ser maior que o restante.')
        }

        const arrayParcelamento = () => {
          const arrayParcelamento: number[] = [];
          for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
            arrayParcelamento.push(i);
          }
          return arrayParcelamento;
        };

        const arrayParc = arrayParcelamento().filter((item: number) => {
          return digitado.current / item >= paymentMethod.vMinParc;
        });

        if (
          paymentMethod.qMaxParc > 1 &&
          paymentMethod.vMinParc < (100 ?? 0) - (0 ?? 0) &&
          arrayParc.length !== 1
        ) {
          const parapagar = digitado.current;

          abrirParcelamento(
            async (parcelas: number) => {
              const pagModel: PagsModel = {
                ...new PagsModel(),
                tPag: paymentMethod.tpMod,
                descricao: paymentMethod.descricao,
                tpTransacao: EnumPagTpTransacao.NORMAL,
                vPag: digitado.current,
                qtdeParcela: parcelas,
                pagamentoId: paymentMethod.id,
                card: {
                  ...new CardModel(),
                  id: paymentMethod.id,
                }
              }
              const res = await pagCb?.callback(pagModel)
              if (res) {
                fecharDadosPagamento();
              }
            },
            1,
            parapagar,
            paymentMethod
          );
        } else {
          const pagModel: PagsModel = {
            ...new PagsModel(),
            tPag: paymentMethod.tpMod,
            descricao: paymentMethod.descricao,
            tpTransacao: EnumPagTpTransacao.NORMAL,
            vPag: digitado.current,
            vTroco: digitado.current > (pagCb?.valor || 0) ? roundTo(digitado.current - (pagCb?.valor || 0) ?? 0) : 0,
            qtdeParcela: 1,
            pagamentoId: paymentMethod.id,
            card: {
              ...new CardModel(),
              id: paymentMethod.id,
            }
          }

          const res = await pagCb?.callback(pagModel)

          if (res) {
            fecharDadosPagamento();
          }
        }
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [abrirParcelamento, fecharDadosPagamento, pagCb, showToast]
  );

  const onCardChecked = useCallback((model: FinalizadoraModel) => { }, []);

  return (
    <div className={classes.root}>
      {carregandoFinalizadoras ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Dados do Pagamento'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregandoFinalizadoras ? classes.contentFormsLoading : undefined
          )}
        >
          <Grid
            direction="column"
            style={{ height: '100%', width: '100%', display: 'flex' }}
          >
            <Grid
              style={{
                flex: '1 1 100%',
                overflowX: 'hidden',
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <PagamentoParcialEfetuarFragment
                valorDigitadoConfirmadoRef={valorDigitadoConfirmado}
                vRestante={pagCb?.valor ?? 0}
                vPago={0}
                vPessoa={pagCb?.valor ?? 0}
                textChanged={textChanged}
              />
            </Grid>

            <Grid
              style={{
                height: 200,
                minHeight: 200,
              }}
            >
              <NovoPagamentoListData
                carregando={carregandoFinalizadoras}
                list={queryStatus.list}
                selectedList={[]}
                onCardClicked={onCardClicked}
                onCardChecked={onCardChecked}
                paymentScreen
                entrada
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
