import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { VariaveisAmbiente } from 'config';

export function usePostProdutoCategoria() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postProdutoCategoria = useCallback(
    (produtoCategoria: ProdutoCategoriaModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/Categoria`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produtoCategoria),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoCategoria
  };
}
