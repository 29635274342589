import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const DividirIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M 25 9 C 22.800781 9 21 10.800781 21 13 C 21 15.199219 22.800781 17 25 17 C 27.199219 17 29 15.199219 29 13 C 29 10.800781 27.199219 9 25 9 Z M 25 11 C 26.117188 11 27 11.882813 27 13 C 27 14.117188 26.117188 15 25 15 C 23.882813 15 23 14.117188 23 13 C 23 11.882813 23.882813 11 25 11 Z M 9 24 L 9 26 L 41 26 L 41 24 Z M 25 33 C 22.800781 33 21 34.800781 21 37 C 21 39.199219 22.800781 41 25 41 C 27.199219 41 29 39.199219 29 37 C 29 34.800781 27.199219 33 25 33 Z M 25 35 C 26.117188 35 27 35.882813 27 37 C 27 38.117188 26.117188 39 25 39 C 23.882813 39 23 38.117188 23 37 C 23 35.882813 23.882813 35 25 35 Z" />
      </DefaultIcon>
    </>
  );
};
