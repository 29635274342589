import React, { useMemo, useState } from "react"
import { Paginacao } from "./paginacao";
import { isDecimal } from "utils/to-decimal";
import { roundTo } from "utils/round-to";

interface Props<T> {
    children: any;
    list: T[];
    listPropName?: string; //passe o nome da prop que irá para o componente filho (ListData ou algo assim)
    pageSize?: number;
}

//Fiz esse componente pra fazer uma paginação somente no front, mande um array gigante pra cá pra ele paginar
export function PaginacaoLocal<T = any>({
    children,
    list,
    listPropName = 'list',
    pageSize = 25,
}: Props<T>) {

    const [page, setPage] = useState<number>(1);

    const pageSlice = useMemo(() => {
        if (page === 1) {
            return 0;
        }
        return (page - 1) * pageSize
    }, [page, pageSize])

    const changePage = (newPage: number) => {
        if (newPage <= (list.length / pageSize) || newPage > 0) {
            setPage(newPage);
        }
    }


    return (
        <>
            <Paginacao
                currentPage={page}
                totalRegisters={list.length}
                totalPages={isDecimal(list.length / pageSize) ? roundTo(list.length / pageSize, 0) : list.length / pageSize}
                pageChanged={changePage}
            />
            {React.cloneElement(children, {
                [listPropName]: list.slice(pageSlice, pageSlice + (pageSize - 1))
            })}
        </>
    )
}