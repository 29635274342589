import { Box, Button, Container, Grid, Typography } from "@material-ui/core"
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ButtonPrivateHeader } from "views/components/controles";
import { PrivatePageHeader } from "views/components/headers";
import { DocumentoEntradaIcon, DocumentoRefIcon, FornecedorIcon, InformacaoIcon, LojaIcon, PagamentosIcon, ProdutosIcon, SituacaoEntradaIcon, TransporteIcon, VoltarIcon } from "views/components/icons";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { useStyles } from "./opcoes-entrada-styles";
import { useGetVendaCompleta } from "data/api/gestao/venda/get-venda-completa";
import { useCadastros, useToastSaurus } from "services/app";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { CircularLoading } from "views/components";
import { toDateString } from "utils/to-date";
import { TpAmbMock } from "data/mocks/tp-amb-mock";
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { completarEndereco } from "utils/completar-endereco";
import { toDecimalString } from "utils/to-decimal";
import { CarrinhoIcon } from "views/components/icons/carrinho-icon";
import classNames from "classnames";
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov";
import { PessoaPerguntaIcon } from "views/components/icons/pessoa-pergunta-icon";
import { ManifestoModel, ManifestoNNFModel } from "model/api/gestao/manifesto/manifesto-model";
import { useGetManifesto } from "data/api/gestao/empresa-documento/get-manifesto";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { ManifestoStatusMockTodos } from "data/mocks/acao-manifesto-mock";
import { descMonth, getCurrentMonth } from "utils/get-date";


const OpcoesEntradaPage = () => {
    const cadClasses = useDefaultCadastroStyles();
    const classes = useStyles()
    const history = useHistory()
    const { showToast } = useToastSaurus()
    const { getVendaCompleta, carregando: carregandoVenda } = useGetVendaCompleta()
    const { getManifesto, carregando: carregandoManifesto } = useGetManifesto();
    const location = useLocation<{ mov?: VendaCompletaModel | null, manifesto: ManifestoNNFModel | null, dtManifesto: string | null }>()
    const { movId } = useParams<{ movId: string }>()
    const [venda, setVenda] = useState<VendaCompletaModel>(new VendaCompletaModel())
    const [manifesto, setManifesto] = useState<ManifestoModel | null>(null)

    const { getEmpresaAtual } = useEmpresaAtual();
    const { abrirModalSituacaoManifesto } = useCadastros();

    const carregando = carregandoVenda || carregandoManifesto

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader icon={<VoltarIcon tipo="PRIVATE_HEADER" />} tooltip="Menu" onClick={() => {
                if (location.search.includes('manifesto')) {
                    history.push({
                        pathname: '/manifestos',
                        search: location.search
                    })
                    return
                }
                history.push('/entrada-mercadoria')
            }}></ButtonPrivateHeader>
        ),
        [history, location.search]
    );

    const getVendaCompletaWrapper = useCallback(async () => {
        try {
            let mov: VendaCompletaModel;
            if (location.state?.mov) {
                setVenda(location.state.mov)
                mov = location.state.mov;
            } else {
                const res = await getVendaCompleta(movId)
                if (res.erro) throw res.erro

                setVenda(res.resultado?.data)

                mov = res.resultado?.data;
            }

            if (location.state && location.state.manifesto) {
                setManifesto(location.state.manifesto)
                return
            }

            if (!mov.idNFe) return;

            const query = `integrado=true&chave=${mov.idNFe.split('NFe')[1] ? mov.idNFe.split('NFe')[1] : mov.idNFe}&dataInicio=${toDateString(descMonth(new Date(getCurrentMonth()), 38), 'yyyy-MM-DD')!}`

            const resManifesto = await getManifesto(getEmpresaAtual()?.cpfcnpj || '', query);

            if (resManifesto.erro) throw resManifesto.erro;

            const data = (resManifesto.resultado?.data as ManifestoModel[]).find((manifesto) => {
                if (!mov.idNFe) return false;
                const chave1 = manifesto.chave.split('NFe')[1] ? manifesto.chave.split('NFe')[1] : manifesto.chave;
                const chave2 = mov.idNFe.split('NFe')[1] ? mov.idNFe.split('NFe')[1] : mov.idNFe;
                return chave1 === chave2;
            })

            if (data) {
                setManifesto(data)
            }

        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getEmpresaAtual, getManifesto, getVendaCompleta, location.state, movId, showToast])


    useEffect(() => {
        getVendaCompletaWrapper()
    }, [getVendaCompletaWrapper])

    const calculoVFinalProd = useMemo(() => {
        let vFinal = 0

        venda.infMov.prod.forEach(prod => {
            vFinal += prod.vProd + prod.vFrete + prod.vSeg + prod.vOutro - prod.vDesc
        })

        return vFinal
    }, [venda.infMov.prod])

    const calculoVImpostos = useMemo(() => {
        const totais = venda.infMov.total

        const vImposto =
            totais.vCOFINS +
            totais.vFCP +
            totais.vFCPST +
            totais.vFCPSTRet +
            totais.vFCPUFDest +
            totais.vICMS +
            totais.vICMSDeson +
            totais.vICMSUFDest +
            totais.vICMSUFRemet +
            totais.vII +
            totais.vIPI +
            totais.vST +
            totais.vIPIDevol +
            totais.vPIS;

        return vImposto
    }, [venda.infMov.total])

    const irParaPagina = (path: string) => {
        history.push(({
            pathname: path,
            search: location.search,
            state: {
                mov: venda,
                manifesto,
                dtManifesto: location.state?.dtManifesto || null
            }
        }))
    }

    const descontoPag = venda.infMov.total.vDesc
    const jurosPag = venda.infMov.total.vOutro
    const vNF = venda.infMov.total.vnf

    const statusEntrada = (status: EnumTpStatusMov) => ({
        [EnumTpStatusMov.EmDigitacao]: 'Em digitação',
        [EnumTpStatusMov.Abortado]: 'Abortado',
        [EnumTpStatusMov.Cancelado]: 'Cancelado',
        [EnumTpStatusMov.Finalizado]: 'Finalizado',
        [EnumTpStatusMov.Pendente]: 'Pendente',
        [EnumTpStatusMov.Rejeitado]: 'Rejeitado',
    })[status] || 'Em digitação'

    return (
        <Grid className={cadClasses.root}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid className={cadClasses.header}>
                <PrivatePageHeader
                    title="Opções de Entrada"
                    leftArea={leftArea()}
                />
            </Grid>
            <Container maxWidth="md" className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} >
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/entrada`)} fullWidth className={classes.btn} variant="contained">
                            <Grid xs={12} className={classes.infoContent} >
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" marginBottom={1}>
                                        <DocumentoEntradaIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary" >
                                            Dados da Entrada
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className={classes.lineInfo}>
                                    <Grid xs={7}>
                                        <Typography color="textSecondary" variant="body2">
                                            Número da NF
                                        </Typography>
                                        <Typography variant="body1">
                                            {venda.infMov.nnf}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={5}>
                                        <Typography color="textSecondary" variant="body2">
                                            Série
                                        </Typography>
                                        <Typography variant="body1">
                                            {String(venda.infMov.serie).padStart(3, '0')}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} className={classes.lineInfo}>
                                    <Grid item xs={7}>
                                        <Typography color="textSecondary" variant="body2">
                                            Data de Emissão
                                        </Typography>
                                        <Typography variant="body1">
                                            {toDateString(venda.infMov.dhEmi, 'DD/MM/yyyy HH:mm')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                        <Typography color="textSecondary" variant="body2">
                                            Ambiente
                                        </Typography>
                                        <Typography variant="body1" >
                                            {TpAmbMock.find(x => x.Key === venda.infMov.tpAmb)?.Value}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/destinatario`)} fullWidth className={classes.btn} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <LojaIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Destinatário
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        CNPJ
                                    </Typography>
                                    <Typography variant="body1">
                                        {
                                            venda.infMov.dest?.documento
                                                ? formatarCPFCNPJ(venda.infMov.dest?.documento)
                                                : "Não informado"
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Razão Social
                                    </Typography>
                                    <Typography variant="body1">
                                        {venda.infMov.dest?.xNome || 'Sem Razão Social'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/emitente`)} fullWidth className={classes.btn} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <FornecedorIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Emitente (Fornecedor)
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" variant="body2">
                                        CNPJ
                                    </Typography>
                                    <Typography variant="body1">
                                        {formatarCPFCNPJ(venda.infMov.emit?.doc || '')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" variant="body2">
                                        Razão Social
                                    </Typography>
                                    <Typography variant="body1">
                                        {venda.infMov.emit?.xNome || 'Sem razão social'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Endereço
                                    </Typography>
                                    <Typography variant="body1">
                                        {venda.infMov.emit?.xLgr && venda.infMov.emit?.xBairro ? (
                                            <>
                                                {completarEndereco({
                                                    rua: venda.infMov.emit?.xLgr || '',
                                                    bairro: venda.infMov.emit?.xBairro || '',
                                                    numero: venda.infMov.emit?.nro || '',
                                                    municipio: venda.infMov.emit?.xMun || '',
                                                    uf: venda.infMov.emit?.uf || '',
                                                    complemento: venda.infMov.emit?.xCpl || '',
                                                    cep: venda.infMov.emit?.cep || ''
                                                })}
                                            </>
                                        ) : <>Sem endereço</>}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={8} sm={8} md={6}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/produtos`)} fullWidth className={classes.btn} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <ProdutosIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Produtos
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Quantidade de Itens
                                    </Typography>
                                    <Typography variant="body1">
                                        {venda.infMov.prod.length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" variant="body2">
                                        Valor Total
                                    </Typography>
                                    <Typography variant="body1">
                                        R$ {toDecimalString(calculoVFinalProd)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="textSecondary" variant="body2">
                                        Valor de Impostos
                                    </Typography>
                                    <Typography variant="body1">
                                        R$ {toDecimalString(calculoVImpostos)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/transportadora`)} fullWidth className={classNames(classes.btn, classes.btnCenter)} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <TransporteIcon tipo="BUTTON" class={classNames(classes.colorSecondary, classes.mBottom)} />
                                    </Box>
                                    <Typography color="primary" align="center">
                                        Transporte e Volumes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} className={classes.cardComp}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/info-complementares`)} fullWidth className={classNames(classes.btn, classes.btnCenter)} variant="contained">
                            <Grid container>
                                <Grid item xs={12} >
                                    <Box display="flex" justifyContent="center">
                                        <InformacaoIcon tipo="BUTTON" class={classNames(classes.colorSecondary, classes.mBottom)} />
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <Typography color="primary" align="center" className={classes.lineHeight}>
                                            Informações Complementares
                                        </Typography>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} className={classes.cardComp}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/doc-referenciados`)} fullWidth className={classNames(classes.btn, classes.btnCenter)} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <DocumentoRefIcon tipo="BUTTON" class={classNames(classes.colorSecondary, classes.mBottom)} />
                                    </Box>
                                    <Typography color="primary" align="center" className={classes.lineHeight}>
                                        Documentos Referenciados
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} className={classes.cardComp}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/info-pedido-compra`)} fullWidth className={classNames(classes.btn, classes.btnCenter)} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <CarrinhoIcon tipo="BUTTON" class={classNames(classes.colorSecondary, classes.mBottom)} />
                                    </Box>
                                    <Typography color="primary" align="center" className={classes.lineHeight}>
                                        Informações de Pedido de Compra
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={manifesto ? 12 : 8} sm={manifesto ? 6 : 8}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/pagamento`)} fullWidth className={classes.btn} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <PagamentosIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Pagamentos
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2">
                                        Desconto
                                    </Typography>
                                    <Typography variant="body1">
                                        R$ {toDecimalString(descontoPag)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2">
                                        Outros
                                    </Typography>
                                    <Typography variant="body1">
                                        R$ {toDecimalString(jurosPag)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography color="textSecondary" variant="body2">
                                        Valor Final
                                    </Typography>
                                    <Typography variant="body1">
                                        R$ {toDecimalString(vNF)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Forma de Pagamento
                                    </Typography>
                                    <Typography variant="body1">
                                        {venda.infMov.pag[0]?.descricao || 'Não Informado'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item xs={manifesto ? 6 : 4} sm={manifesto ? 3 : 4}>
                        <Button onClick={() => irParaPagina(`/entrada-mercadoria/visualizar/${movId}/situacao-entrada`)} fullWidth className={classes.btn} variant="contained">
                            <Grid container >
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <SituacaoEntradaIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Situação da Entrada
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Situação Atual
                                    </Typography>
                                    <Typography variant="body2">
                                        {statusEntrada(venda.status)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    {manifesto && <Grid item xs={6} sm={3}>
                        <Button onClick={() => abrirModalSituacaoManifesto({
                            ...manifesto,
                            vnf: venda.infMov.total.vnf,
                            nome: venda.infMov.emit.xNome,
                            documento: venda.infMov.emit.doc
                        })} fullWidth className={classes.btn} variant="contained">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <PessoaPerguntaIcon tipo="BUTTON" class={classes.colorSecondary} />
                                        <Typography color="primary">
                                            Manifesto
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" variant="body2">
                                        Status
                                    </Typography>
                                    <Typography variant="body1">
                                        {ManifestoStatusMockTodos.find(item => item.Key === manifesto.statusManifesto)?.Value || 'Sem Status'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>}
                </Grid>
            </Container>
        </Grid>
    )
}

export default OpcoesEntradaPage