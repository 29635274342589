import { Redirect, Route, Switch } from 'react-router-dom';
import MovFinalizarPage from 'views/pages/private/movimentacao/mov-finalizar/mov-finalizar-page';
import MovCancelarPage from 'views/pages/private/movimentacao/mov-cancelar/mov-cancelar-page';
import MovProdAvulsoPage from 'views/pages/private/movimentacao/mov-prod-avulso/mov-prod-avulso-page';
import MovProdCatalogoPage from 'views/pages/private/movimentacao/mov-prod-catalogo/mov-prod-catalogo-page';
import MovProdLeitorPage from 'views/pages/private/movimentacao/mov-prod-leitor/venda-leitor-page';
import { MovSelecionarPDVPage } from 'views/pages/private/movimentacao/mov-selecionar-pdv/mov-selecionar-pdv-page';
import MovSimplesLandingPage from 'views/pages/private/movimentacao/mov-simples-landing/mov-simples-landing-page';
import { MovClienteIdentificacaoPage } from 'views/pages/private/movimentacao/mov-cliente-identificacao/mov-cliente-identificacao-page';
import MovErroFinalizarPage from 'views/pages/private/movimentacao/mov-erro-finalizar/mov-erro-finalizar-page';
import { MovAtualValidationRoute } from '../mov-atual-validation-route/mov-atual-validation-route';
import { MovSelecaoVendedorPage } from 'views/pages/private/movimentacao/mov-selecao-vendedor/mov-selecao-vendedor-page';
import { MovPagamentoNovoPage } from 'views/pages/private/movimentacao/mov-pagamento-novo/mov-pagamento-novo-page';
import { MovCarrinhoPage } from 'views/pages/private/movimentacao/mov-carrinho/mov-carrinho-page';
import React from 'react';
import { MovComprovantePage } from 'views/pages/private/movimentacao/mov-comprovante/mov-comprovante-page';
import { MovComprovanteDetalhePage } from 'views/pages/private/movimentacao/mov-comprovante-detalhe/mov-comprovante-detalhe-page';
import MovHistoricoPagamentoPage from 'views/pages/private/movimentacao/mov-historico-pagamento/mov-historico-pagamento-page';
import MovLeitorComandaPage from 'views/pages/private/movimentacao/mov-leitor-comanda/venda-leitor-comanda-page';
import { MovVincularMesaPage } from 'views/pages/private/movimentacao/mov-vincular-mesa/mov-vincular-mesa-page';
import MovEnviarPedidoPage from 'views/pages/private/movimentacao/mov-enviar-pedido/mov-enviar-pedido-page';
import AtendimentoPage from 'views/pages/private/movimentacao/mov-atendimento-mesas-comandas/atendimento-page';
import { MovVisualizarMesaEComandaPage } from 'views/pages/private/movimentacao/mov-visualizar-mesa-e-comanda/mov-visualizar-mesa-e-comanda-page';
import { MovSelecionarComandaFechamentoPage } from 'views/pages/private/movimentacao/mov-selecionar-comanda-fechamento/mov-selecionar-comanda-fechamento-page';
import { MovVisualizarPedidoPage } from 'views/pages/private/movimentacao/mov-visualizar-pedido/mov-visualizar-pedido';
import MovFecharComandaPage from 'views/pages/private/movimentacao/mov-fechar-pedido/mov-fechar-pedido-page';
import MovImportarComandaPage from 'views/pages/private/movimentacao/mov-importar-pedidos/mov-importar-pedidos-page';
import MovFinalizarPedidoPage from 'views/pages/private/movimentacao/mov-finalizar-pedidos/mov-finalizar-pedidos-page';
import MovCancelarImportacaoPage from 'views/pages/private/movimentacao/mov-cancelar-importacao/mov-cancelar-importacao-page';
import { MovTransferirItensPedidoPage } from 'views/pages/private/movimentacao/mov-transferir-itens-pedido/mov-transferir-itens-pedido-page';
import { MovAlterarVendedorProdutoPedidoPage } from 'views/pages/private/movimentacao/mov-selecionar-vendedor-produto/mov-selecionar-vendedor-produto-page';
import { AbrirCaixa } from 'views/pages/private/movimentacao/mov-abrir-caixa/abrir-caixa-page';
import MovNumeracaoPage from 'views/pages/private/movimentacao/mov-numeracao/mov-numeracao-page';
import { consoleDev } from 'utils/console-dev';
import MovGerarComandaPage from 'views/pages/private/movimentacao/mov-controle-salao/mov-gerar-comanda/mov-gerar-comanda-page';
import { MovFinalizacaoParcialComandaPage } from 'views/pages/private/movimentacao/mov-finalizacao-parcial-comanda/mov-finalizacao-parcial-comanda-page';
import DashboardPage from 'views/pages/private/relatorios/dashboard/dashboard-page';
import { HistoricoVendaPage } from 'views/pages/private/historico-vendas/historico-vendas-page';
import MovProcessandoPagamentoPage from 'views/pages/private/movimentacao/mov-processando-pagamento/mov-processando-pagamento-page';
import MovInformacoesClientePage from 'views/pages/private/movimentacao/mov-informacoes-cliente/mov-informacoes-cliente-page';
import { CentralAdministrativa } from 'views/pages/private/movimentacao/mov-central-administrativa/central-page';
import MovGerenciamentoSessoesPage from 'views/pages/private/movimentacao/mov-gerenciamento-sessoes/mov-gerenciamento-sessoes-page';
import MovDefinicoesVendaPage from 'views/pages/private/movimentacao/mov-definicoes-venda/mov-definicoes-venda-page';
import MovGerarPagerIdentificadorPage from 'views/pages/private/movimentacao/mov-identificacao-pager/mov-gerar-pager-identificador-page';
// import MovEnviarDeliveryPage from 'views/pages/private/movimentacao/mov-enviar-delivery/mov-enviar-delivery-page';
// import MovTaxaEntregaPage from 'views/pages/private/movimentacao/mov-taxa-entrega/mov-taxa-entrega-page';

export const RouterVenda = () => {
  const movLeitorComandaPage = React.useMemo(() => {
    return <MovLeitorComandaPage></MovLeitorComandaPage>;
  }, []);

  const movDashboardPage = React.useMemo(() => {
    return <DashboardPage />;
  }, []);

  const movSelecionarPDVPage = React.useMemo(() => {
    return <MovSelecionarPDVPage></MovSelecionarPDVPage>;
  }, []);
  const movInformacoesClientePage = React.useMemo(() => {
    return <MovInformacoesClientePage></MovInformacoesClientePage>
  }, []);

  const movCentralAdministrativaPage = React.useMemo(() => {
    return <CentralAdministrativa></CentralAdministrativa>;
  }, []);

  const movAbrirCaixaPage = React.useMemo(() => {
    return <AbrirCaixa></AbrirCaixa>;
  }, []);
  const movGerencimantoSessaoPage = React.useMemo(() => {
    return <MovGerenciamentoSessoesPage></MovGerenciamentoSessoesPage>
  }, [])

  const movComprovantePage = React.useMemo(() => {
    return <MovComprovantePage></MovComprovantePage>;
  }, []);

  const movComprovanteDetalhePage = React.useMemo(() => {
    return <MovComprovanteDetalhePage></MovComprovanteDetalhePage>;
  }, []);

  const movFinalizarPage = React.useMemo(() => {
    return <MovFinalizarPage></MovFinalizarPage>;
  }, []);

  const movSimplesLandingPage = React.useMemo(() => {
    return <MovSimplesLandingPage></MovSimplesLandingPage>;
  }, []);

  const movProdAvulsoPage = React.useMemo(() => {
    return <MovProdAvulsoPage></MovProdAvulsoPage>;
  }, []);

  const movProdLeitorPage = React.useMemo(() => {
    return <MovProdLeitorPage></MovProdLeitorPage>;
  }, []);

  const movProdCatalogoPage = React.useMemo(() => {
    return <MovProdCatalogoPage></MovProdCatalogoPage>;
  }, []);

  const movCarrinhoPage = React.useMemo(() => {
    return <MovCarrinhoPage></MovCarrinhoPage>;
  }, []);

  const movCancelarPage = React.useMemo(() => {
    return <MovCancelarPage></MovCancelarPage>;
  }, []);

  const movErroFinalizarPage = React.useMemo(() => {
    return <MovErroFinalizarPage></MovErroFinalizarPage>;
  }, []);

  const movClienteIdentificacaoPage = React.useMemo(() => {
    return <MovClienteIdentificacaoPage></MovClienteIdentificacaoPage>;
  }, []);

  const movSelecaoVendedorPage = React.useMemo(() => {
    return <MovSelecaoVendedorPage></MovSelecaoVendedorPage>;
  }, []);

  const movPagamentoNovoPage = React.useMemo(() => {
    return <MovPagamentoNovoPage></MovPagamentoNovoPage>;
  }, []);

  const movVincularMesaPage = React.useMemo(() => {
    return <MovVincularMesaPage></MovVincularMesaPage>;
  }, []);

  const movEnviarPedidoPage = React.useMemo(() => {
    return <MovEnviarPedidoPage></MovEnviarPedidoPage>;
  }, []);

  // const movEnviarDeliveryPage = React.useMemo(() => {
  //   return <MovEnviarDeliveryPage></MovEnviarDeliveryPage>;
  // }, []);

  const movVisualizarMesaEComandaPage = React.useMemo(() => {
    return <MovVisualizarMesaEComandaPage></MovVisualizarMesaEComandaPage>;
  }, []);

  const movSelecionarComandaFechamentoPage = React.useMemo(() => {
    return (
      <MovSelecionarComandaFechamentoPage></MovSelecionarComandaFechamentoPage>
    );
  }, []);

  const movVisualizarPedidoPage = React.useMemo(() => {
    return <MovVisualizarPedidoPage></MovVisualizarPedidoPage>;
  }, []);

  const movHistoricoPagamento = React.useMemo(() => {
    return <MovHistoricoPagamentoPage></MovHistoricoPagamentoPage>;
  }, []);

  const movFecharComanda = React.useMemo(() => {
    return <MovFecharComandaPage></MovFecharComandaPage>;
  }, []);

  const movImportarPedidos = React.useMemo(() => {
    return <MovImportarComandaPage />;
  }, []);

  const movFinalizarPedidos = React.useMemo(() => {
    return <MovFinalizarPedidoPage />;
  }, []);

  const movCancelarImportacao = React.useMemo(() => {
    return <MovCancelarImportacaoPage />;
  }, []);

  const movTransferirItensPedido = React.useMemo(() => {
    return <MovTransferirItensPedidoPage />;
  }, []);

  const movAlterarVendedorProduto = React.useMemo(() => {
    return <MovAlterarVendedorProdutoPedidoPage />;
  }, []);

  const movNumeracao = React.useMemo(() => {
    return <MovNumeracaoPage />;
  }, []);

  const movGerarMesaComanda = React.useMemo(() => {
    return <MovGerarComandaPage />;
  }, []);

  const movFinalizacaoParcialComanda = React.useMemo(() => {
    return <MovFinalizacaoParcialComandaPage />;
  }, []);

  const movProcessandoPagamento = React.useMemo(() => {
    return <MovProcessandoPagamentoPage />;
  }, []);

  const movDefinicoesVenda = React.useMemo(() => {
    return <MovDefinicoesVendaPage />;
  }, []);

  const movGerarPagerIdentificador = React.useMemo(() => {
    return <MovGerarPagerIdentificadorPage />;
  }, []);

  // const movIdentificarClienteDelivery = React.useMemo(() => {
  //   return <MovClienteDeliveryPage />;
  // }, []);


  // const movTaxaEntregaPage = React.useMemo(() => {
  //   return <MovTaxaEntregaPage />
  // }, [])

  // const movAtendimentoDeliveryPage = React.useMemo(() => {
  //   return <MovAtendimentoDeliveryPage />
  // }, [])

  const switchVendaInterno = React.useMemo(() => {
    consoleDev('routerVenda-switchVendaInterno');

    return (
      <>
        <Route path="/venda-simples/pedidos" component={AtendimentoPage} />
        <Route path="/venda-simples/historico" component={HistoricoVendaPage} />
        <Route exact path="/venda-simples/dashboard">
          {movDashboardPage}
        </Route>
        <Route exact path="/venda-simples/finalizacao-parcial">
          {movFinalizacaoParcialComanda}
        </Route>
        <Route exact path="/venda-simples/vincular-mesa">
          {movVincularMesaPage}
        </Route>
        <Route exact path="/venda-simples/visualizar-pedido/:tipoTrabalhoId">
          {movVisualizarPedidoPage}
        </Route>
        <Route exact path="/venda-simples/mesa-e-comanda/:mesaId">
          {movVisualizarMesaEComandaPage}
        </Route>
        <Route exact path="/venda-simples/transferir-itens">
          {movTransferirItensPedido}
        </Route>
        <Route exact path="/venda-simples/fechamento-comanda">
          {movSelecionarComandaFechamentoPage}
        </Route>
        <Route exact path="/venda-simples/fechar-comanda">
          {movFecharComanda}
        </Route>
        <Route exact path="/venda-simples/alterar-vendedor">
          {movAlterarVendedorProduto}
        </Route>
        <Route exact path="/venda-simples/importar-pedidos">
          {movImportarPedidos}
        </Route>
        <Route exact path="/venda-simples/avulso">
          {movProdAvulsoPage}
        </Route>
        <Route exact path="/venda-simples/leitor">
          {movProdLeitorPage}
        </Route>
        <Route exact path="/venda-simples/enviar-pedido">
          {movEnviarPedidoPage}
        </Route>
        {/* <Route exact path="/venda-simples/enviar-delivery">
          {movEnviarDeliveryPage}
        </Route> */}
        <Route exact path="/venda-simples/catalogo">
          {movProdCatalogoPage}
        </Route>
        <Route exact path="/venda-simples/numeracao">
          {movNumeracao}
        </Route>
        <Route exact path="/venda-simples/carrinho">
          {movCarrinhoPage}
        </Route>
        <Route exact path="/venda-simples/cancelar">
          {movCancelarPage}
        </Route>
        <Route exact path="/venda-simples/leitor-comanda">
          {movLeitorComandaPage}
        </Route>
        <Route path="/venda-simples/finalizar-venda/rejeicao" exact>
          {movErroFinalizarPage}
        </Route>
        <Route path="/venda-simples/identificar-cliente" exact>
          {movClienteIdentificacaoPage}
        </Route>
        <Route exact path="/venda-simples/administrativo">
          {movCentralAdministrativaPage}
        </Route>
        <Route path="/venda-simples/selecao-vendedor" exact>
          {movSelecaoVendedorPage}
        </Route>
        <Route path="/venda-simples/novo-pagamento">
          {movPagamentoNovoPage}
        </Route>
        <Route path="/venda-simples/pagamentos">
          {movHistoricoPagamento}
        </Route>
        <Route path="/venda-simples/processando-pagamento">
          {movProcessandoPagamento}
        </Route>
        <Route path="/venda-simples/definicoes">
          {movDefinicoesVenda}
        </Route>
        <Route path="/venda-simples/identificador-pedido">
          {movGerarPagerIdentificador}
        </Route>
        {/* <Route path="/venda-simples/identificar-cliente-delivery">
          {movIdentificarClienteDelivery}
        </Route> */}
        {/* <Route path="/venda-simples/entrega-frete">
          {movTaxaEntregaPage}
        </Route> */}
        {/* <Route path="/venda-simples/atendimento-delivery">
          {movAtendimentoDeliveryPage}
        </Route> */}
      </>
    );
  }, [movDashboardPage, movFinalizacaoParcialComanda, movVincularMesaPage, movVisualizarPedidoPage, movVisualizarMesaEComandaPage, movTransferirItensPedido, movSelecionarComandaFechamentoPage, movFecharComanda, movAlterarVendedorProduto, movImportarPedidos, movProdAvulsoPage, movProdLeitorPage, movEnviarPedidoPage, movProdCatalogoPage, movNumeracao, movCarrinhoPage, movCancelarPage, movLeitorComandaPage, movErroFinalizarPage, movClienteIdentificacaoPage, movCentralAdministrativaPage, movSelecaoVendedorPage, movPagamentoNovoPage, movHistoricoPagamento, movProcessandoPagamento, movDefinicoesVenda, movGerarPagerIdentificador]);

  const switchVenda = React.useMemo(() => {
    consoleDev('routerVenda-switchVenda');
    return (
      <Switch>
        <Route exact path="/venda-simples/cancelar-importacao">
          {movCancelarImportacao}
        </Route>
        <Route exact path="/venda-simples/finalizar-pedidos">
          {movFinalizarPedidos}
        </Route>
        <Route exact path="/venda-simples/selecionar-pdv">
          {movSelecionarPDVPage}
        </Route>
        <Route exact path="/venda-simples/abrir-caixa">
          {movAbrirCaixaPage}
        </Route>
        <Route exact path="/venda-simples/gerenciamento-sessoes">
          {movGerencimantoSessaoPage}
        </Route>
        <Route path="/venda-simples/finalizar-venda/comprovante" exact>
          {movComprovantePage}
        </Route>
        <Route path="/venda-simples/finalizar-venda/detalhes-venda" exact>
          {movComprovanteDetalhePage}
        </Route>
        <Route path="/venda-simples/finalizar-venda" exact>
          {movFinalizarPage}
        </Route>
        <Route exact path="/venda-simples/landing">
          {movSimplesLandingPage}
        </Route>
        <Route exact path="/venda-simples/gerar-mesa-comanda">
          {movGerarMesaComanda}
        </Route>
        <Route exact path="/venda-simples/informacoes-cliente">
          {movInformacoesClientePage}
        </Route>
        <MovAtualValidationRoute>{switchVendaInterno}</MovAtualValidationRoute>
        <Route>
          <Redirect to="/venda-simples/landing" />
        </Route>
      </Switch>
    );
  }, [
    movCancelarImportacao,
    movFinalizarPedidos,
    movSelecionarPDVPage,
    movAbrirCaixaPage,
    movGerencimantoSessaoPage,
    movComprovantePage,
    movComprovanteDetalhePage,
    movFinalizarPage,
    movSimplesLandingPage,
    switchVendaInterno,
    movGerarMesaComanda,
    movInformacoesClientePage
  ]);
  return switchVenda;
};
