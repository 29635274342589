export class PedidoProdutoTransferirDestino {
    constructor(
        public comandaId: string | null = null,
        public mesaId: string | null = null,
    ) { }
}

export class PedidoTransferirProduto {
    constructor(
        public produtosIds: string[] = [],
        public destino: PedidoProdutoTransferirDestino = new PedidoProdutoTransferirDestino(),
    ) { }
}
