import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    ButtonKeyboardContainer: {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "10px",
    },
    TypographyButtonKeyboard: {
        webkitUserSelect: "none",
        mozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        fontWeight:700,
        color:theme.palette.primary.main    
},
}));
