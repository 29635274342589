import { useState, useCallback } from 'react';
import { useDefaultCadastroStyles } from '../components/default-cadastro-styles';
import { FinalizadoraHeader } from './components/finalizadora-header/finalizadora-header';
import { FinalizadoraList } from './components/finalizadora-list/finalizadora-list';
import { ButtonFab } from 'views/components/controles';
import { NovoIcon } from 'views/components/icons';
import { Grid } from '@material-ui/core';
import { FinalizadoraListSearchProps } from './components/finalizadora-list/finalizadora-list-search-props';
import { useCadastros } from 'services/app';

export const FinalizadoraPage = () => {
  const classes = useDefaultCadastroStyles();
  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<FinalizadoraListSearchProps>({
    termo: '',
    status: 1,
  });
  const { abrirCadastroFinalizadora } = useCadastros();

  const onClickAdd = useCallback(() => {
    abrirCadastroFinalizadora('', '', true);
  }, [abrirCadastroFinalizadora]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <FinalizadoraHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <FinalizadoraList searchProps={searchProps} />
      </Grid>
      <ButtonFab
        tooltip="Adicionar Forma de Pagamento"
        icon={<NovoIcon tipo="BUTTON_FAB" />}
        onClick={onClickAdd}
      />
    </Grid>
  );
};

export default FinalizadoraPage;
