import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";


export function useGetVendaCompleta() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getVendaCompleta = useCallback(
        async (id: string) => {
            const retorno = await invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Movimento/${id}/completo`,
                method: "GET",
                enviarTokenUsuario: true,
            });

            if (retorno?.resultado) {
                let venda = retorno.resultado.data as VendaCompletaModel
                //TRATAMENTO PARA RETORNO DA API DO FILIPE
                if (Array.isArray(venda.infMov.emit) && venda.infMov.emit.length > 0) {
                    venda = { ...venda, infMov: { ...venda.infMov, emit: venda.infMov.emit[0] } };
                }

                if (Array.isArray(venda.infMov.dest) && venda.infMov.dest.length > 0) {
                    venda = { ...venda, infMov: { ...venda.infMov, dest: venda.infMov.dest[0] } };
                }

                retorno.resultado.data = venda
            }
            return retorno;
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getVendaCompleta,
    };
}
