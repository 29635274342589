import { KeyValueModel } from "model";

export const CstIpiMock: KeyValueModel[] = [
    new KeyValueModel(-1, 'Não informar IPI'),
    new KeyValueModel(0, "00 - Entrada com Recuperação de Crédito"),
    new KeyValueModel(1, "01 - Entrada Tributada com Alíquota Zero"),
    new KeyValueModel(2, "02 - Entrada Isenta"),
    new KeyValueModel(3, "03 - Entrada não Tributada"),
    new KeyValueModel(4, "04 - Entrada Imune"),
    new KeyValueModel(5, "05 - Entrada com Suspensão"),
    new KeyValueModel(49, "49 - Outras Entradas"),
    new KeyValueModel(50, "50 - Saída Tributada"),
    new KeyValueModel(51, "51 - Saída Tributada com Alíquota Zero"),
    new KeyValueModel(52, "52 - Saída Isenta"),
    new KeyValueModel(53, "53 - Saída não Tributada"),
    new KeyValueModel(54, "54 - Saída Imune"),
    new KeyValueModel(55, "55 - Saída com Suspensão"),
    new KeyValueModel(99, "99 - Outras Saídas"),
]