import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePostPedidoDados } from 'data/api/gestao/pedido-dados/post-pedido-dados';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper } from '@material-ui/core';
import { FormPagerIdentificador } from 'views/components/form/pager-identificador/form-pager-identificador';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { PedidoDadosIntegracaoModel } from 'model/api/gestao/pedido/pedido-integracao-model';
import { useStyles } from './gerar-pager-identificador-styles';
import { CloseIconCircle } from 'views/components/icons/close-icon-circle';
import { ConfirmarIconCircle } from 'views/components/icons/confirmar-icon-circle';
import { useThemeQueries } from 'views/theme';
import { usePDV } from 'services/app/hooks/pdv';


export const GerarPagerIdentificador = () => {
    const classes = useStyles();

    const { getMov, setIdentificadorPager, setSalaoId, setarSeUsaIdentificador } = useMovAtual();
    const { selClienteDesativado } = usePDV();
    const { push: historyPush } = useHistory();
    const { getEmpresaAtual } = useEmpresaAtual()
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const { postPedidoDados, carregando: carregandoPedidos } = usePostPedidoDados();
    const { theme } = useThemeQueries()
    const [semIdentificacao, setSemIdentificacao] = useState<boolean>(false)
    const carregando = carregandoPedidos
    const formRef = useRef<DefaultFormRefs<PedidoDadosModelPost>>(null);

    const redirecionarPedido = useCallback(() => {
        const mov = getMov();
        if (!mov) return;

        if (mov.produtos.length === 0) {
            historyPush('/venda-simples/landing')
            return
        }
        historyPush('/venda-simples/enviar-pedido');
        return;
    }, [getMov, historyPush]);

    const pedidoIntegradorWrapper = useCallback(() => {
        const integrador = new PedidoDadosIntegracaoModel();

        integrador.nomeIntegrador = "TouchOne";
        integrador.cnpjIntegrador = "11914993000123";
        integrador.credencialCliente = getEmpresaSelecionada()?.Id ?? '';
        integrador.codigoAplicacao = 990009859;

        return integrador;
    }, [getEmpresaSelecionada])

    const handleSubmitPedidoComItem = useCallback(async (model: PedidoDadosModelPost) => {

        if (!model.identificador) {
            setarSeUsaIdentificador(true)
        }

        await setIdentificadorPager(model.identificador)
        await setSalaoId(model?.salaoId ?? "")

        if (selClienteDesativado() && !getMov()?.clienteIdentificado) {
            historyPush('/venda-simples/identificar-cliente')
            return
        }

        redirecionarPedido()
    }, [getMov, historyPush, redirecionarPedido, selClienteDesativado, setIdentificadorPager, setSalaoId, setarSeUsaIdentificador])

    const handleSubmitPedidoSemItem = useCallback(async (model: PedidoDadosModelPost) => {
        try {

            if (!model.identificador) {
                setarSeUsaIdentificador(true)
            }

            await setIdentificadorPager(model.identificador)
            await setSalaoId(model?.salaoId ?? "")
            model.documentoLoja = getEmpresaAtual()!.cpfcnpj;
            model.dadosIntegracao = pedidoIntegradorWrapper();

            const res = await postPedidoDados(getEmpresaAtual()!.id, model)
            if (res.erro) throw res.erro
            if (selClienteDesativado() && !getMov()?.clienteIdentificado) {
                historyPush('/venda-simples/identificar-cliente')
                return
            }
            historyPush('/venda-simples/enviar-pedido');

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getEmpresaAtual, getMov, historyPush, pedidoIntegradorWrapper, postPedidoDados, selClienteDesativado, setIdentificadorPager, setSalaoId, setarSeUsaIdentificador, showToast])

    const produtosDaMov = useMemo(() => {
        const prodMov = getMov()?.produtos
        if (prodMov && prodMov.length > 0) {
            return true
        }
        return false
    }, [getMov])

    useEffect(() => {
        formRef.current?.fillForm(new PedidoDadosModelPost())
    }, [])

    return (
        <Box className={classes.container}>
            <Paper className={classes.cardContainer}>
                <Box className={classes.cardContent}>
                    <FormPagerIdentificador
                        loading={carregando}
                        onSubmit={async () => { }}
                        handleSubmit={produtosDaMov ? handleSubmitPedidoComItem : handleSubmitPedidoSemItem}
                        showLoading={carregando}
                        ref={formRef}
                        semIdentificacao={semIdentificacao}
                    />

                    <Box className={classes.buttonContainer}>
                        <Box>
                            <Button
                                fullWidth
                                color='primary'
                                variant='outlined'
                                onClick={() => {
                                    setSemIdentificacao(true)
                                    setTimeout(() => { formRef.current?.submitForm() }, 500)
                                }}
                                className={classes.submitButton1}
                            >
                                <CloseIconCircle tipo='BUTTON' fill={theme.palette.primary.main} />
                                Não Identificar
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                fullWidth
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                    formRef.current?.submitForm()
                                }}
                                className={classes.submitButton2}
                            >
                                <ConfirmarIconCircle tipo='BUTTON_PRIMARY' fill={theme.palette.common.white} />
                                Salvar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box >
    );
};