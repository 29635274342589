import { Grid } from '@material-ui/core';
import { useStyles } from './cart-styles';
import { CarrinhoList } from '../carrinho-list/carrinho-list';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import {
  useCadastros,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { useCarrinho } from 'services/app/hooks/carrinho';
import { memo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useHistory } from 'react-router-dom';

const Cart = () => {
  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { push } = useHistory();
  // PROVIDERS
  const { getPermissaoBoolean } = useSessaoAtual();

  // HOOKS
  const { abrirAdicionarProdutoSubItem, abrirSolicitarPermissao } =
    useCadastros();
  const { alterarQtdProduto, inativarProduto, reativarProduto } = useCarrinho();
  const { getMov } = useMovAtual();

  const checkInactivatePermission = useCallback(
    async (model: MovSimplesProdutoModel, naoAlteraTaxa: boolean = false) => {
      if (!getPermissaoBoolean(530105)) {
        abrirSolicitarPermissao(
          async () => {
            await inativarProduto(model, naoAlteraTaxa);
          },
          530105,
          'inativar o produto'
        );
        return;
      }
      //caso tenha permissão segue o fluxo normalmente
      inativarProduto(model, naoAlteraTaxa);
    },
    [abrirSolicitarPermissao, getPermissaoBoolean, inativarProduto]
  );

  const editProductWithSubItem = useCallback(
    (produtoId: string) => {
      const mov = getMov();
      if (isEmpty(mov) || !mov) {
        showToast('error', 'Não existe uma venda em Andamento.');
        push('/venda-simples/landing');
        return;
      }

      const produto = mov.produtos.find((p) => p.id === produtoId);
      if (isEmpty(produto)) return;

      abrirAdicionarProdutoSubItem(produto, true);
    },
    [abrirAdicionarProdutoSubItem, getMov, push, showToast]
  );

  return (
    <Grid container className={classes.list}>
      <CarrinhoList
        inativarProduto={checkInactivatePermission}
        reativarProduto={reativarProduto}
        alterarQtdeProduto={alterarQtdProduto}
        setProductEdit={editProductWithSubItem}
      />
    </Grid>
  );
};

export default memo(Cart);
