export enum EnumTpPermissaoUsuario {
    Numero = 0,
    Texto = 1,
    SimNao = 2,
    Valor = 3,
    Invisivel = 4,
}

export enum EnumTpPermissaoUsuarioNome {
    TextField = 'TextField_',
    Switch = 'Switch_'
}