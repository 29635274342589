import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePutDocumentoIntegrar() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putManifestoDocumentoIntegrar = useCallback(
        (cnpj: string, chave: string, integrado: boolean) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${cnpj}/Documento/${chave}/integrar?integrado=${integrado ? 'true' : 'false'}`,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putManifestoDocumentoIntegrar,
    };
}