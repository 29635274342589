import * as Yup from "yup";
import { useMemo } from "react";
import { EnumFormCodigo } from "./form-produto-codigo-pre-cadastro";
import { validaAnvisa } from "utils/valida-anvisa";
import { validaEan } from "utils/valida-ean";

interface Props {
  obrigatorio: boolean;
  tipoForm: EnumFormCodigo;
}

export const useFormProdutoCodigoaPreCadastroValidation = ({
  obrigatorio = false,
  tipoForm = EnumFormCodigo.EAN,
}: Props) => {

  const FormProdutoCodigoaPreCadastroYupValidation = useMemo(() => {
    return Yup.object().shape({
      codigo: obrigatorio ? Yup.string().required('Informe o código.')
        .test({
          message: 'Código ANVISA inválido.',
          test: (value) => {
            if (tipoForm === EnumFormCodigo.ANVISA_EAN) {
              return validaAnvisa(value || '')
            }
            return true
          }
        }).test({
          message: 'Código EAN/ANVISA inválido.',
          test: (value) => {
            if (tipoForm === EnumFormCodigo.AMBOS) {
              return validaAnvisa(value || '') || (validaEan(value || '') || false)

            }
            return true
          }
        })
        :
        Yup.string().notRequired().test({
          message: 'Código ANVISA inválido.',
          test: (value) => {
            if (tipoForm === EnumFormCodigo.ANVISA_EAN) {
              return validaAnvisa(value || '')
            }
            return true
          }
        }).test({
          message: 'Código EAN/ANVISA inválido.',
          test: (value) => {
            if (tipoForm === EnumFormCodigo.AMBOS) {
              return validaAnvisa(value || '') || (validaEan(value || '') || false)

            }
            return true
          }
        }),
        codigoEAN: tipoForm === EnumFormCodigo.ANVISA_EAN ? Yup.string().required('Informe código EAN').typeError('Informe código EAN') : Yup.string().notRequired()
    });
  }, [obrigatorio, tipoForm]);

  return {
    FormProdutoCodigoaPreCadastroYupValidation,
  };
};
