import { KeyValueModel } from "model/api";
import { EnumPDVConfigCod } from "model/enums/enum-pdv-config";

export const TooltipPdvConfigMock: KeyValueModel[] = [
   
    new KeyValueModel(EnumPDVConfigCod.ModeloTrabalho, "Apenas Caixa - realizar somente cobrança. Lançador com Fechamento de Venda - realizar pedido e cobrança.Lançador sem Fechamento de Venda -  realizar pedido."),
    new KeyValueModel(EnumPDVConfigCod.ModeloVenda, "Venda Simples - controle manual.    ECF - para transmissão manual dos cupons.    SAT CF-e - para transmissão automática dos cupons.     NFC-e - controle fiscal."),
    new KeyValueModel(EnumPDVConfigCod.SolicitarCliente, "Caso queira incluir um cliente na venda, informe o momento em que o CPF/CNPJ será solicitado."),
    new KeyValueModel(EnumPDVConfigCod.DispararSetorFinalizacaoVenda, "Defina o momento em que o pedido será enviado para o preparo.    Sim - após o pagamento.    Não - após o lançamento."),
    new KeyValueModel(EnumPDVConfigCod.ImpressaoComprovante, "Escolha se os comprovantes serão impressos de forma automática ou manual após o pagamento"),
    new KeyValueModel(EnumPDVConfigCod.MomentoSolicitacaoMesaComanda, "Defina o momento em que será solicitado o número da mesa e comanda para inserir os produtos na venda."),
    new KeyValueModel(EnumPDVConfigCod.TicketConsumacao, "Escolha se deseja ou não utilizar o ticket de consumação. Ao habilitar, será gerado ticket em todas as vendas."),

];
