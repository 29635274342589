import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  btnStatus: {
    fontSize: 15,
    '& svg': {
      width: 20
    },
    height: 30
  }
}));
