import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { TemaPutModel } from "model/api/gestao/cardapio/tema-put-model";
import { VariaveisAmbiente } from "config";

export function usePutTema() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putTema = useCallback((empresaId: string, model: TemaPutModel) =>
        invocarApi({
            url: `/v4/empresa/${empresaId}/tema`,
            method: "PUT",
            enviarTokenUsuario: true,
            data: model,
            baseURL: `${VariaveisAmbiente.apiUrlCardapio}/api`
        }),
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putTema,
    }
}