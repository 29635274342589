import { useCallback } from 'react';
import { ProdutoAtualizarModel } from '../../../../../model/api/gestao/produto/produto/produto-atualizar-model';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutProduto() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const putProduto = useCallback(
    (produto: ProdutoAtualizarModel, empresaId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/produto/${produto.id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(produto),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProduto
  };
}
