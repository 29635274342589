import { useButtonFabMenuStyles } from './button-fab-menu-styles';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { ButtonFabMenuModel } from 'model/app/components/button-fab-menu/button-fab-menu';
import { useThemeQueries } from 'views/theme';

interface ButtonFabMenuProps {
  icon: JSX.Element;
  open: boolean;
  values: ButtonFabMenuModel[];
  size?: 'small' | 'medium' | 'large';
  color?: string;
  colorItens?: string;
  style?: React.CSSProperties;
  onOpen?: () => any;
  onClose?: () => any;
  onClick?: () => any;
  carregando?: boolean;
  id?: string;
}

export const ButtonFabMenu = (props: ButtonFabMenuProps) => {
  const { theme } = useThemeQueries();
  const classes = useButtonFabMenuStyles();
  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial Saurus"
        className={classes.root}
        icon={props.icon}
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onClick={props.onClick}
        color={props.color}
        style={props.style}
        FabProps={{
          style: {
            background: props.open ? theme.palette.primary.light : props.color
          },
          id: props.id
        }}
      >
        {props.values.map((item, index) => {
          return (
            <SpeedDialAction
              icon={item.icon}
              tooltipTitle={
                <span className={classes.tootipTitle}>{item.tittle}</span>
              }
              tooltipOpen
              onClick={item.onClick}
              FabProps={{
                style: {
                  padding: '8px',
                  backgroundColor: props.colorItens
                }
              }}
            />
          );
        })}
      </SpeedDial>
    </>
  );
};
