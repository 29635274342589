import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useDeleteProdutoVariacao() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteProdutoVariacao = useCallback(
    (empresaId: string, produtoId: string, variacaoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    deleteProdutoVariacao,
  };
}
