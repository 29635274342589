import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { useStyles } from './card-opcao-styles';
import { useThemeQueries } from 'views';
import { CardOpcaoProps } from './card-opcao-props';
import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { IconsHelper } from 'views/components/icons-helper';
import { EstrelaFavoritoIcon } from 'views/components/icons/estrela-favorito-icon';
import { EscolhaPadrao } from './components/escolha-padrao';
import { EnumMenu } from 'model';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';
import { stopPropagationWithFeedback } from 'utils/custom-stop-propagation';

const CardOpcao = ({
    model,
    onClick,
    handleFavorite,
    preventCardClick,
    handleClickAddPadrao
}: CardOpcaoProps) => {
    // AUX
    const classes = useStyles();
    const { theme } = useThemeQueries();

    const handleClick = () => {
        onClick(model);
    }

    const handleClickAddPadraoInterno = (rota: EnumInsercaoFavorita, event: any) => {
        handleClickAddPadrao(rota);
        stopPropagationWithFeedback(event);
    }

    const handleClickFavorite = (event: any) => {
        handleFavorite({ ...model, favorito: !model.favorito });
        stopPropagationWithFeedback(event);
    };


    const opcoesParaPadrao = [
        EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO,
        EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO,
        EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO,
        EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS,
        EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR,
        EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY,
        EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY,
    ].includes(model.codigo)

    const parentePadrao = useCallback(() => {
        switch (model.codigo) {
            case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                return EnumInsercaoFavorita.AVULSO;
            case EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO:
                return EnumInsercaoFavorita.CARRINHO;
            case EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO:
                return EnumInsercaoFavorita.CATALOGO;
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS:
                return EnumInsercaoFavorita.ATENDIMENTO;
            case EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR:
                return EnumInsercaoFavorita.LEITOR;
            case EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY:
                return EnumInsercaoFavorita.ATENDIMENTO_DELIVERY;
            case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY:
                return EnumInsercaoFavorita.ACOMPANHAMENTO_DELIVERY;
            default:
                return EnumInsercaoFavorita.CATALOGO
        }
    }, [model.codigo])

    const idFavorito = useCallback
        (() => {
            switch (model.codigo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                    return 'tour-favoritar'
                default:
                    return model.nome
            }
        }, [model.codigo, model.nome])

    const idPadrao = useCallback
        (() => {
            switch (model.codigo) {
                case EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO:
                    return 'tour-padronizar'
                default:
                    return model.nome
            }
        }, [model.codigo, model.nome])

    const isCancelarVenda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR;
    const isFinalizarVenda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA;
    const isRetaguarda = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA;
    const isSincronizar = model.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS;

    return (
        <Button onClick={handleClick} className={classNames(
            classes.content,
            (isCancelarVenda ? classes.red : null),
            (isFinalizarVenda ? classes.green : null),
            (isRetaguarda || isSincronizar ? classes.destaque : null)
        )}>
            <Box className={classes.contentBox} flex flexDirection="row">
                <IconsHelper
                    class={classes.icon}
                    codigoModulo={model.codigo}
                    fill={
                        isCancelarVenda ? theme.palette.error.dark :
                            isFinalizarVenda ? theme.palette.success.dark :
                                isRetaguarda || isSincronizar ? theme.palette.primary.dark :
                                    theme.palette.primary.main}
                    tipo="GERAL"
                />
                <Box className={classes.contentTexto} flex flexDirection="row">
                    <Typography className={classes.text} variant='body1' color="secondary">
                        {model.nome}
                    </Typography>
                    <span className={classes.descricao}>
                        {model.descricao}
                    </span>
                </Box>
            </Box>
            {opcoesParaPadrao && (
                <Tooltip title="Tornar modo de venda padrão">
                    <Button
                        id={idPadrao()}
                        className={classes.btnModo}
                        variant="text"
                        onClick={(event) => handleClickAddPadraoInterno(parentePadrao(), event)}
                    >
                        <EscolhaPadrao parent={parentePadrao()} />
                    </Button>
                </Tooltip>
            )}
            <Tooltip title="Favoritar opção">
                <Button
                    id={idFavorito()}
                    className={classes.btnFavorito}
                    variant="text"
                    onClick={(event) => handleClickFavorite(event)}
                >
                    <EstrelaFavoritoIcon fill={model.favorito ? '#FFBF00' : '#B7B7B7'} />
                </Button>
            </Tooltip>
        </Button>

    );
};

export default memo(CardOpcao);
