interface PropsConteudoLeitura {
  text: string;
}

export const ConteudoLeitura = ({ text }: PropsConteudoLeitura) => {
  function createMarkup() {
    return { __html: text };
  }

  return (
    <div
      style={{
        marginLeft: '8px',
        marginRight: '8px',
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};
