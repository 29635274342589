import { useCallback } from 'react';
import { useApiBase } from '../../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePutProdutoFavorito() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  interface Favorito {
    favorito: boolean;
  }

  const putProdutoFavorito = useCallback(
    (favorito: Favorito, produtoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/favorito`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(favorito),
        enviarTokenUsuario: true
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    putProdutoFavorito
  };
}
