import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './card-vincular-comanda-styles';
import { useThemeQueries } from 'views';
import { CardVincularComandaProps } from './card-vincular-comanda-props';
import { AddIcon } from 'views/components/icons/add-icon';

export const CardVincularComanda = ({ onClick }: CardVincularComandaProps) => {
  const classes = useStyles();
  const { theme } = useThemeQueries();

  return (
    <Grid
      onClick={() => onClick()}
      className={classes.cardContainer}
      style={{
        borderStyle: 'dashed',
        borderColor: theme.palette.grey[300],
      }}
    >
      <div className={classes.cardContent}>
        <AddIcon tipo="INPUT" fill={theme.palette.grey[400]} />
        <Typography
          variant="h6"
          style={{
            fontWeight: '600',
            marginBottom: '8px',
            marginTop: '8px',
            color: theme.palette.grey[400],
            textAlign: 'center',
          }}
        >
          VINCULAR COMANDA
        </Typography>
        <div style={{ width: '70%', borderTop: '2px solid #ECEDED' }} />
      </div>
    </Grid>
  );
};
