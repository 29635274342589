import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { ImpressaoLocalModel } from 'model/api/gestao/impressora/impressao-local-model';

export function usePostEquipamentoImpressaoLocal() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postEquipamentoImpressaoLocal = useCallback(
    (empresaId: string, impressao: ImpressaoLocalModel) =>
      invocarApi({
        url: `/api/v2/empresa/${empresaId}/impressao`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(impressao),
        enviarTokenUsuario: true,
        baseURL: VariaveisAmbiente.apiUrlImpressaoLocal
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postEquipamentoImpressaoLocal,
  };
}
