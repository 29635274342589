import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetPlanos() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getPlanos = useCallback(
        () => invocarApi({
            url: `/${VariaveisAmbiente.gatewayVersion}/planos`,
            method: 'GET',
            enviarTokenUsuario: true
        }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getPlanos,
    };
}
