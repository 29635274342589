import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogPlanoCustomizado } from "views/components/dialog/dialog-plano-customizado/dialog-plano-customizado";

export class PlanoCustomizadoDialogProps {
    constructor(
        public aberto: boolean = false,
        public pathToRedirect: string = ''
    ) { }
}

export const PlanoCustomizadoDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<PlanoCustomizadoDialogProps>(
        new PlanoCustomizadoDialogProps()
    );

    const modalAlterado = useCallback(({ aberto, pathToRedirect }: PlanoCustomizadoDialogProps) => {
        setModalState({
            aberto: aberto,
            pathToRedirect: pathToRedirect
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogPlanoCustomizado, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogPlanoCustomizado, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogPlanoCustomizado openned={modalState.aberto} pathToRedirect={modalState.pathToRedirect}/>
            )}
        </>
    )
}