import { HistoricoVendaHeader } from './components/historico-venda-header/historico-venda-header';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
// import { CardControleCotaVendas } from 'views/components/cards/card-controle-cota-vendas';
// import { isPlanoFiscal } from 'utils/plano-utils';
import { HistoricoVendaTabs } from './components/historico-venda-tabs/historico-venda-tabs';
// import { useSessaoAtual } from 'services/app';

export const HistoricoVendaPage = () => {
  const classes = useDefaultCadastroStyles();
  // const { plano } = useSessaoAtual();
  // const isFiscal = isPlanoFiscal(plano?.plano);

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <HistoricoVendaHeader/>
        </Grid>
        <Grid className={classes.list}>
          <HistoricoVendaTabs />
        </Grid>
        {/* {!isFiscal && (
          <Grid style={{ zIndex: 1 }}>
            <CardControleCotaVendas />
          </Grid>
        )} */}
      </Grid>
    </>
  );
};