import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        gap: theme.spacing(2),
        flexGrow: 1,
        overflowY: 'auto'
    },
    subtitle: {
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    divider: {
        background: theme.palette.secondary.main
    },
}));