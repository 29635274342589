import { KeyValueModel } from "model/api";
import { EnumIndstatusStatus } from "model/enums/enum-indstatus-status";

export const StatusIndstatusMock: KeyValueModel[] = [
    new KeyValueModel(EnumIndstatusStatus.ATIVADO, "Ativado"),
    new KeyValueModel(EnumIndstatusStatus.DESATIVADO, "Desativado"),
    new KeyValueModel(EnumIndstatusStatus.BLOQUEADO, "Bloqueado"),
];

export const StatusIndstatusMockTodos: KeyValueModel[] = [
    new KeyValueModel(-1, "Todos"),
    new KeyValueModel(EnumIndstatusStatus.ATIVADO, "Ativado"),
    new KeyValueModel(EnumIndstatusStatus.DESATIVADO, "Desativado"),
    new KeyValueModel(EnumIndstatusStatus.BLOQUEADO, "Bloqueado"),
];