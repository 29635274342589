import { useCallback } from "react"
import { useCadastros } from "./cadastros"

export const useShowAviso = () => {

    const { abrirDialogAviso, fecharDialogAviso } = useCadastros();

    const showAviso = useCallback(
        (
            mode: 'success' | 'info' | 'error' | 'warning',
            message: string,
            time?: number,
            disableTimeout?: boolean,
            showCloseButton?: boolean,
            permitirFechar: boolean = true,
        ) => {
            if (!time) {
                const words = message.split(' ').length;
                time = 500 * words;
            }
            abrirDialogAviso(mode, message, showCloseButton, permitirFechar);
            if (!disableTimeout) {
                setTimeout(() => {
                    fecharDialogAviso();
                }, time)
            }
        }, [abrirDialogAviso, fecharDialogAviso])

    return {
        showAviso
    }
}