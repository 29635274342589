import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerTextMessage: {
        padding: '8px',
        background: theme.palette.grey[100],
        borderRadius: '5px',
    },
    textMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    }
}))