import {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { MedicamentoModel } from 'model/api/gestao/medicamento/medicamento-model';
import { AutocompleteMarcas } from 'views/components/controles/autocompletes/autocomplete-marcas/autocomplete-marcas';
import { guidEmpty } from 'utils/guid-empty';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import { useFormMedicamentoProdutoValidation } from './form-medicamento-produto-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useMarca } from 'services/app/hooks/marca';


export interface FormMedicamentoProdutoProps
    extends DefaultFormProps<MedicamentoModel> {
    setCarregandoExterno(carregando: boolean): void;
    valoresEncontrados: boolean;
}

export const FormMedicamentoProduto = forwardRef<
    DefaultFormRefs<MedicamentoModel>,
    FormMedicamentoProdutoProps
>(
    (
        {
            loading,
            setCarregandoExterno,
            valoresEncontrados = false,
            ...props
        }: FormMedicamentoProdutoProps,
        ref,
    ) => {
        const utilClasses = makeUtilClasses();
        const refInputNome = useRef<HTMLInputElement>(null);
        const { isMobile } = useThemeQueries();
        const { FormMedicamentoProdutoYupValidation } = useFormMedicamentoProdutoValidation();
        const infoLab = useRef<MarcaModel>();
        const { addNovaMarca, carregando } = useMarca();

        const {
            handleSubmit,
            control,
            formState: { errors },
            setValue,
            reset,
        } = useForm<MedicamentoModel>({
            defaultValues: { ...new MedicamentoModel() },
            criteriaMode: 'all',
            mode: 'onChange',
            resolver: yupResolver(FormMedicamentoProdutoYupValidation)
        });

        const onSubmit = (values: MedicamentoModel) => {
            const model = picker<MedicamentoModel>(
                values,
                new MedicamentoModel(),
            );

            props.onSubmit(model);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(onSubmit)();
            },
            resetForm: () => {
                if (!isMobile) refInputNome.current?.focus();
                reset();
            },
            fillForm: (model: MedicamentoModel) => {
                if (model.laboratorioId && model.laboratorioNome) {
                    const marca = new MarcaModel('', '', model.laboratorioNome, model.laboratorioDocumento);
                    infoLab.current = marca;
                }
                reset({ ...model });
                setTimeout(() => {
                    if (!isMobile) refInputNome.current?.focus();
                }, 500);
            },
        }));

        const cadastrarNovoLaboratorio = async (val: string) => {
            const ret = await addNovaMarca(new MarcaModel(guidEmpty(), '', val));
            if(ret){
                setValue('laboratorioId', ret.id)
            }
        }

        return (
            <>
                <div className={utilClasses.formContainer}>
                    {(loading && props.showLoading) || carregando ? (
                        <div className={utilClasses.controlLoading}>
                            <CircularLoading tipo="FULLSIZED" />
                        </div>
                    ) : null}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={loading ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="nome"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label={`Nome do Medicamento`}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={`Informe o nome do Medicamento`}
                                            error={Boolean(errors.nome && errors.nome.message)}
                                            helperText={
                                                errors.nome
                                                    ? errors.nome?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="descritivo"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label={`Descrição do Medicamento`}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={`Informe a descrição do Medicamento`}
                                            error={Boolean(errors.descritivo && errors.descritivo.message)}
                                            helperText={
                                                errors.descritivo
                                                    ? errors.descritivo?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="composicao"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label='Composição/Princípio Ativo'
                                            readOnly={valoresEncontrados}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder='Ex: Sulfato de Magnésio'
                                            error={Boolean(errors.composicao && errors.composicao.message)}
                                            helperText={
                                                errors.composicao
                                                    ? errors.composicao?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="laboratorioId"
                                    control={control}
                                    render={({ field }) => (
                                        <AutocompleteMarcas
                                            disabled={loading}
                                            label={'Laboratório'}
                                            permiteAdicionar
                                            readOnly={valoresEncontrados}
                                            error={Boolean(errors.laboratorioId && errors.laboratorioId.message)}
                                            {...field}
                                            onChange={(e) => {
                                                if (e.isNewVal) {
                                                    if (e.isString) {
                                                        cadastrarNovoLaboratorio(e.value)
                                                    }
                                                    return
                                                }
                                                setValue('laboratorioId', e.value.id);
                                                setValue('laboratorioNome', e.value.nome);
                                                setValue('laboratorioDocumento', e.value.cnpjFab)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="codigoEan"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            fullWidth
                                            variant="outlined"
                                            label='Código de Barras'
                                            limite={60}
                                            readOnly={valoresEncontrados}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.codigoEan && errors.codigoEan.message)}
                                            helperText={
                                                errors.codigoEan
                                                    ? errors.codigoEan?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="tipoMedicamento"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            disabled={loading || valoresEncontrados}
                                            conteudo={TipoMedicamentoMock}
                                            fullWidth
                                            variant="outlined"
                                            label='Tipo do Medicamento'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.tipoMedicamento && errors.tipoMedicamento.message)}
                                            helperText={
                                                errors.tipoMedicamento
                                                    ? errors.tipoMedicamento?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="classeTerapeutica"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            readOnly={valoresEncontrados}
                                            fullWidth
                                            variant="outlined"
                                            limite={50}
                                            label='Classe Terapêutica'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.classeTerapeutica && errors.classeTerapeutica.message)}
                                            helperText={
                                                errors.classeTerapeutica
                                                    ? errors.classeTerapeutica?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="tarja"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            disabled={loading || valoresEncontrados}
                                            conteudo={TarjaMedicamentoMock}
                                            fullWidth
                                            variant="outlined"
                                            label='Tarja'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.tarja && errors.tarja.message)}
                                            helperText={
                                                errors.tarja
                                                    ? errors.tarja?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="codigoAnvisa"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            fullWidth
                                            readOnly
                                            variant="outlined"
                                            label='Código ANVISA'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.codigoAnvisa && errors.codigoAnvisa.message)}
                                            helperText={
                                                errors.codigoAnvisa
                                                    ? errors.codigoAnvisa?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="produtoReferencia"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            disabled={loading}
                                            readOnly={valoresEncontrados}
                                            fullWidth
                                            variant="outlined"
                                            label='Produto Referência'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={Boolean(errors.produtoReferencia && errors.produtoReferencia.message)}
                                            helperText={
                                                errors.produtoReferencia
                                                    ? errors.produtoReferencia?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </div>
            </>
        );
    },
);
