import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from '../../../../../theme/util-styles';
import { useStyles } from './accordion-webhook-styles';
import classNames from 'classnames';
import { CompartilhamentoDadosWebHookModel } from 'model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model';
import { useGetCompartilhamentoDadosWebHook, usePutCompartilhamentoDadosWebHook } from 'data/api/gestao/compartilhamento-dados-webhook';
import { CopiarIcon, EditarIcon, LixoIcon, NovoIcon } from 'views/components/icons';
import { CompartilhamentoDeDadosMock } from 'data/mocks/compartilhamento-de-dados-mock';
import { isEmpty } from 'lodash';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { WebhookIcon } from 'views/components/icons/webhook-icon';
import { useConfirm } from 'material-ui-confirm';
import { EnumCompartilhamentoDeDados } from 'model/enums/enum-compartilhamento-dados';

export const AccordionWebhook = () => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);
  const [dadosWebHook, setDadosWebHook] = useState<CompartilhamentoDadosWebHookModel>(new CompartilhamentoDadosWebHookModel());
  // HOOKS
  const { getPessoa } = useSessaoAtual();
  const confirm = useConfirm()
  // CHAMADAS API
  const { getCompartilhamentoDadosWebHook } = useGetCompartilhamentoDadosWebHook()
  const { putCompartilhamentoDadosWebHook } = usePutCompartilhamentoDadosWebHook()
  const { abrirConfigWebhook } = useCadastros()
  // AUX
  const { showToast } = useToastSaurus();
  const classesModal = useModalStyles();
  const { theme } = useThemeQueries();
  const classes = useStyles();
  const { addHandler, removeHandler } = useEventTools();

  const copiarUrl = (valor: string, event: any) => {
    event.stopPropagation();

    navigator.clipboard.writeText(valor)
    showToast('success', 'Webhook copiado.')
  }

  const deletarWebhook = (event: any) => {

    event.stopPropagation();

    const dadosDelete: CompartilhamentoDadosWebHookModel = { webhook: '', tipoNotificacaoWebhook: EnumCompartilhamentoDeDados.TODOS }

    confirm({
      cancellationText: "Não, desejo manter.",
      confirmationText: "Sim, apagar.",
      title: 'Tem certeza que deseja apagar o webhook cadastrado?',
      description: 'Caso apague o webhook cadastrado será necessário o cadastro de um novo para continuar recebendo as notificações de compartilhamento.'
    })
      .then(async () => {
        try {
          const res = await putCompartilhamentoDadosWebHook(dadosDelete)
          if (res.erro) throw res.erro;
          showToast('success', 'Webhook deletado')
          chamarCadWebhook(false)
        } catch (err: any) {
          showToast('error', err.message)
        }
      })
  }

  const getCompartilhamentoDadosWebHookWrapper = useCallback(async () => {
    try {
      const res = await getCompartilhamentoDadosWebHook(getPessoa().pessoa.contratoId);
      if (res.erro) throw res.erro;

      setDadosWebHook(res.resultado?.data as CompartilhamentoDadosWebHookModel)


    } catch (err: any) {
      showToast('error', err.message)
    }

  }, [getCompartilhamentoDadosWebHook, getPessoa, showToast]);


  useEffect(() => {
    if (openAccordion) {
      getCompartilhamentoDadosWebHookWrapper()
    }
  }, [getCompartilhamentoDadosWebHookWrapper, openAccordion])

  const chamarCadWebhook = useCallback(({ aberto }: any) => {
    if (!aberto) {
      getCompartilhamentoDadosWebHookWrapper();
    }
  }, [getCompartilhamentoDadosWebHookWrapper])

  useEffect(() => {

    addHandler(AppEventEnum.AdicionarWebhookDialog, chamarCadWebhook)

    return () => {
      removeHandler(AppEventEnum.AdicionarWebhookDialog, chamarCadWebhook)
    }
  }, [addHandler, chamarCadWebhook, removeHandler])



  return (
    <AccordionSaurus
      labelPrimary={'Webhook'}
      tituloChildren={
        <WebhookIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(prev => !prev)}
    >
      <div className={classNames(classesModal.root)}>
        <div className={classesModal.content}>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>

              <Grid container className={classes.containerCards} onClick={() => abrirConfigWebhook(dadosWebHook)}>
                {isEmpty(dadosWebHook.webhook) ? (
                  <>
                    <Grid item xs={12} className={classes.gridBtn}>
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        className={classes.btnDashed}
                        onClick={() => abrirConfigWebhook(dadosWebHook)}
                      >
                        <NovoIcon tipo="BUTTON" fill="#A8A8A8" />
                        Novo Webhook
                      </Button>
                    </Grid>
                    <Grid style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: 16
                    }}>
                      <Typography style={{ color: "#808080", textAlign: 'center' }}>
                        Nenhum gerenciamento de webhook foi encontrado. Clique no botão acima e adicione.
                      </Typography>
                    </Grid>
                  </>
                ) :
                  <Button className={classes.botaoPrincipal} fullWidth variant='outlined' /*onClick={() => abrirConfigWebhook()}*/>
                    <Grid className={classes.card} item sm={12} >
                      <Grid className={classes.legenda} xs={12} sm={3}>
                        <Typography className={classes.legendaText} variant='caption'>
                          Módulo Compartilhado
                        </Typography>
                        <Typography variant='body1' className={classes.nome}>
                          {CompartilhamentoDeDadosMock.find(item => item.Key === dadosWebHook.tipoNotificacaoWebhook)?.Value}
                        </Typography>
                      </Grid>
                      <Grid className={classes.legenda} xs={12} sm={6}>
                        <Typography className={classes.legendaText} variant='caption'>
                          URL do Webhook
                        </Typography>
                        <Grid className={classes.link} xs={12} >
                          <Typography variant='body1' className={classes.nome}>
                            {dadosWebHook.webhook}
                          </Typography>
                        </Grid>

                      </Grid>


                      <Grid sm={3} className={classes.botoesEdicao}>
                        <Grid className={classes.botaoCopy}>
                          <Button onClick={(event) => { copiarUrl(dadosWebHook.webhook, event) }} >
                            <CopiarIcon tipo='BUTTON' fill={theme.palette.text.primary} />
                          </Button>
                        </Grid>
                        <Grid className={classes.botaoEdicao} >
                          <Button onClick={(event) => { deletarWebhook(event) }}>

                            <LixoIcon tipo='BUTTON_PRIMARY' fill={theme.palette.text.primary} />
                          </Button>
                        </Grid>
                        <Grid className={classes.botaoEdicao} >
                          <Button>
                            <EditarIcon tipo='BUTTON_PRIMARY' fill={theme.palette.text.primary} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Button>
                }
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

    </AccordionSaurus >
  );
};
