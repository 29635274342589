import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutPedidoDadosMesa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPedidoDadosMesa = useCallback(
        (empresaId: string, pedidoId: string, mesaId: string) => {
            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao-dados/${pedidoId}/alterar-mesa/${mesaId}`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putPedidoDadosMesa,
    };
}
