import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    background: {
        textAlign: 'center',
        width: '100%',
        background: theme.palette.primary.main,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mensagem: {
        color: '#FFF',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center'
    }
}));
