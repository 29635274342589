import { Grid } from "@material-ui/core";
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { CardPagamentosDetalheVenda } from "views/components/cards/card-pagamentos-detalhe-venda/card-pagementos-detalhe-venda";
import { useThemeQueries } from "views/theme"

interface AccordionPagamentosProps {
    model: VendaCompletaModel;
}

export const AccordionPagamentos = (props: AccordionPagamentosProps) => {

    const { theme } = useThemeQueries();

    return (
        <>
            <AccordionSaurus
                labelPrimary={'Pagamentos'}
                showDivider={true}
                colorDivider={theme.palette.grey[300]}
                style={{ boxShadow: theme.shadows[1] }}
            >
                <Grid container spacing={2} style={{
                    maxHeight: '230px',
                    overflowY: 'auto',
                }}>
                    {props.model.infMov.pag.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <CardPagamentosDetalheVenda 
                                        model={item}
                                        index={index}
                                    />
                                </Grid>
                            </>
                        )
                    })}

                </Grid>
            </AccordionSaurus>
        </>
    )
}