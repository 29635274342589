import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import { CardToastProdutoProps } from "./card-toast-produto-props";
import { useStyles } from "./card-toast-produto-styles"
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from "utils/to-decimal";
import { useThemeQueries } from "views/theme";

export const CardToastProduto = ({ model }: CardToastProdutoProps) => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries()

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.containerAvatar}>
                    {model.imgUrl?.length > 0 && (
                        <img
                            src={model.imgUrl}
                            className={classes.cardImg}
                            alt={model.xProd}
                        />
                    )}
                    {isEmpty(model.imgUrl) && (
                        <img
                            src={SemImagem}
                            className={classes.cardImg}
                            alt={model.xProd}
                        />
                    )}
                </Grid>
                <Grid className={classes.container1}>
                    <Grid className={classes.qtde}>
                        <b>{toDecimalString(model.qCom, 0)}</b>x <b>{model.cProd}</b>
                    </Grid>
                    <Grid className={classes.desc}>
                        {
                            model.xProd.length >= 35
                                ? isMobile
                                    ? model.xProd.substring(0, 35) + '...'
                                    : model.xProd.substring(0, 70) + '...'
                                : model.xProd.substring(0, 35)
                        }
                    </Grid>
                </Grid>
                <Grid className={classes.valor}>
                    R$ {toDecimalString(model.vFinal, 2)}
                </Grid>
            </Grid>
        </>
    );
};
