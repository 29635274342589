import * as Yup from "yup";
import { useValidationYupNcmCodigo } from "views/components/form-validations/yup/ncm-codigo";
import { useValidationYupCest } from "../../../form-validations/yup/cest";
export const useFormNcmEditValidation = () => {
  const { ncmCodigoYup } = useValidationYupNcmCodigo();
  const { cestYup } = useValidationYupCest();
  const FormNcmEditYupValidation = Yup.object().shape({
    codigo: ncmCodigoYup(),
    cest: cestYup(),
  });

  return {
    FormNcmEditYupValidation,
  };
};
