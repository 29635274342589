import { Box, Button } from "@material-ui/core"
import { FiltroIcon, OkIcon, VoltarIcon } from "views/components/icons";
import { useCallback, useState } from "react";
import { DefaultModal, ModalHeader } from "views/components/modals/components";
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header";
import { useModalStyles } from "views/components/modals/utils/modal-styles";
import classNames from "classnames";
import { useStyles } from './impostos-filtro-styles'
import { CardFiltro } from "views/components/cards/components/card-filtro/card-filtro";
import { RadioList } from "views/components/controles/radio-list/radio-list";
import { BorrachaIcon } from "views/components/icons/borracha-icon";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";
import { useHistory } from "react-router-dom";
import { SituacaoGeralMockTodos } from "data/mocks/situacao-geral-mock";
import { NcmListSearchProps } from "views/pages/private/cadastros/ncm/components/ncm-list/ncm-list-search-props";
import { ImpostoListSearchProps } from "views/pages/private/cadastros/impostos/components/imposto-list/imposto-list-search-props";

interface ITelaState {
    status: boolean;
}

const retornaLabel = (valor: number) => {
    switch (valor) {
        case 1:
            return 'Habilitado'
        case 0:
            return 'Desabilitado'
        default:
            return 'Todos'
    }
}

interface ImpostosFiltroModalProps {
    openned: boolean;
    filtros: ImpostoListSearchProps;
}
export const ImpostosFiltroModal = ({
    openned,
    filtros
}: ImpostosFiltroModalProps) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const { fecharImpostosFiltroModal } = useFiltrosModais();
    const history = useHistory();

    const [telas, setTelas] = useState<ITelaState>({
        status: false
    })
    const [localProps, setLocalProps] = useState<NcmListSearchProps>(filtros)

    const voltarBtn = useCallback(() => {
        if (Object.values(telas).some(x => x)) {
            setTelas(() => ({
                status: false
            }))
            return
        }
        fecharImpostosFiltroModal(false)
    }, [fecharImpostosFiltroModal, telas])

    const onSubmit = useCallback((localProps: NcmListSearchProps) => {
        const searchs: Array<string | null> = [
            `status=${localProps.status}`,
            localProps.termo.length > 0 ? `termo=${localProps.termo}` : null,
        ]

        let query = ''

        searchs
            .filter(x => x)
            .forEach((x, i) => i === 0 ? query += `?${x}` : query += `&${x}`)

        history.push({
            pathname: '/impostos',
            search: query
        })
        fecharImpostosFiltroModal(true)
    }, [fecharImpostosFiltroModal, history])

    return (

        <DefaultModal
            minWidth="400px"
            open={openned}
            variant={"temporary"}
            anchor="right"
        >
            <Box className={modalClasses.root}>
                <ModalHeader
                    title={"Filtrar"}
                    leftArea={
                        <ButtonModalHeader
                            tooltip="Voltar"
                            icon={<VoltarIcon tipo="MODAL_HEADER" />}
                            onClick={voltarBtn}
                        />
                    }
                />
                <>
                    <Box className={classNames(classes.container)}>
                        <CardFiltro<NcmListSearchProps, ITelaState>
                            icon={<FiltroIcon />}
                            nome="Situação do Imposto"
                            propName="status"
                            setState={setLocalProps}
                            setTela={setTelas}
                            telaName="status"
                            value={retornaLabel(localProps.status)}
                            valorPadrao={1}
                            valorAtual={localProps.status}
                        />
                        <Box className={classes.buttonContainer}>
                            <Button variant='outlined' color='primary' fullWidth onClick={() => onSubmit({
                                status: 1,
                                termo: '',
                            })}>
                                <BorrachaIcon tipo='BUTTON' />
                                Limpar Filtros
                            </Button>
                            <Button variant='contained' color='primary' fullWidth onClick={() => onSubmit(localProps)}>
                                <OkIcon tipo='BUTTON_PRIMARY' />
                                Buscar
                            </Button>
                        </Box>
                        {telas.status && (
                            <RadioList
                                handleSubmit={async (status) => {
                                    setLocalProps(prev => ({
                                        ...prev,
                                        status: status
                                    }))
                                    setTelas(prev => ({
                                        ...prev,
                                        status: false
                                    }))
                                }}
                                selecionado={localProps.status}
                                mock={SituacaoGeralMockTodos}
                            />
                        )}
                    </Box>
                </>
            </Box>
        </DefaultModal >
    )
}