import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-suporte-styles';
import { CircularLoading } from 'views';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback } from 'react';
import { WhatsappIcon } from 'views/components/icons/whatsapp-icon';
import { QrCodeIcon, VoltarIcon } from 'views/components/icons';
import { AccordionSuporteRemoto } from 'views/pages/private/configuracoes/components/accordion-suporte-remoto/accordion-suporte-remoto';
import { isPlanoSuporteWhatsApp } from 'utils/plano-utils';
import { useSessaoAtual } from 'services/app';
import { OfertaIcon } from 'views/components/icons/oferta-icon';
import { useHistory } from 'react-router-dom';

interface DialogSuporteProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  text: string;
}

export const DialogSuporte = ({
  openned,
  closeModal,
  loading,
  text,
}: DialogSuporteProps) => {

  //AUX
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()

  //PROVIDERS
  const { plano } = useSessaoAtual();

  //STATES
  const [abrirQrCode, setAbrirQrCode] = React.useState(false);

  const abrirWhatsapp = useCallback(() => {
    if (!isPlanoSuporteWhatsApp(plano?.plano)) {
      const redirectTo = '/alterar-plano';
      history.push(redirectTo)
      closeModal();
    } else {
      window.open(`${text}`, '_blank');
    }
  }, [closeModal, history, plano?.plano, text])

  const abrirQrCodeWhatsapp = useCallback(() => {
    if (!isPlanoSuporteWhatsApp(plano?.plano)) {
      const redirectTo = '/alterar-plano';
      history.push(redirectTo)
      closeModal();
    } else {
      setAbrirQrCode(true);
    }
  }, [closeModal, history, plano?.plano])

  return (
    <DialogSaurus
      icone={
        <WhatsappIcon tipo="BUTTON" fill={`${theme.palette.primary.main}`} />
      }
      colorTitulo={`${theme.palette.primary.main}`}
      centralizarTitulo={true}
      aberto={openned || false}
      titulo="Precisa de Ajuda?"
      tamanho="xs"
      bottomArea={
        <>
          {closeModal && (
            <div className={classes.buttomBottom}>
              <Button
                disabled={loading}
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => {
                  closeModal();
                  setAbrirQrCode(false);
                }}
              >
                <VoltarIcon tipo='BUTTON' />
                Voltar
              </Button>
            </div>
          )}
        </>
      }
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box sx={{ flex: 1, justifyContent: 'center' }}>
              <Typography className={classes.label}>
                Nosso suporte está sempre pronto para te ajudar!
              </Typography>
              <Typography className={classes.label}>
                Para realizar o suporte, você precisa ler este qrCode em seu
                celular para iniciar um atendimento via Whatsapp. É mais prático
                e rápido!
              </Typography>

              {VariaveisAmbiente.paymentDevice ===
                EnumDeviceType.MERCADOPAGO && (
                  <div className={classes.qrcode}>
                    <QRCodeSVG value={`${text}`} size={180} />
                    <Typography variant="caption" className={classes.qrText}>
                      Leia o QRCode para iniciar <br /> um atendimento.
                    </Typography>
                  </div>
                )}

              {(VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA) &&
                abrirQrCode && (
                  <div className={classes.qrcode}>
                    <QRCodeSVG value={text} size={180} />
                    <Typography variant="caption" className={classes.qrText}>
                      Leia o QRCode para iniciar <br /> um atendimento.
                    </Typography>
                  </div>
                )}
            </Box>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 16, marginBottom: 8 }}
            >
              {(VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR ||
                VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA) &&
                !abrirQrCode && (
                  <>
                    <Grid item xs={12}>
                      <Button
                        disabled={loading}
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={abrirQrCodeWhatsapp}
                      >
                        <QrCodeIcon tipo='BUTTON_PRIMARY' />
                        Abrir QrCode
                        {!isPlanoSuporteWhatsApp(plano?.plano) && (
                          <OfertaIcon class={classes.iconOferta} />
                        )}
                      </Button>
                    </Grid>

                    {VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR && <Grid item xs={12}>
                      <Button
                        disabled={loading}
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={abrirWhatsapp}
                      >
                        <WhatsappIcon tipo='BUTTON_PRIMARY' fill={theme.palette.background.default} />
                        Abrir WhatsApp
                        {!isPlanoSuporteWhatsApp(plano?.plano) && (
                          <OfertaIcon class={classes.iconOferta} />
                        )}
                      </Button>
                    </Grid>}
                  </>
                )}

              <Grid item xs={12}>
                <AccordionSuporteRemoto />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};