import { useCallback, FocusEvent, useState, useEffect } from 'react';
import { ProdutoFiscalModel } from 'model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { useGetProdutosFiscais } from 'data/api/gestao/produto-fiscal/get-produtos-fiscais';
import { TouchoneDBPrimary } from 'database/touchone-database';

export interface AutocompleteGrupoImpostosProps {
  label?: string;
  loadingExterno?: boolean;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  placeholder?: string;
  allowSubmit?: boolean;
  disabled?: boolean;
  dentroDaVenda?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
}

export const AutocompleteGrupoImpostos = ({
  onChange,
  loadingExterno,
  dentroDaVenda,
  ...props
}: AutocompleteGrupoImpostosProps) => {
  const [impostoState, setImpostoState] = useState<Array<ProdutoFiscalModel>>(
    new Array<ProdutoFiscalModel>(),
  );
  const { getProdutosFiscais, carregando: carregandoImpostos } =
    useGetProdutosFiscais();

  useEffect(() => {
    (async () => {
      let impostos: Array<ProdutoFiscalModel> = new Array<ProdutoFiscalModel>();
      if (dentroDaVenda) {
        const ret = await TouchoneDBPrimary.impostos.toArray();
        impostos = ret as Array<ProdutoFiscalModel>;
      } else {
        const ret = await getProdutosFiscais('Ativo=true', '', 1, 100);
        if (ret.erro) throw ret.erro;

        if (ret.resultado?.data?.list?.length > 0) {
          impostos = ret.resultado?.data?.list as Array<ProdutoFiscalModel>;
        }
      }

      setImpostoState(impostos);
    })();
  }, [dentroDaVenda, getProdutosFiscais]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      name={props.name}
      loading={carregandoImpostos}
      onChange={onChangeWrapper}
      opcoes={impostoState}
      optionLabelKey="descricao"
      optionValueKey="descricao"
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
