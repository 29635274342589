import { isEmpty } from "lodash";
import { DefaultModal } from '../../components/default-modal/default-modal';
import { ModalProps } from '../../utils/modal-props';
import { PessoaEdicao } from "./pessoa-edicao/pessoa-edicao";
import { PessoaCadastroSteps } from "./pessoa-cadastro-steps/pessoa-cadastro-steps";

export const PessoaModal = (props: ModalProps) => {
  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (<PessoaCadastroSteps />)}
      {props.openned && !isEmpty(props.id) && (<PessoaEdicao id={props.id} />)}
    </DefaultModal>
  );
};
