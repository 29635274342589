import { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { CompartilhamentoDadosWebHookModel } from 'model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model';
import { usePutCompartilhamentoDadosWebHook } from 'data/api/gestao/compartilhamento-dados-webhook';
import { FormWebhook } from 'views/components/form/webhook/form-webhook/form-webhook';

export interface DialogWebhookProps {
  aberto: boolean;
  modelo: CompartilhamentoDadosWebHookModel;
}
export const DialogWebhook = ({
  ...props
}: DialogWebhookProps) => {

  const { fecharConfigWebhook } = useCadastros()
  const { carregando } = useMovProd()
  const { putCompartilhamentoDadosWebHook } = usePutCompartilhamentoDadosWebHook()
  const { showToast } = useToastSaurus();

  const putHook = useCallback(
    async (modelo: CompartilhamentoDadosWebHookModel) => {

      try {
        const ret = await putCompartilhamentoDadosWebHook(modelo)
        if (ret.erro) throw ret.erro;
        showToast('success', 'Webhook adicionado com sucesso!');
        fecharConfigWebhook()
        return ret

      } catch (e: any) {
        showToast('error', e.message);
      }
    }, [fecharConfigWebhook, putCompartilhamentoDadosWebHook, showToast])

  const handleSubmit = useCallback(async (model: CompartilhamentoDadosWebHookModel) => {
    return putHook(model)
  }, [putHook])

  return (
    <DialogSaurus
      aberto={props.aberto}
      carregando={carregando}
      tamanho="sm"
    >

      <Grid xs={12}>
        <FormWebhook
          onSubmit={handleSubmit}
          loading={carregando}
          showLoading={false}
          model={props.modelo}
        ></FormWebhook>
      </Grid>

    </DialogSaurus >
  );
};
