import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const PermissaoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 25 0 C 22.546875 0 20.476563 1.355469 17.40625 2.9375 C 14.335938 4.519531 10.214844 6.429688 3.78125 7.84375 L 3 8.03125 L 3 8.8125 C 3 24.132813 8.371094 34.371094 13.75 40.71875 C 19.128906 47.066406 24.59375 49.59375 24.59375 49.59375 L 25.03125 49.8125 L 25.46875 49.59375 C 25.46875 49.59375 47 38.46875 47 8.8125 L 47 8.03125 L 46.21875 7.84375 C 39.785156 6.429688 35.664063 4.519531 32.59375 2.9375 C 29.523438 1.355469 27.453125 0 25 0 Z M 25 2 C 26.644531 2 28.550781 3.105469 31.6875 4.71875 C 34.667969 6.25 38.859375 8.128906 44.9375 9.5625 C 44.570313 36.679688 26.253906 46.851563 24.96875 47.53125 C 24.347656 47.230469 20.019531 45.066406 15.25 39.4375 C 10.222656 33.503906 5.246094 23.960938 5.0625 9.5625 C 11.140625 8.128906 15.332031 6.25 18.3125 4.71875 C 21.449219 3.105469 23.355469 2 25 2 Z M 32.6875 14.625 L 32.15625 15.46875 L 24.03125 28.09375 L 18.65625 23.25 L 17.9375 22.59375 L 16.59375 24.0625 L 17.34375 24.75 L 23.53125 30.375 L 24.40625 31.15625 L 25.0625 30.15625 L 33.84375 16.53125 L 34.375 15.6875 Z" />
            </DefaultIcon>
        </>
    );
};

