
import { EnumBalcaoSalao } from "model/enums/enum-balcao-salao";
import { EnumStatusSalao } from "model/enums/enum-status-salao";
import { guidEmpty } from "utils/guid-empty";

export class StatusSaloesModel {
    constructor(
        public descricao: string = 'Ativo',
        public codigo: EnumStatusSalao = EnumStatusSalao.ATIVO,
    ) { }
}

export class BalcaoSaloesModel {
    constructor(
        public descricao: string = 'Utiliza',
        public codigo: EnumBalcaoSalao = EnumBalcaoSalao.UTILIZA,
    ) { }
}
export class SaloesModel {
    constructor(
        public id: string | null = guidEmpty(),
        public descricao: string = 'Todos os Salões',
        public status: StatusSaloesModel = new StatusSaloesModel(),
        public balcao: BalcaoSaloesModel = new BalcaoSaloesModel(),
    ) { }
}

export class NewSalaoModel {
    constructor(
        public descricao: string = '',
        public status: EnumStatusSalao = EnumStatusSalao.ATIVO,
        public balcao: EnumBalcaoSalao = EnumBalcaoSalao.UTILIZA,
    ) { }
}
