import { useCallback } from "react";
import * as Yup from "yup";


export const useValidationYupNcmCodigo = () => {
  const ncmCodigoYup = useCallback(() => {
    return Yup.string()
      .required("O Ncm é obrigatório.")
    //   .test("codigo", "Favor selecione o NCM", (value: any) => {
    //     let valueNumber = stringNumeros(value);
    //     return valueNumber.length === 8;
    //   });
  }, []);

  return {
    ncmCodigoYup,
  };
};
