import { isEmpty } from "lodash";
import { ProdutoResumidoModel } from "model/api/gestao/produto/produto/produto-resumido-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogSelecaoProdutosCodigos, DialogSelecaoProdutosCodigosProps } from "views/components/dialog/dialog-selecao-produtos-codigos/dialog-selecao-produtos-codigos";

export const SelecaoProdutosCodigosDialog = () => {
    const { addHandler, removeHandler } = useEventTools();

    consoleDev('SelecaoProdutosCodigosDialog');

    const [modalState, setModalState] = useState<DialogSelecaoProdutosCodigosProps>({
        aberto: false,
        prod: new ProdutoResumidoModel(),
        detailed: false
    });

    const modalAlterado = useCallback(({ aberto, prod, detailed }: any) => {
        setModalState({
            prod: prod,
            aberto: aberto,
            detailed
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogSelecaoProdutosCodigos, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogSelecaoProdutosCodigos, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {!isEmpty(modalState.prod) && modalState.aberto && (
                <DialogSelecaoProdutosCodigos
                    aberto={modalState.aberto}
                    prod={modalState.prod}
                    detailed={modalState.detailed}
                />
            )}
        </>
    )
}