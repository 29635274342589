import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { CardDashboardAdminChartVendasProps } from '../card-dashboard-admin-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';
import { useState } from 'react';
import { CategoriaIcon, DinheiroIcon, ProdutoIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';

export const CardDashboardAdminChartLucros = ({
  model,
  carregando,
  onClick
}: CardDashboardAdminChartVendasProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();
  const { theme, isMobile } = useThemeQueries()
  const [isMore, setIsMore] = useState<boolean>(false)

  let modUnicos = new Map(
    model.map((obj) => [obj.emitDoc, obj]),
  );

  const modsUnicosList = Array.from(modUnicos.values());

  const vendasSerelizados = modsUnicosList.map((vendas) => {
    return {
      ...vendas,
      vTotal: 0,
      qVendas: 0,
      vCustoTotal: 0,
      qItensTotal: 0
    };
  });

  vendasSerelizados.map((item) => {
    model.map((i) => {
      if (i.emitDoc === item.emitDoc) {
        let novaQuantidade = (item.vTotal += i.vVenda);
        let novaQTDE = (item.qVendas += i.qCom)
        let novoCusto = item.vCustoTotal += i.vCusto
        let novoQItens = item.qItensTotal += i.qItens
        return {
          ...item,
          vTotal: novaQuantidade,
          qVendas: novaQTDE,
          vCustoTotal: novoCusto,
          qItensTotal: novoQItens
        };
      }
      return i;
    });
    return item;
  });

  const totalCusto = model.reduce((a, b) => a + b.vCusto, 0)
  const totalVendas = model.reduce((a, b) => a + b.vVenda, 0);
  const totalLucro = totalVendas - totalCusto

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Maiores vendedores por lucro
            </Typography>
          </div>
          <div style={{ padding: '0 10px', width: '100%' }}>
            <div className={utilClasses.divider} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 20,
            }}
          >
            {model.map(item => item.qCom)[0] === 0 || model.length === 0 ? (
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                Não há dados para exibir
              </Typography>
            ) : (
              <>
                {vendasSerelizados.sort((a, b) =>
                  parseInt((b.vTotal - b.vCustoTotal).toString()) - parseInt((a.vTotal - a.vCustoTotal).toString())).filter((item, index) => {
                    if (!isMore) return index < 10

                    return item
                  }).map((item, index) => {
                    let dinheiro = item.vTotal - item.vCustoTotal
                    let vendas = item.qVendas
                    const venda = parseInt(dinheiro.toString());
                    const porcentagem = (
                      (venda * 100) /
                      parseInt(totalLucro.toString())
                    ).toFixed(0);

                    return (
                      <Paper
                        key={index}
                        className={classNames('card')}
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          position: 'relative',
                          overflow: 'hidden'
                        }}
                      >
                        <div style={{
                          paddingBottom: theme.spacing(1),
                          paddingLeft: theme.spacing(2),
                          paddingRight: theme.spacing(2),
                        }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                            <div style={{ width: '100%' }}>
                              <Typography
                                style={{ fontWeight: 600, fontSize: '.9rem' }}
                                className={utilClasses.buttonClick}
                                onClick={() => onClick(item.emitDoc)}
                              >
                                {item.emitNome !== null ? item.emitNome : 'Sem vendas para mostrar'}
                              </Typography>
                              <Typography variant="caption">
                                {formatarCPFCNPJ(item.emitDoc)}
                              </Typography>
                              {!isMobile && (
                                <div style={{
                                  display: 'flex',
                                }}>
                                  <Typography variant="inherit" style={{ paddingRight: 16, display: 'flex', alignItems: 'center' }}>
                                    <CategoriaIcon tipo="BUTTON" /> {vendas < 2 ? `Vendas: ${vendas}` : `Vendas: ${vendas}`}
                                  </Typography>
                                  <Typography variant="inherit" style={{ paddingRight: 16, display: 'flex', alignItems: 'center' }}>
                                    <ProdutoIcon tipo="BUTTON" /> {`Faturamento: R$ ${toDecimalString(item.vTotal)}`}
                                  </Typography>
                                  <Typography variant="inherit" style={{ display: 'flex', alignItems: 'center' }}>
                                    <DinheiroIcon tipo='BUTTON' /> {`Custo: R$ ${toDecimalString(item.vCustoTotal)}`}
                                  </Typography>
                                </div>
                              )}
                            </div>
                            <div>
                              <div style={{ textAlign: 'right', padding: '10px 0', width: 100 }}>
                                <Typography
                                  style={{ fontWeight: 600, fontSize: '0.8rem' }}
                                >
                                  R$ {String(toDecimalString(dinheiro, 2))}
                                </Typography>
                                <Typography
                                  style={{ fontWeight: 500, fontSize: '1rem' }}
                                >
                                  {porcentagem}%
                                </Typography>
                              </div>
                            </div>
                          </div>
                          {isMobile && (
                            <div style={{
                              display: 'flex',
                            }}>
                              <Typography variant="inherit" style={{ paddingRight: 16, display: 'flex', alignItems: 'center' }}>
                                <CategoriaIcon tipo="BUTTON" /> {vendas < 2 ? `Vendas: ${vendas}` : `Vendas: ${vendas}`}
                              </Typography>
                              <Typography variant="inherit" style={{ paddingRight: 16, display: 'flex', alignItems: 'center' }}>
                                <ProdutoIcon tipo="BUTTON" /> {`Faturamento: ${toDecimalString(item.vTotal)}`}
                              </Typography>
                              <Typography variant="inherit" style={{ display: 'flex', alignItems: 'center' }}>
                                <DinheiroIcon tipo='BUTTON' /> {`Custo: R$ ${toDecimalString(item.vCustoTotal)}`}
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div style={{
                          width: `${porcentagem}%`,
                          backgroundColor: `#${((Math.random() * 0xffffff) << 0)
                            .toString(16)
                            .padStart(6, '0')}`,
                          height: 5,
                          zIndex: '-1000'
                        }} />
                      </Paper>
                    );
                  })}
              </>
            )}
            {vendasSerelizados.length > 10 && (
              <Grid item xs={12}>
                <Divider variant="middle" />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button size='large' onClick={() => setIsMore(prevState => !prevState)}>
                    {!isMore
                      ? <><ExpandMore style={{ color: theme.palette.primary.main }} />Ver mais</>
                      : <><ExpandLess style={{ color: theme.palette.primary.main }} />Ver mais</>}
                  </Button>
                </div>
              </Grid>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};
