import { EnumIndIEDest } from "model/enums/enum-indiedest";
import { EnumCadastroStatus } from "model/enums/enum-cadastro-status";
import { EnumCadastroTipo } from "model/enums/enum-cadastro-tipo";
import { guidEmpty } from "utils/guid-empty";
import { PessoaContatosModel } from "../../pessoa";
import { PessoaDocumentoModel } from "../../pessoa/pessoa-documento-model";
import { EnumSexo } from "model/enums/enum-sexo";

export class MovSimplesPessoaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public regimeTributarioId: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public tpCadastro: EnumCadastroTipo = EnumCadastroTipo.CLIENTE,
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public indIEDest: EnumIndIEDest = EnumIndIEDest.NAO_CONTRIBUINTE,
        public nome: string = '',
        public infOperador: string = '',
        public status: EnumCadastroStatus = EnumCadastroStatus.LIBERADO,
        public contatos: Array<PessoaContatosModel> = new Array<PessoaContatosModel>(),
        public endereco: MovSimplesPessoaEnderecoModel | null = null,
        public documentos: Array<PessoaDocumentoModel> = new Array<PessoaDocumentoModel>(),
        public fantasia: string = '',
        public infInterno: string = '',
        public representanteId: string = '',
        public dtNasc: Date | string | null = null,
        public sexo: EnumSexo = EnumSexo.NaoInformado
    ) { }
}

export class MovSimplesPessoaEnderecoModel {
    constructor(
        public id: string = guidEmpty(),
        public cadastroId: string = guidEmpty(), //IdPessoa
        public pessoaId: string = guidEmpty(),
        public cep: string = '',
        public logradouro: string = '',
        public numero: string = '',
        public complemento: string = '',
        public referencia: string = '',
        public bairro: string = '',
        public cMun: string = '',
        public xMun: string = '',
        public uf: string = '',
        public cuf: number = 0,
        public ierg: string = '',
        public im: string = '',
        public indIEDest: EnumIndIEDest = EnumIndIEDest.CONTRIBUINTE_ICMS,
        public pessoaTransportadoraPadraoId: string = guidEmpty()
    ) { }
}