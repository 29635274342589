import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormDocReferenciadoValidation = () => {

  const formDocReferenciadoValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
      })
    )
  }, [])
  return {
    formDocReferenciadoValidationYup,
  }
}

