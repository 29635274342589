import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const RamoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 35.90625 1 C 35.566406 1.0625 35.28125 1.300781 35.15625 1.625 L 34.21875 3.9375 C 31.449219 2.726563 28.390625 2.027344 25.1875 2 C 25.054688 1.972656 24.914063 1.972656 24.78125 2 C 24.769531 2 24.761719 2 24.75 2 C 12.289063 2.132813 2.183594 12.207031 2 24.65625 C 1.925781 24.867188 1.925781 25.101563 2 25.3125 C 2.167969 37.859375 12.414063 48 25 48 C 37.691406 48 48 37.691406 48 25 C 48 21.726563 47.296875 18.601563 46.0625 15.78125 L 48.375 14.84375 C 48.625 14.746094 48.824219 14.550781 48.933594 14.304688 C 49.039063 14.058594 49.039063 13.78125 48.9375 13.53125 C 46.507813 7.667969 41.90625 3.316406 36.46875 1.0625 C 36.320313 1.003906 36.160156 0.984375 36 1 C 35.96875 1 35.9375 1 35.90625 1 Z M 36.59375 3.40625 C 40.875 5.421875 44.441406 8.835938 46.59375 13.40625 L 44.8125 14.15625 C 44.800781 14.15625 44.792969 14.15625 44.78125 14.15625 C 44.449219 14.117188 44.121094 14.246094 43.90625 14.5 C 43.90625 14.511719 43.90625 14.519531 43.90625 14.53125 L 29.53125 20.46875 L 35.5625 5.90625 C 35.574219 5.886719 35.585938 5.863281 35.59375 5.84375 C 35.648438 5.757813 35.691406 5.664063 35.71875 5.5625 C 35.746094 5.503906 35.765625 5.4375 35.78125 5.375 Z M 23.90625 4.03125 C 23.9375 4.03125 23.96875 4.03125 24 4.03125 L 24 24 L 4.03125 24 C 4.53125 13.210938 13.128906 4.578125 23.90625 4.03125 Z M 26 4.03125 C 28.640625 4.152344 31.140625 4.742188 33.4375 5.75 L 26.75 21.9375 C 26.589844 22.3125 26.671875 22.75 26.960938 23.039063 C 27.25 23.328125 27.6875 23.410156 28.0625 23.25 L 44.25 16.5625 C 45.378906 19.144531 46 22 46 25 C 46 30.4375 43.914063 35.367188 40.53125 39.09375 L 26 24.5625 Z M 4.03125 26 L 24.5625 26 L 39.09375 40.53125 C 35.367188 43.914063 30.4375 46 25 46 C 13.722656 46 4.546875 37.148438 4.03125 26 Z"></path>
            </DefaultIcon>
        </>
    );
};


