import { Grid } from '@material-ui/core';
import { TarjaMedicamentoMock } from 'data/mocks/tarja-medicamento-mock';
import { TipoMedicamentoMock } from 'data/mocks/tipo-medicamento-mock';
import { MedicamentoModel } from 'model/api/gestao/medicamento/medicamento-model';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { DefaultFormRefs, DefaultFormProps } from 'views/components/form/utils';

export class MedicamentoEditFormModel extends MedicamentoModel {
  constructor(
    public model: MedicamentoModel = new MedicamentoModel(),
    public precoFabricacao: number = 0,
    public precoMaximoConsumidr: number = 0
  ) {
    super(
      model.id,
      model.laboratorioId,
      model.laboratorioNome,
      model.laboratorioDocumento,
      model.codigoEan,
      model.codigoAnvisa,
      model.codigoGGRem,
      model.codigoCAS,
      model.codigoDCB,
      model.codigoATC,
      model.codigoTissTuss,
      model.codigoPort34498,
      model.nome,
      model.descritivo,
      model.composicao,
      model.qtdEmbalagem,
      model.dataLancamento,
      model.dataEncerramento,
      model.ncmId,
      model.ncm,
      model.cest,
      model.tarja,
      model.tipoMedicamento,
      model.classeTerapeutica,
      model.produtoReferencia,
      model.participaCAP,
      model.participaConfaz87,
      model.status
    );
  }
}

export const FormMedicamentoEditPrincipal = forwardRef<
  DefaultFormRefs<MedicamentoEditFormModel>,
  DefaultFormProps<MedicamentoEditFormModel>
>((props: DefaultFormProps<MedicamentoEditFormModel>, ref) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<MedicamentoEditFormModel>({
    defaultValues: { ...new MedicamentoEditFormModel() },
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const onSubmit = (model: MedicamentoEditFormModel) => {
    props.onSubmit(model);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
    },
    fillForm: (model: MedicamentoEditFormModel) => {
      reset({ ...model });
    }
  }));

  // const cadastrarNovaMarca = async (val: string) => {
  //     abrirDialogMarca(new MarcaModel(guidEmpty(), val, ''),
  //         async (laboratorio) => {
  //             fecharDialogMarca();
  //             setValue('laboratorioId', laboratorio.id)
  //         });
  // }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="codigoAnvisa"
          render={({ field }) => (
            <TextFieldSaurus
              label="Código ANVISA"
              tipo={'NUMERO'}
              readOnly
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="composicao"
          render={({ field }) => (
            <TextFieldSaurus
              label="Substância (Princípio Ativo)"
              readOnly
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="tarja"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              conteudo={TarjaMedicamentoMock}
              fullWidth
              disabled
              variant="outlined"
              label="Tarja"
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors.tarja && errors.tarja.message)}
              helperText={errors.tarja ? errors.tarja?.message : undefined}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="classeTerapeutica"
          control={control}
          render={({ field }) => (
            <TextFieldSaurus
              fullWidth
              variant="outlined"
              label="Classe Terapêutica"
              readOnly
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(
                errors.classeTerapeutica && errors.classeTerapeutica.message
              )}
              helperText={
                errors.classeTerapeutica
                  ? errors.classeTerapeutica?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
                <Controller
                    name="laboratorioId"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteMarcas
                            label={label}
                            placeholder='Ex: CIMED'
                            permiteAdicionar
                            error={Boolean(errors.laboratorioId && errors.laboratorioId.message)}
                            helperText={
                                errors.laboratorioId
                                    ? errors.laboratorioId?.message
                                    : undefined
                            }
                            {...field}
                            onChange={(e) => {
                                if (e.isNewVal) {
                                    if (e.isString) {
                                        cadastrarNovaMarca(e.value)
                                    }
                                    return
                                }
                                setValue('laboratorioId', e.value.id);
                            }}
                        />
                    )}
                />
            </Grid> */}
      <Grid item xs={12} md={6}>
        <Controller
          name="tipoMedicamento"
          control={control}
          render={({ field }) => (
            <SelectSaurus
              conteudo={TipoMedicamentoMock}
              fullWidth
              variant="outlined"
              label="Tipo do Medicamento"
              disabled
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(
                errors.tipoMedicamento && errors.tipoMedicamento.message
              )}
              helperText={
                errors.tipoMedicamento
                  ? errors.tipoMedicamento?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="precoFabricacao"
          render={({ field }) => (
            <TextFieldSaurus
              label="Preço de Fabricação"
              tipo={'DECIMAL'}
              showStartAdornment
              readOnly
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="precoMaximoConsumidr"
          render={({ field }) => (
            <TextFieldSaurus
              label="Preço Máximo Consumidor"
              tipo={'DECIMAL'}
              showStartAdornment
              readOnly
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
});
