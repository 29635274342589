import { useGetConfigNfceExists } from "data/api/gestao/empresa/get-config-emissor-exists";
import { useCallback } from "react";
import { useGetEmpresaId } from "data/api/gestao/empresa";
import { useGetConfigEmpresaId } from "data/api/gestao/empresa/get-config-empresa-id";
import { ConfigEmpresaModel } from "model/api/gestao/config-empresa/config-empresa-model";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { EmpresaCompletaModel } from "model/api/gestao/master/empresaCompleta";
import { isEmpty } from "lodash";
import { useEventTools } from "./events/event-tools";
import { EmpresaModel } from "model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { PutConfigEmpresaProps, usePutConfigEmpresa } from "data/api/gestao/empresa/put-config-empresa";
import { useToastSaurus } from "./toast-saurus";

export const useEmpresaAtual = () => {
    const { getRegistro, setRegistro } = useGestaoStorage()
    const { callEvent } = useEventTools()

    const { getConfigNfceExists, carregando: carregandoEmissorExists } = useGetConfigNfceExists();
    const { getEmpresaId, carregando: carregandoEmpresaId } = useGetEmpresaId()
    const { getConfigEmpresaId, carregando: carregandoConfigEmpresa } =
        useGetConfigEmpresaId();
    const { putConfigEmpresa, carregando: carregandoPutConfig } = usePutConfigEmpresa()
    const { showToast } = useToastSaurus()

    const carregando =
        carregandoEmissorExists ||
        carregandoEmpresaId ||
        carregandoConfigEmpresa ||
        carregandoPutConfig

    const getEmpresaAtual = useCallback((): EmpresaCompletaModel | undefined => {
        const ret = getRegistro(GestaoStorageKeys.EmpresaAtual, false) as EmpresaCompletaModel;
        if (isEmpty(ret))
            return undefined;

        return ret;
    }, [getRegistro])

    const isEmpresaNFCe = useCallback(
        async (empresaId: string): Promise<boolean> => {
            const emissor = await getConfigNfceExists(empresaId || '');
            if (emissor.erro) {
                new Error('Erro ao consultar a situação de NFC-e.');
                return false;
            }

            if ((emissor.resultado?.data || false) === true) {
                return true;
            }

            return false;
        },
        [getConfigNfceExists],
    );

    const getEmpresaConfig = useCallback(
        async (empresaId: string) => {
            const res = await getConfigEmpresaId(empresaId);

            if (res.erro) {
                throw res.erro;
            }

            const data = res.resultado?.data as ConfigEmpresaModel[];

            return data
        },
        [getConfigEmpresaId],
    );
    
    const refreshEmpresa = useCallback(async () => {
        const empresaAtual = getEmpresaAtual()
        const empresaConfig = await getEmpresaConfig(empresaAtual?.id || '')
        const novoObj = { ...empresaAtual, configuracoes: [...empresaConfig] }
        setRegistro(GestaoStorageKeys.EmpresaAtual, novoObj, false)
        callEvent(AppEventEnum.EmpresaAtualAlterado, novoObj)
    }, [callEvent, getEmpresaAtual, getEmpresaConfig, setRegistro])

    const getEmpresaIdWrapper = useCallback(async (empresaId: string) => {

        const res = await getEmpresaId(empresaId)

        return res.resultado?.data as EmpresaModel

    }, [getEmpresaId])

    const selecionarEmpresa = useCallback(
        async (empresaId: string): Promise<void> => {
            const empresa = await getEmpresaIdWrapper(empresaId)
            const empresaConfig = await getEmpresaConfig(empresaId);
            const nfce = await isEmpresaNFCe(empresaId);

            const empresaAtual: EmpresaCompletaModel = {
                ...empresa,
                configuracoes: empresaConfig,
                isFiscal: nfce || false
            }

            setRegistro(GestaoStorageKeys.EmpresaAtual, empresaAtual, false)
            callEvent(AppEventEnum.EmpresaSelecionada, empresaId)
        },
        [callEvent, getEmpresaConfig, getEmpresaIdWrapper, isEmpresaNFCe, setRegistro],
    );

    const refreshEmpresaFiscal = useCallback(
        async (): Promise<void> => {
            const empresaAtualStorage = getEmpresaAtual();

            if (!empresaAtualStorage) return;

            const nfce = await isEmpresaNFCe(empresaAtualStorage.id);

            const empresaAtual: EmpresaCompletaModel = {
                ...empresaAtualStorage!,
                isFiscal: nfce || false
            }

            setRegistro(GestaoStorageKeys.EmpresaAtual, empresaAtual, false)
            callEvent(AppEventEnum.EmpresaSelecionada, empresaAtualStorage.id)
        },
        [callEvent, getEmpresaAtual, isEmpresaNFCe, setRegistro],
    );

    const getConfigByCod = useCallback((cod: EnumEmpresaConfig): string | undefined => {
        const empresaAtual = getEmpresaAtual()

        if (isEmpty(empresaAtual))
            return undefined;
        if (isEmpty(empresaAtual?.configuracoes))
            return undefined;

        const empresaConfig =
            getEmpresaAtual()
                ?.configuracoes
                .find(x => x.cod === cod)
                ?.vConfig

        if (isEmpty(empresaConfig))
            return undefined

        return empresaConfig
    }, [getEmpresaAtual])

    const putEmpresaConfig = useCallback(async (array: PutConfigEmpresaProps[]) => {
        const res = await putConfigEmpresa(array, getEmpresaAtual()?.id ?? '')

        if (res.erro) {
            throw res.erro
        }

        try {
            await refreshEmpresa()
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [getEmpresaAtual, putConfigEmpresa, refreshEmpresa, showToast])

    return {
        getEmpresaAtual,
        refreshEmpresaFiscal,
        selecionarEmpresa,
        refreshEmpresa,
        carregando,
        getConfigByCod,
        putEmpresaConfig
    }
}