import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInfoComplementaresValidation } from './form-info-complementares-validation';
import { FormInfoComplementaresProps } from './form-info-complementares-props';
import { InfoComplementaresFormModel } from 'model/app/forms/entrada/info-complementares-form-model';
import { useStyles } from './form-info-complementares-style';

export const FormInfoComplementares = forwardRef<
    DefaultFormRefs<InfoComplementaresFormModel>,
    FormInfoComplementaresProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formInfoComplementaresValidationYup } = useFormInfoComplementaresValidation();
    const [model, setModel] = useState<InfoComplementaresFormModel>(new InfoComplementaresFormModel())
    const modelFormRef = useRef<InfoComplementaresFormModel>(new InfoComplementaresFormModel())
    const classes = useStyles()
    const {
        handleSubmit,
        control,
        formState,
        reset,
    } = useForm<InfoComplementaresFormModel>({
        resolver: yupResolver(formInfoComplementaresValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: any) => {
        props.onSubmit(values, model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: InfoComplementaresFormModel) => {
            reset({ ...model })
            setModel({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Controller
                                name="infCpl"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        multiline
                                        minRows={6}
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Informações Complementares"
                                        placeholder='Digite aqui as Informações Complementares'
                                        allowSubmit
                                        className={classes.inputInfo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.infCpl && errors.infCpl.message)}
                                        helperText={
                                            touchedFields.infCpl || errors.infCpl
                                                ? errors.infCpl?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="infAdFisco"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        multiline
                                        minRows={6}
                                        autoComplete='new-password'
                                        variant="outlined"
                                        placeholder='Digite aqui as Informações ao Fisco'
                                        label="Informações Adicionais ao Fisco"
                                        allowSubmit
                                        className={classes.inputInfo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.infAdFisco && errors.infAdFisco.message)}
                                        helperText={
                                            touchedFields.infAdFisco || errors.infAdFisco
                                                ? errors.infAdFisco?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
