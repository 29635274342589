import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
export const DocumentoAdicionarIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5662 19.0553L26.3391 8.40859H8.82078V39.2687H26.3649C26.6657 40.8753 27.2893 42.3681 28.1657 43.6773H8.82078C6.71935 43.6773 5 41.6935 5 39.2687V8.40859C5 5.98387 6.71935 4 8.82078 4H26.3391C27.3516 4 28.3259 4.4629 29.0327 5.30053L38.2599 15.9473C38.9858 16.7629 39.387 17.8871 39.387 19.0553V25.2339C38.9528 25.1859 38.5115 25.1612 38.0644 25.1612C37.2075 25.1612 36.3718 25.2518 35.5662 25.4238V19.0553ZM28.1656 30.4516H12.6416V34.8601H26.3649C26.6657 33.2536 27.2892 31.7608 28.1656 30.4516ZM31.7455 21.6344H12.6416V26.043H31.7455V21.6344ZM26.0143 12.8172H12.6416V17.2258H26.0143V12.8172Z" />
        <path d="M45.9998 37.6001C45.9998 37.9643 45.9998 38.1463 45.9301 38.2859C45.8661 38.414 45.7622 38.5178 45.6341 38.5819C45.4946 38.6516 45.3125 38.6516 44.9484 38.6516H40.7029C40.3388 38.6516 40.1567 38.6516 40.0172 38.7213C39.8891 38.7853 39.7852 38.8892 39.7212 39.0173C39.6515 39.1569 39.6515 39.3389 39.6515 39.703V43.9486C39.6515 44.3127 39.6515 44.4947 39.5817 44.6343C39.5177 44.7624 39.4139 44.8663 39.2858 44.9303C39.1462 45 38.9641 45 38.6 45H37.5287C37.1646 45 36.9826 45 36.843 44.9303C36.7149 44.8663 36.611 44.7624 36.547 44.6343C36.4773 44.4947 36.4773 44.3127 36.4773 43.9486V39.703C36.4773 39.3389 36.4773 39.1569 36.4075 39.0173C36.3435 38.8892 36.2397 38.7853 36.1116 38.7213C35.972 38.6516 35.7899 38.6516 35.4258 38.6516H31.1804C30.8162 38.6516 30.6342 38.6516 30.4946 38.5819C30.3665 38.5178 30.2627 38.414 30.1986 38.2859C30.1289 38.1463 30.1289 37.9643 30.1289 37.6001V36.5288C30.1289 36.1647 30.1289 35.9827 30.1986 35.8431C30.2627 35.715 30.3665 35.6111 30.4946 35.5471C30.6342 35.4774 30.8162 35.4774 31.1804 35.4774H35.4258C35.7899 35.4774 35.972 35.4774 36.1116 35.4076C36.2397 35.3436 36.3435 35.2398 36.4075 35.1117C36.4773 34.9721 36.4773 34.7901 36.4773 34.4259V30.1804C36.4773 29.8163 36.4773 29.6342 36.547 29.4947C36.611 29.3666 36.7149 29.2627 36.843 29.1987C36.9826 29.129 37.1646 29.129 37.5287 29.129H38.6C38.9641 29.129 39.1462 29.129 39.2858 29.1987C39.4139 29.2627 39.5177 29.3666 39.5817 29.4947C39.6515 29.6342 39.6515 29.8163 39.6515 30.1804V34.4259C39.6515 34.7901 39.6515 34.9721 39.7212 35.1117C39.7852 35.2398 39.8891 35.3436 40.0172 35.4076C40.1567 35.4774 40.3388 35.4774 40.7029 35.4774H44.9484C45.3125 35.4774 45.4946 35.4774 45.6341 35.5471C45.7622 35.6111 45.8661 35.715 45.9301 35.8431C45.9998 35.9827 45.9998 36.1647 45.9998 36.5288V37.6001Z" />
      </DefaultIcon>
    </>
  );
};

