import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const ModificadoresIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M 4 0 L 4 48 L 21 48 C 21.359375 48.003906 21.695313 47.816406 21.878906 47.503906 C 22.058594 47.191406 22.058594 46.808594 21.878906 46.496094 C 21.695313 46.183594 21.359375 45.996094 21 46 L 6 46 L 6 2 L 24 2 L 24 16 L 38 16 L 38 27 C 37.996094 27.359375 38.183594 27.695313 38.496094 27.878906 C 38.808594 28.058594 39.191406 28.058594 39.503906 27.878906 C 39.816406 27.695313 40.003906 27.359375 40 27 L 40 14.59375 L 39.71875 14.28125 L 25.71875 0.28125 L 25.40625 0 Z M 26 3.4375 L 36.5625 14 L 26 14 Z M 45.90625 24.90625 C 44.855469 24.90625 43.800781 25.292969 43 26.09375 L 42.3125 26.8125 L 42.34375 26.84375 L 26.90625 42.28125 C 26.777344 42.398438 26.679688 42.550781 26.625 42.71875 L 24.9375 48.71875 C 24.835938 49.066406 24.925781 49.441406 25.179688 49.703125 C 25.433594 49.960938 25.804688 50.0625 26.15625 49.96875 L 32.15625 48.375 C 32.324219 48.320313 32.476563 48.222656 32.59375 48.09375 L 48.40625 32.40625 C 48.53125 32.296875 48.628906 32.15625 48.6875 32 L 48.8125 31.90625 C 50.414063 30.304688 50.414063 27.695313 48.8125 26.09375 C 48.011719 25.292969 46.957031 24.90625 45.90625 24.90625 Z M 43.75 28.25 L 46.75 31.25 L 31.40625 46.5 L 28.46875 43.65625 L 28.5 43.53125 Z" />
      </DefaultIcon>
    </>
  );
};
