import { FinalizadoraModel } from 'model/api/gestao/finalizadora/finalizadora-model';
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { DialogParcelamento } from 'views/components/modals/parcelamento/dialog-parcelamento';

export const ParcelamentoModal = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
        callback: (qtdeParcelas: number) => {},
        qtdePessoas: 0,
        vRestante: 0,
        paymentMethod: new FinalizadoraModel()
    });

    const modalAlterado = useCallback(({ openned, callback, qtdePessoas, vRestante, paymentMethod }: any) => {
        setModalState({
            aberto: openned,
            callback,
            qtdePessoas,
            vRestante,
            paymentMethod
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.Parcelamento, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.Parcelamento, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DialogParcelamento openned={modalState.aberto} key="parcelamentomodal" id="" callback={modalState.callback} vRestante={modalState.vRestante} qtdePessoas={modalState.qtdePessoas} paymentMethod={modalState.paymentMethod} />
    )
}