import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogMovOpcoesProdutoCarrinho } from 'views/components/dialog/dialog-mov-opcoes-produto-carrinho/dialog-mov-opcoes-produto-carrinho';

class DialogOpcoesProdutoCarrinhoProps {
    constructor(
        public aberto: boolean = false,
        public model: MovSimplesProdutoModel = new MovSimplesProdutoModel(),
        public inativarProduto: (model: MovSimplesProdutoModel) => Promise<void> = (model: MovSimplesProdutoModel) => Promise.resolve(),
    ) { }
}

export const DialogOpcoesProdutoCarrinho = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState<DialogOpcoesProdutoCarrinhoProps>(
        new DialogOpcoesProdutoCarrinhoProps()
    );

    const modalAlterado = useCallback(
        ({ aberto, model, inativarProduto }: any) => {
            setModalState({
                aberto,
                model,
                inativarProduto,
            });
        },
        []
    );

    useEffect(() => {
        addHandler(AppEventEnum.DialogOpcoesProdutoCarrinho, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogOpcoesProdutoCarrinho, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    return (
        <>
            {modalState.aberto && (
                <DialogMovOpcoesProdutoCarrinho
                    aberto={modalState.aberto}
                    model={modalState.model}
                    inativarProduto={modalState.inativarProduto}
                />
            )}
        </>
    );
};
