export const validateEan = (ean: any) => {
  if (ean.length !== 13) return false;

  const eanDigit = parseInt(ean[ean.length - 1]);
  let multiplier = 0;

  const eanSum = ean
    .substring(0, 12)
    .split('')
    .reduce((total: any, num: any) => {
      multiplier = multiplier === 1 ? 3 : 1;
      return total + parseInt(num) * multiplier;
    }, 0);

  const validDigit = 10 - (eanSum % 10);

  const bool = eanDigit === validDigit ? true : false
  return bool;
};
