import { HistoricoVendaModel } from 'model/api/gestao/historico-vendas/historico-vendas-model';
import { useThemeQueries } from 'views';
import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CardHistoricoMobile } from '../../../../../../components/cards/card-historico-venda/card-historico-mobile';
import { CardHistoricoVenda } from '../../../../../../components/cards/card-historico-venda/card-historico-venda';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useEffect, useState } from 'react';
import { PessoaModel } from 'model/api/gestao/pessoa';

export interface HistoricoVendasGridProps {
  list: Array<HistoricoVendaModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
}

export const HistoricoVendaListData = (props: HistoricoVendasGridProps) => {

  const [consumidorPadrao, setConsumidorPadrao] = useState<PessoaModel>(new PessoaModel())

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  const { isMobile } = useThemeQueries();
  const { getConsumidor } = useCadastroPadrao();

  useEffect(() => {
    (async () => {
      const consumidorPadrao = await getConsumidor();
      if (consumidorPadrao) {
        setConsumidorPadrao(consumidorPadrao)
      }
    })()
  }, [getConsumidor])

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem="Nenhuma movimentação encontrada."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.list.length > 0 &&
        props.list.map((historico, index) => {
          return isMobile ? (
            <CardHistoricoMobile
              selected={
                props.selectedList.filter((item) => item === historico.id)
                  .length > 0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              model={historico}
              key={index}
              consumidorPadrao={consumidorPadrao}
            />
          ) : (
            <CardHistoricoVenda
              selected={
                props.selectedList.filter((item) => item === historico.id)
                  .length > 0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              consumidorPadrao={consumidorPadrao}
              model={historico}
              key={index}
            />
          );
        })}
    </>
  );
};
