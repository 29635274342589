import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const CartaoFidelidadeIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M 7 9 C 4.25 9 2 11.25 2 14 L 2 36 C 2 38.75 4.25 41 7 41 L 43 41 C 45.75 41 48 38.75 48 36 L 48 14 C 48 11.25 45.75 9 43 9 Z M 7 11 L 43 11 C 44.667969 11 46 12.332031 46 14 L 46 36 C 46 37.667969 44.667969 39 43 39 L 7 39 C 5.332031 39 4 37.667969 4 36 L 4 14 C 4 12.332031 5.332031 11 7 11 Z M 28.84375 16 C 28.515625 16.050781 28.234375 16.261719 28.09375 16.5625 L 20.09375 32.5625 C 19.851563 33.0625 20.0625 33.664063 20.5625 33.90625 C 21.0625 34.148438 21.664063 33.9375 21.90625 33.4375 L 29.90625 17.4375 C 30.078125 17.101563 30.042969 16.695313 29.820313 16.390625 C 29.59375 16.085938 29.214844 15.9375 28.84375 16 Z M 20 17 C 18.355469 17 17 18.355469 17 20 L 17 21 C 17 22.644531 18.355469 24 20 24 C 21.644531 24 23 22.644531 23 21 L 23 20 C 23 18.355469 21.644531 17 20 17 Z M 20 19 C 20.566406 19 21 19.433594 21 20 L 21 21 C 21 21.566406 20.566406 22 20 22 C 19.433594 22 19 21.566406 19 21 L 19 20 C 19 19.433594 19.433594 19 20 19 Z M 30 26 C 28.355469 26 27 27.355469 27 29 L 27 30 C 27 31.644531 28.355469 33 30 33 C 31.644531 33 33 31.644531 33 30 L 33 29 C 33 27.355469 31.644531 26 30 26 Z M 30 28 C 30.566406 28 31 28.433594 31 29 L 31 30 C 31 30.566406 30.566406 31 30 31 C 29.433594 31 29 30.566406 29 30 L 29 29 C 29 28.433594 29.433594 28 30 28 Z" />
      </DefaultIcon>
    </>
  );
};
