import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const ConfirmarIcon = (props: DefaultIconProps) => {
  return (
    <DefaultIcon
      tipo={props.tipo}
      class={props.class}
      style={props.style}
      fill={props.fill}
    >
      <path d="M18.75 33.75L10 25L7.08337 27.9167L18.75 39.5834L43.75 14.5834L40.8334 11.6667L18.75 33.75Z" />
    </DefaultIcon>
  );
};
