import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    icon: {
        margin: 0,
        height: 25,
        width: 25,
        marginRight: theme.spacing(1)
    },
    iconMais: {
        fill: theme.palette.secondary.main
    },
    btnLabel: {
        height: '100%',
        paddingLeft: theme.spacing(1)
    },
    btnLixeira: {
        height: 50,
        width: 50,
        padding: 0,
        margin: 0,
        marginLeft: theme.spacing(2),
        '& svg': {
            fill: theme.palette.secondary.main,
            height: 25,
            width: 25
        }
    }
}))