import { guidEmpty } from "utils/guid-empty";

export class MovRegistrarResponseModel {
    constructor(
        public id: string = guidEmpty(),
        public pixCopiaCola: string = '',
        public pixChave: string = '',
        public dataRegistro: string = '',
        public dataExpiracao: string = ''
    ) { }
}