import { Box } from '@material-ui/core';
import { useCallback, useState, useMemo, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { CorrigirSaldo, EntradaEstoque, SaidaEstoque } from './components';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DepositosEstoque } from './components/depositos-estoque';
import { useGetDeposito } from 'data/api/gestao/deposito';
import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';
import { usePostProdutoVariacaoAtualizarSaldo } from 'data/api/gestao/produto/produto-variacao/post-produto-variacao-atualizar-saldo';
import { guidEmpty } from 'utils/guid-empty';
import { useStyles } from './produto-estoque-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CircularLoading } from 'views/components/utils';
import { useGetProdutoVariacaoDeposito } from 'data/api/gestao/produto/produto-variacao/get-produto-variacao-deposito';
import { ProdutoVariacaoDeposito } from 'model/api/gestao/produto/produto-variacao/produto-variacao-deposito';

export interface ProdutoEstoqueRefs {
    close: () => void;
}

export interface DepositoListModel extends DepositoModel {
    qSaldo: number;
}

interface ProdutoEstoqueProps {
    variacaoPadraoId: string
    id: string
    atualizarVariacao: () => void
    onClose: () => void;
}

export const ProdutoEstoque = forwardRef<
    ProdutoEstoqueRefs,
    ProdutoEstoqueProps
>(({
    variacaoPadraoId,
    id,
    atualizarVariacao,
    onClose
}: ProdutoEstoqueProps, ref) => {
    const classes = useStyles();
    const [acao, setAcao] = useState<number>(4)
    const { postProdutoVariacaoAtualizarSaldo, carregando: carregandoPost } = usePostProdutoVariacaoAtualizarSaldo()
    const { getDeposito, carregando: carregandoGet } = useGetDeposito()
    const { getProdutoVariacaoDeposito, carregando: carregandoGetDepos } = useGetProdutoVariacaoDeposito();
    const [depList, setDepList] = useState<Array<DepositoListModel>>([])
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const [depId, setDepId] = useState<string>('')
    const [valorEstoque, setValorEstoque] = useState<number>(0)
    const { callEvent } = useEventTools();

    const depsRef = useRef<ProdutoVariacaoDeposito[]>([])

    const carregando =
        carregandoGet ||
        carregandoPost ||
        carregandoGetDepos

    const getDepositosWrapper = useCallback(async () => {
        const res = await getDeposito("PageSize=100", 1)
        if (res.erro) throw res.erro

        return res.resultado?.data.list as DepositoModel[]
    }, [getDeposito])

    const getDepositosVariacaoWrapper = useCallback(async () => {
        const res = await getProdutoVariacaoDeposito(id, variacaoPadraoId);
        if (res.erro) throw res.erro

        return res.resultado?.data as ProdutoVariacaoDeposito[];
    }, [getProdutoVariacaoDeposito, id, variacaoPadraoId])

    useEffect(() => {
        const getDep = async () => {
            try {
                const depositoWrapper = await getDepositosWrapper();
                const depsProduto = await getDepositosVariacaoWrapper();
                depsRef.current = depsProduto;
                
                if (depositoWrapper.length > 1) {
                    setDepList(depositoWrapper.map(dep => {
                        return {
                            ...dep,
                            qSaldo: (depsProduto.find(depProd => depProd.depositoId === dep.id)?.qSaldoTotal ?? 0)
                        }
                    }))
                    setAcao(4)
                    return;
                } else if (depositoWrapper.length === 1) {
                    setDepId(depositoWrapper[0].id);

                    const resGetDep = depsProduto[0]
                    setDepList(depositoWrapper.map(dep => {
                        return {
                            ...dep,
                            qSaldo: (depsProduto.find(depProd => depProd.depositoId === dep.id)?.qSaldoTotal ?? 0)
                        }
                    }))
                    setValorEstoque(resGetDep?.qSaldoTotal ?? 0);
                }
                setAcao(4)
            } catch (e: any) {
                showToast('error', e.message)
            }
        }

        getDep();

    }, [getDepositosVariacaoWrapper, getDepositosWrapper, getEmpresaSelecionada, id, showToast, variacaoPadraoId])

    const handleVoltar = useCallback(() => {
        setAcao(4)
    }, [])

    const onClickDepId = useCallback(async (idDeposito: string) => {
        try {
            setDepId(idDeposito);

            const dep = depsRef.current.find(dep => dep.depositoId === idDeposito);


            setValorEstoque(dep?.qSaldoTotal ?? 0);
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [showToast])

    const saveChangesProdutoEstoque = useCallback(
        async (value: number) => {

            if (value === undefined) {
                value = 0;
            }

            const produtoEstoque = {
                qSaldo: value!,
                depositoId: depId,
                empresaId: getEmpresaSelecionada()?.Id || ''
            };
            const ret = await postProdutoVariacaoAtualizarSaldo(
                id,
                variacaoPadraoId,
                { ...produtoEstoque, movId: guidEmpty() },
            );

            if (ret.erro) {
                throw ret.erro;
            }

            showToast('success', 'Estoque do produto atualizado com sucesso!');

            atualizarVariacao()
        },
        [atualizarVariacao, depId, getEmpresaSelecionada, id, postProdutoVariacaoAtualizarSaldo, showToast, variacaoPadraoId],
    );

    const depositoNome = depList.find(item => item.id === depId)?.nome ?? ''

    const acoes = useMemo(() => {
        switch (acao) {
            case 1:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Correção de Saldo'
                })
                return (
                    <CorrigirSaldo
                        closeModal={onClose}
                        valorEstoque={valorEstoque}
                        depositoId={depId}
                        depositoNome={depositoNome}
                        handleVoltar={handleVoltar}
                        carregando={carregando}
                        handleSubmit={saveChangesProdutoEstoque}
                    />
                )
            case 2:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Entrada de Estoque'
                })
                return (
                    <EntradaEstoque
                        closeModal={onClose}
                        depositoNome={depositoNome}
                        depositoId={depId}
                        valorEstoque={valorEstoque}
                        handleVoltar={handleVoltar}
                        carregando={carregando}
                        handleSubmit={saveChangesProdutoEstoque}
                    />
                )
            case 3:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Saída de Estoque'
                })
                return (
                    <SaidaEstoque
                        closeModal={onClose}
                        depositoNome={depositoNome}
                        depositoId={depId}
                        valorEstoque={valorEstoque}
                        handleVoltar={handleVoltar}
                        carregando={carregando}
                        handleSubmit={saveChangesProdutoEstoque}
                    />
                )
            case 4:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Depósitos'
                })
                return (
                    <DepositosEstoque
                        depositos={depList}
                        onClick={onClickDepId}
                        mudarAcao={setAcao}
                    />
                )

        }
    }, [acao, callEvent, carregando, depId, depList, depositoNome, handleVoltar, onClickDepId, onClose, saveChangesProdutoEstoque, valorEstoque])


    useImperativeHandle(ref, () => ({
        close() {
            const redirectToList = [1, 2, 3].includes(acao)

            if (redirectToList) {
                setAcao(4);
                return
            }
            callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                titulo: ''
            })
            onClose();
        },
    }))

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.cardContainer}>
                    <Box className={classes.cardContent}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            {carregando && <CircularLoading tipo='FULLSIZED' />}
                            {acoes}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
})