import { Slide, Typography } from '@material-ui/core';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import WarningIcon from '@material-ui/icons/Warning';
import { useStyles } from './mensagem-sem-conexao-styles';
import { useGetHealth } from 'data/api/gestao/healthy';

export const MensagemSemConexao = () => {
  const [ativar, setAtivar] = useState({
    ativo: false,
    message: 'Sem internet, verifique a sua conexão.'
  });
  const classes = useStyles();

  const { addHandler, removeHandler } = useEventTools();

  const { getHealth } = useGetHealth();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!navigator.onLine) return;
      await getHealth();
    }, 60000);

    return () => clearInterval(interval);
  }, [getHealth]);

  useEffect(() => {
    addHandler(AppEventEnum.SemConexao, setAtivar);

    return () => removeHandler(AppEventEnum.SemConexao, setAtivar);
  }, [addHandler, removeHandler]);

  return (
    <>
      <Slide direction="up" in={ativar.ativo} mountOnEnter unmountOnExit>
        <div className={classes.background}>
          <Typography variant="body2" className={classes.mensagem}>
            <WarningIcon style={{ marginRight: 8 }} />
            {ativar.message}
          </Typography>
        </div>
      </Slide>
    </>
  );
};
