import { KeyValueModel } from "model/api";

import { EnumOrigemMercadoria } from "../../model/enums/enum-origem-mercadoria";

export const OrigemMercadoriaMock: KeyValueModel[] = [
  new KeyValueModel(EnumOrigemMercadoria.OM_NACIONAL, "Nacional"),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_ESTRANGEIRA_IMPORTACAO_DIRETA,
    "Estrangeira – Importação direta"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_ESTRANGEIRA_ADQUIRIDA_BRASIL,
    "Estrangeira – Adquirida no mercado interno"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_NACIONAL_CONTEUDO_IMPORTACAO_SUPERIOR_40,
    "Nacional – mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_NACIONAL_PROCESSOS_BASICOS,
    "Nacional – cuja produção tenha sido feita em conformidade com os processos produtivos básicos"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_NACIONAL_CONTEUDO_IMPORTACAO_INFERIOR_IGUAL_40,
    "Nacional – mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_ESTRANGEIRA_IMPORTACAO_DIRETAS_EM_SIMILAR,
    "Estrangeira – Importação direta, sem similar nacional, constante em lista de Resolução CAMEX e gás natural"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_ESTRANGEIRA_ADQUIRIDA_BRASIL_SEM_SIMILAR,
    "Estrangeira – Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural"
  ),
  new KeyValueModel(
    EnumOrigemMercadoria.OM_NACIONAL_CONTEUDO_IMPORTACAO_SUPERIOR_70,
    "Nacional – mercadoria ou bem com Conteúdo de Importação superior a 70%"
  ),
];
