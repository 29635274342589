import { useEffect, useState, useCallback, useRef } from 'react';
import parse from 'html-react-parser';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { PublicPageHeader } from 'views/components/headers';
import { CircularLoading, makeUtilClasses } from 'views';
import { useStyles } from './politica-page-styles';
import { useToastSaurus } from 'services/app';
import { CancelarIcon } from '../../../components/icons/cancelar-icon';
import { OkIcon } from '../../../components/icons/ok-icon';
import { TermosIcon } from '../../../components/icons/termos-icon';
import { useGetPoliticaPendente } from '../../../../data/api/gestao/politica/get-politica-pendente';
import { PoliticaModel } from '../../../../model';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router';
import { useSessaoAtual } from 'services/app';
import { isEmpty } from 'lodash';
import { VoltarIcon } from '../../../components/icons/voltar-icon';
import { AvancarIcon } from '../../../components/icons/avancar-icon';
import { FormPoliticaAceite } from '../../../components/form/master/politica-aceite/form-politica-aceite';
import { PoliticaAceiteFormModel } from '../../../../model/app/forms/master/politica-aceite-form';
import { DefaultFormRefs } from '../../../components/form/utils';
import { usePutPoliticaAceitar } from '../../../../data/api/gestao/politica/put-politica-aceite';
import { EnumTpTermo } from 'model/enums/enum-tptermo';
import classNames from 'classnames';

const PoliticaPage = () => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { putPoliticaAceitar, carregando: carregandoAceite } =
    usePutPoliticaAceitar();
  const { push: historyPush } = useHistory();
  const confirm = useConfirm();
  const {
    deslogar,
    carregarDadosUsuario,
    carregando: carregandoLogin,
    usuario,
    getPessoa,
    plano,
  } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const theme = useTheme();
  const { getPoliticaPendente, carregando: carregandoGet } =
    useGetPoliticaPendente();
  const [politica, setPolitica] = useState<PoliticaModel>();
  const [passoAtual, setPassoAtual] = useState(0);
  const aceiteRefs = useRef<DefaultFormRefs<PoliticaAceiteFormModel>>(null);
  const [position, setPosition] = useState<string>('');
  const loading = carregandoAceite || carregandoGet || carregandoLogin;
  const aceitarTermos = useCallback(() => {
    setPassoAtual(2);
  }, [setPassoAtual]);

  const rejeitarTermos = useCallback(() => {
    confirm({
      title: 'Rejeitar Termos de Uso?',
      description:
        'Infelizmente ao rejeitar os termos de uso, você não poderá acessar o sistema. Deseja realmente Rejeitar?',
      cancellationText: 'Recusar e Realizar Logoff',
      cancellationButtonProps: { color: 'secondary' },
      confirmationButtonProps: { variant: 'contained' },
      confirmationText: 'Ler Novamente',
    }).catch(() => {
      deslogar(usuario?.usuarioId || '');
      historyPush('/login')
    });
  }, [confirm, deslogar, usuario?.usuarioId, historyPush]);

  const getTermos = useCallback(async () => {
    try {
      const ret = await getPoliticaPendente(getPessoa().pessoa?.contratoId || '');
      if (ret.erro) throw ret.erro;

      if (ret.resultado?.data.length <= 0) {
        historyPush('/login')
        return;
      }

      setPolitica(ret.resultado?.data[0]);
      setPassoAtual(1);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getPessoa, getPoliticaPendente, historyPush, showToast]);

  const voltarForm = useCallback(() => {
    setPassoAtual(1);
  }, []);

  const RequireIp = async () => {
    try {
      const publicIp = require('public-ip');
      const ipv4 = (await publicIp.v4()) || '';
      return ipv4;
    } catch (err: any) {
      return '';
    }
  };

  const finalizarProcesso = useCallback(
    async (model: PoliticaAceiteFormModel) => {
      try {
        const ipv4 = await RequireIp();
        const ret = await putPoliticaAceitar({
          aceito: true,
          referencia: getPessoa().pessoa?.contratoId || '',
          localizacao: position || '',
          documento: model.rg ? model.rg : getPessoa().pessoa?.cpfcnpj ?? '',
          ip: plano?.plano?.tpTermo === EnumTpTermo.SIMPLES ? '' : ipv4,
          nome: model.nome ? model.nome : getPessoa().pessoa?.nome || '',
          termoId: politica?.id ?? ''
        });
        if (ret.erro) throw ret.erro;

        if (usuario) {
          await carregarDadosUsuario(usuario);
        }

        historyPush('/');
      } catch (e: Error | any) {
        showToast('error', e.message);
      }
    },
    [putPoliticaAceitar, getPessoa, position, plano?.plano?.tpTermo, politica?.id, usuario, historyPush, carregarDadosUsuario, showToast],
  );

  const aceitarTermosSimples = useCallback(() => {
    finalizarProcesso(new PoliticaAceiteFormModel());
  }, [finalizarProcesso]);

  useEffect(() => {
    if (usuario) {
      if ('geolocation' in navigator) {
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setPosition(
              position?.coords.latitude + ';' + position?.coords.longitude,
            );
            getTermos();
          },
          function (error) {
            setPosition('denied');
            getTermos();
          },
          options,
        );
      }
    }
  }, [getTermos, usuario]);

  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <div className={classNames(utilClasses.contentCenter, classes.contentCenter)}>
        <Grid container className={utilClasses.rootContainer}>
          {(loading || isEmpty(politica)) && position === '' ? (
            <CircularLoading tipo="FULLSIZED" />
          ) : null}
          {passoAtual > 0 && (
            <Grid item xs={12}>
              <Typography align="center" variant="h3" color="primary">
                <TermosIcon tipo="GERAL" class={classes.imgTitulo} />
                Termos de Uso
              </Typography>
              <Box mt={3}>
                <Typography align="center" variant="body1">
                  {passoAtual === 1
                    ? 'Leia os termos de uso e política de privacidade para continuar o acesso ao sistema.'
                    : 'Preencha seus dados para finalizar o procedimento de Termos.'}
                </Typography>
              </Box>
              {passoAtual === 1 && (
                <>
                  <div className={classes.termosDeUso}>
                    {parse(politica?.termo || '')}
                  </div>
                  <Box mt={5}>
                    <Grid spacing={2} container>
                      <Grid item xs={12} sm={6}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="outlined"
                          fullWidth={true}
                          disabled={loading}
                          onClick={() => {
                            rejeitarTermos();
                          }}
                        >
                          <CancelarIcon
                            fill={theme.palette.primary.main}
                            tipo="BUTTON"
                          />
                          Recusar e Sair
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth={true}
                          disabled={loading}
                          onClick={() => {
                            if (plano?.plano?.tpTermo === EnumTpTermo.SIMPLES) {
                              aceitarTermosSimples();
                            } else if (
                              plano?.plano?.tpTermo === EnumTpTermo.COMPLETO
                            ) {
                              aceitarTermos();
                            }
                          }}
                        >
                          <AvancarIcon tipo="BUTTON_PRIMARY" />
                          Aceitar e Continuar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              {passoAtual === 2 && (
                <>
                  <div>
                    <FormPoliticaAceite
                      showLoading={false}
                      ref={aceiteRefs}
                      loading={loading}
                      onSubmit={(model) => {
                        finalizarProcesso(model);
                      }}
                    />
                  </div>
                  <Box mt={5}>
                    <Grid spacing={2} container>
                      <Grid item xs={5}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="outlined"
                          fullWidth={true}
                          disabled={loading}
                          onClick={() => {
                            voltarForm();
                          }}
                        >
                          <VoltarIcon
                            fill={theme.palette.primary.main}
                            tipo="BUTTON"
                          />
                          Voltar
                        </Button>
                      </Grid>
                      <Grid item xs={7}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth={true}
                          disabled={loading}
                          onClick={() => {
                            aceiteRefs.current?.submitForm();
                          }}
                        >
                          <OkIcon tipo="BUTTON_PRIMARY" />
                          Finalizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default PoliticaPage;
