import { useCallback } from "react";
import { PrivatePageHeader } from "views/components/headers";
import { ButtonPrivateHeader } from "views/components/controles/buttons";
import { VoltarIcon } from "views/components/icons";
import { useHistory } from "react-router-dom";

interface HeaderProps {
    title: string
}

export const GerarPagerIdentificadorHeader = ({ title }: HeaderProps) => {
  const history = useHistory()

    const handleClickGoBack = useCallback(() => history.goBack(), [history])

    const leftArea = useCallback(
        () => (
            <ButtonPrivateHeader
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Ir para Menu Administrativo"
                onClick={handleClickGoBack}
            />
        ), [handleClickGoBack],
    );

    return (
        <>
            <PrivatePageHeader title={title} leftArea={leftArea()} />
        </>
    );
};