import {  Button, Grid, List, ListItem, Typography } from '@material-ui/core';
import { SetorCadastroFormModel } from 'model/app/forms/setor/setor-cadastro-form-model';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { useStyles } from './form-setor-config-inicial-styles'
import classNames from 'classnames';
// import { useFormSetoresConfigInicialValidation } from './form-setor-config-inicial-validation';
import { AddIconAlt } from 'views/components/icons/add-icon-alt';
import { CloseIconAlt } from 'views/components/icons/close-icon-alt';
import { isEmpty } from 'lodash';
import { useToastSaurus } from 'services/app';

export const FormSetoresConfigInicial = forwardRef<
  DefaultFormRefs<SetorCadastroFormModel[]>,
  DefaultFormProps<SetorCadastroFormModel[]>
>((props: DefaultFormProps<SetorCadastroFormModel[]>, ref) => {
  const utilClasses = makeUtilClasses();
  const {
    handleSubmit,
    control,

    formState: { errors, touchedFields, },
    getValues,
    setValue,
  } = useForm<SetorCadastroFormModel>({

    criteriaMode: 'all',
    mode: 'onChange',
  });

  const [setores, setSetores] = useState<Array<SetorCadastroFormModel>>([])

  const onSubmit = () => {
    const arraySetores: Array<SetorCadastroFormModel> = setores
    props.onSubmit(arraySetores);
  };

  const classes = useStyles()
  const { showToast } = useToastSaurus()
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
    },
    fillForm: () => {

    },
  }));

  const atualizarLista = () => {
    const newSetor = new SetorCadastroFormModel(getValues('descricao'))
    if(isEmpty(newSetor.descricao)){
     showToast('error', 'Preencha o campo com algum setor')
     return
    }
    setSetores([...setores, newSetor])
    setValue('descricao','')
  }
  const deletaItemDaLista = (index: number) => {
    const novaLista = [...setores]
    novaLista.splice(index, 1)
    setSetores(novaLista)
  }
  return (

    <div className={utilClasses.formContainer}>
      {props.loading && props.showLoading ? (
        <div className={utilClasses.controlLoading}>
          <CircularLoading tipo="NORMAL" />
        </div>
      ) : null}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(classes.formulario, props.loading ? utilClasses.controlLoading : '')}
      >

        <Grid container xs={12}>
          <Grid item xs={12} >
            <Typography >Informe o nome do setor: Ex: Cozinha, Bar.</Typography>
          </Grid>
          <Grid className={classes.inputForm} xs={12}>
            <Controller
              name="descricao"
              control={control}
              render={({ field }) => (
                <TextFieldSaurus
                  tipo={'TEXTO'}
                  disabled={props.loading}
                  fullWidth
                  variant="outlined"
                  label="Setor"
                  allowSubmit
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Digite o nome do setor"
                  error={Boolean(
                    errors.descricao && errors.descricao.message,
                  )}
                  inputProps={{ maxLength: 70 }}
                  helperText={
                    touchedFields.descricao || errors.descricao
                      ? errors.descricao?.message
                      : undefined
                  }
                  {...field}
                />
              )}
            />
            <Button onClick={atualizarLista} >
              <AddIconAlt tipo='BUTTON' />
            </Button>
          </Grid>

        </Grid>
        <List className={classes.listSetores}>
          {setores.map((x, index) =>
            <Grid className={classes.itemContent} key={index}>
              <ListItem className={classes.cardItem}>{x.descricao}</ListItem>
              <Button onClick={() => { deletaItemDaLista(index) }}>
                <CloseIconAlt fill="red" tipo='BUTTON' />
              </Button>
            </Grid>
          )}
        </List>
        <Button style={{ display: 'none' }} type="submit"></Button>

      </form>
    </div>


  );
});
