import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { useKeyboard } from "services/app/hooks/keyboard";
import { Keyboard } from "views/components/keyboard/keyboard"
import { useStyles } from '../pessoa-cadastro-steps-styles'
import { formatarCPFCNPJ } from "utils/cpfcnpj-format";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { EnumCadastroTipo } from "model";
import { AvancarIcon, CancelarIcon, VoltarIcon } from "views/components/icons";
import { isEmpty } from "lodash";
import { InfoDocTeclado, tiposComCPFCNPJObrigatorio } from "../pessoa-cadastro-steps";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { useSessaoAtual, useToastSaurus } from "services/app";
import classNames from "classnames";
import { stringNumeros } from 'utils/string-numeros';
import { EnumTour } from "model/enums/enum-tour";
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { isPlanoFarmaceutico } from "utils/plano-utils";


interface PessoaCadastroTecladoProps {
    submitDocumento: (cpfCnpj: string, isObrigatorio: boolean, info?: InfoDocTeclado) => void;
    handleAvancar: () => void;
    handleVoltar: () => void;
    tipo: EnumCadastroTipo;
    valorPadrao?: string;
}

export const PessoaCadastroTecladoCPFCNPJ = ({
    submitDocumento,
    handleAvancar,
    handleVoltar,
    tipo,
    valorPadrao,
}: PessoaCadastroTecladoProps) => {

    const { addHandler, removeHandler } = useEventTools();
    const classes = useStyles({});
    const { showToast } = useToastSaurus();

    const { plano } = useSessaoAtual();

    const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

    const isObrigatorio = Boolean(tiposComCPFCNPJObrigatorio.includes(tipo))

    const digitado = useRef<boolean>(false);
    const textoAtual = useRef<string>('');
    const valueRef = useRef<HTMLInputElement | null>(null);
    const documento = useRef<string>('');


    const textChanged = useCallback(
        async (text: string, formattedText: string) => {
            documento.current = text;
            return true;
        },
        []
    );

    const submit = useCallback(async () => {
        try {
            if (!validarCPFCNPJ(documento.current)) {
                showToast('error', 'CPF/CNPJ Inválido!')
                throw new Error('CPF/CNPJ Inválido!');
            }
            submitDocumento(documento.current, isObrigatorio, undefined)
            return true
        } catch (e: any) {
            return false
        }
    }, [isObrigatorio, showToast, submitDocumento])

    const {
        getFormattedText,
        addValue,
        sendText,
        addKey,
        submitKey,
        backSpaceKey,
    } = useKeyboard({
        maxLength: 15,
        floatCases: 0,
        isNumeric: false,
        handleTextChanged: textChanged,
        handleSubmit: submit,
        digitado: digitado,
        textoAtual: textoAtual
    });

    const attValueDisplay = useCallback((any: any) => {
        const v = getFormattedText();
        if (valueRef.current) {
            if (v.length === 0) {
                valueRef.current.textContent = isObrigatorio ? "Aguardando Digitação" : "Opcional"
                valueRef.current.className = valueRef.current!.className = classNames(classes.noValue, classes.placeholder);
                return
            }
            valueRef.current!.className = classNames(classes.hasValue, classes.placeholder)
            valueRef.current.textContent = formatarCPFCNPJ(v)
        };
    }, [classes.hasValue, classes.noValue, classes.placeholder, getFormattedText, isObrigatorio])

    const handleAddValue = useCallback(
        (value: number) => {
            addValue(value);
        },
        [addValue],
    );

    const handleText = useCallback(
        (value: string) => {
            sendText(value);
        },
        [sendText],
    );

    const handleAdd = useCallback(() => {
        addKey();
    }, [addKey]);

    useEffect(() => {
        addHandler(AppEventEnum.AlterarVersao, attValueDisplay)
        return () => removeHandler(AppEventEnum.AlterarVersao, attValueDisplay)
    }, [addHandler, attValueDisplay, removeHandler])

    useEffect(() => {
        if (valorPadrao) {
            handleText(valorPadrao)
        }
        valueRef.current!.value = isObrigatorio ? "Aguardando Digitação" : "Opcional"
        valueRef.current!.className = classNames(classes.noValue, classes.placeholder)
    }, [classes.noValue, classes.placeholder, handleText, isObrigatorio, tipo, valorPadrao])


    async function pasteFromClipboard() {
        try {
            const clipboardText = await navigator.clipboard.readText();
            const isValid = validarCPFCNPJ(clipboardText);
            if (valueRef.current && isValid) {
                valueRef.current!.className = classNames(classes.hasValue, classes.placeholder)
                valueRef.current.textContent = formatarCPFCNPJ(clipboardText);
                handleText(stringNumeros(clipboardText))
            }
        } catch (error) {
            console.error('Erro ao ler da área de transferência:', error);
        }
    }

    const { callTour } = useTourSaurus()

    useEffect(() => {
        pasteFromClipboard();
        if (!isFarma) {
            callTour(EnumTour.CADASTROPESSOASDOCUMENTO)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Box className={classes.keyboardContainer}>
        <Grid className={classes.content} id='tour-form-pessoas-documento'>
            <Typography className={classes.title}>Informe o CPF/CNPJ</Typography>
            <Grid className={classes.inputArea}>
                <Typography className={classes.placeholder} ref={valueRef}>
                    {isObrigatorio ? "Aguardando Digitação" : "Opcional"}
                </Typography>
            </Grid>
        </Grid>
        <Box className="teclado">
            <Keyboard
                isNumeric
                isButtonBackspace
                handleSubmit={submitKey}
                handleAddValue={handleAddValue}
                handleBackSpace={backSpaceKey}
                handleText={handleText}
                handleAdd={handleAdd}
                isButtonKeyboard00
            />
        </Box>
        {!isObrigatorio && <Box className={classes.buttonContainer} style={{ marginBottom: '8px' }}>
            <Button color='primary' variant='outlined' onClick={handleVoltar}>
                <VoltarIcon tipo='BUTTON' />
                Voltar
            </Button>
        </Box>}
        <Box className={classes.buttonContainer}>
            {!isObrigatorio && <Button color='primary' variant='outlined' onClick={() => {
                handleAvancar();
            }}>
                <CancelarIcon tipo='BUTTON' />
                Não Informar
            </Button>}

            {isObrigatorio && <Button color='primary' variant='outlined' onClick={async () => {
                handleVoltar();
                submit()
            }}>
                <VoltarIcon tipo='BUTTON' />
                Voltar
            </Button>}
            <Button color='primary' variant='contained' onClick={async () => {
                if (!isObrigatorio) {
                    handleAvancar();
                }

                if (isObrigatorio && isEmpty(documento.current)) {
                    showToast('error', 'Informe o Documento')
                    return
                }
                submit()
            }}>
                <AvancarIcon tipo='BUTTON_PRIMARY' />
                Avançar
            </Button>
        </Box>
    </Box>
}