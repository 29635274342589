import { useCallback } from "react";
import { ProdutoModificadorModel } from "../../../../../model/api/gestao/produto/produto-modificador/produto-modificador-model";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";
import { useSessaoAtual } from "services/app";

export function usePostProdutoModificador() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const { getEmpresaSelecionada } = useSessaoAtual()

  const postProdutoModificador = useCallback(
    (modificadorId: string, produtoModificador: ProdutoModificadorModel) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${getEmpresaSelecionada()?.Id ?? ''}/modificador/${modificadorId}/produto`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(produtoModificador),
        enviarTokenUsuario: true,
      }),
    [getEmpresaSelecionada, invocarApi]
  );

  return {
    ...outrasPropriedades,
    postProdutoModificador,
  };
}
