import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(1) * -1,
    marginBottom: theme.spacing(2) * -1
  },
  content: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 5,
    '&:nth-child(odd)': {
      background: theme.palette.grey[100]
    }
  },
  descrContent: {
    flex: '1 1 100%',
    width: '100%'
  },
  titulo: {
    fontWeight: 'bold',
    fontSize: 16
  },
  descricao: {
    fontSize: 12
  },
  ctrlContent: {},
  defaultList: {
    width: '100%'
  }
}));
