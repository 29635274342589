import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DocReferenciadoModal } from "views/components/modals/doc-referenciado/doc-referenciado-modal";

export const CadastroDocReferenciadoModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        mov: new VendaCompletaModel(),
        id: ''
    });

    consoleDev('CadastroDocReferenciadoModal')

    const modalAlterado = useCallback(({ openned, mov, id }: any) => {
        setModalState({
            openned,
            mov,
            id
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DocReferenciadoModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DocReferenciadoModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DocReferenciadoModal
            key="mdlDocReferenciado"
            openned={modalState.openned}
            mov={modalState.mov}
            id={modalState.id}
        />
    )
}