import { useStyles } from "./loading-button-styles";

type ColorLoad = "AZUL" | "BRANCO"
type SizeLoad = 'LARGE' | 'NORMAL'

interface Props {
    tipo: ColorLoad
    size?: SizeLoad; 
}

export const LoadingButton = (props: Props) => {
    const classes = useStyles();
    const myClass = props.tipo === "AZUL" ? classes.loadAzul : classes.loadBranco

    return (
        <div className={`${classes.cLoader} ${myClass} ${props.size && props.size === 'LARGE' ? classes.cLoaderLarge : ''}`}>
        </div>
    )
}
