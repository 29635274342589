
import { EnumMesas } from "model/enums/enum-mesas";

export class StatusMesas {
    constructor(
        public descricao: string = "Ativo",
        public codigo: EnumMesas = EnumMesas.ATIVO
    ) { }
}
export class MesasModel {
    constructor(
        public id: string,
        public codigo: string = '',
        public salaoId: string = '',
        public status: StatusMesas = new StatusMesas(),
        public pedidoComQrCode: boolean = false
    ) { }
}