import { isEmpty } from "lodash";
import { formatarCEP } from "./formatar-cep";

interface CompletarEnderecoProps {
    rua: string;
    bairro: string;
    numero?: string;
    municipio: string;
    uf: string;
    complemento?: string;
    cep: string;
}

export const completarEndereco = ({ rua, bairro, numero, municipio, uf, complemento, cep }: CompletarEnderecoProps) => {

    return `${rua}${!isEmpty(numero) ? ', ' + numero : ''}${' - ' + bairro}, ${municipio}, ${uf}${!isEmpty(complemento) ? ', ' + complemento : ''}${cep ? ', ' + formatarCEP(cep) : ''}`

}