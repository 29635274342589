import { useCallback, useState, useEffect, useRef } from 'react';
import { useStyles } from './input-color-styles';
import { ColorPicker } from 'material-ui-color';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

export interface InputColorProps {
    labelTxt: string,
    onChange(cor: string): void,
    valor: string
}

export const InputColor = (props: InputColorProps) => {
    const classes = useStyles();
    const [cor, setCor] = useState(props.valor);
    const timeoutSelect = useRef<any>();
    const containerRef = useRef<any>();

    const onChangeColor = useCallback((color: any) => {
        setCor(color);
        clearTimeout(timeoutSelect.current);
        timeoutSelect.current = setTimeout(() => {
            props.onChange('#' + color.hex);
        }, 500);
    }, [props]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.children[1].children[0].setAttribute('tabIndex', '-1');
        }
    }, []);

    useEffect(() => {
        setCor(props.valor);
    }, [props.valor]);

    return (
        <div className={classes.inputContainer} ref={containerRef} >
            <Grid className={classes.labelPersonalizado}>
                <label className={classNames("MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled")} data-shrink="true">{props.labelTxt}</label>
            </Grid>
            <ColorPicker
                defaultValue="#FFFFFF"
                disablePlainColor
                onChange={onChangeColor}
                value={cor}
            />
        </div>
    );
};
