import { EnumTpEmis } from "./../../../model/enums/enum-tp-emis";
import { usePutPedidoStatus } from "data/api/gestao/pedido-dados/put-pedido-status";
import { useGetPessoaByDoc } from "data/api/gestao/pessoa/get-pessoa-by-doc";
import { usePostNovaVenda } from "data/api/gestao/venda/post-nova-venda";
import { useGetUltimaNumeracao } from "data/api/gestao/ultima-numerao/get-ultima-numeracao";
import { useDeleteCancelarVenda } from "data/api/gestao/venda/delete-cancelar-venda";
import { usePutFinalizarVenda } from "data/api/gestao/venda/put-finalizar-venda";
import { TabelaNumeracao } from "database/interfaces/interface-tabela-numeracao";
import { TouchoneDBPrimary } from "database/touchone-database";
import { isEmpty, toInteger } from "lodash";
import {
  EnumCadastroTipo,
  EnumMovModelo,
  EnumPagTpMod,
  EnumPagTpTransacao,
  RetornoApiModel
} from "model";
import {
  MovDescAcrecInfoModel,
  MovSimplesModel
} from "model/api/gestao/movimentacao/simples/mov-simples-model";
import { MovSimplesPagamentoModel } from "model/api/gestao/movimentacao/simples/mov-simples-pagamento-model";
import {
  MovSimplesProdutoModel,
  RastroVenda
} from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { MovNumeracaoModel } from "model/api/gestao/movimentacao/utils/mov-numeracao";
import { PedidoModel } from "model/api/gestao/pedido/pedido-model";
import {
  PedidoProdutoModelVenda,
  RastroPedido
} from "model/api/gestao/pedido/pedido-produto-model";
import { PessoaContatosModel, PessoaModel } from "model/api/gestao/pessoa";
import { VendaModel } from "model/api/gestao/venda/venda-model";
import { VendaPagsModel } from "model/api/gestao/venda/venda-pags-model";
import { VendaProdutoModel } from "model/api/gestao/venda/venda-produto-model";
import { FinalizarVendaModel } from "model/app/mov-simples/finalizar-venda-model";
import { FluxoVendaModel } from "model/app/mov-simples/fluxo-venda-model";
import { PaymentSuccessModel } from "model/app/pagamento-cartao/payment-success";
import { AppEventEnum } from "model/enums/enum-app-event";
import { EnumBalanca } from "model/enums/enum-balanca";
import { EnumIndStatusMovPag } from "model/enums/enum-indstatus-movpag";
import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { EnumTipoMovimentacao } from "model/enums/enum-tipo-movimentacao";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { validarCPFCNPJ } from "utils/cpfcnpj-validate";
import { guidEmpty } from "utils/guid-empty";
import { picker } from "utils/picker";
import {
  isPlanoConsumacao,
  isPlanoControleMesasComandas,
  isPlanoFarmaceutico,
  isPlanoFiscal
} from "utils/plano-utils";
import { roundTo } from "utils/round-to";
import { dataAtual } from "utils/to-date";
import { useCadastroPadrao } from "./cadastro-padrao";
import { useDevice } from "./device";
import { useEventTools } from "./events/event-tools";
import { useNumeracao } from "./gerenciar-numeracao";
import { useVenda } from "./gerenciar-venda";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useMovAnteriorStorage } from "./mov-anterior-storage";
import { useMovAtualStorage } from "./mov-atual-storage";
import { useMovInsercaoStorage } from "./mov-insercao-storage";
import { useMovSimples } from "./mov-simples";
import { usePDV } from "./pdv";
import { useToastSaurus } from "./toast-saurus";
import { TabelaNCM } from "database/interfaces/interface-tabela-ncm";
import { calcVTrib } from "./utils/calcVTrib";
import { useSessaoPDV } from "./sessao-pdv";
import { useSincronizacaoGeral } from "./sincronizar-dados";
import { EnumSincronizacaoGeralStatus } from "model/enums/enum-sincronizacao-geral-status";
import { calcPercent } from "utils/calc-percent";
import { newGuid } from "utils/new-guid";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";
import { EnumTpProduto } from "model/enums/enum-tp-produto";
import { EnumIndDesperdicio } from "model/enums/enum-ind-desperdicio";
import { EnumStatusSituacaoPedido } from "model/enums/enum-status-situacao-pedido";
import { useDeleteFechamentoParcialPedido } from "data/api/gestao/pedido/delete-fechamento-parcial-pedido";
import { consoleDev } from "utils/console-dev";
import { EnumTpSincronizacao } from "model/enums/enum-tp-sincronizacao";
import {
  CredenciamentoSafra,
  FinalizadoraModel
} from "model/api/gestao/finalizadora/finalizadora-model";
import { TabelaProdutos } from "database/interfaces/interface-tabela-produtos";
import { useCadastros } from "./cadastros";
import { VariaveisAmbiente } from "config";
import { EnumDeviceType } from "model/enums/enum-device-type";
import useFirebase, { EnumColectionFireBase } from "./firebase";
import { EnumPDVConfigCod } from "model/enums/enum-pdv-config";
import { PontosVendaCompletoModel } from "model/app/ponto-venda/ponto-venda-completo-model";
import { useContratoAtual } from "./contrato-atual";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";
import { useSessaoAtual } from "../providers/sessao-atual";
import { EnumTpCalculoModificador } from "model/enums/enum-tpcalculo-modificador";
import { AdicionarDescontoAcrescimoFormModel } from "model/app/forms/adicionar-desconto-acrescimo/adicionar-desconto-acrescimo-form-model";
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo";
import { toDecimal } from "utils/to-decimal";
import { stringNumeros } from "utils/string-numeros";
import { useEmpresaAtual } from "./empresa-atual";
import { EnumTipoPessoaContato } from "model/enums/enum-tipo-pessoa-contato";
import { MedicamentoPreco } from "model/api/gestao/medicamento/medicamento-model";
import { EnumTarja } from "model/enums/enum-tarja";
import {
  CobrModel,
  DupCobrModel,
  ReceitaMedicaModel
} from "model/api/gestao/venda/venda-completa-model";
import { useMenuFavoritos } from "./menu-favoritos";
import { MovSimplesPessoaModel } from "model/api/gestao/movimentacao/simples/mov-simples-pessoa-model";
import { EnumInsercaoFavorita } from "model/enums/enum-insercao-favorita";
import { EnumModeloDeTrabalho } from "model/enums/enum-modelo-de-trabalho";
import { EnumTipoFinalizacaoPDV } from "model/enums/enum-tipo-finalizacao-pdv";
import { EnumIdeStatus } from "model/enums/enum-ide-status";

export function useMovAtual() {
  //CHAMADAS DE API
  const { getPessoaByDoc, carregando: carregandoGetPessoaByDoc } =
    useGetPessoaByDoc();
  const { getConsumidor } = useCadastroPadrao();
  const { postNovaVenda, carregando: postNovaVendaCarregando } =
    usePostNovaVenda();
  const { putFinalizarVenda, carregando: putFinalizarVendaCarregando } =
    usePutFinalizarVenda();
  const { deleteCancelarVenda, carregando: deleteCancelarVendaCarregando } =
    useDeleteCancelarVenda();

  const {
    getUltimaNumeracao: UltimaNumeracao,
    carregando: carregandoGetUltimaNumeracao
  } = useGetUltimaNumeracao();
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();
  const { deleteFechamentoParcialPedido, carregando: deleteFechamentoParcial } =
    useDeleteFechamentoParcialPedido();
  const { delFavoritoBackup } = useMenuFavoritos();

  // OFF
  //PROVIDERS E OUTROS AUXILIARES
  const { getEmpresaSelecionada, getPessoa, plano } = useSessaoAtual();
  const { getEmpresaAtual, getConfigByCod: getConfigEmp } = useEmpresaAtual();
  const { startPayment, carregando: carregandoDevice, printType } = useDevice();
  const {
    getPDV,
    aplicaTaxaServico,
    getConfigByCod,
    carregando: carregandoPDV,
    selClienteDesativado,
  } = usePDV();
  const { getInsercaoFavorita } = useMovInsercaoStorage();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const planoFiscal = isPlanoFiscal(plano?.plano);
  const planoMesasComandas = isPlanoControleMesasComandas(plano?.plano);
  const empresaAtual = getEmpresaAtual();
  const location = useLocation();
  const { imprimirCupom, imprimirTicket } = useMovSimples();
  const { getRegistro, setRegistro } = useGestaoStorage();
  const { get: getMovStorage, persist: persistMovStorage } =
    useMovAtualStorage();
  const { persist: persistMovAnterior } = useMovAnteriorStorage();
  const { getNumeracao, setNumeracao } = useNumeracao();
  const { callEvent } = useEventTools();
  const { setVenda, cancelarVenda, getVendas } = useVenda();
  const { getSessao } = useSessaoPDV();
  const { getProdutoServico, getProdutoEntrega } = useCadastroPadrao();
  const {
    abrirDialogPix,
    abrirDialogCredenciarPix,
    fecharDialogCredenciarPix,
    abrirDialogImpressaoNfe
  } = useCadastros();
  const { getStatusSincronizacaoAtual, iniciarSincronizacaoGeral } =
    useSincronizacaoGeral();

  const { getConfigByCod: getConfigContratoByCod } = useContratoAtual();
  const { logError } = useFirebase();

  const tpSincronizacao = getConfigEmp(EnumEmpresaConfig.TipoSincronizacao);

  const carregando =
    carregandoGetPessoaByDoc ||
    putFinalizarVendaCarregando ||
    deleteCancelarVendaCarregando ||
    postNovaVendaCarregando ||
    carregandoPDV ||
    carregandoPutPedidoStatus ||
    deleteFechamentoParcial ||
    carregandoGetUltimaNumeracao;

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  consoleDev("MovAtual");

  const getMov = useCallback((): MovSimplesModel | undefined => {
    return getMovStorage();
  }, [getMovStorage]);

  const aplicaTaxaServicoMov = useCallback(() => {
    let aplicaTaxa = true
    const mov = getMov()
    const modeloVenda = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho)

    if (!mov && isEmpty(mov)) {
      return false
    }

    if (modeloVenda === '0') {
      aplicaTaxa = aplicaTaxaServico()
    } else {
      const hasPedidosImportados = (mov?.informacoesGeraisPedido?.pedidos ?? []).length > 0
      if (hasPedidosImportados) {
        aplicaTaxa = aplicaTaxaServico()
      }
    }
    return aplicaTaxa
  }, [aplicaTaxaServico, getConfigByCod, getMov])

  const getUltimaNumeracao = useCallback(
    async (
      pdv: PontosVendaCompletoModel | undefined,
      mod: number | null
    ): Promise<MovNumeracaoModel | undefined> => {
      let tpAmb = 2;
      // PROCURANDO O TIPO DO AMBIENTE NAS CONFIGURACOES DO EMISSOR
      const emissor = getPDV()?.emissor;
      if (emissor) {
        tpAmb = toInteger(emissor?.tpAmb ?? "2");
      }

      if (pdv === undefined) {
        throw new Error("Não foi possível identificarmos seu ponto de venda!");
      }

      const tipoFinalizacaoPDV = getConfigEmp(
        EnumEmpresaConfig.TipoFinalizacaoVendaPDV
      );

      if (
        mod === EnumMovModelo.NFCE ||
        mod === EnumMovModelo.NFE ||
        tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL ||
        tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE
      ) {
        const numeracaoAPI = await UltimaNumeracao(
          getEmpresaSelecionada()!.Id,
          `${mod ?? getMov()!.mod}`,
          `${+pdv!.numCaixa + 100}`,
          `${tpAmb}`
        );

        if (numeracaoAPI.erro) {
          if (mod === EnumMovModelo.ORCAMENTO) {
            // Tento obter a numeração local
            let numeracaoLocal: TabelaNumeracao | undefined = undefined;
            let respostaNumeradaAPI: RetornoApiModel | undefined = undefined;

            // TODO: IF TEMPORARIO PARA DESABILITAR BUSCA DA NUMERACAO OFFLINE
            if (
              getMov()!.mod === EnumMovModelo.NFCE ||
              getMov()!.mod === EnumMovModelo.NFE
            ) {
              numeracaoLocal = undefined;
            } else {
              numeracaoLocal = await getNumeracao(
                mod ?? getMov()!.mod ?? 10,
                +pdv!.numCaixa + 100,
                tpAmb
              );
            }

            if (!numeracaoLocal) {
              respostaNumeradaAPI = await UltimaNumeracao(
                getEmpresaSelecionada()!.Id,
                `${mod ?? getMov()!.mod}`,
                `${+pdv!.numCaixa + 100}`,
                `${tpAmb}`
              );

              if (
                respostaNumeradaAPI.erro ||
                respostaNumeradaAPI === undefined
              ) {
                if (
                  getMov()!.mod === EnumMovModelo.NFCE ||
                  getMov()!.mod === EnumMovModelo.NFE
                ) {
                  throw new Error("Erro ao buscar ultima numeração");
                }
                // SE NÃO CONSEGUIR BUSCAR NA API, RETORNO UNDEFINED PARA QUEM USA TRATAR.
                return undefined;
              } else {
                try {
                  await setNumeracao(
                    respostaNumeradaAPI.resultado?.data.mod,
                    respostaNumeradaAPI.resultado?.data.serie,
                    tpAmb,
                    respostaNumeradaAPI.resultado?.data.nnf
                  );
                } catch (e: any) { }
              }
            }

            const ultimo = new MovNumeracaoModel();

            ultimo.modelo = numeracaoLocal
              ? numeracaoLocal?.mod ?? 10
              : respostaNumeradaAPI?.resultado?.data.mod;
            ultimo.nNf = numeracaoLocal
              ? numeracaoLocal?.numero
              : respostaNumeradaAPI?.resultado?.data.nnf;
            ultimo.serie = numeracaoLocal
              ? numeracaoLocal?.serie
              : respostaNumeradaAPI?.resultado?.data.serie;
            ultimo.tpAmb = tpAmb;

            return ultimo;
          }
          throw new Error("Não foi possível identificar a numeração");
        }

        const ultimoNumeroApi = new MovNumeracaoModel();

        ultimoNumeroApi.modelo = numeracaoAPI?.resultado?.data.mod;
        ultimoNumeroApi.nNf = numeracaoAPI?.resultado?.data.nnf;
        ultimoNumeroApi.serie = numeracaoAPI?.resultado?.data.serie;
        ultimoNumeroApi.tpAmb = tpAmb;

        return ultimoNumeroApi;
      }

      // Tento obter a numeração local
      let numeracaoLocal: TabelaNumeracao | undefined = undefined;
      let respostaNumeradaAPI: RetornoApiModel | undefined = undefined;

      // TODO: IF TEMPORARIO PARA DESABILITAR BUSCA DA NUMERACAO OFFLINE
      if (
        getMov()!.mod === EnumMovModelo.NFCE ||
        getMov()!.mod === EnumMovModelo.NFE
      ) {
        numeracaoLocal = undefined;
      } else {
        numeracaoLocal = await getNumeracao(
          mod ?? getMov()!.mod ?? 10,
          +pdv!.numCaixa + 100,
          tpAmb
        );
      }

      if (!numeracaoLocal) {
        respostaNumeradaAPI = await UltimaNumeracao(
          getEmpresaSelecionada()!.Id,
          `${mod ?? getMov()!.mod}`,
          `${+pdv!.numCaixa + 100}`,
          `${tpAmb}`
        );

        if (respostaNumeradaAPI.erro || respostaNumeradaAPI === undefined) {
          if (
            getMov()!.mod === EnumMovModelo.NFCE ||
            getMov()!.mod === EnumMovModelo.NFE
          ) {
            throw new Error("Erro ao buscar ultima numeração");
          }
          // SE NÃO CONSEGUIR BUSCAR NA API, RETORNO UNDEFINED PARA QUEM USA TRATAR.
          return undefined;
        } else {
          try {
            await setNumeracao(
              respostaNumeradaAPI.resultado?.data.mod,
              respostaNumeradaAPI.resultado?.data.serie,
              tpAmb,
              respostaNumeradaAPI.resultado?.data.nnf
            );
          } catch (e: any) { }
        }
      }

      const ultimo = new MovNumeracaoModel();

      ultimo.modelo = numeracaoLocal
        ? numeracaoLocal?.mod ?? 10
        : respostaNumeradaAPI?.resultado?.data.mod;
      ultimo.nNf = numeracaoLocal
        ? numeracaoLocal?.numero
        : respostaNumeradaAPI?.resultado?.data.nnf;
      ultimo.serie = numeracaoLocal
        ? numeracaoLocal?.serie
        : respostaNumeradaAPI?.resultado?.data.serie;
      ultimo.tpAmb = tpAmb;

      return ultimo;
    },
    [
      UltimaNumeracao,
      getConfigEmp,
      getEmpresaSelecionada,
      getMov,
      getNumeracao,
      getPDV,
      setNumeracao
    ]
  );

  const getUltimoProduto = useCallback(
    async (produtoGradeId: string) => {
      const movAtual = getMov();
      if (isEmpty(movAtual?.produtos)) return undefined;

      const produtos = movAtual!.produtos.filter(
        (x) => x.produtoGradeId === produtoGradeId && x.ativo
      );

      if (produtos.length === 0) return undefined;

      return produtos[produtos.length - 1];
    },
    [getMov]
  );

  const criarProdutoTaxa = useCallback(
    async (vendedorId: string | null, qtdProd: number) => {
      const produtoIndexDb = await getProdutoServico();

      if (!produtoIndexDb || !produtoIndexDb?.ativo) {
        return;
      }

      const getVendedor = await TouchoneDBPrimary.clientes.get({
        id: vendedorId ?? ""
      });

      const movAtual = getMov();

      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      // let quantidadeProdutosNoCarrinho = qtdProd
      let NCMIndex;
      // quantidadeProdutosNoCarrinho += 1

      if (produtoIndexDb?.ncm ?? 0) {
        // NCM DO PRODUTO
        NCMIndex = await TouchoneDBPrimary.ncms.get({
          codigo: produtoIndexDb?.ncm ?? 0
        });
      }

      const categoria = await TouchoneDBPrimary.categorias.get({
        id: produtoIndexDb?.categoriaId ?? ""
      });

      const produtoVenda: MovSimplesProdutoModel = {
        ...new MovSimplesProdutoModel(),
        ativo: true,
        balanca: produtoIndexDb?.balanca ?? 0,
        cEan: "",
        cProd: produtoIndexDb?.codigo ?? "",
        cProdKit: "",
        categoria: categoria?.descricao ?? "",
        grupoImpostoId: produtoIndexDb?.grupoImpostoId ?? "",
        id: newGuid(),
        imgUrl: produtoIndexDb?.imagemUrl ?? "",
        infAdic: "",
        ncm: produtoIndexDb?.ncm ?? "",
        ncmId: produtoIndexDb?.ncmId ?? "",
        pedidoId: "",
        produtoGradeId: produtoIndexDb?.produtoGradeId ?? "",
        produtoId: produtoIndexDb?.produtoId ?? "",
        qCom: 1,
        tabelaPrecoId: movAtual?.cliente?.tabelaPrecoId ?? "",
        uCom: produtoIndexDb?.medida ?? "",
        vAcrescUsuario: 0,
        vDescUsuario: 0,
        vFinal: 0,
        vFrete: 0,
        vProd: 0,
        vSeg: 0,
        vUnCom: 0,
        vendedorId: vendedorId || null,
        vendedorNome: getVendedor?.nome ?? "",
        xProd: produtoIndexDb?.nome ?? "",
        nSeq: 0,
        temImposto: false,
        comandaId: "",
        mesaId: "",
        setorId: produtoIndexDb?.setorId ?? "",
        salaoId: "",
        pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
        pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
        pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
        pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
        vTrib: 0,
        taxaServico: 0,
        valorServico: 0,
        cobraTaxaServico: false,
        indFin: true,
        subItens: [],
        adicionais: [],
        idAdicional: null,
        idDoProdutoPaiInfoSubItem: null,
        idGroup: null,
        infoSubItem: null,
        prodSubItem: [],
        produtoPaiId: null,
        tpProduto: EnumTpProduto.Produto,
        validacaoSubItem: true,
        indDesperdicio: EnumIndDesperdicio.NaoSeAplica,
        nivel: 0,
        produtoIdReferencia: produtoIndexDb?.produtoId ?? null,
        quantidadeMax: 1,
        codigoComanda: null,
        mesaCodigo: null,
        modificadores: [],
        modificadorId: null,
        vUnComOrig: 0,
        qComModificador: 0,
        ordem: 0,
        modificadorUnicoId: "",
        cProdANVISA: null,
        nSeqReceitaMedica: null,
        ignorarReceituario: true,
        rastro: null
      };

      return produtoVenda;
    },
    [getMov, getProdutoServico]
  );

  const calcularTaxaServico = useCallback(
    async (mov: MovSimplesModel) => {
      const servico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico);

      const ret = mov.produtos.filter((x) => x.produtoId === servico);

      const vendedores = Array.from(
        new Set<string>(
          mov.produtos
            .filter((x) => x.cobraTaxaServico && x.ativo && x.indFin && x.taxaServico && x.taxaServico > 0)
            .map((x) => x.vendedorId || "")
        )
      );

      const naoTemProdTaxa = vendedores
        .map((x) => (x.length > 0 ? x : null))
        .filter(
          (idVendedor) =>
            !ret.map((prodTaxa) => prodTaxa.vendedorId).includes(idVendedor)
        );

      if (naoTemProdTaxa.length > 0) {
        for await (const idVendedor of naoTemProdTaxa) {
          const novoProdTaxa = await criarProdutoTaxa(
            idVendedor,
            mov.produtos.length
          );

          if (!isEmpty(novoProdTaxa)) mov.produtos.push(novoProdTaxa);
        }
      }

      for (const x in mov.produtos) {
        if (mov.produtos[x].produtoId === servico) {
          let vFinal = 0;
          let vProd = 0;
          const produtosPraTaxa = mov.produtos.filter(
            (y) =>
              y.ativo &&
              y.vendedorId === mov.produtos[x].vendedorId &&
              y.cobraTaxaServico &&
              y.indFin &&
              y.taxaServico &&
              y.taxaServico > 0
          );
          produtosPraTaxa.forEach((x) => {
            const percent = calcPercent(x.vFinal, x.taxaServico ?? 0) ?? 0;
            vFinal += percent;
            vProd += percent;
          });
          mov.produtos[x] = {
            ...mov.produtos[x],
            vFinal: roundTo(vFinal),
            vProd: roundTo(vProd),
            vUnCom: roundTo(vFinal),
            indFin: roundTo(vFinal) <= 0 ? false : true,
            cobraTaxaServico: false
          };
        }
      }
    },
    [criarProdutoTaxa, getConfigContratoByCod]
  );

  const lancandoProduto = useCallback(() => {
    const mov = getMov();
    if (
      !isEmpty(mov?.informacoesGeraisPedido.comandaId) ||
      !isEmpty(mov?.informacoesGeraisPedido.mesaId)
    ) {
      return true;
    }

    return false;
  }, [getMov]);

  const persistMovDados = useCallback(
    async (
      movAtual: MovSimplesModel | undefined,
      naoAlteraTaxa: boolean = false
    ) => {
      if (movAtual) {
        let somaPagsIntegrado = 0
        let qCom = 0;
        let vNF = 0;
        let vnfAux = 0;
        let vTotTrib = 0;
        let qtdeItens = 0;
        let qComItens = 0;
        let vPago = 0;
        let vOrig = 0;

        const produtoIndexDb = await getProdutoServico();

        if (
          planoMesasComandas &&
          produtoIndexDb &&
          !lancandoProduto()
        ) {
          if (
            !naoAlteraTaxa &&
            !isFarmaceutico
          ) {
            await calcularTaxaServico(movAtual)
          }
        }

        movAtual.produtos
          .filter((item) => {
            if (item.idGroup) {
              const findPai = movAtual.produtos.find(prod => prod.id === item.idGroup)
              if (findPai) {
                return findPai.ativo
              }
            } else {
              return item.ativo
            }
            return false
          })
          .forEach((x) => {
            qComItens +=
              x.indFin && !x.produtoPaiId
                ? (x.balanca === EnumBalanca.Normal ||
                  x.balanca === EnumBalanca.Unitario) &&
                  x.tpProduto !== EnumTpProduto.Combo
                  ? x.qCom
                  : 1
                : 0;
            qCom +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.qCom : 0;
            vNF +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vFinal : 0;
            vnfAux +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo
                ? x.qCom * x.vUnCom
                : 0;
            vOrig +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.vProd : 0;
            vTotTrib += x.vTrib;
            qtdeItens +=
              x.indFin && x.tpProduto !== EnumTpProduto.Combo ? x.qCom : 0;
          });

        movAtual.pags.forEach((x) => {
          const filtroPagTpMod = [
            EnumPagTpMod.CARTAO_CREDITO,
            EnumPagTpMod.CARTAO_DEBITO,
            EnumPagTpMod.PAGAMENTO_INSTANTANEO,
            EnumPagTpMod.VALE_ALIMENTACAO,
            EnumPagTpMod.VALE_COMBUSTIVEL,
            EnumPagTpMod.VALE_PRESENTE,
            EnumPagTpMod.VALE_REFEICAO
          ];
          if (filtroPagTpMod.includes(x.modPagamento)) {
            somaPagsIntegrado += x.vPag
          }
          vPago += x.vPag;
        });

        if (isEmpty(movAtual.descInfo)) {
          movAtual.descInfo = new MovDescAcrecInfoModel();
        }

        if (isEmpty(movAtual.acrescInfo)) {
          movAtual.acrescInfo = new MovDescAcrecInfoModel();
        }

        if (!movAtual.cobr) {
          movAtual.cobr = new CobrModel();
        }
        movAtual.cobr = {
          ...movAtual.cobr,
          fat: {
            ...movAtual.cobr.fat,
            vOrig: roundTo(vOrig, 2),
            vDesc: movAtual.vDesc ?? 0,
            vLiq: roundTo(vNF, 2)
          }
        };
        movAtual.dEmi = dataAtual();
        movAtual.qCom = qCom;
        movAtual.vNF = roundTo(vNF, 2);
        movAtual.vnfAux = roundTo(vnfAux, 2);
        movAtual.vTotTrib = vTotTrib;
        movAtual.qtdeItens = qtdeItens;
        movAtual.qComItens = qComItens;
        movAtual.vPago = roundTo(vPago, 2);
        movAtual.vTroco = vPago - vNF > 0 ? roundTo(vPago - vNF) : 0;
        if (somaPagsIntegrado > movAtual.vNF) {
          throw new Error("Valor de pagamentos integrados não pode ser menor do que o valor final da venda ")
        }
        callEvent(AppEventEnum.MovAtualAlterada, movAtual);
      }
      persistMovStorage(movAtual);
    },
    [
      calcularTaxaServico,
      callEvent,
      getProdutoServico,
      isFarmaceutico,
      lancandoProduto,
      persistMovStorage,
      planoMesasComandas
    ]
  );

  const getVendedor = useCallback(async () => {
    return (
      getRegistro(GestaoStorageKeys.VendedorAtual, false) || getPessoa()?.pessoa
    );
  }, [getPessoa, getRegistro]);

  const setVendedor = useCallback(
    async (vendedor: PessoaModel | undefined, substituirProdutos: boolean) => {
      let vend: PessoaModel =
        vendedor || getPessoa()?.pessoa || new PessoaModel();

      setRegistro(GestaoStorageKeys.VendedorAtual, vend, false);
      if (substituirProdutos) {
        const movAtual = getMov();

        movAtual?.produtos.forEach((item) => {
          item.vendedorId = vend.id;
          item.vendedorNome = vend.nome;
        });
        await persistMovDados(movAtual);
      }
    },
    [getMov, getPessoa, persistMovDados, setRegistro]
  );

  const saveQtdPessoasPagamento = useCallback(
    (qtd: number) => {
      setRegistro(
        GestaoStorageKeys.QtdPessoasPagamento,
        {
          pessoas: qtd
        },
        false
      );
    },
    [setRegistro]
  );

  const resetQtdPessoasPagamento = useCallback(() => {
    setRegistro(
      GestaoStorageKeys.QtdPessoasPagamento,
      {
        pessoas: 1
      },
      false
    );
  }, [setRegistro]);

  const getQtdPessoasPagamento = useCallback(() => {
    const obj = getRegistro(GestaoStorageKeys.QtdPessoasPagamento, false);
    return obj;
  }, [getRegistro]);

  const restaurarMov = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (!isEmpty(movAtual)) {
      //TODO: IR A API E CONSULTAR STATUS DA VENDA
    }
  }, [getMov]);

  const preencherClientePadrao = useCallback(
    async (mov: MovSimplesModel) => {
      const consumidorPadrao = await getConsumidor();
      //adicionando o consumidor padrão
      mov.cliente = new MovSimplesPessoaModel();
      mov.cliente.nome = consumidorPadrao?.nome || "";
      mov.cliente.contratoId = consumidorPadrao?.contratoId || guidEmpty();
      mov.cliente.regimeTributarioId =
        consumidorPadrao?.regimeTributarioId || guidEmpty();
      mov.cliente.tabelaPrecoId =
        consumidorPadrao?.tabelaPrecoId || guidEmpty();
      mov.cliente.contatos = [];
      mov.cliente.documentos = [];
      mov.cliente.cpfcnpj = consumidorPadrao?.cpfcnpj || "";
      mov.cliente.id = consumidorPadrao?.id || guidEmpty();
      mov.documento = consumidorPadrao?.cpfcnpj || "";
      mov.pessoaId = consumidorPadrao?.id || guidEmpty();
      mov.regimeTributarioId =
        consumidorPadrao?.regimeTributarioId || guidEmpty();
    },
    [getConsumidor]
  );

  const iniciarMov = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (movAtual) {
      throw new Error(
        "Já existe uma venda em Andamento. Finalize-a para iniciar outra venda."
      );
    }

    const mov = new MovSimplesModel();
    mov.contratoId = getPessoa().pessoa?.contratoId || guidEmpty();
    mov.operadorId = getPessoa()?.pessoa?.id || guidEmpty();
    mov.dEmi = new Date();
    mov.empresaId = getEmpresaSelecionada()?.Id || guidEmpty();

    const tpTrabalhoPdv = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho) as EnumModeloDeTrabalho;

    if ([EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS].includes(tpTrabalhoPdv)) {
      mov.tipoMovimentacao = EnumTipoMovimentacao.PEDIDO;
    }

    const modelo = getConfigByCod(50);
    const selCliente = getConfigByCod(20);

    if (selCliente === "0" && !(modelo === "NF-e")) {
      mov.clienteIdentificado = true;
    }

    if (
      modelo?.toUpperCase() === "SAT CF-E" ||
      modelo?.toUpperCase() === "ECF"
    ) {
      throw new Error(`O modelo "${modelo}" ainda não foi implementado.`);
    }

    switch (modelo?.toUpperCase()) {
      case "NFCE SINCRONO":
      case "NFC-E":
      case "NFCE":
        mov.mod = EnumMovModelo.NFCE;
        break;
      case "NFC-SAT":
        mov.mod = EnumMovModelo.SAT;
        break;
      case "NF-E":
        mov.mod = EnumMovModelo.NFE;
        break;
      default:
        mov.mod = EnumMovModelo.ORCAMENTO;
        break;
    }

    //CONFERENCIA PRA FAZER NAO FISCAL COM PLANO E/CONFIG ERRADA
    if (!(planoFiscal && empresaAtual?.isFiscal)) {
      mov.mod = EnumMovModelo.ORCAMENTO;
    }
    const sessaoID = await getSessao();
    mov.sessaoId = sessaoID?.id ?? null;

    setVendedor(undefined, false);

    resetQtdPessoasPagamento();

    await preencherClientePadrao(mov);
    await persistMovDados(mov);
    callEvent(AppEventEnum.MovAtualAlterada, mov);
  }, [
    callEvent,
    empresaAtual?.isFiscal,
    getConfigByCod,
    getEmpresaSelecionada,
    getMov,
    getPessoa,
    getSessao,
    persistMovDados,
    planoFiscal,
    preencherClientePadrao,
    resetQtdPessoasPagamento,
    setVendedor,
  ]);

  const alterarTpMod = useCallback(
    async (tpMod: EnumMovModelo): Promise<void> => {
      const movAtual = getMov();
      const consumidorPadrao = await getConsumidor();
      if (!movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      movAtual.mod = tpMod;

      if (tpMod === EnumMovModelo.NFE) {
        if (
          consumidorPadrao?.id === movAtual.cliente?.id ||
          guidEmpty() === movAtual.cliente?.id ||
          stringNumeros(consumidorPadrao?.cpfcnpj ?? "") ===
          stringNumeros(movAtual.cliente?.cpfcnpj ?? "")
        ) {
          movAtual.clienteIdentificado = false;
        }
      }
      await persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualAlterada, movAtual);
    },
    [callEvent, getConsumidor, getMov, persistMovDados]
  );

  const setClienteByDoc = useCallback(
    async (
      documento: string,
      setAsIdentificado: boolean = true,
      fillWithConsumidorPadrao: boolean = true
    ): Promise<MovSimplesPessoaModel | string | undefined> => {
      const movAtual = getMov();
      const isVendaNfe = movAtual?.mod === EnumMovModelo.NFE;
      const consumidorPadrao = await getConsumidor();

      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o cliente "${documento}"`
        );
      }

      if (isEmpty(documento)) {
        movAtual.clienteIdentificado = setAsIdentificado;
        if (fillWithConsumidorPadrao) {
          await preencherClientePadrao(movAtual);
        }
        await persistMovDados(movAtual);
        return;
      }

      if (!validarCPFCNPJ(documento)) {
        throw new Error(`O documento ${documento} não é um CPF ou CNPJ válido`);
      }

      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.documento = documento;
      movAtual.cliente!.cpfcnpj = documento;
      let novocliente = new PessoaModel();

      const getCli = await getPessoaByDoc(documento);
      const cliente = getCli.resultado?.data.list?.[0] as PessoaModel | null;


      if (isVendaNfe && !cliente) {
        throw new Error("Não encontramos o cadastro deste CPF/CNPJ");
      }
      if (isVendaNfe && cliente?.id === consumidorPadrao?.id) {
        throw new Error("O cliente deve ser diferente do consumidor padrão.");
      }
      if (cliente) {
        movAtual.clienteIdentificado = true;
        novocliente = cliente;
        movAtual.cliente = {
          ...novocliente,
          endereco: null,
          tpCadastro: novocliente.tpCadastro ?? EnumCadastroTipo.CLIENTE
        };
        movAtual.telefone =
          novocliente.contatos.find((c) => c.tipo === 0)?.valor ?? "";
        movAtual.pessoaId = novocliente.id;
      } else {
        if (fillWithConsumidorPadrao) {
          await preencherClientePadrao(movAtual);
        }
      }

      movAtual.cliente!.cpfcnpj = documento;
      movAtual.documento = documento;

      await persistMovDados(movAtual);

      return cliente ? movAtual.cliente : documento;
    },
    [
      getConsumidor,
      getMov,
      getPessoaByDoc,
      persistMovDados,
      preencherClientePadrao
    ]
  );

  const setDocumentoNaNota = useCallback(
    async (value: boolean) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o documento na nota`
        );
      }

      movAtual.documentoNaNota = value;
      await persistMovDados(movAtual);
    },
    [getMov, persistMovDados]
  );

  const setTelefoneCliente = useCallback(
    async (
      telefone: string,
      cliente: MovSimplesPessoaModel,
      setAsIdentificado: boolean = true
    ): Promise<PessoaModel | string | undefined> => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o telefone "${telefone}"`
        );
      }
      if (isEmpty(telefone)) {
        movAtual.clienteIdentificado = setAsIdentificado;
        if (isEmpty(cliente)) {
          preencherClientePadrao(movAtual);
        } else if (movAtual.cliente && movAtual.cliente.contatos) {
          movAtual.cliente.contatos = movAtual.cliente?.contatos.filter(
            (contato) => contato.tipo === EnumTipoPessoaContato.TELEFONE
          );
        }
        await persistMovDados(movAtual);
        return;
      }

      if (movAtual.cliente) {
        movAtual.cliente.contatos.push({
          ...new PessoaContatosModel(),
          tipo: EnumTipoPessoaContato.TELEFONE,
          valor: telefone
        });
      }
      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.telefone = telefone;

      await persistMovDados(movAtual);

      return telefone;
    },
    [getMov, persistMovDados, preencherClientePadrao]
  );

  const setClientePessoa = useCallback(
    async (
      cliente: MovSimplesPessoaModel,
      setAsIdentificado: boolean = true
    ): Promise<void> => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe venda em aberto para definir o cliente selecionado.`
        );
      }

      if (isEmpty(cliente)) {
        preencherClientePadrao(movAtual);
        await persistMovDados(movAtual);
        return;
      }
      movAtual.cliente = cliente;
      movAtual.regimeTributarioId = cliente.regimeTributarioId || "";
      movAtual.pessoaId = cliente.id || "";
      movAtual.documento = cliente.cpfcnpj;
      movAtual.clienteIdentificado = setAsIdentificado;
      movAtual.telefone =
        cliente.contatos.find((c) => c.tipo === 0)?.valor ?? movAtual.telefone;

      await persistMovDados(movAtual);
    },
    [getMov, persistMovDados, preencherClientePadrao]
  );

  const setTransportadora = useCallback(
    async (transportadora: PessoaModel): Promise<void> => { },
    []
  );

  const rateioDescontoProdutos = useCallback(
    async (venda: MovSimplesModel) => {
      // Buscando produto serviço
      const servico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico);
      const ret = venda!.produtos.filter((x) => x.produtoId === servico);

      let porcentagemDesconto = 0;
      let valorProdutoTotal = 0;
      let totalProdutos = 0;
      let descontoTotal = 0;

      venda?.produtos?.forEach((item) => {
        const produtoServico = ret.find((x) => x.id === item.id);

        if (isEmpty(produtoServico) && item.ativo && item.indFin) {
          totalProdutos += roundTo(item.vProd);
        }
      });

      //Valida se está trabalhando com valor fixo ou porcentagem
      if (venda?.descInfo.tipo === EnumTpDescontoAcrescimo.ValorFixo) {
        let valorTotalVenda = totalProdutos ?? 0;
        porcentagemDesconto =
          ((venda?.descInfo.vValorFixo ?? 0) / valorTotalVenda) * 100;
      } else if (venda?.descInfo.tipo === EnumTpDescontoAcrescimo.Porcentagem) {
        porcentagemDesconto = venda?.descInfo.vPorcentagem ?? 0;
      } else {
        porcentagemDesconto = 0;
      }

      //Rateio dos descontos por produto
      const prods = venda?.produtos?.map((item) => {
        let desconto = 0;
        const produtoServico = ret.find((x) => x.id === item.id);

        if (item.indFin && isEmpty(produtoServico) && item.ativo) {
          desconto = roundTo(
            (item.indAcrescDesc ? item.vProd - item.vDescUsuario : item.vProd) *
            (porcentagemDesconto / 100)
          );

          item.vDescUsuario = roundTo(
            item.indAcrescDesc ? item.vDescUsuario + desconto : desconto
          );

          item.vFinal = roundTo(
            item.vProd - item.vDescUsuario + item.vAcrescUsuario, 2
          );
          valorProdutoTotal += roundTo(item.vProd - item.vDescUsuario);
          descontoTotal += item.vDescUsuario;
          return item;
        }

        return item;
      });

      let valorDescontoCalculado = roundTo(
        totalProdutos * (porcentagemDesconto / 100)
      );

      //Valida se tem diferença nos descontos
      if (valorProdutoTotal !== totalProdutos - valorDescontoCalculado) {
        let diferenca = roundTo(
          totalProdutos - valorDescontoCalculado - valorProdutoTotal
        );

        const diferencaProds = prods?.filter((x) => x.vDescUsuario < diferenca);

        if (!isEmpty(diferencaProds)) {
          roundTo((diferencaProds[0].vDescUsuario -= diferenca));
        }
      }

      venda!.produtos = prods ?? [];
      venda!.vDesc = descontoTotal;
      await persistMovDados(venda);
    },
    [getConfigContratoByCod, persistMovDados]
  );

  const rateioAcrescimoProduto = useCallback(
    async (venda: MovSimplesModel) => {
      // Buscando produto serviço
      const servico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico);
      const ret = venda!.produtos.filter((x) => x.produtoId === servico);

      let porcentagemAcrescimo = 0;
      let valorProdutoTotal = 0;
      let totalProdutos = 0;
      let acrescimoTotal = 0;

      venda?.produtos?.forEach((item) => {
        const produtoServico = ret.find((x) => x.id === item.id);

        if (isEmpty(produtoServico) && item.ativo && item.indFin) {
          totalProdutos += roundTo(item.vProd);
        }
      });

      //Valida se está trabalhando com valor fixo ou porcentagem
      if (venda!.acrescInfo.tipo === EnumTpDescontoAcrescimo.ValorFixo) {
        let valorTotalVenda = totalProdutos ?? 0;
        porcentagemAcrescimo =
          ((venda?.acrescInfo.vValorFixo ?? 0) / valorTotalVenda) * 100;
      } else if (
        venda?.acrescInfo.tipo === EnumTpDescontoAcrescimo.Porcentagem
      ) {
        porcentagemAcrescimo = venda?.acrescInfo.vPorcentagem ?? 0;
      } else {
        porcentagemAcrescimo = 0;
      }

      //Rateio dos acrescimos por produto
      const prods = venda?.produtos?.map((item) => {
        let acrescimo = 0;

        const produtoServico = ret.find((x) => x.id === item.id);

        if (item.indFin && isEmpty(produtoServico) && item.ativo) {
          acrescimo = roundTo(
            (item.indAcrescDesc
              ? item.vProd + item.vAcrescUsuario
              : item.vProd) *
            (porcentagemAcrescimo / 100)
          );

          item.vAcrescUsuario = roundTo(
            item.indAcrescDesc ? item.vAcrescUsuario + acrescimo : acrescimo
          );

          item.vFinal = item.vProd - item.vDescUsuario + item.vAcrescUsuario;
          valorProdutoTotal += roundTo(item.vProd + item.vAcrescUsuario);
          acrescimoTotal += item.vAcrescUsuario;
          return item;
        }

        return item;
      });

      let valorAcrescimoCalculado = roundTo(
        totalProdutos * (porcentagemAcrescimo / 100)
      );

      //Valida se tem diferença nos acrescimos
      if (valorProdutoTotal !== totalProdutos + valorAcrescimoCalculado) {
        let diferenca = roundTo(
          totalProdutos + valorAcrescimoCalculado + valorProdutoTotal
        );

        const diferencaProds = prods?.filter(
          (x) => x.vAcrescUsuario > diferenca
        );

        if (!isEmpty(diferencaProds)) {
          (diferencaProds ?? [])[0].vAcrescUsuario += diferenca;
        }
      }

      venda!.produtos = prods ?? [];
      venda!.vAcresc = acrescimoTotal;
      await persistMovDados(venda, true);
    },
    [getConfigContratoByCod, persistMovDados]
  );

  const recalcularDesconto = useCallback(async () => {
    const mov = getMov();
    try {
      await rateioDescontoProdutos(mov!);
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getMov, rateioDescontoProdutos, showToast]);

  const recalcularAcrescimo = useCallback(async () => {
    const mov = getMov();
    try {
      await rateioAcrescimoProduto(mov!);
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getMov, rateioAcrescimoProduto, showToast]);

  const inserirProduto = useCallback(
    async (
      produto: MovSimplesProdutoModel
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const idProdutoTaxa = getConfigContratoByCod(
        EnumContratoConfig.ProdutoServico
      );
      const idProdutoEntrega = getConfigContratoByCod(
        EnumContratoConfig.ProdutoEntrega
      );

      if (idProdutoTaxa === produto.produtoId) {
        throw new Error("Não é possível inserir o produto Taxa de Serviço.");
      }

      if (idProdutoEntrega === produto.produtoId) {
        throw new Error("Não é possível inserir o produto Taxa de Serviço.");
      }

      if (produto.qCom < 0 && produto.vFinal < 0 && produto.vUnCom < 0) {
        throw new Error("Os valores do produto informado estão inválidos.");
      }
      const produtosMaiorQueZero = movAtual.produtos.filter((x) => x.nSeq > 0);
      produto.nSeq =
        (produtosMaiorQueZero[produtosMaiorQueZero.length - 1]?.nSeq ?? 0) + 1;
      movAtual.produtos.push(produto);

      await persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      const isValorDesc =
        (movAtual?.descInfo.vPorcentagem ?? 0) > 0 ||
        (movAtual?.descInfo.vValorFixo ?? 0) > 0;
      const isValorACres =
        (movAtual?.acrescInfo.vPorcentagem ?? 0) > 0 ||
        (movAtual.acrescInfo.vValorFixo ?? 0) > 0;

      if (isValorDesc && movAtual.vDesc > 0) {
        await recalcularDesconto();
      }

      if (isValorACres && movAtual.vAcresc > 0) {
        await recalcularAcrescimo();
      }

      return produto;
    },
    [
      callEvent,
      getConfigContratoByCod,
      getMov,
      persistMovDados,
      recalcularAcrescimo,
      recalcularDesconto
    ]
  );

  const alterarProduto = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      naoAlteraTaxa: boolean = false
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      const ret = movAtual.produtos.find((x) => x.id === produto.id);

      if (!ret) {
        throw new Error("O produto informado não foi localizado na venda.");
      }

      if (ret.produtoGradeId !== produto.produtoGradeId) {
        throw new Error(
          "Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente"
        );
      }

      var index = movAtual.produtos.indexOf(ret);
      movAtual.produtos[index] = produto;

      await persistMovDados(movAtual, naoAlteraTaxa);
      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      return produto;
    },
    [callEvent, getMov, persistMovDados]
  );

  const removerProdutoComSubItens = useCallback(
    async (
      produto: MovSimplesProdutoModel
    ): Promise<MovSimplesProdutoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const ret = movAtual.produtos.find((x) => x.nSeq === produto.nSeq);

      if (!ret) {
        throw new Error("O produto informado não foi localizado na venda.");
      }

      if (ret.produtoGradeId !== produto.produtoGradeId) {
        throw new Error(
          "Não é possível alterar o produto original da venda. Realize o cancelamento e faça o processo novamente"
        );
      }

      const filterProducts = movAtual.produtos.filter(
        (p) => p.id !== produto.id && p.idGroup !== produto.id
      );
      movAtual.produtos = filterProducts;
      persistMovDados(movAtual);
      callEvent(AppEventEnum.MovAtualProdAlterado, produto);

      return produto;
    },
    [callEvent, getMov, persistMovDados]
  );

  const alterarSessaoId = useCallback(async (): Promise<void> => {
    const movAtual = getMov();
    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    const sessao = await getSessao();

    if (!sessao) {
      throw new Error("Nenhuma sessão foi identificada.");
    }

    movAtual.sessaoId = sessao.id;
    persistMovDados(movAtual);
  }, [getMov, getSessao, persistMovDados]);

  const getInsercao = useCallback(() => {
    // const isDelivery = () => {
    //   const configuracaoDelivery = getRegistro(GestaoStorageKeys.IsDelivery, false)

    //   if (typeof configuracaoDelivery !== "boolean") {
    //     return false;
    //   }

    //   return configuracaoDelivery;
    // }
    if (
      // !isDelivery() &&
      getInsercaoFavorita() === EnumInsercaoFavorita.ATENDIMENTO_DELIVERY
    ) {
      return "/venda-simples/catalogo";
    }
    return getInsercaoFavorita();
  }, [getInsercaoFavorita]);

  const retornaFluxoVenda = async (): Promise<FluxoVendaModel> => {
    const movAtual = getMov();
    const isVendaNfe = movAtual?.mod === EnumMovModelo.NFE;

    if (isEmpty(movAtual) || !movAtual) {
      return new FluxoVendaModel(
        "/venda-simples/landing",
        "Não existe uma venda em Andamento.",
        location.pathname.indexOf("venda-simples/landing") === -1
      );
    }

    if (movAtual.id !== guidEmpty()) {
      movAtual.id = guidEmpty();

      if (movAtual.tipoMovimentacao !== EnumTipoMovimentacao.VENDA) {
        movAtual.tipoMovimentacao = EnumTipoMovimentacao.VENDA;
      }

      await persistMovDados(movAtual);
    }

    if (movAtual.vNF <= 0) {
      const isError =
        location.pathname.indexOf("venda-simples/") > -1 &&
        location.pathname.indexOf("venda-simples/landing") === -1;

      return new FluxoVendaModel(
        getInsercao(),
        "Não existe nenhum produto no carrinho.",
        isError
      );
    }

    if (
      movAtual.vNF > 0 &&
      movAtual.vPago < movAtual.vNF &&
      getMov()?.tipoMovimentacao === EnumTipoMovimentacao.VENDA
    ) {
      const isError = location.pathname.indexOf("/finalizar-venda") > -1;
      return new FluxoVendaModel(
        "/venda-simples/novo-pagamento",
        "Existe pagamento pendente para a Venda.",
        isError
      );
    }
    const consumidorPadrao = await getConsumidor();

    if (
      isVendaNfe &&
      movAtual.vNF > 0 &&
      (!movAtual.clienteIdentificado ||
        [consumidorPadrao?.id, guidEmpty()].includes(movAtual.cliente?.id))
    ) {
      const isError = location.pathname.indexOf("/finalizar-venda") > -1;
      return new FluxoVendaModel(
        "/venda-simples/identificar-cliente",
        "Identifique o cliente da venda para finalizar, o cliente precisa estar cadastro no sistema.",
        isError
      );
    }

    if (
      movAtual.vNF > 0 &&
      !movAtual.clienteIdentificado &&
      !selClienteDesativado()
    ) {
      const isError = location.pathname.indexOf("/finalizar-venda") > -1;
      return new FluxoVendaModel(
        "/venda-simples/identificar-cliente",
        "Identifique o cliente da venda para finalizar.",
        isError
      );
    }

    if (getMov()?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO) {
      return new FluxoVendaModel(
        "/venda-simples/enviar-pedido",
        "Preencha as informações necessárias para realizar o pedido",
        true
      );
    }

    return new FluxoVendaModel(
      "/venda-simples/finalizar-venda",
      "Venda Disponível para finalização",
      false
    );
  };

  const calcularVTroco = useCallback((movAtual: MovSimplesModel) => {
    if (!movAtual) return;

    let vPago = 0;
    movAtual.pags.forEach((x) => {
      x.vTroco = 0;
      if (x.status === EnumIndStatusMovPag.Aprovado) {
        vPago += x.vPag;
      }
    });

    if (vPago - movAtual.vNF > 0) {
      const pags = movAtual.pags
        .filter(
          (x) =>
            x.modPagamento === EnumPagTpMod.DINHEIRO ||
            x.modPagamento === EnumPagTpMod.OUTRO
        )
        .sort(function (a, b) {
          return b.vPag - a.vPag;
        });
      if (pags[0]) {
        pags[0].vTroco = roundTo(vPago - movAtual.vNF);
      }
    }
  }, []);

  const isPedidoDelivery = useCallback(() => {
    const configuracaoDelivery = getRegistro(
      GestaoStorageKeys.IsDelivery,
      false
    );

    if (typeof configuracaoDelivery !== "boolean") {
      return false;
    }

    return configuracaoDelivery;
  }, [getRegistro]);

  const gerarCobranca = useCallback(
    (mov: MovSimplesModel, pagamento: MovSimplesPagamentoModel) => {
      if (!mov.cobr) {
        return;
      }
      let dup = mov.cobr.dup;
      const nParcelas = pagamento.nParcelas <= 0 ? 1 : pagamento.nParcelas;

      for (let sequencia = 0; sequencia < nParcelas; sequencia++) {
        let newDup = new DupCobrModel();
        const vPag = pagamento.vPag / nParcelas;
        const data = new Date();
        const dVenc = new Date(data.setMonth(data.getMonth() + sequencia));

        newDup.vDup = roundTo(vPag, 2);
        newDup.dVenc = dVenc;
        newDup.nDup = String(dup.length + 1);
        dup.push(newDup);
      }

      mov.cobr.dup = dup;
    },
    []
  );

  const iniciarPagamento = useCallback(
    async (
      pagamento: MovSimplesPagamentoModel,
      naoAlteraTaxa: boolean = false,
      credenciamento: CredenciamentoSafra,
      credenciado?: boolean,
      tpPayments?: EnumPagTpMod[] | undefined
    ): Promise<MovSimplesPagamentoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      if (isPedidoDelivery()) {
        pagamento.status = EnumIndStatusMovPag.Aprovado;
        movAtual.pags.push(pagamento);
        calcularVTroco(movAtual);
        gerarCobranca(movAtual, pagamento);
        await persistMovDados(movAtual, naoAlteraTaxa);
        return pagamento;
      }

      if (
        pagamento.modPagamento !== EnumPagTpMod.DINHEIRO &&
        pagamento.modPagamento !== EnumPagTpMod.OUTRO &&
        pagamento.vPag + movAtual.vPago > movAtual.vNF
      ) {
        throw new Error(
          "O Valor do Pagamento é maior do que o restante para a venda e não permite troco."
        );
      }
      if (
        pagamento.tpTransacao === EnumPagTpTransacao.S2_PAY ||
        (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX &&
          VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA &&
          !isEmpty(tpPayments) &&
          tpPayments?.length === 1 &&
          tpPayments?.[0] !== EnumPagTpMod.DINHEIRO)
      ) {
        return new Promise<MovSimplesPagamentoModel>(
          async (resolve, reject) => {
            try {
              pagamento.status = EnumIndStatusMovPag.Processando;
              await startPayment(
                pagamento.vPag,
                pagamento.modPagamento,
                pagamento.nParcelas,
                credenciamento,
                {
                  erro: async (e: any) => {
                    if (
                      pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX
                    ) {
                      const venda = picker(movAtual, new VendaModel());
                      const token = getRegistro(GestaoStorageKeys.Token, false);

                      await logError(
                        {
                          empresa: getEmpresaAtual()?.nomeFantasia,
                          documento: getEmpresaAtual()?.cpfcnpj,
                          empresaId: getEmpresaSelecionada()?.Id ?? "",
                          venda: venda,
                          vendaString: JSON.stringify(venda),
                          error: e,
                          data: new Date(),
                          token: token
                        },
                        EnumColectionFireBase.PIX
                      );
                    }
                    reject(e);
                  },
                  sucesso: async (e: PaymentSuccessModel) => {
                    if (movAtual) {
                      pagamento.dhTransacao = e.dhTransacao;
                      pagamento.cAut = e.cAut;
                      pagamento.codNsu = e.codNsu;
                      pagamento.envioAPI = e.envioAPI;
                      pagamento.tid = e.tid;
                      pagamento.numCartao = e.numCartao;
                      pagamento.adquirente = e.adquirente;
                      pagamento.nParcelas = e.nParcelas;
                      pagamento.bandeira = e.bandeira;
                      pagamento.nomeCartao = e.nomeCartao;
                      pagamento.status = EnumIndStatusMovPag.Aprovado;
                      pagamento.retornoAPI = e.retornoAPI;
                      pagamento.viaCliente = e.viaCliente;
                      pagamento.viaLojista = e.viaLojista;

                      movAtual.pags.push(pagamento);
                      calcularVTroco(movAtual);
                      gerarCobranca(movAtual, pagamento);
                      await persistMovDados(movAtual, naoAlteraTaxa);
                      resolve(pagamento);
                      return;
                    }
                    reject(new Error("Erro ao interpretar o Pagamento"));
                  }
                }
              );
            } catch (e: any) {
              reject(e);
            }
          }
        );
      }

      if (pagamento.tpTransacao === EnumPagTpTransacao.SAFRA_PIX) {
        if (!navigator.onLine) {
          throw new Error(
            "É necessário conexão com a internet para realizar o PIX."
          );
        }
        if (!credenciado) {
          const sugerirCredenciamento = () =>
            new Promise<boolean>((resolve, reject) => {
              abrirDialogCredenciarPix(
                pagamento.pagamentoId,
                async (finalizadora: FinalizadoraModel) => {
                  try {
                    resolve(true);
                  } catch (e: any) {
                    reject(false);
                  }
                },
                async (message: string) => {
                  if (message.length > 0) showToast("error", message);
                  fecharDialogCredenciarPix();
                  reject(false);
                }
              );
            });

          const resultadoCredenciamento = await sugerirCredenciamento();

          if (!resultadoCredenciamento) {
            throw new Error("Não foi possível prosseguir com o pagamento PIX.");
          }
        }
        return new Promise((resolve, reject) => {
          abrirDialogPix(
            pagamento,
            async () => {
              pagamento.status = EnumIndStatusMovPag.Aprovado;
              movAtual.pags.push(pagamento);
              calcularVTroco(movAtual);
              gerarCobranca(movAtual, pagamento);
              await persistMovDados(movAtual, naoAlteraTaxa);
              resolve(pagamento);
            },
            () => reject(pagamento)
          );
        });
      }

      pagamento.status = EnumIndStatusMovPag.Aprovado;
      movAtual.pags.push(pagamento);
      calcularVTroco(movAtual);
      gerarCobranca(movAtual, pagamento);
      await persistMovDados(movAtual, naoAlteraTaxa);
      return pagamento;
    },
    [
      abrirDialogCredenciarPix,
      abrirDialogPix,
      calcularVTroco,
      fecharDialogCredenciarPix,
      gerarCobranca,
      getEmpresaAtual,
      getEmpresaSelecionada,
      getMov,
      getRegistro,
      isPedidoDelivery,
      logError,
      persistMovDados,
      showToast,
      startPayment
    ]
  );

  const alterarPagamento = useCallback(
    async (
      pagamento: MovSimplesPagamentoModel
    ): Promise<MovSimplesPagamentoModel> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      const pgto = movAtual.pags.find((x) => x.tid === pagamento.tid);
      if (isEmpty(pgto)) {
        throw new Error("O Pagamento informado não foi Identificado");
      }

      const index = movAtual.pags.indexOf(
        pgto || new MovSimplesPagamentoModel()
      );
      movAtual.pags[index] = pagamento;

      calcularVTroco(movAtual);
      await persistMovDados(movAtual);

      return pagamento;
    },
    [calcularVTroco, getMov, persistMovDados]
  );

  // fazendo o picker dos pagamentos para enviar para venda
  const getPagamentoApi = useCallback(async () => {
    const movAtual = getMov();
    if (!movAtual) {
      throw new Error("Não existe uma venda em andamento!");
    }

    return movAtual.pags.map((item) => {
      return {
        ...picker<VendaPagsModel>(item, new VendaPagsModel()),
        viaEstabelecimento: item.viaLojista,
        viaConsumidor: item.viaCliente,
        qtdeParcela: item.nParcelas
      }
    });
  }, [getMov]);

  //fazendo o piker dos produtos para enviar na venda
  const getProdutosApi = useCallback(
    async (mov: MovSimplesModel) => {
      const movAtual = getMov();
      if (isEmpty(movAtual?.produtos) || !movAtual) {
        throw new Error("Não é possivel finalizar uma venda sem produtos.");
      }

      return movAtual.produtos.map((item) => {
        const produtoVenda = picker<VendaProdutoModel>(
          item,
          new VendaProdutoModel()
        );

        produtoVenda.qCom =
          item.infoSubItem?.modificadorTipoCalculo !==
            EnumTpCalculoModificador.Rateia
            ? item.qComModificador > 0
              ? item.qComModificador
              : item.qCom
            : item.qCom;

        if (
          item.infoSubItem?.modificadorTipoCalculo ===
          EnumTpCalculoModificador.Rateia
        ) {
          const vProd = produtoVenda.vProd;
          produtoVenda.vUnCom = +roundTo(vProd / produtoVenda.qCom, 4);
        }

        produtoVenda.vDesc = item.vDescUsuario;
        produtoVenda.vProd = produtoVenda.qCom * produtoVenda.vUnCom;
        produtoVenda.vOutro = item.vAcrescUsuario;
        produtoVenda.cancelado = !item.ativo;

        if (
          !isEmpty(produtoVenda.setorId) &&
          getConfigByCod(EnumPDVConfigCod.DispararSetorFinalizacaoVenda) ===
          "Sim" &&
          isEmpty(item.pedidoId)
        ) {
          mov.tpEmis = EnumTpEmis.NORMAL;
          produtoVenda.enviarSetor = true;
        } else if (!isEmpty(item.pedidoId)) {
          produtoVenda.pedidoId = item.pedidoId;
        }
        const prodPai = movAtual.produtos.find((prod) => {
          return (
            prod.produtoId === produtoVenda.produtoPaiId &&
            !isEmpty(prod.setorId)
          );
        });
        produtoVenda.rastro = item.rastro;
        produtoVenda.med = item.med;

        if (prodPai) {
          produtoVenda.enviarSetor = true;
          produtoVenda.produtoPaiId = prodPai.produtoGradeId;
          //gui disse pra colocar o grade id pra enviar para o KDS
        }

        produtoVenda.depositoId = getPDV()?.depositoId ?? null;

        if (
          item.vProd === 0 ||
          item.vFinal === 0 ||
          item.tpProduto === EnumTpProduto.Combo
        ) {
          produtoVenda.indFin = false;
        }

        return produtoVenda;
      });
    },
    [getConfigByCod, getMov, getPDV]
  );

  const saveNovaVenda = useCallback(
    async (movAtual: MovSimplesModel): Promise<string> => {
      const venda = picker<VendaModel>(movAtual, new VendaModel());
      if (venda.cobr && movAtual.cobr) {
        venda.cobr.dup = movAtual.cobr.dup;
      }
      venda.dEmi = dataAtual()
      venda.pags = (await getPagamentoApi()) || [new VendaPagsModel()];
      venda.produtos = (await getProdutosApi(movAtual)) || [
        new VendaProdutoModel()
      ];
      venda.telefone = venda.telefone ? stringNumeros(venda.telefone) : "";
      venda.receitasMedicas = movAtual.receitasMedicas;

      if (movAtual.tpEmis === EnumTpEmis.NORMAL) {
        const ret = await postNovaVenda(venda, 120000);

        if (ret.erro) {
          const tipoFinalizacaoPDV = getConfigEmp(
            EnumEmpresaConfig.TipoFinalizacaoVendaPDV
          );

          if (
            tipoFinalizacaoPDV ===
            EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL ||
            tipoFinalizacaoPDV ===
            EnumTipoFinalizacaoPDV.OFFLINESEMPREQUEPOSSIVEL
          ) {
            const vendaSalva = await setVenda(venda);
            return vendaSalva.id;
          }
          const token = getRegistro(GestaoStorageKeys.Token, false);
          const erro = {
            mensagem: ret.erro?.message,
            stack: ret.erro?.stack,
            statusCode: ret.statusCode
          };

          const objToLog = {
            empresa: getEmpresaAtual()?.nomeFantasia,
            documento: getEmpresaAtual()?.cpfcnpj,
            empresaId: getEmpresaSelecionada()?.Id ?? "",
            venda: venda,
            vendaId: venda.id,
            vendaString: JSON.stringify(venda),
            erro,
            data: new Date(),
            token,
            mod: venda.mod
          };

          try {
            logError(objToLog, EnumColectionFireBase.VENDAS);
          } catch (e: any) { }
          throw ret.erro;
        }

        await setVenda(venda, new Date().toDateString());
        return ret.resultado!.data.id;
      }

      const vendaSalva = await setVenda(venda);

      return vendaSalva.id;
    },
    [
      getConfigEmp,
      getEmpresaAtual,
      getEmpresaSelecionada,
      getPagamentoApi,
      getProdutosApi,
      getRegistro,
      logError,
      postNovaVenda,
      setVenda
    ]
  );

  const inserirVenda = useCallback(
    async (
      movAtual: MovSimplesModel,
      mod: EnumMovModelo | null = null
    ): Promise<FinalizarVendaModel> => {
      const consumidorPadrao = await getConsumidor();
      const isVendaNfe = movAtual?.mod === EnumMovModelo.NFE;
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      //VALIDA SE A NF TEM VALOR
      if (movAtual.vNF === 0) {
        return {
          error: new Error(
            "Você não possui produtos na venda atual, insira produtos para continuar!"
          ),
          status: "invalid",
          url: getInsercao(),
          adicional: null
        };
      }

      // verificando se o valor pago é maior ou igual o valor da nota
      if (movAtual.vPago < movAtual.vNF) {
        return {
          error: new Error("Finalize o pagamento da venda atual!"),
          status: "invalid",
          url: "/venda-simples/novo-pagamento",
          adicional: null
        };
      }

      if (
        isVendaNfe &&
        movAtual.vNF > 0 &&
        (!movAtual.clienteIdentificado ||
          [consumidorPadrao?.id, guidEmpty()].includes(movAtual.cliente?.id))
      ) {
        return {
          error: new Error("Cliente não identificado!"),
          status: "invalid",
          url: "/venda-simples/identificar-cliente",
          adicional: null
        };
      }

      // validando se já foi definido um cliente para a venda
      if (!movAtual.clienteIdentificado && selClienteDesativado()) {
        return {
          error: new Error("Cliente não identificado!"),
          status: "invalid",
          url: "/venda-simples/identificar-cliente",
          adicional: null
        };
      }

      const getUltimoNro = await getUltimaNumeracao(getPDV(), mod);

      const tipoFinalizacaoPDV = getConfigEmp(
        EnumEmpresaConfig.TipoFinalizacaoVendaPDV
      );

      if (
        (getMov()!.mod === EnumMovModelo.NFCE ||
          getMov()!.mod === EnumMovModelo.NFE) &&
        !getUltimoNro &&
        tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE
      ) {
        throw new Error("Não foi possível identificar a ultima numeração");
      }

      // verificando se o foi possível obter a numeracao
      if (!getUltimoNro) {
        return {
          error: null,
          status: "invalid",
          url: "/venda-simples/numeracao",
          adicional: mod
        };
      }

      movAtual.tpAmb = getUltimoNro.tpAmb;
      movAtual.nnf = getUltimoNro.nNf + 1;
      movAtual.serie = getUltimoNro.serie;
      movAtual.mod = mod ?? getUltimoNro.modelo;
      movAtual.dEmi = dataAtual();
      movAtual.tpEmis =
        ([EnumMovModelo.NFCE, EnumMovModelo.NFE].includes(
          mod || EnumMovModelo.ORCAMENTO
        ) ||
          [EnumMovModelo.NFCE, EnumMovModelo.NFE].includes(
            getUltimoNro.modelo
          ) ||
          tipoFinalizacaoPDV ===
          EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE ||
          tipoFinalizacaoPDV ===
          EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL) &&
          getEmpresaAtual()?.uf
          ? EnumTpEmis.NORMAL
          : EnumTpEmis.OFFLINE;

      if (movAtual.cobr) {
        movAtual.cobr.fat.nFat = String(movAtual.nnf);
      }
      try {
        const id = await saveNovaVenda(movAtual);
        movAtual.id = id;

        return {
          error: null,
          status: "salvo",
          url: "/venda-simples/finalizar-venda/transmitir",
          adicional: null
        };
      } catch (e: any) {
        return {
          error: e,
          status: "error",
          url: "/venda-simples/finalizar-venda/rejeicao",
          adicional: null
        };
      }
    },
    [
      getConfigEmp,
      getConsumidor,
      getEmpresaAtual,
      getInsercao,
      getMov,
      getPDV,
      getUltimaNumeracao,
      saveNovaVenda,
      selClienteDesativado
    ]
  );

  const finalizarMov = useCallback(
    async (mod: number | null = null): Promise<FinalizarVendaModel> => {
      let retornoTransmissao: any = null;
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      let idVenda: string | undefined = undefined;

      try {
        //ZERO A VARIAVEL PARA TER CERTEZA QUE A INFORMAÇÃO SERÁ PREENCHIDA NOVAMENTE OU FICARA VAZIA
        movAtual.retornoFinalizacao = undefined;
        if ((movAtual.id || guidEmpty()) === guidEmpty()) {
          const retInserir = await inserirVenda(movAtual, mod);
          movAtual!.retornoFinalizacao = retInserir;
          await persistMovDados(movAtual, true);

          if (retInserir.status === "invalid") {
            movAtual!.retornoFinalizacao = undefined;
            await persistMovDados(movAtual, true);
            return retInserir;
          }

          //SE VIR ERROR, ARMAZENO NA MOV O ULTIMO ERRO E RETORNO
          if (retInserir.error) {
            throw retInserir.error;
          }
        }

        idVenda = movAtual.id;

        const tipoFinalizacaoPDV = getConfigEmp(
          EnumEmpresaConfig.TipoFinalizacaoVendaPDV
        );

        if (
          movAtual.tpEmis === EnumTpEmis.NORMAL ||
          tipoFinalizacaoPDV ===
          EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE ||
          tipoFinalizacaoPDV === EnumTipoFinalizacaoPDV.ONLINESEMPREQUEPOSSIVEL
        ) {
          const ret = await putFinalizarVenda(idVenda!, 120000);
          if (ret.erro) {
            retornoTransmissao = ret.statusCode;
            if (
              movAtual.mod === EnumMovModelo.NFCE ||
              movAtual.mod === EnumMovModelo.NFE ||
              tipoFinalizacaoPDV ===
              EnumTipoFinalizacaoPDV.ONLINEMAISVALIDACAOESTOQUE
            ) {
              throw ret.erro;
            }
          }
        }

        persistMovAnterior(movAtual);
        resetQtdPessoasPagamento();

        const configAuto = getConfigByCod(EnumPDVConfigCod.ImpressaoComprovante);
        if (configAuto) {
          if (configAuto.toLowerCase() === "automático") {
            if (movAtual.mod === EnumMovModelo.NFE) {
              abrirDialogImpressaoNfe(movAtual.id);
            } else {
              try {
                await imprimirCupom(
                  idVenda!,
                  movAtual.tpEmis,
                  movAtual.mod,
                  true
                );
              } catch (e: any) {
                showToast("error", e.message);
              }
            }
          }
        }

        if (
          getConfigByCod(18) === "1" &&
          isPlanoConsumacao(plano?.plano) &&
          !(movAtual?.informacoesGeraisPedido?.pedidos.length > 0)
        ) {
          try {
            await imprimirTicket(
              movAtual.id,
              movAtual.tpEmis,
              true,
              printType()
            );
          } catch (e: any) {
            showToast("info", e.message);
          }
        }

        await setVendedor(undefined, false);
        await persistMovDados(undefined, true);

        callEvent(AppEventEnum.MovAtualAlterada, undefined);

        // adiciono a numeração utilizada na venda atual.
        await setNumeracao(
          movAtual.mod,
          movAtual.serie,
          movAtual.tpAmb,
          movAtual.nnf
        );

        if (movAtual.informacoesGeraisPedido.pedidos.length > 0) {
          return {
            error: null,
            status: "sucesso",
            url: "/venda-simples/finalizar-pedidos",
            adicional: null
          };
        }

        const vConfig = getEmpresaAtual()?.configuracoes.filter(
          (item) => item.cod === EnumEmpresaConfig.AguardarIniciarVenda
        )[0].vConfig;

        if (vConfig === "-1") {
          const sincEmAndamento = getStatusSincronizacaoAtual();

          if (
            sincEmAndamento &&
            sincEmAndamento.status === EnumSincronizacaoGeralStatus.EmAndamento
          ) {
            history.push("/venda-simples/landing");
            return {
              error: null,
              status: "sucesso",
              url: "/venda-simples/landing",
              adicional: null
            };
          }

          const vendas = await getVendas();

          if (vendas && vendas.length > 0) {
            if (!navigator.onLine) {
              showToast(
                "info",
                "Sem acesso a internet, não foi possível realizar a sincronização."
              );
              return {
                error: null,
                status: "sucesso",
                url: "/venda-simples/landing",
                adicional: null
              };
            }

            if (tpSincronizacao === EnumTpSincronizacao.Automatica) {
              iniciarSincronizacaoGeral();
            }
          }

          return {
            error: null,
            status: "sucesso",
            url: "/venda-simples/landing",
            adicional: null
          };
        }

        return {
          error: null,
          status: "sucesso",
          url: "/venda-simples/finalizar-venda/comprovante",
          adicional: null
        };
      } catch (e: any) {
        movAtual.retornoFinalizacao = {
          error: e.message,
          status: "error",
          url: "/venda-simples/finalizar-venda/rejeicao",
          adicional: retornoTransmissao
        };

        await persistMovDados(movAtual, true);

        return movAtual.retornoFinalizacao;
      }
    },
    [
      getMov,
      getConfigEmp,
      persistMovAnterior,
      resetQtdPessoasPagamento,
      getConfigByCod,
      plano?.plano,
      setVendedor,
      persistMovDados,
      callEvent,
      setNumeracao,
      getEmpresaAtual,
      inserirVenda,
      putFinalizarVenda,
      abrirDialogImpressaoNfe,
      imprimirCupom,
      showToast,
      imprimirTicket,
      printType,
      getStatusSincronizacaoAtual,
      getVendas,
      history,
      tpSincronizacao,
      iniciarSincronizacaoGeral
    ]
  );

  const putCancelarVendaEmAndamento = useCallback(
    async (idVenda: string, motivo: string, tpEmis: EnumTpEmis) => {
      try {
        if (tpEmis === EnumTpEmis.NORMAL) {
          const ret = await deleteCancelarVenda(idVenda, motivo);
          if (ret.erro) {
            throw ret.erro;
          }
        } else {
          await cancelarVenda(idVenda);
        }
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [cancelarVenda, deleteCancelarVenda, showToast]
  );

  const cancelarMov = useCallback(
    async (
      motivo: string,
      urlCallback?: string | undefined | null
    ): Promise<void> => {
      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }
      resetQtdPessoasPagamento();
      if (movAtual.informacoesGeraisPedido.pedidos.length > 0) {
        return history.push({
          pathname: "/venda-simples/cancelar-importacao",
          state: {
            pedidos: movAtual.informacoesGeraisPedido.pedidos,
            motivo: motivo
          }
        });
      }

      movAtual.status = EnumIdeStatus.CANCELADO;

      if (isEmpty(movAtual.produtos)) {
        showToast("success", "Venda descartada!");
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        callEvent(AppEventEnum.MovAtualAlterada, undefined);
        await setVendedor(undefined, false);
        delFavoritoBackup();

        return history.push("/venda-simples/landing");
      }

      const getUltimoNro = await getUltimaNumeracao(getPDV(), null);

      if (!getUltimoNro) {
        showToast("success", "Venda descartada!");
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        await setVendedor(undefined, false);
      }

      movAtual.tpAmb = 2;
      movAtual.nnf = getUltimoNro!.nNf + 1;
      movAtual.serie = getUltimoNro!.serie;
      movAtual.mod = getUltimoNro!.modelo;
      movAtual.dEmi = new Date();
      if (movAtual.cobr) {
        movAtual.cobr.fat.nFat = String(movAtual.nnf);
      }

      let idVenda;
      try {
        if (movAtual.id === guidEmpty()) {
          idVenda = await saveNovaVenda(movAtual);
        } else {
          idVenda = movAtual.id;
        }
        await setNumeracao(
          movAtual.mod,
          movAtual.serie,
          movAtual.tpAmb,
          movAtual.nnf
        );
        await putCancelarVendaEmAndamento(idVenda, motivo, movAtual.tpEmis);
      } finally {
        showToast("success", "Venda cancelada!");
        persistMovAnterior(movAtual);
        await persistMovDados(undefined);
        callEvent(AppEventEnum.MovAtualAlterada, undefined);
        await setVendedor(undefined, false);
        delFavoritoBackup();
        setRegistro(GestaoStorageKeys.IsDelivery, false, false);

        if (urlCallback) {
          return history.push(urlCallback);
        }

        return history.push("/venda-simples/landing");
      }
    },
    [
      callEvent,
      delFavoritoBackup,
      getMov,
      getPDV,
      getUltimaNumeracao,
      history,
      persistMovAnterior,
      persistMovDados,
      putCancelarVendaEmAndamento,
      resetQtdPessoasPagamento,
      saveNovaVenda,
      setNumeracao,
      setRegistro,
      setVendedor,
      showToast
    ]
  );

  const refazerPagamento = useCallback(async () => {
    const movAtual = getMov();

    if (isEmpty(movAtual) || !movAtual) {
      throw new Error("Não existe uma venda em Andamento.");
    }

    const notZero = movAtual?.pags.filter(
      (item) => item.tpTransacao !== EnumPagTpTransacao.NORMAL
    );

    const integradoNormal = movAtual!.pags.filter(
      (item) => item.tpTransacao === EnumPagTpTransacao.NORMAL
    );

    if (integradoNormal.length < 1)
      return showToast("error", "Não há pagamento não integrado.");

    movAtual.pags = notZero;

    showToast("success", "Pagamentos resetados!");

    await persistMovDados(movAtual);

    history.push("/venda-simples/novo-pagamento");
  }, [getMov, history, persistMovDados, showToast]);

  const setMesaId = useCallback(
    async (idMesa: string | null, codMesa?: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a mesa`);
      }

      if (idMesa) {
        if (codMesa) {
          movAtual.informacoesGeraisPedido.codigoMesa = codMesa;
        }
        movAtual.informacoesGeraisPedido.mesaId = idMesa;
        await persistMovDados(movAtual);
        return;
      }

      movAtual.informacoesGeraisPedido.mesaId = idMesa;
      movAtual.informacoesGeraisPedido.balcaoIdentificado = true;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  const setSalaoId = useCallback(
    async (idSalao: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir o salão`);
      }

      movAtual.informacoesGeraisPedido.salaoId = idSalao;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  const setComandaId = useCallback(
    async (idComanda: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a comanda`);
      }

      if (!idComanda && idComanda !== "") {
        throw new Error(`A comanda não foi selecionada`);
      }

      movAtual.informacoesGeraisPedido.comandaId = idComanda;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  const setIdentificadorPager = useCallback(
    async (identificador: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir o pager identificador`);
      }

      movAtual.informacoesGeraisPedido.identificador = identificador;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  //status pra dizer que será ou não usado pager no pedido
  const setarSeUsaIdentificador = useCallback((semIdentiticador: boolean) => {
    setRegistro(GestaoStorageKeys.SemPagerPedido, semIdentiticador, false);
  }, [setRegistro])

  const getSeUsaIdentificador = useCallback((): boolean => {
    return getRegistro(GestaoStorageKeys.SemPagerPedido, false);
  }, [getRegistro])

  const setCodigoComanda = useCallback(
    async (codigo: string) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(`Não existe venda em aberto para definir a comanda`);
      }

      if (!codigo) {
        throw new Error(`A comanda não foi selecionada`);
      }

      movAtual.informacoesGeraisPedido.codigoComanda = codigo;
      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  const setTipoMovimentacao = useCallback(
    async (tipoMov: EnumTipoMovimentacao, naoAlteraTaxa: boolean = false) => {
      try {
        const movAtual = getMov();
        if (!movAtual) {
          return
        }
        movAtual.tipoMovimentacao = tipoMov;
        await persistMovDados(movAtual, naoAlteraTaxa);
        return;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [getMov, persistMovDados, showToast]
  );

  const setPedidoExistente = useCallback(
    async (
      idPedido: string,
      idComanda: string,
      idMesa: string,
      idSalao: string,
      codigoMesa: string,
      codigoComanda: string,
      codigoPedido: string,
    ) => {
      const movAtual = getMov();
      if (!movAtual) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }

      if (!idPedido) {
        throw new Error(`Id do pedido não identificado.`);
      }

      movAtual.id = idPedido;
      movAtual.informacoesGeraisPedido = {
        mesaId: idMesa,
        salaoId: idSalao,
        comandaId: idComanda,
        codigoMesa: codigoMesa ?? "",
        codigoComanda: codigoComanda ?? "",
        balcaoIdentificado: idSalao ? true : false,
        pedidos: [],
        codigoPedido: codigoPedido,
      };

      movAtual.tipoMovimentacao = EnumTipoMovimentacao.PEDIDO;

      await persistMovDados(movAtual);
      return;
    },
    [getMov, persistMovDados]
  );

  const addInfoDoPedidoNoProduto = useCallback((pedidos: PedidoModel[]) => {
    return pedidos.map((pedido) => {
      return pedido.produtos.map((produto) => {
        return {
          ...produto,
          pedidoId: pedido.id,
          comandaId: pedido.comandaId,
          mesaId: pedido.mesaId,
          mesaCodigo: pedido.mesaCodigo,
          codigoComanda: pedido.codigoComanda,
          codigoPedido: pedido.codigoPedido,
        };
      });
    });
  }, []);

  const rastroPedidosParaRastroVenda = (
    rastros: RastroPedido[]
  ): RastroVenda[] => {
    return rastros.map((rastro) => ({
      nLote: rastro.numeroLote,
      qLote: rastro.quantidadeLote,
      dFab: rastro.dataFabricacao,
      dVal: rastro.dataValidade,
      cAgreg: rastro.codigoAgregacao
    }));
  };

  const produtosPedidoParaProdutosVenda = useCallback(
    async (produtosPedido: PedidoProdutoModelVenda[]) => {
      const venda = getMov();

      let quantidadeProdutosNoCarrinho =
        venda?.produtos[venda.produtos.length - 1]?.nSeq ?? 0;

      const produtos: MovSimplesProdutoModel[] = [];

      for await (const produto of produtosPedido) {
        let produtoIndexDb: TabelaProdutos | undefined = undefined;

        if (!produto.plu) {
          produtoIndexDb = await TouchoneDBPrimary.produtos
            .where("produtoId")
            .equals(produto.produtoIdReferencia!)
            .first();
        } else {
          produtoIndexDb = await TouchoneDBPrimary.produtos
            .where("codigo")
            .equals(produto.plu)
            .first();
        }

        quantidadeProdutosNoCarrinho += 1;

        if (!produtoIndexDb) {
          const todosProdutos = await TouchoneDBPrimary.produtos
            .where("numCodigos")
            .above(1)
            .toArray();

          produtoIndexDb = todosProdutos.find((item) =>
            item.codigos?.some((c) => c.codigo === produto.plu)
          );

          if (!produtoIndexDb) {
            throw new Error(
              `Falha na importação, o produto ${produto.descricao} não foi encontrado.`
            );
          }
        }

        const categoria = await TouchoneDBPrimary.categorias.get({
          id: produtoIndexDb?.categoriaId ?? ""
        });

        let NCMIndex: TabelaNCM | undefined = undefined;

        if (produtoIndexDb.ncm) {
          // NCM DO PRODUTO
          NCMIndex = await TouchoneDBPrimary.ncms.get({
            codigo: produtoIndexDb.ncm
          });
        }
        const vFinal =
          (produto.valorUnitario * produto.quantidade ?? 0) -
          produto.valorTotalDesconto +
          produto.valorTotalAdicional;

        const rastro = rastroPedidosParaRastroVenda(produto?.rastros ?? []);

        const produtoVenda: MovSimplesProdutoModel = {
          ...new MovSimplesProdutoModel(),
          ativo: produto.status.codigo === 1 ? true : false,
          balanca: produto.pesoVariavel ? 3 : 0,
          cEan: produto.codigoBarra,
          cProd: produto.plu,
          codigoPedido: produto.codigoPedido,
          cProdKit: "",
          categoria: categoria?.descricao ?? "",
          grupoImpostoId: produtoIndexDb.grupoImpostoId,
          id: produto.codigoReferencia,
          imgUrl: produtoIndexDb.imagemUrl ?? "",
          infAdic: produto.observacao,
          ncm: produtoIndexDb.ncm ?? "",
          ncmId: produtoIndexDb.ncmId ?? "",
          pedidoId: produto.pedidoId,
          produtoGradeId: produtoIndexDb.produtoGradeId,
          produtoId: produtoIndexDb.produtoId,
          qCom: produto.quantidade,
          tabelaPrecoId: venda?.cliente?.tabelaPrecoId ?? "",
          uCom: produtoIndexDb.medida ?? "",
          vAcrescUsuario: produto.valorTotalAdicional,
          vDescUsuario: produto.valorTotalDesconto,
          vFinal: roundTo(vFinal),
          vFrete: produto.valorTotalFrete,
          vProd: produto.valorUnitario * produto.quantidade ?? 0,
          vSeg: 0,
          vUnCom: produto.valorUnitario,
          vendedorId: produto.vendedorId,
          vendedorNome: produto.vendedor,
          xProd: produto.descricao,
          nSeq: quantidadeProdutosNoCarrinho,
          temImposto: false,
          comandaId: produto.comandaId,
          mesaId: produto.mesaId,
          setorId: produto.setorId,
          salaoId: produto.salaoId,
          pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
          pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
          pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
          pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
          vTrib: calcVTrib(
            NCMIndex?.pTribManual ?? 0,
            NCMIndex?.pTribFederal ?? 0,
            NCMIndex?.pTribEstadual ?? 0,
            NCMIndex?.pTribMunicipal ?? 0,
            produto.valorTotal
          ),
          taxaServico: produto.taxaServico,
          valorServico: roundTo(
            calcPercent(roundTo(vFinal), produto.taxaServico) ?? 0
          ),
          cobraTaxaServico: produto.taxaServico > 0,
          subItens: produtoIndexDb?.subItens ?? [],
          prodSubItem:
            produto?.subItens?.length > 0
              ? await produtosPedidoParaProdutosVenda(produto.subItens)
              : [],
          infoSubItem: null,
          idDoProdutoPaiInfoSubItem: produto.produtoPai,
          indFin: produto.indFin,
          produtoPaiId: produto.produtoPai,
          tpProduto: produto.tpProduto.codigo,
          validacaoSubItem: true,
          idAdicional: produto.adicionalId,
          idGroup: produto.groupId,
          adicionais: [],
          indDesperdicio: produto.indDesperdicio.codigo,
          nivel: produto.nivel,
          produtoIdReferencia: produtoIndexDb.produtoId,
          quantidadeMax: produto.quantidade,
          codigoComanda: produto.codigoComanda,
          mesaCodigo: produto.mesaCodigo,
          modificadores: [],
          modificadorId: null,
          vUnComOrig: produtoIndexDb.vPreco,
          qComModificador: 0,
          ordem: 0,
          modificadorUnicoId: "",
          cProdANVISA: produto.codigoAnvisa,
          nSeqReceitaMedica: produto.numeroSequenciaReceitaMedica,
          rastro: rastro.length > 0 ? rastro : null,
          ignorarReceituario: (produto.numeroSequenciaReceitaMedica ?? 0) > 0 ? true : false,
          med: {
            cProdANVISA: produto.codigoAnvisa,
            nSeqReceitaMedica: produto.numeroSequenciaReceitaMedica,
            vendaControlada: produto.numeroSequenciaReceitaMedica ? true : false,
            vPMC: produto.precoMaximoConsumidor === 0 ? 0 : produto.precoMaximoConsumidor,
            xMotivoIsencao: null
          }
        };

        produtos.push(produtoVenda);
      }

      return produtos;
    },
    [getMov]
  );

  const transformarPedidosEmVendaSimples = useCallback(
    async (pedidos: PedidoModel[]) => {
      const venda = getMov();

      if (!venda) {
        showToast("error", "Venda não iniciada");
        return history.push("/venda-simples/landing");
      }

      // setando que a venda recebeu pedidos para importação
      venda.informacoesGeraisPedido.pedidos = [
        ...venda.informacoesGeraisPedido.pedidos,
        ...pedidos
      ];

      const produtos = addInfoDoPedidoNoProduto(pedidos)
        .map((produtos) => produtos)
        .reduce((arr, obj) => arr.concat(obj), []) as PedidoProdutoModelVenda[];

      const produtosVenda = await produtosPedidoParaProdutosVenda(produtos);

      venda.produtos = [...venda.produtos, ...produtosVenda];

      // Cliente
      if (pedidos[0].cliente.cpfCnpj) {
        venda.clienteIdentificado = true;
        venda!.cliente!.id =
          pedidos[0].cliente.referenceId ?? venda!.cliente!.id;
        venda!.cliente!.nome =
          pedidos[0].cliente.nomeFantasia ?? venda!.cliente!.nome;
        venda!.cliente!.cpfcnpj =
          pedidos[0].cliente.cpfCnpj ?? venda!.cliente!.cpfcnpj;
        venda!.cliente!.ierg = pedidos[0].cliente.ieRg ?? venda!.cliente!.ierg;

        venda.documento = pedidos[0].cliente.cpfCnpj ?? venda.documento;
      }

      // receitas
      venda.receitasMedicas = pedidos[0].receitasMedicas;

      venda.tipoMovimentacao = EnumTipoMovimentacao.VENDA;

      await persistMovDados(venda);
    },
    [
      addInfoDoPedidoNoProduto,
      getMov,
      history,
      persistMovDados,
      produtosPedidoParaProdutosVenda,
      showToast
    ]
  );

  const removerItensdoPedidodaVendaSimples = useCallback(
    async (idPedido: string) => {
      const venda = getMov();
      const modeloVenda = getConfigByCod(EnumPDVConfigCod.ModeloTrabalho);

      if (!venda) {
        showToast("error", "Venda não iniciada");
        return history.push("/venda-simples/landing");
      }

      venda.produtos = venda.produtos.filter(
        (produto) => produto.pedidoId !== idPedido
      );
      venda.informacoesGeraisPedido.pedidos =
        venda.informacoesGeraisPedido.pedidos.filter(
          (pedido) => pedido.id !== idPedido
        );
      if (
        venda.informacoesGeraisPedido.pedidos.length <= 0 &&
        modeloVenda !== EnumModeloDeTrabalho.APENAS_CAIXA
      ) {
        venda.tipoMovimentacao = EnumTipoMovimentacao.PEDIDO;
      }
      await persistMovDados(venda);

      callEvent(AppEventEnum.MovAtualProdAlterado, venda);
    },
    [callEvent, getConfigByCod, getMov, history, persistMovDados, showToast]
  );

  const removerPedidoDoCarrinho = useCallback(
    async (idPedido: string) => {
      const venda = getMov();

      if (!venda) {
        showToast("error", "Venda não iniciada");
        return history.push("/venda-simples/landing");
      }

      const pedido = getMov()?.informacoesGeraisPedido.pedidos.find(
        (pedido) => pedido.id === idPedido
      );
      if (pedido) {
        if (
          pedido.statusAutomacao.codigo ===
          EnumStatusSituacaoPedido.FECHAMENTO_PARCIAL
        ) {
          const respostaDeleteStatus = await deleteFechamentoParcialPedido(
            getEmpresaSelecionada()?.Id ?? "",
            idPedido
          );

          if (respostaDeleteStatus.erro) {
            throw respostaDeleteStatus.erro;
          }
        } else {
          const respostaPutStatus = await putPedidoStatus(
            getEmpresaSelecionada()?.Id ?? "",
            idPedido,
            EnumStatusPedido.FECHADO
          );

          if (respostaPutStatus.erro) {
            throw respostaPutStatus.erro;
          }
        }
      }

      await removerItensdoPedidodaVendaSimples(idPedido);
    },
    [
      deleteFechamentoParcialPedido,
      getEmpresaSelecionada,
      getMov,
      history,
      putPedidoStatus,
      removerItensdoPedidodaVendaSimples,
      showToast
    ]
  );



  const adicionarDescontoVenda = useCallback(
    async (
      model: AdicionarDescontoAcrescimoFormModel,
      fechaDialog: () => void
    ) => {
      const venda = getMov();
      if (!venda) {
        showToast("error", "Nenhuma movimentação em andamento.");
        return;
      }

      venda.descInfo = {
        tipo: model.tpCampo,
        vPorcentagem: model.vPorcentagem ?? 0,
        vValorFixo: model.vValorFixo ?? 0
      };
      try {
        await rateioDescontoProdutos(venda);
        showToast(
          "success",
          `Desconto de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
            ? `${model.vPorcentagem}%`
            : `R$ ${toDecimal(model.vValorFixo)}`
          }
        foi atribuído na venda total.`
        );
      } catch (err: any) {
        showToast('error', err.message)
      }
      fechaDialog();
      return;
    },
    [getMov, rateioDescontoProdutos, showToast]
  );

  const adicionarAcrescimoVenda = useCallback(
    async (
      model: AdicionarDescontoAcrescimoFormModel,
      fechaDialog: () => void
    ) => {
      const venda = getMov();

      if (venda!.vAcresc >= venda!.vNF) {
        showToast(
          "error",
          "O acréscimo não pode ser maior ou igual ao valor final da venda."
        );
        return;
      }

      venda!.acrescInfo = {
        tipo: model.tpCampo,
        vPorcentagem: model.vPorcentagem ?? 0,
        vValorFixo: model.vValorFixo ?? 0
      };
      try {
        await rateioAcrescimoProduto(venda!);
        showToast(
          "success",
          `Acréscimo de ${model.tpCampo === EnumTpDescontoAcrescimo.Porcentagem
            ? `${model.vPorcentagem}%`
            : `R$ ${toDecimal(model.vValorFixo)}`
          }
        foi atribuído na venda total.`
        );
      } catch (err: any) {
        showToast('error', err.message)
      }
      fechaDialog();
      return;
    },
    [getMov, rateioAcrescimoProduto, showToast]
  );

  const criarTaxaEntrega = useCallback(
    async (valor: number, tipoEntrega: "propria" | "terceirizada") => {
      const produtoIndexDb = await getProdutoEntrega();
      if (produtoIndexDb && !produtoIndexDb.ativo) {
        return;
      }

      const movAtual = getMov();
      if (isEmpty(movAtual) || !movAtual) {
        throw new Error("Não existe uma venda em Andamento.");
      }

      let NCMIndex;
      let quantidadeProdutosNoCarrinho = movAtual.produtos.length;
      quantidadeProdutosNoCarrinho += 1;

      if (produtoIndexDb?.ncm ?? 0) {
        // NCM DO PRODUTO
        NCMIndex = await TouchoneDBPrimary.ncms.get({
          codigo: produtoIndexDb?.ncm ?? 0
        });
      }

      const vendedor: PessoaModel = await getVendedor();

      const categoria = await TouchoneDBPrimary.categorias.get({
        id: produtoIndexDb?.categoriaId ?? ""
      });

      const produtoVenda: MovSimplesProdutoModel = {
        ...new MovSimplesProdutoModel(),
        ativo: true,
        balanca: produtoIndexDb?.balanca ?? 0,
        cEan: "",
        cProd: produtoIndexDb?.codigo ?? "",
        cProdKit: "",
        categoria: categoria?.descricao ?? "",
        grupoImpostoId: produtoIndexDb?.grupoImpostoId ?? "",
        id: newGuid(),
        imgUrl: produtoIndexDb?.imagemUrl ?? "",
        infAdic:
          tipoEntrega === "propria"
            ? "Entrega Própria"
            : "Entrega Terceirizada",
        ncm: produtoIndexDb?.ncm ?? "",
        ncmId: produtoIndexDb?.ncmId ?? "",
        pedidoId: "",
        produtoGradeId: produtoIndexDb?.produtoGradeId ?? "",
        produtoId: produtoIndexDb?.produtoId ?? "",
        qCom: 1,
        tabelaPrecoId: movAtual?.cliente?.tabelaPrecoId ?? "",
        uCom: produtoIndexDb?.medida ?? "",
        vAcrescUsuario: 0,
        vDescUsuario: 0,
        vFinal: valor,
        vFrete: valor,
        vProd: valor,
        vSeg: 0,
        vUnCom: valor,
        vendedorId: vendedor.id,
        vendedorNome: vendedor.nome,
        xProd: produtoIndexDb?.nome ?? "",
        nSeq: quantidadeProdutosNoCarrinho,
        temImposto: false,
        comandaId: "",
        mesaId: "",
        setorId: produtoIndexDb?.setorId ?? "",
        salaoId: "",
        pTribEstadual: NCMIndex ? NCMIndex?.pTribEstadual ?? 0 : 0,
        pTribFederal: NCMIndex ? NCMIndex?.pTribFederal ?? 0 : 0,
        pTribManual: NCMIndex ? NCMIndex?.pTribManual ?? 0 : 0,
        pTribMunicipal: NCMIndex ? NCMIndex?.pTribMunicipal ?? 0 : 0,
        vTrib: 0,
        taxaServico: 0,
        valorServico: 0,
        cobraTaxaServico: false,
        indFin: true,
        subItens: [],
        adicionais: [],
        idAdicional: null,
        idDoProdutoPaiInfoSubItem: null,
        idGroup: null,
        infoSubItem: null,
        prodSubItem: [],
        produtoPaiId: null,
        tpProduto: EnumTpProduto.Produto,
        validacaoSubItem: true,
        indDesperdicio: EnumIndDesperdicio.NaoSeAplica,
        nivel: 0,
        produtoIdReferencia: produtoIndexDb?.produtoId ?? null,
        quantidadeMax: 1,
        codigoComanda: null,
        mesaCodigo: null,
        indAcrescDesc: false,
        modificadores: [],
        modificadorId: "",
        ordem: 0,
        qComModificador: 0,
        tpAcresDesc: EnumTpDescontoAcrescimo.Porcentagem,
        vAcresDesc: 0,
        vUnComOrig: 0,
        modificadorUnicoId: ""
      };

      return produtoVenda;
    },
    [getMov, getProdutoEntrega, getVendedor]
  );

  const calcularTaxaEntrega = useCallback(
    async (valor: number, tipoEntrega: "propria" | "terceirizada") => {
      const mov = getMov();
      const entrega = getConfigContratoByCod(EnumContratoConfig.ProdutoEntrega);
      if (!mov) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }
      const prodEntrega = mov.produtos.find(
        (prod) => prod.produtoId === entrega
      );

      if (isEmpty(prodEntrega)) {
        const res = await criarTaxaEntrega(valor, tipoEntrega);
        if (!res) {
          throw new Error(
            "A venda não está aberta ou não foi configurado o Produto de Entrega."
          );
        }
        mov.produtos.push(res);
      } else {
        var index = mov.produtos.indexOf(prodEntrega);
        const prod: MovSimplesProdutoModel = {
          ...prodEntrega,
          vFinal: valor,
          vFrete: valor,
          vProd: valor,
          vUnCom: valor,
          indFin: valor > 0,
          infAdic:
            tipoEntrega === "propria"
              ? "Entrega Própria"
              : "Entrega Terceirizada"
        };
        mov.produtos[index] = prod;
      }

      await persistMovDados(mov);
      callEvent(
        AppEventEnum.MovAtualProdAlterado,
        new MovSimplesProdutoModel()
      );
    },
    [
      callEvent,
      criarTaxaEntrega,
      getConfigContratoByCod,
      getMov,
      persistMovDados
    ]
  );

  const editarInfAdicional = useCallback(
    async (infAdicional: string) => {
      const mov = getMov();
      if (!mov) {
        throw new Error(
          `Não existe uma venda em aberto para definir as informações de pedido.`
        );
      }

      mov.infAdicional = infAdicional;
      await persistMovDados(mov);
      callEvent(
        AppEventEnum.MovAtualProdAlterado,
        new MovSimplesProdutoModel()
      );
    },
    [callEvent, getMov, persistMovDados]
  );

  const retornaDescontoTotal = useCallback(() => {
    const mov = getMov();
    let vDescTotal = 0;

    mov?.produtos?.map((item) => {
      if (item.ativo) {
        return (vDescTotal += item.vDescUsuario);
      }
      return item;
    });

    return vDescTotal;
  }, [getMov]);

  const retornaAcrescimoTotal = useCallback(() => {
    const mov = getMov();
    let vAcrescTotal = 0;

    mov?.produtos?.map((item) => {
      if (item.ativo) {
        return (vAcrescTotal += item.vAcrescUsuario);
      }
      return item;
    });

    return vAcrescTotal;
  }, [getMov]);

  const verificaProdutosControlados = useCallback(async () => {
    if (!isPlanoFarmaceutico(plano?.plano)) {
      return [];
    }
    const mov = getMov();
    const medicamentos =
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? [];
    const codigosAnvisa = Array.from(
      new Set<string>(medicamentos.map((x) => x.cProdANVISA || ""))
    );
    const medicamentosVerificados: MovSimplesProdutoModel[] = [];

    for await (let cod of codigosAnvisa) {
      const medicamento = await TouchoneDBPrimary.medicamentos.where('codigoAnvisa').equals(cod).first();

      if (!isEmpty(medicamento)) {
        const ret = medicamento as MedicamentoPreco;

        if (
          ret.tarja === EnumTarja.TarjaPreta ||
          ret.tarja === EnumTarja.TarjaVermelha
        ) {
          medicamentos
            .filter((produtoMed) => produtoMed.cProdANVISA === cod)
            .forEach((produtoMed) => {
              const medi = {
                ...produtoMed, med: {
                  cProdANVISA: produtoMed.cProdANVISA,
                  nSeqReceitaMedica: 0,
                  vendaControlada: true,
                  vPMC: ret?.precos?.[0]?.precoMaximoConsumidor ?? 0,
                  xMotivoIsencao: null
                }
              };

              medicamentosVerificados.push(medi);
            });
        } else {
          for await (let med of medicamentos.filter(
            (m) => m.cProdANVISA === cod
          )) {
            const medi = medicamentos.find((produtoMed) => produtoMed.cProdANVISA === cod);

            await alterarProduto({
              ...med, ignorarReceituario: true, med: {
                cProdANVISA: medi?.cProdANVISA ?? null,
                nSeqReceitaMedica: 0,
                vendaControlada: false,
                vPMC: ret?.precos?.[0]?.precoMaximoConsumidor ?? 0,
                xMotivoIsencao: null
              }
            });
          }
        }

        for await (let med of medicamentos.filter(
          (m) => m.cProdANVISA === cod
        )) {
          const medi = medicamentos.find((produtoMed) => produtoMed.cProdANVISA === cod);

          await alterarProduto({
            ...med, med: {
              cProdANVISA: medi?.cProdANVISA ?? null,
              nSeqReceitaMedica: 0,
              vendaControlada: ret.tarja === EnumTarja.TarjaPreta ||
                ret.tarja === EnumTarja.TarjaVermelha ? true : false,
              vPMC: ret?.precos?.[0]?.precoMaximoConsumidor ?? 0,
              xMotivoIsencao: null
            }
          });
        }
      } else {
        const medi = medicamentos.find((produtoMed) => produtoMed.cProdANVISA === cod);
        if (medi) {
          await alterarProduto({
            ...medi, med: {
              cProdANVISA: cod,
              nSeqReceitaMedica: 0,
              vendaControlada: false,
              vPMC: 0,
              xMotivoIsencao: null,
            },
            ignorarReceituario: true
          });
        }
      }
    }
    return medicamentosVerificados;
  }, [alterarProduto, getMov, plano?.plano]);

  const retornaProdutosMedicamentos = useCallback(() => {
    const mov = getMov();
    return (
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? []
    );
  }, [getMov]);

  const naoInformarDadosDaReceita = useCallback(async () => {
    const mov = getMov();
    const medicamentos =
      mov?.produtos.filter(
        (p) => p.cProdANVISA && !p.nSeqReceitaMedica && !p.ignorarReceituario && p.ativo
      ) ?? [];

    for await (let med of medicamentos) {
      await alterarProduto({ ...med, ignorarReceituario: true });
    }
  }, [alterarProduto, getMov]);

  const adicionarReceitaNaVenda = useCallback(
    async (
      receita: ReceitaMedicaModel,
      medicamentos: MovSimplesProdutoModel[]
    ) => {
      const mov = getMov();

      if (!mov) {
        showToast("error", "Nenhuma movimentação em andamento.");
        return;
      }
      const qtdReceitas = mov?.receitasMedicas.length ?? 0;

      mov.receitasMedicas = [
        ...(mov?.receitasMedicas ?? []),
        { ...receita, nSeq: qtdReceitas + 1 }
      ];

      await persistMovDados(mov);


      for await (let medicamento of medicamentos) {
        await alterarProduto({
          ...medicamento,
          nSeqReceitaMedica: qtdReceitas + 1,
          ignorarReceituario: true,
          med: {
            ...medicamento.med!,
            nSeqReceitaMedica: qtdReceitas + 1,
          }
        });
      }
    },
    [alterarProduto, getMov, persistMovDados, showToast]
  );

  const naoIdentificarCliente = useCallback(async () => {
    const mov = getMov();

    if (!mov) {
      showToast("error", "Nenhuma movimentação em andamento.");
      return;
    }

    mov.clienteIdentificado = true;
    persistMovStorage(mov);
  }, [getMov, persistMovStorage, showToast]);

  const alterarNNF = async (nnf: number) => {
    const mov = getMov();

    if (!mov) {
      showToast("error", "Nenhuma movimentação em andamento.");
      return;
    }

    mov.nnf = nnf;

    await persistMovDados(mov);
  };

  const currentClienteIsPadrao = useCallback(async (): Promise<boolean> => {
    const mov = getMov();
    let response = false
    if (mov) {
      if (mov.cliente) {
        const consumidorPadrao = await getConsumidor()
        response = (consumidorPadrao?.id === mov.cliente.id)
      }
    }

    return response
  }, [getConsumidor, getMov]);

  const hasTaxaServicoProduto = useCallback(() => {
    const mov = getMov()
    if (!mov?.produtos) return false

    const prodTaxaServico = getConfigContratoByCod(EnumContratoConfig.ProdutoServico)
    const prodsTaxa = mov.produtos.filter(prod => prod.produtoId === prodTaxaServico)
    const prodsCalculaTaxa = mov.produtos.filter(prod => prod.taxaServico && prod.taxaServico > 0)

    return prodsTaxa.length > 0 && prodsCalculaTaxa.length > 0
  }, [getConfigContratoByCod, getMov])


  return {
    getUltimoProduto,

    //SESSAO VENDEDOR
    getVendedor,
    setVendedor,

    //SESSAO MOV
    getMov,
    iniciarMov,
    restaurarMov,
    finalizarMov,
    cancelarMov,
    alterarSessaoId,
    removerProdutoComSubItens,
    alterarTpMod,
    retornaDescontoTotal,
    retornaAcrescimoTotal,
    retornaProdutosMedicamentos,
    verificaProdutosControlados,
    naoInformarDadosDaReceita,
    adicionarReceitaNaVenda,
    alterarNNF,
    carregando,

    //SESSAO CLIENTE
    setClienteByDoc,
    setClientePessoa,
    setTelefoneCliente,
    setDocumentoNaNota,
    naoIdentificarCliente,

    //SESSAO TRANSPORTADORA
    setTransportadora,

    //SESSAO PRODUTO
    alterarProduto,
    inserirProduto,
    adicionarDescontoVenda,
    adicionarAcrescimoVenda,
    aplicaTaxaServicoMov,
    hasTaxaServicoProduto,

    //SESSAO PAGAMENTO
    iniciarPagamento,
    alterarPagamento,
    retornaFluxoVenda,
    refazerPagamento,
    carregandoPgto: carregandoDevice,

    // SESSAO PEDIDO
    setMesaId,
    setSalaoId,
    setComandaId,
    setIdentificadorPager,
    setarSeUsaIdentificador,
    getSeUsaIdentificador,
    setCodigoComanda,
    setPedidoExistente,
    transformarPedidosEmVendaSimples,
    removerPedidoDoCarrinho,
    removerItensdoPedidodaVendaSimples,

    // TIPO DA MOVIMENTAÇÃO
    setTipoMovimentacao,

    //DIVIDIR CONTA
    saveQtdPessoasPagamento,
    resetQtdPessoasPagamento,
    getQtdPessoasPagamento,

    // SESSAO DELIVERY
    criarTaxaEntrega,
    calcularTaxaEntrega,
    editarInfAdicional,


    //Outros
    currentClienteIsPadrao,

  };
}
