import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerQtde: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    textQCom: {
        color: theme.palette.grey[700],
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 5px',
        fontSize: '14px',
        "& h6": {
            fontWeight: 'bold',
            fontSize: '14px',
        },
        "& span": {
            fontWeight: 'bold',
        }
    },
    btnAlterarQuantidade: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        padding: '0!important'
    },
    btnAlterarQuantidadeInt: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        padding: '0!important',
        [theme.breakpoints.down('xs')]: {
            margin: '14px 0px'
        }
    },
}));
