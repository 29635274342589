import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { consoleDev } from 'utils/console-dev';
import { DialogEnviarCupomSMS } from 'views/components/dialog/dialog-enviar-cupom-venda-por-sms/dialog-enviar-cupom-venda-por-sms';

class EnviarSmsDialogProps {
  constructor(
    public aberto: boolean = false,
    public id: string = '',
    public mod: number = 0
  ) {}
}

export const EnviarSmsDialog = () => {
  consoleDev('EnviarSmsDialog');

  const { addHandler, removeHandler } = useEventTools();
  const { enviarCupomPorSMS } = useMovSimples();

  const [carregando, setCarregando] = useState<boolean>(false);
  const [numero, setNumero] = useState<string>('');
  const [modalState, setModalState] = useState<EnviarSmsDialogProps>(
    new EnviarSmsDialogProps()
  );

  const { fecharEnviarSmsDialog } = useCadastros();
  const { showToast } = useToastSaurus();

  const modalAlterado = useCallback(({ aberto, id, mod }: any) => {
    setModalState({
      aberto: aberto,
      id,
      mod
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.EnviarSmsDialog, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.EnviarSmsDialog, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  const handleClickEnviarComprovantePorSMS = async () => {
    try {
      setCarregando(true);
      if(numero.length !== 15) {
        showToast('error', `informe um número válido: ${numero}`);
        return
      }
      await enviarCupomPorSMS(modalState.id, numero, modalState.mod);
      showToast('success', `Cupom enviado para o Número: ${numero}`);
      setNumero('');
      fecharEnviarSmsDialog();
    } catch (err: any) {
      showToast('error', err.message);
    } finally {
      setCarregando(false);
    }
  };

  return (
    <>
      {modalState.aberto && (
        <DialogEnviarCupomSMS
          loading={carregando}
          openned={modalState.aberto}
          closeModal={() => {
            setNumero('');
            fecharEnviarSmsDialog();
          }}
          numero={numero}
          setNumero={setNumero}
          enviarSMS={async () => {
            await handleClickEnviarComprovantePorSMS();
          }}
        />
      )}
    </>
  );
};
