import { useMemo, useCallback } from "react";
import classNames from "classnames";
import { IconsHelper } from "views/components/icons-helper";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./list-modulo-filho-styles";
import { useAcoesMenu } from "./acoes-menu/acoes-menu";
import { ListModuloFilhoProps } from "./list-modulo-filho-props";
import { Tooltip } from "@material-ui/core";
import React from "react";
import { useSessaoAtual } from "services/app";
import { NewPropagandaIcon } from "views/components/icons/new-propaganda-icon";
import { OfertaIcon } from "views/components/icons/oferta-icon";
import { isPlanoAtivo } from "utils/plano-utils";
import { useHistory } from "react-router-dom";

export const ListModuloFilho = (props: ListModuloFilhoProps) => {
  const { ...listItemProps } = useMemo(() => {
    return {
      ...props,
    };
  }, [props]);

  const classes = useStyles();
  const { selectAction } = useAcoesMenu();
  const { plano } = useSessaoAtual();
  const history = useHistory();

  const onClickItem = useCallback(() => {
    if (!isPlanoAtivo(props.codigoModulo, plano?.plano)) {
      const redirectTo = '/alterar-plano';
      history.push(redirectTo)
    } else {
      selectAction(listItemProps.codigoModulo, listItemProps.rota);
    }
  }, [props.codigoModulo, plano?.plano, history, selectAction, listItemProps.codigoModulo, listItemProps.rota]);

  const isNew = useCallback((dtLimiteIcone: Date) => {
    if (isPlanoAtivo(props.codigoModulo, plano?.plano)) {
      if (new Date() <= dtLimiteIcone) {
        return true
      }
    }
    return false;
  }, [plano?.plano, props.codigoModulo]);

  const component = React.useMemo(() => {
    return (<>
      <ListItem id={props.id} className={props.isFavorite ? classes.favoriteRoot : classes.root} button onClick={onClickItem}>
        <Tooltip arrow title={listItemProps.nomeModulo} placement="right">
          <ListItemIcon>
            <IconsHelper
              codigoModulo={listItemProps.codigoModulo} class={props.isFavorite ? classes.favoriteIcon : classes.icon} tipo="GERAL"
            />
          </ListItemIcon>
        </Tooltip>
        <div className={classes.containerNomeDescricao}>
          {props.modeloAtual === "Completo" && (
            <ListItemText
              classes={{
                primary: classNames(
                  classes.text,
                  props.isFavorite &&
                  classes.favoriteText
                ),
              }}
              primary={listItemProps.nomeModulo}
            />
          )}

          {props.descricaoModulo?.length! > 0 && (
            <>
              <ListItemText
                className={classes.containerDecricaoModulo}
                secondary={listItemProps.descricaoModulo}
                secondaryTypographyProps={{
                  className: classes.descricaoText,
                }}
              />
            </>
          )}
        </div>

        {isPlanoAtivo(props.codigoModulo, plano?.plano) ? (
          <>
            {(props.dataValidade && isNew(new Date(props.dataValidade))) && (
              <NewPropagandaIcon
                style={{
                  width: '45px',
                  height: '45px',
                  marginRight: '8px',
                  marginLeft: '4px',
                  marginBottom: '0'
                }} />
            )}
          </>
        ) :
          <OfertaIcon
            style={{
              width: '55px',
              height: '55px',
              marginRight: '8px',
              marginLeft: '4px',
              marginBottom: '0'
            }} />}
      </ListItem>
    </>)
  }, 
    [classes.containerDecricaoModulo, classes.containerNomeDescricao, classes.descricaoText, classes.favoriteIcon, classes.favoriteRoot, classes.favoriteText, classes.icon, classes.root, classes.text, isNew, listItemProps.codigoModulo, listItemProps.descricaoModulo, listItemProps.nomeModulo, onClickItem, plano?.plano, props.codigoModulo, props.dataValidade, props.descricaoModulo?.length, props.id, props.isFavorite, props.modeloAtual],
  );

  return component;
};
