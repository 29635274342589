import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    keyboardContainer: {
        flex: 1,
        height: "100%",
        background: theme.palette.grey[50],
    },
    setarValorPadraoContainer: {
        display: "flex",
        background: theme.palette.grey[50],
    },
    setarValorPadraoItem: {
        flexBasis: "20%",
    },
}));