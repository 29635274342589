import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rootTeclado: {
    background: theme.palette.grey[100],
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  gridTeclado: {
    flex: "1 1 100%",
    overflowX: "hidden",
    overflow: "auto",
    position: "relative",
  },
}));