import { useCallback } from "react";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { ButtonPrivateHeader } from "views/components/controles";
import { PrivatePageHeader } from "views/components/headers";
import { MenuIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";

export interface MesasComandasHeaderProps {
    openPesquisa: boolean;
    setOpenPesquisa: (state: boolean) => any;
    setSearchProps: (props: any) => any;
}

export const SaloesHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();

    const leftArea = useCallback(
        () =>
            isMobile ? (
                <ButtonPrivateHeader
                    icon={<MenuIcon tipo="PRIVATE_HEADER"></MenuIcon>}
                    tooltip="Menu"
                    onClick={abrirMenu}
                ></ButtonPrivateHeader>
            ) : null,
        [isMobile, abrirMenu],
    );

    const pesquisa = () => null;

    return (
        <>
            <PrivatePageHeader
                title="Salões"
                leftArea={leftArea()}
                bottomArea={pesquisa}
            />
        </>
    )
}