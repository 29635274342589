import { Button, Grid } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-sem-preco-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { TextFieldSaurus } from '../../controles/inputs';
import { useEffect, useRef } from 'react';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { Controller, useForm } from 'react-hook-form';
import { ProdutoSemPrecoFormModel } from 'model/app/forms/movimentacao/produto-sem-preco-form-model';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDialogProdutoSemPrecoValidation } from './dialog-produto-sem-preco-validations';

export interface DialogAdicionarProdutoSemPrecoProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
}

export const DialogAdicionarProdutoSemPreco = ({
  aberto,
  produto,
}: DialogAdicionarProdutoSemPrecoProps) => {
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const refInputVUnCom = useRef<HTMLInputElement>(null);
  const { isMobile } = useThemeQueries();
  const { carregando, adicionarProdutoSemPreco } = useMovProd()
  const { fecharAdicionarProdutoSemPreco } = useCadastros()
  const { callEvent } = useEventTools()
  const { DialogProdutoSemPrecoValidation } = useDialogProdutoSemPrecoValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue
  } = useForm<ProdutoSemPrecoFormModel>({
    defaultValues: { ...new ProdutoSemPrecoFormModel() },
    resolver: yupResolver(DialogProdutoSemPrecoValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, callEvent, produto])

  useEffect(() => {
    if (produto) {
      setValue('vUnCom', toDecimalString(''));
      setValue('qCom', toDecimalString(produto.qCom));

      if (aberto && !isMobile) {
        setTimeout(() => {
          refInputVUnCom.current?.focus();
        }, 50);
      }
    }
  }, [aberto, isMobile, produto, setValue]);

  const onSubmit = async (model: ProdutoSemPrecoFormModel) => {
    if (toDecimal(model.vUnCom, 2) === 0 || toDecimal(model.qCom, 2) === 0) {
      showToast('error', 'Informe o Valor e Quantidade do Produto');
      return;
    }

    await adicionarProdutoSemPreco(produto, toDecimal(model.vUnCom, 10), toDecimal(model.qCom, 4));
    fecharAdicionarProdutoSemPreco()
  };

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Produto sem Preço"
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name="vUnCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Valor de Venda"
                      max={8}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={12}
                      {...field}
                      error={Boolean(
                        errors.vUnCom && errors.vUnCom.message,
                      )}
                      helperText={
                        touchedFields.vUnCom || errors.vUnCom
                          ? errors.vUnCom?.message
                          : undefined
                      }
                      placeholder={toDecimalString(produto!.vUnCom, 2)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="qCom"
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      manterMascara
                      disabled={carregando}
                      fullWidth
                      variant="outlined"
                      label="Quantidade"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      maxTexto={12}
                      {...field}
                      error={Boolean(
                        errors.qCom && errors.qCom.message,
                      )}
                      helperText={
                        touchedFields.qCom || errors.qCom
                          ? errors.qCom?.message
                          : undefined
                      }
                      placeholder=""
                      allowSubmit
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                {fecharAdicionarProdutoSemPreco && (
                  <Button
                    disabled={carregando}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onClick={() => {
                      fecharAdicionarProdutoSemPreco();
                    }}
                  >
                    <VoltarIcon tipo="BUTTON" />
                    Voltar
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  {'Confirmar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
