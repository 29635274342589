import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { consoleDev } from "utils/console-dev";
import { DialogVincularContratos } from "views/components/dialog/dialog-vincular-contrato/dialog-vincular-contratos";

export const CadastroVincularContratosDialog = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        aberto: false,
    });

    consoleDev('CadastroVincularContratosDialog')

    const modalAlterado = useCallback(({ openned }: any) => {
        setModalState({
            aberto: openned,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.DialogVincularContratos, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.DialogVincularContratos, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);


    return (
        <DialogVincularContratos openned={modalState.aberto} key="mdlPessoa" />
    )
}