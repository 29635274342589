import { Paper, Typography } from '@material-ui/core';
import { CardDashboardAdminChartLineProps } from '../card-dashboard-admin-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';

export const CardDashboardAdminChartLine = ({
  model,
  carregando
}: CardDashboardAdminChartLineProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();

  const hora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora} h`),
    ),
  );
  const compararHora = Array.from(
    new Set<string>(
      model?.sort((a, b) => a.hora - b.hora).map((item) => `${item?.hora}`),
    ),
  );

  const options = useCallback(() => {
    const options = {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '20px',
        },
        y: {
          formatter: (val: any) => `R$ ${toDecimalString(val)}`
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: (val: any) => `R$ ${val.toFixed(0)}`
        }
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
      ],
      chart: {
        width: '700px',
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: hora,
      },
    };
    return options;
  }, [hora]);

  const retornaSeries = useCallback(() => {
    const series: any[] = [
      {
        name: 'Custo',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vCusto, 0).toFixed(2),
        ),
      },
      {
        name: 'Total vendido',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + b.vVenda, 0).toFixed(2),
        ),
      },
      {
        name: 'Lucro',
        data: compararHora.map((hora) =>
          model
            .filter((i) => String(i.hora) === hora && i)
            .reduce((a, b) => a + (b.vVenda - b.vCusto), 0).toFixed(2),
        ),
      },
    ];
    return series;
  }, [compararHora, model]);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Receita por horário
            </Typography>
          </div>
          <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>
          <div className={utilClasses.alinharChart}>
            <div style={{ display: 'block', marginTop: 20 }}>
              {model.length === 0 ? (
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                  Não há dados para exibir
                </Typography>
              ) : (
                <Chart
                  options={options()}
                  series={retornaSeries()}
                  type="line"
                  className={utilClasses.chart}
                />
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
