import { KeyValueModel } from "model/api";
import { EnumTipoReceita } from 'model/enums/enum-tipo-receita';

export const TipoReceitaMock: KeyValueModel[] = [
   new KeyValueModel(EnumTipoReceita.NotificacaoReceitaA, "Notificação Receita A"),
   new KeyValueModel(EnumTipoReceita.NotificacaoReceitaB, "Notificação Receita B"),
   new KeyValueModel(EnumTipoReceita.NotificacaoReceitaEspecial, "Notificação Receita Especial"),
   new KeyValueModel(EnumTipoReceita.ReceitaControleEspecial2Vias, "Receita Controle Especial 2 Vias"),
   new KeyValueModel(EnumTipoReceita.ReceitaAntimicrobiano2Vias, "Receita Antimicrobiano 2 Vias"),
];
