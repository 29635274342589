import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const AdicionarEntradaIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} onClick={props.onClick} style={{ ...props.style }} fill={props.fill}>
                <path d="M42.9861 15.504C42.9861 15.0028 42.8475 14.6408 42.4869 14.2788C38.604 10.4085 34.7488 6.52432 30.8937 2.6401C30.5609 2.30597 30.228 2.15283 29.7427 2.15283C22.4206 2.16675 15.0986 2.16675 7.76271 2.15283C7.15254 2.15283 7 2.30597 7 2.91854C7.02773 10.3389 7.01387 17.7593 7.01387 25.1936C7.01387 32.6418 7.02773 40.0901 7 47.5244C7 48.1648 7.16641 48.3318 7.80431 48.3179C19.2727 48.304 30.7273 48.304 42.1957 48.3179C42.8336 48.3179 43 48.1509 43 47.5105C42.9723 36.8463 42.9723 26.1821 42.9861 15.504ZM31.0185 5.82822C33.7781 8.6126 36.5516 11.397 39.3112 14.1814C39.2142 14.251 39.02 14.1814 38.8259 14.1814C36.4407 14.1814 34.0416 14.1674 31.6564 14.1953C31.1572 14.1953 30.9769 14.1118 30.9769 13.5549C31.0046 11.1325 30.9908 8.71006 30.9908 6.28764C30.9908 6.12058 30.9214 5.93959 31.0185 5.82822ZM40.9753 45.6449C40.9753 46.16 40.8505 46.3132 40.3236 46.3132C30.1171 46.2992 19.9106 46.2992 9.71803 46.3132C9.16333 46.3132 9.02465 46.1739 9.02465 45.6171C9.03852 38.8092 9.03852 32.0154 9.03852 25.2075C9.03852 18.4276 9.03852 11.6476 9.02465 4.86761C9.02465 4.32465 9.13559 4.15759 9.70416 4.15759C15.9029 4.18543 22.1017 4.17151 28.3005 4.15759C28.8413 4.15759 29.0216 4.28289 29.0077 4.85368C28.98 8.36201 29.0216 11.8843 28.98 15.3926C28.9661 16.1026 29.2019 16.214 29.8259 16.2C33.3205 16.1722 36.8151 16.2 40.3236 16.1722C40.8367 16.1722 40.9892 16.2836 40.9892 16.8265C40.9615 26.4327 40.9615 36.0388 40.9753 45.6449Z" fill={props.fill} />
                <path d="M14.738 24.233C18.3158 24.2052 21.9075 24.2191 25.4853 24.2191C28.8967 24.2191 32.2943 24.2191 35.7057 24.2191C36.9953 24.2191 36.9537 24.2191 37.0092 22.9522C37.0369 22.3118 36.8012 22.2004 36.2188 22.2004C29.2573 22.2143 22.2819 22.2143 15.3205 22.2143C14.003 22.2143 14.0446 22.2143 13.9892 23.523C13.9614 24.1495 14.1833 24.233 14.738 24.233Z" fill={props.fill} />
                <path d="M14.7103 20.2374C18.3158 20.2096 21.9214 20.2235 25.5269 20.2235C28.9383 20.2235 32.3358 20.2235 35.7472 20.2235C36.9814 20.2235 36.9398 20.2235 36.9953 19.0123C37.023 18.3719 36.8289 18.2048 36.191 18.2048C29.2156 18.2327 22.2542 18.2187 15.2788 18.2187C13.9891 18.2187 14.0169 18.2187 13.9753 19.4856C13.9753 20.0704 14.114 20.2513 14.7103 20.2374Z" fill={props.fill} />
                <path d="M14.6826 12.2045C18.3297 12.1766 21.9769 12.1906 25.6102 12.1906C27.0247 12.1906 27.0108 12.1906 27.0108 10.7427C27.0108 10.2972 26.886 10.1719 26.4561 10.1719C24.4869 10.1858 22.5316 10.1858 20.5624 10.1858C18.8151 10.1858 17.0678 10.1858 15.3344 10.1858C14.0031 10.1858 14.0308 10.1858 14.0031 11.4945C13.9753 12.0513 14.114 12.2045 14.6826 12.2045Z" fill={props.fill} />
                <path d="M26.3173 14.1814C22.6841 14.2092 19.0647 14.1953 15.4314 14.1953C13.9614 14.1953 13.9891 14.1953 13.9753 15.6432C13.9753 16.0748 14.0862 16.214 14.53 16.2001C16.5269 16.1722 18.5099 16.1861 20.5069 16.1861C22.2264 16.1861 23.9599 16.1861 25.6794 16.1861C26.9969 16.1861 26.9691 16.1861 26.9969 14.8775C27.0246 14.3484 26.8859 14.1675 26.3173 14.1814Z" fill={props.fill} />
                <path d="M25.014 26.7668C25.014 26.3074 24.8614 26.2238 24.4593 26.2378C21.45 26.2517 18.4546 26.2517 15.4454 26.2517C13.9477 26.2517 13.9616 26.2517 13.9893 27.7692C13.9893 28.2008 14.1419 28.2843 14.5301 28.2704C16.1804 28.2564 17.8445 28.2564 19.4947 28.2564C20.8398 28.2564 22.185 28.2564 23.5301 28.2564C25.0278 28.2564 25.0001 28.2564 25.014 26.7668Z" fill={props.fill} />
                <path d="M31.0186 28.2564C26.6087 28.2425 23.017 31.8065 23.0032 36.2337C22.9754 40.633 26.5532 44.2666 30.9492 44.2945C35.3314 44.3223 38.9369 40.7166 38.9508 36.3033C38.9785 31.8622 35.4284 28.2704 31.0186 28.2564ZM30.9076 42.2897C27.5794 42.248 24.9446 39.5053 25.0001 36.1223C25.0555 32.8507 27.8152 30.1916 31.074 30.2612C34.4161 30.3308 37.0232 33.0177 36.9816 36.359C36.9261 39.6724 34.222 42.3315 30.9076 42.2897Z" fill={props.fill} />
                <path d="M33.584 35.2592C31.9892 35.2592 31.9892 35.2592 31.9892 33.686C31.9892 32.1128 31.9892 32.1267 30.3944 32.2381C30.0755 32.266 29.9784 32.3634 29.9784 32.6697C29.9923 33.3797 29.9645 34.0897 29.9923 34.7997C30.0061 35.1478 29.8952 35.2731 29.5346 35.2592C28.869 35.2313 28.2034 35.2731 27.5516 35.2452C27.1078 35.2174 26.9969 35.3705 26.9969 35.816C26.9969 37.2639 26.983 37.2639 28.3975 37.2639C28.4391 37.2639 28.4807 37.2639 28.5362 37.2639C29.9923 37.2639 29.9923 37.2639 29.9923 38.7118C29.9923 38.7536 29.9923 38.7953 29.9923 38.851C29.9923 40.3267 29.9923 40.3128 31.4622 40.2989C31.8505 40.2989 32.0169 40.2015 32.0031 39.7838C31.9753 39.1573 32.0169 38.5447 31.9892 37.9183C31.9615 37.431 32.0863 37.2361 32.5994 37.2639C33.2095 37.3057 33.8336 37.25 34.4576 37.2779C34.8459 37.2918 34.9984 37.1804 34.9984 36.7627C35.0262 35.2592 35.04 35.2592 33.584 35.2592Z" fill={props.fill} />

            </DefaultIcon>
        </>
    );
};