import { Grid, Button } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonPrivateHeader } from 'views/components/controles';
import { PrivatePageHeader } from 'views/components/headers';
import { VoltarIcon } from 'views/components/icons';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { SelecionarMesaList } from './components/selecionar-mesa-list/selecionar-mesa-list';
import TecladoMesa from './components/teclado-mesa/teclado-mesa';

export const MovVincularMesaPage = () => {
  const [digitarCodigo, setDigitarCodigo] = useState<boolean>(false);
  const [codigoMesa, setCodigoMesa] = useState('');
  const classes = useDefaultCadastroStyles();
  const { goBack } = useHistory();

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
        tooltip="Voltar"
        onClick={goBack}
      ></ButtonPrivateHeader>
    );
  }, [goBack]);

  const header = useMemo(() => {
    return <PrivatePageHeader title="Seleção de Mesa" leftArea={leftArea()} />;
  }, [leftArea]);

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>{header}</Grid>
        <Grid className={classes.list}>
          <SelecionarMesaList codigoMesa={codigoMesa} />
        </Grid>

        {digitarCodigo ? (
          <>
            <TecladoMesa setCodigoMesa={setCodigoMesa} />
            <Grid style={{ padding: '0 8px', marginBottom: 8 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setDigitarCodigo(false)}
                style={{
                  borderRadius: '20px',
                  height: '57px',
                  color: '#fff',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '33px',
                  textAlign: 'center',
                }}
              >
                Ver Mesas
              </Button>
            </Grid>
          </>
        ) : (
          <Grid style={{ padding: '0 8px', marginBottom: 8 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setDigitarCodigo(true)}
              style={{
                height: '57px',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '33px',
                textAlign: 'center',
              }}
            >
              Digitar Nº Mesa
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
