import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    defaultContainer: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
    },
    listContainer: {
        flex: "1 1 100%",
        overflowX: "hidden",
        overflow: "auto",
    },
    containerListCard: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(3),
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            margin: 0,
        },
    },
    containertitle: {
        margin: '16px 8px 16px 8px',
        paddingBottom: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
        },
    },

}));
