import classNames from 'classnames';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';

import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { useHistory } from 'react-router';
import LimiteAtingidoSVG from '../../../../assets/img/limite-atingido.svg';
import { useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCallback } from 'react';

export interface modalLimiteAtingidoProps {
  carregando?: boolean;
  aberto: boolean;
}

export const ModalLimiteProdutoAtingido = ({
  ...props
}: modalLimiteAtingidoProps) => {
  const classes = useModalStyles();
  const history = useHistory();
  const { fecharLimiteAtingido } = useCadastros();
  const { plano } = useSessaoAtual();
  const onCloseClick = useCallback(() => {
    fecharLimiteAtingido();
  }, [fecharLimiteAtingido]);

  const handleClick = () => {
    fecharLimiteAtingido();
    history.push('/alterar-plano');
  };

  const total = plano?.plano?.opcoes.find((item) => item.codigo === 100200);

  return (
    <>
      {props.aberto && (
        <DialogSaurus
          aberto={props.aberto}
          carregando={props.carregando}
          titulo="Plano insuficiente!"
          tamanho="xs"
          isButtonTitleClose
          handleClickClose={onCloseClick}
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div
                className={classNames(
                  classes.contentForms,
                  props.carregando ? classes.contentFormsLoading : undefined,
                )}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    height: '70%',
                    width: '100%',
                  }}
                >
                  <img src={LimiteAtingidoSVG} alt="Limite Atingido" />
                </div>

                <Typography
                  style={{
                    fontWeight: 600,
                    textAlign: 'center',
                    color: '#6C6C6C',
                    marginTop: '24px',
                  }}
                >
                  Você possui{' '}
                  <strong
                    style={{ fontWeight: 800, lineHeight: 2, color: '#F44336' }}
                  >
                    {total?.valor} itens!
                  </strong>
                </Typography>
                <Typography
                  style={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#B7B7B7',
                  }}
                >
                  Para continuar cadastrando faça um upgrade no seu plano,
                  clicando no botão a baixo.
                </Typography>
              </div>
            </div>
            <div className={classes.acoes}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button
                    disabled={props.carregando}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                  >
                    Ver planos
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogSaurus>
      )}
    </>
  );
};
