import { isEmpty } from "lodash";

import { DefaultModal } from "views/components/modals/components/default-modal";
import { ModalProps } from "views/components/modals/utils/modal-props";
import { MarcaCadastro } from "./components/marca-cadastro/marca-cadastro";
import { MarcaEdit } from "./components/marca-edit/marca-edit";

export const MarcaModal = (props: ModalProps) => {

  return (
    <DefaultModal
      minWidth="400px"
      open={props.openned || false}
      variant={"temporary"}
      anchor="right"
    >
      {props.openned && isEmpty(props.id) && (<MarcaCadastro />)}
      {props.openned && !isEmpty(props.id) && <MarcaEdit id={props.id} />}
    </DefaultModal>
  );
};
