import { useCallback, useEffect, useState, useRef } from 'react';
import { SalvarIcon, VoltarIcon } from 'views/components/icons';
import { Button, Grid, Typography } from '@material-ui/core';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useGetSalaoById } from 'data/api/gestao/saloes/get-salao-by-id';
import { SaloesEditFormModel } from 'model/app/forms/saloes/saloes-edit-form-model';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { usePutSalao } from 'data/api/gestao/saloes/put-salao';
import { FormSaloesEdit } from 'views/components/form/saloes/form-salao-edit/form-salao-edit';
import { Mesas } from 'views/components/form/saloes/form-salao-edit/components/mesas';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';

export const SaloesEdit = (props: { id: string }) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);
  const classes = useModalStyles();
  const { fecharCadastroSaloes } = useCadastros();
  const { getSalaoById, carregando: carregandoSalao } = useGetSalaoById();
  const { putSalao, carregando: carregandoPut } = usePutSalao();

  const carregando = carregandoPut || carregandoSalao || preenchendoTela;
  const [salaoFormState, setSalaoForm] = useState<SaloesEditFormModel>(
    new SaloesEditFormModel(),
  );

  const refEditForm = useRef<DefaultFormRefs<SaloesEditFormModel>>(null);
  const refSalaoModel = useRef<SaloesModel>(new SaloesModel());

  const recarregarForm = useCallback((model: SaloesEditFormModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(salaoFormState);
  }, [salaoFormState, recarregarForm]);

  const getSalaoByIdWapper = useCallback(async () => {
    const res = await getSalaoById(getEmpresaSelecionada()!.Id, props.id);
    if (res.erro) {
      throw res.erro;
    }

    return {
      ...res.resultado?.data,
      status: res.resultado?.data.status.codigo,
      balcao: res.resultado?.data.balcao.codigo,
    } as SaloesEditFormModel;
  }, [getEmpresaSelecionada, getSalaoById, props.id]);

  const preencherTela = useCallback(async () => {
    try {
      const salao = await getSalaoByIdWapper();
      setSalaoForm({ ...salao });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getSalaoByIdWapper, showToast]);

  useEffect(() => {
    (async () => {
      setPreenchendoTela(true);
      preencherTela();
      setPreenchendoTela(false);
    })();
    return () => {
      //funcao de limpeza
      setSalaoForm(new SaloesEditFormModel());
    };
  }, [preencherTela]);

  const saveChangesSalao = useCallback(
    async (salaoModelForm: SaloesEditFormModel) => {
      let salao = picker<SaloesModel>(salaoModelForm, refSalaoModel.current);
      const ret = await putSalao(salao, getEmpresaSelecionada()!.Id);
      if (ret.erro) {
        showToast(
          'error',
          'Não foi possível atualizar o salão, tente novamente mais tarde',
        );
      }
    },
    [getEmpresaSelecionada, putSalao, showToast],
  );

  const handleSubmit = useCallback(
    async (model: SaloesEditFormModel, beforeModel: SaloesEditFormModel) => {
      try {
        const SalaoEqual = isEqual(model, beforeModel);
        if (!SalaoEqual) {
          saveChangesSalao(model);
        }

        if (SalaoEqual) {
          showToast('info', 'Nenhuma informação foi alterada');
        } else {
          showToast('success', 'Salao atualizado com Sucesso!');
          preencherTela();
        }
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [saveChangesSalao, showToast, preencherTela],
  );

  const onCloseClick = useCallback(() => {
    fecharCadastroSaloes();
  }, [fecharCadastroSaloes]);

  const saveChangeStatus = useCallback(
    async (salao: SaloesEditFormModel) => {
      let salaoPicker = picker<SaloesModel>(salao, refSalaoModel.current);
      const ret = await putSalao(salaoPicker, getEmpresaSelecionada()!.Id);
      if (ret.erro) {
        throw ret.erro;
      }

      setSalaoForm({...salao})
    },
    [getEmpresaSelecionada, putSalao],
  );

  const changeStatus = useCallback(async () => {
    try {
      let model = salaoFormState;
      let newState =
        model.status === EnumStatusSalao.ATIVO
          ? EnumStatusSalao.INATIVO
          : EnumStatusSalao.ATIVO;

      model.status = newState;

      saveChangeStatus(model);
      showToast(
        'success',
        'Status alterada com Sucesso!',
        2000,
        'bottom-center',
        60,
      );
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [salaoFormState, saveChangeStatus, showToast]);

  const createButtonRight = useCallback(() => {
    return (
      <MenuOptions
        options={[
          new MenuOptionsModel(
            `${
              salaoFormState?.status === EnumStatusSalao.ATIVO
                ? 'Desativar'
                : 'Ativar'
            } Salão`,
            <></>,
            changeStatus,
          ),
        ]}
      />
    );
  }, [salaoFormState.status, changeStatus]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Edição Salão'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={createButtonRight()}
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormSaloesEdit
            ref={refEditForm}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />

          <Grid container spacing={2} style={{ marginBottom: '8px' }}>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <Button
                disabled={carregando}
                onClick={() => refEditForm.current?.submitForm()}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarIcon tipo="BUTTON_PRIMARY" />
                Salvar Salão
              </Button>
            </Grid>
          </Grid>

          <Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="h5">
                Mesas
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}>
              <Mesas idSalao={props.id} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
