import { Tooltip, Typography } from '@material-ui/core';
import { CardProdutoProps } from './card-medicamento-props';
import { memo, useCallback } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import {
  DefaultCard,
  useDefaultCardStyles
} from 'views/components/cards/components';
import { useThemeQueries } from 'views';
import useOnPress from 'utils/useOnPress';
import { EnumTarja } from 'model/enums/enum-tarja';

const CardMedicamento = ({ model, onClick, onHold }: CardProdutoProps) => {
  const classes = useDefaultCardStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    return model.tarja === EnumTarja.TarjaVermelha
      ? theme.palette.error.main
      : model.tarja === EnumTarja.TarjaPreta
        ? '#000'
        : theme.palette.success.main;
  }, [model.tarja, theme.palette.error.main, theme.palette.success.main]);

  const handlePress = () => {
    onHold(model);
  };

  const handleClick = async () => {
    onClick(model);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700
  };

  const EventPressAndClick = useOnPress(
    handlePress,
    handleClick,
    defaultOptions
  );

  const img = () => {
    let url = ''
    if (model?.codigoEan) {
      url = `https://saurusean.blob.core.windows.net/eanimagens/${model.codigoEan}.jpg`
    } else {
      url = SemImagem
    }
    return <img src={url} className={classes.cardImg} onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = SemImagem;
    }} alt={model.nome} />;
  };

  return (
    <>
      <div {...EventPressAndClick}>
        <DefaultCard>
          <Tooltip
            arrow
            title={
              model.tarja === EnumTarja.TarjaVermelha
                ? 'Tarja Vermelha'
                : model.tarja === EnumTarja.TarjaPreta
                  ? 'Tarja Preta'
                  : 'Venda Livre'
            }
            placement="right"
          >
            <div
              className={classes.tagStatus}
              style={{ background: retornarCorStatus() }}
            ></div>
          </Tooltip>
          <div className={classes.cardContent}>
            <div style={{ width: '100%', padding: '4px 0' }}>
              {model.codigoEan && model.codigoEan.length > 0 && (
                <div style={{ minWidth: 100, display: 'flex', marginLeft: 8 }}>
                  <Typography
                    color="textPrimary"
                    variant="caption"
                    style={{ fontSize: 10 }}
                  >
                    EAN: <strong>{model.codigoEan}</strong>
                  </Typography>
                </div>
              )}

              <div className={classes.cardContent}>
                <div className={classes.celulaGrid} style={{ marginLeft: 8 }}>
                  {img()}
                </div>

                <div className={classes.celulaGridFull}>
                  <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                    style={{
                      maxWidth: isMobile ? '270px' : '400px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {model.nome}
                  </Typography>
                  <Typography color="textPrimary" variant="caption">
                    {model.composicao}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </DefaultCard>
      </div>
    </>
  );
};

export default memo(CardMedicamento, (prev, next) => {
  if (prev !== next) return false;
  return true;
});
