import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  selecionarArquivo: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: '14px 12px',
    wordBreak: 'break-word',
    border: 2,
    width: '100%',
    '& span': {
      textAlign: 'center'
    },
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    '&:hover': {
      backgroundColor: '#ECEDED'
    }
  },
  trocarArquivo: {
    display: 'block',
    textAlign: 'right'
  }
}));
