import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

interface RefreshTokenModel {
    token: string
}
export function usePostIdentidadeRefreshToken() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postIdentidadeRefreshToken = useCallback(
        (tokenAtual: RefreshTokenModel) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/Auth/refresh-token`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(tokenAtual),
                enviarTokenUsuario: false,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postIdentidadeRefreshToken,
    };
}
