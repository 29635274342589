import { useStyles } from '../../dashboard-content-styles';
import { Grid } from "@material-ui/core";
import { CardDashboard, CardDashboardChartBar, CardDashboardChartCategorias, CardDashboardChartDonut, CardDashboardChartHeatMap, CardDashboardChartLine, CardDashboardChartProdutos, CardDashboardChartVendedores } from "views/components/cards/card-dashboard";
import { DinheiroIcon, LucroIcon, PessoasIcon, QuantidadeItensIcon, TicketIcon, TotalVendidoIcon } from "views/components/icons";
import { VariaveisAmbiente } from "config";
import { DashboardContentProps } from "../../dashboard-content";
import { EnumHistoricoVendaStatus } from "model/enums/enum-historico-venda-status";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { isEmpty } from 'lodash';
import { DashboardVendasModel } from 'model/api/gestao/dashboard/dashboard-vendas';
import { useThemeQueries } from 'views/theme';

export interface DashboardInfoProps {
    data: DashboardContentProps;
    periodoPesquisar: Number | undefined;
    mudarPeriodos: Number | undefined;
    fromAccordion?: boolean;
}

function DashboardInfo(props: DashboardInfoProps) {

    const { dataCategorias, dataPagamentos, dataProdutos, dataVendas, dataVendedores } = !isEmpty(props.data) ? props.data : {
        dataCategorias: [],
        dataPagamentos: [],
        dataProdutos: [],
        dataVendas: [new DashboardVendasModel()],
        dataVendedores: [],
    }
    const { mudarPeriodos, periodoPesquisar } = props

    const classes = useStyles();
    const { theme } = useThemeQueries();

    const vendasFinalizadas = dataVendas.filter(
        (item) => item.status === EnumHistoricoVendaStatus.Finalizado,
    );


    const getLucro = () => {
        const venda = vendasFinalizadas?.reduce((a, b) => a + b.vVenda, 0);
        const custo = vendasFinalizadas?.reduce((a, b) => a + b.vCusto, 0);
        return venda - custo;
    };

    const getTicketMedio = () => {
        const vVenda = vendasFinalizadas?.reduce((a, b) => a + b.vVenda, 0);
        const qVendas = vendasFinalizadas?.reduce((a, b) => a + b.qVendas, 0);
        return vVenda / qVendas || 0.0;
    };

    return (
        <Grid item style={{ background: theme.palette.grey[100], borderRadius: theme.shape.borderRadius }}>
            <Grid
                container
                spacing={1}
                className={classes.containerCardDashboard}
            >
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Total Vendas"
                        icone={<PessoasIcon tipo="BUTTON" />}
                        isDivider
                        conteudo={
                            vendasFinalizadas?.reduce((a, b) => a + b.qVendas, 0) || 0
                        }
                    />
                </Grid>
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Itens Vendidos"
                        icone={<QuantidadeItensIcon tipo="BUTTON" />}
                        isDivider
                        conteudo={
                            vendasFinalizadas?.reduce((a, b) => a + b.qCom, 0) || 0
                        }
                    />
                </Grid>
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Valor Vendido"
                        icone={<TotalVendidoIcon tipo="BUTTON" />}
                        isDivider
                        conteudo={
                            vendasFinalizadas?.reduce((a, b) => a + b.vVenda, 0) || 0
                        }
                        tipo="dinheiro"
                    />
                </Grid>
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Valor Custo"
                        icone={<DinheiroIcon tipo="BUTTON" />}
                        isDivider
                        conteudo={
                            vendasFinalizadas?.reduce((a, b) => a + b.vCusto, 0) || 0
                        }
                        tipo="dinheiro"
                    />
                </Grid>
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Lucro Apurado"
                        icone={<LucroIcon tipo="BUTTON" />}
                        conteudo={getLucro()}
                        tipo="dinheiro"
                    />
                </Grid>
                <Grid item className={classes.listContainer}>
                    <CardDashboard
                        titulo="Ticket Médio"
                        icone={<TicketIcon tipo="BUTTON" />}
                        conteudo={getTicketMedio()}
                        tipo="dinheiro"
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                className={classes.containerCardDashboardChart}
            >
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    className={classes.listContainerChart}
                >
                    <CardDashboardChartDonut model={dataPagamentos} />
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    className={classes.listContainerChart}
                >
                    <CardDashboardChartLine model={vendasFinalizadas} />
                </Grid>
                {periodoPesquisar === 0 && mudarPeriodos === 0 ? (
                    ''
                ) : (
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        className={classes.listContainerChart}
                    >
                        <CardDashboardChartBar model={vendasFinalizadas} />
                    </Grid>
                )}
                {VariaveisAmbiente.paymentDevice === EnumDeviceType.MERCADOPAGO ||
                    VariaveisAmbiente.paymentDevice ===
                    EnumDeviceType.MERCADOPAGO_DEV ? (
                    ''
                ) : (
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        className={classes.listContainerChart}
                    >
                        <CardDashboardChartHeatMap model={vendasFinalizadas} />
                    </Grid>
                )}
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    className={classes.listContainerChart}
                >
                    <CardDashboardChartCategorias model={dataCategorias} />
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    className={classes.listContainerChart}
                >
                    <CardDashboardChartVendedores model={dataVendedores} />
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={12}
                    xs={12}
                    className={classes.listContainerChart}
                >
                    <CardDashboardChartProdutos model={dataProdutos} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DashboardInfo;
