import { Box, Typography } from "@material-ui/core";
import { useStyle } from "./card-filtro-styles"
import React from "react";
import { MaisIcon } from "views/components/icons";
import { isEqual } from "lodash";
import { useThemeQueries } from "views/theme";
import { FecharIconCircle } from "views/components/icons/fechar-icon-circle";

interface CardFiltroProps<T, X> {
  nome: string;
  propName: string | string[];
  icon: React.ReactNode;
  setState: React.Dispatch<React.SetStateAction<T>>;
  setTela: React.Dispatch<React.SetStateAction<X>>;
  telaName: string;
  valorPadrao: any | any[];
  value?: string;
  valorAtual?: any | any[];
}

export const CardFiltro = <T, X>({
  icon,
  nome,
  value,
  propName,
  setState,
  setTela,
  telaName,
  valorPadrao,
  valorAtual
}: CardFiltroProps<T, X>) => {
    const classes = useStyle();
    const {theme} = useThemeQueries()
    return (
        <Box className={classes.item} onClick={() => setTela(prev => ({
            ...prev,
            [telaName]: true
        }))}>
            <Box>
                {icon}
                <Typography>{nome}</Typography>
            </Box>
            <Box>
                <Typography className={classes.greyText}>{value}</Typography>
                {!value || (isEqual(valorAtual, valorPadrao)) ? <MaisIcon fill={theme.palette.secondary.main} /> : <Box height={25} onClick={(e) => {
                    e.stopPropagation()
                    setState(prev => {
                        if (typeof propName === 'string') {
                            return {
                                ...prev,
                                [propName]: valorPadrao
                            }
                        }
                        const obj = propName.reduce<T>((prev, curr, i) => {
                            return {
                                ...prev,
                                [curr]: valorPadrao[i]
                            }
                        }, prev)

                        return {
                            ...obj
                        }
                    })
                }}>
                    <FecharIconCircle fill={theme.palette.secondary.main} />
                </Box>}
            </Box>
        </Box>
    )
}
