
// 1. Libs Externas
import { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
// 2. Serviços e API Hooks
import { useCadastros } from 'services/app';
// 3. Componentes
import { ModalHeader } from 'views/components/modals/components';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import {
    AdicionarEntradaIcon,
    VoltarIcon,
} from 'views/components/icons';
// 5. Estilos
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { useStyles } from './entrada-opcoes-styles';
// 6. Tipos e Interfaces
import { EntradaOpcoesProps } from './entrada-opcoes-props';
import { EnumEntradaOpcoes } from '../../entrada-modal-props';
import { useThemeQueries } from 'views/theme';
import { UploadCloudIcon } from 'views/components/icons/upload-cloud-icon';

export const EntradaOpcoes = ({ setOpcao }: EntradaOpcoesProps) => {
    // Hooks de Estado e Refs
    const { fecharEntradaModal } = useCadastros();
    // Estilos
    const modalClasses = useModalStyles();
    const classes = useStyles();
    const { theme } = useThemeQueries()
    
    const handleImportClick = useCallback(() => {
        setOpcao(EnumEntradaOpcoes.ImportarXML);
    }, [setOpcao]);



    const opcoes: {
        icone: ReactNode,
        title: string,
        acao: () => void,
        disabled: boolean,
        tooltip: string
    }[] = [
        {
            icone: <UploadCloudIcon tipo="BUTTON" class={classes.icon} fill={theme.palette.secondary.main} />,
            title: 'Importar XML',
            acao: handleImportClick,
            disabled: false,
            tooltip: 'Importar XML'
        },
        // {
        //     icone: <BarcodeIcon tipo="BUTTON" class={classes.icon} fill={theme.palette.secondary.main} />,
        //     title: 'Escanear Chave de Acesso',
        //     acao: () => setOpcao(EnumEntradaOpcoes.ImportarXML),
        //     disabled: false,
        //     tooltip: 'Ainda não feito'
        // },
        //Está comentado porque ainda não existe a tela para escanear a chave de acesso. 

        {
            icone: <AdicionarEntradaIcon tipo="BUTTON" class={classes.icon} fill={theme.palette.secondary.main} />,
            title: 'Adicionar Entrada',
            acao: () => setOpcao(EnumEntradaOpcoes.EntradaManual),
            disabled: false,
            tooltip: ''
        }
    ]

    return (
        <div className={modalClasses.root}>

            <ModalHeader
                title={'Entradas'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={fecharEntradaModal}
                    />
                }
            />

                <div className={classNames(modalClasses.content, classes.content)}>
                    <Grid className={classNames(modalClasses.contentForms, classes.container)}>
                        <Grid container spacing={2}>
                            {opcoes.map((opcao, i) => {
                                return (
                                    <Grid item key={`opcoes-entrada-${i}`} xs={4}>
                                        <Tooltip
                                            title={opcao.tooltip}
                                            arrow
                                            disableHoverListener={!opcao.disabled}
                                            disableFocusListener={!opcao.disabled}
                                            disableTouchListener={!opcao.disabled}

                                        >
                                            <Grid>
                                                <Button variant="contained" fullWidth className={classes.btn} onClick={opcao.acao} disabled={opcao.disabled} >
                                                    <Grid direction='column' className={classes.buttonContent}>
                                                        <Grid className={classes.iconContainer} justifyContent="center" alignItems="center">
                                                            {opcao.icone}
                                                        </Grid>
                                                        <Grid className={classes.titleContainer} alignItems="center">
                                                            <Typography className={classes.title}>
                                                                {opcao.title}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </div>
            
        </div>
    )}


