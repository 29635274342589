import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { Controller, useForm } from "react-hook-form";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { useFormInfoAdicionalProdutoValidation } from "./form-info-adicional-produto-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-ui/core";
import { forwardRef, useImperativeHandle } from "react";
import { DefaultFormProps, DefaultFormRefs } from "../../utils";



export const FormInfoAdicionalProduto = forwardRef<
    DefaultFormRefs<MovSimplesProdutoModel>,
    DefaultFormProps<MovSimplesProdutoModel>
>(({ loading, ...props }, ref) => {

    const { FormInfoAdicionalProdutoValidation } = useFormInfoAdicionalProdutoValidation();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<MovSimplesProdutoModel>({
        resolver: yupResolver(FormInfoAdicionalProdutoValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (model: MovSimplesProdutoModel) => {
        props.onSubmit(model)
    }

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: () => {
        },
    }));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="infAdic"
                control={control}
                render={({ field }) => (
                    <TextFieldSaurus
                        label=' Observação'
                        tipo='TEXTO'
                        minRows={1}
                        maxRows={4}
                        multiline
                        fullWidth
                        allowSubmit
                        placeholder='Informações adicionais'
                        error={Boolean(
                            errors.infAdic && errors.infAdic.message,
                        )}
                        helperText={
                            errors.infAdic
                                ? errors.infAdic?.message
                                : undefined
                        }
                        {...field}
                    />
                )}
            />
            <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
    );
})