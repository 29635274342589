import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback } from 'react';
import { CarrinhoListPedido } from 'views/pages/private/movimentacao/mov-carrinho/components/carrinho-list/carrinho-list-data';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';

export const useAgruparProdutos = (list: Array<MovSimplesProdutoModel>) => {
  // HOOKS
  const { getConfigByCod } = useContratoAtual();

  const produtosVendaAdicionais =
    list?.filter(
      (produto) =>
        produto.pedidoId === '' &&
        (produto.indFin || (produto.ativo && (produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Produto || produto.tpProduto === EnumTpProduto.Combo || produto.tpProduto === EnumTpProduto.Medicamento))) && 
        produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoServico) &&
        produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoEntrega)
    ) ?? [];

  const produtosPedidosAdicionais = useCallback(
    () =>
      list?.filter(
        (produto) =>
          produto.indFin &&
          produto.pedidoId !== '' &&
          produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoServico) &&
          produto.produtoId !== getConfigByCod(EnumContratoConfig.ProdutoEntrega)
      ) ?? [],
    [getConfigByCod, list]
  );

  const produtosPedidos = useCallback(
    () =>
      list
        ?.filter(
          (produto) =>
            produto.pedidoId !== '' && (produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Produto || produto.tpProduto === EnumTpProduto.Combo || produto.tpProduto === EnumTpProduto.Medicamento)
        ).filter(p => {
          if (p.tpProduto === EnumTpProduto.Combo || p.indFin || (p.tpProduto === EnumTpProduto.ProdutoComSubItem && !p.produtoPaiId && p.subItens.length > 0)) {
            return p
          }

          return null
        })
        .map((p) => {
          const adicionais = produtosPedidosAdicionais().filter(
            (adicional) => adicional.idGroup === p.id
          );
          if (adicionais.length > 0) {
            return {
              ...p,
              adicionais: adicionais
            };
          }
          return p;
        }) ?? [],
    [list, produtosPedidosAdicionais]
  );

  const agruparProdutosPorPedido = useCallback(() => {
    const produtosAgrupados: CarrinhoListPedido[] = [];

    for (var i = 0; i < produtosPedidos().length; i++) {
      var pedidoIgual = false;
      for (var j = 0; j < i; j++) {
        if (
          produtosAgrupados[j] &&
          produtosPedidos()[i].pedidoId === produtosAgrupados[j].pedidoId
        ) {
          produtosAgrupados[j].produtos.push(produtosPedidos()[i]);
          pedidoIgual = true;
          break;
        }
      }

      if (!pedidoIgual) {
        produtosAgrupados.push({
          codigoPedido: produtosPedidos()[i].codigoPedido,
          pedidoId: produtosPedidos()[i].pedidoId,
          comandaId: produtosPedidos()[i].comandaId,
          mesaId: produtosPedidos()[i].mesaId,
          produtos: [produtosPedidos()[i]],
        });
      }
    }

    return produtosAgrupados;
  }, [produtosPedidos]);

  const agruparProdutosPorAdicionais = () => {
    const produtosAgrupados: MovSimplesProdutoModel[] =
      produtosVendaAdicionais.filter((p) => !p.produtoPaiId && (p.tpProduto === EnumTpProduto.ProdutoComSubItem || p.tpProduto === EnumTpProduto.Produto || p.tpProduto === EnumTpProduto.Combo || p.tpProduto === EnumTpProduto.Medicamento));
    return produtosAgrupados.map((prodAgrupado) => {
      const prodAdicional = produtosVendaAdicionais.filter(
        (adicional) => adicional.idGroup === prodAgrupado.id && adicional.tpProduto !== EnumTpProduto.Combo && adicional.tpProduto !== EnumTpProduto.ProdutoComSubItem && adicional.tpProduto !== EnumTpProduto.Produto && adicional.tpProduto !== EnumTpProduto.Medicamento
      );

      if (prodAdicional.length > 0) {
        return {
          ...prodAgrupado,
          adicionais: prodAdicional
        };
      }

      return prodAgrupado;
    });
  };

  return {
    agruparProdutosPorPedido,
    agruparProdutosPorAdicionais
  };
};
