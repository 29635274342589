import { makeStyles } from '@material-ui/core';

export const useButtonFabStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[8],
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));
