import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    button: {
        margin: 0,
        width: '45px',
        "& svg": {
            fill: theme.palette.primary.contrastText,
        }
    }
}))
