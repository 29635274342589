import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './mov-finalizar-page-styles';
import { FinalizarVendaHeader } from './components/finalizar-venda-header';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useState } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { useValidarVenda } from 'services/app/hooks/validacao-finalizar-venda';
import { EnumMovModelo } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useHistory } from 'react-router-dom';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { DialogNotaFiscal } from 'views/components/dialog/dialog-nota-fiscal/dialog-nota-fiscal';
// import { usePDV } from 'services/app/hooks/pdv';
// import { isPlanoFiscal } from 'utils/plano-utils';
// import { useSessaoAtual } from 'services/app';

const MovFinalizarPage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { getMov } = useMovAtual();
  const { validarVendaAtual } = useValidarVenda();
  const history = useHistory();
  const mov = getMov();
  // const { getConfigByCod } = usePDV();
  const [dialogNF, setDialogNF] = useState<boolean>(false);
  // const { plano } = useSessaoAtual()

  const [statusAtualMov, setStatusAtualMov] = useState(
    mov?.retornoFinalizacao?.status,
  );
  const { addHandler, removeHandler } = useEventTools();

  // REMOVENDO NOTA MANUAL TEMPORARIAMENTE
  // const modeloVenda = getConfigByCod(50);
  // const notaManual = getConfigByCod(102);
  // const isFiscal = isPlanoFiscal(plano?.plano)

  useEffect(() => {
    if (!mov) {
      history.push('/venda-simples/finalizar-venda/comprovante');
    }

    if (mov?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO) {
      history.push('/venda-simples/enviar-pedido');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const movAlterada = useCallback((mov: MovSimplesModel) => {
    setStatusAtualMov((previous) => {
      if ((mov?.retornoFinalizacao?.status || '') !== (previous || '')) {
        return mov?.retornoFinalizacao?.status;
      }
      return previous;
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    return () => {
      removeHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    };
  }, [addHandler, movAlterada, removeHandler]);

  const isVendaFiscal =
    mov?.mod === EnumMovModelo.NFCE ||
    mov?.mod === EnumMovModelo.NFE ||
    mov?.mod === EnumMovModelo.SAT;
  const nnf = mov?.nnf || 0;
  const serie = mov?.serie || 0;
  const vNF = mov?.vNF || 0;

  const selecionarMod = useCallback(
    async (mod: number) => {
      setDialogNF(false);
      await validarVendaAtual(mod);
    },
    [validarVendaAtual],
  );

  //EFFECT INICIAL
  useEffect(() => {
    validarVendaAtual(getMov()?.mod);
    // DESATIVANDO NOTA MANUAL TEMPORARIAMENTE
    // if (notaManual !== '1') {
    //   validarVendaAtual();
    //   return;
    // }
    // if (modeloVenda !== 'Venda Simples') {
    //   if (
    //     notaManual === '1' &&
    //     mov?.mod !== EnumMovModelo.NFE &&
    //     mov?.mod !== EnumMovModelo.SAT &&
    //     mov?.mod !== EnumMovModelo.NOTAMANUAL &&
    //     isFiscal
    //   ) {
    //     setDialogNF(true);
    //   } else {
    //     validarVendaAtual();
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid className={classes.header}>
          <FinalizarVendaHeader />
        </Grid>
        {!dialogNF && (
          <Grid className={classes.content}>
            <Grid
              className={
                isMobile ? classes.infoContainer : classes.infoContainerPC
              }
            >
              <Typography className={classes.textTitle}>
                {(statusAtualMov || '') === 'salvo'
                  ? !isVendaFiscal
                    ? 'Finalizando Venda'
                    : 'Emitindo Nota Fiscal'
                  : 'Processando Venda'}
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 32,
                }}
              >
                <LoadingFinalizacao />
              </Grid>

              <Grid
                className={
                  !isMobile ? classes.infoCardContent : classes.cardInfo
                }
              >
                <Grid container justifyContent="space-between">
                  <Typography className={classes.label}>
                    Número / Série
                  </Typography>
                  <Typography className={classes.label}>Valor</Typography>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: 16 }}
                  justifyContent="space-between"
                >
                  <Typography className={classes.infoTotal}>
                    {nnf !== 0 && serie !== 0
                      ? nnf + ' / ' + serie
                      : 'Gerando...'}
                  </Typography>
                  <Typography className={classes.infoTotal}>
                    R$ {toDecimalString(vNF)}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="subtitle1" className={classes.textSubtitle}>
                {statusAtualMov === 'emitindo' && isVendaFiscal
                  ? isVendaFiscal
                    ? 'Aguarde mais alguns instantes enquanto emitimos a nota fiscal. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'
                    : 'Aguarde mais alguns instantes enquanto finalizamos a venda. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'
                  : 'Aguarde mais alguns instantes enquanto salvamos a venda. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DialogNotaFiscal
        openned={dialogNF}
        onClickNF={async () => await selecionarMod(65)}
        onClickManual={async () => await selecionarMod(1)}
      />
    </>
  );
};

export default MovFinalizarPage;
