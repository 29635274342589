import { guidEmpty } from "utils/guid-empty";

export class ConfigIntervalo{
    constructor(
        public minimo: number = 0,
        public maximo: number = 0
    ){}
}

export class ConfigEmpresaOpcoes{
    constructor(
        public intervalo: ConfigIntervalo = new ConfigIntervalo(),
        public opcoesTexto: string[] | null = null,
        public opcoesValores: number[] | null = null
    ){ }
}

export class ConfigEmpresaModel {
    constructor(
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public cod: number = 0,
        public descConfig: string = '',
        public vConfig: string = '',
        public grupoConfig: string = '',
        public ordem: number = 0,
        public tpControle: number = 0,
        public editado: boolean = false,
        public titulo: string = '',
        public opcoes:  ConfigEmpresaOpcoes | null = null
    ) { }
}
