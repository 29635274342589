import { Button, Grid, Slide } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { useThemeQueries } from 'views/theme';
import { SATIcon, SalvarIcon, VoltarIcon } from 'views/components/icons';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { DefaultFormRefs } from 'views/components/form/utils';
import { FormHorarioAcesso } from 'views/components/form/horario-acesso/form-horario-acesso';
import { useGetPerfilById } from 'data/api/gestao/perfil';
import { PerfilModel } from 'model';
import { usePutPerfil } from 'data/api/gestao/perfil/put-perfil';
import { useGetHorario } from 'data/api/gestao/empresa-horario';
import { HorarioModel } from 'model/api/gestao/horarios-funcionamento/horario-model';
import { guidEmpty } from 'utils/guid-empty';
import { PerfilHorarioModel } from 'model/api/gestao/perfil-horario/perfil-horario-model';

interface DialogHorarioAcessoProps {
  openned: boolean;
  closeModal: () => void;
  perfilId: string
}

const Transition = forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export const DialogHorarioAcesso = ({
  openned,
  closeModal,
  perfilId
}: DialogHorarioAcessoProps) => {

  const { isMobile } = useThemeQueries()
  const { getEmpresaSelecionada } = useSessaoAtual()

  const cadHorarioFormRef =
    useRef<DefaultFormRefs<PerfilModel>>(null);
  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(false)
  const { getPerfilById, carregando: carregandoGet } = useGetPerfilById()
  const { getHorario, carregando: carregandoGetHorario } = useGetHorario()
  const { putPerfil, carregando: carregandoPut } = usePutPerfil()
  const { showToast } = useToastSaurus()
  const [perfilHorarioModel, setPerfilHorarioModel] = useState<PerfilModel>(new PerfilModel())
  const [empresaHorario, setEmpresaHorario] = useState<HorarioModel>(new HorarioModel())
  const horarioFormRef = useRef<PerfilModel>(new PerfilModel())

  const carregando =
    preenchendoTela ||
    carregandoGet ||
    carregandoGetHorario ||
    carregandoPut

  const recarregarForm = useCallback((value: PerfilModel) => {
    cadHorarioFormRef.current?.fillForm(value)
  }, [])

  useEffect(() => {
    recarregarForm(perfilHorarioModel)
  }, [perfilHorarioModel, recarregarForm])

  const getHorarioWrapper = useCallback(async () => {
    const res = await getPerfilById(perfilId)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as PerfilModel

    horarioFormRef.current = ret

    return ret
  }, [getPerfilById, perfilId])

  const preencherTela = useCallback(async () => {
    try {
      setPreenchendoTela(true)
      const horario = await getHorarioWrapper()
      setPerfilHorarioModel(horario)
    } catch (e: any) {
      showToast('error', e.message)
    } finally {
      setPreenchendoTela(false)
    }
  }, [getHorarioWrapper, showToast])

  useEffect(() => {
    if (openned)
      (async () => {
        await preencherTela()
      })()
  }, [getHorarioWrapper, openned, preencherTela, showToast])

  const saveHorario = useCallback(async (value: PerfilModel) => {

    const res = await putPerfil(value)

    if (res.erro)
      showToast('error', res.erro.message)

  }, [putPerfil, showToast])

  const handleSubmit = useCallback(
    async (model: PerfilModel) => {
      try {
        await saveHorario(model)
        showToast('success', 'Horários Salvos')

        await preencherTela()
      } catch (e: any) {
      }
    },
    [preencherTela, saveHorario, showToast],
  );

  useEffect(() => {
    (async () => {
      if (openned) {
        try {
          const res = await getHorario(getEmpresaSelecionada()!.Id, 0)

          if (res.erro) throw res.erro
          if (res.resultado?.data.list.length === 0) {
            return
          }

          const horario = res.resultado?.data?.list[0] as HorarioModel
          setEmpresaHorario(horario)
        } catch (e: any) {

        }
      }
    })()
  }, [getEmpresaSelecionada, getHorario, openned])

  const replicarHorarioEmpresa = useCallback(async () => {
    try {
      setPreenchendoTela(true)

      const horariosReplicados = { ...empresaHorario }
      //colocando os IDS do horário atual no horário replicado do delivery
      horariosReplicados.id = horarioFormRef.current.id
      horariosReplicados.empresaHorarioDias.map((dia) => {
        dia.empresaHorarioId = horarioFormRef.current.id
        const diaId = horarioFormRef.current.horarios.find(item => item.diaSemana === dia.diaSemana)?.id

        if (!diaId) {
          dia.id = guidEmpty()
          return dia
        }

        dia.id = diaId

        return dia
      })

      setPerfilHorarioModel(prev => {
        const newObj = { ...prev }
        const perfilHorario = horariosReplicados.empresaHorarioDias.map(hour => {
          const newObj = { ...hour, perfilId: prev.id } as PerfilHorarioModel
          return newObj
        })
        newObj.horarios = perfilHorario

        return newObj
      })
    } catch (e: any) {
      showToast('error', e.message)
    } finally {
      setPreenchendoTela(false)
    }
  }, [empresaHorario, showToast])

  return (
    <DialogSaurus
      aberto={openned}
      titulo="Horário de Acesso"
      removerPaddingContent={isMobile}
      fullHeightContent
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : undefined}
    >
      {openned && (
        <div style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}>
          <div style={{
            height: isMobile ? '0' : '100%',
            flex: '1 1 auto',
            overflowY: 'auto'
          }}>
            <FormHorarioAcesso
              loading={carregando}
              onSubmit={handleSubmit}
              showLoading={true}
              ref={cadHorarioFormRef}
              perfilId={perfilId}
            />
          </div>
          <div style={{
            flex: '0 0 auto',
            padding: '16px',
            display: 'flex',
          }}>
            <Grid container spacing={2} style={{
              justifyContent: 'between'
            }}>
              <Grid item xs={12} md={6}>
                {empresaHorario.empresaHorarioDias.length > 0 && <Grid item>
                  <Button color="primary" onClick={replicarHorarioEmpresa} variant="outlined" fullWidth={isMobile} size={isMobile ? 'small' : 'medium'}>
                    <SATIcon tipo="BUTTON" />
                    Replicar do Estabelecimento
                  </Button>
                </Grid>}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item xs={6} md={'auto'}>
                    <Button color="primary" onClick={closeModal} variant="outlined" fullWidth={isMobile}>
                      <VoltarIcon tipo="BUTTON" />
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={'auto'}>
                    <Button color="primary" onClick={() => cadHorarioFormRef.current?.submitForm()} variant="contained" fullWidth={isMobile}>
                      <SalvarIcon tipo="BUTTON_PRIMARY" />
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </DialogSaurus>
  );
};
