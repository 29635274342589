import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        position: 'relative'
    },
    fillError: {
        fill: theme.palette.error.main,
    },
    fillSuccess: {
        fill: theme.palette.success.main,
    },
    fillWarning: {
        fill: theme.palette.warning.main,
    },
    fillInfo: {
        fill: theme.palette.info.main,
    },
    btnError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '&:hover': {
            borderColor: theme.palette.error.main,
            background: 'rgba(0, 0, 0, 0.03)'
        }
    },
    btnSuccess: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        '&:hover': {
            borderColor: theme.palette.success.main,
            background: 'rgba(0, 0, 0, 0.03)'
        }
    },
    btnWarning: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        '&:hover': {
            borderColor: theme.palette.warning.main,
            background: 'rgba(0, 0, 0, 0.03)'
        }
    },
    btnInfo: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        '&:hover': {
            borderColor: theme.palette.info.main,
            background: 'rgba(0, 0, 0, 0.03)'
        }
    },
    icon: {
        width: 92,
        height: 92
    },
    text: {
        fontWeight: 500,
    },
    fecharBox: {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        zIndex: 1301
    },
}));