import { Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { ConteudoLeitura } from "views/pages/private/gerenciar-sessao/sessao-leitura-caixa/components/conteudo-leitura/conteudo-leitura";
import { useThemeQueries } from "views/theme"
import { CancelarIcon } from "views/components/icons";
import { RetornarIcon } from "views/components/icons/retornar-icon";
import { useStyles } from "./accordion-edicao-gerenciamento-sessao-mobile-styles";

export interface AccordionEdicaoGerenciamentoSessaoImpressaoMobileProps {
    conteudotela: string;
    error: boolean;
    getResumo: () => Promise<void>
}

export const AccordionEdicaoGerenciamentoSessaoImpressaoMobile = ({
    conteudotela,
    error,
    getResumo,
}: AccordionEdicaoGerenciamentoSessaoImpressaoMobileProps) => {

    const { theme } = useThemeQueries();
    const classes = useStyles();

    const [openAccordion, setOpenAccordion] = useState<boolean>(false);

    return (
        <>
            <AccordionSaurus
                labelPrimary={'Impressão da Sessão'}
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
            >
                <>
                    <Grid item xs={12} className={classes.list}>
                        <Grid className={classes.containerResumo}>
                            {error ? (
                                <Grid className={classes.resumo}>
                                    <Grid className={classes.containerError}>
                                        <Grid className={classes.imageError}>
                                            <CancelarIcon tipo="GERAL" fill="#D22" />
                                        </Grid>
                                        <Typography
                                            variant="body1"
                                            className={classes.textError}
                                        >
                                            Dados não sincronizados, por favor tente novamente mais tarde.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={async () => {
                                                await getResumo();
                                            }}
                                        >
                                            <RetornarIcon tipo="BUTTON_PRIMARY" />
                                            Tentar Novamente
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid className={classes.resumo}>
                                    {conteudotela && <ConteudoLeitura text={conteudotela} />}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            </AccordionSaurus>
        </>
    )
}