import * as Yup from "yup";
import { useMemo } from "react";
import { useGestaoToken, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { isPlanoFiscal } from "utils/plano-utils";

export const useFormProdutoCompletoPreCadastroValidation = () => {
  const { plano } = useSessaoAtual();
  const {getEmpresaAtual} = useGestaoToken()
  const isFiscal = isPlanoFiscal(plano?.plano);
  const empresaFiscal = getEmpresaAtual()?.isFiscal

  const FormProdutoCompletoPreCadastroYupValidation = useMemo(() => {
    if (isFiscal) {
      return Yup.object().shape({
        nome: Yup.string()
          .required("O nome é obrigatório")
          .min(3, "O tamanho mínimo do nome é de 3 caracteres"),
        medida: Yup.string().required("Nos informe a medida"),
        imposto: empresaFiscal ? Yup.string().required("Nos informe o grupo de imposto.") : Yup.string(),
        codigoNcm: empresaFiscal ? Yup.string().required("Nos informe o código NCM.") : Yup.string(),
        balanca: Yup.number(),
        vPreco: Yup.string()
          .when('balanca', {
            is: 0,
            then: Yup.string(),
            otherwise: Yup.string().required('Insira um valor se for pesável.')
          })
        // nomeCategoria: Yup.string().required("Favor selecione a categoria do produto"),
      });
    }

    return Yup.object().shape({
      nome: Yup.string()
        .required("O nome é obrigatório")
        .min(3, "O tamanho mínimo do nome é de 3 caracteres"),
      medida: Yup.string().required("Nos informe a medida"),
      // nomeCategoria: Yup.string().required("Favor selecione a categoria do produto"),
    });
  }, [isFiscal, empresaFiscal]);

  return {
    FormProdutoCompletoPreCadastroYupValidation,
  };
};
