import { Grid } from "@material-ui/core"
import { SaloesHeader } from "./components/saloes-header/saloes-header"
import { useDefaultCadastroStyles } from "../components/default-cadastro-styles"
import { SaloesList } from "./components/saloes-list/saloes-list";

const SaloesPage = () => {

    const classes = useDefaultCadastroStyles();

    return (
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <SaloesHeader />
                </Grid>
                <Grid className={classes.list}>
                    <SaloesList />
                </Grid>
            </Grid>
        </>
    )
}

export default SaloesPage