import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        background: theme.palette.common.white,
    },
    cardContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        background: "#fff",
        borderRadius: 4,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        "& svg": {
            width: 20,
            height: 20,
        }
    },
    textTitle: {
        fontWeight: 500,

    },
    textConsumed: {
        fontWeight: 600
    },
    textInfinite: {
        fontWeight: 600,
        marginTop: -3,
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(1)
    },
    textTotal: {
        fontWeight: 600,
        color: theme.palette.error.main,
    }
}));
