import { Button, Card, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { EmpresaIcon } from 'views/components/icons';
import { useStyles } from './card-adicionar-empresa-styles';

export const CardAdicionarEmpresa = () => {
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push('/adicionar-empresa');
  };

  return (
    <Card className={classes.card}>
      <Typography variant="h6" className={classes.title}>
        Quer gerenciar mais empresas?
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Clique abaixo para adicionar novas empresas em sua conta de um só lugar!
      </Typography>
      <Button onClick={onClick} variant="contained" color="primary" fullWidth>
        <EmpresaIcon tipo="BUTTON_PRIMARY" />
        Adicionar
      </Button>
    </Card>
  );
};
