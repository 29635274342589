import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { MovGerenciamentoSessoesSearchProps } from './components/mov-gerenciamento-sessoes-list/mov-gerenciamento-sessoes-search-props';
import { MovGerenciamentoSessoesList } from './components/mov-gerenciamento-sessoes-list/mov-gerenciamento-sessoes-list';
import { MovGerenciamentoSessoesHeader } from './components/mov-gerenciamento-sessoes-header/mov-gerencimento-sessoes-header';

const MovGerenciamentoSessoesPage = () => {
  const classes = useDefaultCadastroStyles();

  const dataAtual = new Date().toISOString().split('T')[0];

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<MovGerenciamentoSessoesSearchProps>(
    {
      dInicial: dataAtual,
      dFinal: dataAtual,
      status: -1,
    },
  );

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <MovGerenciamentoSessoesHeader
            openPesquisa={openPesquisa}
            setSearchProps={setSearchProps}
            setOpenPesquisa={setOpenPesquisa}
          />
        </Grid>
        <Grid className={classes.list}>
          <MovGerenciamentoSessoesList searchProps={searchProps} />
        </Grid>
      </Grid>
    </>
  );
};

export default MovGerenciamentoSessoesPage