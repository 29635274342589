import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Card, Divider, Grid } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';

import { AutocompleteProdutos } from '../../../../controles/autocompletes/autocomplete-produtos/autocomplete-produtos';
import { ProdutoResumidoModel } from '../../../../../../model/api/gestao/produto/produto/produto-resumido-model';
import { Controller, useForm } from 'react-hook-form';
import { FecharIcon, LixoIcon } from 'views/components/icons';
import { useStyles } from './form-modificador-produto-styles'
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { useFormNovoProdutoSubItemValidation } from './form-modificador-produto-validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCadastros, useToastSaurus } from 'services/app';
import { useGetProdutoById } from 'data/api/gestao/produto/produto/get-produto-by-id';
import { ProdutoNovoModel } from 'model/api/gestao/produto/produto/produto-novo-model';
import { ProdutoCompletoModel } from 'model/api/gestao/produto/produto/produto-completo-model';
import { usePostProduto } from 'data/api/gestao/produto/produto/post-produto';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { ProdutoCompletoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { FormModificadorProdutoProps } from './form-modificador-produto-props';
import { ProdutoModificadorFormModel } from 'model/app/forms/produto/produto-modificador/produto-modificador-form-model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { toDecimal } from 'utils/to-decimal';
import { toInteger } from 'lodash';


export const FormModificadorProduto = forwardRef<
  DefaultFormRefs<ProdutoModificadorFormModel>,
  FormModificadorProdutoProps
>(({ loading, closeForm, isFormEdit = false, ...props }: FormModificadorProdutoProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInput = useRef<HTMLInputElement>(null);
  const { isMobile, theme } = useThemeQueries();
  const [modelForm, setModelForm] = useState<ProdutoModificadorFormModel>(
    props.model ?? new ProdutoModificadorFormModel()
  );
  const [, setUpdate] = useState<boolean>(false)
  const [focus, setFocus] = useState<boolean>(false)
  const classes = useStyles()
  const { showToast } = useToastSaurus();

  const { getProdutoById, carregando: carregandoGet } = useGetProdutoById();
  const { postProduto, carregando: carregandoPost } = usePostProduto();

  const { abrirCadastroProdutoFacilitado } = useCadastros()
  const { getEmpresaAtual } = useEmpresaAtual();

  const { FormNovoProdutoSubItemYupValidation } = useFormNovoProdutoSubItemValidation()

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<ProdutoModificadorFormModel>({
    resolver: yupResolver(FormNovoProdutoSubItemYupValidation),
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    mode: 'onChange',
  });


  const onSubmit = useCallback(async (values: ProdutoModificadorFormModel) => {
    const model = picker<ProdutoModificadorFormModel>(
      values,
      new ProdutoModificadorFormModel(),
    );
    const beforeModel = picker<ProdutoModificadorFormModel>(
      modelForm,
      new ProdutoModificadorFormModel()
    )

    const res = await props.onSubmit(model, beforeModel);

    if (res) {
      closeForm && closeForm()
    }
  }, [closeForm, modelForm, props]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInput.current?.focus();
    },
    fillForm: (model: ProdutoModificadorFormModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInput.current?.focus();
      }, 500);
    },
  }));

  const getAdicionalById = useCallback(async (id: string) => {
    const res = await getProdutoById(props.empresaId, id)

    if (res.erro) throw res.erro

    const ret = res.resultado?.data as ProdutoCompletoModel

    return ret
  }, [getProdutoById, props.empresaId])

  const handleCadastroProdutoFacilitado = useCallback((produto: ProdutoCompletoModel, adicional: ProdutoNovoModel) => {
    try {
      setValue('produtoNome', produto.nome)
      setValue('produtoGradeId', produto.grades[0].id)

      // if (autoCadastrar) {
      //   handleSubmit(onSubmit)()
      //   return
      // }

      setUpdate(prev => !prev)

    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [setValue, showToast])

  const cadastrarProdutoFacilitado = useCallback(async (name: string, editar: boolean) => {
    try {
      const produto = new ProdutoNovoModel();
      produto.nome = name;
      produto.empresaId = getEmpresaAtual()!.id
      produto.tipo = EnumTipoProduto.Produto
      const ret = await postProduto(produto, getEmpresaAtual()!.id);
      if (ret.erro) throw ret.erro;

      produto.id = ret.resultado?.data.id

      const produtoCompleto = await getAdicionalById(produto.id)

      showToast('success', 'Adicional Cadastrado!')


      if (editar) {
        abrirCadastroProdutoFacilitado(async (produtoRetornado: ProdutoCompletoPreCadastroFormModel) => {
          produtoCompleto.nome = produtoRetornado.nome
          handleCadastroProdutoFacilitado(produtoCompleto, produto)
        }, produto, produtoCompleto.grades[0].id)
        return
      }

      handleCadastroProdutoFacilitado(produtoCompleto, produto)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [abrirCadastroProdutoFacilitado, getAdicionalById, getEmpresaAtual, handleCadastroProdutoFacilitado, postProduto, showToast])

  const carregando = loading || carregandoGet || carregandoPost

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {carregando && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="FULLSIZED" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Card style={{
              padding: 16,
              position: 'relative'
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.closeButtonContainer}>
                  <FecharIcon class={classes.closeButtonIcon} onClick={closeForm && closeForm} />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="produtoNome"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutos
                        inputRef={refInput}
                        loadingExterno={loading}
                        label={"Produto"}
                        allowSubmit
                        error={Boolean(
                          errors.produtoNome && errors.produtoNome.message,
                        )}
                        helperText={
                          errors.produtoNome
                            ? errors.produtoNome?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('produtoNome')}
                        onChange={(retorno) => {
                          if (retorno.isNewVal) {
                            let editar = false
                            if (typeof retorno.isNewVal === 'object') {
                              editar = retorno.isNewVal.identificador === 'editar'
                            }
                            cadastrarProdutoFacilitado(retorno.value, editar)
                            return
                          }
                          if (!retorno.isString) {
                            const produto = picker<ProdutoResumidoModel>(
                              retorno.value,
                              new ProdutoResumidoModel(),
                            );

                            setValue('produtoNome', produto.nome);
                            setValue('produtoGradeId', produto.produtoGradeId);
                          }
                        }}
                        permiteAdicionar
                        textNovoItem={[
                          {
                            texto: 'Adicionar:',
                            identificador: 'adicionar'
                          },
                          {
                            texto: 'Adicionar e Editar:',
                            identificador: 'editar'
                          }
                        ]}
                        tpProduto={[
                          EnumTipoProduto.Insumo,
                          EnumTipoProduto.Produto,
                          EnumTipoProduto.Servico,
                          EnumTipoProduto.Combo
                        ]}
                        exibirTipo
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="valor"
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor"
                        disabled={loading}
                        tipo="DECIMAL"
                        placeholder="(Opcional)"
                        manterMascara
                        error={Boolean(
                          errors.valor && errors.valor.message,
                        )}
                        helperText={
                          errors.valor
                            ? errors.valor?.message
                            : undefined
                        }
                        {...field}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        value={(!focus && toInteger(toDecimal(getValues('valor'))) === 0) ? '' : getValues('valor')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"}>
                    {isFormEdit && (
                      <Button
                        variant='contained'
                        onClick={props.handleDelete}
                        className={classes.buttonCancelar}
                      >
                        <LixoIcon tipo="BUTTON_PRIMARY" style={{
                          margin: 0
                        }} />
                      </Button>
                    )}
                    <Button variant="contained" color='primary' fullWidth type="submit" size='small'>
                      <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                      Confirmar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>

          </form>
        </div>
      </Box>
    </>
  );
});
