import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './carrinho-vazio-styles';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';

export const CarrinhoVazio = () => {
  const classesCarrinhoVazio = useStyles();

  return (
    <Grid className={classesCarrinhoVazio.containerCarrinhoVazio}>
      <Grid
        className={classNames(
          classesCarrinhoVazio.cardCarrinhoVazio
        )}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Typography
            className={classesCarrinhoVazio.title}
            color="textPrimary"
            variant="h4"
          >
            CAIXA ABERTO
          </Typography>
          <CarrinhoIcon tipo="INPUT" />
          <Typography
            className={classesCarrinhoVazio.textInfo}
            color="textPrimary"
            variant="h4"
          >
            Adicione ou Passe algum Produto.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
