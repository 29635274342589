import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const NewPropagandaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <svg viewBox="0 0 117 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="115" height="54.4107" rx="9.44643" fill="white" stroke="#1F9CE4" stroke-width="2" />
          <path d="M27.7039 36.5358V18.9858H29.7598L41.3678 33.4018H40.2898V18.9858H42.7969V36.5358H40.7411L29.133 22.1197H30.2111V36.5358H27.7039ZM50.3315 26.5573H59.3572V28.6884H50.3315V26.5573ZM50.5571 34.3546H60.7863V36.5358H48.05V18.9858H60.4353V21.167H50.5571V34.3546ZM68.6375 36.5358L62.7708 18.9858H65.3532L70.7435 35.1819H69.4398L75.0307 18.9858H77.3373L82.8029 35.1819H81.5493L87.0149 18.9858H89.3967L83.53 36.5358H80.8975L75.7829 21.518H76.4598L71.3202 36.5358H68.6375Z" fill="#1F9CE4" />
        </svg>

      </DefaultIcon>
    </>
  );
};


