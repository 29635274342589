import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const TristeIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M 25 2 C 12.308633 2 2 12.308633 2 25 C 2 37.691367 12.308633 48 25 48 C 37.691367 48 48 37.691367 48 25 C 48 12.362796 37.775013 2.1030941 25.158203 2.015625 A 1.0001 1.0001 0 0 0 25 2 z M 25 4 C 36.610633 4 46 13.389367 46 25 C 46 36.610633 36.610633 46 25 46 C 13.389367 46 4 36.610633 4 25 C 4 13.389367 13.389367 4 25 4 z M 17 18 A 3 3 0 0 0 14 21 A 3 3 0 0 0 17 24 A 3 3 0 0 0 20 21 A 3 3 0 0 0 17 18 z M 33 18 A 3 3 0 0 0 30 21 A 3 3 0 0 0 33 24 A 3 3 0 0 0 36 21 A 3 3 0 0 0 33 18 z M 25 29 C 18.555556 29 14.292969 33.292969 14.292969 33.292969 A 1.0001 1.0001 0 1 0 15.707031 34.707031 C 15.707031 34.707031 19.444444 31 25 31 C 30.555556 31 34.292969 34.707031 34.292969 34.707031 A 1.0001 1.0001 0 1 0 35.707031 33.292969 C 35.707031 33.292969 31.444444 29 25 29 z" />
      </DefaultIcon>
    </>
  );
};
