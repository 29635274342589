import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';


export function usePutConfirmarEntradaXML() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putConfirmarEntradaXML = useCallback(
        (venda: VendaCompletaModel, id: string, empresaId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/Entrada/${id}/concluir`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(venda),
                enviarTokenUsuario: true,
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putConfirmarEntradaXML,
    };
}