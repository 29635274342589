import { EnumTipoExibicao } from "model/enums/enum-tipo-exibicao";
import { guidEmpty } from "utils/guid-empty";

export class ProdutoCategoriaModel {
  constructor(
    public id: string  = guidEmpty(),
    public categoriaPaiId: string | null = null,
    public breadCrumbs: string = "",
    public nome: string = "",
    public descricao: string = "",
    public cor: string = "",
    public ativo: boolean = true,
    public foto: string = '',
    public tipoExibicao: EnumTipoExibicao = EnumTipoExibicao.Todos, 
    public ordem: number = 0
  ) { }
}
