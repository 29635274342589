import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormGerenciamentoSessaoValorValidation = () => {
    const formGerenciamentoSessaoValorValidation = useMemo(() => {
        return (
            Yup.object().shape({
               tpLancamento: Yup.number().required('O tipo de correção é obrigatório.').typeError('O tipo de correção é obrigatório.'),
            })
        )
    }, [])
    return {
        formGerenciamentoSessaoValorValidation,
    }
}

