import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";
export const FinalizadoraIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon
                tipo={props.tipo}
                class={props.class}
                style={props.style}
                fill={props.fill}
            >
                <path d="M 34.625 3.03125 C 33.84375 3.042969 32.980469 3.214844 31.90625 3.5 C 28.824219 4.3125 11.09375 9.21875 11.09375 9.21875 C 11.0625 9.226563 11.03125 9.238281 11 9.25 C 8.191406 10.308594 6.421875 11.304688 5.34375 12.75 C 4.273438 14.1875 4.035156 15.914063 4.03125 17.96875 C 4.03125 17.980469 4.03125 17.988281 4.03125 18 L 4.03125 18.625 C 4.019531 18.75 4 18.875 4 19 L 4 40.375 C 4 43.464844 6.535156 46 9.625 46 L 40.375 46 C 43.464844 46 46 43.464844 46 40.375 L 46 18.84375 C 46 15.65625 43.148438 13 40 13 L 10 13 C 8.878906 13 7.871094 13.320313 7.03125 13.84375 C 7.757813 12.949219 9.128906 12.105469 11.625 11.15625 C 11.652344 11.144531 11.660156 11.136719 11.6875 11.125 C 11.800781 11.09375 29.425781 6.222656 32.40625 5.4375 C 33.398438 5.175781 34.140625 5.039063 34.65625 5.03125 C 35.171875 5.023438 35.414063 5.101563 35.53125 5.1875 C 35.648438 5.273438 35.746094 5.441406 35.84375 5.84375 C 35.941406 6.246094 36 6.859375 36 7.65625 L 36 11 L 38 11 L 38 7.65625 C 38 6.789063 37.945313 6.054688 37.78125 5.375 C 37.617188 4.695313 37.296875 4.027344 36.71875 3.59375 C 36.140625 3.160156 35.40625 3.019531 34.625 3.03125 Z M 10 15 L 40 15 C 41.949219 15 44 16.9375 44 18.84375 L 44 40.375 C 44 42.382813 42.382813 44 40.375 44 L 9.625 44 C 7.613281 44 6 42.386719 6 40.375 L 6 20.46875 L 6.03125 18.8125 C 6.089844 18.132813 6.4375 17.164063 7.09375 16.40625 C 7.808594 15.578125 8.785156 15 10 15 Z M 38.5 27 C 37.117188 27 36 28.117188 36 29.5 C 36 30.882813 37.117188 32 38.5 32 C 39.878906 32 41 30.882813 41 29.5 C 41 28.117188 39.878906 27 38.5 27 Z" />
            </DefaultIcon>
        </>
    );
};
