import { EnumTpAmb } from "model/enums/enum-tpamb";
import { EnumTpHistoricoVenda } from "model/enums/enum-tphistorico-venda";

export class TemaPutModel {
    constructor(
        public empresaId: string = '',
        public type: number = 0,
        public primaryMain: string = '',
        public primaryLight: string = '',
        public primaryDark: string = '',
        public primaryContrast: string = '',
        public secondaryMain: string = '',
        public secondaryLight: string = '',
        public secondaryDark: string = '',
        public secondaryContrast: string = '',
        public textPrimary: string = '',
        public textSecondary: string = '',
        public informacaoAdicional: string = '',
        public senhaAcesso: string = '',
        public autoAtendimento: boolean = false,
        public statusLoja: string = '',
        public permiteEntrega: boolean = false,
        public distanciaMaxima: number = 0,
        public permiteRetirada: boolean = false,
        public tipoUsoCardapio: number = 0,
        public serie: number = 0,
        public ambiente: EnumTpAmb = EnumTpAmb.Homologacao,
        public tipoVenda: EnumTpHistoricoVenda = EnumTpHistoricoVenda.VendaSimples,
        public disponibilizarPagamentoFinalizacao: boolean = false,
        public endereco: string = '',
    ) { }
}