import { InfIntermediadorModel } from "model/api/gestao/venda/venda-completa-model";
import { EnumMovModelo } from "model/enums";
import { EnumFinNFe } from "model/enums/enum-fin-nfe";
import { EnumIdDest } from "model/enums/enum-id-dest";
import { EnumIndFinal } from "model/enums/enum-ind-final";
import { EnumIndIntermed } from "model/enums/enum-ind-intermed";
import { EnumIndPres } from "model/enums/enum-ind-pres";
import { EnumOrigemEmissao } from "model/enums/enum-origem-emissao";
import { EnumTpEmis } from "model/enums/enum-tp-emis";
import { EnumTpImp } from "model/enums/enum-tp-imp";
import { EnumTpNf } from "model/enums/enum-tp-nf";
import { EnumTpAmb } from "model/enums/enum-tpamb";

export class DadosEntradaFormModel {
  constructor(
    public nnf: string = '',
    public tpAmb: EnumTpAmb = EnumTpAmb.Producao,
    public serie: number | string = 0,
    public dhEmi: string = '',
    public dhSaiEnt: string = '',
    public tpNF: EnumTpNf = EnumTpNf.ENTRADA,
    public tpEmis: EnumTpEmis = EnumTpEmis.NORMAL,
    public indIntermed: EnumIndIntermed = EnumIndIntermed.OP_SEM_INTERMEDIADOR,
    public infIntermediador: InfIntermediadorModel = new InfIntermediadorModel(),
    public finNFe: EnumFinNFe = EnumFinNFe.NORMAL,
    public indFinal: EnumIndFinal = EnumIndFinal.NORMAL,
    public indPres: EnumIndPres = EnumIndPres.NAO_SE_APLICA,
    public cnf: string = '',
    public cuf: string = '',
    public idDest: EnumIdDest = EnumIdDest.INTERNA,
    public tpImp: EnumTpImp = EnumTpImp.SEM_GERACAO_DANFE,
    public cMunFG: number = 0,
    public mod: EnumMovModelo = EnumMovModelo.NFE,
    public cdv: number = 0,
    public versao: number = 4.00,
    public natOp: string = '',
    public idNFe: string = '',
    public schemaValidador: string = '4.00',
    public origemEmissao: EnumOrigemEmissao = EnumOrigemEmissao.TERCEIROS,
    public depositoId: string | null = null,
  ) { }
}
