import { forwardRef, useImperativeHandle } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { PontosVendaCadastroFormModel } from 'model/app/forms/pontos-venda/pontos-venda-cadastro-form-model';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpCaixaMock } from 'data/mocks/tp-caixa-mock';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormPontosVendaValidation } from './form-pontos-venda-cadastro-validation';
import { useSessaoAtual } from 'services/app';
import { KeyValueModel } from 'model';
import { isPlanoIntegradoSaurusPDV } from 'utils/plano-utils';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';

export const FormPontosVendaCadastro = forwardRef<
  DefaultFormRefs<PontosVendaCadastroFormModel>,
  DefaultFormProps<PontosVendaCadastroFormModel>
>((props: DefaultFormProps<PontosVendaCadastroFormModel>, ref) => {

  const utilClasses = makeUtilClasses();
  const refInputCnpjCpf = useRef<HTMLInputElement>(null);
  const { isMobile } = useThemeQueries();
  const { FormPontosVendaValidationYup } = useFormPontosVendaValidation();
  const { plano } = useSessaoAtual()
  const isIntegracaoPDV = isPlanoIntegradoSaurusPDV(plano?.plano);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<PontosVendaCadastroFormModel>({
    resolver: yupResolver(FormPontosVendaValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: PontosVendaCadastroFormModel) => {
    props.onSubmit(values);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset();
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
    fillForm: () => {
      if (!isMobile) refInputCnpjCpf.current?.focus();
    },
  }));

  const planoFilter = (item: KeyValueModel) => {
    if (!isIntegracaoPDV) {
      if (item.Key === EnumPDVTpCaixa.SAURUSPDV) {
        return false
      }
    }
    return true
  }

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="numCaixa"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'NUMERO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Número do Caixa"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: 1"
                      error={Boolean(
                        errors.numCaixa && errors.numCaixa.message,
                      )}
                      helperText={
                        touchedFields.numCaixa || errors.numCaixa
                          ? errors.numCaixa?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="chave"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'TEXTO'}
                      disabled={props.loading}
                      fullWidth
                      variant="outlined"
                      label="Chave Única"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(errors.chave && errors.chave.message)}
                      helperText={
                        touchedFields.chave || errors.chave
                          ? errors.chave?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Controller
                  name="tpCaixa"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Tipo de caixa"
                      variant="outlined"
                      fullWidth
                      conteudo={TpCaixaMock.filter(planoFilter)}
                      error={Boolean(errors.tpCaixa && errors.tpCaixa.message)}
                      helperText={
                        touchedFields.tpCaixa || errors.tpCaixa
                          ? errors.tpCaixa?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => {
                        const item = TpCaixaMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('tpCaixa', item);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
