import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useFormProdutoCompletoPreCadastroValidation } from './form-produto-completo-pre-cadastro-validations';
import { makeUtilClasses } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import {
  TextFieldSaurus,
  ImagemInput,
} from 'views/components/controles/inputs';
import { AutocompleteMedidas } from '../../../../controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { guidEmpty } from '../../../../../../utils/guid-empty';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { usePostProdutoMedida } from 'data/api/gestao/produto/produto-medida/post-produto-medida';
import { useGestaoToken, useToastSaurus } from 'services/app';
import { retornoAutoComplete } from '../../../../controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { ProdutoCompletoPreCadastroFormModel } from '../../../../../../model/app/forms/produto/produto-pre-cadastro/produto-completo-pre-cadastro-form-model';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { usePostProdutoCategoria } from 'data/api/gestao/produto/produto-categoria/post-produto-categoria';
import { AutocompleteProdutoCategorias } from '../../../../controles/autocompletes/autocomplete-produto-categorias/autocomplete-produto-categorias';
import { toDecimalString, toDecimal } from 'utils/to-decimal';
import { AutocompleteNcmsGestao } from '../../../../controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { useConfirm } from 'material-ui-confirm';
import { AutocompleteGrupoImpostos } from '../../../../controles/autocompletes/autocomplete-grupo-impostos/autocomplete-grupo-impostos';
import { ProdutoFiscalModel } from 'model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { useThemeQueries } from '../../../../../theme/util-styles';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { BalancaMock } from 'data/mocks/balanca-mock';
import { isPlanoControleProducao, isPlanoFarmaceutico, isPlanoFiscal } from 'utils/plano-utils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutocompleteProdutoSetor } from 'views/components/controles/autocompletes/autocomplete-setores/autocomplete-setores';
import { SetoresModel } from 'model/api/gestao/setores/setores-model';
import { useSessaoAtual } from 'services/app';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { produtoPageNome } from 'views/pages/private/cadastros/produto/produto-page';
import { FormProdutoCompletoPreCadastroProps } from './form-produto-completo-pre-cadastro-props';
import { AutocompleteMarcas } from 'views/components/controles/autocompletes/autocomplete-marcas/autocomplete-marcas';
import { MarcaModel } from 'model/api/gestao/marca/marca-model';
import { useMarca } from 'services/app/hooks/marca';

export const FormProdutoCompletoPreCadastro = forwardRef<
  DefaultFormRefs<ProdutoCompletoPreCadastroFormModel>,
  FormProdutoCompletoPreCadastroProps
>(
  (
    {
      loading: loadingExterno,
      desabilitarCampos,
      setCarregandoExterno,
      ...props
    }: FormProdutoCompletoPreCadastroProps,
    ref,
  ) => {
    const utilClasses = makeUtilClasses();
    const { isMobile } = useThemeQueries();
    const refInputValorVenda = useRef<HTMLInputElement>(null);
    const refInputCodigo = useRef<HTMLInputElement>(null);
    const [initialValues, setInitialValues] =
      useState<ProdutoCompletoPreCadastroFormModel>(
        new ProdutoCompletoPreCadastroFormModel(),
      );
    const { showToast } = useToastSaurus();
    const confirm = useConfirm();
    const { postProdutoMedida } = usePostProdutoMedida();
    const { postProdutoCategoria } = usePostProdutoCategoria();
    const { FormProdutoCompletoPreCadastroYupValidation } =
      useFormProdutoCompletoPreCadastroValidation();
    const { plano } = useSessaoAtual();
    const { getEmpresaAtual } = useGestaoToken();

    const isFiscal = isPlanoFiscal(plano?.plano);
    const planoControleProducao = isPlanoControleProducao(plano?.plano);
    const planoFarmaceutico = isPlanoFarmaceutico(plano?.plano);
    const empresaFiscal = getEmpresaAtual()?.isFiscal;
    const [loadingInterno, setLoadingInterno] = useState(true)

    const { addNovaMarca, carregando } = useMarca();

    const showConfirm = useCallback(
      (campo: string) => {
        return confirm({
          title: `Campo "${campo}" sem valor!`,
          description: `Deseja manter o campo "${campo}" sem valor?`,
          cancellationText: 'Não',
          confirmationText: 'Sim',
        });
      },
      [confirm],
    );

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      getValues,
      setValue,
      reset,
      setError
    } = useForm<ProdutoCompletoPreCadastroFormModel>({
      defaultValues: { ...initialValues },
      resolver: yupResolver(FormProdutoCompletoPreCadastroYupValidation),
      criteriaMode: 'all',
      mode: 'onChange',
    });

    const onSubmit = async (values: ProdutoCompletoPreCadastroFormModel) => {

      const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/

      if (!planoFarmaceutico) {
        if (regexCaracEspeciais.test(values.infAdic)) {
          setError('infAdic', { type: "error", message: 'Não pode conter caracteres especiais.' })
          return
        }

        if (regexCaracEspeciais.test(values.nome)) {
          setError('nome', { type: "error", message: 'Não pode conter caracteres especiais.' })
          return
        }
      }

      if (regexCaracEspeciais.test(values.codigo)) {
        setError('codigo', { type: "error", message: 'Não pode conter caracteres especiais.' })
        return
      }


      const model = picker<ProdutoCompletoPreCadastroFormModel>(
        values,
        new ProdutoCompletoPreCadastroFormModel(),
      );

      if (toDecimal(model.vPreco) === 0 && model.balanca !== 0) {
        if (!isMobile) refInputValorVenda.current?.focus();
        showToast(
          'error',
          `${(model.tipo === EnumTipoProduto.Produto) ? 'Produtos' : 'Insumos'} pesáveis devem possuir um valor de venda maior que R$ 0.`,
        );
        return;
      }

      if (model.vPreco !== initialValues.vPreco) {
        let confirmVPreco = true;

        if (model.vPreco.length === 0 || isNaN(toDecimal(model.vPreco))) {
          model.vPreco = '0';
        }
        if (model.vCompra.length === 0 || isNaN(toDecimal(model.vCompra))) {
          model.vCompra = '0';
        }

        if (toDecimal(model.vPreco) === 0) {
          await showConfirm('Valor Venda').catch(() => {
            confirmVPreco = false;
            if (!isMobile) refInputValorVenda.current?.focus();
            return;
          });
          if (!confirmVPreco) {
            return;
          }
        }
      }
      props.onSubmit(model);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit)();
      },
      resetForm: () => {
        setInitialValues(new ProdutoCompletoPreCadastroFormModel());
        if (!isMobile) refInputCodigo.current?.focus();
        reset();
      },
      fillForm: (model: ProdutoCompletoPreCadastroFormModel) => {
        model.vCompra = toDecimalString(model.vCompra);
        model.vPreco = toDecimalString(model.vPreco);
        model.pLucro = calcularValorReal(
          toDecimal(model.vPreco),
          toDecimal(model.vCompra),
        );

        setInitialValues(model);
        reset({ ...model });
        setLoadingInterno(false)
        setTimeout(() => {
          if (!isMobile) refInputCodigo.current?.focus();
        }, 500);
      },
    }));

    const addNovaMedida = useCallback(
      async (value: string) => {
        setCarregandoExterno(true);

        const novaMedida = new ProdutoMedidaModel(
          guidEmpty(),
          props.contratoId,
          props.empresaId,
          value,
          value,
        );
        const ret = await postProdutoMedida(novaMedida);
        if (ret.erro) {
          throw ret.erro;
        }
        const medidaRet = ret.resultado?.data as ProdutoMedidaModel;
        setValue('medida', medidaRet.sigla);
        setValue('medidaId', medidaRet.id);
        setCarregandoExterno(false);
      },
      [
        setCarregandoExterno,
        props.contratoId,
        props.empresaId,
        postProdutoMedida,
        setValue,
      ],
    );

    const onChangeMedidaWrapper = useCallback(
      async (retorno: retornoAutoComplete) => {
        if (retorno.isNewVal) {
          const ultimaMedida = getValues('medida');
          try {
            setValue('medida', retorno.value);
            addNovaMedida(retorno.value);
          } catch (e: any) {
            setValue('medida', ultimaMedida);
            setCarregandoExterno(false);
            showToast(
              'error',
              'Erro ao cadastrar a medida. Tente novamente em alguns instantes. Detalhe: ' +
              e.message,
            );
          }
        } else if (!retorno.isString) {
          const medida = picker<ProdutoMedidaModel>(
            retorno.value,
            new ProdutoMedidaModel(),
          );

          setValue('medida', medida.sigla);
          setValue('medidaId', medida.id);
        }
      },
      [addNovaMedida, getValues, setCarregandoExterno, setValue, showToast],
    );

    const addNovaCategoria = useCallback(
      async (categoria: ProdutoCategoriaModel) => {
        setCarregandoExterno(true);
        const ret = await postProdutoCategoria(categoria);
        if (ret.erro) {
          throw ret.erro;
        }
        const categoriaRet = ret.resultado?.data as ProdutoCategoriaModel;
        setValue('categoriaId', categoriaRet.id);
        setValue('nomeCategoria', categoriaRet.nome);
      },
      [setValue, postProdutoCategoria, setCarregandoExterno],
    );

    const onChangeCategoria = useCallback(
      async (retorno: retornoAutoComplete | null) => {
        if (!retorno) return
        if (retorno.isNewVal) {
          const ultimacategoria = getValues('nomeCategoria');
          try {
            setValue('nomeCategoria', retorno.value);
            let novaCategoria = new ProdutoCategoriaModel();
            novaCategoria.nome = retorno.value;
            novaCategoria.descricao = retorno.value;
            await addNovaCategoria(novaCategoria);
          } catch (e: any) {
            setValue('nomeCategoria', ultimacategoria);
            showToast(
              'error',
              'Erro ao cadastrar a categoria. Detalhe: ' +
              e.message,
            );
          } finally {
            setCarregandoExterno(false);
          }
        } else if (!retorno.isString) {
          let categoria = picker<ProdutoCategoriaModel>(
            retorno.value,
            new ProdutoCategoriaModel(),
          );
          setValue('categoriaId', categoria.id);
          setValue('nomeCategoria', categoria.nome);
        }
      },
      [addNovaCategoria, getValues, setCarregandoExterno, setValue, showToast],
    );

    const onChangeSetor = useCallback(
      async (retorno: retornoAutoComplete) => {
        let setor = picker<SetoresModel>(retorno.value, new SetoresModel());
        setValue('setorId', setor.id === '1' || setor.id === guidEmpty() ? null : setor.id);
        setValue('nomeSetor', setor.id === '1' || setor.id === guidEmpty() ? 'Sem Setor' : setor.descricao);
      },
      [setValue],
    );

    const calcularValorReal = useCallback((vPreco: number, vCompra: number) => {
      const pLucro = ((vPreco - vCompra) / vCompra) * 100;

      return toDecimalString(isNaN(pLucro) ? 0 : pLucro);
    }, []);

    const calcularValorFinal = useCallback(
      (vCompra: number, pLucro: number) => {
        return toDecimalString((vCompra * pLucro) / 100 + vCompra);
      },
      [],
    );

    const calcularValores = useCallback(
      (event: any) => {
        setValue(event.target.name, event.target.value);
        const vCompra = toDecimal(
          event.target.name === 'vCompra'
            ? event.target.value
            : getValues('vCompra'),
        );
        const vPreco = toDecimal(
          event.target.name === 'vPreco'
            ? event.target.value
            : getValues('vPreco'),
        );
        const pLucro = toDecimal(
          event.target.name === 'pLucro'
            ? event.target.value
            : getValues('pLucro'),
        );

        if (event.target.name === 'pLucro') {
          setValue('vPreco', calcularValorFinal(vCompra, pLucro));
        } else {
          setValue('pLucro', calcularValorReal(vPreco, vCompra));
        }
      },
      [calcularValorFinal, calcularValorReal, getValues, setValue],
    );

    const cadastrarNovoLaboratorio = async (val: string) => {
      const ret = await addNovaMarca(new MarcaModel(guidEmpty(), '', val));
      if (ret) {
        setValue('marcaId', ret.id)
      }
    }

    const loading = [loadingExterno, loadingInterno].includes(true)

    return (
      <>
        <div className={utilClasses.formContainer}>
          {(loading && props.showLoading) || carregando ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo='FULLSIZED' />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item container justifyContent="center" xs={12}>
                <Box my={props.imageSize === 'small' ? 2 : 5}>
                  <Controller
                    name="imagemUrl"
                    control={control}
                    render={({ field }) => (
                      <ImagemInput
                        disabled={loading || desabilitarCampos}
                        loadErrorImage={SemImagem}
                        imageRadius="5%"
                        width={isMobile ? '150px' : (props.imageSize === 'small' ? '150px' : '220px')}
                        height={isMobile ? '150px' : (props.imageSize === 'small' ? '150px' : '220px')}
                        accept="image/*"
                        error={Boolean(
                          errors.imagemUrl && errors.imagemUrl.message,
                        )}
                        helperText={
                          errors.imagemUrl
                            ? errors.imagemUrl?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('imagemUrl')}
                        onChange={({ base64 }: any) => {
                          setValue('imagemUrl', base64);
                        }}
                        onBlur={() => {
                          setValue('imagemUrl', '');
                        }}
                        semImagemRepresentante
                        mode='contain'
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      variant="outlined"
                      label={`Nome do ${produtoPageNome(props.tipo, false)}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={`Informe o nome do ${produtoPageNome(props.tipo, false).toLowerCase()}`}
                      error={Boolean(errors.nome && errors.nome.message)}
                      helperText={
                        errors.nome
                          ? errors.nome?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="infAdic"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      id="outlined-textarea"
                      allowSubmit
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      variant="outlined"
                      label={`Descrição do ${produtoPageNome(props.tipo, false).toLowerCase()}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder='Limite de 400 caracteres'
                      error={Boolean(errors.infAdic && errors.infAdic.message)}
                      helperText={
                        errors.infAdic
                          ? errors.infAdic?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="marcaId"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteMarcas
                      disabled={loading}
                      label='Marca'
                      permiteAdicionar
                      error={Boolean(errors.marcaId && errors.marcaId.message)}
                      helperText={
                        errors.marcaId
                          ? errors.marcaId?.message
                          : undefined
                      }
                      {...field}
                      value={field.value || undefined}
                      onChange={(e) => {
                        if (e.isNewVal) {
                          if (e.isString) {
                            cadastrarNovoLaboratorio(e.value)
                          }
                          return
                        }
                        setValue('marcaId', e.value.id);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="nomeCategoria"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteProdutoCategorias
                      disabled={loading || desabilitarCampos}
                      modo="categoria"
                      placeholder="Ex: Eletrônicos"
                      loadingExterno={loading}
                      label="Categoria"
                      error={Boolean(
                        errors.nomeCategoria && errors.nomeCategoria.message,
                      )}
                      helperText={
                        errors.nomeCategoria
                          ? errors.nomeCategoria?.message
                          : undefined
                      }
                      {...field}
                      onChange={onChangeCategoria}
                      permiteAdicionar
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      variant="outlined"
                      label="Código"
                      inputRef={refInputCodigo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Ex: 123"
                      error={Boolean(errors.codigo && errors.codigo.message)}
                      helperText={
                        touchedFields.codigo || errors.codigo
                          ? errors.codigo?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              {(isFiscal && empresaFiscal) && <>
                <Grid item xs={6}>
                  <Controller
                    name="codigoNcm"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteNcmsGestao
                        disabled={loading || desabilitarCampos}
                        allowSubmit
                        modo="campoFormatado"
                        loadingExterno={loading}
                        label="NCM do Produto"
                        placeholder="Ex: 84109000"
                        error={Boolean(
                          errors.codigoNcm && errors.codigoNcm.message,
                        )}
                        helperText={
                          errors.codigoNcm
                            ? errors.codigoNcm?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) => {
                          if (!retorno.isString) {
                            const ncm = picker<NcmModel>(
                              retorno.value,
                              new NcmModel(),
                            );
                            setValue('codigoNcm', ncm.codigo);
                          }
                        }}
                        value={getValues('codigoNcm')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="imposto"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteGrupoImpostos
                        allowSubmit
                        loadingExterno={loading}
                        label="Grupo de Imposto"
                        placeholder="-Selecione-"
                        error={Boolean(
                          errors.imposto && errors.imposto.message,
                        )}
                        helperText={
                          errors.imposto
                            ? errors.imposto?.message
                            : undefined
                        }
                        {...field}
                        onChange={(retorno) => {
                          if (!retorno.isString) {
                            const imposto = picker<ProdutoFiscalModel>(
                              retorno.value,
                              new ProdutoFiscalModel(),
                            );
                            setValue('imposto', imposto.descricao);
                            setValue('impostoId', imposto.id);
                          }
                        }}
                        value={getValues('imposto')}
                      />
                    )}
                  />
                </Grid>
              </>}
              <Grid item xs={planoFarmaceutico ? 12 : 6}>
                <Controller
                  name="medida"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteMedidas
                      disabled={loading || desabilitarCampos}
                      loadingExterno={loading}
                      label='Medida de Venda'
                      error={Boolean(errors.medida && errors.medida.message)}
                      helperText={
                        errors.medida
                          ? errors.medida?.message
                          : undefined
                      }
                      {...field}
                      onChange={onChangeMedidaWrapper}
                      permiteAdicionar
                    />
                  )}
                />
              </Grid>
              {!planoFarmaceutico && <Grid item xs={6}>
                <Controller
                  name="balanca"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Balança"
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={BalancaMock}
                      {...field}
                      onChange={(event) => {
                        const item = BalancaMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('balanca', item);
                        }
                      }}
                      value={getValues('balanca')}
                    />
                  )}
                />
              </Grid>}
              <Grid item xs={4}>
                <Controller
                  name="vCompra"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      manterMascara
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      variant="outlined"
                      label="Valor Compra"
                      showStartAdornment
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 12 }}
                      placeholder=""
                      error={Boolean(errors.vCompra && errors.vCompra.message)}
                      helperText={
                        touchedFields.vCompra || errors.vCompra
                          ? errors.vCompra?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => calcularValores(event)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name="pLucro"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="DECIMAL"
                      manterMascara
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      variant="outlined"
                      label="Margem de Lucro (%)"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                      error={Boolean(errors.pLucro && errors.pLucro.message)}
                      helperText={
                        touchedFields.pLucro || errors.pLucro
                          ? errors.pLucro?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => calcularValores(event)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="vPreco"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      inputRef={refInputValorVenda}
                      tipo="DECIMAL"
                      manterMascara
                      disabled={loading || desabilitarCampos}
                      fullWidth
                      showStartAdornment
                      variant="outlined"
                      label="Valor Venda"
                      inputProps={{ maxLength: 12 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder=""
                      error={Boolean(errors.vPreco && errors.vPreco.message)}
                      helperText={
                        errors.vPreco
                          ? errors.vPreco?.message
                          : undefined
                      }
                      {...field}
                      onChange={(event) => calcularValores(event)}
                    />
                  )}
                />
              </Grid>
              {planoControleProducao && !planoFarmaceutico && (
                <Grid item xs={12}>
                  <Controller
                    name="nomeSetor"
                    control={control}
                    render={({ field }) => (
                      <AutocompleteProdutoSetor
                        disabled={loading || desabilitarCampos}
                        modo="setor"
                        placeholder="Ex: Bar"
                        loadingExterno={loading}
                        label="Setor"
                        {...field}
                        idSetor={getValues('setorId')}
                        onChange={onChangeSetor}
                        value={getValues('nomeSetor')}
                      />
                    )}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12}>
                <Controller
                  name="tipo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      label="Produto ou Insumo"
                      variant="outlined"
                      fullWidth
                      disabled={loading}
                      conteudo={TpProdutoMock}
                      {...field}
                      onChange={(event) => {
                        const item = TpProdutoMock.filter(
                          (item) => item.Key === event.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          setValue('tipo', item);
                        }
                      }}
                      value={getValues('tipo')}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </>
    );
  },
);
