import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: (props: any) => {
    const { isMobile } = props;

    return {
      ...(isMobile ? { height: 60 } : null),
      padding: 5,
      width: '100%',
      borderBottom: '1px solid rgba(230, 230, 230, 1)',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
        marginBottom: 8,
        borderBottom: 'none',
      }
    };
  },
  btnContent: (props: any) => {
    const { location } = props;

    return {
      width: '100%',
      minWidth: 70,
      backgroundColor: location ? `${theme.palette.primary.main}30` : undefined,
      '&:hover': {
        backgroundColor: location
          ? `${theme.palette.primary.main}30`
          : undefined
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 100
      }
    };
  },
  btnLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: (props: any) => {
    const { isMobile } = props;

    return {
      fill: theme.palette.primary.main,
      ...(isMobile ? { width: 22 } : { width: 25 }),
      ...(isMobile ? { height: 22 } : { height: 25 }),
      marginBottom: isMobile ? 2 : theme.spacing(1)
    };
  },
  textPrimary: (props: any) => {
    const { isMobile } = props;

    return {
      ...(isMobile ? { fontSize: 10 } : { fontSize: 12 }),
      fontWeight: 600
    };
  },
  btnText: {
    padding: theme.spacing(1)
  },
  btnRoot: {
    height: 'inherit'
  }
}));
