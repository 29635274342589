import {
  useCallback
} from 'react';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';

export interface KeyboardProviderProps {
  isNumeric: boolean;
  floatCases: number;
  maxLength: number;
  handleTextChanged?: (
    value: string,
    formattedValue: string,
  ) => Promise<boolean>;
  handleAdd?: () => Promise<boolean>;
  handleSubmit?: () => Promise<boolean>;
  digitado: React.MutableRefObject<boolean>;
  textoAtual: React.MutableRefObject<string>;
}
export const useKeyboard = ({
  isNumeric,
  floatCases,
  maxLength,
  handleTextChanged,
  handleAdd,
  handleSubmit,
  digitado,
  textoAtual
}: KeyboardProviderProps) => {

  const { callEvent } = useEventTools()

  const changeVersao = useCallback((date: Date) => {
    callEvent(AppEventEnum.AlterarVersao, date)
  }, [callEvent])

  const getFormattedTextInterno = useCallback(
    (text: string): string => {
      
      if (!isNumeric) return text;
      if (isNaN(Number(text))) return Number(0).toFixed(floatCases);
      return toDecimalString(
        (Number(text) / Math.pow(10, floatCases)).toFixed(floatCases),
        floatCases,
      );
    },
    [floatCases, isNumeric],
  );

  const getFormattedText = useCallback(() => {
    return getFormattedTextInterno(textoAtual.current);
  }, [getFormattedTextInterno, textoAtual]);

  const tryChangeText = useCallback(
    async (text: string, digitou: boolean) => {
      if (text.length > maxLength) return;

      try {
        if (
          toDecimal(getFormattedTextInterno(textoAtual.current), 2) === 0 &&
          toDecimal(text, 2) === 0 &&
          isNumeric
        )
          return;
      } catch { }

      if (handleTextChanged) {
        if (await handleTextChanged(text, getFormattedTextInterno(text))) {
          textoAtual.current = text;
          digitado.current = digitou;
          changeVersao(new Date());
        }
      } else {
        textoAtual.current = text;
        digitado.current = digitou;
        changeVersao(new Date());
      }
    },
    [changeVersao, digitado, getFormattedTextInterno, handleTextChanged, isNumeric, maxLength, textoAtual],
  );

  const sendText = useCallback(
    async (text: string) => {
      await tryChangeText(
        (!digitado.current ? '' : textoAtual.current) + text,
        true,
      );
    },
    [digitado, textoAtual, tryChangeText],
  );

  const resetText = useCallback(
    async (text: string, validarDecimal: boolean = true) => {
      let newvalue = text;
      if (isNumeric) {
        newvalue = (validarDecimal ? (toDecimal(newvalue, floatCases) / Math.pow(10, floatCases)) : (toDecimal(newvalue, floatCases)))
          .toFixed(floatCases)
          .replaceAll(',', '')
          .replaceAll('.', '');
      }
      await tryChangeText(newvalue, false);
    },
    [floatCases, isNumeric, tryChangeText],
  );

  const addValue = useCallback(
    async (value: number) => {
      if (isNaN(Number(textoAtual.current))) return;

      const newvalue = (Number(textoAtual.current) + value).toFixed(0);
      await tryChangeText(newvalue, false);
    },
    [textoAtual, tryChangeText]);

  const addMultiply = useCallback(
    async (value: number) => {
      let newvalue = value + '*' + textoAtual.current;
      if (textoAtual.current.indexOf('*') > -1) {
        if (textoAtual.current.replace('*', '').length === 0) {
          newvalue = textoAtual.current.replace('*', '');
        } else {
          const novaqtde = parseFloat(textoAtual.current.replace('*', ''));

          newvalue =
            (Number(novaqtde) + Number(value)).toString().replace('.', ',') +
            '*' +
            textoAtual.current.substring(textoAtual.current.indexOf('*') + 1);
        }
      }
      await tryChangeText(newvalue, true);
    }, [textoAtual, tryChangeText]
  );

  const backSpaceKey = useCallback(async () => {
    if (textoAtual.current.length > 0) {
      await tryChangeText(
        textoAtual.current.substring(0, textoAtual.current.length - 1),
        true,
      );
    }
  }, [textoAtual, tryChangeText]);

  const submitKey = useCallback(async () => {
    if (handleSubmit) {
      if (await handleSubmit()) {
        digitado.current = false;
        textoAtual.current = '';
        changeVersao(new Date());
      }
    }
  }, [changeVersao, digitado, handleSubmit, textoAtual]);

  const addKey = useCallback(async () => {
    if (textoAtual.current.length === 0) return;

    if (handleAdd) {
      if (await handleAdd()) {
        digitado.current = false;
        textoAtual.current = '';
        changeVersao(new Date());
      }
    }
  }, [changeVersao, digitado, handleAdd, textoAtual]);

  return {
    text: textoAtual.current,
    getFormattedText,
    sendText,
    resetText,
    addKey,
    submitKey,
    backSpaceKey,
    addValue,
    addMultiply,
  };
};
