import { guidEmpty } from "utils/guid-empty";

export class RastroLoteModel {
    constructor(
        public medicamentoId: string = guidEmpty(),
        public medicamentoNome: string = '',
        public codigoAnvisa: string = '',
        public lotes: LoteModel[] = [],
        public quantidadeDisponivel: number = 0,
        public quantidadeTotal: number = 0,
        public quantidadeBloqueada: number = 0,
    ) { }
}

export class LoteModel {
    constructor(
        public lote: string = '',
        public quantidade: number = 0,
        public quantidadeBloqueadaCompra: number = 0,
        public quantidadeBloqueadaDispensacao: number = 0,
        public dataValidade: string | Date = '',
        public dataFabricacao: string | Date = '',
        public codigoAgregador: number = 0,
    ) { }
}