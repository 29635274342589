import { forwardRef, useCallback, useImperativeHandle, useState } from "react"
import { DefaultFormProps, DefaultFormRefs } from "../utils"
import { Button, FormControlLabel, Grid } from "@material-ui/core"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { Controller, useForm } from "react-hook-form"
import { SaurusRadio } from "views/components/controles/radio/saurus-radio"
import { EnumTpDescontoAcrescimo } from "model/enums/enum-tp-desconto-acrescimo"
import { useFormAdicionarDescontoAcrescimoValidation } from "./form-adicionar-desconto-acrescimo-validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { picker } from "utils/picker"
import { toDecimal } from "utils/to-decimal"
import { DescontoAcrescimoFormModel } from "model/app/forms/adicionar-desconto-acrescimo/desconto-acrescimo-form-model"

export const FormAdicionarDescontoAcrescimo = forwardRef<
    DefaultFormRefs<DescontoAcrescimoFormModel>,
    DefaultFormProps<DescontoAcrescimoFormModel>
>(({ loading, ...props }: DefaultFormProps<DescontoAcrescimoFormModel>, ref) => {

    //STATES
    const [valor, setValor] = useState<number>(0);
    const [modelForm, setModelForm] = useState<DescontoAcrescimoFormModel>(new DescontoAcrescimoFormModel());

    //VALIDATION FORM
    const { formAdicionarDescontoAcrescimoValidation } = useFormAdicionarDescontoAcrescimoValidation(valor);

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        reset,
        resetField,
    } = useForm<DescontoAcrescimoFormModel>({
        defaultValues: { ...modelForm },
        resolver: yupResolver(formAdicionarDescontoAcrescimoValidation),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = useCallback(async (values: DescontoAcrescimoFormModel) => {
        if (values.tpCampo === EnumTpDescontoAcrescimo.Porcentagem) {
            values.vPorcentagem = toDecimal(values.vPorcentagem);
        } else {
            values.vValorFixo = toDecimal(values.vValorFixo);
        }

        const modelo = picker<DescontoAcrescimoFormModel>(values, modelForm);
        props.onSubmit(modelo);
    }, [modelForm, props]);

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: (model: DescontoAcrescimoFormModel) => {
            setModelForm(model);
            reset({
                ...model,
            });
        },
    }));

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Porcentagem"
                            checked={valor === EnumTpDescontoAcrescimo.Porcentagem}
                            onClick={() => {
                                setValue('tpCampo', EnumTpDescontoAcrescimo.Porcentagem)
                                setValue('vValorFixo', '')
                                setTimeout(() => {
                                    resetField('vValorFixo')
                                }, 50)
                                setValor(EnumTpDescontoAcrescimo.Porcentagem)
                            }}
                            control={<SaurusRadio />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={'vPorcentagem'}
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    disabled={valor !== EnumTpDescontoAcrescimo.Porcentagem}
                                    placeholder="EX: 10"
                                    manterMascara
                                    tipo="DECIMAL"
                                    max={3}
                                    maxTexto={6}
                                    error={Boolean(
                                        errors.vPorcentagem && errors.vPorcentagem.message,
                                    )}
                                    helperText={
                                        touchedFields.vPorcentagem || errors.vPorcentagem
                                            ? errors.vPorcentagem?.message
                                            : undefined
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Valor Fixo"
                            checked={valor === EnumTpDescontoAcrescimo.ValorFixo}
                            onClick={() => {
                                setValue('tpCampo', EnumTpDescontoAcrescimo.ValorFixo)
                                setValue('vPorcentagem', '')
                                setTimeout(() => {
                                    resetField('vPorcentagem')
                                }, 50)
                                setValor(EnumTpDescontoAcrescimo.ValorFixo)
                            }}
                            control={<SaurusRadio />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name={'vValorFixo'}
                            control={control}
                            render={({ field }) => (
                                <TextFieldSaurus
                                    disabled={valor !== EnumTpDescontoAcrescimo.ValorFixo}
                                    placeholder="EX: 10,00"
                                    tipo="DECIMAL"
                                    manterMascara
                                    max={3}
                                    maxTexto={6}
                                    error={Boolean(
                                        errors.vValorFixo && errors.vValorFixo.message,
                                    )}
                                    helperText={
                                        touchedFields.vValorFixo || errors.vValorFixo
                                            ? errors.vValorFixo?.message
                                            : undefined
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Button style={{ display: 'none' }} type="submit"></Button>
            </form>
        </>
    )
})