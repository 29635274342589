export enum EnumPDVConfigCod {
    ModeloVenda = 50,
    TicketConsumacao = 18,
    SolicitarCliente = 20,
    NotaManual = 102,
    ModeloTrabalho = 101,
    ModeloEquipamento = 51,
    PortaComunicacao = 52,
    QuantidadeColunas = 57,
    ImpressaoComprovante = 59,
    MomentoSolicitacaoMesaComanda = 104,
    DispararSetorFinalizacaoVenda = 500,
    AplicarTaxaServico = 600,
}