import { VariaveisAmbiente } from "config";
import { PedidoProdutoModelPost } from "model/api/gestao/pedido/pedido-produto-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePostPedidoProduto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postPedidoProdutos = useCallback(
        (empresaId: string, pedidoId: string, produtos: PedidoProdutoModelPost[]) => {

            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/produto/varios`,
                method: "POST",
                enviarTokenUsuario: true,
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(produtos),
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        postPedidoProdutos,
    };
}
