import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: '4px',
    minWidth: '90px',
    minHeight: "100px",
    flex: 1,
    padding: '4px',
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    background: theme.palette.common.white,
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",

  },
  codigoMesa: {
    fontSize: '12px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
}));

