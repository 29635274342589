import { useStyles } from './loading-sincronizacao-geral-styles';

export const LoadingSicronizacaoGeral = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.cLoader} />
    </>
  );
};
