import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogAlterarMesa } from 'views/components/dialog/dialog-alterar-mesa/dialog-mesa';

class AlterarMesaProps {
  constructor(
    public aberto: boolean = false,
    public pedido: PedidoModel = new PedidoModel(),
    public carregarInfoPedido: () => Promise<void> = () => Promise.resolve()
  ) {}
}

export const DialogAlterarMesaPedido = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<AlterarMesaProps>(
    new AlterarMesaProps()
  );

  consoleDev('DialogAlterarMesaPedido');

  const modalAlterado = useCallback(
    ({ aberto, pedido, carregarInfoPedido}: any) => {
      setModalState({
        aberto,
        pedido,
        carregarInfoPedido
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogAlterarMesa, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogAlterarMesa, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogAlterarMesa
          aberto={modalState.aberto}
          carregarInfoPedido={modalState.carregarInfoPedido}
          pedido={modalState.pedido}
        />
      )}
    </>
  );
};
