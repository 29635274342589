import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    fontSize: '1.25rem',
    fontWeight: 500,
    padding: 0,
    color: '#666666'
  },
  alinharVertical: {
    display: 'flex',
    alignItems: 'center'
  },
  expandStyle: {
    height: 30,
    width: 30,
    marginRight: 0,
    transform: 'rotate(-90deg)'
  }
}));
