
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { ComandasModel } from "model/api/gestao/comanda/comanda-model";
import { VariaveisAmbiente } from 'config';

export function usePutComanda() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const putComanda = useCallback(
		(comanda: ComandasModel, empresaId: string) =>
			invocarApi({
				url: `/v2/empresa/${empresaId}/comanda/${comanda.id}`,
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify(comanda),
				enviarTokenUsuario: true,
				baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
			}),
		[invocarApi],
	);
	return {
		...outrasPropriedades,
		putComanda,
	};
}
