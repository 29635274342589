import { FocusEvent } from "react";
import { CfopMock } from '../../../../../data/mocks/cst-cfop-mock';

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from "../autocomplete-saurus/autocomplete-saurus";

export interface AutocompleteCFOPsProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  allowSubmit?: boolean;
  disabled?: boolean;
}

export const AutocompleteCFOPs = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteCFOPsProps) => {

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      onChange={onChange}
      opcoes={CfopMock}
      optionLabelKey="Value"
      optionValueKey="Key"
      disabledValueKey="Key"
      disabledValues={[]}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
