import React from 'react';
import {
  Stepper as MaterialStepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles
} from '@material-ui/core';
import { StepperProps } from './stepper-props';
import { StepIcone } from './componentes';
import { useStepperStyles } from './stepper-styles';

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      background: theme.palette.primary.light
    }
  },
  completed: {
    '& $line': {
      background: theme.palette.primary.main
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}))(StepConnector);

export function Stepper({ steps, stepAtual }: Readonly<StepperProps>) {
  const classes = useStepperStyles();

  return (
    <MaterialStepper
      alternativeLabel
      activeStep={stepAtual ?? 0}
      className={classes.stepper}
      connector={<Connector />}
    >
      {steps.map((step, index) => {
        const key = `step-${step.stepLabel}-${index}`;
        return (
          <Step key={key}>
            <StepLabel
              StepIconComponent={(props) =>
                StepIcone({ ...props, icon: step.stepIcon })
              }
            >
              {step.stepLabel}
            </StepLabel>
          </Step>
        );
      })}
    </MaterialStepper>
  );
}
