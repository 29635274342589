import { Grid } from "@material-ui/core";
import { SessaoGerenciamentoModel, SessaoValoresModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { useState } from "react";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { CardEdicaoGerenciamentoSessaoValor } from "views/components/cards/card-edicao-gerenciamento-sessao-valor/card-edicao-gerenciamento-sessao-valor";
import { useThemeQueries } from "views/theme";

export interface AccordionEdicaoGerenciamentoSessaoValorMobileProps {
    sessao: SessaoGerenciamentoModel;
    sessaoValores: SessaoValoresModel[];
    buttonOrForm: JSX.Element;
    getSessaoValorWrapper: () => Promise<void>
}

export const AccordionTipoLancamento = ({
    sessao,
    sessaoValores,
    buttonOrForm,
    getSessaoValorWrapper,
}: AccordionEdicaoGerenciamentoSessaoValorMobileProps) => {

    const { theme } = useThemeQueries();

    const [openAccordion, setOpenAccordion] = useState<boolean>(false);
    return (
        <AccordionSaurus
            labelPrimary={'Correções da Sessão'}
            tipoExpand="bold"
            noPaperRoot={false}
            heightDivider={2}
            showDivider={openAccordion}
            colorDivider={theme.palette.primary.main}
            colorExpand={theme.palette.primary.main}
            expanded={openAccordion}
            onChange={() => setOpenAccordion(!openAccordion)}
        >
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {sessaoValores.map((item) => {
                            return (
                                <CardEdicaoGerenciamentoSessaoValor
                                    model={item}
                                    sessao={sessao}
                                    getSessaoValorWrapper={getSessaoValorWrapper}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        {buttonOrForm}
                    </Grid>
                </Grid>
            </>
        </AccordionSaurus>
    );
}