import { Button, Grid, Typography } from '@material-ui/core';
import { useStyles } from './pendencias-page-styles';
import { PublicPageHeader } from 'views/components/headers';
import { AtencaoIcon } from 'views/components/icons/atencao-icon';
import { AutenticacaoPendenciaContratoModel } from 'model/api/gestao/autenticacao-pendencia/autenticacao-pendencia';
import { GestaoStorageKeys, useChatSuporteSaurus, useGestaoStorage } from 'services/app';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AvancarIcon, VoltarIcon } from 'views/components/icons';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { EnumTipoCobranca } from 'model/enums/enum-tipo-combranca';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { DialogSuporte } from 'views/components/dialog/dialog-suporte/dialog-suporte';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { UltimoLoginModel } from 'model/app/ultimo-login/ultimo-login-model';


const PendenciasPage = () => {

  const { getRegistro } = useGestaoStorage()
  const history = useHistory();
  const { abrirChat } = useChatSuporteSaurus()
  const pendencia: AutenticacaoPendenciaContratoModel = isEmpty(getRegistro(GestaoStorageKeys.PendenciaContrato)) ? new AutenticacaoPendenciaContratoModel() : getRegistro(GestaoStorageKeys.PendenciaContrato)
  const ultimoLogin = getRegistro(GestaoStorageKeys.UltimoLogin, false) as UltimoLoginModel



  useEffect(() => {
    isEmpty(getRegistro(GestaoStorageKeys.PendenciaContrato)) && history.push('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isNotMP =
    VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO && VariaveisAmbiente.paymentDevice !== EnumDeviceType.MERCADOPAGO_DEV

  // AUX
  const classes = useStyles();

  const [text, setText] = React.useState('');

  const { addHandler, removeHandler } = useEventTools()



  const [showDialogSuporte, setShowDialogSuporte] =
    React.useState<boolean>(false);
  const abrirFecharValor = useCallback((text: string) => {
    setText(text)
    setShowDialogSuporte(prev => !prev)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.SuporteChamada, abrirFecharValor)
    return () => removeHandler(AppEventEnum.SuporteChamada, abrirFecharValor)

  }, [abrirFecharValor, addHandler, removeHandler])



  return (
    <>
      <PublicPageHeader topOpacity="0.8" />
      <Grid className={classes.container}>
        <Grid className={classes.content}>
          <Grid style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center'
          }}>
            <Grid className={classes.infoContainer}>
              <Grid container justifyContent='center'>
                <AtencaoIcon tipo="GERAL" style={{ maxWidth: '280px' }} />
              </Grid>
              <Typography className={classes.textTitle}>
                {isNotMP && pendencia.tipoCobrancaRevenda !== EnumTipoCobranca.ClienteFinal &&
                  !pendencia.ativo ? (
                  'Conta Desativada'
                ) : (
                  'Acesso Bloqueado'
                )}
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography align='center'>
                  {isNotMP && pendencia.tipoCobrancaRevenda !== EnumTipoCobranca.ClienteFinal &&
                    !pendencia.ativo ? (
                    'Para ativar, clique no botão "Ativar" e segue a opção de ativar via suporte.'
                  ) : (
                    'Para desbloqueá-lo, entre em contato com seu Representante para Regularizar a Situação.'
                  )}
                </Typography>
                <Typography align="center">
                  {pendencia.status.mensagemUsuario && pendencia.status.mensagemUsuario.length > 0 && (
                    <><b>Aviso:</b> {pendencia.status.mensagemUsuario}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.containerButton}>
            <Grid container spacing={2}>
              {!isNotMP && (
                <Grid item xs={12} className={classes.cardMP}>
                  <Typography variant="body1" align='center' className={classes.textCardMP}>
                    Para visualizar suas pendências, entre no TouchOne em um dispositivo móvel ou em um computador.
                  </Typography>
                  <Typography variant="body1" align="center" className={classes.linkCardMP}>
                    {VariaveisAmbiente.frontUrl.replaceAll("https://", "").replaceAll("/", "")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={isNotMP && ((pendencia.tipoCobrancaRevenda === EnumTipoCobranca.ClienteFinal && pendencia.ativo) || (pendencia.tipoCobrancaRevenda !== EnumTipoCobranca.ClienteFinal && !pendencia.ativo)) ? 4 : 12}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size="large"
                  onClick={() => history.push('/')}
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              {isNotMP && pendencia.tipoCobrancaRevenda === EnumTipoCobranca.ClienteFinal &&
                pendencia.ativo && (
                  <Grid item xs={12} sm={8}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      size="large"
                      onClick={() => window.open(pendencia.LinkCobranca)}
                    >
                      <AvancarIcon tipo="BUTTON_PRIMARY" />
                      Visualizar Pendências
                    </Button>
                  </Grid>
                )}
              {isNotMP && pendencia.tipoCobrancaRevenda !== EnumTipoCobranca.ClienteFinal &&
                !pendencia.ativo && (
                  <Grid item xs={12} sm={8}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      size="large"
                      onClick={
                        () => {
                          abrirChat(ultimoLogin.email, formatarCPFCNPJ(pendencia.CpfCnpjCliente),
                            'Gostaria de reativar o contrato.')
                        }}
                    >
                      <AvancarIcon tipo="BUTTON_PRIMARY" />
                      Ativar
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showDialogSuporte &&
        <DialogSuporte
          openned={showDialogSuporte}
          loading={false}
          closeModal={() => setShowDialogSuporte(false)}
          text={text}

        />}
    </>
  );
};

export default PendenciasPage;
