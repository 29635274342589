import * as Yup from "yup";
import { useMemo } from "react";

export const useDialogProdutoBalancaValidation = () => {
  const DialogProdutoBalancaValidation = useMemo(() => {
    return Yup.object().shape({
      qCom: Yup.string().required('Informe a quantidade do produto'),
    });
  }, []);

  return {
    DialogProdutoBalancaValidation,
  };
};
