
import { guidEmpty } from 'utils/guid-empty';

export class ProdutoImagemModel {
    constructor(
      public id: string = guidEmpty(),
      public produtoId: string = guidEmpty(),
      public produtoGradeId: string = guidEmpty(),
      public imagemUrl: string = "",
      public padrao: boolean=true,
     

    ) {}
  }
