import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        background: '#FFF',
        flex: 1,
        flexDirection: 'column',
        padding: 0
    },
    containerAcoes: {
        padding: 0,
    },
    btnRemover: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '& svg': {
            fill: theme.palette.error.main
        },
        '&:hover': {
            borderColor: theme.palette.error.dark
        }
    }
}))