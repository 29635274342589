import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0 8px 8px',
        background: '#FDFDFD',
        boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.08)",
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        marginBottom: '8px',
        fontWeight: 600,
        fontSize: '26px'
    },
    button: {
        width: '100%',
        height: '100%',
    },
    divider: {
        backgroundColor: theme.palette.grey[300],
        height: '1px',
        marginTop: '8px',
        marginleft: '8px',
        marginRight: '8px'
    },
    more: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '4px',
        marginBottom: '4px',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '15px'
    }
}));
