import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        fontSize: '0.9rem',
        color: '#BCBCBC',
        lineHeight: '14,1px'
    },
    listContainer: {
        "& .card": {
            marginBottom: theme.spacing(1),
        }
    }
}));
