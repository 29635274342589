import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function usePostEnviarCupomVendaEmail() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const postEnviarCupomVendaEmail = useCallback(
        (destinatario: string, titulo: string, conteudo: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/envio/email`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    destinatario,
                    titulo,
                    conteudo
                }),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        postEnviarCupomVendaEmail,
    };
}
