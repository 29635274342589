import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { DialogAcoesPedido } from 'views/components/dialog/dialog-acoes-pedido/dialog-acoes-pedido';

class PedidoAcoesProps {
  constructor(
    public aberto: boolean = false,
    public pedido: PedidoModel = new PedidoModel(),
    public carregarInfoPedido: () => Promise<void> = () => Promise.resolve(),
    public titulo: string = ''
  ) { }
}

export const DialogPedidosAcoes = () => {
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState<PedidoAcoesProps>(
    new PedidoAcoesProps()
  );

  consoleDev('DialogPedidosAcoes');

  const modalAlterado = useCallback(
    ({ aberto, pedido, carregarInfoPedido, titulo }: any) => {
      setModalState({
        aberto,
        pedido,
        carregarInfoPedido,
        titulo
      });
    },
    []
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogPedidoAcoes, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.DialogPedidoAcoes, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      {modalState.aberto && (
        <DialogAcoesPedido
          aberto={modalState.aberto}
          pedido={modalState.pedido}
          carregarInfoPedido={modalState.carregarInfoPedido}
          titulo={modalState.titulo}
        />
      )}
    </>
  );
};
