import { Grid } from "@material-ui/core"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { FormPeFcpProps } from "./form-pe-fcp-props"
import { Controller } from "react-hook-form"
import { TextFieldSaurus } from "views/components/controles/inputs"

export const FormPeFcp = ({ control, formState, ...props }: FormPeFcpProps) => {

    const { errors } = formState
    return (
        <AccordionSaurus
            labelPrimary="FCP"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        name="icms.vBcFcp"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo FCP"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcFcp && errors.icms.vBcFcp.message)}
                                helperText={
                                    errors.icms?.vBcFcp
                                        ? errors.icms?.vBcFcp?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pFcp"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota FCP"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pFcp && errors.icms.pFcp.message)}
                                helperText={
                                    errors.icms?.pFcp
                                        ? errors.icms?.pFcp?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vFcp"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor FCP"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vFcp && errors.icms.vFcp.message)}
                                helperText={
                                    errors.icms?.vFcp
                                        ? errors.icms?.vFcp?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.vBcFcpSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo FCP ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcFcpSt && errors.icms.vBcFcpSt.message)}
                                helperText={
                                    errors.icms?.vBcFcpSt
                                        ? errors.icms?.vBcFcpSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pFcpSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota FCP ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pFcpSt && errors.icms.pFcpSt.message)}
                                helperText={
                                    errors.icms?.pFcpSt
                                        ? errors.icms?.pFcpSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vFcpSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor FCP ST"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vFcpSt && errors.icms.vFcpSt.message)}
                                helperText={
                                    errors.icms?.vFcpSt
                                        ? errors.icms?.vFcpSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.pSt"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Alíquota Suportada pelo Consumidor"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pSt && errors.icms.pSt.message)}
                                helperText={
                                    errors.icms?.pSt
                                        ? errors.icms?.pSt?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="icms.vBcFcpStRet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Base de Cálculo FCP Retido"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vBcFcpStRet && errors.icms.vBcFcpStRet.message)}
                                helperText={
                                    errors.icms?.vBcFcpStRet
                                        ? errors.icms?.vBcFcpStRet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.pFcpStRet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Aliquota FCP Retido"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.pFcpStRet && errors.icms.pFcpStRet.message)}
                                helperText={
                                    errors.icms?.pFcpStRet
                                        ? errors.icms?.pFcpStRet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="icms.vFcpStRet"
                        control={control}
                        render={({ field }) => (
                            <TextFieldSaurus
                                tipo="DECIMAL"
                                manterMascara
                                disabled={props.loading}
                                fullWidth
                                autoComplete='new-password'
                                variant="outlined"
                                label="Valor FCP Retido"
                                allowSubmit
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={Boolean(errors.icms?.vFcpStRet && errors.icms.vFcpStRet.message)}
                                helperText={
                                    errors.icms?.vFcpStRet
                                        ? errors.icms?.vFcpStRet?.message
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </AccordionSaurus>
    )
}