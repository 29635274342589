import { AppEventEnum } from 'model/enums/enum-app-event';
import React, { useCallback, useEffect } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { DialogSuporte } from 'views/components/dialog/dialog-suporte/dialog-suporte';


export const SuporteComponent = () => {

  const [text, setText] = React.useState('');
  const { addHandler, removeHandler } = useEventTools()

  const [showDialogSuporte, setShowDialogSuporte] =
    React.useState<boolean>(false);

  const abrirFecharValor = useCallback((text: string) => {
    setText(text)
    setShowDialogSuporte(prev => !prev)
  }, [])

  useEffect(() => {
    addHandler(AppEventEnum.SuporteChamada, abrirFecharValor)

    return () => removeHandler(AppEventEnum.SuporteChamada, abrirFecharValor)
  }, [abrirFecharValor, addHandler, removeHandler])

  return (

    <DialogSuporte
      openned={showDialogSuporte}
      loading={false}
      closeModal={() => setShowDialogSuporte(false)}
      text={text}
    />
  );
};
