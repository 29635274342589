import { FormSituacaoManifestoAcao } from "views/components/form/manifesto/situacao-manifesto-acao/form-situacao-manifesto-acao";
import { DialogSaurus } from "../dialog-saurus/dialog-saurus"
import { usePostManifestoAcao } from "data/api/gestao/empresa-documento/post-manifesto-acao";
import { ManifestoAcaoModel } from "model/api/gestao/manifesto/manifesto-acao-model";
import { useCadastros, useToastSaurus } from "services/app";
import { EnumAcaoManifesto } from "model/enums/enum-acao-manifesto";
import { CircularLoading } from "views/components/utils";
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus";
import { useCallback, useEffect, useRef } from "react";
import { Box, Button } from "@material-ui/core";
import { DefaultFormRefs } from "views/components/form/utils";
import { PessoaPerguntaIcon } from "views/components/icons/pessoa-pergunta-icon";

const acoesQueNaoMostramDialog = [
    EnumAcaoManifesto.EventosDeConfirmacaoDeOperacao,
    EnumAcaoManifesto.EventosDeCienciaDaOperacao
]

interface DialogConfirmarAcaoManifestoProps {
    openned: boolean;
    cnpj: string;
    chave: string;
    acao: EnumAcaoManifesto;
}

export const DialogConfirmarAcaoManifesto = ({
    openned,
    chave,
    cnpj,
    acao
}: DialogConfirmarAcaoManifestoProps) => {

    const { postManifestoAcao, carregando } = usePostManifestoAcao();
    const { showToast } = useToastSaurus();
    const { showConfirm } = useConfirmSaurus();
    const { fecharConfirmarSituacaoManifesto, fecharModalSituacaoManifesto } = useCadastros();

    const formRef = useRef<DefaultFormRefs<ManifestoAcaoModel>>(null);

    const handleSubmit = useCallback(async (model: ManifestoAcaoModel) => {
        try {
            const res = await postManifestoAcao(cnpj, chave, acao, model)

            if (res.erro) throw res.erro

            showToast('success', 'Ação do Manifesto realizada.')

            fecharModalSituacaoManifesto()
            fecharConfirmarSituacaoManifesto()
            

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [acao, chave, cnpj, fecharConfirmarSituacaoManifesto, fecharModalSituacaoManifesto, postManifestoAcao, showToast])

    useEffect(() => {
        if (acoesQueNaoMostramDialog.includes(acao)) {
            showConfirm({
                title: 'Confirmar Manifesto?',
                description: 'Após a confirmação do manifesto, não será possível alterar a situação  da Nota Fiscal',
                primaryButtonText: 'Confirmar',
                secondaryButtonText: 'Cancelar'
            }).then(async () => {
                const model = new ManifestoAcaoModel(acao, '');
                await handleSubmit(model);
            }).finally(() => {
                fecharConfirmarSituacaoManifesto()
            })
        }
    }, [acao, fecharConfirmarSituacaoManifesto, handleSubmit, showConfirm])

    return (
        <>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            {!acoesQueNaoMostramDialog.includes(acao) && <DialogSaurus
                aberto={openned}
                titulo='Informar Motivo'
                isButtonTitleClose
                handleClickClose={() => fecharConfirmarSituacaoManifesto()}
                tamanho='xs'
            >
                <Box display='flex' flexDirection='column' gridGap={8} alignItems='stretch' mb={1}>
                    <FormSituacaoManifestoAcao
                        loading={false}
                        showLoading={false}
                        onSubmit={handleSubmit}
                        ref={formRef}
                    />
                    <Button fullWidth variant='contained' color='primary' onClick={() => formRef.current?.submitForm()}>
                        <PessoaPerguntaIcon tipo='BUTTON_PRIMARY' />
                        Manifestar
                    </Button>
                </Box>
            </DialogSaurus>}
        </>
    )
}