import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';

export const NaoSeAplicaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon
        tipo={props.tipo}
        class={props.class}
        style={props.style}
        fill={props.fill}
      >
        <path d="M48.9884 22.5751C48.8545 22.3282 48.906 22.0401 48.803 21.7932C48.422 20.6203 47.5467 19.8693 46.5582 19.2314C44.478 17.863 42.1199 17.1737 39.7411 16.5873C35.931 15.651 32.0385 15.2292 28.1151 15.0749C23.3164 14.8897 18.5383 15.0234 13.7808 15.7539C10.9902 16.1757 8.24069 16.7416 5.5839 17.719C3.92599 18.326 2.32985 19.0668 1.07354 20.3734C-0.285744 21.7932 -0.357828 23.5011 0.877888 25.0135C1.712 26.032 2.82414 26.6802 3.98777 27.246C4.26581 27.3798 4.57474 27.4518 4.7498 27.7296C5.67658 29.2214 7.02557 30.2502 8.53932 31.0836C11.6389 32.8018 15.0165 33.6043 18.4868 34.1187C19.3621 34.3759 20.2786 34.345 21.1745 34.4273C23.059 34.6125 24.9537 34.5199 26.8485 34.4891C28.0739 34.4685 29.2993 34.3244 30.5145 34.1187C33.4596 33.6969 36.3326 33.0281 39.0512 31.7935C41.121 30.8573 43.0158 29.6741 44.2721 27.6987C44.3751 27.5444 44.5295 27.4621 44.7046 27.3798C45.6726 26.9271 46.6302 26.4333 47.4335 25.7028C48.3705 24.8797 49.1017 23.9332 48.9884 22.5751ZM35.4985 31.0527C30.4733 32.5446 25.3348 32.8532 20.1447 32.3491C18.2706 32.1639 16.417 31.8244 14.5737 31.3408C12.8437 30.8881 11.2064 30.2708 9.63087 29.4375C9.42492 29.3346 9.24986 29.1905 9.0645 29.0362C19.3621 31.1659 29.6495 31.1762 39.9368 29.0465C38.5672 29.9828 37.0638 30.5795 35.4985 31.0527ZM46.0742 24.3036C44.7561 25.3736 43.1805 25.96 41.5844 26.4538C36.3738 28.0691 30.9984 28.5527 25.5819 28.635C25.2215 28.6453 24.8508 28.635 24.4903 28.635C20.6802 28.6453 16.8804 28.4189 13.1218 27.781C10.1766 27.2872 7.27271 26.6493 4.56444 25.3221C3.79212 24.9414 3.06098 24.5093 2.48432 23.8612C1.81497 23.0998 1.80467 22.4928 2.48432 21.7418C3.45229 20.6718 4.7498 20.1265 6.05759 19.6224C8.23039 18.7684 10.4959 18.2643 12.8025 17.8733C18.5589 16.8959 24.3565 16.721 30.1746 17.1429C34.3349 17.4412 38.4642 18.0174 42.4288 19.4063C43.7366 19.859 45.0032 20.394 46.0948 21.2788C47.3511 22.3385 47.3717 23.2541 46.0742 24.3036Z" />
        <path d="M43.4379 21.5257C43.1496 21.2685 42.8098 21.0936 42.4597 20.9393C40.6988 20.1882 38.8246 19.9001 36.9607 19.6326C33.7273 19.1799 30.4732 19.005 27.2089 18.9124C22.647 18.7787 18.0955 18.9639 13.5645 19.4372C11.5359 19.6532 9.51756 19.931 7.56102 20.5586C6.49006 20.9084 5.38822 21.2376 4.93512 22.4619C4.67768 22.9249 4.97631 23.2747 5.16167 23.6554C5.53238 24.0978 5.98548 24.4064 6.51066 24.6431C7.70518 25.1678 8.96149 25.4559 10.2281 25.6822C13.4822 26.2584 16.7671 26.5156 20.0623 26.6287C23.8004 26.7522 27.5281 26.7522 31.2661 26.5053C34.3554 26.2995 37.4447 26.032 40.4619 25.3221C41.4917 25.0752 42.5112 24.8077 43.3659 24.1389C44.3132 23.3982 44.3338 22.3076 43.4379 21.5257ZM40.7297 23.213C38.4436 23.8714 36.0957 24.1492 33.7376 24.355C28.3416 24.8386 22.9354 24.8797 17.5291 24.5299C14.4398 24.3344 11.3608 24.0669 8.35393 23.2233C8.00381 23.1204 7.59191 23.0998 7.35506 22.7192C8.67316 22.1636 10.053 21.9167 11.4329 21.7006C17.5291 20.7644 23.6665 20.7335 29.8039 20.9598C33.1197 21.0833 36.415 21.3919 39.669 22.0916C39.9882 22.1636 40.3075 22.2356 40.6164 22.3385C40.9665 22.4414 41.296 22.5751 41.6771 22.7192C41.4196 23.0998 41.0386 23.1204 40.7297 23.213Z" />
      </DefaultIcon>
    </>
  );
};
