import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"

export const TransporteIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M0 8V10H28.0938C28.4922 10 29 10.5078 29 10.9062V38H18.9062C18.4297 35.1641 15.9648 33 13 33C10.0352 33 7.57031 35.1641 7.09375 38H3C2.44531 38 2 37.5547 2 37V28H0V37C0 38.6445 1.35547 40 3 40H7.09375C7.57031 42.8359 10.0352 45 13 45C15.9648 45 18.4297 42.8359 18.9062 40H34.0938C34.5703 42.8359 37.0352 45 40 45C42.9648 45 45.4297 42.8359 45.9062 40H47C47.832 40 48.5625 39.625 49.0938 39.0938C49.625 38.5625 50 37.832 50 37V27.4062C50 26.2812 49.5703 25.25 49.1875 24.4688C48.8047 23.6875 48.4062 23.125 48.4062 23.125V23.0938L44.3125 17.5938H44.2812V17.5625C43.3945 16.4531 41.9727 15 40 15H32C31.6406 15 31.3125 15.0664 31 15.1875V10.9062C31 9.30469 29.6953 8 28.0938 8H0ZM0 12V14H18V12H0ZM0 16V18H15V16H0ZM32 17H36V26C36 26.832 36.375 27.5625 36.9062 28.0938C37.4375 28.625 38.168 29 39 29H48V37C48 37.168 47.875 37.4375 47.6562 37.6562C47.4375 37.875 47.168 38 47 38H45.9062C45.4297 35.1641 42.9648 33 40 33C37.0352 33 34.5703 35.1641 34.0938 38H31V18C31 17.832 31.125 17.5625 31.3438 17.3438C31.5625 17.125 31.832 17 32 17ZM38 17H40C40.8242 17 41.9727 17.9258 42.6875 18.8125L46.7812 24.2812L46.8125 24.3125C46.832 24.3398 47.1016 24.7227 47.4062 25.3438C47.6602 25.8594 47.793 26.4727 47.875 27H39C38.832 27 38.5625 26.875 38.3438 26.6562C38.125 26.4375 38 26.168 38 26V17ZM0 20V22H12V20H0ZM0 24V26H9V24H0ZM13 35C15.2227 35 17 36.7773 17 39C17 41.2227 15.2227 43 13 43C10.7773 43 9 41.2227 9 39C9 36.7773 10.7773 35 13 35ZM40 35C42.2227 35 44 36.7773 44 39C44 41.2227 42.2227 43 40 43C37.7773 43 36 41.2227 36 39C36 36.7773 37.7773 35 40 35Z" />
            </DefaultIcon>
        </>
    );
};
