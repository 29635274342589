import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export class PutConfigContratoProps {
    constructor(
        public empresaId: null = null,
        public codigo: Number = 0,
        public valor: string = '',
    ) { }
}

export function usePutConfigContrato() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putConfigContrato = useCallback(
        (configModel: Array<PutConfigContratoProps>, contratoId: string) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/Contrato/${contratoId}/configuracao`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(configModel),
                enviarTokenUsuario: true,
            }),
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putConfigContrato,
    };
}
