import { VariaveisAmbiente } from 'config';
import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';

export function useDeleteFechamentoParcialPedido() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const deleteFechamentoParcialPedido = useCallback(
    (empresaId: string, pedidoId: string) => {
      return invocarApi({
        url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/fechamento-parcial`,
        method: 'DELETE',
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`
      });
    },
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    deleteFechamentoParcialPedido
  };
}
