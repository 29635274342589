import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const NcmAddIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <g id="surface11944083">
                    <path d="M 11 3 L 11 6 L 39 6 L 39 3 L 47 3 L 47 11 L 44 11 L 44 28.300781 C 43.363281 27.996094 42.691406 27.746094 42 27.554688 L 42 11 L 39 11 L 39 8 L 11 8 L 11 11 L 8 11 L 8 39 L 11 39 L 11 42 L 27.1875 42 C 27.359375 42.691406 27.589844 43.359375 27.875 44 L 11 44 L 11 47 L 3 47 L 3 39 L 6 39 L 6 11 L 3 11 L 3 3 Z M 5 9 L 9 9 L 9 5 L 5 5 Z M 41 9 L 45 9 L 45 5 L 41 5 Z M 29 13 L 29 21 L 37 21 L 37 27.269531 C 36.3125 27.375 35.644531 27.542969 35 27.761719 L 35 23 L 29 23 L 29 29 L 23 29 L 23 35 L 27.574219 35 C 27.335938 35.644531 27.152344 36.3125 27.027344 37 L 21 37 L 21 29 L 13 29 L 13 13 Z M 15 27 L 27 27 L 27 15 L 15 15 Z M 5 45 L 9 45 L 9 41 L 5 41 Z M 5 45 " />
                    <path d="M 44.738281 39.128906 C 44.738281 39.726562 44.335938 40.128906 43.738281 40.128906 L 39.835938 40.128906 L 39.835938 44.027344 C 39.835938 44.628906 39.4375 45.027344 38.835938 45.027344 C 38.238281 45.027344 37.835938 44.628906 37.835938 44.027344 L 37.835938 40.128906 L 33.9375 40.128906 C 33.335938 40.128906 32.9375 39.726562 32.9375 39.128906 C 32.9375 38.527344 33.335938 38.128906 33.9375 38.128906 L 37.835938 38.128906 L 37.835938 34.226562 C 37.835938 33.628906 38.238281 33.226562 38.835938 33.226562 C 39.4375 33.226562 39.835938 33.628906 39.835938 34.226562 L 39.835938 38.128906 L 43.738281 38.128906 C 44.335938 38.128906 44.738281 38.527344 44.738281 39.128906 Z M 48.835938 39.128906 C 48.835938 44.628906 44.335938 49.128906 38.835938 49.128906 C 33.335938 49.128906 28.835938 44.628906 28.835938 39.128906 C 28.835938 33.628906 33.335938 29.128906 38.835938 29.128906 C 44.335938 29.128906 48.835938 33.628906 48.835938 39.128906 Z M 46.835938 39.128906 C 46.835938 34.726562 43.238281 31.128906 38.835938 31.128906 C 34.4375 31.128906 30.835938 34.726562 30.835938 39.128906 C 30.835938 43.527344 34.4375 47.128906 38.835938 47.128906 C 43.238281 47.128906 46.835938 43.527344 46.835938 39.128906 Z M 46.835938 39.128906 " />
                </g>
            </DefaultIcon>
        </>
    );
};
