import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useGetSegmentoCnae() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getSegmentoCnae = useCallback(
        (cnae: string) => invocarApi({
            url: `/${VariaveisAmbiente.gatewayVersion}/Segmento/cnae/${cnae}`,
            method: 'GET',
            enviarTokenUsuario: false,
        }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        getSegmentoCnae,
    };
}
