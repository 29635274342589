import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => (
    {
        cardContainer: {
            transition: "0.3s ease-in-out border",
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: "5px",
            display: "flex",
            minHeight: "60px",
            position: "relative",
            marginTop: "5px",
        },
        cardContent: {
            flex: "1 1 100%",
            justifyContent: "space-between",
            alignItems: "center",
            "& span": {
                fontSize: "0.7em",
            },
        },
        divider: {
            padding: "0 10px",
            marginTop: '-8px',
            background: theme.palette.grey[200],
            height: "3px!important",
        },
        alinharChart: {
            height: 'auto',
            [theme.breakpoints.down("lg")]: {
                height: 'auto',
            },
            margin: 'auto!important',
            alignItems: 'center',
            alignContent: 'center',
        },
        alinharChartDonut: {
            height: '90%',
            [theme.breakpoints.down("md")]: {
                height: 'auto',
            },
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto!important',
            alignItems: 'center',
            alignContent: 'center',
        },
        chart: {
            width: '97%',
            marginLeft: '5px'
        },
        chartDonut: {
            width: '500px',
            [theme.breakpoints.down("sm")]: {
                width: '370px !important',
            },
            marginLeft: '5px'
        },
        progressBar: {
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-50%',
                width: '100%',
                height: '100%',
                background: `#${((Math.random() * 0xffffff) << 0)
                    .toString(16)
                    .padStart(6, '0')}`,
                pointerEvents: ' none',
                zIndex: 1,
            }
        }
    }
))