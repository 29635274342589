import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

    root: {
        position: 'relative',
        height: 'calc(100% - 8px)',
        overflow: 'auto',
        paddingBottom: theme.spacing(1),
    },
    stepperOuterContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    rootContainer: {
        flex: '1 1 100%',
        display: 'block',
    },
    divForm: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }

}));