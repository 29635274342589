export const imagemForUpload = (imagem: string): string => {
    try {
        if (!imagem)
            return '';

        if (imagem.indexOf('data:') === -1)
            return '';

        return imagem.substring(imagem.indexOf(',') + 1);
    } catch {
        return '';
    }
}