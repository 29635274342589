import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
   containerInternoCard: {
      padding: theme.spacing(1),
   },
   topHeader: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
   },
   containerButtonRemover: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end'
   },
   containerTitlePedido: {
      display: "flex",
      alignItems: 'center'
   },
   buttonRemoverPedido: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
      height: '30px',
      borderRadius: '5px'
   },
   codigo: {
      fontWeight: 'bold',
   },
   divider: {
      width: "100%",
      marginTop: '5px',
      marginBottom: theme.spacing(1),
      background: theme.palette.grey[400],
      height: "1px",
   },
   containerProdutos: {
      margin: theme.spacing(1)
   },
   vendedorContent: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: 5,
   },
   vendedorText: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      paddingBottom: 5,
      "& b": {
         color: theme.palette.text.primary,
      }
   },
   vendedorDivider: {
      height: 2,
      backgroundColor: theme.palette.grey[300],
   },
   infoIcon: {
      height: 20,
      width: 20,
      fill: theme.palette.primary.main
    },
    tooltipText: {
      padding: theme.spacing(1)
    },
    tooltipContent: {
      marginLeft: theme.spacing(1)
    }
}));
