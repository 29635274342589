import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
  cardContainer: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#D4D4D4",
    transition: "0.3s ease-in-out border",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "row",
    flex: 1,
    minHeight: "60px",
    position: "relative",
    marginTop: "5px",
    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    padding: theme.spacing(1),
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },

  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },

  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    "& div:last-child": {
      marginRight: theme.spacing(1),
    },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },

  celulaGrid: {
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGridFull: {
    flex: "1 1 auto",
  },
  celulaGridStatus: {
    padding: theme.spacing(0.5),
    borderRadius: 4,
    justifyContent: "center",
    textOverflow: "ellipsis"
  }
}));
