import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInfoPedidoCompraValidation } from './form-info-pedido-compra-validation';
import { FormInfoPedidoCompraProps } from './form-info-pedido-compra-props';
import { InfoPedidoCompraFormModel } from 'model/app/forms/entrada/info-pedido-compra-form-model';

export const FormInfoPedidoCompra = forwardRef<
    DefaultFormRefs<InfoPedidoCompraFormModel>,
    FormInfoPedidoCompraProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const { formInfoPedidoCompraValidationYup } = useFormInfoPedidoCompraValidation();
    const [model, setModel] = useState<InfoPedidoCompraFormModel>(new InfoPedidoCompraFormModel())
    const modelFormRef = useRef<InfoPedidoCompraFormModel>(new InfoPedidoCompraFormModel())

    const {
        handleSubmit,
        control,
        formState,
        reset,
    } = useForm<InfoPedidoCompraFormModel>({
        resolver: yupResolver(formInfoPedidoCompraValidationYup),
        defaultValues: { ...model },
        criteriaMode: 'firstError',
        mode: 'onChange',
    });
    const { errors, touchedFields } = formState

    const onSubmit = (values: InfoPedidoCompraFormModel) => {
        props.onSubmit(values, model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit(onSubmit)();
        },
        resetForm: () => {
        },
        fillForm: (model: InfoPedidoCompraFormModel) => {
            setModel(model)
            reset({ ...model })
            modelFormRef.current = { ...model }
        },
    }));

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {props.loading && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="NORMAL" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="xNEmp"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número do Empenho"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xNEmp && errors.xNEmp?.message)}
                                        helperText={
                                            touchedFields.xNEmp || errors.xNEmp
                                                ? errors.xNEmp?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="xPed"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número do Pedido"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xPed && errors.xPed?.message)}
                                        helperText={
                                            touchedFields.xPed || errors.xPed
                                                ? errors.xPed?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="xCont"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'NUMERO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número do Contrato"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xCont && errors.xCont?.message)}
                                        helperText={
                                            touchedFields.xCont || errors.xCont
                                                ? errors.xCont?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
