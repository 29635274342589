import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';

export const DefinicoesVendaHeader = () => {
  const { goToAdministrativo } = useOpenMovRota();

  const leftArea = useCallback(
    () => (
      <ButtonPrivateHeader
        icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
        tooltip="Ir para Menu Administrativo"
        onClick={goToAdministrativo}
      ></ButtonPrivateHeader>
    ),
    [goToAdministrativo]
  );

  return (
    <>
      <PrivatePageHeader title={'Definições da Venda'} leftArea={leftArea()} />
    </>
  );
};
