export class StringBuilder {
    private _lines: string[] = [];

    constructor(public value?: string) {
        this.append(value ?? '')
    }

    append(line: string = ""): void {
        this._lines.push(line);
    }

    appendWithln(line: string = ""): void {
        this._lines.push(line);
        this._lines.push("\r\n");
    }

    clear() {
        this._lines.length = 0;
    }

    toString(): string {
        return this._lines.join("");
    }
}
