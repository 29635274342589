import { guidEmpty } from 'utils/guid-empty';

export class ProdutoVincularFiscalModel {
  constructor(
    public produtoGradeId: string = guidEmpty(),
    public ncmId: string | null = guidEmpty(),
    public grupoImpostoId: string = guidEmpty(),
    public codncm: string = ''
  ) { }
}


