import * as Yup from "yup";
import { useMemo } from "react";
import { guidEmpty } from "utils/guid-empty";

export const useFormNovoProdutoSubItemValidation = () => {

  const FormNovoProdutoSubItemYupValidation = useMemo(() => {
    return Yup.object().shape({
      produtoNome: Yup.string().nullable().optional().test("produtoSubGradeId", "A quantidade padrão é maior que 0, selecione um produto. ", function (value: any) {
        const prodSubGradeId = (this as any).parent.produtoSubGradeId
        const qPadrao = (this as any).parent.qPadrao

        if (qPadrao > 0 && (prodSubGradeId === guidEmpty() || !prodSubGradeId)) {
          return false
        }

        return true
      })
        .test("produtoSubGradeId", "Selecione o produto ou modificador", function (value: any) {
          const modificador = (this as any).parent.modificadorId
          const prodSubGradeId = (this as any).parent.produtoSubGradeId
          
          if (modificador && modificador !== guidEmpty()) {
            return true
          }

          if (prodSubGradeId && prodSubGradeId !== guidEmpty()) {
            return true
          }
          
          return false
        }),
      produtoSubGradeId: Yup.string().nullable().optional(),
      modificadorId: Yup.string().nullable().optional(),
      qMin:
        Yup
          .number()
          .integer()
          .typeError('Precisa ter um valor válido.')
          .max(Yup.ref('qMax'), 'A quantidade é maior que a quantidade máxima.')
          .required('Digite uma quantidade.'),
      qMax:
        Yup
          .number()
          .integer()
          .typeError('Precisa ter um valor válido.')
          .min(Yup.ref('qMin'), 'A quantidade é menor que a quantidade mínima.')
          .required('Digite uma quantidade.'),
      qPadrao:
        Yup
          .number()
          .integer()
          .typeError('Precisa ter um valor válido.')
          .max(Yup.ref('qMax'), 'A quantidade é maior que a quantidade máxima.')
          .required('Digite uma quantidade.')
    });
  }, []);

  return {
    FormNovoProdutoSubItemYupValidation,
  };
};
