import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginBottom: theme.spacing(2),
    },
    acoes: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    buttonExcluir: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        padding: 0,
        height: '100%',
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        },
        '& svg': {
            margin: 0
        }
    },
}));