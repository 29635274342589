import { EnumMovModelo } from "model";
import { guidEmpty } from "utils/guid-empty";

export class MovNumeracaoModel {
    constructor(
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public modelo: EnumMovModelo = EnumMovModelo.ORCAMENTO,
        public nNf: number = 0,
        public tpAmb: number = 2,
        public serie: number = 0
    ) { }
}
