import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';

import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import classNames from 'classnames';
import { useStyles } from './accordion-limpar-dados-styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DesfazerIcon } from 'views/components/icons';
import React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';

export const AccordionLimparDados = () => {
  // STATES E REFS
  const [openAccordion, setOpenAccordion] = useState(false);

  const confirm = useConfirm();
  const { limparCadastros } = useSincronizacaoCadastros();

  // AUX
  const { theme } = useThemeQueries();
  const classesModal = useModalStyles();
  const classes = useStyles();
  const { push } = useHistory();

  const limparDados = React.useCallback(() => {
    confirm({
      title: 'Confirma a Restauração?',
      description:
        'Deseja restaurar a aplicação neste dispositivo e configurá-lo novamente?',
      cancellationText: 'Cancelar',
      cancellationButtonProps: {
        color: 'default',
      },
      confirmationText: 'Restaurar',
    }).then(() => {
      limparCadastros();
      localStorage.clear();
      sessionStorage.clear();

      push('/login');
    });
  }, [confirm, limparCadastros, push]);

  return (
    <AccordionSaurus
      labelPrimary={`Restaurar Dispositivo`}
      tituloChildren={
        <DesfazerIcon tipo="BUTTON" fill={theme.palette.text.primary} />
      }
      tipoExpand="bold"
      noPaperRoot={false}
      heightDivider={2}
      showDivider={openAccordion}
      colorDivider={theme.palette.primary.main}
      colorExpand={theme.palette.primary.main}
      expanded={openAccordion}
      onChange={() => setOpenAccordion(!openAccordion)}
    >
      <div className={classNames(classesModal.root, classes.root)}>
        <div className={classesModal.content}>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          <div
            className={classNames(classesModal.contentForms)}
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', fontWeight: '500' }}
                >
                  Restaurar a aplicação no dispositivo pode ajudar em alguns
                  problemas de lentidão por conta do tamanho do cadastro ou por
                  diversos outros problemas de vinculação/reviculação do
                  equipamento na sua conta.
                  <br />
                  Clique abaixo se desejar fazer a limpeza e iniciar uma nova
                  instalação.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
                <Button
                  onClick={() => limparDados()}
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonRestaurar}
                >
                  <DesfazerIcon tipo="BUTTON_PRIMARY" />
                  Restaurar este Dispositivo
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </AccordionSaurus>
  );
};
