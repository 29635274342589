import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
    containerPDV: {
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            height: 0,
        }
    }
}))