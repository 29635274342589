import { guidEmpty } from "../../../../../utils/guid-empty";
import { EnumIndEscala } from "../../../../enums/enum-ind-escala";
import { EnumOrigemMercadoria } from "../../../../enums/enum-origem-mercadoria";
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';

export class ProdutoAtualizarModel {
  constructor(
    public id: string = guidEmpty(),
    public nome: string = "",
    public descricao: string = "",
    public infAdic: string = '',
    public categoriaId: string | null = null,
    public ncmId: string | null = null,
    public marcaId: string | null = null,
    public cnpjFab: string = "",
    public orig: EnumOrigemMercadoria = EnumOrigemMercadoria.OM_NACIONAL,
    public indEscala: EnumIndEscala = EnumIndEscala.N,
    public codigoNcm: string = "",
    public impostoId: string | null = null,
    public imposto: string = "",
    public setorId: string | null = null,
    public cobraTaxaServico: boolean = true,
    public tipo: EnumTipoProduto = EnumTipoProduto.Produto
  ) { }
}
