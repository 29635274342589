import { TipoDocumentoOrgaoExpedidorEnum } from "model/enums/enum-orgao-expedidor";
import { EnumTipoDocumento } from "model/enums/enum-tp-documento";
import { guidEmpty } from "utils/guid-empty";

export class PessoaDocumentoModel {
  constructor(
    public id: string = guidEmpty(),
    public pessoaId: string = guidEmpty(),
    public tipo: EnumTipoDocumento = EnumTipoDocumento.CarteiraRegistroProfissional,
    public documento: string = '',
    public dataExpedicao: string = '',
    public ufExpedicao: string = '',
    public orgaoExpedicao: null | TipoDocumentoOrgaoExpedidorEnum  = null,
    public dataValidade: string = '',
    public informacoes: string = '',
  ) { }
}