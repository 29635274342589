import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerItemFooter: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        "& .MuiBadge-badge": {
            "z-index": 0,
        }
    },
    textPreview: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        fontSize: '16px',
        lineHeight: '31px',
        fontWeight: 600,
        height: '57px',
    },
    containerItemFooterLeft: {
        paddingLeft: theme.spacing(1)
    }
}));