import { useCallback } from "react";
import { useMenuPrincipal } from "services/app/hooks/menu-principal";
import { ButtonPrivateHeader } from "views/components/controles";
import { PrivatePageHeader } from "views/components/headers";
import { FiltroIcon, MenuIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { useHistory } from "react-router-dom";
import { useFiltrosModais } from "services/app/hooks/filtros-modais";


export const DocumentoFiscalHeader = () => {

    const { abrirMenu } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const history = useHistory();
    const { abrirDocumentoFiscalFiltroModal } = useFiltrosModais();

    const urlParams = new URLSearchParams(history.location.search)

    const filtros = {
        dInicial: urlParams.get('dInicial') || '',
        dFinal: urlParams.get('dFinal') || '',
    }

    const leftArea = useCallback(() => (
        isMobile ?
            <ButtonPrivateHeader
                icon={<MenuIcon tipo="PRIVATE_HEADER" />}
                tooltip="Menu"
                onClick={abrirMenu}
            /> :
            null
    ),
        [isMobile, abrirMenu]
    );

    const rightArea = useCallback(() => (
        <ButtonPrivateHeader
            icon={<FiltroIcon tipo="PRIVATE_HEADER" />}
            tooltip={'Abrir Filtros'}
            onClick={() => {
                abrirDocumentoFiscalFiltroModal({
                    dFinal: filtros.dFinal,
                    dInicial: filtros.dInicial,
                })
            }}
        />
    ),
        [abrirDocumentoFiscalFiltroModal, filtros.dFinal, filtros.dInicial]
    );

    return (
        <PrivatePageHeader
            title="Documento Fiscal"
            leftArea={leftArea()}
            rightArea={rightArea()}
        />
    )
}