import { VariaveisAmbiente } from "config";
import { PedidoProdutoModelPost } from "model/api/gestao/pedido/pedido-produto-model";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutPedidoProduto() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPedidoProdutos = useCallback(
        (empresaId: string, pedidoId: string, pedidoProdutoId: string, produto: PedidoProdutoModelPost) => {

            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/produto/${pedidoProdutoId}`,
                method: "PUT",
                enviarTokenUsuario: true,
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(produto),
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putPedidoProdutos,
    };
}
