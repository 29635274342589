import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function usePostAdicionarVersao() {

  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postAdicionarVersao = useCallback(
    ( empresaId: string, id: string, data: string) =>
      invocarApi({
        url: `${VariaveisAmbiente.gatewayVersion}/empresa/${empresaId}/caixa/${id}/versao?versao=${data}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );
  return {
    ...outrasPropriedades,
    postAdicionarVersao,
  };
}
