import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';

export function useDeleteConfigNfce() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const deleteConfigNfce = useCallback(
        (documento: string) =>
            invocarApi({
                url: `/${VariaveisAmbiente.gatewayVersion}/empresa/${documento}/fiscal/configuracao-fiscal`,
                method: 'DELETE',
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        deleteConfigNfce,
    };
}
