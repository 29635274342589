import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const RelatorioIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 7 2 L 7 48 L 43 48 L 43 47 L 43 14.585938 L 30.414062 2 L 7 2 z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 L 9 4 z M 31 5.4140625 L 39.585938 14 L 31 14 L 31 5.4140625 z M 36 23 A 2 2 0 0 0 34 25 A 2 2 0 0 0 34.136719 25.726562 L 28.298828 33.025391 A 2 2 0 0 0 28 33 A 2 2 0 0 0 27.597656 33.042969 L 21.896484 26.628906 A 2 2 0 0 0 22 26 A 2 2 0 0 0 20 24 A 2 2 0 0 0 18 26 A 2 2 0 0 0 18.068359 26.517578 L 14.515625 30.070312 A 2 2 0 0 0 14 30 A 2 2 0 0 0 12 32 A 2 2 0 0 0 14 34 A 2 2 0 0 0 16 32 A 2 2 0 0 0 15.931641 31.482422 L 19.484375 27.929688 A 2 2 0 0 0 20 28 A 2 2 0 0 0 20.402344 27.957031 L 26.103516 34.371094 A 2 2 0 0 0 26 35 A 2 2 0 0 0 28 37 A 2 2 0 0 0 30 35 A 2 2 0 0 0 29.863281 34.273438 L 35.701172 26.974609 A 2 2 0 0 0 36 27 A 2 2 0 0 0 38 25 A 2 2 0 0 0 36 23 z" />
            </DefaultIcon>
        </>
    );
};
