import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { picker } from 'utils/picker';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useFormImpressoraEditValidation } from './form-impressora-edit-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ImpressorasMock } from 'data/mocks/impressoras-mock';
import { KeyValueModel } from 'model';
import { InformacaoIcon } from 'views/components/icons';
import { useStyles } from './form-impressora-edit-styles'

export interface FormImpressoraEditProps extends DefaultFormProps<ImpressoraModel> {
  setCarregandoExterno(carregando: boolean): void;
}
export const FormImpressoraEdit = forwardRef<
  DefaultFormRefs<ImpressoraModel>,
  FormImpressoraEditProps
>(({ loading, ...props }: FormImpressoraEditProps, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputCodigo = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<ImpressoraModel>(
    new ImpressoraModel(),
  );
  const { FormImpressoraEditYupValidation } = useFormImpressoraEditValidation();
  const { isMobile } = useThemeQueries();
  const classes = useStyles()

  const modelosMock: Array<KeyValueModel> = ImpressorasMock.map(x => new KeyValueModel(x, x))
  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<ImpressoraModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormImpressoraEditYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: ImpressoraModel) => {
    const ncm = picker<ImpressoraModel>(values, new ImpressoraModel());

    props.onSubmit(ncm, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      if (!isMobile) refInputCodigo.current?.focus();
    },
    fillForm: (model: ImpressoraModel) => {
      setModelForm(model);
      reset({ ...model });
      //senão o focus n funfa
      setTimeout(() => {
        if (!isMobile) refInputCodigo.current?.focus();
      }, 500);
    },
  }));
  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      label="Descrição *"
                      variant="outlined"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="caminho"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      label="Caminho *"
                      variant="outlined"
                      placeholder='Ex: \\127.0.0'
                      error={Boolean(
                        errors.caminho && errors.caminho.message,
                      )}
                      helperText={
                        touchedFields.caminho || errors.caminho
                          ? errors.caminho?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="modelo"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      fullWidth
                      allowSubmit
                      label="Modelo *"
                      variant="outlined"
                      conteudo={modelosMock}
                      placeholder='Ex: \\127.0.0'
                      error={Boolean(
                        errors.modelo && errors.modelo.message,
                      )}
                      helperText={
                        touchedFields.modelo || errors.modelo
                          ? errors.modelo?.message
                          : undefined
                      }
                      {...field}
                      onChange={ev => {
                        const item = modelosMock
                          .filter(item => item.Key === ev.target.value)

                        if (item[0].Key) {
                          setValue('modelo', item[0].Value)
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="colunas"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="NUMERO"
                      maxTexto={3}
                      label="Colunas *"
                      variant="outlined"
                      placeholder='Ex: 42'
                      error={Boolean(
                        errors.colunas && errors.colunas.message,
                      )}
                      helperText={
                        touchedFields.colunas || errors.colunas
                          ? errors.colunas?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="quantidadeVias"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="NUMERO"
                      maxTexto={3}
                      label="Vias *"
                      variant="outlined"
                      placeholder='Ex: 2'
                      error={Boolean(
                        errors.quantidadeVias && errors.quantidadeVias.message,
                      )}
                      helperText={
                        touchedFields.quantidadeVias || errors.quantidadeVias
                          ? errors.quantidadeVias?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="usuario"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="TEXTO"
                      label="Usuário"
                      variant="outlined"
                      {...field}
                      endAdornmentButton={
                        <Tooltip title="Usuário que tenha permissão para acessar o dispositivo em compartilhamento." arrow>
                          <Box display="flex" className={classes.infoIcon}>
                            <InformacaoIcon tipo='BUTTON' />
                          </Box>
                        </Tooltip>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="senha"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      fullWidth
                      allowSubmit
                      tipo="PASSWORD"
                      label="Senha"
                      variant="outlined"
                      {...field}
                      endAdornmentButton={
                        <Tooltip title="Senha que tenha permissão para acessar o dispositivo em compartilhamento." arrow>
                          <Box display="flex" className={classes.infoIcon}>
                            <InformacaoIcon tipo='BUTTON' />
                          </Box>
                        </Tooltip>
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
