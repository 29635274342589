import { DialogSaurus } from "../dialog-saurus/dialog-saurus";
import { isEmpty } from "lodash";
import { DialogUsuariosConvidar } from "./usuarios-convidar/dialog-usuarios-convidar";
import { DialogUsuarioEdit } from "./usuario-edit/dialog-usuario-edit";
import { EmpresaUsuarioModel } from "model";

interface DialogUsuarioProps{
    openned: boolean;
    model?: EmpresaUsuarioModel;
}

export const DialogUsuario = ({ model, openned }: DialogUsuarioProps) => {

    return (
        <>
            {openned &&
                <DialogSaurus
                    aberto={openned || false}
                    carregando={false}
                    titulo={openned && isEmpty(model) ? "Convidar Novo Usuário" : "Editar Usuário"}
                    tamanho="sm"
                >
                    {openned && isEmpty(model) && (<DialogUsuariosConvidar aberto={openned} />)}
                    {openned && model && (<DialogUsuarioEdit model={model} aberto={openned} />)}
                </DialogSaurus>
            }
        </>
    );
};
