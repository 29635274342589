import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    containerError: {
        height: '100%',
    },
    imageError: {
        textAlign: 'center',
        marginBottom: 30,
        "& svg": {
            width: 150,
            height: 150,

        },
    },
    btnTentarNovamente: {
        marginTop: 30,
    },
    containerLoading: {
        height: '100%',
        "& .loader-container": {
            width: 150,
            height: 150,
        }
    }
}));