import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { PlanoPutModel } from 'model/api/gestao/plano/plano-form-model';
import { VariaveisAmbiente } from 'config';

export function usePutPlanoLicenca() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPlanoLicenca = useCallback(
        (model: PlanoPutModel) =>
            invocarApi({
                url: `${VariaveisAmbiente.gatewayVersion}/licenca`,
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                data: JSON.stringify(model),
                enviarTokenUsuario: true
            }),
        [invocarApi],
    );
    return {
        ...outrasPropriedades,
        putPlanoLicenca,
    };
}