import { EnumIndstatusStatus } from "model/enums/enum-indstatus-status";
import { guidEmpty } from "utils/guid-empty";

export class PontosVendaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public empresaId: string = guidEmpty(),
        public depositoId: string = guidEmpty(),
        public numCaixa: number = 1,
        public chave: string = '',
        public descricao: string = '',
        public indStatus: EnumIndstatusStatus = EnumIndstatusStatus.ATIVADO,
        public dominio: string = '',
        public tpCaixa: number = 0
    ) { }
}