import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        background: theme.palette.common.white,
    },
    containerBackgroundColor: {
        background: theme.palette.grey[100],
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    divider: {
        width: "100%",
        background: theme.palette.primary.main,
        height: "2px!important",
    },
    containerInfoGeralPC: {
        height: "auto",
        flex: 1,
        minHeight: '100%',
        background: '#FFF',
        boxShadow: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        padding: theme.spacing(2),
        borderRadius: "8px",
    },
    containerInfo: {
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    dividersub: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid #DCDCDC`,
    },
    header: {
        flex: "0 1 auto"
    },
    containerInfoWidth: {
        maxWidth: '650px',
        flex: 1,
        margin: theme.spacing(2),
    },
    cardInfoPreview: {
        background: '#FFFFFF',
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 8px rgba(96, 97, 112, 0.16)",
        borderRadius: "8px",

    },
    containerInfoPreview: {
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        borderRadius: "8px",
    },
    containerInfoPreviewBlue: {
        backgroundColor: 'rgba(31, 156, 228, 0.04)',
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        borderRadius: "8px",
    },
    containerItemPreview: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    containerPrice: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        fontSize: '1.125rem',
        lineHeight: '2rem',
        fontWeight: 600,
        color: '#858585',
    },
    labelSemi: {
        color: '#858585',
        fontSize: '1.125rem',
        lineHeight: '2rem',
        fontWeight: 500
    },
    labelPagamento: {
        fontSize: '1.125rem',
        lineHeight: '0.8rem',
        fontWeight: 600
    },

}));
