import { Paper, Typography } from '@material-ui/core';
import { CardDashboardAdminChartBarProps } from '../card-dashboard-admin-chart-props';
import { useDefaultCardStyles } from '../../../components/default-card/default-card-styles';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';
import { DashboardAdminVendasModel } from 'model/api/gestao/dashboard-admin';

export const CardDashboardAdminChartBar = ({
  model,
  carregando
}: CardDashboardAdminChartBarProps) => {
  const classes = useDefaultCardStyles();
  const utilClasses = useStyles();

  const series = useCallback(() => {
    let totalVendido = [];
    let custo = [];
    let lucro = [];

    for (let i = 0; i < 7; i++) {
      let diaAtual = Array.from(
        new Set<DashboardAdminVendasModel>(
          model?.filter(
            (item) => new Date(pegarDia(String(item.data))).getDay() === i,
          ),
        ),
      );

      if (diaAtual === undefined) {
        totalVendido[i] = 0;
        custo[i] = 0;
        lucro[i] = 0;
      } else {
        totalVendido[i] = diaAtual.reduce((a, b) => a + b.vVenda, 0).toFixed(2);
        custo[i] = diaAtual.reduce((a, b) => a + b.vCusto, 0).toFixed(2);
        lucro[i] = diaAtual.reduce((a, b) => a + (b.vVenda - b.vCusto), 0).toFixed(2);
      }
    }
    const series: any[] = [
      {
        name: 'Custo',
        data: custo,
      },
      {
        name: 'Total vendido',
        data: totalVendido,
      },
      {
        name: 'Lucro',
        data: lucro,
      },
    ];
    return series;
  }, [model]);

  const pegarDia = (item: String) => {
    let ano = item.slice(0, 4);
    let mes = item.slice(4, 6);
    let dia = item.slice(6, 8);
    return `${ano}/${mes}/${Number(dia)}`;
  };

  const options = useCallback(() => {
    const semana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const options = {
      labels: semana,
      tooltip: {
        style: {
          fontSize: '20px',
        },
        y: {
          formatter: (val: any) => `R$ ${toDecimalString(val)}`
        },
      },
      xaxis: {
        labels: {
          formatter: (val: any) => `R$ ${val.toFixed(0)}`
        }
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
            label: {
              fontSize: 16,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#46B2F0', '#1F9CE4', '#B2DDF6'],
      dataLabels: {
        enabled: false,
      },
    };
    return options;
  }, []);

  return (
    <>
      <Paper className={classNames(utilClasses.cardContainer, 'card')}>
        <div className={utilClasses.cardContent} style={{ width: '100%' }}>
          <div className={classes.celulaGridFull} style={{ padding: '10px 0' }}>
            <Typography
              color="primary"
              style={{ fontSize: '1.1rem', fontWeight: 500 }}
            >
              Receita por dia da semana
            </Typography>
          </div>
          <div style={{padding: '0 10px', width: '100%'}}>
            <div className={utilClasses.divider} />
          </div>

          <div className={utilClasses.alinharChart}>
            <div style={{ display: 'block', marginTop: 20 }}>
              <>
                {model.length === 0 ? (
                  <Typography variant="h5" style={{ textAlign: 'center' }}>
                    Não há dados para exibir
                  </Typography>
                ) : (
                  <Chart
                    options={options()}
                    series={series()}
                    type="bar"
                    className={utilClasses.chart}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
