import { useEffect, useState } from 'react';
import { AppEventEnum } from '../../../model/enums/enum-app-event';
import { useEventTools } from '../../../services/app/hooks/events/event-tools';
import { useSincronizacaoGeral } from 'services/app/hooks/sincronizar-dados';
import { EnumSincronizacaoGeralStatus } from 'model/enums/enum-sincronizacao-geral-status';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumTpSincronizacao } from 'model/enums/enum-tp-sincronizacao';

const SincronizacaoGeralChamada = () => {
  // HOOKS
  const { addHandler, removeHandler } = useEventTools();
  const { iniciarSincronizacaoGeral, getStatusSincronizacaoAtual } =
    useSincronizacaoGeral();
  const { getConfigByCod } = useEmpresaAtual()


  // STATES
  const [, setAtualiza] = useState<boolean>(false)

  const tempoSinc = getConfigByCod(6)
  const tpSincronizacao = getConfigByCod(5)

  const apenasNumero = (tempoSinc ?? '').replace(/\D/g, '')
  const converterNum = Number(apenasNumero)
  const numPraSegundos = converterNum * 60

  useEffect(() => {
    if (tpSincronizacao === EnumTpSincronizacao.Automatica || tpSincronizacao === EnumTpSincronizacao.AutomaticaComTemporizador) {

      let timerStart = 1000 * numPraSegundos;
      const sincAtual = getStatusSincronizacaoAtual();

      const startSinc = setInterval(async () => {
        if (
          sincAtual &&
          sincAtual.status === EnumSincronizacaoGeralStatus.EmAndamento
        ) {
          return;
        }

        if (!navigator.onLine) {
          return;
        }

        await iniciarSincronizacaoGeral();
      }, timerStart);

      return () => clearInterval(startSinc);
    }
  }, [getStatusSincronizacaoAtual, iniciarSincronizacaoGeral, numPraSegundos, tpSincronizacao]);

  useEffect(() => {
    addHandler(AppEventEnum.SincronizacaoGeral, () => { });

    return () => removeHandler(AppEventEnum.SincronizacaoGeral, () => { });
  }, [addHandler, removeHandler]);

  useEffect(() => {
    addHandler(AppEventEnum.EmpresaAtualAlterado, () => setAtualiza(prev => !prev))
    addHandler(AppEventEnum.ContratoAtualizado, () => setAtualiza(prev => !prev))

    return () => {
      removeHandler(AppEventEnum.EmpresaAtualAlterado, () => setAtualiza(prev => !prev))
      removeHandler(AppEventEnum.ContratoAtualizado, () => setAtualiza(prev => !prev))
    }
  }, [addHandler, removeHandler])

  return <></>;
};

export { SincronizacaoGeralChamada };
