import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { VoltarIcon } from 'views/components/icons';

export interface LocationProps {
  from: {
    page?: number;
  }
}

interface UploadCargaDetalheHeaderProps{
  handleVoltar: () => void;
}

export const UploadCargaDetalheHeader = ({ handleVoltar }: UploadCargaDetalheHeaderProps) => {

  const leftArea = useCallback(() => {
    return (
      <ButtonPrivateHeader
        icon={<VoltarIcon tipo="PRIVATE_HEADER"></VoltarIcon>}
        tooltip="Voltar"
        onClick={handleVoltar}
      ></ButtonPrivateHeader>
    );
  }, [handleVoltar]);

  return (
    <>
      <PrivatePageHeader title="Detalhes da Conversão" leftArea={leftArea()} />
    </>
  );
};
