import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme)=>({
    itemContent:{
        display:"flex",
        justifyContent:"space-between",
        marginBottom: theme.spacing(1),

    },
    ContainerGrid: {
        display: "flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        margin:"auto"
        
    },
    inputForm:{
        display:"flex",
        marginTop: theme.spacing(2),
    },
    formulario:{
        marginTop: theme.spacing(3),
        width:'100%'
    },
    cardItem:{
        boxSizing:'border-box',
        padding: theme.spacing(2),
        boxShadow: ' 0px 2.5px 8px 0px #28293D40',
        border: '.5px inset #28293D40',
        borderRadius: theme.shape.borderRadius,
    },

    listSetores:{
        marginTop: theme.spacing(2),
    }
}))