import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const CatalogoIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
                <path d="M 41.027344 1.8125 C 40.8125 1.808594 40.601563 1.871094 40.425781 1.992188 C 40.425781 1.992188 39.121094 2.925781 35.597656 3.945313 C 32.074219 4.964844 26.417969 6 18 6 C 17.621094 6 17.273438 6.214844 17.105469 6.554688 L 2.179688 36.40625 C 1.90625 36.789063 1.933594 37.308594 2.246094 37.65625 C 2.261719 37.675781 2.277344 37.691406 2.289063 37.703125 C 2.308594 37.722656 2.324219 37.738281 2.34375 37.753906 C 2.34375 37.757813 2.34375 37.757813 2.34375 37.757813 C 2.363281 37.773438 2.378906 37.785156 2.398438 37.796875 C 2.675781 38.035156 14.480469 48 34 48 C 34.382813 48 34.730469 47.78125 34.898438 47.4375 L 47.914063 20.839844 C 48.066406 20.535156 48.058594 20.171875 47.882813 19.878906 C 47.710938 19.585938 47.402344 19.402344 47.0625 19.386719 C 46.660156 19.371094 46.289063 19.59375 46.117188 19.957031 L 33.394531 45.964844 C 27.089844 45.890625 21.675781 44.753906 17.238281 43.285156 C 20.789063 43.785156 24.855469 43.863281 29.359375 43.078125 C 29.675781 43.023438 29.945313 42.820313 30.089844 42.53125 L 46.105469 9.796875 C 46.269531 9.476563 46.246094 9.09375 46.050781 8.792969 C 45.855469 8.492188 45.511719 8.324219 45.15625 8.34375 C 44.789063 8.367188 44.464844 8.585938 44.3125 8.914063 L 28.53125 41.15625 C 20.839844 42.394531 14.523438 41.082031 10.171875 39.5 C 10.226563 39.503906 10.28125 39.515625 10.339844 39.519531 C 14.695313 40.042969 20.171875 40.082031 24.707031 37.902344 C 24.910156 37.804688 25.074219 37.640625 25.171875 37.4375 L 41.898438 3.25 C 42.046875 2.945313 42.03125 2.585938 41.855469 2.296875 C 41.675781 2.003906 41.367188 1.824219 41.027344 1.8125 Z M 38.886719 4.855469 L 23.550781 36.199219 C 19.644531 37.96875 14.640625 38.023438 10.578125 37.535156 C 7.554688 37.175781 5.566406 36.667969 4.441406 36.355469 L 18.636719 7.960938 C 26.8125 7.902344 32.535156 6.910156 36.152344 5.867188 C 37.390625 5.507813 38.085938 5.1875 38.886719 4.855469 Z" />
            </DefaultIcon>
        </>
    );
};
