import * as Yup from 'yup';

export const useFormUploadCargaCadastroValidation = () => {

  const FormUploadCargaCadastroYupValidation = Yup.object().shape({
    conversao: Yup.string().required('O arquivo para a carga é necessário'),
    tipo: Yup.number().required('O tipo de carga é necessária'),
  });

  return {
    FormUploadCargaYupValidation: FormUploadCargaCadastroYupValidation
  }
}

