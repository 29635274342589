import { ProcessosModel } from 'model/api/gestao/processos/processos-model';
import { CardNaoEncontrado } from 'views/components/cards/card-naoencontrado';
import { CardProcessos } from 'views/components/cards/card-processos/card-processos';
import { InformacaoIcon } from 'views/components/icons';

export interface ProcessosListDataProps {
  model: Array<ProcessosModel>;
  onClick: (processos: ProcessosModel) => void;
}

export const ProcessosListData = (props: ProcessosListDataProps) => {
  return (
    <>
      {props.model.length === 0 && (
        <CardNaoEncontrado
          mensagem="Nenhum processo encontrado."
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props.model.length > 0 &&
        props.model.map((item, index) => {
          props.model.sort((a, b) => a.processoProducao - b.processoProducao)
          return (
            <CardProcessos
              onClick={props.onClick}
              model={item}
              key={index}
              order={index}
              selected={false}
            />
          );
        })}
    </>
  );
};
