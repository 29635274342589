import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    cardContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
    },
    label: {
        color: theme.palette.grey[700],
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: '1px',
        marginTop: '4px',
        marginBottom: '8px'
    },
}));
