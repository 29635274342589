import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { newGuid } from 'utils/new-guid';
import useSubItens from './get-subitens';
import { MovSimplesModificadoresProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-modificadores-produto-model';
import { EnumTpCalculoModificador } from 'model/enums/enum-tpcalculo-modificador';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { roundTo } from 'utils/round-to';


export const useNavigateSubItens = (
  product: MovSimplesProdutoModel,
  edit?: boolean
) => {
  // STATES REFS
  const productFinal = useRef<MovSimplesProdutoModel>(product);
  const [currentProduct, setCurrentProduct] =
    useState<MovSimplesProdutoModel | null>(null);
  const [productInitial, setProductInitial] =
    useState<MovSimplesProdutoModel>(product);
  const [productStack, setProductStack] = useState<
    Array<MovSimplesProdutoModel>
  >([]);
  const [productsAdicionais, setProductsAdicionais] = useState<
    MovSimplesProdutoModel[]
  >(product.adicionais);
  const [carregando, setCarregando] = useState<boolean>(false);




  // Hooks
  const { getFullSubItens, getProductForEditingWithAdditional, adicionais } =
    useSubItens();

  useEffect(() => {
    const getAllSubItens = async () => {
      setCarregando(true)
      let prod: MovSimplesProdutoModel;
      if (edit) {
        prod = await getProductForEditingWithAdditional(
          product,
          product.id,
          product.adicionais,
          product.qCom
        );
      } else {
        prod = await getFullSubItens(product, product.id);
        prod.adicionais = adicionais.current;
        setProductsAdicionais(adicionais.current);
      }
      prod = { ...prod, tpProduto: product.tpProduto }
      productFinal.current = prod;
      setProductInitial(prod);
      setCurrentProduct(prod);
      setCarregando(false)
    };

    getAllSubItens();
  }, [adicionais, edit, getFullSubItens, getProductForEditingWithAdditional, product]);

  function findParent(
    item: MovSimplesProdutoModel,
    child: MovSimplesProdutoModel
  ): MovSimplesProdutoModel | null {
    // Verifica se o item atual é o pai do item filho
    const contains = item.prodSubItem.some((obj) => {
      return JSON.stringify(obj) === JSON.stringify(child);
    });

    if (contains) {
      return item;
    }

    // Percorre os subItens do item atual de forma recursiva
    for (const subProduct of item.prodSubItem) {
      const parent = findParent(subProduct, child);
      if (parent) {
        return parent;
      }
    }

    return null;
  }

  function findSibling(
    parent: MovSimplesProdutoModel | null,
    current: MovSimplesProdutoModel
  ): MovSimplesProdutoModel | null {
    if (parent === null) return null;

    const currentIndex = parent.prodSubItem.indexOf(current);
    for (let i = currentIndex + 1; i < parent.prodSubItem.length; i++) {
      const sibling = parent.prodSubItem[i];
      if (sibling.prodSubItem.length > 0 && sibling.qCom > 0) {
        return sibling;
      }
    }

    const parentOfParent = findParent(productInitial, parent);
    return findSibling(parentOfParent, parent);
  }

  function findParentAndSibling(
    currentItem: MovSimplesProdutoModel,
    id: string
  ): {
    parent: MovSimplesProdutoModel | null;
    sibling: MovSimplesProdutoModel | null;
  } | null {
    // Verifica se o item atual é o item com o ID desejado
    if (currentItem?.id === id) {
      const parent = findParent(productInitial, currentItem);
      const sibling = findSibling(parent, currentItem);
      return { parent, sibling };
    }

    // Percorre os subItens do item atual de forma recursiva
    for (const subProduct of currentItem.prodSubItem) {
      const result = findParentAndSibling(subProduct, id);
      if (result) {
        return result;
      }
    }

    return null;
  }

  const returnNewProductQuantity = useCallback((valor, quantidade) => {
    const resultadoNaoCorrigido = Number(roundTo(valor / quantidade, 2));
    const resultado: number[] = [];

    for (let i = 0; i < quantidade; i++) {
      resultado.push(resultadoNaoCorrigido);
    }

    const soma = resultado.reduce((acc, current) => acc + current, 0);
    const sobra = valor - soma;

    resultado[resultado.length - 1] = resultado[resultado.length - 1] + sobra;

    return resultado;
  }, []);

  const returnaMaiorPreco = useCallback(
    (
      produtos: MovSimplesProdutoModel[],
      modificador: MovSimplesModificadoresProdutoModel
    ) => {
      return produtos
        .filter((p) => p.modificadorId === modificador.id && p.qCom > 0)
        .reduce((itemMax, itemAtual) => {
          if (itemAtual.vUnComOrig > itemMax) {
            return itemAtual.vUnComOrig;
          }
          return itemMax;
        }, 0);
    },
    []
  );

  const returnaQuantidadeProdutos = useCallback(
    (
      produtos: MovSimplesProdutoModel[],
      modificador: MovSimplesModificadoresProdutoModel
    ) => {
      return produtos
        .filter((p) => p.modificadorId === modificador.id)
        .reduce((acc, current) => acc + current.qCom, 0);
    },
    []
  );

  const returnaQuantidadeAtualizadaDoProduto = useCallback(
    (quantidadesDisponiveis: number[], qtdProdAtual) => {
      let novoValor = 0;
      const quantidades = quantidadesDisponiveis;

      for (let i = 0; i < qtdProdAtual; i++) {
        novoValor = novoValor + (quantidades.pop() ?? 0);
      }

      return {
        novaQuantidade: novoValor,
        quantidadesDisponiveis: quantidades
      };
    },
    []
  );

  const returnaProdAdicional = useCallback(
    (
      tpProduto: EnumTpProduto,
      valorProdutoPai: number,
      prod: MovSimplesProdutoModel,
      produtoId: string,
      qtd: number,
      qtdPai: number,
      produtos: MovSimplesProdutoModel[],
      modificador?: MovSimplesModificadoresProdutoModel,
      fator?: 'add' | 'sub'
    ) => {
      const tpCalculo = modificador?.tpCalculo ?? 0;

      const maiorPreco = modificador
        ? returnaMaiorPreco(produtos.filter(p => p.modificadorId === modificador.id), modificador)
        : 0;

      const attProdutcs = produtos.map((i) => {
        if (i.idAdicional === produtoId) {
          return {
            ...i,
            qCom: qtd
          };
        }
        return i;
      });

      const quantidadeGeral = modificador
        ? returnaQuantidadeProdutos(attProdutcs, modificador)
        : 0;

      let novasQuantidades = returnNewProductQuantity(1, quantidadeGeral);

      return produtos.map((i) => {
        if (i.idAdicional === produtoId) {
          const qCom = (qtd - prod.infoSubItem!.qPadrao) * qtdPai;

          const novaQuantidadeDoProduto = returnaQuantidadeAtualizadaDoProduto(
            novasQuantidades,
            qCom
          );

          novasQuantidades = novaQuantidadeDoProduto.quantidadesDisponiveis;

          const qComModificador = tpCalculo === EnumTpCalculoModificador.Maior ||
            tpCalculo === EnumTpCalculoModificador.Rateia
            ? novaQuantidadeDoProduto.novaQuantidade
            : 0

          const prodAtt = {
            ...i,
            vUnCom:
              tpCalculo === EnumTpCalculoModificador.Maior
                ? maiorPreco : tpCalculo === EnumTpCalculoModificador.Rateia && tpProduto === EnumTpProduto.Combo ? valorProdutoPai
                  : i.vUnComOrig,
            qCom: qCom,
            qComModificador: qComModificador,
            vProd:
              tpCalculo === EnumTpCalculoModificador.Maior ||
                tpCalculo === EnumTpCalculoModificador.Rateia
                ? novaQuantidadeDoProduto.novaQuantidade *
                Number(roundTo(tpCalculo === EnumTpCalculoModificador.Maior
                  ? maiorPreco
                  : tpProduto === EnumTpProduto.Combo ? valorProdutoPai : qCom * i.vUnComOrig, 3))
                : qCom * i.vUnComOrig,
            vFinal:
              tpCalculo === EnumTpCalculoModificador.Maior ||
                tpCalculo === EnumTpCalculoModificador.Rateia
                ? novaQuantidadeDoProduto.novaQuantidade *
                Number(roundTo(tpCalculo === EnumTpCalculoModificador.Maior
                  ? maiorPreco
                  : tpProduto === EnumTpProduto.Combo ? valorProdutoPai : qCom * i.vUnComOrig, 3))
                : qCom * i.vUnComOrig,
            indFin: true
          };

          return prodAtt
        } else if (modificador && modificador.id === i.modificadorId && i.qCom > 0) {
          const novaQuantidade = returnaQuantidadeAtualizadaDoProduto(
            novasQuantidades,
            i.qCom
          );
          novasQuantidades = novaQuantidade.quantidadesDisponiveis;

          const p = {
            ...i,
            qComModificador:
              tpCalculo === EnumTpCalculoModificador.Maior ||
                tpCalculo === EnumTpCalculoModificador.Rateia
                ? novaQuantidade.novaQuantidade
                : 0,
            vUnCom:
              tpCalculo === EnumTpCalculoModificador.Maior
                ? maiorPreco ?? 0 : tpCalculo === EnumTpCalculoModificador.Rateia && tpProduto === EnumTpProduto.Combo ? valorProdutoPai
                  : i.vUnComOrig,
            vProd:
              tpCalculo === EnumTpCalculoModificador.Maior ||
                tpCalculo === EnumTpCalculoModificador.Rateia
                ? Number(
                  roundTo(
                    novaQuantidade.novaQuantidade *
                    (tpCalculo === EnumTpCalculoModificador.Maior
                      ? maiorPreco ?? 0
                      : tpProduto === EnumTpProduto.Combo ? valorProdutoPai : i.qCom * i.vUnComOrig)
                    , 3)
                )
                : i.qCom * i.vUnComOrig,
            vFinal:
              tpCalculo === EnumTpCalculoModificador.Maior ||
                tpCalculo === EnumTpCalculoModificador.Rateia
                ? Number(
                  roundTo(
                    novaQuantidade.novaQuantidade *
                    (tpCalculo === EnumTpCalculoModificador.Maior
                      ? maiorPreco ?? 0
                      : tpProduto === EnumTpProduto.Combo ? valorProdutoPai : i.qCom * i.vUnComOrig)
                    , 3)
                )
                : i.qCom * i.vUnComOrig
          };
          return p;
        }

        return i;
      });
    },
    [
      returnNewProductQuantity,
      returnaMaiorPreco,
      returnaQuantidadeAtualizadaDoProduto,
      returnaQuantidadeProdutos
    ]
  );

  const returnProductAtt = useCallback(
    (
      tpProduto: EnumTpProduto,
      valorProdutoPai: number,
      prod: MovSimplesProdutoModel,
      id: string,
      qtd: number,
      qtdDoPai: number,
      modificador?: MovSimplesModificadoresProdutoModel,
      prodGradeId?: string,
      fator?: 'add' | 'sub'
    ): MovSimplesProdutoModel => {
      // altera o modificador
      const isRadioModificador =
        modificador?.qMin === 1 && modificador?.qMax === 1;

      const tpCalculo = modificador?.tpCalculo ?? 0;

      let qtdProd: number = 0;
      let maiorPreco: number = 0;

      // Se o id do produto for igual ao id desejado, atualize o valor de qCom
      if (prod.id === id) {
        const qCom = qtd <= prod.infoSubItem!.qMin
          ? prod.infoSubItem!.qMin
          : qtd > prod.infoSubItem!.qMax
            ? prod.infoSubItem!.qMax
            : qtdDoPai * qtd
        const p = {
          ...prod,
          qCom: qCom,
          vProd:
            qtd < (prod.infoSubItem!.qPadrao ?? 1)
              ? prod.infoSubItem!.qPadrao * prod.vUnCom
              : (qCom) * prod.vUnCom,
          vFinal:
            qtd < (prod.infoSubItem!.qPadrao ?? 1)
              ? prod.infoSubItem!.qPadrao * prod.vUnCom
              : (qCom) * prod.vUnCom
        };

        const isRadio = modificador?.qMin === 1 && modificador?.qMax === 1;

        setProductsAdicionais((prev) => {
          const temOAdicional = prev.find((item) => item.idAdicional === id);

          if (
            isRadio &&
            prod.produtoGradeId === modificador.produtoSubGradeIdPadrao
          ) {
            return prev;
          }

          if (tpCalculo === EnumTpCalculoModificador.Maior && modificador) {
            qtdProd = returnaQuantidadeProdutos(prev, modificador);
            maiorPreco = returnaMaiorPreco(prev, modificador);
          }

          if (tpCalculo === EnumTpCalculoModificador.Rateia && modificador) {
            qtdProd = returnaQuantidadeProdutos(prev, modificador);
          }

          if (qtd > prod.infoSubItem!.qPadrao) {
            if (temOAdicional) {
              prod.prodSubItem = returnaProdAdicional(
                tpProduto,
                valorProdutoPai,
                prod,
                id,
                qtd,
                qtdDoPai,
                prod.prodSubItem,
                modificador,
                fator
              );
              return returnaProdAdicional(
                tpProduto,
                valorProdutoPai,
                prod,
                id,
                qtd,
                qtdDoPai,
                prev,
                modificador,
                fator
              );
            }

            qtdProd = qtdProd + (qtd - prod.infoSubItem!.qPadrao) * qtdDoPai;
            maiorPreco = maiorPreco > prod.vUnCom ? maiorPreco : prod.vUnCom;

            const pr = {
              ...prod,
              qCom: (qtd - prod.infoSubItem!.qPadrao) * qtdDoPai,
              vProd: (qtd - prod.infoSubItem!.qPadrao) * prod.vUnCom,
              vFinal: (qtd - prod.infoSubItem!.qPadrao) * prod.vUnCom,
              indFin: true,
              idAdicional: prod.id,
              vDescUsuario: (((isRadio || modificador?.qMax === 1) && modificador) ? modificador?.descontoPadrao : prod.vDescUsuario),
              id: newGuid()
            };

            let adc = [
              ...prev,
              pr
            ];

            prod.prodSubItem = returnaProdAdicional(
              tpProduto,
              valorProdutoPai,
              prod,
              id,
              qtd,
              qtdDoPai,
              prod.prodSubItem,
              modificador,
              fator
            );
            adc = returnaProdAdicional(
              tpProduto,
              valorProdutoPai,
              prod,
              id,
              qtd,
              qtdDoPai,
              adc,
              modificador,
              fator
            );

            const produtoARemover = adc.find(
              (p) =>
                p.produtoGradeId === modificador?.produtoSubGradeIdPadrao ?? ''
            );

            const newAdc = adc.filter(
              (p) => p.produtoGradeId !== modificador?.produtoSubGradeIdPadrao
            )
              .filter(
                (p) =>
                  p.idDoProdutoPaiInfoSubItem !== produtoARemover?.idAdicional
              );

            return isRadio ? newAdc : adc;
          } else {
            if (temOAdicional) {
              return returnaProdAdicional(
                tpProduto,
                valorProdutoPai,
                prod,
                id,
                qtd,
                qtdDoPai,
                prev
                  .filter((i) => i.id !== temOAdicional.id)
                  .filter(
                    (p) => p.idDoProdutoPaiInfoSubItem !== temOAdicional.id
                  ),
                modificador,
                fator
              );
            } else if (isRadio) {
              const produtoAdicionalRadio = prev.find(p => p.produtoGradeId === modificador.produtoSubGradeIdPadrao && p.modificadorId === modificador.id)
              return returnaProdAdicional(
                tpProduto,
                valorProdutoPai,
                prod,
                id,
                qtd,
                qtdDoPai,
                prev
                  .filter((i) => i.id !== produtoAdicionalRadio?.id)
                  .filter(
                    (p) => p.idDoProdutoPaiInfoSubItem !== produtoAdicionalRadio?.id
                  ),
                modificador,
                fator
              );
            }
          }
          return prev;
        });

        if (
          isRadio &&
          prod.produtoGradeId === modificador.produtoSubGradeIdPadrao
        ) {
          return p;
        }

        if (prod.prodSubItem.length > 0) {
          for (let i of prod.prodSubItem) {
            if (i.qCom > 0) {
              returnProductAtt(
                tpProduto,
                valorProdutoPai,
                i,
                i.id,
                i.qCom,
                (qtd - prod.infoSubItem!.qPadrao) * qtdDoPai,
                modificador,
                i?.produtoGradeId ?? '',
                fator
              );
            }
          }
        }

        return p;
      }

      if (tpCalculo === EnumTpCalculoModificador.Maior && modificador) {
        qtdProd =
          qtdProd +
          prod.prodSubItem
            .filter((p) => p.modificadorId === modificador.id && p.qCom > 0)
            .reduce((acc, current) => acc + current.qCom, 0);

        maiorPreco = prod.prodSubItem
          .filter((p) => p.modificadorId === modificador.id && p.qCom > 0)
          .reduce((itemMax, itemAtual) => {
            if (itemAtual.vUnCom > itemMax) {
              return itemAtual.vUnCom;
            }
            return itemMax;
          }, 0);

        prod.prodSubItem = returnaProdAdicional(
          tpProduto,
          valorProdutoPai,
          prod,
          id,
          qtd,
          qtdDoPai,
          prod.prodSubItem,
          modificador,
          fator
        );
      }

      if (tpCalculo === EnumTpCalculoModificador.Rateia && modificador) {
        qtdProd =
          qtdProd +
          prod.prodSubItem
            .filter((p) => p.modificadorId === modificador.id && p.qCom > 0)
            .reduce((acc, current) => acc + current.qCom, 0);

        const newQtd = returnNewProductQuantity(valorProdutoPai, qtdProd);

        prod.prodSubItem = prod.prodSubItem.map((p) => {
          if (p.modificadorId === modificador.id && p.qCom > 0) {
            const quantidadeProduto = newQtd.pop() ?? 0;
            return {
              ...p,
              qComModificador: 0,
              vUnCom: quantidadeProduto,
              vProd:
                Number(roundTo((tpCalculo === EnumTpCalculoModificador.Rateia
                  ? quantidadeProduto * p.qCom
                  : p.qCom * p.vUnComOrig) -
                  p.vDescUsuario +
                  p.vAcrescUsuario, 3)),
              vFinal:
                Number(roundTo((tpCalculo === EnumTpCalculoModificador.Rateia
                  ? quantidadeProduto * p.qCom
                  : p.qCom * p.vUnComOrig) -
                  p.vDescUsuario +
                  p.vAcrescUsuario, 3))
            };
          }
          return p;
        });
      }

      const product = {
        ...prod,
        prodSubItem: isRadioModificador
          ? prod.prodSubItem.map((p) => {
            if (p.produtoGradeId === modificador.produtoSubGradeIdPadrao) {
              return {
                ...p,
                qCom: 0,
                indFin: false
              };
            }
            return p;
          })
          : prod.prodSubItem,

        modificadores: isRadioModificador
          ? prod.modificadores.map((m) => {
            if (m.id === modificador?.id) {
              return {
                ...modificador,
                produtoSubGradeIdPadrao: prodGradeId ?? ''
              };
            }

            return m;
          })
          : Boolean(modificador)
            ? prod.modificadores.map((m) => {
              if (m.id === modificador?.id) {
                return {
                  ...modificador,
                  qAtual:
                    fator && fator === 'add'
                      ? modificador.qAtual + 1
                      : fator === 'sub'
                        ? modificador.qAtual - 1
                        : modificador.qAtual
                };
              }

              return m;
            })
            : prod.modificadores
      };

      maiorPreco = 0;
      qtdProd = 0;

      // Caso contrário, percorra os subprodutos e chame a função recursivamente
      const updatedSubProducts = product.prodSubItem.map((subProd) =>
        returnProductAtt(
          tpProduto,
          valorProdutoPai,
          subProd,
          id,
          qtd,
          product.qCom,
          modificador,
          prodGradeId,
          fator
        )
      );

      // Retorne o produto atualizado com os subprodutos atualizados
      return { ...product, prodSubItem: updatedSubProducts };
    },
    [
      returnNewProductQuantity,
      returnaMaiorPreco,
      returnaProdAdicional,
      returnaQuantidadeProdutos
    ]
  );

  const handleAlterQuantity = async (
    tpProduto: EnumTpProduto,
    valorProdutoPai: number,
    produto: MovSimplesProdutoModel,
    qtd: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    prodGradeId?: string,
    fator?: 'add' | 'sub'
  ) => {

    const prodAtt = returnProductAtt(
      tpProduto,
      valorProdutoPai,
      productInitial,
      produto.id,
      qtd,
      produto.qCom,
      modificador,
      prodGradeId,
      fator
    );
    productFinal.current = prodAtt;
    setProductInitial(prodAtt);

    const isRadio = modificador?.qMin === 1 && modificador?.qMax === 1;

    setCurrentProduct((prev) => {
      if (prev) {
        const subProds = prev.prodSubItem.map((i) => {
          if (i.id === produto.id) {
            return {
              ...i,
              qCom: qtd
            };
          }
          return i;
        });

        return {
          ...prev,
          modificadores: prev.modificadores.map((m) => {
            if (m.id === modificador?.id && m.unicoId === modificador.unicoId) {

              const quantidadeAtual = (fator && fator === 'add') || isRadio
                ? modificador.qAtual + 1
                : fator === 'sub'
                  ? modificador.qAtual - 1
                  : modificador.qAtual

              return {
                ...m,
                produtoSubGradeIdPadrao: prodGradeId ?? '',
                qAtual: quantidadeAtual
              };
            }

            return m;
          }),
          prodSubItem: isRadio
            ? subProds.map((p) => {
              if (p.produtoGradeId === modificador.produtoSubGradeIdPadrao) {
                return {
                  ...p,
                  qCom: 0,
                  indFin: false
                };
              }

              return p;
            })
            : subProds
        };
      }

      return prev;
    });
  };

  function next() {
    let nextCurrent: MovSimplesProdutoModel | null | undefined = null;
    const hasSubProducts =
      currentProduct?.prodSubItem.length && currentProduct.qCom > 0;

    // Adiciona ao historico
    setProductStack((prev) => {
      if (currentProduct) {
        const hasProduct = prev.find((prod) => prod?.id === currentProduct?.id);
        if (hasProduct) {
          return [...prev];
        }
        return [...prev, currentProduct];
      }
      return prev;
    });

    if (hasSubProducts) {
      nextCurrent = currentProduct.prodSubItem.find(
        (sub) => sub.prodSubItem.length > 0 && sub.qCom > 0
      );

      if (!nextCurrent) {
        const result = findParentAndSibling(productInitial, currentProduct.id);
        nextCurrent = result?.sibling ?? null;
      }
    }
    setCurrentProduct(nextCurrent);
  }

  function goBack() {
    // remove o ultimo verificado
    const lastProduct = productStack[productStack.length - 1];
    // Atualiza o array
    setProductStack((prev) => prev.slice(0, -1));
    // Define o produto atual como o último produto visitado ou o produto inicial se o array estiver vazio
    setCurrentProduct(lastProduct);
  }

  function checkCredit(product: MovSimplesProdutoModel | null) {
    let totalItensPadrao = 0;
    let valorTotalDosPadroes = 0;
    let totalItensAtual = 0;
    let valorAtual = 0;
    let creditoDisponivel = 0;

    if (product !== null) {
      for (let i = 0; i < product.subItens.length; i++) {
        totalItensPadrao += product.subItens[i]?.qPadrao;
      }

      for (let i = 0; i < product.prodSubItem.length; i++) {
        valorTotalDosPadroes += product.prodSubItem[i].vFinal;
        totalItensAtual += product.prodSubItem[i].qCom;
        valorAtual +=
          product.prodSubItem[i].qCom * product.prodSubItem[i].vUnCom;
      }
    }

    creditoDisponivel =
      totalItensAtual < totalItensPadrao
        ? Number(roundTo(valorTotalDosPadroes - valorAtual, 2)) < 0
          ? 0
          : Number(roundTo(valorTotalDosPadroes - valorAtual, 2))
        : 0;

    return {
      totalItensPadrao,
      valorTotalDosPadroes,
      totalItensAtual,
      valorAtual,
      creditoDisponivel
    };
  }

  return {
    // STATES E REFS
    productFinal,
    currentProduct,
    productsAdicionais,
    productInitial,
    carregando,

    // NAVIGATE
    next,
    goBack,

    // UTILS
    isGoback: productStack.length > 0 ? true : false,
    setCurrentProduct,
    handleAlterQuantity,
    setProductInitial,
    checkCredit
  };
};
