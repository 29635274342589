import React from 'react';
import { useStyles } from './step-icone-styles';
import clsx from 'clsx';

export const StepIcone = (props: any) => {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {props.icon}
    </div>
  );
};
