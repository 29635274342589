import { useCallback } from 'react';
import { useApiBase } from '../../base/api-base';
import { VariaveisAmbiente } from 'config';


export function useDeletePerfilEmpresaUsuario() {
	const { invocarApi, ...outrasPropriedades } = useApiBase();

	const deletePerfilEmpresaUsuario = useCallback(
		(id: string) =>
			invocarApi({
				url: `/${VariaveisAmbiente.gatewayVersion}/UsuarioEmpresaPerfil/${id}`,
				method: "DELETE",
				headers: { "Content-Type": "application/json" },
				enviarTokenUsuario: true,
			}),
		[invocarApi]
	);
	return {
		...outrasPropriedades,
		deletePerfilEmpresaUsuario
	}
}