import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        height: '100%',
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        zIndex: 99999
    },
    btnDelete: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.light,
            borderColor: theme.palette.error.main,
        },
        '& svg': {
            fill: theme.palette.error.main
        }
    },
}));