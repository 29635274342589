import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        borderRadius: '8px',
        padding: '8px',
        boxShadow: '1px 1px 2px rgb(0 0 0 / 10%)',
        marginBottom: 8,
        backgroundColor: '#FFF'
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'spacing-between'
    },
    containerType: {
        flexWrap: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    containerInstallments: {
        display: 'flex',
        alignItems: 'center'
    },
    containerValue: {
        textAlign: 'end'
    },
    textCard: {
        fontSize: '16px',
        fontWeight: 500,
    },
    textCardPag: {
        fontSize: '16px',
        fontWeight: 700,
    }
}));
